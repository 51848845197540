import React, { useState, useEffect } from 'react';
import { 
    Grid, Dialog, DialogContent, Button, 
    Card, CardContent, Typography, Divider
} from '@material-ui/core';
import { 
    AutocompleteField, TextFieldComponent, DateField, 
    NumericTextField, CheckboxField, SearchComponent
} from './../../../components/SharedComponents/Fields';
import { 
    ConfirmDialog, DialogHeader, DialogFooter, 
} from './../../../components/SharedComponents/Dialogs';
import {  } from './../../../utils/axios';
import { FileSelection } from '../../../components/SharedComponents/FileSelection';
import { dateFormat, errorHandling } from './../../../components/SharedComponents/CustomHooks';
import NumberFormat from './../../../components/SharedComponents/NumberFormat';
import { FilterList, AttachMoney } from '@material-ui/icons';
import { fetchBankAccounts, fetchExpiries, fetchExpiry } from './../../../utils/axios';

function ConfirmDialogComponent(props) {
    const data = props.data.data;
    let dialogConfirmContent = '';
    switch(props.data.msg) {
        case 'file-upload':
            dialogConfirmContent='¿Desea subir el archivo seleccionado?';
            break;
        case 'proceed-payment':
            dialogConfirmContent='¿Desea realizar el pago especificado?';
            break;
        case 'proceed-delete':
            dialogConfirmContent=
                `El pago con referencia: ${data.row.paymentId} será eliminado.`;
            break;
        case 'proceed-delete-multiple':
            dialogConfirmContent =
                `¿Está seguro que desea eliminar todos los pagos seleccionados?`;
            break;
        default:
            dialogConfirmContent='...';
    }
    return(
        <ConfirmDialog 
            open={props.open}
            handleToggle={props.handleToggle}
            confirmCallback={data.callback}
            data={dialogConfirmContent}
        />
    )
}

function PaymentDialog(props) {
    const defaultFieldsValue = {
        amountPayed: '',
        paymentType: null,
        creditor: null,
        paymentDate: null,
        expiration: null,
        paymentId: '',
        paymentStatus: false,
        client: null,
        destinationAccount: null,
    }
    const rFields = ['amountPayed', 'paymentType', 'creditor', 'paymentDate', 'expiration'];
    const action = props.modify ? 'update' : 'create';
    const [fields, setField] = useState(defaultFieldsValue);
    const [errorFields, setErrorFields] = useState({}); // the error field obj (to tell the field if there's an error)
    const [error, setError] = useState(false); // general error variable. Show if there's a field with an error.
    const [file, setFile] = useState('');
    const [bankAccounts, setBankAccounts] = useState([]);
    const [requiredFields, setRequiredFields] = useState(rFields);
    const [expiries, setExpiries] = useState([]);

    const getFiltersOptions = (option) => {
        return(
            (option ? option : [])
        );
    }

    const validateRequiredFields = (type, fieldToCheck, value) => { // validate fields errors
        let newErrorState = {...errorFields};
        const fieldCheck = (key, value) => {
            newErrorState[key] = 
            (value === '' || value === null || value === undefined || Object.keys(value).length === 0) ? true : false;
        }
        if(type === 'all') {
            requiredFields.forEach(key => {
                fieldCheck(key, fields[key]);
            });
        }else if(type === 'single' && requiredFields.includes(fieldToCheck)) {
            fieldCheck(fieldToCheck, value);
        }
        const hasErr = Object.keys(newErrorState).find(item => newErrorState[item]);
        setError(Boolean(hasErr));
        setErrorFields({...newErrorState});
        return Boolean(hasErr);
    }

    const handleFieldChange = (field, event, value, type) => {
        let newVal;
        switch(field) {
            case 'paymentDate':
                newVal = dateFormat(event);
                break;
            case 'paymentType':
                newVal = value;
                setField({...fields, destinationAccount: null});
                break;
            case 'expiration':
            case 'destinationAccount':
                newVal = value;
                break;
            case 'creditor':
                newVal = value;
                setField({...fields, destinationAccount: null});
                break;
            case 'paymentStatus':
                newVal = !fields[field];
                break;
            case 'date':
                newVal = {...fields[field], [type]: event}
                break;
            case 'client':
                newVal = event;
                setField({...fields, expiration: null});
                break;
            default:
                newVal = event.target ? event.target.value : event.value;
                break;
        }
        validateRequiredFields('single', field, newVal);
        setField(prevState=>({ ...prevState, [field]: newVal})); //this way the state of the object property updates.
    }

    const handleSaveBtn = () => {
        if(validateRequiredFields('all')) { return }
        const data = {
            ...fields,
            paymentDate: fields.paymentDate.trim(),
            amountPayed: Number(fields.amountPayed),
            creditor: fields.creditor.uniqueId,
            paymentType: fields.paymentType.value,
            expirationId: fields.expiration.uniqueId,
            file: file,
            paymentStatus: fields.paymentStatus ? 'Processed' : 'Complete',
            destinationAccount: fields.destinationAccount && fields.destinationAccount.value,
        }
        props.handlePayment(data, action);
    }

    const handleEditRow = (expiry, bAccounts) => {
        let editData = {uniqueId: props.data.uniqueId};
        Object.keys(fields).forEach(key => {
            switch(key){
                case 'expiration':
                    editData[key] = expiry;
                    break;
                case 'paymentType':
                    editData[key] = props.listData.payTypes.find(item => item.name === props.data[key]);
                    break;
                case 'creditor':
                    editData[key] = props.listData.creditors.find(item => item.uniqueId === props.data.creditorId);
                    break;
                case 'amountPayed':
                    editData[key] = props.data[key].toString();
                    break;
                case 'paymentStatus':
                    editData[key] = props.data[key] === "Processed" ? true : false;
                    break;
                case 'client':
                    editData[key] = {fullName: props.data.clientName};
                    break;
                case 'destinationAccount':
                    editData[key] = bAccounts.find(item => item.value === props.data[key]);
                    break;
                case 'paymentDate':
                    editData[key] = props.data[key] + ' '; // the JS date object falls behind 1 day in some specific cases.
                    break;
                default:
                    editData[key] = props.data[key];
                    break;
            }
            
        });
        setField({...editData});
    }
    const getBankAccounts = async (fromEditRow) => {
        const preventFetch = bankAccounts.find(item => item.owner === (fields.creditor && fields.creditor.uniqueId));
        if(!preventFetch) {
            if(!fromEditRow) {props.setLoading(true)}
            const endpoint = fetchBankAccounts({token: props.token, owner: fromEditRow ? props.data.creditorId : fields.creditor.uniqueId});
            return await endpoint.then(response => {
                const formattedData = response.data.map(item => {
                    return {
                        name: `${item.name} -- ${item.number}`,
                        value: item.uniqueId,
                        owner: item.owner,
                    }
                })
                setBankAccounts(formattedData);
                props.setLoading(false);
                return formattedData;
            }).catch(errors => {
                props.setLoading(false);
                errorHandling(errors, props.handleSimpleDialog);
            });
        }
    }
    const expiryFormattedData = (expiry) => {
        return {
            name: `${expiry.expirationDate} | $${expiry.amountReceivable}`,
            amountReceivable: expiry.amountReceivable,
            amountCharged: expiry.amountCharged,
            uniqueId: expiry.uniqueId,
        }
    }
    const getExpiries = async () => {
        const params = {
            token: props.token, 
            client: fields.client ? fields.client.fullName : null,
            expiration_date_from: dateFormat(fields.date ? fields.date.start : null),
            expiration_date_to: dateFormat(fields.date ? fields.date.end : null),
        }
        props.setLoading(true);
        const endpoint = props.modify ? fetchExpiry(props.token, props.data.expirationId) : fetchExpiries(params);
        return await endpoint.then(response => {
            let formattedData = typeof response.data.length === 'number' ? response.data : [response.data];
            formattedData = formattedData.map(item => {
                return expiryFormattedData(item)
            });
            setExpiries(formattedData);
            props.setLoading(false);
            return formattedData;
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleSimpleDialog);
        });
    }

    const setInitialModifyData = async () => {
        const cExpiries = await getExpiries();
        const cBankAccounts = props.data.paymentType === 'Transferencia bancaria' ? await getBankAccounts(true) : [];
        handleEditRow(cExpiries[0], cBankAccounts);
    }

    useEffect(() => {
        if(props.modify) {
            setInitialModifyData();
        }
        if(props.hasExpiry) {
            const expiryData = expiryFormattedData(props.data);
            setField({...fields, expiration: expiryData, client: {fullName: props.data.client}});
        }
    }, []);

    useEffect(() => {
        if(!props.modify) {
            if(fields.client && fields.client.fullName) {
                getExpiries();
            }else {
                setExpiries([]);
            }
        }
    }, [fields.client]);

    useEffect(() => {
        if(!props.modify && fields.client && fields.client.fullName && fields.date) {
            getExpiries();
        }
    }, [fields.date]);

    useEffect(() => {
        let newRequiredFields;
        if(fields.paymentType && fields.paymentType.value === 'Bank transfer') {
            newRequiredFields = rFields.concat('destinationAccount');
        }else {
            newRequiredFields = rFields;
        }
        setRequiredFields(newRequiredFields || rFields);
    }, [fields.paymentType])


    useEffect(() => {
        if(
            fields.paymentType && fields.paymentType.value === 'Bank transfer' && 
            fields.creditor && fields.creditor.uniqueId
        ) {
            getBankAccounts();
        }
    }, [fields.creditor, fields.paymentType]);

    return(
        <Dialog
            
            fullWidth
            maxWidth="lg"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Pago vencimiento"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />
            <DialogContent style={{margin:'2% 0'}}>
                <Grid container direction="row" spacing={2} justifyContent="space-between" style={{padding:'10px 16px 15px 16px'}}>
                    <Grid item xs={4}>
                        <Card>
                            <CardContent style={{marginTop:'5px'}}>
                                <Typography
                                    variant="subtitle1"
                                    style={{color:'#424242'}}
                                >
                                    <div style={{display:'table'}}>
                                        <NumberFormat value={fields.expiration ? fields.expiration.amountReceivable : 0} />
                                    </div>
                                    <Typography
                                        variant="overline"
                                        style={{color:'#616161', fontSize:'11px'}}
                                    >Monto vencido</Typography>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <CardContent style={{marginTop:'5px'}}>
                                <Typography
                                    variant="subtitle1"
                                    style={{color:'#424242'}}
                                >
                                    <div style={{display:'table'}}>
                                        <NumberFormat value={fields.expiration ? fields.expiration.amountCharged : 0} />
                                    </div>
                                    <Typography 
                                        variant="overline"
                                        style={{color:'#616161', fontSize:'11px'}}
                                    >Monto pagado</Typography>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <CardContent style={{marginTop:'5px'}}>
                                <Typography
                                    variant="subtitle1"
                                    style={{color:'#424242'}}
                                >
                                    <div style={{display:'table'}}>
                                        <span>
                                            {fields.client ? fields.client.fullName : '- - -'}
                                        </span>
                                    </div>
                                    <Typography
                                        variant="overline"
                                        style={{color:'#616161', fontSize:'11px'}}
                                    >Cliente</Typography>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {
                    !props.removeExpirySelection &&
                    <>
                        <Typography
                            variant={"subtitle1"}
                            className={"section-header"}
                            style={{fontWeight: 'bold', color:'#878787', display:'flex', alignItems:'center', marginTop:'1rem'}}
                        >
                            <FilterList style={{fontSize:20, marginRight:'.3rem'}} />
                            SELECCIÓN DE VENCIMIENTO
                        </Typography>
                        <Divider style={{margin:'1rem 0'}}/>
                        <Grid container direction="row" spacing={2} style={{padding:'0 16px'}}>
                            {
                                !props.modify &&
                                <Grid container item xs={9} spacing={2}>
                                    <SearchComponent 
                                        xs={4}
                                        containerClass="field-container"
                                        fieldLabel="Participantes"
                                        fieldID="payment-client-add"
                                        fieldInnerLabel="Seleccione un participante"
                                        handleChange={(event, value) => { handleFieldChange('client', event, value) }}
                                        optionType={'participants'}
                                        clearOnBlur={false}
                                        limitTags={1}
                                    />
                                    <DateField 
                                        xs={4}
                                        typeVariant="subtitle1"
                                        typeClass="field-label"
                                        fieldLabel="Fecha inicio"
                                        dateFieldID="filter-date-start"
                                        fullWidth={true}
                                        dateFieldClass="date-field"
                                        inputVariant="outlined"
                                        dateFieldLabel="Inicio vencimiento"
                                        format="dd/MM/yyyy"
                                        value={(fields.date && fields.date.start) ? fields.date.start : null}
                                        handleChange={(event, value) => { handleFieldChange('date', event, value, 'start') }}
                                    />
                                    <DateField 
                                        xs={4}
                                        typeVariant="subtitle1"
                                        typeClass="field-label"
                                        fieldLabel="Fecha corte"
                                        dateFieldID="filter-date-end"
                                        fullWidth={true}
                                        dateFieldClass="date-field"
                                        inputVariant="outlined"
                                        dateFieldLabel="Corte vencimiento"
                                        format="dd/MM/yyyy"
                                        value={(fields.date && fields.date.end) ? fields.date.end  : null}
                                        handleChange={(event, value) => { handleFieldChange('date', event, value, 'end') }}
                                    />
                                </Grid>
                            }
                            <AutocompleteField
                                xs={props.modify ? 4 : 3}
                                containerClass="field-container"
                                fieldClass="fields"
                                typeVariant="subtitle1"
                                typeClass="field-label"
                                fieldLabel="Vencimientos"
                                fieldID="autocomplete-field-expiry"
                                fieldInnerLabel="Seleccione un vencimiento"
                                fieldVariant="outlined"
                                value={fields.expiration ? fields.expiration : null}
                                handleChange={handleFieldChange.bind(this, 'expiration')}
                                options={getFiltersOptions(expiries)}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                                limitTags={1}
                                disabled={props.modify}
                                error={errorFields.expiration}
                                helperText={errorFields.expiration ? "Seleccione un vencimiento" : null}
                                noOptionsText={!fields.client ? <i>SELECCIONE UN PARTICIPANTE</i> : null}
                            />
                        </Grid>
                    </>
                }
                <Typography
                    variant={"subtitle1"}
                    className={"section-header"}
                    style={{fontWeight: 'bold', color:'#878787', display:'flex', alignItems:'center', marginTop:'2rem'}}
                >
                    <AttachMoney style={{fontSize:20, marginRight:'.3rem'}} />
                    DETALLE DE PAGO
                </Typography>
                <Divider style={{margin:'1rem 0'}}/>
                <Grid container direction="row" spacing={2} style={{padding:'0 16px'}}>
                    <TextFieldComponent 
                        xs={4}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Referencia"
                        fieldID="filter-field-reference"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        placeholder="Ej: 1234567890"
                        margin='dense'
                        fullWidth
                        value={fields.paymentId ? fields.paymentId : ''}
                        onChange={handleFieldChange.bind(this, 'paymentId')}
                    />
                    <NumericTextField
                        xs={4}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Monto a pagar"
                        placeholder="Ej: $50,000"
                        fieldID="text-field-amount"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        margin='dense'
                        fullWidth
                        prefix="$"
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={fields.amountPayed ? fields.amountPayed : ''}
                        onChange={handleFieldChange.bind(this, 'amountPayed')}
                        error={errorFields.amountPayed}
                        helperText={errorFields.amountPayed ? "Introduzca un monto" : null}
                        disabled={props.modify}
                    />
                    <AutocompleteField
                        xs={4}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Tipo de pago"
                        fieldID="autocomplete-field-pay-type"
                        fieldInnerLabel="Seleccione un tipo de pago"
                        fieldVariant="outlined"
                        value={fields.paymentType ? fields.paymentType : null}
                        handleChange={handleFieldChange.bind(this, 'paymentType')}
                        options={getFiltersOptions(props.listData.payTypes)}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.value === value.value}
                        error={errorFields.paymentType}
                        helperText={errorFields.paymentType ? "Seleccione un tipo de pago" : null}
                        limitTags={1}
                    />
                </Grid>
                <Grid container direction="row" spacing={2} style={{padding:'0 16px'}}>
                    <AutocompleteField
                        xs={4}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Acreedor"
                        fieldID="autocomplete-field-creditor"
                        fieldInnerLabel="Seleccione un acreedor"
                        fieldVariant="outlined"
                        value={fields.creditor ? fields.creditor : null}
                        handleChange={handleFieldChange.bind(this, 'creditor')}
                        options={getFiltersOptions(props.listData.creditors)}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        error={errorFields.creditor}
                        helperText={errorFields.creditor ? "Seleccione un acreedor" : null}
                        limitTags={1}
                    />
                    {
                        fields.paymentType && fields.paymentType.value === 'Bank transfer' ?
                            <AutocompleteField
                                xs={4}
                                containerClass="field-container"
                                fieldClass="fields"
                                typeVariant="subtitle1"
                                typeClass="field-label"
                                fieldLabel="Cuentas destino"
                                fieldID="autocomplete-field-destiny-accounts"
                                fieldInnerLabel="Seleccione una cuenta"
                                fieldVariant="outlined"
                                value={fields.destinationAccount ? fields.destinationAccount : null}
                                handleChange={handleFieldChange.bind(this, 'destinationAccount')}
                                options={getFiltersOptions(bankAccounts)}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.value === value.value}
                                error={errorFields.destinationAccount}
                                helperText={errorFields.destinationAccount ? "Seleccione una cuenta" : null}
                                limitTags={1}
                                noOptionsText={!fields.creditor ? <i>SELECCIONE UN ACREEDOR</i> : null}
                            /> : null
                    }
                    <DateField 
                        xs={4}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Fecha"
                        dateFieldID="field-payment-date"
                        fullWidth={true}
                        dateFieldClass="date-field"
                        inputVariant="outlined"
                        dateFieldLabel="Fecha de pago"
                        format="dd/MM/yyyy"
                        value={fields.paymentDate ? fields.paymentDate : null}
                        handleChange={handleFieldChange.bind(this, 'paymentDate')}
                        error={errorFields.paymentDate}
                        helperText={errorFields.paymentDate ? "Seleccione una fecha" : null}
                    />
                    <Grid item xs={4}>
                        <Typography
                            variant="subtitle1"
                            className="field-label"
                        >Comprobante</Typography>
                        <FileSelection
                            xs={12}
                            file={file}
                            setFile={setFile}
                            fileExtensions=".pdf,.png,.jpg,.jpeg"
                        />
                    </Grid>
                    {
                        props.modify && 
                        <Grid container item xs={4} direction="row" alignItems='flex-end' spacing={2} style={{padding:'16px 0 16px 16px'}}>
                            <CheckboxField
                                xs={12}
                                alignItems="flex-end"
                                check={fields.paymentStatus || false}
                                handleChange={handleFieldChange.bind(this, 'paymentStatus')}
                                color="primary"
                                textColor="#43A047"
                                label="PAGO PROCESADO"
                            />
                        </Grid>
                    }
                </Grid>
                <Grid container justifyContent="flex-end" style={{padding: '32px 16px 0 0'}}>
                    <Button 
                        variant="contained" 
                        className={error ? "action-method-btn disabled" : "action-method-btn save"}
                        disableElevation
                        size="small"
                        onClick={handleSaveBtn}
                        disabled={error}
                    >
                        {props.modify ? "modificar pago" : "realizar pago"}
                    </Button>
                </Grid>
            </DialogContent>
            <DialogFooter 
                // dialogFooterText="Guardar cambios"
            />
        </Dialog>
    );
}

export { PaymentDialog, ConfirmDialogComponent }