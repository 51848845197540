import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createCreditor } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  debtorsValidation,
  resetError,
  successHandler,
  translations,
} from "../../../utils";
import {
  createCreditor as createCR,
} from "../../../utils/axios";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import ParticipantSelectInput from "src/containers/Participant/ParticipantSelectInput";
import InstitutionsSelectInput from "src/containers/Institutions/InstitutionsSelectInput";
import { MenuItem } from '@material-ui/core';
import { SimpleConfirmDialog } from '../../../components/SharedComponents/Dialogs';

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    display: "none",
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  checkboxLabelReactSelect: {
    color: green[500],
    fontSize: "small",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    overflowy: "auto",
    padding: 20,
    paddingBottom: 64,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface ICreditorFormProps {
  classes: { [key: string]: string };

  user: IUser;
  createCreditor(creditor: ICreditor): IAction<ICreditor[]>;
  changeSection(sectionNumber: number): void;
}

interface IDialogOptions {
  name: string, 
  msg: string, 
  data?: string
}

interface ICreditorFormState {
  [key: string]: string | string[] | number | boolean | IOptionType | IOptionType[] | IDialogOptions;
  abbreviation: string;
  abbreviationError: string;
  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  isRepresentative: boolean;
  isRepresentativeError: string;
  loading: boolean;
  participant: string[];
  institution: string[];
  type: string;
  typeError: string;
  simpleDialog: IDialogOptions;

}
class CreditorForm extends React.Component<
  ICreditorFormProps,
  ICreditorFormState
> {
  public state: ICreditorFormState = {
    abbreviation: "",
    abbreviationError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    isRepresentative: false,
    isRepresentativeError: "",
    loading: false,
    participant: [],
    institution: [],
    type: "physical",
    typeError: "",
    simpleDialog: { name: '', data: '', msg: ''}
  };

  constructor(props: ICreditorFormProps) {
    super(props);

    this.handleAddParticipant = this.handleAddParticipant.bind(this);
    this.handleAddInstitution = this.handleAddInstitution.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteParticipant = this.handleDeleteParticipant.bind(this);
    this.handleDeleteInstitution = this.handleDeleteInstitution.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleToggleSimpleDialog = this.handleToggleSimpleDialog.bind(this);
  }

  public handleAddParticipant(id: string) {
    this.setState({ participant: [id] });
  }
  public handleDeleteParticipant(id: string) {
    this.setState({ participant: [] });
  }
  public handleAddInstitution(id: string) {
    this.setState({ institution: [id] });
  }
  public handleDeleteInstitution(id: string) {
    this.setState({ institution: [] });
  }

  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }

  public handleSelect(key: string, value: string) {
    this.setState({
      [key]: value,
    });
  }

  public handleToggleSimpleDialog = (dialog: string, msgs: string) => {
    this.setState({ simpleDialog: {name: dialog, msg: msgs} });
  }

  public async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();
    
    const creditor: ICreditor = {
      abbreviation: this.state.abbreviation.toUpperCase(),
      isRepresentative: this.state.isRepresentative,
      type: this.state.type,
      participant: this.state.participant[0],
      institution: this.state.type === 'legal' ? this.state.institution[0] : undefined,
    };

    this.setState({ loading: true });

    if (await debtorsValidation(this, this.props.user.token, this.state.participant, false)) {
      createCR(this.props.user.token, creditor)
        .then((response) => {
          if (response.data.warning) {
            this.handleToggleSimpleDialog('simple-dialog', response.data.warning);
            this.setState({ loading: false });
          }else {
            this.props.createCreditor(response.data.data);
            successHandler(response, this, translations.CREDITOR);
            this.handleReset()
          }
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleReset() {
    this.setState((prevState) => ({
      abbreviation: "",
      dialog: false,
      dialogTitle: "",
      errorMessage: "",
      participant: [],
      institution: [],
      isRepresentative: false,
      loading: false,
    }));
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public handleCheckBoxChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public render() {
    const { classes } = this.props;
    return (
      <form
        id="ClientForm"
        onSubmit={this.handleSubmit}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.NEW_CREDITOR}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="type" shrink={true}>
                  {translations.TYPE}
                </InputLabel>
                <Select
                  value={this.state.type}
                  onChange={this.handleSelectChange("type")}
                  required={true}
                  error={this.state.typeError !== ""}
                  inputProps={{
                    id: "type",
                    name: "type",
                  }}
                >
                  <MenuItem value="legal">{translations.LEGAL_2}</MenuItem>
                  <MenuItem value="physical">{translations.PHYSICAL}</MenuItem>
                </Select>
              </FormControl>
              <br />
              <FormControlLabel
                label="Es Representante"
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.isRepresentative}
                    onChange={this.handleCheckBoxChange("isRepresentative")}
                  />
                }
                value="isRepresentative"
              />
              <br />
              <TextField
                label={translations.ABBREVIATION}
                className={classes.textField}
                value={this.state.abbreviation.toUpperCase()}
                onChange={this.handleChange("abbreviation")}
                fullWidth={true}
                required={true}
                error={this.state.abbreviationError !== ""}
                helperText={this.state.abbreviationError}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <br />
              {this.state.type === 'physical' ?
                <div>                  
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.CLIENT}
                  </Typography>
                  <ParticipantSelectInput
                    selectedParticipantId={this.state.participant}
                    addParticipant={this.handleAddParticipant}
                    deleteParticipant={this.handleDeleteParticipant}
                    participantType='company representative'
                  />
                </div> :
                <div>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.INSTITUTION}
                  </Typography>
                  <InstitutionsSelectInput
                    selectedInstitutionId={this.state.institution}
                    addInstitution={this.handleAddInstitution}
                    deleteInstitution={this.handleDeleteInstitution}
                  />
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.COMPANY_REPRESENTATIVE}
                  </Typography>
                  <ParticipantSelectInput
                    selectedParticipantId={this.state.participant}
                    addParticipant={this.handleAddParticipant}
                    deleteParticipant={this.handleDeleteParticipant}
                    participantType='company representative'
                  />
                </div>
              }
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  form="ClientForm"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
        {
        this.state.simpleDialog.name === 'simple-dialog' ?
          <SimpleConfirmDialog
            open={true}
            handleToggle={this.handleToggleSimpleDialog}
            data={this.state.simpleDialog.msg}
          /> : null
      }
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createCreditor })(CreditorForm)
);
