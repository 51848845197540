import * as React from 'react';

import Buttom from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import ArrowbackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';

import { translations } from '../../utils';

const styles = createStyles({
    button: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white',
    },
    checkboxLabel: {
        color: green[500],
        fontSize: 'medium'
    },
    formControl: {
        marginTop: 20,
        minWidth: 300,
        width: 300
    },
    root: {
        marginBottom: 5,
        marginTop: 5
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: 500

    }
});

class SelectInput extends React.Component {
    constructor(props) {
        super(props);
    this.state = {
        institutionType: '',
        institutionTypeError: '',
        institution: '',
        institutionError: '',
        accountNumber: '',
        accountNumberError: '',
        accountType: '',
        accountTypeError: '',
        currencyType: 'DOP',
        currencyTypeError: '',
        open: false,
    };


        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.openDialogHandler = this.openDialogHandler.bind(this);
        this.closeDialogHandler = this.closeDialogHandler.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.map = this.map.bind(this);
    }
    handleChange(key) {
        return (event) => {
            this.setState({
                [key]: event.currentTarget.value,
                [key + 'Error']: ''
            });
        }
    }
    handleSelectChange(key) {
        return (event) => {
            this.setState({
                [key]: event.target.value,
                [key + 'Error']: ''
            });
        }
    }
    openDialogHandler() {
        this.setState({ open: true });
    }
    closeDialogHandler() {
        this.setState({ open: false });
    }
    handleAdd(event) {

        let valid = true;
        const errorMessage = "Este campo no puede estar vacio";

        if (this.state.institutionType.trim() === '') {
            valid = false;
            this.setState({ institutionTypeError: errorMessage });
        }

        if (this.state.institution.trim() === '') {
            valid = false;
            this.setState({ institutionError: errorMessage });
        }

        if (this.state.accountNumber.trim() === '') {
            valid = false;
            this.setState({ accountNumberError: errorMessage });
        }

        if (this.state.accountType.trim() === '') {
            valid = false;
            this.setState({ accountTypeError: errorMessage });
        }

        if (valid) {
            this.props.add({
                institutionType: this.state.institutionType,
                institution: this.state.institution,
                accountNumber: this.state.accountNumber,
                accountType: this.state.accountType,
                currencyType: this.state.currencyType
            }, "bankAccountInformation");

            this.setState({ open: false, institutionType: '', institution: '' });
        }

    }
    handleDelete(index) {
        return (event) => {
            this.props.delete(index,"bankAccountInformation");
        }
    }
    map(bankAccount, index) {
        return (
            <TableRow key={index}>
                <TableCell>{
                    bankAccount.institutionType === "cooperative" ? "Cooperativa":
                    bankAccount.institutionType === "bank" ? "Bancaria": null
                }</TableCell>
                <TableCell>{bankAccount.institution}</TableCell>
                <TableCell align={'right'}>
                    <IconButton onClick={this.handleDelete(index)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }

    render() {
        const { classes, bankAccounts } = this.props;
        const { open } = this.state;

        return (
            <div className={classes.root}>
                <Dialog open={open} onClose={this.closeDialogHandler} >
                    <DialogActions>
                        <IconButton className={classes.button} onClick={this.closeDialogHandler}>
                            <ArrowbackIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent >
                    <FormControl className={classes.formControl} error={this.state.institutionTypeError !== ''}>
                                <InputLabel htmlFor="institutionType" shrink={true}>{translations.INSTITUTION_TYPE}</InputLabel>
                                    <Select
                                        value={this.state.institutionType}
                                        onChange={this.handleSelectChange('institutionType')}
                                        required={true}
                                        native={true}
                                        inputProps={{
                                            id: 'institutionType',
                                            name: 'institutionType',
                                        }}
                                    >
                                        <option value="">{"Seleccione una opcion"}</option>
                                        <option value="bank">{"Bancaria"}</option>
                                        <option value="cooperative">{"Cooperativa"}</option>
                                    </Select>
                                </FormControl>
                                
                                <TextField
                                    label={translations.INSTITUTION}
                                    className={classes.textField}
                                    value={this.state.institution}
                                    onChange={this.handleChange("institution")}
                                    fullWidth={true}
                                    error={this.state.institutionError !== ''}
                                    helperText={this.state.institutionError}
                                    InputLabelProps={{ style: styles.checkboxLabel }}
                                />

                                <TextField
                                    label={translations.ACCOUNT_NUMBER}
                                    className={classes.textField}
                                    value={this.state.accountNumber}
                                    onChange={this.handleChange("accountNumber")}
                                    fullWidth={true}
                                    error={this.state.accountNumberError !== ''}
                                    helperText={this.state.accountNumberError}
                                    InputLabelProps={{ style: styles.checkboxLabel }}
                                />
                                <FormControl className={classes.formControl} error={this.state.accountTypeError !== ''}>
                                <InputLabel htmlFor="accountType" shrink={true}>{translations.ACCOUNT_TYPE}</InputLabel>
                                    <Select
                                        value={this.state.accountType}
                                        onChange={this.handleSelectChange('accountType')}
                                        required={true}
                                        native={true}
                                        inputProps={{
                                            id: 'accountType',
                                            name: 'accountType',
                                        }}
                                    >
                                        <option value="">{"Seleccione una opcion"}</option>
                                        <option value="saving">{translations.SAVING}</option>
                                        <option value="checking">{translations.CHECKING}</option>
                                    </Select>
                                </FormControl>

                                <FormControl className={classes.formControl} error={this.state.currencyTypeError !== ''}>
                                <InputLabel htmlFor="currencyType" shrink={true}>{translations.CURRENCY}</InputLabel>
                                    <Select
                                        value={this.state.currencyType}
                                        onChange={this.handleSelectChange('currencyType')}
                                        required={true}
                                        native={true}
                                        inputProps={{
                                            id: 'currencyType',
                                            name: 'currencyType',
                                        }}
                                    >
                                        <option value="DOP">{translations.DOP}</option>
                                        <option value="USD">{translations.USD}</option>
                                    </Select>
                                </FormControl>

                                        
                    </DialogContent>
                    <DialogActions>
                        <Buttom onClick={this.handleAdd} className={classes.button}>
                            {translations.SAVE}
                        </Buttom>
                    </DialogActions>
                </Dialog>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{translations.INSTITUTION_TYPE}</TableCell>
                                <TableCell>{translations.INSTITUTION}</TableCell>
                                <TableCell align={'right'}>
                                {bankAccounts.length < this.props.tableLimit ?
                                    <IconButton className={classes.button} onClick={this.openDialogHandler}>
                                        <AddIcon />
                                    </IconButton>
                                : "Acciones"}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bankAccounts.map(this.map)}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(SelectInput); 
