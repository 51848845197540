import { SEARCH_REQUEST, SEARCH_SUCCESS, CLEAR_SEARCH } from '../actions';

const initialState = {
    // searchResults: [],
    searchResults: {},
    currentQuery: ""
}

export default function searchReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }

    switch(action.type){
        case SEARCH_REQUEST:
            state.currentQuery = action.payload;
            break;

        case SEARCH_SUCCESS:
            if(state.currentQuery === action.payload.query){
                state.searchResults[action.optiontype] = action.payload.results;
            }
            break;
        case CLEAR_SEARCH:
            state.searchResults = action.payload;
            break;
        default:
            break;
    }
    return {...state};
}