import React, { useState, useEffect } from 'react';
import { 
    Dialog, DialogContent, Grid, List, ListItem, ListItemText, 
    ListItemSecondaryAction, ListSubheader, Button, Typography, 
    ListItemAvatar, IconButton, Divider, Avatar, Paper, DialogActions
} from '@material-ui/core';
import { 
    ConfirmDialog, DialogHeader, DialogFooter, 
} from './../../../components/SharedComponents/Dialogs';
import { 
    TextFieldComponent, AutocompleteField, DateField, RadioGroupComponent,
    SearchComponent, NumericTextField
} from 'src/components/SharedComponents/Fields';
import NumberFormat from 'src/components/SharedComponents/NumberFormat';
import { 
    PriorityHigh, LowPriority, Delete, Send, Visibility, CheckCircleOutline, RemoveCircleOutline,
} from '@material-ui/icons';
import WorkIcon from '@material-ui/icons/Work';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FileSelection } from '../../../components/SharedComponents/FileSelection';
import AcquaintanceMembershipForm from './../../AcquaintanceshipPersonContract/Form';
import PaymentCalculator from '../../../components/Calculators/PaymentTable';
import QuotationForm from '../../../components/Calculators/AmortizationTable';
import { 
    createTicket, fetchPotentialMemberValidation, fetchFileAttachments, 
    createFileAttachment, deleteFileAttachment, createFileAttachmentTicket, 
    fetchTime, getFileAttachment, postRohiLoanRecalculation, fetchQuotation, fetchTicket, rohiSavingAccountsInfo,
} from '../../../utils/axios';
import { cancelTicketDocuments, ticketHistoryGeneral, FormWrapper, handlePrevStep } from '../TicketGeneralMethods';
import { cancelOptions } from './../../../utils/';
import { errorHandling, dateFormat } from '../../../components/SharedComponents/CustomHooks';
import {useSelector} from 'react-redux';
import WorkProfile from 'src/containers/WorkProfile';
import { green } from '@material-ui/core/colors';
import FinancialProfile from 'src/containers/FinancialProfile';
import AvailabilityCalculator from 'src/components/Calculators/AvailabilityCalculator';
import ClientButton from "../../Claim/Utils/index";
import ClientDocuments from 'src/components/ClientDocuments';
import { disbursementRequest, disbursementSteps, prevStepsDisbursement } from '../TicketGeneralMethods/consts';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, 
    TimelineItem, TimelineOppositeContent, TimelineSeparator} from '@material-ui/lab';

export function ConfirmDialogComponent(props) {
    const data = props.data.data;
    let dialogConfirmContent = '';
    // SET OTHER CASES

    switch(props.data.msg) {
        case 'file-upload':
            dialogConfirmContent='¿Desea subir el archivo seleccionado?';
            break;
        case 'proceed-file-delete':
            dialogConfirmContent=`El archivo será eliminado, ¿desea proceder?`;
            break;
        case 'proceed-comment-delete':
            dialogConfirmContent=`El comentario seleccionado será eliminado, ¿desea proceder?`;
            break;
        case 'proceed-complete-ticket':
            dialogConfirmContent=`¿Desea marcar el ticket como completado?`;
            break;
        case 'proceed-cancel-ticket':
            dialogConfirmContent=`¿Desea marcar el ticket como cancelado/rechazado?`;
            break;
        case 'proceed-complete-outlays':
            dialogConfirmContent=`¿Desea completar las fases con el desembolso selecionado?`;
            break;
        case 'proceed-complete-expirations':
            dialogConfirmContent=`¿Desea ejecutar el proceso de recreación de vencimientos?`;
            break;
        case 'confirm-delete-payment':
            dialogConfirmContent=`¿Desea eliminar la negociación de pago seleccinada?`;
            break;
        case 'proceed-complete-negotiation':
            dialogConfirmContent=`
                Una vez completado el paso no podrá modificar la vista 
                previa del recalculo ni las negociaciones.
            `;
            break;
        default:
            dialogConfirmContent='...';
    }
    return(
        <ConfirmDialog 
            open={props.open}
            handleToggle={props.handleToggle}
            confirmCallback={data.callback}
            data={dialogConfirmContent}
        />
    )
}

const clientAdmissionSteps = [
    {
        approvalObj: {
            status: '',
            quotation: '',
            actNumber: '',
            actDate: '',
            approvalInstance: '',
            outlayRelation: '',
            relationOutlayId: '',
            debtorCreatedRohi: false,
        }
    },
    {
        fileRequirements: [
            {
                text: 'Copia de identificación',
                value: [],
                required: true,
                step:'requirements_gathering',
            },
            {
                text: 'Certificación de dirección',
                value: [],
                required: false,
                step:'requirements_gathering',
            },
            {
                text: 'Justificación de ingresos',
                value: [],
                required: false,
                step:'requirements_gathering',
            },
        ]
    },
    {
        step: 'data_adquisition',
        requirements: [
            {
                type: 'form',
                name: 'acquaintanceFormId',
                text: 'Formulario de conocimiento de persona',
                value: '',
                required: true,
            }
        ],
        status: '',
        order: 0,
    },
    {
        step: 'requirements_gathering',
        requirements: [
            {
                type: 'form',
                name: 'memebershipApplFormId',
                text: 'Solicitud de admisión de socio',
                value: '',
                required: true,
            },
            {
                type: 'form',
                name: 'creditReviewAuthId',
                text: 'Autorización de revisión crediticia',
                value: '',
                required: true,
            },
        ],
        status: '',
        order: 1,
    },
    {
        step: 'membership_analysis',
        requirements: [
            {
                type: 'text',
                name: 'comment',
                text: 'Comentario recomendación',
                value: '',
                required: true,
            },
            {
                type: 'text',
                name: 'approveState',
                text: 'Aprobación',
                value: '',
                required: true,
            },
        ],
        status: '',
        order: 2,
    },
    {
        step: 'client_enrol',
        requirements: [
            {
                type: 'form',
                name: 'inputCertificate',
                text: 'Certificado de aportación',
                value: '',
                required: true,
            },
            {
                type: 'form',
                name: 'savingAccount',
                text: 'Certificado cuenta de ahorro a la vista',
                value: '',
                required: true,
            },
            {
                type: 'form',
                name: 'savingAccountExtra',
                text: 'Tabla solicitud de cuentas',
                value: [],
                required: false,
            },
            {
                type: 'form',
                name: 'accountManagementContract',
                text: 'Contrato de manejo de cuenta',
                value: '',
                required: true,
            },
            {
                type: 'text',
                name: 'paymentMethod',
                text: 'Forma de pago',
                value: [],
                required: true,
            },
        ],
        status: '',
        order: 3,
    },
    {
        step: 'payment_validation',
        requirements: [
            {
                type: 'bool',
                name: 'paymentVerified',
                text: 'Pago verificado',
                value: false,
                required: true,
            },
            {
                type: 'text',
                name: 'referenceNumber',
                text: 'Tabla métodos de pago',
                value: [],
                required: true,
            },
        ],
        status: '',
        order: 4,
    },
    {
        step: 'documents_issuance',
        status: '',
        order: 5,
    },
]

const paymentSteps = [
    {
        approvalObj: {
            status: '',
            quotation: '',
            recalcStatus: '',
            negotiationTotal: '',
        }
    },
    {   
        fileRequirements: [],
    },
    {
        step: 'negotiation_process',
        requirements: [
            {
                type: 'text',
                name: 'paymentType',
                text: 'Tipo de pago',
                value: '',
                required: true,
            },
            {
                type: 'object',
                name: 'paymentNegotiation',
                text: 'Negociación de pago',
                value: null,
                required: true,
            },
            {
                type: 'text',
                name: 'paymentDate',
                text: 'Fecha de pago',
                value: null,
                required: true,
            },
            {
                type: 'object',
                name: 'recalcPreview',
                text: 'Vista previa recalculo',
                value: {},
                required: true,
            },
            {
                type: 'object',
                name: 'creditMethodTables',
                text: 'Tabla métodos de crédito',
                value: null,
                required: true,
            },
            {
                type: 'text',
                name: 'paymentAssistanceRequired',
                text: 'Asistencia requerida para pago',
                value: '',
                required: true,
            },
        ],
        status: '',
        order: 0,
    },
    {
        step: 'payment_reconciliation',
        requirements: [
            {
                type: 'object',
                name: 'creditMethodTables',
                text: 'Tabla métodos de crédito',
                value: null,
                required: true,
            },
            {
                type: 'text',
                name: 'amount',
                text: 'Verificación de fondos',
                value: '',
                required: true,
            },

        ],
        status: '',
        order: 1,
    },
    {
        step: 'last_confirm',
        requirements: [
            {
                type: 'form',
                name: 'promissoryNote',
                text: 'Formulario de pagaré',
                value: '',
                required: false,
            },
        ],
        status: '',
        order: 2,
    },
]

export function TicketCreationDialog(props) {
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [typedPerson, setTypedPerson] = useState('');
    const [isInBlacklist, setIsInBlacklist] = useState(true);
    const [newTicketData, setNewTicketData] = useState({});
    const [errorFields, setErrFields] = useState({});
    const [optionType, setOptionType] = useState('');
    const results = useSelector(state => state.autocompleteDebunce.searchResults[optionType]);

    const ticketOptions = [
        {name: 'Desembolso', value:'outlay'},
        {name: 'Solicitud de admisión', value:'new partner'},
        {name: 'Pagos', value:'payment'},
    ];

    const setListName = (list) => {
        let name = '';
        switch(list) {
            case 'international_national_blacklist':
                name = 'LISTA NACIONAL/INTERNACIONAL';
                break;
            case 'peplist':
                name = 'LISTA PEP';
                break;
            case 'ofaca_onu':
                name = 'LISTA OFACA/ONU';
                break;
            case 'blacklist':
                name = 'BLACKLIST';
                break;
            default:
                name = list;
                break;
        }
        return name;
    }

    const handleCheckBlacklist = async () => {
        const selected = typedPerson || selectedPerson;
        if(checkErrors(selected)) {
            return
        }
        const lists = [];
        let listName = '';
        if(newTicketData.type.value === 'new partner' && typedPerson && results.length > 0) {
            // CHECK FOR COTNENT TYPED INSIDE ALL BLACK LISTED PEOPLE
            setIsInBlacklist(true);
            props.handleToggleSimpleDialog(
                'simple-dialog', 
                `La persona digitada podría encontrarse en el blacklist, favor digite correctamente el nombre/identificación o seleccione una opción.`
            );
            return;

        }else if(selectedPerson) {
            // CHECK FOR SELECTED PERSON (BLACKLIST PERSON) THEN PUSH THE LIST
            if(selectedPerson.list) {
                listName = setListName(selectedPerson.list);
                lists.push(listName);
            }else {
                // CHECK FOR SELECTED PERSON (PARTICIPANT PERSON) THEN PUSH THE LIST
                props.setLoading(true);
                const blackLists = 
                    await fetchPotentialMemberValidation(props.token, {participant: selectedPerson.value})
                        .then(response => response.data)
                        .catch(errors => {
                            errorHandling(errors, props.handleToggleSimpleDialog);
                        });
                props.setLoading(false);
                Object.keys(blackLists).forEach(key => {
                    if (blackLists[key].length > 0) {
                        if (blackLists.blacklist.length > 0) {
                            if (!["pendingDocumentation", "paymentConfigurationPending"].includes(blackLists.blacklist[0].reason)) {
                                listName = setListName(key);
                                lists.push(listName);
                            }
                        } else {
                            listName = setListName(key);
                            lists.push(listName);
                        }
                    }
            });
            }
        }else if(newTicketData.type.value !== 'new partner'){
            setIsInBlacklist(true);
            props.handleToggleSimpleDialog(
                'simple-dialog', 
                `Debe seleccionar una opción para proceder.`
            );
            return;
        }
        if(lists.length > 0) {
            // SHOW MESSAGE IF IS BLACK LISTED
            setIsInBlacklist(true);
            props.handleToggleSimpleDialog(
                'simple-dialog', 
                `La persona seleccionada se encuentra en las siguientes listas: ${lists.join(', ')}, 
                por lo que no se puede proceder`
            );
        }else {
            // LET THE TICKET BE CREATED
            setIsInBlacklist(false);
        }
    }

    const checkErrors = (value) => {
        if(!value) {
            setErrFields({...errorFields, document: true});
            return true;
        }else {
            setErrFields({...errorFields, document: false});
            return false;
        }
    }

    const handlePersonChange = (value) => {
        setTypedPerson('');
        setIsInBlacklist(true);
        setSelectedPerson(value);
    }

    const handleTypedPersonChange = (value) => {
        setIsInBlacklist(true);
        setTypedPerson(value);
    }

    const handleNewTicketData = (field, event, value) => {
        if(field === 'type') {
            setSelectedPerson(null);
            setTypedPerson('');
            setIsInBlacklist(true);
        }
        const dateVal = field === 'date' && event;
        let data = dateVal ? dateVal : value ? value : event.target && event.target.value;
        setNewTicketData({...newTicketData, [field]: data});
    }

    const ticketPriority = () => {
        return [
            {text: 'BAJA', value: 'low', color:'#41abe8', element: 
                <div>
                    <LowPriority style={{color: '#41abe8', margin:'0 1rem 0 -.7rem'}}/>
                </div>
            },
            {text: 'MEDIA', value: 'mid', color:'#f5c000', element: 
                <div>
                    <PriorityHigh style={{color: '#f5c000', margin:'0 1rem 0 -1rem', fontSize:20}}/>
                </div>
            },
            {text: 'ALTA', value: 'high', color:'#fa5448', element: 
                <div>
                    <PriorityHigh style={{color: '#fa5448', margin:'0 0 0 -1rem', fontSize:20}}/>
                    <PriorityHigh style={{color: '#fa5448', marginLeft:'-0.8rem', fontSize:20}}/>
                </div>
            },
        ]
    }

    const handleCreateTicket = () => {
        let ticketType;
        switch(newTicketData.type.value) {
            case 'new partner':
                ticketType = clientAdmissionSteps;
                break;
            case 'outlay':
                ticketType = disbursementRequest;
                break;
            case 'payment':
                ticketType = paymentSteps;
                break;
            default:
                ticketType = clientAdmissionSteps;
        }
        const ticket = {
            createdBy: props.loggedUser,
            assignee: props.loggedUser,
            lastModifiedBy: props.loggedUser,
            reviewer: props.loggedUser,
            customer: selectedPerson ? selectedPerson.value : '',
            title: newTicketData.title,
            requestType: newTicketData.type.value,
            description: newTicketData.description,
            parentTicket: (newTicketData.parent && newTicketData.parent.referenceCode) || '',
            dueTime: newTicketData.date || new Date(),
            priority: newTicketData.priority,
            status: 'new_request',
            steps: ticketType || null,
        }
        props.setLoading(true);
        const endpoint = createTicket(props.token, ticket);
        endpoint.then(response => {
            props.setCurrentTab(0);
            props.setLoading(false);
            props.handleToggleDialog();
            props.handleDetailToggle(response.data);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    useEffect(() => {
        const type = newTicketData.type ? 
        newTicketData.type.value === 'new partner' ? 'blacklist' : 'participants' : ''
        setOptionType(type);
    }, [newTicketData.type]);

    return(

        <Dialog
            fullWidth
            maxWidth="sm"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Nuevo Ticket"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />

            <DialogContent style={{margin:'2% 0'}}>

                <AutocompleteField
                    xs={12}
                    containerClass="field-container pd-y-2"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Tipo de ticket"
                    fieldID="new-ticket-type"
                    fieldInnerLabel="Seleccione..."
                    fieldVariant="outlined"
                    value={newTicketData.type || null}
                    handleChange={handleNewTicketData.bind(this, 'type')}
                    options={ticketOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.value === value.value}
                />

                <div style={{display:'flex', flexDirection:'row'}}>
                    <SearchComponent 
                        xs={9}
                        containerClass="field-container"
                        fieldLabel="Personas"
                        fieldID="new-ticket-document-check"
                        fieldInnerLabel="Seleccione una persona"
                        handleChange={handlePersonChange}
                        onInputChange={handleTypedPersonChange}
                        optionType={
                            newTicketData.type ? 
                                newTicketData.type.value === 'new partner' ? 'blacklist' : 'participants' : ''
                        }
                        clearOnBlur={
                            newTicketData.type ? 
                                newTicketData.type.value !== 'new partner' && true : false
                        }
                        limitTags={1}
                        error={errorFields.document}
                        helperText={errorFields.document ? "Debe introducir un valor." : null}
                        disabled={!newTicketData.type}
                    />
                    <Button
                        variant="contained"
                        className={
                            !newTicketData.type ?
                            "action-method-btn disabled" : "action-method-btn"
                        }
                        disableElevation
                        disabled={!newTicketData.type}
                        style={{height:30, marginLeft:'.5rem', marginTop:41}}
                        onClick={handleCheckBlacklist}
                    >
                        verificar
                    </Button>
                    
                </div>

                <div style={{position:'relative'}}>
                    {
                        (isInBlacklist) &&
                            <div style={{width:'100%', height:'100%', position:'absolute', backgroundColor:'rgb(255, 255, 255, 0.4)', zIndex:9999}}></div>
                    }
                    <TextFieldComponent
                        xs={12}
                        containerClass="field-container pd-y-2"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Título"
                        fieldID="new-ticket-title"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        placeholder="Título"
                        margin="dense"
                        fullWidth
                        value={newTicketData.title || ''}
                        onChange={handleNewTicketData.bind(this, 'title')}
                    />
                    <TextFieldComponent
                        xs={12}
                        containerClass="field-container pd-y-2"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Descripción"
                        fieldID="new-ticket-description"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        placeholder="Descripción..."
                        margin='dense'
                        fullWidth
                        value={newTicketData.description || ''}
                        onChange={handleNewTicketData.bind(this, 'description')}
                        multiline={true}
                        rows={6}
                    />
                    <AutocompleteField
                        xs={12}
                        containerClass="field-container pd-y-2"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Ticket padre"
                        fieldID="new-ticket-parent"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        fieldInnerLabel="Seleccione..."
                        margin="dense"
                        fullWidth
                        value={newTicketData.parent || null}
                        handleChange={handleNewTicketData.bind(this, 'parent')}
                        options={props.tickets || []}
                        getOptionLabel={(option) => option.fullName}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                    />
                    <DateField 
                        xs={12}
                        containerClass="field-container pd-y-2"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Fecha"
                        dateFieldID="new-ticket-date"
                        fullWidth={true}
                        dateFieldClass="date-field"
                        inputVariant="outlined"
                        dateFieldLabel="Fecha vencimiento"
                        format="dd/MM/yyyy"
                        value={newTicketData.date}
                        handleChange={handleNewTicketData.bind(this, 'date')}
                    />
                    <RadioGroupComponent 
                        xs={12}
                        containerClass="field-container pd-y-2"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Prioridades"
                        fieldID="radio-field-priorities"
                        fieldClass="fields"
                        row={true}
                        options={ticketPriority()}
                        value={newTicketData.priority || 'low'}
                        onChange={handleNewTicketData.bind(this, 'priority')}
                    />
                    <div style={{display: 'flex', width:'100%', justifyContent:'flex-end'}}>
                        <Button
                            variant="contained"
                            className={isInBlacklist ? "action-method-btn disabled" : "action-method-btn"}
                            disabled={isInBlacklist}
                            disableElevation
                            onClick={handleCreateTicket}
                        >
                            crear
                        </Button>
                    </div>
                </div>
            </DialogContent>

            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
        </Dialog>
    )
}

export function DataAdquisitionDialog(props) {
    return(
        // WHEN CREATING: ON CLOSE, FETCH THE CLIENT DATA.
        <Dialog
            fullWidth
            maxWidth="lg"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Adquisición de datos"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />

            <DialogContent style={{margin:'2% 0'}}>
                <AcquaintanceMembershipForm  />
            </DialogContent>

            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
        </Dialog>
    )
}

export function FilesManagement(props) {

    const requirements = [];
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [ticketSteps, setTicketSteps] = useState([]);

    const getTicketData = (ticketData) => {
        const data = ticketData || props.ticket;
        const reqs = 
            data.steps
            .find(item => item.fileRequirements).fileRequirements
            .filter(item => props.data.allReqs ? item : item.step === props.data.step)
            .find(item => item.text === props.data.requirementName);
        setFiles(reqs.value);
        setTicketSteps(data.steps);
    }

    const handleFileDelete = (fileId) => {
        const modifyFiles = [...ticketSteps];
        const fileIndex = files.findIndex(item => item.uniqueId === fileId);

        modifyFiles.find(item => item.fileRequirements).fileRequirements
            .filter(item => item.step === props.data.step)
            .find(item => item.text === props.data.requirementName)
            .value.splice(fileIndex, 1);
        props.data.handleFileRequirements(modifyFiles, getTicketData);
        const endpoint = deleteFileAttachment(props.token, fileId);
        endpoint.then(() => {
            const newFiles = 
                modifyFiles.find(item => item.fileRequirements).fileRequirements
                .filter(item => item.step === props.data.step)
                .find(item => item.text === props.data.requirementName);
            setFiles(newFiles.value);
            props.handleToggleSimpleDialog();
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    const handleViewFile = (file) => {
        if (file){
          const endpoint = getFileAttachment(props.token, file);
          endpoint.then((res) => {
              const dbuff = new Uint8Array(res.data.file).buffer
              const data = new Blob([dbuff], {type: res.data.type});
              const fileURL = URL.createObjectURL(data);
              const NewWindow = window.open();
              NewWindow.location.href = fileURL;
          }).catch(errors => {
            console.log(errors)
          });
        }
      }

    const uploadFile = () => {
        props.setLoading(true);
        let formData = new FormData();
        const name = props.data.step+"-"+props.data.requirementName;
        formData.append("file", file);
        formData.append("name", name);
        const endpoint = createFileAttachment(props.token, formData);
        endpoint.then((response) => {
            let ticketStep = props.data.ticketSteps ? props.data.ticketSteps : [...ticketSteps];
            ticketStep.find(item => item.fileRequirements).fileRequirements.forEach(item => {
                if(item.text === props.data.requirementName) {
                    item.value.push(response.data);
                }
            });
            props.data.handleFileRequirements(ticketStep, getTicketData);
            setFile(null);
            props.setLoading(false);
            props.handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setFile(null);
            props.setLoading(false);
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    useEffect(() => {
        getTicketData();
    }, []);

    return(
        <div>
            <FileSelection
                justifyContent="flex-end"
                xs={8}
                file={file}
                setFile={setFile}
                fileExtensions="*"
                inputId="input-file-selection-upload-step"
                uploadFile={props.handleToggleSimpleDialog.bind(this, 'confirm-dialog', 'file-upload', {callback: uploadFile})}
                disabled={Boolean(props.ticketCompleted)}
                
            />
            <Paper style={{marginTop:'1rem'}}>
                <List
                    subheader={
                        <ListSubheader component="div" id="requirements-list">
                            Archivos
                        </ListSubheader>
                    }
                > 
                    {
                        files.length > 0 && files.map((item, index) => {
                            return(
                                <div key={index}>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <p style={{color: '#2a9c32'}}>
                                                    {item.file ? item.file.split("/").pop() : ""}
                                                </p >
                                            }
                                        />
                                        
                                       
                                           <ListItemSecondaryAction >
                                                <IconButton aria-label="visibility" onClick={handleViewFile.bind(this, item.uniqueId)}>
                                                    <Visibility />
                                                </IconButton>
                                                <IconButton 
                                                    aria-label="delete" 
                                                    onClick={
                                                        props.handleToggleSimpleDialog.bind(this, 'confirm-dialog', 
                                                        'proceed-file-delete', {callback: handleFileDelete.bind(this, item.uniqueId)})}>
                                                    <Delete />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                    </ListItem>
                                    {
                                        (index+1) !== requirements.length &&
                                        <Divider />
                                    }
                                </div>
                            )
                        })
                    }
                </List>
            </Paper>
        </div>
    )
}

export function FileManagementDialog(props) {
    return(
        <Dialog
            fullWidth
            maxWidth="sm"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Archivos"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />

            <DialogContent style={{margin:'2% 0'}}>
                <FilesManagement {...props} />
            </DialogContent>

            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
        </Dialog>
    )
}

export function FilesManagementGeneral(props) {

    const requirements = [];
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);

    const getFiles = () => {
        const endpoint = fetchFileAttachments({token: props.token, name: props.ticket.uniqueId});
        endpoint.then(response => {
            setFiles(response.data);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }
    
    const handleViewFile = (file) => {
        if (file){
          const endpoint = getFileAttachment(props.token, file);
          endpoint.then((res) => {
              const dbuff = new Uint8Array(res.data.file).buffer
              const data = new Blob([dbuff], {type: res.data.type});
              const fileURL = URL.createObjectURL(data);
              const NewWindow = window.open();
              NewWindow.location.href = fileURL;
          }).catch(errors => {
            console.log(errors)
          });
        }
      }

    const handleFileDelete = (fileId) => {
        const endpoint = deleteFileAttachment(props.token, fileId);
        endpoint.then(() => {
            getFiles();
            props.handleToggleSimpleDialog();
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    const uploadFile = () => {
        props.setLoading(true);
        let formData = new FormData();
        formData.append("file", file);
        const endpoint = createFileAttachmentTicket(props.token, props.ticket.uniqueId, formData);
        endpoint.then(() => {
            getFiles();
            setFile(null);
            props.setLoading(false);
            props.handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setFile(null);
            props.setLoading(false);
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    useEffect(() => {
        getFiles();
    }, []);

    return(
        <div>
            <FileSelection
                justifyContent="flex-start"
                xs={12}
                file={file}
                setFile={setFile}
                fileExtensions="*"
                inputId="input-file-selection-upload-general"
                uploadFile={props.handleToggleSimpleDialog.bind(this, 'confirm-dialog', 'file-upload', {callback: uploadFile})}
                disabled={Boolean(props.ticketCompleted)}
            />
            <Paper style={{marginTop:'1rem'}}>
                <Typography
                    variant="overline"
                    style={{color:'#424242', fontSize:'.9rem' , padding:16}}
                >ARCHIVOS</Typography>
                <List> 
                    {
                        files.length > 0 ? files.map((item, index) => {
                            const fileText = item.file && item.file.split("/").pop();
                            const fileTextTrim = (fileText && fileText.length > 25) ? fileText.substring(25, 0).concat('...') : fileText;
                            return(
                                <div key={index}>
                                    <ListItem style={{display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <ListItemText
                                            primary={
                                                <p title={fileText} style={{color: '#2a9c32'}}>
                                                    {
                                                        fileTextTrim || "" 
                                                    }
                                                </p>
                                            }
                                        />
                                        <Grid container justifyContent='space-evenly' style={{width:100}}>
                                            <IconButton aria-label="visibility" onClick={handleViewFile.bind(this, item.uniqueId)}>
                                                <Visibility />
                                            </IconButton>
                                            <IconButton 
                                                aria-label="delete" 
                                                onClick={
                                                    props.handleToggleSimpleDialog.bind(this, 'confirm-dialog', 
                                                    'proceed-file-delete', {callback: handleFileDelete.bind(this, item.uniqueId)})}>
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </ListItem>
                                    {
                                        (index+1) !== requirements.length &&
                                        <Divider />
                                    }
                                </div>
                            )
                        })
                        :
                            <Typography
                                variant={"subtitle1"}
                                className={"section-header"}
                                style={{
                                    fontWeight: 'bold', color:'#7d7d7d', fontStyle:'italic', 
                                    marginBottom: '2rem', display:'flex', alignItems:'center',
                                    marginLeft:'1rem',
                                }}
                            >
                                No hay ningún ningún archivo adjunto...
                            </Typography>
                    }
                </List>
            </Paper>
        </div>
    )
}

export function ActivityInfoData(props) {

    const date = (time) => {
        const newTime = time.replace(/(\d{2})-(\d{2})-(\d{2})T(.*)/, '20$3-$1-$2T$4')
        const options = { year: 'numeric', month: 'short', day: '2-digit', hours: 'numeric', minutes: 'numeric' };
        return newTime ? new Date(newTime).toLocaleTimeString('es-DO', options) : '---'
    }
    const KeyValueList = (jsonString) => {
        let keyValuePairs = [];
        const startIndex = jsonString.indexOf('{');
        const endIndex = jsonString.lastIndexOf('}');
      
        try {
            const jsonObjectString = jsonString.substring(startIndex, endIndex + 1);
            const jsonObject = JSON.parse(jsonObjectString);
            keyValuePairs = Object.entries(jsonObject)
                .filter(([key, value]) => value !== '')
                .map(([key, value]) => `${key} ${value}`);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
        const previousString = jsonString.substring(0, startIndex);
        const keyValueString = keyValuePairs.join(', ');
      
        return (
          <div>{`${previousString} ${keyValueString}`}</div>
        );
      }
      const disbursementStepsTranslate = (string) => {
        let newString = string;
      
        disbursementSteps.forEach((step) => {
          const regex = new RegExp(step.value, "i"); // Ignorar mayúsculas y minúsculas
      
          if (regex.test(newString)) {
            newString = newString.replace(step.value.toUpperCase(), step.label);
          }
        });
      
        return newString;
      };
      

    return (
        <>
       <Dialog
            fullWidth
            maxWidth='md'
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Registro de Actividad"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />
            <DialogContent style={{margin:'2% 0'}}>
                <Timeline>
                    {props.data && props.data.map((item, index) => {
                        return(
                            <TimelineItem  key={`activity-info-view-${index}`}>
                                <TimelineOppositeContent color="textSecondary">
                                <Typography variant="body1" color="textSecondary">
                                    {date(item.time)}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {item.user}  
                                </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    {index !== props.data.length - 1 && <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent>
                                    {item.description.includes("objeto de aprobación")
                                        ? KeyValueList(item.description)
                                        : disbursementStepsTranslate(item.description)
                                    }
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })}
                </Timeline>
            </DialogContent>

            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
            
        </Dialog>
        </>
    )
};

export function CancelTicket(props) {
    const init = {
        reason: null,
        other: '',
    }
    const [cancelReason, setCancelReason] = useState(init);
    // const [otherCancelReason, setOtherCancelReason] = useState('');
    
    const handleChangeReason = (type, event, value) => {
        const val = value ? value : event.target ? event.target.value : '';
        setCancelReason({...init, [type]: val});
    }

    const handleCancelTicket = async () => {
        
        if(!cancelReason.reason && !cancelReason.other.trim()) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe seleccionar una razón.`);
            return;
        }
        const reason = cancelReason.reason ? cancelReason.reason.label : cancelReason.other;

        const ticketCancelReason = reason ? reason : 'NO ESPECIFICADO';
        if(props.ticket.requestType === 'outlay') {
            const documentCancelReason = `--TICKET. CANCELADO POR: ${ticketCancelReason}--`;
            await cancelTicketDocuments(documentCancelReason, props);
        }
        const time = await fetchTime().then(response => response.data);
        const removeT = time.substr(0, time.indexOf('T'));
        const formmattedDate = dateFormat(new Date(removeT));
        const dataToModify = {
            status: 'cancelled',
            cancellationReason: ticketCancelReason,
            cancelledDate: formmattedDate,
            cancelledBy: props.loggedUser,
        }
        // const approvalObj = props.ticket.steps.find(item => item.approvalObj).approvalObj
        // approvalObj.status = 'denied';
        props.ticket.steps.find(item => item.approvalObj).approvalObj.status = 'denied';
        const historyDescription = `Se ha modificado el estado del ticket a: Cancelado`;
        ticketHistoryGeneral(props, historyDescription).then( async (response) => {
            const history = response;
            await props.modifyTicket(dataToModify, history);
            props.handleToggleSimpleDialog('success-dialog');
        });
    }

    return(
        <Dialog
            fullWidth
            maxWidth="sm"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="ANULACIÓN DE TICKET"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />

            <DialogContent style={{margin:'2% 0'}}>
                <Typography
                    variant={"subtitle1"}
                    className={"section-header"}
                    style={{fontWeight: 'bold', color:'#6b6b6b', marginLeft:5}}
                >
                    ESPECIFIQUE UNA RAZÓN
                </Typography>
                <Divider style={{marginBottom:'1rem'}}/>

                <AutocompleteField
                    xs={12}
                    containerClass="field-container pd-y-2"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Razón"
                    fieldID="new-ticket-type"
                    fieldInnerLabel="Seleccione..."
                    fieldVariant="outlined"
                    value={cancelReason.reason || null}
                    handleChange={handleChangeReason.bind(this, 'reason')}
                    options={cancelOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    multiple={false}
                    disabled={Boolean(cancelReason.other) || props.ticketCompleted}
                />

                <TextFieldComponent
                    xs={12}
                    containerClass="field-container pd-y-2"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Otra razón"
                    fieldID="cancel-ticket-other-reason"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    placeholder="Otra razón..."
                    margin="dense"
                    fullWidth
                    value={cancelReason.other || ''}
                    onChange={handleChangeReason.bind(this, 'other')}
                    disabled={Boolean(cancelReason.reason) || props.ticketCompleted}
                />
                <Grid item container xs={12} justifyContent="flex-end" style={{margin:'1rem 0'}}>
                    <Button
                        variant="contained"
                        className={
                            props.ticketCompleted ?
                            "action-method-btn disabled" : "action-method-btn cancel"
                        }
                        disableElevation
                        disabled={Boolean(props.ticketCompleted)}
                        style={{height:30, marginLeft:'.5rem', marginTop:41}}
                        onClick={handleCancelTicket}
                    >
                        PROCEDER
                    </Button>
                </Grid>
            </DialogContent>

            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
        </Dialog>
    )
}

export function PaymentCalculatorDialog(props) {
    const participant = props.data && props.data.participant;
    const clientData = participant && participant.fullName;
    let paymentCalcData = null;
    let filters = {
        rohiId: participant && participant.rohiId,
        uniqueId: participant && participant.uniqueId,
        paymentType: props.data.paymentType || '',
    };
    let actionType = 'new';
    if(props.data.hasOwnProperty('row')) {
        actionType = 'detail'
        paymentCalcData = props.data.row;
    }
    return(
        <Dialog
            fullWidth
            maxWidth="xl"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Negociación de pagos"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />

            <DialogContent style={{margin:'2% 0'}}>
                <PaymentCalculator
                    data={paymentCalcData ? paymentCalcData.expirationDetails : []} // detail
                    clientData={clientData} // detail
                    filters={filters} // detail
                    setCalculatorInfo={(props.data && props.data.callback) && props.data.callback}
                    readOnly={actionType === 'detail'}
                    fromTicket={'paymentTicket'}
                />
            </DialogContent>

            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
        </Dialog>
    )
}

export function RecalcPreview(props) {

    const [expand, setExpand] = useState(false);
    const [quotationData, setQuotationData] = useState([]);

    const handleClickExpand = (form, event, isExpanded) => {
        const expandAction = form === expand ? false : form
        setExpand(expandAction);
    }

    const handleQuotationData = (info, data) => {
        const dataFound = quotationData.find(item => item.quotation === info.quotation);
        if(!dataFound) {
            const newData = {...data, ...info}
            setQuotationData(quotationData.concat(newData));
        }
    }

    const handleRecreateExpiration = () => {
        const completed = quotationData.length === props.data.quotations.length;
        if(!completed) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe seleccionar PCP en todas las cotizaciones`);
            return;
        }
        props.setLoading(true);
        const endpoint = postRohiLoanRecalculation({...quotationData, uniqueId: props.ticket.uniqueId, token: props.token});
        endpoint.then(response => {
            const data = response.data;
            props.handleToggleSimpleDialog('success-dialog');
            props.setLoading(false);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
            props.setLoading(false);
        });
    }

    return(
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Vista previa recalculo"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />
            <DialogContent style={{margin:'2% 0'}}>
                {
                    props.data.quotations.length && props.data.quotations.map((item, index) => {
                        return(
                            <div key={`quotation-recalculate-view-${index}`}>
                                <FormWrapper
                                    title={item.reference_code}
                                    name={`quotation-recalculate-view-${index}`}
                                    contentType={'Cotización'}
                                    headerAction={
                                        quotationData.find(qd => qd.quotation === item.quotation) ?
                                            <CheckCircleOutline style={{fontSize:20, color: '#44c94d'}} />
                                        :
                                            <RemoveCircleOutline style={{fontSize:20, color: '#edd155'}} />
                                    }
                                    form=
                                    {
                                        <QuotationForm
                                            quotationId={item.quotation}
                                            paymentTicket={true}
                                            handleQuotationData={handleQuotationData.bind(this, item)}
                                            handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                                        />
                                    }
                                    handleExpand={handleClickExpand.bind(this, item.quotation)}
                                    expand={expand === item.quotation}
                                />
                            </div>
                        )
                    })
                }
                <Grid container justifyContent='flex-end' style={{margin:'1rem 0'}}>
                    <Button
                        variant="contained"
                        className="action-method-btn"
                        disableElevation
                        disabled={
                            // SOME CONDITION TO DISABLED BTN
                            false
                        }
                        style={{height:35}}
                        onClick={handleRecreateExpiration}
                    >
                        RECREAR VENCIMIENTOS
                    </Button>
                </Grid>
            </DialogContent>
            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
        </Dialog>
    )
}

export function ParticipantDetail(props) {

    const [workProfileDialog, setWorkProfileDialog] = useState(false);
    const [financialProfileDialog, setFinancialProfileDialog] = useState(false);
    const [avaiabilityCalcDialog, setAvaiabilityCalcDialog] = useState(false);
    const [claimDialog, setClaimDialog] = useState(false);
    const [savingFee, setSavingFee] = useState(0)
    const [curParticipant, setCurParticipant] = React.useState({
        uniqueId: '',
        fullName: '',
        identificationNumber: ''
    })

    const openWorkProfile = () => {
        setWorkProfileDialog(true);
    }
    const closeWorkProfile = () => {
        setWorkProfileDialog(false);
    }
    const openFinancialProfile = () =>  {
        setFinancialProfileDialog(true)
    }
    const closeFinancialProfile = () => {
        setFinancialProfileDialog(false)
    }
    const openAvaibilityCalc = () => {
        setAvaiabilityCalcDialog(true)
    }
    const closeAvaibilityCalc = () => {
        setAvaiabilityCalcDialog(false)
    }
    const togleClaimDialog = () => {
        setClaimDialog(!claimDialog)
    }
    const getSavingAccounts = (associateId) => {
        let saSavingFee = 0
        const endpoint = rohiSavingAccountsInfo({
            associateId: associateId,
            token: props.token,
        })
        endpoint.then(response => {
            response.data.forEach((element) => {
                saSavingFee = saSavingFee += Number(element.saving_fee);
            });
            setSavingFee(saSavingFee)
        }).catch(errors => {
            console.log(errors);
        });
    }

    const data = [
        {
            label: 'Nombre',
            value: props.ticket.participantDetail.fullName,
            type: 'text'
        },
        {
            label: 'Cedula',
            value: props.ticket.participantDetail.identificationNumber,
            type: 'text'
        },
        {
            label: 'Numero de contacto',
            value: props.ticket.participantDetail.cellPhone,
            type: 'numeric',
            extraProps: {format: '+#(###) ###-####'},
        },
        {
            label: 'Correo electronico',
            value: props.ticket.participantDetail.email,
            type: 'text'
        },
        {
            label: 'Lugar de trabajo',
            value: props.ticket.participantDetail.workProfileInstitution,
            type: 'text'
        },
        {
            label: 'Salario',
            value: props.ticket.participantDetail.workProfileObj.salary,
            type: 'numeric',
            extraProps: {
                thousandSeparator: true,
                decimalScale: 0,
                fixedDecimalScale: false,
            },
        },
        {
            label: 'Tiempo laboral (Años)',
            value: Math.ceil((props.ticket.participantDetail?.workProfileObj?.employmentLength || 0)/12),
            type: 'text'
        },
        {
            label: 'Edad',
            value: props.ticket.participantDetail?.age,
            type: 'text',
        },
        {
            label: 'Rating',
            value: props.ticket.participantDetail?.rating,
            type: 'text'
        },
        {
            label: 'Pagare esta realizado por la linea?',
            value: props.ticket.participantDetail?.notarialPayEqualLoan ? "Si" : "No",
            type: 'text'
        },
        {
            label: 'Tiene Garantias?',
            value: props.ticket.participantDetail?.hasWarranty ? "Si" : "No",
            type: 'text'
        },
        {
            label: 'Cantidad de Volantes',
            value: props.ticket.participantDetail?.flierAmount,
            type: 'numeric'
        },
        {
            label: 'Cuota de Ahorro',
            value: savingFee,
            type: 'numeric',
            extraProps: {
                thousandSeparator: true,
                decimalScale: 0,
                fixedDecimalScale: false,
            },
        },
    ]

    useEffect(() => {
        if (props.ticket.participantDetail){
            setCurParticipant(props.ticket.participantDetail)
            getSavingAccounts(props.ticket.participantDetail.identificationNumber);
        }
    }, [props.ticket])

    return(
        <>
        <Dialog
            fullWidth
            maxWidth="xl"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
            >
            <DialogHeader
                dialogTitle="Información del cliente"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
                />

            <DialogContent style={{margin:'2% 0'}}>
                <Grid container spacing={2}>
                    {data.map((item, index) => {
                        const props = {
                            xs:3,
                            typeVariant:"subtitle1",
                            typeClass:"field-label",
                            fieldLabel:item.label,
                            fieldID:"requestNumber",
                            fieldClass:"fields",
                            fieldVariant:"outlined",
                            margin:"dense",
                            fullWidth: true,
                            value:item.value,
                            disabled:true,
                        }
                        return (
                            <>
                                {
                                    item.type === 'text' ?
                                    <TextFieldComponent
                                        {...props}
                                    />
                                    :
                                    item.type === 'numeric' ?
                                    <NumericTextField 
                                        {...props}
                                        {...(item.extraProps || {})}
                                    />
                                    : null
                                }
                                {/* <TextFieldComponent
                                    xs={4}
                                    typeVariant="subtitle1"
                                    typeClass="field-label"
                                    fieldLabel={item.label}
                                    fieldID="requestNumber"
                                    fieldClass="fields"
                                    fieldVariant="outlined"
                                    margin="dense"
                                    fullWidth
                                    value={item.value}
                                    disabled={true}
                                /> */}
                            </>
                            );
                        })}
                        <br/>
                    <Divider style={{marginTop: 10}}/>
                        <br/>
                    <TextFieldComponent
                        xs={3}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Perfil de trabajo"
                        fieldID="requestNumber"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        margin="dense"
                        fullWidth
                        value={props.ticket.participantDetail?.workProfileObj?.referenceCode || "-----"}
                        inputProps={{
                        endAdornment: (
                            <IconButton 
                                disabled={
                                    !props.ticket.participantDetail?.workProfileObj ||
                                    !props.ticket.participantDetail?.uniqueId
                                }
                                onClick={() => openWorkProfile(props.ticket.participantDetail?.uniqueId)}>
                            <WorkIcon
                                style={{
                                    fontSize: 22,
                                    color: green[500],
                                }}
                            />
                            </IconButton>
                        ),
                        }}
                        disabled={true}
                    />
                    <TextFieldComponent
                        xs={3}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Perfil financiero"
                        fieldID="requestNumber"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        margin="dense"
                        fullWidth
                        value={props.ticket.participantDetail?.financialProfileObj?.referenceCode || "-----"}
                        inputProps={{
                        endAdornment: (
                            <IconButton 
                                onClick={() => openFinancialProfile(props.ticket.participantDetail?.uniqueId)}
                                disabled={
                                    !props.ticket.participantDetail?.financialProfileObj ||
                                    !props.ticket.participantDetail?.uniqueId
                                }
                            >
                            <AssignmentIndIcon
                                style={{
                                    fontSize: 22,
                                    color: green[500],
                                }}
                            />
                            </IconButton>
                        ),
                        }}
                        disabled={true}
                    />
                    <TextFieldComponent
                        xs={3}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Calculadora Disponibilidad"
                        fieldID="requestNumber"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        margin="dense"
                        fullWidth
                        value={'Calculadora Disponibilidad'}
                        inputProps={{
                        endAdornment: (
                            <IconButton 
                                onClick={() => openAvaibilityCalc(props.ticket.participantDetail?.uniqueId)}
                                disabled={!props.ticket.participantDetail?.uniqueId}
                            >
                            <FontAwesomeIcon icon={faCalculator}
                                style={{
                                    fontSize: 22,
                                    color: green[500],
                                }}
                            />
                            </IconButton>
                        ),
                        }}
                        disabled={true}
                    />
                    <TextFieldComponent
                        xs={3}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Reclamaciones"
                        fieldID="requestNumber"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        margin="dense"
                        fullWidth
                        value={'Reclamaciones'}
                        inputProps={{
                        endAdornment: (
                            <ClientButton                    
                                client={curParticipant.uniqueId ? curParticipant : ''}
                                open={claimDialog}
                                togle={togleClaimDialog}
                                color={true}
                            />
                        ),
                        }}
                        disabled={true}
                    />
                    <Divider style={{marginTop: 10}}/>

                    <Grid item container xs={12} justifyContent="space-between" direction="row" style={{padding:8}}>
                        <ClientDocuments
                            debtorId={props.ticket.customer}
                            user={{token: props.token}}
                            title={
                                <Typography
                                    variant={"subtitle1"}
                                    className={"section-header"}
                                    style={{fontWeight: 'bold', color:'#424242', marginLeft:5}}
                                >
                                    DOCUMENTOS
                                </Typography>
                            }
                        />
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
                />
        </Dialog>
        <Dialog open={workProfileDialog} onClose={closeWorkProfile} fullScreen={true}>
            <DialogActions>
                <IconButton onClick={closeWorkProfile}>
                    <ArrowBackIcon />
                </IconButton>
            </DialogActions>
            <DialogContent style={{ position: 'relative'}}>
                <WorkProfile ownerId={props.ticket.participantDetail.uniqueId}  />
            </DialogContent>
        </Dialog>
        <Dialog open={financialProfileDialog} onClose={closeFinancialProfile} fullScreen={true}>
            <DialogActions> 
                <IconButton onClick={closeFinancialProfile} >
                    <ArrowBackIcon />
                </IconButton>
            </DialogActions>
            <DialogContent style={{ position: 'relative' }}>
                <FinancialProfile ownerId={props.ticket.participantDetail.uniqueId} />
            </DialogContent>
        </Dialog>
        <Dialog open={avaiabilityCalcDialog} onClose={closeAvaibilityCalc} fullScreen={true}>
            <DialogActions>
                <IconButton onClick={closeAvaibilityCalc} >
                    <ArrowBackIcon />
                </IconButton>
            </DialogActions>
            <DialogContent style={{ position: 'relative' }}>
                <AvailabilityCalculator ownerId={props.ticket.participantDetail.uniqueId} />
            </DialogContent>
        </Dialog>
    </>
    )
}

export function Financiamientos(props) {

    const [ticket, setTicket] = useState({});
    const [quotation, setQuotation] = useState("");

    const getTicket = () => {
        const endpoint = fetchTicket(props.token, props.data.id_ticket);
        endpoint.then(response => {
            setTicket(response.data);
        }).catch(errors => {
            console.log(errors);
        });
    }

    const getQuotation = () => {
        const endpoint = fetchQuotation(props.token, props.data.id_cotizacion);
        endpoint.then(response => {
            setQuotation(response.data);
        }).catch(errors => {
            console.log(errors);
        });
    }

    useEffect(() => {
        if(props.data?.id_ticket) {
            getTicket();
        }
        if(props.data?.id_cotizacion) {
            getQuotation();
        }
    }, [props.data]);

    const data = [
        {
            label: 'Id desembolso',
            value: props.data.numero_prestamo
        },
        {
            label: 'Id cotizacion',
            value: quotation?.referenceCode || '---',
            isDialog: true,
            uniqueId: props.data.id_cotizacion,
            data: {
                open: quotation?.uniqueId,
                name: 'quotation',
                quotationId: quotation?.uniqueId,
            },
        },
        {
            label: 'Id ticket',
            value: ticket?.referenceCode || "---",
            isDialog: true,
            data: {
                open: ticket?.uniqueId,
                name: 'ticket',
                id: ticket?.uniqueId,
            },
        },
        {
            label: 'Fecha desembolso',
            value: props.data.fecha_desembolso
        },
        {
            label: 'Tipo de Producto',
            value: props.data.tipo_producto
        },
        {
            label: 'Dia pago',
            value: props.data.dia_pago
        },
        {
            label: 'Monto original',
            value:  Number(props.data.monto_original).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
            })
        },
        {
            label: 'Monto actual',
            value:  Number(props.data.saldo_actual).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
            })
        },
        {
            label: 'Tasa de Interes Anual',
            value:  Number(props.data.tasa_interes).toLocaleString("en-US", {}) + "%"
        },
        {
            label: 'Plazo',
            value: props.data.plazo
        },
        {
            label: 'Monto Cuotas',
            value:  Number(props.data.monto_cuota).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
            })
        },
    ]

    return(
        <>
        <Dialog
            fullWidth
            maxWidth="xl"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
            >
            <DialogHeader
                dialogTitle="Información del cliente"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
                />

            <DialogContent style={{margin:'2% 0'}}>
                <Grid container spacing={2}>
                    {data.map((item, index) => {
                        return (
                            <React.Fragment key={`financing-${index}`}>
                                <TextFieldComponent
                                    xs={4}
                                    typeVariant="subtitle1"
                                    typeClass="field-label"
                                    fieldLabel={item.label}
                                    fieldID="requestNumber"
                                    fieldClass="fields"
                                    fieldVariant="outlined"
                                    margin="dense"
                                    fullWidth
                                    value={item.value}
                                    disabled={true}
                                    inputProps={
                                        item.isDialog && {
                                        endAdornment: (
                                            // <IconButton onClick={() => props.handleToggleQuotation(quotation.uniqueId)}>
                                            <IconButton onClick={() => props.handleToggleComponent(item.data)}>
                                            <Visibility
                                                style={{
                                                    fontSize: 22,
                                                    color: green[500],
                                                }}
                                                />
                                        </IconButton>
                                    ),
                                    }}
                                />
                            </React.Fragment>
                            );
                        })}
                        <br/>
                </Grid>
            </DialogContent>
            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
                />
        </Dialog>
    </>
    )
}

export function Atrasos(props) {
    const [ticket, setTicket] = useState({});
    const [quotation, setQuotation] = useState("");

    const getTicket = () => {
        const endpoint = fetchTicket(props.token, props.data.id_ticket);
        endpoint.then(response => {
            setTicket(response.data);
        }).catch(errors => {
            console.log(errors);
        });
    }

    const getQuotation = () => {
        const endpoint = fetchQuotation(props.token, props.data.id_cotizacion);
        endpoint.then(response => {
            setQuotation(response.data);
        }).catch(errors => {
            console.log(errors);
        });
    }

    useEffect(() => {
        if(props.data?.id_ticket) {
            getTicket();
        }
        if(props.data?.id_cotizacion) {
            getQuotation();
        }
    }, [props.data]);

    const data = [
        {
            label: 'Id desembolso',
            value: props.data.numero_prestamo
        },
        {
            label: 'Id cotizacion',
            value: quotation?.referenceCode || '---',
            isDialog: true,
            uniqueId: props.data.id_cotizacion,
            data: {
                open: quotation?.uniqueId,
                name: 'quotation',
                quotationId: quotation?.uniqueId,
            },
        },
        {
            label: 'Id ticket',
            value: ticket?.referenceCode || "---",
            isDialog: true,
            data: {
                open: ticket?.uniqueId,
                name: 'ticket',
                id: ticket?.uniqueId,
            },
        },
        {
            label: 'Tipo de Producto',
            value: props.data.tipo_producto
        },
        {
            label: 'Fecha vencimiento',
            value: props.data.fecha_vencimiento
        },
        {
            label: 'Atraso ahorro',
            value:  Number(props.data.atrasos_ahorros).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
            })
        },
        {
            label: 'Atraso prestamos',
            value:  Number(props.data.total_prestamo).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
            })
        },
        {
            label: 'Otros cargos',
            value:  Number(props.data.otros_cargos).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
            })
        },
        {
            label: 'Total atrasado',
            value:  Number(props.data.total_atrasado).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
            })
        },
    ]

    return(
        <>
        <Dialog
            fullWidth
            maxWidth="xl"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
            >
            <DialogHeader
                dialogTitle="Información del cliente"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
                />

            <DialogContent style={{margin:'2% 0'}}>
                <Grid container spacing={2}>
                    {data.map((item, index) => {
                        return (
                            <React.Fragment key={`arrear-${index}`}>
                                <TextFieldComponent
                                    xs={4}
                                    typeVariant="subtitle1"
                                    typeClass="field-label"
                                    fieldLabel={item.label}
                                    fieldID="requestNumber"
                                    fieldClass="fields"
                                    fieldVariant="outlined"
                                    margin="dense"
                                    fullWidth
                                    value={item.value}
                                    disabled={true}
                                    inputProps={
                                        item.isDialog && {
                                        endAdornment: (
                                            <IconButton onClick={() => props.handleToggleComponent(item.data)}>
                                            <Visibility
                                                style={{
                                                    fontSize: 22,
                                                    color: green[500],
                                                }}
                                                />
                                        </IconButton>
                                    ),
                                    }}
                                />
                            </React.Fragment>
                            );
                        })}
                        <br/>
                </Grid>
            </DialogContent>
            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
                />
        </Dialog>
    </>
    )
}

export function ConfirmPrevTicket(props) {

    const [selctedStep, setSelctedStep] = useState(null);

    const handleSelectedStep = (value) => {
        setSelctedStep(value);
    }

    return(
        <Dialog
            fullWidth
            maxWidth="sm"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="CONFIRMACIÓN PASO PREVIO"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />

            <DialogContent style={{margin:'2% 0'}}>
                <AutocompleteField
                    xs={12}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Pasos disponibles"
                    fieldID="new-ticket-type"
                    fieldInnerLabel="Seleccione..."
                    fieldVariant="outlined"
                    value={selctedStep || null}
                    handleChange={(e, val) => handleSelectedStep(val)}
                    options={prevStepsDisbursement}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                />
                <Grid container item xs={12} justifyContent="flex-end">
                    <Button
                        variant="contained"
                        className={
                            !selctedStep ?
                            "action-method-btn disabled" : "action-method-btn"
                        }
                        disableElevation
                        disabled={!selctedStep}
                        style={{height:30, marginLeft:'.5rem', marginTop:41}}
                        onClick={
                            () =>{
                                props.data.handleToggleDialog(undefined);
                                handlePrevStep(props.data, selctedStep?.value, {label: "Pendiente de Revisión", value: "review_pending"});
                            } 
                        }
                    >
                        confirmar
                    </Button>
                </Grid>
            </DialogContent>

            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
        </Dialog>
    )
}