import { CREDITOR_TYPES } from '../actions';

export default function(state: ICreditor[] = [], action: IAction<ICreditor[]>): ICreditor[] {
    switch(action.type) {
        case CREDITOR_TYPES.FETCH_ALL:
            return action.payload || [];
        case CREDITOR_TYPES.FETCH:
            return state.map(creditor => (action.payload !== undefined && creditor.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: creditor);
        case CREDITOR_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case CREDITOR_TYPES.UPDATE:
            return state.map(creditor => (action.payload !== undefined && creditor.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : creditor);
        case CREDITOR_TYPES.DELETE:
            return state.filter(creditor => action.payload !== undefined && creditor.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}