import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Grid from "@material-ui/core/Grid";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import WarrantyTable from "../../Warranty/WarrantySelectInput/warrantyTable";
import { createNotarialPayContract } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import attorneyNumberMaskInput from "../../../components/TextMaskInputComponents/attorneyNumberMaskInput";
import moneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import {
  catchError,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
  weekDays,
  periodicity,
  notarialTypes,
} from "../../../utils";
import { createNotarialPayContract as create } from "../../../utils/axios";
import LoanContractSelectInput from "../../LoanContract/LoanContractSelectInput";
import AttorneySelectInput from "../../Attorney/AttorneySelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "50%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});
interface INotarialPayContractFormProps {
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    errorInput: string;
    formControl: string;
    greenButton: string;
    half: string;
    paper: string;
    root: string;
    textField: string;
  };

  user: IUser;
  handleSaveCallBack?(uid: string): void;
  createNotarialPayContract(
    notarialPayContract: INotarialPayContract
  ): IAction<INotarialPayContract[]>;
  changeSection(sectionNumber: number): void;
}
interface INotarialPayContractFormState {
  [key: string]: string | number | boolean | string[] | IBankAccount[];

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;

  title: string;
  titleError: string;

  attorney: string[];
  attorneyError: string;

  actNumber: string;
  actNumberError: string;

  loanContract: string[];
  loanContractError: string;

  interestRate: string;
  interestRateError: string;

  contractDate: string;
  contractDateError: string;

  notarialAmount: number;
  notarialAmountError: string;

  creditors: string[];
  creditorsError: string;

  debtors: string[];
  debtorsError: string;

  warranties: any[];
  warrantiesError: string;

  firstExpirationDate: string;
  firstExpirationDateError: string;

  lastExpirationDate: string;
  lastExpirationDateError: string;

  firstPayDate: string;
  firstPayDateError: string;

  lastPayDate: string;
  lastPayDateError: string;

  term: string;
  termError: string;

  fees: number;
  feesError: string;

  hasFees: boolean;
  hasFeesError: string;

  hasWarranty: boolean;
  hasWarrantyError: string;

  modifyDefaultInterests: boolean;
  modifyDefaultInterestsError: string;

  periodicity: string;
  periodicityError: string;

  notarialPayType: string;
  notarialPayTypeError: string;

  destinationDescription: string;
  destinationDescriptionError: string;

  destination: string;
  destinationError: string;

  defaultInterest: string;
  defaultInterestError: string;

  secondPayDate: string;
  secondPayDateError: string;

  checked: string[];
  disabledPoint: boolean;
}

const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    "&$checked": {
      color: green[500],
    },
    "&$checked + $track": {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);
class NotarialPayContractForm extends React.Component<
  INotarialPayContractFormProps,
  INotarialPayContractFormState
> {
  public state: INotarialPayContractFormState = {
    attorney: [],
    attorneyError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    disabledPoint: false,
    interestRate: "5.5",
    interestRateError: "",
    loanContract: [],
    loanContractError: "",
    notarialAmount: 0,
    notarialAmountError: "",
    actNumber: "",
    actNumberError: "",
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    title: "",
    titleError: "",
    debtors: [],
    debtorsError: "",
    creditors: [],
    creditorsError: "",
    warranties: [],
    warrantiesError: "",
    fees: 0,
    feesError: "",
    term: "",
    termError: "",
    firstExpirationDate: new Date().toISOString().split("T")[0],
    firstExpirationDateError: "",
    lastExpirationDate: "",
    lastExpirationDateError: "",
    firstPayDate: "",
    firstPayDateError: "",
    lastPayDate: "",
    lastPayDateError: "",
    hasFees: false,
    hasFeesError: "",
    hasWarranty: false,
    hasWarrantyError: "",
    contractFee: 0,
    contractFeeError: "",
    notarialPayType: "",
    notarialPayTypeError: "",
    destinationDescription: "",
    destinationDescriptionError: "",
    destination: "",
    destinationError: "",
    defaultInterest: "3.0",
    defaultInterestError: "",
    periodicity: "monthly",
    periodicityError: "",
    modifyDefaultInterests: false,
    modifyDefaultInterestsError: "",
    secondPayDate: "",
    secondPayDateError: "",
    checked: [],
  };

  constructor(props: INotarialPayContractFormProps) {
    super(props);

    this.handleAddLoanContract = this.handleAddLoanContract.bind(this);
    this.handleDeleteLoanContract = this.handleDeleteLoanContract.bind(this);

    // attorney
    this.handleAddAttorney = this.handleAddAttorney.bind(this);
    this.handleDeleteAttorney = this.handleDeleteAttorney.bind(this);

    // core event
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
  }
  public handleChecked(indx: string, warranty: string, warrantyType: string) {
    if (warrantyType === "property_warranty") {
      let warranties = this.state.checked;
      if (warranties.every((val) => val.slice(0, -1) === warranty)) {
        if (warranties.includes(indx)) {
          warranties = warranties.filter((wId) => wId !== indx);
        } else {
          warranties = warranties.concat(indx);
        }
      } else {
        warranties = [];
        warranties = warranties.concat(indx);
      }
      this.setState({
        warranty,
        checked: warranties,
      });
    } else {
      this.setState({
        warranty,
        checked: [indx],
      });
    }
  }

  public handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };
  public handleAddAttorney(id: string) {
    const attorney: string[] = [id];
    this.setState({ attorney });
  }
  public handleDeleteAttorney(id: string) {
    this.setState({ attorney: [] });
  }
  public handleAddLoanContract(contract: any) {
    this.setState({
      loanContract: [contract.uniqueId || ""],
      notarialAmount: contract.firstOutlayAmount || 0,
    });
    this.setState({ debtors: contract.debtors || "" });
  }
  public handleDeleteLoanContract(id: string) {
    this.setState({
      loanContract: [],
      notarialAmount: 0,
    });
  }
  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    const { state } = this;
    if (!state.loanContract.length) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "Debe de seleccionar un contrato de línea de credito",
      });
    } else if (
      state.loanContract.length &&
      this.state.hasWarranty &&
      this.state.checked.length < 0
    ) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "Debe de seleccionar una garantía",
      });
    } else if (!state.attorney.length) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "Debe de seleccionar un abogado",
      });
    } else {
      const notarialPayContract: INotarialPayContract = {
        actNumber: state.actNumber || "",
        contractDate: state.contractDate,
        deadline: state.term,
        destination: state.destinationDescription
          ? state.destinationDescription
          : state.destination,
        firstPaymentDate: state.firstExpirationDate,
        lastPaymentDate: state.lastExpirationDate,
        interestRate: state.interestRate,
        loanContract: state.loanContract[0] || "",
        moratoriumInterest: state.defaultInterest,
        notarialAmount:
          state.notarialAmount.toString().trim() === ""
            ? 0
            : state.notarialAmount,
        notarialAttorney: state.attorney[0],
        firstPaymentDay: state.firstPayDate,
        secondPaymentDay: state.secondPayDate,
        periodicity: state.periodicity,
        hasFees: state.hasFees,
        quota: state.fees,
        title: titleCaseHandler(state.title),
        typeNpc: state.notarialPayType,
        warranties: state.warranty || "",
        warranty: !state.checked ? [] : state.checked,
      };

      this.setState({ loading: true });

      create(this.props.user.token, notarialPayContract)
        .then((res) => {
          this.props.createNotarialPayContract(
            res.data.results ? res.data.results : res.data
          );
          if (this.props.handleSaveCallBack) {
            this.props.handleSaveCallBack(res.data.uniqueId);
          }
          successHandler(res, this, translations.NOTARIAL_PAY);
          this.handleReset();
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.periodicity !== this.state.periodicity) {
      if (this.state.periodicity === "single_payment") {
        this.setState({
          disabledPoint: true,
          term: "1",
          hasFees: false,
          fees: 0,
        });
      } else {
        this.setState({
          disabledPoint: false,
        });
      }
    }
  }

  public handleReset() {
    this.setState({
      attorney: [],
      attorneyError: "",
      dialog: false,
      dialogTitle: "",
      errorMessage: "",
      loading: false,
      interestRate: "5.5",
      interestRateError: "",
      loanContract: [],
      loanContractError: "",
      notarialAmount: 0,
      notarialAmountError: "",
      actNumber: "",
      actNumberError: "",
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      title: "",
      titleError: "",
      debtors: [],
      debtorsError: "",
      creditors: [],
      creditorsError: "",
      warranties: [],
      warrantiesError: "",
      fees: 0,
      feesError: "",
      term: "",
      termError: "",
      firstExpirationDate: new Date().toISOString().split("T")[0],
      firstExpirationDateError: "",
      lastExpirationDate: "",
      lastExpirationDateError: "",
      firstPayDate: "",
      firstPayDateError: "",
      lastPayDate: "",
      lastPayDateError: "",
      hasFees: false,
      hasFeesError: "",
      hasWarranty: false,
      hasWarrantyError: "",
      contractFee: 0,
      contractFeeError: "",
      notarialPayType: "",
      notarialPayTypeError: "",
      destinationDescription: "",
      destinationDescriptionError: "",
      destination: "",
      destinationError: "",
      defaultInterest: "3.0",
      defaultInterestError: "",
      periodicity: "monthly",
      periodicityError: "",
      modifyDefaultInterests: false,
      modifyDefaultInterestsError: "",
      secondPayDate: "",
      secondPayDateError: "",
      checked: [],
    });
  }

  public handleDialogClose() {
    this.setState({ dialog: false });
  }

  public render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">
            {translations.NEW_NOTARIAL_PAY_CONTRACT}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  disabled={this.state.disabledPoint}
                  control={
                    <GreenSwitch
                      checked={this.state.hasFees}
                      onChange={this.handleChanges}
                      name="hasFees"
                    />
                  }
                  label="¿Tiene cuotas?"
                />
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={this.state.hasWarranty}
                      onChange={this.handleChanges}
                      name="hasWarranty"
                    />
                  }
                  label="¿Tiene garantía?"
                />
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={this.state.modifyDefaultInterests}
                      onChange={this.handleChanges}
                      name="modifyDefaultInterests"
                    />
                  }
                  label="¿Desea modificar los intereses moratorios?"
                />
              </div>
              <br />
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <FormControl
                    style={{ width: "fit-content" }}
                    className={classes.formControl}
                    error={this.state.destinationError !== ""}
                  >
                    <InputLabel
                      style={styles.checkboxLabel}
                      htmlFor="destination"
                    >
                      {translations.DESTINATION}
                    </InputLabel>
                    <Select
                      value={this.state.destination}
                      onChange={this.handleSelectChange("destination")}
                      required={true}
                      inputProps={{
                        id: "destination",
                        name: "destination",
                      }}
                    >
                      <MenuItem value={translations.WORKING_CAPITAL}>
                        {translations.WORKING_CAPITAL}
                      </MenuItem>
                      <MenuItem value={translations.CONSUMER_FINANCING}>
                        {translations.CONSUMER_FINANCING}
                      </MenuItem>
                      <MenuItem value={translations.COLLEGE_CREDIT}>
                        {translations.COLLEGE_CREDIT}
                      </MenuItem>
                      <MenuItem value={translations.DEBT_REFINANCING}>
                        {translations.DEBT_REFINANCING}
                      </MenuItem>
                      <MenuItem value={translations.REAL_STATE_PROPERTY}>
                        {translations.REAL_STATE_PROPERTY}
                      </MenuItem>
                      <MenuItem value={translations.MOTOR_VEHICLE}>
                        {translations.MOTOR_VEHICLE}
                      </MenuItem>
                      <MenuItem value={translations.OTHERS}>
                        {translations.OTHERS}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {this.state.destination === translations.OTHERS ? (
                  <Grid item={true} xs={4}>
                    <TextField
                      className={classes.textField}
                      label={translations.DESCRIPTION}
                      value={this.state.destinationDescription}
                      onChange={this.handleChange("destinationDescription")}
                      required={true}
                      error={this.state.destinationDescriptionError !== ""}
                      helperText={this.state.destinationDescriptionError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                ) : null}
                {this.state.hasFees ? (
                  <Grid item={true} xs={4}>
                    <TextField
                      className={classes.textField}
                      label={translations.FEE}
                      value={this.state.fees}
                      onChange={this.handleCurrencyInputChange("fees")}
                      required={true}
                      error={this.state.feesError !== ""}
                      InputProps={{
                        inputComponent: moneyInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid container={true} spacing={2}>
                {!this.state.disabledPoint ? (
                  <Grid item={true} xs={4}>
                    <TextField
                      label="Primera fecha de vencimiento"
                      className={classes.textField}
                      value={this.state.firstExpirationDate}
                      type="date"
                      onChange={this.handleChange("firstExpirationDate")}
                      required={true}
                      error={this.state.firstExpirationDateError !== ""}
                      helperText={this.state.firstExpirationDateError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item={true} xs={4}>
                  <TextField
                    label="Última fecha de vencimiento"
                    className={classes.textField}
                    value={this.state.lastExpirationDate}
                    type="date"
                    onChange={this.handleChange("lastExpirationDate")}
                    required={true}
                    error={this.state.lastExpirationDateError !== ""}
                    helperText={this.state.lastExpirationDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <FormControl
                    style={{ width: "fit-content", marginTop: "35px" }}
                    className={classes.formControl}
                    error={this.state.periodicityError !== ""}
                  >
                    <InputLabel
                      style={styles.checkboxLabel}
                      htmlFor="periodicity"
                    >
                      {translations.PERIODICITY}
                    </InputLabel>
                    <Select
                      value={this.state.periodicity}
                      onChange={this.handleSelectChange("periodicity")}
                      required={true}
                      inputProps={{
                        id: "periodicity",
                        name: "periodicity",
                      }}
                    >
                      {periodicity.map((opt: any, index: any) => {
                        return (
                          <MenuItem key={index} value={opt.value}>
                            {opt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={4}>
                  <FormControl
                    style={{ width: "fit-content", marginTop: "35px" }}
                    className={classes.formControl}
                    error={this.state.notarialPayTypeError !== ""}
                  >
                    <InputLabel
                      style={styles.checkboxLabel}
                      htmlFor="notarialPayType"
                    >
                      {translations.NOTARIAL_PAY_TYPE}
                    </InputLabel>
                    <Select
                      value={this.state.notarialPayType}
                      onChange={this.handleSelectChange("notarialPayType")}
                      required={true}
                      inputProps={{
                        id: "notarialPayType",
                        name: "notarialPayType",
                      }}
                      defaultValue={"Garantia Solidaria"}
                    >
                      {notarialTypes.map((opt: any, index: any) => {
                        return (
                          <MenuItem key={index} value={opt.label}>
                            {opt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <TextField
                    label={`${translations.INTEREST_RATE}: ${this.state.interestRate}%`}
                    className={classes.textField}
                    value={this.state.interestRate}
                    type="number"
                    onChange={this.handleChange("interestRate")}
                    required={true}
                    error={this.state.interestRateError !== ""}
                    helperText={this.state.interestRateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 100,
                      min: 0,
                      step: 0.01,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={translations.TIME_LIMIT}
                    className={classes.textField}
                    value={this.state.term}
                    type="number"
                    onChange={this.handleChange("term")}
                    required={true}
                    disabled={this.state.disabledPoint}
                    error={this.state.termError !== ""}
                    helperText={this.state.termError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 100,
                      min: 0,
                      step: 1,
                    }}
                  />
                </Grid>
              </Grid>
              {this.state.periodicity === "biweekly" ? (
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={4}>
                    <TextField
                      className={classes.textField}
                      label={`Primer ${translations.PAYMENT_DAY}`}
                      value={this.state.firstPayDate}
                      type="number"
                      onChange={this.handleChange("firstPayDate")}
                      required={true}
                      error={this.state.firstPayDateError !== ""}
                      helperText={this.state.firstPayDateError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                      inputProps={{
                        max: 31,
                        min: 1,
                        step: 1,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <TextField
                      className={classes.textField}
                      label={`Segundo ${translations.PAYMENT_DAY}`}
                      value={this.state.secondPayDate}
                      type="number"
                      onChange={this.handleChange("secondPayDate")}
                      required={true}
                      error={this.state.secondPayDateError !== ""}
                      helperText={this.state.secondPayDateError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                      inputProps={{
                        max: 31,
                        min: 1,
                        step: 1,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <TextField
                      label={translations.ACT_NUMBER}
                      className={classes.textField}
                      value={this.state.actNumber}
                      onChange={this.handleChange("actNumber")}
                      error={this.state.actNumberError !== ""}
                      helperText={this.state.actNumberError}
                      InputProps={{
                        inputComponent: attorneyNumberMaskInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                </Grid>
              ) : this.state.periodicity === "weekly" ? (
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={4}>
                    <FormControl
                      style={{ width: "fit-content" }}
                      className={classes.formControl}
                      error={this.state.notarialTypeError !== ""}
                    >
                      <InputLabel
                        style={styles.checkboxLabel}
                        htmlFor="notarialType"
                      >
                        {translations.PAYMENT_DAY}
                      </InputLabel>
                      <Select
                        label="sd"
                        value={this.state.firstPayDate}
                        onChange={this.handleSelectChange("firstPayDate")}
                      >
                        {weekDays.map((day, index) => {
                          return (
                            <option value={day.value} key={index}>
                              {day.label}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <TextField
                      label={translations.ACT_NUMBER}
                      className={classes.textField}
                      value={this.state.actNumber}
                      onChange={this.handleChange("actNumber")}
                      error={this.state.actNumberError !== ""}
                      helperText={this.state.actNumberError}
                      InputProps={{
                        inputComponent: attorneyNumberMaskInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <TextField
                      label={translations.NOTARIAL_AMOUNT}
                      value={this.state.notarialAmount}
                      onChange={this.handleCurrencyInputChange(
                        "notarialAmount"
                      )}
                      required={true}
                      style={{ marginTop: "23px" }}
                      error={this.state.notarialAmountError !== ""}
                      helperText={this.state.notarialAmountError}
                      InputProps={{
                        inputComponent: moneyInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={4}>
                    <TextField
                      className={classes.textField}
                      label={`Primer ${translations.PAYMENT_DAY}`}
                      value={this.state.firstPayDate}
                      onChange={this.handleChange("firstPayDate")}
                      required={true}
                      type="number"
                      error={this.state.firstPayDateError !== ""}
                      helperText={this.state.firstPayDateError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                      inputProps={{
                        max: 31,
                        min: 1,
                        step: 1,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <TextField
                      label={translations.ACT_NUMBER}
                      className={classes.textField}
                      value={this.state.actNumber}
                      onChange={this.handleChange("actNumber")}
                      error={this.state.actNumberError !== ""}
                      helperText={this.state.actNumberError}
                      InputProps={{
                        inputComponent: attorneyNumberMaskInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <TextField
                      label={translations.NOTARIAL_AMOUNT}
                      value={this.state.notarialAmount}
                      onChange={this.handleCurrencyInputChange(
                        "notarialAmount"
                      )}
                      required={true}
                      style={{ marginTop: "23px" }}
                      error={this.state.notarialAmountError !== ""}
                      helperText={this.state.notarialAmountError}
                      InputProps={{
                        inputComponent: moneyInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <TextField
                    label={`${translations.DEFAULT_INTERESTS}: ${this.state.defaultInterest}%`}
                    className={classes.textField}
                    disabled={this.state.modifyDefaultInterests ? false : true}
                    value={this.state.defaultInterest}
                    onChange={this.handleChange("defaultInterest")}
                    error={this.state.defaultInterestError !== ""}
                    helperText={this.state.defaultInterestError}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 100,
                      min: 0,
                      step: 0.01,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={translations.NOTARIAL_AMOUNT}
                    value={this.state.notarialAmount}
                    onChange={this.handleCurrencyInputChange("notarialAmount")}
                    required={true}
                    style={{
                      marginTop: "23px",
                      display:
                        this.state.periodicity === "biweekly"
                          ? "inline"
                          : "none",
                    }}
                    error={this.state.notarialAmountError !== ""}
                    helperText={this.state.notarialAmountError}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={6}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.ATTORNEY}
                  </Typography>
                  <AttorneySelectInput
                    selectedAttorneysId={this.state.attorney}
                    addAttorney={this.handleAddAttorney}
                    deleteAttorney={this.handleDeleteAttorney}
                  />
                  <p className={classes.errorInput}>
                    {this.state.attorneyFError}
                  </p>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.NEW_LOAN_CONTRACT}
                  </Typography>
                  <LoanContractSelectInput
                    selectedLoanContractsId={this.state.loanContract}
                    add={this.handleAddLoanContract}
                    delete={this.handleDeleteLoanContract}
                  />
                  <p className={classes.errorInput}>
                    {this.state.loanContractError}
                  </p>
                </Grid>
              </Grid>
              {this.state.loanContract.length && this.state.hasWarranty ? (
                <Grid item={true} xs={12}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.WARRANTIES}
                  </Typography>
                  <WarrantyTable
                    checked={this.state.checked}
                    handleChecked={this.handleChecked}
                    debtors={this.state.debtors}
                  />
                </Grid>
              ) : null}
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createNotarialPayContract })(
    NotarialPayContractForm
  )
);
