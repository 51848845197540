import React, {useEffect} from 'react';
import { Grid } from '@material-ui/core';
import { 
    TextFieldComponent
} from './../../../components/SharedComponents/Fields';

export default function Comment({
    fields, onChange, disabled
}){
    return(
        <Grid container spacing={3} className="form-wrapper-fields-container">
            <TextFieldComponent
                xs={12}
                fullWidth
                fieldLabel= {`Comentario`}
                fieldID="leads-field-comment"
                placeholder="Digite un comentario..."
                value={fields.comment || ''}
                onChange={e => onChange('text', 'comment', e)}
                multiline={true}
                rows={6}
                disabled={disabled}
            />
        </Grid>
    )
}