import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Button,
  Toolbar,
} from "@material-ui/core";
import { Description, Settings } from "@material-ui/icons";
import { GeneralTableExpandable } from "./../../../components/SharedComponents/Table";
import { NumericTextField } from "./../../../components/SharedComponents/Fields";
import NumberFormat from "../../SharedComponents/NumberFormat";
import "../Calculators.css";

const amount = (column, row, value) => {
  return (
    <NumberFormat
      // value={row[column]}
      value={value}
      prefix="$"
    />
  );
};

const interest = (column, row, value) => {
  return (
    <NumberFormat
      // value={row[column]}
      value={value}
      suffix="%"
    />
  );
};

function PaymentTableToolbar(props) {
  return (
    <Toolbar>
      <Grid container item direction="row" spacing={5} alignItems="center">
        <Typography
          variant="h6"
          className="detail-header"
          style={{ fontWeight: "bold", paddingLeft: 16 }}
        >
          {props.title || "PRESTAMOS"}
          {props.print ? (
            <div className="detail-container">
              <Button
                variant="contained"
                className={
                  props.disablePrint
                    ? "action-method-btn disabled"
                    : "action-method-btn save"
                }
                style={{ marginLeft: ".8rem", fontSize: "10px" }}
                disableElevation
                size="small"
                disabled={props.disablePrint}
                onClick={props.generateFile}
              >
                <Description
                  style={{
                    fontSize: 15,
                    color: "#fff",
                    margin: "0 .5rem .1rem 0",
                  }}
                />
                Imprimir
              </Button>
              {props.showSave ? (
                <Button
                  variant="contained"
                  className={
                    props.disablePrint
                      ? "action-method-btn disabled"
                      : "action-method-btn save"
                  }
                  style={{ marginLeft: ".8rem", fontSize: "10px" }}
                  disableElevation
                  size="small"
                  disabled={props.disablePrint}
                  onClick={() => {
                    props.setDialogClose(false);
                    props.setCalculatorInfo();
                  }}
                >
                  <Description
                    style={{
                      fontSize: 15,
                      color: "#fff",
                      margin: "0 .5rem .1rem 0",
                    }}
                  />
                  Guardar
                </Button>
              ) : null}
            </div>
          ) : null}
        </Typography>
      </Grid>
    </Toolbar>
  );
}

function PaymentTable(props) {
  const [selected, setSelected] = useState([]);
  const nonEmptyData = props.data.filter(
    (item) => Object.keys(item).length > 0
  );

  useEffect(() => {
    props.handleSelected("", selected);
  }, [selected]);

  useEffect(() => {
    setSelected([]);
  }, [props.data]);

  // useEffect(() => {
  //     if(props.selectedData && props.selectedData.length > 0) {
  //         console.log('Selected edit data!');
  //         console.log(props.selectedData);
  //         setSelected(props.selectedData);
  //     }
  // }, [props.selectedData]);

  const conventionalColumns = [
    { id: "collapse", label: "", minWidth: 20 },
    { id: "id_dbs_rohi", label: "NÚMERO DE PRESTAMO", minWidth: 100 },
    { id: "tipo_transaccion", label: "TIPO DE TRANSACCIÓN", minWidth: 120 },
    { id: "fecha_desembolso", label: "FECHA DE TRANSACCIÓN", minWidth: 120 },
    { id: "dia_pago", label: "DÍA DE PAGO", minWidth: 120 },
    {
      id: "tasa_interes",
      label: "TASA INTERES PERIODICA",
      minWidth: 120,
      element: interest.bind(this, "tasa_interes"),
    },
    {
      id: "monto_original",
      label: "MONTO ORIGINAL",
      minWidth: 120,
      element: amount.bind(this, "monto_original"),
    },
    {
      id: "monto_actual",
      label: "SALDO ACTUAL",
      minWidth: 120,
      element: amount.bind(this, "monto_actual"),
    },
    { id: "periodicidad", label: "PERIODICIDAD", minWidth: 120 },
  ];

  const extraordinaryColumns = [
    { id: "checkbox" },
    { id: "id_dbs_rohi", label: "NÚMERO DE PRESTAMO", minWidth: 100 },
    { id: "tipo_transaccion", label: "TIPO DE TRANSACCIÓN", minWidth: 120 },
    {
      id: "configuracion_financiera",
      label: "CONFIGURACIÓN FINANCIERA",
      minWidth: 120,
    },
    { id: "fecha_desembolso", label: "FECHA DE TRANSACCIÓN", minWidth: 120 },
    { id: "dia_pago", label: "DÍA DE PAGO", minWidth: 120 },
    {
      id: "tasa_interes",
      label: "TASA INTERES PERIODICA",
      minWidth: 120,
      element: interest.bind(this, "tasa_interes"),
    },
    {
      id: "monto_original",
      label: "MONTO ORIGINAL",
      minWidth: 120,
      element: amount.bind(this, "monto_original"),
    },
    {
      id: "monto_actual",
      label: "SALDO ACTUAL",
      minWidth: 120,
      element: amount.bind(this, "monto_actual"),
    },
    { id: "periodicidad", label: "PERIODICIDAD", minWidth: 120 },
  ];

  const detailColumns = [
    { id: "checkbox" },
    { id: "id_vencimiento", label: "ID DE VENCIMIENTO", minWidth: 100 },
    { id: "fecha_vencimiento", label: "FECHA DE VENCIMIENTO", minWidth: 120 },
    {
      id: "total_vencido",
      label: "MONTO DE VENCIMIENTO",
      minWidth: 120,
      element: amount.bind(this, "total_vencido"),
    },
    {
      id: "total_pendiente",
      label: "MONTO PENDIENTE DE PAGO",
      minWidth: 120,
      element: amount.bind(this, "total_pendiente"),
    },
  ];

  return (
    <GeneralTableExpandable
      columns={
        props.paymentType === "extraordinary"
          ? extraordinaryColumns
          : conventionalColumns
      }
      detailColumns={props.paymentType === "extraordinary" ? [] : detailColumns}
      data={nonEmptyData}
      collapse={props.collapse}
      selected={selected}
      setSelected={setSelected}
      handleSelected={props.handleSelected}
      subTitle={props.subTitle}
      noPagination={props.noPagination}
      noDetailPagination={props.noDetailPagination}
      toolbar={<PaymentTableToolbar title={props.title} />}
    />
  );
}

function DetailPaymentTable(props) {
  const actions = (row) => {
    return (
      <Grid container direciton="row" justifyContent="center">
        <IconButton
          onClick={() => {
            props.handleModalData(row);
            props.switchState("openModal");
          }}
        >
          <Settings style={{ fontSize: 22, color: "#616161" }} />
        </IconButton>
      </Grid>
    );
  };

  const discount = (column, row) => {
    const data = [...props.data];
    const key = data.findIndex(
      (elem) => elem.id_vencimiento === row.id_vencimiento
    );
    return (
      <NumericTextField
        xs={10}
        typeVariant="subtitle1"
        typeClass="field-label"
        fieldID="discount"
        fieldClass="fields"
        fullWidth
        prefix="$"
        isAllowed={(values) => {
          const { floatValue } = values;
          return floatValue >= 0 && floatValue <= row.total_pendiente;
        }}
        allowLeadingZeros={false}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={true}
        disabled={props.readOnly}
        value={data[key].descuento}
        onChange={(value) => {
          data[key].descuento = value.floatValue;
          data[key].monto_pagar =
            data[key].principal_pagar +
            parseFloat(data[key].interes_pagar) +
            data[key].comision_pagar +
            data[key].mora_pagar +
            data[key].seguro_pagar +
            data[key].otros_cargos -
            value.floatValue;
          props.discount(data);
        }}
      />
    );
  };
  const othersFees = (column, row) => {
    const data = [...props.data];
    const key = data.findIndex(
      (elem) => elem.id_vencimiento === row.id_vencimiento
    );
    return (
      <NumericTextField
        xs={10}
        typeVariant="subtitle1"
        typeClass="field-label"
        fieldID="othersFees"
        fieldClass="fields"
        fullWidth
        prefix="$"
        isAllowed={(values) => {
          const { floatValue } = values;
          return floatValue >= 0;
        }}
        allowLeadingZeros={false}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={true}
        disabled={props.readOnly}
        value={data[key].otros_cargos}
        onChange={(value) => {
          data[key].otros_cargos = value.floatValue;
          data[key].monto_pagar =
            data[key].principal_pagar +
            parseFloat(data[key].interes_pagar) +
            data[key].comision_pagar +
            data[key].mora_pagar +
            data[key].seguro_pagar -
            data[key].descuento +
            value.floatValue;
          props.discount(data);
        }}
      />
    );
  };
  const legalFees = (column, row) => {
    const data = [...props.data];
    const key = data.findIndex(
      (elem) => elem.id_vencimiento === row.id_vencimiento
    );
    return (
      <NumericTextField
        xs={10}
        typeVariant="subtitle1"
        typeClass="field-label"
        fieldID="legalFees"
        fieldClass="fields"
        fullWidth
        prefix="$"
        isAllowed={(values) => {
          const { floatValue } = values;
          return floatValue >= 0;
        }}
        allowLeadingZeros={false}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={true}
        disabled={props.readOnly}
        value={data[key].gastos_legales}
        onChange={(value) => {
          data[key].gastos_legales = value.floatValue;
          data[key].monto_pagar =
            data[key].principal_pagar +
            parseFloat(data[key].interes_pagar) +
            data[key].comision_pagar +
            data[key].mora_pagar +
            data[key].seguro_pagar -
            data[key].descuento +
            data[key].otros_cargos +
            value.floatValue;
          props.discount(data);
        }}
      />
    );
  };

  const conventionalColumns = [
    { id: "id_dbs_rohi", label: "NÚMERO DE PRESTAMO", minWidth: 80 },
    { id: "id_vencimiento", label: "ID VENCIMIENTO", minWidth: 120 },
    {
      id: "principal_pagar",
      label: "PRINCIPAL",
      minWidth: 120,
      element: amount.bind(this, "principal_pagar"),
    },
    {
      id: "interes_pagar",
      label: "INTERES",
      minWidth: 100,
      element: amount.bind(this, "interes_pagar"),
    },
    {
      id: "comision_pagar",
      label: "COMISIÓN",
      minWidth: 100,
      element: amount.bind(this, "comision_pagar"),
    },
    {
      id: "mora_pagar",
      label: "MORA",
      minWidth: 100,
      element: amount.bind(this, "mora_pagar"),
    },
    {
      id: "seguro_pagar",
      label: "SEGURO",
      minWidth: 100,
      element: amount.bind(this, "seguro_pagar"),
    },
    {
      id: "total_pendiente",
      label: "TOTAL A VENCER",
      minWidth: 120,
      element: amount.bind(this, "total_pendiente"),
    },
    {
      id: "descuento",
      label: "DESCUENTO",
      minWidth: 120,
      element: discount.bind(this, "descuento"),
    },
    {
      id: "otros_cargos",
      label: "OTROS CARGOS",
      minWidth: 120,
      element: othersFees.bind(this, "otros_cargos"),
    },
    {
      id: "gastos_legales",
      label: "GASTOS LEGALES",
      minWidth: 120,
      element: legalFees.bind(this, "gastos_legales"),
    },
    {
      id: "monto_pagar",
      label: "MONTO A PAGAR",
      minWidth: 120,
      element: amount.bind(this, "monto_pagar"),
    },
  ];

  const extraordinaryColumns = [
    { id: "id_dbs_rohi", label: "NÚMERO DE PRESTAMO", minWidth: 120 },
    { id: "tipo_transaccion", label: "TIPO DE PRODUCTO", minWidth: 120 },
    {
      id: "monto_aplicado",
      label: "MONTO APLICADO",
      minWidth: 120,
      element: amount.bind(this, "monto_aplicado"),
    },
    {
      id: "total_pagar",
      label: "TOTAL A PAGAR",
      minWidth: 120,
      element: amount.bind(this, "total_pagar"),
    },
    {
      id: "proxima_cuota",
      label: "PRÓXIMA CUOTA",
      minWidth: 120,
      element: amount.bind(this, "proxima_cuota"),
    },
    {
      id: "action",
      label: "CONFIGURACIÓN",
      align: "center",
      minWidth: 170,
      element: actions.bind(this),
    },
  ];

  return (
    <GeneralTableExpandable
      columns={
        props.paymentType === "extraordinary"
          ? extraordinaryColumns
          : conventionalColumns
      }
      data={props.data}
      collapse={props.collapse}
      noPagination={props.noPagination}
      toolbar={
        <PaymentTableToolbar
          title={props.title}
          print={props.print}
          setDialogClose={props.setDialogClose}
          disablePrint={props.disablePrint}
          generateFile={props.generateFile}
          setCalculatorInfo={props.setCalculatorInfo}
          showSave={props.showSave}
        />
      }
    />
  );
}

export { PaymentTable, DetailPaymentTable };
