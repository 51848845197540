import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { ArrowRightAlt } from "@material-ui/icons";
import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  Timeline,
  TimelineContent,
} from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { DialogHeader } from "src/components/SharedComponents/Dialogs";
import { civilStatus, dwellingTypes } from "src/utils";

const changed_participant_fields = [
  { label: "Nombre", value: "full_name" },
  { label: "Identificación", value: "identification_number" },
  { label: "Teléfono móvil", value: "cell_phone" },
  { label: "Correo electrónico", value: "email" },
  { label: "Dirección", value: "address" },
  { label: "Teléfono del trabajo", value: "work_phone" },
  { label: "Extensión del trabajo", value: "work_phone_extension" },
  { label: "Teléfono de casa", value: "home_phone" },
  { label: "Tipo de Vivienda", value: "house_info"},
  { label: "Estado civil", value: "civil_status"}
];

function ParticipantActivityHistory(props) {
  const { handleClose, open, token } = props;
  const [participantHistory, setParticipantHistory] = useState();

  const formatDateString = (inputDateString) => {
    const [datePart, timePart] = inputDateString.split("T");
    const formattedTimePart = timePart.split(".")[0];

    return `${datePart} ${formattedTimePart}`;
  };

  const getFieldTranslation = (field) => {
    const combinedDictionaries = [...changed_participant_fields, ...dwellingTypes, ...civilStatus]
    const translation = combinedDictionaries.find(
      (item) => item.value === field
    );
    return translation ? translation.label : field;
  };

  useEffect(() => {
    setParticipantHistory(props.participantHistory);
  }, [props.participantHistory]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogHeader
        dialogTitle="Registro de Actividad"
        handleClick={handleClose}
      />
      <DialogContent>
      {participantHistory && participantHistory.length > 0 ? (
        <Timeline>
          {participantHistory !== undefined &&
            participantHistory.map((history, index) => {
              const changes = history.changed_fields;
              return (
                <TimelineItem key={`participant-history-${index}`}>
                  <TimelineOppositeContent color="textSecondary">
                    <Typography variant="body1" color="textSecondary">
                      {formatDateString(history.changed_at)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {history.changed_by_user}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {changes.map((change, index) => {
                      return (
                        <>
                          <Typography
                            variant="body2"
                            key={`changed-fields-${index}`}
                          >
                            Se ha cambiado el campo{" "}
                            <b>{getFieldTranslation(change.field)}</b> de{" "}
                            <i>"{getFieldTranslation(change.old_field)}" </i> a 
                            <ArrowRightAlt /> <i>"{getFieldTranslation(change.new_field)}"</i>
                          </Typography>
                          <hr />
                        </>
                      );
                    })}
                  </TimelineContent>
                </TimelineItem>
              );
            })}
        </Timeline>
        ) : (
          <i>ESTE CLIENTE NO POSEE HISTORIAL DE CAMBIOS REGISTRADO.</i>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ParticipantActivityHistory;
