import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchClaimsCountByParticipant } from './../../../utils/axios';
import LoadingDialog from '../../../components/SharedComponents/LoadingScreen';
import { IconButton, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClaimView from '../View';
import { green } from '@material-ui/core/colors';

function ClientButton(props){
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState();
    const [color, setColor] = useState();
    const [dialog, setDialog] = useState();

    const status = () => {
        const statusColors = [
            { bgColor: '#43A047', color: '#FFF', state:'closed', name: 'Cerrado', quantity: () => count.closed }, 
            { bgColor: '#f44336', color: '#FFF', state:'canceled', name: 'Cancelado', quantity: () => count.canceled },
            { bgColor: '#ef6c00', color: '#FFF', state:'delayed', name: 'Atrasado', quantity: () => count.delayed },
            { bgColor: '#1ea0f7', color: '#FFF', state:'active', name: 'Activo', quantity: () => count.active },
        ]

        let keys = Object.keys(count)

        if (keys.includes('delayed')) {
            setColor(statusColors[2]);
        }else if (keys.includes('active')) {
            setColor(statusColors[3]);
        // }else if (keys.includes('canceled')) {
        //         setColor(statusColors[1]);
        // }else if (keys.includes('closed')) {
        //     setColor(statusColors[0]);
        }else{
            setColor(null);
        }
    }

    const getCount = () => {
        setLoading(true);
        fetchClaimsCountByParticipant({token: props.user.token, clientUniqueId: props.client.uniqueId})
        .then(res => {
            setCount(res.data || {});
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const updateCount = () => {
        getCount();
    }

    const togleDialog = (show) => {
        show && !color?setDialog({name:'claims'}):setDialog({});
        props.togle()
    }

    useEffect(() => {
        if (props.client) {
            getCount();
        }
    }, [])

    useEffect(() => {
        if (count) {
            status();
        }
    }, [count])

    return (
        <>
            <LoadingDialog open={loading} />
            {/* <SpeakerNotesIcon  /> */}
            {
                color ?
                <Button
                    variant="contained"
                    disableElevation
                    onClick={props.togle}
                    // startIcon={<SpeakerNotesIcon />}
                    style={{
                        minWidth:120, height: 25, borderRadius:20,
                        backgroundColor: color.bgColor,
                        color: color.color,
                        fontSize:12,
                    }}
                >
                    {color.name} <b>{': '+color.quantity()}</b>
                </Button> :
                <IconButton onClick={togleDialog}>
                    {props.color ? 
                     <SpeakerNotesIcon 
                        style={{
                            fontSize: 22,
                            color: green[500],
                            }}
                        /> : <SpeakerNotesIcon />
                }
                </IconButton>
            }
            <Dialog open={props.open} onClose={props.togle} fullScreen={true}>
                <DialogActions>
                    <IconButton onClick={props.togle}>
                        <ArrowBackIcon />
                    </IconButton>
                </DialogActions>
                <DialogContent style={{ position: 'relative' }}>
                    <ClaimView client={props.client} dialog={dialog} updateCount={updateCount}/>
                </DialogContent>
            </Dialog>
        </>
    )

}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(ClientButton);