import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createProvider } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import idNumberMaskInput from "../../../components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "../../../components/TextMaskInputComponents/passportMaskInput";
import {
  catchError,
  IDENTIFICATION_TYPE,
  resetError,
  successHandler,
  translations,
  titleCaseHandler,
} from "../../../utils";
import { createProvider as create } from "../../../utils/axios";
import Address from "../../../components/Address/Address";

const GreenRadio = withStyles({
  checked: {},
  root: {
    "&$checked": { color: green[600] },
    color: green[400],
  },
})((props) => <Radio color="default" {...props} />);

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  button: {
    color: green[500],
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  filterButton: {
    width: "15%",
  },
  formControl: {
    marginTop: 20,
    width: "90%",
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  textField2: {
    display: "block",
    marginTop: 20,
    width: "90%",
  },
});

interface IProviderFormProps {
  classes: { [key: string]: string };

  user: IUser;
  createProvider(provider: IProvider): IAction<IProvider[]>;
  changeSection(sectionNumber: number, providerId: string): void;
}
interface IProviderFormState {
  [key: string]: string | string[] | number | boolean;

  identificationNumber: string;
  identificationNumberError: string;
  identificationType: string;
  identificationTypeError: string;
  fullName: string;
  fullNameError: string;
  city: string;
  cityError: string;
  country: string;
  countryError: string;
  providerType: string;
  open: boolean;
  isOpen: boolean;
  providerContacts: string[];
  providerContactsError: string;
  reference: string;
  referenceError: string;
  sector: string;
  sectorError: string;
  state: string;
  stateError: string;
  region: string;
  regionError: string;
  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
  newOnSave: boolean;
  website: string;
  websiteError: string;
  zipCode: string;
  zipCodeError: string;
}
class ProviderForm extends React.Component<
  IProviderFormProps,
  IProviderFormState
> {
  public state: IProviderFormState = {
    city: "",
    cityError: "",
    country: "República Dominicana",
    countryError: "",
    fullName: "",
    fullNameError: "",
    identificationNumber: "",
    identificationNumberError: "",
    identificationType: "national",
    identificationTypeError: "",
    isOpen: false,
    newOnSave: false,
    open: false,
    providerType: "company",
    reference: "",
    referenceError: "",
    region: "",
    regionError: "",
    sector: "",
    sectorError: "",
    state: "",
    stateError: "",
    website: "",
    websiteError: "",
    zipCode: "",
    zipCodeError: "",

    providerContacts: [],
    providerContactsError: "",

    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
  };

  constructor(props: IProviderFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.openFilterDialog = this.openFilterDialog.bind(this);
    this.closeFilterDialog = this.closeFilterDialog.bind(this);
  }
  public openDialog() {
    this.setState({ open: true });
  }
  public openFilterDialog() {
    this.setState({ isOpen: true });
  }
  public closeFilterDialog() {
    this.setState({ isOpen: false });
  }
  public handleCheckInputChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelect(key: string, value: string) {
    this.setState({
      [key]: value,
    });
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    const provider: IProvider = {
      address: {
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        region: this.state.region,
        reference: this.state.reference,
        sector: titleCaseHandler(this.state.sector),
      },
      fullName: this.state.fullName,
      identificationNumber: this.state.identificationNumber,
      identificationType: this.state.identificationType,
      providerType: this.state.providerType,
      website: this.state.website,
      zipCode: this.state.zipCode,
    };

    this.setState({ loading: true });

    create(this.props.user.token, provider)
      .then((res) => {
        this.props.createProvider(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, translations.PROVIDER);
        this.handleReset();

        if (!this.state.newOnSave) {
          this.props.changeSection(2, res.data.uniqueId);
        }
      })
      .catch((err: AxiosError) => {
        console.debug(err);
        catchError(err, this);
      });
  }

  public handleReset() {
    this.setState({
      city: "",
      cityError: "",
      country: "República Dominicana",
      countryError: "",
      fullName: "",
      fullNameError: "",
      identificationNumber: "",
      identificationNumberError: "",
      identificationType: "national",
      identificationTypeError: "",
      reference: "",
      referenceError: "",
      region: "",
      regionError: "",
      sector: "",
      sectorError: "",
      state: "",
      stateError: "",
      website: "",
      websiteError: "",
      zipCode: "",
      zipCodeError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }

  public getIdentificationInput(identificationType: string) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }
  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.NEW_PROVIDER}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <FormControl className={classes.formControl}>
                <FormLabel component="legend">{`Tipo`}</FormLabel>
                <RadioGroup
                  style={{ display: "-webkit-box" }}
                  value={this.state.providerType}
                  onChange={this.handleChange("providerType")}
                >
                  <FormControlLabel
                    value="company"
                    control={<GreenRadio />}
                    label="Empresa"
                  />
                  <FormControlLabel
                    value="person"
                    control={<GreenRadio />}
                    label="Persona"
                  />
                </RadioGroup>
              </FormControl>

              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={this.state.fullName}
                onChange={this.handleChange("fullName")}
                fullWidth={true}
                error={this.state.fullNameError !== ""}
                helperText={this.state.fullNameError}
                required={true}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <div style={{ display: "flex" }}>
                <div style={{ width: "25%" }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="identificationType" shrink={true}>
                      {translations.IDENTIFICATION_TYPE}
                    </InputLabel>
                    <Select
                      value={this.state.identificationType}
                      onChange={this.handleSelectChange("identificationType")}
                      required={true}
                      native={true}
                      error={this.state.identificationTypeError !== ""}
                      inputProps={{
                        id: "identificationType",
                        name: "identificationType",
                      }}
                    >
                      {IDENTIFICATION_TYPE.map((opt, indx) => {
                        return (
                          <option value={opt.value} key={indx}>
                            {opt.label}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <br />

                <div style={{ width: "25%" }}>
                  <TextField
                    label={translations.IDENTIFICATION}
                    className={classes.textField2}
                    value={this.state.identificationNumber}
                    onChange={this.handleChange("identificationNumber")}
                    fullWidth={true}
                    required={true}
                    error={this.state.identificationNumberError !== ""}
                    helperText={this.state.identificationNumberError}
                    InputProps={this.getIdentificationInput(
                      this.state.identificationType
                    )}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </div>
              </div>
              <br />
              <br />

              <Address
                city={this.state.city}
                cityError={this.state.cityError}
                country={this.state.country}
                countryError={this.state.countryError}
                handleChange={this.handleChange}
                handleSelect={this.handleSelect}
                reference={this.state.reference}
                referenceError={this.state.referenceError}
                region={this.state.region}
                regionError={this.state.regionError}
                sector={this.state.sector}
                sectorError={this.state.sectorError}
                state={this.state.state}
                stateError={this.state.stateError}
                title={translations.ADDRESS}
              />
              <TextField
                label={translations.ZIP_CODE}
                className={classes.textField}
                value={this.state.zipCode}
                onChange={this.handleChange("zipCode")}
                fullWidth={true}
                error={this.state.zipCodeError !== ""}
                helperText={this.state.zipCodeError}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.WEBSITE}
                className={classes.textField}
                value={this.state.website}
                onChange={this.handleChange("website")}
                fullWidth={true}
                error={this.state.websiteError !== ""}
                helperText={this.state.websiteError}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              />
              <br />
              <br />

              <FormControlLabel
                label={translations.OPEN_NEW_ON_SAVE}
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.newOnSave}
                    onChange={this.handleCheckInputChange("newOnSave")}
                  />
                }
                value="hasDebtorPossessedPublicPower"
              />

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
        <br />
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createProvider })(ProviderForm)
);
