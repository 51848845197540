import * as React from "react";

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@material-ui/core";

import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { NoRowsOverlay } from "../../../components/SharedComponents/Table";

function Row(props) {
  const row = props.data;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow key={props.index + "-" + props.id}>
        {props.columns.map((column, index) => {
          const value = row[column.id];
          const isCollapse =
            column.isCollapse && column.isCollapse(row, row[column.id]);

          return column.id === "collapse" && isCollapse > 0 ? (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ width: column.width }}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </TableCell>
          ) : (
            <TableCell key={column.id + "-" + index} align={column.align}>
              {column.element ? column.element(row, row[column.id]) : value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow key={props.id + "-" + props.index}>
        <TableCell style={{ padding: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead style={{ backgroundColor: "#f5f5f5" }}>
                  <TableRow key={props.id}>
                    {props.columnsCollapse.map((item, index) => {
                      return (
                        <TableCell
                          padding={
                            item.id === "checkbox" ? "checkbox" : undefined
                          }
                          key={item.id + "-" + index}
                          align={item.align}
                          style={{ width: item.width }}
                        >
                          {item.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.collapseData.map((row2, index) => {
                    const id = row2.uniqueId || row2.id;
                    return (
                      <>
                        <TableRow
                          key={"tableRow-" + index + "-" + id}
                          tabIndex={-1}
                          style={{ backgroundColor: "#f5f5f5" }}
                        >
                          {props.columnsCollapse.map((column, index) => {
                            const value = row2[column.id];
                            return (
                              <TableCell
                                key={column.id + "-" + index}
                                align={column.align}
                                onClick={
                                  props.rowClick &&
                                  props.rowClick.bind(this, row2)
                                }
                              >
                                {column.element
                                  ? column.element(row2, row2[column.id])
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function CollapsibleTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper style={{ width: "100%" }}>
      {props.toolbar}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {props.columns.map((item, index) => {
                return (
                  <TableCell
                    padding={item.id === "checkbox" ? "checkbox" : undefined}
                    key={item.id + "-" + index}
                    align={item.align}
                  >
                    {item.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.length < 1 ? (
              <NoRowsOverlay colSpan={props.columns.length} />
            ) : (
              props.data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const id = row.uniqueId || row.id;
                  // const idKeyName = row.uniqueId ? 'uniqueId' : 'id';
                  // const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <Row
                      id={id}
                      key={index}
                      index={index}
                      columns={props.columns}
                      columnsCollapse={props.columnsCollapse}
                      data={row}
                    />
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.noPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Filas por página:"}
        />
      )}
    </Paper>
  );
}

export { CollapsibleTable };
