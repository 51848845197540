import { AxiosError } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';



import { deleteCreditReviewAuthorization, fetchCreditReviewAuthorization, updateCreditReviewAuthorization } from '../../../actions';
import AlertDialog from '../../../components/AlertDialog';
import Loading from '../../../components/Loading';
import {
    catchError, resetError, successHandler,
    titleCaseHandler, translations, IDENTIFICATION_TYPE, filesGeneration
} from '../../../utils';
import {
    deleteCreditReviewAuthorization as deleteCRA, fetchCreditReviewAuthorization as fetchCRA,
    updateCreditReviewAuthorization as updateCRA
} from '../../../utils/axios';
import ParticipantSelectInput from '../../Participant/ParticipantSelectInput';
import Address from '../../../components/Address/Address'
import phoneMaskInput from 'src/components/TextMaskInputComponents/phoneMaskInput';
import idNumberMaskInput from 'src/components/TextMaskInputComponents/idNumberMaskInput';
import passportMaskInput from 'src/components/TextMaskInputComponents/passportMaskInput';
import rncMaskInput from 'src/components/TextMaskInputComponents/rncMaskInput';
import FileUploadSelectInput from 'src/containers/FileUpload/FileUploadSelectInput';

const styles: any = createStyles({
    checkboxLabel: {
        color: green[500],
    },
    controls: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    deleteButton: {
        backgroundColor: red[500],
        color: 'white'
    },
    errorInput: {
        color: 'red'
    },
    formControl: {
        marginTop: 17,
        minWidth: 300,
        width: 300
    },
    greenButton: {
        backgroundColor: green[500],
        color: 'white'
    },
    half: {
        width: '100%'
    },
    paper: {
        padding: 20
    },
    root: {
        height: '90vh',
        overflowY: 'auto',
        padding: 20,
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: '50%'
    }

});

interface ICreditReviewAuthorizationViewProps {
    creditReviewAuthorizationId: string;
    classes: {
        attachmentIcon: string;
        attachmentIconButton: string;
        checkboxLabel: string;
        controls: string;
        deleteButton: string;
        errorInput: string;
        formControl: string;
        greenButton: string;
        half: string;
        paper: string;
        root: string;
        textField: string;
    };

    user: IUser;
    changeSection(sectionNumber: number): void;
    deleteCreditReviewAuthorization(uniqueId: string): IAction<ICreditReviewAuthorization[]>;
    fetchCreditReviewAuthorization(creditReviewAuthorization: ICreditReviewAuthorization): IAction<ICreditReviewAuthorization[]>;
    updateCreditReviewAuthorization(creditReviewAuthorization: ICreditReviewAuthorization): IAction<ICreditReviewAuthorization[]>;
}
interface ICreditReviewAuthorizationViewState {
    [key: string]: string | number | boolean | string[] | IBankAccount[] | undefined;

    dialog: boolean;
    dialogTitle: string;
    errorMessage: string;
    loading: boolean;
    status: string;
    uniqueId: string;

    title: string;
    titleError: string;
    
    CRA: string;

    debtor: string[];
    debtorError: string;

    contractDate: string;
    contractDateError: string;

    fullName: string;
    idType: any;
    idNumber: string;
    email: string;
    cellPhone: string;


    country: string;
    region: string;
    reference: string;
    state: string;
    sector: string;
    city: string;

    newClient: boolean;

}
const GreenSwitch = withStyles({
    switchBase: {
        color: green[300],
        '&$checked': {
            color: green[500],
        },
        '&$checked + $track': {
            backgroundColor: green[500],
        },
    },
    checked: {},
    track: {},
})(Switch);
class CreditReviewAuthorizationView extends React.Component<ICreditReviewAuthorizationViewProps, ICreditReviewAuthorizationViewState> {
    public state: ICreditReviewAuthorizationViewState = {
        contractDate: new Date().toISOString().split('T')[0],
        contractDateError: '',
        CRA: '',
        debtor: [],
        debtorError: '',
        dialog: false,
        dialogTitle: '',
        errorMessage: '',
        loading: false,
        status: 'pending_signature',
        title: '',
        titleError: '',
        uniqueId: '',
        newClient: false,
        fullName: '',
        idType: '',
        idNumber: '',
        email: '',
        cellPhone: '',
        // address
        country: "",
        reference: "",
        state: "",
        region: "",
        sector: "",
        city: ""
    }
    constructor(props: ICreditReviewAuthorizationViewProps) {
        super(props);
        // client
        this.handleAddClient = this.handleAddClient.bind(this);
        this.handleDeleteClient = this.handleDeleteClient.bind(this);
        // core events
        this.generateFile = this.generateFile.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.handleChanges = this.handleChanges.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getIdentificationInput = this.getIdentificationInput.bind(this);
        this.handleState = this.handleState.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleSelect = this.handleSelect.bind(this);


    }
    public handleSelect(key: string, value: string) {
        this.setState({
            [key]: value,
        });
    }
    public handleChangeAddress(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                [key]: event.currentTarget.value,
                [key + "Error"]: "",
            });
        };
    }
    public handleState(key: any, newState: any) {
        this.setState((state: any) => {
            state[key] = newState;
            return state;
        })
    }
    public getIdentificationInput = (identificationType: any) => {
        switch (identificationType) {
            case "national":
                return {
                    inputComponent: idNumberMaskInput,
                };
            case "RNC":
                return {
                    inputComponent: rncMaskInput,
                };
            case "passport":
                return {
                    inputComponent: passportMaskInput,
                };
            default:
                return {
                    title: "",
                };
        }
    }
    public handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    public handleAddClient(id: string) {
        const debtor = this.state.debtor.filter(debtorId => debtorId !== id);
        debtor.push(id)
        this.setState({ debtor });
    }
    public handleDeleteClient(id: string) {
        const debtor = this.state.debtor.filter(debtorId => debtorId !== id);
        this.setState({ debtor });
    }
    public async generateFile() {
        this.setState({ loading: true });
        const url = `/lending/credit-review-authorization/${this.state.uniqueId}/print/`;
        await filesGeneration(url, this.props.user.token);
        this.setState({ loading: false });
    }
    public changeStatus(status: string) {
        return () => {
            this.setState({ status });
        }
    }

    public componentDidMount() {

        fetchCRA(this.props.user.token, this.props.creditReviewAuthorizationId).then(res => {
            const curCreditReviewAuthorization = res.data as ICreditReviewAuthorization;
            this.setState({
                CRA: `lending/credit-review-authorization/${curCreditReviewAuthorization.uniqueId}/upload_attachment/`,
                contractDate: curCreditReviewAuthorization.contractDate || '',
                dialog: false,
                dialogTitle: '',
                errorMessage: '',
                loading: false,
                status: curCreditReviewAuthorization.status || '',
                title: curCreditReviewAuthorization.title || '',
                uniqueId: curCreditReviewAuthorization.uniqueId || '',
                debtor: [curCreditReviewAuthorization.debtor!] || '',
                newClient: curCreditReviewAuthorization.debtor! ? false : true,
                
            });
            if (curCreditReviewAuthorization.potentialDebtor!==null) {
                this.setState({
                    fullName: curCreditReviewAuthorization.potentialDebtor.fullName || '',
                    idType: curCreditReviewAuthorization.potentialDebtor.identificationType || '',
                    idNumber: curCreditReviewAuthorization.potentialDebtor.identificationNumber || '',
                    email: curCreditReviewAuthorization.potentialDebtor.email || '',
                    cellPhone: curCreditReviewAuthorization.potentialDebtor.cellPhone || '',
                    country: curCreditReviewAuthorization.potentialDebtor.address.country || '',
                    city: curCreditReviewAuthorization.potentialDebtor.address.city || '',
                    reference: curCreditReviewAuthorization.potentialDebtor.address.reference || '',
                    state: curCreditReviewAuthorization.potentialDebtor.address.state || '',
                    region: curCreditReviewAuthorization.potentialDebtor.address.region || '',
                    sector: curCreditReviewAuthorization.potentialDebtor.address.sector || ''
                })
            }

        })
        // .catch(err => {
        //     catchError(err, this)
        // });

    }

    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({ [key]: event.target.value });
        }
    }

    public async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        const creditReviewAuthorization: ICreditReviewAuthorization = {
            contractDate: this.state.contractDate,
            debtor: this.state.debtor[0],
            status: this.state.status,
            title: titleCaseHandler(this.state.title),
            potentialDebtor: {
                fullName: this.state.fullName,
                email: this.state.email,
                cellPhone: this.state.cellPhone,
                identificationType: this.state.idType,
                identificationNumber: this.state.idNumber,
                address: {
                    city: this.state.city,
                    country: this.state.country,
                    state: this.state.state,
                    region: this.state.region,
                    reference: this.state.reference,
                    sector: this.state.sector,
                },
            }
        };
        event.preventDefault();
        resetError(this);

        if ((this.state.debtor.length === 0 && this.state.newClient === false) || (this.state.debtor[0] === null && this.state.newClient === false)) {
            this.setState({
                dialog: true,
                dialogTitle: 'Disculpe',
                errorMessage: 'El deudor es requerido',
            });

        } else if (this.state.debtor.length !== 0 && this.state.newClient === false) {
            this.setState({ loading: true });
            updateCRA(this.props.user.token, this.state.uniqueId, creditReviewAuthorization).then(res => {
                this.props.updateCreditReviewAuthorization(res.data as ICreditReviewAuthorization);
                successHandler(res, this, translations.CREDIT_REVIEW_AUTHORIZATION)
            }).catch((error: AxiosError) => {
                catchError(error, this)
            });
        } else if (this.state.newClient) {
            updateCRA(this.props.user.token, this.state.uniqueId, creditReviewAuthorization).then(res => {
                this.props.updateCreditReviewAuthorization(res.data as ICreditReviewAuthorization);
                successHandler(res, this, translations.CREDIT_REVIEW_AUTHORIZATION)
            }).catch((error: AxiosError) => {
                catchError(error, this)
            });

        }
    }
    public handleDelete() {
        if (confirm(translations.ARE_YOU_SURE)) {

            this.setState({ loading: true });
            deleteCRA(this.props.user.token, this.props.creditReviewAuthorizationId).then(res => {
                this.props.changeSection(0);
                this.props.deleteCreditReviewAuthorization(this.props.creditReviewAuthorizationId);
            }).catch((err: AxiosError) => {
                catchError(err, this)
            })
        }
    }
    public handleDialogClose() {
        this.setState({ dialog: false });
    }
    public render() {
        const { classes } = this.props;

        return (
            <form onSubmit={this.handleSubmit} className={classes.root}>
                <AlertDialog
                    open={this.state.dialog}
                    message={this.state.errorMessage}
                    title={this.state.dialogTitle}
                    handleClose={this.handleDialogClose}
                />
                <Paper className={classes.paper}>

                    <FileUploadSelectInput
                        contractId={this.state.uniqueId}
                        url={this.state.CRA}
                        description='CRA' 
                    />
                    <Typography variant="h3">{translations.CREDIT_REVIEW_AUTHORIZATION}</Typography>
                    {this.state.loading ?
                        <Loading message={translations.LOADING} />
                        : <div>
                            <Button className={`${classes.greenButton} generate-print-document`} onClick={this.generateFile} style={{ marginRight: 10, marginTop: 25 }}>
                                <DocumentIcon />
                                &nbsp;
                                {translations.GENERATE_DOCUMENT}
                            </Button>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="status" shrink={true}>{translations.STATUS}</InputLabel>
                                <Select
                                    value={this.state.status}
                                    onChange={this.handleSelectChange('status')}
                                    required={true}
                                    inputProps={{
                                        id: 'status',
                                        name: 'status',
                                    }}

                                >
                                    <MenuItem value="pending_signature">{translations.PENDING_SIGNATURE}</MenuItem>
                                    <MenuItem value="completed">{translations.COMPLETED}</MenuItem>
                                    <MenuItem value="archived">{translations.ARCHIVED}</MenuItem>
                                </Select>
                            </FormControl>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    label={translations.TITLE}
                                    className={classes.textField}
                                    value={this.state.title}
                                    onChange={this.handleChange('title')}
                                    required={true}
                                    fullWidth={true}
                                    error={this.state.titleError !== ''}
                                    helperText={this.state.titleError}
                                    InputLabelProps={{ style: styles.checkboxLabel }}
                                />
                                <TextField
                                    label={translations.CONTRACT_DATE}
                                    className={classes.textField}
                                    style={{ marginLeft: 20 }}
                                    value={this.state.contractDate}
                                    type="date"
                                    onChange={this.handleChange('contractDate')}
                                    required={true}
                                    error={this.state.contractDateError !== ''}
                                    helperText={this.state.contractDateError}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.checkboxLabel
                                    }}
                                />
                            </div>
                            <br />
                            <FormControlLabel
                                control={<GreenSwitch checked={this.state.newClient} onChange={this.handleChanges} name="newClient" />}
                                label="¿Cliente nuevo?"
                            />
                            <br />
                            <br />
                            {this.state.newClient ?
                                <div>
                                    <Grid container={true} spacing={2}>
                                        <Grid item={true} xs={4}>
                                            <TextField
                                                label={translations.FULL_NAME}
                                                value={this.state.fullName}
                                                onChange={this.handleChange('fullName')}
                                                required={true}
                                                fullWidth={true}
                                                InputLabelProps={{ style: styles.checkboxLabel }}
                                            />
                                        </Grid>
                                        <Grid item={true} xs={4}>
                                            <FormControl fullWidth={true}>
                                                <InputLabel htmlFor="idType" shrink={true}>
                                                    {translations.IDENTIFICATION_TYPE}
                                                </InputLabel>
                                                <Select
                                                    value={this.state.idType}
                                                    onChange={this.handleSelectChange("idType")}
                                                    required={true}
                                                    error={this.state.idTypeError !== ""}
                                                    inputProps={{
                                                        id: "idType",
                                                        name: "idType",
                                                    }}
                                                >
                                                    {IDENTIFICATION_TYPE.map((opt, indx) => {
                                                        return (
                                                            <MenuItem value={opt.value} key={indx}>
                                                                {opt.label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={4}>
                                            <TextField
                                                required={true}
                                                value={this.state.idNumber || ''}
                                                onChange={this.handleChange('idNumber')}
                                                label={translations.IDENTIFICATION_NUMBER}
                                                placeholder={translations.IDENTIFICATION_NUMBER}
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth={true}
                                                InputProps={this.getIdentificationInput(
                                                    this.state.idType
                                                )} />
                                        </Grid>
                                        <Grid item={true} xs={4}>
                                            <TextField
                                                required={true}
                                                value={this.state.cellPhone}
                                                onChange={this.handleChange('cellPhone')}
                                                label={translations.CELL_PHONE}
                                                placeholder={translations.CELL_PHONE}
                                                fullWidth={true}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{ inputComponent: phoneMaskInput }} />
                                        </Grid>
                                        <Grid item={true} xs={4}>
                                            <TextField
                                                required={true}
                                                value={this.state.email}
                                                onChange={this.handleChange('email')}
                                                label={translations.EMAIL}
                                                fullWidth={true}
                                                placeholder={translations.EMAIL}
                                                InputLabelProps={{ shrink: true }}
                                                type="email" />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <h3>{translations.ADDRESS}</h3>
                                    <Address
                                        handleSelect={this.handleSelect}
                                        country={this.state.country}
                                        countryError={""}
                                        reference={this.state.reference}
                                        referenceError={""}
                                        state={this.state.state}
                                        stateError={""}
                                        city={this.state.city}
                                        cityError={""}
                                        region={this.state.region}
                                        regionError={""}
                                        sector={this.state.sector}
                                        sectorError={""}
                                        handleChange={this.handleChangeAddress}
                                        max={true}
                                    />
                                    <br />
                                </div> : <div className={classes.half}>
                                    <Typography variant="h6" className={classes.checkboxLabel}>{translations.DEBTOR}</Typography>
                                    <ParticipantSelectInput
                                        selectedParticipantId={this.state.debtor}
                                        addParticipant={this.handleAddClient}
                                        deleteParticipant={this.handleDeleteClient}

                                    />
                                    <p className={classes.errorInput}>{this.state.debtorError}</p>
                                </div>
                            }
                            <br />
                            <div className={classes.controls}>
                                <Button variant="contained" onClick={this.handleDelete} className={classes.deleteButton}>
                                    <DeleteIcon />
                                    &nbsp;
                                    {translations.DELETE}
                                </Button>
                                <Button type="submit" variant="contained" className={classes.greenButton} style={{ marginLeft: 10 }}>
                                    <SaveIcon />
                                    &nbsp;
                                    {translations.SAVE}
                                </Button>
                            </div>
                        </div>
                    }
                </Paper>
            </form>
        );
    }
}

function mapStateToProps(state: IState) {
    return {
        creditReviewAuthorizations: state.creditReviewAuthorizations,
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps, { deleteCreditReviewAuthorization, fetchCreditReviewAuthorization, updateCreditReviewAuthorization })(CreditReviewAuthorizationView)
);