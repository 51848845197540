import { DEBT_CAPACITY_TYPE } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case DEBT_CAPACITY_TYPE.FETCH_ALL:
            return action.payload || [];
        case DEBT_CAPACITY_TYPE.FETCH:
            return state.map(debtCapacity => (action.payload !== undefined && debtCapacity.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : debtCapacity);
        default:
            return state;
    }
}