import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createAffidavitOfFunds } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import { createAffidavitOfFunds as create } from "../../../utils/axios";
import CompanyRepresentativeSelectInput from "../../CompanyRepresentative/SelectInput";
import InstitutionSelectInput from "../../Institutions/InstitutionsSelectInput";
import ExtraPaymentTable from "../../../components/Ticket/ExtraPayTable/ExtraPaymentTable";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

interface IFormProps {
  classes: {
    attachmentIcon: any;
    checkboxLabel: any;
    controls: any;
    deleteButton: any;
    errorInput: any;
    formControl: any;
    greenButton: any;
    half: any;
    paper: any;
    root: any;
    textField: any;
    title: any;
  };
  user: IUser;
  changeSection(sectionNumber: number): void;
  createAffidavitOfFunds(
    affidavitOfFunds: IAffidavitOfFunds
  ): IAction<IAffidavitOfFunds[]>;
}
interface IFormState {
  [key: string]: string | number | boolean | string[] | undefined;

  editIdx: number;

  sources: string[]; // done
  sourcesError: string;

  contractDate: string;
  contractDateError: string;

  dialog: boolean;
  dialogTitle: string;
  title: string;
  errorMessage: string;
  debtor: string[];
  debtorError: string;
  loading: boolean;

  city: string;
  cityError: string;

  institution: string[];
  institutionError: string;
}

class Form extends React.Component<IFormProps, IFormState> {
  public state: IFormState = {
    editIdx: -1,
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    debtor: [],
    debtorError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    title: "",
    titleError: "",
    institution: [],
    institutionError: "",
    city: "",
    cityError: "",
    sources: [], // done
    sourcesError: "",
  };

  constructor(props: IFormProps) {
    super(props);

    // debtor
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // institution
    this.handleAddInstitution = this.handleAddInstitution.bind(this);
    this.handleDeleteInstitution = this.handleDeleteInstitution.bind(this);

    // core event
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleStartEdit = this.handleStartEdit.bind(this);
    this.handleStopEdit = this.handleStopEdit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeTableData = this.handleChangeTableData.bind(this);
  }

  public handleChangeTableData = (i: any, x: any) => {
    this.setState((state) => ({
      sources: state.sources.map((row: any, j: any) => (j === i ? x : row)),
    }));
    this.handleStopEdit();
  };

  public handleStopEdit = () => {
    this.setState({ editIdx: -1 });
  };

  public handleRemove = (i: any) => {
    this.setState({
      sources: this.state.sources.filter((row: any, j: any) => j !== i),
    });
  };

  public handleSave(data: any) {
    this.setState({
      sources: data,
    });
  }

  public handleStartEdit = (i: any) => {
    this.setState({ editIdx: i });
  };
  public handleAddInstitution(id: string) {
    this.setState({ institution: [id] });
  }
  public handleDeleteInstitution(id: string) {
    this.setState({ institution: [] });
  }
  public handleAddClient(id: any) {
    this.setState({ debtor: [id] });
  }
  public handleDeleteClient(id: any) {
    this.setState({ debtor: [] });
  }
  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleCheckBoxChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  public handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);
    const { state } = this;
    if (!this.state.city) {
      this.setState({ cityError: "Este campo es requerido" });
    } else if (!state.institution.length) {
      this.setState({ institutionError: "Este campo es requerido" });
    } else if (!state.debtor.length) {
      this.setState({ debtorError: "Este campo es requerido" });
    } else if (!state.sources.length) {
      this.setState({ sourcesError: "Este campo es requerido" });
    } else {
      const contract: IAffidavitOfFunds = {
        contractDate: state.contractDate,
        companyRepresentative: state.debtor[0] || "",
        title: state.title,
        city: state.city || "",
        sources: state.sources || [],
        institution: state.institution[0] || "",
      };

      this.setState({ loading: true });

      create(this.props.user.token, contract)
        .then((res) => {
          this.props.createAffidavitOfFunds(
            res.data.results ? res.data.results : res.data
          );
          successHandler(
            res,
            this,
            "Declaración de procedencia de Fondos Persona Juridica"
          );
          this.handleReset();
        })
        .catch((err: any) => {
          catchError(err, this);
        });
    }
  }
  public handleReset() {
    this.setState({
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      debtor: [],
      debtorError: "",
      loading: false,
      title: "",
      titleError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    const headersAR = [
      { name: "Descripción", prop: "description", type: "text" },
    ];
    return (
      <form
        name="MA"
        noValidate={true}
        onSubmit={this.handleSubmit}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            Declaración de procedencia de Fondos Persona Juridica
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
                <TextField
                  label={translations.CITY}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.city}
                  onChange={this.handleChange("city")}
                  required={true}
                  error={this.state.cityError !== ""}
                  helperText={this.state.cityError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.COMPANY_REPRESENTATIVE}
                </Typography>
                <CompanyRepresentativeSelectInput
                  selectedCompanyRepresentativeId={this.state.debtor}
                  addCompanyRepresentative={this.handleAddClient}
                  deleteCompanyRepresentative={this.handleDeleteClient}
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>

              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.INSTITUTION}
                </Typography>
                <InstitutionSelectInput
                  selectedInstitutionId={this.state.institution}
                  addInstitution={this.handleAddInstitution}
                  deleteInstitution={this.handleDeleteInstitution}
                />
                <p className={classes.errorInput}>
                  {this.state.institutionError}
                </p>
              </div>
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  Fuentes
                </Typography>
                <ExtraPaymentTable
                  actions={true}
                  create={true}
                  data={this.state.sources}
                  editIdx={this.state.editIdx}
                  handleChange={this.handleChangeTableData}
                  handleRemove={this.handleRemove}
                  handleSave={this.handleSave}
                  handleStartEdit={this.handleStartEdit}
                  handleStopEdit={this.handleStopEdit}
                  header={headersAR}
                  tableLimit={5}
                />
                <p className={classes.errorInput}>{this.state.sourcesError}</p>
              </div>

              <br />
              <br />
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createAffidavitOfFunds })(Form)
);
