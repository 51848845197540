import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, MenuItem, Select } from "@material-ui/core";
import { ClearAll } from "@material-ui/icons";
import {
  DateField,
  TextFieldComponent,
} from "./../../../components/SharedComponents/Fields";
import { dateFormat } from "./../../../components/SharedComponents/CustomHooks";
import { personTypes } from "src/utils";
import { fetchNationalities } from "src/utils/axios";
import Loading from "../../../components/SharedComponents/LoadingScreen";

function FiltersHeader(props) {
  return (
    <Grid container justify="space-between" alignItems="center">
      <Typography
        variant={props.typeVariant}
        className={props.typeClass}
        style={{ fontWeight: "bold" }}
      >
        {props.filtersTitle}
      </Typography>
    </Grid>
  );
}

function Filters(props) {
  const [openFilter, setOpenFilter] = useState(true);
  const [fullName, setFullName] = useState("");
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [date, setDate] = useState({ start: null, end: null });
  const [country, setCountry] = useState("");
  const [personType, setPersonType] = useState("");
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const getNationalities = () => {
    setLoading(true);
    fetchNationalities({ token: props.token })
      .then((res) => {
        setCountries(res.data.results ? res.data.results : res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const clearFilters = () => {
    setFullName("");
    setIdentificationNumber("");
    setPersonType("");
    setCountry("");
    setDate({ start: null, end: null });
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };
  const handleDateChange = (type, dateInfo, value) => {
    setDate({ ...date, [type]: dateInfo });
  };

  // const getFiltersOptions = (option => {
  //     return (
  //         (option ? option : [])
  //     )
  // })

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleIdentificationChange = (event) => {
    setIdentificationNumber(event.target.value);
  };

  const handlePersonTypeChange = (event, value) => {
    return (event) => {
      setPersonType(event.target.value);
    };
  };

  const handleCountryChange = () => {
    return (event) => {
      setCountry(event.target.value);
    };
  };

  const applyFilters = () => {
    const fields = {
      fullName: fullName,
      personType: personType,
      country: country,
      identificationNumber: identificationNumber,
      creationDateFrom: dateFormat(date.start),
      creationDateTo: dateFormat(date.end),
    };
    Object.keys(fields).forEach((key) => {
      if (!fields[key]) {
        delete fields[key];
      }
    });
    props.applyFilters(fields);
  };
  useEffect(() => {
    getNationalities();
  }, []);
  return (
    <Grid container className="filters-container">
      <Loading open={loading} />
      <FiltersHeader
        typeVariant="subtitle1"
        typeClass="section-header"
        filtersTitle="Filtros"
        btnClass={`filter-btn-close ${props.hideClass}`}
        iconClass="filter-icon-close"
        toggleFilter={toggleFilter}
        openFilter={openFilter}
        disableClose={props.disableCloseBtn}
      />
      <Grid
        container
        className={openFilter ? "filter-content" : "filter-content collided"}
      >
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2}>
            <TextFieldComponent
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Nombre completo"
              fieldID="filter-field-reference"
              fieldClass="fields"
              fieldVariant="outlined"
              placeholder="Nombre completo"
              margin="dense"
              fullWidth
              value={fullName}
              onChange={handleFullNameChange}
            />
            <TextFieldComponent
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Identificacion"
              fieldID="filter-field-reference"
              fieldClass="fields"
              fieldVariant="outlined"
              placeholder="Identificacion"
              margin="dense"
              fullWidth
              value={identificationNumber}
              onChange={handleIdentificationChange}
            />
            <div style={{ width: "32%", marginLeft: 8 }}>
              <Typography style={{ marginTop: 17 }}>Tipo de persona</Typography>
              <Select
                id="personType"
                value={personType}
                style={{ width: "99%", backgroundColor: "#ffffff", height: 39 }}
                placeholder={"Tipo de persona"}
                required={true}
                variant="outlined"
                onChange={handlePersonTypeChange("personType")}
              >
                {personTypes.map((opt, indx) => {
                  return (
                    <MenuItem value={opt.value} key={indx}>
                      {opt.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </Grid>

          <Grid container direction="row" spacing={2}>
            <div style={{ width: "32%", marginLeft: 8 }}>
              <Typography style={{ marginTop: 17 }}>Pais</Typography>
              <Select
                id="country"
                value={country}
                style={{ width: "99%", backgroundColor: "#ffffff", height: 39 }}
                placeholder={"Pais"}
                required={true}
                variant="outlined"
                onChange={handleCountryChange("country")}
              >
                {countries.map((opt, indx) => {
                  return (
                    <MenuItem value={opt.name} key={indx}>
                      {opt.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <DateField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Fecha de creacion desde"
              dateFieldID="filter-date-start"
              fullWidth={true}
              dateFieldClass="date-field"
              inputVariant="outlined"
              dateFieldLabel="Desde"
              format="dd/MM/yyyy"
              value={date.start}
              dateType="start"
              handleChange={handleDateChange.bind(this, "start")}
            />
            <DateField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Fecha de creacion hasta"
              dateFieldID="filter-date-end"
              fullWidth={true}
              dateFieldClass="date-field"
              inputVariant="outlined"
              dateFieldLabel="Hasta"
              format="dd/MM/yyyy"
              value={date.end}
              dateType="end"
              handleChange={handleDateChange.bind(this, "end")}
            />
          </Grid>
        </Grid>
        {/* FILTERS CONTENT APPLY BUTTON */}
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ marginTop: "35px" }}
        >
          <Button
            disableElevation
            className="filter-clear-btn"
            onClick={clearFilters.bind(this, false)}
          >
            limpiar
            <ClearAll style={{ marginLeft: 8, fontSize: "19px" }} />
          </Button>

          <Button
            disableElevation
            className="filter-apply-btn"
            onClick={applyFilters}
          >
            aplicar filtros
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
export { Filters };
