import React, { useState, useEffect } from 'react';
import {
    Accordion, AccordionSummary, Typography, Button, CircularProgress,
    AccordionDetails, Grid, Card, CardHeader, CardContent, TextField,
    IconButton
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ExpandMore, Search, Clear } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { PlainClientsTable } from './../Tables';
import { ConfirmDialog, BotInitConfigDialog } from './../Dialogs';
import { CheckboxField, TextFieldComponent } from './../../../components/SharedComponents/Fields';

const AccordionStyled = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      overflowAnchor: 'none !important',
      width:'100%',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
})(Accordion);

const AccordionSummaryStyled = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
})(AccordionSummary);

class BotChargeAction extends React.Component {

    state={
        botState: false,
        openDialog: false,
        actionWord: 'Ejecutará',
        // disableChargeButton: this.props.clientSelection && this.props.data.length <= 0,
    }
    botState=false;

    getChargeGroupTitle = () => {
        let chargeGroupTitle = 'Los vencimientos seleccionados';
        if(this.props.data.responsible){
            chargeGroupTitle = this.props.data.responsible;
        }
        if(this.props.data.work){
            chargeGroupTitle = this.props.data.work;
        }
        if(this.props.data.bank){
            chargeGroupTitle = this.props.data.bank;
        }
        return chargeGroupTitle;
    }

    renderContentBotConfirm = () => {
        return(
            <span>¿Seguro que desea detener el bot?</span>
        );
    }

    handleDialog = (dialog, e) =>{
        e.stopPropagation();
        this.setState({
            openDialog: dialog,
        });
    }

    handleConfirm = (botInitConfig, e) => {
        this.botState = !this.state.botState;
        this.handleDialog(false, e);
        if(this.botState) {
            this.props.executeBotChargeAction(this.props.data, 'execute', this.props.group, botInitConfig, this.props.responsible);
        }else {
            this.props.executeBotChargeAction(this.props.data, 'stop', this.props.group, botInitConfig, this.props.responsible);
        }
        this.setState({
            botState: this.botState,
            actionWord: !this.botState ? 'Ejecutará' : 'Detendrá',
        });
    }

    render() {
        return(
            <div>
            {
                !this.state.botState ?
                    this.props.initBtn ?
                        <label onClick={this.handleDialog.bind(this, 'start-bot')}>
                            {this.props.initBtn}
                        </label>
                        :
                        <Button
                            onClick={this.handleDialog.bind(this, 'start-bot')}
                            variant="contained"
                            disableElevation
                            size="small"
                            style={{
                                backgroundColor:'#FFFFFF',
                                border: this.props.data.length < 1 && this.props.group === 'all' ? '1px solid #BDBDBD' : '1px solid #E0E0E0',
                                color: this.props.data.length < 1 && this.props.group === 'all' ? '#757575' : '#43A047',
                                fontSize:11, maxHeight:28, marginLeft: this.props.clientSelection ? 0 : 10
                            }}
                            disabled={(this.props.data.length < 1 && this.props.group === 'all') || this.props.botRunning}
                        >
                        iniciar bot
                        </Button>
                    :
                    <Button
                        onClick={this.handleDialog.bind(this, 'stop-bot')}
                        variant="contained"
                        disableElevation
                        size="small"
                        style={{
                            backgroundColor:'#FFFFFF', border:'1px solid #E53935',
                            color:'#D32F2F', fontSize:11, maxHeight:28,
                            marginLeft: this.props.clientSelection ? 0 : 10
                        }}
                    >
                        <CircularProgress style={{color: 'black', marginRight:'5px'}} size={15} thickness={3}/>
                        detener bot
                    </Button>
            }
                <BotInitConfigDialog
                    type={this.props.initBtn && 'login'}
                    open={this.state.openDialog === 'start-bot'}
                    handleToggleDialog={this.handleDialog}
                    handleConfirm={this.handleConfirm}
                    stopPropagation={(e) => {e.stopPropagation()}}
                    tableData={this.props.data}
                    infrastructures={this.props.infrastructures}
                />
                <ConfirmDialog
                    open={this.state.openDialog === 'stop-bot'}
                    handleToggle={this.handleDialog.bind(this, undefined)}
                    dialogTitle="Confirmación"
                    dialogContent={this.renderContentBotConfirm()}
                    dialogConfirmText="Continuar"
                    dialogCancelText="Cancelar"
                    handleConfirm={this.handleConfirm.bind(this, undefined)}
                    stopPropagation={(e) => {e.stopPropagation()}}
                />
            </div>
        );
    }
}

function DataLayerHeader(props) {
    return(
        <AccordionSummaryStyled
            expandIcon={props.icon}
            aria-controls="panel1a-content"
        >
            <Typography
                variant={props.typeVariant} className={props.typeClass}
            >
                {props.title}
                <Typography variant="caption" style={{color:'#757575'}}>&nbsp;Gestor de cobros</Typography>
            </Typography>

            <BotChargeAction
                data={props.data}
                responsible={props.title}
                title={props.title}
                executeBotChargeAction={props.executeBotChargeAction}
                group={props.group}
                botRunning={props.botRunning}
                handleToggleDialog={props.handleToggleDialog}
            />
        </AccordionSummaryStyled>
    );
}

function DataLayerBank(props) {
    return(
        <AccordionStyled
            expanded={props.expandBank === props.data.bank}
            onChange={props.handleExpandBank.bind(this, props.data.bank)}
            TransitionProps={props.TransitionProps}
        >
            <AccordionSummaryStyled
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
            >
                <Typography variant="subtitle1">{props.data.bank}</Typography>

                <BotChargeAction
                    data={props.data}
                    responsible={props.responsible}
                    executeBotChargeAction={props.executeBotChargeAction}
                    handleToggleDialog={props.handleToggleDialog}
                    botRunning={props.botRunning}
                    group="bank"
                />

            </AccordionSummaryStyled>
            <AccordionDetails style={{paddingTop: '16px'}}>
                <PlainClientsTable
                    data={props.data.expiries}
                    handleToggleDialog={props.handleToggleDialog}
                    handleToogleConfirm={props.handleToogleConfirm}
                    order={'view'}
                    specificOrder={props.specificOrder}
                    token={props.token}
                    setLoading={props.setLoading}
                    executeBotChargeAction={props.executeBotChargeAction}
                    botRunning={props.botRunning}
                />
            </AccordionDetails>
        </AccordionStyled>
    );
}

function DataLayerWork(props) {

    return(
        <Grid
            container
            direction="column"
            style={{width:'100%', border: '1px solid #EEEEEE', borderRadius: 5, backgroundColor:'#FCFCFC', padding:'24px 24px'}}
            className={props.containerClass}
        >

            <Grid container direction="row" alignItems="center" style={{marginBottom:'1rem'}}>
                <Typography
                    variant='subtitle1'
                    style={{fontWeight:'bold', color: '#424242'}}
                >
                    {props.data.work}
                    <Typography variant="caption" style={{color:'#757575'}}>&nbsp;Institución de trabajo</Typography>
                </Typography>

                <BotChargeAction
                    data={props.data}
                    responsible={props.responsible}
                    executeBotChargeAction={props.executeBotChargeAction}
                    handleToggleDialog={props.handleToggleDialog}
                    botRunning={props.botRunning}
                    group="work"
                />

            </Grid>

            <Grid container justifyContent="flex-start">
                {
                    props.data.banks.map((item, index) => {
                        return(
                            <DataLayerBank
                                data={item}
                                responsible={props.responsible}
                                handleExpandBank={props.handleExpandBank}
                                expandBank={props.expandBank}
                                key={item.bank+"-"+index}
                                handleToggleDialog={props.handleToggleDialog}
                                handleToogleConfirm={props.handleToogleConfirm}
                                executeBotChargeAction={props.executeBotChargeAction}
                                // // toggleExpiryDialog={props.toggleExpiryDialog}
                                TransitionProps={props.transition}
                                specificOrder={props.specificOrder}
                                token={props.token}
                                setLoading={props.setLoading}
                            />
                        );
                    })
                }
            </Grid>
        </Grid>
    );
}

class DataLayerResponsible extends React.Component {

    constructor(props) {
        super(props);
        this.workRef = [];
    }

    state={
        currentPageItem: this.props.data.works.find(item => item),
        expandBank: this.props.data.works.find(item => item).banks[0].bank,
        transition: undefined,
    }
    workElements = undefined;
    work = undefined;

    handlePageChange = (event, value) => {
        this.workRef.forEach(item => {
            if(!item.classList.contains('work-hidden')){
                item.classList.add('work-hidden');
            }
            if(item.getAttribute("id") === (value - 1).toString()) {
                item.classList.remove('work-hidden');
                this.setState({
                    expandBank: item.getAttribute("bank"),
                    transition: {timeout: 0},
                });
            }
        });
    }

    handleExpandBank = (bank, event, isExpanded) => {
        this.setState({
            expandBank: bank,
            transition: undefined,
        });
    }


    render() {
        return(
            <AccordionStyled
                expanded={this.props.expandParticipant === this.props.data.responsible}
                onChange={this.props.handleExpandParticipant.bind(this, this.props.data.responsible)}
                square
                className="responsible-container"
            >
                <DataLayerHeader
                    icon={<ExpandMore />}
                    typeVariant="subtitle1"
                    typeClass="responsible-header-text"
                    data={this.props.data}
                    title={this.props.data.responsible}
                    executeBotChargeAction={this.props.executeBotChargeAction}
                    handleToggleDialog={this.props.handleToggleDialog}
                    group="responsible"
                />
                <AccordionDetails style={{padding:'2.5% 0 5% 0'}}>
                    <Grid container direction="column" justifyContent="center" style={{width:'100%', padding: '0 2%'}}>
                        <Pagination
                            count={this.props.data.works.length}
                            onChange={this.handlePageChange}
                            style={{marginBottom:'2%', width:'100%', display:'flex', justifyContent:'center'}}
                            className="nav-pagination"
                            shape="round"
                            size='medium'
                        />
                        {
                            this.props.data.works.map((item, index) => {
                                return(
                                    <div
                                        ref={workRef=>this.workRef[index] = workRef}
                                        key={item.work + "-" + index} id={index}
                                        className={
                                            index === 0 ? 'work-'+this.props.data.responsible
                                            : 'work-hidden work-'+this.props.data.responsible
                                        }
                                        style={{width:'100%'}}
                                        bank={item.banks[0].bank}
                                    >
                                        <DataLayerWork
                                            data={item}
                                            responsible={this.props.data.responsible}
                                            handleExpandBank={this.handleExpandBank}
                                            expandBank={this.state.expandBank}
                                            key={item.work+"-"+index}
                                            handleToggleDialog={this.props.handleToggleDialog}
                                            handleToogleConfirm={this.props.handleToogleConfirm}
                                            executeBotChargeAction={this.props.executeBotChargeAction}
                                            // // toggleExpiryDialog={this.props.toggleExpiryDialog}
                                            transition={this.state.transition}
                                            specificOrder={this.props.specificOrder}
                                            token={this.props.token}
                                            setLoading={this.props.setLoading}
                                        />
                                    </div>
                                );
                            })
                        }
                    </Grid>
                </AccordionDetails>
            </AccordionStyled>
        );
    }
}

function DataLayer(props){

    const [expandParticipant, setExpandParticipant] = useState(null);
    const [group, setGroupChange] = useState(false);
    const [groupedData, setGroupedData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [search, setSearch] = useState('');

    const handleExpandParticipant = (panel, event, isExpanded) => {
        setExpandParticipant(isExpanded ? panel : false);
    }
    const groupOrder = (order, options) => {
        const data = order;
        const responsibles = [...new Set(data.map(item => item.assignee))];
        const works = responsibles.map(r => {
            return {
                responsible: r,
                works: [...new Set(data.filter(item => item.assignee === r).map(item => item.work))],
            }
        });
        const banks = works.map(w => {
            return {
                responsible: w.responsible,
                works: w.works.map(ww => {
                    return {
                        work: ww,
                        banks: [...new Set(data.filter(item => item.assignee === w.responsible && item.work === ww).map(b => b.bank))]
                    }
                })
            }
        })
        const expiries = banks.map(r => {
            return {
                responsible: r.responsible,
                works: r.works.map(w => {
                    return {
                        work: w.work,
                        banks: w.banks.map(b => {
                            return {
                                work: w.work,
                                bank: b,
                                expiries: data.filter(item => item.assignee === r.responsible && item.work === w.work && item.bank === b).map(e => e),
                            }
                        })
                    }
                })
            }
        })
        setGroupedData(expiries);
        if(options && options.preventCheck) { return }
        setGroupChange(!group);
    }

    const handleGroupChange = () => {
        if(group) {
            setGroupedData([]);
            setGroupChange(!group);
        }else {
            groupOrder(tableData);
        }
    }

    useEffect(() => {
        setGroupChange(false);
    }, [props.specificOrder]);

    useEffect(() => {
        setTableData(props.data);
    }, [props.data])

    const botActionHeader = () => {
        return(
            <div>
                {/* <Grid container alignItems="flex-start" style={{marginBottom:'15px'}}> */}
                <Grid container justifyContent="space-between" direction="row" style={{marginBottom:'15px'}}>
                    <Typography variant='subtitle1' style={{fontWeight:'bold', color: '#424242'}}>
                        Vencimientos
                    </Typography>
                    <CheckboxField
                        xs={5}
                        check={group}
                        handleChange={handleGroupChange}
                        color="primary"
                        textColor="#43A047"
                        label="AGRUPAR VISTA"
                        justifyContent="flex-end"
                        disabled={props.botQuantity > 0 || tableData.length < 1}
                    />
                </Grid>
                <Grid container alignItems="flex-start">
                    <Grid item container xs={9} justifyContent="flex-start">
                        {
                            !group ?
                                <Grid container direction="row" alignItems="center">
                                    <BotChargeAction
                                        data={tableData}
                                        responsible={tableData.length > 0 && tableData[0].assignee}
                                        handleToggleDialog={props.handleToggleDialog}
                                        executeBotChargeAction={props.executeBotChargeAction}
                                        clientSelection={true}
                                        order={props.specificOrder}
                                        botRunning={props.botRunning}
                                        group="all"
                                        infrastructures={props.infrastructures}
                                    />
                                    {
                                        props.botRunningMode &&
                                        <Typography variant='subtitle1' style={{fontWeight:'bold', color: '#424242', marginLeft:'1rem'}}>
                                            Modo de ejecución:
                                            <Typography variant='overline' style={{fontWeight:'bold', color: '#424242'}}>
                                                {" " + props.botRunningMode}
                                            </Typography>
                                        </Typography>
                                    }

                                </Grid>
                            : props.botRunningMode &&
                                <Typography variant='subtitle1' style={{fontWeight:'bold', color: '#424242', marginLeft:'1rem'}}>
                                    Modo de ejecución:
                                    <Typography variant='overline' style={{fontWeight:'bold', color: '#424242'}}>
                                        {" " + props.botRunningMode}
                                    </Typography>
                                </Typography>

                        }
                    </Grid>
                </Grid>
            </div>
        )
    }
    return(
        <Card style={{marginTop:'3%'}}>
            <CardHeader
                title={botActionHeader()}
            />
                <CardContent>
                {
                    !group ?
                        <PlainClientsTable
                            // data={tableData.expiries}
                            data={tableData}
                            handleToggleDialog={props.handleToggleDialog}
                            handleToggleConfirmDialog={props.handleToogleConfirm}
                            // handleToggleExpiryDialog={props.toggleExpiryDialog}
                            order={'view'}
                            specificOrder={props.specificOrder}
                            token={props.token}
                            setLoading={props.setLoading}
                            executeBotChargeAction={props.executeBotChargeAction}
                            botRunning={props.botRunning}
                        />
                    :
                        groupedData.map(item => {
                            return (
                                <DataLayerResponsible
                                    data={item}
                                    key={item.responsible}
                                    handleExpandParticipant={handleExpandParticipant}
                                    expandParticipant={expandParticipant}
                                    handleToggleDialog={props.handleToggleDialog}
                                    handleToogleConfirm={props.handleToogleConfirm}
                                    executeBotChargeAction={props.executeBotChargeAction}
                                    botRunning={props.botRunning}
                                    specificOrder={props.specificOrder}
                                    token={props.token}
                                    setLoading={props.setLoading}
                                    // // toggleExpiryDialog={props.toggleExpiryDialog}
                                />
                            );
                        })
                }
                </CardContent>
        </Card>
    );
}

export { DataLayer, BotChargeAction }