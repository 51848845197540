import axios from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';
import AlertDialog from 'src/components/AlertDialog';
import { successHandler, translations } from 'src/utils';

import { DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { green, grey } from '@material-ui/core/colors';
import { createStyles, withStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

import { deleteFileAttachment, fetchFileAttachment } from '../../../actions';
import { API_KEY } from '../../../utils/axios';

const styles:any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200]
    },
    backgroundColor: green[500],
    color: "white"
  },
  closeButton: {
    color: grey[500],
    position: 'absolute',
    right: 1,
    top: 1,
  },
  formStyle: {
    padding: 10,
    textAlign: 'center',
  },
  greenButton: {
    backgroundColor: '#4caf50',
    color: 'white'
  },
  root: {
    margin: 0,
    padding: 2,
  },
  title: {
    padding: 10,
    textAlign: 'left',
    top: 1
  },
  uploadButton: {
    backgroundColor: '#4caf50',
    color: 'white',
    margin: 11,
    width: 248
  },
  uploadIcon: {
    height: 50,
    width: 50
  }
});

interface IFileUploadFormProps {
  classes: {
    button: string;
    closeButton: string;
    formStyle: string;
    greenButton: string;
    root: string;
    title: string;
    uploadButton: string;
    uploadIcon: string;
  }
  url: string;

  contractId: string;
  user: IUser;
  deleteFileAttachment(uniqueId: string): IAction<IFileAttachment[]>;
  fetchFileAttachment(fileAttachment: IFileAttachment): IAction<IFileAttachment[]>;
}
interface IFileUploadFormState {
  [key: string]: string | number | boolean | string[] | IFileAttachment[];

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  fileName: string;
  formDialog: boolean;
  loading: boolean;
  open: boolean;
  selectedFile: string;
  uniqueId: string;
  uploadPercentage: number;
}

class FileUpload extends React.Component<IFileUploadFormProps, IFileUploadFormState>{
  public state: IFileUploadFormState = {

    dialog: false,
    dialogTitle: '',
    errorMessage: '',
    fileName: '',
    formDialog: false,
    loading: false,
    open: false,
    selectedFile: '',
    uniqueId: '',
    uploadPercentage: 0
  };
  constructor(props: any) {
    super(props);


    this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
    this.fileUploadHandler = this.fileUploadHandler.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.switchFormDialog = this.switchFormDialog.bind(this);
  }
  public switchFormDialog() {
    this.setState({ formDialog: !this.state.formDialog });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public fileSelectedHandler = (event: any) => {
    this.setState({ 
      selectedFile: event.target.files[0],
      fileName: event.target.files[0].name 
    })
  }


  public fileUploadHandler = () => {
    const fd = new FormData;
    fd.append('file', this.state.selectedFile);
    fd.append('name', this.state.fileName);
    axios({
      data: fd,
      headers: {
        Authorization: 'Bearer ' + this.props.user.token,
        'Content-Type': 'multipart/form-data'
      },
      method: 'post',
      onUploadProgress: progressEvent => {
        this.setState({ uploadPercentage: Math.round((progressEvent.loaded * 100) / progressEvent.total) })
      },
      responseType: "blob",
      url: `${API_KEY}/${this.props.url}`,
    }).then(res => {
      successHandler(res, this, translations.FILE_UPLOADED)
    }).catch(err => {
      this.setState({
        dialog: true,
        dialogTitle: 'Error',
        errorMessage: 'Debe seleccionar un archivo primero',
      })
    })
  }
  public render() {
    const { classes } = this.props;
    return (
      <div>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <DialogTitle className={classes.title}>
          Archivos Adjuntos
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <div>
            <form className={classes.formStyle} method='post' encType='multipart/form-data' >
              <GetAppIcon className={classes.uploadIcon} />
              <br />
              <input
                type='file'
                id='file'
                onChange={this.fileSelectedHandler} />
            </form>
          </div>
        </DialogContent>
        <div >
          <LinearProgress variant="determinate" value={this.state.uploadPercentage} />
        </div>
        <Button className={classes.uploadButton} onClick={this.fileUploadHandler}>
          Cargar Archivos
        </Button>
      </div>
    )
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,

  }
}
export default withStyles(styles)(
  connect(mapStateToProps, { deleteFileAttachment, fetchFileAttachment })(FileUpload)
);
