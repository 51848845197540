import * as React from 'react';
import { connect } from 'react-redux';
import { translations } from 'src/utils';

import { List, ListItem, ListItemText } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FiberManualRecordIcon from '@material-ui/icons/Brightness1';
import FilterListIcon from '@material-ui/icons/FilterList';
import TuneIcon from '@material-ui/icons/Tune';

import FilterDialog from '../../../components/FilterDialog';
import OutlayChecktSearchFilter from "../OutlayCheckSearchFilter";

const styles:any = createStyles({
    filterButton: {
        width: '15%'
    },
    paginationGroup: {
        border: '.5px solid rgba(25,25,25,0.2)',
        bottom: 0,
        display: 'flex',
        height: 50,
        justifyContent: 'center',
        justifyItems: 'center',
        position: 'absolute',
        textAlign: 'center',
        width: 280
    },
    root: {
        borderRightColor: 'rgba(25,25,25,0.2)',
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        bottom: 51,
        boxSizing: 'border-box',
        overflowY: 'auto',
        padding: 10,
        paddingLeft: 15,
        position: 'absolute',
        top: 65,
        width: 280
    },
    textField: {
        width: '70%'
    }
});

interface ISearchSectionProps {
    classes: {
        filterButton: string,
        paginationGroup: string;
        root: string,
        textField: string
    };
    
    loading: boolean;
    checks: IOutlayCheck[];
    user: IUser;
    pagination: boolean;
    fetchData(params: IFetchOutlayCheckParams): void;
    handleClick(checkId: string): void;
    advanceSearch(queryParameters: IFetchOutlayCheckParams): void;
}
interface ISearchSectionState {
    filterField: string;
    limit: number;
    offset: number;
    open: boolean;
    advanceFilter: boolean;
    searchValue: string;
    searching: boolean;
    status: object
}
class SearchSection extends React.Component<ISearchSectionProps, ISearchSectionState> {
    public state: ISearchSectionState = {
        advanceFilter: false,
        filterField: 'title',
        limit: 20,
        offset: 0,
        open: false,
        searchValue: '',
        searching: false,
        status : {
            'cancelled' : translations.OUTLAY_CHECK_STATUS_CANCELLED,
            'completed' : translations.OUTLAY_CHECK_STATUS_COMPLETED,
            'in_transit' : translations.OUTLAY_CHECK_STATUS_IN_TRANSIT,
            'pending_signature' : translations.OUTLAY_CHECK_STATUS_PENDING_SIGNATURE,
            'archived' : translations.OUTLAY_CHECK_STATUS_ARCHIVED,
        }
    }
    constructor(props: ISearchSectionProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.openFilterDialog = this.openFilterDialog.bind(this);
        this.closeFilterDialog = this.closeFilterDialog.bind(this);
        this.handleChange = this.handleChange.bind(this);
        
        this.mapFunction = this.mapFunction.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.search = this.search.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);

    }
    public openDialog() {

        this.setState({ open: true });
    }
    public handleDialogClose(value: string) {
        this.setState({
            filterField: value,
            open: false
        });
    }
    public openFilterDialog() {
        this.setState({ advanceFilter: true });
    }
    public closeFilterDialog() {
        this.setState({ advanceFilter: false });
    }
    public search(params:IFetchOutlayCheckParams) {
        this.props.advanceSearch(params);
    }
    public handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!this.state.searching || event.currentTarget.value === '') {

            const params: IFetchOutlayCheckParams = {
                limit: this.state.limit,
                offset: 0,
                token: this.props.user.token
            };
            params[this.state.filterField] = event.currentTarget.value;

            this.setState({
                offset: 0,
                searchValue: event.currentTarget.value,
                searching: true
            });

            this.props.fetchData(params);

            setTimeout(() => this.setState({ searching: false }), 300);

        } else {
            this.setState({ searchValue: event.currentTarget.value });
        }
    }
    
    public mapFunction(check: IOutlayCheck) {
        let status = '';
        if (check.status) {
            status = this.state.status[check.status];
        }
        let statusColor: string = "";

        switch (check.status) {
            case 'cancelled':
                statusColor = red[500];
                break;
            case 'in_transit':
                statusColor = blue[500];
                break;
            case 'completed':
                statusColor = orange[500];
                break;
            case 'pending_signature':
                statusColor = yellow[500];
                break;
            case 'archived':
                statusColor = green[500];
                break;
        }
        
        if (check.uniqueId === 'none') {
            return (
                <ListItem divider={true} key={check.uniqueId} button={true} onClick={this.selectItem(check.uniqueId || '')}>
                    <ListItemText
                        primary={check.title} secondary={status}                         
                    />
                </ListItem>
            );
        }
        return (
            <ListItem dense={true} divider={true} key={check.uniqueId} button={true} onClick={this.selectItem(check.uniqueId || '')}>
                <FiberManualRecordIcon style={{ color: statusColor }} />
                <ListItemText
                    primary={check.title} secondary={status} 
                />
            </ListItem>
        );
    }
    public selectItem(id: string) {
        return (event: React.MouseEvent<HTMLElement>) => {
            this.props.handleClick(id);
        }
    }
    public fetchData() {
        this.props.fetchData({ token: this.props.user.token });
    }
    public prev() {
        if (this.state.offset !== 0) {

            this.setState(prevState => {

                const params: IFetchOutlayCheckParams = {
                    limit: prevState.limit,
                    offset: prevState.offset - 20,
                    token: this.props.user.token
                };
                params[this.state.filterField] = this.state.searchValue;

                this.props.fetchData(params);

                return {
                    offset: prevState.offset - 20
                };
            });
        }
    }
    public next() {

        this.setState(prevState => {

            const params: IAccountingRecordParams = {
                limit: prevState.limit,
                offset: prevState.offset + 20,
                token: this.props.user.token
            };
            params[this.state.filterField] = this.state.searchValue;

            this.props.fetchData(params);

            return {
                offset: prevState.offset + 20
            };
        });

    }
    public render() {
        const { classes, checks, loading, pagination } = this.props;
       
        return (
            <div>
                <div className={classes.root}>
                    <FilterDialog
                        options={[
                            { caption: translations.NAME, value: 'title' }
                        ]}
                        
                        open={this.state.open}
                        onClose={this.handleDialogClose}
                        selectedValue={this.state.filterField}
                    />
                    <OutlayChecktSearchFilter
                        handleClose={this.closeFilterDialog}
                        open={this.state.advanceFilter}
                        search={this.search}
                        token={this.props.user.token}
                        
                    />
                    <div>
                        <TextField
                            className={classes.textField}
                            value={this.state.searchValue}
                            onChange={this.handleChange}
                            placeholder={translations.SEARCH}
                        />
                        <IconButton className={classes.filterButton} onClick={this.openDialog}>
                            <FilterListIcon />
                        </IconButton>
                        <IconButton className={classes.filterButton} onClick={this.openFilterDialog}>
                            <TuneIcon />
                        </IconButton>
                    </div>
                    <div>
                        <List >
                            {(loading) ?
                                <ListItem>
                                    <CircularProgress style={{ color: green[500] }} thickness={7} />
                                    <ListItemText primary={translations.LOADING} />
                                </ListItem>
                                : checks.length === 0 ? 
                                    [{ title: translations.NOT_FOUND, uniqueId: 'none' }].map(this.mapFunction)
                                    : 
                                    checks.map(this.mapFunction)}
                        </List>
                    </div>
                </div>
                {!loading && pagination ?
                    <div className={classes.paginationGroup}>
                        <IconButton onClick={this.prev}>
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton>
                            {(this.state.offset / 20) + 1}
                        </IconButton>
                        <IconButton onClick={this.next}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}
function mapStateToProps(state: IState) {
    return {
        checks: state.outlayChecks,
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps)(SearchSection)
);
