import React, { useEffect, useState } from 'react';
import { Grid, Button, InputBase, Toolbar, Typography, Description, IconButton } from '@material-ui/core';
import { AttachFile, CloudDownload, Delete, Image } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { GeneralTable } from './Table';
import {axios, getFileAttachment} from "../../utils/axios";
import { errorHandling } from './CustomHooks';

const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius:0,
    },
}));

function TableToolbar(props) {
    return (
        <Toolbar>
            <Grid container item direction="row" spacing={5} alignItems="center">
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{ fontWeight: 'bold', paddingLeft: 16 }}
                >
                    Archivos
                </Typography>
            </Grid>
        </Toolbar>
    );
}

function FileSelection(props) {
    const classes = useStyles();
    let fileInput = null;

    const handleFileChange = event => {
        if(props.setSavedFiles && props.multiple && props.preShow) {
            const newFiles = event.target.files;
            Object.keys(newFiles).forEach(key => {
                props.setSavedFiles(prevState => ([...prevState, newFiles[key]]));
            });
        }
        if(props.multiple) {
            props.setFile(event.target.files);
            return;
        }
        props.setFile(event.target.files[0])
    }
    
    const getName = () => {
        if(props.multiple){
            if(!props.file) {return ''}
            return props.file.length>0?Object.keys(props.file).map(file=>props.file[file].name).join(', '):'Select Files';
        }
        return props.file?props.file.name:'Select File';
    }

    const uploadFile = () => {
        props.setLoading(true);
        const endpoint = props.uploadFileEndpoint();
        endpoint.then(response => {
            props.setSavedFile(response.data);
            props.setLoading(false);
            props.handleSimpleDialog('success-dialog');
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleSimpleDialog);
        });
    }
    const openAttachment = (uid) => {
        if (uid !== undefined){
            props.setLoading(true);
            const endpoint = getFileAttachment(props.token, uid);
            endpoint.then((res) => {
                const dbuff = new Uint8Array(res.data.file).buffer
                const data = new Blob([dbuff], {type: res.data.type});
                const fileURL = URL.createObjectURL(data);
                const NewWindow = window.open();
                NewWindow.location.href = fileURL;
                props.setLoading(false);
            }).catch(errors => {
                props.setLoading(false);
                errorHandling(errors, props.handleSimpleDialog);
            });
        }
    }

    const download = (row) => {
        const getType =(name)=> name.split('.')[1]
        const type = getType(row.name)
        props.loading(true);
        getFileAttachment(props.token, row.uniqueId)
        .then((res) => {
            const dbuff = new Uint8Array(res.data.file).buffer
            const data = new Blob([dbuff], {type: res.data.type});
            const fileURL = URL.createObjectURL(data);
            const NewWindow = window.open();
            NewWindow.location.href = fileURL;
            props.loading(false);
        })
        .catch((err) => {
            props.loading(false);
            console.error(err)
        });
    }

    const name = (row) => {
        let name = '';
        if(row.uniqueId) {
            const index = row.file.lastIndexOf("/");
            const formattedText = row.file.substring(index + 1);
            name = formattedText;
        }else {
            name = row.name;
        }
        return(
            <span>
                {name}
            </span>
        )
    }

    const actions = (row) => {
        return (
            <Grid container direciton="row">  
                <Grid container justifyContent='center'>
                    {
                        row.uniqueId &&
                        <IconButton
                            onClick={
                                () =>download(row)}
                        >
                            <CloudDownload style={{ fontSize: 22, color: '#616161' }} />
                        </IconButton>
                    }
                    <IconButton
                        onClick={
                            () =>{
                                if(row.uniqueId) {
                                    props.handleToggleSimpleDialog('confirm-dialog', 'proceed-delete-file', {callback: props.removeFile.bind(this, row)})
                                }else {
                                    props.removeFile(row);
                                }
                            }
                        }
                    >
                        <Delete style={{ fontSize: 22, color: '#616161' }} />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };
    const columns = [
        { id: 'name', label: 'NOMBRE', align: 'left', minWidth: 230, element: name },
        { id: 'action', label: 'ACCIÓN', align: 'center', minWidth: 150, element: actions },
    ]

    useEffect(() => {
        if(!props.file) {
            fileInput.value = null;
        }
    }, [props.file]);
    
    return(
        
        <Grid container item direction="row" xs={props.xs} justifyContent={props.justifyContent}>
            <div style={{backgroundColor:'#FFFFFF', border: '2px solid #43A047', borderRadius:4, width:370, marginBottom:'1.5rem'}}>
                <input
                    ref={(input) => {fileInput = input;}}
                    type="file"
                    accept={props.fileExtensions}
                    id={props.inputId || "input-file-selection"}
                    onChange={handleFileChange}
                    style={{display:'none'}} 
                    disabled={props.disabled}
                    multiple={props.multiple}
                />
                <div style={{minWidth:250, whiteSpace: 'nowrap', overflow: 'hidden', display:'flex', justifyContent:'space-between', alignItems:'center', height:30}}>
                    <div style={{maxWidth:'65%', display:'inline-block'}}>
                        <label htmlFor={props.inputId || "input-file-selection"}>
                            <Button
                                className={`${classes.root} ${props.disabled ? "action-method-btn disabled" : "action-method-btn save"}`}
                                variant="contained" 
                                style={{fontSize:'12px', display:'inline-block'}}
                                component="span"
                                disableElevation
                                disabled={props.disabled}
                                
                            >
                                <AttachFile style={{margin:'0 5px -4px -5px', fontSize:'16px'}}/>
                                Elegir
                            </Button>
                        </label>
                            {/* {!props.multiple? */}
                         <InputBase
                            value={getName()}
                            style={{color:'#616161', paddingLeft:5, display:'inline-block', width:'100%'}}
                            disabled={true}
                        />
                    </div>             
                    {
                        (props.uploadFile || props.uploadFileEndpoint) ?
                        <div style={{maxWidth:'35%', display:'inline-block', float:'right'}}>
                                <Button
                                    className={`${classes.root} ${props.disabled ? "action-method-btn disabled" : "action-method-btn save"}`}
                                    onClick={props.uploadFileEndpoint ? uploadFile : props.uploadFile}
                                    variant="contained"
                                    style={{fontSize:'12px', marginLeft:10}}
                                    disableElevation
                                    disabled={!props.file || props.disabled}
                                >
                                    cargar
                                </Button>
                        </div> : null
                    }
                </div>
            </div>
            {
                props.showSavedFile ?
                <Button 
                    variant="contained" 
                    className={!props.savedFile?.uniqueId ? "action-method-btn disabled" : "action-method-btn save"}
                    disableElevation
                    size="small"
                    onClick={() => openAttachment(props.savedFile?.uniqueId)}
                    disabled={!props.savedFile?.uniqueId}
                    style={{margin:'0 0 3px 1rem', height:30}}
                >
                    {/* ver adjunto */}
                    <Image style={{fontSize:22, }} />
                </Button> : null
            }
            {
                props.multiple? 
                <GeneralTable
                    columns={columns}
                    data={props.savedFiles || []}
                    toolbar={
                        <TableToolbar />
                    }
                />
                : null
            }
        </Grid>
    )
}

export { FileSelection }