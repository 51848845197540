
import { CREDIT_CARD_TYPES } from '../actions';

export default function(state: ICreditCard[] = [], action: IAction<ICreditCard[]>): ICreditCard[] {
    switch(action.type) {
        case CREDIT_CARD_TYPES.FETCH_ALL:
            return action.payload || [];
        case CREDIT_CARD_TYPES.FETCH:
            return state.map(creditCard => (action.payload !== undefined && creditCard.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: creditCard);
        case CREDIT_CARD_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case CREDIT_CARD_TYPES.UPDATE:
            return state.map(creditCard => (action.payload !== undefined && creditCard.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : creditCard);
        case CREDIT_CARD_TYPES.DELETE:
            return state.filter(creditCard => action.payload !== undefined && creditCard.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}