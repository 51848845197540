import * as React from "react";
import { connect } from "react-redux";
import FileUploadSelectInput from "src/containers/FileUpload/FileUploadSelectInput";

import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import DocumentIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";

import {
  deleteDebitCardOrPassbookAccountContract,
  fetchDebitCardOrPassbookAccountContract,
  updateDebitCardOrPassbookAccountContract,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  debtorsValidation,
  filesGeneration,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import {
  deleteDebitCardOrPassbookAccountContract as deleteMA,
  fetchDebitCardOrPassbookAccountContract as fetchMA,
  updateDebitCardOrPassbookAccountContract as updateMA,
} from "../../../utils/axios";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

interface IViewProps {
  debitCardOrPassbookAccountContracts: IDebitCardOrPassbookAccountContract[];
  classes: {
    attachmentIcon: any;
    checkboxLabel: any;
    controls: any;
    deleteButton: any;
    errorInput: any;
    formControl: any;
    greenButton: any;
    half: any;
    paper: any;
    root: any;
    textField: any;
    title: any;
  };
  user: IUser;
  id: any;
  changeSection(sectionNumber: number): void;
  deleteDebitCardOrPassbookAccountContract(
    uniqueId: string
  ): IAction<IDebitCardOrPassbookAccountContract[]>;
  fetchDebitCardOrPassbookAccountContract(
    debitCardOrPassbookAccountContract: IDebitCardOrPassbookAccountContract
  ): IAction<IDebitCardOrPassbookAccountContract[]>;
  updateDebitCardOrPassbookAccountContract(
    debitCardOrPassbookAccountContract: IDebitCardOrPassbookAccountContract
  ): IAction<IDebitCardOrPassbookAccountContract[]>;
}
interface IViewState {
  [key: string]:
    | string
    | number
    | boolean
    | string[]
    | IBankAccount[]
    | undefined;

  status: string;

  MA: string;
  contractDate: string;
  contractDateError: string;
  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  debtor: string[];
  debtorError: string;
  loading: boolean;
  title: string;
  titleError: string;
  city: string;
  cityError: string;
  uniqueId: string;
}
class View extends React.Component<IViewProps, IViewState> {
  public state: IViewState = {
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    debtor: [],
    debtorError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    MA: "",
    title: "",
    titleError: "",
    city: "Santo Domingo",
    cityError: "",
    uniqueId: "",
    status: "pending_signature",
  };
  constructor(props: IViewProps) {
    super(props);

    // client
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // core events
    this.generateFile = this.generateFile.bind(this);
    this.changeStatus = this.changeStatus.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }
  public handleAddClient(id: any) {
    this.setState({ debtor: [id] });
  }
  public handleDeleteClient(id: any) {
    this.setState({ debtor: [] });
  }
  public async generateFile() {
    this.setState({ loading: true });
    if (
      await debtorsValidation(
        this,
        this.props.user.token,
        this.state.debtor,
        false
      )
    ) {
      const url = `/lending/debit-card-or-passbook-account/${this.state.uniqueId}/print/`;
      await filesGeneration(url, this.props.user.token);
      this.setState({ loading: false });
    }
  }
  public changeStatus(newStatus: any) {
    return () => {
      this.setState({ newStatus });
    };
  }

  public componentDidMount() {
    fetchMA(this.props.user.token, this.props.id)
      .then((res) => {
        const contract = res.data;

        this.setState({
          contractDate: contract.contractDate || "",
          debtor: contract.debtor ? [contract.debtor] : [],
          dialog: false,
          dialogTitle: "",
          financialAccounts: contract.financialAccounts || [],
          loading: false,
          MA: `lending/debit-card-or-passbook-account/${contract.uniqueId}/upload_attachment/`,
          title: contract.title || "",
          city: contract.city || "",
          uniqueId: contract.uniqueId || "",
          status: contract.status || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }

  public handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    const contract = {
      contractDate: state.contractDate,
      debtor: state.debtor[0] || "",
      title: state.title,
      status: state.status || "",
      city: state.city || "",
    };

    this.setState({ loading: true });

    updateMA(this.props.user.token, this.state.uniqueId, contract)
      .then((res) => {
        this.props.updateDebitCardOrPassbookAccountContract(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, "Contrato de manejo de cuenta");
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteMA(this.props.user.token, this.props.id)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteDebitCardOrPassbookAccountContract(this.props.id);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public render() {
    const { classes } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        noValidate={true}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <FileUploadSelectInput
            contractId={this.state.uniqueId}
            url={this.state.MA}
            description="DCPAF"
          />

          <Typography variant="h4" className={classes.title}>
            Contrato de manejo de cuenta
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={classes.greenButton}
                onClick={this.generateFile}
                style={{ marginRight: 10, marginTop: 25 }}
              >
                <DocumentIcon />
                &nbsp;
                {translations.GENERATE_DOCUMENT}
              </Button>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="status" shrink={true}>
                  {translations.STATUS}
                </InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                  required={true}
                  inputProps={{
                    id: "status",
                    name: "status",
                  }}
                >
                  <MenuItem value="pending_signature">
                    {translations.PENDING_SIGNATURE}
                  </MenuItem>
                  <MenuItem value="internal_signature_pending">
                    {translations.INTERNAL_SIGNATURE_PENDING}
                  </MenuItem>
                  <MenuItem value="in_legalization_process">
                    {translations.IN_LEGALIZATION_PROCESS}
                  </MenuItem>
                  <MenuItem value="completed">
                    {translations.COMPLETED}
                  </MenuItem>
                  <MenuItem value="archived">{translations.ARCHIVED}</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={4}>
                  <TextField
                    label={translations.TITLE}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.title)}
                    onChange={this.handleChange("title")}
                    fullWidth={true}
                    required={true}
                    error={this.state.titleError !== ""}
                    helperText={this.state.titleError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={translations.CITY}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.city)}
                    onChange={this.handleChange("city")}
                    fullWidth={true}
                    required={true}
                    error={this.state.cityError !== ""}
                    helperText={this.state.cityError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={translations.CONTRACT_DATE}
                    className={classes.textField}
                    value={this.state.contractDate}
                    type="date"
                    onChange={this.handleChange("contractDate")}
                    required={true}
                    error={this.state.contractDateError !== ""}
                    helperText={this.state.contractDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.PARTNERS}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtor}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    debitCardOrPassbookAccountContracts:
      state.debitCardOrPassbookAccountContracts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteDebitCardOrPassbookAccountContract,
    fetchDebitCardOrPassbookAccountContract,
    updateDebitCardOrPassbookAccountContract,
  })(View)
);
