import React, { useState, useEffect } from 'react';
import { Grid, Typography, IconButton, Button, Toolbar} from '@material-ui/core';
import { Edit, Delete, Image, Description } from '@material-ui/icons';
import { GeneralTable } from './../../../components/SharedComponents/Table';
import NumberFormat from './../../../components/SharedComponents/NumberFormat';
import { getFileAttachment } from "../../../utils/axios";
import { TableSearch } from './../../../components/SharedComponents/Fields';
import { DeleteOutline, } from '@material-ui/icons';
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';

function PaymentToolbar(props) {
    return(
        <Toolbar>
            <Grid container item direction="row" spacing={5} alignItems="center">
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{fontWeight: 'bold', paddingLeft:16}}
                >
                    Pagos
                </Typography>
                {
                    props.selected && props.selected.length ?
                    <Button
                        variant="outlined"
                        style={{fontSize: '10px', color:'#e34f4f', borderColor:'#e34f4f', backgroundColor:'#fff', marginLeft: 16 }}
                        disableElevation
                        size="small"
                        onClick={
                            props.handleToggleSimpleDialog
                            .bind(this, 'confirm-dialog', 'proceed-delete-multiple', {callback: props.handleDeleteMultiplePayment})
                        }
                    >
                        <DeleteOutline style={{ fontSize: 15, color: '#e34f4f', margin: '0 .5rem .1rem 0' }} />
                        eliminar pagos
                    </Button>
                    :
                    <>
                        <Button
                            variant="contained"
                            className={props.paymentsQuantity < 1 ? "action-method-btn disabled" : "action-method-btn save"}
                            style={{marginLeft: '.8rem', fontSize:'10px'}}
                            disableElevation
                            size="small"
                            disabled={props.paymentsQuantity < 1}
                            onClick={props.handleReport}
                        >
                            <Description style={{fontSize:15, color:'#fff', margin:'0 .5rem .1rem 0'}} />
                            reporte imprimible
                        </Button>
                        <Button
                            variant="contained"
                            className={props.paymentsQuantity < 1 ? "action-method-btn disabled" : "action-method-btn save"}
                            style={{marginLeft: '.8rem', fontSize:'10px'}}
                            disableElevation
                            size="small"
                            disabled={props.paymentsQuantity < 1}
                            onClick={props.handleReportCsv}
                        >
                            <Description style={{fontSize:15, color:'#fff', margin:'0 .5rem .1rem 0'}} />
                            reporte csv
                        </Button>
                    </>
                }
            </Grid>
        </Toolbar>
    );
}

function PaymentTable(props) {
    const [tableData, setTableData] = useState([]);
    const actions = (row) => {
        return(
            <Grid container direciton="row" justifyContent="space-between">
                <IconButton
                    onClick={props.handleToggleDialog.bind(this, 'payment-modify', row)}
                >
                    <Edit style={{fontSize:22, color:'#616161'}} />
                </IconButton>
                <IconButton
                    onClick={
                        props.handleToggleSimpleDialog
                        .bind(this, 'confirm-dialog', 'proceed-delete', {callback: props.handleDelete.bind(this, row), row: row})
                    }
                >
                    <Delete style={{fontSize:22, color:'#616161'}} />
                </IconButton>
            </Grid>
        );
    };
    const voucher = (row) => {
        const openVoucher = (uid) => {
            if (uid !== undefined){
                props.setLoading(true);
                const endpoint = getFileAttachment(props.user.token, uid);
                endpoint.then((res) => {
                    const dbuff = new Uint8Array(res.data.file).buffer
                    const data = new Blob([dbuff], {type: res.data.type});
                    const fileURL = URL.createObjectURL(data);
                    const NewWindow = window.open();
                    NewWindow.location.href = fileURL;
                    props.setLoading(false);
                }).catch(errors => {
                    props.setLoading(false);
                    errorHandling(errors, props.handleToggleSimpleDialog);
                });
              }
        }
        return(
            <Grid container direciton="row" justifyContent="space-between">
                <IconButton
                    onClick={openVoucher.bind(this, row.voucher)} // a dialog showing the voucher
                    disabled={!row.voucher}
                >
                    <Image style={{fontSize:22, color:row.voucher ? '#43a047' : '#616161'}} />
                </IconButton>
            </Grid>
        )
    }
    const amount = (column, row) => {
        return(
            <NumberFormat
                value={row[column]}
            />
        )
    }
    const columns = [
        {id:'checkbox', label:'SELECCIÓN', minWidth: 70 },
        {id:'paymentId', label: 'REFERENCIA', minWidth: 150},
        {id:'clientName', label: 'NOMBRE CLIENTE', minWidth: 150},
        {id:'paymentDestinationAccount', label: 'CUENTA DESTINO', minWidth: 150},
        {id:'paymentType', label: 'TIPO DE PAGO', minWidth: 120},
        {id:'creditorName', label: 'ACREEDOR', minWidth: 150,},
        {id:'amountPayed', label: 'MONTO PAGADO', minWidth: 150, element: amount.bind(this, 'amountPayed')},
        {id:'paymentDate', label: 'FECHA PAGO', minWidth: 120},
        {id:'voucher', label: 'COMPROBANTE', minWidth: 120, element: voucher},
        {id:'action', label: 'ACCIÓN', align:'center', minWidth: 100, element: actions},
    ];
    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);
    return(
        <GeneralTable
            columns={columns}
            data={tableData}
            selected={props.selected}
            setSelected={props.setSelected}
            handleSelected={props.handleSelected}
            toolbar={
                <Grid container justifyContent='space-between' alignItems='center'>
                    <PaymentToolbar
                        handleReport={props.handleReport}
                        handleReportCsv={props.handleReportCsv}
                        paymentsQuantity={props.data.length}
                        selected={props.selected}
                        handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                        handleDeleteMultiplePayment={props.handleDeleteMultiplePayment}
                    />
                    <TableSearch
                        data={props.data}
                        setTableData={setTableData}
                        searchFields={['clientName']}
                    />
                </Grid>
            }
        />
    );
}

export { PaymentTable }
