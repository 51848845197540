import React from "react";
import { connect } from "react-redux";

import {
  Paper,
  Typography,
  IconButton,
  Toolbar,
  Box,
  Tab,
  Grid,
  InputAdornment,
  Button,
} from "@material-ui/core";

import { green, red } from "@material-ui/core/colors";

import {
  Settings,
  Visibility,
  CheckCircle,
  HighlightOff,
} from "@material-ui/icons";

import { GeneralTable } from "../../../components/SharedComponents/Table";
import TableContainer from "@material-ui/core/TableContainer";

import { TextFieldComponent } from "./../../../components/SharedComponents/Fields";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { CollapsibleTable } from "../Tables/inflex";
import { PCPDialog } from "../Dialogs/PCPDialog";

function TableToolbar(props) {
  return (
    <Toolbar>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          className="section-header"
          style={{ fontWeight: "bold", paddingLeft: 16 }}
        >
          {props.title}
        </Typography>
      </div>
    </Toolbar>
  );
}

function Loans(props) {
  const [value, setValue] = React.useState("1");
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTotalPCP = (loanNumber) => {
    const currentYear = new Date();

    const data = [];

    const total_pcp =
      props.data[0].pcpInfo &&
      props.data[0].pcpInfo.map((item) => {
        item.pcp_details.map((item2) => {
          data.push(item2);
        });
      });

    const dataClean = data.filter(
      (item) =>
        (loanNumber ? item.loan_number === loanNumber : true) &&
        (currentYear
          ? Number(item.due_date.slice(0, 4)) === currentYear.getFullYear()
          : true) &&
        (currentYear
          ? currentYear.getTime() <= new Date(item.due_date).getTime()
          : true)
    );

    let totalPCP = 0;

    if (dataClean.length > 0) {
      dataClean.forEach((item) => {
        totalPCP += item.pcp_amount;
      });
    }

    totalPCP = totalPCP.toLocaleString("en-US", {
      style: "currency",
      currency: "DOP",
    });

    return totalPCP;
  };

  const actions = (row) => {
    return (
      <>
        <div style={{ alignItems: "center" }}>
          <IconButton onClick={() => props.handleModal(row, "loan")}>
            <Settings
              style={{
                fontSize: 22,
                color: green[500],
              }}
            />
          </IconButton>
        </div>
      </>
    );
  };

  const setCurrency = (value) => {
    let data = parseFloat(value).toFixed(2);
    return Number(data).toLocaleString("en-US", {
      style: "currency",
      currency: "DOP",
    });
  };

  const columnsLoans = [
    {
      id: "collapse",
      label: "",
      minWidth: 20,
      align: "center",
      isCollapse: (row) => row.collapseData.length,
    },
    {
      id: "product_type",
      label: "Tipo producto".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "total_loan_stock",
      label: "Monto total".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.total_loan_stock),
    },
    {
      id: "w_intr",
      label: "Tasa interés".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => `${parseFloat(row.w_intr).toFixed(2)}%`,
    },
    {
      id: "total_next_amount_due",
      label: "Proximo pago".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.total_next_amount_due),
    },
    {
      id: "is_pcp",
      label: "es pcp".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) =>
        row.collapseData.length > 0 ? (
          <HighlightOff
            style={{
              fontSize: 22,
              color: red[500],
            }}
          />
        ) : (
          <CheckCircle
            style={{
              fontSize: 22,
              color: green[500],
            }}
          />
        ),
    },
  ];

  const columnsLoansCollaps = [
    {
      id: "loan_number",
      label: "Número de prestamo".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "loan_oustanding_balalance",
      label: "Saldo".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.loan_oustanding_balalance),
    },
    {
      id: "interest_rate",
      label: "Tasa interés".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => `${parseFloat(row.interest_rate).toFixed(2)}%`,
    },
    {
      id: "next_amount_due",
      label: "Proximo pago".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.next_amount_due),
    },
    {
      id: "total_pcp_amount",
      label: "total pcp".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => getTotalPCP(row.loan_number),
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      element: actions,
    },
  ];

  let datapr = [];
  let datacab = [];
  let regulares = [];
  let compras = [];
  const loans = props.data[0] ? props.data[0].loansGeneralInfo : [];
  const pcp_data = props.data[0] ? props.data[0].pcpInfo : [];

  loans.forEach((obj) => {
    if (obj.product_classification === "COMPRA ANTICIPADA DE BONOS") {
      compras = obj;
      datacab = obj.product_type_details;
    } else if (obj.product_classification === "PRESTAMO REGULAR") {
      regulares = obj;
      datapr = obj.product_type_details;
    }
  });

  const data = datapr.map((item) => {
    return {
      product_type: item.product_type,
      total_loan_stock: item.total_loan_stock,
      total_next_amount_due: item.total_next_amount_due,
      total_pcp_amount: item.total_pcp_amount,
      w_intr: item.w_intr,

      collapseData: item.product_details,
    };
  });
  const data2 = datacab.map((item) => {
    return {
      product_type: item.product_type,
      total_loan_stock: item.total_loan_stock,
      total_next_amount_due: item.total_next_amount_due,
      total_pcp_amount: item.total_pcp_amount,
      w_intr: item.w_intr,

      collapseData: item.product_details
        ? [
            ...item.product_details
              .filter((i) => i.loans_details[0].loan_status === "ACTIVO")
              .map((i2) => {
                return {
                  ...i2,
                  total_pcp_amount: item.total_pcp_amount,
                };
              }),
          ]
        : [],
    };
  });

  return (
    <>
      <Paper>
        <div style={{ width: "100%" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: green[500],
                  },
                }}
              >
                <Tab label="Prestamos regurales" value="1" />
                <Tab label="Compras anticipadas de bonos" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              {data.length > 0 ? (
                <Grid container spacing={2}>
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="CLASIFICACIÓN DEL PRODUCTO"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      regulares
                        ? regulares.product_classification
                        : "PRESTAMO REGULAR"
                    }
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="CANTIDAD PRESTAMOS"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={regulares ? regulares.loans_qty : 0}
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="TOTAL PROXIMO PAGO"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      regulares
                        ? Number(
                            regulares.total_next_amount_due
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "DOP",
                          })
                        : 0
                    }
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="TOTAL PCP"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      regulares
                        ? Number(regulares.total_pcp_amount).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "DOP",
                            }
                          )
                        : 0
                    }
                    inputProps={{
                      endAdornment: (
                        <IconButton onClick={() => pcp_data && setOpen(!open)}>
                          <Visibility
                            style={{
                              fontSize: 22,
                              color: green[500],
                            }}
                          />
                        </IconButton>
                      ),
                    }}
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="SALDO INSOLUTO PRINCIPAL"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      regulares
                        ? Number(regulares.total_loan_stock).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "DOP",
                            }
                          )
                        : 0
                    }
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="TASA INTERÉS"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      regulares
                        ? `${parseFloat(regulares.w_intr).toFixed(2)}%`
                        : 0
                    }
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="TOTAL PENDIENTE"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      regulares
                        ? Number(
                            regulares.total_amout_pending_by_pc
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "DOP",
                          })
                        : 0
                    }
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="TOTAL SALDO"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      regulares
                        ? Number(
                            regulares.total_oustanding_balance_due_by_pc
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "DOP",
                          })
                        : 0
                    }
                    disabled={true}
                  />
                </Grid>
              ) : null}
              <TableContainer style={{ width: "100%" }}>
                <CollapsibleTable
                  columns={columnsLoans}
                  columnsCollapse={columnsLoansCollaps}
                  data={data}
                  toolbar={<TableToolbar title={"PRESTAMOS REGULARES"} />}
                />
              </TableContainer>
            </TabPanel>
            <TabPanel value="2">
              {data2.length > 0 ? (
                <Grid container spacing={2}>
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="CLASIFICACION DE PRODUCTO"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={compras ? compras.product_classification : ""}
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="CANTIDAD PRESTAMOS"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={compras ? compras.loans_qty : ""}
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="TOTAL PROXIMO PAGO"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      compras
                        ? Number(compras.total_next_amount_due).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "DOP",
                            }
                          )
                        : ""
                    }
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="TOTAL PCP"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      compras
                        ? Number(compras.total_pcp_amount).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "DOP",
                            }
                          )
                        : ""
                    }
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="SALDO INSOLUTO PRINCIPAL"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      compras
                        ? Number(compras.total_loan_stock).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "DOP",
                            }
                          )
                        : ""
                    }
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="TASA INTERÉS"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      compras ? `${parseFloat(compras.w_intr).toFixed(2)}%` : ""
                    }
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="TOTAL PENDIENTE"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      compras
                        ? Number(
                            compras.total_amout_pending_by_pc
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "DOP",
                          })
                        : ""
                    }
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="TOTAL SALDO"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      compras
                        ? Number(
                            compras.total_oustanding_balance_due_by_pc
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "DOP",
                          })
                        : ""
                    }
                    disabled={true}
                  />
                </Grid>
              ) : null}
              <TableContainer style={{ width: "100%" }}>
                <CollapsibleTable
                  columns={columnsLoans}
                  columnsCollapse={columnsLoansCollaps}
                  data={data2}
                  toolbar={
                    <TableToolbar title={"COMPRA ANTICIPADA DE BONOS"} />
                  }
                />
              </TableContainer>
            </TabPanel>
          </TabContext>
        </div>
      </Paper>
      {open ? (
        <PCPDialog
          open={open}
          setOpen={setOpen}
          data={pcp_data ? pcp_data : ""}
        />
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(Loans);
