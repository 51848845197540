import * as React from 'react';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import { MenuItem } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";

import { SearchComponent } from "../../../components/SharedComponents/Fields";
import moneyInput from '../../../components/TextMaskInputComponents/moneyInput';

import { translations, operatorList } from '../../../utils';

const styles: any = createStyles({
    formControl: {
        marginTop: 20
    },
    greenButton: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    label: {
        color: green[500]
    },
    redButton: {
        '&:hover': {
            backgroundColor: red[200]
        },
        backgroundColor: red[500],
        color: 'white'
    },
    textField: {
        marginTop: 10,
        minWidth: 190
    }
});

interface ISearchFilterProps {
    token: string;

    open: boolean;
    classes: {
        formControl: any;
        greenButton: any;
        redButton: any;
        label: any;
        textField: any;
    };
    handleClose(): void;
    search(params: IFetchDebitCardOrPassbookAccountContractParams): void;
}

interface ISearchFilterState {
    [key: string]: string | number | any;
    contract_date_from: string;
    contract_date_to: string;

    input_amount_options: any;
    input_amount: number;
    input_amount_from: number;
    input_amount_to: number;
    debtor_unique_id: any;
    certificate_number: number | string;
    title: string;
    status: any;
}

class SearchFilter extends React.Component<ISearchFilterProps, ISearchFilterState>{
    public state: ISearchFilterState = {
        contract_date_from: '',
        contract_date_to: new Date().toISOString().split('T')[0],

        debtor_unique_id: [],
        title: '',

        status: [],
        certificate_number: "",

        input_amount_options: 'equal',
        input_amount: 0,
        input_amount_from: 0,
        input_amount_to: 0,
    };
    constructor(props: ISearchFilterProps) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    }
    public handleCurrencyInputChange(key: any) {
        return (event?: any, maskedValue?: any, floatValue?: any) => {
            this.setState({ [key]: floatValue || "" });
        };
    }
    public handleSearch() {
        const params = {
            inputAmountFrom: this.state.input_amount_from === 0 ? '' : this.state.input_amount_from.toString(),
            inputAmountTo: this.state.input_amount_to === 0 ? '' : this.state.input_amount_to.toString(),
            inputAmount: this.state.input_amount === 0 ? '' : this.state.input_amount.toString(),

            contractDateFrom: this.state.contract_date_from,
            contractDateTo: this.state.contract_date_to,

            certificateNumber: this.state.certificate_number,
            status: this.state.status.map((item: any) => item.value).join(','),

            debtorUniqueId: this.state.debtor_unique_id,

            title: this.state.title,
            token: this.props.token
        };
        this.props.search(params);
        this.props.handleClose();
    }

    public handleClear() {
        this.setState({
            contract_date_from: '',
            contract_date_to: new Date().toISOString().split('T')[0],
            debtor_unique_id: [],
            status: [],
            certificate_number: '',
            input_amount_options: 'equal',
            input_amount: 0,
            input_amount_from: 0,
            input_amount_to: 0,
            title: '',
        });
        this.props.search({ token: this.props.token })
        this.props.handleClose();
    }

    public handleChange(key: any) {
        return (event: any) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }

    public handleSelectChange(key: any) {
        return (event: any) => {
            this.setState({ [key]: event.target.value });
        }
    }

    public handleAutocompleteChange(key: any, obj: any, val: any) {
        /* Handle the state of Autocomplete components */
        const data = obj || val;

        this.setState({
            [key]: data.value ? data.value : ""
        });

    }

    public render() {
        const { classes, handleClose, open } = this.props;

        const handleMultipleSelection = (key: string, val: string) =>
            this.setState({ [key]: val });

        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12}>
                            <SearchComponent
                                xs={12}
                                containerClass="field-container"
                                fieldClass={{ style: styles.label }}
                                typeClass={styles.label}
                                fieldID="client"
                                fieldInnerLabel={translations.DEBTOR}
                                handleChange={this.handleAutocompleteChange.bind(this, "debtor_unique_id")}
                                optionType={'participants'}
                                variant="standard"
                                limitTags={1}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Autocomplete
                                multiple={true}
                                options={[
                                    { value: "in_process", label: translations.ON_PROCESS },
                                    { value: "completed", label: translations.COMPLETED },
                                    { value: "archived", label: translations.ARCHIVED }
                                ]}
                                getOptionLabel={(option: any) => option.label}
                                onChange={(e: any, newValue: any) =>
                                    handleMultipleSelection("status", newValue)
                                }
                                value={this.state.status}
                                renderInput={(params: any) => (
                                    <TextField {...params} label={translations.STATUS} variant="standard" InputLabelProps={{
                                        style: styles.label,
                                        shrink: true,
                                    }} />
                                )}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                label={"Número de certificado"}
                                className={classes.textField}
                                value={this.state.certificate_number}
                                fullWidth={true}
                                inputProps={{
                                    min: 0,
                                    step: 9999
                                }}
                                type="number"
                                onChange={this.handleChange('certificate_number')}
                                InputLabelProps={{
                                    style: styles.label,
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={4}>
                            <InputLabel htmlFor="input_amount_options" shrink={true}>{translations.AMOUNT}</InputLabel>
                            <Select
                                style={{ width: "100%" }}
                                value={this.state.input_amount_options}
                                onChange={this.handleSelectChange('input_amount_options')}
                                inputProps={{
                                    id: 'input_amount_options',
                                    name: 'input_amount_options'
                                }}
                            >
                                {operatorList.map((item, index) => {
                                    return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                })}
                            </Select>
                        </Grid>
                        {this.state.input_amount_options === "equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.input_amount}
                                    style={{ marginTop: "16px" }}
                                    onChange={this.handleCurrencyInputChange('input_amount')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                        {this.state.input_amount_options === "between" ?
                            <>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        value={this.state.input_amount_from}
                                        style={{ marginTop: "16px" }}
                                        onChange={this.handleCurrencyInputChange('input_amount_from')}
                                        InputProps={{
                                            inputComponent: moneyInput,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.label
                                        }}
                                    />
                                </Grid>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        value={this.state.input_amount_to}
                                        style={{ marginTop: "16px" }}
                                        onChange={this.handleCurrencyInputChange('input_amount_to')}
                                        InputProps={{
                                            inputComponent: moneyInput,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.label
                                        }}
                                    />
                                </Grid>
                            </> : null
                        }
                        {this.state.input_amount_options === "greater_than_equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.input_amount_from}
                                    style={{ marginTop: "16px" }}
                                    onChange={this.handleCurrencyInputChange('input_amount_from')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                        {this.state.input_amount_options === "low_than_equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.input_amount_to}
                                    style={{ marginTop: "16px" }}
                                    onChange={this.handleCurrencyInputChange('input_amount_to')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.FROM}`}
                                value={this.state.contract_date_from}
                                fullWidth={true}
                                type="date"
                                onChange={this.handleChange('contract_date_from')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.TO}`}
                                value={this.state.contract_date_to}
                                type="date"
                                fullWidth={true}
                                onChange={this.handleChange('contract_date_to')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.redButton} onClick={this.handleClear}>
                        <ClearIcon />
                        &nbsp;
                        {`${translations.CLEAR} ${translations.SEARCH}`}
                    </Button>
                    <Button className={classes.greenButton} onClick={this.handleSearch}>
                        <SearchIcon />
                        &nbsp;
                        {translations.SEARCH}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


export default withStyles(styles)(SearchFilter);