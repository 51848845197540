import React from "react";

import {
  Grid,
  Typography,
  Button,
  Toolbar,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { Description, ListAlt, PlaylistAdd, AccessTime, Check, Clear } from "@material-ui/icons";
import { GeneralTable } from "./../../../components/SharedComponents/Table";
import { approbationStates, responsibleOptionsAssociate } from "./../../../utils/index";
import { AutocompleteField } from "./../../../components/SharedComponents/Fields";
import {
} from "./../../../utils/axios";

function TableToolbar(props) {
  return (
    <Toolbar>
      <Grid container item direction="row" spacing={5} alignItems="center">
        <Typography
          variant="subtitle1"
          className="section-header"
          style={{ fontWeight: "bold", paddingLeft: 16 }}
        >
          {props.title || "Solicitudes"}
          <Button
            variant="contained"
            className={
              props.dataQuantity < 1
                ? "action-method-btn disabled"
                : "action-method-btn save"
            }
            style={{ marginLeft: ".8rem", fontSize: "10px" }}
            disableElevation
            size="small"
            disabled={props.dataQuantity < 1}
            onClick={props.onClick}
          >
            <Description
              style={{ fontSize: 15, color: "#fff", margin: "0 .5rem .1rem 0" }}
            />
            {props.buttonTitle}
          </Button>
        </Typography>
      </Grid>
    </Toolbar>
  );
}

const date = (column, row) => {
  const value = row[column] ? row[column].split("T")[0] : "";
  return <span>{value}</span>;
};

const ticketStatus = (column, row) => {
  if (row[column] === null) {return false}
  if (row[column] !== "") {
    const values = approbationStates.filter((x) => x.value === row[column])[0].label || "";
    return <span>{values}</span>;
  }else {
    const values = '---'
    return <span>{values}</span>;
  }
};

const ticketsColumns = [
  { id: "checkbox" },
  { id: "participantName", label: "NOMBRE", minWidth: 100 },
  { id: "referenceCode", label: "NUMERO DE TICKET", minWidth: 150 },
  { id: "ticketStatus", label: "ESTADO", minWidth: 150, element: ticketStatus.bind(this, "ticketStatus"), },
];

function ApprovementTable(props) {
  const actions = (row) => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: "space-between" }}>

          <Tooltip title="Conocer">
            <IconButton
              className={
                (row.status === 'pending_approval' || row.status === 'postpone')
                  ? "action-method-btn save"
                  : "action-method-btn disabled"
              }
              style={{ margin: 5 }}
              disabled={(row.status === 'pending_approval' || row.status === 'postpone') ? false : true}
              onClick={props.handleStatusChange.bind(this, row, "known")}
            >
              <Check />
            </IconButton>
          </Tooltip>

          <Tooltip title="Posponer">
            <IconButton
              className={"action-method-btn save"}
              style={{ margin: 5 }}
              onClick={
                props.removeApprobation.bind(this, row)
              }
            >
              <AccessTime />
            </IconButton>
          </Tooltip>

          <Tooltip title="Denegar">
            <IconButton
              className={
                (row.status === 'pending_approval' || row.status === 'postpone')
                  ? "action-method-btn--blue"
                  : "action-method-btn disabled"
              }
              style={{ margin: 5 }}
              disabled={(row.status === 'pending_approval' || row.status === 'postpone') ? false : true}
              onClick={props.handleStatusChange.bind(this, row, "denied")}
            >
            <Clear />
          </IconButton>
        </Tooltip>

        <Tooltip title="Debida diligencias">
          <IconButton
            className={"action-method-btn save"}
            style={{ margin: 5 }}
            onClick={props.handleStatusChange.bind(this, row, "diligence")}
          >
            <ListAlt />
          </IconButton>
        </Tooltip>

        <Tooltip title="Requerimientos extras">
          <IconButton
            className={"action-method-btn save"}
            style={{ margin: 5 }}
            onClick={props.handleStatusChange.bind(this, row, "extra_req")}
          >
            <PlaylistAdd />
          </IconButton>
        </Tooltip>
      </div>
      </>
    );
};

const status = (column, row) => {
  const values = approbationStates.filter((x) => x.value === row[column])[0].label || "";
  return <span>{values}</span>;
};

const responsibleSelector = (column, row) => {
  return (
    <div style={{ marginTop: 10 }}>
      <AutocompleteField
        xs={12}
        containerClass="field-container"
        fieldClass="fields"
        typeVariant="subtitle1"
        typeClass="field-label"
        fieldID="filter-field-status"
        fieldVariant="outlined"
        options={responsibleOptionsAssociate}
        value={responsibleOptionsAssociate.find((x) => x.value === row.responsibleForApproval)}
        handleChange={(e, value) => props.handleAutoCompleteChange(e, value, row)}
        getOptionLabel={(option) => option.value || ""}
        limitTags={1}
      />
    </div>
  );
};



const approvementColumns = [
  { id: "participantName", label: "NOMBRE", minWidth: 100 },
  { id: "participantIdNumber", label: "CÉDULA", minWidth: 150 },
  {
    id: "status",
    label: "ESTADO DE LA SOLICITUD",
    minWidth: 100,
    element: status.bind(this, "status"),
  },
  { id: "actNumber", label: "NÚMERO DE ACTA", minWidth: 100 },
  {
    id: "actDate",
    label: "FECHA DE ACTA",
    minWidth: 100,
    element: date.bind(this, "actDate"),
  },
  {
    id: "requestDate",
    label: "FECHA DE SOLICITUD",
    minWidth: 80,
    element: date.bind(this, "requestDate"),
  },
  {
    id: "responsibleForApproval",
    label: "RESPONSABLE DE LA APROBACION",
    minWidth: 100,
    element: responsibleSelector.bind(this, "responsibleForApproval"),
  },

  {
    id: "action",
    label: "ACCIÓN",
    align: "center",
    minWidth: 250,
    element: actions,
  },
];

return (
  <GeneralTable
    columns={approvementColumns}
    data={props.data}
    toolbar={
      <TableToolbar
        title={props.title}
        buttonTitle={"Generar Acta"}
        onClick={props.createAct.bind(this)}
        dataQuantity={props.data.length}
      />
    }
  />
);
}

function TicketsTable(props) {
  return (
    <GeneralTable
      columns={ticketsColumns}
      data={props.data}
      selected={props.selected}
      setSelected={props.setSelected}
      toolbar={
        <TableToolbar
          title={props.title}
          buttonTitle={"Generar Aprobaciones"}
          onClick={props.generateApprobations}
          dataQuantity={props.data.length}
        />
      }
    />
  );
}

export { ApprovementTable, TicketsTable };
