import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import { blue, green } from "@material-ui/core/colors";
import { Business, Check, FiberManualRecord, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { TableSearch } from "src/components/SharedComponents/Fields";
import { GeneralTable } from "src/components/SharedComponents/Table";
import { participantType } from "src/utils";

function ClientTable(props) {
  const [tableData, setTableData] = useState([]);
  const actions = (row) => {
    return (
      <Grid container direction="row" justifyContent="space-between">
        <IconButton onClick={() => props.showParticipant(row)}>
          <Visibility style={{ fontSize: 22, color: "#616161" }} />
        </IconButton>
      </Grid>
    );
  };

  const participantTypes = (row) => {
    return (
      <span>
        {participantType.length > 0
          ? participantType.find((item) => item.value === row.participantType)
              .label
          : row.participantType}
      </span>
    );
  };

  const status = (row) => {
    return (
      <div>
        {row.hasAccessToCoopvirtual ? (
          <Button
            style={{
              borderRadius: 10,
              color: blue[500],
              fontSize: 7,
              border: `1px solid ${blue[500]}`
            }}
          >
            <Check style={{ color: blue[500], fontSize: 14 }} /> coopvirtual
          </Button>
        ) : (
          null
        )}
        {row.affiliatedInstitution ? (
          <Button
            style={{
              borderRadius: 10,
              color: green[500],
              fontSize: 7,
              border: `1px solid ${green[500]}`,
              marginLeft: row.hasAccessToCoopvirtual ? 10 : 0
            }}
          >
            <Business style={{ color: green[500], fontSize: 14 }} /> TuNomina
          </Button>
        ) : (
          null
        )}
      </div>
    );
  };

  const columns = [
    { id: "rohiId", label: "ID ROHI", minWidth: 70 },
    { id: "fullName", label: "NOMBRE", minWidth: 100 },
    { id: "identificationNumber", label: "IDENTIFICACIÓN", minWidth: 150 },
    {
      id: "participantType",
      label: "TIPO DE CLIENTE",
      element: participantTypes,
      minWidth: 120,
    },
    { id: "status", label: "ESTADO", element: status },
    { id: "action", label: "DETALLES", minWidth: 100, element: actions },
  ];
  useEffect(() => {
    setTableData(props.data);
  }, [props.data]);
  return (
    <GeneralTable
      columns={columns}
      data={tableData}
      selected={props.selected}
      setSelected={props.setSelected}
      handleSelected={props.handleSelected}
      rowClick={props.showParticipant}
      toolbar={
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography
            variant="subtitle1"
            className="section-header"
            style={{ fontWeight: "bold", paddingLeft: 16 }}
          >
            Socios
          </Typography>
          <TableSearch
            data={props.data}
            setTableData={setTableData}
            searchFields={["clientName"]}
          />
        </Grid>
      }
    />
  );
}

export default ClientTable;
