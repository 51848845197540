import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";

import { Button } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import attorneyNumberMaskInput from "src/components/TextMaskInputComponents/attorneyNumberMaskInput";
import phoneMaskInput from "src/components/TextMaskInputComponents/phoneMaskInput";

import {
  createProviderContact,
  deleteProviderContact,
  updateProviderContact,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import idNumberMaskInput from "../../../components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "../../../components/TextMaskInputComponents/passportMaskInput";
import {
  catchError,
  resetError,
  successHandler,
  translations,
} from "../../../utils";
import {
  createProviderContact as create,
  deleteProvider as deletePR,
  fetchProviderContact as fetchPC,
  updateProviderContact as updatePC,
} from "../../../utils/axios";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  button: {
    color: green[500],
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  filterButton: {
    width: "15%",
  },
  formControl: {
    marginTop: 20,
    width: "90%",
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  textField2: {
    display: "block",
    marginTop: 20,
    width: "90%",
  },
});

interface IProviderContactFormProps {
  classes: {
    attachmentIcon: string;
    button: string;
    checkboxLabel: string;
    controls: string;
    deleteButton: string;
    filterButton: string;
    formControl: string;
    greenButton: string;
    input: string;
    paper: string;
    root: string;
    textField: string;
    textField2: string;
  };

  user: IUser;
  providerId: string;
  providerContactId: string;
  deleteProviderContact(uniqueId: string): IAction<IProviderContact[]>;
  updateProviderContact(
    uniqueId: IProviderContact
  ): IAction<IProviderContact[]>;
  createProviderContact(
    provider: IProviderContact
  ): IAction<IProviderContact[]>;
  changeSection(sectionNumber: number): void;
}
interface IProviderContactFormState {
  [key: string]: string | string[] | number | boolean;

  firstName: string;
  firstNameError: string;
  lastName: string;
  lastNameError: string;
  position: string;
  positionError: string;
  workPhone: string;
  workPhoneError: string;
  cellPhone: string;
  cellPhoneError: string;
  extPhone: string;
  extPhoneError: string;
  email: string;
  emailError: string;
  name: string;
  nameError: string;
  open: boolean;
  isOpen: boolean;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
}
class ProviderForm extends React.Component<
  IProviderContactFormProps,
  IProviderContactFormState
> {
  public state: IProviderContactFormState = {
    cellPhone: "",
    cellPhoneError: "",

    dialog: false,
    dialogTitle: "",

    email: "",
    emailError: "",
    errorMessage: "",

    extPhone: "",
    extPhoneError: "",
    firstName: "",
    firstNameError: "",
    identificationType: "national",
    identificationTypeError: "",
    isOpen: false,
    lastName: "",
    lastNameError: "",

    loading: false,

    name: "",
    nameError: "",
    open: false,
    position: "",
    positionError: "",
    workPhone: "",
    workPhoneError: "",
  };

  constructor(props: IProviderContactFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.openFilterDialog = this.openFilterDialog.bind(this);
    this.closeFilterDialog = this.closeFilterDialog.bind(this);
  }
  public openDialog() {
    this.setState({ open: true });
  }
  public openFilterDialog() {
    this.setState({ isOpen: true });
  }
  public closeFilterDialog() {
    this.setState({ isOpen: false });
  }
  public handleCheckInputChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public componentDidMount() {
    if (this.props.providerContactId) {
      this.setState({ loading: true });
      fetchPC(this.props.user.token, this.props.providerContactId)
        .then((res) => {
          const curProvider = res.data as IProviderContact;

          this.setState({
            cellPhone: curProvider.cellPhone as string,
            email: curProvider.email as string,
            extPhone: curProvider.extPhone as string,
            firstName: curProvider.firstName as string,
            lastName: curProvider.lastName as string,
            loading: false,
            position: curProvider.position as string,
            provider: curProvider.provider as string,
            uniqueId: curProvider.uniqueId as string,
            workPhone: curProvider.workPhone as string,
          });
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  public handleDelete() {
    if (confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });

      deletePR(this.props.user.token, this.props.providerContactId)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteProviderContact(this.props.providerContactId);
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    const providerContact: IProviderContact = {
      cellPhone: this.state.cellPhone,
      email: this.state.email,
      extPhone: this.state.extPhone,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      position: this.state.position,
      provider: this.props.providerId,
      workPhone: this.state.workPhone,
    };

    this.setState({ loading: true });

    const errorCallbakc = (err: AxiosError) => catchError(err, this);

    if (this.props.providerContactId) {
      updatePC(
        this.props.user.token,
        this.props.providerContactId,
        providerContact
      )
        .then((res) => {
          this.props.updateProviderContact(
            res.data.results ? res.data.results : res.data
          );
          successHandler(res, this, translations.CONTACT);
          this.handleReset();
        })
        .catch(errorCallbakc);
    } else {
      create(this.props.user.token, providerContact)
        .then((res) => {
          this.props.createProviderContact(
            res.data.results ? res.data.results : res.data
          );
          successHandler(res, this, translations.CONTACT);
          this.handleReset();
        })
        .catch(errorCallbakc);
    }
  }

  public handleReset() {
    this.setState({
      cellPhone: "",
      cellPhoneError: "",
      email: "",
      emailError: "",
      extPhone: "",
      extPhoneError: "",
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      position: "",
      positionError: "",
      provider: "",
      workPhone: "",
      workPhoneError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }

  public getIdentificationInput(identificationType: string) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }
  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.NEW_PROVIDER}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={this.state.firstName}
                onChange={this.handleChange("firstName")}
                fullWidth={true}
                error={this.state.firstNameError !== ""}
                helperText={this.state.firstNameError}
                required={true}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <TextField
                label={translations.LAST_NAME}
                className={classes.textField}
                value={this.state.lastName}
                onChange={this.handleChange("lastName")}
                fullWidth={true}
                error={this.state.lastNameError !== ""}
                helperText={this.state.lastNameeError}
                required={true}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <TextField
                label={translations.POSITION}
                className={classes.textField}
                value={this.state.position}
                onChange={this.handleChange("position")}
                fullWidth={true}
                error={this.state.positionError !== ""}
                helperText={this.state.positioneError}
                required={true}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <TextField
                label={translations.CELL_PHONE}
                className={classes.textField}
                value={this.state.cellPhone}
                onChange={this.handleChange("cellPhone")}
                placeholder="+1(809) 000-0000"
                InputProps={{
                  inputComponent: phoneMaskInput,
                }}
                fullWidth={true}
                error={this.state.cellPhoneError !== ""}
                helperText={this.state.cellPhoneError}
                required={true}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />

              <div style={{ display: "flex" }}>
                <div style={{ width: "37%" }}>
                  <TextField
                    label={translations.WORK_PHONE}
                    className={classes.textField}
                    value={this.state.workPhone}
                    placeholder="+1(809) 000-0000"
                    onChange={this.handleChange("workPhone")}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    fullWidth={true}
                    error={this.state.workPhoneError !== ""}
                    helperText={this.state.workPhoneError}
                    style={{ width: "89%" }}
                    required={true}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </div>

                <div style={{ width: "26%" }}>
                  <TextField
                    label={translations.WORK_PHONE_EXTENSION}
                    className={classes.textField}
                    value={this.state.extPhone}
                    onChange={this.handleChange("extPhone")}
                    fullWidth={true}
                    error={this.state.extPhoneError !== ""}
                    helperText={this.state.extPhoneError}
                    InputProps={{
                      inputComponent: attorneyNumberMaskInput,
                    }}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                    }}
                  />
                  <br />
                </div>
              </div>
              <TextField
                label={translations.EMAIL}
                className={classes.textField}
                value={this.state.email}
                onChange={this.handleChange("email")}
                fullWidth={true}
                error={this.state.emailError !== ""}
                helperText={this.state.emailError}
                required={true}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
        <br />
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    createProviderContact,
    deleteProviderContact,
    updateProviderContact,
  })(ProviderForm)
);
