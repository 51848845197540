import * as React from 'react';
import { connect } from 'react-redux';

import { Button, Grid } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from "@material-ui/icons/Delete";


import { deleteProfession, fetchProfession } from '../../../actions';
import AlertDialog from '../../../components/AlertDialog';
import Loading from '../../../components/Loading';
import { catchError, translations } from '../../../utils';
import {
    deleteProfession as deletePRO, fetchProfession as fetchPRO
} from '../../../utils/axios';

const styles = createStyles({
    checkboxLabel: {
        color: green[500],
        fontSize: 'medium'
    },
    button: {
        color: green[500]
    },
    controls: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    formControl: {
        marginTop: 20,
        width: '90%'
    },
    greenButton: {
        backgroundColor: green[500],
        color: 'white'
    },
    deleteButton: {
        backgroundColor: red[500],
        color: "white",
    },
    paper: {
        padding: 20
    },
    root: {
        height: '90vh',
        overflowY: 'auto',
        padding: 20,
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: '100%'
    },
});

class ProfessionsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            professionName: "",
            professionNameError: "",
            referenceCode: "",
            referenceCodeError: "",

            dialog: false,
            dialogTitle: '',
            errorMessage: '',
            loading: false,
            uniqueId: '',
            rohiId: '',
            rohiIeId: '',
            rohiMfsId: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);

    }

    
    componentDidMount() {
        this.setState({ loading: true });
        fetchPRO(
            this.props.user.token, this.props.professionId
        )
            .then((res) => {
                const curProfession = res.data

                this.setState({
                    loading: false,
                    professionName: curProfession.name || "",
                    referenceCode: curProfession.referenceCode || "",
                    rohiId: curProfession.rohiId || "",
                    rohiIeId: curProfession.rohiIeId || "",
                    rohiMfsId: curProfession.rohiMfsId || "",
                });
            })
    }

    handleChange(key) {
        return (event) => {
            this.setState({
                [key]: event.currentTarget.value,
                [key + 'Error']: ''
            });
        }
    }
    handleDelete() {

        this.setState({ loading: true });

        deletePRO(this.props.user.token, this.props.professionId).then(res => {
            this.props.changeSection(0);
            this.props.deleteProfession(this.props.professionId);
        }).catch((err) => {
            catchError(err, this)
        })
    }
    handleDialogClose() {
        this.setState({ dialog: false });
    }
    render() {
        const { classes } = this.props;
        return (
          <form className={classes.root}>
            <AlertDialog
              open={this.state.dialog}
              message={this.state.errorMessage}
              title={this.state.dialogTitle}
              handleClose={this.handleDialogClose}
            />
            <Paper className={classes.paper}>
              <Typography variant="h3" style={{ display: "inline" }}>
                {translations.PROFESSIONS}
              </Typography>
              {this.state.loading ? (
                <Loading message={translations.LOADING} />
              ) : (
                <div>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={6}>
                      <TextField
                        label={translations.PROFESSION}
                        className={classes.textField}
                        value={this.state.professionName}
                        onChange={this.handleChange("professionName")}
                        fullWidth={true}
                        disabled={true}
                        variant={"outlined"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <p style={{ color: "red" }}>
                        {this.state.professionNameError}
                      </p>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <TextField
                        label={translations.REFERENCE_NUMBER}
                        className={classes.textField}
                        value={this.state.referenceCode}
                        onChange={this.handleChange("referenceCode")}
                        fullWidth={true}
                        disabled={true}
                        variant={"outlined"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <p style={{ color: "red" }}>
                        {this.state.referenceCodeError}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={4}>
                      <TextField
                        label={"Rohi ID Barcelona"}
                        className={classes.textField}
                        value={this.state.rohiId}
                        disabled={true}
                        variant={"outlined"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={4}>
                      <TextField
                        label={"Rohi ID IE"}
                        className={classes.textField}
                        value={this.state.rohiIeId}
                        disabled={true}
                        variant={"outlined"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={4}>
                      <TextField
                        label={"Rohi ID MFS"}
                        className={classes.textField}
                        value={this.state.rohiMfsId}
                        disabled={true}
                        variant={"outlined"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <div className={classes.controls}>
                    <Button
                      variant="contained"
                      onClick={this.handleDelete}
                      className={classes.deleteButton}
                    >
                      <DeleteIcon />
                      &nbsp;
                      {translations.DELETE}
                    </Button>
                  </div>
                </div>
              )}
            </Paper>
          </form>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps, { deleteProfession, fetchProfession })(ProfessionsView)
);
