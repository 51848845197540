export const PaymentTypes = () => {
    return [
        {name: 'Cheque', value: 'Check'},
        {name: 'Efectivo', value: 'Cash'},
        {name: 'Nota de crédito', value: 'Credit note'},
        {name: 'Tarjeta', value: 'card'},
        {name: 'Transferencia bancaria', value: 'Bank transfer'},
        {name: 'Depósito bancario', value: 'Bank deposit'},
        {name: 'Capitalizado', value: 'Capitalized'},
    ]
}
export const ExpiryStates = () => {
    return [
        {name: 'COMPLETADO', value: 'Complete'},
        {name: 'ATRASADO', value: 'Late'},
        {name: 'PAGO PARCIAL', value: 'Partially paid'},
        {name: 'PROCESADO', value: 'Processed'},
        {name: 'PROGRAMADO', value: 'Programmed'},
        {name: 'EN PROCESO', value: 'On process'},
        {name: 'ERROR', value: 'Error'},
    ]
}

export const ProductTypeTranslations = () => {
    return [
        {name: 'Cuenta de ahorro', value: 'saving'},
        {name: 'Cuenta corriente', value: 'checking'},
        {name: 'Cuenta efectivo', value: 'cash'},
    ]
}
export const PrioritiesTranslations = () => {
    return [
        {name: 'Baja', value:'low'},
        {name: 'Media', value:'mid'},
        {name: 'Alta', value:'high'},
    ]
}
export const TicketStatusTranslations = () => {
    return [
        {name: 'Nuevo',value: 'new_request'},
        {name: 'Atrasado',value: 'delayed'},
        {name: 'Procesado',value: 'processed'},
        {name: 'Cancelado',value: 'cancelled'},
        {name: 'Liquidado',value: 'liquidated'},
        {name: 'Pendiente liquidación',value: 'pending_settlement'},
    ]
}
export const TicketTypeTranslations = () => {
    return [
        {name: 'Desembolso', value:'outlay'},
        {name: 'Solicitud de admisión', value:'new partner'},
        {name: 'Pagos', value:'payment'},
    ];
}