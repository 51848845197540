import React, { useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableHead,
  Checkbox,
  TableBody,
  TablePagination,
  IconButton,
  Collapse,
  Box,
  Typography,
  Tooltip,
} from "@material-ui/core";
import {
  useHandleSelectAllRows,
  useHandleSelectRow,
  useIsSelected,
} from "./CustomHooks";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    "& .ant-empty-img-1": {
      fill: theme.palette.type === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.type === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.type === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.type === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.type === "light" ? "0.8" : "0.08",
      fill: theme.palette.type === "light" ? "#f5f5f5" : "#fff",
    },
  },
  label: {
    marginTop: theme.spacing(1),
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#EEEEEE",
    },
    "&:hover": {
      backgroundColor: "#f5f5f5",
      cursor: "pointer",
    },
  },
  selected: {},
}));

function NoRowsOverlay(props) {
  const classes = useStyles();
  return (
    <TableRow style={{ marginTop: "50px", borderBottom: "2px solid #FFFFFF" }}>
      <TableCell colSpan={props.colSpan} style={{ textAlign: "center" }}>
        <div className={classes.root}>
          <svg
            width="120"
            height="100"
            viewBox="0 0 184 152"
            aria-hidden
            focusable="false"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(24 31.67)">
                <ellipse
                  className="ant-empty-img-5"
                  cx="67.797"
                  cy="106.89"
                  rx="67.797"
                  ry="12.668"
                />
                <path
                  className="ant-empty-img-1"
                  d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                />
                <path
                  className="ant-empty-img-2"
                  d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                />
                <path
                  className="ant-empty-img-3"
                  d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                />
              </g>
              <path
                className="ant-empty-img-3"
                d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
              />
              <g
                className="ant-empty-img-4"
                transform="translate(149.65 15.383)"
              >
                <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
              </g>
            </g>
          </svg>
          <div className={classes.label}>No hay información para mostrar</div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function GeneralTable(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (props.onDemandPageChange) {
      props.onDemandPageChange(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const checkLimitRow = (row, id) => {
    if (props.selected.length === props.limitSelection) {
      const selectedOption = props.selected.find(
        (item) => item?.uniqueId === id || item.id === id
      );
      return selectedOption ? false : true;
    }
    return false;
  };
  return (
    <Paper style={{ width: "100%" }}>
      {props.toolbar}
      <TableContainer
        style={{ height: props.height || "default" }}
        className="general-table-container"
      >
        <Table>
          <TableHead>
            <TableRow>
              {props.columns.map((item, index) => {
                return (
                  <TableCell
                    padding={item.id === "checkbox" ? "checkbox" : undefined}
                    key={`${index} - ${item.id}`}
                    align={item.align}
                    style={{ minWidth: item.minWidth }}
                  >
                    {item.id === "checkbox" && !props.removeSelectAll ? (
                      <Checkbox
                        indeterminate={
                          props.selected.length > 0 &&
                          props.selected.length < props.data.length
                        }
                        checked={
                          props.data.length > 0 &&
                          props.selected.length === props.data.length
                        }
                        onChange={useHandleSelectAllRows.bind(
                          this,
                          props.setSelected,
                          props.data
                        )}
                        inputProps={{
                          "aria-label": "seleccione todos los clientes",
                        }}
                        color="primary"
                      />
                    ) : item.tooltip ? (
                      <Tooltip title={item.tooltip} placement="top-start">
                        <span>{item.label}</span>
                      </Tooltip>
                    ) : (
                      <span>{item.label}</span>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.length < 1 ? (
              <NoRowsOverlay colSpan={props.columns.length} />
            ) : (
              props.data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const id = row?.uniqueId || row.id;
                  const idKeyName = row?.uniqueId ? "uniqueId" : "id";
                  const isItemSelected = props.selected
                    ? useIsSelected(id, idKeyName, props.selected)
                    : null;
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const rowKey = `tableRow-${index}-${id}`;
                  return (
                    <TableRow
                      key={rowKey}
                      tabIndex={-1}
                      className={`table-row ${classes.tableRow}`}
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                      classes={{ selected: classes.selected }}
                    >
                      {props.columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          // CHECK FOR CHECKBOX IN ROW
                          column.id === "checkbox" ? (
                            <TableCell
                              key={column.id + "-" + index}
                              padding="checkbox"
                              align={column.align}
                            >
                              <Checkbox
                                onClick={(event) =>
                                  useHandleSelectRow(
                                    event,
                                    id,
                                    props.setSelected,
                                    props.selected,
                                    row,
                                    idKeyName
                                  )
                                }
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                color="primary"
                                disabled={
                                  props.disableSelection ||
                                  (props.limitSelection &&
                                    checkLimitRow(row, id))
                                }
                              />
                            </TableCell>
                          ) : (
                            <TableCell
                              key={column.id + "-" + index}
                              align={column.align}
                              onClick={
                                props.rowClick && props.rowClick.bind(this, row)
                              }
                            >
                              {column.element
                                ? column.element(row, row[column.id])
                                : value || column.defaultValue || ""}
                            </TableCell>
                          )
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.noPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Filas por página:"}
        />
      )}
    </Paper>
  );
}

function Row(props) {
  const { row } = props;
  // props.info.detail.some(elem => row.selected.includes(elem))
  // props.info.detail.every(elem => row.selected.includes(elem))
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const data = !row.noDetailPagination
    ? props.info.detail.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    : props.info.detail;

  useEffect(() => {
    // console.log(props.id)
    row.handleSelected(props.id, selected);
  }, [selected]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <TableRow
        key={"expiry-obj-" + props.index + "-" + props.id}
        tabIndex={-1}
        className={`table-row ${classes.tableRow}`}
        aria-checked={props.isItemSelected}
        selected={props.isItemSelected}
        classes={{ selected: classes.selected }}
      >
        {row.columns.map((column) => {
          const value = props.info[column.id];
          return column.id === "checkbox" ? (
            <TableCell padding="checkbox" key={column.id} align={column.align}>
              <Checkbox
                onClick={(event) =>
                  useHandleSelectRow(
                    event,
                    props.id,
                    setSelected,
                    selected,
                    props.info,
                    props.idKeyName
                  )
                }
                checked={props.isItemSelected}
                inputProps={{ "aria-labelledby": props.labelId }}
                color="primary"
              />
            </TableCell>
          ) : column.id === "collapse" ? (
            <TableCell key={column.id} align={column.align}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          ) : (
            <TableCell key={column.id} align={column.align}>
              {column.element
                ? column.element(props.info, props.info[column.id])
                : value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                style={{
                  backgroundColor: "#4caf50",
                  color: "#fff",
                  textAlign: "center",
                  borderRadius: "4px",
                }}
              >
                {row.subTitle || "VENCIMIENTOS"}
              </Typography>
              <TableContainer
                style={{ height: props.height || "default" }}
                className="general-table-container"
              >
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {row.detailColumns.map((item) => {
                        return (
                          <TableCell
                            padding={
                              item.id === "checkbox" ? "checkbox" : undefined
                            }
                            key={item.id + "-" + props.id}
                            align={item.align}
                            style={{ minWidth: item.minWidth }}
                          >
                            {item.id === "checkbox" ? (
                              <Checkbox
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < props.info.detail.length
                                }
                                checked={
                                  props.info.detail.length > 0 &&
                                  selected.length === props.info.detail.length
                                }
                                onChange={useHandleSelectAllRows.bind(
                                  this,
                                  setSelected,
                                  props.info.detail
                                )}
                                inputProps={{
                                  "aria-label": "seleccione todos los clientes",
                                }}
                                color="primary"
                              />
                            ) : item.id === "collapse" ? (
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                              >
                                {open ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            ) : (
                              item.label
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.info.detail.length < 1 ? (
                      <NoRowsOverlay colSpan={row.detailColumns.length} />
                    ) : (
                      data.map((rows, index) => {
                        const id =
                          rows.uniqueId || rows.id_vencimiento || rows.id;
                        const idKeyName = rows.uniqueId
                          ? "uniqueId"
                          : rows.id_vencimiento
                          ? "id_vencimiento"
                          : "id";
                        const isItemSelected = selected
                          ? useIsSelected(id, idKeyName, selected)
                          : null;
                        const labelId = `enhanced-table-checkbox-${props.index +
                          "-" +
                          index}`;
                        return (
                          <TableRow
                            key={"expiry-obj-" + index + "-" + id}
                            tabIndex={-1}
                            className={`table-row ${classes.tableRow}`}
                            aria-checked={isItemSelected}
                            selected={isItemSelected}
                            classes={{ selected: classes.selected }}
                          >
                            {row.detailColumns.map((column) => {
                              const value = rows[column.id];
                              return column.id === "checkbox" ? (
                                <TableCell
                                  padding="checkbox"
                                  key={column.id}
                                  align={column.align}
                                >
                                  <Checkbox
                                    onClick={(event) =>
                                      useHandleSelectRow(
                                        event,
                                        id,
                                        setSelected,
                                        selected,
                                        rows,
                                        idKeyName
                                      )
                                    }
                                    checked={isItemSelected}
                                    inputProps={{ "aria-labelledby": labelId }}
                                    color="primary"
                                  />
                                </TableCell>
                              ) : (
                                <TableCell key={column.id} align={column.align}>
                                  {column.element
                                    ? column.element(rows, rows[column.id])
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {!row.noDetailPagination && (
                <TablePagination
                  // style={{ backgroundColor: '#4caf50', color: '#fff', borderRadius: '4px' }}
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={props.info.detail.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={"Filas por página:"}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function GeneralTableExpandable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [data, setData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const paginationCalc = !props.noPagination
      ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : props.data;
    setData([...paginationCalc]);
  }, [props.data, page, rowsPerPage]);
  return (
    <Paper style={{ width: "100%" }}>
      {/* <TableContainer style={{maxHeight:props.maxHeight || 440}}> */}
      {props.toolbar}
      <TableContainer
        style={{ height: props.height || "default" }}
        className="general-table-container"
      >
        <Table>
          <TableHead>
            <TableRow>
              {props.columns.map((item) => {
                return (
                  <TableCell
                    padding={item.id === "checkbox" ? "checkbox" : undefined}
                    key={item.id}
                    align={item.align}
                    style={{ minWidth: item.minWidth }}
                  >
                    {item.id === "checkbox" ? (
                      <Checkbox
                        indeterminate={
                          props.selected.length > 0 &&
                          props.selected.length < props.data.length
                        }
                        checked={
                          props.data.length > 0 &&
                          props.selected.length === props.data.length
                        }
                        // checked={true}
                        onChange={useHandleSelectAllRows.bind(
                          this,
                          props.setSelected,
                          props.data
                        )}
                        inputProps={{
                          "aria-label": "seleccione todos los clientes",
                        }}
                        color="primary"
                      />
                    ) : (
                      item.label
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.length < 1 ? (
              <NoRowsOverlay colSpan={props.columns.length} />
            ) : (
              data.map((row, index) => {
                if (row) {
                  const id = row.uniqueId || row.id_dbs_rohi || row.id;
                  const idKeyName = row.uniqueId
                    ? "uniqueId"
                    : row.id_dbs_rohi
                    ? "id_dbs_rohi"
                    : "id";
                  const isItemSelected = props.selected
                    ? useIsSelected(id, idKeyName, props.selected)
                    : null;
                  const labelId = `enhanced-table-checkbox-${index}`;
                  if (props.collapse) {
                    // return (
                    //     props.columns.map(column => {
                    // const value = row[column.id];
                    return (
                      <Row
                        key={"expiry-obj-" + index + "-" + id}
                        row={props}
                        index={index}
                        id={id}
                        isItemSelected={isItemSelected}
                        info={row}
                        idKeyName={idKeyName}
                        labelId={labelId}
                      />
                    );
                    //     })
                    // );
                  } else {
                    return (
                      <TableRow
                        key={"expiry-obj-" + index + "-" + id}
                        tabIndex={-1}
                        className={`table-row ${classes.tableRow}`}
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                        classes={{ selected: classes.selected }}
                      >
                        {props.columns.map((column) => {
                          // Django converts snake property names to camel case.
                          // using the next function to match:
                          // const propertyToCamelcase = toCamel(column.id);
                          const value = row[column.id];
                          return column.id === "checkbox" ? (
                            <TableCell
                              padding="checkbox"
                              key={column.id}
                              align={column.align}
                            >
                              <Checkbox
                                onClick={(event) =>
                                  useHandleSelectRow(
                                    event,
                                    id,
                                    props.setSelected,
                                    props.selected,
                                    row,
                                    idKeyName
                                  )
                                }
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                color="primary"
                              />
                            </TableCell>
                          ) : (
                            <TableCell key={column.id} align={column.align}>
                              {column.element
                                ? column.element(row, value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }
                }
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.noPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Filas por página:"}
        />
      )}
    </Paper>
  );
}

export { GeneralTable, NoRowsOverlay, GeneralTableExpandable };
