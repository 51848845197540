import { AxiosError } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';

import { Button, Paper, TextField, Typography } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { createStyles, withStyles } from '@material-ui/core/styles';
import DocumentIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';

import { fetchAccountingRecord, updateAccountingRecord } from '../../../actions';
import AlertDialog from '../../../components/AlertDialog';
import Loading from '../../../components/Loading';
import { catchError, resetError, successHandler, translations } from '../../../utils';
import {
    fetchAccountingRecord as fetch, updateAccountingRecord as update
} from '../../../utils/axios';
import AccountingTable from '../AccountingRecordsTable/AccountingTable';

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "50%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    marginTop: 20,
    width: "50%",
  },
  textField_2: {
    display: "flex",
    marginTop: 20,
    width: "30%",
  },
});

interface IAccountingRecordViewProps {
  accountingRecordId: string;
  accountingRecords: IAccountingRecord[];
  classes: {
    [key: string]: string;
  };
  user: IUser;
  changeSection(sectionNumber: number): void;
  fetchAccountingRecord(
    accountingRecord: IAccountingRecord
  ): IAction<IAccountingRecord[]>;
  updateAccountingRecord(
    accountingRecord: IAccountingRecord
  ): IAction<IAccountingRecord[]>;
}
interface IAccountingRecordViewState {
  [key: string]: string | number | boolean | string[] | undefined;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;

  editIdx: number;

  accountingId: string;
  creditTotal: number;
  debitTotal: number;
  difference: number;
  financialId: string;
  notes: string;
  outlayId: string;
  recordData: string[];
  recordStatus: string;
  referenceCode: string;
  ticketId: string;
  transactionDate: string;

  uniqueId: string;
}
class AccountingRecordView extends React.Component<
  IAccountingRecordViewProps,
  IAccountingRecordViewState
> {
  public state: IAccountingRecordViewState = {
    accountingId: "",
    creditTotal: 0,
    debitTotal: 0,
    dialog: false,
    dialogTitle: "",
    difference: 0,
    editIdx: -1,
    errorMessage: "",
    financialId: "",
    loading: false,
    notes: "",
    outlayId: "",
    recordData: [],
    recordStatus: "",
    referenceCode: "",
    ticketId: "",
    transactionDate: "",
    uniqueId: "",
  };
  constructor(props: IAccountingRecordViewProps) {
    super(props);

    // core events
    // this.generateFile = this.generateFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleStartEdit = this.handleStartEdit.bind(this);
    this.handleStopEdit = this.handleStopEdit.bind(this);
    this.handleChangeTableData = this.handleChangeTableData.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  // public generateFile() {
  //   this.setState({ loading: true });
  //   axios({
  //     headers: {
  //       Authorization: "Bearer " + this.props.user.token,
  //     },
  //     method: "post",
  //     responseType: "blob",
  //     url: `${API_KEY}/tms/${this.state.requestType}-ticket/${this.state.uniqueId}/download`,
  //   })
  //     .then((res) => {
  //       this.setState({ loading: false });
  //       saveAs(res.data, `${this.state.title}.docx`);
  //     })
  //     .catch((err) => {
  //       catchError(err, this);
  //     });
  // }

  public componentDidMount() {
    fetch(this.props.user.token, this.props.accountingRecordId)
      .then((res) => {
        const curAR = res.data as IAccountingRecord;

        this.setState({
          accountingId: curAR.accountingId || "",
          creditTotal: curAR.creditTotal || 0,
          debitTotal: curAR.debitTotal || 0,
          difference: curAR.difference || 0,
          financialId: curAR.financialId || "",
          notes: curAR.notes || "",
          outlayId: curAR.outlayId || "",
          recordData: curAR.recordData || [],
          recordStatus: curAR.recordStatus || "",
          referenceCode: curAR.referenceCode || "",
          ticketId: curAR.ticketId || "",
          transactionDate: curAR.transactionDate || "",
          uniqueId: curAR.uniqueId || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleRemove = (i: any) => {
    this.setState({
      recordData: this.state.recordData.filter((row: any, j: any) => j !== i),
    });
  };

  public handleStartEdit = (i: any) => {
    this.setState({ editIdx: i });
  };

  public handleStopEdit = () => {
    this.setState({ editIdx: -1 });
  };

  public handleChangeTableData = (i: any, x: any) => {
    this.setState((state) => ({
      recordData: state.recordData.map((row: any, j: any) =>
        j === i ? x : row
      ),
    }));
    this.handleStopEdit();
  };

  public handleSave(data: any) {
    this.setState({
      recordData: data,
    });
  }

  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.currentTarget.value });
    };
  }

  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    const accountingRecord: IAccountingRecord = {
      accountingId: state.accountingId,
      creditTotal: state.creditTotal,
      debitTotal: state.debitTotal,
      difference: state.difference,
      financialId: state.financialId,
      notes: state.notes,
      outlayId: state.outlayId,
      recordData: state.recordData,
      recordStatus: state.recordStatus,
      referenceCode: state.referenceCode,
      ticketId: state.ticketId,
      transactionDate: state.transactionDate,
    };

    this.setState({ loading: true });

    update(this.props.user.token, this.state.uniqueId, accountingRecord)
      .then((res) => {
        this.props.updateAccountingRecord(res.data as IAccountingRecord);
        successHandler(res, this, `Estado de documento`);
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }

  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    const headersAR = [
      { name: "Cuenta", prop: "account", type: "text" },
      {
        name: "Tercero",
        prop: "thirdMember",
        type: "text",
      },
      { name: "Debito", prop: "debit", type: "number" },
      { name: "Credito", prop: "credit", type: "number" },
    ];
    
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">Procesados</Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={classes.greenButton}
                // onClick={this.generateFile}
                style={{ marginRight: 10, marginTop: 25 }}
              >
                <DocumentIcon />
                &nbsp;
                {translations.GENERATE_DOCUMENT}
              </Button>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  label="Id Financiero"
                  variant="outlined"
                  className={classes.textField_2}
                  value={this.state.financialId}
                  onChange={this.handleChange("financialId")}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label="Fecha de Transaccion"
                  variant="outlined"
                  className={classes.textField_2}
                  value={this.state.transactionDate}
                  onChange={this.handleChange("transactionDate")}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  label="Id de Desembolso"
                  variant="outlined"
                  className={classes.textField_2}
                  value={this.state.outlayId}
                  onChange={this.handleChange("outlayId")}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label="Numero de ticket"
                  variant="outlined"
                  className={classes.textField_2}
                  value={this.state.ticketId}
                  onChange={this.handleChange("ticketId")}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label="Id Contable"
                  variant="outlined"
                  className={classes.textField_2}
                  value={this.state.accountingId}
                  onChange={this.handleChange("accountingId")}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
              </div>
              <br />
              <AccountingTable
                data={this.state.recordData}
                editIdx={this.state.editIdx}
                handleChange={this.handleChangeTableData}
                handleRemove={this.handleRemove}
                handleSave={this.handleSave}
                handleStartEdit={this.handleStartEdit}
                handleStopEdit={this.handleStopEdit}
                header={headersAR}
              />
              <div style={{ display: "flex" }}>
                <div
                  style={{ display: "flex", flexFlow: "column", width: "50%" }}
                >
                  <TextField
                    label="Numero de referencia"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.referenceCode}
                    onChange={this.handleChange("referenceCode")}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <TextField
                    label="Notas"
                    variant="outlined"
                    className={classes.textField}
                    multiline={true}
                    rows={3}
                    rowsMax={5}
                    value={this.state.notes}
                    onChange={this.handleChange("notes")}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </div>
                <div
                  style={{ display: "flex", flexFlow: "column", width: "50%" }}
                >
                  <TextField
                    label="Total de credito"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.creditTotal}
                    onChange={this.handleChange("creditTotal")}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <TextField
                    label="Total de Debito"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.debitTotal}
                    onChange={this.handleChange("debitTotal")}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </div>
              </div>
              <div className={classes.controls}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    accountingRecords: state.accountingRecords,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchAccountingRecord, updateAccountingRecord })(
    AccountingRecordView
  )
);
