import { AxiosError } from "axios";
import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import phoneMaskInput from "src/components/TextMaskInputComponents/phoneWithOneMaskInput";
import FileUploadSelectInput from "src/containers/FileUpload/FileUploadSelectInput";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import DocumentIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";

import {
  deleteAddendumContract,
  fetchAddendumContract,
  updateAddendumContract,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import moneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import {
  catchError,
  debtorsValidation,
  filesGeneration,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils/";
import {
  deleteAddendumContract as deleteAC,
  fetchAddendumContract as fetchAC,
  updateAddendumContract as updateAC
} from "../../../utils/axios";
import LoanContractSelectInput from "../../LoanContract/LoanContractSelectInput";
import Address from "../../../components/Address/Address";
import { MenuItem } from "@material-ui/core";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "50%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface IAddendumContractViewProps {
  addendumContracts: IAddendumContract[];
  addendumContractId: string;
  classes: { [key: string]: string };

  user: IUser;
  changeSection(sectionNumber: number): void;
  deleteAddendumContract(uniqueId: string): IAction<IAddendumContract[]>;
  fetchAddendumContract(
    addendumContract: IAddendumContract
  ): IAction<IAddendumContract[]>;
  updateAddendumContract(
    addendumContract: IAddendumContract
  ): IAction<IAddendumContract[]>;
}
interface IAddendumContractViewState {
  [key: string]:
  | string
  | number
  | boolean
  | string[]
  | IBankAccount[]
  | undefined;

  ADD: string;
  addendumType: string;
  addendumTypeError: string;
  city: string;
  cityError: string;
  country: string;
  countryError: string;
  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
  loanContract: string[];
  loanContractError: string;
  modificationDate: string;
  modificationDateError: string;
  newEmail: string;
  newEmailError: string;
  newInterestRate: string;
  region: string;
  regionError: string;
  newInterestRateError: string;
  newOutlayAmount: number;
  newOutlayAmountCurrency: string;
  newOutlayAmountCurrencyError: string;
  newOutlayAmountError: string;
  newPhone: string;
  newPhoneError: string;
  state: string;
  stateError: string;
  status: string;
  reference: string;
  referenceError: string;
  sector: string;
  sectorError: string;
  title: string; // done
  titleError: string; // done
  uniqueId: string;
}
class AddendumContracView extends React.Component<
  IAddendumContractViewProps,
  IAddendumContractViewState
> {
  public state: IAddendumContractViewState = {
    ADD: "",
    addendumType: "outlay",
    addendumTypeError: "",
    city: "",
    cityError: "",
    country: "",
    countryError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    loanContract: [],
    region: "",
    regionError: "",
    loanContractError: "",
    modificationDate: new Date().toISOString().split("T")[0],
    modificationDateError: "",
    newEmail: "",
    newEmailError: "",
    newInterestRate: "5.5",
    newInterestRateError: "",
    newOutlayAmount: 0,
    newOutlayAmountCurrency: "DOP",
    newOutlayAmountCurrencyError: "",
    newOutlayAmountError: "",
    newPhone: "",
    newPhoneError: "",
    state: "",
    stateError: "",
    status:"",
    reference: "",
    referenceError: "",
    sector: "",
    sectorError: "",
    title: "", // done
    titleError: "",
    uniqueId: "",
  };
  constructor(props: IAddendumContractViewProps) {
    super(props);

    // handle loancontract
    this.addLoanContract = this.addLoanContract.bind(this);
    this.removeLoanContract = this.removeLoanContract.bind(this);

    // core events
    this.generateFile = this.generateFile.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    // this.loadLoanContract = this.loadLoanContract.bind(this);

    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }
  public addLoanContract(contract: ILoanContract) {
    this.setState({ loanContract: [contract.uniqueId || ""] });
  }
  public removeLoanContract(id: string) {
    this.setState({ loanContract: [] });
  }
  public async generateFile() {
    this.setState({ loading: true });
    if ( await debtorsValidation(this, this.props.user.token, this.state.loanContract, true)) {
      const url= `/lending/addendum-contracts/${this.state.uniqueId}/print/`
      await filesGeneration(url, this.props.user.token);
      this.setState({ loading: false });
    }
  }
  public changeStatus(status: string) {
    return () => {
      this.setState({ status });
    };
  }

  public componentDidMount() {
    fetchAC(this.props.user.token, this.props.addendumContractId)
      .then((res) => {
        const curAddendumContract = res.data as IAddendumContract;

        this.setState({
          ADD: `lending/addendum-contracts/${curAddendumContract.uniqueId}/upload_attachment/`,
          addendumType: curAddendumContract.addendumType || "",
          city: curAddendumContract.newAddress
            ? curAddendumContract.newAddress.city
            : "",
          country: curAddendumContract.newAddress
            ? curAddendumContract.newAddress.country
            : "",
          region: curAddendumContract.newAddress
            ? curAddendumContract.newAddress.region
            : "",
          loanContract: curAddendumContract.loanContract
            ? [curAddendumContract.loanContract]
            : [],
          modificationDate: curAddendumContract.modificationDate || "",
          newEmail: curAddendumContract.newEmail
            ? curAddendumContract.newEmail
            : "",
          newInterestRate: curAddendumContract.newInterestRate || "",
          newOutlayAmount: curAddendumContract.newOutlayAmount || 0,
          newOutlayAmountCurrency:
            curAddendumContract.newOutlayAmountCurrency || "",
          newPhone: curAddendumContract.newPhone || "",
          state: curAddendumContract.newAddress
            ? curAddendumContract.newAddress.state
            : "",
          reference: curAddendumContract.newAddress
            ? curAddendumContract.newAddress.reference
            : "",
          sector: curAddendumContract.newAddress
            ? curAddendumContract.newAddress.sector
            : "",
          title: curAddendumContract.title || "",
          uniqueId: curAddendumContract.uniqueId || "",
          status: curAddendumContract.status || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public handleSelect(key: string, value: string) {
    this.setState({
      [key]: value,
    });
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    let addendumContract: IAddendumContract = {
      addendumType: state.addendumType,
      loanContract: state.loanContract[0] || "",
      modificationDate: state.modificationDate,
      newAddress: {
        city: state.city,
        country: state.country,
        state: state.state,
        region: state.region,
        reference: state.reference,
        sector: titleCaseHandler(state.sector),
      },
      newEmail: state.addendumType === "contact" ? state.newEmail: "",
      newInterestRate: state.addendumType === "interest" ? state.newInterestRate : "0.0",
      newOutlayAmount: state.addendumType === "outlay" ? 
        state.newOutlayAmount.toString() === "" ? 0 : state.newOutlayAmount
        : 0,
      newOutlayAmountCurrency: state.newOutlayAmountCurrency,
      newPhone: state.addendumType === "contact" ? state.newPhone : "",
      title: titleCaseHandler(state.title),
      status: state.status
    };

    this.setState({ loading: true });

    const { addendumType, newEmail } = this.state;

    if (addendumType !== "contact") {
      addendumContract = Object.assign(addendumContract, {
        newAddress: null,
      });
    }

    if (newEmail.trim() === "") {
      addendumContract = Object.assign(addendumContract, { newEmail: null });
    }

    updateAC(this.props.user.token, this.state.uniqueId, addendumContract)
      .then((res) => {
        successHandler(res, this, translations.ADDENDUM);
        this.props.updateAddendumContract(res.data as IAddendumContract);
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }
  public handleDelete() {
    if (confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteAC(this.props.user.token, this.props.addendumContractId)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteAddendumContract(this.props.addendumContractId);
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <FileUploadSelectInput
            contractId={this.state.uniqueId}
            url={this.state.ADD}
            description="addendum"
          />
          <Typography variant="h3">{translations.ADDENDUM}</Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={classes.greenButton}
                onClick={this.generateFile}
                style={{ marginRight: 10, marginTop: 25 }}
              >
                <DocumentIcon />
                &nbsp;
                {translations.GENERATE_DOCUMENT}
              </Button>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="status" shrink={true}>
                  {translations.STATUS}
                </InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                  required={true}
                  native={true}
                  inputProps={{
                    id: "status",
                    name: "status",
                  }}
                >
                  <option value="pending_signature">
                    {translations.PENDING_SIGNATURE}
                  </option>
                  <option value="internal_signature_pending">
                    {translations.INTERNAL_SIGNATURE_PENDING}
                  </option>
                  <option value="in_legalization_process">
                    {translations.IN_LEGALIZATION_PROCESS}
                  </option>
                  <option value="completed">{translations.COMPLETED}</option>
                  <option value="cancelled">{translations.CANCELLED}</option>
                  <option value="archived">{translations.ARCHIVED}</option>
                </Select>
              </FormControl>
              <TextField
                label={translations.TITLE}
                className={classes.textField}
                value={titleCaseHandler(this.state.title)}
                onChange={this.handleChange("title")}
                fullWidth={true}
                required={true}
                error={this.state.titleError !== ""}
                helperText={this.state.titleError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.LOAN_CONTRACT}
                </Typography>
                <LoanContractSelectInput
                  selectedLoanContractsId={this.state.loanContract}
                  add={this.addLoanContract}
                  delete={this.removeLoanContract}
                />
                <p className={classes.errorInput}>
                  {this.state.loanContractError}
                </p>
              </div>
              <TextField
                label={translations.MODIFICATION_DATE}
                className={classes.textField}
                value={this.state.modificationDate}
                type="date"
                onChange={this.handleChange("modificationDate")}
                required={true}
                error={this.state.modificationDateError !== ""}
                helperText={this.state.modificationDateError}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="addendumType" shrink={true}>
                  {translations.ADDENDUM_TYPE}
                </InputLabel>
                <Select
                  value={this.state.addendumType}
                  onChange={this.handleSelectChange("addendumType")}
                  required={true}
                  error={this.state.addendumTypeError !== ""}
                  inputProps={{
                    id: "addendumType",
                    name: "addendumType",
                  }}
                >
                  <MenuItem value="outlay">{translations.CREDIT_LINE}</MenuItem>
                  <MenuItem value="interest">{translations.INTEREST}</MenuItem>
                  <MenuItem value="contact">{translations.CONTACT}</MenuItem>
                </Select>
              </FormControl>

              {this.state.addendumType === "outlay" ? (
                <>
                  <br />
                  <TextField
                    className={classes.formControl}
                    label={translations.NEW_CREDIT_LINE_AMOUNT}
                    value={this.state.newOutlayAmount}
                    onChange={this.handleCurrencyInputChange("newOutlayAmount")}
                    style={{ width: "30%" }}
                    error={this.state.newOutlayAmountError !== ""}
                    helperText={this.state.newOutlayAmountError}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                  <FormControl
                    style={{ marginTop: "33px", minWidth: 0, width: "100px" }}
                    error={this.state.newOutlayAmountCurrencyError !== ""}
                  >
                    <Select
                      value={this.state.newOutlayAmountCurrency}
                      onChange={this.handleSelectChange(
                        "newOutlayAmountCurrency"
                      )}
                      native={true}
                      inputProps={{
                        id: "newOutlayAmountCurrency",
                        name: "newOutlayAmountCurrency",
                      }}
                    >
                      <option value="DOP">{translations.DOP}</option>
                      <option value="USD">{translations.USD}</option>
                    </Select>
                  </FormControl>
                </>
              ) : this.state.addendumType === "interest" ? (
                <>
                  <TextField
                    label={`${translations.NEW_INTEREST_RATE}: ${this.state.newInterestRate}%`}
                    className={classes.textField}
                    value={this.state.newInterestRate}
                    type="number"
                    onChange={this.handleChange("newInterestRate")}
                    error={this.state.newInterestRateError !== ""}
                    helperText={this.state.newInterestRateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 100,
                      min: 0,
                      step: 0.01,
                    }}
                  />
                </>
              ) : (
                <>
                  <TextField
                    label={translations.EMAIL}
                    className={classes.textField}
                    placeholder="mycompany@email.com"
                    type="email"
                    value={this.state.newEmail}
                    onChange={this.handleChange("newEmail")}
                    fullWidth={true}
                    error={this.state.newEmailError !== ""}
                    helperText={this.state.newEmailError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <TextField
                    label={translations.NEW_PHONE}
                    className={classes.textField}
                    value={this.state.newPhone}
                    onChange={this.handleChange("newPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    fullWidth={true}
                    error={this.state.newPhoneError !== ""}
                    helperText={this.state.newPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                    }}
                  />
                  <Address
                    city={this.state.city}
                    cityError={this.state.cityError}
                    country={this.state.country}
                    countryError={this.state.countryError}
                    handleChange={this.handleChange}
                    handleSelect={this.handleSelect}
                    reference={this.state.reference}
                    referenceError={this.state.referenceError}
                    region={this.state.region}
                    regionError={this.state.regionError}
                    sector={this.state.sector}
                    sectorError={this.state.sectorError}
                    state={this.state.state}
                    stateError={this.state.stateError}
                    title={translations.ADDRESS}
                  />
                </>
              )}
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    addendumContracts: state.addendumContracts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteAddendumContract,
    fetchAddendumContract,
    updateAddendumContract,
  })(AddendumContracView)
);
