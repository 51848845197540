import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import red from "@material-ui/core/colors/red";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  FormControlLabel,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  Grid,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import {
  deleteInterest,
  fetchInterest,
  updateInterest,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  translations,
  INTEREST_CALCULATOR_TYPES,
  headersCoopSanForm,
  headersPlannedSavingsForm,
  headersFinancialCertificatesForm,
  headerApLevel,
  headerMembershipBreakdown,
  headerFunctional,
} from "../../../utils";
import {
  deleteInterest as deleteIN,
  fetchAssignees,
  fetchInterest as fetchIN,
  updateInterest as updateIN,
} from "../../../utils/axios";
import moneyInput from "src/components/TextMaskInputComponents/moneyInput";
import InterestTable from "../InterestTable";
import jwtDecode from "jwt-decode";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#4CAF50",
      },
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
      color: "red",
    },
    "& input:focus + fieldset, & select:focus + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& label.Mui-focused": {
      color: "green",
    },
    margin: 8,
  },
})(TextField);

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    // marginTop: 20,
    minWidth: 300,
    width: "100%",
  },
  gridFormControl: {
    margin: 8,
    width: "100%",
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
});

interface IInterestViewProps {
  interests: IInterest[];
  interestId: string;

  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    deleteButton: string;
    formControl: string;
    gridFormControl: string;
    greenButton: string;
    input: string;
    paper: string;
    root: string;
    textField: string;
  };

  user: IUser;
  changeSection(sectionNumber: number): void;
  deleteInterest(uniqueId: string): IAction<IInterest[]>;
  fetchInterest(interest: IInterest): IAction<IInterest[]>;
  updateInterest(interest: IInterest): IAction<IInterest[]>;
}
interface IInterestViewState {
  [key: string]: string | string[] | number | boolean | Blob;

  calculatorType: string;
  minimumBalance: number;
  minimumBalanceError: string;
  minimumQuota: number;
  cuttingDay: number;
  contributionAmount: number;
  savingsBook: boolean;
  descriptions: any[];
  roles: any[];
  minTimeLimit: number;
  maxTimeLimit: number;
  editIdx: number;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
}
class InterestView extends React.Component<
  IInterestViewProps,
  IInterestViewState
> {
  public state: IInterestViewState = {
    descriptions: [],
    calculatorType: "",
    savingsBook: false,
    minimumQuota: 0,
    minimumBalance: 0,
    minimumBalanceError: "",
    cuttingDay: 0,
    contributionAmount: 0,
    minTimeLimit: 0,
    maxTimeLimit: 0,
    roles: [],
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    editIdx: -1,
  };

  constructor(props: IInterestViewProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);

    this.handleCheckInputChange = this.handleCheckInputChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.handleSave = this.handleSave.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleStartEdit = this.handleStartEdit.bind(this);
    this.handleStopEdit = this.handleStopEdit.bind(this);
    this.handleChangeTD = this.handleChangeTD.bind(this);
  }
  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public componentDidMount() {
    this.setState({ loading: true });
    const userId = jwtDecode<IUser>(this.props.user.token)?.user_id;
    fetchAssignees({ token: this.props.user.token, id: userId }).then(
      (response) => {
        const data = response.data[0];
        this.setState({
          roles: data.groups,
        });
      }
    );

    fetchIN(this.props.user.token, this.props.interestId)
      .then((res) => {
        const curInterest = res.data as IInterest;

        this.setState({
          descriptions: curInterest.description || [],
          calculatorType: curInterest.calculatorType || "",
          savingsBook: curInterest.savingsBook || false,
          contributionAmount: curInterest.contributionAmount || 0,
          minimumQuota: curInterest.minimumQuota || 0,
          minimumBalance: curInterest.minimumBalance || 0,
          minTimeLimit: curInterest.minTimeLimit || 0,
          maxTimeLimit: curInterest.maxTimeLimit || 0,
          cuttingDay: curInterest.cuttingDay || 0,
          uniqueId: curInterest.uniqueId || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      })
      .finally(() => this.setState({ loading: false }));
  }
  public handleCheckInputChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (key === "calculatorType") {
        this.setState({
          descriptions: [],
        });
      }
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    const interest: IInterest = {
      calculatorType: this.state.calculatorType,
      minimumBalance: this.state.minimumBalance,
      minimumQuota: this.state.minimumQuota,
      cuttingDay: this.state.cuttingDay,
      contributionAmount: this.state.contributionAmount,
      savingsBook: this.state.savingsBook,
      description: this.state.descriptions,
      minTimeLimit: this.state.minTimeLimit,
      maxTimeLimit: this.state.maxTimeLimit,
    };

    this.setState({ loading: true });

    updateIN(this.props.user.token, String(this.state.uniqueId), interest)
      .then((res) => {
        this.props.fetchInterest(
          (res.data.results ? res.data.results : res.data) as IInterest
        );
        this.props.updateInterest(
          (res.data.results ? res.data.results : res.data) as IInterest
        );
        successHandler(res, this, translations.INTEREST);
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      })
      .finally(() => this.setState({ loading: false }));
  }
  public handleReset() {
    this.setState({
      descriptions: [],
      calculatorType: "",
      savingsBook: false,
      minimumQuota: 0,
      minimumBalance: 0,
      cuttingDay: 0,
      dialog: false,
      dialogTitle: "",
      errorMessage: "",
      loading: false,
      editIdx: -1,
    });
  }

  public handleRemove = (i: any) => {
    this.setState((prevState) => ({
      descriptions: prevState.descriptions.filter((row, j) => j !== i),
    }));
  };

  public handleStartEdit = (i: any) => {
    this.setState({ editIdx: i });
  };

  public handleStopEdit = () => {
    this.setState({ editIdx: -1 });
  };

  public handleChangeTD = (i: any, x: any) => {
    this.setState((prevState) => ({
      descriptions: prevState.descriptions.map((row, j) => (j === i ? x : row)),
    }));
    this.handleStopEdit();
  };

  public handleSave(data: any) {
    this.setState({
      descriptions: data,
    });
  }

  public handleDelete() {
    if (confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });

      deleteIN(this.props.user.token, this.props.interestId)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteInterest(this.props.interestId);
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        })
        .finally(() => this.setState({ loading: false }));
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    const validators = [
      {
        value: "minimumBalance",
        display: [
          "in_sight",
          "youth",
          "institutional",
          "financial_certificates",
        ],
      },
      { value: "minimumQuota", display: ["coopsan"] },
      {
        value: "cuttingDay",
        display: ["in_sight", "youth", "institutional", "planned_savings"],
        min: [
          "coopsan",
          "financial_certificates",
          "contributions",
          "service_order",
          "digital_loans",
          "ap_lvl_na",
          "ap_lvl_sl",
          "membership_breakddown",
          "functional"
        ],
      },
      {
        value: "minTimeLimit",
        display: ["planned_savings", "financial_certificates"],
      },
      {
        value: "maxTimeLimit",
        display: ["planned_savings", "financial_certificates"],
      },
      { value: "savingsBook", display: ["in_sight", "youth", "institutional"] },
      {
        value: "descriptions",
        display: [
          "in_sight",
          "youth",
          "institutional",
          "planned_savings",
          "coopsan",
          "financial_certificates",
          "service_order",
          "digital_loans",
          "ap_lvl_na",
          "ap_lvl_sl",
          "membership_breakddown",
          "functional"
        ],
      },
      { value: "contributionAmount", display: ["contributions"] },
    ];

    const GetHeader = (calcType: string) => {
      switch(calcType){
        case 'coopsan':
          return headersCoopSanForm;
        case 'financial_certificates':
          return headersFinancialCertificatesForm;
        case 'ap_lvl_na':
        case 'ap_lvl_sl':
          return headerApLevel;
        case 'membership_breakddown':
          return headerMembershipBreakdown;
        case 'functional':
          return headerFunctional;
        default:
          return headersPlannedSavingsForm;
      }
    }

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Typography variant="h3" style={{ display: "inline" }}>
                Pool de Tasa de Interés Pasiva
              </Typography>
            </Grid>
          </Grid>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={3}>
                  <FormControl
                    variant="outlined"
                    className={classes.gridFormControl}
                  >
                    <InputLabel id="type">{translations.TYPE}</InputLabel>
                    <Select
                      label={translations.TYPE}
                      id="type"
                      value={this.state.calculatorType}
                      onChange={this.handleSelectChange("calculatorType")}
                      disabled={true}
                    >
                      {INTEREST_CALCULATOR_TYPES.map((opt, indx) => {
                        return (
                          <MenuItem value={opt.value} key={indx}>
                            {opt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item={true}
                  xs={3}
                  style={{
                    display: validators
                      .find((item) => item.value === "contributionAmount")
                      ?.display?.includes(this.state.calculatorType)
                      ? "block"
                      : "none",
                  }}
                >
                  <CssTextField
                    variant="outlined"
                    label={"Valor por Aportación"}
                    value={this.state.contributionAmount}
                    name={"contributionAmount"}
                    onChange={this.handleCurrencyInputChange(
                      "contributionAmount"
                    )}
                    disabled={!this.state.roles.includes("EditContributions")}
                    required={
                      validators
                        .find((item) => item.value === "contributionAmount")
                        ?.display?.includes(this.state.calculatorType)
                        ? true
                        : false
                    }
                    style={{ width: "100%" }}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={3}
                  style={{
                    display: validators
                      .find((item) => item.value === "minimumBalance")
                      ?.display?.includes(this.state.calculatorType)
                      ? "block"
                      : "none",
                  }}
                >
                  <CssTextField
                    variant="outlined"
                    label={translations.MINIMUM_BALANCE}
                    value={this.state.minimumBalance}
                    name={"minimumBalance"}
                    onChange={this.handleCurrencyInputChange("minimumBalance")}
                    required={
                      validators
                        .find((item) => item.value === "minimumBalance")
                        ?.display?.includes(this.state.calculatorType)
                        ? true
                        : false
                    }
                    style={{ width: "100%" }}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={3}
                  style={{
                    display: validators
                      .find((item) => item.value === "minimumQuota")
                      ?.display?.includes(this.state.calculatorType)
                      ? "block"
                      : "none",
                  }}
                >
                  <CssTextField
                    variant="outlined"
                    label={translations.MINIMUM_QUOTA}
                    value={this.state.minimumQuota}
                    name={"minimumQuota"}
                    onChange={this.handleCurrencyInputChange("minimumQuota")}
                    style={{ width: "100%" }}
                    required={
                      validators
                        .find((item) => item.value === "minimumQuota")
                        ?.display?.includes(this.state.calculatorType)
                        ? true
                        : false
                    }
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={3}
                  style={{
                    display: validators
                      .find((item) => item.value === "cuttingDay")
                      ?.display?.includes(this.state.calculatorType)
                      ? "block"
                      : "none",
                  }}
                >
                  <CssTextField
                    variant="outlined"
                    value={this.state.cuttingDay}
                    label={translations.CUTTING_DAY}
                    name={"cuttingDay"}
                    onChange={this.handleChange("cuttingDay")}
                    fullWidth={true}
                    required={
                      validators
                        .find((item) => item.value === "cuttingDay")
                        ?.display?.includes(this.state.calculatorType)
                        ? true
                        : false
                    }
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: 30,
                      min: validators
                        .find((item) => item.value === "cuttingDay")
                        ?.min?.includes(this.state.calculatorType)
                        ? 0
                        : 1,
                      step: 1,
                    }}
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={3}
                  style={{
                    display: validators
                      .find((item) => item.value === "minTimeLimit")
                      ?.display?.includes(this.state.calculatorType)
                      ? "block"
                      : "none",
                  }}
                >
                  <CssTextField
                    variant="outlined"
                    value={this.state.minTimeLimit}
                    name={"minTimeLimit"}
                    label="Tiempo límite mínimo"
                    onChange={this.handleChange("minTimeLimit")}
                    fullWidth={true}
                    required={
                      validators
                        .find((item) => item.value === "minTimeLimit")
                        ?.display?.includes(this.state.calculatorType)
                        ? true
                        : false
                    }
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: 100,
                      min:
                        this.state.calculatorType === "planned_savings" ||
                        this.state.calculatorType === "financial_certificates"
                          ? 1
                          : 0,
                      step: 1,
                    }}
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={3}
                  style={{
                    display: validators
                      .find((item) => item.value === "maxTimeLimit")
                      ?.display?.includes(this.state.calculatorType)
                      ? "block"
                      : "none",
                  }}
                >
                  <CssTextField
                    variant="outlined"
                    value={this.state.maxTimeLimit}
                    name={"maxTimeLimit"}
                    label="Tiempo límite máximo"
                    onChange={this.handleChange("maxTimeLimit")}
                    fullWidth={true}
                    required={
                      validators
                        .find((item) => item.value === "maxTimeLimit")
                        ?.display?.includes(this.state.calculatorType)
                        ? true
                        : false
                    }
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: 100,
                      min:
                        this.state.calculatorType === "planned_savings" ||
                        this.state.calculatorType === "financial_certificates"
                          ? parseInt(String(this.state.minTimeLimit), 10) + 1
                          : 0,
                      step: 1,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container={true}
                spacing={2}
                style={{
                  display: validators
                    .find((item) => item.value === "savingsBook")
                    ?.display?.includes(this.state.calculatorType)
                    ? "block"
                    : "none",
                }}
              >
                <Grid item={true} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="savingsBook"
                        checked={this.state.savingsBook}
                        onChange={this.handleCheckInputChange("savingsBook")}
                        value="savingsBook"
                      />
                    }
                    label={translations.SAVING_BOOK}
                  />
                </Grid>
              </Grid>
              <Grid
                container={true}
                spacing={2}
                style={{
                  display: validators
                    .find((item) => item.value === "descriptions")
                    ?.display?.includes(this.state.calculatorType)
                    ? "block"
                    : "none",
                }}
              >
                <Grid item={true} xs={12}>
                  <Typography
                    variant="h5"
                    className={classes.checkboxLabel}
                    style={{ display: "inline" }}
                  >
                    {"Datos"}
                  </Typography>
                  <InterestTable
                    create={true}
                    actions={true}
                    tableLimit={
                      this.state.calculatorType === "financial_certificates"
                        ? 24
                        : 6
                    }
                    data={this.state.descriptions}
                    editIdx={this.state.editIdx}
                    handleChange={this.handleChangeTD}
                    handleRemove={this.handleRemove}
                    handleSave={this.handleSave}
                    handleStartEdit={this.handleStartEdit}
                    handleStopEdit={this.handleStopEdit}
                    header={GetHeader(this.state.calculatorType)}
                  />
                </Grid>
              </Grid>
              <Grid container={true} spacing={2} style={{ display: "block" }}>
                <Grid item={true} xs={12}>
                  <Button
                    variant="contained"
                    onClick={this.handleDelete}
                    className={classes.deleteButton}
                  >
                    <DeleteIcon />
                    &nbsp;
                    {translations.DELETE}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.greenButton}
                    style={{ marginLeft: 10 }}
                  >
                    <SaveIcon />
                    &nbsp;
                    {translations.SAVE}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    interests: state.interests,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { deleteInterest, fetchInterest, updateInterest })(
    InterestView
  )
);
