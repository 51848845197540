import {
  fetchParticipants,
  fetchCreditors,
  fetchBlacklistData,
  fetchAssignees,
  fetchTickets,
  fetchInstitutions,
  fetchPositions,
  fetchCreditorAccounts,
  fetchResponsibleAnalysts,
} from "../utils/axios";

const request = async (query, token, optionType) => {
  const options = {
    token: token,
    searchQuery: query.toLowerCase(),
    limit: 10,
  };
  let data = [];
  switch (optionType) {
    case "participants": {
      data = await fetchParticipants(options).then(
        (response) => response.data.results
      );
      data = data.map((item) => {
        return {
          name: `${item.fullName} | ${item.identificationNumber}`,
          value: item.uniqueId,
          fullName: item.fullName,
          rohiId: item.rohiId,
          identificationNumber: item.identificationNumber,
          identificationType: item.identificationType,
        };
      });
      return data;
    }
    case "creditors": {
      data = await fetchCreditors(options).then(
        (response) => response.data.results
      );
      const cleanData = data.map((item) => {
        return {
          type: item.type,
          uniqueId: item.uniqueId,
          document:
            item.type === "legal"
              ? item.institutionDetail.registry || "--"
              : item.participantDetail.identificationNumber,
          fullName:
            item.type === "legal"
              ? item.abbreviation || "--"
              : item.participantDetail.fullName,
        };
      });
      data = cleanData.map((item) => {
        return {
          name: `${item.fullName} | ${item.document}`,
          value: item.uniqueId,
        };
      });
      return data;
    }
    case "blacklist": {
      data = await fetchBlacklistData(options).then(
        (response) => response.data
      );
      let cleanData = [];
      Object.keys(data).forEach((list) => {
        if (data[list].length > 0) {
          cleanData = cleanData.concat(
            data[list].map((property) => {
              const fullName = property.temporary_info
                ? `${property.temporary_info.full_name || "--"} | ${property
                    .temporary_info.identification_number || "--"}`
                : `${property.full_name ||
                    "--"} | ${property.identification_number || "--"}`;
              return {
                name: fullName,
                value: fullName,
                list: list,
              };
            })
          );
        }
      });
      return cleanData;
    }
    case "users": {
      options.username = query.toLowerCase();
      data = await fetchAssignees(options).then(
        (response) => response.data.results
      );
      data = data.map((item) => {
        return {
          name: `${item.firstName} ${item.lastName}`,
          value: item.username,
        };
      });
      return data;
    }
    case "tickets": {
      options.referenceCode = query.toLowerCase();
      data = await fetchTickets(options).then(
        (response) => response.data.results
      );
      data = data.map((item) => {
        return {
          name: `${item.fullName} - ${item.referenceCode}`,
          value: item.uniqueId,
        };
      });
      return data;
    }
    case "institutions": {
      options.name = query.toLowerCase();
      // options.institutionType = 'private';
      data = await fetchInstitutions(options).then(
        (response) => response.data.results
      );
      data = data.map((item) => {
        return {
          fullName: `${item.name} | ${item.employerCode}`,
          name: item.name,
          employerCode: item.employerCode,
          paymentFrequency: item.paymentFrequency,
          paymentDays: item.paymentDays,
          value: item.uniqueId,
          info: item,
        };
      });
      return data;
    }
    case "banks": {
      options.name = query.toLowerCase();
      options.institutionType = 'financial';
      data = await fetchInstitutions(options).then(
        (response) => response.data.results
      );
      data = data.map((item) => {
        return {
          fullName: `${item.name} | ${item.employerCode}`,
          name: item.name,
          employerCode: item.employerCode,
          paymentFrequency: item.paymentFrequency,
          paymentDays: item.paymentDays,
          value: item.uniqueId,
          info: item,
        };
      });
      return data;
    }
    case "position": {
      data = await fetchPositions(options).then(
        (response) => response.data.results
      );
      //   data = data.map((item) => {
      //     return {
      //       fullName: `${item.name} | ${item.employerCode}`,
      //       name: item.name,
      //       employerCode: item.employerCode,
      //       paymentFrequency: item.paymentFrequency,
      //       paymentDays: item.paymentDays,
      //       value: item.uniqueId,
      //       info: item,
      //     };
      //   });
      return data;
    }
    case "creditorAccounts": {
      data = await fetchCreditorAccounts(options).then(
        (response) => response.data.results
      );
      return data;
    }
    case "responsibleManagement": {
      data = await fetchResponsibleAnalysts(options.token, options.searchQuery).then(
        (response) => response.data
      );

        data = data.map((item)=>{
          return{
            name: item.fullName,
            fullName: item.fullName,
            value: item.uniqueId,
            email: item.email,
            isBot: item.isBot,
            referenceCode: item.referenceCode,
            url: item.url
          }
        })
      return data;
    }
    default: {
      data = [];
      return data;
    }
  }
};

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export async function searchAPI(query, token, optionType) {
  await sleep(50);
  const data = await request(query, token, optionType);
  // const cleanData = data.map(item => `${item.fullName} ${item.identificationNumber}`);
  return {
    data: {
      results: data,
      query: query,
    },
  };
}
