import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './CircleMenu.css';
import PersonIcon from '@material-ui/icons/AccountCircle';

// Circle drop down menu
const CircleMenu = ({ options = [], isActive, onClick }) => {
    // options -> list of objects:
    //  "title": The display name of the option
    //  "action": A function to call when the option is clicked
    //  "icon": A font-awesome icon for the menu item

    return (
        <div className="circle-menu-button" onClick={onClick}>
            <PersonIcon className='account-icon' />
            {isActive ?
                <div className="dropdown-content">
                    {options.map((opt, indx) => {
                        return (
                            <span key={indx} onClick={opt.action}>
                                <span className="cicle-menu-item-text">{opt.title}</span>
                                <FontAwesomeIcon className="circle-menu-icon" icon={opt.icon} />
                            </span>)
                    })}
                </div>
                : null}
        </div>
    )
}

export default CircleMenu;
