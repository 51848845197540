import React, {useState} from 'react';
import {
    Grid, Typography, IconButton, Button
} from '@material-ui/core';
import { 
    Close, Tune, ClearAll
} from '@material-ui/icons';
import { 
    AutocompleteField, DateField, NumericMinMax 
} from './../../../components/SharedComponents/Fields';
import { dateFormat } from './../../../components/SharedComponents/CustomHooks';
import {
    approbationStates, responsibleOptions
} from 'src/utils';

function FiltersHeader(props) {
    return (
        <Grid container justify='space-between' alignItems="center">
            <Typography
                variant={props.typeVariant}
                className={props.typeClass}
                style={{fontWeight: 'bold'}}
            >
                {props.filtersTitle}
            </Typography>
            <IconButton 
                className={props.btnClass}
                onClick={props.toggleFilter}
                disabled={props.disableClose}
            >
                {
                    props.openFilter ? 
                    <Close
                        className={props.iconClass}     
                    />
                    :
                    <Tune
                        className={props.iconClass}
                    />
                }
            </IconButton>
        </Grid>
    )
}

function Filters(props) {
    const [status, setStatus] = useState({});
    const [selectedParticipant, setParticipant] = useState([]);
    const [selectedTicket, setTicket] = useState({});
    const [selectedAct, setAct] = useState({});
    const [selectedAssignee, setAssignee] = useState({});
    const [amount, setAmount] = useState({min: '', max: ''});
    // const [chargedAmount, setChargedAmount] = useState({min: '', max: ''});
    // const [clients, setClients] = useState([]);
    const [date, setDate] = useState({start: null, end: null});
    const [openFilter, setOpenFilter] = useState(true);

    const clearFilters = () => {
        setStatus({});
        setParticipant([]);
        setTicket({});
        setAct({});
        setAssignee({});
        setAmount({...{min: '', max: ''}});
        setDate({start: null, end: null});
    }

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    }
    const handleStatusChange = (event, value) => {
        setStatus(value);
    }
    const handleParticipantChange = (event, value) => {
        setParticipant(value);
    }
    const handleTicketChange = (event, value) => {
        setTicket(value);
    }
    const handleActChange = (event, value) => {
        setAct(value);
    }
    const handleAssigneeChange = (event, value) => {
        setAssignee(value);
    }
    const handleAmount = (type, event) => {
        setAmount({...amount, [type]: event.value});
    }
    
    const handleDateChange = (type, dateInfo, value) => {
        setDate({...date, [type]: dateInfo});
    }

    const getFiltersOptions = (option) => {
        return(
            (option ? option : [])
        );
    }
    const applyFilters = () => {
        const fields = {
            participant: selectedParticipant ? selectedParticipant.map(item => { return item.uniqueId }).join('|') : "",
            ticket: selectedTicket !== null && selectedTicket.uniqueId ? selectedTicket.uniqueId : "",
            act: selectedAct !== null && selectedAct.uniqueId  ? selectedAct.uniqueId : "",
            assignee: selectedAssignee !== null && selectedAssignee.value ? selectedAssignee.value : "",
            amountFrom: Number(amount.min),
            amountTo: Number(amount.max),
            dateFrom: dateFormat(date.start),
            dateTo: dateFormat(date.end),
        };
        Object.keys(fields).forEach(key => {
            if(!fields[key]) {
                delete fields[key];
            }
        });
        props.applyFilters(fields);
    }
    return(
        <Grid container className="filters-container">
            <FiltersHeader
                typeVariant="subtitle1"
                typeClass="section-header"
                filtersTitle="Filtros"
                btnClass={"filter-btn-close " + props.hideClass}
                iconClass="filter-icon-close"
                toggleFilter={toggleFilter}
                openFilter={openFilter}
                disableClose={props.disableCloseBtn}
            />
            <Grid container className={openFilter ? "filter-content" : "filter-content collided"}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Nombre de socio"
                            fieldID="filter-field-status"
                            fieldInnerLabel="Socio"
                            fieldVariant="outlined"
                            value={selectedParticipant}
                            handleChange={handleParticipantChange}
                            options={getFiltersOptions(props.listData.clients)}
                            getOptionLabel={(option) => option.name || ""}
                            multiple={true}
                            limitTags={2}
                        />
                        <AutocompleteField 
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Cédula"
                            fieldID="filter-field-work"
                            fieldInnerLabel="Cédula"
                            fieldVariant="outlined"
                            value={selectedParticipant}
                            handleChange={handleParticipantChange}
                            options={getFiltersOptions(props.listData.clients)}
                            getOptionLabel={(option) => option.idNumber || ""}
                            multiple={true}
                            limitTags={2}
                        />
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Número de ticket"
                            fieldID="filter-field-org"
                            fieldInnerLabel="Ticket"
                            fieldVariant="outlined"
                            value={selectedTicket}
                            handleChange={handleTicketChange}
                            options={getFiltersOptions(props.listData.tickets)}
                            getOptionLabel={(option) => option.referenceCode || ""}
                            multiple={false}
                            limitTags={1}
                        />
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Número de acta"
                            fieldID="filter-field-client"
                            fieldInnerLabel="Acta"
                            fieldVariant="outlined"
                            value={selectedAct}
                            handleChange={handleActChange}
                            getOptionSelected={(option)=> option.uniqueId}
                            options={getFiltersOptions(props.listData.acts)}
                            getOptionLabel={(option) => option.actNumber || ""}
                            multiple={false}
                            limitTags={1}
                        />
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Responsable de aprobación"
                            fieldID="filter-field-client"
                            fieldInnerLabel="Responsable"
                            fieldVariant="outlined"
                            value={selectedAssignee}
                            handleChange={handleAssigneeChange}
                            options={getFiltersOptions(responsibleOptions)}
                            getOptionLabel={(option) => option.value || ""}
                            multiple={false}
                            limitTags={1}
                        />
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Estado de aprobación"
                            fieldID="filter-field-client"
                            fieldInnerLabel="Estado"
                            fieldVariant="outlined"
                            value={status}
                            handleChange={handleStatusChange}
                            options={getFiltersOptions(approbationStates)}
                            getOptionSelected={(option)=> option.value}
                            getOptionLabel={(option) => option.label || ""}
                            multiple={false}
                            limitTags={1}
                        />
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                        <NumericMinMax 
                            xs={4}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Monto"
                            fieldID="filter-field-overdue-amount"
                            fieldClass="fields"
                            fieldVariant="outlined"
                            margin='dense'
                            fullWidth
                            prefix="$"
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            valueMin={amount.min}
                            valueMax={amount.max}
                            onChangeMin={handleAmount.bind(this, 'min')}
                            onChangeMax={handleAmount.bind(this, 'max')}
                        />
                        <DateField 
                            xs={4}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Fecha inicio"
                            dateFieldID="filter-date-start"
                            fullWidth={true}
                            dateFieldClass="date-field"
                            inputVariant="outlined"
                            dateFieldLabel="Fecha de inicio"
                            format="dd/MM/yyyy"
                            // value={startDate}
                            value={date.start}
                            dateType="start"
                            handleChange={handleDateChange.bind(this, "start")}
                        />
                        <DateField 
                            xs={4}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Fecha fin"
                            dateFieldID="filter-date-end"
                            fullWidth={true}
                            dateFieldClass="date-field"
                            inputVariant="outlined"
                            dateFieldLabel="Fecha de fin"
                            format="dd/MM/yyyy"
                            // value={endDate}
                            value={date.end}
                            dateType="end"
                            handleChange={handleDateChange.bind(this, "end")}
                        />
                    </Grid>
                </Grid>
                {/* FILTERS CONTENT APPLY BUTTON */}
                <Grid container direction="row" justify='space-between' style={{marginTop:'35px'}}>

                    <Button
                        disableElevation
                        className="filter-clear-btn"
                        onClick={clearFilters.bind(this, false)}
                    >
                        limpiar
                        <ClearAll style={{marginLeft:8, fontSize: '19px'}}/>
                    </Button>

                    <Button
                        disableElevation
                        className="filter-apply-btn"
                        onClick={applyFilters}
                    >
                        aplicar filtros
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    );
}
export { Filters }