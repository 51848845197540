import React, { useState, useEffect } from 'react';
import {
    Grid, Typography, IconButton, Button
} from '@material-ui/core';
import { 
    Close, Tune, ClearAll
} from '@material-ui/icons';
import { 
    AutocompleteField, DateField, NumericMinMax , SearchComponent
} from './../../../components/SharedComponents/Fields';
import { dateFormat } from './../../../components/SharedComponents/CustomHooks';
import { fetchBusinessRepresentatives, fetchInstitutions } from 'src/utils/axios';
import axios from 'axios';

function FiltersHeader(props) {

    return (
        <Grid container justifyContent='space-between' alignItems="center">

            <Typography
                variant={props.typeVariant}
                className={props.typeClass}
                style={{fontWeight: 'bold'}}
            >
                {props.filtersTitle}
            </Typography>

            <IconButton
                className={props.btnClass}
                onClick={props.toggleFilter}
                disabled={props.disableClose}
            >
                {
                    props.openFilter ?
                    <Close
                        className={props.iconClass}
                    />
                    :
                    <Tune
                        className={props.iconClass}
                    />
                }

            </IconButton>
        </Grid>
    )
}
function Filters(props) {

    const [fieldData, setFieldData] = useState({});
    const [openFilter, setOpenFilter] = useState(true);
    const [filterData, setFilterData] = useState({});

    const getFilters = () => {
        props.setLoading(true);
        const getAgents = fetchBusinessRepresentatives({token: props.token});
        const getInstitutions = fetchInstitutions({token: props.token});

        const endpoints = [getAgents, getInstitutions]

        axios.all(endpoints).then(axios.spread((...responses) => {
            const data = {
                businessAgents: responses[0].data,
                works: (responses[1] && responses[1].data).map(item => {
                    return {
                        abbreviation: item.abbreviation,
                        name: item.name,
                        fullName: item.abbreviation + ": " + item.name,
                        uniqueId: item.uniqueId
                    }
                })
            };
            setFilterData({...data});
            props.setLoading(false);
        })).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        })
    }

    const clearFilters = () => {
        setFieldData({});
    }

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    }

    const handleFieldData = (field, event, value, type) => {
        let data;
        switch(field) {
            case 'client':
                data = event;
                break;
            default:
                data = value;
                break;
        }
        setFieldData({...fieldData, [field]: data});
    }

    const getFiltersOptions = (option) => {
        return(
            (option ? option : [])
        );
    }
    
    const applyFilters = () => {
        const fields = {
            business_agents: fieldData.businessAgents && fieldData.businessAgents.map(item => item.relatedUser).join('|'),
            work_institution: fieldData.work && fieldData.work.map(item => item.name).join('|'),
            // finantial_institution: fieldData.bank && fieldData.bank.map(item => item.name).join('|'),
            client: fieldData.client && fieldData.client.fullName,
            // expiration_status: fieldData.states && fieldData.states.map(item => item.value).join('|'),
            // expiration_date_from: dateFormat(fieldData.date ? fieldData.date.start : null),
            // expiration_date_to: dateFormat(fieldData.date ? fieldData.date.end : null),
            // work_class_id: fieldData.financialClass ? fieldData.financialClass.map(item => item.value).join('|') : null,
        };
        Object.keys(fields).forEach(key => {
            if(!fields[key] || fields[key].length === 0) {
                delete fields[key];
            }
        });
        props.applyFilters(fields);
    }

    useEffect(() => {
        getFilters();
    }, []);

    return(
        <Grid container className="filters-container">
            <FiltersHeader
                typeVariant="subtitle1"
                typeClass="section-header"
                filtersTitle="Filtros"
                btnClass={("filter-btn-close ") + props.hideClass}
                iconClass="filter-icon-close"
                toggleFilter={toggleFilter}
                openFilter={openFilter}
                disableClose={props.disableCloseBtn}
            />
            <Grid container className={openFilter ? "filter-content" : "filter-content collided"}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Responsable"
                            fieldID="filter-field-responsible"
                            fieldInnerLabel="Representante de negocios"
                            fieldVariant="outlined"
                            value={fieldData.businessAgents || []}
                            handleChange={(event, value) => { handleFieldData('businessAgents', event, value) }}
                            options={getFiltersOptions(filterData.businessAgents)}
                            getOptionLabel={(option) => option.name}
                            multiple={true}
                            limitTags={2}
                        />
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Trabajo"
                            fieldID="filter-field-work"
                            fieldInnerLabel="Lugar de Trabajo"
                            fieldVariant="outlined"
                            value={fieldData.work || []}
                            handleChange={(event, value) => { handleFieldData('work', event, value) }}
                            options={getFiltersOptions(filterData.works)}
                            getOptionLabel={(option) => option.fullName}
                            multiple={true}
                            limitTags={3}
                        />
                        <SearchComponent
                            xs={4}
                            containerClass="field-container"
                            fieldLabel="Participantes"
                            fieldID="reassign-client-filter"
                            fieldInnerLabel="Seleccione un participante"
                            handleChange={(event, value) => { handleFieldData('client', event, value) }}
                            optionType={'participants'}
                            clearOnBlur={false}
                            limitTags={1}
                        />
                    </Grid>
                </Grid>
                {/* FILTERS CONTENT APPLY BUTTON */}
                <Grid container direction="row" justifyContent='space-between' style={{marginTop:'35px'}}>

                    <Button
                        disableElevation
                        className="filter-clear-btn"
                        onClick={clearFilters.bind(this, false)}
                    >
                        limpiar
                        <ClearAll style={{marginLeft:8, fontSize: '19px'}}/>
                    </Button>

                    <Button
                        disableElevation
                        className="filter-apply-btn"
                        onClick={applyFilters}
                    >
                        aplicar filtros
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    );
}

export { Filters }