import * as React from 'react';
import { connect } from 'react-redux';
import { translations } from 'src/utils';

import { List, ListItem, ListItemText } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue, orange } from '@material-ui/core/colors';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FiberManualRecordIcon from '@material-ui/icons/Brightness1';
import FilterListIcon from '@material-ui/icons/FilterList';
import TuneIcon from '@material-ui/icons/Tune';

import FilterDialog from '../../../components/FilterDialog';
import AccountingRecordSearchFilter from '../AccountingRecordSearchFilter';

const styles: any = createStyles({
  dateLabel: {
    bottom: "40%",
    color: grey[400],
    fontSize: "small",
    position: "absolute",
    right: 0,
    textDecoration: "underline",
  },
  filterButton: {
    width: "15%",
  },
  paginationGroup: {
    border: "1px solid rgba(25,25,25,0.2)",
    bottom: 0,
    display: "flex",
    height: 50,
    justifyContent: "center",
    justifyItems: "center",
    position: "absolute",
    textAlign: "center",
    width: 278,
  },
  root: {
    borderRightColor: "rgba(25,25,25,0.2)",
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    bottom: 51,
    boxSizing: "border-box",
    overflowY: "auto",
    padding: 10,
    paddingLeft: 15,
    position: "absolute",
    top: 65,
    width: 280,
  },
  textField: {
    width: "70%",
  },
});

interface IAccountingRecordSearchSectionProps {
  classes: {
    dateLabel: string;
    filterButton: string;
    paginationGroup: string;
    root: string;
    textField: string;
  };

  loading: boolean;
  accountingRecords: IAccountingRecord[];
  user: IUser;
  pagination: boolean;
  handleClick(id: string): void;
  fetchData(params: IAccountingRecordParams): void;
  advanceSearch(queryParameters: IAccountingRecordParams): void;
}
interface IAccountingRecordSearchSectionState {
  filterField: string;
  limit: number;
  offset: number;
  open: boolean;
  advanceFilter: boolean;
  searchValue: string;
  searching: boolean;
}
class AccountingRecordSearchSection extends React.Component<
  IAccountingRecordSearchSectionProps,
  IAccountingRecordSearchSectionState
> {
  public state: IAccountingRecordSearchSectionState = {
    advanceFilter: false,
    filterField: "title",
    limit: 20,
    offset: 0,
    open: false,
    searchValue: "",
    searching: false,
  };
  constructor(props: IAccountingRecordSearchSectionProps) {
    super(props);

    this.openDialog = this.openDialog.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.openFilterDialog = this.openFilterDialog.bind(this);
    this.closeFilterDialog = this.closeFilterDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.mapFunction = this.mapFunction.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.search = this.search.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
  }
  public openDialog() {
    this.setState({ open: true });
  }
  public handleDialogClose(value: string) {
    this.setState({
      filterField: value,
      open: false,
    });
  }
  public openFilterDialog() {
    this.setState({ advanceFilter: true });
  }
  public closeFilterDialog() {
    this.setState({ advanceFilter: false });
  }
  public search(params: IAccountingRecordParams) {
    this.props.advanceSearch(params);
  }
  public handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!this.state.searching || event.currentTarget.value === "") {
      const params: IAccountingRecordParams = {
        limit: this.state.limit,
        offset: 0,
        token: this.props.user.token,
      };
      params[this.state.filterField] = event.currentTarget.value;

      this.setState(
        {
          offset: 0,
          searchValue: event.currentTarget.value,
          searching: true,
        },
        () => {
          this.props.fetchData(params);
          setTimeout(() => this.setState({ searching: false }), 500);
        }
      );
    } else {
      this.setState({ searchValue: event.currentTarget.value });
    }
  }

  public mapFunction(accountingRecord: IAccountingRecord) {
    let statusColor: string = blue[500];
    let statusText: string = translations.PENDING_SIGNATURE;

    switch (accountingRecord.recordStatus) {
      case "signed":
        statusColor = orange[500];
        statusText = translations.LEGALIZED;
        break;
      case "archived":
        statusColor = green[500];
        statusText = translations.ARCHIVED;
        break;
    }

    if (accountingRecord.uniqueId === "none") {
      return (
        <ListItem
          dense={true}
          divider={true}
          key={accountingRecord.uniqueId}
          button={true}
        >
          <ListItemText primary={`${accountingRecord.referenceCode}`} />
        </ListItem>
      );
    }

    return (
      <ListItem
        title={statusText}
        dense={true}
        divider={true}
        key={accountingRecord.uniqueId}
        button={true}
        onClick={this.selectItem(accountingRecord.uniqueId || "")}
      >
        <FiberManualRecordIcon style={{ color: statusColor }} />
        <ListItemText primary={`${accountingRecord.referenceCode}`} />
      </ListItem>
    );
  }

  public selectItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      if (id !== "none") {
        this.props.handleClick(id);
      }
    };
  }
  public fetchData() {
    this.props.fetchData({ token: this.props.user.token });
  }
  public prev() {
    if (this.state.offset !== 0) {
      this.setState((prevState) => {
        const params: IAccountingRecordParams = {
          limit: prevState.limit,
          offset: prevState.offset - 20,
          token: this.props.user.token,
        };
        params[this.state.filterField] = this.state.searchValue;

        this.props.fetchData(params);

        return {
          offset: prevState.offset - 20,
        };
      });
    }
  }
  public next() {
    this.setState((prevState) => {
      const params: IAccountingRecordParams = {
        limit: prevState.limit,
        offset: prevState.offset + 20,
        token: this.props.user.token,
      };
      params[this.state.filterField] = this.state.searchValue;

      this.props.fetchData(params);

      return {
        offset: prevState.offset + 20,
      };
    });
  }
  public render() {
    const { classes, accountingRecords, loading, pagination } = this.props;

    return (
      <div>
        <div className={classes.root}>
          <FilterDialog
            options={[{ caption: translations.TITLE, value: "title" }]}
            open={this.state.open}
            onClose={this.handleDialogClose}
            selectedValue={this.state.filterField}
          />

          <AccountingRecordSearchFilter
            search={this.search}
            token={this.props.user.token}
            open={this.state.advanceFilter}
            handleClose={this.closeFilterDialog}
          />
          <div>
            <TextField
              className={classes.textField}
              value={this.state.searchValue}
              onChange={this.handleChange}
              placeholder={translations.SEARCH}
            />
            <IconButton
              className={classes.filterButton}
              onClick={this.openDialog}
            >
              <FilterListIcon />
            </IconButton>
            <IconButton
              className={classes.filterButton}
              onClick={this.openFilterDialog}
            >
              <TuneIcon />
            </IconButton>
          </div>
          <div>
            <List>
              {loading ? (
                <ListItem>
                  <CircularProgress
                    style={{ color: green[500] }}
                    thickness={7}
                  />
                  <ListItemText primary={translations.LOADING} />
                </ListItem>
              ) : accountingRecords.length === 0 ? (
                [{ title: translations.NOT_FOUND, uniqueId: "none" }].map(
                  this.mapFunction
                )
              ) : (
                accountingRecords.map(this.mapFunction)
              )}
            </List>
          </div>
        </div>
        {!loading && pagination ? (
          <div className={classes.paginationGroup}>
            <IconButton onClick={this.prev}>
              <ArrowBackIcon />
            </IconButton>
            <IconButton>{this.state.offset / 20 + 1}</IconButton>
            <IconButton onClick={this.next}>
              <ArrowForwardIcon />
            </IconButton>
          </div>
        ) : null}
      </div>
    );
  }
}
function mapStateToProps(state: IState) {
  return {
    accountingRecords: state.accountingRecords,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps)(AccountingRecordSearchSection)
);
