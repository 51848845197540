import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateBlacklist } from "src/actions";
import Loading from "../../../components/SharedComponents/LoadingScreen";
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';

import {
  fetchBlacklists,
  deleteBlacklist
} from "src/utils/axios";

import { Paper, Typography, IconButton, Button, Toolbar } from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { Delete, Replay } from '@material-ui/icons';

import { GeneralTable } from './../../../components/SharedComponents/Table';
import TableContainer from "@material-ui/core/TableContainer";
import { Filters } from '../Filters'
import { ConfirmDialogComponent } from '../Dialogs/index'
import { SuccessDialog, ErrorDialog, SimpleConfirmDialog } from '../../../components/SharedComponents/Dialogs';
import { blacklistReasons } from "src/utils";

function TableToolbar(props) {
  return (
    <Toolbar>
      <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
        <Typography
          variant="subtitle1"
          className="section-header"
          style={{ fontWeight: 'bold', paddingLeft: 16 }}
        >
          {(props.title || "Black list")}
        </Typography>
        <Button
          onClick={props.refresh}
          variant="contained"
          style={{ backgroundColor: green[500], color: '#FFFFFF', fontSize: '12px', marginLeft: 10, display: 'inline-block' }}
          disableElevation
        >
          <Replay style={{ margin: ' 0 5 -4 -5', fontSize: '16px' }} />
          Recargar tabla
        </Button>
      </div>
    </Toolbar>
  );
};

function BlackListView(props) {

  const limitRequest = {limit: 50};
  const [loading, setLoading] = useState(false);
  const [simpleDialog, setSimpleDialog] = useState({ name: '', data: null, callback: null });
  const [blackListData, setblackListData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : '', msg: msg, data: data };
    setSimpleDialog({ ...obj });
  }

  const removeFromList = (row) => {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <IconButton
          onClick={
            handleToggleSimpleDialog
              .bind(this, 'confirm-dialog', 'proceed-delete', { callback: deleteBl.bind(this, row), row: row })
          }                >
          <Delete style={{
            fontSize: 22,
            color: green[500]
          }} />
        </IconButton>
      </div>
    );
  }

  const reasonTranslate = (row) => {
    const translate = blacklistReasons.find(x => x.value == row.reason);
        const translateText = translate ? translate.label : row.reason;
        return(
            <span>
                {translateText}
            </span>
        )
  }


  const columns = [
    {
      id: 'referenceCode',
      label: 'Codigo de referencia',
      minWidth: 160,
      align: 'center'
    },
    {
      id: 'fullName',
      label: 'NOMBRE COMPLETO',
      minWidth: 200,
      align: 'center'
    },
    {
      id: 'identificationType',
      label: 'TIPO DE ID',
      minWidth: 160,
      align: 'center'
    },
    {
      id: 'identificationNumber',
      label: 'IDENTIFICACION',
      type: 'number',
      minWidth: 160,
      align: 'center'
    },
    {
      id: 'reason',
      label: 'RAZON DE BLOQUEO',
      minWidth: 200,
      align: 'center',
      element: reasonTranslate
    },
    {
      label: 'ACCIONES',
      minWidth: 160,
      align: 'center',
      element: removeFromList
    },
  ];

  const getBlackListData = (data) => {
    setLoading(true);
    const endpoint = fetchBlacklists({ ...data, token: props.user.token })
    endpoint.then(response => {
      let data = response.data.results ? response.data.results : response.data;
      const blackListData = data.map(item => {
        return {
          id: item.uniqueId,
          fullName: item.temporaryInfo ? item.temporaryInfo.fullName : item.customerDetail.fullName,
          identificationNumber: item.temporaryInfo ? item.temporaryInfo.identificationNumber
            : item.customerDetail.identificationNumber,
          identificationType: item.temporaryInfo ? item.temporaryInfo.identificationType === "national" ? 'Cedula'
            : item.temporaryInfo.identificationType === "passport" ? 'Pasaporte' : item.temporaryInfo.identificationType
            : item.customerDetail.identificationType === "national" ? 'Cedula'
              : item.customerDetail.identificationType === "passport" ? 'Pasaporte' : item.customerDetail.identificationType,
          referenceCode: item.referenceCode,
          reason: item.reason,
        }
      });
      setblackListData(blackListData)
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      console.error(err);
    });
  }

  const deleteBl = (IBL) => {
    setLoading(true);
    const endpoint = deleteBlacklist(props.user.token, IBL.id);
    endpoint.then(() => {
      getBlackListData(selectedFilters);
      setLoading(false);
      handleToggleSimpleDialog('success-dialog');
    }).catch(errors => {
      setLoading(false);
      errorHandling(errors, handleToggleSimpleDialog);
    });
  }

  useEffect(() => {
    getBlackListData(limitRequest)
  }, [])

  const applyFilters = (fields) => {
    let data = fields;
    getBlackListData(data);
    setSelectedFilters({ ...data });
  }

  return (
    <div style={{ padding: '20ps, 20px,10px 20px' }}>
      <Paper style={{ padding: '20px' }}>
        <Typography variant="h3">BlackList</Typography>
        <Loading open={loading} />
        <br />
        <br />
        <>
          <Filters
            applyFilters={applyFilters}
          />
          <div style={{ height: 700, width: '100%', marginTop: 60 }}>
            <TableContainer style={{ width: '100%' }}>

              <GeneralTable
                columns={columns}
                data={blackListData}
                toolbar={
                  <TableToolbar
                    title={"Black list"}
                    refresh={getBlackListData}
                  />
                }
              />
            </TableContainer>
          </div>
          {
            simpleDialog.name === 'confirm-dialog' ?
              <ConfirmDialogComponent
                open={true}
                data={simpleDialog}
                handleToggle={handleToggleSimpleDialog}
              /> : null
          }
          {
            simpleDialog.name === 'simple-dialog' ?
              <SimpleConfirmDialog
                open={true}
                handleToggle={handleToggleSimpleDialog}
                data={simpleDialog.msg}
              /> : null
          }
          {
            simpleDialog.name === 'success-dialog' ?
              <SuccessDialog
                open={true}
                handleToggle={handleToggleSimpleDialog}
              /> : null
          }
          {
            simpleDialog.name === 'error-dialog' ?
              <ErrorDialog
                open={true}
                handleToggle={handleToggleSimpleDialog}
                data={simpleDialog.msg}
              /> : null
          }
        </>
      </Paper>
    </div>
  );
}

export default connect(mapStateToProps, { updateBlacklist, })(BlackListView)

function mapStateToProps(state) {
  return {
    blacklist: state.blacklist,
    user: state.user,
  };
}
