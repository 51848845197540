import { COMPANY_REPRESENTATIVE_TYPES } from '../actions';

export default function(state: IParticipant[] = [], action: IAction<IParticipant[]>): IParticipant[] {
    switch(action.type) {
        case  COMPANY_REPRESENTATIVE_TYPES.FETCH_ALL:
            return action.payload || [];
        case  COMPANY_REPRESENTATIVE_TYPES.FETCH:
            return state.map(companyRepresentative => (action.payload !== undefined && companyRepresentative.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: companyRepresentative);
        case  COMPANY_REPRESENTATIVE_TYPES.CREATE: 
            return state.concat(action.payload || []);
        case  COMPANY_REPRESENTATIVE_TYPES.UPDATE:
            return state.map(companyRepresentative => (action.payload !== undefined && companyRepresentative.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : companyRepresentative);
        case  COMPANY_REPRESENTATIVE_TYPES.DELETE:
            return state.filter( companyRepresentative => action.payload !== undefined && companyRepresentative.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}