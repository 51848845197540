import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import {
  deleteCreditor,
  fetchCreditor,
  updateCreditor,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import idNumberMaskInput from "../../../components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "../../../components/TextMaskInputComponents/passportMaskInput";
import rncMaskInput from "../../../components/TextMaskInputComponents/rncMaskInput";
import {
  catchError,
  nationalitiesToOptions,
  resetError,
  successHandler,
  translations,
} from "../../../utils";
import {
  deleteCreditor as deleteCR,
  fetchCreditor as fetchCR,
  fetchNationalities,
  resetSecurityQuestions,
  updateCreditor as updateCR,
} from "../../../utils/axios";
import FinancialProfile from "../../FinancialProfile/index";
import WorkProfile from "../../WorkProfile";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import ParticipantSelectInput from "src/containers/Participant/ParticipantSelectInput";
import InstitutionsSelectInput from "src/containers/Institutions/InstitutionsSelectInput";
import { MenuItem } from "@material-ui/core";
import { Restore } from "@material-ui/icons";
import { orange } from "@material-ui/core/colors";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  checkboxLabelReactSelect: {
    color: green[500],
    fontSize: "small",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  yellowButton: {
    backgroundColor: orange[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    overflowy: "auto",
    padding: 20,
    paddingBottom: 64,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface ICreditorViewProps {
  creditors: ICreditor[];
  creditorId: string;
  classes: { [key: string]: string };
  user: IUser;
  changeSection(sectionNumber: number): void;
  deleteCreditor(uniqueId: string): IAction<ICreditor[]>;
  fetchCreditor(creditor: ICreditor): IAction<ICreditor[]>;
  updateCreditor(creditor: ICreditor): IAction<ICreditor[]>;
}

interface ICreditorViewState {
  [key: string]:
    | string
    | string[]
    | number
    | boolean
    | IOptionType
    | IOptionType[];
  abbreviation: string;
  abbreviationError: string;
  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  isRepresentative: boolean;
  loading: boolean;
  participant: string[];
  institution: string[];
  profileUniqueId: string;
  type: string;
  typeError: string;
  uniqueId: string;
}
class CreditorView extends React.Component<
  ICreditorViewProps,
  ICreditorViewState
> {
  public state: ICreditorViewState = {
    abbreviation: "",
    abbreviationError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    isRepresentative: false,
    loading: false,
    participant: [],
    institution: [],
    profileUniqueId: "",
    type: "legal",
    typeError: "",
    uniqueId: "",
  };
  constructor(props: ICreditorViewProps) {
    super(props);

    this.fetchCreditor = this.fetchCreditor.bind(this);
    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.handleAddParticipant = this.handleAddParticipant.bind(this);
    this.handleAddInstitution = this.handleAddInstitution.bind(this);
    this.handleDeleteParticipant = this.handleDeleteParticipant.bind(this);
    this.handleDeleteInstitution = this.handleDeleteInstitution.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleAddParticipant(id: string) {
    this.setState({ participant: [id] });
  }
  public handleDeleteParticipant(id: string) {
    this.setState({ participant: [] });
  }
  public handleAddInstitution(id: string) {
    this.setState({ institution: [id] });
  }
  public handleDeleteInstitution(id: string) {
    this.setState({ institution: [] });
  }

  public handleReactSelectChange(nationality: IOptionType) {
    this.setState({ nationality });
  }

  public componentDidMount() {
    this.setState({ loading: true });
    fetchNationalities({ token: this.props.user.token })
      .then((res) => {
        this.setState(
          {
            nationalityOptions: nationalitiesToOptions(
              res.data.results ? res.data.results : res.data
            ),
          },
          () => {
            this.fetchCreditor();
          }
        );
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando nacionalidades",
          loading: false,
        });
      });
  }
  public fetchCreditor() {
    fetchCR(this.props.user.token, this.props.creditorId)
      .then((res) => {
        const curCreditor = res.data as ICreditor;

        this.setState((prevState) => ({
          abbreviation: curCreditor.abbreviation || "",
          isRepresentative: curCreditor.isRepresentative || false,
          loading: false,
          participant: [curCreditor.participant || ""],
          institution: [curCreditor.institution || ""],
          profileUniqueId: curCreditor?.participantDetail?.uniqueId || "",
          type: curCreditor.type || "",
          uniqueId: curCreditor.uniqueId || "",
        }));
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }

  public handleSelect(key: string, value: string) {
    this.setState({
      [key]: value,
    });
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);

    const creditor: ICreditor = {
      abbreviation: this.state.abbreviation.toUpperCase(),
      isRepresentative: this.state.isRepresentative,
      participant: this.state.participant[0],
      institution:
        this.state.type === "legal" ? this.state.institution[0] : undefined,
      type: this.state.type,
    };

    this.setState({ loading: true });
    updateCR(this.props.user.token, this.state.uniqueId, creditor)
      .then((res) => {
        this.props.updateCreditor(res.data as ICreditor);
        successHandler(res, this, translations.CREDITOR);
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }

  handleResetQuestions = () => {
    resetSecurityQuestions(this.props.user.token, this.state.profileUniqueId)
      .then((res) => {
        this.setState({
          dialog: true,
          dialogTitle: "Éxito",
          errorMessage: res.data.message,
        });
      })
      .catch((error) => {
        // Verifica si hay una respuesta en el error
        if (error.response && error.response.data) {
          const { message, status } = error.response.data;
          console.error("Error al resetear las preguntas:", message);
          if (status === "error") {
            this.setState({
              dialog: true,
              dialogTitle: "Error",
              errorMessage: message,
            });
          } else {
            console.error(message);
          }
        } else {
          // Si no hay respuesta, muestra un mensaje genérico
          console.error("Error al resetear las preguntas:", error);
          this.setState({
            dialog: true,
            dialogTitle: "Error",
            errorMessage:
              "Hubo un problema al intentar reiniciar las preguntas de seguridad.",
          });
        }
      });
  };

  public handleDelete() {
    if (confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });

      deleteCR(this.props.user.token, this.props.creditorId)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteCreditor(this.props.creditorId);
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public handleCheckBoxChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public getIdentificationInput(identificationType: string) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }
  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">{translations.CREDITOR}</Typography>

          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="type" shrink={true}>
                  {translations.TYPE}
                </InputLabel>
                <Select
                  value={this.state.type}
                  onChange={this.handleSelectChange("type")}
                  required={true}
                  disabled={true}
                  error={this.state.typeError !== ""}
                  inputProps={{
                    id: "type",
                    name: "type",
                  }}
                >
                  <MenuItem value="legal">{translations.LEGAL_2}</MenuItem>
                  <MenuItem value="physical">{translations.PHYSICAL}</MenuItem>
                </Select>
              </FormControl>
              <br />
              <FormControlLabel
                label="Es Representante"
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.isRepresentative}
                    onChange={this.handleCheckBoxChange("isRepresentative")}
                  />
                }
                value="isRepresentative"
              />
              <br />
              <TextField
                label={translations.ABBREVIATION}
                className={classes.textField}
                value={this.state.abbreviation.toUpperCase()}
                onChange={this.handleChange("abbreviation")}
                fullWidth={true}
                required={true}
                error={this.state.abbreviationError !== ""}
                helperText={this.state.abbreviationError}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <br />
              {this.state.type === "physical" ? (
                <div>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.CLIENT}
                  </Typography>
                  <ParticipantSelectInput
                    selectedParticipantId={this.state.participant}
                    addParticipant={this.handleAddParticipant}
                    deleteParticipant={this.handleDeleteParticipant}
                  />
                  <div className={classes.controls}>
                    <Button
                      variant="contained"
                      onClick={this.handleDelete}
                      className={classes.deleteButton}
                    >
                      <DeleteIcon />
                      &nbsp;
                      {translations.DELETE}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.greenButton}
                      style={{ marginLeft: 10 }}
                    >
                      <SaveIcon />
                      &nbsp;
                      {translations.SAVE}
                    </Button>
                    <Button
                      onClick={this.handleResetQuestions}
                      variant="contained"
                      className={classes.yellowButton}
                      style={{ marginLeft: 10 }}
                    >
                      <Restore />
                      &nbsp; REINICIAR PREGUNTAS DE SEGURIDAD
                    </Button>
                  </div>
                  {!this.state.loading && (
                    <div>
                      <FinancialProfile ownerId={this.state.profileUniqueId} />
                      <WorkProfile ownerId={this.state.profileUniqueId} />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.INSTITUTION}
                  </Typography>
                  <InstitutionsSelectInput
                    selectedInstitutionId={this.state.institution}
                    addInstitution={this.handleAddInstitution}
                    deleteInstitution={this.handleDeleteInstitution}
                  />
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.COMPANY_REPRESENTATIVE}
                  </Typography>
                  <ParticipantSelectInput
                    selectedParticipantId={this.state.participant}
                    addParticipant={this.handleAddParticipant}
                    deleteParticipant={this.handleDeleteParticipant}
                    participantType="company representative"
                  />
                  <div className={classes.controls}>
                    <Button
                      variant="contained"
                      onClick={this.handleDelete}
                      className={classes.deleteButton}
                    >
                      <DeleteIcon />
                      &nbsp;
                      {translations.DELETE}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.greenButton}
                      style={{ marginLeft: 10 }}
                    >
                      <SaveIcon />
                      &nbsp;
                      {translations.SAVE}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    creditors: state.creditors,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { deleteCreditor, fetchCreditor, updateCreditor })(
    CreditorView
  )
);
