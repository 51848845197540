import { PARTICIPANTBYEXPIRATION_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case PARTICIPANTBYEXPIRATION_TYPES.FETCH_ALL:
            return action.payload || [];
        case PARTICIPANTBYEXPIRATION_TYPES.FETCH:
            return state.map(method => (action.payload !== undefined && method.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: method);
        case PARTICIPANTBYEXPIRATION_TYPES.CREATE: 
            return state.concat(action.payload || []);
        case PARTICIPANTBYEXPIRATION_TYPES.UPDATE:
            return state.map(method => (action.payload !== undefined && method.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : method);
        case PARTICIPANTBYEXPIRATION_TYPES.DELETE:
            return state.filter(method => action.payload !== undefined && method.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}