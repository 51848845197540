import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";

import {
  deleteWarranty,
  fetchWarranty,
  updateWarranty,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import ExtraPaymentTable from "../../../components/Ticket/ExtraPayTable/ExtraPaymentTable";

import {
  catchError,
  resetError,
  successHandler,
  translations,
  warrantyTypes,
  headersVehicleWarranty,
  headersPropertyWarranty,
  headersMortgageWarranty,
} from "../../../utils";
import {
  deleteWarranty as deleteWa,
  fetchWarranty as fetchWa,
  updateWarranty as updateWa,
} from "../../../utils/axios";
import ParticipantSelectInput from "src/containers/Participant/ParticipantSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  half: {
    width: "50%",
  },
});

interface IWarrantyViewProps {
  warranties: IWarranty[];
  warrantyId: string;
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    deleteButton: string;
    formControl: string;
    greenButton: string;
    input: string;
    paper: string;
    root: string;
    textField: string;
    half: string;
  };

  user: IUser;
  changeSection(sectionNumber: number): void;
  deleteWarranty(uniqueId: string): IAction<IWarranty[]>;
  fetchWarranty(warranty: IWarranty): IAction<IWarranty[]>;
  updateWarranty(warranty: IWarranty): IAction<IWarranty[]>;
}

interface IWarrantyViewState {
  [key: string]: string | string[] | number | boolean | Blob;

  type: string;
  typeError: string;

  warrantyDetails: any;

  debtor: string[];
  debtorError: string;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
  uniqueId: string;
  editIdx: number;
}
class WarrantyView extends React.Component<
  IWarrantyViewProps,
  IWarrantyViewState
> {
  public state: IWarrantyViewState = {
    debtor: [],
    debtorError: "",
    warrantyDetails: "",
    type: "",
    typeError: "",
    dialog: false,
    dialogTitle: "",
    loading: false,
    errorMessage: "",
    uniqueId: "",
    editIdx: -1,
  };
  constructor(props: IWarrantyViewProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCheckInputChange = this.handleCheckInputChange.bind(this);

    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);

    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    this.handleCheckInputChange = this.handleCheckInputChange.bind(this);

    this.handleStartEdit = this.handleStartEdit.bind(this);
    this.handleStopEdit = this.handleStopEdit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);

    this.handleChangeTD = this.handleChangeTD.bind(this);
    this.getHeaders = this.getHeaders.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.jsonToArray = this.jsonToArray.bind(this);
    this.arrayToJson = this.arrayToJson.bind(this);
  }
  public arrayToJson(array: any[]): { [key: string]: any } {
    if (array.length === 0) {
      return {};
    }
    const obj: { [key: string]: any } = {};

    array.forEach((q, index) => {
      obj[index] = q;
    });

    return obj;
  }
  public jsonToArray(json: { [key: string]: any }): any[] {
    try {
      const array: any[] = [];

      for (const prop in json) {
        if (json.hasOwnProperty(prop)) {
          array.push({
            propertyDescription: json[prop].propertyDescription || "",
            propertyReferenceNumber: json[prop].propertyReferenceNumber || "",
            propertyAmount: json[prop].propertyAmount || "",
            propertyDate: json[prop].propertyDate || "",
            propertyDocumentType: json[prop].propertyDocumentType || "",
            vehicleType: json[prop].vehicleType || "",
            vehicleBrand: json[prop].vehicleBrand || "",
            vehicleModel: json[prop].vehicleModel || "",
            vehicleYear: json[prop].vehicleYear || "",
            vehicleRegistration: json[prop].vehicleRegistration || "",
            vehicleColor: json[prop].vehicleColor || "",
            vehicleChassis: json[prop].vehicleChassis || "",
            vehicleLicensePlate: json[prop].vehicleLicensePlate || "",
            houseDescription: json[prop].houseDescription || "",
          });
        }
      }

      return array;
    } catch (err) {
      return [];
    }
  }
  public handleSave(data: any) {
    this.setState((state: any) => {
      state.guaranteedChecks = data;
      return state;
    });
  }
  public handleAddClient(id: string) {
    const debtor = this.state.debtor.filter((debtorId) => debtorId !== id);
    debtor.push(id);
    this.setState({ debtor });
  }
  public handleDeleteClient(id: string) {
    const debtor = this.state.debtor.filter((debtorId) => debtorId !== id);
    this.setState({ debtor });
  }
  public handleCheckInputChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public handleReset() {
    this.setState({
      debtor: [],
      debtorError: "",
      warrantyDetails: "",
      type: "",
      typeError: "",
      uniqueId: "",
    });
  }
  public getHeaders() {
    switch (this.state.type) {
      case "property_warranty":
        return headersPropertyWarranty;
      case "vehicle_warranty":
        return headersVehicleWarranty;
      case "mortgage":
        return headersMortgageWarranty;
      default:
        return headersMortgageWarranty;
    }
  }
  public handleStartEdit = (i: any) => {
    this.setState({ editIdx: i });
  };

  public handleStopEdit = () => {
    this.setState({ editIdx: -1 });
  };

  public handleRemove = (i: any) => {
    this.setState((state: any) => {
      state.warrantyDetails = state.warrantyDetails.filter(
        (row: any, j: any) => j !== i
      );
      return state;
    });
  };

  public handleChangeTD = (i: any, x: any) => {
    this.setState((state: any) => {
      state.warrantyDetails = state.warrantyDetails.map((row: any, j: any) =>
        j === i ? x : row
      );
      return state;
    });
    this.handleStopEdit();
  };
  public componentDidMount() {
    this.setState({ loading: true });
    fetchWa(this.props.user.token, this.props.warrantyId)
      .then((res) => {
        const curWarranty = (res.data.results
          ? res.data.results
          : res.data) as IWarranty;

        this.setState({
          type: curWarranty.warrantyType || "",
          debtor: [curWarranty.debtor!] || [],
          uniqueId: curWarranty.uniqueId || "",
          warrantyDetails: this.jsonToArray(curWarranty.warrantyDetails) || [],
          loading: false,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();
    if (!this.state.debtor.length) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "Debe de seleccionar un deudor",
      });
    } else {
      const warranty: IWarranty = {
        debtor: this.state.debtor[0],
        warrantyType: this.state.type,
        warrantyDetails: this.arrayToJson(this.state.warrantyDetails),
      };

      this.setState({ loading: true });

      updateWa(this.props.user.token, this.state.uniqueId, warranty)
        .then((res) => {
          this.props.fetchWarranty(
            (res.data.results ? res.data.results : res.data) as IWarranty
          );
          this.props.updateWarranty(
            (res.data.results ? res.data.results : res.data) as IWarranty
          );
          successHandler(res, this, translations.WARRANTY);
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleDelete() {
    if (confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });

      deleteWa(this.props.user.token, this.props.warrantyId)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteWarranty(this.props.warrantyId);
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.WARRANTIES}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <FormControl
                style={{ width: "fit-content", marginTop: "35px" }}
                className={classes.formControl}
                error={this.state.typeError !== ""}
              >
                <InputLabel style={styles.checkboxLabel} htmlFor="type">
                  {translations.TYPE}
                </InputLabel>
                <Select
                  disabled={true}
                  value={this.state.type}
                  onChange={this.handleSelectChange("type")}
                  required={true}
                  inputProps={{
                    id: "type",
                    name: "type",
                  }}
                >
                  {warrantyTypes.map((opt: any, index: any) => {
                    return (
                      <MenuItem key={index} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.DEBTORS}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtor}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                />
              </div>
              <br />
              <ExtraPaymentTable
                actions={true}
                create={true}
                data={this.state.warrantyDetails}
                editIdx={this.state.editIdx}
                handleChange={this.handleChangeTD}
                handleRemove={this.handleRemove}
                handleSave={this.handleSave}
                handleStartEdit={this.handleStartEdit}
                handleStopEdit={this.handleStopEdit}
                header={this.getHeaders()}
                tableLimit={10}
              />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    warranties: state.warranties,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { deleteWarranty, fetchWarranty, updateWarranty })(
    WarrantyView
  )
);
