import "./Calculators.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as React from "react";
import {
  createStyles,
  withStyles,
  TextField,
} from "@material-ui/core";
import {
  CALCULATORS,
} from "src/utils";
import { green } from "@material-ui/core/colors";
import { connect } from "react-redux";
import PlannedSavingsCalculator from "./PlannedSavingsTable";
import CoopSanCalculator from "./CoopSanTable";
import PaymentCalculator from "./PaymentTable";


const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#4CAF50",
      },
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
      color: "red",
    },
    "& input:focus + fieldset, & select:focus + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& label.Mui-focused": {
      color: "green",
    },
    margin: 8,
  },

})(TextField);

const styles = createStyles({
  autoComplete: {
    color: "#4CAF50",
    margin: 8,
    width: "40%",
  },
  calculate: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  clean: {
    background: "#F44636",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  download: {
    background: "#37C0EC",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  formControl: {
    margin: 8,
  },
  gridFormControl: {
    margin: 8,
    width: '100%',
  },
  pcp: {
    backgroundColor: green[500],
    color: "white",
  },
  save: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
});

class Calculators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calculator: "",
    };

    this.initialState = {
      calculator: "",
    };

    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleAutocompleteChange(key, obj) {
    /* Handle the state of Autocomplete components */
    if (obj) {
      this.setState({
        [key]: obj.value,
      });
    }
  }

  handleReset() {
    this.setState({ ...this.initialState });
  }

  render() {
    // const classes = styles;

    return (
      <div className="quotation-container">
        <section className="header-section1">
          <Autocomplete
              style={{ width: 250 }}
              options={CALCULATORS}
              getOptionLabel={(option) => option.label}
              value={
                this.state.calculator
                  ? CALCULATORS.find(
                      (item) => item.value === this.state.calculator
                    )
                  : null
              }
              id={'calculator'}
              disabled={false}
              onChange={(e, value) =>
                this.handleAutocompleteChange('calculator', value)
              }
              renderInput={(props) => (
                <CssTextField
                  {...props}
                  label={"Elegir calculadora"}
                  variant="outlined"
                />
              )}
            />
        </section>
        {this.state.calculator !== "" ? (
          <section className="header-section2">
              {this.state.calculator === "payment" ? (
                <PaymentCalculator />
              ) : this.state.calculator === "coopsan" ? (
                <CoopSanCalculator />
              ) : this.state.calculator === "planned savings" ? (
                <PlannedSavingsCalculator />
              ) : null}
          </section>
        ): null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps)(Calculators)
);
