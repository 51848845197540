import * as React from 'react';

import { InputBaseComponentProps } from '@material-ui/core/InputBase';

import TextMaskInput from '.';

const passportMaskInput = (props: InputBaseComponentProps) => {
    const { inputRef, ...others } = props;
    return (
        <TextMaskInput 
            {...others}
            guide={false}
            mask={[/[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/, /[a-zA-Zd0-9]/ ]}
            showMask={false}
        />
    );
}

export default passportMaskInput;