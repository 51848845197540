import { FILE_ATTACHMENT_TYPES } from '../actions';

const initialState: IFileAttachment[] = []; 
 
export default function(state: IFileAttachment[] = initialState, action: IAction<IFileAttachment[]>): IFileAttachment[] {
    switch(action.type) {
        case FILE_ATTACHMENT_TYPES.FETCH_ALL:
            return action.payload || [];
        case FILE_ATTACHMENT_TYPES.FETCH:
            return state.map(fileAttachment =>(action.payload !== undefined && fileAttachment.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: fileAttachment);
        case FILE_ATTACHMENT_TYPES.DELETE:
            return state.filter( fileAttachment => action.payload !== undefined && fileAttachment.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}