import * as React from "react";
import { connect } from "react-redux";
import { createBlacklist } from "src/actions";
import AlertDialog from "src/components/AlertDialog";
import Loading from "src/components/Loading";
import Autocomplete from "@material-ui/lab/Autocomplete";
import idNumberMaskInput from "src/components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "src/components/TextMaskInputComponents/passportMaskInput";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";
import {
  IDENTIFICATION_TYPE,
  blacklistReasons,
  catchError,
  successHandler,
  titleCaseHandler,
  translations,
} from "src/utils";
import {
  // fetchParticipants,
  createBlacklist as createBL,
  // fetchTickets as fetchT,
  fetchAssignees,
} from "src/utils/axios";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import ReplayIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import jwtDecode from "jwt-decode";
import { SearchComponent } from "src/components/SharedComponents/Fields";

const styles = createStyles({
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#4CAF50",
      },
    },
    color: "#4CAF50",
    margin: 8,
    width: "50%",
  },
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
    display: "flex",
    float: "right",
    position: "relative",
  },
  clientSection: {
    alignItems: "center",
    display: "flex",
    flex: "auto",
    flexFlow: "wrap",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  formControl: {
    margin: 8,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
});

class BlackListForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addClient: false,
      blacklister: "",
      client: "",
      clients: [],
      // tickets: [],
      creationDate: new Date().toISOString(),
      dialog: false,
      dialogTitle: "",
      errorMessage: "",
      errors: {},
      fullName: "",
      identificationNumber: "",
      identificationType: "",
      isClientStillBlacklisted: true,
      loading: false,
      otherReason: "",
      reason: "",
      reasonError: "",
    };

    this.initialState = this.state;

    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.switchAddClient = this.switchAddClient.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fillClientInfo = this.fillClientInfo.bind(this);
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSwitchDialog = this.handleSwitchDialog.bind(this);
  }
  componentDidMount() {
    const { token } = this.props.user;
    fetchAssignees({ token: token })
      .then((res) => {
        try {
          const loggedUser = res.data.find(
            (user) => user.id === jwtDecode(token).user_id
          ).username;
          this.setState({
            blacklister: loggedUser,
          });
        } catch (e) {
          console.error(e);
        }
      })
      .catch((e) => console.error(e));

    // fetchParticipants({
    //   participantType: "client",
    //   token: this.props.user.token,
    // })
    //   .then((res) => {
    //     this.setState({ clients: res.data });
    //   })
    //   .catch((err) => {
    //     catchError(err, this);
    //   });

    // fetchT({
    //   token: this.props.user.token
    // })
    //   .then((res) => {
    //     this.setState({ tickets: res.data });
    //   })
    //   .catch((err) => {
    //     catchError(err, this);
    //   });
  }

  handleSwitch(key) {
    this.setState({
      [key]: !this.state[key],
    });
  }

  switchAddClient() {
    this.setState({
      addClient: !this.state.addClient,
      client: "",
      fullName: "",
      identificationNumber: "",
      identificationType: "",
    });
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  fillClientInfo(data) {
    if (data) {
      this.setState({
        // client: data.uniqueId ? data.uniqueId : "",
        client: data.value ? data.value : "",
        identificationNumber: data.identificationNumber
          ? data.identificationNumber
          : "",
        identificationType: data.identificationType
          ? data.identificationType
          : "",
      });
    }
  }

  handleAutocompleteChange(key, obj) {
    /* Handle the state of Autocomplete components */
    if (obj) {
      if (key === "client") {
        this.fillClientInfo(obj);
      } else {
        this.setState({
          [key]: obj.value,
        });
      }
    }
  }

  handleSelectChange(key) {
    /* Handle the state of Select components */
    return (event) => {
      this.setState({
        [key]: event.target.value,
      });
    };
  }

  handleReset() {
    this.setState({
      client: "",
      errors: {},
      fullName: "",
      identificationNumber: "",
      identificationType: "",
      otherReason: "",
      reason: "",
      reasonError: "",
    });
  }

  getIdentificationInput(identificationType) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };

    if (fieldValues.addClient) {
      /* Handle validations before saving */
      const fields = ["fullName", "identificationNumber", "identificationType"];
      for (let field of Object.keys(fieldValues)) {
        if (fields.includes(field)) {
          //  Validar que los campos no esten vacíos
          if (!this.state[field]) {
            temp[field] = "Campo Requerido";
          }
        }
      }
    } else {
      temp.client = fieldValues.client ? "" : "Campo Requerido";
    }

    if (fieldValues.reason === "otherReason") {
      temp.otherReason = fieldValues.otherReason ? "" : "Campo Requerido";
    }

    this.setState({
      errors: { ...temp },
    });

    if (fieldValues === this.state) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  handleSubmit(event) {
    event.preventDefault();

    let blacklist = {
      blacklister: this.state.blacklister,
      creationDate: this.state.creationDate,
      isClientStillBlacklisted: this.state.isClientStillBlacklisted,
      reason:
        this.state.reason === "otherReason"
          ? this.state.otherReason.toUpperCase()
          : this.state.reason,
    };
    if (this.state.client) {
      blacklist = {
        ...blacklist,
        customer: this.state.client,
      };
    } else {
      blacklist = {
        ...blacklist,
        temporaryInfo: {
          fullName: titleCaseHandler(this.state.fullName),
          identificationNumber: this.state.identificationNumber.replace(
            /-/g,
            ""
          ),
          identificationType: this.state.identificationType,
        },
      };
    }

    if (this.validate()) {
      createBL(this.props.user.token, blacklist)
        .then((res) => {
          this.props.createBlacklist(
            res.data.results ? res.data.results : res.data
          );
          successHandler(res, this, "Blacklist");
          this.handleReset();
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }

  handleSwitchDialog() {
    this.setState({ dialog: false });
  }

  render() {
    const { classes } = this.props;
    const { errors } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleSwitchDialog}
        />
        <Paper className={classes.paper}>
          <IconButton
            className={classes.button}
            onClick={() => this.props.changeSection(0)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h3">BlackList</Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <div className={classes.clientSection}>
                {!this.state.addClient ? (
                  <SearchComponent
                    xs={4}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    // fieldLabel={translations.PARTICIPANT}
                    fieldID="client"
                    fieldInnerLabel="Nombre del participante"
                    fieldVariant="outlined"
                    handleChange={this.handleAutocompleteChange.bind(
                      this,
                      "client"
                    )}
                    optionType={"participants"}
                    limitTags={1}
                    error={errors.client ? true : false}
                    helperText={errors.client}
                  />
                ) : (
                  // <Autocomplete
                  //   id="clients"
                  //   options={this.state.clients}
                  //   getOptionLabel={(option) => option.fullName}
                  //   className={classes.autoComplete}
                  //   value={
                  //     this.state.client
                  //       ? this.state.clients.find(
                  //           (item) => item.uniqueId === this.state.client
                  //         )
                  //       : null
                  //   }
                  //   onChange={(e, value) =>
                  //     this.handleAutocompleteChange("client", value)
                  //   }
                  //   renderInput={(params) => (
                  //     <TextField
                  //       {...params}
                  //       label="Cliente"
                  //       error={errors.client ? true : false}
                  //       helperText={errors.client}
                  //       variant="outlined"
                  //     />
                  //   )}
                  // />
                  <TextField
                    className={classes.autoComplete}
                    label={translations.CLIENT}
                    error={errors.fullName ? true : false}
                    helperText={errors.fullName}
                    value={titleCaseHandler(this.state.fullName)}
                    onChange={this.handleChange("fullName")}
                    variant="outlined"
                  />
                )}
                <IconButton onClick={() => this.switchAddClient()}>
                  {!this.state.addClient ? <AddCircleIcon /> : <CancelIcon />}
                </IconButton>
              </div>

              <FormControl
                className={classes.formControl}
                error={errors.identificationType ? true : false}
                variant="outlined"
              >
                <InputLabel id="identificationType">
                  {translations.IDENTIFICATION_TYPE}
                </InputLabel>
                <Select
                  id="identificationType"
                  value={this.state.identificationType}
                  disabled={!this.state.addClient}
                  style={{ width: 200 }}
                  label={translations.IDENTIFICATION_TYPE}
                  onChange={this.handleSelectChange("identificationType")}
                >
                  {IDENTIFICATION_TYPE.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.identificationType}</FormHelperText>
              </FormControl>
              <br />

              <TextField
                style={{ margin: 8 }}
                label={translations.IDENTIFICATION}
                value={this.state.identificationNumber}
                disabled={!this.state.addClient}
                onChange={this.handleChange("identificationNumber")}
                variant="outlined"
                error={errors.identificationNumber ? true : false}
                helperText={errors.identificationNumber}
                InputLabelProps={{ shrink: true }}
                InputProps={this.getIdentificationInput(
                  this.state.identificationType
                )}
              />
              <br />
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={this.state.reasonError ? true : false}
              >
                <InputLabel id="reason">Razon de Bloqueo</InputLabel>
                <Select
                  id="reason"
                  value={this.state.reason}
                  style={{ width: 400 }}
                  label="Razon de Bloqueo"
                  onChange={this.handleSelectChange("reason")}
                >
                  {blacklistReasons.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label.toUpperCase()}
                      </MenuItem>
                    );
                  })}
                  <MenuItem value="otherReason">Otro</MenuItem>
                </Select>
                <FormHelperText>{this.state.reasonError}</FormHelperText>
              </FormControl>
              <br />
              {[
                "otherReason",
                "pendingDocumentation",
                "paymentConfigurationPending",
              ].includes(this.state.reason) ? (
                <TextField
                  label="Describa la razon de Bloqueo"
                  variant="outlined"
                  multiline={true}
                  style={{ width: "500px", marginLeft: "8px" }}
                  rows={3}
                  rowsMax={5}
                  error={errors.otherReason ? true : false}
                  helperText={errors.otherReason}
                  value={this.state.otherReason.toUpperCase()}
                  onChange={this.handleChange("otherReason")}
                />
              ) : null}
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.deleteButton}
                >
                  <ReplayIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    blacklist: state.blacklist,
    user: state.user,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    createBlacklist,
  })(BlackListForm)
);
