
import { NATIONALITY_TYPES } from '../actions';

export default function(state: INationality[] = [], action: IAction<INationality[]>): INationality[] {
    switch(action.type) {
        case NATIONALITY_TYPES.FETCH_ALL:
            return action.payload || [];
        case NATIONALITY_TYPES.FETCH:
            return state.map(nationality => (action.payload !== undefined && nationality.id === action.payload[0].id) ? action.payload[0]: nationality);
        case NATIONALITY_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case NATIONALITY_TYPES.UPDATE:
            return state.map(nationality => (action.payload !== undefined && nationality.id === action.payload[0].id) ? action.payload[0] : nationality);
        case NATIONALITY_TYPES.DELETE:
            return state.filter(nationality => action.payload !== undefined && nationality.id !== action.payload[0].id);
        default:
            return state;
    }
}