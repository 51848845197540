import './App.css';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import BotBoard from 'src/containers/BOT/index.js';
import Dashboard from 'src/containers/Dashboard/index.js';

import LoginForm from '../../containers/LoginForm';
import TmsDashboard from '../tmsDashboard';

const App = () => {
  return(
    <BrowserRouter>
        <div className="App">
          <Switch>
            <Route path="/tmsdashboard" component={(props:any) => <TmsDashboard {...props} />}/>
            <Route path="/dashboard" component={(props:any) => <Dashboard {...props} />} />
            <Route path="/bot" component={(props:any) => <BotBoard {...props} />} />
            <Route path="/" component={(props:any) => <LoginForm {...props} />} />
          </Switch>
        </div>
    </BrowserRouter>
  )
}
export default App;
