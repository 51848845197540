import { AxiosError } from "axios";
import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createPrivateInstitution } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import phoneMaskInput from "../../../components/TextMaskInputComponents/phoneMaskInput";
import rncMaskInput from "../../../components/TextMaskInputComponents/rncMaskInput";
import {
  catchError,
  paymentFrequency,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
  institutionClassification,
  institutionStatus,
} from "../../../utils";
import { createInstitution, fetchAssignees } from "../../../utils/axios";
import Address from "../../../components/Address/Address";
import MainAddress from "../../../components/Address/MainAddress";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import jwtDecode from "jwt-decode";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface IPrivateInstitutionFormProps {
  classes: { [key: string]: string };

  user: IUser;
  createPrivateInstitution(institution: IInstitution): IAction<IInstitution[]>;
  changeSection(sectionNumber: number): void;
}
interface IPrivateInstitutionFormState {
  [key: string]: string | number | boolean | any;

  abbreviation: string;
  abbreviationError: string;

  city: string;
  cityError: string;

  country: string;
  countryError: string;

  reference: string;
  referenceError: string;

  sector: string;
  sectorError: string;

  state: string;
  stateError: string;

  email: string;
  emailError: string;

  mainAddressCity: string;
  mainAddressCityError: string;

  mainAddressCountry: string;
  mainAddressCountryError: string;

  mainAddressReference: string;
  mainAddressReferenceError: string;

  mainAddressSector: string;
  mainAddressSectorError: string;

  mainAddressState: string;
  mainAddressStateError: string;

  mainAddressRegion: string;
  mainAddressRegionError: string;

  region: string;
  regionError: string;

  merchantRecord: string;
  merchantRecordError: string;

  name: string;
  nameError: string;

  paymentFrequency: string;
  paymentFrequencyError: any;

  paymentDay: any;
  paymentDayError: any;

  secondPaymentDay: number;
  secondPaymentDayError: any;

  institutionClassification: string;
  institutionClassificationError: string;

  phoneNumber: string;
  phoneNumberError: string;

  registry: string;
  registryError: string;

  registryEndDate: string;
  registryEndDateError: string;

  tradename: string;
  tradenameError: string;

  website: string;
  websiteError: string;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;

  status: string;
  statusError: string;

  roles: string[];
}
class PrivateInstitutionForm extends React.Component<
  IPrivateInstitutionFormProps,
  IPrivateInstitutionFormState
> {
  public state: IPrivateInstitutionFormState = {
    abbreviation: "",
    abbreviationError: "",
    city: "Santo Domingo",
    cityError: "",
    country: "República Dominicana",
    countryError: "",
    dialog: false,
    dialogTitle: "",
    email: "",
    emailError: "",
    errorMessage: "",
    loading: false,
    mainAddressCity: "",
    mainAddressCityError: "",
    mainAddressCountry: "",
    mainAddressCountryError: "",
    mainAddressState: "",
    mainAddressStateError: "",
    mainAddressRegion: "",
    mainAddressRegionError: "",
    mainAddressReference: "",
    mainAddressReferenceError: "",
    mainAddressSector: "",
    mainAddressSectorError: "",
    merchantRecord: "",
    merchantRecordError: "",
    name: "",
    nameError: "",
    paymentDay: 0,
    paymentDayError: "",
    secondPaymentDay: 0,
    secondPaymentDayError: "",
    paymentFrequency: "monthly",
    paymentFrequencyError: "",
    institutionClassification: "",
    institutionClassificationError: "",
    phoneNumber: "",
    phoneNumberError: "",
    state: "",
    stateError: "",
    region: "",
    regionError: "",
    reference: "",
    referenceError: "",
    registry: "",
    registryEndDate: "",
    registryEndDateError: "",
    registryError: "",
    sector: "",
    sectorError: "",
    tradename: "",
    tradenameError: "",
    website: "",
    websiteError: "",
    status: "not affiliate",
    statusError: "",
    roles: [],
  };

  constructor(props: IPrivateInstitutionFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }

  public handleSelect(key: string, value: string) {
    this.setState({
      [key]: value,
    });
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    let institution: IInstitution = {
      state: this.state.status,
      abbreviation: this.state.abbreviation.toUpperCase(),
      address: {
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        region: this.state.region,
        reference: this.state.reference,
        sector: titleCaseHandler(this.state.sector),
      },
      email: this.state.email,
      institutionType: "private",
      mainAddress: {
        city: this.state.mainAddressCity,
        country: this.state.mainAddressCountry,
        state: this.state.mainAddressState,
        region: this.state.mainAddressRegion,
        reference: this.state.mainAddressReference,
        sector: titleCaseHandler(this.state.mainAddressSector),
      },
      merchantRecord: this.state.merchantRecord,
      name: this.state.name.toUpperCase(),
      paymentFrequency: this.state.paymentFrequency,
      paymentDay: this.state.paymentDay,
      secondPaymentDay: this.state.secondPaymentDay,
      institutionClassification: this.state.institutionClassification,
      phoneNumber: this.state.phoneNumber,
      registry: this.state.registry.replace(/-/g, ""),
      registryEndDate: this.state.registryEndDate,
      tradename: this.state.tradename,
      website: this.state.website,
    };

    this.setState({ loading: true });
    const {
      mainAddressCity,
      mainAddressCountry,
      mainAddressState,
      mainAddressReference,
      mainAddressRegion,
      mainAddressSector,
      merchantRecord,
      tradename,
      registryEndDate,
    } = this.state;

    if (
      mainAddressCity.trim() === "" &&
      mainAddressCountry.trim() === "" &&
      mainAddressReference.trim() === "" &&
      mainAddressRegion.trim() === "" &&
      mainAddressSector.trim() === "" &&
      mainAddressState.trim() === ""
    ) {
      // @ts-ignore
      institution = Object.assign(institution, { mainAddress: null });
    }

    if (merchantRecord.trim() === "") {
      institution = Object.assign(institution, { merchantRecord: null });
    }

    if (tradename.trim() === "") {
      institution = Object.assign(institution, { tradename: null });
    }

    if (registryEndDate.trim() === "") {
      institution = Object.assign(institution, { registryEndDate: null });
    }

    createInstitution(this.props.user.token, institution)
      .then((res) => {
        this.props.createPrivateInstitution(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, translations.INSTITUTION);
        this.handleReset();
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }

  public handleReset() {
    this.setState({
      abbreviation: "",
      abbreviationError: "",
      city: "Santo Domingo",
      cityError: "",
      country: "República Dominicana",
      countryError: "",
      email: "",
      emailError: "",
      mainAddressCity: "",
      mainAddressCityError: "",
      mainAddressCountry: "",
      mainAddressCountryError: "",
      mainAddressReference: "",
      mainAddressReferenceError: "",
      mainAddressRegion: "",
      mainAddressRegionError: "",
      mainAddressSector: "",
      mainAddressSectorError: "",
      mainAddressState: "",
      mainAddressStateError: "",
      merchantRecord: "",
      merchantRecordError: "",
      name: "",
      nameError: "",
      paymentDay: 0,
      paymentDayError: "",
      secondPaymentDay: 0,
      secondPaymentDayError: "",
      paymentFrequency: "monthly",
      paymentFrequencyError: "",
      institutionClassification: "",
      institutionClassificationError: "",
      phoneNumber: "",
      phoneNumberError: "",
      reference: "",
      referenceError: "",
      region: "",
      regionError: "",
      registry: "",
      registryEndDate: "",
      registryEndDateError: "",
      registryError: "",
      sector: "",
      sectorError: "",
      state: "",
      stateError: "",
      tradename: "",
      tradenameError: "",
      website: "",
      websiteError: "",
      status: "",
    });
  }

  public handleDialogClose() {
    this.setState({ dialog: false });
  }

  public componentDidMount() {
    this.setState({ loading: true });
    const userId: any = jwtDecode<IUser>(this.props.user.token).user_id;
    fetchAssignees({ token: this.props.user.token, id: userId }).then(
      (response) => {
        const data = response.data[0];
        this.setState({ roles: data.groups, loading: false });
      }
    );
  }
  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.NEW_INSTITUTION}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <FormControl
                className={classes.textField}
                error={this.state.statusError !== ""}
              >
                <InputLabel htmlFor="status" shrink={true}>
                  {translations.STATUS}
                </InputLabel>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                  disabled={
                    !this.state.roles.includes("institution-change-state")
                  }
                  required={this.state.roles.includes(
                    "institution-change-state"
                  )}
                  inputProps={{
                    id: "status",
                    name: "status",
                  }}
                >
                  {institutionStatus.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={this.state.name.toUpperCase()}
                onChange={this.handleChange("name")}
                fullWidth={true}
                required={true}
                error={this.state.nameError !== ""}
                helperText={this.state.nameError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.ABBREVIATION}
                className={classes.textField}
                value={this.state.abbreviation.toUpperCase()}
                onChange={this.handleChange("abbreviation")}
                fullWidth={true}
                required={true}
                error={this.state.abbreviationError !== ""}
                helperText={this.state.abbreviationError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                className={classes.textField}
                label={translations.RNC}
                placeholder="0-00-00000-0"
                value={this.state.registry}
                onChange={this.handleChange("registry")}
                fullWidth={true}
                required={true}
                error={this.state.registryError !== ""}
                helperText={this.state.registryError}
                InputProps={{
                  inputComponent: rncMaskInput,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.REGISTRY_END_DATE}
                className={classes.textField}
                value={this.state.registryEndDate}
                type="date"
                onChange={this.handleChange("registryEndDate")}
                error={this.state.registryEndDateError !== ""}
                helperText={this.state.registryEndDateError}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.EMAIL}
                className={classes.textField}
                placeholder="mycompany@email.com"
                type="email"
                value={this.state.email}
                onChange={this.handleChange("email")}
                fullWidth={true}
                required={true}
                error={this.state.emailError !== ""}
                helperText={this.state.emailError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.WEBSITE}
                className={classes.textField}
                placeholder="https://www.mysite.com"
                type="url"
                value={this.state.website}
                onChange={this.handleChange("website")}
                fullWidth={true}
                error={this.state.websiteError !== ""}
                helperText={this.state.websiteError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.PHONE}
                className={classes.textField}
                value={this.state.phoneNumber}
                onChange={this.handleChange("phoneNumber")}
                type="phone"
                placeholder="+1(809) 000-0000"
                fullWidth={true}
                required={true}
                error={this.state.phoneNumberError !== ""}
                helperText={this.state.phoneNumberError}
                InputProps={{
                  inputComponent: phoneMaskInput,
                }}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              />
              <FormControl
                className={classes.textField}
                error={this.state.paymentFrequencyError !== ""}
              >
                <InputLabel htmlFor="paymentFrequency" shrink={true}>
                  {translations.PAYMENT_FREQUENCY}
                </InputLabel>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.paymentFrequency}
                  onChange={this.handleSelectChange("paymentFrequency")}
                  required={true}
                  inputProps={{
                    id: "paymentFrequency",
                    name: "paymentFrequency",
                  }}
                >
                  {paymentFrequency.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                label={"Dias de pago"}
                type="number"
                className={classes.textField}
                value={this.state.paymentDay}
                onChange={this.handleChange("paymentDay")}
                fullWidth={true}
                error={this.state.paymentDayError.length === 0 ? false : true}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
                inputProps={{
                  max: 31,
                  min: 1,
                  step: 1,
                }}
              />
              {this.state.paymentFrequency === "biweekly" ? (
                <TextField
                  label={"Dias de pago 2"}
                  type="number"
                  className={classes.textField}
                  value={this.state.secondPaymentDay}
                  onChange={this.handleChange("secondPaymentDay")}
                  fullWidth={true}
                  error={
                    this.state.secondPaymentDayError.length === 0 ? false : true
                  }
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                  inputProps={{
                    max: 31,
                    min: 1,
                    step: 1,
                  }}
                />
              ) : null}
              <FormControl
                className={classes.textField}
                error={this.state.institutionClassificationError !== ""}
              >
                <InputLabel htmlFor="institutionClassification" shrink={true}>
                  Clasificación de institución
                </InputLabel>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.institutionClassification}
                  onChange={this.handleSelectChange(
                    "institutionClassification"
                  )}
                  required={true}
                  inputProps={{
                    id: "institutionClassification",
                    name: "institutionClassification",
                  }}
                >
                  {institutionClassification.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                label={translations.MERCHANT_RECORD}
                className={classes.textField}
                value={this.state.merchantRecord}
                onChange={this.handleChange("merchantRecord")}
                fullWidth={true}
                error={this.state.merchantRecordError !== ""}
                helperText={this.state.merchantRecordError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.TRADE_NAME}
                className={classes.textField}
                value={this.state.tradename.toUpperCase()}
                onChange={this.handleChange("tradename")}
                fullWidth={true}
                error={this.state.tradenameError !== ""}
                helperText={this.state.tradenameError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />

              <Address
                city={this.state.city}
                cityError={this.state.cityError}
                country={this.state.country}
                countryError={this.state.countryError}
                handleChange={this.handleChange}
                handleSelect={this.handleSelect}
                reference={this.state.reference}
                referenceError={this.state.referenceError}
                region={this.state.region}
                regionError={this.state.regionError}
                sector={this.state.sector}
                sectorError={this.state.sectorError}
                state={this.state.state}
                stateError={this.state.stateError}
                title={translations.ADDRESS}
              />

              <MainAddress
                mainAddressCity={this.state.mainAddressCity}
                mainAddressCityError={this.state.mainAddressCityError}
                mainAddressCountry={this.state.mainAddressCountry}
                mainAddressCountryError={this.state.mainAddressCountryError}
                handleChange={this.handleChange}
                handleSelect={this.handleSelect}
                mainAddressReference={this.state.mainAddressReference}
                mainAddressReferenceError={this.state.mainAddressReferenceError}
                mainAddressRegion={this.state.mainAddressRegion}
                mainAddressRegionError={this.state.mainAddressRegionError}
                mainAddressSector={this.state.mainAddressSector}
                mainAddressSectorError={this.state.mainAddressSectorError}
                mainAddressState={this.state.mainAddressState}
                mainAddressStateError={this.state.mainAddressStateError}
                title={translations.MAIN_ADDRESS}
              />
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createPrivateInstitution })(PrivateInstitutionForm)
);
