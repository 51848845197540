
import { LOAN_CONTRACT_TYPES } from '../actions';

export default function(state: ILoanContract[] = [], action: IAction<ILoanContract[]>): ILoanContract[] {
    switch(action.type) {
        case LOAN_CONTRACT_TYPES.FETCH_ALL:
            return action.payload || [];
        case LOAN_CONTRACT_TYPES.FETCH:
            return state.map(loanContract => (action.payload !== undefined && loanContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: loanContract);
        case LOAN_CONTRACT_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case LOAN_CONTRACT_TYPES.UPDATE:
            return state.map(loanContract => (action.payload !== undefined && loanContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : loanContract);
        case LOAN_CONTRACT_TYPES.DELETE:
            return state.filter(loanContract => action.payload !== undefined && loanContract.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}