import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DeleteIcon from "@material-ui/icons/Delete";
import WorkIcon from "@material-ui/icons/Work";

import { fetchClients } from "../../../actions";
import { fetchParticipants, fetchParticipant } from "../../../utils/axios";
import ClientSearchSection from "../../Client/ClientSearchSection";
import FinancialProfile from "../../FinancialProfile/index";
import WorkProfile from "../../WorkProfile";
import ClientForm from "../ClientForm";
import ClientView from "../ClientView";
import AvailabilityCalculator from "../../../components/Calculators/AvailabilityCalculator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { Edit } from "@material-ui/icons";

const styles: any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

interface IFindParticipantsProps {
  token: string;
  participantId: string;
  functionProps: any;
}

const FindParticipants: React.FC<IFindParticipantsProps> = ({
  token,
  participantId,
  functionProps,
}) => {
  const {
    openFinancialProfile,
    openWorkProfile,
    openAvaibilityCalc,
    openClientEdit,
    handleDeleteItem,
  } = functionProps;
  const [curParticipant, setCurParticipant] = React.useState({
    uniqueId: "",
    fullName: "",
    identificationNumber: "",
  });

  React.useEffect(() => {
    if (participantId) {
      fetchParticipant(token, participantId)
        .then((res) => {
          setCurParticipant(res.data.results ? res.data.results : res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return curParticipant && participantId ? (
    <TableRow key={curParticipant.uniqueId}>
      <TableCell>{curParticipant.fullName}</TableCell>
      <TableCell>{curParticipant.identificationNumber}</TableCell>
      <TableCell align="center">
        <IconButton
          onClick={openFinancialProfile(
            curParticipant.uniqueId ? curParticipant.uniqueId : ""
          )}
        >
          <AssignmentIndIcon />
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={openWorkProfile(
            curParticipant.uniqueId ? curParticipant.uniqueId : ""
          )}
        >
          <WorkIcon />
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={openAvaibilityCalc(
            curParticipant.uniqueId ? curParticipant.uniqueId : ""
          )}
        >
          <FontAwesomeIcon icon={faCalculator} />
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={openClientEdit(
            curParticipant.uniqueId ? curParticipant.uniqueId : ""
          )}
        >
          <Edit />
        </IconButton>
        <IconButton
          onClick={handleDeleteItem(
            curParticipant.uniqueId ? curParticipant.uniqueId : ""
          )}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ) : null;
};

interface IClientSelectInputProps {
  classes: {
    button: string;
    root: string;
  };

  user: IUser;
  clients: IParticipant[];
  selectedClientsId: string[];
  addClient(clientId: string): void;
  deleteClient(clientId: string): void;
  fetchClients(clients: IParticipant[]): IAction<IParticipant[]>;
}
interface IClientSelectInputState {
  clients: IParticipant[];
  formDialog: boolean;
  loading: boolean;
  open: boolean;
  financialProfileDialog: boolean;
  id: string;
  workProfileDialog: boolean;
  avaiabilityCalcDialog: boolean;
  clientEditDialog: boolean;
}

class ClientSelectInput extends React.Component<
  IClientSelectInputProps,
  IClientSelectInputState
> {
  public state: IClientSelectInputState = {
    clients: [],
    financialProfileDialog: false,
    formDialog: false,
    id: "",
    loading: false,
    open: false,
    workProfileDialog: false,
    avaiabilityCalcDialog: false,
    clientEditDialog: false,
  };
  constructor(props: IClientSelectInputProps) {
    super(props);

    this.openFinancialProfile = this.openFinancialProfile.bind(this);
    this.closeFinancialProfile = this.closeFinancialProfile.bind(this);
    this.openWorkProfile = this.openWorkProfile.bind(this);
    this.closeWorkProfile = this.closeWorkProfile.bind(this);
    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.openAvaibilityCalc = this.openAvaibilityCalc.bind(this);
    this.closeAvaibilityCalc = this.closeAvaibilityCalc.bind(this);
    this.openClientEdit = this.openClientEdit.bind(this);
    this.closeClientEdit = this.closeClientEdit.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
  }
  public openFinancialProfile(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.setState({ id, financialProfileDialog: true });
    };
  }
  public closeFinancialProfile() {
    this.setState({ financialProfileDialog: false });
  }
  public openAvaibilityCalc(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.setState({ id, avaiabilityCalcDialog: true });
    };
  }
  public closeAvaibilityCalc() {
    this.setState({ avaiabilityCalcDialog: false });
  }
  public openClientEdit(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.setState({ id, clientEditDialog: true });
    };
  }
  public closeClientEdit() {
    this.setState({ clientEditDialog: false });
  }
  public openWorkProfile(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.setState({ id, workProfileDialog: true });
    };
  }
  public closeWorkProfile() {
    this.setState({ workProfileDialog: false });
  }

  public componentDidMount() {
    this.setState({ loading: true });
    fetchParticipants({ token: this.props.user.token, limit: 20, offset: 0 })
      .then((res) => {
        this.props.fetchClients(res.data.results ? res.data.results : res.data);
        this.setState({
          clients: (res.data.results
            ? res.data.results
            : res.data) as IParticipant[],
          loading: false,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public openDialogHandler() {
    this.setState({ open: true });
  }
  public closeDialogHandler() {
    this.setState({ open: false });
  }
  public openFormDialog() {
    this.setState({ formDialog: true });
  }
  public closeFormDialog() {
    this.setState({ formDialog: false });
  }
  public emptyFunction() {
    return null;
  }
  public handleItemClick(id: string) {
    this.props.addClient(id);
    this.setState({ open: false });
  }
  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.props.deleteClient(id);
    };
  }

  public fetchData(params: IFetchParticipantParams) {
    params.limit = 20;
    params.offset = 0;

    this.setState({ loading: true });
    fetchParticipants(params)
      .then((res) => {
        this.props.fetchClients(
          (res.data.results ? res.data.results : res.data) as IParticipant[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public render() {
    const { classes } = this.props;
    const {
      financialProfileDialog,
      formDialog,
      id,
      loading,
      open,
      workProfileDialog,
      avaiabilityCalcDialog,
      clientEditDialog,
    } = this.state;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={this.closeDialogHandler}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.openFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: 400, width: 280 }}>
            <ClientSearchSection
              loading={loading}
              handleClick={this.handleItemClick}
              fetchData={this.fetchData}
              pagination={false}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={formDialog}
          fullScreen={true}
          onClose={this.closeFormDialog}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <ClientForm
              changeSection={this.emptyFunction}
              handleSaveCallback={this.emptyFunction}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={financialProfileDialog}
          onClose={this.closeFinancialProfile}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFinancialProfile}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <FinancialProfile ownerId={id} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={workProfileDialog}
          onClose={this.closeWorkProfile}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeWorkProfile}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <WorkProfile ownerId={id} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={clientEditDialog}
          onClose={this.closeClientEdit}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeClientEdit}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <ClientView
              changeSection={() => null}
              clientId={id}
              viewOnly={true}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={avaiabilityCalcDialog}
          onClose={this.closeAvaibilityCalc}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeAvaibilityCalc}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <AvailabilityCalculator ownerId={id} />
          </DialogContent>
        </Dialog>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">{translations.NAME}</TableCell>
                <TableCell align="center">
                  {translations.IDENTIFICATION}
                </TableCell>
                <TableCell align="center">
                  {translations.FINANCIAL_PROFILE}
                </TableCell>
                <TableCell align="center">
                  {translations.WORK_PROFILE}
                </TableCell>
                <TableCell align="center">
                  {translations.AVAILABILITY_CALCULATOR}
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={this.openDialogHandler}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.selectedClientsId.map((participantId) => (
                <FindParticipants
                  key={participantId}
                  token={this.props.user.token}
                  participantId={participantId}
                  functionProps={{
                    openFinancialProfile: this.openFinancialProfile,
                    openWorkProfile: this.openWorkProfile,
                    openAvaibilityCalc: this.openAvaibilityCalc,
                    openClientEdit: this.openClientEdit,
                    handleDeleteItem: this.handleDeleteItem,
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    clients: state.clients,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchClients })(ClientSelectInput)
);
