import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Grid, makeStyles } from "@material-ui/core";
import LoadingDialog from "src/components/SharedComponents/LoadingScreen";
import { ToolbarComponent } from "src/components/SharedComponents/Toolbar";
import { GetApp } from "@material-ui/icons";
import RohiDownload from "../Dialogs/RohiDownload";
import {
  ConfirmDialog,
  SimpleConfirmDialog,
} from "src/components/SharedComponents/Dialogs";
import UploadFile from "../Dialogs/UploadFile";
import { GetInsuranceRecord, UploadInsuranceRecord, http } from "src/utils/axios";
import { errorHandling } from "src/components/SharedComponents/CustomHooks";
import InsuranceTable from "../Table";

const useStyles = makeStyles({
  root: {
    padding: "20px 20px 10% 20px",
  },
  content: {
    padding: "1%",
    border: "1px solid #EEEEEE",
    borderTop: "none",
  },
  filters: {
    marginTop: "35px",
    justifyContent: "space-between",
  },
});

function InsuranceReport(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState("");
  const [simpleDialog, setSimpleDialog] = useState("");
  const [file, setFile] = useState(null);
  const [params, setParams] = useState({});
  const [filters, setFilters] = useState({ year: null, month: null });
  const [tableData, setTableData] = useState([]);

  const handleToggleDialog = (dialog) => {
    const selectedDialog = dialog ? dialog : "";
    setDialog(selectedDialog);
  };
  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };

  const getInsuranceReports = async () => {
    try {
      setLoading(true); 
      const response = await GetInsuranceRecord(props.user.token); 
  
      setTableData(response.data);
  
      setLoading(false); 
    } catch (error) {
      setLoading(false); 
      console.error("Error fetching insurance reports:", error);
    }
  };
  

  useEffect(() => {
    getInsuranceReports();
    }, []);

  const DefineParams = (confirmation) => {
    const formattedDate = `${filters.year}-${filters.month?.value}`;
    let formData = new FormData();
    formData.append("file", file);
    formData.append("date", formattedDate);
    formData.append("confirmation", confirmation)

    return formData;
  };

  const UploadReport = async () => {
    try {
      if (!filters.year || !filters.month) {
        handleToggleSimpleDialog(
          "simple-dialog",
          "Por favor, seleccione el mes y el año para cargar el archivo."
        );
      } else {
        setLoading(true);
        const params = DefineParams(false);
        const response = await UploadInsuranceRecord(props.user.token, params);
  
        setFile(null);
        setLoading(false);
  
        if (response.data?.file_exists) {
          handleToggleSimpleDialog("confirm-dialog", "file-upload", {
            callback: UpdateReport,
          });
        } else {
          handleToggleSimpleDialog(
            "simple-dialog",
            "Archivo subido con éxito."
          );
        }
      }
    } catch (errors) {
      setFile(null);
      setLoading(false);
      errorHandling(errors, handleToggleSimpleDialog);
    }
  };
  
  const UpdateReport = async () => {
    try {
      setLoading(true);
      const params = DefineParams(true);
      const response = await UploadInsuranceRecord(props.user.token, params);
      if(response.data.message == "File in Minio updated successfully"){
          handleToggleSimpleDialog("simple-dialog", "Archivo reemplazado con éxito.");
      }else{
        handleToggleSimpleDialog("simple-dialog", "No se pudo reemplazar el archivo.");
      }
      setLoading(false);
    } catch (errors) {
      setLoading(false);
      errorHandling(errors, handleToggleSimpleDialog);
    }
  };
  
  const handleDownload = async (record) => {
    try {
      const response = await fetch(record.url);
      const blob = await response.blob();
  
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${record.filename}.xlsx`;
      link.style.display = 'none';
  
      document.body.appendChild(link);
  
      link.click();
  
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  
  

  return (
    <Grid className={classes.root}>
      <LoadingDialog open={loading} />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle="Reportes de Seguro"
        btnVariant="contained"
        toolbarActions={
          <Button
            variant="contained"
            className={"action-method-btn"}
            disableElevation
            size="small"
            style={{ marginRight: "2rem" }}
            onClick={handleToggleDialog.bind(this, "rohi-download")}
          >
            <GetApp
              style={{ fontSize: 20, color: "#fff", marginRight: ".5rem" }}
            />
            descargar reporte rohi
          </Button>
        }
      />
      <Grid className={classes.content}>
        <UploadFile
          UploadReport={UploadReport}
          file={file}
          setFile={setFile}
          filters={filters}
          setFilters={setFilters}
        />
        <div style={{ margin: "25px 0" }}></div>
        <InsuranceTable data={tableData} handleDownload={handleDownload}/>
      </Grid>
      {dialog === "rohi-download" ? (
        <RohiDownload
          open={true}
          setLoading={setLoading}
          handleToggleDialog={handleToggleDialog}
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          token={props.user.token}
        />
      ) : null}

      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
      {simpleDialog.name === "confirm-dialog" ? (
        <ConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          confirmCallback={simpleDialog.data.callback}
          data={
            "Ya existe un archivo en la fecha seleccionada, ¿Desea reemplazarlo?"
          }
        />
      ) : null}
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(InsuranceReport);
