
import { CANCELLATION_MEMBERSHIP_CONTRACT_TYPES } from '../actions';

export default function (state: ICancellationMembershipContract[] = [], action: IAction<ICancellationMembershipContract[]>): ICancellationMembershipContract[] {
    switch (action.type) {
        case CANCELLATION_MEMBERSHIP_CONTRACT_TYPES.FETCH_ALL:
            return action.payload || [];
        case CANCELLATION_MEMBERSHIP_CONTRACT_TYPES.FETCH:
            return state.map(CSC => (action.payload !== undefined && CSC.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : CSC);
        case CANCELLATION_MEMBERSHIP_CONTRACT_TYPES.CREATE:
            return state.concat(action.payload || []).reverse();
        case CANCELLATION_MEMBERSHIP_CONTRACT_TYPES.UPDATE:
            return state.map(CSC => (action.payload !== undefined && CSC.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : CSC);
        case CANCELLATION_MEMBERSHIP_CONTRACT_TYPES.DELETE:
            return state.filter(CSC => action.payload !== undefined && CSC.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}