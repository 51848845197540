import * as React from 'react';
import { connect } from 'react-redux';
import { translations } from 'src/utils';

import { List, ListItem, ListItemText } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FilterListIcon from '@material-ui/icons/FilterList';

import FilterDialog from '../../../components/FilterDialog';

const styles = createStyles({
    filterButton: {
        width: '20%'
    },
    paginationGroup: {
        border: '.5px solid rgba(25,25,25,0.2)',
        bottom: 0,
        display: 'flex',
        height: 50,
        justifyContent: 'center',
        justifyItems: 'center',
        position: 'absolute',
        textAlign: 'center',
        width: 280
    },
    root: {
        borderRightColor: 'rgba(25,25,25,0.2)',
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        bottom: 51,
        boxSizing: 'border-box',
        overflowY: 'auto',
        padding: 10,
        paddingLeft: 15,
        position: 'absolute',
        top: 65,
        width: 280
    },
    textField: {
        width: '80%'
    }
});

class ProfessionsSearchSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        filterField: 'name',
        limit: 20,
        offset: 0,
        open: false,
        searchValue: '',
        searching: false,
    }

        this.openDialog = this.openDialog.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.mapFunction = this.mapFunction.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);

    }
    openDialog() {

        this.setState({ open: true });
    }
    handleDialogClose(value) {
        this.setState({
            filterField: value,
            open: false
        });
    }
    handleChange(event) {
        if (!this.state.searching || event.currentTarget.value === '') {

            const params = {
                limit: this.state.limit,
                offset: 0,
                token: this.props.user.token
            };
            params[this.state.filterField] = event.currentTarget.value;

            this.setState({
                offset: 0,
                searchValue: event.currentTarget.value,
                searching: true
            });

            this.props.fetchData(params);

            setTimeout(() => this.setState({ searching: false }), 300);

        } else {
            this.setState({ searchValue: event.currentTarget.value });
        }
    }

    mapFunction(profession) {

        return (
            <ListItem divider={true} key={profession.uniqueId} button={true} onClick={this.selectItem(profession.uniqueId || '')}>
                <ListItemText primary={profession.name} />
            </ListItem>
        );
    }
    selectItem(id) {
        return (event) => {
            if (id === '') { return };
            this.props.handleClick(id);
        }
    }
    fetchData() {
        this.props.fetchData({ token: this.props.user.token });
    }
    prev() {
        if (this.state.offset !== 0) {

            this.setState(prevState => {

                const params = {
                    limit: prevState.limit,
                    offset: prevState.offset - 20,
                    token: this.props.user.token
                };
                params[this.state.filterField] = this.state.searchValue;

                this.props.fetchData(params);

                return {
                    offset: prevState.offset - 20
                };
            });
        }
    }
    next() {

        this.setState(prevState => {

            const params = {
                limit: prevState.limit,
                offset: prevState.offset + 20,
                token: this.props.user.token
            };
            params[this.state.filterField] = this.state.searchValue;

            this.props.fetchData(params);

            return {
                offset: prevState.offset + 20
            };
        });

    }
    render() {
        const { classes, professions, loading, pagination } = this.props;
        return (
            <div>
                <div className={classes.root}>
                    <FilterDialog
                        options={[
                            { caption: translations.NAME, value: 'name' }
                        ]}

                        open={this.state.open}
                        onClose={this.handleDialogClose}
                        selectedValue={this.state.filterField}
                    />
                    <div>
                        <TextField
                            className={classes.textField}
                            value={this.state.searchValue}
                            onChange={this.handleChange}
                            placeholder={translations.SEARCH}
                        />
                        <IconButton className={classes.filterButton} onClick={this.openDialog}>
                            <FilterListIcon />
                        </IconButton>
                    </div>
                    <div>
                        <List >
                            {(loading) ?
                                <ListItem>
                                    <CircularProgress style={{ color: green[500] }} thickness={7} />
                                    <ListItemText primary={translations.LOADING} />
                                </ListItem>
                                : professions.length === 0 ?
                                    [{ fullName: translations.NOT_FOUND, uniqueId: 'none' }].map(this.mapFunction)
                                    :
                                    professions.map(this.mapFunction)}
                        </List>
                    </div>
                </div>
                {!loading && pagination ?
                    <div className={classes.paginationGroup}>
                        <IconButton onClick={this.prev}>
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton>
                            {(this.state.offset / 20) + 1}
                        </IconButton>
                        <IconButton onClick={this.next}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        professions: state.professions,
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps)(ProfessionsSearchSection)
);
