import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createInternationalBlackList } from "src/actions";
import idNumberMaskInput from "src/components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "src/components/TextMaskInputComponents/passportMaskInput";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";
import { IDENTIFICATION_TYPE, translations } from "src/utils";
import {
  fetchNationalities,
  createInternationalBlackList as createIBL,
  createFileAttachment,
  updateInternationalBlackList,
} from "src/utils/axios";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AttachFile } from "@material-ui/icons";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  InputBase,
} from "@material-ui/core";
import { green, red, grey } from "@material-ui/core/colors";
import ReplayIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import {
  SuccessDialog,
  SimpleConfirmDialog,
} from "../../../components/SharedComponents/Dialogs";
import Loading from "../../../components/SharedComponents/LoadingScreen";
import { errorHandling } from "../../../components/SharedComponents/CustomHooks";

function InternationalBlackListForm(props) {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });
  const [formValues, setFormValues] = useState({
    fullName: "",
    nickName: "",
    identificationType: "",
    identificationNumber: "",
    nationality: "",
    crimeCommitted: "",
    newsLink: "",
    newsEvidence: image,
  });

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };

  const getNationalities = () => {
    setLoading(true);
    fetchNationalities({ token: props.user.token })
      .then((res) => {
        setCountries(res.data.results ? res.data.results : res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleChange = (key) => {
    return (event) => {
      setFormValues({ ...formValues, [key]: event.currentTarget.value });
    };
  };

  const handleSelectChange = (key) => {
    /* Handle the state of Select components */
    return (event) => {
      setFormValues({ ...formValues, [key]: event.target.value });
    };
  };

  const handleReset = () => {
    setFormValues({
      ...formValues,
      fullName: "",
      nickName: "",
      newsLink: "",
      identificationType: "",
      identificationNumber: "",
      nationality: "",
      crimeCommitted: "",
    });
    setPreview(null);
  };

  const getIdentificationInput = (identificationType) => {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  };

  const setEvidence = (idFile, idRecord) => {
    const newData = {
      newsEvidence: idFile,
    };
    const endpoint = updateInternationalBlackList(
      props.user.token,
      idRecord,
      newData
    );
    endpoint
      .then(() => {
        handleToggleSimpleDialog("success-dialog");
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const uploadFile = (data, uniqueId) => {
    setLoading(true);
    let formData = new FormData();
    const name = uniqueId;
    formData.append("file", data);
    formData.append("name", name);
    const endpoint = createFileAttachment(props.user.token, formData);
    endpoint
      .then((response) => {
        setEvidence(response.data.uniqueId, uniqueId);
        handleReset();
        setLoading(false);
        handleToggleSimpleDialog("success-dialog");
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const createInternationalBL = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    let internationalBlackList = {
      fullName: formValues.fullName,
      nickName: formValues.nickName,
      newsLink: formValues.newsLink,
      identificationType: formValues.identificationType,
      identificationNumber: formValues.identificationNumber,
      nationality: formValues.nationality,
      crimeCommitted: formValues.crimeCommitted,
    };
    const endpoint = createIBL;
    endpoint({ ...internationalBlackList, token: props.user.token })
      .then((response) => {
        if (response.data.warning) {
          handleToggleSimpleDialog(
            "simple-dialog",
            "El proceso fue ejecutado correctamente, pero: " +
              response.data.warning
          );
          setLoading(false);
        } else {
          if (image) {
            uploadFile(image, response.data.data.uniqueId);
          }
          handleToggleSimpleDialog("success-dialog");
          handleReset();
          setLoading(false);
        }
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  useEffect(() => {
    getNationalities();
  }, []);
  useEffect(() => {
    if (image) {
      setImage(image);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  return (
    <form
      onSubmit={createInternationalBL}
      style={{ height: "90vh", overflowY: "auto", padding: 20 }}
    >
      <Paper style={{ padding: 20 }}>
        <Loading open={loading} />
        <IconButton
          style={{
            "&:hover": {
              backgroundColor: green[200],
            },
            backgroundColor: green[500],
            color: "white",
            display: "flex",
            float: "right",
            position: "relative",
          }}
          onClick={() => props.changeSection(0)}
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography variant="h3">BlackList nacional e internacional</Typography>
        <div>
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "47%" }}>
              <div style={{ width: "100%" }}>
                <TextField
                  style={{ margin: 8, width: "100%" }}
                  label={translations.FULL_NAME}
                  value={formValues.fullName}
                  onChange={handleChange("fullName")}
                  variant="outlined"
                  required={true}
                />
              </div>
              <div style={{ width: "100%" }}>
                <TextField
                  style={{ margin: 8, width: "100%" }}
                  label={translations.NICKNAME}
                  value={formValues.nickName}
                  onChange={handleChange("nickName")}
                  variant="outlined"
                />
              </div>
              <div style={{ width: "100%" }}>
                <FormControl
                  style={{ margin: 8, width: "47%" }}
                  variant="outlined"
                >
                  <InputLabel id="identificationType">
                    {translations.IDENTIFICATION_TYPE}
                  </InputLabel>
                  <Select
                    id="identificationType"
                    value={formValues.identificationType}
                    style={{ width: "100%" }}
                    required={true}
                    label={translations.IDENTIFICATION_TYPE}
                    onChange={handleSelectChange("identificationType")}
                  >
                    {IDENTIFICATION_TYPE.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField
                  style={{ margin: 8, width: "100%" }}
                  label={translations.IDENTIFICATION}
                  value={formValues.identificationNumber}
                  onChange={handleChange("identificationNumber")}
                  required={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  InputProps={getIdentificationInput(
                    formValues.identificationType
                  )}
                />
              </div>
              <FormControl
                style={{ margin: 8, width: "47%" }}
                variant="outlined"
              >
                <InputLabel htmlFor="nationality">
                  {translations.NATIONALITY}
                </InputLabel>
                <Select
                  style={{ width: "100%" }}
                  onChange={handleSelectChange("nationality")}
                  required={true}
                  value={formValues.nationality}
                  inputProps={{
                    id: "nationality",
                    name: "nationality",
                  }}
                >
                  {countries.map((opt, index) => {
                    return (
                      <MenuItem key={index} value={opt.name}>
                        {opt.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <br />
              <TextField
                label={"Crimen cometido"}
                style={{ margin: 8, width: "100%" }}
                onChange={handleChange("crimeCommitted")}
                value={formValues.crimeCommitted}
                variant="outlined"
                multiline={true}
                required={true}
                rows={4}
                rowsMax={4}
              />
              <div style={{ width: "100%" }}>
                <TextField
                  style={{ margin: 8, width: "100%" }}
                  label={"Link de evidencia"}
                  value={formValues.newsLink}
                  onChange={handleChange("newsLink")}
                  variant="outlined"
                />
              </div>
            </div>
            <div
              style={{
                id: "preview",
                width: "47%",
                height: 550,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: grey[200],
                borderRadius: 20,
                overflow: "hidden",
              }}
            >
              <img
                alt="preview"
                src={preview}
                style={{
                  objectFit: "cover",
                  maxWidth: "100%",
                  minWidth: "-webkit-fill-available",
                }}
              />
            </div>
            <div />
            <br />
          </div>
          <div style={{ width: "100%", margin: 8 }}>
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(event) => {
                const file = event.target.files[0];
                setImage(file);
              }}
            />
            <div
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <div style={{ maxWidth: "65%", display: "inline-block" }}>
                <label htmlFor="file">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#43A047",
                      color: "#FFFFFF",
                      fontSize: "12px",
                      display: "inline-block",
                    }}
                    component="span"
                    disableElevation
                  >
                    <AttachFile
                      style={{ margin: " 0 5 -4 -5", fontSize: "16px" }}
                    />
                    Elegir
                  </Button>
                </label>

                <InputBase
                  value={image ? image.name : "Seleccione un archivo"}
                  style={{
                    color: "#616161",
                    paddingLeft: 5,
                    display: "inline-block",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              variant="contained"
              onClick={handleReset}
              style={{ backgroundColor: red[500], color: "white" }}
            >
              <ReplayIcon />
              &nbsp;
              {translations.RESET}
            </Button>
            <Button
              variant="contained"
              type="submit"
              style={{
                backgroundColor: green[500],
                color: "white",
                marginLeft: 10,
              }}
            >
              <SaveIcon />
              &nbsp;
              {translations.SAVE}
            </Button>
          </div>
        </div>
      </Paper>
      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}
      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
    </form>
  );
}

function mapStateToProps(state) {
  return {
    internationalBlackList: state.internationalBlackList,
    user: state.user,
  };
}

export default connect(mapStateToProps, { createInternationalBlackList })(
  InternationalBlackListForm
);
