import * as React from 'react';
import { connect } from "react-redux";

import { createStyles, withStyles } from "@material-ui/core/styles";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import green from "@material-ui/core/colors/green";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import TicketManagementView from "../../TicketManagement/View"

const styles = createStyles({
  button: {
    '&:hover': {
        backgroundColor: green[200]
    },
    backgroundColor: green[500],
    color: 'white'
},
});

class QuotationDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        open: false,
        fTicket: "",
        };  
    }


    render() {
        const {  handleClose, open, classes, uniqueId } = this.props;
        return (
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth = {'xl'}>
                <DialogActions>
                  <IconButton className={classes.button} onClick={handleClose}>
                    <ArrowBackIcon />
                  </IconButton>
                </DialogActions>
                <DialogContent>
                    <div>
                    <TicketManagementView
                        id={uniqueId}
                    />
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
      user: state.user,
    };
  }
  export default withStyles(styles)(connect(mapStateToProps, {})(QuotationDialog)
  );