import React, { useState } from 'react';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import { } from '@material-ui/icons';
import { ReconciliationTable } from './../Tables/index';
import { ConfirmDialog, DialogContainer, DialogHeader, } 
    from './../../../components/SharedComponents/Dialogs';

function ConfirmDialogComponent(props) {

    let dialogConfirmContent = '';
    let dialogConfirmTitle = 'Confirmación';
    switch(props.actionType) {
        case 'reconcile':
            // dialogConfirmTitle='Confirmación';
            dialogConfirmContent='¿Seguro que desea unir las transacciones seleccionadas?';
            break;
        case 'restore':
            // dialogConfirmTitle='Confirmación';
            dialogConfirmContent='¿Seguro que desea restaurar las transacciones seleccionadas?';
            break;
        case 'load-file':
            // dialogConfirmTitle='Confirmación';
            dialogConfirmContent='¿Seguro que desea subir las transacciones seleccionadas?';
            break;
        default:
            dialogConfirmContent='¿...?';
    }
    return(
        <div>
            <ConfirmDialog
                open={true}
                handleToggle={props.handleToggle}
                dialogTitle={dialogConfirmTitle}
                dialogContent={dialogConfirmContent}
                dialogConfirmText="Continuar"
                dialogCancelText="Cancelar"
                handleConfirm={props.handleConfirm}
            />;
        </div>
    )
}

function ReconciliationDialog(props) {

    const [tableData, setTableData] = useState(props.data);

    const execReconcileRestore = (data) => {
        //do stuff to retreive the response with data
        // send the transactions I want to reconcile/restore,
        // and send the parent transaction.
        
        if(props.actionType === "reconcile") {
            alert('Conciliado!');
        }else {
            alert('Restored!');
        }
        // close everything.
        // condición para evitar conciliar.
    }

    const [selectedConfirmDialog, setConfirmDialog] = useState('');
    let dialogConfirm = undefined;
    const handleConfirm = (data) => {
        setConfirmDialog('');
        execReconcileRestore(data);
    }
    const handleConfirmDialog = (action, data) => {
        if(['reconcile', 'restore'].includes(action)) {
            dialogConfirm = 
                <ConfirmDialogComponent
                    actionType={action}
                    handleToggle={handleConfirmDialog}
                    handleConfirm={handleConfirm.bind(this, data)}
                />;
        }else {
            dialogConfirm = undefined;
        }
        setConfirmDialog(dialogConfirm);
    }

    const [selected, setSelected] = useState([]);
    const handleSelectAllRows = (event) => {
        if (event.target.checked) {
          const newSelecteds = tableData.map(item => item.reference);
          setSelected(newSelecteds);
          calcTotalTransferAmount(newSelecteds);
          return;
        }
        setSelected([]);
        setTotalTransferAmount('');
      };
    
    const handleSelectRow = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        calcTotalTransferAmount(newSelected);
    };
    const isSelected = (reference) => selected.indexOf(reference) !== -1;

    const [totalTransferAmount, setTotalTransferAmount]=useState('');
    const calcTotalTransferAmount = (rows) => {
        let total = 0;
        tableData.forEach(item => {
            if(rows.includes(item.reference)) {
                return total += parseFloat(item.amount);
            }
            return false;
        });
        setTotalTransferAmount(total.toString());
    }

    return(
        <div>
            <DialogContainer 
                open={props.open}
                content={
                    <div style={{ backgroundColor: '#FAFAFA' }}>
                        <DialogHeader 
                            dialogTitle={props.actionType === 'reconcile' ? "Conciliar" : "Restaurar"}
                            handleClick={props.handleDialogToggle}
                        />
                        <Grid container justify="space-between" style={{padding:'10px 24px'}}>
                            <Grid container item xs={4}>
                                <Card style={{width:'100%'}}>
                                    <CardContent style={{marginTop:'5px'}}>
                                        <Typography
                                            variant="h6"
                                            style={{color:'#424242'}}
                                        >
                                            ${!props.account_book_balance ? '0.00' : props.account_book_balance}
                                            <br/>
                                            <Typography 
                                                variant="overline"
                                                style={{color:'#616161'}}
                                            >Balance</Typography>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid container item xs={4}>
                                <Card style={{width:'100%'}}>
                                    <CardContent style={{marginTop:'5px'}}>
                                        <Typography
                                            variant="h6"
                                            style={{color:'#424242'}}
                                        >
                                            ${!totalTransferAmount ? '0.00' : totalTransferAmount}
                                            <br/>
                                            <Typography 
                                                variant="overline"
                                                style={{color:'#616161'}}
                                            >Total monto transferido</Typography>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid container item xs={12} style={{marginTop:15}}>
                                <ReconciliationTable 
                                    data={props.data}
                                    tableData={tableData}
                                    setTableData={setTableData}
                                    selectedRows={selected}
                                    isSelected={isSelected}
                                    handleSelectAllRows={handleSelectAllRows}
                                    handleSelectRow={handleSelectRow}
                                    actionType={props.actionType}
                                    handleConfirmDialog={handleConfirmDialog}
                                />
                            </Grid>
                        </Grid>
                    </div>
                }
            />
            { selectedConfirmDialog }
        </div>
    )

}

export { ReconciliationDialog, ConfirmDialogComponent }