import * as React from "react";
import { connect } from "react-redux";

import { List, ListItem, ListItemText } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import yellow from "@material-ui/core/colors/yellow";
import orange from "@material-ui/core/colors/orange";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FilterListIcon from "@material-ui/icons/FilterList";
import TuneIcon from "@material-ui/icons/Tune";
import FiberManualRecordIcon from "@material-ui/icons/Brightness1";

import FilterDialog from "../../../components/FilterDialog";
import { translations } from "../../../utils";
import SearchFilter from "../SearchFilter";

const styles = createStyles({
  dateLabel: {
    bottom: "40%",
    color: grey[400],
    fontSize: "small",
    position: "absolute",
    right: 0,
    textDecoration: "underline",
  },
  filterButton: {
    width: "15%",
  },
  paginationGroup: {
    border: "1px solid rgba(25,25,25,0.2)",
    bottom: 0,
    display: "flex",
    height: 50,
    justifyContent: "center",
    justifyItems: "center",
    position: "absolute",
    textAlign: "center",
    width: 278,
  },
  root: {
    borderRightColor: "rgba(25,25,25,0.2)",
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    bottom: 51,
    boxSizing: "border-box",
    overflowY: "auto",
    padding: 10,
    paddingLeft: 15,
    position: "absolute",
    top: 65,
    width: 280,
  },
  textField: {
    width: "70%",
  },
});

class SearchSection extends React.Component {
  state = {
    advanceFilter: false,
    filterField: "search",
    limit: 20,
    offset: 0,
    open: false,
    searchValue: "",
    searching: false,
  };
  constructor(props) {
    super(props);

    this.openDialog = this.openDialog.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.openFilterDialog = this.openFilterDialog.bind(this);
    this.closeFilterDialog = this.closeFilterDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.mapFunction = this.mapFunction.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.search = this.search.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
  }
  openDialog() {
    this.setState({ open: true });
  }
  handleDialogClose(value) {
    this.setState({
      filterField: value,
      open: false,
    });
  }
  openFilterDialog() {
    this.setState({ advanceFilter: true });
  }
  closeFilterDialog() {
    this.setState({ advanceFilter: false });
  }
  search(params) {
    this.props.advanceSearch(params);
  }
  handleChange(event) {
    if (!this.state.searching || event.currentTarget.value === "") {
      const params = {
        limit: this.state.limit,
        offset: 0,
        token: this.props.user.token,
      };
      params[this.state.filterField] = event.currentTarget.value;

      this.setState(
        {
          offset: 0,
          searchValue: event.currentTarget.value,
          searching: true,
        },
        () => {
          this.props.fetchData(params);
          setTimeout(() => this.setState({ searching: false }), 500);
        }
      );
    } else {
      this.setState({ searchValue: event.currentTarget.value });
    }
  }

  mapFunction(MA) {
    let statusColor = blue[500];
    let statusText = translations.PENDING_SIGNATURE;

    switch (MA.status) {
      case "completed":
        statusColor = yellow[500];
        statusText = translations.LEGALIZED;
        break;
      case "archived":
        statusColor = green[500];
        statusText = translations.ARCHIVED;
        break;
      default:
        statusColor = orange[500];
        statusText = translations.PENDING_SIGNATURE;
        break;
    }

    if (MA.uniqueId === "none") {
      return (
        <ListItem dense={true} divider={true} key={MA.uniqueId} button={true}>
          <ListItemText primary={MA.title} />
        </ListItem>
      );
    }

    return (
      <ListItem
        title={statusText}
        dense={true}
        divider={true}
        key={MA.uniqueId}
        button={true}
        onClick={this.selectItem(MA.uniqueId || "")}
      >
        <FiberManualRecordIcon style={{ color: statusColor }} />
        <ListItemText primary={MA.title} />
        <div style={styles.dateLabel}>{MA.contractDate}</div>
      </ListItem>
    );
  }
  selectItem(id) {
    return (event) => {
      if (id !== "none") {
        this.props.handleClick(id);
      }
    };
  }
  fetchData() {
    this.props.fetchData({ token: this.props.user.token });
  }
  prev() {
    if (this.state.offset !== 0) {
      this.setState((prevState) => {
        const params = {
          limit: prevState.limit,
          offset: prevState.offset - 20,
          token: this.props.user.token,
        };
        params[this.state.filterField] = this.state.searchValue;

        this.props.fetchData(params);

        return {
          offset: prevState.offset - 20,
        };
      });
    }
  }
  next() {
    this.setState((prevState) => {
      const params = {
        limit: prevState.limit,
        offset: prevState.offset + 20,
        token: this.props.user.token,
      };
      params[this.state.filterField] = this.state.searchValue;

      this.props.fetchData(params);

      return {
        offset: prevState.offset + 20,
      };
    });
  }
  render() {
    const {
      classes,
      membershipAcquaintanceship,
      loading,
      pagination,
    } = this.props;
    return (
      <div>
        <div className={classes.root}>
          <FilterDialog
            options={[
              {
                caption: `${translations.NAME} / ${translations.IDENTIFICATION_NUMBER}`,
                value: "search",
              },
            ]}
            open={this.state.open}
            onClose={this.handleDialogClose}
            selectedValue={this.state.filterField}
          />

          <SearchFilter
            search={this.search}
            token={this.props.user.token}
            open={this.state.advanceFilter}
            handleClose={this.closeFilterDialog}
          />
          <div>
            <TextField
              className={classes.textField}
              value={this.state.searchValue}
              onChange={this.handleChange}
              placeholder={translations.SEARCH}
            />
            <IconButton
              className={classes.filterButton}
              onClick={this.openDialog}
            >
              <FilterListIcon />
            </IconButton>
            <IconButton
              className={classes.filterButton}
              onClick={this.openFilterDialog}
            >
              <TuneIcon />
            </IconButton>
          </div>
          <div>
            <List>
              {loading ? (
                <ListItem>
                  <CircularProgress
                    style={{ color: green[500] }}
                    thickness={7}
                  />
                  <ListItemText primary={translations.LOADING} />
                </ListItem>
              ) : membershipAcquaintanceship.length === 0 ? (
                [{ title: translations.NOT_FOUND, uniqueId: "none" }].map(
                  this.mapFunction
                )
              ) : (
                membershipAcquaintanceship.map(this.mapFunction)
              )}
            </List>
          </div>
        </div>
        {!loading && pagination ? (
          <div className={classes.paginationGroup}>
            <IconButton onClick={this.prev}>
              <ArrowBackIcon />
            </IconButton>
            <IconButton>{this.state.offset / 20 + 1}</IconButton>
            <IconButton onClick={this.next}>
              <ArrowForwardIcon />
            </IconButton>
          </div>
        ) : null}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    membershipAcquaintanceship: state.membershipAcquaintanceship,
    user: state.user,
  };
}
export default withStyles(styles)(connect(mapStateToProps)(SearchSection));
