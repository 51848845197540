import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";

import { fetchLoanContracts, fetchLoanContract } from "../../../actions";
import {
  fetchLoanContracts as fetchLoans,
  fetchLoanContract as fetchLoan,
} from "../../../utils/axios";
import Form from "../LoanContractForm";
import SearchSection from "../LoanContractSearchSection";

const styles: any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

interface IFindContractsProps {
  token: string;
  contractId: string;
  functionProps: any;
}

const FindContracts: React.FC<IFindContractsProps> = ({
  token,
  contractId,
  functionProps,
}) => {
  const { handleDeleteItem } = functionProps;
  const [curContract, setCurContract] = React.useState({
    uniqueId: "",
    title: "",
  });

  React.useEffect(() => {
    if (contractId) {
      fetchLoan(token, contractId)
        .then((res) => {
          setCurContract(res.data.results ? res.data.results : res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return curContract && contractId ? (
    <TableRow key={curContract.uniqueId}>
      <TableCell>{curContract.title}</TableCell>
      <TableCell>
        <IconButton
          onClick={handleDeleteItem(
            curContract.uniqueId ? curContract.uniqueId : ""
          )}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ) : null;
};

interface ILoanContractSelectInputProps {
  classes: {
    button: string;
    root: string;
  };

  user: IUser;
  loanContracts: ILoanContract[];
  selectedLoanContractsId: string[];
  add(id: ILoanContract): void;
  delete(id: string): void;
  fetchLoanContracts(loanContracts: ILoanContract[]): IAction<ILoanContract[]>;
  fetchLoanContract(loanContract: ILoanContract): IAction<ILoanContract[]>;
}
interface ILoanContractSelectInputState {
  loanContracts: ILoanContract[];
  formDialog: boolean;
  loading: boolean;
  open: boolean;
}
class LoanContractSelectInput extends React.Component<
  ILoanContractSelectInputProps,
  ILoanContractSelectInputState
> {
  public state: ILoanContractSelectInputState = {
    formDialog: false,
    loading: false,
    loanContracts: [],
    open: false,
  };
  constructor(props: ILoanContractSelectInputProps) {
    super(props);

    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
  }
  public componentDidMount() {
    this.setState({ loading: true });

    fetchLoans({ token: this.props.user.token, limit: 20, offset: 0 })
      .then((res) => {
        this.props.fetchLoanContracts(
          res.data.results ? res.data.results : res.data
        );

        this.setState({
          loading: false,
          loanContracts: (res.data.results
            ? res.data.results
            : res.data) as ILoanContract[],
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public openDialogHandler() {
    this.setState({ open: true });
  }
  public closeDialogHandler() {
    this.setState({ open: false });
  }
  public openFormDialog() {
    this.setState({ formDialog: true });
  }
  public closeFormDialog() {
    this.setState({ formDialog: false });
  }
  public emptyFunction() {
    return null;
  }

  public handleItemClick(id: string) {
    this.setState({ loading: true });

    fetchLoan(this.props.user.token, id)
      .then((res) => {
        this.props.fetchLoanContract(
          res.data.results ? res.data.results : res.data
        );

        this.setState({
          loading: false,
          open: false,
        });
        const selectedContract = res.data.results ? res.data.results : res.data;

        if (selectedContract) {
          this.props.add(selectedContract);
        }
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.props.delete(id);
    };
  }

  public fetchData(params: IFetchLoanContractParams) {
    params.limit = 20;
    params.offset = 0;

    this.setState({ loading: true });
    fetchLoans(params)
      .then((res) => {
        this.props.fetchLoanContracts(
          (res.data.results ? res.data.results : res.data) as ILoanContract[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public render() {
    const { classes } = this.props;
    const { formDialog, open, loading } = this.state;

    return (
      <div className={classes.root}>
        <Dialog open={open}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.openFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: 600, width: 280 }}>
            <SearchSection
              advanceSearch={this.emptyFunction}
              loading={loading}
              handleClick={this.handleItemClick}
              fetchData={this.fetchData}
              pagination={false}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={formDialog}
          fullScreen={true}
          onClose={this.closeFormDialog}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <Form changeSection={this.emptyFunction} />
          </DialogContent>
        </Dialog>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.TITLE}</TableCell>
                <TableCell>
                  <IconButton onClick={this.openDialogHandler}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.selectedLoanContractsId.map((loanContractsId) => (
                <FindContracts
                  key={loanContractsId}
                  token={this.props.user.token}
                  contractId={loanContractsId}
                  functionProps={{
                    handleDeleteItem: this.handleDeleteItem.bind(this),
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    loanContracts: state.loanContracts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchLoanContracts, fetchLoanContract })(
    LoanContractSelectInput
  )
);
