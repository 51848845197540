import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import {
  deleteNationality,
  fetchNationality,
  updateNationality,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  rohiDBList,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import {
  deleteNationality as deleteNa,
  fetchNationality as fetchNa,
  updateNationality as updateNa,
  submitRohiNationality,
} from "../../../utils/axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface INationalityViewProps {
  nationalities: INationality[];
  nationalityId: string;
  classes: { [key: string]: string };

  user: IUser;
  changeSection(sectionNumber: number): void;
  deleteNationality(uniqueId: string): IAction<INationality[]>;
  fetchNationality(nationality: INationality): IAction<INationality[]>;
  updateNationality(nationality: INationality): IAction<INationality[]>;
}

interface INationalityViewState {
  [key: string]: string | string[] | number | boolean | Blob;

  name: string;
  nameError: string;

  denomynForMale: string;
  denomynForMaleError: string;

  denomynForFemale: string;
  denomynForFemaleError: string;

  id: string;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
  uniqueId: string;
  rohiId: string;
  rohiIeId: string;
  rohiMfsId: string;
  rohiDB: string;
  rohiDialog: boolean;
}
class NationalityView extends React.Component<
  INationalityViewProps,
  INationalityViewState
> {
  public state: INationalityViewState = {
    denomynForFemale: "",
    denomynForFemaleError: "",
    denomynForMale: "",
    denomynForMaleError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    id: "",
    loading: false,
    name: "",
    nameError: "",
    uniqueId: "",
    rohiId: "",
    rohiIeId: "",
    rohiMfsId: "",
    rohiDialog: false,
    rohiDB: "barcelona",
  };
  constructor(props: INationalityViewProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleAddBank = this.handleAddBank.bind(this);
    this.handleDeleteBank = this.handleDeleteBank.bind(this);

    this.handleAddParticipant = this.handleAddParticipant.bind(this);
    this.handleDeleteParticipant = this.handleDeleteParticipant.bind(this);
    this.handleCheckInputChange = this.handleCheckInputChange.bind(this);
    this.handleRohiSubmit = this.handleRohiSubmit.bind(this);
    this.handleRohiDialog = this.handleRohiDialog.bind(this);
  }

  public handleRohiSubmit(db: string) {
    this.setState({ loading: true });

    const endpoint = submitRohiNationality(
      this.props.user.token,
      Number(this.state.id),
      db
    );
    endpoint
      .then((res) => {
        successHandler(res, this, translations.NATIONALITY);
        this.handleRohiDialog();
      })
      .catch((errors) => {
        catchError(errors, this);
      })
      .finally(() => this.setState({ loading: false }));
  }
  public handleCheckInputChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public handleAddBank(id: string) {
    this.setState({ bank: [id] });
  }
  public handleDeleteBank(id: string) {
    this.setState({ bank: [] });
  }
  public handleAddParticipant(id: string) {
    this.setState({ owner: [id] });
  }
  public handleDeleteParticipant(id: string) {
    this.setState({ owner: [] });
  }
  public componentDidMount() {
    this.setState({ loading: true });
    fetchNa(this.props.user.token, this.props.nationalityId)
      .then((res) => {
        const curNationality = (res.data.results
          ? res.data.results
          : res.data) as INationality;

        this.setState({
          denomynForFemale: curNationality.denomynForFemale || "",
          denomynForMale: curNationality.denomynForMale || "",
          id: curNationality.id || "",
          rohiId: curNationality.rohiId || "",
          rohiIeId: curNationality.rohiIeId || "",
          rohiMfsId: curNationality.rohiMfsId || "",
          loading: false,
          name: curNationality.name || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    const nationality: INationality = {
      denomynForFemale: titleCaseHandler(this.state.denomynForFemale),
      denomynForMale: titleCaseHandler(this.state.denomynForMale),
      name: titleCaseHandler(this.state.name),
    };

    this.setState({ loading: true });

    updateNa(this.props.user.token, this.state.id, nationality)
      .then((res) => {
        this.props.fetchNationality(
          (res.data.results ? res.data.results : res.data) as INationality
        );
        this.props.updateNationality(
          (res.data.results ? res.data.results : res.data) as INationality
        );
        successHandler(res, this, translations.NATIONALITY);
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }

  public handleRohiDialog() {
    this.setState({ rohiDialog: !this.state.rohiDialog });
  }

  public handleDelete() {
    if (confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });

      deleteNa(this.props.user.token, this.props.nationalityId)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteNationality(this.props.nationalityId);
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    const { rohiDialog } = this.state;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Dialog open={rohiDialog} onClose={this.handleRohiDialog}>
          <DialogContent>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="rohiDB" shrink={true}>
                {"Favor elegir una Base de Datos"}
              </InputLabel>
              <Select
                value={this.state.rohiDB}
                onChange={this.handleSelectChange("rohiDB")}
                error={this.state.rohiDBError !== ""}
                inputProps={{
                  id: "rohiDB",
                  name: "rohiDB",
                }}
              >
                {rohiDBList.map((item, indx) => {
                  return (
                    <MenuItem key={indx} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRohiDialog} className={classes.button}>
              {translations.CANCEL}
            </Button>
            <Button
              onClick={() => this.handleRohiSubmit(this.state.rohiDB)}
              className={classes.button}
            >
              {translations.SAVE}
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.NATIONALITIES}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={this.state.name}
                disabled={true}
              />
              <TextField
                label={translations.DEMOMYN_MALE}
                className={classes.textField}
                value={titleCaseHandler(this.state.denomynForMale)}
                onChange={this.handleChange("denomynForMale")}
                fullWidth={true}
                required={true}
                error={this.state.denomynForMaleError !== ""}
                helperText={this.state.denomynForMaleError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />

              <TextField
                label={translations.DEMOMYN_FEMALE}
                className={classes.textField}
                value={titleCaseHandler(this.state.denomynForFemale)}
                onChange={this.handleChange("denomynForFemale")}
                fullWidth={true}
                required={true}
                error={this.state.denomynForFemaleError !== ""}
                helperText={this.state.denomynForFemaleError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Rohi ID Barcelona"}
                    className={classes.textField}
                    value={this.state.rohiId}
                    disabled={true}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Rohi ID IE"}
                    className={classes.textField}
                    value={this.state.rohiIeId}
                    disabled={true}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Rohi ID MFS"}
                    className={classes.textField}
                    value={this.state.rohiMfsId}
                    disabled={true}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
              </Grid>
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  onClick={this.handleRohiDialog}
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  <SaveIcon />
                  &nbsp; Enviar a Rohi
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    nationalities: state.nationalities,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteNationality,
    fetchNationality,
    updateNationality,
  })(NationalityView)
);
