import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Delete, Save, Description } from "@material-ui/icons";

import {
  deleteOutlayCheck,
  fetchOutlayCheck,
  updateOutlayCheck,
} from "../../../actions";
import Loading from "../../../components/Loading";
import moneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import FileUploadSelectInput from "../../FileUpload/FileUploadSelectInput";
import { translations, checkStatus } from "../../../utils";
import {
  deleteOutlayCheck as deleteCH,
  fetchOutlayCheck as fetchCH,
  updateOutlayCheck as updateCH,
  checkTemplate,
} from "../../../utils/axios";
import BankAccountSelectInput from "../../BankAccount/BankAccountSelectInput";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
import { Divider, Grid, Modal } from "@material-ui/core";
import {
  TextFieldComponent,
  DateField,
} from "../../../components/SharedComponents/Fields";
import {
  SuccessDialog,
  ErrorDialog,
  SimpleConfirmDialog,
} from "../../../components/SharedComponents/Dialogs";
import { errorHandling } from "../../../components/SharedComponents/CustomHooks";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 52,
    top: 25,
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  fileText: {
    fontSize: 14,
    position: "absolute",
    right: 32,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "90%",
  },
});

function OutlayCheckView(props) {
  const { token } = props.user;
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });
  const [client, setClient] = useState([]);
  const [account, setAccount] = useState([]);

  const [amount, setAmount] = useState(0);
  const [concept, setConcept] = useState("");
  const [date, setDate] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [status, setStatus] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [participantName, setParticipantName] = useState("");
  const [amountAsStr, setAmountAsStr] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState("");

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };

  const generateTemplate = (uniqueId, templateType) => {
    setLoading(true);
    const type = { type: templateType };
    const endpoint = checkTemplate(props.user.token, uniqueId, type);
    endpoint
      .then((response) => {
        const file = new Blob([response.data], { type: "text/html" });
        const fileURL = URL.createObjectURL(file);
        const NewWindow = window.open();
        NewWindow.location.href = fileURL;
        handleToggleSimpleDialog("success-dialog");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        errorHandling(err, handleToggleSimpleDialog);
      });
  };

  const handleAddBankAccount = (id) => {
    setAccount([id]);
  };
  const handleDeleteBankAccount = (id) => {
    setAccount([]);
  };
  const handleAddClient = (id) => {
    setClient([id]);
  };
  const handleDeleteClient = (id) => {
    setClient([]);
  };

  const getCheck = () => {
    fetchCH(token, props.outlayCheckId)
      .then((res) => {
        const curCheck = res.data;

        setUniqueId(curCheck.uniqueId);
        setStatus(curCheck.status);
        setCurrentStatus(curCheck.status);
        setDate(curCheck.date);
        setConcept(curCheck.concept);
        setAmount(curCheck.amount);
        setParticipantName(curCheck.participantName);
        setAmountAsStr(curCheck.amountAsStr);
        setClient([curCheck.client]);
        setAccount([curCheck.account]);
        setAttachmentUrl(
          `outlay/check/${curCheck.uniqueId}/upload_attachment/`
        );
        setReferenceNumber(curCheck.referenceNumber);
      })
      .catch((err) => {
        setLoading(false);
        errorHandling(err, handleToggleSimpleDialog);
      });
  };

  const handleCurrencyInputChange = (key) => {
    return (event, maskedValue, floatValue) => {
      setAmount(floatValue || "");
    };
  };

  const handleChange = (key) => {
    return (event) => {
      if (key === "referenceNumber") {
        setReferenceNumber(event.target.value);
      } else if (key === "date") {
        setDate(event.target.value);
      } else if (key === "concept") {
        setConcept(event.target.value);
      }
    };
  };
  const handleSelectChange = (key) => {
    return (event) => {
      setStatus(event.target.value);
    };
  };
  const handleSubmit = (key) => {
    if (key === "template") {
      const outlayCheckTem = {
        referenceNumber: referenceNumber || "",
      };

      const endpointTem = updateCH(token, uniqueId, outlayCheckTem);

      endpointTem
        .then((res) => {
          props.updateOutlayCheck(
            res.data.results ? res.data.results : res.data
          );

          generateTemplate(uniqueId, "template");

          setOpenModal(false);
        })
        .catch((err) => {
          setLoading(false);
          errorHandling(err, handleToggleSimpleDialog);
        });
    } else {
      const outlayCheck = {
        account: account[0] || "",
        amount: amount,
        client: client[0] || "",
        concept: concept,
        date: date,
        status: status,
      };

      setLoading(true);

      const endpoint = updateCH(token, uniqueId, outlayCheck);

      endpoint
        .then((res) => {
          getCheck();
          props.updateOutlayCheck(
            res.data.results ? res.data.results : res.data
          );
          setLoading(false);
          handleToggleSimpleDialog("success-dialog");
        })
        .catch((err) => {
          setLoading(false);
          errorHandling(err, handleToggleSimpleDialog);
        });
    }
  };
  const handleDelete = () => {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      setLoading(true);

      deleteCH(token, props.outlayCheckId)
        .then((res) => {
          props.changeSection(0);
          props.deleteOutlayCheck(props.outlayCheckId);

          handleToggleSimpleDialog("success-dialog");
        })
        .catch((err) => {
          setLoading(false);
          errorHandling(err, handleToggleSimpleDialog);
        });
    }
  };

  useEffect(() => {
    getCheck();
  }, []);

  return (
    <div className={props.classes.root}>
      <Paper className={props.classes.paper}>
        <Typography variant="h3" style={{ display: "inline" }}>
          {translations.CHECK}
        </Typography>
        {loading ? (
          <Loading message={translations.LOADING} />
        ) : (
          <div>
            <FileUploadSelectInput
              contractId={uniqueId}
              url={attachmentUrl}
              description="outlay_check"
            />

            <br />
            <br />

            <div
              style={{
                display: "flex",
                width: "40%",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                className={
                  currentStatus !== "completed"
                    ? "action-method-btn disabled"
                    : "action-method-btn save"
                }
                style={{ fontSize: "10px" }}
                disableElevation={true}
                disabled={currentStatus !== "completed"}
                size="small"
                onClick={() => setOpenModal(true)}
              >
                <Description
                  style={{
                    fontSize: 15,
                    color: "#fff",
                    margin: "0 .5rem .1rem 0",
                  }}
                />
                {"Generar Plantilla"}
              </Button>

              <Button
                variant="contained"
                className={
                  currentStatus !== "in_transit"
                    ? "action-method-btn disabled"
                    : "action-method-btn save"
                }
                style={{ fontSize: "10px" }}
                disableElevation={true}
                disabled={currentStatus !== "in_transit"}
                size="small"
                onClick={() => generateTemplate(uniqueId, "request")}
              >
                <Description
                  style={{
                    fontSize: 15,
                    color: "#fff",
                    margin: "0 .5rem .1rem 0",
                  }}
                />
                {"Generar Solicitud"}
              </Button>
            </div>

            <br />
            <br />

            <Typography variant="h6" className={props.classes.checkboxLabel}>
              {translations.BANK_ACCOUNT}
            </Typography>
            <BankAccountSelectInput
              selectedBankAccountsId={account}
              bankAccountFilter="checking"
              ownerRegistryNumber="430298557"
              addBankAccount={handleAddBankAccount}
              deleteBankAccount={handleDeleteBankAccount}
            />
            <br />
            <br />
            <Typography variant="h6" className={props.classes.checkboxLabel}>
              {translations.CLIENT}
            </Typography>
            <div style={{ width: "100%" }}>
              <ParticipantSelectInput
                selectedParticipantId={client}
                addParticipant={handleAddClient}
                deleteParticipant={handleDeleteClient}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "50%" }}>
                <FormControl className={props.classes.formControl}>
                  <InputLabel htmlFor="status" shrink={true}>
                    {translations.STATUS}
                  </InputLabel>
                  <Select
                    value={status}
                    onChange={handleSelectChange("status")}
                    required={true}
                    disabled={currentStatus === "cancelled"}
                    native={true}
                    inputProps={{
                      id: "status",
                      name: "status",
                    }}
                  >
                    {currentStatus === "in_transit" ? (
                      <>
                        <option value="in_transit">
                          {translations.OUTLAY_CHECK_STATUS_IN_TRANSIT}
                        </option>
                        <option value="completed">
                          {translations.OUTLAY_CHECK_STATUS_COMPLETED}
                        </option>
                        <option value="cancelled">
                          {translations.OUTLAY_CHECK_STATUS_CANCELLED}
                        </option>
                      </>
                    ) : null}
                    {currentStatus === "completed" ? (
                      <>
                        <option value="completed">
                          {translations.OUTLAY_CHECK_STATUS_COMPLETED}
                        </option>
                        <option value="pending_signature">
                          {translations.OUTLAY_CHECK_STATUS_PENDING_SIGNATURE}
                        </option>
                        <option value="cancelled">
                          {translations.OUTLAY_CHECK_STATUS_CANCELLED}
                        </option>
                      </>
                    ) : null}
                    {currentStatus === "pending_signature" ? (
                      <>
                        <option value="pending_signature">
                          {translations.OUTLAY_CHECK_STATUS_PENDING_SIGNATURE}
                        </option>
                        <option value="archived">
                          {translations.OUTLAY_CHECK_STATUS_ARCHIVED}
                        </option>
                        <option value="cancelled">
                          {translations.OUTLAY_CHECK_STATUS_CANCELLED}
                        </option>
                      </>
                    ) : null}
                    {currentStatus === "cancelled" ? (
                      <>
                        <option value="cancelled">
                          {translations.OUTLAY_CHECK_STATUS_CANCELLED}
                        </option>
                      </>
                    ) : null}
                    {currentStatus === "archived" ? (
                      <>
                        <option value="archived">
                          {translations.OUTLAY_CHECK_STATUS_ARCHIVED}
                        </option>
                      </>
                    ) : null}
                  </Select>
                </FormControl>
                <br />
                <br />

                <TextField
                  label={translations.DATE}
                  className={props.classes.textField}
                  value={date}
                  type="date"
                  disabled={currentStatus === "cancelled"}
                  onChange={handleChange("date")}
                  required={true}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.label,
                  }}
                />
                <TextField
                  label={translations.CHECK_NUMBER}
                  disabled={true}
                  className={props.classes.textField}
                  value={referenceNumber}
                  onChange={handleChange("referenceNumber")}
                  fullWidth={true}
                  InputLabelProps={{ style: styles.label }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <TextField
                  label={translations.AMOUNT_2}
                  className={props.classes.textField}
                  value={amount}
                  disabled={currentStatus === "cancelled"}
                  onChange={handleCurrencyInputChange("amount")}
                  fullWidth={true}
                  required={true}
                  InputProps={{
                    inputComponent: moneyInput,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />

                <TextField
                  label={translations.CONCEPT}
                  className={props.classes.textField}
                  value={concept}
                  disabled={currentStatus === "cancelled"}
                  onChange={handleChange("concept")}
                  fullWidth={true}
                  variant="outlined"
                  multiline={true}
                  rows={5}
                  rowsMax={5}
                  required={true}
                  InputLabelProps={{ style: styles.label }}
                />
              </div>
            </div>

            <div className={props.classes.controls}>
              <Button
                variant="contained"
                onClick={handleDelete}
                className={props.classes.deleteButton}
              >
                <Delete />
                &nbsp;
                {translations.DELETE}
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                className={
                  currentStatus === "cancelled"
                    ? "action-method-btn disabled"
                    : props.classes.greenButton
                }
                onClick={() => handleSubmit()}
                variant="contained"
                disabled={currentStatus === "cancelled"}
              >
                <Save />
                &nbsp;
                {translations.SAVE}
              </Button>
            </div>
            <br />
          </div>
        )}
      </Paper>

      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}

      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}

      {simpleDialog.name === "error-dialog" ? (
        <ErrorDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="calculator-container">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-title">Datos de la plantilla</h3>
              <Divider />
            </Grid>
            <TextFieldComponent
              xs={5}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Nombre del Participante"
              fieldID="participantName"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={participantName}
              disabled={true}
            />
            <DateField
              xs={5}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Fecha"
              dateFieldID="filter-last-outlay-date"
              fullWidth={true}
              dateFieldClass="date-field"
              inputVariant="outlined"
              dateFieldLabel="Fecha/Hora"
              format="dd/MM/yyyy"
              value={date}
              dateType="participantPaymentDay"
              disabled={true}
            />
            <TextFieldComponent
              xs={5}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Estado"
              fieldID="participantName"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={
                status ? checkStatus.find((x) => x.value === status).label : ""
              }
              disabled={true}
            />
            <TextFieldComponent
              xs={5}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Monto"
              fieldID="participantName"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={amount}
              disabled={true}
            />
            <Grid item xs={12}>
              <TextFieldComponent
                xs={5}
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Numero de cheque"
                fieldID="participantName"
                fieldClass="fields"
                fieldVariant="outlined"
                margin="dense"
                fullWidth
                value={referenceNumber}
                onChange={handleChange("referenceNumber")}
              />
            </Grid>
            <TextFieldComponent
              xs={5}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Monto en letras"
              fieldID="participantName"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={amountAsStr}
              disabled={true}
              multiline={true}
              rows={5}
            />
            <TextFieldComponent
              xs={5}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Concepto"
              fieldID="participantName"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={concept}
              disabled={true}
              multiline={true}
              rows={5}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-title">Acciones</h3>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                className={"action-method-btn save"}
                style={{ marginRight: 5 }}
                onClick={() => handleSubmit("template")}
              >
                Generar Plantilla
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    outlayChecks: state.outlayChecks,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteOutlayCheck,
    fetchOutlayCheck,
    updateOutlayCheck,
  })(OutlayCheckView)
);
