
import * as React from "react";
import { connect } from "react-redux";
import FileUploadSelectInput from "src/containers/FileUpload/FileUploadSelectInput";

import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import DocumentIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import DepositBankAccountSelectInput from "../../DepositBankAccount/DepositBankAccountSelectInput";

import moneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import interestRateInput from "src/components/TextMaskInputComponents/interestRateInput";

import {
  deleteFinancialCertificationContract,
  fetchFinancialCertificationContract,
  updateFinancialCertificationContract,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  translations,
  signatureTypes,
  debtorsValidation,
  filesGeneration,
} from "../../../utils";
import {
  deleteFinancialCertificationContract as deleteMA,
  fetchFinancialCertificationContract as fetchMA,
  updateFinancialCertificationContract as updateMA,
} from "../../../utils/axios";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
import { toInteger } from "lodash";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#4CAF50",
      },
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
      color: "red",
    },
    "& input:focus + fieldset, & select:focus + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& label.Mui-focused": {
      color: "green",
    },
    margin: 8,
  },
})(TextField);

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
  gridFormControl: {
    margin: 8,
    width: "100%",
  },
});

interface IViewProps {
  financialCertificationContracts: IFinancialCertificationContract[];
  classes: {
    attachmentIcon: any;
    checkboxLabel: any;
    controls: any;
    deleteButton: any;
    errorInput: any;
    formControl: any;
    greenButton: any;
    half: any;
    paper: any;
    root: any;
    textField: any;
    title: any;
    gridFormControl: any;
  };
  user: IUser;
  id: any;
  changeSection(sectionNumber: number): void;
  deleteFinancialCertificationContract(
    uniqueId: string
  ): IAction<IFinancialCertificationContract[]>;
  fetchFinancialCertificationContract(
    inputCertificationContract: IFinancialCertificationContract
  ): IAction<IFinancialCertificationContract[]>;
  updateFinancialCertificationContract(
    inputCertificationContract: IFinancialCertificationContract
  ): IAction<IFinancialCertificationContract[]>;
}
interface IViewState {
  [key: string]:
    | string
    | number
    | boolean
    | string[]
    | IBankAccount[]
    | undefined;

  status: string;
  currentStatus: string;

  MA: string;
  contractDate: any;
  contractDateError: string;

  dialog: boolean;
  dialogTitle: string;

  errorMessage: string;

  mainDebtor: string[];
  mainDebtorError: string;

  secondaryDebtors: string[];
  secondaryDebtorsError: string;

  loading: boolean;

  certificateNumber: string;
  certificateNumberError: string;

  inputAmount: number;
  inputAmountError: string;

  inputAmountCurrency: string;
  inputAmountCurrencyError: string;

  uniqueId: string;

  signatureType: string;
  signatureTypeError: string;

  collectionType: string;
  collectionTypeError: string;

  expirationDate: string;
  expirationDateError: string;

  bankTransfer: string;
  bankTransferError: string;

  office: string;
  officeError: string;

  savingsAccount: string;
  savingsAccountError: string;

  rate: string;
  interestRateError: string;

  term: number;
  termError: string;
  fp: IFinancialProfile[];
  wp: IWorkProfile[];

  financialAccounts: any;
  financialAccountsError: string;
}
class View extends React.Component<IViewProps, IViewState> {
  public state: IViewState = {
    expirationDate: new Date().toISOString().split("T")[0],
    expirationDateError: "",
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    mainDebtor: [],
    mainDebtorError: "",
    secondaryDebtors: [],
    secondaryDebtorsError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    MA: "",
    inputAmount: 0,
    inputAmountError: "",
    inputAmountCurrency: "DOP",
    inputAmountCurrencyError: "",
    uniqueId: "",
    status: "in_process",
    currentStatus: "",
    certificateNumber: "",
    certificateNumberError: "",
    term: 0,
    termError: "",
    rate: '0',
    interestRateError: "",
    office: "Gazcue",
    officeError: "",
    savingsAccount: "",
    savingsAccountError: "",
    collectionType: "",
    collectionTypeError: "",
    bankTransfer: "not_applicable",
    bankTransferError: "",
    signatureType: "",
    signatureTypeError: "",
    wp: [],
    fp: [],
    financialAccounts: [],
    financialAccountsError: "",
  };
  constructor(props: IViewProps) {
    super(props);

    // client
    this.handleAddMainClient = this.handleAddMainClient.bind(this);
    this.handleAddSecondaryClient = this.handleAddSecondaryClient.bind(this);
    this.handleDeleteMainClient = this.handleDeleteMainClient.bind(this);
    this.handleDeleteSecondaryClient = this.handleDeleteSecondaryClient.bind(this);

    // core events
    this.generateTemplate = this.generateTemplate.bind(this);
    this.generateFile = this.generateFile.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    // financial Accounts
    this.handleAddBankAccount = this.handleAddBankAccount.bind(this);
    this.handleDeleteBankAccount = this.handleDeleteBankAccount.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
  }
  public handleAddMainClient(id: any) {
    this.setState({ mainDebtor: [id] });
  }
  public handleDeleteMainClient(id: any) {
    this.setState({ mainDebtor: [] });
  }
  public handleAddSecondaryClient(id: string) {
    const secondaryDebtors = this.state.secondaryDebtors.filter(
      (debtorId: any) => debtorId !== id
    );
    secondaryDebtors.push(id);
    this.setState({ secondaryDebtors });
  }
  public handleDeleteSecondaryClient(id: string) {
    const secondaryDebtors = this.state.secondaryDebtors.filter(
      (debtorId: any) => debtorId !== id
    );
    this.setState({ secondaryDebtors });
  }
  public handleAddBankAccount(id: any) {
    this.setState({ financialAccounts: [id] });
  }
  public handleDeleteBankAccount(id: any) {
    this.setState({ financialAccounts: [] });
  }

  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || 0 });
    };
  }
  
  public async generateFile() {
    this.setState({ loading: true });
    if (
      await debtorsValidation(
        this,
        this.props.user.token,
        this.state.mainDebtor,
        false
      )
    ) {
      const url = `/lending/financial-certificate-form/${this.state.uniqueId}/print_request/`;
      await filesGeneration(url, this.props.user.token)
      this.setState({loading: false})
    }
  }
  public async generateTemplate() {
    this.setState({ loading: true });
    if (
      await debtorsValidation(
        this,
        this.props.user.token,
        this.state.mainDebtor,
        false
      )
    ) {
      const url = `/lending/financial-certificate-form/${this.state.uniqueId}/print/`;
      await filesGeneration(url, this.props.user.token);
      this.setState({ loading: false });
    }
  }
  public changeStatus(newStatus: any) {
    return () => {
      this.setState({ newStatus });
    };
  }

  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public componentDidMount() {
    fetchMA(this.props.user.token, this.props.id)
      .then((res) => {
        const contract = res.data;

        this.setState({
          contractDate: contract.contractDate || "",
          mainDebtor: [contract.mainMember] || [],
          secondaryDebtors: contract.secondaryMembers || [],
          dialog: false,
          dialogTitle: "",
          loading: false,
          MA: `lending/financial-certificate-form/${contract.uniqueId}/upload_attachment/`,
          certificateNumber: contract.certificateNumber || "",
          uniqueId: contract.uniqueId || "",
          status: contract.status || "",
          currentStatus: contract.status || "",
          term: contract.term || 0,
          rate: contract.interestRate || 0.0,
          signatureType: contract.signType || "",
          collectionType: contract.paymentType || 0.0,
          inputAmount: contract.amount || 0.0,
          inputAmountCurrency: contract.amountCurrency || "DOP",
          expirationDate: contract.dueDate || "",
          office: contract.branchOffice || "",
          savingsAccount: contract.savingsAccount || "",
          bankTransfer: contract.transferType || "not_applicable",
          financialAccounts: [contract.depositBankAccount] || [],
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public async handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    if (!state.certificateNumber && state.status !== "in_process") {
      this.setState({ certificateNumberError: "Este campo es requerido" });
    } else if (
      state.bankTransfer === "wire_transfer" &&
      state.collectionType === "liquidable" &&
      !state.financialAccounts.length
    ) {
      this.setState({ financialAccountsError: "Este campo es requerido" });
    } else if (state.inputAmount === 0 || !state.term) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "Complete todos los campos requeridos",
      });
    } else {
      const contract: IFinancialCertificationContract = {
        contractDate: state.contractDate,
        status: state.status,
        mainMember: state.mainDebtor[0] || [],
        secondaryMembers: state.secondaryDebtors! || [],
        certificateNumber: state.certificateNumber || "",
        term: state.term || 0,
        interestRate: parseFloat(state.rate),
        signType: state.signatureType || "",
        paymentType: state.collectionType || "",
        amount: state.inputAmount || 0,
        amountCurrency: state.inputAmountCurrency || "DOP",
        dueDate: state.expirationDate || "",
        branchOffice: state.office || "",
        transferType:
          state.collectionType === "liquidable"
            ? state.bankTransfer
            : "not_applicable",
        depositBankAccount: state.financialAccounts[0] || "",
        savingsAccount: state.savingsAccount || "",
      };

      this.setState({ loading: true });

      updateMA(this.props.user.token, this.state.uniqueId, contract)
        .then((results) => {
          this.props.updateFinancialCertificationContract(results.data);
          successHandler(results, this, "Solicitud Certificado Financiero");
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    if ( prevState.contractDate !== this.state.contractDate ) {
      const contractDate = new Date(this.state.contractDate.replace("-", ","));
      const newDate = new Date(
        contractDate.setMonth(
          contractDate.getMonth() + toInteger(this.state.term)
        )
      );
      this.setState({
        expirationDate: newDate.toISOString().split("T")[0],
      });
    }
  }

  public handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteMA(this.props.user.token, this.props.id)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteFinancialCertificationContract(this.props.id);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public render() {
    const { classes } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        noValidate={true}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <FileUploadSelectInput
            contractId={this.state.uniqueId}
            url={this.state.MA}
            description="financial-certificate"
          />

          <Typography variant="h4" className={classes.title}>
            Solicitud de Certificado Financiero
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={4}>
                  <FormControl variant='outlined' className={classes.formControl}>
                    <InputLabel id="status-select">
                      {translations.STATUS}
                    </InputLabel>
                    <Select
                      labelId="status"
                      id="status"
                      value={this.state.status}
                      label={translations.STATUS}                      
                      required={true}
                      onChange={this.handleSelectChange("status")}
                    >
                      <MenuItem value="in_process">
                        {translations.ON_PROCESS}
                      </MenuItem>
                      <MenuItem value="completed">
                        {translations.COMPLETED}
                      </MenuItem>
                      <MenuItem value="archived">
                        {translations.ARCHIVED}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={4}>
                  <Button
                    className={classes.greenButton}
                    style={{ marginRight: 10, marginTop: 25 }}
                    onClick={this.generateFile}
                  >
                    <ArrowDownward /> &nbsp;
                    {`Generar solicitud`}
                  </Button>
                </Grid>
                <Grid item={true} xs={4}>
                  {this.state.currentStatus !== "in_process" &&
                  this.state.certificateNumber ? (
                    <Button
                      className={classes.greenButton}
                      onClick={this.generateTemplate}
                      style={{ marginRight: 10, marginTop: 25 }}
                    >
                      <DocumentIcon /> &nbsp;
                      {translations.GENERATE_DOCUMENT}
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={3}>
                  <CssTextField
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={translations.CONTRACT_DATE}
                    value={this.state.contractDate}
                    type="date"
                    onChange={this.handleChange("contractDate")}
                    required={true}
                    error={this.state.contractDateError !== ""}
                    helperText={this.state.contractDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    variant="outlined"
                    label={translations.EXPIRATION_DATE}
                    value={this.state.expirationDate}
                    style={{ width: "100%" }}
                    type="date"
                    disabled={true}
                    error={this.state.expirationDateError !== ""}
                    helperText={this.state.expirationDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl
                    variant="outlined"
                    className={classes.gridFormControl}
                  >
                    <InputLabel
                      id="signatureType"
                      error={this.state.signatureTypeError ? true : false}
                    >
                      Tipo de firma
                    </InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      id="signatureType"
                      onChange={this.handleSelectChange("signatureType")}
                      value={this.state.signatureType}
                      label={"Tipo de forma"}
                    >
                      {signatureTypes.map((opt, indx) => (
                        <MenuItem key={indx} value={opt.value}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {this.state.signatureTypeError}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl
                    className={classes.gridFormControl}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="collectionType" shrink={true}>
                      Forma de liquidación
                    </InputLabel>
                    <Select
                      value={this.state.collectionType}
                      onChange={this.handleSelectChange("collectionType")}
                      label={"Forma de liquidación"}
                      style={{ width: "100%" }}
                      required={true}
                      inputProps={{
                        id: "collectionType",
                        name: "collectionType",
                      }}
                    >
                      <MenuItem value="due_date">A vencimiento</MenuItem>
                      <MenuItem value="withdrawable">Retirables</MenuItem>
                      <MenuItem value="capitalizable">Capitalizable</MenuItem>
                      <MenuItem value="liquidable">Liquidable</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    variant="outlined"
                    label={"Monto de Certificado"}
                    value={this.state.inputAmount}
                    style={{ width: "100%" }}
                    required={true}
                    disabled={true}
                    error={this.state.inputAmountError !== ""}
                    helperText={this.state.inputAmountError}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={translations.TERM}
                    value={this.state.term}
                    type="number"
                    required={true}
                    disabled={true}
                    error={this.state.termError !== ""}
                    helperText={this.state.termError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 36,
                      min: 0,
                      step: 1,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    variant="outlined"
                    label={"Tasa"}
                    value={this.state.rate}
                    style={{ width: "100%" }}
                    onChange={this.handleChange("rate")}
                    disabled={true}
                    error={this.state.interestRateError !== ""}
                    helperText={this.state.interestRateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    InputProps={{
                      inputComponent: interestRateInput,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={"Oficina"}
                    value={this.state.office}
                    onChange={this.handleChange("office")}
                    error={this.state.officeError !== ""}
                    helperText={this.state.officeError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={3}
                  style={{
                    display:
                      this.state.collectionType === "withdrawable"
                        ? "block"
                        : "none",
                  }}
                >
                  <CssTextField
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={"Cuenta de ahorro"}
                    value={this.state.savingsAccount}
                    onChange={this.handleChange("savingsAccount")}
                    error={this.state.savingsAccountError !== ""}
                    helperText={this.state.savingsAccountError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    variant="outlined"
                    required={this.state.status === "completed" ? true : false}
                    label={"Número de certificado"}
                    style={{ width: "100%" }}
                    value={this.state.certificateNumber}
                    onChange={this.handleChange("certificateNumber")}
                    error={this.state.certificateNumberError !== ""}
                    helperText={this.state.certificateNumberError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                {this.state.collectionType === "liquidable" ? (
                  <Grid item={true} xs={3}>
                    <FormControl
                      className={classes.gridFormControl}
                      error={this.state.bankTransferError !== ""}
                    >
                      <Select
                        variant="outlined"
                        value={this.state.bankTransfer}
                        onChange={this.handleSelectChange("bankTransfer")}
                        required={true}
                        style={{ width: "100%" }}
                        inputProps={{
                          id: "bankTransfer",
                          name: "bankTransfer",
                        }}
                      >
                        <MenuItem value="not_applicable">{"N/A"}</MenuItem>
                        <MenuItem value="check">{translations.CHECK}</MenuItem>
                        <MenuItem value="wire_transfer">
                          {translations.BANK_TRANSFER}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
              </Grid>
              <br />
              <br />
              {this.state.bankTransfer === "wire_transfer" &&
              this.state.collectionType === "liquidable" ? (
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      {translations.BANK_ACCOUNT}
                    </Typography>
                    <DepositBankAccountSelectInput
                      selectedDepositBankAccountsId={
                        this.state.financialAccounts
                      }
                      addDepositBankAccount={this.handleAddBankAccount}
                      deleteDepositBankAccount={this.handleDeleteBankAccount}
                    />
                    <p className={classes.errorInput}>
                      {this.state.financialAccountsError}
                    </p>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      Miembro Principal
                    </Typography>
                    <ParticipantSelectInput
                      selectedParticipantId={this.state.mainDebtor}
                      addParticipant={this.handleAddMainClient}
                      deleteParticipant={this.handleDeleteMainClient}
                    />
                    <p className={classes.errorInput}>
                      {this.state.mainDebtorError}
                    </p>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      Miembro(s) Secundario(s)
                    </Typography>
                    <ParticipantSelectInput
                      selectedParticipantId={this.state.secondaryDebtors}
                      addParticipant={this.handleAddSecondaryClient}
                      deleteParticipant={this.handleDeleteSecondaryClient}
                    />
                    <p className={classes.errorInput}>
                      {this.state.secondaryDebtorsError}
                    </p>
                  </Grid>
                </Grid>
              ) : (
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      Miembro Principal
                    </Typography>
                    <ParticipantSelectInput
                      selectedParticipantId={this.state.mainDebtor}
                      addParticipant={this.handleAddMainClient}
                      deleteParticipant={this.handleDeleteMainClient}
                    />
                    <p className={classes.errorInput}>
                      {this.state.mainDebtorError}
                    </p>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      Miembro(s) Secundario(s)
                    </Typography>
                    <ParticipantSelectInput
                      selectedParticipantId={this.state.secondaryDebtors}
                      addParticipant={this.handleAddSecondaryClient}
                      deleteParticipant={this.handleDeleteSecondaryClient}
                    />
                    <p className={classes.errorInput}>
                      {this.state.secondaryDebtorsError}
                    </p>
                  </Grid>
                </Grid>
              )}
              <br />
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    financialCertificationContracts: state.financialCertificationContracts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteFinancialCertificationContract,
    fetchFinancialCertificationContract,
    updateFinancialCertificationContract,
  })(View)
);
