import * as React from 'react';
import { translations, warrantyTypes } from 'src/utils';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import { SearchComponent } from '../../components/SharedComponents/Fields';


const styles = createStyles({
    greenButton: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    label: {
        color: green[500]
    },
    redButton: {
        '&:hover': {
            backgroundColor: red[200]
        },
        backgroundColor: red[500],
        color: 'white'
    },
});

interface IWarrantySearchFilterProps {
    open: any;
    token: any;
    classes: {
        greenButton: string;
        redButton: string;
    };

    search(token: any): any;
    handleClose(): any;
}

interface IWarrantySearchFilter {
    [key: string]: string | string[] | number | boolean | Blob;


    debtor_unique_id: any[];
    warranty_type: any[];
    open: boolean;
}

class WarrantySearchFilter extends React.Component<IWarrantySearchFilterProps, IWarrantySearchFilter> {
    public state: IWarrantySearchFilter = {
        debtor_unique_id: [],
        warranty_type: [],
        open: false
    };
    constructor(props: IWarrantySearchFilterProps) {
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.cleanAutoComplete = this.cleanAutoComplete.bind(this);
        this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    }
   
    public handleSearch() {

        const params = {
            warrantyType: this.cleanAutoComplete(this.state.warranty_type),
            debtor: this.state.debtor_unique_id,
        };
        this.clearState();
        this.props.search(params);
        this.props.handleClose();
    }
    public handleClear() {
        this.clearState();
        this.props.search({})
        this.props.handleClose();
    }

    public clearState(){
        this.setState({
            warranty_type: [],
            debtor_unique_id: [''],
        });
    }
    public handleChange(key: any) {
        return (event: any) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }
    public handleCurrencyInputChange(key: any) {
        return (event: any, maskedValue: any, floatValue: any) => {
            this.setState({ [key]: floatValue || "" });
        };
    }
    public handleSelectChange(key: any) {
        return (event: any) => {
            this.setState({ [key]: event.target.value });
        }
    }

    public cleanAutoComplete(optionList: any) {
        // if length of list is not 0
        if (optionList.length) {
            let cleanList = ""
            for (const i of optionList) {
                // take every object, return only the value
                cleanList += `${(i.value)}|`
            }
            return cleanList
        }

        // else, return default value
        return ''
    }

    public handleAutocompleteChange(key: any, obj: any, val: any) {
        /* Handle the state of Autocomplete components */
        const data = obj || val;

        this.setState({
            [key]: data.value ? data.value : ""
        });

    }

    public render() {
        const { open, handleClose, classes } = this.props;
        const handleChange2 = (key: any, val: any) => this.setState({ [key]: val })
        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12}>
                            <InputLabel style={{color: green[500]}} htmlFor="type" shrink={true}>{translations.TYPE}</InputLabel>
                            <Autocomplete
                                multiple={true}
                                options={warrantyTypes}
                                getOptionLabel={(option) => option.label}
                                value={this.state.warranty_type}
                                onChange={(e, newValue) => handleChange2("warranty_type", newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <SearchComponent
                                xs={12}
                                containerClass="field-container"
                                fieldClass={{ style: styles.label }}
                                typeClass={styles.label}
                                fieldID="client"
                                fieldInnerLabel={translations.DEBTOR}
                                handleChange={this.handleAutocompleteChange.bind(this, "debtor_unique_id")}
                                optionType={'participants'}
                                variant="standard"
                                limitTags={1}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.redButton} onClick={this.handleClear}>
                        <ClearIcon />
                        &nbsp;
                        {`${translations.CLEAR} ${translations.SEARCH}`}
                    </Button>
                    <Button className={classes.greenButton} onClick={this.handleSearch}>
                        <SearchIcon />
                        &nbsp;
                        {translations.SEARCH}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default withStyles(styles)(WarrantySearchFilter);