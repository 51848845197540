import * as React from 'react';
import { translations } from 'src/utils';

import Buttom from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import ArrowbackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';


const styles:any = createStyles({
    button: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    checkboxLabel: {
        color: green[500],
        fontSize: 'medium'
    },
    root: {
        marginBottom: 5,
        marginTop: 5
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: 500

    }
});

interface ISecretQuestionsSelectInputProp {
    questions: IQuestion[];
    classes: {
        button: string;
        checkboxLabel: string;
        root: string;
        textField: string;
    },
    
    
    addQuestion(question: IQuestion): void;
    updQuestion(question: IQuestion, index: number): void;
    deleteQuestion(index: number): void
}

interface ISecretQuestionsSelectInputState {
    [key: string]: string | boolean | number;
    question: string;
    questionError: string;
    answer: string;
    answerError: string;
    open: boolean;
    index: number;
}

class SecretQuestionsSelectInput extends React.Component<ISecretQuestionsSelectInputProp, ISecretQuestionsSelectInputState> {
    public state: ISecretQuestionsSelectInputState = {
        answer: '',
        answerError: '',
        open: false,
        question: '',
        questionError: '',
        index: -1
    };

    constructor(props: ISecretQuestionsSelectInputProp) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.openDialogHandler = this.openDialogHandler.bind(this);
        this.closeDialogHandler = this.closeDialogHandler.bind(this);
        this.handleAddQuestions = this.handleAddQuestions.bind(this);
        this.handleDeleteQuestions = this.handleDeleteQuestions.bind(this);
        this.mapQuestions = this.mapQuestions.bind(this);
        this.handleEditQuestion = this.handleEditQuestion.bind(this);
        this.handleUpdateQuestions = this.handleUpdateQuestions.bind(this);
    }
    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                [key]: event.currentTarget.value,
                [key + 'Error']: ''
            });
        }
    }
    public openDialogHandler() {
        this.setState({ open: true });
    }
    public closeDialogHandler() {
        this.setState({ open: false });
    }
    public handleAddQuestions(event: React.MouseEvent<HTMLElement>) {

        let valid: boolean = true;
        const errorMessage = "Este campo no puede estar vacio";

        if (this.state.question.trim() === '') {
            valid = false;
            this.setState({ questionError: errorMessage });
        }

        if (this.state.answer.trim() === '') {
            valid = false;
            this.setState({ answerError: errorMessage });
        }

        if (valid) {
            if (this.state.index > -1){
                this.props.updQuestion({
                    answer: this.state.answer,
                    question: this.state.question
                }, this.state.index);
            }else{
                this.props.addQuestion({
                    answer: this.state.answer,
                    question: this.state.question
                });
            }
            this.setState({ open: false, question: '', answer: '' });
        }

    }
    public handleDeleteQuestions(index: number) {
        return (event: React.MouseEvent<HTMLElement>) => {
            this.props.deleteQuestion(index);
        }
    }
    public handleEditQuestion(i: number, question: IQuestion) {
        return (event: React.MouseEvent<HTMLElement>) => {
            this.setState({open: true, question: question.question, answer: question.answer, index: i });
        }
    }
    public handleUpdateQuestions() {        
        let valid: boolean = true;
        const errorMessage = "Este campo no puede estar vacio";

        if (this.state.question.trim() === '') {
            valid = false;
            this.setState({ questionError: errorMessage });
        }

        if (this.state.answer.trim() === '') {
            valid = false;
            this.setState({ answerError: errorMessage });
        }

        if (valid) {
            this.props.updQuestion({
                answer: this.state.answer,
                question: this.state.question
            }, this.state.index);

            this.setState({ open: false, question: '', answer: '' });
        }
    }
    public mapQuestions(question: IQuestion, index: number) {
        return (
            <TableRow key={index}>
                <TableCell>{question.question}?</TableCell>
                <TableCell>{question.answer}</TableCell>
                <TableCell>
                    <IconButton onClick={this.handleDeleteQuestions(index)}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton onClick={this.handleEditQuestion(index, question)}>
                        <EditIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
    public render() {
        const { classes, questions } = this.props;
        const { open } = this.state;
        return (
            <div className={classes.root}>
                <Dialog open={open} onClose={this.closeDialogHandler} >
                    <DialogActions>
                        <IconButton className={classes.button} onClick={this.closeDialogHandler}>
                            <ArrowbackIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent >
                        <TextField
                            label={translations.QUESTION}
                            className={classes.textField}
                            value={this.state.question}
                            onChange={this.handleChange('question')}
                            error={this.state.questionError !== ''}
                            fullWidth={true}
                            helperText={this.state.questionError}
                            required={true}
                            InputProps={{ style: styles.checkboxLabel }}
                        />
                        <TextField
                            label={translations.ANSWER}
                            className={classes.textField}
                            value={this.state.answer}
                            onChange={this.handleChange('answer')}
                            fullWidth={true}
                            error={this.state.answerError !== ''}
                            helperText={this.state.answerError}
                            required={true}
                            InputProps={{ style: styles.checkboxLabel }}
                        />
                        <br />
                        <Buttom onClick={this.handleAddQuestions} className={classes.button}>
                            {translations.SAVE}
                        </Buttom>
                    </DialogContent>
                </Dialog>
                <Paper>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{translations.QUESTION}</TableCell>
                                <TableCell>{translations.ANSWER}</TableCell>
                                <TableCell>
                                    <IconButton className={classes.button} onClick={this.openDialogHandler}>
                                        <AddIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {questions.map(this.mapQuestions)}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(SecretQuestionsSelectInput); 
