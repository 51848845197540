import React, { useState, useEffect } from 'react';
import {
    Tooltip, Dialog, DialogTitle, DialogActions, DialogContent, Grid,
    Typography, Button, List, ListItem, ListItemText, Divider, InputAdornment,
    FormControlLabel, Checkbox, IconButton, Card, CardContent, TextField, Paper
} from '@material-ui/core';
import {
    CloseOutlined, ArrowBack, ArrowDownward, Add, Sync
} from '@material-ui/icons';
import {
    HistoryTable, DataGridTable,
    ExpiryObjectTable, DueAssignmentTable, ResponsibleTable,
    CustomOrdersTable, PlainClientsTable, BotConfigDestinyAccounts, InfrastructureTable, PlainInfrastructureTable
} from './../Tables';
import {
    TextFieldComponent, AutocompleteField, RadioGroupComponent,
    NumericTextField, CheckboxField
} from './../../../components/SharedComponents/Fields';
import NumberFormat from './../../../components/SharedComponents/NumberFormat';
import { Filters as ChargingFilters } from './../Filters';
import FinancialProfile from './../../FinancialProfile';
import axios from 'axios';
import {
    fetchParticipants, fetchResponsibleAnalysts, fetchExpirationLog,
    createResponsibleAnalyst, updateResponsibleAnalyst, deleteResponsibleAnalyst,
    fetchBankAccounts, fetchExpiries, fetchExpiry, fetchChargingMethod,
    fetchSpecificOrders, createSpecificOrder, deleteSpecificOrder,
    createPaymentSetting, fetchPaymentSettings, updatePaymentSetting,
    deletePaymentSetting, fetchCreditors, fetchInstitutions, fetchInfrastructures,
    fetchNodes, fetchPods, updateSpecificOrder, deletePods
} from './../../../utils/axios';
import { ProductTypeTranslations } from '../../../components/SharedComponents/DataListTypes';
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const CustomizedTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
    //   color: '#D32F2F',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
}))(Tooltip);
const TooltipError = makeStyles((theme) => ({
    tooltip: {
      color: '#D32F2F',
    },
}));
const TooltipWarning = makeStyles((theme) => ({
    tooltip: {
      color: '#db9300',
    },
}));

function ConfirmDialogComponent(props) {
    const data = props.data.data;
    let dialogConfirmContent = '';
    switch (props.data.msg) {
        case 'confirm-delete-responsible':
            dialogConfirmContent = `¿Desea eliminar al responsable: ${data.row ? data.row.fullName : '---'}?`;
            break;
        default:
            dialogConfirmContent = '...';
    }
    return (
        <ConfirmDialogSimple
            open={props.open}
            handleToggle={props.handleToggle}
            confirmCallback={data.callback}
            data={dialogConfirmContent}
        />
    )
}

function DialogHeaderConfirm(props) {
    return(

        <DialogTitle style={{borderBottom:'1px solid #E0E0E0'}} className={props.dialogTypeClass}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Typography component={'span'} variant="h5">
                    {props.dialogTitle}
                </Typography>
            </Grid>
        </DialogTitle>
    );
}

function DialogFooterConfirm(props) {

    let confirm;
    !props.onClickConfirm ? confirm = props.onClickCancel : confirm = props.onClickConfirm;

    return(
        <DialogActions className={"dialog-footer"}>
            <Button
                variant="text"
                style={{color:'#1565C0'}}
                onClick={confirm}
            >
                {props.confirmText}
            </Button>
            {
                props.onClickConfirm ?
                    props.cancelText ?
                    <Button
                        variant="text"
                        style={{color:'#E53935'}}
                        onClick={props.onClickCancel}
                    >
                        {props.cancelText}
                    </Button>
                : null : null
            }
        </DialogActions>
    );
}

function ConfirmDialog(props) {

    return(
        <Dialog

            disableEscapeKeyDown
            fullWidth
            maxWidth="xs"
            open={props.open}
            onClick={props.stopPropagation}
            className="dialog-container"
        >
            <DialogHeaderConfirm
                dialogTitle={props.dialogTitle}
                dialogTypeClass={props.dialogTypeClass}
            />
            <DialogContent style={{margin:'2% 0'}}>
                {props.dialogContent}
            </DialogContent>
            <DialogFooterConfirm
                confirmText={props.dialogConfirmText}
                cancelText={props.dialogCancelText}
                onClickConfirm={props.handleConfirm}
                onClickCancel={props.handleToggle}
                dialogTypeClass={props.dialogTypeClass}
            />
        </Dialog>
    );
}

function ConfirmDialogSimple(props) {
    return(
        <SimpleDialog
            open={props.open}
            dialogTypeClass="confirm-dialog"
            handleToggle={props.handleToggle}
            dialogTitle="Confirmación"
            dialogContent={
                <Typography variant="subtitle1">
                    {props.data}
                </Typography>
            }
            dialogConfirmText="Aceptar"
            dialogCancelText="Cancelar"
            handleConfirm={props.confirmCallback}
        />
    );
}

function SimpleDialog(props) {
    return(
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="xs"
            open={props.open}
            onClick={props.stopPropagation}
            className="dialog-container"
        >
            <DialogHeaderConfirm
                dialogTitle={props.dialogTitle}
                dialogTypeClass={props.dialogTypeClass}
            />
            <DialogContent style={{margin:'2% 0'}}>
                {props.dialogContent}
            </DialogContent>
            <DialogFooterConfirm
                confirmText={props.dialogConfirmText}
                cancelText={props.dialogCancelText}
                onClickConfirm={props.handleConfirm}
                onClickCancel={props.handleToggle}
                dialogTypeClass={props.dialogTypeClass}
            />
        </Dialog>
    );
}


function DialogHeader(props) {
    return(
        <DialogTitle className="dialog-header">
            <Grid container justifyContent="space-between" alignItems="center" direction="row">

                <Grid item container xs={8} alignItems="center">
                    {
                        props.goBack ?
                        <IconButton onClick={props.goBack}>
                            <ArrowBack style={{color:'#424242'}} />
                        </IconButton>
                        : null
                    }
                    <Typography component={'span'} variant="h5" className="dialog-title">
                        {props.dialogTitle}
                    </Typography>
                </Grid>
                <IconButton onClick={props.handleClick}>
                    <CloseOutlined style={{color:'#424242'}} />
                </IconButton>
            </Grid>
        </DialogTitle>
    );
}

function DialogFooter(props) {
    return(
        <DialogActions className="dialog-footer">
            <Button
                variant="text"
                style={{color: '#43A047'}}
                disableElevation={true}
                onClick={props.handleClick}
                disabled={props.disabled}
            >
                {props.dialogFooterText}
            </Button>
        </DialogActions>
    );
}

function SingleAssingmentDialog(props) {

    const responsibles = [
        'Edward Gonzalez',
        'Enrique Peña',
        'Marcos Cabral',
        'Tormenta Eléctrica'
    ];
    const orders = ['1','2','3','4','5','6'];

    const [selectedResponsible, setResponsible] = useState(null);
    const [selectedOrder, setOrder] = useState(null);

    const handleResponsibleChange = (event, value) => {
        setResponsible(value);
    }
    const handleOrderChange = (event, value) => {
        setOrder(value);
    }

    return(
        <Dialog

            fullWidth
            maxWidth="sm"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Asignación de responsable"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />
            <DialogContent style={{margin:'2% 0'}}>
                <Grid container direction="row" spacing={2} style={{padding:'0 1%'}}>
                    <AutocompleteField
                        xs={6}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Responsables"
                        fieldID="select-field-responsible-assignment"
                        fieldInnerLabel="Seleccionar responsable"
                        fieldVariant="outlined"
                        value={selectedResponsible}
                        handleChange={handleResponsibleChange}
                        options={responsibles}
                    />
                    <AutocompleteField
                        xs={6}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Orden"
                        fieldID="select-field-order-assignment"
                        fieldInnerLabel="Seleccionar orden"
                        fieldVariant="outlined"
                        value={selectedOrder}
                        handleChange={handleOrderChange}
                        options={orders}
                    />
                </Grid>

                <Grid container justifyContent="flex-end" style={{marginTop:30, paddingRight: '1%'}}>
                    <Button
                        variant="contained"
                        style={{backgroundColor: '#43A047', color: '#FFF', fontSize: 12}}
                        disableElevation
                        size="small"
                    >
                        guardar asignación
                    </Button>
                </Grid>
            </DialogContent>
            <DialogFooter
                // dialogFooterText="Guardar cambios"
            />
        </Dialog>
    )
}

function MassAssignmentDialogContent(props) {
    const fieldsToHide = {
        custom: true,
        group: true,
        status: true,
        startDate: true,
        endDate: true,
    }
    const [clientsData, setClientsData] = useState([]);
    const [responsibles, setResponsibles] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    // get clients method
    const getClients = (filters) => {
        props.setLoading(true);
        const endpoint = fetchParticipants({...filters, token: props.token});
        endpoint.then(response => {
            const cleanData = response.data.filter(item => item.financialProfileObj && item.workProfileObj);
            const data = cleanData.map(item => {
                return {
                    asignee: item.financialProfileObj.assignee || 'SIN INFORMACIÓN',
                    work: item.workProfileInstitution || 'SIN INFORMACIÓN',
                    bank: item.financialProfileInstitution || 'SIN INFORMACIÓN',
                    fullName: item.fullName || 'SIN INFORMACIÓN',
                    identificationNumber: item.identificationNumber || 'SIN INFORMACIÓN',
                    rating: item.financialProfileObj.rating || 'SIN INFORMACIÓN',
                    uniqueId: item.uniqueId,
                }
            });
            setClientsData(data);
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleConfirm);
        });
    }
    const getResponsibles = () => {
        props.setLoading(true);
        const endpoint = fetchResponsibleAnalysts(props.token);
        endpoint.then(response => {
            setResponsibles(response.data);
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleConfirm);
        });
    }

    // apply filters btn.
        // Gets the filter fields selected data, then pass it as parameters
        // in the get clients method and fetch the data in the table.
        // this participant endpoint has differente property names.
    const applyFilters = (fields) => {
        const data = {
            ...fields,
            responsible_analyst: fields.responsible_analyst,
            work: fields.work_institution,
            institution: fields.finantial_institution,
            fullName: fields.client,
        }
        Object.keys(data).forEach(key => {
            if(!data[key] || data[key].length === 0) {
                delete data[key];
            }
        });
        getClients(data);
        setSelectedFilters({...data});
    }

    useEffect(() => {
        getResponsibles();
    }, []);

    return(
        <Grid container direction="row">
            <div style={{marginBottom:'2%', width:'100%'}}>
                <ChargingFilters
                    hideFields={fieldsToHide}
                    hideClass="hide"
                    disableCloseBtn={true}
                    applyFilters={applyFilters}
                    setLoading={props.setLoading}
                    token={props.token}
                />
            </div>
            <DueAssignmentTable
                responsibles={responsibles}
                data={clientsData}
                handleToggleConfirm={props.handleToggleConfirm}
                handleClients={getClients.bind(this, selectedFilters)}
                setLoading={props.setLoading}
                token={props.token}
            />

        </Grid>
    );
}

function MassAssignmentDialog(props) {

    const [selectedResponsible, setResponsible] = useState('');
    const [selectedOrder, setOrder] = useState('');

    const handleResponsibleChange = (event) => {
        setResponsible(event.target.value);
    }

    const handleOrderChange = (event) => {
        setOrder(event.target.value);
    }
    return(
        <Dialog

            fullWidth
            maxWidth="lg"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Reasignación de clientes"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />
            <DialogContent style={{marginTop: '2%', marginBottom:'2%'}}>
                <MassAssignmentDialogContent
                    responsible={selectedResponsible}
                    order={selectedOrder}
                    handleToggleConfirm={props.handleConfirm}
                    handleResponsible={handleResponsibleChange}
                    handleOrder={handleOrderChange}
                    setLoading={props.setLoading}
                    token={props.token}
                />
            </DialogContent>
        </Dialog>
    )
}

function InfrastructureDialogContent(props) {
    const [specificOrder, setSpecificOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [availablePods, setAvailablePods] = useState(null);
    const [pods, setPods] = useState(null);

    // get Specific Order
    const getSpecificOrders = () => {
        props.setLoading(true);
        const endpoint = fetchSpecificOrders(props.token);
        endpoint.then(response => {
            setSpecificOrders(response.data);
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    // get pods
    const getPods = () => {
        props.setLoading(true);
        const endpoint = fetchPods(props.token);
        endpoint.then(response => {
            setAvailablePods(response.data);
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }

    const getNewAvailablePods = () => {
        const usedPods = specificOrder.reduce((accumulator, object) => {
            return accumulator + object.pods;
        }, 0)
        getPods()
        setAvailablePods(availablePods - usedPods)
    }
    const handleCustomOrderChange = (event, value) => {
        setSelectedOrder({...value});
    }

    const handlePodsChange = (event) => {
        setPods(event.target ? event.target.value : event.value)
    }

    const handleAddPodsToOrder = () => {
        props.setLoading(true);
        if (Number(pods) < Number(availablePods)){
            const endpoint = updateSpecificOrder(props.token, {...selectedOrder, pods: Number(pods)});
            endpoint.then(() => {
                getSpecificOrders();
                setPods(NaN);
                setSelectedOrder({});
                props.setLoading(false);
                props.handleConfirm('success-dialog');
            }).catch(errors => {
                props.setLoading(false);
                errorHandling(errors, props.handleConfirm);
            });
        }else{
            errorHandling("La cantidad de Pods supera la disponibe, favor revisar", props.handleConfirm);
        }
    }

    const reloadOrdersEl = () => {
        return(
            <IconButton
                onClick={getSpecificOrders}
                style={{padding:4}}
            >
                <Sync style={{fontSize:18, color:'rgba(0, 0, 0, 0.54)'}} />
            </IconButton>
        )
    }

    useEffect(() => {
        getSpecificOrders()
        getPods()
    }, []);

    return(
        <Grid container direction="row">
            <div style={{marginBottom:'2%', width:'100%'}}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <AutocompleteField
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Ordenes personalizados"
                            fieldID="filter-field-custom-order"
                            fieldInnerLabel="Orden"
                            fieldVariant="outlined"
                            handleChange={handleCustomOrderChange}
                            options={specificOrder}
                            getOptionLabel={(option) => option.name || ''}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId || {}}
                            multiple={false}
                            value={selectedOrder}
                            disabled={Boolean(pods)}
                            insideElement={reloadOrdersEl()}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <NumericTextField
                            xs={12}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Pods"
                            fieldID="field-pods"
                            fieldClass="fields"
                            fieldVariant="outlined"
                            margin='dense'
                            fullWidth
                            value={pods}
                            onChange={handlePodsChange}
                            // disabled={props.ticketCompleted || props.enableReference}
                            // error={errorFields.name}
                        />
                    </Grid>
                    <Grid item xs={1} style={{placeSelf: "end" }}>
                        <Button
                            onClick={handleAddPodsToOrder}
                            variant="contained"
                            className={!Object.keys(selectedOrder).length == 0 && Boolean(pods) ? "action-method-btn save" : "action-method-btn disabled"}
                            size="medium"
                            disableElevation
                            style={{marginBottom:12, height:32}}
                            disabled={Object.keys(selectedOrder).length == 0 || !pods}
                        >
                            Guardar
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <TextFieldComponent
                            xs={12}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Pods Disponibles"
                            fieldID="field-available-pods"
                            fieldClass="fields"
                            fieldVariant="outlined"
                            margin='dense'
                            fullWidth
                            value={availablePods}
                            // onChange={handlePodsChange}
                            disabled={true}
                            // error={errorFields.name}
                        />
                    </Grid>
                </Grid>
            </div>
            <Divider />
            <Grid container>
                {/* <Grid item xs={6}>
                    <InfrastructureTable
                        data={specificOrder}
                        inst={selectedBank}
                        node={selectedNode}
                        setBank={setBank}
                        refreshInfrastructure={getSpecificOrders}
                        handleConfirm={props.handleConfirm}
                        handleClients={getInts.bind(this)}
                        setLoading={props.setLoading}
                        token={props.token}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <PlainInfrastructureTable
                        data={specificOrder}
                        pods={pods}
                        getPods={getPods}
                        getNewAvailablePods={getNewAvailablePods}
                        handleConfirm={props.handleConfirm}
                        // handleClients={getInts.bind(this)}
                        refreshInfrastructure={getSpecificOrders}
                        setLoading={props.setLoading}
                        token={props.token}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

function InfrastructureDialog(props) {
    return(
        <Dialog
            disableBackdropClick
            fullWidth
            maxWidth="xl"
            onClose={props.handleSecondaryDialog.bind(this, undefined)}
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Infraestructura"
                handleClick={props.handleSecondaryDialog.bind(this, undefined)}
                />
            <DialogContent style={{marginTop: '2%', marginBottom:'2%'}}>
                <InfrastructureDialogContent
                    handleConfirm={props.handleConfirm}
                    setLoading={props.setLoading}
                    token={props.token}
                />
            </DialogContent>
        </Dialog>
    )
}

function HistoryDialog(props) {
    const [logs, setLogs] = useState([]);

    const getLogs = (event) => {
        const endpoints = fetchExpirationLog(props.token, props.data.uniqueId);
        endpoints.then(response => {
            return setLogs(response.data)
        }).catch(errors => {
            errorHandling(errors, props.handleToggleConfirmDialog);
        });
    }

    useEffect(() => {
        getLogs()
    }, []);

    return(
        <Dialog

            fullWidth
            maxWidth="md"
            onClose={
            (e, reason) => reason === 'backdropClick' && e.preventDefault()
        }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Eventos del bot"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />
            <DialogContent style={{margin:'2% 0'}}>
                <HistoryTable
                    data={logs}
                />
            </DialogContent>
            <DialogFooter
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
        </Dialog>
    )
}

function ExpiryObjectDialog(props) {
    return(
        <Dialog

            fullWidth
            maxWidth="lg"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Objeto de Vencimiento"
                handleClick={props.handleSecondaryDialog.bind(this, undefined)}
            />
            <DialogContent style={{margin:'2% 0'}}>

                <Grid container>
                    <ExpiryObjectTable
                        data={props.data}
                        handleToggleDialog={props.handleToggleDialog}
                    />
                </Grid>
            </DialogContent>
            <DialogFooter
                dialogFooterText="Cerrar"
                handleClick={props.handleSecondaryDialog.bind(this, undefined)}
            />
        </Dialog>
    )
}

function BotConfigDialog(props) {
    const defaultChargeConfig = {
        irregularAmount: "0",
        clientAccounts: null,
        destinyAccounts: [],
        matchPayroll: true,
        captcha: false,
        controlAmountNotice: "0",
        controlAmountCharge: "0",
        botAction: "",
        checkChargingAccounts: true,
    };
    const [tableData, setTableData] = useState([]);
    const [botConfig, setBotConfig] = useState({});
    const [bankProfiles, setBankProfiles] = useState([]);
    const [destinyAccounts, setDestinyAccounts] = useState([]);
    const [difference, setDifference] = useState({state: '', text: ''});
    const [error, setError] = useState(false);
    const [errorFields, setErrorFields] = useState({});
    const totalAmountPay = (props.data.overdue_amount - props.data.charged_amount);
    const errTooltip = TooltipError();
    const warnTooltip = TooltipWarning();

    const handleSelectedDestinyAccounts = (value) => {
        const selectedAccounts = value.map(item => item.account);
        const currentData = tableData.map(item => item.account);
        if(tableData.length > selectedAccounts.length) {
            const newData = tableData.filter(item => selectedAccounts.includes(item.account));
            setTableData(newData);
            // setDestinyAccountConfig(newData);
            setDifference(newData.length === 0 ? {state: '', text: ''} : difference);
        }else {
            const newData = destinyAccounts.filter(item => selectedAccounts.includes(item.account) && !currentData.includes(item.account));
            const newTableData = tableData.concat(newData);
            setTableData(newTableData);
            // setDestinyAccountConfig(newTableData);
            setDifference(newTableData.length === 0 ? {state: '', text: ''} : difference);
        }
    }

    const handleBotConfig = (field, event, value) => {
        let newVal;
        switch(field) {
            case 'irregularAmount':
                validateAmountToCharge(event.value, undefined);
                newVal = event.value;
                break;
            case 'clientAccounts':
                setErrorFields({...errorFields, clientAccounts: false});
                setError(false);
                newVal = value;
                break;
            case 'destinyAccounts':
                setErrorFields({...errorFields, destinyAccounts: false});
                setError(false);
                handleSelectedDestinyAccounts(value);
                newVal = value;
                break;
            case 'matchPayroll':
            case 'captcha':
            case 'checkChargingAccounts':
                newVal = !botConfig[field];
                break;
            case 'botAction':
                if(typeof event.target.value === 'undefined') {
                    return
                }
                event.target.value === botConfig[field] ?
                newVal = "" : newVal = event.target.value;
                break;
            default:
                newVal = event.target ? event.target.value : event.value;
                break;
        }
        setBotConfig({...botConfig, [field]: newVal}); //this way the state of the object property updates.
    }

    const validateAmountToCharge = (irregular, destinies) => {
        const destinyConfig = typeof destinies === 'undefined' ? tableData : destinies;
        const irregularAmount = typeof irregular === 'undefined' ? Number(botConfig.irregularAmount) : Number(irregular);
        const destinyTotal = destinyConfig.reduce((total, item) => {
            return total + parseFloat(item.chargeAmount === '' ? 0 : item.chargeAmount)
        }, 0);
        let difference = false;

        if(tableData.length > 0 && irregularAmount) {
            difference =
                parseFloat(irregularAmount) < destinyTotal ?
                'exceed' : parseFloat(irregularAmount) > destinyTotal ? 'lack' : false;


        }else if(tableData.length > 0 && totalAmountPay) {
            difference =
                parseFloat(totalAmountPay) < destinyTotal ?
                'exceed' : parseFloat(totalAmountPay) > destinyTotal ? 'lack' : false;
        }
        const text =
            difference === 'exceed' ?
                "El total digitado excede el monto a cobrar"
            :
            difference === 'lack' ?
                "El total digitado es inferior al monto a cobrar" : '';

        setDifference({state: difference, text: text});
        setError(Boolean(difference));
        return Boolean(difference);
    }

    const handleDestinyAccountsChange = (data, field, event) => {
        const result =
            tableData.map(item =>
                (item.uniqueId === data.uniqueId ? {...item, [field]: event.target ? event.target.value : event.value} : item)
            ); // updating value of specific object inside array.
        setTableData(result);
        validateAmountToCharge(undefined, result);
    }

    const botActionsOptions = () => {
        return [
            {text: 'NO REVISAR', value: 'no_check'},
            {text: 'NO COBRAR', value: 'no_charge'},
        ]
    }

    const getChargingMethods = () => {
        props.setLoading(true);
        const endpoint = fetchChargingMethod(props.token, props.data.client_uniqueId);
        endpoint.then(response => {
            const data = response.data.map(item => {
                return {
                    name: item.label + " - " + item.accountNumber,
                    chargingAccount: item.accountNumber,
                    currencyType: item.currencyType,
                    accountType: item.accountType,
                    bankUsername: item.bankUsername,
                    bankPassword: item.bankPassword,
                    secretQuestions: item.secretQuestions,
                    institution: item.institution,
                    codeCard: item.codeCard,
                    owner: item.owner,
                    uniqueId: item.uniqueId,
                }
            });
            if(props.data.charge_config && typeof props.data.charge_config.clientAccounts === 'object') {
                setBotConfig({...props.data.charge_config});
            }else if(props.data.charge_config) {
                let charge = {...props.data.charge_config};
                charge.clientAccounts = data.find(item => item.uniqueId === charge.clientAccounts);
                setBotConfig({...charge});
                // props.data.charge_config.clientAccounts = charge.clientAccounts;
                // if(props.data.charge_config.destinyAccounts.length > 0) {
                //     setTableData(props.data.charge_config.destinyAccounts);
                // }
            }
            if(props.data.charge_config && props.data.charge_config.destinyAccounts.length > 0) {
                setTableData(props.data.charge_config.destinyAccounts);
            }
            setBankProfiles(data);
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }

    const getDestinyAccounts = (creditors) => {
        const endpoint = fetchBankAccounts({token: props.token});
        endpoint.then(response => {
            const data = response.data.map(item => {
                const creditorInfo = creditors.find(c => c.uniqueId === item.owner);
                const creditorName = creditorInfo ? creditorInfo.type === 'legal' ?
                    creditorInfo.institutionDetail.abbreviation : creditorInfo.participantDetail.fullName : '---';
                const participantDetail = creditorInfo && creditorInfo.participantDetail;
                return {
                    name: item.name + " - " + item.number,
                    account: item.number,
                    beneficiary: creditorName,
                    finantialInstitution: item.abbreviation,
                    currency: item.currencyType,
                    productType: item.accountType,
                    transactionType: 'LBTR',
                    chargeAmount: "0",
                    idNumber: participantDetail ? participantDetail.identificationNumber : '---',
                    idType: participantDetail ? participantDetail.identificationType : '---',
                    creditor: creditorInfo && creditorInfo.uniqueId,
                    uniqueId: item.uniqueId,
                }
            });
            setDestinyAccounts(data);
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }

    const getCreditors = () => {
        props.setLoading(true);
        const endpoint = fetchCreditors({token: props.token});
        endpoint.then(response => {
            getDestinyAccounts(response.data);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }

    const validateFields = () => {
        let errData = {}
        let foundErr = false;
        if(botConfig.destinyAccounts.length === 0) {
            errData = {...errData, destinyAccounts: true}
            foundErr = true;
        }
        if(!botConfig.clientAccounts) {
            errData = {...errData, clientAccounts: true}
            foundErr = true;
        }
        setError(foundErr);
        setErrorFields({...errData});
        return foundErr;
    }

    const handleSave = () => {
        if(validateFields()){
            return;
        }
        if(validateAmountToCharge(undefined, undefined)) {
            return;
        }
        let data = {...botConfig};
        data.destinyAccounts = tableData;
        data.amountPerAccount = tableData;
        props.data.charge_config = data;
        props.handleToggleDialog(undefined);
    }

    useEffect(() => {
        getChargingMethods();
        // getDestinyAccounts();
        getCreditors();
        if(!props.data.charge_config) {
            setBotConfig({...defaultChargeConfig});
        }
    }, []);

    return(
        <Dialog

            fullWidth
            maxWidth="xl"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Configuración de Cobro"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />
            <DialogContent style={{margin:'2% 0'}}>

                <Typography variant="h6" style={{color: '#424242', fontWeight:'bold', marginBottom: 20}}>
                    {props.data.client}
                </Typography>
                <Divider style={{marginBottom: 20}}/>
                <Grid container spacing={2}>
                    <Grid item container xs={9} direction="column" style={{height:'100%'}} spacing={2}>
                        <Grid item container xs={12} spacing={1}>
                            <NumericTextField
                                xs={6}
                                typeVariant="subtitle1"
                                typeClass="field-label"
                                fieldLabel="Monto total a cobrar"
                                fieldID="text-field-overdue-amount"
                                fieldClass="fields text-field-info"
                                placeholder="Monto total a cobrar"
                                margin='dense'
                                fieldVariant="outlined"
                                fullWidth
                                prefix="$"
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={(totalAmountPay > 0 && totalAmountPay) || 0}
                            />
                            <Grid item xs={6}>
                                <CustomizedTooltip
                                    open={
                                        botConfig.irregularAmount > 0 ?
                                        Number(botConfig.irregularAmount) < (totalAmountPay * 0.5)
                                        : false
                                    }
                                    title={"El monto es menor al esperado."}
                                    placement="top-start"
                                    className={"warning-text"}
                                    classes={warnTooltip}
                                >
                                    <div>
                                        <NumericTextField
                                            xs={12}
                                            typeVariant="subtitle1"
                                            typeClass="field-label"
                                            fieldLabel="Monto irregular"
                                            fieldID="text-field-irregular-amount"
                                            fieldClass="fields"
                                            margin='dense'
                                            fieldVariant="outlined"
                                            fullWidth
                                            prefix="$"
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            value={botConfig.irregularAmount || 0}
                                            onChange={handleBotConfig.bind(this, 'irregularAmount')}
                                        />
                                    </div>
                                </CustomizedTooltip>
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} direction="row" spacing={1} style={{marginTop:".5rem"}}>
                            <CheckboxField
                                xs={4}
                                alignItems="flex-end"
                                check={botConfig.matchPayroll || false}
                                handleChange={handleBotConfig.bind(this, 'matchPayroll')}
                                color="primary"
                                textColor="#43A047"
                                label="MATCHEAR NOMINA"
                            />
                            <CheckboxField
                                xs={3}
                                alignItems="flex-end"
                                check={botConfig.captcha || false}
                                handleChange={handleBotConfig.bind(this, 'captcha')}
                                color="primary"
                                textColor="#43A047"
                                label="CAPTCHA MANUAL"
                            />
                            <CheckboxField
                                xs={5}
                                alignItems="flex-end"
                                check={botConfig.checkChargingAccounts || false}
                                handleChange={handleBotConfig.bind(this, 'checkChargingAccounts')}
                                color="primary"
                                textColor="#43A047"
                                label="CHEQUEAR CUENTAS COBRO BOT"
                            />
                        </Grid>

                        <Grid item xs={12} container direction="row" spacing={2}>

                            <AutocompleteField
                                xs={6}
                                containerClass="field-container"
                                fieldClass="fields"
                                typeVariant="subtitle1"
                                typeClass="field-label"
                                fieldLabel="Cuenta(s) destino"
                                fieldID="select-field-destiny-account"
                                fieldInnerLabel="Cuentas destino"
                                fieldVariant="outlined"
                                options={destinyAccounts}
                                value={botConfig.destinyAccounts || []}
                                handleChange={handleBotConfig.bind(this, 'destinyAccounts')}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                                multiple={true}
                                limitTags={1}
                                optionLabel={"name"}
                                error={errorFields.destinyAccounts}
                                helperText={errorFields.destinyAccounts ? "Seleccione la(s) cuenta(s)." : null}
                            />

                            <AutocompleteField
                                xs={6}
                                containerClass="field-container"
                                typeVariant="subtitle1"
                                typeClass="field-label"
                                fieldLabel="Cuenta(s) cliente"
                                fieldID="select-field-accounts"
                                fieldClass="fields"
                                fieldInnerLabel="Cuenta actual para cobro del cliente"
                                fieldVariant="outlined"
                                options={bankProfiles}
                                value={botConfig.clientAccounts || null}
                                handleChange={handleBotConfig.bind(this, 'clientAccounts')}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                                limitTags={1}
                                multiple={false}
                                optionLabel={"name"}
                                error={errorFields.clientAccounts}
                                helperText={errorFields.clientAccounts ? "Seleccione una cuenta." : null}
                            />

                        </Grid>
                    </Grid>

                    <Grid item container xs={3} direction="column" justifyContent="flex-end" alignItems='flex-start'>
                        <div style={{backgroundColor:'#fafafa', borderRadius:'3px', padding:'5px 10px', height:'100%', width:'100%'}}>
                            <Typography variant="subtitle1" className="section-header" style={{fontWeight:'bold'}}>
                                Detalle de cuentas cliente
                            </Typography>
                            <div style={{display: 'block', overflow: 'auto', height:'200px'}}>
                                <List>
                                {
                                    botConfig.clientAccounts &&  botConfig.clientAccounts.uniqueId ?
                                        <ListItem>
                                            <ListItemText
                                                primary={botConfig.clientAccounts.chargingAccount}
                                                secondary={
                                                    botConfig.clientAccounts.currencyType + " | " +
                                                    (
                                                        botConfig.clientAccounts.accountType ?
                                                        ProductTypeTranslations().find(item => item.value === botConfig.clientAccounts.accountType).name
                                                        : '--'
                                                    )
                                                }
                                            />
                                        </ListItem>
                                    :
                                        [1,2,3].map((item, index) =>
                                            <ListItem key={(item+'-')+index}>
                                                <div style={
                                                    {backgroundColor:'#F5F5F5', borderRadius:'3px',
                                                    height:'45px', width:'100%', marginTop:'5px'}
                                                }></div>
                                            </ListItem>
                                        )
                                }
                                </List>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid container direction="column" style={{marginTop:'2rem'}}>
                    <Grid item xs={12}>
                        <CustomizedTooltip
                            open={Boolean(difference.state)}
                            title={difference.text}
                            placement="bottom-end"
                            classes={errTooltip}
                        >
                            <div>
                                <BotConfigDestinyAccounts
                                    data={tableData}
                                    difference={difference}
                                    handleBotConfig={handleBotConfig}
                                    handleDestinyChange={handleDestinyAccountsChange}
                                />
                            </div>
                        </CustomizedTooltip>
                    </Grid>
                </Grid>

                <Typography variant="h6" style={{color: '#424242', marginTop: 25, fontWeight:'bold', marginBottom: 20}}>
                    Configuración de control
                </Typography>
                <Divider style={{marginBottom: 20}}/>
                <Grid container direction="row" spacing={2}>
                    <NumericTextField
                        xs={3}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Monto control aviso"
                        fieldID="text-field-alert-control-amount"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        margin='dense'
                        fullWidth
                        prefix="$"
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={botConfig.controlAmountNotice || 0}
                        onChange={handleBotConfig.bind(this, 'controlAmountNotice')}
                    />
                    <NumericTextField
                        xs={3}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Monto control cobro"
                        fieldID="text-field-charge-control-amount"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        margin='dense'
                        fullWidth
                        prefix="$"
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={botConfig.controlAmountCharge || 0}
                        onChange={handleBotConfig.bind(this, 'controlAmountCharge')}
                    />

                    <RadioGroupComponent
                        xs={4}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Acciones del bot"
                        fieldID="radio-field-bot-actions"
                        fieldClass="fields"
                        row={true}
                        options={botActionsOptions()}
                        value={botConfig.botAction || false}
                        onClick={handleBotConfig.bind(this, 'botAction')}
                    />

                </Grid>
                <Grid container justifyContent="flex-end" style={{marginTop:'3%'}}>
                    <Button
                        variant="contained"
                        className={error ? "action-method-btn disabled" : "action-method-btn save"}
                        disableElevation
                        size="small"
                        onClick={handleSave}
                        disabled={error}
                    >
                        guardar cambios
                    </Button>
                </Grid>
            </DialogContent>
            <DialogFooter/>
        </Dialog>
    )

}

function BotInitConfigDialog(props) {

    const [selectedBotInitOption, setSelectedBotInitOption] = useState([props.type === 'login' ? 'login' : 'revision']);
    // const [viewProcess, setViewProcess] = useState(false);
    const [restTime, setRestTime] = useState(5);
    const [selectedInfrastructure, setSelectedInfrastructure] = useState(null);


    const handleChangeRestTime = (event) => {
        setRestTime(event.target.value < 0 ? 0 : event.target.value)
    }

    // const handleChangeViewProcess = () => {
    //     setViewProcess(!viewProcess)
    // }

    const handleBotOptionChange = (event) => {
        setSelectedBotInitOption(event.target.value)
    }

    const handleInfraestructureChange = (event, newValue) => {
        setSelectedInfrastructure(newValue)
    }

    const botInitOptions = () => {
        return [
            {text: 'REVISAR', value: 'revision'},
            {text: 'COBRAR', value: 'charge'},
            {text: 'REVISAR Y COBRAR', value: 'revision and charge'},
            {text: 'REVISIÓN FIJA', value: 'fixed revision'},
        ]
    }

    const botInitOptionsLogin = () => {
        return [
            {text: 'REVISAR', value: 'login'},
            {text: 'COBRAR', value: 'charge'},
        ]
    }

    const resetToDefaultOptions = () => {
        setSelectedBotInitOption('revision');
        setRestTime(5);
        setSelectedInfrastructure(null);
    }

    const handleSave = (e) => {
        const data = {
            view: false, // viewProcess,
            sleep_time: Number(restTime)*1000,
            mode: selectedBotInitOption || null,
            infrastructure: selectedInfrastructure ? selectedInfrastructure.uniqueId : null,
        }
        props.handleConfirm(data, e);
        resetToDefaultOptions();
    }

        return(
            <Dialog
                style={
                    {width:'655px !important'}
                }
                maxWidth={false}
                onClose={
                    (e, reason) => reason === 'backdropClick' && e.preventDefault()
                }
                className="dialog-container"
                open={props.open}
                onClick={props.stopPropagation}
            >
                <DialogHeader
                    dialogTitle="Configuración de Inicio"
                    handleClick={(e) => {
                        resetToDefaultOptions();
                        props.handleToggleDialog(undefined, e);
                    }}
                />
                <DialogContent style={{margin:'2% 0'}}>
                    {/* <Grid container justifyContent="space-between" alignItems="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.viewProcess}
                                    onChange={this.handleChangeViewProcess}
                                    color="primary"
                                    style={{color: '#43A047'}}
                                />
                            }
                            label="VISUALIZAR"
                        />
                    </Grid>
                    <Divider style={{marginBottom: 20}}/> */}
                    <Grid container direction="column" spacing={2}>
                        <Grid container direction="row" spacing={2}>
                            <TextFieldComponent
                                xs={6}
                                typeVariant="subtitle1"
                                typeClass="field-label"
                                fieldLabel="Tiempo descanso"
                                fieldID="text-field-rest-time"
                                fieldClass="fields"
                                fieldVariant="outlined"
                                margin='dense'
                                fullWidth
                                value={restTime}
                                onChange={handleChangeRestTime}
                                type="number"
                                inputProps={{
                                    startAdornment: <InputAdornment position="start">segundos:</InputAdornment>,
                                }}
                            />
                            <AutocompleteField
                                xs={6}
                                containerClass="field-container"
                                typeVariant="subtitle1"
                                typeClass="field-label"
                                fieldID="select-assign-responsible-assignment"
                                fieldClass="fields"
                                fieldVariant="outlined"
                                fieldInnerLabel="Infraestructura"
                                fieldLabel="Infraestructura"
                                value={selectedInfrastructure || null}
                                handleChange={handleInfraestructureChange}
                                options={props.infrastructures || []}
                                getOptionLabel={(option) => option.fullName}
                                getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                                multiple={false}
                            />
                        </Grid>
                        <RadioGroupComponent
                            xs={12}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Seleccione una opción"
                            fieldID="radio-field-bot-init-option"
                            fieldClass="fields"
                            row={true}
                            options={props.type === 'login' ? botInitOptionsLogin() : botInitOptions()}
                            value={selectedBotInitOption}
                            onChange={handleBotOptionChange}
                        />
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginTop:'2rem'}}>
                        <Button
                            variant="contained"
                            className={"action-method-btn save"}
                            style={{height:30, marginBottom:5}}
                            disableElevation
                            size="small"
                            onClick={handleSave}
                        >
                            iniciar bot
                        </Button>
                    </Grid>
                </DialogContent>
                <DialogFooter />
            </Dialog>
        )
}

function FinancialCompositionDialog(props) {

    const columns = [
        { field: 'principal', headerName: 'PRINCIPAL', width: 180, sortable: false },
        { field: 'dischargement', headerName: 'DESEMBOLSO', width: 180, sortable: false },
        { field: 'loan_type', headerName: 'TIPO DE PRÉSTAMO', width: 180, sortable: false, },
        { field: 'interest', headerName: 'INTERÉS', width: 120, sortable: false, },
        { field: 'commission', headerName: 'COMISIÓN', width: 160, sortable: false, },
        { field: 'insurance', headerName: 'SEGURO', width: 180, sortable: false, },
        { field: 'total_due', headerName: 'TOTAL EXIGIBLE', width: 180, sortable: false, },
    ];

    return(
        <Dialog

            fullWidth
            maxWidth="lg"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Composición financiera"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />
            <DialogContent style={{margin:'2% 0'}}>

                <Grid container direction="row">

                    <Grid item xs={12}>
                        <DataGridTable
                            rows={props.data.financial_composition}
                            columns={columns}
                            checkboxSelection={false}
                            hideFooter={true}
                            height={160}
                        />
                    </Grid>

                </Grid>

            </DialogContent>
            <DialogFooter
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
        </Dialog>
    )
}

function FinancialProfileDialog(props) {
    return(
        <Dialog
            fullWidth
            maxWidth="lg"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Perfil Financiero"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />

            <DialogContent style={{margin:'2% 0'}}>
                <Grid container display="flex">
                <Paper style={{width:'250px', padding: '.5rem 1rem', margin: '1rem 0'}}>
                    <Typography
                        variant="subtitle1"
                        style={{color:'#424242', display:'flex', flexDirection:'column'}}
                    >
                        {
                            props.rowData ?
                            <Typography
                            variant="overline"
                            style={{color:'#424242'}}
                        >{props.rowData?.client}</Typography>
                            :
                                <span>NO ENCONTRADO</span>
                        }
                        <Typography
                            variant="overline"
                            style={{color:'#424242'}}
                        >Cliente</Typography>
                    </Typography>
                </Paper>
                <Paper style={{width:'250px', padding: '.5rem 1rem', margin: '1rem 20px'}}>
                    <Typography
                        variant="subtitle1"
                        style={{color:'#424242', display:'flex', flexDirection:'column'}}
                    >
                        {
                            props.rowData ?
                                <NumberFormat
                                    value={props.rowData.remaining_amount}
                                    prefix={"$"}
                                />
                            :
                                <span>NO ENCONTRADO</span>
                        }
                        <Typography
                            variant="overline"
                            style={{color:'#424242'}}
                        >Monto restante</Typography>
                    </Typography>
                </Paper>

                </Grid>
                <FinancialProfile ownerId={props.data}/>
            </DialogContent>

            <DialogFooter
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
        </Dialog>
    )
}

function ManageResponsibleDialog(props) {
    const defaultFieldsValue = {
        fullName: '',
        email: '',
        // ipAddress: '',
        // user: '',
        // password: '',
        // isBot:false
    }
    const [fields, setField] = useState(defaultFieldsValue);
    const emailRegx = // email reg expression
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const minLengthRegx = /.{3,}$/;
    const ipMinLength = /.{12,}$/;
    const requiredFields = ['fullName']; // array with required fields.
    const [proceedObj, setProceedObj] = useState({btnText: 'guardar datos', action: 'create'});
    const [responsibles, setResponsibles] = useState([]);
    const [errorFields, setErrorFields] = useState({}); // the error field obj (to tell the field if there's an error)
    const [error, setError] = useState(false); // general error variable. Show if there's a field with an error.

    const clearBotFields = () => {
        let newFieldObj = {...fields};
        if(fields.isBot) {
            Object.keys(fields).forEach(key => {
                if(!requiredFields.includes(key)) {
                    return newFieldObj[key] = '';
                }
                return;
            });
            setField({...newFieldObj});
        }
    }
    const clearAllFields = () => {
        setField({...defaultFieldsValue});
        setErrorFields({...{}});
        setError(false);
        setProceedObj({...{btnText: 'guardar datos', action: 'create'}});
    }
    // get all responsibles
    const getResponsibles = () => {
        props.setLoading(true);
        const endpoint = fetchResponsibleAnalysts(props.token);
        endpoint.then(response => {
            setResponsibles(response.data);
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    const createResponsible = () => {
        props.setLoading(true);
        const endpoint = createResponsibleAnalyst(props.token, fields);
        endpoint.then(() => {
            getResponsibles();
            props.setLoading(false);
            props.handleConfirm('success-dialog');
            clearAllFields();
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    const updateResponsible = () => {
        const uniqueId = proceedObj.row.uniqueId;
        props.setLoading(true);
        const endpoint = updateResponsibleAnalyst(props.token, uniqueId, fields);
        endpoint.then(() => {
            getResponsibles();
            props.setLoading(false);
            props.handleConfirm('success-dialog');
            clearAllFields();
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    const deleteResponsible = (row) => {
        const uniqueId = row.uniqueId;
        props.setLoading(true);
        const endpoint = deleteResponsibleAnalyst(props.token, uniqueId);
        endpoint.then(() => {
            getResponsibles();
            props.setLoading(false);
            props.handleToggleSimpleDialog(undefined);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }

    const validateRequiredFields = (type, fieldsToCheck, value) => { // validate fields errors
        let errorVal; // current field err variable
        let newErrorState = {...errorFields}; // new error obj to
        const fieldCheck = (key, value) => {
            switch(key) { //check specific field when typing
                case 'fullName':
                    errorVal = !Boolean(minLengthRegx.test(String(value).toLowerCase()));
                    break;
                case 'email':
                    if(value) {
                        errorVal = !Boolean(emailRegx.test(String(value).toLowerCase()));
                    }
                    break;
                // case 'ipAddress':
                //     if(fields.isBot) {
                //         errorVal = !Boolean(ipMinLength.test(String(value).toLowerCase()));
                //     }
                //     break;
                default:
                    if(fields.isBot) {
                        errorVal = !Boolean(minLengthRegx.test(String(value).toLowerCase()));
                    }
            }
            newErrorState[key] = errorVal;
        }
        if(type === 'all') {
            requiredFields.forEach(key => {
                return fieldCheck(key, fieldsToCheck[key]);
            });
        }else if(type === 'single') {
            fieldCheck(fieldsToCheck, value);
        }
        const hasErr = Object.keys(newErrorState).find(item => newErrorState[item]);
        setError(Boolean(hasErr));
        setErrorFields({...newErrorState});
        return Boolean(hasErr);
    }

    const handleFieldChange = (field, event) => {
        let newVal = field === 'isBot' ? !Boolean(fields.isBot) : event.target ? event.target.value : event.value;
        if(field === 'isBot') {
            clearBotFields();
        }else {
            validateRequiredFields('single', field, newVal);
        }
        newVal = (field === 'ipAddress' && event.formattedValue) || newVal; // set ip address as unformatted value to validate its length
        setField(prevState=>({ ...prevState, [field]: newVal})); //this way the state of the object property updates.
    }
    const handleSaveBtn = () => {
        if(validateRequiredFields('all', fields)) { return } // check if there's a field with and error.
        switch(proceedObj.action) {
            case 'create':
                createResponsible();
                break;
            case 'update':
                updateResponsible();
                break;
            default:
                break;
        }
    }
    const handleEditRow = (row) => {
        let editData = {};
        Object.keys(row).forEach(item => {
            editData[item] = row[item];
        });
        setField({...editData});
        setProceedObj({...{btnText: 'actualizar datos', action: 'update', row: row}})
        validateRequiredFields('all', editData);
    }
    const handleDeleteRow = (row) => {
        deleteResponsible(row);
    }

    useEffect(() => {
        getResponsibles();
    }, []);

    return(
        <Dialog

            fullWidth
            maxWidth="lg"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Gestión de gestores de cobro"
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />
            <DialogContent style={{margin:'2% 0'}}>
                {/* <Grid container justifyContent="space-between" alignItems="flex-end">
                    <Typography variant="h6" style={{color: '#424242', fontWeight:'bold', marginBottom: 20}}>
                        Datos del responsable
                    </Typography>
                </Grid>
                <Divider style={{marginBottom: 20}}/> */}
                <form>
                    <Grid container direction="row" spacing={2} style={{padding:'0 1%'}}>
                        <TextFieldComponent
                            xs={6}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Nombre (*)"
                            placeholder="Nombre completo"
                            fieldID="text-field-name-manage-responsible"
                            fieldClass="fields"
                            fieldVariant="outlined"
                            margin='dense'
                            fullWidth
                            value={fields.fullName ? fields.fullName : ''}
                            onChange={handleFieldChange.bind(this, 'fullName')}
                            error={errorFields.fullName}
                            helperText={errorFields.fullName ? "Introduzca mínimo 3 caracteres." : null}
                        />
                        <TextFieldComponent
                            xs={6}
                            typeVariant="subtitle1"
                            typeClass={"field-label"}
                            fieldLabel="Email (*)"
                            placeholder="Ej: mi_correo@dominio.com"
                            fieldID="text-field-email-manage-responsible"
                            fieldClass="fields"
                            fieldVariant="outlined"
                            margin='dense'
                            fullWidth
                            value={fields.email ? fields.email : ''}
                            onChange={handleFieldChange.bind(this, 'email')}
                            error={errorFields.email}
                            helperText={errorFields.email ? "Introduzca un correo válido (ej: mail@domain.com)." : null}
                        />
                    </Grid>
                    {/* <Grid container direction="row" style={{padding:'1%'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={fields.isBot ? fields.isBot : false}
                                    onChange={handleFieldChange.bind(this, 'isBot')}
                                    color="primary"
                                    style={{color: '#43A047'}}
                                />
                            }
                            label="ES UN BOT"
                        />
                    </Grid> */}
                    {/* <Divider style={{marginBottom: 20}}/> */}
                    {/* <div
                        style={
                            !fields.isBot ?
                                {backgroundColor:'#fafafa', borderRadius:3, border:'1px dashed #fafafa', padding:'.5% 1%'} :
                                {padding:'.5% 1%'}
                            }
                    >
                        <Grid container direction="row" spacing={2}>
                            <NumericTextField
                                xs={4}
                                typeVariant="subtitle1"
                                typeClass={!fields.isBot ? "field-label disable-label" : "field-label"}
                                fieldLabel="IP"
                                placeholder="Ej: 127.0.0.1"
                                fieldID="text-field-manage-responsible-ip"
                                fieldClass="fields diabled-field"
                                fieldVariant="outlined"
                                margin='dense'
                                fullWidth
                                format={'###.###.###.###'}
                                mask={'_'}
                                allowEmptyFormatting={true}
                                value={fields.ipAddress ? fields.ipAddress : ''}
                                onChange={handleFieldChange.bind(this, 'ipAddress')}
                                disabled={!fields.isBot}
                                error={errorFields.ipAddress && fields.isBot}
                                helperText={errorFields.ipAddress && fields.isBot ? "Introduzca una dirección ip válida." : null}
                            />
                            <TextFieldComponent
                                xs={4}
                                typeVariant="subtitle1"
                                typeClass={!fields.isBot ? "field-label disable-label" : "field-label"}
                                fieldLabel="Usuario"
                                placeholder="Nombre de usuario"
                                fieldID="text-field-manage-responsible-username"
                                fieldClass="fields diabled-field"
                                fieldVariant="outlined"
                                margin='dense'
                                fullWidth
                                value={fields.user ? fields.user : ''}
                                onChange={handleFieldChange.bind(this, 'user')}
                                disabled={!fields.isBot}
                                error={errorFields.user && fields.isBot}
                                helperText={errorFields.user && fields.isBot ? "Introduzca mínimo 3 caracteres." : null}
                            />
                            <TextFieldComponent
                                xs={4}
                                typeVariant="subtitle1"
                                typeClass={!fields.isBot ? "field-label disable-label" : "field-label"}
                                fieldLabel="Contraseña"
                                placeholder="Contraseña del usuario"
                                fieldID="text-field-manage-responsible-password"
                                fieldClass="fields diabled-field"
                                fieldVariant="outlined"
                                margin='dense'
                                type="password"
                                fullWidth
                                value={fields.password ? fields.password : ''}
                                onChange={handleFieldChange.bind(this, 'password')}
                                disabled={!fields.isBot}
                                error={errorFields.password && fields.isBot}
                                helperText={errorFields.password && fields.isBot ? "Introduzca mínimo 3 caracteres." : null}
                            />
                        </Grid>
                    </div> */}

                    <Grid container justifyContent="flex-end" style={{marginTop:'3%', paddingRight: '1%'}}>
                        {
                            proceedObj.action === 'update' ?
                                <Button
                                    variant="contained"
                                    style={{marginRight:20}}
                                    className={"action-method-btn cancel"}
                                    disableElevation
                                    size="small"
                                    onClick={clearAllFields}
                                >
                                    cancelar
                                </Button> : null
                        }
                        <Button
                            variant="contained"
                            className={error ? "action-method-btn disabled" : "action-method-btn save"}
                            disableElevation
                            size="small"
                            onClick={handleSaveBtn}
                            disabled={error}
                        >
                            {proceedObj.btnText}
                        </Button>
                    </Grid>
                </form>

                <Grid style={{marginTop:30}}>
                    <ResponsibleTable
                        data={responsibles}
                        handleEditRow={handleEditRow}
                        handleDeleteRow={handleDeleteRow}
                        handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                        setResponsibles={setResponsibles}
                    />
                </Grid>
            </DialogContent>
            <DialogFooter />
        </Dialog>
    );
}

function CustomOrderDialog(props) {

    const [detail, setDetail] = useState({state: false, uniqueId: false, orderExpiries: {}});
    const [specificOrders, setSpecificOrders] = useState([]);
    const handleShowDetail = (specificOrder) => {
        setDetail({ ...{
            state: !detail.state,
            uniqueId: specificOrder && specificOrder.uniqueId,
            // orderExpiries: specificOrder && specificOrder.expirationDetail,
            orderExpiries: specificOrder && specificOrder.expirations,
            orderTitle: specificOrder && specificOrder.name,
        }});
    }
    const handleGoBack = (place) => {
        setDetail({...{state: false, order: {}}});
        props.handleConfirm(undefined);
        if(place === 'fromDetail') {
            getSpecificOrders();
        }
    }

    const getSpecificOrders = () => {
        props.setLoading(true);
        const endpoint = fetchSpecificOrders(props.token);
        endpoint.then(response => {
            setSpecificOrders(response.data);
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }

    const deleteSingleSpecificOrder = (row) => {
        props.setLoading(true);
        if (row.isDeployed === true){
            const endpoint = deletePods(props.token, row.uniqueId);
            endpoint.then(() => {
                deleteSpecificOrder(props.token, row.uniqueId)
                    .then(() => {
                        props.handleConfirm('success-dialog');
                        getSpecificOrders();
                        props.setLoading(false);
                });
            }).catch(errors => {
                props.setLoading(false);
                errorHandling(errors, props.handleConfirm);
            });
        }else{
            const endpoint = deleteSpecificOrder(props.token, row.uniqueId)
            endpoint.then(() => {
                props.handleConfirm('success-dialog');
                getSpecificOrders();
                props.setLoading(false);
            }).catch(errors => {
                props.setLoading(false);
                errorHandling(errors, props.handleConfirm);
            });
        }
    }

    const confirmLeave = (callback) => {
        props.handleConfirm('confirm-leave',
        '¿Seguro que desea proceder? Todo progreso será eliminado.', callback);
    }

    useEffect(() => {
        getSpecificOrders();
    }, [])

    return(
        <Dialog

            fullWidth
            maxWidth="xl"
            onClose={
                detail.state ?
                confirmLeave.bind(this, props.handleSecondaryDialog.bind(this, undefined))
                : props.handleSecondaryDialog.bind(this, undefined)
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Gestión de ordenes personalizados"
                handleClick={
                    detail.state ?
                    confirmLeave.bind(this, props.handleSecondaryDialog.bind(this, undefined))
                    : props.handleSecondaryDialog.bind(this, undefined)
                }
                action={handleShowDetail.bind(this, null)}
                goBack={detail.state ? confirmLeave.bind(this, handleGoBack) : null}
            />

            <DialogContent style={{margin:'2% 0'}}>
                {
                    detail.state ?
                    <CustomOrderDetail
                        orderExpiries={detail.orderExpiries}
                        specificOrder={detail.uniqueId}
                        specificOrderTitle={detail.orderTitle}
                        handleGoBack={handleGoBack}
                        getSpecificOrders={getSpecificOrders}
                        handleToggleDialog={props.handleToggleDialog}
                        handleSecondaryDialog={props.handleSecondaryDialog}
                        handleConfirm={props.handleConfirm}
                        showDetail={handleShowDetail.bind(this, null)}
                        token={props.token}
                        setLoading={props.setLoading}
                    />
                    :
                    <div>
                        <Grid container justifyContent="flex-end" style={{marginBottom:'1rem'}}>
                            <Button
                                variant="contained"
                                className={"action-method-btn"}
                                disableElevation
                                onClick={handleShowDetail.bind(this, null)}
                                size="small"
                            >
                                crear nuevo orden
                                <Add style={{fontSize:18, color:'#fff', marginLeft:'.2rem'}} />
                            </Button>
                        </Grid>
                        <CustomOrdersTable
                            data={specificOrders}
                            showDetail={handleShowDetail}
                            handleDelete={deleteSingleSpecificOrder}
                            handleConfirm={props.handleConfirm}
                            token={props.token}
                        />
                    </div>
                }
            </DialogContent>

            <DialogFooter />
        </Dialog>
    )

}

function CustomOrderDetail(props) {

    const fieldsToHide = {
        custom: true,
        group: true,
        client: true,
    }
    const defaultChargeConfig = {
        irregularAmount: "0",
        matchPayroll: true,
        clientAccounts: {},
        captcha: false,
        controlAmountNotice: "0",
        controlAmountCharge: "0",
        botAction: "",
        amountPerAccount: null,
    };
    const limitRequest = {limit: 10};
    const [expiries, setExpiries] = useState([]);
    const [selected, setSelected] = useState([]);
    const [orderExpiries, setOrderExpiries] = useState([]);
    const [error, setError] = useState(false);
    const [specificOrderTitle, setSpecificOrderTitle] = useState('');
    const [paymentSettingsToDelete, setPaymentSettingsToDelete] = useState([]);

    // const handleSpecificOrderTitle = (event) => {
    //     setSpecificOrderTitle(event.target.value);
    // }

    const setExpiriesObj = (expiries) => {
        return expiries.map((item) => {
            const financialObj = item.clientDetail.financialProfileObj;
            return {
                uniqueId: item.uniqueId,
                order: item.order ? item.order : 0,
                responsible_name: financialObj ? financialObj.assignee : '--',
                work: item.clientDetail.workProfileInstitution,
                bank: item.clientDetail.financialProfileInstitution,
                bankProfile: financialObj ? financialObj.uniqueId : null,
                client: item.clientDetail.fullName,
                client_uniqueId: item.clientDetail.uniqueId,
                bank_balance: item.accountBalance || '$0.00',
                assignee: financialObj ? financialObj.assignee : '--',
                due_date: item.expirationDate,
                rating: financialObj ? financialObj.rating : '--',
                overdue_amount: item.amountReceivable,
                charged_amount: item.amountCharged,
                expiration_state: item.expirationStatus,
                remaining_amount: (item.amountReceivable - item.amountCharged),
                charge_config: null,
            }
        });
    }
    const setPaymentSettingObj = (setting) => {
        const destinies = setting.amountPerAccount.length ? setting.amountPerAccount : [setting.amountPerAccount];
        return {
            irregularAmount: setting.irregularAmount,
            clientAccounts: setting.chargingAccount,
            destinyAccounts: destinies,
            matchPayroll: setting.matchPayroll,
            captcha: setting.manualFillCaptcha,
            controlAmountNotice: setting.noticeControlAmount,
            controlAmountCharge: setting.chargeControlAmount,
            expiration: setting.expiration,
            bank: setting.bank,
            order: setting.orderNumber,
            botAction: setting.doNotCharge ? 'no_charge' : setting.doNotCheck ? 'no_check' : '',
            checkChargingAccounts: setting.checkChargingAccounts,
            uniqueId: setting.uniqueId,
        }
    }
    const getExpiries = (data) => {
        props.setLoading(true);
        const endpoint = fetchExpiries({...data, charging_method: 'internet banking', token: props.token});
        endpoint.then(response => {
            let cleanData =
            response.data.filter(item =>
                item.clientDetail.financialProfileObj && item.clientDetail.workProfileInstitution &&
                ['Late', 'Programmed', 'Partially paid'].includes(item.expirationStatus)
            );
            cleanData = setExpiriesObj(cleanData);
            setExpiries(cleanData);
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    const getMultiExpiries = (expirations, formattedSetting) => {
        props.setLoading(true);
        const endpoints = expirations.map(item => fetchExpiry(props.token, item));
        axios.all(endpoints).then(axios.spread((...responses) => {
            const data = responses.map(item => item.data)
            .filter(item => item.clientDetail.financialProfileObj && item.clientDetail.workProfileInstitution)
            const cleanData = setExpiriesObj(data);
            const addPaymentSetting = cleanData.map(item => {
                return {
                    ...item,
                    charge_config: formattedSetting.find(cc => cc.expiration === item.uniqueId),
                    order: formattedSetting.find(cc => cc.expiration === item.uniqueId).order,
                    bank: formattedSetting.find(cc => cc.expiration === item.uniqueId).bank,
                    specificOrder: props.specificOrder,
                }
            }).sort((a,b) => (a.order > b.order) ? 1 : -1);
            setOrderExpiries(addPaymentSetting);
            props.setLoading(false);
        })).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }

    const createSingleSpecificOrder = (data, updatedExpiries) => {
        props.setLoading(true);
        const endpoint = createSpecificOrder(props.token, data);
        endpoint.then(response => {
            const specificOrder = response.data.uniqueId;
            createAllPaymentSettings(specificOrder, updatedExpiries);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    const createAllPaymentSettings = (specificOrder, newExpiries) => {
        const theExpiries = newExpiries ? newExpiries : orderExpiries;
        const endpoints = theExpiries.map(item => createPaymentSetting(props.token, {
            "specificOrder": specificOrder,
            "expiration": item.uniqueId,
            "bankProfile": item.bankProfile || null,
            "chargingAccount": item.charge_config.clientAccounts.uniqueId || null,
            "orderNumber": Number(item.order),
            "irregularAmount": Number(item.charge_config.irregularAmount),
            "matchPayroll": item.charge_config.matchPayroll,
            "manualFillCaptcha": item.charge_config.captcha,
            "noticeControlAmount": Number(item.charge_config.controlAmountNotice),
            "chargeControlAmount": Number(item.charge_config.controlAmountCharge),
            "doNotCheck": (item.charge_config.botAction === 'no_check' && true) || false,
            "doNotCharge": (item.charge_config.botAction === 'no_charge' && true) || false,
            "checkChargingAccounts": item.charge_config.checkChargingAccounts,
            "amountPerAccount": item.charge_config.amountPerAccount,
        }));
        axios.all(endpoints).then(axios.spread((...responses) => {
            props.handleConfirm('success-dialog');
            props.handleGoBack('fromDetail');
            props.setLoading(false);
        })).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    const modifyAllPaymentSettings = (toUpdateExpiries, newExpiries) => {
        if(toUpdateExpiries.length === 0) {
            createAllPaymentSettings(props.specificOrder, newExpiries);
            return;
        }
        const endpoints = toUpdateExpiries.map(item => updatePaymentSetting(props.token, {
            "specificOrder": props.specificOrder,
            "expiration": item.uniqueId,
            "bankProfile": item.bankProfile,
            "chargingAccount": !item.charge_config.clientAccounts.uniqueId ? item.charge_config.clientAccounts : item.charge_config.clientAccounts.uniqueId,
            "orderNumber": Number(item.order),
            "irregularAmount": Number(item.charge_config.irregularAmount),
            "matchPayroll": item.charge_config.matchPayroll,
            "manualFillCaptcha": item.charge_config.captcha,
            "noticeControlAmount": Number(item.charge_config.controlAmountNotice),
            "chargeControlAmount": Number(item.charge_config.controlAmountCharge),
            "doNotCheck": item.charge_config.botAction === 'no_check' && true,
            "doNotCharge": item.charge_config.botAction === 'no_charge' && true,
            "amountPerAccount": !item.charge_config.amountPerAccount ? item.charge_config.destinyAccounts : item.charge_config.amountPerAccount,
            "checkChargingAccounts": item.charge_config.checkChargingAccounts,
            "uniqueId": item.charge_config.uniqueId,
        }));
        axios.all(endpoints).then(axios.spread((...responses) => {
            if(newExpiries.length > 0) {
                createAllPaymentSettings(props.specificOrder, newExpiries);
            }else{
                props.handleConfirm('success-dialog');
                props.handleGoBack('fromDetail');
            }
            props.setLoading(false);
        })).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }

    const getAllPaymentSettings = (data) => {
        const params = {
            token: props.token,
            specificOrder: props.specificOrder,
        }
        const endpoints = fetchPaymentSettings(params);
        endpoints.then(response => {
            const formattedSetting = response.data.map(item => {
                return setPaymentSettingObj(item);
            });
            const expirations = formattedSetting.map(item => item.expiration);
            getMultiExpiries(expirations, formattedSetting);
            // return;
            // const addPaymentSetting = data.map(item => {
            //     return {
            //         ...item,
            //         charge_config: formattedSetting.find(cc => cc.expiration === item.uniqueId),
            //         order: formattedSetting.find(cc => cc.expiration === item.uniqueId).order,
            //         specificOrder: props.specificOrder,
            //     }
            // }).sort((a,b) => (a.order > b.order) ? 1 : -1);
            // setOrderExpiries(addPaymentSetting);
            // props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    const deleteSinglePaymentSetting = () => {
        props.setLoading(true);
        const endpoints = paymentSettingsToDelete.map(item => deletePaymentSetting(props.token, item));
        axios.all(endpoints).then(() => {
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }

    const applyFilters = (fields) => {
        let data = fields;
        if(Object.keys(fields).length === 0){
            data = limitRequest;
        }
        getExpiries(data);
    }

    const handleAddExpiryOrder = () => {
        let maxOrder = Math.max.apply(Math, orderExpiries.length > 0 ? orderExpiries.map(item => {return item.order}) : [0]);
        let orderCounter = maxOrder ? maxOrder : 0;
        const currentExpiries = orderExpiries.map(item => item.uniqueId);
        let newExpiries = selected.filter(item => !currentExpiries.includes(item.uniqueId));


        newExpiries = newExpiries.map(item => {
            orderCounter = orderCounter + 1;
            return {
                ...item,
                order: orderCounter
            }
        });
        setOrderExpiries(orderExpiries.concat(newExpiries));
    }

    const removeExpiry = (expiry) => {
        if(expiry.charge_config) {
            const toDelete = paymentSettingsToDelete;
            setPaymentSettingsToDelete(toDelete.concat(expiry.charge_config.uniqueId));
        }
        const newExpiries = orderExpiries.filter(item => item.uniqueId !== expiry.uniqueId);
        setOrderExpiries(newExpiries);
    }

    const validateDuplicatesOrders = () => {
        const allOrders = [];
        const duplicates = [];
        orderExpiries.forEach(item => {
            const eOrder = String(item.order);
            allOrders.includes(eOrder) ?
                duplicates.push(eOrder) : allOrders.push(eOrder);
        });
        if(duplicates.length > 0) {
            const errData = orderExpiries.map(item => {
                if(duplicates.includes(item.order)) {
                    item.error = true;
                }else {
                    item.error = false;
                }
                return item;
            });
            setError(true);
            setOrderExpiries(errData);
            return true;
        }else {
            const removeErr = orderExpiries.map(item => {
                item.error = false;
                return item;
            });
            setError(false);
            setOrderExpiries(removeErr);
            return false;
        }
        // save the order.
    }

    const handleSaveOrder = () => {
        if(validateDuplicatesOrders()) {
            props.handleConfirm('simple-dialog', 'Hay errores en los ordenes.');
            return;
        };
        if(orderExpiries.length === 0) {
            props.handleConfirm('simple-dialog', 'Por favor agregue algún vencimiento.');
            return;
        }
        // if(!specificOrderTitle) {
        //     props.handleConfirm('simple-dialog', 'Por favor introduzca un nombre al orden personalizado.');
        //     return;
        // }
        if(paymentSettingsToDelete.length > 0) {
            deleteSinglePaymentSetting();
        }
        let updatedExpiries = orderExpiries.map(item => {
            if(!item.charge_config) {
                return {
                    ...item,
                    charge_config: defaultChargeConfig,
                }
            }else {
                return item;
            }
        }).sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0) );
        // updatedExpiries = updatedExpiries.map((item, index) => {
        //     return {
        //         ...item,
        //         order: Number(item.order),
        //     }
        // });
        setOrderExpiries(updatedExpiries);
        if(props.orderExpiries && props.specificOrder) {
            const toUpdateExpiries = updatedExpiries.filter(item => {
                if(item.charge_config.uniqueId) {
                    return item;
                }
                return false;
            });
            const newExpiries = updatedExpiries.filter(item => {
                if(!item.charge_config.uniqueId) {
                    return item;
                }
                return false;
            });
            modifyAllPaymentSettings(toUpdateExpiries, newExpiries);
            return
            // const newExpiries =
            // get expiries with no uniqueid on payment setting;
            // update specific order title
            // create payments settings
        }
        // first create the specific order and store the uniqueId;
        const orderContent = {
            expirations: {},
        }
        createSingleSpecificOrder(orderContent, updatedExpiries);
    }

    useEffect(() => {
        if(props.orderExpiries && props.specificOrder) {
            setSpecificOrderTitle(props.specificOrderTitle);
            // const cleanData = setExpiriesObj(props.orderExpiries);
            // getAllPaymentSettings(cleanData);
            getAllPaymentSettings();
        }
    }, []);

    return(
        <div>
            {
                (props.orderExpiries && props.specificOrder) &&
                <Card style={{marginBottom: '1rem', width:'35rem'}}>
                    <CardContent>
                        <Typography
                            variant="subtitle1"
                            style={{color:'#424242'}}
                        >
                            <div style={{display:'table'}}>{specificOrderTitle}</div>
                            <Typography
                                variant="overline"
                                style={{color:'#616161', fontSize:'11px'}}
                            >ORDEN PERSONALIZADO</Typography>
                        </Typography>
                    </CardContent>
                </Card>
            }
            <ChargingFilters
                applyFilters={applyFilters}
                hideFields={fieldsToHide}
                setLoading={props.setLoading}
                handleConfirm={props.handleConfirm}
                token={props.token}
            />
            <div style={{margin:'1rem 0'}}></div>
            <PlainClientsTable
                title="Vencimientos"
                data={expiries}
                order={'search'}
                selected={selected}
                setSelected={setSelected}
            />
            <Grid style={{width:'100%', padding:'2rem 0'}}>
                <Button
                    variant="contained"
                    style={{backgroundColor: '#43A047', color: '#FFF', fontSize: 12, width:'100%'}}
                    disableElevation
                    size="medium"
                    onClick={handleAddExpiryOrder}
                >
                    <ArrowDownward style={{fontSize:12}}/>
                    <span style={{margin:'0 1rem'}}>agregar al orden</span>
                    <ArrowDownward style={{fontSize:12}}/>
                </Button>
            </Grid>
            <PlainClientsTable
                title="Vencimientos del orden"
                data={orderExpiries}
                order={'customOrder'}
                removeExpiry={removeExpiry}
                handleToggleDialog={props.handleToggleDialog}
                handleSecondaryDialog={props.handleSecondaryDialog}
                handleConfirm={props.handleConfirm}
                error={error}
                setError={setError}
                // handleSaveOrder={saveOrder}
            />
            <Grid container justifyContent="flex-end" alignItems="flex-end" style={{marginTop:'2rem'}}>
                <Grid container item xs={4} justifyContent="flex-end">
                    <Button
                        variant="contained"
                        className={error ? "action-method-btn disabled" : "action-method-btn save"}
                        style={{height:30, marginBottom:5}}
                        disableElevation
                        size="small"
                        onClick={handleSaveOrder}
                        disabled={error}
                    >
                        guardar cambios
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export {
    MassAssignmentDialog, ConfirmDialog, HistoryDialog,
    ExpiryObjectDialog, BotConfigDialog, BotInitConfigDialog,
    FinancialCompositionDialog, FinancialProfileDialog, ManageResponsibleDialog,
    SingleAssingmentDialog, CustomOrderDialog, InfrastructureDialog, ConfirmDialogComponent
}