import * as React from 'react';
import { connect } from 'react-redux';
import { translations } from 'src/utils';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import { Fab } from '@material-ui/core';
import { GridColDef as ColDef, DataGrid } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import BusinessIcon from '@material-ui/icons/Business';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';

import { fetchChartOfAccounts } from '../../../actions';
// import DefaultSection from '../../../components/DefaultSection';
// import Loading from '../../../components/Loading';
// import { fetchChartOfAccounts as fetch } from '../../../utils/axios';
import ChartOfAccountsForm from '../ChartOfAccountsForm';

// import ChartOfAccountSearchSection from '../ChartOfAccountsSearchSection';
// import ChartOfAccountView from '../ChartOfAccountsView';

const styles: any = createStyles({
    addButton: {
        backgroundColor: green[500],
        color: 'white',
    },
    rightSide: {
        alignItems: 'center',
        border: '1px solid transparent',
        boxSizing: 'border-box',
        display: 'block',
        height: '100%',
        justifyContent: 'center',
        marginLeft: 280,
        position: 'relative',
    },
    formContainer: {
        padding: 25,
        display: 'flex',
        height: '60vh',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    formHeader: {
        padding: 10,
        display: 'flex',
    },
    saveButton: {
        backgroundColor: green[500],
        color: 'white',
    },
    actionsSection: {
        padding: 5,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        height: '80%',
        margin: 0,
        padding: 0,
    }
});

interface IChartOfAccountsProps {
    classes: {
        addButton: string;
        rightSide: string;
        formContainer: string;
        formHeader: string;
        saveButton: string;
        actionsSection: string;
        root: string;
    }

    chartOfAccounts: IChartOfAccount[],
    user: IUser
    fetchChartOfAccounts(chartOfAccounts: IChartOfAccount[]): IAction<IChartOfAccount[]>
}

interface IChartOfAccountsState {
    loading: boolean;
    selectedId: string | undefined;
    selectedSection: number;

    showFormModal: boolean;
    chartOfAccounts: any[]; // Array of all account charts
    filterBy: string; // string used to filter the chart of accounts array
    companySelected: string;
}

const columns: ColDef[] = [
    { field: 'id', headerName: 'No.', width: 100, },
    { field: 'account', headerName: translations.ACCOUNT, width: 330 },
    { field: 'group', headerName: translations.GROUP, width: 100 },
];


class ChartOfAccountsSection extends React.Component<IChartOfAccountsProps, IChartOfAccountsState> {
    public state: IChartOfAccountsState = {
        loading: false,
        selectedId: undefined,
        selectedSection: 0,
        showFormModal: false,
        filterBy: '',
        companySelected: 'Inversiones España',
        /* TEST DATA */
        chartOfAccounts: [
            { id: 100000, account: 'Activos',                                        group: 'Activos' },
            { id: 110000, account: 'Activos Corrientes',                             group: 'Activos' },
            { id: 110100, account: 'Efectivo en caja y banco',                       group: 'Activos' },
            { id: 110200, account: 'Cuentas por cobrar clientes',                    group: 'Activos' },
            { id: 110300, account: 'Otras cuentas por cobrar',                       group: 'Activos' },
            { id: 110400, account: 'Inventario',                                     group: 'Activos' },
            { id: 110600, account: 'Gastos pagados por adelantado',                  group: 'Activos' },
            { id: 110700, account: 'Otros activos corrientes',                       group: 'Activos' },
            { id: 110800, account: 'Dividendos a cuentas entregado en el ejercicio', group: 'Activos' },
            { id: 120000, account: 'ACTIVOS FIJOS',                                  group: 'Activos' },
            { id: 130100, account: 'Inversiones en depositos',                       group: 'Activos' },
            { id: 130200, account: 'Inversiones en acciones',                        group: 'Activos' },
            { id: 130300, account: 'Otras inversiones',                              group: 'Activos' },
            { id: 140000, account: 'Otros activos no amortizables',                  group: 'Activos' },
            { id: 140100, account: 'Impuesto / Renta diferido o anticipado',         group: 'Activos' },
            { id: 140200, account: 'Otros activos amortizables',                     group: 'Activos' },
            { id: 150000, account: 'Provisiones para riesgos y gastos',              group: 'Activos' },
            { id: 150100, account: 'Para cuentas incobrables',                       group: 'Activos' },
            { id: 150200, account: 'Otras provisiones',                              group: 'Activos' },
        
            { id: 200000, account: 'Pasivos',                  group: 'Pasivos' },
            { id: 210000, account: 'Acreedores a corto plazo', group: 'Pasivos' },
            { id: 210100, account: 'Prestamos',                group: 'Pasivos' },
            { id: 210200, account: 'Cuentas por pagar',        group: 'Pasivos' },
            { id: 210300, account: 'Impuestos por pagar',      group: 'Pasivos' },
            { id: 210400, account: 'Otras cuentas',            group: 'Pasivos' },
            { id: 210500, account: 'Cobros anticipados',       group: 'Pasivos' },
        
            { id: 600000, account: 'Gastos',                                                            group: 'Gastos' },
            { id: 610000, account: 'Gastos de personal',                                                group: 'Gastos' },
            { id: 610100, account: 'Sueldos y salarios',                                                group: 'Gastos' },
            { id: 610200, account: 'Retribuciones complementarias',                                     group: 'Gastos' },
            { id: 610300, account: 'Seguros',                                                           group: 'Gastos' },
            { id: 610400, account: 'otros seguros',                                                     group: 'Gastos' },
            { id: 610500, account: 'Aporte Seguridad social',                                           group: 'Gastos' },
            { id: 610600, account: 'Aporte infotep',                                                    group: 'Gastos' },
            { id: 620000, account: 'Gastos por trabajos, suministros y servicios',                      group: 'Gastos' },
            { id: 620100, account: 'Honorarios profesionales(pers.fisicas)',                            group: 'Gastos' },
            { id: 620200, account: 'Honorarios profesionales(pers.Morales)',                            group: 'Gastos' },
            { id: 620300, account: 'Honorarios por servicios del exterior',                             group: 'Gastos' },
            { id: 620400, account: 'Honorarios por servicios del exterior(personas fisicas y morales)', group: 'Gastos' },
            { id: 620500, account: 'Seguridad, mensaj., trans.Y otros serv. (pers.fisica)',             group: 'Gastos' },
            { id: 620600, account: 'Seguridad, mensaj., trans.Y otros serv. (pers.Morales)',            group: 'Gastos' },
            { id: 620700, account: 'Otros gastos por trabajos, suministros y servicios',                group: 'Gastos' },
        ],
    }
    constructor(props: IChartOfAccountsProps) {
        super(props);

        this.filterChartAccountsByGroup = this.filterChartAccountsByGroup.bind(this);
        this.changeFilterBy = this.changeFilterBy.bind(this);
        this.toogleModal = this.toogleModal.bind(this);
        this.changeSelectedCompany = this.changeSelectedCompany.bind(this);
        // this.handleClickItem = this.handleClickItem.bind(this);
        // this.handleAddClick = this.handleAddClick.bind(this);
        // this.handleChangeSection = this.handleChangeSection.bind(this);
        // this.advanceSearch = this.advanceSearch.bind(this);
        // this.fetchData = this.fetchData.bind(this);
    }
    // public componentDidMount() {
    //     this.setState({ loading: true });
    //     fetch({ token: this.props.user.token, limit: 20, offset: 0 }).then(res => {
    //         this.props.fetchChartOfAccounts(res.data.results as IChartOfAccount[]);
    //         this.setState({ loading: false });
    //     }).catch(err => {
    //         catchError(err, this);
    //     });
    // }
    public filterChartAccountsByGroup() {
        if (this.state.filterBy) {
            return this.state.chartOfAccounts.filter(c => c.group.toLowerCase() === this.state.filterBy.toLowerCase());
        }
        return this.state.chartOfAccounts;
    }

    public changeFilterBy(keyword: string) {
        return () => {
            this.setState({filterBy: keyword});
        }
    }

    public changeSelectedCompany(keyword: string) {
        return () => {
            this.setState({ companySelected: keyword });
        }
    }

    public toogleModal() {
        this.setState({
            showFormModal: !this.state.showFormModal
        })
    }
    // public handleChangeSection(sectionNumber: number) {
    //     this.setState({ selectedSection: sectionNumber });
    // }
    // public handleClickItem(id: string) {

    //     this.setState({
    //         selectedId: id,
    //         selectedSection: 1
    //     }, () => {
    //         this.setState({ selectedSection: 2 });
    //     });
    // }

    // public handleAddClick() {
    //     this.setState({
    //         selectedSection: 3
    //     });
    // }
    // public advanceSearch(params: IChartOfAccountParams) {
    //     this.setState({ loading: true });
    //     fetch(params)
    //         .then(res => {
    //             this.props.fetchChartOfAccounts(res.data as IChartOfAccount[]);
    //             this.setState({ loading: false });
    //         }).catch(err => {
    //             catchError(err, this);
    //         });
    // }
    // public fetchData(params: IChartOfAccountParams) {
    //     this.setState({ loading: true });
    //     fetch(params)
    //         .then(res => {
    //             this.props.fetchChartOfAccounts(res.data.results as IChartOfAccount[])
    //             this.setState({ loading: false });
    //         }).catch(err => {
    //             catchError(err, this);
    //         });
    // }
    public render() {
        const { classes } = this.props;
        const { filterBy, showFormModal, companySelected } = this.state;
        // const { loading, selectedId, selectedSection } = this.state;
        return (
            <div className={classes.root}>
                <List
                    style={{ width: '20vw'}}
                    component="nav">
                    <ListSubheader component="div">
                        Compañia
                    </ListSubheader>
                    {['Inversiones España', 'CoopBarcelona'].map(
                        (company: string, indx:number) =>
                            <ListItem button={true} key={indx} selected={companySelected === company} onClick={this.changeSelectedCompany(company)}>
                                <ListItemIcon>
                                    <BusinessIcon />
                                </ListItemIcon>
                                <ListItemText>{company}</ListItemText>
                            </ListItem>
                    )}
                    <ListSubheader component="div">
                        Filtrar por estado
                    </ListSubheader>
                    {['Activos', 'Pasivos', 'Capital', 'Ingresos', 'Gastos'].map(
                        (item: string, indx: number) =>
                            <ListItem button={true} key={indx} selected={filterBy === item} onClick={this.changeFilterBy(item)}>
                                <ListItemIcon>
                                    <FolderOpenIcon />
                                </ListItemIcon>
                                <ListItemText>{item}</ListItemText>
                            </ListItem>
                    )}
                    <Divider/>
                    <ListItem button={true} selected={filterBy === ''} onClick={this.changeFilterBy('')}>
                        <ListItemIcon>
                            <VerticalSplitIcon />
                        </ListItemIcon>
                        <ListItemText>Mostrar todas</ListItemText>
                    </ListItem>
                </List>
                <div style={{ width: '-webkit-fill-available' }}>
                    <div className={classes.actionsSection}>
                        <TextField style={{marginRight: 8}} label="Buscar" variant="outlined" name="search" margin="dense"/>
                        <Button
                            className={classes.addButton}
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={this.toogleModal}>
                            Agregar
                        </Button>
                    </div>
                    <DataGrid rows={this.filterChartAccountsByGroup()} columns={columns} pageSize={10} />
                </div>
                
                <Dialog open={showFormModal} onClose={this.toogleModal}>
                    <ChartOfAccountsForm classes={classes}/>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state: IState) {
    return {
        chartOfAccounts: state.chartOfAccounts,
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps, { fetchChartOfAccounts })(ChartOfAccountsSection)
);