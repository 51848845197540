import axios, { AxiosError } from 'axios';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { connect } from 'react-redux';
import FileUploadSelectInput from 'src/containers/FileUpload/FileUploadSelectInput';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import {
  deleteDebitAuthorizationContract, fetchDebitAuthorizationContract,
  updateDebitAuthorizationContract
} from '../../../actions';
import AlertDialog from '../../../components/AlertDialog';
import Loading from '../../../components/Loading';
import moneyInput from '../../../components/TextMaskInputComponents/moneyInput';
import {
  catchError, debtorsValidation, resetError, successHandler,
  titleCaseHandler, translations
} from '../../../utils';
import {
  API_KEY, deleteDebitAuthorizationContract as deleteDA,
  fetchDebitAuthorizationContract as fetchDA, updateDebitAuthorizationContract as updateDA
} from '../../../utils/axios';
import CheckSelectInput from '../../Check/CheckSelectInput';
import CreditCardSelectInput from '../../CreditCard/CreditCardSelectInput';
import FlierSelectInput from '../../Flier/FlierSelectInput';
import LoanContractSelectInput from '../../LoanContract/LoanContractSelectInput';

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "50%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface IViewProps {
  debitAuthorizationContracts: IDebitAuthorizationContract[];
  debitAuthorizationContractId: string;
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    deleteButton: string;
    errorInput: string;
    formControl: string;
    greenButton: string;
    half: string;
    paper: string;
    root: string;
    textField: string;
  };
  user: IUser;
  changeSection(sectionNumber: number): void;
  deleteDebitAuthorizationContract(
    uniqueId: string
  ): IAction<IDebitAuthorizationContract[]>;
  fetchDebitAuthorizationContract(
    debitAuthorizationContract: IDebitAuthorizationContract
  ): IAction<IDebitAuthorizationContract[]>;
  updateDebitAuthorizationContract(
    debitAuthorizationContract: IDebitAuthorizationContract
  ): IAction<IDebitAuthorizationContract[]>;
}
interface IViewState {
  [key: string]:
  | string
  | number
  | boolean
  | string[]
  | IBankAccount[]
  | undefined;

  DA: string;
  cheque: string[];
  chequeError: string;
  contractDate: string;
  contractDateError: string;
  contractFee: number;
  contractFeeCurrency: string;
  contractFeeCurrencyError: string;
  contractFeeError: string;
  creditCard: string[];
  creditCardError: string;
  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  flier: string[];
  flierError: string;
  loading: boolean;
  loanContract: string[];
  loanContractError: string;
  title: string;
  titleError: string;
  status: string;
  statusError: string;
  transferCode: string;
  transferCodeError: string;
  uniqueId: string;
  withdrawalDay: string;
  withdrawalDayError: string;
}
class View extends React.Component<IViewProps, IViewState> {
  public state: IViewState = {
    DA: "",
    cheque: [],
    chequeError: "",
    contractDate: "",
    contractDateError: "",
    contractFee: 0,
    contractFeeCurrency: "DOP",
    contractFeeCurrencyError: "",
    contractFeeError: "",
    creditCard: [],
    creditCardError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    flier: [],
    flierError: "",
    loading: false,
    loanContract: [],
    loanContractError: "",
    title: "",
    titleError: "",
    status: "pending_signature",
    statusError: "",
    transferCode: "",
    transferCodeError: "",
    uniqueId: "",
    withdrawalDay: "",
    withdrawalDayError: "",
  };
  constructor(props: IViewProps) {
    super(props);

    this.handleAddLoanContract = this.handleAddLoanContract.bind(this);
    this.handleDeleteLoanContract = this.handleDeleteLoanContract.bind(this);

    this.handleAddCreditCard = this.handleAddCreditCard.bind(this);
    this.handleDeleteCreditCard = this.handleDeleteCreditCard.bind(this);

    this.handleAddFlier = this.handleAddFlier.bind(this);
    this.handleDeleteFlier = this.handleDeleteFlier.bind(this);

    this.handleAddCheque = this.handleAddCheque.bind(this);
    this.handleDeleteCheque = this.handleDeleteCheque.bind(this);

    // core events
    this.generateFile = this.generateFile.bind(this);
    this.changeStatus = this.changeStatus.bind(this);

    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }
  public handleAddLoanContract(contract: ILoanContract) {
    this.setState({ loanContract: [contract.uniqueId || ""] });
  }
  public handleDeleteLoanContract(id: string) {
    this.setState({ loanContract: [] });
  }
  public handleAddCreditCard(id: string) {
    this.setState({ creditCard: [id] });
  }
  public handleDeleteCreditCard(id: string) {
    this.setState({ creditCard: [""] });
  }
  public handleAddFlier(id: string) {
    this.setState({ flier: [id] });
  }
  public handleDeleteFlier(id: string) {
    this.setState({ flier: [""] });
  }

  public handleAddCheque(id: string) {
    this.setState({ cheque: [id] });
  }
  public handleDeleteCheque(id: string) {
    this.setState({ cheque: [""] });
  }
  public async generateFile() {
    this.setState({ loading: true });

    if (await debtorsValidation(this, this.props.user.token, this.state.loanContract, true)) {
      axios({
        headers: {
          Authorization: "Bearer " + this.props.user.token,
        },
        method: "post",
        responseType: "blob",
        url: `${API_KEY}/lending/debit-authorization-contracts/${this.state.uniqueId}/download`,
      })
        .then((res) => {
          this.setState({ loading: false });
          saveAs(res.data, `${this.state.title}.docx`);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  public changeStatus(status: string) {
    return () => {
      this.setState({ status });
    };
  }

  public componentDidMount() {
    fetchDA(this.props.user.token, this.props.debitAuthorizationContractId)
      .then((res) => {
        const curContract = res.data as IDebitAuthorizationContract;

        this.setState({
          DA: `lending/debit-authorization-contracts/${curContract.uniqueId}/upload_attachment/`,
          cheque: curContract.cheque ? [curContract.cheque] : [],
          contractDate: curContract.contractDate || "",
          contractFee: curContract.contractFee || 0,
          status: curContract.status || '',
          contractFeeCurrency: curContract.contractFeeCurrency || "",
          creditCard: curContract.creditCard ? [curContract.creditCard] : [],
          dialog: false,
          dialogTitle: "",
          errorMessage: "",
          flier: curContract.flier ? [curContract.flier] : [],
          loading: false,
          loanContract: curContract.loanContract
            ? [curContract.loanContract]
            : [],
          title: curContract.title || "",
          transferCode: curContract.transferCode || "",
          uniqueId: curContract.uniqueId || "",
          withdrawalDay: curContract.withdrawalDay || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    const debitAuthorizationContract: IDebitAuthorizationContract = {
      cheque: state.cheque[0],
      contractDate: state.contractDate,
      contractFee:
        state.contractFee.toString().trim() === "" ? 0 : state.contractFee,
      contractFeeCurrency: state.contractFeeCurrency,
      creditCard: state.creditCard[0],
      flier: state.flier[0],
      loanContract: state.loanContract[0] || "",
      title: titleCaseHandler(state.title),
      transferCode: state.transferCode,
      withdrawalDay: state.withdrawalDay,
      status: this.state.status,
    };

    this.setState({ loading: true });

    updateDA(
      this.props.user.token,
      this.state.uniqueId,
      debitAuthorizationContract
    )
      .then((res) => {
        this.props.updateDebitAuthorizationContract(
          res.data as IDebitAuthorizationContract
        );
        successHandler(res, this, translations.DEBIT_AUTHORIZATION);
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }
  public handleDelete() {
    if (confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteDA(this.props.user.token, this.props.debitAuthorizationContractId)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteDebitAuthorizationContract(
            this.props.debitAuthorizationContractId
          );
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <FileUploadSelectInput
            contractId={this.state.uniqueId}
            url={this.state.DA}
            description="debit-authorization"
          />
          <Typography variant="h3">
            {translations.DEBIT_AUTHORIZATION}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={classes.greenButton}
                onClick={this.generateFile}
                style={{ marginRight: 10, marginTop: 25 }}
              >
                <DocumentIcon />
                &nbsp;
                {translations.GENERATE_DOCUMENT}
              </Button>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="status" shrink={true}>{translations.STATUS}</InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleSelectChange('status')}
                  required={true}
                  inputProps={{
                    id: 'status',
                    name: 'status',
                  }}

                >
                  <MenuItem value="pending_signature">{translations.PENDING_SIGNATURE}</MenuItem>
                  <MenuItem value="completed">{translations.COMPLETED}</MenuItem>
                  <MenuItem value="archived">{translations.ARCHIVED}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label={translations.TITLE}
                className={classes.textField}
                value={titleCaseHandler(this.state.title)}
                onChange={this.handleChange("title")}
                fullWidth={true}
                required={true}
                error={this.state.titleError !== ""}
                helperText={this.state.titleError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.LOAN_CONTRACT}
                </Typography>
                <LoanContractSelectInput
                  selectedLoanContractsId={this.state.loanContract}
                  add={this.handleAddLoanContract}
                  delete={this.handleDeleteLoanContract}
                />
                <p className={classes.errorInput}>
                  {this.state.loanContractError}
                </p>
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.PAYROLL_CARD}
                </Typography>
                <CreditCardSelectInput
                  selectedId={this.state.creditCard}
                  add={this.handleAddCreditCard}
                  delete={this.handleDeleteCreditCard}
                />
                <p className={classes.errorInput}>
                  {this.state.creditCardError}
                </p>
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.FLIER}
                </Typography>
                <FlierSelectInput
                  selectedFlierId={this.state.flier}
                  add={this.handleAddFlier}
                  delete={this.handleDeleteFlier}
                />
                <p className={classes.errorInput}>{this.state.flierError}</p>
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.CHECK}
                </Typography>
                <CheckSelectInput
                  selectedCheckId={this.state.cheque}
                  add={this.handleAddCheque}
                  delete={this.handleDeleteCheque}
                />
                <p className={classes.errorInput}>{this.state.chequeError}</p>
              </div>
              <br />
              <TextField
                label={translations.CONTRACT_DATE}
                className={classes.textField}
                value={this.state.contractDate}
                type="date"
                onChange={this.handleChange("contractDate")}
                required={true}
                error={this.state.contractDateError !== ""}
                helperText={this.state.contractDateError}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <br />
              <TextField
                label={translations.CONTRACT_FEE}
                value={this.state.contractFee}
                onChange={this.handleCurrencyInputChange("contractFee")}
                style={{ width: "50%" }}
                required={true}
                error={this.state.contractFeeError !== ""}
                helperText={this.state.contractFeeError}
                InputProps={{
                  inputComponent: moneyInput,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <FormControl
                className={classes.formControl}
                error={this.state.contractFeeCurrencyError !== ""}
              >
                <Select
                  value={this.state.contractFeeCurrency}
                  onChange={this.handleSelectChange("contractFeeCurrency")}
                  required={true}
                  native={true}
                  inputProps={{
                    id: "contractFeeCurrency",
                    name: "contractFeeCurrency",
                  }}
                >
                  <option value="DOP">{translations.DOP}</option>
                  <option value="USD">{translations.USD}</option>
                </Select>
              </FormControl>
              <br />
              <TextField
                className={classes.textField}
                label={translations.WITHDRAWAL_DAY}
                value={this.state.withdrawalDay}
                onChange={this.handleChange("withdrawalDay")}
                fullWidth={true}
                required={true}
                error={this.state.withdrawalDayError !== ""}
                helperText={this.state.withdrawalDayError}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.TRANSFER_CODE_CARD}
                className={classes.textField}
                value={this.state.transferCode}
                onChange={this.handleChange("transferCode")}
                fullWidth={true}
                error={this.state.transferCodeError !== ""}
                helperText={this.state.transferCodeError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    debitAuthorizationContracts: state.debitAuthorizationContracts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteDebitAuthorizationContract,
    fetchDebitAuthorizationContract,
    updateDebitAuthorizationContract,
  })(View)
);
