import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import Grid from "@material-ui/core/Grid";

import MoneyInput from "src/components/TextMaskInputComponents/moneyInput";
import { createInputCertificationContract } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  translations,
} from "../../../utils";
import {
  createInputCertificationContract as create,
  fetchInterests,
} from "../../../utils/axios";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

interface IFormProps {
  classes: {
    attachmentIcon: any;
    checkboxLabel: any;
    controls: any;
    errorInput: any;
    formControl: any;
    greenButton: any;
    half: any;
    paper: any;
    root: any;
    textField: any;
    title: any;
  };
  user: IUser;
  debtor?: string[];
  handleSaveCallBack?(uid: string): void;
  createInputCertificationContract(
    inputCertificationContract: IInputCertificationContract
  ): IAction<IInputCertificationContract[]>;
  changeSection(sectionNumber: number): void;
}
interface IFormState {
  [key: string]: string | number | boolean | string[] | IBankAccount[];

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;

  certificateNumber: string;
  certificateNumberError: string;

  inputAmount: number;
  inputValueTotal: number;
  inputAmountError: string;

  inputValue: number;
  inputValueError: string;

  debtor: string[];
  debtorError: string;

  contractDate: string;
  contractDateError: string;
}
class Form extends React.Component<IFormProps, IFormState> {
  public state: IFormState = {
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    debtor: [],
    debtorError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    inputValue: 0.0,
    inputValueTotal: 0,
    inputValueError: "",
    inputAmount: 0.0,
    inputAmountError: "",
    certificateNumber: "",
    certificateNumberError: "",
  };

  constructor(props: IFormProps) {
    super(props);
    // debtor
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // core event
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
  }
  public handleCurrencyInputChange(key: any) {
    return (event?: any, maskedValue?: any, floatValue?: any) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public handleAddClient(id: any) {
    this.setState({ debtor: [id] });
  }
  public handleDeleteClient(id: any) {
    this.setState({ debtor: [] });
  }
  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleCheckBoxChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  public handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);
    const { state } = this;
    if (!this.state.debtor.length) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "Debe de seleccionar un deudor",
      });
    } else if (!this.state.inputAmount) {
      this.setState({ inputAmountError: "Este campo es requerido" });
    } else {
      const contract: IInputCertificationContract = {
        contractDate: state.contractDate,
        status: "in_process",
        member: state.debtor[0] || "",
        certificateNumber: state.certificateNumber,
        inputAmount: state.inputAmount,
        inputValue: state.inputValue,
      };

      this.setState({ loading: true });

      create(this.props.user.token, contract)
        .then((res) => {
          this.props.createInputCertificationContract(
            res.data.results ? res.data.results : res.data
          );
          successHandler(res, this, "Solicitud de Certificado de Aportación");
          if (this.props.handleSaveCallBack) {
            this.props.handleSaveCallBack(res.data.uniqueId);
          }
          this.handleReset();
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  public handleReset() {
    this.setState({
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      debtor: [],
      debtorError: "",
      loading: false,
      title: "",
      titleError: "",
      certificateNumber: "",
      certificateNumberError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.inputAmount !== this.state.inputAmount) {
      const inputTotal = this.state.inputAmount * this.state.inputValue;
      this.setState({
        inputValueTotal: inputTotal,
      });
    }
  }

  public componentDidMount() {
    fetchInterests({
      calculatorType: "contributions",
      token: this.props.user.token,
    })
      .then((res) => {
        this.setState({ inputValue: res.data[0].contributionAmount });
      })
      .catch((err: any) => {
        catchError(err, this);
      });

    this.setState({
      debtor: this.state.debtor.length
        ? this.state.debtor
        : this.props.debtor || [],
    });
  }

  public render() {
    const { classes } = this.props;

    return (
      <form
        name="MA"
        noValidate={true}
        onSubmit={this.handleSubmit}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            Nueva Solicitud de Certificados de Aportación
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label={"Número de certificado"}
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.certificateNumber}
                    onChange={this.handleChange("certificateNumber")}
                    fullWidth={true}
                    disabled={true}
                    error={this.state.certificateNumberError !== ""}
                    helperText={this.state.certificateNumberError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Cantidad de Aportaciones"}
                    style={{ width: "100%" }}
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.inputAmount}
                    onChange={this.handleChange("inputAmount")}
                    fullWidth={true}
                    required={true}
                    type="number"
                    error={this.state.inputAmountError !== ""}
                    helperText={this.state.inputAmountError}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        step: 1,
                      },
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Valor por Aportacion"}
                    style={{ width: "100%" }}
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.inputValue}
                    fullWidth={true}
                    disabled={true}
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                    error={this.state.inputValueError !== ""}
                    helperText={this.state.inputValueError}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={6}>
                  <TextField
                    label={"Total de Aportaciones"}
                    style={{ width: "100%" }}
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.inputValueTotal}
                    fullWidth={true}
                    disabled={true}
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <TextField
                    label={translations.CONTRACT_DATE}
                    style={{ width: "100%" }}
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.contractDate}
                    fullWidth={true}
                    type="date"
                    onChange={this.handleChange("contractDate")}
                    required={true}
                    error={this.state.contractDateError !== ""}
                    helperText={this.state.contractDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  Miembro
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtor}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>
              <br />
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createInputCertificationContract })(Form)
);
