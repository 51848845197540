import * as React from 'react';
import { translations } from 'src/utils';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Grid from '@material-ui/core/Grid';

const styles: any = createStyles({
    formControl: {
        marginTop: 20
    },
    greenButton: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    label: {
        color: green[500]
    },
    redButton: {
        '&:hover': {
            backgroundColor: red[200]
        },
        backgroundColor: red[500],
        color: 'white'
    },
    textField: {
        marginTop: 10,
        minWidth: 190
    }
});

interface IAddendumContractSearchFilterProps {
    token: string;

    open: boolean;
    classes: {
        [key: string]: string;
    }
    handleClose(): void;
    search(params: IFetchAddendumContractParams): void;
}

interface IAddendumContractSearchFilterState {
    [key: string]: string | number | any;

    addendum_type: any;
    modification_date_from: string;
    modification_date_to: string;
    title: string;
}

class AddendumContractSearchFilter extends React.Component<IAddendumContractSearchFilterProps, IAddendumContractSearchFilterState>{
    public state: IAddendumContractSearchFilterState = {
        addendum_type: [],
        modification_date_from: '',
        modification_date_to: new Date().toISOString().split('T')[0],
        title: '',
    };
    constructor(props: IAddendumContractSearchFilterProps) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }
    public handleSearch() {
        const params: IFetchAddendumContractParams = {
            addendumType: this.state.addendum_type.map((item: any) => item.value).join(','),
            modificationDateFrom: this.state.modification_date_from,
            modificationDateTo: this.state.modification_date_to,
            title: this.state.title,
            token: this.props.token
        };
        this.props.search(params);
        this.props.handleClose();
    }
    public handleClear() {
        this.setState({
            addendum_type: [],
            modification_date_from: '',
            modification_date_to: new Date().toISOString().split('T')[0],
            title: '',
        });
        this.props.search({ token: this.props.token })
        this.props.handleClose();
    }
    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({ [key]: event.target.value });
        }
    }
    public handleCurrencyInputChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>, maskedValue?: string, floatValue?: string) => {
            this.setState({ [key]: floatValue || '' });
        }
    }
    public render() {
        const { classes, handleClose, open } = this.props;

        const handleMultipleSelection = (key: string, val: string) =>
            this.setState({ [key]: val });
        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12}>
                            <TextField
                                label={translations.TITLE}
                                value={this.state.title}
                                fullWidth={true}
                                onChange={this.handleChange('title')}
                                InputLabelProps={{
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Autocomplete
                                style={{ marginTop: "16px" }}
                                multiple={true}
                                value={this.state.addendum_type}
                                options={[
                                    { value: "outlay", label: translations.CREDIT_LINE },
                                    { value: "interest", label: translations.INTEREST },
                                    { value: "contact", label: translations.CONTACT },
                                ]}
                                getOptionLabel={(option: any) => option.label}
                                onChange={(e, newValue: any) =>
                                    handleMultipleSelection("addendum_type", newValue)
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" label={translations.TYPE} InputLabelProps={{
                                        style: styles.label,
                                        shrink: true
                                    }} />
                                )}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.MODIFICATION_DATE} ${translations.FROM}`}
                                fullWidth={true}
                                value={this.state.modification_date_from}
                                type="date"
                                onChange={this.handleChange('modification_date_from')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.MODIFICATION_DATE} ${translations.TO}`}
                                fullWidth={true}
                                value={this.state.modification_date_to}
                                type="date"
                                onChange={this.handleChange('modification_date_to')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        {/* <TextField
                        label={translations.ADDENDUM_TYPE}
                        value={this.state.addendum_type}
                        fullWidth={true}
                        onChange={this.handleChange('addendum_type')}
                        InputLabelProps={{
                            style: styles.label
                        }}
                    /> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.redButton} onClick={this.handleClear}>
                        <ClearIcon />
                        &nbsp;
                        {`${translations.CLEAR} ${translations.SEARCH}`}
                    </Button>
                    <Button className={classes.greenButton} onClick={this.handleSearch}>
                        <SearchIcon />
                        &nbsp;
                        {translations.SEARCH}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}


export default withStyles(styles)(AddendumContractSearchFilter);