import { AxiosError } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import {
  deleteAutomaticDebitAuthorizationContract, fetchAutomaticDebitAuthorizationContract,
  updateAutomaticDebitAuthorizationContract
} from '../../../actions';
import AlertDialog from '../../../components/AlertDialog';
import Loading from '../../../components/Loading';
import {
  catchError, filesGeneration, resetError, successHandler,
  titleCaseHandler, translations
} from '../../../utils';
import { deleteAutomaticDebitAuthorizationContract as deleteDA,
  fetchAutomaticDebitAuthorizationContract as fetchDA, updateAutomaticDebitAuthorizationContract as updateDA
} from '../../../utils/axios';
import LoanContractSelectInput from '../../LoanContract/LoanContractSelectInput';


const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "50%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface IViewProps {
  automaticDebitAuthorizationContracts: IAutomaticDebitAuthorizationContract[];
  automaticDebitAuthorizationContractId: string;
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    deleteButton: string;
    errorInput: string;
    formControl: string;
    greenButton: string;
    half: string;
    paper: string;
    root: string;
    textField: string;
  };
  user: IUser;
  changeSection(sectionNumber: number): void;
  deleteAutomaticDebitAuthorizationContract(
    uniqueId: string
  ): IAction<IAutomaticDebitAuthorizationContract[]>;
  fetchAutomaticDebitAuthorizationContract(
    automaticDebitAuthorizationContract: IAutomaticDebitAuthorizationContract
  ): IAction<IAutomaticDebitAuthorizationContract[]>;
  updateAutomaticDebitAuthorizationContract(
    automaticDebitAuthorizationContract: IAutomaticDebitAuthorizationContract
  ): IAction<IAutomaticDebitAuthorizationContract[]>;
}
interface IViewState {
  [key: string]:
  | string
  | number
  | boolean
  | string[]
  | undefined;

  contractDate: string;
  contractDateError: string;
  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loanContract: string[];
  loanContractError: string;
  loading: boolean;
  title: string;
  titleError: string;
  uniqueId: string;
  status: string;
}
class View extends React.Component<IViewProps, IViewState> {
  public state: IViewState = {
    contractDate: "",
    contractDateError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    title: "",
    titleError: "",
    uniqueId: "",
    loanContract: [],
    loanContractError: "",
    status: 'pending_signature',


  };
  constructor(props: IViewProps) {
    super(props);

    this.handleAddLoanContract = this.handleAddLoanContract.bind(this);
    this.handleDeleteLoanContract = this.handleDeleteLoanContract.bind(this);

    // core events
    this.generateFile = this.generateFile.bind(this);
    this.changeStatus = this.changeStatus.bind(this);

    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }


  public handleAddLoanContract(contract: ILoanContract) {
    this.setState({ loanContract: [contract.uniqueId || ""] });
  }
  public handleDeleteLoanContract(id: string) {
    this.setState({ loanContract: [] });
  }
  public async generateFile() {
    this.setState({ loading: true });
    const url = `/lending/debit-authorization-contracts-extended/${this.state.uniqueId}/print/`
    await filesGeneration(url, this.props.user.token)
    this.setState({ loading: false });
  }
  public changeStatus(status: string) {
    return () => {
      this.setState({ status });
    };
  }

  public componentDidMount() {
    fetchDA(this.props.user.token, this.props.automaticDebitAuthorizationContractId)
      .then((res) => {
        const curContract = res.data as IAutomaticDebitAuthorizationContract;

        this.setState({
          contractDate: curContract.contractDate || "",
          dialog: false,
          dialogTitle: "",
          errorMessage: "",
          loanContract: curContract.loanContract ? [curContract.loanContract] : [],
          status: curContract.status || '',
          loading: false,
          title: curContract.title || "",
          uniqueId: curContract.uniqueId || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);

    const { state } = this;
    if (this.state.loanContract.length === 0) {
      this.setState({
        dialog: true,
        dialogTitle: 'Disculpe',
        errorMessage: 'Seleccione un contrato de línea de crédito',
      });
    } else {
      const automaticDebitAuthorizationContract: IAutomaticDebitAuthorizationContract = {
        contractDate: state.contractDate,
        title: titleCaseHandler(state.title),
        loanContract: state.loanContract[0] || "",
        status: this.state.status,
      };

      this.setState({ loading: true });

      updateDA(
        this.props.user.token,
        this.state.uniqueId,
        automaticDebitAuthorizationContract
      )
        .then((res) => {
          this.props.updateAutomaticDebitAuthorizationContract(
            res.data as IAutomaticDebitAuthorizationContract
          );
          successHandler(res, this, translations.AUTOMATIC_DEBIT_AUTHORIZATION);
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleDelete() {
    if (confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteDA(this.props.user.token, this.props.automaticDebitAuthorizationContractId)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteAutomaticDebitAuthorizationContract(
            this.props.automaticDebitAuthorizationContractId
          );
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">
            {translations.AUTOMATIC_DEBIT_AUTHORIZATION}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={classes.greenButton}
                onClick={this.generateFile}
                style={{ marginRight: 10, marginTop: 25 }}
              >
                <DocumentIcon />
                &nbsp;
                {translations.GENERATE_DOCUMENT}
              </Button>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="status" shrink={true}>{translations.STATUS}</InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleSelectChange('status')}
                  required={true}
                  inputProps={{
                    id: 'status',
                    name: 'status',
                  }}

                >
                  <MenuItem value="pending_signature">{translations.PENDING_SIGNATURE}</MenuItem>
                  <MenuItem value="completed">{translations.COMPLETED}</MenuItem>
                  <MenuItem value="archived">{translations.ARCHIVED}</MenuItem>
                </Select>
              </FormControl>
              <div style={{ display: 'flex' }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={this.handleChange('title')}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ''}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange('contractDate')}
                  required={true}
                  error={this.state.contractDateError !== ''}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel
                  }}
                />
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.LOAN_CONTRACT}
                </Typography>
                <LoanContractSelectInput
                  selectedLoanContractsId={this.state.loanContract}
                  add={this.handleAddLoanContract}
                  delete={this.handleDeleteLoanContract}
                />
                <p className={classes.errorInput}>
                  {this.state.loanContractError}
                </p>
              </div>
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    automaticDebitAuthorizationContracts: state.automaticDebitAuthorizationContracts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteAutomaticDebitAuthorizationContract,
    fetchAutomaticDebitAuthorizationContract,
    updateAutomaticDebitAuthorizationContract,
  })(View)
);
