import * as React from "react";
import { connect } from "react-redux";
import {
  cancelOptions,
  catchError,
  quotationStatus,
  translations,
} from "src/utils";

import { Button, MenuItem, Select, Tooltip } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BlockIcon from "@material-ui/icons/Block";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { fetchQuotations, updateQuotation } from "../../../actions";
import Form from "../../../components/Calculators/AmortizationTable";
import Popup from "../../../components/UI/Popup";
import {
  // fetchParticipants,
  fetchQuotations as fetch,
  updateQuotationStatus as updateQ,
} from "../../../utils/axios";
import QuotationSearchSection from "../QuotationSearchSection";

const styles: any = createStyles({
  approvedButton: {
    backgroundColor: "#4caf50",
    border: "none",
    borderRadius: "10px",
    color: "white",
    display: "flex",
    float: "right",
    fontSize: "11px",
    fontWeight: "bold",
    height: "auto",
    marginRight: "10px",
    padding: " 10px 18px",
    width: "auto",
  },
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

interface IQuotationSelectInputProps {
  classes: {
    [key: string]: string;
  };
  user: IUser;
  dense?: boolean;
  quotations: IQuotation[];
  selectedQuotationsId: string[];
  ticketSettlementStep?: boolean;
  quotationData?: any;
  ticketApprovedQuotation?: boolean;
  client?: string;
  addQuotation(quotationId: string): void;
  removeQuotation(quotationId: string): void;
  fetchQuotations(quotations: IQuotation[]): IAction<IQuotation[]>;
  updateQuotation(quotation: IQuotation): IAction<IQuotation[]>;
}

interface IQuotationSelectInputState {
  cancelModal: boolean;
  cancelationReason: string;
  clients: IParticipant[];
  formDialog: boolean;
  loading: boolean;
  open: boolean;
  openEdit: boolean;
  quotations: IQuotation[];
}

class QuotationSelectInput extends React.Component<
  IQuotationSelectInputProps,
  IQuotationSelectInputState
> {
  public state: IQuotationSelectInputState = {
    cancelModal: false,
    cancelationReason: "",
    clients: [],
    formDialog: false,
    loading: false,
    open: false,
    openEdit: false,
    quotations: [],
  };
  fetching: any;
  constructor(props: IQuotationSelectInputProps) {
    super(props);

    this.closeCancelModal = this.closeCancelModal.bind(this);
    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.findQuotation = this.findQuotation.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.openCancelModal = this.openCancelModal.bind(this);
    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.openEditDialog = this.openEditDialog.bind(this);
    this.closeEditDialog = this.closeEditDialog.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.fetching = false;
  }
  public componentDidMount() {
    this.setState({ loading: true });
    // fetchParticipants({
    //   participantType: "client",
    //   token: this.props.user.token,
    //   limit: 100
    // }).then((res) => {
    //   this.setState({
    //     clients: (res.data.results
    //       ? res.data.results
    //       : res.data) as IParticipant[],
    //   });
    // });

    const params: IQuotationParams = {
      quotationStatus: "approved,on_process",
      token: this.props.user.token,
    };
    if (this.props.client) {
      params.client = this.props.client;
    }
    fetch(params)
      .then((res) => {
        this.props.fetchQuotations(
          res.data.results ? res.data.results : res.data
        );

        this.setState({
          loading: false,
          quotations: (res.data.results
            ? res.data.results
            : res.data) as IQuotation[],
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleQuotationChange() {
    const curQuotation = this.props.selectedQuotationsId[0];
    if (curQuotation) {
      this.props.addQuotation(curQuotation);
    }
  }

  public openDialogHandler() {
    const params: IQuotationParams = {
      quotationStatus: "approved,on_process",
      token: this.props.user.token,
    };
    if (this.props.client) {
      params.client = this.props.client;
    }
    fetch(params)
      .then((res) => {
        this.props.fetchQuotations(
          res.data.results ? res.data.results : res.data
        );

        this.setState({
          loading: false,
          quotations: (res.data.results
            ? res.data.results
            : res.data) as IQuotation[],
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
    this.setState({ open: true });
  }
  public closeDialogHandler() {
    this.handleQuotationChange();
    this.setState({ open: false });
  }
  public openCancelModal() {
    this.setState({ cancelModal: true });
  }
  public closeCancelModal() {
    this.handleQuotationChange();
    this.setState({ cancelModal: false });
  }
  public openFormDialog() {
    this.setState({ formDialog: true });
  }
  public closeFormDialog() {
    this.handleQuotationChange();
    this.setState({ formDialog: false });
  }
  public openEditDialog() {
    this.setState({ openEdit: true });
  }
  public closeEditDialog() {
    this.handleQuotationChange();
    this.setState({ openEdit: false });
  }
  public emptyFunction() {
    return null;
  }
  public handleItemClick(quotationId: string) {
    this.setState({ loading: true });

    const params: IQuotationParams = {
      quotationStatus: "approved,on_process",
      token: this.props.user.token,
    };
    if (this.props.client) {
      params.client = this.props.client;
    }
    fetch(params)
      .then((res) => {
        this.props.fetchQuotations(
          res.data.results ? res.data.results : res.data
        );

        this.setState(
          {
            loading: false,
            open: false,
            quotations: (res.data.results
              ? res.data.results
              : res.data) as IQuotation[],
          },
          () => {
            this.props.addQuotation(quotationId);
          }
        );
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.props.removeQuotation(id);
    };
  }
  public handleSelectChange() {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ cancelationReason: event.target.value });
    };
  }

  public cancelQuotation(curQuotation: any) {
    return (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      curQuotation.quotationStatus = "cancelled";
      curQuotation.cancelationReason = this.state.cancelationReason;

      updateQ(this.props.user.token, curQuotation.uniqueId, curQuotation)
        .then((res) => {
          this.props.updateQuotation(
            (res.data.results ? res.data.results : res.data) as IQuotation
          );
        })
        .catch((err) => {
          console.error(err, "error");
        });

      this.setState({
        cancelModal: false,
        cancelationReason: "",
        quotations: this.state.quotations.filter(
          (row) => row.uniqueId !== curQuotation.uniqueId
        ),
      });
    };
  }

  public approveQuotation(curQuotation: any) {
    return (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      curQuotation.quotationStatus = "approved";
      updateQ(this.props.user.token, curQuotation.uniqueId, curQuotation)
        .then((res) => {
          this.props.updateQuotation(
            (res.data.results ? res.data.results : res.data) as IQuotation
          );
        })
        .catch((err) => {
          console.error(err, "error");
        });
    };
  }
  public findQuotation(uniqueId: string) {
    const quotationData =
      this.props.quotationData && this.props.quotationData.uniqueId
        ? this.props.quotationData
        : null;
    const curQuotation =
      quotationData ||
      this.state.quotations.find(
        (quotation) => quotation.uniqueId === uniqueId
      );
    if (curQuotation) {
      return (
        <TableRow
          key={curQuotation.uniqueId}
          style={{ textAlignLast: "center" }}
        >
          <TableCell>
            {parseFloat(curQuotation.transferAmount as any).toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </TableCell>
          <TableCell>
            {parseFloat(curQuotation.originalAmount as any).toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </TableCell>
          <TableCell>{curQuotation.referenceCode}</TableCell>
          <TableCell>{curQuotation.quotationDate}</TableCell>
          <TableCell>
            {
              quotationStatus.find(
                (x) => x.value === curQuotation.quotationStatus
              )?.label
            }
          </TableCell>
          {!this.props.ticketApprovedQuotation && (
            <TableCell>
              <Tooltip title="Remover Cotización">
                <IconButton
                  onClick={this.handleDeleteItem(
                    curQuotation.uniqueId ? curQuotation.uniqueId : ""
                  )}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar Cotización">
                <IconButton onClick={this.openEditDialog}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancelar Cotización">
                <IconButton onClick={this.openCancelModal}>
                  <BlockIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          )}
        </TableRow>
      );
    } else {
      return null;
    }
  }
  public fetchData(params: IQuotationParams) {
    params.limit = undefined;
    params.offset = 0;
    if (this.props.client) {
      params.client = this.props.client;
    }
    (params.quotationStatus = "approved,on_process"),
      this.setState({ loading: true });
    fetch(params)
      .then((res) => {
        this.props.fetchQuotations(
          (res.data.results ? res.data.results : res.data) as IQuotation[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public render() {
    const { classes } = this.props;
    const { formDialog, open, openEdit, loading, cancelModal } = this.state;
    const curQuotation = this.state.quotations.find(
      (quotation) => quotation.uniqueId === this.props.selectedQuotationsId[0]
    );
    return (
      <div className={classes.root}>
        <Dialog open={cancelModal} onClose={this.closeCancelModal}>
          <DialogContent>
            <div className={classes.cancelModal}>
              <label>Explique la razon? </label>
              <Select
                id="cancelationReason"
                value={this.state.cancelationReason}
                style={{ width: 300 }}
                onChange={this.handleSelectChange()}
              >
                {cancelOptions.map((opt, indx) => {
                  return (
                    <MenuItem value={opt.value} key={indx}>
                      {opt.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <Button onClick={this.cancelQuotation(curQuotation)}>
                {translations.SAVE}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={open} onClose={this.closeDialogHandler}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.openFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: 600, width: 280 }}>
            <QuotationSearchSection
              client={this.props.client}
              advanceSearch={this.fetchData}
              fetchData={this.fetchData}
              handleClick={this.handleItemClick}
              loading={loading}
              pagination={false}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={formDialog}
          fullScreen={true}
          onClose={this.closeFormDialog}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <Form changeSection={this.emptyFunction} />
          </DialogContent>
        </Dialog>
        <Paper>
          <Table size={this.props.dense ? "small" : "medium"}>
            <TableHead style={{ textAlignLast: "center" }}>
              <TableRow>
                <TableCell>
                  {translations.TRANSFER_AMOUNT.toUpperCase()}
                </TableCell>
                <TableCell>MONTO A FINANCIAR</TableCell>
                <TableCell>{translations.REFERENCE.toUpperCase()}</TableCell>
                <TableCell>
                  {translations.QUOTATION_DATE.toUpperCase()}
                </TableCell>
                <TableCell>{translations.STATUS.toUpperCase()}</TableCell>
                {this.props.selectedQuotationsId &&
                !this.props.ticketApprovedQuotation ? (
                  <TableCell>
                    <IconButton onClick={this.openDialogHandler}>
                      <AddIcon />
                    </IconButton>
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.selectedQuotationsId.map((quotationId) =>
                this.findQuotation(quotationId)
              )}
            </TableBody>
          </Table>
        </Paper>
        <br />
        {curQuotation?.quotationStatus === "on_process" &&
        !this.props.ticketSettlementStep ? (
          <Button
            className={classes.approvedButton}
            onClick={this.approveQuotation(curQuotation)}
          >
            Aprobar cotizacion
          </Button>
        ) : null}
        <Popup open={openEdit} funcion={this.closeEditDialog}>
          <Form quotationId={curQuotation?.uniqueId} />
        </Popup>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    quotations: state.quotations,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchQuotations, updateQuotation })(
    QuotationSelectInput
  )
);
