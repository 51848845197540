import * as React from 'react';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Autocomplete } from "@material-ui/lab";
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';

import { SearchComponent } from "../../../components//SharedComponents/Fields";
import ParticipantAutoComplete from 'src/components/Filters/ParticipantAutoComplete';

import { translations, payrollDeductionStatus } from '../../../utils';

const styles: any = createStyles({
    formControl: {
        marginTop: 20
    },
    greenButton: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    label: {
        color: green[500]
    },
    redButton: {
        '&:hover': {
            backgroundColor: red[200]
        },
        backgroundColor: red[500],
        color: 'white'
    },
    textField: {
        marginTop: 10,
        minWidth: 190
    }
});

interface ISearchFilterProps {
    token: any;
    open: boolean;
    classes: {
        attachmentIcon: any;
        checkboxLabel: any;
        controls: any;
        deleteButton: any;
        errorInput: any;
        formControl: any;
        greenButton: any;
        half: any;
        paper: any;
        root: any;
        textField: any;
        title: any;
        redButton: any;
    };
    search(params: IFetchPledgeContractWithoutDivestmentParams): void;
    handleClose(): void;

}
interface ISearchFilterState {
    [key: string]:
    | string
    | number
    | boolean
    | string[]
    | any
    | undefined;

    title: string;
    contract_date_from: string;
    contract_date_to: string;
    creditor_unique_id: any;
    debtor_unique_id: any;
    status: any;

}


class SearchFilter extends React.Component<ISearchFilterProps, ISearchFilterState> {
    public state: ISearchFilterState = {
        contract_date_from: '',
        contract_date_to: new Date().toISOString().split('T')[0],
        creditor_unique_id: [],
        debtor_unique_id: [],
        status: [],
        title: '',
    };
    constructor(props: ISearchFilterProps) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }
    public handleSearch() {
        const params = {
            contractDateFrom: this.state.contract_date_from,
            contractDateTo: this.state.contract_date_to,
            creditorUniqueId: this.state.creditor_unique_id.map((item: ICreditor) => item.participantDetail?.uniqueId).join(','),
            debtorUniqueId: this.state.debtor_unique_id,
            status: this.state.status.map((item: any) => item.value).join(','),
            title: this.state.title,
            token: this.props.token
        };
        this.props.search(params);
        this.props.handleClose();
    }
    public handleClear() {
        this.setState({
            contract_date_from: '',
            contract_date_to: new Date().toISOString().split('T')[0],
            creditor_unique_id: [],
            debtor_unique_id: [],
            status: [],
            economic_activity_info: '',
            title: '',
        });
        this.props.search({ token: this.props.token })
        this.props.handleClose();
    }
    public handleChange(key: any) {
        return (event: any) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }
    public handleSelectChange(key: any) {
        return (event: any) => {
            this.setState({ [key]: event.target.value });
        }
    }

    public handleAutocompleteChange(key: any, obj: any, val: any) {
        /* Handle the state of Autocomplete components */
        const data = obj || val;

        this.setState({
            [key]: data.value ? data.value : ""
        });

    }

    public render() {
        const { classes, handleClose, open } = this.props;
        const handleChange2 = (key: string, val: string) =>
            this.setState({ [key]: val });

        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12}>
                            <SearchComponent
                                xs={12}
                                containerClass="field-container"
                                fieldClass={{ style: styles.label }}
                                typeClass={styles.label}
                                fieldID="client"
                                fieldInnerLabel={translations.DEBTOR}
                                handleChange={this.handleAutocompleteChange.bind(this, "debtor_unique_id")}
                                optionType={'participants'}
                                variant="standard"
                                limitTags={1}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                label={translations.TITLE}
                                value={this.state.title}
                                fullWidth={true}
                                onChange={this.handleChange('title')}
                                InputLabelProps={{
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Autocomplete
                                style={{ marginTop: "16px" }}
                                multiple={true}
                                options={payrollDeductionStatus}
                                getOptionLabel={(option: any) => option.label}
                                onChange={(e, newValue: any) =>
                                    handleChange2("status", newValue)
                                }
                                value={this.state.status}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" label={translations.STATUS} InputLabelProps={{
                                        style: styles.label,
                                        shrink: true
                                    }} />
                                )}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <ParticipantAutoComplete
                                handleSelectChange={(e, newValue: any) =>
                                    handleChange2("creditor_unique_id", newValue)
                                }
                                value={this.state.creditor_unique_id}
                                type={"creditor"} label={translations.CREDITOR} />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.FROM}`}
                                value={this.state.contract_date_from}
                                fullWidth={true}
                                type="date"
                                onChange={this.handleChange('contract_date_from')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.TO}`}
                                fullWidth={true}
                                value={this.state.contract_date_to}
                                type="date"
                                onChange={this.handleChange('contract_date_to')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.redButton} onClick={this.handleClear}>
                        <ClearIcon />
                        &nbsp;
                        {`${translations.CLEAR} ${translations.SEARCH}`}
                    </Button>
                    <Button className={classes.greenButton} onClick={this.handleSearch}>
                        <SearchIcon />
                        &nbsp;
                        {translations.SEARCH}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}


export default withStyles(styles)(SearchFilter);