import React from "react";
import {
  Button,
  TextField,
  createStyles,
} from "@material-ui/core";
import { translations } from "src/utils";

const styles = createStyles({
  clean: {
    background: "#F44636",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  formControl: {
    margin: "0px 4px",
  },
  save: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  textField: {
    margin: "0px 4px",
    width: "46%",
  },
});

class VacationDatesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      daysTaken: "0",
      departureDate: "",
      entryDate: "",
      errors: {},
      id: 0,
    };
    this.initialValues = {
      ...this.state,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { recordForEdit } = this.props;
    if (recordForEdit != null) {
      this.setState({
        ...recordForEdit,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.departureDate !== this.state.departureDate ||
      prevState.entryDate !== this.state.entryDate
    ) {
      this.setState({
        daysTaken: this.getVacationDays(
          this.state.departureDate,
          this.state.entryDate
        ),
      });
    }
  }
  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleReset() {
    this.setState({ ...this.initialValues });
  }

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };
    if ("departureDate" in fieldValues) {
      temp.departureDate = fieldValues.departureDate
        ? ""
        : "Este campo es requerido.";
    }
    if ("entryDate" in fieldValues) {
      temp.entryDate = fieldValues.entryDate ? "" : "Este campo es requerido.";
    }

    this.setState({
      errors: { ...temp },
    });

    if (fieldValues === this.state) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  getVacationDays(startDate, endDate) {
    let numWorkDays = 0;
    let currentDate = new Date(startDate.replace(/-/g, "/"));
    let lastDate = new Date(endDate.replace(/-/g, "/"));
    while (currentDate < lastDate) {
      // Skips Sunday and Saturday
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        numWorkDays++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return numWorkDays;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const state = {
      daysTaken: this.state.daysTaken,
      departureDate: this.state.departureDate,
      entryDate: this.state.entryDate,
      id: this.state.id,
    };
    
    if (this.validate()) {
      this.props.addOrEdit(state);
    }
  };

  render() {
    const classes = styles;
    const { errors } = this.state;

    return (
      <form style={{ width: "auto" }}>
        <TextField
          label={translations.DEPARTURE_DATE}
          value={this.state.departureDate}
          type="date"
          onChange={this.handleChange("departureDate")}
          fullWidth={true}
          error={errors.departureDate ? true : false}
          helperText={errors.departureDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />
        <br />
        <TextField
          label={translations.ENTRY_DATE}
          value={this.state.entryDate}
          type="date"
          onChange={this.handleChange("entryDate")}
          fullWidth={true}
          error={errors.entryDate ? true : false}
          helperText={errors.entryDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />
        <br />
        <TextField
          label="Dias"
          value={this.state.daysTaken}
          disabled={true}
          fullWidth={true}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />
        <br />
        <div>
          <Button style={classes.save} onClick={this.handleSubmit}>
            Guardar
          </Button>
          <Button style={classes.clean} onClick={this.handleReset}>
            Limpiar
          </Button>
        </div>
      </form>
    );
  }
}
export default VacationDatesForm;
