import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";

import { createAutomaticWithdrawalAuthorizationContract } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import { createAutomaticWithdrawalAuthorizationContract as create } from "../../../utils/axios";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
import ChargingMethodSelectInput from "../ChargingMethodSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

interface IFormProps {
  classes: {
    attachmentIcon: any;
    checkboxLabel: any;
    controls: any;
    deleteButton: any;
    errorInput: any;
    formControl: any;
    greenButton: any;
    half: any;
    paper: any;
    root: any;
    textField: any;
    title: any;
  };
  user: IUser;
  debtor?: string[];
  handleSaveCallBack?(uid: string): void;
  changeSection(sectionNumber: number): void;
  createAutomaticWithdrawalAuthorizationContract(
    clientAffidavitOfFunds: IAutomaticWithdrawalAuthorizationContract
  ): IAction<IAutomaticWithdrawalAuthorizationContract[]>;
}
interface IFormState {
  [key: string]: string | number | boolean | string[] | undefined;

  contractDate: string;
  contractDateError: string;

  dialog: boolean;
  dialogTitle: string;
  title: string;
  errorMessage: string;
  debtor: string[];
  debtorError: string;
  loading: boolean;

  chargingMethod: string;
}

class Form extends React.Component<IFormProps, IFormState> {
  public state: IFormState = {
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    debtor: [],
    debtorError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    title: "",
    titleError: "",
    chargingMethod: "",
  };

  constructor(props: IFormProps) {
    super(props);

    // debtor
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // core event
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
  }
  public handleChecked(chargingMethod: string) {
    this.setState({
      chargingMethod,
    });
  }

  public handleAddClient(id: any) {
    this.setState({ debtor: [id] });
  }

  public handleDeleteClient(id: any) {
    this.setState({ debtor: [] });
  }

  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleCheckBoxChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  public handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);
    const { state } = this;
    if (!this.state.debtor.length) {
      this.setState({ debtorError: "Este campo es requerido" });
    } else {
      const contract: IAutomaticWithdrawalAuthorizationContract = {
        contractDate: state.contractDate,
        partner: state.debtor[0] || "",
        title: state.title,
        chargingMethod: state.chargingMethod,
      };

      this.setState({ loading: true });

      create(this.props.user.token, contract)
        .then((res) => {
          if (this.props.handleSaveCallBack) {
            this.props.handleSaveCallBack(res.data.uniqueId);
          }
          this.props.createAutomaticWithdrawalAuthorizationContract(
            res.data.results ? res.data.results : res.data
          );
          successHandler(res, this, "Autorización de retiro via electrónica");
          this.handleReset();
        })
        .catch((err: any) => {
          catchError(err, this);
        });
    }
  }
  public handleReset() {
    this.setState({
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      debtor: [],
      debtorError: "",
      loading: false,
      title: "",
      titleError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public componentDidMount() {
    this.setState({
      debtor: this.state.debtor.length
        ? this.state.debtor
        : this.props.debtor || [],
    });
  }
  public render() {
    const { classes } = this.props;
    return (
      <form
        name="MA"
        noValidate={true}
        onSubmit={this.handleSubmit}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            Autorización de retiro via electrónica
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.PARTNER}
                  </Typography>
                  <ParticipantSelectInput
                    selectedParticipantId={this.state.debtor}
                    addParticipant={this.handleAddClient}
                    deleteParticipant={this.handleDeleteClient}
                  />
                  <p className={classes.errorInput}>{this.state.debtorError}</p>
                </Grid>
                {this.state.debtor.length ? (
                  <Grid item={true} xs={12}>
                    <ChargingMethodSelectInput
                      checked={this.state.chargingMethod}
                      handleChecked={this.handleChecked}
                      debtor={this.state.debtor}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <br />
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createAutomaticWithdrawalAuthorizationContract })(
    Form
  )
);
