import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { ToolbarComponent } from './../../../components/SharedComponents/Toolbar';
import { PaymentReceiptTable } from './../Tables';
import { PaymentReceiptDialog, ConfirmDialogComponent } from './../Dialogs';
import { SuccessDialog, ErrorDialog, SimpleConfirmDialog} from '../../../components/SharedComponents/Dialogs';
import { Filters } from './../Filters';
import {
    fetchPaymentReceipts, updatePaymentReceipt, 
    deletePaymentReceipt, createPaymentReceipt, 
    createPaymentReceiptVoucher
} from './../../../utils/axios';
import LoadingDialog from '../../../components/SharedComponents/LoadingScreen';
import { 
    errorHandling, 
    handleToggleDialog as handleDialog, 
    handleToggleSimpleDialog as handleSimpleDialog,
} from '../../../components/SharedComponents/CustomHooks';
import { PaymentCalculatorDialog } from '../../../components/SharedComponents/PaymentNegotiationComponents';
import axios from 'axios';
import './../index.css';

function PayReceiptView(props) {

    const limitRequest = {limit: 25};
    const dialogTypes = ['payment-receipt','payment-receipt-modify'];
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({});
    const [simpleDialog, setSimpleDialog] = useState({name: '', data: null, callback: null});
    const [paymentReceipts, setPaymentReceipts] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [selected, setSelected] = useState([]);
    
    const handleToggleDialog = handleDialog.bind(this, setDialog, dialog);
    const handleToggleSimpleDialog = handleSimpleDialog.bind(this, setSimpleDialog);
    

    const getPaymentReceipts = (data) => {
        setLoading(true);
        const endpoint = fetchPaymentReceipts({...data, token: props.user.token});
        endpoint.then(response => {
            const data = response.data.results ? response.data.results : response.data;
            setPaymentReceipts(data);
            setLoading(false);
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const handleOnCreate = () => {
        getPaymentReceipts(selectedFilters);
    }

    // const createUpdatePaymentRecipt = (data, action) => {
    //     setLoading(true);
    //     const endpoint = action === 'update' ? updatePaymentReceipt : createPaymentReceipt;
    //     endpoint({...data, token: props.user.token}).then(response => {
    //         if(data.file) {
    //             uploadPaymentReceiptVoucher({...data, uniqueId: response.data.uniqueId});
    //         }else {
    //             getPaymentReceipts(selectedFilters);
    //             setLoading(false);
    //             handleToggleSimpleDialog('success-dialog');
    //             handleToggleDialog('payment-receipt');
    //         }
    //     }).catch(errors => {
    //         setLoading(false);
    //         errorHandling(errors, handleToggleSimpleDialog);
    //     });
    // }

    // const uploadPaymentReceiptVoucher = (data) => {
    //     let formData = new FormData();
    //     formData.append("file", data.file);
    //     const endpoint = createPaymentReceiptVoucher(props.user.token, data.uniqueId, formData);
    //     endpoint.then(() => {
    //         getPaymentReceipts(selectedFilters);
    //         setLoading(false);
    //         handleToggleSimpleDialog('success-dialog');
    //         handleToggleDialog('payment-receipt');
    //     }).catch(errors => {
    //         setLoading(false);
    //         errorHandling(errors, handleToggleSimpleDialog);
    //     });
    // }

    const deleteSingleReceipt = (receipt) => {
        setLoading(true);
        const endpoint = deletePaymentReceipt(props.user.token, receipt.uniqueId);
        endpoint.then(() => {
            getPaymentReceipts(selectedFilters);
            setLoading(false);
            handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }
    const handleDeleteMultipleReceipt = () => {
        const endpoints = selected.map(item => deletePaymentReceipt(props.user.token, item.uniqueId));
        axios.all(endpoints).then(() => {
            getPaymentReceipts(selectedFilters);
            setSelected([]);
            setLoading(false);
            handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }
    const handleSelected = (selection) => {
        setSelected(selection);
    }

    const applyFilters = (fields) => {
        let data = fields;
        if(Object.keys(fields).length === 0){
            data = limitRequest;
        }
        getPaymentReceipts(data);
        setSelectedFilters({...data});
    }

    useEffect(() => {
        getPaymentReceipts(limitRequest);
    }, []);

  return(
    <div style={{padding:'20px 20px 10% 20px'}}>
        <LoadingDialog open={loading}/>
        <ToolbarComponent
            typeVariant="h6"
            typeClass="title-text"
            toolbarTitle="GESTIÓN DE RECIBOS DE PAGO"
            btnVariant="contained"
            toolbarActions={
                <Button
                    variant="contained"
                    className={"action-method-btn"}
                    size="small"
                    disableElevation
                    onClick={() => handleToggleDialog('payment-receipt')}
                >
                    <Add style={{fontSize:20, color:'#fff', marginRight:'.2rem'}} />
                    crear un recibo
                </Button>
            }
        />
        <div style={{padding:'1%', border:'1px solid #EEEEEE', borderTop:'none'}}>
            <Filters
                handleToggleSimpleDialog={handleToggleSimpleDialog}
                applyFilters={applyFilters}
            />
            <div style={{margin:'10px 0'}}></div>
            <PaymentReceiptTable
                data={paymentReceipts}
                user={props.user}
                handleToggleDialog={handleToggleDialog}
                handleToggleSimpleDialog={handleToggleSimpleDialog}
                handleDelete={deleteSingleReceipt}
                handleDeleteMultiplePayment={handleDeleteMultipleReceipt}
                selected={selected}
                setSelected={setSelected}
                handleSelected={handleSelected}
                setLoading={setLoading}
            />
        </div>
        {
            dialog['payment-receipt']?.open ?
            <PaymentReceiptDialog
                open={true}
                handleToggleDialog={handleToggleDialog}
                handleSimpleDialog={handleToggleSimpleDialog}
                // modify={dialog.name === 'payment-receipt-modify' ? true : false}
                modify={dialog['payment-receipt'].data ? true : false}
                // data={dialog.data}
                data={dialog['payment-receipt'].data}
                handlePayment={{
                    onCreate: handleOnCreate, onUpdate: handleOnCreate,
                }}
                setLoading={setLoading}
                token={props.user.token}
            /> : null
        }
        {
            dialog['payment-calculator']?.open ?
            <PaymentCalculatorDialog
                open={true}
                // ticket={currentTicket}
                data={dialog['payment-calculator'].data}
                token={props.user.token}
                // modifyTicket={modifyTicket}
                handleToggleDialog={handleToggleDialog}
                handleToggleSimpleDialog={handleToggleSimpleDialog}
                ticketCompleted={true}
                fromTicket={false}
            /> : null
        }
        {
            simpleDialog.name === 'confirm-dialog' ?
            <ConfirmDialogComponent
                open={true}
                data={simpleDialog}
                handleToggle={handleToggleSimpleDialog}
            /> : null
        }
        {
            simpleDialog.name === 'simple-dialog' ?
                <SimpleConfirmDialog
                    open={true}
                    handleToggle={handleToggleSimpleDialog}
                    data={simpleDialog.msg}
                /> : null
        }
        {
            simpleDialog.name === 'success-dialog' ?
                <SuccessDialog
                    open={true}
                    handleToggle={handleToggleSimpleDialog}
                />: null
        }
        {
            simpleDialog.name === 'error-dialog' ?
                <ErrorDialog
                    open={true}
                    handleToggle={handleToggleSimpleDialog}
                    data={simpleDialog.msg}
                /> : null
        }
      </div>
  );
}

function mapStateToProps(state) {
    return {
      user: state.user,
    };
}
export default connect(mapStateToProps)(PayReceiptView);