import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createInternationalBlackList } from "src/actions";
import idNumberMaskInput from "src/components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "src/components/TextMaskInputComponents/passportMaskInput";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";
import {
  IDENTIFICATION_TYPE,
  translations,
  pepsStatus
} from "src/utils";
import {
  createPepsList as createPep,
  createFileAttachment,
  updatePepsList
} from "src/utils/axios";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AttachFile } from '@material-ui/icons';
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  InputBase
} from "@material-ui/core";
import { green, red, grey } from "@material-ui/core/colors";
import ReplayIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import { SuccessDialog, SimpleConfirmDialog } from '../../../components/SharedComponents/Dialogs';
import Loading from "../../../components/SharedComponents/LoadingScreen";
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';

function PepsListForm(props) {
  const [errorFields, setErrorFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState('');
  const [simpleDialog, setSimpleDialog] = useState({ name: '', data: null, callback: null });
  const [formValues, setFormValues] = useState({
    fullName: '',
    identificationType: '',
    identificationNumber: '',
    status: '',
    employmentLength: '',
    position: '',
    institution: '',
    fileAttachment: image,
  })
  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : '', msg: msg, data: data };
    setSimpleDialog({ ...obj });
  }

  const handleChange = (key) => {
    return (event) => {
      setFormValues({ ...formValues, [key]: event.currentTarget.value, })
    };
  }

  const handleSelectChange = (key) => {
    /* Handle the state of Select components */
    return (event) => {
      setFormValues({ ...formValues, [key]: event.target.value, })
    };
  }

  const handleReset = () => {
    setFormValues({
      ...formValues,
      fullName: '',
      identificationType: '',
      identificationNumber: '',
      status: '',
      employmentLength: '',
      position: '',
      institution: '',
    })
    setPreview(null)
    setErrorFields({ ...{} })
  }

  const getIdentificationInput = (identificationType) => {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }
  
  const setEvidence = (idFile, idRecord) => {
    const newData = {
      fileAttachment: idFile
    }
    const endpoint = updatePepsList(props.user.token, idRecord, newData);
    endpoint.then(() => {
        handleToggleSimpleDialog('success-dialog');
    }).catch(errors => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
    });
}

  const uploadFile = (data, uniqueId) => {
    setLoading(true);
    let formData = new FormData();
    const name = uniqueId
    formData.append("file", data);
    formData.append("name", name);
    const endpoint = createFileAttachment(props.user.token, formData);
    endpoint.then((response) => {

        setEvidence(response.data.uniqueId, uniqueId)
        handleReset()
        setLoading(false);
        handleToggleSimpleDialog('success-dialog');
    }).catch(errors => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
    });
}


  const createPeps = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    let pepData = {
      fullName: formValues.fullName,
      identificationType: formValues.identificationType,
      identificationNumber: formValues.identificationNumber,
      status: formValues.status,
      employmentLength: formValues.employmentLength,
      position: formValues.position,
      institution: formValues.institution,
    }
    const endpoint = createPep;
    endpoint({ ...pepData, token: props.user.token }).then((response) => {
      if (response.data.warning) {
        handleToggleSimpleDialog('simple-dialog', "El proceso fue ejecutado correctamente, pero: " + response.data.warning);
        setLoading(false);
      } else {
        if (image) {
          uploadFile(image, response.data.data.uniqueId)
        }
        handleToggleSimpleDialog('success-dialog');
        handleReset()
        setLoading(false);
      }
    }).catch(errors => {
      setLoading(false);
      errorHandling(errors, handleToggleSimpleDialog);
    });
  }

  useEffect(() => {
    if (image) {
      setImage(image)
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  return (
    <form onSubmit={createPeps} style={{ height: "90vh", overflowY: "auto", padding: 20, }}>
      <Paper style={{ padding: 20 }}>
        <Loading open={loading} />
        <IconButton
          style={{
            "&:hover": {
              backgroundColor: green[200],
            },
            backgroundColor: green[500],
            color: "white",
            display: "flex",
            float: "right",
            position: "relative",
          }}
          onClick={() => props.changeSection(0)}
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography variant="h3">Peps list</Typography>
        <div>
          <br />
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '47%' }}>
              <div style={{ width: '100%' }}>
                <TextField
                  style={{ margin: 8, width: '100%' }}
                  label={translations.FULL_NAME}
                  value={formValues.fullName}
                  onChange={handleChange('fullName')}
                  variant="outlined"
                  required={true}
                  error={errorFields.fullName}
                  helperText={errorFields.fullName ? 'Introduzca un nombre' : null}
                />
                <FormControl
                  style={{ margin: 8, width: '47%' }}
                  error={errorFields.status}
                  variant="outlined"
                >
                  <InputLabel id="status">
                    {translations.STATUS}
                  </InputLabel>
                  <Select
                    id="status"
                    value={formValues.status}
                    style={{ width: '100%' }}
                    label={translations.STATUS}
                    required={true}
                    onChange={handleSelectChange("status")}
                  >
                    {pepsStatus.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{errorFields.status ? 'Seleccione un estado' : null}</FormHelperText>
                </FormControl>
              </div>
              <div style={{ width: '100%' }}>
                <TextField
                  style={{ margin: '5px 1px 8px 8px', width: '47%' }}
                  label={translations.EMPLOYMENT_LENGTH}
                  value={formValues.employmentLength}
                  onChange={handleChange("employmentLength")}
                  variant="outlined"
                  type="number"
                  required={true}
                  error={errorFields.employmentLength}
                  helperText={errorFields.employmentLength ? 'Introduzca la duracion del empleo' : null}
                />

                <FormControl
                  style={{ margin: '5px 0px 7px 5px', width: '50%' }}
                  error={errorFields.identificationType}
                  variant="outlined"
                >
                  <InputLabel id="identificationType">
                    {translations.IDENTIFICATION_TYPE}
                  </InputLabel>
                  <Select
                    id="identificationType"
                    value={formValues.identificationType}
                    style={{ width: '100%' }}
                    label={translations.IDENTIFICATION_TYPE}
                    onChange={handleSelectChange("identificationType")}
                  >
                    {IDENTIFICATION_TYPE.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{errorFields.identificationType ? 'Seleccione un tipo de identificacion' : null}</FormHelperText>
                </FormControl>

                <TextField
                  style={{ margin: 8, width: '100%' }}
                  label={translations.IDENTIFICATION}
                  value={formValues.identificationNumber}
                  onChange={handleChange('identificationNumber')}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={errorFields.identificationNumber}
                  helperText={errorFields.identificationNumber ? 'Introduzca un numero de identificacion' : null}
                  InputProps={getIdentificationInput(
                    formValues.identificationType
                  )}
                />
              </div>
              <TextField
                label={'Posicion'}
                style={{ margin: 8, width: '100%' }}
                variant="outlined"
                onChange={handleChange("position")}
                required={true}
                value={formValues.position}
                error={errorFields.position}
                helperText={errorFields.position ? 'Introduzca una posicion' : null}
              />
              <TextField
                label={'Institucion'}
                style={{ margin: 8, width: '100%' }}
                variant="outlined"
                onChange={handleChange("institution")}
                required={true}
                value={formValues.institution}
                error={errorFields.institution}
                helperText={errorFields.institution ? 'Introduzca una institucion' : null}
              />
            </div>
            <div style={{
              id: 'preview',
              width: '47%',
              height: 550,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: grey[200],
              borderRadius: 20,
              overflow: 'hidden',
            }}>
              <img
                alt="preview"
                src={preview}
                style={{ objectFit: "cover", maxWidth: '100%', minWidth: '-webkit-fill-available' }}
              />
            </div>
            <div />
            <br />
          </div>
          <div style={{ width: '100%', margin: 8 }}>
            <input
              type="file"
              id='file'
              style={{ display: 'none' }}
              accept="image/*"
              onChange={(event) => {
                const file = event.target.files[0];
                setImage(file)
              }}
            />
            <div style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              <div style={{ maxWidth: '65%', display: 'inline-block' }}>
                <label htmlFor='file'>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#43A047', color: '#FFFFFF', fontSize: '12px', display: 'inline-block' }}
                    component="span"
                    disableElevation
                  >
                    <AttachFile style={{ margin: ' 0 5 -4 -5', fontSize: '16px' }} />
                    Elegir
                  </Button>
                </label>

                <InputBase
                  value={image ? image.name : 'Seleccione un archivo'}
                  style={{ color: '#616161', paddingLeft: 5, display: 'inline-block', width: '100%' }}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 20, }}>
            <Button
              variant="contained"
              onClick={handleReset}
              style={{ backgroundColor: red[500], color: "white", }}
            >
              <ReplayIcon />
              &nbsp;
              {translations.RESET}
            </Button>
            <Button
              variant="contained"
              type='submit'
              style={{ backgroundColor: green[500], color: "white", marginLeft: 10 }}
            >
              <SaveIcon />
              &nbsp;
              {translations.SAVE}
            </Button>
          </div>
        </div>
      </Paper>
      {
        simpleDialog.name === 'success-dialog' ?
          <SuccessDialog
            open={true}
            handleToggle={handleToggleSimpleDialog}
          /> : null
      }
      {
        simpleDialog.name === 'simple-dialog' ?
          <SimpleConfirmDialog
            open={true}
            handleToggle={handleToggleSimpleDialog}
            data={simpleDialog.msg}
          /> : null
      }
    </form>
  );
}


function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, { createInternationalBlackList, })(PepsListForm)
