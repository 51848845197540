import * as React from 'react';
import CurrencyInput from 'react-currency-input';

import { InputBaseComponentProps } from '@material-ui/core/InputBase';

const MoneyInputCustom = (props: InputBaseComponentProps) => {
    const { inputRef, onChange, ...others } = props;
    return (
        <CurrencyInput 
            {...others}
            decimalSeparator="."
            thousandSeparator=","
            precision="0"
            onChangeEvent={onChange}
            maxLength={2}
        />
    );
}

export default MoneyInputCustom;