import axios from "axios";
import * as React from "react";
import { connect } from "react-redux";
import jwtDecode from 'jwt-decode';

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import DocumentIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';



import {
  deleteAcquaintanceshipCompanyContract,
  fetchAcquaintanceshipCompanyContract,
  updateAcquaintanceshipCompanyContract,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import ComercialReferencesSelectInput from '../../../components/ComercialReferencesSelectInput';
import ShareholderOrMajorityPartnerSelectInput from "../../../components/AcquaintanceshipProviderSelectInputs/ShareholderOrMajorityPartner";
import {
  catchError,
  jsonToCommercialReferenceArray,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
  capitalizeCaseHandler,
  commercialReferenceArrayToJson,
  shareholderArrayToJson,
  jsonToShareholderArray,
  debtorsValidation,
  filesGeneration
} from "../../../utils";
import {
  API_KEY,
  deleteAcquaintanceshipCompanyContract as deleteLACF,
  fetchAcquaintanceshipCompanyContract as fetchACF,
  updateAcquaintanceshipCompanyContract as updateACF,
  fetchAssignees as fetchA
} from "../../../utils/axios";
import CreditorSelectInput from "../../Creditor/CreditorSelectInput";
import FileUploadSelectInput from "../../FileUpload/FileUploadSelectInput";
import ParticipantSelectInput from "src/containers/Participant/ParticipantSelectInput";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  gridTextField: {
    display: 'block',
    marginTop: 20,
    width: '100%'
  },
});

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ACF: "",
      branch: 'regular loan',
      branchError: '',
      businessName: '',
      contractDate: new Date().toISOString().split('T')[0],
      contractDateError: '',
      creditor: [],
      creditorError: '',
      debtor: [],
      debtorError: '',
      dialog: false,
      dialogTitle: '',
      errorMessage: '',
      infoDestinationError: '',
      loading: false,
      merchantRecordNumberExpirationDate: new Date().toISOString().split('T')[0],
      subsidiary: '',
      commercialReferences: [],
      commercialReferencesError: '',
      title: '',
      titleError: '',
      shareHolders: [],
      loggedUser: "",

      pepMember: false,
      pepMemberPosition: '',
      pepMemberPositionError: '',
      pepMemberEntryDate: '',
      pepMemberEntryDateError: '',
      pepMemberInstitution: '',
      pepMemberInstitutionError: '',

      pepMemberKinship: false,
      pepMemberKinshipPosition: '',
      pepMemberKinshipPositionError: '',
      pepMemberKinshipEntryDate: '',
      pepMemberKinshipEntryDateError: '',
      pepMemberKinshipInstitution: '',
      pepMemberKinshipInstitutionError: '',

    };

    // creditor
    this.handleAddCreditor = this.handleAddCreditor.bind(this);
    this.handleDeleteCreditor = this.handleDeleteCreditor.bind(this);

    // client
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // commercialReference
    this.handleAddCommercialReference = this.handleAddCommercialReference.bind(this);
    this.handleDeleteCommercialReference = this.handleDeleteCommercialReference.bind(this);

    // shareholder
    this.handleAddShareHolder = this.handleAddShareHolder.bind(this);
    this.handleDeleteShareHolder = this.handleDeleteShareHolder.bind(this);

    // core events
    this.generateFile = this.generateFile.bind(this);
    this.changeStatus = this.changeStatus.bind(this);

    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }
  handleCheckBoxChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.checked });
    }
  }
  handleAddCreditor(id) {
    this.setState({ creditor: [id] });
  }
  handleDeleteCreditor(id) {
    this.setState({ creditor: [] });
  }
  handleAddClient(id) {
    this.setState({ debtor: [id] });
  }
  handleDeleteClient(id) {
    this.setState({ debtor: [] });
  }
  handleAddCommercialReference(commercialReference) {
    this.setState((prevState) => ({
      commercialReferences: [...prevState.commercialReferences, commercialReference],
    }));
  }
  handleDeleteCommercialReference(index) {
    this.setState((prevState) => ({
      commercialReferences: prevState.commercialReferences.filter(
        (commercialReference, i) => i !== index
      ),
    }));
  }
  handleAddShareHolder(shareHolder) {
    this.setState((prevState) => ({
      shareHolders: [...prevState.shareHolders, shareHolder],
    }));
  }

  handleDeleteShareHolder(index) {
    this.setState((prevState) => ({
      shareHolders: prevState.shareHolders.filter(
        (shareHolder, i) => i !== index
      ),
    }));
  }
  async generateFile() {
    this.setState({ loading: true });
    if (await debtorsValidation(this, this.props.user.token, this.state.debtor, false)) {
      const url = `/lending/acquaintanceship-company-contracts/${this.state.uniqueId}/print/`
      await filesGeneration(url, this.props.user.token)
      this.setState({ loading: false });
    }
  }
  changeStatus(status) {
    return () => {
      this.setState({ status });
    };
  }
  CancelToken = axios.CancelToken;
  source = this.CancelToken.source();
  abortController = new AbortController()

  componentDidMount() {
    fetchA({ token: this.props.user.token, signal: this.abortController.signal })
      .then(res => {
        try {
          const loggedUser = res.data.find(user => user.id === jwtDecode(this.props.user.token).user_id).username;
          this.setState({ loggedUser: loggedUser }
          )
        } catch (error) { catchError(error, this); console.error(error) }
      })
    fetchACF(this.props.user.token, this.props.id)
      .then((res) => {
        const contract = res.data;
        const commercialReferences = jsonToCommercialReferenceArray(
          contract.commercialReference || { }
        );
        const shareHolders = jsonToShareholderArray(
          contract.shareholders || { }
        );

        this.setState({
          pepMember: contract.peps,
          pepMemberPosition: contract.pepsDescription.pepMemberPosition,
          pepMemberEntryDate: contract.pepsDescription.pepMemberEntryDate,
          pepMemberInstitution: contract.pepsDescription.pepMemberInstitution,
          pepMemberKinship: contract.areQuestionsValidForRelatives,
          pepMemberKinshipPosition: contract.areQuestionsValidForRelativesDescription.pepMemberKinshipPosition,
          pepMemberKinshipEntryDate: contract.areQuestionsValidForRelativesDescription.pepMemberKinshipEntryDate,
          pepMemberKinshipInstitution: contract.areQuestionsValidForRelativesDescription.pepMemberKinshipInstitution,


          ACF: `lending/acquaintanceship-company-contracts/${contract.uniqueId}/upload_attachment/`,
          branch: contract.branch || "",
          status: contract.status || "",
          contractDate: contract.contractDate || "",
          creditor: contract.creditor ? [contract.creditor] : [],
          debtor: contract.debtor ? [contract.debtor] : [],
          dialog: false,
          dialogTitle: "",
          errorMessage: "",
          infoDestination: contract.infoDestination || "",
          loading: false,
          commercialReferences: commercialReferences,
          shareHolders: shareHolders,
          title: contract.title || "",
          uniqueId: contract.uniqueId || "",
          loggedUser: contract.createdBy || "",
          merchantRecordNumberExpirationDate: contract.merchantRecordNumberExpirationDate || "",
          subsidiary: contract.subsidiary || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  handleCurrencyInputChange(key) {
    return (
      event,
      maskedValue,
      floatValue
    ) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  handleSelectChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.value });
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    const commercialReferences = commercialReferenceArrayToJson(state.commercialReferences);
    const shareHolders = shareholderArrayToJson(state.shareHolders);

    const contract = {
      peps: state.pepMember,
      pepsDescription: {
        pepMemberPosition: state.pepMemberPosition,
        pepMemberEntryDate: state.pepMemberEntryDate,
        pepMemberInstitution: state.pepMemberInstitution,
      },
      arePreviousQuestionsValidForDebtorRelatives: state.pepMemberKinship,
      arePreviousQuestionsValidForDebtorRelativesDescription: {
        pepMemberKinshipPosition: capitalizeCaseHandler(state.pepMemberKinshipPosition),
        pepMemberKinshipEntryDate: state.pepMemberKinshipEntryDate,
        pepMemberKinshipInstitution: capitalizeCaseHandler(state.pepMemberKinshipInstitution),
      },
      branch: state.branch,
      contractDate: state.contractDate,
      creditor: (state.creditor[0] || ''),
      debtor: (state.debtor[0] || ''),
      infoDestination: state.infoDestination,
      commercialReference: commercialReferences,
      title: titleCaseHandler(state.title),
      subsidiary: state.subsidiary,
      status: state.status,
      createdBy: state.loggedUser,
      merchantRecordNumberExpirationDate: state.merchantRecordNumberExpirationDate,
      shareholders: shareHolders

    };
    this.setState({ loading: true });

    updateACF(this.props.user.token, this.state.uniqueId, contract)
      .then((res) => {
        this.props.updateAcquaintanceshipCompanyContract(
          res.data
        );
        successHandler(res, this, translations.ACF);
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteLACF(this.props.user.token, this.props.id)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteAcquaintanceshipCompanyContract(this.props.id);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  handleDialogClose() {
    this.setState({ dialog: false });
  }
  render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <FileUploadSelectInput
            contractId={this.state.uniqueId}
            url={this.state.ACF}
            description="ACF"
          />
          <Typography variant="h3">{translations.ACF}</Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={classes.greenButton}
                onClick={this.generateFile}
                style={{ marginRight: 10, marginTop: 25 }}
              >
                <DocumentIcon />
                &nbsp;
                {translations.GENERATE_DOCUMENT}
              </Button>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="status" shrink={true}>{translations.STATUS}</InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleSelectChange('status')}
                  required={true}
                  native={true}
                  inputProps={{
                    id: 'status',
                    name: 'status',
                  }}

                >
                  <option value="pending_signature">{translations.PENDING_SIGNATURE}</option>
                  <option value="internal_signature_pending">{translations.INTERNAL_SIGNATURE_PENDING}</option>
                  <option value="completed">{translations.COMPLETED}</option>
                  <option value="archived">{translations.ARCHIVED}</option>
                </Select>
              </FormControl>
              <div style={{ display: 'flex' }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={this.handleChange('title')}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ''}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.gridTextField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange('contractDate')}
                  required={true}
                  error={this.state.contractDateError !== ''}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    style: styles.checkboxLabel
                  }}
                />
              </div>
              <br />
              <br />
              <Typography variant="h5" className={classes.checkboxLabel}>INFORMACIÓN BÁSICA PERSONAL</Typography>
              <Divider />
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={6}>
                  <TextField
                    label={translations.MERCHANT_RECORD_NUMBER_EXPIRATION_DATE}
                    className={classes.gridTextField}
                    value={this.state.merchantRecordNumberExpirationDate}
                    onChange={this.handleChange('merchantRecordNumberExpirationDate')}
                    type="date"
                    required={true}
                    multiline={false}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <TextField
                    label={translations.SUBSIDIARY}
                    className={classes.gridTextField}
                    value={this.state.subsidiary}
                    onChange={this.handleChange('subsidiary')}
                    multiline={false}
                    fullWidth={true}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
              </Grid>
              <Grid container={true} spacing={3}>
              </Grid>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="branch"
                      style={styles.checkboxLabel}
                      shrink={true}>{translations.BRANCH}</InputLabel>
                    <Select
                      value={this.state.branch}
                      onChange={this.handleSelectChange('branch')}

                      native={true}
                      error={this.state.branchError !== ''}
                      inputProps={{
                        id: 'branch',
                        name: 'branch'
                      }}
                    >
                      <option value="regular loan">{translations.REGULAR_LOAN}</option>
                      <option value="factoring">{translations.FACTORING}</option>
                      <option value="rental management">{translations.RENTAL_MANAGMENT}</option>
                      <option value="administration of accounts receivable">{translations.ADMINISTRATION_ACCOUNT_RECEIVABLE}</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="infoDestination" shrink={true}>{translations.INFO_DESTINATION}</InputLabel>
                    <Select
                      value={this.state.infoDestination}
                      onChange={this.handleSelectChange('infoDestination')}

                      native={true}
                      error={this.state.infoDestinationError !== ''}
                      inputProps={{
                        id: 'infoDestination',
                        name: 'infoDestination'
                      }}
                    >
                      <option value="legal office">{translations.LEGAL_OFFICE}</option>
                      <option value="main office">{translations.MAIN_OFFICE}</option>
                      <option value="email">{translations.EMAIL}</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container={true} spacing={3}>
                <br />
                <br />
              </Grid>
              <Grid item={true} xs={4}>
              </Grid>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>{translations.CREDITORS}</Typography>
                <CreditorSelectInput
                  selectedCreditorsId={this.state.creditor}
                  addCreditor={this.handleAddCreditor}
                  deleteCreditor={this.handleDeleteCreditor}
                />
                <p className={classes.errorInput}>{this.state.creditorsError}</p>
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>{translations.DEBTOR}</Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtor}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.SHARE_HOLDER}
                </Typography>
                <ShareholderOrMajorityPartnerSelectInput
                  tableLimit={5}
                  partners={this.state.shareHolders}
                  add={this.handleAddShareHolder}
                  delete={this.handleDeleteShareHolder}
                />
                <p className={classes.errorInput}>
                  {this.state.shareholderError}
                </p>
              </div>
              <br />
              <Grid container={true} className={classes.half}>
                <Grid item={true} xs={12}>
                  <Typography variant="h6" className={classes.checkboxLabel}>{translations.COMMERCIAL_REFERENCES}</Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  <ComercialReferencesSelectInput
                    tableLimit={5}
                    commercialReferences={this.state.commercialReferences}
                    add={this.handleAddCommercialReference}
                    delete={this.handleDeleteCommercialReference}
                  />
                  <p className={classes.errorInput}>{this.state.commercialReferencesError}</p>
                </Grid>
              </Grid>
              <FormControlLabel
                label={"¿Existe alguna persona políticamente expuesta y/o figura pública dentro de los socios o consejo directivo?"}
                control={<CheckBox
                  color="primary"
                  checked={this.state.pepMember}
                  onChange={this.handleCheckBoxChange('pepMember')}
                />}
                value='pepMember'
              />
              {this.state.pepMember ?
                <div>

                  <TextField
                    label={"Cargo, Rango o Posición en Gobierno"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.pepMemberPosition)}
                    onChange={this.handleChange('pepMemberPosition')}
                    fullWidth={true}
                    required={true}
                    error={this.state.pepMemberPositionError !== ''}
                    helperText={this.state.pepMemberPositionError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                  <TextField
                    label={"Fecha desde que ocupa el cargo, Rango o Posición"}
                    className={classes.gridTextField}
                    value={titleCaseHandler(this.state.pepMemberEntryDate)}
                    onChange={this.handleChange('pepMemberEntryDate')}
                    required={true}
                    type={"date"}
                    error={this.state.pepMemberEntryDateError !== ''}
                    helperText={this.state.pepMemberEntryDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel
                    }}
                  />
                  <br />
                  <TextField
                    label={"Institución a la que pertenece"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.pepMemberInstitution)}
                    onChange={this.handleChange('pepMemberInstitution')}
                    fullWidth={true}
                    required={true}
                    error={this.state.pepMemberInstitutionError !== ''}
                    helperText={this.state.pepMemberInstitutionError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                </div> : null
              }
              <FormControlLabel
                label={"¿Existe alguna persona dentro de los socios o consejo directivo con algún parentesco a una persona persona politicamente expuesta y/o figura pública?"}
                control={<CheckBox
                  color="primary"
                  checked={this.state.pepMemberKinship}
                  onChange={this.handleCheckBoxChange('pepMemberKinship')}
                />}
                value='pepMemberKinship'
              />
              {this.state.pepMemberKinship ?
                <div>
                  <TextField
                    label={"Cargo, Rango o Posición en Gobierno"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.pepMemberKinshipPosition)}
                    onChange={this.handleChange('pepMemberKinshipPosition')}
                    fullWidth={true}
                    required={true}
                    error={this.state.pepMemberKinshipPositionError !== ''}
                    helperText={this.state.pepMemberKinshipPositionError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                  <TextField
                    label={"Fecha desde que ocupa el cargo, Rango o Posición"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.pepMemberKinshipEntryDate)}
                    onChange={this.handleChange('pepMemberKinshipEntryDate')}
                    required={true}
                    type={"date"}
                    error={this.state.pepMemberKinshipEntryDateError !== ''}
                    helperText={this.state.pepMemberKinshipEntryDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel
                    }}
                  />
                  <br />
                  <TextField
                    label={"Institución a la que pertenece"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.pepMemberKinshipInstitution)}
                    onChange={this.handleChange('pepMemberKinshipInstitution')}
                    fullWidth={true}
                    required={true}
                    error={this.state.pepMemberKinshipInstitutionError !== ''}
                    helperText={this.state.pepMemberKinshipInstitutionError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                </div> : null
              }
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form >
    );
  }
}

function mapStateToProps(state) {
  return {
    acquaintanceshipCompanyContracts: state.acquaintanceshipCompanyContracts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteAcquaintanceshipCompanyContract,
    fetchAcquaintanceshipCompanyContract,
    updateAcquaintanceshipCompanyContract,
  })(View)
);
