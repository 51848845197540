import React, { useState } from 'react';

import './Header.css';
import CircleMenu from '../UI/CircleMenu';


const Header = ({options=[]}) => {
    // isActive -> boolean: False by default
    // setActive: handler to toogle the isActive property
    const [isActive, setActive] = useState(false)
    return (
        <div className="header-container">
            <h2 className='title'>Cooperativa Barcelona</h2>
            <CircleMenu
                isActive={isActive}
                onClick={() => setActive(!isActive)}
                options={options} />
        </div>
    );
}

export default Header;