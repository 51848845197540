import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  IconButton,
  Divider,
  Paper,
} from "@material-ui/core";
import {
  AutocompleteField,
  TextFieldComponent,
  DateField,
  NumericTextField,
  SearchComponent,
  CheckboxField,
} from "src/components/SharedComponents/Fields";
import {
  ConfirmDialog,
  DialogHeader,
  DialogFooter,
} from "src/components/SharedComponents/Dialogs";
import {
  dateFormat,
  errorHandling,
  validateRequiredFields,
} from "src/components/SharedComponents/CustomHooks";
import { IDENTIFICATION_TYPE } from "src/utils";
import phoneMaskInput from "src/components/TextMaskInputComponents/phoneWithOneMaskInput";
import { fetchPositions } from "src/utils/axios";

function HumanResource(props) {
  const defaultFieldsValue = {
    identificationType: {
      value: "national",
      label: "Cédula",
    },
    email: "",
    fullName: "",
    identificationNumber: "",
    IdTuNomina: "",
    position: "",
    cellPhone: null,
    workPhone: null,
    workPhoneExtension: null,
    sendMails: false,
  };
  const requiredFields = [
    "fullName",
    "email",
    "position",
    "identificationNumber",
    "cellPhone",
  ];
  const [errorFields, setErrorFields] = useState({});
  const [error, setError] = useState(false);
  const [fields, setField] = useState(defaultFieldsValue);
  const action = props.modify ? "update" : "create";
  const valRequiredFields = validateRequiredFields.bind(
    this,
    fields,
    requiredFields,
    errorFields,
    setErrorFields,
    setError
  );

  const handleSaveBtn = () => {
    if (valRequiredFields("all")) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `Favor llenar todos los campos requeridos.`
      );
      return;
    }
    const data = {
      email: fields.email || "",
      fullName: fields.fullName || "",
      identificationType:
        fields.identificationType?.value || fields.identificationType || "",
      identificationNumber: fields.identificationNumber || "",
      IdTuNomina: fields.IdTuNomina || "",
      position: fields.position?.uniqueId || fields.position || "",
      cellPhone: fields.cellPhone || "",
      workPhone: fields.workPhone || "",
      workPhoneExtension: fields.workPhoneExtension || "",
      sendMails: fields.sendMails || false,
      uniqueId: fields.uniqueId || null,
    };

    props.handleSave(action, data);
  };
  const handleSelectValue = (value, options, property) => {
    // handle logic of multi select
    let result = value;
    if (typeof result !== "object") {
      result = options.find((option) => option[property] === value);
    }
    if (Array.isArray(value)) {
      const checkIfContainsObject = value.find(
        (item) => typeof item === "object"
      );
      if (!checkIfContainsObject) {
        result = options.filter((option) => value.includes(option[property]));
      }
    }
    return result;
  };
  const getFiltersOptions = (option) => {
    return option ? option : [];
  };
  const handleFieldChange = (field, event, value) => {
    let newVal;

    switch (field) {
      case "identificationType":
        newVal = value;
        break;
      case "position":
        newVal = event;
        break;
      default:
        newVal = event.target ? event.target.value : event.value;
        break;
    }
    valRequiredFields("single", field, newVal);
    setField((prevState) => ({ ...prevState, [field]: newVal })); //this way the state of the object property updates.
  };

  const idProps = {
    xs: 4,
    fullWidth: true,
    fieldLabel: `Identificación*`,
    placeholder: "Número único del documento de identifación",
    fieldID: `field-identificationNumber`,
    decimalScale: 0,
    value: fields.identificationNumber || "",
    onChange: handleFieldChange.bind(this, "identificationNumber"),
    error: Boolean(errorFields.identificationNumber),
    helperText: errorFields.identificationNumber
      ? errorFields.identificationNumber
      : null,
  };

  const handleEditRow = (data) => {
    setField({ ...data });
  };
  useEffect(() => {
    if (props.modify) {
      handleEditRow(props.data);
    }
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={props.open}
    >
      <DialogHeader
        dialogTitle="Recursos Humanos"
        handleClick={props.handleToggleDialog.bind(this, undefined)}
      />
      <DialogContent style={{ margin: "2% 0" }}>
        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          <TextFieldComponent
            xs={6}
            fullWidth
            fieldLabel="Nombre Completo*"
            fieldID="field-fullName"
            placeholder="Ej: Carmen Rosario Espinosa"
            value={fields.fullName ? fields.fullName : ""}
            onChange={handleFieldChange.bind(this, "fullName")}
            error={Boolean(errorFields.fullName)}
            helperText={errorFields.fullName ? errorFields.fullName : null}
          />
          <TextFieldComponent
            xs={6}
            fullWidth
            fieldLabel="Correo Electrónico*"
            fieldID="field-email"
            placeholder="Ej: cespinosa@example.com"
            value={fields.email ? fields.email : ""}
            onChange={handleFieldChange.bind(this, "email")}
            error={Boolean(errorFields.email)}
            helperText={errorFields.email ? errorFields.email : null}
          />
        </Grid>
        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          <AutocompleteField
            xs={4}
            fieldLabel="Tipo de Identificación"
            fieldID="field-identificationType"
            fieldInnerLabel="Seleccione tipo identificación"
            value={
              handleSelectValue(
                fields.identificationType,
                IDENTIFICATION_TYPE,
                "value"
              ) || null
            }
            handleChange={(e, val) => {
              handleFieldChange("identificationNumber", e, "");
              handleFieldChange("identificationType", e, val);
            }}
            options={getFiltersOptions(IDENTIFICATION_TYPE)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.value === value.value}
            error={Boolean(errorFields.identificationType)}
            helperText={
              errorFields.identificationType
                ? errorFields.identificationType
                : null
            }
            multiple={false}
          />
          {fields.identificationType === "national" ||
          fields.identificationType?.value === "national" ? (
            <NumericTextField {...idProps} format="###-#######-#" />
          ) : (
            <TextFieldComponent {...idProps} />
          )}
          <SearchComponent
            xs={4}
            fieldLabel="Posición*"
            fieldID="field-position"
            fieldInnerLabel="Seleccione una posición"
            handleChange={handleFieldChange.bind(this, "position")}
            optionType={"position"}
            savedValue={
              handleSelectValue(
                fields.position,
                IDENTIFICATION_TYPE,
                "value"
              ) || null
            }
            clearOnBlur={false}
            limitTags={1}
            error={Boolean(errorFields.position)}
            helperText={errorFields.position ? errorFields.position : null}
          />
          {/* <AutocompleteField
            xs={4}
            fieldLabel="Posición"
            fieldID="field-position"
            fieldInnerLabel="Seleccione una posición"
            value={
              handleSelectValue(
                fields.position,
                props.options.position,
                "name"
              ) || null
            }
            handleChange={handleFieldChange.bind(this, "position")}
            options={getFiltersOptions(props.options.position || [])}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) =>
              option.uniqueId === value.uniqueId
            }
            multiple={false}
            error={Boolean(errorFields.position)}
            helperText={errorFields.position ? errorFields.position : null}
          /> */}
        </Grid>

        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          <TextFieldComponent
            xs={4}
            fieldLabel="Teléfono celular*"
            fieldID="field-cellphone"
            placeholder="Ej: +1(809)000-0001"
            value={fields.cellPhone || ""}
            onChange={handleFieldChange.bind(this, "cellPhone")}
            fullWidth
            type="phone"
            InputProps={{
              inputComponent: phoneMaskInput,
            }}
          />
          <TextFieldComponent
            xs={4}
            fieldLabel="Teléfono Trabajo"
            fieldID="field-workPhone"
            placeholder="Ej: +1(809)000-0001"
            value={fields.workPhone || ""}
            fullWidth
            onChange={handleFieldChange.bind(this, "workPhone")}
            type="phone"
            InputProps={{
              inputComponent: phoneMaskInput,
            }}
          />
          <TextFieldComponent
            xs={4}
            fieldLabel="Extension"
            fullWidth
            fieldID="field-workPhoneExtension"
            placeholder="Ej: +1(809)000-0001"
            value={fields.workPhoneExtension || ""}
            onChange={handleFieldChange.bind(this, "workPhoneExtension")}
          />
        </Grid>

        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="flex-end"
          style={{ padding: "0 1%" }}
        ></Grid>

        <Grid
          container
          justifyContent="flex-end"
          style={{ marginTop: "3%", paddingRight: "1%" }}
        >
          <Button
            variant="contained"
            className={
              error ? "action-method-btn disabled" : "action-method-btn save"
            }
            disableElevation
            size="small"
            onClick={handleSaveBtn}
            disabled={error}
          >
            guardar cambios
          </Button>
        </Grid>
      </DialogContent>
      <DialogFooter
      // dialogFooterText="Guardar cambios"
      />
    </Dialog>
  );
}

export { HumanResource };
