import { AxiosError } from "axios";
import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import FileUploadSelectInput from "src/containers/FileUpload/FileUploadSelectInput";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import { red, indigo } from "@material-ui/core/colors/";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CachedIcon from "@material-ui/icons/Cached";

import {
  deleteFinancialInstitution,
  fetchFinancialInstitution,
  updateFinancialInstitution,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import phoneMaskInput from "../../../components/TextMaskInputComponents/phoneWithOneMaskInput";
import rncMaskInput from "../../../components/TextMaskInputComponents/rncMaskInput";
import {
  catchError,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
  paymentFrequency,
  institutionClassification,
  institutionStatus,
} from "../../../utils";
import {
  deleteInstitution,
  fetchInstitution,
  updateInstitution,
  uploadInstitutionToRohi,
  changeEmployerCode,
  fetchAssignees,
} from "../../../utils/axios";
import Address from "../../../components/Address/Address";
import MainAddress from "../../../components/Address/MainAddress";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import HumanResourcesView from "src/components/HumanResources/View";
import jwtDecode from "jwt-decode";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  refreshButton: {
    backgroundColor: indigo[500],
    color: "white",
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface IInstitutionViewProps {
  institutions: IInstitution[];
  institutionId: string;
  classes: { [key: string]: string };

  user: IUser;
  changeSection(sectionNumber: number): void;
  deleteFinancialInstitution(uniqueId: string): IAction<IInstitution[]>;
  fetchFinancialInstitution(institution: IInstitution): IAction<IInstitution[]>;
  updateFinancialInstitution(
    institution: IInstitution
  ): IAction<IInstitution[]>;
}

interface IInstitutionViewState {
  [key: string]: string | number | boolean | Blob | string[] | any;
  abbreviation: string;
  abbreviationError: string;

  city: string;
  cityError: string;

  country: string;
  countryError: string;

  reference: string;
  referenceError: string;

  sector: string;
  sectorError: string;

  state: string;
  stateError: string;

  email: string;
  emailError: string;

  mainAddressCity: string;
  mainAddressCityError: string;

  mainAddressCountry: string;
  mainAddressCountryError: string;

  mainAddressReference: string;
  mainAddressReferenceError: string;

  mainAddressSector: string;
  mainAddressSectorError: string;

  mainAddressState: string;
  mainAddressStateError: string;

  merchantRecord: string;
  merchantRecordError: string;

  name: string;
  nameError: string;

  paymentFrequency: string;
  paymentFrequencyError: any;

  paymentDay: any;
  paymentDayError: any;

  secondPaymentDay: number;
  secondPaymentDayError: any;

  institutionClassification: string;
  institutionClassificationError: string;

  phoneNumber: string;
  phoneNumberError: string;

  registry: string;
  registryError: string;

  region: string;
  regionError: string;

  mainAddressRegion: string;
  mainAddressRegionError: string;

  registryEndDate: string;
  registryEndDateError: string;

  tradename: string;
  tradenameError: string;

  website: string;
  websiteError: string;

  FI: string;
  rohiId: string;
  rohiIdPatrono: string;

  swiftsCode: string;

  institution: string[];

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
  uniqueId: string;
  employerCode: string;

  status: string;
  openConfirm: boolean;
  roles: string[];
}
class FinancialInstitutionView extends React.Component<
  IInstitutionViewProps,
  IInstitutionViewState
> {
  public state: IInstitutionViewState = {
    FI: "",
    abbreviation: "",
    abbreviationError: "",
    city: "",
    cityError: "",
    country: "República Dominicana",
    countryError: "",
    dialog: false,
    dialogTitle: "",
    email: "",
    emailError: "",
    errorMessage: "",
    loading: false,
    mainAddressCity: "",
    mainAddressCityError: "",
    mainAddressCountry: "",
    mainAddressCountryError: "",
    mainAddressReference: "",
    mainAddressReferenceError: "",
    mainAddressRegion: "",
    mainAddressRegionError: "",
    mainAddressSector: "",
    mainAddressSectorError: "",
    mainAddressState: "",
    mainAddressStateError: "",
    merchantRecord: "",
    merchantRecordError: "",
    name: "",
    nameError: "",
    paymentDay: 0,
    paymentDayError: "",
    secondPaymentDay: 0,
    secondPaymentDayError: "",
    paymentFrequency: "monthly",
    paymentFrequencyError: "",
    institutionClassification: "",
    institutionClassificationError: "",
    phoneNumber: "",
    phoneNumberError: "",
    reference: "",
    referenceError: "",
    region: "",
    regionError: "",
    registry: "",
    registryEndDate: "",
    registryEndDateError: "",
    registryError: "",
    sector: "",
    sectorError: "",
    state: "",
    stateError: "",
    tradename: "",
    tradenameError: "",
    uniqueId: "",
    website: "",
    websiteError: "",
    rohiId: "",
    rohiIdPatrono: "",
    swiftsCode: "",
    institution: [],
    employerCode: "",
    status: "",
    statusError: "",
    openConfirm: false,
    roles: [],
  };
  constructor(props: IInstitutionViewProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectMain = this.handleSelectMain.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleRohiSubmit = this.handleRohiSubmit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);

    this.changeEmployerCode = this.changeEmployerCode.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.openConfirm = this.openConfirm.bind(this);
  }

  public getInstitutions() {
    fetchInstitution(this.props.user.token, this.props.institutionId)
      .then((res) => {
        const data = res.data.results ? res.data.results : res.data;
        const curInstitution = data as IInstitution;

        this.setState({
          FI: `crm/institution/${curInstitution.uniqueId}/upload_logo/`,
          abbreviation: curInstitution.abbreviation || "",
          city: curInstitution.address ? curInstitution.address.city : "",
          country: curInstitution.address ? curInstitution.address.country : "",
          email: curInstitution.email || "",
          rohiId: curInstitution.rohiId || "",
          rohiIdPatrono: curInstitution.rohiIdPatrono || "",
          swiftsCode: curInstitution.swiftsCode || "",
          loading: false,
          mainAddressCity: curInstitution.mainAddress
            ? curInstitution.mainAddress.city
            : "",
          mainAddressCountry: curInstitution.mainAddress
            ? curInstitution.mainAddress.country
            : "",
          mainAddressReference: curInstitution.mainAddress
            ? curInstitution.mainAddress.reference
            : "",
          mainAddressRegion: curInstitution.mainAddress
            ? curInstitution.mainAddress.region
            : "",
          mainAddressSector: curInstitution.mainAddress
            ? curInstitution.mainAddress.sector
            : "",
          mainAddressState: curInstitution.mainAddress
            ? curInstitution.mainAddress.state
            : "",
          merchantRecord: curInstitution.merchantRecord || "",
          name: curInstitution.name || "",
          paymentDay: curInstitution.paymentDay || "",
          secondPaymentDay: curInstitution.secondPaymentDay || 0,
          paymentFrequency: curInstitution.paymentFrequency || "",
          institutionClassification:
            curInstitution.institutionClassification || "",
          phoneNumber: curInstitution.phoneNumber || "",
          reference: curInstitution.address
            ? curInstitution.address.reference
            : "",
          region: curInstitution.address ? curInstitution.address.region : "",
          registry: curInstitution.registry || "",
          registryEndDate: curInstitution.registryEndDate || "",
          sector: curInstitution.address ? curInstitution.address.sector : "",
          state: curInstitution.address ? curInstitution.address.state : "",
          tradename: curInstitution.tradename || "",
          uniqueId: curInstitution.uniqueId || "",
          website: curInstitution.website || "",
          employerCode: curInstitution.employerCode || "",
          status: curInstitution.state || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public componentDidMount() {
    this.setState({ loading: true });
    this.getInstitutions();
    const userId: any = jwtDecode<IUser>(this.props.user.token).user_id;
    fetchAssignees({ token: this.props.user.token, id: userId }).then(
      (response) => {
        const data = response.data[0];
        this.setState({ roles: data.groups, loading: false });
      }
    );

    setTimeout(() => {
      this.setState({});
    }, 5000);
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleDateChange = (type: string, shortDate: any) => {
    let data = this.state.paymentDays;
    const newDate = String(shortDate).slice(8, 10);

    if (data.includes(Number(newDate))) {
      data = data.filter((item: any) => item !== Number(newDate));
    } else {
      data.push(Number(newDate));
    }

    this.setState({
      [type]: data,
    });
  };
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }

  public handleSelect(key: string, value: string) {
    this.setState({
      [key]: value,
    });
  }
  public handleSelectMain(key: string, value: string) {
    this.setState({
      [key]: value,
    });
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    let institution: IInstitution = {
      state: this.state.status,
      abbreviation: this.state.abbreviation.toUpperCase(),
      address: {
        city: this.state.city,
        country: this.state.country,
        reference: this.state.reference,
        region: this.state.region,
        sector: titleCaseHandler(this.state.sector),
        state: this.state.state,
      },
      email: this.state.email,
      mainAddress: {
        city: this.state.mainAddressCity,
        country: this.state.mainAddressCountry,
        reference: this.state.mainAddressReference,
        region: this.state.mainAddressRegion,
        sector: titleCaseHandler(this.state.mainAddressSector),
        state: this.state.mainAddressState,
      },
      merchantRecord: this.state.merchantRecord,
      name: this.state.name.toUpperCase(),
      paymentFrequency: this.state.paymentFrequency,
      paymentDay: this.state.paymentDay,
      secondPaymentDay: this.state.secondPaymentDay,
      institutionClassification: this.state.institutionClassification,
      phoneNumber: this.state.phoneNumber,
      registry: this.state.registry.replace(/-/g, ""),
      registryEndDate: this.state.registryEndDate,
      tradename: this.state.tradename,
      website: this.state.website,
    };

    this.setState({ loading: true });

    const {
      mainAddressCity,
      mainAddressCountry,
      mainAddressRegion,
      mainAddressState,
      mainAddressReference,
      mainAddressSector,
      merchantRecord,
      tradename,
      registryEndDate,
    } = this.state;

    if (
      mainAddressCity === null ||
      (mainAddressCity.trim() === "" && mainAddressCountry === null) ||
      (mainAddressCountry.trim() === "" && mainAddressReference === null) ||
      (mainAddressReference.trim() === "" && mainAddressSector === null) ||
      (mainAddressSector.trim() === "" && mainAddressState === null) ||
      (mainAddressState.trim() === "" && mainAddressRegion === null) ||
      mainAddressRegion.trim() === ""
    ) {
      // @ts-ignore
      institution = Object.assign(institution, { mainAddress: null });
    }

    if (merchantRecord.trim() === "") {
      institution = Object.assign(institution, { merchantRecord: null });
    }

    if (tradename.trim() === "") {
      institution = Object.assign(institution, { tradename: null });
    }

    if (registryEndDate.trim() === "") {
      institution = Object.assign(institution, { registryEndDate: null });
    }

    updateInstitution(this.props.user.token, this.state.uniqueId, institution)
      .then((res) => {
        this.props.updateFinancialInstitution(res.data as IInstitution);
        successHandler(res, this, translations.INSTITUTION);
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }

  public changeEmployerCode() {
    this.setState({ loading: true });
    changeEmployerCode(this.props.user.token, this.state.uniqueId)
      .then((res) => {
        this.setState({ loading: true, employerCode: res.data.employerCode });
        this.closeConfirm();
        successHandler(res, this, "Código de empleador actualizado");
      })
      .catch((err: AxiosError) => {
        this.closeConfirm();
        catchError(err, this);
      });
  }

  public closeConfirm() {
    this.setState({ openConfirm: false });
  }

  public openConfirm() {
    this.setState({ openConfirm: true });
  }

  public handleRohiSubmit() {
    resetError(this);

    this.setState({ loading: true });

    uploadInstitutionToRohi(this.props.user.token, this.state.uniqueId)
      .then((res) => {
        successHandler(res, this, translations.INSTITUTION);
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }
  public handleDelete() {
    if (confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });

      deleteInstitution(this.props.user.token, this.props.institutionId)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteFinancialInstitution(this.props.institutionId);
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }

  public handleDialogClose() {
    this.setState({ dialog: false });
  }

  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Dialog
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={this.state.openConfirm}
        >
          <DialogTitle
            id="confirmation-dialog-title"
            style={{ backgroundColor: "#f7f4f4", textAlign: "center" }}
          >
            Cambiar código de empleador
          </DialogTitle>
          <DialogContent dividers>
            <p>
              Esta accion no podra ser revertida una vez realizada.{" "}
              <strong>¿Desea cambia el código de empleador?</strong>
            </p>
          </DialogContent>
          <DialogActions
            style={{
              backgroundColor: "rgb(247 247 247 / 75%)",
              textAlign: "center",
            }}
          >
            <Button autoFocus onClick={this.closeConfirm} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.changeEmployerCode}>
              <span style={{ color: "#027902" }}>Actualizar</span>
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className={classes.paper}>
          <FileUploadSelectInput
            contractId={this.state.uniqueId}
            description="logo"
            url={this.state.FI}
          />
          <Typography variant="h3">{translations.INSTITUTION}</Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <FormControl
                className={classes.textField}
                error={this.state.statusError !== ""}
              >
                <InputLabel htmlFor="status" shrink={true}>
                  {translations.STATUS}
                </InputLabel>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                  disabled={
                    !this.state.roles.includes("institution-change-state")
                  }
                  required={this.state.roles.includes(
                    "institution-change-state"
                  )}
                  inputProps={{
                    id: "status",
                    name: "status",
                  }}
                >
                  {institutionStatus.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Grid container spacing={3}>
                <Grid item xs={4} sm={4}>
                  <TextField
                    label={"Código empleador"}
                    className={classes.textField}
                    value={this.state.employerCode}
                    onChange={this.handleChange("employerCode")}
                    fullWidth={true}
                    required={true}
                    disabled={true}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Cambiar código de empleador"
                            color="primary"
                            onClick={this.openConfirm}
                          >
                            <CachedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={this.state.name.toUpperCase()}
                onChange={this.handleChange("name")}
                fullWidth={true}
                required={true}
                disabled={true}
                error={this.state.nameError !== ""}
                helperText={this.state.nameError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.ABBREVIATION}
                className={classes.textField}
                value={this.state.abbreviation.toUpperCase()}
                onChange={this.handleChange("abbreviation")}
                fullWidth={true}
                required={true}
                error={this.state.abbreviationError !== ""}
                helperText={this.state.abbreviationError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                className={classes.textField}
                label={translations.RNC}
                placeholder="0-00-00000-0"
                value={this.state.registry}
                onChange={this.handleChange("registry")}
                fullWidth={true}
                required={true}
                error={this.state.registryError !== ""}
                helperText={this.state.registryError}
                InputProps={{
                  inputComponent: rncMaskInput,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.REGISTRY_END_DATE}
                className={classes.textField}
                value={this.state.registryEndDate}
                type="date"
                onChange={this.handleChange("registryEndDate")}
                error={this.state.registryEndDateError !== ""}
                helperText={this.state.registryEndDateError}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.EMAIL}
                className={classes.textField}
                placeholder="mycompany@email.com"
                type="email"
                value={this.state.email}
                onChange={this.handleChange("email")}
                fullWidth={true}
                required={true}
                error={this.state.emailError !== ""}
                helperText={this.state.emailError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.WEBSITE}
                className={classes.textField}
                placeholder="https://www.mysite.com"
                type="url"
                value={this.state.website}
                onChange={this.handleChange("website")}
                fullWidth={true}
                error={this.state.websiteError !== ""}
                helperText={this.state.websiteError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.PHONE}
                className={classes.textField}
                value={this.state.phoneNumber}
                onChange={this.handleChange("phoneNumber")}
                type="phone"
                placeholder="+1(809) 000-0000"
                fullWidth={true}
                required={true}
                error={this.state.phoneNumberError !== ""}
                helperText={this.state.phoneNumberError}
                InputProps={{
                  inputComponent: phoneMaskInput,
                }}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              />
              <FormControl
                className={classes.textField}
                error={this.state.paymentFrequencyError !== ""}
              >
                <InputLabel htmlFor="paymentFrequency" shrink={true}>
                  {translations.PAYMENT_FREQUENCY}
                </InputLabel>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.paymentFrequency}
                  onChange={this.handleSelectChange("paymentFrequency")}
                  required={true}
                  inputProps={{
                    id: "paymentFrequency",
                    name: "paymentFrequency",
                  }}
                >
                  {paymentFrequency.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                label={"Dias de pago"}
                type="number"
                className={classes.textField}
                value={this.state.paymentDay}
                onChange={this.handleChange("paymentDay")}
                fullWidth={true}
                error={this.state.paymentDayError.length === 0 ? false : true}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
                inputProps={{
                  max: 31,
                  min: 1,
                  step: 1,
                }}
              />
              {this.state.paymentFrequency === "biweekly" ? (
                <TextField
                  label={"Dias de pago 2"}
                  type="number"
                  className={classes.textField}
                  value={this.state.secondPaymentDay}
                  onChange={this.handleChange("secondPaymentDay")}
                  fullWidth={true}
                  error={
                    this.state.secondPaymentDayError.length === 0 ? false : true
                  }
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                  inputProps={{
                    max: 31,
                    min: 1,
                    step: 1,
                  }}
                />
              ) : null}
              <FormControl
                className={classes.textField}
                error={this.state.institutionClassificationError !== ""}
              >
                <InputLabel htmlFor="institutionClassification" shrink={true}>
                  Clasificación de institución
                </InputLabel>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.institutionClassification}
                  onChange={this.handleSelectChange(
                    "institutionClassification"
                  )}
                  required={true}
                  inputProps={{
                    id: "institutionClassification",
                    name: "institutionClassification",
                  }}
                >
                  {institutionClassification.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                label={translations.MERCHANT_RECORD}
                className={classes.textField}
                value={this.state.merchantRecord}
                onChange={this.handleChange("merchantRecord")}
                fullWidth={true}
                error={this.state.merchantRecordError !== ""}
                helperText={this.state.merchantRecordError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.TRADE_NAME}
                className={classes.textField}
                value={this.state.tradename.toUpperCase()}
                onChange={this.handleChange("tradename")}
                fullWidth={true}
                error={this.state.tradenameError !== ""}
                helperText={this.state.tradenameError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Rohi ID"}
                    className={classes.textField}
                    value={this.state.rohiId}
                    disabled={true}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Rohi ID Patrono"}
                    className={classes.textField}
                    value={this.state.rohiIdPatrono}
                    disabled={true}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
              </Grid>
              <br />
              <TextField
                label={"Codigo Swifts"}
                className={classes.textField}
                value={this.state.swiftsCode}
                fullWidth={true}
                disabled={true}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <Address
                city={this.state.city}
                cityError={this.state.cityError}
                country={this.state.country}
                countryError={this.state.countryError}
                handleChange={this.handleChange}
                handleSelect={this.handleSelect}
                reference={this.state.reference}
                referenceError={this.state.referenceError}
                region={this.state.region}
                regionError={this.state.regionError}
                sector={this.state.sector}
                sectorError={this.state.sectorError}
                state={this.state.state}
                stateError={this.state.stateError}
                title={translations.ADDRESS}
              />
              <MainAddress
                mainAddressCity={this.state.mainAddressCity}
                mainAddressCityError={this.state.mainAddressCityError}
                mainAddressCountry={this.state.mainAddressCountry}
                mainAddressCountryError={this.state.mainAddressCountryError}
                handleChange={this.handleChange}
                handleSelect={this.handleSelectMain}
                mainAddressReference={this.state.mainAddressReference}
                mainAddressReferenceError={this.state.mainAddressReferenceError}
                mainAddressRegion={this.state.mainAddressRegion}
                mainAddressRegionError={this.state.mainAddressRegionError}
                mainAddressSector={this.state.mainAddressSector}
                mainAddressSectorError={this.state.mainAddressSectorError}
                mainAddressState={this.state.mainAddressState}
                mainAddressStateError={this.state.mainAddressStateError}
                title={translations.MAIN_ADDRESS}
              />
              <br />
              {this.state.uniqueId ? (
                <HumanResourcesView institution={this.state.uniqueId} />
              ) : null}
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                {this.state.uniqueId ? (
                  <Button
                    onClick={this.handleRohiSubmit}
                    variant="contained"
                    className={classes.greenButton}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <SaveIcon />
                    &nbsp; Enviar a Rohi
                  </Button>
                ) : null}
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    institutions: state.financialInstitutions,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteFinancialInstitution,
    fetchFinancialInstitution,
    updateFinancialInstitution,
  })(FinancialInstitutionView)
);
