import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  Tooltip,
  Grid,
  Card,
  CardContent,
  Divider,
  IconButton,
} from "@material-ui/core";
import {
  CheckCircleOutlined,
  NavigateNext,
  NavigateBefore,
  ExpandMore,
  RemoveCircleOutline,
  Print,
  Visibility,
  Save,
  Edit,
} from "@material-ui/icons";
import {
  fetchTime,
  fetchTicket,
  fetchQuotation,
  updateQuotation,
  fetchOutlayCheck,
  updateOutlayCheck,
  fetchBankAccounts,
  fetchDepositBankAccounts,
  createOutlayCheck,
  createCheck,
  fetchParticipant,
  updateQuotationStatus,
  fetchPotentialMemberValidation,
  fetchCreditors,
  fetchDebtCapacities,
  fetchPaymentCapacities,
  updateNotarialPayContract,
} from "src/utils/axios";
import { TextFieldComponent } from "src/components/SharedComponents/Fields";
import NumberFormat from "src/components/SharedComponents/NumberFormat";
import ClientDocuments from "src/components/ClientDocuments";
import { green, orange, red } from "@material-ui/core/colors";
import {
  errorHandling,
  dateFormat,
} from "../../../components/SharedComponents/CustomHooks";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";

export const TabsStyled = withStyles({
  indicator: {
    backgroundColor: "#2E7D32",
  },
})(Tabs);

export const TabStyled = withStyles({
  root: {
    color: "#424242",
    "&:focus": {
      color: "#2E7D32",
    },
    "&:hover": {
      color: "#2E7D32",
      opacity: 1,
    },
  },
})(Tab);

const AccordionStyled = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    overflowAnchor: "none !important",
    width: "100%",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const AccordionSummaryStyled = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(AccordionSummary);

export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "1.5rem",
    minWidth: "500px",
    backgroundColor: "transparent",
  },
})(Tooltip);

export function FormWrapper(props) {
  const handleDocumentPrint = (event) => {
    event.stopPropagation();
    const printBtn = document.querySelector(
      `.accordion-content-wrapper.${props.name} .generate-print-document`
    );
    if (printBtn) {
      printBtn.click();
    }
  };
  return (
    <AccordionStyled
      expanded={props.expand}
      onChange={props.handleExpand.bind(this, props.name)}
      square
      className="responsible-container"
      style={{ marginBottom: "1rem" }}
    >
      <AccordionSummaryStyled
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
      >
        <Typography variant={"subtitle1"} className={"responsible-header-text"}>
          {props.title}
          <Typography variant="caption" style={{ color: "#757575" }}>
            &nbsp;{props.contentType}
          </Typography>
        </Typography>
        <div
          style={{ marginLeft: "1rem", display: "flex", alignItems: "center" }}
        >
          {!props.headerAction && !props.hideAction ? (
            <Button
              variant="contained"
              disableElevation
              style={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #43A047",
                color: "#43A047",
                height: 25,
              }}
              onClick={props.printDocument || handleDocumentPrint}
            >
              <Print
                style={{ fontSize: 17, color: "#43A047", marginRight: ".2rem" }}
              />
            </Button>
          ) : (
            props.headerAction
          )}
        </div>
      </AccordionSummaryStyled>

      <AccordionDetails
        style={{
          border: "1px solid gba(0, 0, 0, .125)",
          justifyContent: "center",
        }}
        className={`accordion-content-wrapper ${props.name}`}
      >
        {props.ticketCompleted && <div className="disable-overlay"></div>}
        {props.form}
      </AccordionDetails>
    </AccordionStyled>
  );
}

export function StepStatus(props) {
  const [viewPrevBtn, setViewPrevBtn] = useState(false);

  useEffect(() => {
    const permission =
      props.showPrevBtn && props.roles.includes("analista de credito");
    setViewPrevBtn(permission);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "2rem",
        width: "100%",
      }}
    >
      <Grid
        item
        container
        xs={12}
        justifyContent={viewPrevBtn ? "space-between" : "flex-end"}
        style={{ marginBottom: "2rem" }}
      >
        {viewPrevBtn && (
          <Button
            variant="contained"
            // props.roles.includes('analista de credito') ||
            className={
              props.ticketCompleted
                ? "action-method-btn disabled"
                : "action-method-btn--red"
            }
            disableElevation
            style={{ height: 35, marginRight: "1rem" }}
            onClick={() =>
              props.handleToggleDialog("prev-step", { ...props, id: true })
            }
            // props.roles.includes('analista de credito') ||
            disabled={props.ticketCompleted}
          >
            <NavigateBefore style={{ fontSize: 25, color: "#fff" }} />
            <span>{"anterior"}</span>
          </Button>
        )}
        <Button
          variant="contained"
          // props.roles.includes('analista de credito') ||
          className={
            props.ticketCompleted
              ? "action-method-btn disabled"
              : "action-method-btn--blue"
          }
          disableElevation
          style={{ height: 35 }}
          onClick={props.handleNextStep}
          // props.roles.includes('analista de credito') ||
          disabled={props.ticketCompleted}
        >
          <span>{props.completed ? "guardar" : "completar"}</span>
          <NavigateNext style={{ fontSize: 25, color: "#fff" }} />
        </Button>
      </Grid>
      <Grid container item xs={12} justifyContent="flex-start">
        <Paper style={{ padding: ".5rem 1rem", marginRight: "1rem" }}>
          <Typography variant="subtitle1" style={{ color: "#424242" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {props.completed ? (
                <>
                  <span
                    style={{
                      color: "#32a852",
                      fontSize: 15,
                      marginRight: "1rem",
                    }}
                  >
                    {"COMPLETADO"}
                  </span>
                  <CheckCircleOutlined
                    style={{ color: "#32a852", fontSize: 20 }}
                  />
                </>
              ) : (
                <>
                  <span
                    style={{
                      color: "#f5b82c",
                      fontSize: 15,
                      marginRight: "1rem",
                    }}
                  >
                    {"EN PROCESO"}
                  </span>
                  <RemoveCircleOutline
                    style={{ color: "#f5b82c", fontSize: 20 }}
                  />
                </>
              )}
            </div>
          </Typography>
        </Paper>
      </Grid>
    </div>
  );
}

export const useCheckStepStatus = (steps, currentStep) => {
  const currentStepStatus = steps.find((item) => item.step === currentStep)
    ?.status;
  return currentStepStatus === "completed" ? true : false;
};

export const handlePrevStep = async (props, stepToGo, status) => {
  const currentTicketStep = props.ticket.steps.find(
    (item) => item.step === props.currentStep
  );
  const previousSteps = props.ticket.steps.filter(
    (item) => item.order < currentTicketStep.order
  );

  previousSteps.forEach((step) => {
    if (step.status === "completed") {
      props.ticket.steps.find((item) => item.step === step.step).status = "";
    }
  });
  if (currentTicketStep.order > 0) {
    currentTicketStep.status = "";
    const selectedStep = props.ticket.steps.find((st) => st.step === stepToGo);
    selectedStep.status = "in_process";
    const dataToModify = {
      status: status.value,
      steps: props.ticket.steps,
      currentStep: selectedStep.step,
    };
    const historyDescription = `Se ha modificado el estado del ticket a: ${status.label}`;
    await ticketHistoryGeneral(props, historyDescription).then((response) => {
      const history = response;
      props.modifyTicket(dataToModify, history);
    });
    props.setCurrentTab(selectedStep.order);
  }
};

export const handleNextStep = async (
  proceed,
  props,
  ticketSteps,
  refreshTicket,
  finalStep,
  generalHistory
) => {
  const currentStepOrder = props.ticket.steps.find(
    (item) => item.step === props.currentStep
  ).order;
  let steps = ticketSteps ? ticketSteps : [...props.ticket.steps];
  const nextStepOrder = steps.find(
    (item) => item.order > currentStepOrder && item.status !== "completed"
  )?.order;
  if (proceed) {
    steps.forEach((item) => {
      if (item.order === currentStepOrder) {
        item.status = "completed";
      }
      if (item.order === (nextStepOrder || currentStepOrder + 1)) {
        item.status = item.status === "" ? "in_process" : item.status;
      }
    });
    const currentWorkingStep = steps.find(
      (item) =>
        item.status === "in_process" ||
        (item.status === "" ? item.order === 0 : false)
    );
    const ticketData = {
      ...props.ticket,
      currentStep: currentWorkingStep
        ? currentWorkingStep.step
        : "all_completed",
    };
    await ticketHistory(steps, props).then((response) => {
      const history =
        typeof generalHistory === "string"
          ? response + " \n" + generalHistory
          : response;
      props.modifyTicketStep(steps, ticketData, history, refreshTicket);
    });
    if (finalStep) {
      const dataToModify = {
        status: "processed",
      };
      const historyDescription = `Se ha modificado el estado del ticket a: Procesado`;
      await ticketHistoryGeneral(props, historyDescription).then((response) => {
        const history = response;
        dataToModify.completedDate = history.time;
        props.modifyTicket(dataToModify, history);
      });
      props.handleToggleSimpleDialog("success-dialog");
      return;
    }
    props.setCurrentTab(nextStepOrder || currentStepOrder + 1);
  } else {
    props.handleToggleSimpleDialog(
      "simple-dialog",
      "Faltan requerimientos por completar."
    );
  }
};

export const ticketHistory = async (steps, props) => {
  const ticketInfo = await fetchTicket(props.token, props.ticket.uniqueId).then(
    (response) => response.data
  );
  const time = await fetchTime().then((response) => response.data);
  const currentStep = steps.find((item) => item.step === props.currentStep);

  const nextStepOrder = steps.find(
    (item) => item.order > currentStep.order && item.status !== "completed"
  )?.order;
  const nextStep = steps.find(
    (item) => item.order === (nextStepOrder || currentStep.order + 1)
  );

  const ticketHistory =
    (ticketInfo.activityInfo && [...ticketInfo.activityInfo]) || [];
  ticketHistory.push({
    time: time,
    user: props.loggedUser,
    description: nextStep
      ? `Se modificó el paso ${props.currentStep.toUpperCase()} a ${nextStep.step.toUpperCase()}.`
      : `Se modificó el paso ${props.currentStep.toUpperCase()}.`,
    detail: currentStep,
  });

  return ticketHistory;
};

export const ticketHistoryGeneral = async (props, description) => {
  const ticketInfo = await fetchTicket(props.token, props.ticket.uniqueId).then(
    (response) => response.data
  );
  const time = await fetchTime().then((response) => response.data);
  const ticketHistory =
    (ticketInfo.activityInfo && [...ticketInfo.activityInfo]) || [];
  ticketHistory.push({
    time: time,
    user: props.loggedUser,
    description: `${description}`,
  });
  return ticketHistory;
};

export const approvalStates = () => {
  return [
    { name: "PENDIENTE DE APROBACIÓN", value: "approval_pending" },
    { name: "APROBADO", value: "approved" },
    { name: "DENEGADO", value: "denied" },
    { name: "RECLASIFICADO", value: "reclassified" },
  ];
};

export function ViewQuotation(props) {
  return (
    <Button
      variant="outlined"
      style={{
        borderRadius: 3,
        borderColor: "#43a047",
        color: "#43a047",
        fontSize: 12,
        height: 30,
        width: 200,
        marginBottom: "1.5rem",
      }}
      disableElevation
      onClick={props.onClick}
    >
      VISUALIZAR COTIZACIÓN
    </Button>
  );
}

export function ViewQuotationText(props) {
  return (
    <TextFieldComponent
      xs={3}
      typeVariant="subtitle1"
      typeClass="field-label"
      fieldLabel="Cotizacion"
      fieldID="requestNumber"
      fieldClass="fields"
      fieldVariant="outlined"
      margin="dense"
      fullWidth
      value={props.quotationData?.referenceCode || "---"}
      inputProps={{
        endAdornment: (
          <IconButton
            onClick={props.handleViewQuotation.bind(
              this,
              props,
              props.quotationData?.uniqueId,
              handleAddQuotation.bind(this, props.setFormIds)
            )}
          >
            <Visibility
              style={{
                fontSize: 22,
                color: green[500],
              }}
            />
          </IconButton>
        ),
      }}
      disabled={true}
    />
  );
}

export function ViewComponent(props) {
  return (
    <Button
      variant="outlined"
      style={{
        borderRadius: 3,
        borderColor: "#43a047",
        color: "#43a047",
        fontSize: 12,
        height: 30,
        marginBottom: 2,
      }}
      disableElevation
      onClick={props.onClick}
    >
      {`VISUALIZAR ${props.title.toUpperCase()}`}
    </Button>
  );
}

export const ticketStepsLabels = () => {
  return [
    // {name: 'Recepción de solicitud', value: 'request_reception', ticket: 'outlay', ticketName: 'Desembolso'},
    {
      name: "Evaluación y formas de liquidación",
      value: "eval_forms_settlement",
      ticket: "outlay",
      ticketName: "Desembolso",
    },
    {
      name: "Análisis de crédito",
      value: "credit_analysis",
      ticket: "outlay",
      ticketName: "Desembolso",
    },
    {
      name: "Elaboración y validación de contratos",
      value: "contract_validation",
      ticket: "outlay",
      ticketName: "Desembolso",
    },
    {
      name: "Formalización de crédito",
      value: "credit_formalization",
      ticket: "outlay",
      ticketName: "Desembolso",
    },
    {
      name: "Adquisición de datos",
      value: "data_adquisition",
      ticket: "new partner",
      ticketName: "Admisión",
    },
    {
      name: "Levantamiento requisitos",
      value: "requirements_gathering",
      ticket: "new partner",
      ticketName: "Admisión",
    },
    {
      name: "Análisis para admisión",
      value: "membership_analysis",
      ticket: "new partner",
      ticketName: "Admisión",
    },
    {
      name: "Enrolamiento socio y cuentas",
      value: "client_enrol",
      ticket: "new partner",
      ticketName: "Admisión",
    },
    {
      name: "Validación de pago",
      value: "payment_validation",
      ticket: "new partner",
      ticketName: "Admisión",
    },
    {
      name: "Emisión y entrega documentos",
      value: "documents_issuance",
      ticket: "new partner",
      ticketName: "Admisión",
    },
    {
      name: "Proceso de negociación",
      value: "negotiation_process",
      ticket: "payment",
      ticketName: "Pago",
    },
    {
      name: "Conciliación de pago",
      value: "payment_reconciliation",
      ticket: "payment",
      ticketName: "Pago",
    },
    {
      name: "Confirmación final",
      value: "last_confirm",
      ticket: "payment",
      ticketName: "Pago",
    },
  ];
};

export const cancelTicketDocuments = async (cancellationReason, props) => {
  const currentStep = props.ticket.currentStep;
  // setting documents id vars.
  let quotationSteps = ["eval_forms_settlement", "credit_analysis"];
  let quotations = [];
  // finding qutation ids
  quotationSteps.forEach((qs) => {
    const quotation = props.ticket.steps
      .find((item) => item.step === qs)
      .requirements.find((item) => item.name === "quotation").value;
    if (quotation) {
      quotations.push(quotation);
    }
  });
  // finding credit methods
  const creditMethods = props.ticket.steps
    .find((item) => item.step === "eval_forms_settlement")
    .requirements.find((item) => item.name === "creditMethodTables").value;
  // cancelling quotations
  if (quotations.length) {
    quotations.forEach(async (item) => {
      const quotation = await fetchQuotation(props.token, item).then(
        (response) => response.data
      );
      const cancelReason = cancellationReason || "";
      quotation.quotationStatus = "cancelled";
      quotation.cancelReason = cancelReason;
      await updateQuotation(props.token, quotation.uniqueId, quotation).catch(
        (errors) => {
          errorHandling(errors, props.handleToggleSimpleDialog);
        }
      );
    });
  }
  // cancelling checks
  if (creditMethods && creditMethods.checks.length) {
    creditMethods.checks.forEach(async (item) => {
      const check = await fetchOutlayCheck(props.token, item.uniqueId).then(
        (response) => response.data
      );
      const cancelReason = (cancellationReason || "").concat(check.concept);
      check.status = "cancelled";
      check.concept = cancelReason;
      await updateOutlayCheck(props.token, check.uniqueId, check).catch(
        (errors) => {
          errorHandling(errors, props.handleToggleSimpleDialog);
        }
      );
    });
  }

  // canceling promissory note
  // should consider modifying this code to cancel every document here.
  let promissoryNotes = [];
  const ticketForms = props.ticket.steps
    .find((item) => item.step === "contract_validation")
    .requirements.filter((item) => item.type === "questionary")
    .map((item) => item.value.forms);
  ticketForms.forEach((item) => {
    const form = item.find((pfn) => pfn.name === "promissoryNote")?.value;
    if (form) promissoryNotes.push(form);
  });
  if (promissoryNotes.length) {
    const endpoints = promissoryNotes.map((pn) =>
      updateNotarialPayContract(props.token, pn, { status: "cancelled" })
    );
    await axios.all(endpoints);
  }
};

export const validateCreditMethodTablesEmpty = async (tables) => {
  let empty = false;
  tables.creditMethods.forEach((item) => {
    if (!empty) {
      // using the switch case 'cuz property names are different.
      switch (item.value) {
        case "internet_banking":
          empty = Boolean(tables[`bankTransfers`].length <= 0);
          break;
        case "check":
          empty = Boolean(tables[`checks`].length <= 0);
          break;
        case "savings_account":
          empty = Boolean(tables[`savingsAccounts`].length <= 0);
          break;
        // case 'financial_certificate':
        //     empty = Boolean(tables[`financialCertificates`].length <= 0);
        //     break;
        case "cash":
          empty = Boolean(tables[`cash`].length <= 0);
          break;
        case "expiration":
          empty = Boolean(Object.keys(tables[`expiration`]).length <= 0);
          break;
        case "outlay":
          empty = Boolean(Object.keys(tables[`outlay`]).length <= 0);
          break;
        default:
          break;
      }
    }
  });
  return empty;
};

export const validateSettlementTotal = (comparingAmount, settlementData) => {
  // value is equal to creditMethods value in utils.
  // Didn't set value and name equal, because there are tickets already created with differente property name
  // than utils creditMethods.

  // VALIDATE AND ADD TRANSFER TOTOAL;
  const handleTransferTotal = (property, currentAmount) => {
    const includesTransfer = [
      "checks",
      "bankTransfers",
      "savingsAccounts",
    ].includes(property);
    if (includesTransfer) transferTotal += Number(currentAmount);
  };
  const requiredSettlementFields = [
    { name: "checks", value: "check" },
    { name: "bankTransfers", value: "internet_banking" },
    { name: "savingsAccounts", value: "savings_account" },
    { name: "financialCertificates", value: "financial_certificate" },
    { name: "cash", value: "cash" },
    { name: "outlay", value: "outlay" },
    { name: "expiration", value: "expiration" },
  ];
  let hasData = false;
  let settlementsTotal = 0;
  let differenceText = "";
  let transferTotal = 0;
  const comparingTotal = Number(Number(comparingAmount || 0).toFixed(3));
  Object.keys(settlementData).forEach((property) => {
    const fieldValue = requiredSettlementFields.find(
      (item) => item.name === property
    );
    const methodIsSelected = settlementData.creditMethods.find(
      (item) => item.value === (fieldValue && fieldValue.value)
    );
    if (property !== "creditMethods" && methodIsSelected) {
      if (settlementData[property].table) {
        hasData = true;
        settlementsTotal += Number(settlementData[property].total);
        handleTransferTotal(property, settlementData[property].total);
      } else if (settlementData[property].length > 0) {
        hasData = true;
        settlementData[property].forEach((item) => {
          settlementsTotal += Number(item.transferAmount);
          handleTransferTotal(property, item.transferAmount);
        });
      }
    }
  });
  settlementsTotal = Number(settlementsTotal.toFixed(3));
  differenceText = !hasData
    ? "no_data"
    : comparingTotal > settlementsTotal
    ? "es menor"
    : comparingTotal < settlementsTotal
    ? "excede"
    : "";
  return {
    text: differenceText,
    total: settlementsTotal,
    transferTotal,
  };
};

export const validateNewCheckData = (newCheck, oldCheck) => {
  const oldFiltered = oldCheck.filter((item) => item.uniqueId);
  const toCancel = [];
  if (oldFiltered.length > 0) {
    oldFiltered.forEach((item) => {
      const found = newCheck.find((nc) => nc.uniqueId === item.uniqueId);
      if (!found) {
        toCancel.push(item);
      }
    });
  }
  return {
    create: newCheck.filter((item) => !item.uniqueId),
    update: newCheck.filter((item) => item.uniqueId),
    cancel: toCancel,
  };
};

export const handleManageChecks = async (
  creditor,
  props,
  checks,
  oldChecks,
  bankAccounts,
  participant,
  step
) => {
  let check = {};
  const actions = validateNewCheckData(checks, oldChecks);
  Object.keys(actions).forEach((property) => {
    if (actions[property].length > 0) {
      actions[property].forEach(async (item) => {
        const account = bankAccounts.find(
          (a) => a.number === item.originAccount
        );
        const random = Date.now();
        check = {
          amount: item.transferAmount,
          creditor: creditor,
          account: account ? account.uniqueId : null,
          client: props.ticket.customer,
          // referenceNumber: item.referenceNumber,
        };
        switch (property) {
          case "create":
            check.title = `CHEQUE-TICKET-${props.ticket.title}-${random}`;
            check.status = "in_transit";
            // check.referenceNumber = random;
            check.operationType = "debit";
            check.concept = `Desembolso por ${check.amount.toLocaleString(
              "en-US",
              {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }
            )} de ${participant.fullName}`;
            await createOutlayCheck(props.token, check)
              .then((response) => {
                item.uniqueId = response.data.uniqueId;
              })
              .catch((errors) => {
                errorHandling(errors, props.handleToggleSimpleDialog);
              });
            break;
          case "update":
            const checkUpdate = await fetchOutlayCheck(
              props.token,
              item.uniqueId
            ).then((response) => response.data);
            checkUpdate.amount = item.transferAmount;
            checkUpdate.account = account
              ? account.uniqueId
              : checkUpdate.account;
            if (step === "contract_validation") {
              checkUpdate.referenceNumber = item.referenceNumber;
            }
            await updateOutlayCheck(
              props.token,
              checkUpdate.uniqueId,
              checkUpdate
            ).catch((errors) => {
              errorHandling(errors, props.handleToggleSimpleDialog);
            });
            break;
          case "cancel":
            check.status = "cancelled";
            check.concept = `--CANCELADO-- Desembolso por ${check.amount.toLocaleString(
              "en-US",
              {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }
            )} de ${participant.fullName}`;
            await updateOutlayCheck(props.token, item.uniqueId, check).catch(
              (errors) => {
                errorHandling(errors, props.handleToggleSimpleDialog);
              }
            );
            break;
          default:
            break;
        }
      });
    }
  });
};

export const handleManageLendingChecks = (props, checks) => {
  let check = {};
  checks.forEach(async (item) => {
    check = {
      ...item,
      action: item.action.value,
      amount: item.transferAmount,
      expirationDate: dateFormat(item.expirationDate),
      client: props.ticket.customer,
    };
    await createCheck(props.token, check)
      .then((response) => {
        item.uniqueId = response.data.uniqueId;
      })
      .catch((errors) => {
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
  });
};

// const getCreditor = async (steps, setUpdate) => {
//     // const creditor =
//     // steps.find(item => item.step === 'request_reception').requirements
//     // .find(item => item.name === 'creditor').value;
//     // // 430298557
//     // if(setUpdate) {
//     //     setUpdate(creditor);
//     // }
//     // return creditor;
// }

export const getBankAccounts = async (token) => {
  const creditor = await fetchCreditors({
    token,
    searchQuery: "430298557",
  }).then((res) => (res.data.results ? res.data.results : res.data));
  const bankAccountData = await fetchBankAccounts({
    token,
    owner: creditor[0]?.uniqueId,
    accountType: "checking",
  }).then((res) => (res.data.results ? res.data.results : res.data));
  return {
    creditor: creditor[0]?.uniqueId,
    creditorAccounts: bankAccountData,
  };
};
export const getClientBankAccounts = async ({ token, owner }) => {
  const data = await fetchDepositBankAccounts({ token, owner }).then((res) =>
    res.data.results ? res.data.results : res.data
  );
  if (Array.isArray(data) && data.length) {
    return {
      clientBankAccounts: data,
    };
  }
  return {};
};

const requiredSettlementFields = [
  { name: "checks", value: "check" },
  { name: "bankTransfers", value: "internet_banking" },
  { name: "savingsAccounts", value: "savings_account" },
  { name: "financialCertificates", value: "financial_certificate" },
  { name: "outlay", value: "outlay" },
  { name: "expiration", value: "expiration" },
];

export const handleState = (updateState, obj, type, value) => {
  let newSettlementData = { ...obj };
  // FIND PROPERTY THAT IS UNSELECTED AND REMOVE IT.
  if (type === "creditMethods") {
    requiredSettlementFields.forEach((rf) => {
      const methodIsSelected = value.find((field) => field.value === rf.value);
      if (!methodIsSelected) {
        newSettlementData[rf.name] = ["outlay", "expiration"].includes(rf.name)
          ? {}
          : [];
      }
    });
  }
  updateState({
    ...newSettlementData,
    [type]: value,
  });
};
export const handleModify = (updateState, obj, value, type) => {
  const data = value.length > 0 ? value : [...(obj[type] || [])].concat(value);
  updateState({
    ...obj,
    [type]: data,
  });
};

export const handleDelete = (updateState, obj, value, type) => {
  switch (type) {
    case "expiration":
    case "outlay":
      obj[type].table.splice(value, 1);
      if (obj[type].table.length < 1) {
        obj[type] = {};
      }
      break;
    default:
      obj[type].splice(value, 1);
      break;
  }
  updateState({ ...obj });
};

export const settlementInitData = {
  bankTransfers: [],
  checks: [],
  creditMethods: [],
  creditorAccounts: [],
  expiration: {},
  financialCertificates: [],
  newClientFullName: "",
  participantInfo: "",
  outlay: {},
  requestAmount: "",
  savingsAccounts: [],
  cash: [],
};
export const handleViewQuotation = (
  props,
  notSavedQuotation,
  handleQuoation
) => {
  const evalStepQuotation = props.ticket.steps
    .find((item) => item.step === "eval_forms_settlement")
    .requirements.find((item) => item.name === "quotation").value;
  const creditStepQuotation = props.ticket.steps
    .find((item) => item.step === "credit_analysis")
    .requirements.find((item) => item.name === "quotation").value;

  // notSavedQuotation is for Step 2, when quotation is selected but not saved,
  // so the user can view the quotation screen.
  const currQuotation =
    (typeof notSavedQuotation === "string" && notSavedQuotation) ||
    creditStepQuotation ||
    evalStepQuotation ||
    "";
  props.handleToggleQuotation(currQuotation, handleQuoation);
};

export const getParticipant = (props, setParticipant) => {
  const endpoint = fetchParticipant(props.token, props.ticket.customer);
  endpoint.then((response) => {
    setParticipant(response.data);
  });
};

export const getQuotationData = async (props, quotation) => {
  let data = {};
  const request = await fetchQuotation(props.token, quotation)
    .then((response) => {
      data = response.data;
    })
    .catch((errors) => {
      errorHandling(errors, props.handleToggleSimpleDialog);
    });
  return data;
};

export const approveQuotation = (
  props,
  quotation,
  setFormIds,
  setQuotation
) => {
  const updatedStatus = { ...quotation };
  updatedStatus.quotationStatus = "approved";
  const endpoint = updateQuotationStatus(
    props.token,
    quotation.uniqueId,
    updatedStatus
  );
  endpoint
    .then((response) => {
      setQuotation(response.data);
      if (setFormIds) {
        setFormIds((prevState) => ({
          ...prevState,
          quotation: response.data.uniqueId,
        }));
      }
    })
    .catch((errors) => {
      errorHandling(errors, props.handleToggleSimpleDialog);
    });
};

export const handleAddQuotation = (setFormIds, id) => {
  if (setFormIds) {
    setFormIds((prevState) => ({
      ...prevState,
      quotation: id,
    }));
  }
};

export const handleRemoveQuotation = (setFormIds) => {
  setFormIds((prevState) => ({
    ...prevState,
    quotation: "",
  }));
};

export const handleModal = (data, key, handleToggleDialog) => {
  if (key === "financiamientos") {
    handleToggleDialog("financiamientos", data);
  } else if (key === "atrasos") {
    handleToggleDialog("atrasos", data);
  }
};
export function DisbursementGeneralBadges(props) {
  const [documents, setDocuments] = useState([]);
  const [isInBlacklist, setisInBlacklist] = useState(false);

  const blackLists = async () => {
    const endpoint = fetchPotentialMemberValidation(props.token, {
      participant: props.customer,
    });
    await endpoint.then((response) => {
      if (response.data.blacklist.length > 0) {
        setisInBlacklist(true);
      }
    });
  };

  useEffect(() => {
    blackLists();
  }, []);

  useEffect(() => {
    if (props.documents) {
      setDocuments(props.documents);
    }
  }, [props.documents, props.setDocuments]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>ATRASOS:</p>
          <CustomTooltip
            title={
              <Card style={{ marginTop: "3%", width: 400 }}>
                <CardContent>
                  <div>
                    <div>
                      Cantidad de cuotas:{" "}
                      {props.ticketInfoData.length > 0
                        ? props.ticketInfoData[0].informacion_atrasos
                          ? props.ticketInfoData[0].informacion_atrasos
                              .cantidad_cuotas_atrasadas
                          : 0
                        : 0}
                    </div>
                    <Divider />
                    <div>
                      Atrasos ahorros:{" "}
                      {props.ticketInfoData.length > 0
                        ? props.ticketInfoData[0].informacion_atrasos
                          ? Number(
                              props.ticketInfoData[0].informacion_atrasos
                                .atrasos_ahorros
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: "DOP",
                            })
                          : 0
                        : 0}
                    </div>
                    <Divider />
                    <div>
                      Atrasos prestamos:{" "}
                      {props.ticketInfoData.length > 0
                        ? props.ticketInfoData[0].informacion_atrasos
                          ? Number(
                              props.ticketInfoData[0].informacion_atrasos
                                .atraso_prestamos
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: "DOP",
                            })
                          : 0
                        : 0}
                    </div>
                  </div>
                </CardContent>
              </Card>
            }
          >
            <Button
              variant="contained"
              disableElevation
              style={{
                minWidth: 130,
                height: 30,
                borderRadius: 20,
                fontSize: 12,
                marginBottom: 5,
                marginLeft: 5,
                backgroundColor:
                  props.ticketInfoData.length > 0
                    ? props.ticketInfoData[0].total_atraso > 0
                      ? red[500]
                      : "#CDCDCD"
                    : "#CDCDCD",
                color:
                  props.ticketInfoData.length > 0
                    ? props.ticketInfoData[0].total_atraso > 0
                      ? "white"
                      : "black"
                    : "black",
              }}
            >
              {props.ticketInfoData.length > 0
                ? Number(props.ticketInfoData[0].total_atraso).toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "DOP",
                    }
                  )
                : 0}
            </Button>
          </CustomTooltip>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>DOCUMENTOS:</p>
          <Button
            variant="contained"
            disableElevation
            style={{
              minWidth: 130,
              height: 30,
              borderRadius: 20,
              fontSize: 12,
              marginBottom: 5,
              marginLeft: 5,
              backgroundColor: documents.length > 1 ? red[500] : "#CDCDCD",
              color: documents.length > 1 ? "white" : "black",
            }}
          >
            {documents.length}
          </Button>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>BLACKLIST:</p>
          <Button
            variant="contained"
            disableElevation
            style={{
              minWidth: 130,
              height: 30,
              borderRadius: 20,
              fontSize: 12,
              marginBottom: 5,
              marginLeft: 5,
              backgroundColor: isInBlacklist ? red[500] : "#CDCDCD",
              color: isInBlacklist ? "white" : "black",
            }}
          >
            {isInBlacklist ? "EXISTE" : "---"}
          </Button>
        </div>
      </Grid>
      {/* <div style={{ display: "none" }}>
        <ClientDocuments
          debtorId={props.customer}
          user={{ token: props.token }}
          setDocuments={props.setDocuments || setDocuments}
          title={
            <Typography
              variant={"subtitle1"}
              className={"section-header"}
              style={{ fontWeight: "bold", color: "#424242", marginLeft: 5 }}
            >
              DOCUMENTOS
            </Typography>
          }
        />
      </div> */}
    </Grid>
  );
}
export const saveSettlementTable = (props) => {
  // SAVE TABLE IN CORRESPONDING TICKET STEPS
  props.ticket.steps
    .find((item) => item.step === props.step)
    .requirements.find((item) => item.name === "creditMethodTables").value =
    props.settlementData;
  props.modifyTicketStep(
    props.ticket.steps,
    props.ticket,
    undefined,
    undefined
  );
};

export function SettlementTableActions(props) {
  const btnProps = (btn) => {
    return {
      variant: "contained",
      className: (btn === "edit"
      ? props.enableEdit
      : props.enableSave)
        ? "action-method-btn disabled"
        : "action-method-btn",
      disableElevation: true,
      style: { height: 28, margin: "0 0 1rem 8px" },
      disabled: btn === "edit" ? props.enableEdit : props.enableSave,
    };
  };
  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent="space-between"
      spacing={2}
    >
      {props.showActions && (
        <div>
          <Button {...btnProps("edit")} onClick={props.modifySettlementTables}>
            <Edit style={{ marginRight: 8, fontSize: 14 }} />
            EDITAR
          </Button>

          <Button
            {...btnProps("save")}
            className={
              props.enableSave
                ? "action-method-btn disabled"
                : "action-method-btn"
            }
            disabled={props.enableSave}
            onClick={() =>
              saveSettlementTable({
                ticket: props.ticket,
                step: props.step,
                settlementData: props.settlementData,
                modifyTicketStep: props.modifyTicketStep,
              })
            }
          >
            <Save style={{ marginRight: 8, fontSize: 14 }} />
            GUARDAR
          </Button>
        </div>
      )}
      {
        <div
          style={{
            display: "inherit",
            margin: !props.showActions ? "auto auto 1rem 10px" : "0",
          }}
        >
          <Typography
            variant={"subtitle1"}
            className={"title-text"}
            style={{ marginRight: ".7rem" }}
          >
            <span>MONTO A TRANSFERIR:&nbsp;</span>
            <strong>
              <NumberFormat prefix={"$"} value={props.amountTransfer || "0"} />
            </strong>
          </Typography>
          <Typography variant={"subtitle1"} className={"title-text"}>
            <span>TOTAL:&nbsp;</span>
            <strong>
              <NumberFormat prefix={"$"} value={props.total || "0"} />
            </strong>
          </Typography>
        </div>
      }
    </Grid>
  );
}

export const getPayCapacity = async (props) => {
  const data = await fetchPaymentCapacities({
    token: props.token,
    customer: props.ticket.customer,
  })
    .then((res) => (res.data.results ? res.data.results : res.data))
    .catch((errors) => {
      errorHandling(errors, props.handleToggleSimpleDialog);
    });
  if (Array.isArray(data) && data.length) {
    return {
      payCapacity: data,
    };
  }
  return {};
};

export const getDebtCapacities = async (props) => {
  const data = await fetchDebtCapacities({
    token: props.token,
    customer: props.ticket.customer,
  })
    .then((res) => (res.data.results ? res.data.results : res.data))
    .catch((errors) => {
      errorHandling(errors, props.handleToggleSimpleDialog);
    });
  if (Array.isArray(data) && data.length) {
    let currSuggestion = 0;
    data.forEach((item) => (currSuggestion += item.modifierData.suggested));
    return {
      suggested: parseFloat(currSuggestion).toFixed(2),
      debtCapacities: data,
    };
  }
  return {};
};

export const getPayCapacityData = (
  ticketInfoData,
  debtCapacities,
  payCapacity
) => {
  let capacidadPagoData =
    ticketInfoData.length > 0 ? ticketInfoData[0].capacidad_pagos_info : [];

  const debtCapacitiesClean = debtCapacities.filter(
    (item) => item.modifierData.productType !== "regular_loan"
  );

  let tableData = {};
  let debt = [];
  let pay = [];

  debtCapacitiesClean.forEach((item) => {
    debt.push(item);
  });
  payCapacity.forEach((item) => {
    pay.push(item);
  });
  tableData = {
    rohi: capacidadPagoData,
    debt,
    pay,
  };

  const result = [];

  const constructTable = (row, type) => {
    const debtPayData = type === "debt" ? row.modifierData : row.data;
    const rohiProduct = tableData.rohi?.find(
      (rc) =>
        rc.tipo_capacidad_pago === transaction_types[debtPayData.productType]
    );
    let data = {};
    if (rohiProduct) {
      data.tipoCapacidadPago = rohiProduct.tipo_capacidad_pago;
      data.totalCapacidadPagos = rohiProduct.total_capacidad_pagos;
      data.totalAhorros = rohiProduct.total_ahorros;
      data.totalPrestamos = rohiProduct.total_prestamos;
    } else {
      data.tipoCapacidadPago = transaction_types[debtPayData.productType];
      data.totalCapacidadPagos = 0;
      data.totalAhorros = 0;
      data.totalPrestamos = 0;
    }
    data.capacidad =
      type === "debt"
        ? Array.isArray(debtPayData.debtCapacity)
          ? debtPayData.debtCapacity[0]
          : debtPayData.debtCapacity
        : debtPayData.paymentCapacity;
    data.disponible = data.capacidad - data.totalCapacidadPagos;
    return data;
  };

  tableData.debt.forEach((item) => {
    const newData = constructTable(item, "debt");
    result.push(newData);
  });
  tableData.pay.forEach((item) => {
    const newData = constructTable(item, "pay");
    result.push(newData);
  });
  tableData.rohi.forEach((rd) => {
    const foundRow = result.find(
      (r) => r.tipoCapacidadPago === rd.tipo_capacidad_pago
    );
    let data = {};
    if (!foundRow) {
      data = {
        tipoCapacidadPago: rd.tipo_capacidad_pago,
        totalCapacidadPagos: rd.total_capacidad_pagos,
        capacidad: 0,
        disponible: -rd.total_capacidad_pagos,
        totalAhorros: rd.total_ahorros,
        totalPrestamos: rd.total_prestamos,
      };
      result.push(data);
    }
  });
  return result;
};

export const transaction_types = {
  regular_loan: "PRESTAMO REGULAR",
  simple_loan: "ORDINARIO SIMPLE",
  constant_fees: "ORDINARIO ESCALONADO",
  decreasing_fees: "ORDINARIO ESCALONADO",
  vacational_bonus: "BONO VACACIONAL (MH y BCRD)",
  // vacational_bonus: "BONO VACACIONAL (MH)",
  antiquity_incentive: "INCENTIVO POR ANTIGÜEDAD (BCRD)",
  antiquity_bonus: "PREMIO POR ANTIGÜEDAD (BCRD)",
  october_bonus_1: "DE ANIVERSARIO I (BCRD)",
  october_bonus_2: "DE ANIVERSARIO II (BCRD)",
  easter_royalty: "NAVICOOP BCRD",
  personal_loan: "PRESTAMO PERSONAL",
  school_supplies: "BONOCOOP (BCRD)",
  irregular_bonus: "PRESTAMOS BONO IRREGULAR",
  anniversary_bonus: "ANIVERSARIO (MH)",
  annual_bonus: "BONIFICACION ANUAL",
  salary_advance: "ORDINARIO EXPRESO",
};
