import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createFlier } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import accountNumberMaskInput from "../../../components/TextMaskInputComponents/accountNumberMaskInput";
import {
  catchError,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import { createFlier as create } from "../../../utils/axios";
import ClientSelectInput from "../../Client/ClientSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface IFlierFormProps {
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    formControl: string;
    greenButton: string;
    input: string;
    paper: string;
    root: string;
    textField: string;
  };

  user: IUser;
  debtor?: string[];
  handleSaveCallBack?(uid: string): void;
  createFlier(flier: IFlier): IAction<IFlier[]>;
  changeSection(sectionNumber: number): void;
}
interface IFlierFormState {
  [key: string]: string | string[] | number | boolean;

  name: string;
  nameError: string;

  amount: string;
  amountError: string;

  client: string[];
  clientError: string;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
}
class CreditCardForm extends React.Component<IFlierFormProps, IFlierFormState> {
  public state: IFlierFormState = {
    amount: "",
    amountError: "",
    client: [],
    clientError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    name: "",
    nameError: "",
  };

  constructor(props: IFlierFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);

    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);
  }
  public handleAddClient(id: string) {
    this.setState({ client: [id] });
  }
  public handleDeleteClient(id: string) {
    this.setState({ client: [] });
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    const flier: IFlier = {
      amount: this.state.amount,
      client: this.state.client[0] || "",
      name: titleCaseHandler(this.state.name),
    };

    this.setState({ loading: true });

    create(this.props.user.token, flier)
      .then((res) => {
        if (this.props.handleSaveCallBack) {
          this.props.handleSaveCallBack(res.data.uniqueId);
        }
        this.props.createFlier(res.data.results ? res.data.results : res.data);
        successHandler(res, this, translations.FLIER);
        this.handleReset();
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }
  public handleReset() {
    this.setState({
      amount: "",
      amountError: "",
      client: [],
      clientError: "",
      name: "",
      nameError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public componentDidMount() {
    this.setState({
      client: this.state.client.length
        ? this.state.client
        : this.props.debtor || [],
    });
  }
  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.FLIER}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={titleCaseHandler(this.state.name)}
                onChange={this.handleChange("name")}
                fullWidth={true}
                required={true}
                error={this.state.nameError !== ""}
                helperText={this.state.nameError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.AMOUNT}
                className={classes.textField}
                value={this.state.amount}
                onChange={this.handleChange("amount")}
                fullWidth={true}
                required={true}
                error={this.state.amountError !== ""}
                helperText={this.state.amountError}
                InputProps={{
                  inputComponent: accountNumberMaskInput,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <br />
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {translations.CLIENT}
              </Typography>
              <div style={{ width: "100%" }}>
                <ClientSelectInput
                  selectedClientsId={this.state.client}
                  addClient={this.handleAddClient}
                  deleteClient={this.handleDeleteClient}
                />
              </div>
              <p style={{ color: "red " }}>{this.state.clientError}</p>
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createFlier })(CreditCardForm)
);
