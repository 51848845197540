import axios, { AxiosPromise } from "axios";

import { AUTHENTICATION_TYPES } from "../actions";
import { API_BASE_URL } from "../Environment";

export const API_KEY: string = API_BASE_URL;

export const http = axios.create({
  baseURL: API_KEY,
});

export const $axios = axios;

// authentication
export function axiosAuthentication(
  actionType: string,
  credentials: ICredentials
): AxiosPromise {
  switch (actionType) {
    case AUTHENTICATION_TYPES.LOG_USER:
      return axios.post(`${API_KEY}/token/`, credentials);
    default:
      return axios.post(`${API_KEY}/token/`, credentials);
  }
}
/* new changes */

export const refreshToken = (token: string, refresh: string) =>
  http.post(
    "/token/refresh/",
    { refresh: refresh },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const verifyToken = (token: string | false | null) =>
  http.post(
    "/token/verify/",
    { token: token },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

// participant CRUD
export const fetchParticipants = (options: IFetchParticipantParams) =>
  http.get("/crm/participant/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      rohi_id: options.rohiId,
      attorney_number: options.attorneyNumber,
      full_name: options.fullName,
      identification_number: options.identificationNumber,
      limit: options.limit,
      offset: options.offset,
      participant_type: options.participantType,
      work: options.work,
      institution: options.institution,
      responsible_analyst: options.responsible_analyst,
      search: options.searchQuery,
      work_class_id: options.work_class_id,
      business_agents: options.businessAgents,
      profession: options.profession
    },
  });
export const fetchParticipant = (token: string, uniqueId: string) =>
  http.get(`/crm/participant/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createParticipant = (token: string, participant: IParticipant) =>
  http.post("/crm/participant/", participant, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const fetchParticipantHistory = (token: string, uniqueId: string) =>
  http.get(`/crm/participant/${uniqueId}/history/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateAssignParticipants = (participant: IParticipant) =>
  http.post("/crm/participant/upload_assignee/", participant, {
    headers: {
      Authorization: `Bearer ${participant.token}`,
    },
  });

export const updateParticipantAgent = (participant: IParticipant) =>
  http.post("/crm/participant/upload_business_agents/", participant, {
    headers: {
      Authorization: `Bearer ${participant.token}`,
    },
  });

export const updateParticipant = (
  token: string,
  uniqueId: string,
  participant: IParticipant
) =>
  http.patch(`/crm/participant/${uniqueId}/`, participant, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteParticipant = (token: string, uniqueId: string) =>
  http.delete(`/crm/participant/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const resetSecurityQuestions = (token: string, uniqueId: string) =>
  http.post(
    `/crm/participant/${uniqueId}/reset-security-question/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

// Institution CRUD
export const fetchInstitutions = (options: IFetchInstitutionParams) =>
  http.get("/crm/institution/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      institution_type: options.institutionType,
      institution_uid: options.intitutionsId,
      limit: options.limit,
      name: options.name,
      offset: options.offset,
      registry: options.registry,
    },
  });
export const fetchInstitution = (token: string, uniqueId: string) =>
  http.get(`/crm/institution/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createInstitution = (token: string, institution: IInstitution) =>
  http.post("/crm/institution/", institution, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updateInstitution = (
  token: string,
  uniqueId: string,
  institution: IInstitution
) =>
  http.patch(`/crm/institution/${uniqueId}/`, institution, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteInstitution = (token: string, uniqueId: string) =>
  http.delete(`/crm/institution/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadInstitutionToRohi = (token: string, uniqueId: string) =>
  http.post(
    "/utility/middleware-rohi-finan-institution/",
    { unique_id: uniqueId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const changeEmployerCode = (token: string, uniqueId: string) =>
  http.post(
    `/crm/institution/${uniqueId}/change_employer_code/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

// deposit bank account CRUD

export const fetchDepositBankAccounts = (
  options: IFetchDepositBankAccountParams
) =>
  http.get("/crm/deposit-bank-accounts/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      account_type: options.accountType,
      limit: options.limit,
      name: options.name,
      number: options.number,
      offset: options.offset,
      owner: options.owner,
      owner_name: options.ownerName,
    },
  });
export const fetchDepositBankAccount = (token: string, uniqueId: string) =>
  http.get(`/crm/deposit-bank-accounts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createDepositBankAccount = (
  token: string,
  depositBankAccount: IDepositBankAccount
) =>
  http.post("/crm/deposit-bank-accounts/", depositBankAccount, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updateDepositBankAccount = (
  token: string,
  uniqueId: string,
  depositBankAccount: IDepositBankAccount
) =>
  http.patch(`/crm/deposit-bank-accounts/${uniqueId}/`, depositBankAccount, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteDepositBankAccount = (token: string, uniqueId: string) =>
  http.delete(`/crm/deposit-bank-accounts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadDBAToRohi = (token: string, uniqueId: string, db: string) =>
  http.post(
    `/utility/middleware-rohi-deposit-account-${db}/`,
    { unique_id: uniqueId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

// FileAttachment CRUD
export const createFileAttachment = (token: string, options: any) =>
  http.post(`/crm/file-attachments/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

export const fetchFileAttachments = (options: IFetchFileAttachmentParams) =>
  http.get("/crm/file-attachments/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      name: options.name,
      uniqueId: options.uniqueId,
      limit: options.limit,
      offset: options.offset,
    },
  });
export const fetchFileAttachment = (token: string, uniqueId: string) =>
  http.get(`/crm/file-attachments/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const getFileAttachment = (token: string, uniqueId: string) =>
  http.get(`/crm/file-attachments/${uniqueId}/get_file`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteFileAttachment = (token: string, uniqueId: string) =>
  http.delete(`/crm/file-attachments/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD financial profile
export const fetchFinancialProfileByOwner = (token: string, ownerId?: string) =>
  http.get(`/crm/financialprofile/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      owner: ownerId,
    },
  });

export const createFinancialProfile = (
  token: string,
  financialProfile: IFinancialProfile
) =>
  http.post(`/crm/financialprofile/`, financialProfile, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateFinancialProfile = (
  token: string,
  uniqueId: string,
  financialProfile: IFinancialProfile
) =>
  http.patch(`/crm/financialprofile/${uniqueId}/`, financialProfile, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteFinancialProfile = (token: string, uniqueId: string) =>
  http.delete(`/crm/financialprofile/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD CALCULATED PAYMENT

export const createCalculatedPayment = (token: string, options: any) =>
  http.post(`/outlay/calculated-payment/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchCalculatedPayments = (options: IFetchCalculatedPayment) =>
  http.get("/outlay/calculated-payment/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      ticket: options.ticket,
      owner: options.owner,
      limit: options.limit,
      offset: options.offset,
    },
  });
export const fetchCalculatedPayment = (token: string, uniqueId: string) =>
  http.get(`/outlay/calculated-payment/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteCalculatedPayment = (token: string, uniqueId: string) =>
  http.delete(`/outlay/calculated-payment/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD Charging Method
export const fetchChargingMethod = (
  token: string,
  ownerId?: string,
  type?: string
) =>
  http.get(`/crm/chargingmethod/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      owner: ownerId,
      charging_method: type,
    },
  });
export const fetchChargingMethodResumed = (token: string, uniqueId: string) =>
  http.post(
    `/crm/get_resumed_charging_methods`,
    { unique_id: uniqueId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const createChargingMethod = (token: string, method: IChargingMethod) =>
  http.post(`/crm/chargingmethod/`, method, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateChargingMethod = (
  token: string,
  uniqueId: string,
  method: IChargingMethod
) =>
  http.patch(`/crm/chargingmethod/${uniqueId}/`, method, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteChargingMethod = (token: string, uniqueId: string) =>
  http.delete(`/crm/chargingmethod/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD ACQUAINTANCESHIP AFFILIATE CONTRACTS

export const fetchAcquaintanceshipAffs = (
  contract: IAcquaintanceshipAffInterface
) =>
  http.get(`/lending/acquaintanceship-affiliate-contracts/`, {
    headers: {
      Authorization: `Bearer ${contract.token}`,
    },
    params: {
      limit: contract.limit,
      unique_id: contract.uniqueId,
      fullname: contract.fullname,
      debtor_unique_id: contract.debtorUniqueId,
      creation_date_from: contract.contractDateFrom,
      creation_date_to: contract.contractDateTo,
      title: contract.title,
      search: contract.search,
      status: contract.status,
    },
  });
export const fetchAcquaintanceshipAff = (token: string, uniqueId: string) =>
  http.get(`/lending/acquaintanceship-affiliate-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createAcquaintanceshipAff = (
  token: string,
  contract: IAcquaintanceshipAffInterface
) =>
  http.post(`/lending/acquaintanceship-affiliate-contracts/`, contract, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updateAcquaintanceshipAff = (
  token: string,
  uniqueId: string,
  contract: IAcquaintanceshipAffInterface
) =>
  http.patch(
    `/lending/acquaintanceship-affiliate-contracts/${uniqueId}/`,
    contract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const deletehAcquaintanceshipAff = (token: string, uniqueId: string) =>
  http.delete(`/lending/acquaintanceship-affiliate-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD Expiration interface
export const fetchExpiries = (expiries: IExpiryInterface) =>
  http.get(`/lending/expiration-interface/`, {
    headers: {
      Authorization: `Bearer ${expiries.token}`,
    },
    params: {
      expiration_status: expiries.expiration_status,
      destination_account: expiries.destination_account,
      client: expiries.client,
      amount_receivable_from: expiries.amount_receivable_from,
      amount_receivable_to: expiries.amount_receivable_to,
      amount_charged_from: expiries.amount_charged_from,
      amount_charged_to: expiries.amount_charged_to,
      expiration_date_from: expiries.expiration_date_from,
      expiration_date_to: expiries.expiration_date_to,
      work_institution: expiries.work_institution,
      finantial_institution: expiries.finantial_institution,
      responsible_analyst: expiries.responsible_analyst,
      charging_method: expiries.charging_method,
      work_class_id: expiries.work_class_id,
      creditor: expiries.creditor,
      creditor_account_number: expiries.creditor_account_number,
      limit: expiries.limit,
    },
  });
export const fetchExpiriesOrdered = (expiries: IExpiryInterface) =>
  http.get(`/lending/expiration-interface-ordered/`, {
    headers: {
      Authorization: `Bearer ${expiries.token}`,
    },
    params: {
      expiration_status: expiries.expiration_status,
      destination_account: expiries.destination_account,
      client: expiries.client,
      amount_receivable_from: expiries.amount_receivable_from,
      amount_receivable_to: expiries.amount_receivable_to,
      amount_charged_from: expiries.amount_charged_from,
      amount_charged_to: expiries.amount_charged_to,
      expiration_date_from: expiries.expiration_date_from,
      expiration_date_to: expiries.expiration_date_to,
      work_institution: expiries.work_institution,
      finantial_institution: expiries.finantial_institution,
      responsible_analyst: expiries.responsible_analyst,
      charging_method: expiries.charging_method,
      work_class_id: expiries.work_class_id,
      creditor: expiries.creditor,
      creditor_account_number: expiries.creditor_account_number,
      limit: expiries.limit,
    },
  });
export const fetchExpiry = (token: string, uniqueId: string) =>
  http.get(`/lending/expiration-interface/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
export const fetchExpirationReport = (token: string, expiries: any) =>
  http.post(`/lending/expiration-interface/print/`, expiries, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const fetchExpirationReportCsv = (token: string, expiries: any) =>
  http.post(`/lending/expiration-interface/print_csv/`, expiries, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createExpiriesUrl = (token: string, data: any) =>
  http.post(`/lending/expiration-interface/upload_expiration_url/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createExpiries = (token: string, file: any) =>
  http.post(`/lending/expiration-interface/upload_expiration/`, file, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
export const createExpiry = (expiries: IExpiryInterface) =>
  http.post(`/lending/expiration-interface/`, expiries, {
    headers: {
      Authorization: `Bearer ${expiries.token}`,
    },
  });
export const updateExpiry = (expiries: IExpiryInterface) =>
  http.patch(`/lending/expiration-interface/${expiries.uniqueId}/`, expiries, {
    headers: {
      Authorization: `Bearer ${expiries.token}`,
    },
  });
export const deleteExpiry = (token: string, uniqueId: any) =>
  http.delete(`/lending/expiration-interface/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD work profile

export const fetchWorkProfileByOwner = (token: string, ownerId?: string) =>
  http.get(`/crm/workprofile/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      owner: ownerId,
    },
  });
export const createWorkProfile = (token: string, workProfile: IWorkProfile) =>
  http.post(`/crm/workprofile/`, workProfile, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateWorkProfile = (
  token: string,
  uniqueId: string,
  workProfile: IWorkProfile
) =>
  http.patch(`/crm/workprofile/${uniqueId}/`, workProfile, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteWorkProfile = (token: string, uniqueId: string) =>
  http.delete(`/crm/workprofile/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD area
export const deleteArea = (token: string, uniqueId: string) =>
  http.delete(`/crm/area/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchAreas = (token: string) =>
  http.get("/crm/area/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD departments
export const fetchDepartments = (token: string) =>
  http.get("/crm/department/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD Leads
export const fetchLeads = (options: ILead) =>
  http.get("/leads/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      fullname: options.fullname,
      identification_number: options.identification,
      state: options.states,
      creation_date_from: options.dateFrom,
      creation_date_to: options.dateTo,
      company: options.institution,
      limit: options.limit,
      offset: options.offset,
    },
  });
export const fetchLead = (token: string, uniqueId: string) =>
  http.get(`/leads/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createLead = (token: string, lead: ILead) =>
  http.post("/leads/", lead, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updateLead = (token: string, uniqueId: string, lead: ILead) =>
  http.patch(`/leads/${uniqueId}/`, lead, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteLead = (token: string, uniqueId: string) =>
  http.delete(`/leads/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const complianceStateLead = (token: string, uniqueId: string) =>
  http.post(
    `/leads/${uniqueId}/compliance_authorize/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const hrStateLead = (token: string, uniqueId: string) =>
  http.post(
    `/leads/${uniqueId}/hr_authorize/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const businessStateLead = (token: string, uniqueId: string) =>
  http.post(
    `/leads/${uniqueId}/business_authorize/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const cancelStateLead = (token: string, uniqueId: string) =>
  http.post(
    `/leads/${uniqueId}/cancel/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const uploadFilesLead = (
  token: string,
  uniqueId: string,
  options: any
) =>
  http.post(`/leads/${uniqueId}/upload_file/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
export const createTicketLeads = (
  token: string,
  uniqueId: string,
  options: any
) =>
  http.post(`/leads/${uniqueId}/create_ticket/ `, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const completeLead = (token: string, uniqueId: string, options: any) =>
  http.post(`/leads/${uniqueId}/create_partner/ `, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createRohiLead = (token: string, uniqueId: string, options: any) =>
  http.post(`/leads/${uniqueId}/create_rohi_data/ `, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
});

export const createOutlayTicket = (token: string, options: any) =>
  http.post(`/tms/create-outlay-ticket`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
});

export const createOutlayLeads = (token: string, options: any) =>
  http.post(`/utility/create-outlay/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
});

// CRUD nacionalities
export const fetchNationalities = (options: IFetchNationalityParams) =>
  http.get("/crm/nationality/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      name: options.name,
      limit: options.limit,
      offset: options.offset,
    },
  });
export const fetchNationality = (token: string, uniqueId: string) =>
  http.get(`/crm/nationality/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createNationality = (token: string, nationality: INationality) =>
  http.post("/crm/nationality/", nationality, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updateNationality = (
  token: string,
  uniqueId: string,
  nationality: INationality
) =>
  http.patch(`/crm/nationality/${uniqueId}/`, nationality, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteNationality = (token: string, uniqueId: string) =>
  http.delete(`/crm/nationality/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD regions
export const fetchRegions = (options: IFetchRegionParams) =>
  http.get("/crm/region/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      offset: options.offset,
      country: options.country,
      name: options.name,
    },
  });
export const fetchRegion = (token: string, uniqueId: string) =>
  http.get(`/crm/region/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createRegion = (token: string, region: IRegion) =>
  http.post("/crm/region/", region, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updateRegion = (
  token: string,
  uniqueId: string,
  region: IRegion
) =>
  http.patch(`/crm/region/${uniqueId}/`, region, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteRegion = (token: string, uniqueId: string) =>
  http.delete(`/crm/region/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// CRUD states
export const fetchStates = (options: IFetchStateParams) =>
  http.get("/crm/state/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      region: options.region,
      offset: options.offset,
      name: options.name,
    },
  });
export const fetchState = (token: string, uniqueId: string) =>
  http.get(`/crm/state/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createState = (token: string, state: IState) =>
  http.post("/crm/state/", state, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updateState = (token: string, uniqueId: string, state: IState) =>
  http.patch(`/crm/state/${uniqueId}/`, state, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteState = (token: string, uniqueId: string) =>
  http.delete(`/crm/state/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD cities
export const fetchCities = (options: IFetchCityParams) =>
  http.get("/crm/city/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      state: options.state,
      offset: options.offset,
      name: options.name,
    },
  });
export const fetchCity = (token: string, uniqueId: string) =>
  http.get(`/crm/city/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createCity = (token: string, city: ICity) =>
  http.post("/crm/city/", city, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updateCity = (token: string, uniqueId: string, city: ICity) =>
  http.patch(`/crm/city/${uniqueId}/`, city, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteCity = (token: string, uniqueId: string) =>
  http.delete(`/crm/city/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// CRUD Creditors
export const fetchCreditors = (options: IFetchCreditorParams) =>
  http.get("/lending/creditors/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      full_name: options.fullName,
      identification_number: options.identificationNumber,
      is_representative: options.isRepresentative,
      institution: options.institution,
      institution_detail: options.institutionDetail,
      search: options.searchQuery,
      limit: options.limit,
      offset: options.offset,
    },
  });
export const fetchCreditor = (token: string, uniqueId: string) =>
  http.get(`/lending/creditors/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createCreditor = (token: string, creditor: ICreditor) =>
  http.post("/lending/creditors/", creditor, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateCreditor = (
  token: string,
  uniqueId: string,
  creditor: ICreditor
) =>
  http.patch(`/lending/creditors/${uniqueId}/`, creditor, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteCreditor = (token: string, uniqueId: string) =>
  http.delete(`/lending/creditors/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const fetchCreditorAccounts = (options: IFetchCreditorParams) =>
  http.get("/lending/creditor-accounts/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      offset: options.offset,
      search: options.searchQuery,
    },
  });
// CRUD Credit Analysis
// export const fetchCreditAnalysisDebtCalc = (options: IFetchCreditorParams) =>
//     http.get('/lending/creditors/', {
//         headers: {
//             Authorization: `Bearer ${options.token}`
//         },
//         params: {
//             full_name: options.fullName,
//             identification_number: options.identificationNumber,
//             is_representative: options.isRepresentative,
//             institution: options.institution,
//             institution_detail: options.institutionDetail,
//             limit: options.limit,
//             offset: options.offset
//         }
//     });
// export const fetchCreditor = (token: string, uniqueId: string) =>
//     http.get(`/lending/creditors/${uniqueId}/`, {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     });
// export const createCreditor = (token: string, creditor: ICreditor) =>
//     http.post('/lending/creditors/', creditor, {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     });

// export const updateCreditor = (token: string, uniqueId: string, creditor: ICreditor) =>
//     http.patch(`/lending/creditors/${uniqueId}/`, creditor, {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     });

// export const deleteCreditor = (token: string, uniqueId: string) =>
//     http.delete(`/lending/creditors/${uniqueId}/`, {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     });

// CRUD loan contract
export const fetchLoanContracts = (options: IFetchLoanContractParams) =>
  http.get("/lending/loan-contracts/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      attorney_unique_id: options.attorneyUniqueId,
      creditor_unique_id: options.creditorUniqueId,
      debtor_unique_id: options.debtorUniqueId,
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      credit_line_amount_from: options.creditLineAmountFrom,
      credit_line_amount_to: options.creditLineAmountTo,
      creditor_id_number: options.creditorIdNumber,
      creditor_name: options.creditorName,
      debtor_id_number: options.debtorIdNumber,
      debtor_name: options.debtorName,
      credit_line_amount: options.creditLineAmount,
      first_outlay_amount_from: options.firstOutlayAmountFrom,
      first_outlay_amount: options.firstOutlayAmount,
      first_outlay_amount_to: options.firstOutlayAmountTo,
      first_outlay_date_from: options.firstOutlayDateFrom,
      first_outlay_date_to: options.firstOutlayDateTo,
      limit: options.limit,
      offset: options.offset,
      status: options.status,
      title: options.title,
    },
  });

// CRUD Payment interface
export const fetchPayments = (payment: IPaymentInterace) =>
  http.get(`/lending/payment-interface/`, {
    headers: {
      Authorization: `Bearer ${payment.token}`,
    },
    params: {
      limit: payment.limit,
      expiration_id: payment.expirationId,
      creditor: payment.creditor,
      payment_id: payment.paymentId,
      payment_date_from: payment.payment_date_from,
      payment_date_to: payment.payment_date_to,
      payment_type: payment.paymentType,
      amount_payed_from: payment.amount_payed_from,
      amount_payed_to: payment.amount_payed_to,
      client: payment.clients,
      creditor_institution: payment.creditorInstitution,
      creditor_work_institution: payment.creditorWorkInstitution,
      client_financial_institution: payment.clientFinancialInstitution,
      client_work_institution: payment.clientWorkInstitution,
      responsible_analyst: payment.responsible_analyst,
      work_class_id: payment.work_class_id,
      creditor_bank_accounts: payment.creditorBankAccounts,
    },
  });
export const fetchPayment = (payment: IPaymentInterace) =>
  http.get(`/lending/payment-interface/${payment.uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${payment.token}`,
    },
  });

export const fetchPaymentReport = (token: string, payments: any) =>
  http.post(`/lending/payment-interface/print/`, payments, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchPaymentReportCsv = (token: string, payments: any) =>
  http.post(`/lending/payment-interface/print_csv/`, payments, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createPayment = (payment: IPaymentInterace) =>
  http.post(`/lending/payment-interface/`, payment, {
    headers: {
      Authorization: `Bearer ${payment.token}`,
    },
  });
export const createPaymentVoucher = (
  token: string,
  uniqueId: string,
  file: any
) =>
  http.post(`/lending/payment-interface/${uniqueId}/upload_voucher/`, file, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
export const updatePayment = (payment: IPaymentInterace) =>
  http.patch(`/lending/payment-interface/${payment.uniqueId}/`, payment, {
    headers: {
      Authorization: `Bearer ${payment.token}`,
    },
  });
export const deletePayment = (token: string, uniqueId: string) =>
  http.delete(`/lending/payment-interface/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD Payment Receipts
export const fetchPaymentReceipts = (paymentReceipt: IPaymentReceiptInterace) =>
  http.get(`/lending/payment-receipt/`, {
    headers: {
      Authorization: `Bearer ${paymentReceipt.token}`,
    },
    params: {
      participant: paymentReceipt.participant,
      limit: paymentReceipt.limit,
      offset: paymentReceipt.offset,
    },
  });
export const fetchPaymentReceipt = (payment: IPaymentReceiptInterace) =>
  http.get(`/lending/payment-receipt/${payment.uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${payment.token}`,
    },
  });
export const createPaymentReceipt = (payment: IPaymentReceiptInterace) =>
  http.post(`/lending/payment-receipt/`, payment, {
    headers: {
      Authorization: `Bearer ${payment.token}`,
    },
  });
export const createPaymentReceiptVoucher = (
  token: string,
  uniqueId: string,
  file: any
) =>
  http.post(`/lending/payment-receipt/${uniqueId}/upload_attachment/`, file, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
export const printPaymentReceiptDocument = (token: string, uniqueId: string) =>
  http.get(`/lending/payment-receipt/${uniqueId}/print`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updatePaymentReceipt = (payment: IPaymentReceiptInterace) =>
  http.patch(`/lending/payment-receipt/${payment.uniqueId}/`, payment, {
    headers: {
      Authorization: `Bearer ${payment.token}`,
    },
  });
export const deletePaymentReceipt = (token: string, uniqueId: string) =>
  http.delete(`/lending/payment-receipt/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// POTENTIAL MEMBERS VALIDATION
export const fetchPotentialMemberValidation = (
  token: string,
  options: IPotentialMemberValidation
) =>
  http.post(`/tms/potential-members-validation/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// ROHI EXPIRATION UPLOAD
export const createRohiExpirations = (
  token: string,
  options: IExpiryRohiInterface
) =>
  http.post(`/utility/rohi-expirations-bot/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// ROHI RECALCULATION
export const getRohiDmsQuotations = (options: IRohiRecalculation) =>
  http.post(`/utility/rohi-dms-quotation/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });

// ROHI RECALCULATION
export const postRohiLoanRecalculation = (options: IRohiRecalculation) =>
  http.post(`/utility/rohi-loan-recalculation`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });

export const fetchRohiTransactions = (options: IRohiTransactions) =>
  http.post(`/utility/rohi-transactions/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });

// CRUD RESPONSIBLE MANAGEMENT
export const fetchResponsibleAnalysts = (token: string, searchQuery: string) =>
  http.get(`/lending/responsible-management/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: searchQuery ? { search: searchQuery } : {},
  });

export const fetchResponsibleAnalyst = (token: string, uniqueId: string) =>
  http.get(`/lending/responsible-management/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createResponsibleAnalyst = (
  token: string,
  responsible: IResponsibleAnalyst
) =>
  http.post(`/lending/responsible-management/`, responsible, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateResponsibleAnalyst = (
  token: string,
  uniqueId: string,
  responsible: IResponsibleAnalyst
) =>
  http.patch(`/lending/responsible-management/${uniqueId}/`, responsible, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteResponsibleAnalyst = (token: string, uniqueId: string) =>
  http.delete(`/lending/responsible-management/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD SPECIFIC ORDER
export const fetchSpecificOrders = (token: string) =>
  http.get(`/lending/specific-order/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchSpecificOrder = (token: string, uniqueId: string) =>
  http.get(`/lending/specific-order/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createSpecificOrder = (token: string, order: IOrder) =>
  http.post(`/lending/specific-order/`, order, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateSpecificOrder = (token: string, order: IOrder) =>
  http.patch(`/lending/specific-order/${order.uniqueId}/`, order, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteSpecificOrder = (token: string, uniqueId: string) =>
  http.delete(`/lending/specific-order/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD PAYMENT SETTINGS
export const fetchPaymentSettings = (options: IPaymentSettingFetch) =>
  http.get(`/lending/payment-settings/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      expiration: options.expiry,
      specific_order: options.specificOrder,
    },
  });

export const fetchPaymentSetting = (token: string, uniqueId: string) =>
  http.get(`/lending/payment-settings/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createPaymentSetting = (
  token: string,
  paymentSetting: IPaymentSetting
) =>
  http.post(`/lending/payment-settings/`, paymentSetting, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updatePaymentSetting = (
  token: string,
  paymentSetting: IPaymentSetting
) =>
  http.patch(
    `/lending/payment-settings/${paymentSetting.uniqueId}/`,
    paymentSetting,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deletePaymentSetting = (token: string, uniqueId: string) =>
  http.delete(`/lending/payment-settings/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// BOT LINK PAYMENT SETTINGS
export const fetchBotLink = (token: string, uniqueId: string) =>
  http.get(`/lending/payment-settings/${uniqueId}/bot_link/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchLoanContract = (token: string, uniqueId: string) =>
  http.get(`/lending/loan-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createLoanContract = (
  token: string,
  loanContract: ILoanContract
) =>
  http.post("/lending/loan-contracts/", loanContract, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateLoanContract = (
  token: string,
  uniqueId: string,
  loanContract: ILoanContract
) =>
  http.patch(`/lending/loan-contracts/${uniqueId}/`, loanContract, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteLoanContract = (token: string, uniqueId: string) =>
  http.delete(`/lending/loan-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD notarial pay contract
export const fetchNotarialPayContracts = (
  options: IFetchNotarialPayContractParams
) =>
  http.get("/lending/notarial-pay-contracts/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      notarial_amount: options.amount,
      notarial_amount_to: options.amountTo,
      notarial_amount_from: options.amountFrom,
      act_number_to: options.actNumberTo,
      act_number_from: options.actNumberFrom,
      attorney_unique_id: options.attorneyUniqueId,
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      creditor_unique_id: options.creditorUniqueId,
      debtor_unique_id: options.debtorUniqueId,
      limit: options.limit,
      offset: options.offset,
      title: options.title,
      status: options.status,
    },
  });

export const fetchNotarialPayContract = (token: string, uniqueId: string) =>
  http.get(`/lending/notarial-pay-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createNotarialPayContract = (
  token: string,
  notarialPayContract: INotarialPayContract
) =>
  http.post("/lending/notarial-pay-contracts/", notarialPayContract, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateNotarialPayContract = (
  token: string,
  uniqueId: string,
  notarialPayContract: INotarialPayContract
) =>
  http.patch(
    `/lending/notarial-pay-contracts/${uniqueId}/`,
    notarialPayContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteNotarialPayContract = (token: string, uniqueId: string) =>
  http.delete(`/lending/notarial-pay-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD BankAccount
export const fetchBankAccounts = (options: IFetchBankAccountParams) =>
  http.get("/crm/bank-accounts/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      account_type: options.accountType,
      owner_registry_number: options.ownerRegistryNumber,
      bank: options.bank,
      limit: options.limit,
      name: options.name,
      number: options.number,
      offset: options.offset,
      owner: options.owner,
      owner_name: options.ownerName,
      owner_registry: options.ownerRegistry,
    },
  });

export const fetchBankAccount = (token: string, uniqueId: string) =>
  http.get(`/crm/bank-accounts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createBankAccount = (token: string, bankAccount: IBankAccount) =>
  http.post("/crm/bank-accounts/", bankAccount, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateBankAccount = (
  token: string,
  uniqueId: string,
  bankAccount: IBankAccount
) =>
  http.patch(`/crm/bank-accounts/${uniqueId}/`, bankAccount, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteBankAccount = (token: string, uniqueId: string) =>
  http.delete(`/crm/bank-accounts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD  CreditCard
export const fetchCreditCards = (options: IFetchCreditCardParams) =>
  http.get("/lending/credit-cards/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      bank: options.bank,
      name: options.name,
      number: options.number,
      owner_name: options.owner,
      limit: options.limit,
      offset: options.offset,
    },
  });

export const fetchCreditCard = (token: string, uniqueId: string) =>
  http.get(`/lending/credit-cards/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createCreditCard = (token: string, creditCard: ICreditCard) =>
  http.post("/lending/credit-cards/", creditCard, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateCreditCard = (
  token: string,
  uniqueId: string,
  creditCard: ICreditCard
) =>
  http.patch(`/lending/credit-cards/${uniqueId}/`, creditCard, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteCreditCard = (token: string, uniqueId: string) =>
  http.delete(`/lending/credit-cards/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD Flier
export const fetchFliers = (options: IFetchFlierParams) =>
  http.get("/lending/fliers/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      amount: options.amount,
      client: options.client,
      name: options.name,
      client_identification_number: options.client_identification_number,
      limit: options.limit,
      offset: options.offset,
    },
  });

export const fetchFlier = (token: string, uniqueId: string) =>
  http.get(`/lending/fliers/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createFlier = (token: string, flier: IFlier) =>
  http.post("/lending/fliers/", flier, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateFlier = (token: string, uniqueId: string, flier: IFlier) =>
  http.patch(`/lending/fliers/${uniqueId}/`, flier, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteFlier = (token: string, uniqueId: string) =>
  http.delete(`/lending/fliers/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Check
export const fetchChecks = (options: IFetchCheckParams) =>
  http.get("/lending/checks/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      amount: options.amount,
      client: options.client,
      limit: options.limit,
      name: options.name,
      offset: options.offset,
    },
  });

export const fetchCheck = (token: string, uniqueId: string) =>
  http.get(`/lending/checks/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createCheck = (token: string, check: ICheck) =>
  http.post("/lending/checks/", check, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateCheck = (token: string, uniqueId: string, check: ICheck) =>
  http.patch(`/lending/checks/${uniqueId}/`, check, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteCheck = (token: string, uniqueId: string) =>
  http.delete(`/lending/checks/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Outlay Check
export const fetchOutlayChecks = (options: IFetchOutlayCheckParams) =>
  http.get("/outlay/check/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      client: options.beneficiary,
      date_from: options.dateFrom,
      date_to: options.dateTo,
      limit: options.limit,
      name: options.name,
      offset: options.offset,
      status: options.status,
      title: options.title,
    },
  });

export const fetchOutlayCheck = (token: string, uniqueId: string) =>
  http.get(`/outlay/check/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createOutlayCheck = (token: string, outlayCheck: IOutlayCheck) =>
  http.post("/outlay/check/", outlayCheck, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateOutlayCheck = (
  token: string,
  uniqueId: string,
  check: IOutlayCheck
) =>
  http.patch(`/outlay/check/${uniqueId}/`, check, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteOutlayCheck = (token: string, uniqueId: string) =>
  http.delete(`/outlay/check/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const checkRequest = (token: string, uniqueId: string) =>
  http.post(`/outlay/check/${uniqueId}/print/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const checkTemplate = (token: string, uniqueId: string, type: any) =>
  http.post(`/outlay/check/${uniqueId}/print/`, type, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// Outlay Check
export const fetchOutlayCashes = (options: IFetchOutlayCashParams) =>
  http.get("/outlay/cash/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      client: options.beneficiary,
      date_from: options.dateFrom,
      date_to: options.dateTo,
      limit: options.limit,
      name: options.name,
      offset: options.offset,
      status: options.status,
      title: options.title,
    },
  });

export const fetchOutlayCash = (token: string, uniqueId: string) =>
  http.get(`/outlay/cash/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createOutlayCash = (token: string, outlayCash: IOutlayCash) =>
  http.post("/outlay/cash/", outlayCash, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateOutlayCash = (
  token: string,
  uniqueId: string,
  check: IOutlayCash
) =>
  http.patch(`/outlay/cash/${uniqueId}/`, check, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteOutlayCash = (token: string, uniqueId: string) =>
  http.delete(`/outlay/cash/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// Outlay Check
export const fetchOutlays = (options: IFetchOutlayParams) =>
  http.get("/outlay/outlay/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      customer: options.customer,
      customer_name: options.customerName,
      name: options.name,
      ticket: options.ticket,
      quotation: options.quotation,
      creditor: options.creditor,
      offset: options.offset,
      limit: options.limit,
      transaction_status: options.transactionStatus,
      approbation_instance: options.approbationInstance,
      product_type: options.productType,
      status: options.status,
      reference_code: options.referenceCode,
      transaction_date_from: options.transactionDateFrom,
      transaction_date_to: options.transactionDateTo,
      act_date_from: options.actDateFrom,
      act_date_to: options.actDateTo,
      act_number_from: options.actNumberFrom,
      act_number_to: options.actNumberTo,
      transfered_amount_from: options.transferedAmountFrom,
      transfered_amount_to: options.transferedAmountTo,
      transfered_amount: options.transferedAmount,
      original_amount_from: options.originalAmountFrom,
      original_amount_to: options.originalAmountTo,
      original_amount: options.originalAmount,
      is_refianceable: options.isRefianceable,
    },
  });
export const fetchNewOutlays = (token: string, filters: {}) => {
  return http.get("/outlay/outlay/get_outlay_view/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: filters,
  });
};

export const fetchOutlay = (token: string, uniqueId: number) =>
  http.get(`/outlay/outlay/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createOutlay = (token: string, outlay: IOutlay) =>
  http.post("/outlay/outlay/", outlay, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateOutlay = (
  token: string,
  uniqueId: string,
  outlay: IOutlay
) =>
  http.patch(`/outlay/outlay/${uniqueId}/`, outlay, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteOutlay = (token: string, uniqueId: string) =>
  http.delete(`/outlay/outlay/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  export const fetchPrecalification = (token: string, params: any) =>
    http.post('/outlay/prequalification', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

// CRUD Addendum
export const fetchAddendumContracts = (options: IFetchAddendumContractParams) =>
  http.get("/lending/addendum-contracts/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      addendum_type: options.addendumType,
      limit: options.limit,
      modification_date_from: options.modificationDateFrom,
      modification_date_to: options.modificationDateTo,
      offset: options.offset,
      title: options.title,
      debtor_unique_id: options.debtorUniqueId,
    },
  });

export const fetchAddendumContract = (token: string, uniqueId: string) =>
  http.get(`/lending/addendum-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createAddendumContract = (
  token: string,
  addendumContract: IAddendumContract
) =>
  http.post("/lending/addendum-contracts/", addendumContract, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateAddendumContract = (
  token: string,
  uniqueId: string,
  addendumContract: IAddendumContract
) =>
  http.patch(`/lending/addendum-contracts/${uniqueId}/`, addendumContract, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteAddendumContract = (token: string, uniqueId: string) =>
  http.delete(`/lending/addendum-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD DebitAuthorizationContract
export const fetchDebitAuthorizationContracts = (
  options: IFetchDebitAuthorizationContractParams
) =>
  http.get("/lending/debit-authorization-contracts/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      contract_fee: options.contractFee,
      debtor_unique_id: options.debtorUniqueId,
      limit: options.limit,
      offset: options.offset,
      title: options.title,
      withdrawal_day: options.withdrawalDay,
    },
  });

export const fetchDebitAuthorizationContract = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/debit-authorization-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createDebitAuthorizationContract = (
  token: string,
  debitAuthorizationContract: IDebitAuthorizationContract
) =>
  http.post(
    "/lending/debit-authorization-contracts/",
    debitAuthorizationContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateDebitAuthorizationContract = (
  token: string,
  uniqueId: string,
  debitAuthorizationContract: IDebitAuthorizationContract
) =>
  http.patch(
    `/lending/debit-authorization-contracts/${uniqueId}/`,
    debitAuthorizationContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteDebitAuthorizationContract = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/debit-authorization-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// CRUD AutomaticDebitAuthorizationContract
export const fetchAutomaticDebitAuthorizationContracts = (
  options: IFetchAutomaticDebitAuthorizationContractParams
) =>
  http.get("/lending/debit-authorization-contracts-extended/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      debtor_unique_id: options.debtor,
      limit: options.limit,
      status: options.status,
      offset: options.offset,
      title: options.title,
    },
  });

export const fetchAutomaticDebitAuthorizationContract = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/debit-authorization-contracts-extended/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createAutomaticDebitAuthorizationContract = (
  token: string,
  AutomaticdebitAuthorizationContract: IAutomaticDebitAuthorizationContract
) =>
  http.post(
    "/lending/debit-authorization-contracts-extended/",
    AutomaticdebitAuthorizationContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateAutomaticDebitAuthorizationContract = (
  token: string,
  uniqueId: string,
  AutomaticdebitAuthorizationContract: IAutomaticDebitAuthorizationContract
) =>
  http.patch(
    `/lending/debit-authorization-contracts-extended/${uniqueId}/`,
    AutomaticdebitAuthorizationContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteAutomaticDebitAuthorizationContract = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/debit-authorization-contracts-extended/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD AcquaintanceshipCompanyContract
export const fetchAcquaintanceshipCompanyContracts = (
  options: IFetchAcquaintanceshipCompanyContractParams
) =>
  http.get("/lending/acquaintanceship-company-contracts/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      activity_type: options.activityType,
      creditor_name: options.creditorName,
      debtor_name: options.debtorName,
      creditor_unique_id: options.creditorUniqueId,
      debtor_unique_id: options.debtorUniqueId,
      limit: options.limit,
      offset: options.offset,
      status: options.status,
      title: options.title,
      anual_primary_income_from: options.anualPrimaryIncomeFrom,
      anual_primary_income_to: options.anualPrimaryIncomeTo,
      debtor_representative_name: options.debtorRepresentativeName,
      economic_sector: options.economicSector,
    },
  });

export const fetchAcquaintanceshipCompanyContract = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/acquaintanceship-company-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createAcquaintanceshipCompanyContract = (
  token: string,
  acquaintanceshipCompanyContract: IAcquaintanceshipCompanyContract
) =>
  http.post(
    "/lending/acquaintanceship-company-contracts/",
    acquaintanceshipCompanyContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateAcquaintanceshipCompanyContract = (
  token: string,
  uniqueId: string,
  acquaintanceshipCompanyContract: IAcquaintanceshipCompanyContract
) =>
  http.patch(
    `/lending/acquaintanceship-company-contracts/${uniqueId}/`,
    acquaintanceshipCompanyContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteAcquaintanceshipCompanyContract = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/acquaintanceship-company-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD AcquaintanceshipPersonContract
export const fetchAcquaintanceshipPersonContracts = (
  options: IFetchAcquaintanceshipPersonContractParams
) =>
  http.get("/lending/acquaintanceship-person-contracts/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      creditor_name: options.creditorName,
      debtor_name: options.debtorName,
      creditor_unique_id: options.creditorUniqueId,
      debtor_unique_id: options.debtorUniqueId,
      economic_activity_info: options.economicActivityInfo,
      limit: options.limit,
      status: options.status,
      offset: options.offset,
      title: options.title,
    },
  });

export const fetchAcquaintanceshipPersonContract = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/acquaintanceship-person-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createAcquaintanceshipPersonContract = (
  token: string,
  acquaintanceshipPersonContract: IAcquaintanceshipPersonContract
) =>
  http.post(
    "/lending/acquaintanceship-person-contracts/",
    acquaintanceshipPersonContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateAcquaintanceshipPersonContract = (
  token: string,
  uniqueId: string,
  acquaintanceshipPersonContract: IAcquaintanceshipPersonContract
) =>
  http.patch(
    `/lending/acquaintanceship-person-contracts/${uniqueId}/`,
    acquaintanceshipPersonContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteAcquaintanceshipPersonContract = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/acquaintanceship-person-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD Tickets
export const fetchTickets = (options: ITicketParams) => {
  return http.get(`/tms/ticket/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      filter_finished_tickets: options.filterFinishedTickets,
      assignee: options.assignee,
      cancelled_date_from: options.cancelledDateFrom,
      cancelled_date_to: options.cancelledDateTo,
      completed_date_from: options.completedDateFrom,
      completed_date_to: options.completedDateTo,
      current_step: options.currentStep,
      steps: options.steps,
      creation_date_from: options.creationDateFrom,
      creation_date_to: options.creationDateTo,
      customer: options.customer,
      document_status: options.documentStatus,
      dueTime: options.dueTime,
      due_date_from: options.dueDateFrom,
      due_date_to: options.dueDateTo,
      is_customer_visit_required: options.isCustomerVisitRequired,
      limit: options.limit,
      offset: options.offset,
      parentTicket: options.parentTicket,
      priority: options.priority,
      reference_code: options.referenceCode,
      request_type: options.requestType,
      reviewer: options.reviewer,
      status: options.status,
      title: options.title,
      transaction_reference: options.transactionReference,
      web_ticket: options.webTicket,
      institution: options.institution,
    },
  });
};

export const fetchTicket = (token: string, uniqueId: string) =>
  http.get(`/tms/ticket/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createTicket = (token: string, ticket: string) =>
  http.post("/tms/ticket/", ticket, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateTicket = (
  token: string,
  uniqueId: string,
  ticket: ITicket
) =>
  http.patch(`/tms/ticket/${uniqueId}/`, ticket, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteTicket = (token: string, uniqueId: string) =>
  http.delete(`/tms/ticket/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createFileAttachmentTicket = (
  token: string,
  uniqueId: string,
  file: any
) =>
  http.post(`/tms/ticket/${uniqueId}/upload_attachment/`, file, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

// TICKET DISBURSEMENT DEBT CAPACITY
export const getCreditDebtCapacity = (options: ICreditDebtCapacity) =>
  http.post(`/outlay/credit_analysis_debt_capacity_calculator`, options, {
    headers: {
      Authorization: `Bearer ${options.accessToken}`,
    },
  });
export const getCreditPayCapacity = (options: ICreditPayCapacity) =>
  http.post(`/outlay/credit_analysis_payment_capacity_calculator`, options, {
    headers: {
      Authorization: `Bearer ${options.accessToken}`,
    },
  });

export const fetchQuota = (options: IQuota) =>
  http.post("/outlay/quota", options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });

// CRUD Quotation
export const fetchQuotations = (options: IQuotationParams) => {
  return http.get(`/outlay/quotation/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      client: options.client,
      customer_name: options.customerName,
      limit: options.limit,
      offset: options.offset,
      quotation_date_from: options.quotationDateFrom,
      quotation_date_to: options.quotationDateTo,
      quotation_status: options.quotationStatus,
      product_type: options.productType,
      reference_code: options.referenceCode,
    },
  });
};

export const fetchQuotation = (token: string, uniqueId: string) =>
  http.get(`/outlay/quotation/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createQuotation = (token: string, quotation: IQuotation) =>
  http.post("/outlay/quotation/", quotation, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateQuotation = (
  token: string,
  uniqueId: string,
  quotation: IQuotation
) =>
  http.patch(`/outlay/quotation/${uniqueId}/`, quotation, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  export const fetchParticipantBalance = (token: string, uniqueId: string) =>
    http.get(`/outlay/quotation/${uniqueId}/get_balances/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

export const updateQuotationStatus = (
  token: string,
  uniqueId: string,
  quotation: IQuotation
) =>
  http.patch(`/outlay/quotation/${uniqueId}/update_status/`, quotation, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteQuotation = (token: string, uniqueId: string) =>
  http.delete(`/outlay/quotation/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD BlackList
export const fetchBlacklists = (options: IBlacklistParams) => {
  return http.get(`/outlay/blacklist/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      blacklister: options.blacklister,
      creation_date_from: options.creationDateFrom,
      creation_date_to: options.creationDateTo,
      customer: options.customer,
      full_name: options.fullName,
      reason: options.reason,
      identification_number: options.identificationNumber,
      is_client_still_blacklisted: options.isClientStillBlacklisted,
      limit: options.limit,
      offset: options.offset,
    },
  });
};

export const fetchBlacklist = (token: string, uniqueId: string) =>
  http.get(`/outlay/blacklist/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createBlacklist = (token: string, blacklist: IBlacklist) =>
  http.post("/outlay/blacklist/", blacklist, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateBlacklist = (
  token: string,
  uniqueId: string,
  blacklist: IBlacklist
) =>
  http.patch(`/outlay/blacklist/${uniqueId}/`, blacklist, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteBlacklist = (token: string, uniqueId: string) =>
  http.delete(`/outlay/blacklist/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// ALL BLACKLIST PERSONS
export const fetchBlacklistData = (options: IBlacklistData) =>
  http.post("/outlay/blacklist-data", options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });

// CRUD Accounting Records
export const fetchAccountingRecords = (options: IAccountingRecordParams) => {
  return http.get(`/tms/accountingRecord/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      accounting_id: options.accountingId,
      financial_id: options.financialId,
      limit: options.limit,
      offset: options.offset,
      outlay_id: options.outlayId,
      record_data: options.recordData,
      record_status: options.recordStatus,
      reference_code: options.referenceCode,
      ticket_id: options.ticketId,
      transaction_date_from: options.transactionDateFrom,
      transaction_date_to: options.transactionDateTo,
    },
  });
};

export const fetchAccountingRecord = (token: string, uniqueId: string) =>
  http.get(`/tms/accountingRecord/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createAccountingRecord = (
  token: string,
  accountingRecord: IAccountingRecord
) =>
  http.post("/tms/accountingRecord/", accountingRecord, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateAccountingRecord = (
  token: string,
  uniqueId: string,
  accountingRecord: IAccountingRecord
) =>
  http.patch(`/tms/accountingRecord/${uniqueId}/`, accountingRecord, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteAccountingRecord = (token: string, uniqueId: string) =>
  http.delete(`/tms/accountingRecord/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD Accounting Records
export const fetchDebtCapacities = (options: IDebtCapacityParams) => {
  return http.get(`/outlay/debt-capacity/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      customer: options.customer,
      institution_name: options.institutionId,
      institution_uid: options.institutionName,
      product_type: options.productType,
      limit: options.limit,
      offset: options.offset,
    },
  });
};

export const fetchDebtCapacity = (token: string, uniqueId: string) =>
  http.get(`/outlay/debt-capacity/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createDebtCapacity = (
  token: string,
  debtCapacity: IDebtCapacity
) =>
  http.post("/outlay/debt-capacity/", debtCapacity, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateDebtCapacity = (
  token: string,
  uniqueId: string,
  debtCapacity: IDebtCapacity
) =>
  http.patch(`/outlay/debt-capacity/${uniqueId}/`, debtCapacity, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteDebtCapacity = (token: string, uniqueId: string) =>
  http.delete(`/outlay/debt-capacity/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// CRUD Accounting Records
export const fetchPaymentCapacities = (options: IPaymentCapacityParams) => {
  return http.get(`/outlay/payment-capacity/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      customer: options.customer,
      institution_name: options.institutionId,
      institution_uid: options.institutionName,
      limit: options.limit,
      offset: options.offset,
    },
  });
};

export const fetchPaymentCapacity = (token: string, uniqueId: string) =>
  http.get(`/outlay/payment-capacity/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createPaymentCapacity = (
  token: string,
  paymentCapacity: IPaymentCapacity
) =>
  http.post("/outlay/payment-capacity/", paymentCapacity, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updatePaymentCapacity = (
  token: string,
  uniqueId: string,
  paymentCapacity: IPaymentCapacity
) =>
  http.patch(`/outlay/payment-capacity/${uniqueId}/`, paymentCapacity, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deletePaymentCapacity = (token: string, uniqueId: string) =>
  http.delete(`/outlay/payment-capacity/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD Asignee
export const fetchAssignees = (options: ITicketParams) =>
  http.get(`/auth/user/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      id: options.id,
      username: options.username,
      group: options.group,
      limit: options.limit,
      offset: options.offset,
    },
  });

export const fetchAssignee = (token: string, uniqueId: string) =>
  http.get(`/auth/user/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchGroups = (options: any) =>
  http.get("/auth/group/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      offset: options.offset,
    },
  });

export const fetchGroup = (token: string, uniqueId: string) =>
  http.get(`/auth/group/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchTime = () => http.get(`/utility/server-time/`);

// Calculator
export const simpleLoanCalulator = (options: any, token: string) =>
  axios({
    data: options,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    method: "post",
    url: `${API_KEY}/outlay/loan`,
  });

export const plannedSavingsCalulator = (options: any, token: string) =>
  axios({
    data: options,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    method: "post",
    url: `${API_KEY}/outlay/saving`,
  });

// Providers
export const fetchProviders = (options: IFetchProviderParams) =>
  http.get("/crm/provider/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      name: options.name,
      offset: options.offset,
    },
  });

export const fetchProvider = (token: string, uniqueId: string) =>
  http.get(`/crm/provider/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createProvider = (token: string, provider: IProvider) =>
  http.post("/crm/provider/", provider, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateProvider = (
  token: string,
  uniqueId: string,
  check: IProvider
) =>
  http.patch(`/crm/provider/${uniqueId}/`, check, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteProvider = (token: string, uniqueId: string) =>
  http.delete(`/crm/provider/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Provider contact
export const fetchProviderContacts = (options: IFetchProviderContactParams) =>
  http.get(`/crm/providercontact/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      name: options.name,
      offset: options.offset,
      provider: options.providerId,
    },
  });

export const fetchProviderContact = (token: string, uniqueId: string) =>
  http.get(`/crm/providercontact/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createProviderContact = (
  token: string,
  providerContact: IProviderContact
) =>
  http.post(`/crm/providercontact/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateProviderContact = (
  token: string,
  uniqueId: string,
  providerContact: IProviderContact
) =>
  http.patch(`/crm/providercontact/${uniqueId}/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteProviderContact = (token: string, uniqueId: string) =>
  http.delete(`/crm/providercontact/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Expiration
export const fetchExpirations = (options: IFetchExpirationParams) =>
  http.get("/outlay/expiration/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      offset: options.offset,
      status: options.status,
      reference_number: options.referenceNumber,
      outlay: options.outlay,
    },
  });

export const fetchExpiration = (token: string, uniqueId: string) =>
  http.get(`/outlay/expiration/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchExpirationByParticipant = (
  token: string,
  participantId: string
) =>
  http.get(`/outlay/expiration/?customer=${participantId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchParticipantsbyActiveExpirations = (
  options: IFetchExpirationParams
) =>
  http.get("/outlay/expiration/participants/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      full_name: options.fullName,
      institution: options.institution,
      identification_number: options.identificationNumber,
      status: options.status,
      date_from: options.dateFrom,
      date_to: options.dateTo,
      amount_from: options.amountFrom,
      amount_to: options.amountTo,
      limit: options.limit,
      offset: options.offset,
    },
  });

export const getAmortizationTable = (token: string, uniqueId: string) =>
  http.get(`/outlay/outlay/${uniqueId}/amortization`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD AcquaintanceshipProviderContract
export const fetchAcquaintanceshipProviderContracts = (
  options: IFetchAcquaintanceshipProviderContractParams
) =>
  http.get("/lending/acquaintanceship-provider-contracts/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      title: options.title,
      status: options.status,
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      provider_unique_id: options.providerUniqueId,
      // limit: options.limit,
      // offset: options.offset,
    },
  });

export const fetchAcquaintanceshipProviderContract = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/acquaintanceship-provider-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createAcquaintanceshipProviderContract = (
  token: string,
  acquaintanceshipProviderContract: IAcquaintanceshipProviderContract
) =>
  http.post(
    "/lending/acquaintanceship-provider-contracts/",
    acquaintanceshipProviderContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateAcquaintanceshipProviderContract = (
  token: string,
  uniqueId: string,
  acquaintanceshipProviderContract: IAcquaintanceshipProviderContract
) =>
  http.patch(
    `/lending/acquaintanceship-provider-contracts/${uniqueId}/`,
    acquaintanceshipProviderContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteAcquaintanceshipProviderContract = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/acquaintanceship-provider-contracts/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Membership application
export const fetchMembershipApplications = (
  options: IFetchMembershipApplicationParams
) =>
  http.get(`/lending/membership-application/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      creditor_unique_id: options.creditorUniqueId,
      debtor_unique_id: options.debtorUniqueId,
      status: options.status,
      limit: options.limit,
      offset: options.offset,
      title: options.title,
      token: options.token,
    },
  });

export const fetchMembershipApplication = (token: string, uniqueId: string) =>
  http.get(`/lending/membership-application/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createMembershipApplication = (
  token: string,
  providerContact: IMembershipApplication
) =>
  http.post(`/lending/membership-application/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateMembershipApplication = (
  token: string,
  uniqueId: string,
  providerContact: IMembershipApplication
) =>
  http.patch(`/lending/membership-application/${uniqueId}/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteMembershipApplication = (token: string, uniqueId: string) =>
  http.delete(`/lending/membership-application/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Profession
export const fetchProfessions = (options: IFetchProfessionParams) =>
  http.get(`/crm/profession/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      name: options.name,
      offset: options.offset,
    },
  });

export const fetchProfession = (token: string, uniqueId: string) =>
  http.get(`/crm/profession/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createProfession = (token: string, profession: IProfession) =>
  http.post(`/crm/profession/`, profession, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateProfession = (
  token: string,
  uniqueId: string,
  profession: IProfession
) =>
  http.patch(`/crm/profession/${uniqueId}/`, profession, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteProfession = (token: string, uniqueId: string) =>
  http.delete(`/crm/profession/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Occupation
export const fetchOccupations = (options: IFetchOccupationParams) =>
  http.get(`/crm/occupation/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      name: options.name,
      offset: options.offset,
    },
  });

export const fetchOccupation = (token: string, uniqueId: string) =>
  http.get(`/crm/occupation/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createOccupation = (token: string, occupation: IOccupation) =>
  http.post(`/crm/occupation/`, occupation, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateOccupation = (
  token: string,
  uniqueId: string,
  occupation: IOccupation
) =>
  http.patch(`/crm/occupation/${uniqueId}/`, occupation, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteOccupation = (token: string, uniqueId: string) =>
  http.delete(`/crm/occupation/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CreditReviewAuthorization
export const fetchCreditReviewAuthorizations = (
  options: IFetchCreditReviewAuthorizationParams
) =>
  http.get(`/lending/credit-review-authorization/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      offset: options.offset,
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      debtor_unique_id: options.debtorUniqueId,
      status: options.status,
      title: options.title,
    },
  });

export const fetchCreditReviewAuthorization = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/credit-review-authorization/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createCreditReviewAuthorization = (
  token: string,
  creditReviewAuthorizations: ICreditReviewAuthorization
) =>
  http.post(
    `/lending/credit-review-authorization/`,
    creditReviewAuthorizations,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateCreditReviewAuthorization = (
  token: string,
  uniqueId: string,
  creditReviewAuthorizations: ICreditReviewAuthorization
) =>
  http.patch(
    `/lending/credit-review-authorization/${uniqueId}/`,
    creditReviewAuthorizations,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteCreditReviewAuthorization = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/credit-review-authorization/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// Position
export const createPositions = (token: string, position: IPosition) =>
  http.post("/crm/position/", position, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchPositions = (options: IPositionParams) =>
  http.get("/crm/position/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      name: options.name,
      search: options.searchQuery,
      limit: options.limit,
      offset: options.offset,
    },
  });

export const fetchPosition = (token: string, uniqueId: string) =>
  http.get(`/crm/position/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Cancellation Membership
export const fetchCancellationMembershipContracts = (
  options: IFetchCancellationMembershipContractParams
) =>
  http.get(`/lending/cancellation-membership-form/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      date_from: options.dateFrom,
      date_to: options.dateTo,
      debtor_unique_id: options.debtorUniqueId,
      title: options.title,
      status: options.status,
      offset: options.offset,
    },
  });
export const fetchCancellationMembershipContract = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/cancellation-membership-form/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createCancellationMembershipContract = (
  token: string,
  cancellationMembershipContract: ICancellationMembershipContract
) =>
  http.post(
    `/lending/cancellation-membership-form/`,
    cancellationMembershipContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateCancellationMembershipContract = (
  token: string,
  uniqueId: string,
  cancellationMembershipContract: ICancellationMembershipContract
) =>
  http.patch(
    `/lending/cancellation-membership-form/${uniqueId}/`,
    cancellationMembershipContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteCancellationMembershipContract = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/cancellation-membership-form/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Debit Card Or Passbook Account Membership
export const fetchDebitCardOrPassbookAccountContracts = (
  options: IFetchDebitCardOrPassbookAccountContractParams
) =>
  http.get(`/lending/debit-card-or-passbook-account/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      debtor_unique_id: options.debtorUniqueId,
      title: options.title,
      status: options.status,
      type: options.type,
      offset: options.offset,
    },
  });

export const fetchDebitCardOrPassbookAccountContract = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/debit-card-or-passbook-account/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createDebitCardOrPassbookAccountContract = (
  token: string,
  debitCardOrPassbookAccountContract: IDebitCardOrPassbookAccountContract
) =>
  http.post(
    `/lending/debit-card-or-passbook-account/`,
    debitCardOrPassbookAccountContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateDebitCardOrPassbookAccountContract = (
  token: string,
  uniqueId: string,
  debitCardOrPassbookAccountContract: IDebitCardOrPassbookAccountContract
) =>
  http.patch(
    `/lending/debit-card-or-passbook-account/${uniqueId}/`,
    debitCardOrPassbookAccountContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteDebitCardOrPassbookAccountContract = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/debit-card-or-passbook-account/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Debit Card Or Passbook Account Membership
export const fetchSavingsAccountContracts = (
  options: IFetchSavingsAccountContractParams
) =>
  http.get(`/lending/savings-account-form/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      opening_date_from: options.openingDateFrom,
      opening_date_to: options.openingDateTo,
      main_debtor_unique_id: options.mainDebtorUniqueId,
      secondary_debtors_unique_id: options.secondaryDebtorsUniqueId,
      main_debtor_name: options.mainDebtorName,
      type: options.type,
      title: options.title,
      status: options.status,
      offset: options.offset,
    },
  });

export const fetchSavingsAccountContract = (token: string, uniqueId: string) =>
  http.get(`/lending/savings-account-form/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createSavingsAccountContract = (
  token: string,
  SavingsAccountContract: ISavingsAccountContract
) =>
  http.post(`/lending/savings-account-form/`, SavingsAccountContract, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateSavingsAccountContract = (
  token: string,
  uniqueId: string,
  SavingsAccountContract: ISavingsAccountContract
) =>
  http.patch(
    `/lending/savings-account-form/${uniqueId}/`,
    SavingsAccountContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteSavingsAccountContract = (token: string, uniqueId: string) =>
  http.delete(`/lending/savings-account-form/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// inputCertification
export const fetchInputCertificationContracts = (
  options: IFetchInputCertificationContractParams
) =>
  http.get(`/lending/input-certification-form/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      status: options.status,
      limit: options.limit,
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      member_name: options.memberName,
      input_amount_from: options.inputAmountFrom,
      input_amount_to: options.inputAmountTo,
      input_amount: options.inputAmount,
      debtor_unique_id: options.debtorUniqueId,
      certificate_number: options.certificateNumber,
      offset: options.offset,
    },
  });

export const fetchInputCertificationContract = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/input-certification-form/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createInputCertificationContract = (
  token: string,
  InputCertification: IInputCertificationContract
) =>
  http.post(`/lending/input-certification-form/`, InputCertification, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateInputCertificationContract = (
  token: string,
  uniqueId: string,
  InputCertification: IInputCertificationContract
) =>
  http.patch(
    `/lending/input-certification-form/${uniqueId}/`,
    InputCertification,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteInputCertificationContract = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/input-certification-form/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// FinancialCertification
export const fetchFinancialCertificationContracts = (
  options: IFetchFinancialCertificationContractParams
) =>
  http.get(`/lending/financial-certificate-form/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      main_debtor_unique_id: options.mainDebtorUniqueId,
      member_name: options.memberName,
      secondary_debtors_unique_id: options.secondaryDebtorsUniqueId,
      due_date_from: options.dueDateFrom,
      due_date_to: options.dueDateTo,
      payment_type: options.paymentType,
      certificate_number: options.certificateNumber,
      sign_type: options.signType,
      offset: options.offset,
      status: options.status,
    },
  });

export const fetchFinancialCertificationContract = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/financial-certificate-form/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createFinancialCertificationContract = (
  token: string,
  FinancialCertification: IFinancialCertificationContract
) =>
  http.post(`/lending/financial-certificate-form/`, FinancialCertification, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateFinancialCertificationContract = (
  token: string,
  uniqueId: string,
  FinancialCertification: IFinancialCertificationContract
) =>
  http.patch(
    `/lending/financial-certificate-form/${uniqueId}/`,
    FinancialCertification,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteFinancialCertificationContract = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/financial-certificate-form/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Warranty
export const fetchWarranties = (options: IFetchWarrantyParams) =>
  http.get(`/lending/warranty/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      warranty_type: options.warrantyType,
      debtor_name: options.debtorName,
      debtor: options.debtor,
      limit: options.limit,
      offset: options.offset,
    },
  });

export const fetchWarranty = (token: string, uniqueId: string) =>
  http.get(`/lending/warranty/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createWarranty = (token: string, warranty: IWarranty) =>
  http.post(`/lending/warranty/`, warranty, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateWarranty = (
  token: string,
  uniqueId: string,
  warranty: IWarranty
) =>
  http.patch(`/lending/warranty/${uniqueId}/`, warranty, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const deleteWarranty = (token: string, uniqueId: string) =>
  http.delete(`/lending/warranty/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Interest
export const fetchInterests = (options: IFetchInterestParams) =>
  http.get(`/outlay/interest/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      calculator_type: options.calculatorType,
      minimum_balance: options.minimumBalance,
      minimum_quota: options.minimumQuota,
      contribution_amount: options.contributionAmount,
      cutting_day: options.cuttingDay,
      savings_book: options.savingsBook,
      offset: options.offset,
    },
  });

export const fetchInterest = (token: string, uniqueId: string) =>
  http.get(`/outlay/interest/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createInterest = (token: string, interest: IInterest) =>
  http.post(`/outlay/interest/`, interest, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateInterest = (
  token: string,
  uniqueId: string,
  interest: IInterest
) =>
  http.patch(`/outlay/interest/${uniqueId}/`, interest, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const deleteInterest = (token: string, uniqueId: string) =>
  http.delete(`/outlay/interest/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const fetchFilterInterest = (options: IFetchInterestParams) =>
  http.post(`/outlay/filter_interest`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });

export const fetchInternationalBlackLists = (
  options: IFetchInternationalBlackListParams
) =>
  http.get(`/outlay/international-national-blacklist/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      full_name: options.fullName,
      crime_committed: options.crimeCommitted,
      identification_number: options.identificationNumber,
      creation_date_from: options.creationDateFrom,
      creation_date_to: options.creationDateTo,
      offset: options.offset,
    },
  });

export const fetchInternationalBlackList = (token: string, uniqueId: string) =>
  http.get(`/outlay/international-national-blacklist/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createInternationalBlackList = (
  internationalBlackList: IInternationalBlackList
) =>
  http.post(
    `/outlay/international-national-blacklist/`,
    internationalBlackList,
    {
      headers: {
        Authorization: `Bearer ${internationalBlackList.token}`,
      },
    }
  );

export const updateInternationalBlackList = (
  token: string,
  uniqueId: string,
  internationalBlackList: IInternationalBlackList
) =>
  http.patch(
    `/outlay/international-national-blacklist/${uniqueId}/`,
    internationalBlackList,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteInternationalBlackList = (token: string, uniqueId: string) =>
  http.delete(`/outlay/international-national-blacklist/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadEvidence = (token: string, uniqueId: string, file: any) =>
  http.post(
    `/outlay/international-national-blacklist/${uniqueId}/upload_evidence/`,
    file,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const uploadRecords = (token: string, file: any) =>
  http.post("/outlay/international-national-blacklist/upload_records/", file, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

export const printAnalystTemplate = (token: string, ticketId: string) =>
  http.get(`/tms/ticket/${ticketId}/print_credit_analisys/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const printOutlayTemplate = (token: string, ticketId: string) =>
  http.get(`/outlay/outlay/${ticketId}/print/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// PepsList
export const fetchPepsLists = (options: IFetchPepsListParams) =>
  http.get(`/outlay/peps-list/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      full_name: options.fullName,
      institution: options.institution,
      status: options.status,
      identification_number: options.identificationNumber,
      creation_date_from: options.creationDateFrom,
      creation_date_to: options.creationDateTo,
      offset: options.offset,
    },
  });

export const fetchPepsList = (token: string, uniqueId: string) =>
  http.get(`/outlay/peps-list/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createPepsList = (pepsList: IPepsList) =>
  http.post(`/outlay/peps-list/`, pepsList, {
    headers: {
      Authorization: `Bearer ${pepsList.token}`,
    },
  });

export const updatePepsList = (
  token: string,
  uniqueId: string,
  pepsList: IPepsList
) =>
  http.patch(`/outlay/peps-list/${uniqueId}/`, pepsList, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deletePepsList = (token: string, uniqueId: string) =>
  http.delete(`/outlay/peps-list/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadFile = (token: string, uniqueId: string, file: any) =>
  http.post(`/outlay/peps-list/${uniqueId}/upload_file/`, file, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

export const uploadPEPs = (token: string, file: any) =>
  http.post("/outlay/peps-list/upload_records/", file, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

// OFACAndONUList
export const fetchOFACAndONULists = (options: IFetchOFACAndONUListParams) =>
  http.get(`/outlay/ofac-onu-list/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      full_name: options.fullName,
      identification_number: options.identificationNumber,
      person_type: options.personType,
      country: options.country,
      creation_date_from: options.creationDateFrom,
      creation_date_to: options.creationDateTo,
      offset: options.offset,
    },
  });

export const fetchOFACAndONUList = (token: string, uniqueId: string) =>
  http.get(`/outlay/ofac-onu-list/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createOFACAndONUList = (OFACAndONUList: IOFACAndONUList) =>
  http.post(`/outlay/ofac-onu-list/`, OFACAndONUList, {
    headers: {
      Authorization: `Bearer ${OFACAndONUList.token}`,
    },
  });

export const updateOFACAndONUList = (
  token: string,
  uniqueId: string,
  OFACAndONUList: IOFACAndONUList
) =>
  http.patch(`/outlay/ofac-onu-list/${uniqueId}/`, OFACAndONUList, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteOFACAndONUList = (token: string, uniqueId: string) =>
  http.delete(`/outlay/ofac-onu-list/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadFileOFAC = (token: string, uniqueId: string, file: any) =>
  http.post(`/outlay/ofac-onu-list/${uniqueId}/upload_file/`, file, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

// export const uploadPEPs = (token: string, file: any) =>
//     http.post('/outlay/ofac-onu-list/upload_records/', file, {
//         headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': "multipart/form-data",
//         }
//     });

// Payroll Deductions
export const fetchPayrollDeductions = (options: IFetchPayrollDeductionParams) =>
  http.get(`/lending/payroll-deduction-form/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      creditor_unique_id: options.creditorUniqueId,
      debtor_unique_id: options.debtorUniqueId,
      status: options.status,
      limit: options.limit,
      offset: options.offset,
      title: options.title,
    },
  });

export const fetchPayrollDeduction = (token: string, uniqueId: string) =>
  http.get(`/lending/payroll-deduction-form/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createPayrollDeduction = (
  token: string,
  providerContact: IPayrollDeduction
) =>
  http.post(`/lending/payroll-deduction-form/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updatePayrollDeduction = (
  token: string,
  uniqueId: string,
  providerContact: IPayrollDeduction
) =>
  http.patch(`/lending/payroll-deduction-form/${uniqueId}/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deletePayrollDeduction = (token: string, uniqueId: string) =>
  http.delete(`/lending/payroll-deduction-form/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// Affidavits Of Funds
export const fetchAffidavitsOfFunds = (options: IFetchAffidavitOfFundsParams) =>
  http.get(`/lending/affidavit-of-funds/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      institution_unique_id: options.institutionUniqueId,
      debtor_name: options.debtorName,
      debtor_unique_id: options.debtorUniqueId,
      limit: options.limit,
      status: options.status,
      offset: options.offset,
      title: options.title,
    },
  });

export const fetchAffidavitOfFunds = (token: string, uniqueId: string) =>
  http.get(`/lending/affidavit-of-funds/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createAffidavitOfFunds = (
  token: string,
  providerContact: IAffidavitOfFunds
) =>
  http.post(`/lending/affidavit-of-funds/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateAffidavitOfFunds = (
  token: string,
  uniqueId: string,
  providerContact: IAffidavitOfFunds
) =>
  http.patch(`/lending/affidavit-of-funds/${uniqueId}/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteAffidavitOfFunds = (token: string, uniqueId: string) =>
  http.delete(`/lending/affidavit-of-funds/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// Client Affidavits Of Funds
export const fetchClientAffidavitsOfFunds = (
  options: IFetchClientAffidavitOfFundsParams
) =>
  http.get(`/lending/client-affidavit-of-funds/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      debtor_unique_id: options.debtorUniqueId,
      status: options.status,
      limit: options.limit,
      offset: options.offset,
      title: options.title,
    },
  });

export const fetchClientAffidavitOfFunds = (token: string, uniqueId: string) =>
  http.get(`/lending/client-affidavit-of-funds/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createClientAffidavitOfFunds = (
  token: string,
  providerContact: IClientAffidavitOfFunds
) =>
  http.post(`/lending/client-affidavit-of-funds/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateClientAffidavitOfFunds = (
  token: string,
  uniqueId: string,
  providerContact: IClientAffidavitOfFunds
) =>
  http.patch(
    `/lending/client-affidavit-of-funds/${uniqueId}/`,
    providerContact,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteClientAffidavitOfFunds = (token: string, uniqueId: string) =>
  http.delete(`/lending/client-affidavit-of-funds/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// Compliance Registatration Forms
export const fetchComplianceRegistrationForms = (
  options: IFetchComplianceRegistrationFormParams
) =>
  http.get(`/lending/compliance-registration-form/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      debtor_unique_id: options.debtorUniqueId,
      institution_unique_id: options.institutionUniqueId,
      limit: options.limit,
      offset: options.offset,
      title: options.title,
    },
  });

export const fetchComplianceRegistrationForm = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/compliance-registration-form/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createComplianceRegistrationForm = (
  token: string,
  providerContact: IComplianceRegistrationForm
) =>
  http.post(`/lending/compliance-registration-form/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateComplianceRegistrationForm = (
  token: string,
  uniqueId: string,
  providerContact: IComplianceRegistrationForm
) =>
  http.patch(
    `/lending/compliance-registration-form/${uniqueId}/`,
    providerContact,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteComplianceRegistrationForm = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/compliance-registration-form/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const executeBot = (token: string, options: any) =>
  http.post("/lending/payment-settings/execute_bot/", options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const stopBot = (token: string, options: any) =>
  http.post("/lending/payment-settings/stop_bot/", options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// PledgeContractsWithoutDivestment
export const fetchPledgeContractsWithoutDivestment = (
  options: IFetchPledgeContractWithoutDivestmentParams
) =>
  http.get(`/lending/pledge-contract-without-divestment/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      creditor_unique_id: options.creditorUniqueId,
      debtor_unique_id: options.debtorUniqueId,
      status: options.status,
      limit: options.limit,
      offset: options.offset,
      title: options.title,
      token: options.token,
    },
  });

export const fetchPledgeContractWithoutDivestment = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/pledge-contract-without-divestment/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createPledgeContractWithoutDivestment = (
  token: string,
  providerContact: IPledgeContractWithoutDivestment
) =>
  http.post(`/lending/pledge-contract-without-divestment/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updatePledgeContractWithoutDivestment = (
  token: string,
  uniqueId: string,
  providerContact: IPledgeContractWithoutDivestment
) =>
  http.patch(
    `/lending/pledge-contract-without-divestment/${uniqueId}/`,
    providerContact,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deletePledgeContractWithoutDivestment = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/pledge-contract-without-divestment/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// ROHI CLIENT LOANS & INFO
export const fetchRohiClientLoans = (options: IRohiClientLoans) =>
  http.post(`/utility/rohi-client-loans/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });

export const fetchRohiClientLoansInfo = (options: IRohiClientLoans) =>
  http.post(`/utility/rohi-client-loans-info/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });

// ROHI CREDIT METHODS
export const fetchRohiSavingAccounts = (options: IRohiOutlay) =>
  http.post(`/utility/rohi-savings-accounts/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });
export const fetchRohiFinancialCertificates = (options: IRohiOutlay) =>
  http.post(`/utility/rohi-financial-certificate/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });

// ROHI EXPIRATION UPLOAD
export const createRohiParticipant = (
  token: string,
  options: IRohiParticipant
) =>
  http.post(`/utility/rohi-participants/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updateRohiParticipant = (
  token: string,
  options: IRohiParticipant
) =>
  http.patch(`/utility/rohi-participants/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// ROHI OUTLAY TICKET DISBURSEMENTS
export const fetchRohiOutlay = (options: IRohiOutlay) =>
  http.post(`/utility/rohi-outlay-ticket/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });
export const postRohiTicketOutlay = (
  token: string,
  options: IRohiTicketOutlay
) =>
  http.post(`/utility/rohi-outlay-middleware-rework-barcelona/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Payment Calculator
export const fetchLoans = (token: string, options: any) =>
  http.post(`/utility/rohi-payment-calculator/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const calculateNextQuota = (token: string, options: any) =>
  http.post(`/outlay/payment`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const submitRohiInstitution = (
  token: string,
  uniqueId: string,
  db: string
) =>
  http.post(
    `/utility/middleware-rohi-institution-${db}/`,
    { unique_id: uniqueId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const submitRohiNationality = (
  token: string,
  uniqueId: number,
  db: string
) =>
  http.post(
    `/utility/middleware-rohi-nationality-${db}/`,
    { id: uniqueId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const submitRohiParticipant = (
  token: string,
  uniqueId: string,
  db: string
) =>
  http.post(
    `/utility/middleware-rohi-participant-${db}/`,
    { unique_id: uniqueId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const rohiSavingAccountsInfo = (options: IRohiSavingAccounts) =>
  http.post(`/utility/rohi-saving-accounts-info/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      associate_id: options.associateId,
    },
  });
export const rohiSavingAccountsDetails = (options: IRohiSavingAccountDetails) =>
  http.post(`/utility/rohi-saving-accounts-details/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      saving_account_number: options.savingAccountNumber,
      associate_id: options.associateId,
      rohi_id: options.rohiId,
      part_id: options.partId,
      from_date: options.fromDate,
      to_date: options.toDate,
      debit_amount_lower_bound: options.debitAmountLowerBound,
      debit_amount_uper_bound: options.debitAmountUperBound,
      credit_amount_lower_bound: options.creditAmountLowerBound,
      credit_amount_uper_bound: options.creditAmountUperBound,
    },
  });
export const rohiInvestmentCertificate = (
  options: IRohiInvestmentCertificates
) =>
  http.post(`/utility/rohi-investment-certificate/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      associate_id: options.associateId,
    },
  });
export const rohiInvestmentCertificateDetails = (
  options: IRohiInvestmentCertificatesDetails
) =>
  http.post(`/utility/rohi-investment-certificate-details/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      saving_account_number: options.savingAccountNumber,
      associate_id: options.associateId,
      rohi_id: options.rohiId,
      part_id: options.partId,
      from_date: options.fromDate,
      to_date: options.toDate,
      debit_amount_lower_bound: options.debitAmountLowerBound,
      debit_amount_uper_bound: options.debitAmountUperBound,
      credit_amount_lower_bound: options.creditAmountLowerBound,
      credit_amount_uper_bound: options.creditAmountUperBound,
    },
  });
export const rohiLoans = (options: IRohiLoans) =>
  http.post(`/utility/rohi-loans/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      associate_id: options.associateId,
    },
  });
export const rohiOutlay = (options: IRohiLoans) =>
  http.post(`/utility/rohi-outlay2/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      associate_id: options.associateId,
      associate_number: options.associateNumber,
    },
  });
export const rohiArr = (options: IRohiLoans) =>
  http.post(`/utility/rohi-arr/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      associate_id: options.associateId,
    },
  });
export const ticketInfo = (options: IRohiLoans) =>
  http.post(`/utility/ticket-info/`, options, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      associate_id: options.associateId,
    },
  });
export const AccountStatusTemplates = (token: string, options: any) =>
  http.post(`/lending/account-status-templates`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      client: options.client,
      saving_accounts: options.savingAccounts,
      saving_plans: options.savingPlans,
      investment_certificate: options.investmentCertificate,
      loans: options.loans,
      loan: options.loan,
      logged_user: options.loggedUser,
      details_data: options.detailsData,
      outlays: options.outlays,
      arr: options.arr,
      key: options.key,
      rows_per_page: options.rowsPerPage,
      total_account_balance: options.totalAccountBalance,
      total_available_balance: options.totalAvailableBalance,
      total_account_balance_cert: options.totalAccountBalanceCert,
      total_available_balance_cert: options.totalAvailableBalanceCert,
      total_loans_amount: options.totalLoansAmount,
      total_next_amount_due: options.totalNextAmountDue,
      total_saving_fee: options.totalSavingFee,
      from_date: options.fromDate,
      to_date: options.toDate,
    },
  });

// Compliance Registatration Forms
export const fetchAutomaticWithdrawalAuthorizationContracts = (
  options: IFetchAutomaticWithdrawalAuthorizationContractParams
) =>
  http.get(`/lending/automatic-withdrawal-authorization/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      contract_date_from: options.contractDateFrom,
      contract_date_to: options.contractDateTo,
      creditor_name: options.creditorName,
      debtor_unique_id: options.debtorUniqueId,
      limit: options.limit,
      offset: options.offset,
      title: options.title,
    },
  });

export const fetchAutomaticWithdrawalAuthorizationContract = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/automatic-withdrawal-authorization/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createAutomaticWithdrawalAuthorizationContract = (
  token: string,
  providerContact: IAutomaticWithdrawalAuthorizationContract
) =>
  http.post(`/lending/automatic-withdrawal-authorization/`, providerContact, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateAutomaticWithdrawalAuthorizationContract = (
  token: string,
  uniqueId: string,
  providerContact: IAutomaticWithdrawalAuthorizationContract
) =>
  http.patch(
    `/lending/automatic-withdrawal-authorization/${uniqueId}/`,
    providerContact,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteAutomaticWithdrawalAuthorizationContract = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/automatic-withdrawal-authorization/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
// Actas
export const fetchActs = (option: IActParams) =>
  http.get(`/lending/approbation-act/`, {
    headers: {
      Authorization: `Bearer ${option.token}`,
    },
    params: {
      act_number: option.actNumber,
      limit: option.limit,
      offset: option.offset,
    },
  });

export const fetchAct = (token: string, uniqueId: string) =>
  http.get(`/lending/approbation-act/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchNodes = (token: string) =>
  http.get(`/lending/infrastructure/nodes/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchPods = (token: string) =>
  http.get(`/lending/pod-management/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createPods = (token: string, uniqueId: string) =>
  http.get(`/lending/specific-order/${uniqueId}/create_pods/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deletePods = (token: string, uniqueId: string) =>
  http.get(`/lending/specific-order/${uniqueId}/delete_pods/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchInfrastructures = (
  token: string,
  financialInstitution: string,
  workInstitution: string
) =>
  http.get(`/lending/infrastructure/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      work_institution: workInstitution,
      financial_institution: financialInstitution,
    },
  });

export const fetchInfrastructure = (token: string, uniqueId: string) =>
  http.get(`/lending/infrastructure/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createInfrastructure = (
  token: string,
  infrastructure: IInfrastructure
) =>
  http.post(`/lending/infrastructure/`, infrastructure, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateInfrastructure = (
  token: string,
  uniqueId: string,
  infrastructure: IInfrastructure
) =>
  http.patch(`/lending/infrastructure/${uniqueId}/`, infrastructure, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteInfrastructure = (token: string, uniqueId: string) =>
  http.delete(`/lending/infrastructure/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const printApprobationAct = (token: string, uniqueId: string) =>
  http.get(`/lending/approbation-act/${uniqueId}/print/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const printBalanceLetter = (data: IBalanceLetter) =>
  http.post(`crm/participant/print_balance_letter/`, data, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

// Loan Approbation
export const fetchLoanApprobation = (token: string, options: any) =>
  http.get(`/lending/loan-approbation-act/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      participant: options.participant,
      act: options.act,
      ticket: options.ticket,
      assignee: options.assignee,
      status: options.status,
      requested_amount_from: options.amountFrom,
      requested_amount_to: options.amountTo,
      request_date_from: options.dateFrom,
      request_date_to: options.dateTo,
      limit: options.limit,
      offset: options.offset,
    },
  });

// export const updateLoanApprobation = (token: string, uniqueId: string, options: any) =>
//     http.patch(`/lending/loan-approbation-act/${uniqueId}/`, options, {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     });

export const updateLoanApprobation = (token: string, options: any) =>
  http.patch(`/lending/loan-approbation-act/${options.uniqueId}/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchApprobationActs = (options?: any) =>
  http.get(`/lending/approbation-act/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      act_number: options.act_number || "",
      associate_approbation: options.associate_approbation,
      type: options.type || "",
    },
  });

export const fetchApprobationAct = (token: string, uniqueId: string) =>
  http.get(`/lending/approbation-act/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const createApprobationAct = (token: string, options: any) =>
  http.post(`/lending/approbation-act/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchAssociateApprobation = (token: string, options?: any) =>
  http.get(`/lending/associate-approbation-act/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      act: options.act,
      ticket: options.ticket,
      ticket_status: options.ticket_status,
      status: options.status,
      participant: options.participant,
      request_date_from: options.contractDateFrom || options.request_date_from,
      request_date_to: options.contractDateTo || options.request_date_to,
      limit: options.limit,
      offset: options.offset,
    },
  });
export const updateAssociateApprobationAct = (token: string, options: any) =>
  http.patch(
    `/lending/associate-approbation-act/${options.uniqueId}/`,
    options,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const createAssociateApprobation = (token: string, options: any) =>
  http.post(`/lending/associate-approbation-act/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const generateAssociateApprobations = (token: string, options: any) =>
  http.post(`/lending/generate-associate-approbations`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tickets: options.tickets,
    },
  });

export const generateLoanApprobations = (token: string, options: any) =>
  http.post(`/lending/generate-loan-approbations`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      tickets: options.tickets,
    },
  });

export const fetchHumanResources = (options: IHumanParams) =>
  http.get(`/crm/human-resource/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      search: options.search,
      limit: options.limit,
      offset: options.offset,
      identification_number: options.identificationNumber,
      institution: options.institution,
    },
  });

export const fetchHumanResource = (token: string, uniqueId: string) =>
  http.get(`crm/human-resource/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createHumanResources = (humanResource: IHumanResources) =>
  http.post(`crm/human-resource/`, humanResource, {
    headers: {
      Authorization: `Bearer ${humanResource.token}`,
    },
  });

export const updateHumanResources = (humanResource: IHumanResources) =>
  http.patch(`crm/human-resource/${humanResource.uniqueId}/`, humanResource, {
    headers: {
      Authorization: `Bearer ${humanResource.token}`,
    },
  });

export const deleteHumanResources = (token: string, uniqueId: string) =>
  http.delete(`crm/human-resource/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createHRTuNomina = (humanResource: IHumanResources) =>
  http.post(
    `crm/human-resource/${humanResource.uniqueId}/create_human_resource/`,
    humanResource,
    {
      headers: {
        Authorization: `Bearer ${humanResource.token}`,
      },
    }
  );

export const fetchExpirationLog = (token: string, expiration: string) =>
  http.get(`/utility/expiration-log/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      expiration: expiration,
    },
  });

export const fetchBusinessRepresentatives = (
  options: IFetchBusinessRepresentativeParams
) =>
  http.get(`crm/business-representatives/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      offset: options.offset,
      name: options.name,
      is_active: options.isActive,
      rohi_id: options.rohiId,
    },
  });
export const fetchBusinessRepresentative = (token: string, uniqueId: string) =>
  http.get(`/crm/business-representatives/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createBusinessRepresentative = (
  token: string,
  business: IBusinessRepresentative
) => {
  return http.post("/crm/business-representatives/", business, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const updateBusinessRepresentative = (
  token: string,
  business: IBusinessRepresentative
) =>
  http.patch(`/crm/business-representatives/${business.uniqueId}/`, business, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const submitRohiAgents = (token: string, uniqueId: string) =>
  http.post(
    `/utility/rohi-agents/`,
    { unique_id: uniqueId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteBusinessRepresentative = (token: string, uniqueId: string) =>
  http.delete(`/crm/business-representatives/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD claims
export const fetchClaims = (options: IFetchClaimParams) =>
  http.get("/crm/claim/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      client_unique_id: options.clientUniqueId,
      status: options.status,
      department: options.department,
      type_of_case: options.typeOfCase,
      creation_date_from: options.creationDateFrom,
      creation_date_to: options.creationDateTo,
      limit: options.limit,
      offset: options.offset,
    },
  });

export const fetchClaim = (token: string, uniqueId: string) =>
  http.get(`/crm/claim/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchClaimsCountByParticipant = (options: IFetchClaimParams) =>
  http.get("/crm/claim/get_status_count_by_participant/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      client_unique_id: options.clientUniqueId,
    },
  });

export const createClaim = (token: string, claim: IClaim) => {
  return http.post("/crm/claim/", claim, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const updateClaim = (token: string, claim: IClaim) =>
  http.patch(`/crm/claim/${claim.uniqueId}/`, claim, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteClaim = (token: string, uniqueId: string) =>
  http.delete(`/crm/claim/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// CRUD claims
export const fetchClaimDetails = (options: IFetchClaimDetailParams) =>
  http.get("/crm/claim-detail/", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      client_unique_id: options.clientUniqueId,
      status: options.status,
      department: options.department,
      type_of_case: options.typeOfCase,
      creation_date_from: options.creationDateFrom,
      creation_date_to: options.creationDateTo,
      limit: options.limit,
      offset: options.offset,
    },
  });
export const fetchClaimDetail = (token: string, uniqueId: string) =>
  http.get(`/crm/claim-detail/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createClaimDetail = (token: string, claim: IClaimDetail) => {
  return http.post("/crm/claim-detail/", claim, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const updateClaimDetail = (token: string, claim: IClaimDetail) =>
  http.patch(`/crm/claim-detail/${claim.uniqueId}/`, claim, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteClaimDetail = (token: string, uniqueId: string) =>
  http.delete(`/crm/claim-detail/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const putUpateClaim = (options: IFetchClaimParams) => {
  http.put(`/crm/claim_detail`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      unique_id: options.uniqueId,
      details: options.details,
      department: options.department,
      type_of_case: options.typeOfCase,
      description: options.description,
      comments: options.description,
      status: options.status,
      client: options.clientUniqueId,
    },
  });
};

export const createDetailFileAttachment = (
  token: string,
  uniqueId: string,
  options: any
) =>
  http.post(`/crm/claim-detail/${uniqueId}/upload_file/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

export const createClaimFileAttachment = (
  token: string,
  uniqueId: string,
  options: any
) =>
  http.post(`/crm/claim/${uniqueId}/upload_file/`, options, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

// Electronic Means Service Contract
export const fetchElectronicMeansServiceContracts = (
  options: IFetchElectronicMeansServiceContractParams
) =>
  http.get(`/lending/electronic-means-service-contract/`, {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
    params: {
      limit: options.limit,
      date_from: options.contractDateFrom,
      date_to: options.contractDateTo,
      debtor_unique_id: options.debtorUniqueId,
      title: options.title,
      status: options.status,
      offset: options.offset,
    },
  });
export const fetchElectronicMeansServiceContract = (
  token: string,
  uniqueId: string
) =>
  http.get(`/lending/electronic-means-service-contract/${uniqueId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createElectronicMeansServiceContract = (
  token: string,
  electronicMeansServiceContract: IElectronicMeansServiceContract
) =>
  http.post(
    `/lending/electronic-means-service-contract/`,
    electronicMeansServiceContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateElectronicMeansServiceContract = (
  token: string,
  uniqueId: string,
  electronicMeansServiceContract: IElectronicMeansServiceContract
) =>
  http.patch(
    `/lending/electronic-means-service-contract/${uniqueId}/`,
    electronicMeansServiceContract,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteElectronicMeansServiceContract = (
  token: string,
  uniqueId: string
) =>
  http.delete(`/lending/electronic-means-service-contract/${uniqueId}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchDelayReports = (
  token: string,
  perPage: number,
  page: number,
  filters: any
) =>
  http.get(`/utility/delay-reports/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      filters,
      page,
      per_page: perPage,
    },
  });

export const UploadInsuranceRecord = (token: string, params: any) =>
  http.post("/utility/rohi-monthly-insurance-minio/", params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const GetInsuranceRecord = (token: string, params: any) =>
  http.get("/utility/rohi-monthly-insurance-minio/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const generateRiskMatrix = (token: string, params: any) =>
  http.get("/crm/risk-matrix/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
    responseType: "blob",
  });
