import { CLAIM_TYPES } from '../actions';

export default function(state: IClaim[] = [], action: IAction<IClaim[]>): IClaim[] {
    switch(action.type) {
        case CLAIM_TYPES.FETCH_ALL:
            return action.payload || [];
        case CLAIM_TYPES.FETCH:
            return state.map(claim => (action.payload !== undefined && claim.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: claim);
        case CLAIM_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case CLAIM_TYPES.UPDATE:
            return state.map(claim => (action.payload !== undefined && claim.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : claim);
        case CLAIM_TYPES.DELETE:
            return state.filter(claim => action.payload !== undefined && claim.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}
