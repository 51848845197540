import "../Calculators.css";
import MoneyInput from "src/components/TextMaskInputComponents/moneyInput";
import React, { Component } from "react";
import interestRateInput from "src/components/TextMaskInputComponents/interestRateInput";
import {
  plannedSavingsCalulator,
  fetchInterests
} from "src/utils/axios";
import {
  faCalculator,
  faBroom,
} from "@fortawesome/free-solid-svg-icons";
import MuiAlert from "@material-ui/lab/Alert";

import {
  Button,
  FormControl,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  createStyles,
  MenuItem,
  withStyles,
  FormHelperText,
  Typography,
  Snackbar,
  Grid,
} from "@material-ui/core";
import {
  translations,
  HEADERS_COOPSAN,
} from "src/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { green } from "@material-ui/core/colors";
import { connect } from "react-redux";
import { AlertTitle } from "@material-ui/lab";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#4CAF50",
      },
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
      color: "red",
    },
    "& input:focus + fieldset, & select:focus + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& label.Mui-focused": {
      color: "green",
    },
    margin: 8,
  },

})(TextField);

const styles = createStyles({
  autoComplete: {
    color: "#4CAF50",
    margin: 8,
    width: "40%",
  },
  calculate: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  calculateDisabled: {
    border: "2px solid",
    borderRadius: 5,
    fontSize: 16,
  },
  clean: {
    background: "#F44636",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  download: {
    background: "#37C0EC",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  formControl: {
    margin: 8,
  },
  gridFormControl: {
    margin: 8,
    width: '100%',
  },
  pcp: {
    backgroundColor: green[500],
    color: "white",
  },
  save: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
});

class CoopSanCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calculationType: "",
      calculatorType: "coopsan",
      openingDate: new Date().toISOString().split("T")[0],
      periodicity: "monthly",
      quota: "",
      tableContent: [],
      timeLimit: "",
      interest: "",
      averageAnnualInterest: "",
      irr: "",
      periods: [],
      minQuota: 0,
      calculate: false,
      errors: {},
    };

    this.initialState = {
      calculationType: "",
      calculatorType: "coopsan",
      openingDate: new Date().toISOString().split("T")[0],
      periodicity: "monthly",
      quota: "",
      tableContent: [],
      timeLimit: "",
      interest: "",
      averageAnnualInterest: "",
      irr: "",
      calculate: false,
      errors: {},
    };

    this.calculateTable = this.calculateTable.bind(this);
    this.fillDisabledFields = this.fillDisabledFields.bind(this);
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.switchState = this.switchState.bind(this);
  }

  componentDidMount() {
    const { token } = this.props.user;

    let options = {
      limit: 20,
      token,
      offset: 0,
      calculatorType: this.state.calculatorType,
    }
    fetchInterests(options).then(res => {
      if (res.data.results.length === 0) {
        this.setState({
          openAlert: true,
          severity: 'error',
          alertTitle: 'Ha ocurrido un error',
          alertMessage: 'Debe crear la configuración de interés para Ahorros CoopSan',
          calculate: true,
        })
      } else {
        const data = res.data.results[0]
        this.setState({
          periods: data.description.sort((a,b) => a.consecutiveQuotas - b.consecutiveQuotas),
          minQuota: data.minimumQuota
        })
      }
    });
    this.setState({ loading: false });
  }

  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || "" });
    };
  }

  handleSelectChange(key) {
    /* Handle the state of Select components */
    return (event) => {
      if (key === "timeLimit") {
        this.setState({
          [key]: event.target.value,
          interest: event.target.value.distributionPercentage,
          averageAnnualInterest: event.target.value.interestRate,
        })
      } else {
        this.setState({
          [key]: event.target.value,
        });
      }
    };
  }

  handleAutocompleteChange(key, obj) {
    /* Handle the state of Autocomplete components */
    if (obj) {
      this.setState({
        [key]: obj.value,
      });
    }
  }

  switchState(key) {
    this.setState({
      [key]: !this.state[key],
    });
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleReset() {
    this.setState({ ...this.initialState });
  }
  
  fillDisabledFields() {
    let s = { ...this.state };

    s.errors = {}

    s.amount = parseFloat(s.amount) || 0.0;
    s.quota = parseFloat(s.quota) || 0.0;


    // Funcion interna para darle formato a las fechas
    const formatDate = (dateObj) => {
      // Agregar un 0 en caso de que mes o dia sean menor a 9
      const month =
        dateObj.getMonth() + 1 <= 9
          ? `0${dateObj.getMonth() + 1}`
          : dateObj.getMonth() + 1;
      const day =
        dateObj.getDate() <= 9 ? `0${dateObj.getDate()}` : dateObj.getDate();

      return `${dateObj.getFullYear()}-${month}-${day}`;
    };

    // Si la fecha de cotizacion no se especifica
    // poner el valor del dia actual por defecto
    if (!s.openingDate) {
      s.openingDate = formatDate(new Date());
    }

    return s;
  }

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };

    temp.quota = fieldValues.quota >= fieldValues.minQuota ? "" : "La cuota debe ser mayor o igual a " + fieldValues.minQuota;

    temp.timeLimit = fieldValues.timeLimit.consecutiveQuotas !== "" && fieldValues.timeLimit.consecutiveQuotas > 0.00 ? "" : "El plazo debe ser mayor a 0";

    // Verificar que el y/o los dias de pago esten entre el rango del 1 al 30

    temp.paymentDay =
      fieldValues.paymentDay >= 1 && fieldValues.paymentDay <= 30
        ? ""
        : "El dia de pago digitado debe ser entre 1 y 30";

    /* Handle validations before saving */
    const fields = [
      "calculatorType",
      "interest",
      "periodicity"
    ];
    
    for (let field of Object.keys(fieldValues)) {
      if (fields.includes(field)) {
        //  Validar que los campos no esten vacíos
        if (!this.state[field]) {
          temp[field] = "Campo Requerido";
        }
      }
    }

    this.setState({
      errors: { ...temp },
    });

    if (fieldValues === this.state) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  calculateTable(state, token) {
    //  create Json object with formatted data
    const options = JSON.stringify({
      periods: parseInt(this.state.timeLimit.consecutiveQuotas,10),
      calculator_type: this.state.calculatorType,
      opening_date: this.state.openingDate,
      interest_rate: parseFloat(this.state.interest),
      quota: parseFloat(this.state.quota),
      payment_day: parseInt(this.state.paymentDay,10),
      periodicity: this.state.periodicity
    });
    // Call API And display data on screen
    plannedSavingsCalulator(options, token)
      .then((res) => {
        this.setState({
          tableContent: res.data.table,
          // averageAnnualInterest: res.data.annualAverageInterest,
          irr: res.data.irr,
        });
      })
      .catch((err) => console.error(err));
  }

  handleTable() {
    const { token } = this.props.user;
    const { state } = this;

    this.setState(
      // Get new state object and pass it to set state
      this.fillDisabledFields(),
      () => {
        // Call back the validate the actual state
        if (this.validate()) {
          this.calculateTable(state, token);
          this.switchState("openCalculate");
        }
      }
    );
  }

  render() {
    const classes = styles;
    const { errors, openAlert, alertMessage, severity, alertTitle } = this.state;

    return (
      <div className="quotation-container">
        <Snackbar
          open={openAlert}
          // autoHideDuration={6000}
          onClose={() => this.switchState("openAlert")}
        >
          <MuiAlert
            onClose={() => this.switchState("openAlert")}
            severity={severity}
          >
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertMessage}
          </MuiAlert>
        </Snackbar>
        <Typography variant="h4">
          {"Calculadora de CoopSan"}
        </Typography>
        <div>
          <section className="header-section1">
            <Grid container={true} className={classes.half} spacing={2}>
              <Grid item={true} xs={3}>
                <CssTextField
                  style={{ width: "100%" }}
                  type="date"
                  label={translations.OPENING_DATE}
                  variant="outlined"
                  disabled={false}
                  onChange={this.handleChange("openingDate")}
                  value={this.state.openingDate}
                  inputProps={{ max: new Date().toISOString().split("T")[0] }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <CssTextField
                  error= {errors.quota ? true : false}
                  helperText= {errors.quota}
                  id= {"quota"}
                  label= {translations.QUOTA}
                  required= {true}
                  style= {{ width: "100%" }}
                  value= {this.state.quota}
                  variant= "outlined"
                  disabled={false}
                  onChange={this.handleCurrencyInputChange("quota")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: MoneyInput,
                  }}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <CssTextField
                  error= {errors.interest ? true : false}
                  helperText= {errors.interest}
                  id= {"interest"}
                  label= {translations.INTEREST}
                  required= {true}
                  style= {{ width: "100%" }}
                  value= {this.state.interest}
                  variant= "outlined"
                  disabled={true}
                  onChange={this.handleChange("interest")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: interestRateInput,
                  }}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <FormControl 
                  variant="outlined"
                  style={classes.gridFormControl}
                  error={errors.timeLimit ? true : false}
                >
                  <InputLabel
                    id="timeLimit"
                  >
                    {translations.TERM}
                  </InputLabel>
                  <Select
                    label={translations.TERM}
                    id="timeLimit"
                    disabled={false}
                    style={{ width: "100%" }}
                    onChange={this.handleSelectChange('timeLimit')}
                    value={this.state.timeLimit}
                  >
                    {this.state.periods.map((opt, indx) => {
                      return (
                        <MenuItem value={opt} key={indx}>
                          {opt.consecutiveQuotas}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{errors.timeLimit}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item={true} xs={3}>
                <CssTextField
                  error= {errors.paymentDay ? true : false}
                  helperText= {errors.paymentDay}
                  id= {"paymentDay"}
                  label= {translations.PAYMENT_DAY}
                  style= {{ width: "100%" }}
                  value= {this.state.paymentDay}
                  variant= "outlined"
                  required= {true}
                  disabled= {false}
                  onChange={this.handleChange("paymentDay")}
                  type="number"
                />
              </Grid>
              <Grid item={true} xs={3}>
                <CssTextField
                  error= {errors.irr ? true : false}
                  helperText= {errors.irr}
                  id= {"irr"}
                  label= {"IRR"}
                  required= {false}
                  style= {{ width: "100%" }}
                  value= {this.state.irr}
                  variant= "outlined"
                  disabled={true}
                  onChange={this.handleChange("irr")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: interestRateInput,
                  }}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <CssTextField
                  error= {errors.averageAnnualInterest ? true : false}
                  helperText= {errors.averageAnnualInterest}
                  id= {"averageAnnualInterest"}
                  label= {`Promedio ${translations.ANNUAL_INTEREST}`}
                  required= {false}
                  style= {{ width: "100%" }}
                  value= {this.state.averageAnnualInterest}
                  variant= "outlined"
                  disabled={true}
                  onChange={this.handleChange("averageAnnualInterest")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: interestRateInput,
                  }}
                />
              </Grid>
            </Grid>
            <div className="action-section">
              <div className="action-button">
                  <Button
                    style={classes.clean}
                    onClick={this.handleReset}
                  >
                    <FontAwesomeIcon icon={faBroom} />
                    &nbsp; Limpiar
                  </Button>
                  <Button
                    style={this.state.calculate ? classes.calculateDisabled : classes.calculate}
                    onClick={this.handleTable}
                    disabled={this.state.calculate}
                  >
                    <FontAwesomeIcon icon={faCalculator} />
                    &nbsp; Calcular
                  </Button>
              </div>
            </div>
          </section>
        </div>
        <div>
          <div className="table-section">
            <TableContainer component={Paper} className="amort-table">
              <Table size="small" stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    {HEADERS_COOPSAN.map((opt, indx) => {
                      return (
                        <TableCell
                          style={{ color: "white", background: "#4caf50" }}
                          key={indx}
                        >
                          {opt.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.tableContent.map((opt, indx) => {
                    if (indx > 0) {
                      return (
                        <TableRow key={indx}>
                          {opt.map((campo, key) => (
                            <TableCell key={key}>{campo}</TableCell>
                          ))}
                        </TableRow>
                      );
                    }
                    return false;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps)(CoopSanCalculator)
);
