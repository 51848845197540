import * as React from 'react';
import NumberInput, { NumberFormatProps } from 'react-number-format';

// import { InputBaseComponentProps } from '@material-ui/core/InputBase';

const interestRateInput = (props: NumberFormatProps) => {
    const { onChange, ...others } = props;
    return (
        <NumberInput
            {...others}
            decimalSeparator="."
            suffix= '%'
            max = {100}
            maxLength={9}
            min = {0}
            step="any"
            onChange={onChange}
        />
    );
}

export default interestRateInput;