
import { ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES.FETCH_ALL:
            return action.payload || [];
        case ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES.FETCH:
            return state.map(acquaintanceshipProviderContract => (action.payload !== undefined && acquaintanceshipProviderContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: acquaintanceshipProviderContract);
        case ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES.UPDATE:
            return state.map(acquaintanceshipProviderContract => (action.payload !== undefined && acquaintanceshipProviderContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : acquaintanceshipProviderContract);
        case ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES.DELETE:
            return state.filter(acquaintanceshipProviderContract => action.payload !== undefined && acquaintanceshipProviderContract.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}