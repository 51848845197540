import { Grid, IconButton } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { TableSearch } from 'src/components/SharedComponents/Fields';
import { GeneralTable } from 'src/components/SharedComponents/Table';

function InsuranceTable(props) {
    const [tableData, setTableData] = useState([])

    const actions = (row) => {
        return(
            <Grid container direciton="row">
                <IconButton
                    onClick={() => props.handleDownload(row)}
                >
                    <GetApp style={{fontSize:22, color:'#616161'}} />
                </IconButton>
            </Grid>
        );
    };


    const columns = [
        {id:'filename', label: 'NOMBRE DE ARCHIVO', minWidth: 150},
        {id:'url', label: 'ACCIÓN', minWidth: 150, element: actions},
    ];

    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);
  return (
    <GeneralTable
            columns={columns}
            data={tableData}
            selected={props.selected}
            setSelected={props.setSelected}
            handleSelected={props.handleSelected}
            toolbar={
                <Grid container justifyContent='flex-end' alignItems='center'>
                    <TableSearch
                        data={props.data}
                        setTableData={setTableData}
                        searchFields={['filename']}
                    />
                </Grid>
            }
        />
  )
}

export default InsuranceTable