import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";

import { fetchAttorneys } from "../../../actions";
import { fetchParticipants } from "../../../utils/axios";
import AttorneySearchSection from "../../Attorney/AttorneySearchSection";
import AttorneyForm from "../AttorneyForm";

const styles: any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

interface IAttorneySelectInputProps {
  classes: {
    button: string;
    root: string;
  };

  user: IUser;
  attorneys: IParticipant[];
  selectedAttorneysId: string[];
  addAttorney(attorneyId: string): void;
  deleteAttorney(attorneyId: string): void;
  fetchAttorneys(attorneyId: IParticipant[]): IAction<IParticipant[]>;
}
interface IAttorneySelectInputState {
  attorneys: IParticipant[];
  formDialog: boolean;
  open: boolean;
  loading: boolean;
}

class AttorneySelectInput extends React.Component<
  IAttorneySelectInputProps,
  IAttorneySelectInputState
> {
  public state: IAttorneySelectInputState = {
    attorneys: [],
    formDialog: false,
    loading: false,
    open: false,
  };
  constructor(props: IAttorneySelectInputProps) {
    super(props);

    this.findAttorneys = this.findAttorneys.bind(this);
    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  public componentDidMount() {
    this.setState({ loading: true });
    fetchParticipants({
      token: this.props.user.token,
      participantType: "attorney",
    })
      .then((res) => {
        this.props.fetchAttorneys(
          res.data.results ? res.data.results : res.data
        );
        this.setState({
          attorneys: res.data.results
            ? res.data.results
            : (res.data as IParticipant[]),
          loading: false,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public openDialogHandler() {
    this.setState({ open: true });
  }
  public closeDialogHandler() {
    this.setState({ open: false });
  }
  public openFormDialog() {
    this.setState({ formDialog: true });
  }
  public closeFormDialog() {
    this.setState({ formDialog: false });
  }
  public emptyFunction() {
    return null;
  }
  public handleItemClick(attorneyId: string) {
    //  request data then add the id
    this.props.addAttorney(attorneyId);
    this.setState({ open: false });
  }
  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.props.deleteAttorney(id);
    };
  }
  public findAttorneys(uniqueId: string) {
    const curAttorney: IParticipant | undefined = this.state.attorneys.find(
      (attorney) => attorney.uniqueId === uniqueId
    );
    if (curAttorney) {
      return (
        <TableRow key={curAttorney.uniqueId}>
          <TableCell>{curAttorney.fullName || ""}</TableCell>
          <TableCell>{curAttorney.identificationNumber || ""}</TableCell>
          <TableCell>{curAttorney.attorneyNumber}</TableCell>
          <TableCell>
            <IconButton
              onClick={this.handleDeleteItem(
                curAttorney.uniqueId ? curAttorney.uniqueId : ""
              )}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    } else {
      return null;
    }
  }
  public fetchData(params: IFetchParticipantParams) {
    params.limit = undefined;
    params.offset = 0;

    this.setState({ loading: true });
    fetchParticipants(params)
      .then((res) => {
        this.props.fetchAttorneys(
          res.data.results ? res.data.results : (res.data as IParticipant[])
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public render() {
    const { classes } = this.props;
    const { formDialog, open, loading } = this.state;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={this.closeDialogHandler}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.openFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: 600, width: 280 }}>
            <AttorneySearchSection
              loading={loading}
              handleClick={this.handleItemClick}
              fetchData={this.fetchData}
              pagination={false}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={formDialog}
          fullScreen={true}
          onClose={this.closeFormDialog}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <AttorneyForm changeSection={this.emptyFunction} />
          </DialogContent>
        </Dialog>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.NAME}</TableCell>
                <TableCell>{translations.IDENTIFICATION}</TableCell>
                <TableCell>{translations.ATTORNEY_NUMBER}</TableCell>
                <TableCell>
                  <IconButton onClick={this.openDialogHandler}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.selectedAttorneysId.map((attorneyId) =>
                this.findAttorneys(attorneyId)
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    attorneys: state.attorneys,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchAttorneys })(AttorneySelectInput)
);
