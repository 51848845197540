import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  Switch,
  TextField,
  Typography,
  Checkbox,
  createStyles,
  MenuItem,
} from "@material-ui/core";
import {
  catchError,
  financialMethods,
  headersCheck,
  // jsonToQuestionArray,
  // questionArrayToJson,
  successHandler,
  translations,
} from "src/utils";
import AlertDialog from "../../components/AlertDialog";
import { green, orange, red } from "@material-ui/core/colors";
import {
  deleteChargingMethod as deleteCM,
  fetchDepositBankAccounts,
  fetchInstitutions,
  updateChargingMethod as updateCM,
} from "../../utils/axios";
import { deleteChargingMethod, updateChargingMethod } from "../../actions";
import ExtraPaymentTable from "../../components/Ticket/ExtraPayTable/ExtraPaymentTable";
import CreditCardSelectInput from "../CreditCard/CreditCardSelectInput";
import SecretQuestionsSelectInput from "src/components/SecretQuestionsSelectInput";
import phoneMaskInput from "../../components/TextMaskInputComponents/phoneMaskInput";
import codeMaskInput from "../../components/TextMaskInputComponents/codeMaskInput";
import InstitutionsSelectInput from "../Institutions/InstitutionsSelectInput";
import FileUploadSelectInput from "../FileUpload/FileUploadSelectInput";

const styles = createStyles({
  checkbox: {
    color: "#4caf50 !important",
    padding: "1px !important",
  },
  clean: {
    background: orange[500],
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  codes: {
    margin: "0px 4px",
  },
  delete: {
    background: red[500],
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  formControl: {
    margin: "0px 4px",
  },
  grid: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateRows: "repeat(10, 60px)",
  },
  root: {
    padding: "10px 0px",
  },
  save: {
    background: green[500],
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  textField: {
    margin: "0px 4px",
    width: "46%",
  },
});

class TabPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bank: "",
      bankAccounts: [],
      bankEmail: "",
      bankPassword: "",
      bankPhone: "",
      bankUsername: "",
      banks: [],
      chargingAccount: [],
      checkAmount: "",
      clientAccounts: [],
      codeCard: {},
      contactEmail: "",
      creditCard: [],
      deviceExpirationDate: "",
      deviceNumber: "",
      dialog: false,
      dialogTitle: "",
      editIdx: -1,
      emailPassword: "",
      errorMessage: "",
      errors: {},
      flierAmount: "",
      guaranteedChecks: [],
      hasToken: false,
      hasWarranty: false,
      institution: [],
      label: "",
      secretQuestions: [],
    };

    this.initialState = { ...this.state };

    this.addInstitution = this.addInstitution.bind(this);
    this.deleteInstitution = this.deleteInstitution.bind(this);
    this.fillAccountsByBank = this.fillAccountsByBank.bind(this);
    this.handleAddCreditCard = this.handleAddCreditCard.bind(this);
    this.handleAddQuestion = this.handleAddQuestion.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTD = this.handleChangeTD.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleDeleteCreditCard = this.handleDeleteCreditCard.bind(this);
    this.handleDeleteMethod = this.handleDeleteMethod.bind(this);
    this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
    this.handleUpdateQuestion = this.handleUpdateQuestion.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleStartEdit = this.handleStartEdit.bind(this);
    this.handleStopEdit = this.handleStopEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
  }

  async componentDidMount() {
    const { data } = this.props;

    let banksId = undefined;
    const range = Array.from({ length: (40 - 1) / 1 + 1 }, (_, i) => 1 + i * 1);
    this.setState((state) => {
      for (let item of range) {
        state.codeCard[item] = "";
      }
      return state;
    });

    this.setState({
      bankEmail: data.bankEmail || "",
      bankPassword: data.bankPassword || "",
      bankPhone: data.bankPhone || "",
      bankUsername: data.bankUsername || "",
      bank: data.institution || "",
      chargingAccount: data.chargingAccount || "",
      chargingMethod: data.chargingMethod.replace(/ /g, "_") || "",
      checkAmount: data.chargingMethod === "check" ? data.amount : "",
      codeCard: data.codeCard || {},
      contactEmail: data.contactEmail || "",
      creditCard: [data.creditCard || ""],
      deviceExpirationDate: data.deviceExpirationDate || "",
      deviceNumber: data.deviceNumber || "",
      emailPassword: data.emailPassword || "",
      flierAmount: data.chargingMethod === "flier" ? data.amount : "",
      guaranteedChecks: data.guaranteedChecks || "",
      hasToken: data.hasToken || false,
      hasWarranty: data.hasWarranty || false,
      institution: [data.institution || ""],
      label: data.label || "",
      secretQuestions: data.secretQuestions || [], // jsonToQuestionArray(data.secretQuestions) || {},
      uniqueId: data.uniqueId || "",
    });

    await fetchDepositBankAccounts({
      owner: data.owner,
      token: this.props.user.token,
    })
      .then((res) => {
        this.setState({ bankAccounts: res.data });
        try {
          const bankId = res.data.filter(
            (x) => x.uniqueId === data.chargingAccount
          )[0].bank;
          this.fillAccountsByBank(bankId);
          const banks = res.data.map((item) => item.bank);
          const filter = banks.filter((v, i) => banks.indexOf(v) === i);
          banksId = filter.join();
        } catch (error) {
          return;
        }
      })
      .catch((e) => console.error(e));

    fetchInstitutions({
      institutionType: "financial",
      intitutionsId: banksId,
      token: this.props.user.token,
    })
      .then((res) => {
        this.setState({ banks: res.data });
      })
      .catch((e) => console.error(e));
  }

  fillAccountsByBank(bankId) {
    this.setState((state) => {
      state.bank = bankId;
      state.clientAccounts = state.bankAccounts.filter(
        (acc) => acc.bank === bankId
      );

      return state;
    });
  }

  handleRemove = (i) => {
    this.setState((state) => {
      state.guaranteedChecks = state.guaranteedChecks.filter(
        (row, j) => j !== i
      );
      return state;
    });
  };

  handleStartEdit = (i) => {
    this.setState({ editIdx: i });
  };

  handleStopEdit = () => {
    this.setState({ editIdx: -1 });
  };

  handleChangeTD = (i, x) => {
    this.setState((state) => {
      state.guaranteedChecks = state.guaranteedChecks.map((row, j) =>
        j === i ? x : row
      );
      return state;
    });
    this.handleStopEdit();
  };

  handleSave(data) {
    this.setState((state) => {
      state.guaranteedChecks = data;
      return state;
    });
  }

  handleAddCreditCard(id) {
    this.setState({ creditCard: [id] });
  }

  handleDeleteCreditCard(id) {
    this.setState({ creditCard: [""] });
  }

  addInstitution(id) {
    this.setState({ institution: [id] });
  }

  deleteInstitution(id) {
    this.setState({ institution: [""] });
  }

  handleAddQuestion(question) {
    this.setState((prevState) => ({
      secretQuestions: [...prevState.secretQuestions, question],
    }));
  }

  handleDeleteQuestion(index) {
    this.setState((prevState) => ({
      secretQuestions: prevState.secretQuestions.filter(
        (question, i) => i !== index
      ),
    }));
  }

  handleUpdateQuestion(question, index) {
    this.setState((prevState) => ({
      secretQuestions: prevState.secretQuestions.map((q, i) => {
        if (i === index) {
          q.question = question.question;
          q.answer = question.answer;
        }
        return q;
      }),
    }));
  }

  handleCurrencyChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || "" });
    };
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleChangeCode(key, newValue) {
    this.setState((s) => {
      s.codeCard[key] = newValue;
      return s;
    });
  }

  handleSwitch(key) {
    return (event) => {
      this.setState({
        [key]: !this.state[key],
      });
    };
  }

  handleSelectChange(key) {
    return (event) => {
      this.setState({
        [key]: event.target.value,
      });
    };
  }

  handleCheckboxChange(key) {
    return (event) => {
      this.setState({
        [key]: event.target.checked,
      });
    };
  }

  handleReset() {
    const range = Array.from({ length: (40 - 1) / 1 + 1 }, (_, i) => 1 + i * 1);
    this.setState({ ...this.initialState });

    this.setState((state) => {
      for (let item of range) {
        state.errors[item] = "";
      }
      return state;
    });
  }

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };
    if (this.state.chargingMethod === "internet_banking") {
      if ("bankPassword" in fieldValues) {
        temp.bankPassword = fieldValues.bankPassword
          ? ""
          : "Este campo no puede estar vacio";
      }
      if ("bankUsername" in fieldValues) {
        temp.bankUsername = fieldValues.bankUsername
          ? ""
          : "Este campo no puede estar vacio";
      }
      if ("chargingAccount" in fieldValues) {
        temp.chargingAccount = fieldValues.chargingAccount
          ? ""
          : "Este campo no puede estar vacio";
      }
      if (!this.state.hasToken) {
        for (let code in fieldValues.codeCard) {
          if (`${code}` in fieldValues.codeCard) {
            temp[`${code}`] =
              fieldValues.codeCard[code] !== ""
                ? fieldValues.codeCard[code].length < 4 ||
                  fieldValues.codeCard[code].length > 5
                  ? "El codigo debe tener entre 4 y 5 digitos"
                  : ""
                : "Debe digitar este codigo";
          }
        }
      }
    }
    if (this.state.chargingMethod === "payroll") {
      if ("contactEmail" in fieldValues) {
        temp.contactEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          fieldValues.contactEmail
        )
          ? ""
          : "Email invalido";
      }
    }
    this.setState({
      errors: { ...temp },
    });

    if (fieldValues === this.state) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  handleSubmit(event) {
    const { changeOccurs } = this.props;
    event.preventDefault();
    const state = this.state;
    const props = this.props;

    let method = {
      chargingMethod: state.chargingMethod.replace(/_/g, " "),
      owner: props.ownerId,
    };

    if (this.state.chargingMethod === "internet_banking") {
      method = {
        ...method,
        bankEmail: state.bankEmail || null,
        bankPassword: state.bankPassword || null,
        bankPhone: state.bankPhone,
        bankUsername: state.bankUsername || null,
        institution: state.bank || null,
        chargingAccount: state.chargingAccount || null,
        codeCard: state.hasToken ? null : state.codeCard,
        deviceExpirationDate: state.deviceExpirationDate || null,
        deviceNumber: state.deviceNumber,
        emailPassword: state.emailPassword,
        hasToken: state.hasToken,
        label: state.label,
        secretQuestions: JSON.parse(JSON.stringify(state.secretQuestions)), // questionArrayToJson(state.secretQuestions),
      };
    } else if (this.state.chargingMethod === "check") {
      method = {
        ...method,
        amount: this.state.checkAmount,
        guaranteedChecks: this.state.hasWarranty
          ? this.state.guaranteedChecks
          : null,
        hasWarranty: this.state.hasWarranty,
      };
    } else if (this.state.chargingMethod === "payroll_card") {
      method = {
        ...method,
        creditCard: this.state.creditCard[0],
      };
    } else if (this.state.chargingMethod === "flier") {
      method = {
        ...method,
        amount: state.flierAmount,
      };
    } else if (this.state.chargingMethod === "payroll") {
      method = {
        ...method,
        contactEmail: state.contactEmail,
        institution: state.institution[0],
      };
    } else {
      method = {
        ...method,
      };
    }

    if (this.validate()) {
      updateCM(this.props.user.token, this.state.uniqueId, method)
        .then((res) => {
          this.props.updateChargingMethod(
            res.data.results ? res.data.results : res.data
          );
          successHandler(res, this, translations.CHARGING_METHOD);
          changeOccurs();
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }

  handleDeleteMethod() {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      const { changeOccurs } = this.props;

      this.setState({ loading: true });
      deleteCM(this.props.user.token, this.state.uniqueId)
        .then((res) => {
          this.props.deleteChargingMethod(this.state.uniqueId);
          changeOccurs();
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }

  render() {
    const classes = styles;
    const { value, index } = this.props;
    const range = Array.from({ length: (40 - 1) / 1 + 1 }, (_, i) => 1 + i * 1);
    const { errors } = this.state;

    const renderTab = (chargingMethod) => {
      switch (chargingMethod) {
        case "internet_banking":
          return (
            <>
              <FileUploadSelectInput
                contractId={this.state.uniqueId}
                url={`crm/chargingmethod/${this.state.uniqueId}/upload_code_card/`}
                description="charging-method"
              />
              <TextField
                label={translations.BANK_USERNAME}
                style={classes.textField}
                value={this.state.bankUsername}
                error={errors.bankUsername ? true : false}
                helperText={errors.bankUsername}
                onChange={this.handleChange("bankUsername")}
                fullWidth={true}
              />
              <TextField
                label={translations.PASSWORD}
                style={classes.textField}
                value={this.state.bankPassword}
                error={errors.bankPassword ? true : false}
                helperText={errors.bankPassword}
                onChange={this.handleChange("bankPassword")}
                fullWidth={true}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.hasToken}
                    onChange={this.handleSwitch("hasToken")}
                    name="hasToken"
                  />
                }
                label="Token"
              />

              <br />
              {!this.state.hasToken ? (
                <div style={classes.grid}>
                  {range.map((x, indx) => {
                    return (
                      <TextField
                        key={indx}
                        label={`No. ${x}`}
                        style={classes.codes}
                        InputProps={{
                          inputComponent: codeMaskInput,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.codeCard[x]}
                        error={errors[x] ? true : false}
                        onChange={(e) =>
                          this.handleChangeCode(`${x}`, e.currentTarget.value)
                        }
                      />
                    );
                  })}
                </div>
              ) : null}

              <Typography variant="h6">
                {translations.SECRET_QUESTIONS}
              </Typography>
              <div>
                <SecretQuestionsSelectInput
                  questions={this.state.secretQuestions}
                  addQuestion={this.handleAddQuestion}
                  updQuestion={this.handleUpdateQuestion}
                  deleteQuestion={this.handleDeleteQuestion}
                />
              </div>
              <TextField
                label={translations.DEVICE_NUMBER}
                style={classes.textField}
                value={this.state.deviceNumber}
                error={errors.deviceNumber ? true : false}
                helperText={errors.deviceNumber}
                type="number"
                onChange={this.handleChange("deviceNumber")}
              />
              <TextField
                label={translations.DEVICE_EXPIRATION_DATE}
                style={classes.textField}
                value={this.state.deviceExpirationDate}
                error={errors.deviceExpirationDate ? true : false}
                helperText={errors.deviceExpirationDate}
                type="date"
                onChange={this.handleChange("deviceExpirationDate")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label={translations.BANK_EMAIL}
                style={classes.textField}
                placeholder="myemail@email.com"
                type="email"
                value={this.state.bankEmail}
                error={errors.bankEmail ? true : false}
                helperText={errors.bankEmail}
                onChange={this.handleChange("bankEmail")}
                fullWidth={true}
              />
              <TextField
                label={translations.BANK_PASSWORD}
                style={classes.textField}
                value={this.state.emailPassword}
                error={errors.emailPassword ? true : false}
                helperText={errors.emailPassword}
                onChange={this.handleChange("emailPassword")}
                fullWidth={true}
              />
              <TextField
                label={translations.BANK_CELL}
                style={classes.textField}
                value={this.state.bankPhone}
                onChange={this.handleChange("bankPhone")}
                fullWidth={true}
                InputProps={{
                  inputComponent: phoneMaskInput,
                }}
              />
              <FormControl style={classes.formControl}>
                <InputLabel>{translations.BANK}</InputLabel>
                <Select
                  onChange={(e) => this.fillAccountsByBank(e.target.value)}
                  value={this.state.bank}
                  style={{ width: "16em" }}
                  inputProps={{
                    name: "bank",
                  }}
                >
                  {this.state.banks.map((bank, indx) => {
                    return (
                      <MenuItem value={bank.uniqueId} key={indx}>
                        {bank.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl
                style={classes.formControl}
                error={errors.chargingAccount ? true : false}
              >
                <InputLabel shrink={true}>{`Cuenta de Cobro`}</InputLabel>
                <Select
                  value={this.state.chargingAccount}
                  onChange={this.handleSelectChange("chargingAccount")}
                  style={{ width: "16em" }}
                  inputProps={{
                    name: "chargingAccount",
                  }}
                >
                  {this.state.clientAccounts.map((account, indx) => {
                    return (
                      <MenuItem value={account.uniqueId} key={indx}>
                        {`${account.name} - ${account.number}`}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.chargingAccount}</FormHelperText>
              </FormControl>
            </>
          );
        case "check":
          return (
            <>
              <FileUploadSelectInput
                contractId={this.state.uniqueId}
                url={`crm/chargingmethod/${this.state.uniqueId}/upload_code_card/`}
                description="charging-method"
              />
              <TextField
                label={translations.AMOUNT}
                style={classes.textField}
                type="number"
                value={this.state.checkAmount}
                onChange={this.handleChange("checkAmount")}
                fullWidth={true}
              />
              <FormControlLabel
                label="El cheque posee garantia"
                control={
                  <Checkbox
                    style={{
                      color: "#4caf50 !important",
                      padding: "1px !important",
                    }}
                    checked={this.state.hasWarranty}
                  />
                }
                onChange={this.handleCheckboxChange("hasWarranty")}
                labelPlacement="end"
              />
              <br />
              <br />
              {this.state.hasWarranty ? (
                <ExtraPaymentTable
                  actions={true}
                  create={true}
                  data={this.state.guaranteedChecks}
                  editIdx={this.state.editIdx}
                  handleChange={this.handleChangeTD}
                  handleRemove={this.handleRemove}
                  handleSave={this.handleSave}
                  handleStartEdit={this.handleStartEdit}
                  handleStopEdit={this.handleStopEdit}
                  header={headersCheck}
                  tableLimit={5}
                />
              ) : null}
            </>
          );
        case "payroll_card":
          return (
            <CreditCardSelectInput
              selectedId={this.state.creditCard}
              add={this.handleAddCreditCard}
              delete={this.handleDeleteCreditCard}
            />
          );
        case "flier":
          return (
            <TextField
              label={translations.AMOUNT}
              style={classes.textField}
              value={this.state.flierAmount}
              type="number"
              onChange={this.handleChange("flierAmount")}
              fullWidth={true}
            />
          );
        case "payroll":
          return (
            <>
              <Typography variant="h6">{translations.INSTITUTION}</Typography>
              <div>
                <InstitutionsSelectInput
                  selectedInstitutionId={this.state.institution}
                  addInstitution={this.addInstitution}
                  deleteInstitution={this.deleteInstitution}
                />
              </div>
              <TextField
                label={`${translations.EMAIL} de ${translations.CONTACT}`}
                style={{ margin: "10px" }}
                value={this.state.contactEmail}
                type="email"
                error={errors.contactEmail ? true : false}
                helperText={errors.contactEmail}
                onChange={this.handleChange("contactEmail")}
                fullWidth={true}
              />
            </>
          );
        case "cash":
          return <Typography>Este metodo no tiene campos</Typography>;
        default:
          return <Typography>Escoja un Metodo de Cobro</Typography>;
      }
    };

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={index}
        aria-labelledby={`simple-tab-${index}`}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleSwitch("dialog")}
        />
        {value === index && (
          <Box p={3}>
            <FormControl style={classes.formControl}>
              <InputLabel>{`Tipo de ${translations.CHARGING_METHOD}`}</InputLabel>
              <Select
                style={{ width: "13em" }}
                disabled={true}
                value={this.state.chargingMethod}
                inputProps={{
                  name: "chargingMethod",
                }}
              >
                {financialMethods.map((method, indx) => {
                  return (
                    <MenuItem value={method.value} key={indx}>
                      {method.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div>{renderTab(this.state.chargingMethod)}</div>
            <div style={{ padding: "10px 0px" }}>
              <Button onClick={this.handleSubmit} style={classes.save}>
                Guardar
              </Button>
              <Button
                onClick={this.handleReset}
                color="default"
                style={classes.clean}
              >
                Limpiar
              </Button>
              <Button style={classes.delete} onClick={this.handleDeleteMethod}>
                Eliminar
              </Button>
            </div>
          </Box>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps, {
  deleteChargingMethod,
  updateChargingMethod,
})(TabPanel);
