import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IconButton,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import {
  Visibility,
  Delete as DeleteIcon,
  AttachFile,
  ArrowBack,
} from "@material-ui/icons";
import { deleteFileAttachment, fetchFileAttachments } from "../../../actions";
import {
  deleteFileAttachment as Delete,
  fetchFileAttachments as fetchFA,
  getFileAttachment,
} from "../../../utils/axios";
import { catchError, successHandler, translations } from "src/utils";
import AlertDialog from "src/components/AlertDialog";
import LoadingDialog from "src/components/SharedComponents/LoadingScreen";
import FileUploadForm from "src/containers/FileUpload/FileUploadForm";
import { GeneralTable } from "src/components/SharedComponents/Table";
import { TableSearch } from "src/components/SharedComponents/Fields";
import { green } from "@material-ui/core/colors";

const FilesTable = ({ contractId, user, deleteFileAttachment }) => {
  const [fileAttachments, setFileAttachments] = useState([]);
  const [uploadFile, setUploadFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    alertDialog: false,
    message: "",
    title: "",
  });
  const [uploadDialog, setUploadDialog] = useState({ open: false, url: "" });
  const attachmentTypes = [
    {
      type: `${contractId}-credit-file`,
      label: "Historial Crediticio",
      url: `crm/participant/${contractId}/upload_credit_file/`,
    },
    {
      type: `${contractId}-identification-file`,
      label: "Identificación",
      url: `crm/participant/${contractId}/upload_identification_file/`,
    },
    {
      type: `${contractId}-warranty-file`,
      label: "Garantía",
      url: `crm/participant/${contractId}/upload_warranty_file/`,
    },
  ];

  const DialogHandler = () => {
    setDialog({ alertDialog: false, message: "", title: "" });
  };

  const UploadDialogHandler = () => {
    setUploadFile(false);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [contractId]);

  const fetchData = () => {
    const params = {
      name: contractId,
      offset: 0,
      token: user.token,
    };
    fetchFA(params)
      .then((res) => {
        const data = res.data.results ? res.data.results : res.data;
        const filteredData = data.filter((file) =>
          attachmentTypes.some((desc) => file.name.includes(desc.type))
        );
        const organizedData = organizeFiles(filteredData);
        setFileAttachments(organizedData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleViewFile = (uniqueId) => {
    const file = fileAttachments.find((file) => file.uniqueId === uniqueId);
    if (file) {
      setLoading(true);
      const endpoint = getFileAttachment(user.token, uniqueId);
      endpoint
        .then((res) => {
          const dbuff = new Uint8Array(res.data.file).buffer;
          const data = new Blob([dbuff], { type: res.data.type });
          const fileURL = URL.createObjectURL(data);
          const NewWindow = window.open();
          if (NewWindow) {
            NewWindow.location.href = fileURL;
          } else {
            window.location.href = fileURL;
          }
          setLoading(false);
        })
        .catch((errors) => {
          console.error(errors);
          setLoading(false);
          setDialog({
            alertDialog: true,
            message: "No se ha encontrado ningún adjunto para ser abierto.",
            title: "ERROR",
          });
        });
    }
  };

  const handleDeleteItem = (uniqueId) => {
    setLoading(true);
    Delete(user.token, uniqueId)
      .then((res) => {
        deleteFileAttachment(uniqueId);
        fetchData();
        setLoading(false);
        setDialog({
          alertDialog: true,
          message: "Se ha eliminado el adjunto con éxito.",
          title: translations.SUCCESS,
        });
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setDialog({
          alertDialog: true,
          message: "No hay ningún adjunto para eliminar.",
          title: "ERROR",
        });
      });
  };

  const SelectUploadFile = (event) => {
    setUploadDialog({...uploadDialog, url: event.target.value });
  };

  const organizeFiles = (files) => {
    return files.map((file) => {
      const attachmentType = attachmentTypes.find((desc) =>
        file.name.includes(desc.type)
      );
      return {
        attachmentType: attachmentType.label,
        name: file.file.split("/").pop(),
        uniqueId: file.uniqueId,
        file: file.file,
      };
    });
  };

  return (
    <Grid>
      <LoadingDialog open={loading} />
      <AlertDialog
        open={dialog.alertDialog}
        message={dialog.message}
        title={dialog.title}
        handleClose={DialogHandler}
      />
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Typography
        variant={"h5"}
        className={"section-header"}
        style={{
          display: "flex",
          alignItems: "center",
          color: "#605F5F",
          fontWeight: "bold",
          margin: "20px",
          textTransform: "uppercase",
        }}
      >
        archivos adjuntos
      </Typography>
      
        <Button
          variant="outlined"
          disableElevation
          style={{
            borderRadius: 3,
            backgroundColor: green[500],
            color: "#fff",
            fontSize: 12,
            marginBottom: "1rem",
            marginLeft: "1rem",
          }}
          onClick={() => setUploadDialog({open: true})}
        >
          Agregar nuevo adjunto
          <AttachFile style={{ fontSize: 17, marginLeft: ".5rem" }} />
        </Button>
      </Grid>
      <Grid
        style={{
          margin: "20px",
        }}
      >
        <AttachmentTable
          data={fileAttachments}
          handleViewFile={handleViewFile}
          handleDeleteItem={handleDeleteItem}
        />
      </Grid>

      <Dialog open={uploadDialog.open} onClose={() => setUploadDialog({open: false})}>
        <DialogActions>
          <IconButton onClick={() => setUploadDialog({open: false})}>
            <ArrowBack />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ width: 300, padding: 10 }}>
            <Grid>
                <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={"Seleccione tipo de adjunto"}
                    value={uploadDialog.url}
                    onChange={SelectUploadFile}
                  >
                    {attachmentTypes.map((opt, indx) => (
                      <MenuItem value={opt.url} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
            </Grid>
            <Grid container justifyContent="flex-end">
            <Button
          variant="outlined"
          disableElevation
          style={{
            borderRadius: 3,
            backgroundColor: green[500],
            color: "#fff",
            fontSize: 12,
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
          onClick={() => setUploadFile(true)}
        >
          Cargar
        </Button>
            </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={uploadFile} onClose={UploadDialogHandler}>
        <DialogActions>
          <IconButton onClick={UploadDialogHandler}>
            <ArrowBack />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ position: "relative", padding: 10 }}>
          <FileUploadForm contractId={contractId} url={uploadDialog.url} />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

function AttachmentTable(props) {
  const [tableData, setTableData] = useState([]);

  const actions = (row) => {
    return (
      <Grid container direction="row" justifyContent="center">
        <IconButton onClick={() => props.handleViewFile(row.uniqueId)}>
          <Visibility />
        </IconButton>
        <IconButton onClick={() => props.handleDeleteItem(row.uniqueId)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    );
  };

  const columns = [
    { id: "attachmentType", label: "TIPO DE ADJUNTO", minWidth: 70 },
    { id: "name", label: "NOMBRE", minWidth: 150 },
    {
      id: "action",
      label: "ACCIÓN",
      minWidth: 100,
      element: actions,
      align: "center",
    },
  ];

  useEffect(() => {
    setTableData(props.data);
  }, [props.data]);

  return (
    <GeneralTable
      columns={columns}
      data={tableData}
      selected={props.selected}
      setSelected={props.setSelected}
      handleSelected={props.handleSelected}
      toolbar={
        <Grid container justifyContent="flex-end" alignItems="center">
          <TableSearch
            data={props.data}
            setTableData={setTableData}
            searchFields={["attachmentType"]}
          />
        </Grid>
      }
    />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps, {
  deleteFileAttachment,
  fetchFileAttachments,
})(FilesTable);
