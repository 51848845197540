import React from 'react';
import { 
        Dialog, DialogTitle, DialogActions, DialogContent, Grid, 
        Typography, Button, IconButton
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import DOMPurify from 'dompurify';

function DialogHeaderConfirm(props) {
    return(
        <DialogTitle className={props.dialogTypeClass}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Typography component={'span'} variant="h5">
                    {props.dialogTitle}
                </Typography>
            </Grid>
        </DialogTitle>
    );
}

function DialogFooterConfirm(props) {

    let confirm;
    !props.onClickConfirm ? confirm = props.onClickCancel : confirm = props.onClickConfirm;

    return(
        <DialogActions className="dialog-footer">
            <Button 
                variant="text"
                style={{color:'#1565C0'}}
                onClick={confirm}
            >
                {props.confirmText}
            </Button>
            {
                props.onClickConfirm ?
                <Button
                    variant="text" 
                    style={{color:'#E53935'}}
                    onClick={props.onClickCancel}
                >
                    {props.cancelText}
                </Button>
                : false
            }
        </DialogActions>
    );
}

function SimpleDialog(props) {
    return(
        <Dialog
            
            disableEscapeKeyDown
            fullWidth
            maxWidth="xs"
            open={props.open}
            onClick={props.stopPropagation}
            className="dialog-container"
        >
            <DialogHeaderConfirm
                dialogTitle={props.dialogTitle}
                dialogTypeClass={props.dialogTypeClass}
            />
            <DialogContent style={{margin:'2% 0'}}>
                {props.dialogContent}
            </DialogContent>
            <DialogFooterConfirm
                confirmText={props.dialogConfirmText}
                cancelText={props.dialogCancelText}
                onClickConfirm={props.handleConfirm}
                onClickCancel={props.handleToggle}
                dialogTypeClass={props.dialogTypeClass}
            />
        </Dialog>
    );
}

function DialogContainer(props) {
    return(
        <Dialog
            
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            className="dialog-container"
            open={props.open}
        >
            {props.content}
        </Dialog>
    )
}

function DialogHeader(props) {
    return(
        <DialogTitle className="dialog-header">
            <Grid container justifyContent="space-between" alignItems="center">
                <Typography component={'span'} variant="h5" className="dialog-title">
                    {props.dialogTitle}
                </Typography>
                <IconButton onClick={props.handleClick}>
                    <CloseOutlined className="dialog-header-close-btn" />
                </IconButton>
            </Grid>
        </DialogTitle>
    );
}

function DialogFooter(props) {
    return(
        <DialogActions className="dialog-footer" style={{ display:'flex', justifyContent: props.secondaryActions ? 'space-between' : 'flex-end' }}>
            {/* SECONDARY ACTIONS */}
            {
                props.secondaryActions
            }
            {/* PRIAMRY ACTION */}
            {
                props.primaryAction ||
                <Button
                    // variant="contained"
                    // style={{backgroundColor:'#1976D2', color: '#FFF'}}
                    variant="text"
                    style={{color: '#1E88E5'}}
                    disableElevation={true}
                    onClick={props.handleClick}
                >
                    {props.dialogFooterText}
                </Button>
            }
        </DialogActions>
    );
}

function ConfirmDialog(props) {
    return(
        <SimpleDialog
            open={props.open}
            dialogTypeClass="confirm-dialog"
            handleToggle={props.handleToggle}
            dialogTitle="Confirmación"
            dialogContent={
                <Typography variant="subtitle1">
                    {props.data}
                </Typography>
            }
            dialogConfirmText="Aceptar"
            dialogCancelText="Cancelar"
            handleConfirm={props.confirmCallback}
        />
    );
}

function SimpleConfirmDialog(props) {
    return(
        <SimpleDialog
            open={props.open}
            dialogTypeClass="confirm-dialog"
            handleToggle={props.handleToggle}
            dialogTitle="Aviso"
            dialogContent={
                <Typography variant="subtitle1">
                    {props.data}
                </Typography>
            }
            dialogConfirmText="Aceptar"
        />
    );
}

function SuccessDialog(props) {
    return(
        <SimpleDialog
            open={props.open}
            dialogTypeClass="success-confirm"
            handleToggle={props.handleToggle}
            dialogTitle="¡Éxito!"
            dialogContent={
                <Typography variant="subtitle1"
                >La operación ha sido ejecutada correctamente.</Typography>
            }
            dialogConfirmText="Aceptar"
        />
    );
}
function ErrorDialog(props) {

    const errorList = () => {
        if(props.data && props.data.length > 0) {
            return props.data.map((item, index) => {
                return(
                    <div key={index+'-'+item} style={{listStyleType: 'none', padding:'10px 0'}}>
                        <Typography variant="subtitle1" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item)}} />
                    </div>
                    )
            });
        }else {
            return(
                <Typography variant="subtitle1">
                    Ha ocurrido un problema procesando la información.
                </Typography>
            )
        }
    }
    return(
        <SimpleDialog
            open={props.open}
            dialogTypeClass="error-confirm"
            handleToggle={props.handleToggle}
            dialogTitle="¡Aviso!"
            dialogContent={errorList()}
            dialogConfirmText="Aceptar"
        />
    );
}

export function GeneralDialog(props) {
    return(
      <Dialog
          fullWidth
          maxWidth={props.maxWidth || "md"}
          onClose={
              (e, reason) => reason === 'backdropClick' && e.preventDefault()
          }
          className="dialog-container"
          open={props.open}
      >
          <DialogHeader
              dialogTitle={props.dialogTitle}
              handleClick={props.handleHeaderClick}
          />
          <DialogContent style={{margin:'2% 0'}}>
              {props.children}
          </DialogContent>
          <DialogFooter
              primaryAction={props.footerPrimaryAction}
              handleClick={props.handleFooterPrimaryClick}
              dialogFooterText={props.dialogFooterText}
              secondaryActions={props.footerSecondaryActions}
          />
      </Dialog>
    )
  }

// Incomplete, working on it.
export function GeneralSimpleDialog(props){
    // const [dialogProps, setDialogProps] = useState({});
    return(
        <SimpleDialog
            open={props.open}
            dialogTypeClass="confirm-dialog"
            handleToggle={props.handleToggle}
            dialogTitle="Confirmación"
            dialogContent={
                <Typography variant="subtitle1">
                    {props.data}
                </Typography>
            }
            dialogConfirmText="Aceptar"
            dialogCancelText="Cancelar"
            handleConfirm={props.confirmCallback}
        />
    );
}

export {
    DialogHeaderConfirm, DialogFooterConfirm, SimpleDialog,
    DialogContainer, DialogHeader, DialogFooter, ConfirmDialog,
    SuccessDialog, ErrorDialog, SimpleConfirmDialog
}