import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";

import { fetchBankAccounts } from "../../../actions";
import { fetchBankAccounts as fetch } from "../../../utils/axios";
import BankAccountForm from "../BankAccountForm";
import BankAccountSearchSection from "../BankAccountSearchSection";

const styles: any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

interface IBankAccountSelectInputProps {
  classes: { [key: string]: string };
  user: IUser;
  bankAccounts: IBankAccount[];
  selectedBankAccountsId: string[];
  bankAccountFilter?: string;
  ownerRegistryNumber?: string;
  addBankAccount(bankAccountId: string): void;
  deleteBankAccount(bankAccountId: string): void;
  fetchBankAccounts(bankAccounts: IBankAccount[]): IAction<IBankAccount[]>;
}

interface IBankAccountSelectInputState {
  bankAccounts: IBankAccount[];
  formDialog: boolean;
  loading: boolean;
  open: boolean;
}

class BankAccountSelectInput extends React.Component<
  IBankAccountSelectInputProps,
  IBankAccountSelectInputState
> {
  public state: IBankAccountSelectInputState = {
    bankAccounts: [],
    formDialog: false,
    loading: false,
    open: false,
  };
  constructor(props: IBankAccountSelectInputProps) {
    super(props);

    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.findBankAccounts = this.findBankAccounts.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
  }

  public componentDidMount() {
    this.setState({ loading: true });

    fetch({
      accountType: this.props.bankAccountFilter,
      ownerRegistryNumber: this.props.ownerRegistryNumber,
      token: this.props.user.token,
    })
      .then((res) => {
        this.props.fetchBankAccounts(
          res.data.results ? res.data.results : res.data
        );

        this.setState({
          bankAccounts: res.data.results
            ? res.data.results
            : (res.data as IBankAccount[]),
          loading: false,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public openDialogHandler() {
    this.setState({ open: true });
  }
  public closeDialogHandler() {
    this.setState({ open: false });
  }
  public openFormDialog() {
    this.setState({ formDialog: true });
  }
  public closeFormDialog() {
    this.setState({ formDialog: false });
  }
  public emptyFunction() {
    return null;
  }

  public handleItemClick(bankAccountId: string) {
    this.setState({ loading: true });

    fetch({ token: this.props.user.token })
      .then((res) => {
        this.props.fetchBankAccounts(
          res.data.results ? res.data.results : res.data
        );

        this.setState(
          {
            bankAccounts: res.data.results
              ? res.data.results
              : (res.data as IBankAccount[]),
            loading: false,
            open: false,
          },
          () => {
            this.props.addBankAccount(bankAccountId);
          }
        );
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.props.deleteBankAccount(id);
    };
  }
  public findBankAccounts(uniqueId: string) {
    const curBankAccount:
      | IBankAccount
      | undefined = this.state.bankAccounts.find(
      (bankAccount) => bankAccount.uniqueId === uniqueId
    );

    if (curBankAccount) {
      return (
        <TableRow key={curBankAccount.uniqueId}>
          <TableCell>{curBankAccount.name}</TableCell>
          <TableCell>{curBankAccount.number}</TableCell>
          <TableCell>
            <IconButton
              onClick={this.handleDeleteItem(
                curBankAccount.uniqueId ? curBankAccount.uniqueId : ""
              )}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    } else {
      return null;
    }
  }

  public fetchData(params: IFetchBankAccountParams) {
    params.limit = undefined;
    params.offset = 0;

    if (this.props.bankAccountFilter) {
      params.accountType = this.props.bankAccountFilter;
    }

    this.setState({ loading: true });
    fetch(params)
      .then((res) => {
        this.props.fetchBankAccounts(
          res.data.results ? res.data.results : (res.data as IBankAccount[])
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }

  public render() {
    const { classes } = this.props;
    const { formDialog, open, loading } = this.state;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={this.closeDialogHandler}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.openFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: 600, width: 280 }}>
            <BankAccountSearchSection
              loading={loading}
              handleClick={this.handleItemClick}
              fetchData={this.fetchData}
              pagination={false}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={formDialog}
          fullScreen={true}
          onClose={this.closeFormDialog}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <BankAccountForm changeSection={this.emptyFunction} />
          </DialogContent>
        </Dialog>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.NAME}</TableCell>
                <TableCell>{translations.ACCOUNT_NUMBER}</TableCell>
                <TableCell>
                  <IconButton onClick={this.openDialogHandler}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.selectedBankAccountsId.map((bankAccountId) =>
                this.findBankAccounts(bankAccountId)
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    bankAccounts: state.bankAccounts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchBankAccounts })(BankAccountSelectInput)
);
