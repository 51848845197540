import { PUBLIC_INSTITUTION_TYPES } from '../actions';

const initialState: IInstitution[] = []; 
 
export default function(state: IInstitution[] = initialState, action: IAction<IInstitution[]>): IInstitution[] {
    switch(action.type) {
        case PUBLIC_INSTITUTION_TYPES.FETCH_ALL:
            return action.payload || [];
        case PUBLIC_INSTITUTION_TYPES.FETCH:
            return state.map(institution =>(action.payload !== undefined && institution.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: institution);
        case PUBLIC_INSTITUTION_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case PUBLIC_INSTITUTION_TYPES.UPDATE:
            return state.map(institution => (action.payload !== undefined && institution.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : institution);
        case PUBLIC_INSTITUTION_TYPES.DELETE:
            return state.filter( institution => action.payload !== undefined && institution.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}