import { HUMAN_RESOURCES_REP_TYPES } from "../actions";

export default function(state = [], action) {
  switch (action.type) {
    case HUMAN_RESOURCES_REP_TYPES.FETCH_ALL:
      return action.payload || [];
    case HUMAN_RESOURCES_REP_TYPES.FETCH:
      return state.map((humanResources) =>
        action.payload !== undefined &&
        humanResources.uniqueId === action.payload[0].uniqueId
          ? action.payload[0]
          : humanResources
      );
    case HUMAN_RESOURCES_REP_TYPES.CREATE:
      return state.concat(action.payload || []).reverse();
    case HUMAN_RESOURCES_REP_TYPES.UPDATE:
      return state.map((humanResources) =>
        action.payload !== undefined &&
        humanResources.uniqueId === action.payload[0].uniqueId
          ? action.payload[0]
          : humanResources
      );
    case HUMAN_RESOURCES_REP_TYPES.DELETE:
      return state.filter(
        (humanResources) =>
          action.payload !== undefined &&
          humanResources.uniqueId !== action.payload[0].uniqueId
      );
    default:
      return state;
  }
}
