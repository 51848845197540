import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress, Dialog, DialogTitle, DialogContent, Typography } from '@material-ui/core';

const LoadingBar = withStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    height:8,
    marginTop:10,
  },
  colorPrimary: {
    //   backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      backgroundColor: '#7dbd7e',
  },
  bar: {
    backgroundColor: '#fff',
  }

}))(LinearProgress);

export default function LoadingDialog(props) {
  
  const { open } = props;

  return (
    <Dialog aria-labelledby="loading-dialog-title" maxWidth="xs" open={open} style={{padding:'20px'}}>
        <div style={{backgroundColor:'#399e3c'}}>
            <DialogTitle id="loading-dialog-title" style={{color:'#fff', paddingBottom:'5px'}}>Cargando</DialogTitle>
            <DialogContent style={{marginBottom:10}}>
                <Typography variant="subtitle1" style={{color:'#fff'}}>
                    Por favor espere, las solicitudes pueden tomar algo de tiempo.
                </Typography>
                <LoadingBar variant="indeterminate"/>
            </DialogContent>
        </div>
    </Dialog>
  );
}