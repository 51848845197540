import React from 'react';
import { ConfirmDialog } from './../../../components/SharedComponents/Dialogs';

function ConfirmDialogComponent(props) {
    const data = props.data.data;
    let dialogConfirmContent = '';
    switch (props.data.msg) {
        case 'proceed-delete':
            dialogConfirmContent =
                '¿Esta seguro que desea eliminar este record?';
            break;
        default:
            dialogConfirmContent = '...';
    }
    return (
        <ConfirmDialog
            open={props.open}
            handleToggle={props.handleToggle}
            confirmCallback={data.callback}
            data={dialogConfirmContent}
        />
    )
}

export { ConfirmDialogComponent }