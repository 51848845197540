import React, { useState } from 'react';
import {
    Grid, Typography, IconButton, Button
} from '@material-ui/core';
import {
    Close, Tune, ClearAll
} from '@material-ui/icons';
import {
    AutocompleteField, DateField, TextFieldComponent,
    NumericMinMax, SearchComponent
} from './../../../components/SharedComponents/Fields';
import { dateFormat } from './../../../components/SharedComponents/CustomHooks';
import { institutionClassification } from './../../../utils';

function FiltersHeader(props) {

    return (
        <Grid container justifyContent='space-between' alignItems="center">

            <Typography
                variant={props.typeVariant}
                className={props.typeClass}
                style={{fontWeight: 'bold'}}
            >
                {props.filtersTitle}
            </Typography>

            <IconButton
                className={props.btnClass}
                onClick={props.toggleFilter}
                disabled={props.disableClose}
            >
                {
                    props.openFilter ?
                    <Close
                        className={props.iconClass}
                    />
                    :
                    <Tune
                        className={props.iconClass}
                    />
                }

            </IconButton>
        </Grid>
    )
}

function Filters(props) {

    const [fieldData, setFieldData] = useState({});
    const [openFilter, setOpenFilter] = useState(true);

    const clearFilters = () => {
        setFieldData({});
    }

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    }
    const handleFieldData = (field, event, value, type) => {
        let data;
        switch(field) {
            case 'paidAmount':
                data = {...fieldData[field], [type]: event.value};
                break;
            case 'date':
                data = {...fieldData[field], [type]: event}
                break;
            case 'client':
            case 'work':
            case 'bank':
            case 'responsibles':
                data = event;
                break;
            default:
                data = value;
                break;
        }
        setFieldData({...fieldData, [field]: data});
    }

    const getFiltersOptions = (option) => {
        return(
            (option ? option : [])
        );
    }
    const applyFilters = () => {
        if (fieldData.date && (fieldData.date.start && !fieldData.date.end)) {
            props.handleToggleSimpleDialog('simple-dialog', "Debe seleccionar ambas fechas.");
            return;
        } else if (fieldData.date && (!fieldData.date.start && fieldData.date.end)) {
            props.handleToggleSimpleDialog('simple-dialog', "Debe seleccionar ambas fechas.");
            return;
        }
        const data = {
            paymentType: fieldData.payType && fieldData.payType.map(item => {return item.value}).join('|'),
            creditor: fieldData.creditors && fieldData.creditors.map(item => {return item.uniqueId}).join('|'),
            amount_payed_from: Number(fieldData.paidAmount ? fieldData.paidAmount.min : ''),
            amount_payed_to: Number(fieldData.paidAmount ? fieldData.paidAmount.max : ''),
            payment_date_from: dateFormat(fieldData.date ? fieldData.date.start : null),
            payment_date_to: dateFormat(fieldData.date ? fieldData.date.end : null),
            clients: fieldData.client && fieldData.client.fullName,
            clientWorkInstitution: fieldData.work && fieldData.work?.value,
            creditorBankAccounts: fieldData.bank && fieldData.bank?.accountNumber,
            work_class_id: fieldData.financialClass ? fieldData.financialClass.map(item => item.value).join('|') : null,
            responsible_analyst: fieldData.responsibles ? fieldData.responsibles.fullName : null,
        };
        Object.keys(data).forEach(key => {
            if(!data[key]) {
                delete data[key];
            }
        });
        props.applyFilters(data);
    }
    return(
        <Grid container className="filters-container">
            <FiltersHeader
                typeVariant="subtitle1"
                typeClass="section-header"
                filtersTitle="Filtros"
                btnClass={("filter-btn-close ") + props.hideClass}
                iconClass="filter-icon-close"
                toggleFilter={toggleFilter}
                openFilter={openFilter}
                disableClose={props.disableCloseBtn}
            />
            <Grid container className={openFilter ? "filter-content" : "filter-content collided"}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Tipo de pago"
                            fieldID="filter-field-pay-mode"
                            fieldInnerLabel="Seleccionar tipo(s) de pago(s)"
                            fieldVariant="outlined"
                            value={fieldData.payType || []}
                            handleChange={(event, value) => { handleFieldData('payType', event, value) }}
                            options={getFiltersOptions(props.listData.payTypes)}
                            getOptionLabel={(option) => option.name}
                            multiple={true}
                            limitTags={1}
                        />
                        <NumericMinMax
                            xs={4}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Monto pagado"
                            fieldID="filter-field-paid-amount"
                            fieldClass="fields"
                            fieldVariant="outlined"
                            margin='dense'
                            fullWidth
                            prefix="$"
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            valueMin={fieldData.paidAmount ? fieldData.paidAmount.min : ''}
                            valueMax={fieldData.paidAmount ? fieldData.paidAmount.max : ''}
                            onChangeMin={(event, value) => { handleFieldData('paidAmount', event, value, 'min') }}
                            onChangeMax={(event, value) => { handleFieldData('paidAmount', event, value, 'max') }}
                        />
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Acreedores"
                            fieldID="filter-field-creditors"
                            fieldInnerLabel="Seleccionar acreedor(es)"
                            fieldVariant="outlined"
                            value={fieldData.creditors || []}
                            handleChange={(event, value) => { handleFieldData('creditors', event, value) }}
                            options={getFiltersOptions(props.listData.creditors)}
                            getOptionLabel={(option) => option.name}
                            multiple={true}
                            limitTags={1}
                        />
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <DateField
                            xs={4}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Fecha inicio"
                            dateFieldID="filter-date-start"
                            fullWidth={true}
                            dateFieldClass="date-field"
                            inputVariant="outlined"
                            dateFieldLabel="Fecha inicio de transacción"
                            format="dd/MM/yyyy"
                            value={(fieldData.date && fieldData.date.start) ? fieldData.date.start : null}
                            dateType="start"
                            handleChange={(event, value) => { handleFieldData('date', event, value, 'start') }}
                        />
                        <DateField
                            xs={4}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Fecha corte"
                            dateFieldID="filter-date-end"
                            fullWidth={true}
                            dateFieldClass="date-field"
                            inputVariant="outlined"
                            dateFieldLabel="Fecha corte de transacción"
                            format="dd/MM/yyyy"
                            value={(fieldData.date && fieldData.date.end) ? fieldData.date.end  : null}
                            dateType="end"
                            handleChange={(event, value) => { handleFieldData('date', event, value, 'end') }}
                        />
                        <SearchComponent
                            xs={4}
                            containerClass="field-container"
                            fieldLabel="Participantes"
                            fieldID="expiry-client-filter"
                            fieldInnerLabel="Seleccione un participante"
                            handleChange={(event, value) => { handleFieldData('client', event, value) }}
                            onInputChange={() => {
                                // gg
                            }}
                            optionType={'participants'}
                            clearOnBlur={false}
                            limitTags={1}
                        />
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <SearchComponent 
                            xs={4}
                            containerClass="field-container"
                            fieldLabel="Trabajo"
                            fieldID="filter-field-work"
                            fieldInnerLabel="Lugar de trabajo"
                            handleChange={(event, value) => {handleFieldData('work', event, value) }}
                            onInputChange={() => {
                            }}
                            optionType={'institutions'}
                            clearOnBlur={false}
                            limitTags={1}
                        />
                        <SearchComponent 
                            xs={4}
                            containerClass="field-container"
                            fieldLabel="Cuenta acreedor"
                            fieldID="autocomplete-field-org"
                            fieldInnerLabel="Seleccione una cuenta"
                            handleChange={(event, value) => { handleFieldData('bank', event, value) }}
                            onInputChange={() => {}}
                            optionType={'creditorAccounts'}
                            clearOnBlur={false}
                            limitTags={1}
                        />
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Clasificación de institución"
                            fieldID="filter-field-financial-classification"
                            fieldInnerLabel="Seleccione..."
                            fieldVariant="outlined"
                            value={fieldData.financialClass || []}
                            handleChange={(event, value) => { handleFieldData('financialClass', event, value) }}
                            options={institutionClassification}
                            getOptionLabel={(option) => option.label}
                            multiple={true}
                            limitTags={2}
                        />
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <SearchComponent 
                            xs={4}
                            containerClass="field-container"
                            fieldLabel="Gestor de cobro"
                            fieldID="autocomplete-field-responsibles"
                            fieldInnerLabel="Seleccione un gestor"
                            handleChange={(event, value) => { handleFieldData('responsibles', event, value) }}
                            onInputChange={() => {}}
                            optionType={'responsibleManagement'}
                            clearOnBlur={false}
                            limitTags={1}
                        />
                    </Grid>
                </Grid>
                {/* FILTERS CONTENT APPLY BUTTON */}
                <Grid container direction="row" justifyContent='space-between' style={{marginTop:'35px'}}>

                    <Button
                        disableElevation
                        className="filter-clear-btn"
                        onClick={clearFilters.bind(this, false)}
                    >
                        limpiar
                        <ClearAll style={{marginLeft:8, fontSize: '19px'}}/>
                    </Button>

                    <Button
                        disableElevation
                        className="filter-apply-btn"
                        onClick={applyFilters}
                    >
                        aplicar filtros
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    );
}
export { Filters }