
import { DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES } from '../actions';

export default function (state: IDebitCardOrPassbookAccountContract[] = [], action: IAction<IDebitCardOrPassbookAccountContract[]>): IDebitCardOrPassbookAccountContract[] {
    switch (action.type) {
        case DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES.FETCH_ALL:
            return action.payload || [];
        case DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES.FETCH:
            return state.map(debitCardOrPassbookAccountContract => (action.payload !== undefined && debitCardOrPassbookAccountContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : debitCardOrPassbookAccountContract);
        case DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES.CREATE:
            return state.concat(action.payload || []).reverse();
        case DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES.UPDATE:
            return state.map(debitCardOrPassbookAccountContract => (action.payload !== undefined && debitCardOrPassbookAccountContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : debitCardOrPassbookAccountContract);
        case DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES.DELETE:
            return state.filter(debitCardOrPassbookAccountContract => action.payload !== undefined && debitCardOrPassbookAccountContract.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}