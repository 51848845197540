import React, {useState, useEffect} from 'react';
import {
    Grid, Typography, IconButton, Button
} from '@material-ui/core';
import {
    Close, Tune, ClearAll, Refresh
} from '@material-ui/icons';
import { AutocompleteField, DateField, CheckboxField, SearchComponent } from './../../../components/SharedComponents/Fields';
import { dateFormat, errorHandling } from './../../../components/SharedComponents/CustomHooks';
import {
    fetchResponsibleAnalysts, fetchInstitutions, fetchSpecificOrders,
    fetchParticipants
} from './../../../utils/axios';
import { institutionClassification } from './../../../utils';
import { ExpiryStates } from '../../../components/SharedComponents/DataListTypes';
import axios from 'axios';

function FiltersHeader(props) {

    return (
        <Grid container justifyContent='space-between' alignItems="center">

            <Typography
                variant={props.typeVariant}
                className={props.typeClass}
                style={{fontWeight: 'bold'}}
            >
                {props.filtersTitle}
            </Typography>

            <IconButton
                className={props.btnClass}
                onClick={props.toggleFilter}
                disabled={props.disableClose}
            >
                {
                    props.openFilter ?
                    <Close
                        className={props.iconClass}
                    />
                    :
                    <Tune
                        className={props.iconClass}
                    />
                }

            </IconButton>
        </Grid>
    )
}

function Filters(props) {

    const [fieldData, setFieldData] = useState({});
    const [openFilter, setOpenFilter] = useState(true);
    const [isGroup, setGroupChange] = useState(false);
    const [filterData, setFilterData] = useState({});

    const getFilters = () => {
        props.setLoading(true);
        const getResponsibles = fetchResponsibleAnalysts(props.token);
        const getPrivateWorks = fetchInstitutions({token: props.token, institutionType: 'private'});
        const getPublicWorks = fetchInstitutions({token: props.token, institutionType: 'public'});
        const getBanks = fetchInstitutions({token: props.token, institutionType: 'financial'});
        const customOrder = (props.hideFields && !props.hideFields.custom) && fetchSpecificOrders(props.token);
        const getExpiryStates = ExpiryStates().filter(item => ['Late', 'Programmed', 'Partially paid'].includes(item.value));

        const endpoints = [getResponsibles, getPrivateWorks, getPublicWorks, getBanks, customOrder]

        axios.all(endpoints).then(axios.spread((...responses) => {
            const data = {
                responsibles: responses[0].data,
                works: (responses[1] && responses[2] && responses[3]) && (responses[1].data.concat(responses[2].data, responses[3].data)).map(item => {
                    return {
                        abbreviation: item.abbreviation,
                        name: item.name,
                        fullName: item.abbreviation + ": " + item.name,
                        uniqueId: item.uniqueId
                    }
                }), //this is to select some properties of the object
                banks: responses[3] && (responses[3].data).map(item => {
                    return {
                        abbreviation: item.abbreviation,
                        name: item.name,
                        fullName: item.abbreviation + ": " + item.name,
                        uniqueId: item.uniqueId
                    }
                }), //this is to select some properties of the object.
                states: getExpiryStates,
                customOrder: responses[4] && responses[4].data,
            };
            setFilterData({...data});
            props.setLoading(false);
        })).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        })
    }

    const getSpecificOrders = () => {
        props.setLoading(true);
        const endpoint = fetchSpecificOrders(props.token);
        endpoint.then(response => {
            setFilterData({...filterData, customOrder: response.data});
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }

    const clearFilters = () => {
        setFieldData({});
    }

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    }
    const handleGroupChange = () => {
        setGroupChange(!isGroup);
    }
    const handleFieldData = (field, event, value, type) => {
        let data;
        switch(field) {
            case 'date':
                data = {...fieldData[field], [type]: event}
                break;
            case 'client':
            case 'bank':
            case 'responsible':
            case 'work':
                data = event;
                break;
            default:
                data = value;
                break;
        }
        setFieldData({...fieldData, [field]: data});
    }

    const getFiltersOptions = (option) => {
        return(
            (option ? option : [])
        );
    }
    const applyFilters = () => {
        const fields = {
            order: fieldData.customOrder,
            group: isGroup,
            responsible_analyst: fieldData.responsible && fieldData.responsible.fullName,
            work_institution: fieldData.work && fieldData.work.name,
            finantial_institution: fieldData.bank && fieldData.bank.name,
            client: fieldData.client && fieldData.client.fullName,
            expiration_status: fieldData.states && fieldData.states.map(item => item.value).join('|'),
            expiration_date_from: dateFormat(fieldData.date ? fieldData.date.start : null),
            expiration_date_to: dateFormat(fieldData.date ? fieldData.date.end : null),
            work_class_id: fieldData.financialClass ? fieldData.financialClass.map(item => item.value).join('|') : null,
        };
        Object.keys(fields).forEach(key => {
            if(!fields[key] || fields[key].length === 0) {
                delete fields[key];
            }
        });
        props.applyFilters(fields);
    }

    useEffect(() => {
        getFilters();
    }, []);

    return(
        <Grid container className="filters-container">
            <FiltersHeader
                typeVariant="subtitle1"
                typeClass="section-header"
                filtersTitle="Filtros"
                btnClass={("filter-btn-close ") + props.hideClass}
                iconClass="filter-icon-close"
                toggleFilter={toggleFilter}
                openFilter={openFilter}
                disableClose={props.disableCloseBtn}
            />
            <Grid container className={openFilter ? "filter-content" : "filter-content collided"}>
                <Grid item xs={12}>
                    {
                        !props.hideFields.custom &&
                        <Grid container direction="row">
                            <CheckboxField
                                xs={12}
                                check={isGroup}
                                handleChange={handleGroupChange}
                                color="primary"
                                textColor="#43A047"
                                label="AGRUPAR VISTA"
                            />
                        </Grid>
                    }
                    <Grid container direction="row" alignItems="center" spacing={2}>
                        {
                            !props.hideFields.custom &&
                            <>
                                <AutocompleteField
                                    xs={6}
                                    containerClass="field-container"
                                    fieldClass="fields"
                                    typeVariant="subtitle1"
                                    typeClass="field-label"
                                    fieldLabel="Perzonalización"
                                    fieldID="filter-field-custom-order"
                                    fieldInnerLabel="Orden personalizado"
                                    fieldVariant="outlined"
                                    value={fieldData.customOrder || []}
                                    handleChange={(event, value) => { handleFieldData('customOrder', event, value) }}
                                    options={getFiltersOptions(filterData.customOrder)}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                                    multiple={false}
                                />
                                <Button
                                    onClick={getSpecificOrders}
                                    variant="contained"
                                    className="action-method-btn"
                                    disableElevation
                                    style={{marginTop:35}}
                                >
                                    <Refresh style={{fontSize:20, color:'#fff'}} />
                                </Button>
                            </>
                        }
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                        {
                            !props.hideFields.responsible &&
                                // <AutocompleteField
                                //     xs={4}
                                //     containerClass="field-container"
                                //     fieldClass="fields"
                                //     typeVariant="subtitle1"
                                //     typeClass="field-label"
                                //     fieldLabel="Responsable"
                                //     fieldID="filter-field-responsible"
                                //     fieldInnerLabel="Analista Responsable"
                                //     fieldVariant="outlined"
                                //     value={fieldData.responsible || []}
                                //     handleChange={(event, value) => { handleFieldData('responsible', event, value) }}
                                //     options={getFiltersOptions(filterData.responsibles)}
                                //     getOptionLabel={(option) => option.fullName}
                                //     multiple={true}
                                //     limitTags={2}
                                // />
                                <SearchComponent 
                                    xs={4}
                                    containerClass="field-container"
                                    fieldLabel="Responsable"
                                    fieldID="autocomplete-field-responsible"
                                    fieldInnerLabel="Analista Responsable"
                                    handleChange={(event, value) => { handleFieldData('responsible', event, value) }}
                                    onInputChange={() => {}}
                                    optionType={'responsibleManagement'}
                                    clearOnBlur={false}
                                    limitTags={1}
                                />
                        }
                        {
                            !props.hideFields.work &&
                                // <AutocompleteField
                                //     xs={4}
                                //     containerClass="field-container"
                                //     fieldClass="fields"
                                //     typeVariant="subtitle1"
                                //     typeClass="field-label"
                                //     fieldLabel="Trabajo"
                                //     fieldID="filter-field-work"
                                //     fieldInnerLabel="Lugar de Trabajo"
                                //     fieldVariant="outlined"
                                //     value={fieldData.work || []}
                                //     handleChange={(event, value) => { handleFieldData('work', event, value) }}
                                //     options={getFiltersOptions(filterData.works)}
                                //     getOptionLabel={(option) => option.fullName}
                                //     multiple={true}
                                //     limitTags={3}
                                // />
                                <SearchComponent 
                                    xs={4}
                                    containerClass="field-container"
                                    fieldLabel="Trabajo"
                                    fieldID="filter-field-work"
                                    fieldInnerLabel="Lugar de trabajo"
                                    handleChange={(event, value) => {handleFieldData('work', event, value) }}
                                    onInputChange={() => {}}
                                    optionType={'institutions'}
                                    clearOnBlur={false}
                                    limitTags={1}
                                />
                        }
                        {
                            !props.hideFields.bank &&
                                // <AutocompleteField
                                //     xs={4}
                                //     containerClass="field-container"
                                //     fieldClass="fields"
                                //     typeVariant="subtitle1"
                                //     typeClass="field-label"
                                //     fieldLabel="Banco"
                                //     fieldID="filter-field-org"
                                //     fieldInnerLabel="Institución financiera"
                                //     fieldVariant="outlined"
                                //     value={fieldData.bank || []}
                                //     handleChange={(event, value) => { handleFieldData('bank', event, value) }}
                                //     options={getFiltersOptions(filterData.banks)}
                                //     getOptionLabel={(option) => option.fullName}
                                //     multiple={true}
                                //     limitTags={3}
                                // />
                                <SearchComponent 
                                    xs={4}
                                    containerClass="field-container"
                                    fieldLabel="Banco"
                                    fieldID="filter-field-org"
                                    fieldInnerLabel="Institución financiera"
                                    handleChange={(event, value) => { handleFieldData('bank', event, value) }}
                                    onInputChange={() => {}}
                                    optionType={'banks'}
                                    clearOnBlur={false}
                                    limitTags={1}
                                />
                        }

                    </Grid>

                    <Grid container direction="row" spacing={2}>
                        {
                            !props.hideFields.status &&
                                <AutocompleteField
                                    xs={4}
                                    containerClass="field-container"
                                    fieldClass="fields"
                                    typeVariant="subtitle1"
                                    typeClass="field-label"
                                    fieldLabel="Estados"
                                    fieldID="filter-field-status"
                                    fieldInnerLabel="Estados de vencimiento"
                                    fieldVariant="outlined"
                                    value={fieldData.states || []}
                                    handleChange={(event, value) => { handleFieldData('states', event, value) }}
                                    options={getFiltersOptions(filterData.states)}
                                    getOptionLabel={(option) => option.name}
                                    multiple={true}
                                    limitTags={1}
                                />
                        }
                        {
                            !props.hideFields.startDate &&
                                <DateField
                                    xs={4}
                                    typeVariant="subtitle1"
                                    typeClass="field-label"
                                    fieldLabel="Fecha inicio"
                                    dateFieldID="filter-date-start"
                                    fullWidth={true}
                                    dateFieldClass="date-field"
                                    inputVariant="outlined"
                                    dateFieldLabel="Fecha inicio de transacción"
                                    format="dd/MM/yyyy"
                                    value={(fieldData.date && fieldData.date.start) ? fieldData.date.start : null}
                                    handleChange={(event, value) => { handleFieldData('date', event, value, 'start') }}
                                />
                        }
                        {
                            !props.hideFields.endDate &&
                                <DateField
                                    xs={4}
                                    typeVariant="subtitle1"
                                    typeClass="field-label"
                                    fieldLabel="Fecha corte"
                                    dateFieldID="filter-date-end"
                                    fullWidth={true}
                                    dateFieldClass="date-field"
                                    inputVariant="outlined"
                                    dateFieldLabel="Fecha corte de transacción"
                                    format="dd/MM/yyyy"
                                    value={(fieldData.date && fieldData.date.end) ? fieldData.date.end : null}
                                    handleChange={(event, value) => { handleFieldData('date', event, value, 'end') }}
                                    hide={props.hideFields.endDate}
                                />
                        }
                        {
                            !props.hideFields.client &&
                            <SearchComponent
                                xs={4}
                                containerClass="field-container"
                                fieldLabel="Participantes"
                                fieldID="reassign-client-filter"
                                fieldInnerLabel="Seleccione un participante"
                                handleChange={(event, value) => { handleFieldData('client', event, value) }}
                                optionType={'participants'}
                                clearOnBlur={false}
                                limitTags={1}
                            />
                        }
                        {
                            !props.hideFields.financialClass &&
                                <AutocompleteField
                                    xs={4}
                                    containerClass="field-container"
                                    fieldClass="fields"
                                    typeVariant="subtitle1"
                                    typeClass="field-label"
                                    fieldLabel="Clasificación de institución"
                                    fieldID="filter-field-financial-classification"
                                    fieldInnerLabel="Seleccione..."
                                    fieldVariant="outlined"
                                    value={fieldData.financialClass || []}
                                    handleChange={(event, value) => { handleFieldData('financialClass', event, value) }}
                                    options={institutionClassification}
                                    getOptionLabel={(option) => option.label}
                                    multiple={true}
                                    limitTags={2}
                                />
                        }
                    </Grid>

                </Grid>
                {/* FILTERS CONTENT APPLY BUTTON */}
                <Grid container direction="row" justifyContent='space-between' style={{marginTop:'35px'}}>

                    <Button
                        disableElevation
                        className="filter-clear-btn"
                        onClick={clearFilters.bind(this, false)}
                    >
                        limpiar
                        <ClearAll style={{marginLeft:8, fontSize: '19px'}}/>
                    </Button>

                    <Button
                        disableElevation
                        className="filter-apply-btn"
                        onClick={applyFilters}
                    >
                        aplicar filtros
                        {/* <DoneOutline style={{marginLeft:8, fontSize: '17px'}}/> */}
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    );
}
function ViewFilters(props) {

    const [openFilter, setOpenFilter] = useState(true);
    const [filterData, setFilterData] = useState({});

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    }

    const clearFilters = () => {
        setFilterData({});
    }

    const handleFilterDataChange = (field, event, value) => {
        let data;
        switch(field) {
            case 'institutions':
                data = value;
                break;
            default:
                break;
        }
        setFilterData({...filterData, [field]: data});
    }

    const getFiltersOptions = (option) => {
        return(
            (option ? option : [])
        );
    }
    const applyFilters = () => {
        const fields = {
            financial_classification: filterData.institutions.map(item => item.value).join('|'),
        };
        Object.keys(fields).forEach(key => {
            if(!fields[key] || fields[key].length === 0) {
                delete fields[key];
            }
        });
        props.applyFilters(fields);
    }
    return(
        <Grid container className="filters-container">
            <FiltersHeader
                typeVariant="subtitle1"
                typeClass="section-header"
                filtersTitle="Filtros"
                btnClass={("filter-btn-close ") + props.hideClass}
                iconClass="filter-icon-close"
                toggleFilter={toggleFilter}
                openFilter={openFilter}
                disableClose={props.disableCloseBtn}
            />
            <Grid container className={openFilter ? "filter-content" : "filter-content collided"}>
                <Grid item xs={12}>
                    <AutocompleteField
                        style={{width:350}}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Clasificación financiera"
                        fieldID="filter-field-financial-classification"
                        fieldInnerLabel="Seleccione..."
                        fieldVariant="outlined"
                        value={filterData.institutions || []}
                        handleChange={handleFilterDataChange.bind(this, 'institutions')}
                        options={getFiltersOptions(institutionClassification)}
                        getOptionLabel={(option) => option.label}
                        multiple={true}
                        limitTags={2}
                    />
                </Grid>
                <Grid container direction="row" justifyContent='space-between' style={{marginTop:'35px'}}>
                    <Button
                        disableElevation
                        className="filter-clear-btn"
                        onClick={clearFilters.bind(this, false)}
                    >
                        limpiar
                        <ClearAll style={{marginLeft:8, fontSize: '19px'}}/>
                    </Button>

                    <Button
                        disableElevation
                        className="filter-apply-btn"
                        onClick={applyFilters}
                    >
                        aplicar filtros
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
export { Filters, ViewFilters }