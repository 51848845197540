import React, { useState } from "react";
import { Grid, Button, Typography, IconButton } from "@material-ui/core";
import {
  DateField,
  SelectField,
  TextFieldComponent,
} from "../../../components/SharedComponents/Fields";
import { green } from "@material-ui/core/colors";
import { Close, Tune } from "@material-ui/icons";

function FiltersHeader(props) {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Typography
        variant={props.typeVariant}
        className={props.typeClass}
        style={{ fontWeight: "bold" }}
      >
        {props.filtersTitle}
      </Typography>
      <IconButton
        className={props.btnClass}
        onClick={props.toggleFilter}
        disabled={props.disableClose}
      >
        {props.openFilter ? (
          <Tune className={props.iconClass} />
        ) : (
          <Close className={props.iconClass} />
        )}
      </IconButton>
    </Grid>
  );
}

function RegularLoansTemplate2(props) {
  const [date, setDate] = useState({ start: null, end: null });

  const [openFilter, setOpenFilter] = useState(false);

  const handleDateChange = (type, dateInfo, value) => {
    setDate({ ...date, [type]: dateInfo });
    props.setDate({ ...date, [type]: dateInfo });
  };

  const switchState = (key) => {
    setOpenFilter(!openFilter);
  };

  return (
    <Grid container className="filters-container">
      <FiltersHeader
        typeVariant="subtitle1"
        typeClass="section-header"
        filtersTitle="FILTROS"
        iconClass="filter-icon-close"
        toggleFilter={switchState}
        openFilter={openFilter}
        disableClose={props.disableCloseBtn}
        btnClass={`filter-btn-close`}
      />
      <Grid
        container
        className={openFilter ? "filter-content collided" : "filter-content"}
      >
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2}>
            <DateField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Fecha inicio"
              dateFieldID="filter-date-start"
              fullWidth={true}
              dateFieldClass="date-field"
              inputVariant="outlined"
              dateFieldLabel="Fecha inicio de transacción"
              format="dd/MM/yyyy"
              value={date.start}
              handleChange={handleDateChange.bind(this, "start")}
            />
            <DateField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Fecha corte"
              dateFieldID="filter-date-end"
              fullWidth={true}
              dateFieldClass="date-field"
              inputVariant="outlined"
              dateFieldLabel="Fecha corte de transacción"
              format="dd/MM/yyyy"
              value={date.end}
              handleChange={handleDateChange.bind(this, "end")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export { RegularLoansTemplate2 };
