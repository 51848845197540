import { DEPOSIT_BANK_ACCOUNT_TYPES } from '../actions';

const initialState: IDepositBankAccount[] = []; 
 
export default function(state: IDepositBankAccount[] = initialState, action: IAction<IDepositBankAccount[]>): IDepositBankAccount[] {
    switch(action.type) {
        case DEPOSIT_BANK_ACCOUNT_TYPES.FETCH_ALL:
            return action.payload || [];
        case DEPOSIT_BANK_ACCOUNT_TYPES.FETCH:
            return state.map(depositBankAccount =>(action.payload !== undefined && depositBankAccount.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: depositBankAccount);
        case DEPOSIT_BANK_ACCOUNT_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case DEPOSIT_BANK_ACCOUNT_TYPES.UPDATE:
            return state.map(depositBankAccount => (action.payload !== undefined && depositBankAccount.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : depositBankAccount);
        case DEPOSIT_BANK_ACCOUNT_TYPES.DELETE:
            return state.filter( depositBankAccount => action.payload !== undefined && depositBankAccount.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}

// need to fix FETCH AND update