import * as React from "react";
import { connect } from "react-redux";
import ReactSelect from "react-select";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";

import AlertDialog from "../../components/AlertDialog";
import Loading from "../../components/Loading";
import moneyInput from "../../components/TextMaskInputComponents/moneyInput";
import {
  catchError,
  economicActivity,
  institutionState,
  paymentTypesLeads,
  resetError,
  rohiDBList,
  secondaryIncomeMonthlyActivity,
  sessionTypes,
  successHandler,
  translations,
  workTypes,
} from "../../utils";
import {
  createWorkProfile,
  deleteWorkProfile,
  fetchPositions,
  fetchDepartments,
  fetchWorkProfileByOwner,
  submitRohiParticipant,
  updateWorkProfile,
  fetchInstitution,
} from "../../utils/axios";
import InstitutionsSelectInput from "../Institutions/InstitutionsSelectInput";
import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
} from "@material-ui/core";
import {
  AutocompleteField,
  SearchComponent,
} from "src/components/SharedComponents/Fields";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  checkboxLabelReactSelect: {
    color: green[500],
    fontSize: "small",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginLeft: 5,
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  paper: {
    padding: 15,
  },
  root: {
    padding: "10px 0px",
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

class WorkProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      department: { label: "", value: "" },
      departmentError: "",
      departmentsOptions: [],
      position: { label: "", value: "" },
      positionError: "",
      positionsOptions: [],
      dialog: false,
      dialogTitle: "",
      departureDate: "",
      departureDateError: "",
      employmentLength: "",
      employmentLengthError: "",
      entryDate: "",
      entryDateError: "",
      errorMessage: "",
      institution: [],
      institutionData: {},
      institutionError: "",
      isMounted: false,
      laboralCode: "",
      laboralCodeError: "",
      laboralStatus: "active",
      laboralStatusError: "",
      loading: false,
      profileExist: false,
      reentryDate: "",
      reentryDateError: "",
      salary: 0,
      salaryError: "",
      secondaryIncome: "",
      secondaryIncomeError: "",
      secondaryIncomeActivity: "",
      secondaryIncomeActivityError: "",
      uniqueId: "",
      paymentType: "",
      sessionType: "",
      workType: "",
    };

    this.addInstitution = this.addInstitution.bind(this);
    this.deleteInstitution = this.deleteInstitution.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toOptions = this.toOptions.bind(this);
    this.getInstitutionInfo = this.getInstitutionInfo.bind(this);
    this.getState = this.getState.bind(this);
  }
  addInstitution(id) {
    this.setState({ institution: [id] });
  }
  deleteInstitution(id) {
    this.setState({ institution: [""] });
  }
  toOptions(collection) {
    return collection.map((item) => ({
      label: item.name,
      value: item.uniqueId,
    }));
  }
  handleReactSelectChange(key) {
    return (option, value) => {
      const val = option.target.value ? option.target.value : value;
      this.setState({ [key]: val });
    };
  }
  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  componentWillUnmount() {
    this.setState({ isMounted: false });
  }
  componentDidMount() {
    const {
      ownerId,
      user: { token },
    } = this.props;
    this.setState({ loading: true, isMounted: true });
    if (this.props.newClient) {
      this.setState(this.props.newClient);
    }

    if (ownerId && ownerId !== "") {
      Promise.all([
        fetchPositions({ token: token }),
        fetchDepartments(token),
        fetchWorkProfileByOwner(token, ownerId),
      ])
        .then((res) => {
          this.setState(
            {
              positionsOptions: this.toOptions(res[0].data),
              departmentsOptions: this.toOptions(res[1].data),
            },
            () => {
              if (
                res[2].data &&
                res[2].data.length !== 0 &&
                this.state.isMounted
              ) {
                const curProfile = res[2].data[0];

                this.setState((prevState) => ({
                  department:
                    prevState.departmentsOptions.find(
                      (d) => d.value === curProfile.department
                    ) || prevState.departmentsOptions[0],
                  employmentLength: curProfile.employmentLength
                    ? (curProfile.employmentLength / 12).toString()
                    : "",
                  entryDate: curProfile.entryDate || "",
                  departureDate: curProfile.departureDate || "",
                  institution: [curProfile.institution || ""],
                  laboralCode: curProfile.laboralCode || "",
                  laboralStatus: curProfile.laboralStatus || "",
                  loading: false,
                  position:
                    prevState.positionsOptions.find(
                      (a) => a.value === curProfile.position
                    ) || prevState.positionsOptions[0],
                  profileExist: true,
                  reentryDate: curProfile.reentryDate || "",
                  salary: curProfile.salary || 0,
                  secondaryIncome: curProfile.secondaryIncome,
                  secondaryIncomeActivity:
                    curProfile.secondaryIncomeActivity || "",
                  uniqueId: curProfile.uniqueId || "",
                  paymentType: curProfile.paymentType || "",
                  sessionType: curProfile.sessionType || "",
                  workType: curProfile.workType || "",
                }));
              } else if (this.state.isMounted) {
                this.setState((prevState) => ({
                  position: prevState.positionsOptions[0],
                  department: prevState.departmentsOptions[0],
                  loading: false,
                  profileExist: false,
                }));
              }
            }
          );
        })
        .catch((err) => {
          if (this.state.isMounted) {
            catchError(err, this);
          }
        });
    }
  }
  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  handleSelectChange(key) {
    return (event) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  calculateDatesHandler(entryDate, reentryDate, departureDate) {
    const day = 1000 * 60 * 60 * 24;
    let desde = new Date();
    let hasta = new Date();
    let diff = 0;
    let result = 0;
    if (reentryDate) {
      desde = new Date(reentryDate.substr(0, 10));
    } else {
      desde = new Date(entryDate.substr(0, 10));
    }
    if (departureDate) {
      hasta = new Date(departureDate.substr(0, 10));
    }

    diff = hasta.getTime() - desde.getTime();
    result = diff / day / 30;
    return result;
  }
  handleReset() {
    this.setState({
      departmentError: "",
      employmentLength: "",
      employmentLengthError: "",
      entryDate: "",
      entryDateError: "",
      departureDate: "",
      departureDateError: "",
      institution: [],
      institutionData: {},
      institutionError: "",
      laboralCode: "",
      laboralCodeError: "",
      laboralStatus: "active",
      laboralStatusError: "",
      position: "",
      positionError: "",
      profileExist: false,
      reentryDate: "",
      reentryDateError: "",
      salary: 0,
      salaryError: "",
      secondaryIncome: "",
      secondaryIncomeActivity: "",
      secondaryIncomeActivityError: "",
      uniqueId: "",
    });
  }

  handleSubmit(event) {
    resetError(this);

    let profile = {
      department: this.state.department.value,
      employmentLength: parseInt(this.state.employmentLength, 10) * 12,
      entryDate: this.state.entryDate,
      departureDate: this.state.departureDate,
      institution: this.state.institutionData?.value,
      laboralCode: this.state.laboralCode,
      laboralStatus: this.state.laboralStatus,
      position: this.state.position.value,
      reentryDate: this.state.reentryDate,
      salary: this.state.salary,
      secondaryIncome: this.state.secondaryIncome,
      secondaryIncomeActivity: this.state.secondaryIncomeActivity,
      paymentType: this.state.paymentType,
      sessionType: this.state.sessionType,
      workType: this.state.workType,
    };
    this.setState({ loading: true });

    const { reentryDate, departureDate } = this.state;

    if (reentryDate.trim() === "") {
      profile = Object.assign(profile, { reentryDate: null });
    }
    if (departureDate.trim() === "") {
      profile = Object.assign(profile, { departureDate: null });
    }

    Object.keys(profile).forEach(key => {
      if(!profile[key] || profile[key].length < 1) {
          delete profile[key];
      }
    });

    if (this.state.profileExist) {
      updateWorkProfile(this.props.user.token, this.state.uniqueId, profile)
        .then((res) => {
          successHandler(res, this, translations.WORK_PROFILE);
        })
        .catch((err) => {
          catchError(err, this);
        });
    } else {
      profile.owner = this.props.ownerId;
      createWorkProfile(this.props.user.token, profile)
        .then((res) => {
          if (this.props.handleSaveCallBack) {
            this.props.handleSaveCallBack(res.data.uniqueId);
          }
          successHandler(res, this, translations.WORK_PROFILE);
          if (this.props.newClient) {
            this.props.handleSaveCallBack(res.data.uniqueId);
          }
          this.handleDelete();
          this.setState({
            profileExist: true,
            uniqueId: res.data.uniqueId || "",
          });
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE) && this.state.profileExist) {
      this.setState({ loading: true });

      deleteWorkProfile(this.props.user.token, this.state.uniqueId)
        .then((res) => {
          // check secret questions
          this.handleReset();
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  handleDialogClose() {
    this.setState({ dialog: false });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.laboralStatus !== this.state.laboralStatus) {
      if (this.state.laboralStatus === "retired") {
        this.setState({ reentryDate: "" });
      } else {
        this.setState({ departureDate: "" });
      }
    }
    if (
      prevState.entryDate !== this.state.entryDate ||
      prevState.reentryDate !== this.state.reentryDate ||
      prevState.departureDate !== this.state.departureDate
    ) {
      const calc = this.calculateDatesHandler(
        this.state.entryDate,
        this.state.reentryDate,
        this.state.departureDate
      );
      if (calc < 1) {
        this.setState((state) => {
          state.employmentLength = Math.ceil(calc / 12);
          return state;
        });
      } else {
        this.setState((state) => {
          state.employmentLength = Math.floor(calc / 12);
          return state;
        });
      }
    }
    
    const institution = this.state.institution;
    const institutionData = this.state.institutionData;
    const {
      user: { token },
    } = this.props;
    
    if (institution.length > 0 && Object.keys(institutionData).length === 0) {
      this.getInstitutionInfo(token, institution[0]);
    }
  }

  getInstitutionInfo = async (token, institucion) => {
    const res = await fetchInstitution(token, institucion);
    this.setState({ institutionData : res.data })
  }

  getState = (state) => {
    let st = state ? institutionState.find((inst) => inst.value === state) : "";
    st = st.label ? st.label : state;
    return st
  }

  render() {
    const { classes } = this.props;
    const { rohiDialog, departmentsOptions, positionsOptions, institutionData } = this.state;
    const savedValue = Object.keys(institutionData).length > 0 ? institutionData : null

    return (
      <div className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">
            {!this.state.profileExist && "Crear"} {translations.WORK_PROFILE}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <br/>
              <Typography variant="h6" className={classes.checkboxLabel}>
                {translations.INSTITUTION}
              </Typography>
              <br/>
              <Grid container spacing={3}>
                <SearchComponent
                  xs={3}
                  disableClearable={true}
                  containerClass="field-container"
                  fieldID="filter-field-work"
                  fieldInnerLabel="Lugar de trabajo"
                  handleChange={(event, value) => this.setState({institutionData: event})}
                  onInputChange={() => {}}
                  optionType={"institutions"}
                  savedValue={savedValue}
                  clearOnBlur={false}
                  limitTags={1}
                />
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    style={{ backgroundColor: "#ECEAEA" }}
                    disabled
                    fullWidth
                    variant="outlined"
                    label={"RNC"}
                    value={institutionData?.registry ? institutionData.registry : institutionData?.info?.registry || ""}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    style={{ backgroundColor: "#ECEAEA" }}
                    disabled
                    fullWidth
                    variant="outlined"
                    label={"Codigo de Empleador"}
                    value={institutionData?.employerCode || ""}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    style={{ backgroundColor: "#ECEAEA" }}
                    disabled
                    fullWidth
                    variant="outlined"
                    label={"Estado"}
                    value={institutionData?.state ? this.getState(institutionData?.state) : this.getState(institutionData?.info?.state) || ""}
                  />
                </Grid>
              </Grid>

              <br/>
              <div>
              <Typography variant="h6" className={classes.checkboxLabel}>
                {"Datos Laborales"}
              </Typography>
              <br/>
              <Grid container spacing={3}>
                <AutocompleteField
                  xs={3}
                  containerClass="field-container"
                  fieldClass="fields"
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldID="filter-field-department"
                  fieldInnerLabel="Departamento"
                  fieldVariant="outlined"
                  value={this.state.department}
                  handleChange={this.handleReactSelectChange("department")}
                  options={departmentsOptions}
                  getOptionLabel={(option) => option.label || ""}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  limitTags={1}
                />
                <AutocompleteField
                  xs={3}
                  containerClass="field-container"
                  fieldClass="fields"
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldID="filter-field-position"
                  fieldInnerLabel="Posicion"
                  fieldVariant="outlined"
                  value={this.state.position}
                  handleChange={this.handleReactSelectChange("position")}
                  options={positionsOptions}
                  getOptionLabel={(option) => option.label || ""}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  limitTags={1}
                />
                <Grid item xs={3}>
                  <TextField
                    select
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    label={translations.LABORAL_STATUS}
                    value={this.state.laboralStatus}
                    onChange={this.handleSelectChange("laboralStatus")}
                    native={true}
                    error={this.state.laboralStatusError !== ""}
                    inputProps={{
                      id: "laboralStatus",
                      name: "laboralStatus",
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  >
                    <MenuItem value="active">{translations.ACTIVE}</MenuItem>
                    <MenuItem value="inactive">
                      {translations.INACTIVE}
                    </MenuItem>
                    <MenuItem value="retired">{translations.RETIRED}</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    label={translations.EMPLOYMENT_LENGTH}
                    value={this.state.employmentLength}
                    type="number"
                    onChange={this.handleChange("employmentLength")}
                    error={this.state.employmentLengthError !== ""}
                    helperText={this.state.employmentLengthError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 999999999,
                      min: 0,
                      step: 1,
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    label={translations.ENTRY_DATE}
                    value={this.state.entryDate}
                    type="date"
                    onChange={this.handleChange("entryDate")}
                    error={this.state.entryDateError !== ""}
                    helperText={this.state.entryDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  {this.state.laboralStatus === "retired" ? (
                    <TextField
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      label={translations.DEPARTURE_DATE}
                      value={this.state.departureDate}
                      type="date"
                      onChange={this.handleChange("departureDate")}
                      error={this.state.departureDateError !== ""}
                      helperText={this.state.departureDateError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  ) : (
                    <TextField
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      label={translations.REENTRY_DATE}
                      value={this.state.reentryDate}
                      type="date"
                      onChange={this.handleChange("reentryDate")}
                      error={this.state.reentryDateError !== ""}
                      helperText={this.state.reentryDateError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  )}
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    label={translations.SALARY}
                    value={this.state.salary}
                    onChange={this.handleCurrencyInputChange("salary")}
                    error={this.state.salaryError !== ""}
                    helperText={this.state.salaryError}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    label={translations.LABORAL_CODE}
                    value={this.state.laboralCode}
                    onChange={this.handleChange("laboralCode")}
                    error={this.state.laboralCodeError !== ""}
                    helperText={this.state.laboralCodeError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    select
                    fullWidth
                    variant="outlined"
                    label="Jornada de trabajo"
                    value={this.state.sessionType}
                    onChange={this.handleSelectChange("sessionType")}
                    native={true}
                    inputProps={{
                      id: "sessionType",
                      name: "sessionType",
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  >
                    {sessionTypes.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    select
                    fullWidth
                    variant="outlined"
                    label="Forma de pago"
                    value={this.state.paymentType}
                    onChange={this.handleSelectChange("paymentType")}
                    native={true}
                    inputProps={{
                      id: "paymentType",
                      name: "paymentType",
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  >
                    {paymentTypesLeads.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    select
                    fullWidth
                    variant="outlined"
                    label="Tipo de empleo"
                    value={this.state.workType}
                    onChange={this.handleSelectChange("workType")}
                    native={true}
                    inputProps={{
                      id: "workType",
                      name: "workType",
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  >
                    {workTypes.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    fullWidth
                    disabled
                    style={{ backgroundColor: "#ECEAEA" }}
                    variant="outlined"
                    label="Actividad Económica"
                    value={institutionData?.economicSector ? institutionData?.economicSector : institutionData?.info?.economicSector || ""}
                    native={true}
                    inputProps={{
                      id: "economicActivity",
                      name: "economicActivity",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    label={"Actividad Económica Secundaria"}
                    value={this.state.secondaryIncomeActivity}
                    onChange={this.handleChange("secondaryIncomeActivity")}
                    error={this.state.secondaryIncomeActivityError !== ""}
                    helperText={this.state.secondaryIncomeActivityError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    select
                    fullWidth
                    variant="outlined"
                    label="Ingresos secundarios"
                    value={this.state.secondaryIncome}
                    onChange={this.handleSelectChange("secondaryIncome")}
                    native={true}
                    inputProps={{
                      id: "secondaryIncome",
                      name: "secondaryIncome",
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  >
                    {secondaryIncomeMonthlyActivity.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </Grid>
              </div>


              <div className={classes.controls}>
                <Button
                  variant="contained"
                  disabled={true}
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  onClick={this.handleSubmit}
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(connect(mapStateToProps)(WorkProfile));
