import { Button, Grid, Toolbar, Typography } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { TableSearch } from 'src/components/SharedComponents/Fields';
import { GeneralTable } from 'src/components/SharedComponents/Table';

function DiscountsToolbar(props) {
    const selected = props.selected && props.selected.length ? true : false
    const qtySelected = props.selected.length
    return(
        <Toolbar>
            <Grid container item direction="row" spacing={5} alignItems="center">
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{fontWeight: 'bold', paddingLeft:16}}
                >
                    Instituciones
                </Typography>
                {
                    <Button
                        variant="contained"
                        style={{fontSize: '10px', color:'#fff', borderColor: selected? '#43A047' : '#BDBDBD', backgroundColor:selected? '#43A047' : '#BDBDBD', marginLeft: 16 }}
                        disableElevation
                        size="small"
                        disabled={!selected}
                        onClick={props.generateFile}
                    >
                        <Print style={{ fontSize: 15, color: '#fff', margin: '0 .5rem .1rem 0' }} />
                        Generar ({qtySelected}) reporte(s)
                    </Button>
                }
            </Grid>
        </Toolbar>
    );
}

function DiscountsTable(props) {
    const [tableData, setTableData] = useState([])


    const columns = [
        {id:'checkbox', label:'SELECCIÓN', minWidth: 70 },
        {id:'name', label: 'INSTITUCIÓN', minWidth: 150},
        {id:'registry', label: 'RNC', minWidth: 100},
        {id:'paymentFrequency', label: 'FRECUENCIA DE PAGO', minWidth: 150},
        {id:'paymentDay', label: 'DIA DE PAGO', minWidth: 120},
        {id:'institutionType', label: 'TIPO DE INSTITUCIÓN', minWidth: 150,},
    ];

    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);
  return (
    <GeneralTable
            columns={columns}
            data={tableData}
            selected={props.selected}
            setSelected={props.setSelected}
            handleSelected={props.handleSelected}
            toolbar={
                <Grid container justifyContent='space-between' alignItems='center'>
                    <DiscountsToolbar
                        handleReport={props.handleReport}
                        handleReportCsv={props.handleReportCsv}
                        paymentsQuantity={props.data.length}
                        selected={props.selected}
                        handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                        generateFile={props.generateFile}
                    />
                    <TableSearch
                        data={props.data}
                        setTableData={setTableData}
                        searchFields={['name']}
                    />
                </Grid>
            }
        />
  )
}

export default DiscountsTable