import React, {useState, useEffect} from 'react'
import { TextField, Button, Grid } from '@material-ui/core';
import { DateField, SearchComponent } from 'src/components/SharedComponents/Fields';
import { ClearAll } from '@material-ui/icons';
import { dateFormat } from 'src/components/SharedComponents/CustomHooks';
import { fetchBusinessRepresentatives } from 'src/utils/axios';
import { catchError } from 'src/utils';
import { AutocompleteField } from 'src/components/SharedComponents/Fields';


 const defaultFilterData = {
  associate: null,
  representative: null,
  institution: null,
 }

function Filter(props) {
  
  const [date, setDate] = useState({start: null, end: null, startVal: null, endVal: null});
  const [filterData, setFilterData] = useState(defaultFilterData);
  const [repName, setRepName] = useState({});
 
  const filterFieldHandler = (field, event, val) => {
    let value = val ? val : (event && event.target) ? event.target.value : event;
            setFilterData({...filterData, [field]: value});
  }

const handleDateChange = (type, dateInfo, value) => {
  setDate({...date, [type]: dateInfo});
}

const clearFilters = () => {
  setFilterData(defaultFilterData);
  setDate({start: null, end: null, startVal: null, endVal: null});
}

const applyFilters = () => {
  const institutionAbb = filterData.institution?.info?.registry
  const fields = {
    associate_name: filterData.associate?.fullName,
    representante_negocios: filterData?.representative,
    rnc: institutionAbb,
    date_start:dateFormat(date.start),
    date_end:dateFormat(date.end),
  };
  Object.keys(fields).forEach(key => {
      if(!fields[key] || fields[key].length < 1) {
          delete fields[key];
      }
  });
  
  props.applyFilters(fields);
}

const getBusinessAgents = (data) => {
  const endpoint = fetchBusinessRepresentatives({
    token: data.token,
  });
  endpoint
    .then((response) => {
      const data = response.data.results
        ? response.data.results
        : response.data;
      const rep = data.map((representative) => representative.name);
      setRepName(rep);
    })
    .catch((errors) => {
      catchError(errors);
    });
};

useEffect(() => {
  getBusinessAgents(props);
}, []);


  return (
    <Grid className="filters-container">
      <Grid item container spacing={3} direction="row">
        <SearchComponent
          xs={4}
          containerClass="field-container"
          fieldLabel="Institucion"
          fieldID="filter-field-enterprises"
          fieldInnerLabel="Seleccione una institucion"
          value={filterData.institution}
          handleChange={filterFieldHandler.bind(this, "institution")}
          optionType={"institutions"}
          limitTags={1}
        />
        <SearchComponent
          xs={4}
          containerClass="field-container"
          fieldLabel="Socio"
          fieldID="filter-field-participant"
          fieldInnerLabel="Seleccione el socio"
          value={filterData.associate}
          handleChange={filterFieldHandler.bind(this, "associate")}
          optionType={"participants"}
          clearOnBlur={false}
          limitTags={1}
        />
        <AutocompleteField
          xs={4}
          containerClass="field-container"
          fieldClass="fields"
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Representantes"
          fieldID="filter-field-priority"
          fieldInnerLabel="Seleccione..."
          fieldVariant="outlined"
          value={filterData.representative}
          handleChange={filterFieldHandler.bind(this, "representative")}
          options={repName}
          getOptionLabel={(option) => option}
          limitTags={1}
        />
        <DateField
          xs={4}
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Fecha inicio"
          dateFieldID="filter-date-start"
          fullWidth={true}
          dateFieldClass="date-field"
          inputVariant="outlined"
          dateFieldLabel="Fecha inicio"
          format="yyyy/MM/dd"
          value={date.start}
          handleChange={handleDateChange.bind(this, "start")}
        />
        <DateField
          xs={4}
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Fecha fin"
          dateFieldID="filter-date-end"
          fullWidth={true}
          dateFieldClass="date-field"
          inputVariant="outlined"
          dateFieldLabel="Fecha fin"
          format="yyyy/MM/dd"
          value={date.end}
          handleChange={handleDateChange.bind(this, "end")}
        />
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginTop: "35px", justifyContent: "space-between" }}
      >
        <Button
          disableElevation
          className="filter-clear-btn"
          onClick={clearFilters.bind(this, false)}
        >
          limpiar
          <ClearAll style={{ marginLeft: 8, fontSize: "19px" }} />
        </Button>
        <Button
          disableElevation
          className="filter-apply-btn"
          onClick={applyFilters}
        >
          aplicar filtros
        </Button>
      </Grid>
    </Grid>
  );
}

export default Filter