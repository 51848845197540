import { PAYMENT_CAPACITY_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case PAYMENT_CAPACITY_TYPES.FETCH_ALL:
            return action.payload || [];
        case PAYMENT_CAPACITY_TYPES.FETCH:
            return state.map(paymentCapacity => (action.payload !== undefined && paymentCapacity.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : paymentCapacity);
        default:
            return state;
    }
}