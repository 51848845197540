import { MEMBERSHIP_APPLICATION_TYPES } from '../actions';

export default function (state = [], action) {
    switch (action.type) {
        case MEMBERSHIP_APPLICATION_TYPES.FETCH_ALL:
            return action.payload || [];
        case MEMBERSHIP_APPLICATION_TYPES.FETCH:
            return state.map(record => (action.payload !== undefined && record.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : record);
        case MEMBERSHIP_APPLICATION_TYPES.CREATE:
            return state.concat(action.payload || []);
        case MEMBERSHIP_APPLICATION_TYPES.UPDATE:
            return state.map(record => (action.payload !== undefined && record.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : record);
        case MEMBERSHIP_APPLICATION_TYPES.DELETE:
            return state.filter(record => action.payload !== undefined && record.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}