import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";

import { fetchProfessions } from "../../../actions";
import { fetchProfessions as fetch } from "../../../utils/axios";
import ProfessionForm from "../ProfessionForm";
import ProfessionSearchSection from "../ProfessionSearchSection";

const styles = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

class ProfessionsSelectInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      professions: [],
      formDialog: false,
      loading: false,
      open: false,
    };

    this.findProfessions = this.findProfessions.bind(this);
    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });

    fetch({ token: this.props.user.token })
      .then((res) => {
        this.props.fetchProfessions(
          res.data.results ? res.data.results : res.data
        );

        this.setState({
          professions: res.data.results ? res.data.results : res.data,
          loading: false,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  openDialogHandler() {
    this.setState({ open: true });
  }
  closeDialogHandler() {
    this.setState({ open: false });
  }
  openFormDialog() {
    this.setState({ formDialog: true });
  }
  closeFormDialog() {
    this.setState({ formDialog: false });
  }
  emptyFunction() {
    return null;
  }

  handleItemClick(professionId) {
    this.setState({ loading: true });

    fetch({ token: this.props.user.token })
      .then((res) => {
        this.props.fetchProfessions(
          res.data.results ? res.data.results : res.data
        );

        this.setState(
          {
            professions: res.data.results ? res.data.results : res.data,
            loading: false,
            open: false,
          },
          () => {
            this.props.addProfession(professionId);
          }
        );
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  handleDeleteItem(id) {
    return (event) => {
      this.props.deleteProfession(id);
    };
  }
  findProfessions(uniqueId) {
    const curProfession = this.state.professions.find(
      (profession) => profession.uniqueId === uniqueId
    );

    if (curProfession) {
      return (
        <TableRow key={curProfession.uniqueId}>
          <TableCell>{curProfession.name}</TableCell>
          <TableCell>
            <IconButton
              onClick={this.handleDeleteItem(
                curProfession.uniqueId ? curProfession.uniqueId : ""
              )}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    } else {
      return null;
    }
  }
  fetchData(params) {
    params.limit = undefined;
    params.offset = 0;

    this.setState({ loading: true });
    fetch(params)
      .then((res) => {
        this.props.fetchProfessions(
          res.data.results ? res.data.results : res.data
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }
  render() {
    const { classes } = this.props;
    const { formDialog, open, loading } = this.state;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={this.closeDialogHandler}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            {/* <IconButton className={classes.button} onClick={this.openFormDialog}>
                            <AddIcon />
                        </IconButton> */}
          </DialogActions>
          <DialogContent style={{ height: 600, width: 280 }}>
            <ProfessionSearchSection
              advanceSearch={this.emptyFunction}
              loading={loading}
              handleClick={this.handleItemClick}
              fetchData={this.fetchData}
              pagination={false}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={formDialog}
          fullScreen={true}
          onClose={this.closeFormDialog}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <ProfessionForm changeSection={this.emptyFunction} />
          </DialogContent>
        </Dialog>
        <Typography variant="h6" style={{ display: "inline" }}>
          {this.props.title}
        </Typography>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.NAME}</TableCell>
                <TableCell>
                  <IconButton onClick={this.openDialogHandler}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.selectedProfessionId.map((professionId) =>
                this.findProfessions(professionId)
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    professions: state.professions,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchProfessions })(ProfessionsSelectInput)
);
