
import { OUTLAY_CHECK_TYPES } from '../actions';

export default function(state: IOutlayCheck[] = [], action: IAction<IOutlayCheck[]>): IOutlayCheck[] {
    switch(action.type) {
        case OUTLAY_CHECK_TYPES.FETCH_ALL:
            return action.payload || [];
        case OUTLAY_CHECK_TYPES.FETCH:
            return state.map(check => (action.payload !== undefined && check.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: check);
        case OUTLAY_CHECK_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case OUTLAY_CHECK_TYPES.UPDATE:
            return state.map(check => (action.payload !== undefined && check.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : check);
        case OUTLAY_CHECK_TYPES.DELETE:
            return state.filter(check => action.payload !== undefined && check.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}