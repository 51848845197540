import * as React from "react";
import { connect } from "react-redux";

import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import {
  titleCaseHandler,
  translations,
} from "../../../utils";
import {
  fetchNationalities,
  fetchRegions,
  fetchStates,
  fetchCities,
} from "../../../utils/axios";
import { MenuItem } from "@material-ui/core";


const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    display: "none",
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  checkboxLabelReactSelect: {
    color: green[500],
    fontSize: "small",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    width: '49%',
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    overflowy: "auto",
    padding: 20,
    paddingBottom: 64,
  },
  textField: {
    marginTop: 20,
    width: "100%",
  },
});

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      dialogTitle: "",
      errorMessage: "",
      loading: false,
      countries: [],
      regions: [],
      states: [],
      cities: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: false });
    fetchNationalities({ token: this.props.user.token })
      .then((res) => {
        this.setState({
          countries: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando nacionalidades",
          loading: false,
        });
      });
    fetchRegions({ token: this.props.user.token })
      .then((res) => {
        this.setState({
          regions: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando regiones",
          loading: false,
        });
      });
    fetchStates({ token: this.props.user.token })
      .then((res) => {
        this.setState({
          states: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando provincia",
          loading: false,
        });
      });
    fetchCities({ token: this.props.user.token })
      .then((res) => {
        this.setState({
          cities: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando ciudades",
          loading: false,
        });
      });
  }
  handleReactSelectChange(nationality) {
    this.setState({ nationality });
  }

  handleChange(key) {
    return (event) => {
      this.props.handleSelect({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }

  handleSelectChange(key, fn = null, stateChange, providedToken) {
    return (event) => {
      this.props.handleSelect(key, event.target.value);
      if (fn !== null) {
        fn({ token: providedToken, [key]: event.target.value })
          .then((res) => {
            this.setState({
              [stateChange]: res.data,
            });
          })
          .catch((err) => {
            this.setState({
              dialog: true,
              dialogTitle: "Error",
              errorMessage: "Problema cargando opciones",
              loading: false,
            });
          });
      }
    };
  }
  handleDialogClose() {
    this.setState({ dialog: false });
  }
  render() {
    const { classes } = this.props;
    return (
      <div style={{
        marginLeft: 8
      }}>
        <br />
        <Typography variant="h6">{this.props.title}</Typography>
        <FormControl
          className={classes.formControl}
          variant={this.props.variant}
        >
          <InputLabel htmlFor="country">
            {translations.COUNTRY}
          </InputLabel>
          <Select
            value={this.props.country}
            onChange={this.handleSelectChange("country", fetchRegions, "regions", this.props.user.token)}
            required={true}
            inputProps={{
              id: "country",
              name: "country",
            }}
          >
            {this.state.countries.map((opt, index) => {
              return (
                <MenuItem key={index} value={opt.name}>
                  {opt.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        &nbsp;
        <FormControl
          className={classes.formControl}
          variant={this.props.variant}
        >
          <InputLabel htmlFor="region">
            {translations.REGION}
          </InputLabel>
          <Select
            value={this.props.region}
            onChange={this.handleSelectChange("region", fetchStates, "states", this.props.user.token)}
            required={true}
            inputProps={{
              id: "region",
              name: "region",
            }}
          >
            {this.state.regions.map((opt, index) => {
              return (
                <MenuItem key={index} value={opt.name}>
                  {opt.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          className={classes.formControl}
          variant={this.props.variant}
        >          <InputLabel htmlFor="state">
            {translations.PROVINCE}
          </InputLabel>
          <Select
            value={this.props.state}
            onChange={this.handleSelectChange("state", fetchCities, "cities", this.props.user.token)}
            required={true}
            inputProps={{
              id: "state",
              name: "state",
            }}
          >
            {this.state.states.map((opt, index) => {
              return (
                <MenuItem key={index} value={opt.name}>
                  {opt.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        &nbsp;
        <FormControl
          className={classes.formControl}
          variant={this.props.variant}
        >          <InputLabel htmlFor="city">
            {translations.CITY}
          </InputLabel>
          <Select
            value={this.props.city}
            onChange={this.handleSelectChange("city")}
            required={true}
            inputProps={{
              id: "city",
              name: "city",
            }}
          >
            {this.state.cities.map((opt, index) => {
              return (
                <MenuItem key={index} value={opt.name}>
                  {opt.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        &nbsp;
        <TextField
          label={translations.SECTOR}
          style={{ marginTop: 20, width: "99%" }}
          value={titleCaseHandler(this.props.sector)}
          onChange={this.props.handleChange("sector")}
          fullWidth={true}
          required={true}
          helperText={this.props.sectorError}
          InputLabelProps={{
            style: styles.checkboxLabel,
          }}
        />
        &nbsp;
        <TextField
          label={translations.REFERENCE}
          style={{ marginTop: 20, width: "99%" }}
          value={this.props.reference}
          onChange={this.props.handleChange("reference")}
          required={true}
          helperText={this.props.referenceError}
          InputLabelProps={{
            style: styles.checkboxLabel,
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps)(Form)
);
