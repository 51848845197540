import React, { useState } from 'react';
import {
    Table, TableContainer, TableHead, TableRow, TableCell, TableBody, 
    Button, Paper, Grid, Collapse, Toolbar, Typography, IconButton, 
    Checkbox,
} from '@material-ui/core';
import { 
    KeyboardArrowUp, KeyboardArrowDown, FilterList
} from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import { NoRowsOverlay } from './../../../components/SharedComponents/Table';
import { Filter } from './../Filter';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
        '& .ant-empty-img-1': {
        fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
        fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
        fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
        fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
        fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
        fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
        },
    },
    label: {
        marginTop: theme.spacing(1),
    },
    // colorPrimary: {
    //     color:'#1E88E5'
    // },
    tableRow: {
        "&$selected, &$selected:hover": {
          backgroundColor: "#EEEEEE"
        }
    },
    //   tableCell: {
    //     "$selected &": {
    //       color: "yellow"
    //     }
    //   },
      selected: {}
}));

function AccountSelectionTable(props) {

    const checkStatusColor = (status) => {
        switch(status){
            case 'Pendiente por conciliar':
                return '#E53935';
            case 'Conciliado':
                return '#43A047';
            default:
                return '#212121';
        }
    }
    return(
        <TableContainer component={Paper}>
                <Table className="table-container light" size='small'>
                    <TableHead className="table-header">
                        <TableRow>
                            <TableCell align="center">ESTADO</TableCell>
                            <TableCell align="center">BANCO</TableCell>
                            <TableCell align="center">CUENTA</TableCell>
                            <TableCell align="center">BALANCE</TableCell>
                            <TableCell align="center">ACCIÓN</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.data.length < 1 ? <NoRowsOverlay colSpan={4}/>
                            :
                            props.data.map(row => (
                                <TableRow key={row.id} className="table-row">
                                    <TableCell 
                                        align="center"
                                        style={{color: checkStatusColor(row.status)}}
                                    >
                                        {row.status}
                                    </TableCell>
                                    <TableCell align="center">{row.bank_name}</TableCell>
                                    <TableCell align="center">{row.account}</TableCell>
                                    <TableCell align="center">{row.balance}</TableCell>
                                    <TableCell align="center">
                                        <Button 
                                            variant="contained"
                                            disableElevation
                                            style={{width:95, borderRadius:3, backgroundColor:'#4CAF50', color:'#FFFFFF', fontSize:11}}
                                            onClick={props.handleShowDetail.bind(this, row)}
                                        >
                                            detalle
                                        </Button>
                                    </TableCell>
                            </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
    )
}

function MovementTable(props) {

    const [openRow, setOpenRow] = useState(false);
    const handleToggleRowDetail = (row) => {
        if(openRow) {
            openRow === row ? setOpenRow(false) : setOpenRow(row);
        }else {
            setOpenRow(row);
        }
    }
    const checkStatusColor = (status) => {
        switch(status){
            case 'Parcialmente conciliado':
                return '#E53935';
            case 'Conciliado':
                return '#9E9E9E';
            case 'En tránsito':
                return '#FB8C00';
            default:
                return '#212121';
        }
    }
    const checkAmountTypeColor = (amountType) => {
        switch(amountType){
            case 'Débito':
                return '#E53935';
            case 'Crédito':
                return '#212121';
            default:
                return '#212121';
        }
    }
    const rowKey = (n, reference) => {
        return props.movementType + n + '-' + reference;
    }
    const formatDate = (value) => {
        const date = new Date(value.toString());
        return date.getDate() + '/' + (date.getMonth()+1) + '/' + date.getFullYear();
    }
    const formatNumber = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return(
        <TableContainer component={Paper}>
                <Table className="table-container light" size='small'>
                    <TableHead className="table-header">
                        <TableRow>
                            <TableCell align="center">ESTADO</TableCell>
                            <TableCell align="center">REFERENCIA</TableCell>
                            <TableCell align="center">FECHA</TableCell>
                            <TableCell align="center">MONTO</TableCell>
                            <TableCell align="center">DETALLE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        props.data.length < 1 ? <NoRowsOverlay colSpan={4}/>
                        : 
                        props.data.map((row, index) => (
                            <React.Fragment key={rowKey(index, row.reference)}>
                                <TableRow className="table-row">
                                    <TableCell 
                                        align="center"
                                        style={{color: checkStatusColor(row.status)}}
                                    >
                                        {row.status}
                                    </TableCell>
                                    <TableCell align="center">{row.reference}</TableCell>
                                    <TableCell align="center">
                                        {props.movementType === 'banco' ? formatDate(row.date) : row.date}
                                    </TableCell>
                                    <TableCell 
                                        align="center"
                                        style={{color: checkAmountTypeColor(row.amount_type)}}
                                    >
                                        {typeof row.amount === "number" ? formatNumber(row.amount) : row.amount}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton 
                                            size="small" 
                                            onClick={handleToggleRowDetail.bind(this, rowKey(index, row.reference))}
                                        >
                                            {openRow === rowKey(index, row.reference) ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow className="table-row">
                                    <TableCell
                                        style={{ paddingBottom: 0, paddingTop: 0 }} 
                                        colSpan={5}
                                    >   
                                        <Collapse in={openRow === rowKey(index, row.reference)} timeout="auto">
                                            <div style={{padding:10}}>
                                                <Grid container justify="space-between">
                                                    <Grid item container xs={4}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            style={{color:'#424242',fontWeight:'bold'}}
                                                        >
                                                            <Typography 
                                                                variant="overline"
                                                                style={{color:'#616161', fontSize:'14px'}}
                                                            >Estado financiero:&nbsp;</Typography>
                                                            {row.financial_status}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item container xs={4}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            style={{color:'#424242',fontWeight:'bold'}}
                                                        >
                                                            <Typography 
                                                                variant="overline"
                                                                style={{color:'#616161', fontSize:'14px'}}
                                                            >Tipo de operación:&nbsp;</Typography>
                                                            {row.amount_type}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item container xs={4}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            style={{color:'#424242',fontWeight:'bold'}}
                                                        >
                                                            <Typography 
                                                                variant="overline"
                                                                style={{color:'#616161', fontSize:'14px'}}
                                                            >Concepto:&nbsp;</Typography>
                                                            {row.concept}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container justify="space-between">
                                                    <Grid item container xs={4}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            style={{color:'#424242',fontWeight:'bold'}}
                                                        >
                                                            <Typography 
                                                                variant="overline"
                                                                style={{color:'#616161', fontSize:'14px'}}
                                                            >Estado contable:&nbsp;</Typography>
                                                            {row.accounting_status}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item container xs={4}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            style={{color:'#424242',fontWeight:'bold'}}
                                                        >
                                                            <Typography 
                                                                variant="overline"
                                                                style={{color:'#616161', fontSize:'14px'}}
                                                            >Balance:&nbsp;</Typography>
                                                            {row.balance}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item container xs={4}>
                                                        <Typography
                                                            variant="subtitle1"
                                                        >
                                                            <Typography 
                                                                variant="overline"
                                                                style={{color:'#616161', fontSize:'14px'}}
                                                            >Acción:&nbsp;</Typography>

                                                            <Button 
                                                                variant="contained"
                                                                disableElevation
                                                                style={{width:'30%', heigth:28, backgroundColor:'#43A047', 
                                                                color:'#FFFFFF', fontSize:12, marginRight:10}}
                                                                onClick={props.handleDialogToggle.bind(this, "reconcile", row)}
                                                            >
                                                                conciliar
                                                            </Button>

                                                            <Button 
                                                                variant="contained"
                                                                disableElevation
                                                                style={{width:'30%', heigth:28, backgroundColor:'#878787', color:'#FFFFFF', fontSize:12}}
                                                                onClick={props.handleDialogToggle.bind(this, "restore", row)}
                                                            >
                                                                restaurar
                                                            </Button> 
                                                            
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
    )
}

function ReconciliationTableToolbar(props) {

    const { numSelected } = props;

    return(
        <Toolbar>
            <Typography 
                variant="overline"
                style={{color:'#616161', fontSize:14, width:'100%'}}
            >
                {
                    numSelected > 0 ? 
                        <Grid container direction="row" justify="space-between">
                            <div>
                                <span>Selección:&nbsp;</span>
                                <span style={{fontWeight:'bold', fontSize:15}}>{numSelected}</span>
                            </div>

                            <Button
                                variant="contained"
                                disableElevation
                                onClick={props.reconcileRestore.bind(this, props.actionType, props.selectedData)}
                                style={{backgroundColor: props.actionType === "reconcile" ? '#4CAF50' : '#0277BD',
                                        color:'#FFFFFF'}}
                            >
                                {props.actionType === "reconcile" ? "conciliar" : "restaurar"}
                            </Button>
                        </Grid>
                    :
                        <Grid container direction="row" justify="space-between">
                            <div>
                                {props.actionType === "reconcile" ? <span>Conciliación</span> : <span>Restaurar</span>}
                            </div>
                            <div>
                                <Button 
                                    onClick={props.handleToggleFilter}
                                    style={{color:'#424242'}}
                                >
                                    Filtros <FilterList style={{marginLeft:5, fontSize:18, color:'#424242'}}/>
                                </Button>
                                <Filter
                                    filterFields={props.filterFields}
                                    handleFilterSelected={props.handleFilterSelected}
                                    filterFieldsData={props.filterFieldsData}
                                    applyFilters={props.applyFilters}
                                    clearFilters={props.clearFilters}
                                    handleToggleFilter={props.handleToggleFilter}
                                    openFilter={Boolean(props.openFilter)}
                                    anchorEl={props.openFilter}
                                />
                            </div>
                        </Grid>
                }

            </Typography>
        </Toolbar>
    );
}

function ReconciliationTable(props) {

    const classes = useStyles();

    const checkStatusColor = (status) => {
        switch(status){
            case 'Pendiente por conciliar':
                return '#E53935';
            case 'Conciliado':
                return '#43A047';
            default:
                return '#212121';
        }
    }

    const [openFilter, setOpenFilter] = useState(null);
    const handleToggleFilter = (event) => {
        openFilter ? setOpenFilter(null) : setOpenFilter(event.currentTarget);
    }

    const [statusField, setStatus] = useState([]);
    const [referenceField, setReference] = useState('');
    const [startDateField, setStartDate] = useState(null);
    const [formattedStartDate, setFormattedStartDate] = useState(null);
    const [endDateField, setEndDate] = useState(null);
    const [formattedEndDate, setFormattedEndDate] = useState(null);
    const [amountField, setAmount] = useState('');

    const handleFilterSelected = (action, event, value) => {
        switch(action) {
            case "status":
                setStatus(value);
                break;
            case "reference":
                setReference(event.target.value);
                break;
            case "startDate":
                setStartDate(event);
                setFormattedStartDate(value);
                break;
            case "endDate":
                setEndDate(event);
                setFormattedEndDate(value);
                break;
            case "amount":
                setAmount(event.target.value);
                break;
            default:
                break;
        }
    }

    const applyFilters = () => {
        const filteredDataTable = 
                props.data.filter(item => 
                (statusField.length > 0 ? statusField.includes(item.status) : true) &&
                (referenceField ? item.reference === referenceField : true) &&
                ( 
                    (formattedStartDate ? item.date >= formattedStartDate : true) && 
                    (formattedEndDate ? item.date <= formattedEndDate : true) 
                ) &&
                (amountField ? item.amount === amountField : true)
            );
        
        props.setTableData(filteredDataTable);
        handleToggleFilter();
    }

    const clearFilters = () => {
        setStatus([]);
        setReference('');
        setStartDate(null);
        setFormattedStartDate(null);
        setEndDate(null);
        setFormattedEndDate(null);
        setAmount('');
        props.setTableData(props.data);
    }

    return(
        <TableContainer component={Paper}>
            <ReconciliationTableToolbar
                reconcileRestore={props.handleConfirmDialog}
                actionType={props.actionType}
                selectedData={props.selectedRows}
                numSelected={props.selectedRows.length}
                filterFields={Object.keys(props.data[0])}
                handleFilterSelected={handleFilterSelected}
                filterFieldsData={
                    {statusField, referenceField, amountField, startDateField, endDateField}
                }
                applyFilters={applyFilters}
                clearFilters={clearFilters}
                handleToggleFilter={handleToggleFilter}
                openFilter={openFilter}
            />
            <Table className="table-container light" size='small'>
                <TableHead className="table-header">
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={props.selectedRows.length > 0 && props.selectedRows.length < props.tableData.length}
                                checked={props.tableData.length > 0 && props.selectedRows.length === props.tableData.length}
                                onChange={props.handleSelectAllRows}
                                inputProps={{ 'aria-label': 'seleccione todas transacciones' }}
                                // labelStyle={{color: '#1E88E5'}}
                                color='primary'
                            />
                        </TableCell>
                        {
                            props.actionType === 'reconcile' ? 
                                <TableCell align="center">ESTADO</TableCell> 
                            : <></>
                        }
                        <TableCell align="center">REFERENCIA</TableCell>
                        <TableCell align="center">FECHA</TableCell>
                        <TableCell align="center">MONTO</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    props.tableData.length < 1 ? 
                    <NoRowsOverlay colSpan={props.actionType === 'reconcile' ? 5 : 4}/>
                    :
                    props.tableData.map(({status, reference, date, amount}, index) => {
                        const isItemSelected = props.isSelected(reference);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return(
                            <TableRow 
                                key={'recon'+index+'-'+reference} 
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                selected={isItemSelected}
                                classes={{ selected: classes.selected }}
                                className={("table-row ")+classes.tableRow}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        onClick={(event) => props.handleSelectRow(event, reference)}
                                        checked={isItemSelected}
                                        inputProps={{ 'aria-labelledby': labelId }} 
                                        color='primary'
                                    />
                                </TableCell>
                                {
                                    props.actionType === 'reconcile' ?
                                        <TableCell 
                                            align="center"
                                            style={{color: checkStatusColor(status)}}
                                        >
                                            {status}
                                        </TableCell>
                                    :
                                        <></>
                                }
                                <TableCell align="center">{reference}</TableCell>
                                <TableCell align="center">{date}</TableCell>
                                <TableCell align="center">{amount}</TableCell>
                            </TableRow>
                        );
                    })
                }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
function DataGridTable(props) {
    return(
        <div style={{ height: props.height ? props.height : 'none', width: '100%' }}
            >
            <DataGrid 
                rows={props.rows} 
                columns={props.columns} 
                pageSize={props.pageSize}
                checkboxSelection={props.checkboxSelection}
                disableSelectionOnClick
                hideFooter={props.hideFooter}
                rowHeight={props.rowHeight}
                className={props.className}
                error={props.error}
                components={props.components}
                onSelectionChange={props.onSelectionChange}
            />
        </div>
    );
}

export { 
    AccountSelectionTable, MovementTable, 
    ReconciliationTable, DataGridTable,
}