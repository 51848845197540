import * as React from 'react';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ParticipantAutoComplete from 'src/components/Filters/ParticipantAutoComplete';
import { SearchComponent } from "../../../components/SharedComponents/Fields";

import { translations } from '../../../utils';

const styles = createStyles({
    formControl: {
        marginTop: 20
    },
    greenButton: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    label: {
        color: green[500]
    },
    redButton: {
        '&:hover': {
            backgroundColor: red[200]
        },
        backgroundColor: red[500],
        color: 'white'
    },
    gridFormControl: {
        width: '100%',
    },
});


class AcquaintanceshipCompanyContractSearchFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contract_date_from: '',
            contract_date_to: new Date().toISOString().split('T')[0],
            creditor_unique_id: [],
            debtor_unique_id: [],
            // debtor_representative_name: '',
            anual_primary_income_to: '',
            anual_primary_income_from: '',
            title: '',
            status: [],
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }
    handleSearch() {
        const params = {
            contractDateFrom: this.state.contract_date_from,
            contractDateTo: this.state.contract_date_to,
            creditorUniqueId: this.state.creditor_unique_id.map((item) => item.uniqueId).join(','),
            debtorUniqueId: this.state.debtor_unique_id,
            status: this.state.status.map((item) => item.value).join(','),
            // debtorRepresentativeName: this.state.debtor_representative_name,
            anualPrimaryIncomeTo: this.state.anual_primary_income_to,
            anualPrimaryIncomeFrom: this.state.anual_primary_income_from,
            title: this.state.title,
            token: this.props.token
        };
        this.props.search(params);
        this.props.handleClose();
    }
    handleClear() {
        this.setState({
            contract_date_from: '',
            contract_date_to: new Date().toISOString().split('T')[0],
            creditor_unique_id: [],
            debtor_unique_id: [],
            // debtor_representative_name: '',
            title: '',
            status: [],
        });
        this.props.search({ token: this.props.token })
        this.props.handleClose();
    }
    handleChange(key) {
        return (event) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }
    handleSelectChange(key) {
        return (event) => {
            this.setState({ [key]: event.target.value });
        }
    }
    handleCurrencyInputChange(key) {
        return (event, maskedValue, floatValue) => {
            this.setState({ [key]: floatValue || '' });
        }
    }

    handleAutocompleteChange(key, obj, val) {
        /* Handle the state of Autocomplete components */
        const data = obj || val;

        this.setState({
            [key]: data.value ? data.value : ""
        });

    }

    render() {
        const { classes, handleClose, open } = this.props;

        const handleMultipleSelection = (key, val) =>
            this.setState({ [key]: val });
        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item={true} xs={12}>
                            <SearchComponent
                                xs={12}
                                containerClass="field-container"
                                fieldClass={{ style: styles.label }}
                                typeClass={styles.label}
                                fieldID="client"
                                fieldInnerLabel={translations.DEBTOR}
                                handleChange={this.handleAutocompleteChange.bind(this, "debtor_unique_id")}
                                optionType={'participants'}
                                variant="standard"
                                limitTags={1}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                label={translations.TITLE}
                                value={this.state.title}
                                fullWidth={true}
                                onChange={this.handleChange('title')}
                                InputLabelProps={{
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Autocomplete
                                style={{ marginTop: "16px" }}
                                multiple={true}
                                options={[
                                    { value: "pending_signature", label: translations.PENDING_SIGNATURE },
                                    { value: "completed", label: translations.COMPLETED },
                                    { value: "archived", label: translations.ARCHIVED },
                                ]}
                                getOptionLabel={(option) => option.label}
                                onChange={(e, newValue) =>
                                    handleMultipleSelection("status", newValue)
                                }
                                value={this.state.status}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" label={translations.STATUS} InputLabelProps={{
                                        style: styles.label,
                                        shrink: true
                                    }} />
                                )}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <ParticipantAutoComplete
                                handleSelectChange={(e, newValue) =>
                                    handleMultipleSelection("creditor_unique_id", newValue)
                                }
                                value={this.state.creditor_unique_id}
                                type={"creditor"} label={translations.CREDITOR} />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                label={"Nombre del representante del deudor"}
                                value={this.state.debtor_representative_name}
                                fullWidth={true}
                                onChange={this.handleChange('debtor_representative_name')}
                                InputLabelProps={{ style: styles.checkboxLabel, shrink: true }}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <FormControl className={classes.gridFormControl} >
                                <InputLabel htmlFor="anual_primary_income_from" shrink={true}>{translations.ANUAL_PRIMARY_INCOME} {translations.FROM}</InputLabel>
                                <Select
                                    value={this.state.anual_primary_income_from}
                                    onChange={this.handleSelectChange('anual_primary_income_from')}
                                    inputProps={{
                                        id: 'anual_primary_income_from',
                                        name: 'anual_primary_income_from'
                                    }}
                                >
                                    <MenuItem value="less than RD$20 millions">{translations.LESS_20_MM}</MenuItem>
                                    <MenuItem value="from RD$20 to RD$100 millions">{translations.FROM_20_TO_100_MM}</MenuItem>
                                    <MenuItem value="from RD$100 to RD$200 millions">{translations.FROM_100_TO_200_MM}</MenuItem>
                                    <MenuItem value="from RD$200 to RD$1000 millions">{translations.FROM_200_TO_1000_MM}</MenuItem>
                                    <MenuItem value="from RD$1000 to 4000 millions">{translations.FROM_1000_TO_4000_MM}</MenuItem>
                                    <MenuItem value="more than RD$4000 millions">{translations.MORE_4000_MM}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.gridFormControl}>
                                <InputLabel htmlFor="anual_primary_income_to" shrink={true}>{translations.ANUAL_PRIMARY_INCOME} {translations.TO}</InputLabel>
                                <Select
                                    value={this.state.anual_primary_income_to}
                                    onChange={this.handleSelectChange('anual_primary_income_to')}
                                    inputProps={{
                                        id: 'anual_primary_income_to',
                                        name: 'anual_primary_income_to'
                                    }}
                                >
                                    <MenuItem value="less than RD$20 millions">{translations.LESS_20_MM}</MenuItem>
                                    <MenuItem value="from RD$20 to RD$100 millions">{translations.FROM_20_TO_100_MM}</MenuItem>
                                    <MenuItem value="from RD$100 to RD$200 millions">{translations.FROM_100_TO_200_MM}</MenuItem>
                                    <MenuItem value="from RD$200 to RD$1000 millions">{translations.FROM_200_TO_1000_MM}</MenuItem>
                                    <MenuItem value="from RD$1000 to 4000 millions">{translations.FROM_1000_TO_4000_MM}</MenuItem>
                                    <MenuItem value="more than RD$4000 millions">{translations.MORE_4000_MM}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.FROM}`}
                                value={this.state.contract_date_from}
                                fullWidth={true}
                                type="date"
                                onChange={this.handleChange('contract_date_from')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.TO}`}
                                value={this.state.contract_date_to}
                                fullWidth={true}
                                type="date"
                                onChange={this.handleChange('contract_date_to')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.redButton} onClick={this.handleClear}>
                        <ClearIcon />
                        &nbsp;
                        {`${translations.CLEAR} ${translations.SEARCH}`}
                    </Button>
                    <Button className={classes.greenButton} onClick={this.handleSearch}>
                        <SearchIcon />
                        &nbsp;
                        {translations.SEARCH}
                    </Button>
                </DialogActions>
            </Dialog >
        );
    }

}


export default withStyles(styles)(AcquaintanceshipCompanyContractSearchFilter);