import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { ToolbarComponent } from "./../../../components/SharedComponents/Toolbar";
import { ApprovementTable, TicketsTable } from "./../Tables";
import { ConfirmDialogComponent } from "./../Dialogs";
import {
  SuccessDialog,
  ErrorDialog,
  SimpleConfirmDialog,
} from "../../../components/SharedComponents/Dialogs";
import { Filters } from "./../Filters";
import {
  fetchAssociateApprobation,
  updateAssociateApprobationAct,
  fetchApprobationActs,
  fetchParticipants,
  fetchTickets,
  createApprobationAct,
  generateAssociateApprobations,
} from "./../../../utils/axios";
import LoadingDialog from "../../../components/SharedComponents/LoadingScreen";
import { errorHandling } from "../../../components/SharedComponents/CustomHooks";
import "./../index.css";

function ApprovementAdmissionView(props) {
  const limitRequest = { limit: 10 };
  const [loading, setLoading] = useState(false);
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });
  const [clients, setClients] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [associateApprobations, setAssociateApprobations] = useState([]);
  const [acts, setActs] = useState([]);

  const [selected, setSelected] = useState([]);


  useEffect(() => {
    getTickets();
    getClients();
    getActs();
  }, []);


  const getClients = () => {
    setLoading(true);
    const endpoint = fetchParticipants({
      participantType: "client",
      token: props.user.token,
    });
    endpoint
      .then((response) => {
        const data = response.data.map((item) => {
          return {
            name: item.fullName,
            idNumber: item.identificationNumber,
            uniqueId: item.uniqueId,
          };
        });
        setClients(data);
        setLoading(false);
      })
      .catch((errors) => {
        errorHandling(errors, handleToggleSimpleDialog);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTickets = useCallback(async (data) => {
    setLoading(true);
    try {
      let ticketsData = []

      if (!data) {
        let endpoint = await fetchTickets({ requestType: 'new partner', token: props.user.token, filterFinishedTickets: true });
        ticketsData = endpoint.data.filter((x) => x.status !== 'cancelled')
      } else {
        ticketsData = data.filter((x) => x.status !== 'cancelled')
      }

      for (let item of ticketsData) {

        const client = item.participantDetail && item.participantDetail.fullName;

        item.participantName = client || "N/A"
        
        const approvalObj =
          item.steps.find((x) => (x = x.approvalObj)).approvalObj || {};

        item.actNumber = approvalObj.actNumber;

      }
      ticketsData = ticketsData.filter((x) => x.actNumber === '')
      ticketsData = ticketsData.filter((x) => x.approvalObjStatus !== 'diligence')
      ticketsData = ticketsData.filter((x) => x.approvalObjStatus !== 'extra_req')

      setTickets(ticketsData);
      setLoading(false);
    } catch (err) {
      console.log(err);
      errorHandling(err, handleToggleSimpleDialog);
    }
  }, []);

  const getActs = () => {
    setLoading(true);
    const endpoint = fetchApprobationActs({ token: props.user.token });
    endpoint
      .then((response) => {
        const data = response.data.map((item) => {
          return {
            actNumber: item.actNumber,
            uniqueId: item.uniqueId,
          };
        });
        setActs(data);
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const getAssociateApprobations = useCallback(async (data) => {
    setLoading(true);
    try {
      let associate = await fetchAssociateApprobation(
        props.user.token,
        data ? data : {}
      );
      const associateData = associate.data.results
        ? associate.data.results
        : associate.data;
      for (let element of associateData) {

        const act = await fetchApprobationActs({
          token: props.user.token,
          associate_approbation: element.uniqueId,
        });

        if (act.data.length > 0) {
          element.actNumber = act.data[0].actNumber;
          element.actUniqueId = act.data[0].uniqueId;
          element.actDate = act.data[0].creationDate;
        }
        element.disabled = true;
      }

      setAssociateApprobations(associateData);
    } catch (err) {
      console.log(err);
      errorHandling(err, handleToggleSimpleDialog);
    } finally {
      setLoading(false);
    }
  }, []);

  const generateNewApprobations = () => {
    setLoading(true);

    const endpoint = generateAssociateApprobations(props.user.token, {
      tickets: selected,
    });
    endpoint
      .then((response) => {
        handleToggleSimpleDialog("success-dialog");
        setAssociateApprobations(response.data);
        setLoading(false);
        let newList = [...tickets]
        for (let element of selected) {
          newList = newList.filter((ticket) => ticket !== element)
        }
        setTickets(newList)
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const createAct = () => {
    setLoading(true);
    let approbations = [];

    for (let item of associateApprobations) {
      const statuses = ['known', 'denied']
      if (statuses.includes(item.status)) {
        approbations.push(item.uniqueId);
      }
    }

    const act = {
      creation_date: new Date().toISOString().split("T")[0],
      associate_approbation: approbations,
      type: "associate_approbation"
    };

    const endpoint = createApprobationAct(props.user.token, act);
    endpoint
      .then((response) => {
        generateNewApprobations()
        handleToggleSimpleDialog("success-dialog");
      })
      .catch((errors) => {
        errorHandling(errors, handleToggleSimpleDialog);
      })
      .finally(() => {
        setLoading(false);

      })

  };


  const handleAutoCompleteChange = (newVal, currRow, row) => {
    const approbation = {
      uniqueId: row.uniqueId,
      responsibleForApproval: currRow.value,
      status: row.status,
    };
    const endpoint = updateAssociateApprobationAct(
      props.user.token,
      approbation
    );
    endpoint
      .then((response) => {
        handleToggleSimpleDialog("success-dialog");
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const removeApprobation = (approbation, that) => {
    const currApprobations = associateApprobations.filter(
      (item) => item.uniqueId !== approbation.uniqueId
    );

    let dataToSend = [...tickets]
    const ticketRemoved = selected.filter((item) => item.uniqueId === approbation.ticketId);

    dataToSend.push(ticketRemoved[0])

    Promise.resolve()
      .then(() => setAssociateApprobations(currApprobations))
      .then(() => handleStatusChange(approbation, 'postpone'))
      .then(() => getTickets(dataToSend))
      .then(() => setSelected([]))
      .then(() => handleToggleSimpleDialog("success-dialog"))

  };


  const handleStatusChange = (row, type, that) => {
    setLoading(true);

    let act = {
      uniqueId: row.uniqueId,
    };
    switch (type) {
      case "known":
        act = {
          ...act,
          status: "known",
        };
        break;
      case "postpone":
        act = {
          ...act,
          status: "postpone",
        };
        break;
      case "denied":
        act = {
          ...act,
          status: "denied",
        };
        break;
      case "diligence":
        act = {
          ...act,
          status: "diligence",
        };
        break;
      case "extra_req":
        act = {
          ...act,
          status: "extra_req",
        };
        break;
      default:
        act = {
          ...act
        };
        break;
    }
    const endpoint = updateAssociateApprobationAct(props.user.token, act);

    endpoint
      .then((response) => {
        handleToggleSimpleDialog("success-dialog");
        if (type !== 'postpone') {
          generateNewApprobations()
        }
      })
      .catch((errors) => {
        errorHandling(errors, handleToggleSimpleDialog);
        console.log(errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };

  const applyFilters = (fields) => {
    let data = fields;
    if (Object.keys(fields).length === 0) {
      data = limitRequest;
    }
    getAssociateApprobations(data);
  };

  return (
    <div style={{ padding: "20px 20px 10% 20px" }}>
      <LoadingDialog open={loading} />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle="Solicitudes de Admisión"
        btnVariant="contained"
      />
      <div
        style={{
          padding: "1%",
          border: "1px solid #EEEEEE",
          borderTop: "none",
        }}
      >
        <Filters
          applyFilters={applyFilters}
          listData={{
            clients: clients,
            tickets: tickets,
            acts: acts,
          }}
        />

        <TicketsTable
          data={tickets}
          selected={selected}
          setSelected={setSelected}
          title={"Tickets"}
          generateApprobations={generateNewApprobations}
        />
        <ApprovementTable
          data={associateApprobations}
          removeApprobation={removeApprobation}
          handleStatusChange={handleStatusChange}
          createAct={createAct}
          handleAutoCompleteChange={handleAutoCompleteChange}
        />
      </div>

      {simpleDialog.name === "confirm-dialog" ? (
        <ConfirmDialogComponent
          open={true}
          data={simpleDialog}
          handleToggle={handleToggleSimpleDialog}
        />
      ) : null}

      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}

      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}

      {simpleDialog.name === "error-dialog" ? (
        <ErrorDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps)(ApprovementAdmissionView);
