import React, {useEffect, useState} from 'react';
import { 
    Grid, Dialog, Typography, Button
} from '@material-ui/core';
// import { Image } from '@material-ui/icons';
import { FileSelection } from './../../../components/SharedComponents/FileSelection';
// import { getFileAttachment } from './../../../utils/axios';

export default function FilesSelection({
    rFields, fields, onChange, errorFields, setLoading, token, handleSimpleDialog, disabled
}){

    const [file, setFile] = useState('');
    const [savedFiles, setSavedFiles] = useState([]);
    const handleRemoveFile = (file) => {
        const currFiles = savedFiles.filter(item => (item.uniqueId !== file.uniqueId) || (item.name !== file.name));
        setSavedFiles([...currFiles]);
        handleSimpleDialog(undefined);
    }
    useEffect(() => {
        if(savedFiles.length) {
            setFile('');
            onChange("file", "files", savedFiles);
        }
    }, [savedFiles]);

    useEffect(() => {
        if(fields.files?.length) {
            setSavedFiles(fields.files);
        }
    }, []);

    return(
        <Grid container style={{marginTop: '1rem'}}>
            <Typography
                variant="subtitle1"
                className="field-label"
            >Archivos</Typography>
            <FileSelection
                xs={12}
                file={file}
                setFile={setFile}
                setSavedFiles={setSavedFiles}
                savedFiles={savedFiles}
                removeFile={handleRemoveFile}
                preShow={true}
                loading={setLoading}
                token={token}
                handleToggleSimpleDialog={handleSimpleDialog}
                multiple
                fileExtensions=".pdf,.png,.jpg,.jpeg"
                inputId="input-file-selection-payment"
                disabled={disabled}
            />
        </Grid>
    )
}