
import { OUTLAY_CASH_TYPES } from '../actions';

export default function(state: IOutlayCash[] = [], action: IAction<IOutlayCash[]>): IOutlayCash[] {
    switch(action.type) {
        case OUTLAY_CASH_TYPES.FETCH_ALL:
            return action.payload || [];
        case OUTLAY_CASH_TYPES.FETCH:
            return state.map(cash => (action.payload !== undefined && cash.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: cash);
        case OUTLAY_CASH_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case OUTLAY_CASH_TYPES.UPDATE:
            return state.map(cash => (action.payload !== undefined && cash.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : cash);
        case OUTLAY_CASH_TYPES.DELETE:
            return state.filter(cash => action.payload !== undefined && cash.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}