import React from 'react';
import NumberFormats from 'react-number-format';

function NumberFormat(props) {
    return(
        <NumberFormats
            // Temp fix, check if value is list, else try value if not value then use empty string
            value={typeof props.value == 'object' ? props.value[0] : (props.value || 0)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={props.prefix}
            suffix={props.suffix}
            decimalScale={typeof props.decimalScale === 'number' ? props.decimalScale : 2}
            fixedDecimalScale={true}
            disabled={props.disabled}
        />
    );
}
export default NumberFormat;