import * as React from 'react';

import Buttom from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import ArrowbackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';

import { titleCaseHandler, translations, RELATIONSHIP } from '../../utils';
import idNumberMaskInput from '../TextMaskInputComponents/idNumberMaskInput';
import passportMaskInput from '../TextMaskInputComponents/passportMaskInput';
import phoneMaskInput from '../TextMaskInputComponents/phoneMaskInput';
import rncMaskInput from '../TextMaskInputComponents/rncMaskInput';
import interestRateInput from '../TextMaskInputComponents/interestRateInput';

const styles:any = createStyles({
    button: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    checkboxLabel: {
        color: green[500],
        fontSize: 'medium'
    },
    formControl: {
        marginTop: 20,
        minWidth: 300,
        width: 300
    },
    root: {
        marginBottom: 5,
        marginTop: 5
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: 500

    }
});

interface ISelectInputProp {
    stakeHolders: IStakeholder[];
    classes: {
        button: string;
        checkboxLabel: string;
        formControl: string;
        root: string;
        textField: string;
    },
    hasProportion?: boolean;
    add(stakeHolder: IStakeholder): void;
    delete(index: number): void
}

interface ISelectInputState {
    [key: string]: string | boolean;

    fullName: string;
    fullNameError: string;
    identificationType: string;
    identificationTypeError: string;
    identificationNumber: string;
    identificationNumberError: string;
    open: boolean;
    phoneNumber: string;
    phoneNumberError: string;
    relationship: string;
    relationshipError: string;
    proportion: string;
    proportionError: string;
}

class SelectInput extends React.Component<ISelectInputProp, ISelectInputState> {
    public state: ISelectInputState = {
        fullName: '',
        fullNameError: '',
        identificationNumber: '',
        identificationNumberError: '',
        identificationType: 'national',
        identificationTypeError: '',
        open: false,
        phoneNumber: '',
        phoneNumberError: '',
        relationship: 'Father',
        relationshipError: '',
        proportion: '',
        proportionError: '',
    };

    constructor(props: ISelectInputProp) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.openDialogHandler = this.openDialogHandler.bind(this);
        this.closeDialogHandler = this.closeDialogHandler.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.resetState = this.resetState.bind(this);
        this.map = this.map.bind(this);
    }
    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                [key]: event.currentTarget.value,
                [key + 'Error']: ''
            });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({
                [key]: event.target.value,
                [key + 'Error']: ''
            });
        }
    }
    public openDialogHandler() {
        this.setState({ open: true });
    }
    public closeDialogHandler() {
        this.setState({ open: false });
    }
    public handleAdd(event: React.MouseEvent<HTMLElement>) {

        let valid: boolean = true;
        const errorMessage = "Este campo no puede estar vacio";

        if (this.state.fullName.trim() === '') {
            valid = false;
            this.setState({ fullNameError: errorMessage });
        }

        if (valid) {
            this.props.add({
                fullName: this.state.fullName,
                full_name: this.state.fullName,
                identificationNumber: this.state.identificationNumber,
                identification_type: this.state.identificationType,
                identification_number: this.state.identificationNumber,
                phoneNumber: this.state.phoneNumber,
                proportion: this.state.proportion,
                relationship: titleCaseHandler(this.state.relationship),
            });

            this.resetState()
        }

    }
    public resetState(){
        this.setState({
            fullName: '',
            fullNameError: '',
            identificationNumber: '',
            identificationNumberError: '',
            identificationType: 'national',
            identificationTypeError: '',
            open: false,
            phoneNumber: '',
            phoneNumberError: '',
            relationship: 'Father',
            relationshipError: '',
            proportion: '',
            proportionError: '',
        })
    }
    public handleDelete(index: number) {
        return (event: React.MouseEvent<HTMLElement>) => {
            this.props.delete(index);
        }
    }
    public map(stakeHolder: IStakeholder, index: number) {
        return (
            <TableRow key={index}>
               <TableCell>{stakeHolder.fullName || stakeHolder.full_name || '-----'}</TableCell>
                <TableCell>{stakeHolder.identificationNumber || stakeHolder.identification_number || '-----'}</TableCell>
                {this.props.hasProportion &&
                    <TableCell>{stakeHolder.proportion}</TableCell>}
                <TableCell>
                    <IconButton onClick={this.handleDelete(index)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
    public getIdentificationInput(identificationType: string) {
        switch (identificationType) {
            case 'national':
                return {
                    inputComponent: idNumberMaskInput
                }
            case 'RNC':
                return {
                    inputComponent: rncMaskInput
                }
            case 'passport':
                return {
                    inputComponent: passportMaskInput,
                };
            default:
                return {
                    title: ''
                };
        }
    }

    public render() {
        const { classes, stakeHolders } = this.props;
        const { open } = this.state;

        return (
            <div className={classes.root}>
                <Dialog open={open} onClose={this.closeDialogHandler} >
                    <DialogActions>
                        <IconButton className={classes.button} onClick={this.closeDialogHandler}>
                            <ArrowbackIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent >
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="identificationType"
                            style={styles.checkboxLabel} 
                            shrink={true}>{translations.IDENTIFICATION_TYPE}</InputLabel>
                            <Select
                                value={this.state.identificationType}
                                onChange={this.handleSelectChange('identificationType')}
                                required={true}
                                native={true}
                                error={this.state.identificationTypeError !== ''}
                                inputProps={{
                                    id: 'identificationType',
                                    name: 'identificationType'
                                }}
                            >
                                <option value="national">{translations.NATIONAL}</option>
                                <option value="passport">{translations.PASSPORT}</option>
                                <option value="RNC">{translations.RNC}</option>
                            </Select>
                        </FormControl>
                        <TextField
                            label={translations.IDENTIFICATION}
                            className={classes.textField}
                            value={this.state.identificationNumber}
                            onChange={this.handleChange('identificationNumber')}
                            fullWidth={true}
                            error={this.state.identificationNumberError !== ''}
                            helperText={this.state.identificationNumberError}
                            InputProps={this.getIdentificationInput(this.state.identificationType)}
                            InputLabelProps={{
                                shrink: true,
                                style: styles.checkboxLabel
                            }}
                        />
                        <TextField
                            label={translations.NAME}
                            className={classes.textField}
                            value={this.state.fullName}
                            onChange={this.handleChange('fullName')}
                            error={this.state.fullNameError !== ''}
                            fullWidth={true}
                            helperText={this.state.fullNameError}
                            required={true}
                            InputLabelProps={{ style: styles.checkboxLabel }}
                        />
                        <TextField
                            label={translations.PHONE}
                            className={classes.textField}
                            value={this.state.phoneNumber}
                            type="phone"
                            placeholder="+1(809) 000-0000"
                            onChange={this.handleChange('phoneNumber')}
                            error={this.state.phoneNumberError !== ''}
                            fullWidth={true}
                            helperText={this.state.phoneNumberError}
                            InputProps={{
                                inputComponent: phoneMaskInput
                            }}
                            InputLabelProps={{ style: styles.checkboxLabel }}
                        />
                        <FormControl style={{ width: 'fit-content', marginTop: '35px' }} className={classes.formControl} error={this.state.relationshipError !== ''}>
                                <InputLabel style={styles.checkboxLabel}  htmlFor="relationship" >{translations.RELATIONSHIP}</InputLabel>
                                <Select
                                    value={this.state.relationship}
                                    onChange={this.handleSelectChange('relationship')}
                                    required={true}
                                    native={true}
                                    inputProps={{
                                        id: 'relationship',
                                        name: 'relationship',
                                    }}
                                >
                                    {RELATIONSHIP.map((opt: any, index: any) => {
                                         return (
                                            <option key={index} value={opt.value}>{opt.name}</option>)
                                    })}

                                </Select>
                            </FormControl>
                            <br />
                        {this.props.hasProportion &&
                            <>
                                <TextField
                                    label={translations.PROPORTION}
                                    className={classes.textField}
                                    value={this.state.proportion}
                                    onChange={this.handleChange('proportion')}
                                    error={this.state.proportion !== ''}
                                    fullWidth={true}
                                    helperText={this.state.proportionError}
                                    required={true}
                                InputProps={{inputComponent: interestRateInput}}
                                    InputLabelProps={{ style: styles.checkboxLabel }}
                                />
                                <br />
                            </>
                        }
                        <br/>
                        <Buttom onClick={this.handleAdd} className={classes.button}>
                            {translations.SAVE}
                        </Buttom>
                    </DialogContent>
                </Dialog>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{translations.NAME}</TableCell>
                                <TableCell>{translations.IDENTIFICATION}</TableCell>
                                {this.props.hasProportion &&
                                    <TableCell>{translations.PROPORTION}</TableCell>}
                                <TableCell>
                                    <IconButton className={classes.button} onClick={this.openDialogHandler}>
                                        <AddIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stakeHolders.map(this.map)}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(SelectInput); 
