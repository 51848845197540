
import { ADDENDUM_CONTRACTS_TYPES } from '../actions';

export default function(state: IAddendumContract[] = [], action: IAction<IAddendumContract[]>): IAddendumContract[] {
    switch(action.type) {
        case ADDENDUM_CONTRACTS_TYPES.FETCH_ALL:
            return action.payload || [];
        case ADDENDUM_CONTRACTS_TYPES.FETCH:
            return state.map(addendumContract => (action.payload !== undefined && addendumContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: addendumContract);
        case ADDENDUM_CONTRACTS_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case ADDENDUM_CONTRACTS_TYPES.UPDATE:
            return state.map(addendumContract => (action.payload !== undefined && addendumContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : addendumContract);
        case ADDENDUM_CONTRACTS_TYPES.DELETE:
            return state.filter(addendumContract => action.payload !== undefined && addendumContract.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}