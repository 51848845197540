import { WARRANTY_TYPES } from '../actions';

const initialState: IWarranty[] = []; 
 
export default function(state: IWarranty[] = initialState, action: IAction<IWarranty[]>): IWarranty[] {
    switch(action.type) {
        case WARRANTY_TYPES.FETCH_ALL:
            return action.payload || [];
        case WARRANTY_TYPES.FETCH:
            return state.map(w =>(action.payload !== undefined && w.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: w);
        case WARRANTY_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case WARRANTY_TYPES.UPDATE:
            return state.map(w => (action.payload !== undefined && w.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : w);
        case WARRANTY_TYPES.DELETE:
            return state.filter( w => action.payload !== undefined && w.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}

// need to fix FETCH AND update