import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles:any = createStyles({
    circularProgress: {
        color: green [500]
    },
    root: {
        textAlign: 'center'
    }
});
interface ILoadingProps {
    classes: {
        circularProgress: string;
        root: string;
    };
    message: string;
}
const Loading = (props: ILoadingProps) => {
    const { classes, message } = props;    
    return (
        <div className={classes.root}>
            <CircularProgress className={classes.circularProgress} thickness={7} />
            <Typography variant="h4" color="inherit">{message}</Typography>
        </div>
    );
}

export default withStyles(styles)(Loading);