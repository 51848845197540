import * as React from 'react';
import green from '@material-ui/core/colors/green';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { fetchParticipants as fetchP, fetchCreditors as fetchC } from '../../utils/axios';

import { connect } from "react-redux";

const styles: any = createStyles({
    label: {
        color: green[500]
    }
});

interface IAutoCompleteProps {
    user: any;
    type: string;
    label: string;
    value: any;
    classes: {
        [key: string]: string;
    }
    handleSelectChange(e: any, newValue: any): void;
}

interface IAutocompleteState {
    [key: string]: any;
    participants: any;
}

class ParticipantAutoComplete extends React.Component<IAutoCompleteProps, IAutocompleteState>{
    public state: IAutocompleteState = {
        participants: [],
    };

    public componentDidMount() {

        this.props.type === "creditor" ? fetchC({ token: this.props.user.token, limit: 20, offset: 0 }).then((res: any) => {
            this.setState({ participants: res.data.results })
        }).catch((err: any) => {
            console.log(err);
        })
            : this.props.type === "attorney" ? fetchP({ token: this.props.user.token, limit: 20, offset: 0, participantType: 'attorney' }).then((res: any) => {
                this.setState({ participants: res.data.results })
            }).catch((err: any) => {
                console.log(err);
            })
                : fetchP({  participantType: "client", token: this.props.user.token, limit: 20, offset: 0 }).then((res: any) => {
                    this.setState({ participants: res.data.results })
                }).catch((err: any) => {
                    console.log(err);
                })
    }

    public render() {
        const filterOptions = createFilterOptions(
            this.props.type === "creditor" ? {
                stringify: (option: any) => option.participantDetail.fullName! + option.participantDetail.identificationNumber!,
            } : {
                stringify: (option: IParticipant) => option.fullName! + option.identificationNumber!,
            }
        );

        return (
            <Autocomplete
                id="filter-demo"
                multiple={true}
                onChange={this.props.handleSelectChange}
                options={this.state.participants}
                getOptionLabel={this.props.type === 'creditor' ? (option) => `${option.participantDetail.fullName} - ${option.participantDetail.identificationNumber}` : (option) => `${option.fullName} - ${option.identificationNumber}`}
                filterOptions={filterOptions}
                fullWidth={true}
                value={this.props.value}
                renderInput={(params) => <TextField {...params} label={this.props.label}
                    InputLabelProps={{
                        style: styles.label,
                        shrink: true,
                    }} />}
            />
        )
    }
}

function mapStateToProps(state: IState) {
    return {
        user: state.user,
    };
}

export default withStyles(styles)(connect(mapStateToProps)(ParticipantAutoComplete));
