import React, { useState } from 'react';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import { AccountSelectionTable } from './../Tables/index'
import { AutocompleteField } from './../../../components/SharedComponents/Fields'


function AccountSelectionHeader(props) {
    const enterprises = 
        props.data.map(({enterprise_name, enterprise_id}) => ({enterprise_name, enterprise_id}));

    return(
        <Grid container justify="space-between" style={{marginBottom:'1%'}}>
            <Grid container item xs={4}>
                <Card style={{width:'100%'}}>
                    <CardContent>
                        <AutocompleteField
                            xs={12}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="h6"
                            typeClass="field-label"
                            fieldLabel="Instituciones"
                            fieldID="account-selection-field"
                            fieldInnerLabel="Selección de institución"
                            fieldVariant="outlined"
                            handleChange={props.handleEnterpriseChange}
                            options={enterprises}
                            getOptionLabel={(option) => option.enterprise_name}
                            getOptionSelected={(option, value) => option.enterprise_id === value.enterprise_id}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid container item xs={4} justify="flex-end">
                <Card style={{width:'70%'}}>
                    <CardContent style={{marginTop:'5px'}}>
                        <Typography
                            variant="h5"
                            style={{color:'#424242'}}
                        >
                            ${!props.balance ? '0.00' : props.balance}<br/>
                            <Typography 
                                variant="overline"
                                style={{color:'#616161', fontSize:'16px'}}
                            >Balance total</Typography>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

function AccountSelection(props) {

    const [selectedData, setEnterpriseData] = useState([]);
    const handleEnterpriseChange = (event, value) => {
        const val = value ? value : [];
        const data = props.accountsData.find(item => item.enterprise_id === val.enterprise_id);
        setEnterpriseData(data ? data : []);
    }
    return(
        <div>
            <AccountSelectionHeader 
                data={props.accountsData}
                balance={selectedData.total_balance || 0}
                handleEnterpriseChange={handleEnterpriseChange}
            />
            <AccountSelectionTable 
                data={selectedData.bank_accounts || []}
                handleShowDetail={props.handleShowDetail}
            />
        </div>
    )
}

export { AccountSelection }