import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";

import { fetchDepositBankAccounts } from "../../../actions";
import {
  fetchDepositBankAccounts as fetchDBA,
  fetchDepositBankAccount,
} from "../../../utils/axios";
import DepositBankAccountForm from "../DepositBankAccountForm";
import DepositBankAccountSearchSection from "../DepositBankAccountSearchSection";

const styles: any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

interface IFindDepositBankAccountsProps {
  token: string;
  depositAccountId: string;
  functionProps: any;
}

const FindDepositBankAccounts: React.FC<IFindDepositBankAccountsProps> = ({
  token,
  depositAccountId,
  functionProps,
}) => {
  const { handleDeleteItem } = functionProps;
  const [curDepositAccount, setCurDepositAccount] = React.useState({
    uniqueId: "",
    name: "",
    number: "",
  });

  React.useEffect(() => {
    if (depositAccountId) {
      fetchDepositBankAccount(token, depositAccountId)
        .then((res) => {
          setCurDepositAccount(res.data.results ? res.data.results : res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return curDepositAccount && depositAccountId ? (
    <TableRow key={curDepositAccount.uniqueId}>
      <TableCell>{curDepositAccount.name}</TableCell>
      <TableCell>{curDepositAccount.number}</TableCell>
      <TableCell>
        <IconButton
          onClick={handleDeleteItem(
            curDepositAccount.uniqueId ? curDepositAccount.uniqueId : ""
          )}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ) : null;
};
interface IDepositBankAccountSelectInputProps {
  classes: {
    button: string;
    root: string;
  };

  user: IUser;
  depositBankAccounts: IDepositBankAccount[];
  selectedDepositBankAccountsId: string[];
  depositBankAccountFilter?: string;
  addDepositBankAccount(depositBankAccountId: string): void;
  deleteDepositBankAccount(depositBankAccountId: string): void;
  fetchDepositBankAccounts(
    depositBankAccounts: IDepositBankAccount[]
  ): IAction<IDepositBankAccount[]>;
}
interface IDepositBankAccountSelectInputState {
  depositBankAccounts: IDepositBankAccount[];
  formDialog: boolean;
  open: boolean;
  loading: boolean;
  isMounted: boolean;
}

class DepositBankAccountSelectInput extends React.Component<
  IDepositBankAccountSelectInputProps,
  IDepositBankAccountSelectInputState
> {
  public state: IDepositBankAccountSelectInputState = {
    depositBankAccounts: [],
    formDialog: false,
    isMounted: false,
    loading: false,
    open: false,
  };
  constructor(props: IDepositBankAccountSelectInputProps) {
    super(props);

    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  public componentWillUnmount() {
    this.setState({ isMounted: false });
  }
  public componentDidMount() {
    this.setState({ loading: true, isMounted: true });

    fetchDBA({
      token: this.props.user.token,
      accountType: this.props.depositBankAccountFilter,
      limit: 20,
      offset: 0,
    })
      .then((res) => {
        this.props.fetchDepositBankAccounts(
          res.data.results ? res.data.results : res.data
        );

        if (this.state.isMounted) {
          this.setState({
            depositBankAccounts: (res.data.results
              ? res.data.results
              : res.data) as IDepositBankAccount[],
            loading: false,
          });
        }
      })
      .catch((err) => {
        if (this.state.isMounted) {
          catchError(err, this);
        }
      });
  }
  public openDialogHandler() {
    this.setState({ open: true });
  }
  public closeDialogHandler() {
    this.setState({ open: false });
  }
  public openFormDialog() {
    this.setState({ formDialog: true });
  }
  public closeFormDialog() {
    this.setState({ formDialog: false });
  }
  public emptyFunction() {
    return null;
  }
  public handleItemClick(id: string) {
    this.setState({ loading: true });
    fetchDBA({
      token: this.props.user.token,
      accountType: this.props.depositBankAccountFilter,
      limit: 20,
      offset: 0,
    })
      .then((res) => {
        this.setState(
          {
            depositBankAccounts: (res.data.results
              ? res.data.results
              : res.data) as IDepositBankAccount[],
            loading: false,
            open: false,
          },
          () => {
            this.props.addDepositBankAccount(id);
          }
        );
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.props.deleteDepositBankAccount(id);
    };
  }

  public fetchData(params: IFetchDepositBankAccountParams) {
    params.limit = 20;
    params.offset = 0;

    if (this.props.depositBankAccountFilter) {
      params.accountType = this.props.depositBankAccountFilter;
    }

    this.setState({ loading: true });
    fetchDBA(params)
      .then((res) => {
        this.props.fetchDepositBankAccounts(
          (res.data.results
            ? res.data.results
            : res.data) as IDepositBankAccount[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public render() {
    const { classes } = this.props;
    const { formDialog, open, loading } = this.state;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={this.closeDialogHandler}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.openFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: 600, width: 280 }}>
            <DepositBankAccountSearchSection
              loading={loading}
              handleClick={this.handleItemClick}
              fetchData={this.fetchData}
              pagination={false}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={formDialog}
          fullScreen={true}
          onClose={this.closeFormDialog}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <DepositBankAccountForm changeSection={this.emptyFunction} />
          </DialogContent>
        </Dialog>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.NAME}</TableCell>
                <TableCell>{translations.ACCOUNT_NUMBER}</TableCell>
                <TableCell>
                  <IconButton onClick={this.openDialogHandler}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.selectedDepositBankAccountsId.map(
                (depositAccountId) => (
                  <FindDepositBankAccounts
                    key={depositAccountId}
                    token={this.props.user.token}
                    depositAccountId={depositAccountId}
                    functionProps={{
                      handleDeleteItem: this.handleDeleteItem,
                    }}
                  />
                )
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    depositBankAccounts: state.depositBankAccounts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchDepositBankAccounts })(
    DepositBankAccountSelectInput
  )
);
