import React from 'react';

import Backdrop from '../Backdrop';
import './Modal.css';

// children -> JSX: The element that is going to be inserted inside the modal
// onCloseModal -> function: A function that is called when the modal is closed (also is responsible to show/close the modal)
// alertOnClose -> Boolean[optional]: if true, a confirm alert will appear efore closing the modal, false by default 
const Modal = ({children, onCloseModal, alertOnClose=false}) => {
    const close = ()=> alertOnClose ? (
            window.confirm("Are you sure you want to close?") ? onCloseModal() : null
        ) : onCloseModal();
    
    return (
        <div>
            <Backdrop onCloseModal={close}/>
            <div className="modal-container">
                {children}
            </div>
        </div>
    );
}

export default Modal;