import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  IconButton,
  Divider,
  Paper,
  TableContainer,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { ConfirmDialog } from "./../../../components/SharedComponents/Dialogs";
import { green } from "@material-ui/core/colors";
import { TextFieldComponent } from "../../../components/SharedComponents/Fields";
import { CollapsibleTable } from "../Tables/inflex";
import { DetailsFilters } from "../Filters";

function ConfirmDialogComponent(props) {
  const data = props.data.data;
  let dialogConfirmContent = "";
  switch (props.data.msg) {
    case "file-upload":
      dialogConfirmContent = "¿Desea subir el archivo seleccionado?";
      break;
    case "details":
      dialogConfirmContent = `${props.msg}`;
      break;
    case "proceed-delete":
      dialogConfirmContent = "¿Esta seguro que desea eliminar esta evidencia?";
      break;
    case "delete":
      dialogConfirmContent = "¿Esta seguro que desea eliminar esta registro?";
      break;
    default:
      dialogConfirmContent = "...";
  }
  return (
    <ConfirmDialog
      open={props.open}
      handleToggle={props.handleToggle}
      confirmCallback={data.callback}
      data={dialogConfirmContent}
    />
  );
}

function DetailsDialog(props) {
  const [detailsData, setDetailsData] = useState(props.detailsData);

  const setCurrency = (value) => {
    let data = parseFloat(value).toFixed(2);
    return Number(data).toLocaleString("en-US", {
      style: "currency",
      currency: "DOP",
    });
  };

  const columnsSavingAccountDateils = [
    {
      id: "collapse",
      label: "",
      minWidth: 20,
      align: "center",
    },
    {
      id: "transactionNumber",
      label: "Numero transaction".toLocaleUpperCase(),
      minWidth: 100,
      align: "center",
    },
    {
      id: "transactionDate",
      label: "Fecha transacción".toLocaleUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "startingBalance",
      label: "Balance inicial a la fecha".toLocaleUpperCase(),
      type: "number",
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.startingBalance),
    },
    {
      id: "credit",
      label: "Credito".toLocaleUpperCase(),
      type: "number",
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.credit),
    },
    {
      id: "debit",
      label: "Debito".toLocaleUpperCase(),
      type: "number",
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.debit),
    },
    {
      id: "endingBalance",
      label: "Balance final a la fecha".toLocaleUpperCase(),
      type: "number",
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.endingBalance),
    },
    {
      id: "concept",
      label: "Concepto".toLocaleUpperCase(),
      type: "number",
      minWidth: 100,
      align: "center",
    },
  ];

  const columnsSavingAccountCollapse = [
    {
      id: "posting_date",
      label: "Fecha poste".toLocaleUpperCase(),
      minWidth: 100,
      align: "center",
    },
    {
      id: "receipt_number",
      label: "Número recibo".toLocaleUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "accounting_entry",
      label: "Asiento contable".toLocaleUpperCase(),
      minWidth: 100,
      align: "center",
    },
    {
      id: "liquidation_form",
      label: "Forma liquidacion".toLocaleUpperCase(),
      minWidth: 100,
      align: "center",
    },
    {
      id: "comments",
      label: "Comentario".toLocaleUpperCase(),
      width: 500,
      align: "center",
    },
  ];

  useEffect(() => {
    props.getDetail(props.modalData);
  }, []);
  useEffect(() => {
    if (props.detailsData) {
      setDetailsData(props.detailsData);
    }
  }, [props.detailsData]);

  return (
    <Dialog
      fullScreen={true}
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={props.open}
    >
      <DialogTitle style={{ backgroundColor: green[500] }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography
            component={"span"}
            variant="h5"
            className="dialog-title"
            style={{ color: "white" }}
          >
            {props.client.fullName}
          </Typography>
          <IconButton onClick={props.handleToggleDialog.bind(this, undefined)}>
            <CloseOutlined className="dialog-header-close-btn" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ position: "relative" }}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-title">DETALLE DE LA CUENTA</h3>
            </Grid>
            <Divider />
            {props.fields.map((item, index) => {
              return (
                <TextFieldComponent
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel={item.label}
                  fieldID="requestNumber"
                  fieldClass="fields"
                  fieldVariant="outlined"
                  margin="dense"
                  fullWidth
                  value={item.value}
                  disabled={true}
                />
              );
            })}
          </Grid>

          <Paper>
            <div style={{ width: "100%", marginTop: 60 }}>
              <DetailsFilters
                handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                detailsData={props.detailsData}
                setDetailsData={setDetailsData}
              />

              <TableContainer style={{ width: "100%" }}>
                <CollapsibleTable
                  columns={columnsSavingAccountDateils}
                  columnsCollapse={columnsSavingAccountCollapse}
                  data={detailsData}
                />
              </TableContainer>
            </div>
          </Paper>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export { ConfirmDialogComponent, DetailsDialog };
