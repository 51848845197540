import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Fab from "@material-ui/core/Fab";
import { createStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import { fetchPublicInstitutions } from "../../../actions";
import DefaultSection from "../../../components/DefaultSection";
import Loading from "../../../components/Loading";
import { fetchInstitutions } from "../../../utils/axios";
import PublicInstitutionForm from "../PublicInstitutionForm";
import PublicInstitutionSearchSection from "../PublicInstitutionSearchSection";
import PublicInstitutionView from "../PublicInstitutionView";

const styles: any = createStyles({
  addButton: {
    backgroundColor: green[500],
    bottom: 10,
    color: "white",
    position: "fixed",
    right: 10,
  },
  rightSide: {
    alignItems: "center",
    border: "1px solid transparent",
    boxSizing: "border-box",
    display: "block",
    height: "100%",
    justifyContent: "center",
    marginLeft: 280,
    position: "relative",
  },
  root: {
    boxSizing: "border-box",
    height: "100%",
    margin: 0,
    padding: 0,
  },
});

interface IInstitutionSectionProps {
  classes: {
    addButton: string;
    rightSide: string;
    root: string;
  };

  institutions: IInstitution[];
  user: IUser;
  fetchPublicInstitutions(
    institutions: IInstitution[]
  ): IAction<IInstitution[]>;
}
interface IInstitutionSectionState {
  loading: boolean;
  selectedInstitutionId: string | undefined;
  selectedSection: number;
}
class PublicInstitutionSection extends React.Component<
  IInstitutionSectionProps,
  IInstitutionSectionState
> {
  public state: IInstitutionSectionState = {
    loading: false,
    selectedInstitutionId: undefined,
    selectedSection: 0,
  };
  constructor(props: IInstitutionSectionProps) {
    super(props);

    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  public componentDidMount() {
    this.setState({ loading: true });
    fetchInstitutions({
      token: this.props.user.token,
      limit: 20,
      offset: 0,
      institutionType: "public",
    })
      .then((res) => {
        this.props.fetchPublicInstitutions(
          (res.data.results ? res.data.results : res.data) as IInstitution[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleChangeSection(sectionNumber: number) {
    this.setState({ selectedSection: sectionNumber });
  }
  public handleClickItem(institutionId: string) {
    this.setState(
      {
        selectedInstitutionId: institutionId,
        selectedSection: 1,
      },
      () => {
        this.setState({ selectedSection: 2 });
      }
    );
  }
  public handleAddClick() {
    this.setState({
      selectedSection: 3,
    });
  }
  public fetchData(params: IFetchInstitutionParams) {
    this.setState({ loading: true });
    fetchInstitutions(params)
      .then((res) => {
        this.props.fetchPublicInstitutions(
          (res.data.results ? res.data.results : res.data) as IInstitution[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public render() {
    const { classes } = this.props;
    const { loading, selectedInstitutionId, selectedSection } = this.state;
    return (
      <div className={classes.root}>
        <PublicInstitutionSearchSection
          loading={loading}
          handleClick={this.handleClickItem}
          fetchData={this.fetchData}
          pagination={true}
        />
        <div className={classes.rightSide}>
          {selectedSection === 0 ? (
            <DefaultSection
              message={translations.INSTITUTION_DEFAULT_SECTION_MESSAGE}
              key={0}
            />
          ) : null}
          {selectedSection === 1 ? (
            <Loading message={translations.LOADING} key={1} />
          ) : null}
          {selectedSection === 2 ? (
            <PublicInstitutionView
              changeSection={this.handleChangeSection}
              institutionId={selectedInstitutionId ? selectedInstitutionId : ""}
            />
          ) : null}
          {selectedSection === 3 ? (
            <PublicInstitutionForm changeSection={this.handleChangeSection} />
          ) : null}
          {selectedSection !== 3 ? (
            <Fab className={classes.addButton} onClick={this.handleAddClick}>
              <AddIcon />
            </Fab>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    institutions: state.publicInstitutions,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchPublicInstitutions })(
    PublicInstitutionSection
  )
);
