import React from "react";

import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import moneyInput from '../../../components/TextMaskInputComponents/moneyInput'
import { TableRow, TableCell, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";


class InlineCreateForm extends React.Component {
  constructor(props) {
    super(props)
    this.pProps = this.props.table
    this.state = {
      account: "",
      credit: 0,
      debit: 0,
      thirdMember: "",
    }
    this.initialState = { ...this.state }

    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.addTableRow = this.addTableRow.bind(this);
  }

  handleCurrencyChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || '' });
    }
  }

  handleAutocompleteChange(key, obj) {
    /* Handle the state of Autocomplete components */
    if (obj) {
      this.setState({
        [key]: obj.value
      });
    }
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleSelectChange(key) {
    return (event) => {
      this.setState({
        [key]: event.target.value,
      });
    };
  }

  handleReset() {
    this.setState({ ...this.initialState })
  }

  addTableRow(values) {
    const table = this.props.table
    table.data.push(values)
    table.handleSave(table.data)
    this.handleReset()
  }

  render() {
    const pProps = this.props.table
    const { style } = this.props

    return (
      <TableRow style={{ textAlignLast: 'center' }}>
        <TableCell style={style.TableCell}>{pProps.data.length + 1}</TableCell>
        {pProps.header.map((y, k) => {
          return (
            <TableCell style={style.TableCell} key={k}>
              {y.type === 'select' ?
                <Select
                  disabled={y.disabled}
                  onChange={this.handleSelectChange(y.prop)}
                  value={this.state[y.prop]}
                  native={true}
                  inputProps={{
                    id: y.prop,
                    name: y.prop,
                  }}
                >
                  <option defaultValue="" >---Elija una opcion---</option>
                  {y.options.map((opt, indx) => {
                    return <option key={indx} value={opt.value}>{opt.label || opt.name}</option>
                  })}
                </Select>
                :
                y.type === 'number' ?
                  <TextField
                    disabled={y.disabled}
                    placeholder={y.name}
                    name={y.prop}
                    onChange={this.handleCurrencyChange(y.prop)}
                    value={this.state[y.prop]}
                    InputProps={{ inputComponent: moneyInput }} />
                  : y.type === 'auto' ?
                    <Autocomplete
                      id={y.prop}
                      options={y.options}
                      getOptionLabel={(options) => options.fullName}
                      onChange={(e, value) => this.handleAutocompleteChange(y.prop, value)}
                      renderInput={(params) => <TextField {...params} value={this.state[y.prop]} />} />
                    :
                    <TextField
                      disabled={y.disabled}
                      placeholder={y.name}
                      type={y.type}
                      name={y.prop}
                      onChange={this.handleChange(y.prop)}
                      value={this.state[y.prop]} />
              }
            </TableCell>
          )
        })}
        <TableCell style={style.TableCell}>
          <IconButton onClick={() =>  this.addTableRow(this.state) }>
            <AddIcon />
          </IconButton>
        </TableCell>
      </TableRow >
    )
  }
}
export default InlineCreateForm;
