import React from 'react';
import {
    Popover, Button, Grid, Typography, IconButton
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { AutocompleteField, TextFieldComponent, DateField } from './../../../components/SharedComponents/Fields';

const movementStates = ["En tránsito", "Conciliado", "Pendiente por conciliar"];

function SetFiltersFields(props) {
    return(
        <Grid container direction="column">
                {props.filterFields.includes("reference") ? 
                    <TextFieldComponent 
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Referencia"
                        fieldID="filter-reference-field"
                        fieldClass="fields text-field-info"
                        placeholder="ej: 111111111"
                        fullWidth
                        value={props.filterFieldsData.referenceField}
                        onChange={props.handleFilterSelected.bind(this, "reference")}
                    /> : ''
                }
                {props.filterFields.includes("status") ? 
                    <AutocompleteField 
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Estado"
                        fieldID="filter-status-field"
                        fieldInnerLabel="Estado de transacción"
                        fieldVariant="outlined"
                        value={props.filterFieldsData.statusField}
                        handleChange={props.handleFilterSelected.bind(this, "status")}
                        options={movementStates}
                        multiple={true}
                        limitTags={1}
                    />: ''
                }
                {props.filterFields.includes("date") ? 
                    <div style={{width:'100%'}}>
                        <DateField 
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Fecha inicio"
                            dateFieldID="filter-date-start"
                            fullWidth={true}
                            dateFieldClass="date-field"
                            inputVariant="outlined"
                            dateFieldLabel="Fecha desde"
                            format="dd/MM/yyyy"
                            clearable={true}
                            value={props.filterFieldsData.startDateField}
                            handleChange={props.handleFilterSelected.bind(this, "startDate")}
                        />
                        <DateField 
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Fecha corte"
                            dateFieldID="filter-date-end"
                            fullWidth={true}
                            dateFieldClass="date-field"
                            inputVariant="outlined"
                            dateFieldLabel="Fecha hasta"
                            format="dd/MM/yyyy"
                            clearable={true}
                            value={props.filterFieldsData.endDateField}
                            handleChange={props.handleFilterSelected.bind(this, "endDate")}
                        /> 
                    </div> : ''
                }
                {props.filterFields.includes("amount") ?
                    <TextFieldComponent 
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Monto"
                        fieldID="filter-amount-field"
                        fieldClass="fields text-field-info"
                        placeholder="ej: 10,723.49"
                        fullWidth
                        value={props.filterFieldsData.amountField}
                        onChange={props.handleFilterSelected.bind(this, "amount")}
                    /> : ''
                }
            {
                props.filterFields.length < 1 ?
                    <Typography
                        variant="subtitle1"
                        style={{fontWeight: 'bold'}}
                    >
                        Filtros no disponibles.
                    </Typography>
                : ''
            }
        </Grid>
    )
}

function Filter(props) {
    return(
        <Popover
            // style={{width:'70%'}}
            id={'filter-popover'}
            open={props.openFilter}
            anchorEl={props.anchorEl}
            onClose={props.handleToggleFilter}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Grid container style={{padding:'15px 24px'}}>
                <Grid item container justify="space-between" alignItems="center">
                    <Typography 
                        variant="h6"
                        style={{color:'#424242', marginBottom:15}}
                    >
                        Filtros
                    </Typography>
                    <IconButton onClick={props.handleToggleFilter}>
                        <CloseOutlined style={{color:'#424242'}} />
                    </IconButton>
                </Grid>
                <SetFiltersFields 
                    filterFields={props.filterFields}
                    handleFilterSelected={props.handleFilterSelected}
                    filterFieldsData={props.filterFieldsData}
                />
                <Grid container justify="space-between" style={{margin:'25px 0 15px 0'}}>
                    <Button
                        variant="outlined"
                        disableElevation
                        style={{color:'#E53935', borderColor:'#E53935'}}
                        onClick={props.clearFilters}
                    >
                        limpiar
                    </Button>

                    <Button
                        variant="contained"
                        disableElevation
                        style={{backgroundColor:'#1E88E5', color:'#FFFFFF'}}
                        onClick={props.applyFilters}
                    >
                        aplicar
                    </Button>
                </Grid>
            </Grid>
        </Popover>
    )
}

export { Filter }