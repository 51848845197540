
import { DEBIT_AUTHORIZATION_CONTRACTS_TYPES } from '../actions';

export default function(state: IDebitAuthorizationContract[] = [], action: IAction<IDebitAuthorizationContract[]>): IDebitAuthorizationContract[] {
    switch(action.type) {
        case DEBIT_AUTHORIZATION_CONTRACTS_TYPES.FETCH_ALL:
            return action.payload || [];
        case DEBIT_AUTHORIZATION_CONTRACTS_TYPES.FETCH:
            return state.map(debitAuthorizationContract => (action.payload !== undefined && debitAuthorizationContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: debitAuthorizationContract);
        case DEBIT_AUTHORIZATION_CONTRACTS_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case DEBIT_AUTHORIZATION_CONTRACTS_TYPES.UPDATE:
            return state.map(debitAuthorizationContract => (action.payload !== undefined && debitAuthorizationContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : debitAuthorizationContract);
        case DEBIT_AUTHORIZATION_CONTRACTS_TYPES.DELETE:
            return state.filter(debitAuthorizationContract => action.payload !== undefined && debitAuthorizationContract.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}