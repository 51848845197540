import React from "react";

import { Select, TableCell, TableRow } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moneyInput from "../../TextMaskInputComponents/moneyInput";
import moneyInputCustom from "../../TextMaskInputComponents/moneyInputCustom";

class InlineCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.pProps = this.props.table;
    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.addTableRow = this.addTableRow.bind(this);
  }

  componentDidMount() {
    const iState = this.pProps.header.map((x) => x.prop);

    this.setState((s) => {
      for (let item of iState) {
        s[item] = "";
      }
      return s;
    });
  }

  handleCurrencyChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || 0 });
    };
  }

  handleAutocompleteChange(key, obj) {
    /* Handle the state of Autocomplete components */
    if (obj) {
      this.setState({
        [key]: obj.value,
      });
    }
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleSelectChange(key) {
    return (event) => {
      this.setState({
        [key]: event.target.value,
      });
    };
  }

  addTableRow(values) {
    const table = this.props.table;
    const from = values.from;
    const to = values.to;

    if (
      table.calculatorType === "service_order" &&
      values.interestRate === ""
    ) {
      if (from === "" && to === 3) {
        table.data.push({ ...values, from: 0, interestRate: 1 });
      } else if (from === 4 && to === 6) {
        table.data.push({ ...values, interestRate: 2 });
      } else if (from === 7 && to === 12) {
        table.data.push({ ...values, interestRate: 3 });
      } else if (from === 13 && to === 18) {
        table.data.push({ ...values, interestRate: 4 });
      } else if (from === 19 && to === 24) {
        table.data.push({ ...values, interestRate: 5 });
      } else if (from === "") {
        table.data.push({ ...values, from: 0 });
      } else {
        table.data.push(values);
      }
      table.handleSave(table.data);
    } else {
      if (table.calculatorType === "service_order" && from === "") {
        table.data.push({ ...values, from: 0 });
        table.handleSave(table.data);
      } else {
        table.data.push(values);
        table.handleSave(table.data);
      }
    }
  }

  render() {
    const pProps = this.props.table;
    const { style } = this.props;

    return (
      <TableRow style={{ textAlignLast: "center" }}>
        <TableCell style={style.TableCell}>{pProps.data.length + 1}</TableCell>
        {pProps.header.map((y, k) => {
          return (
            <TableCell style={style.TableCell} key={k}>
              {y.type === "select" ? (
                <Select
                  disabled={y.disabled}
                  onChange={this.handleSelectChange(y.prop)}
                  value={this.state[y.prop]}
                  native={true}
                  required={y.required}
                  inputProps={{
                    id: y.prop,
                    name: y.prop,
                  }}
                >
                  <option value="">---Elija una opcion---</option>
                  {y.options.map((opt, indx) => {
                    return (
                      <option key={indx} value={opt.value}>
                        {opt.label || opt.name}
                      </option>
                    );
                  })}
                </Select>
              ) : y.type === "number" ? (
                <TextField
                  disabled={y.disabled}
                  placeholder={y.name}
                  name={y.prop}
                  onChange={this.handleCurrencyChange(y.prop)}
                  value={this.state[y.prop]}
                  InputProps={
                    this.props.table.calculatorType === "service_order" &&
                    k !== 2
                      ? { inputComponent: moneyInputCustom }
                      : { inputComponent: moneyInput }
                  }
                  required={pProps.data.length ? false : y.required}
                />
              ) : y.type === "textArea" ? (
                <TextField
                  disabled={y.disabled}
                  placeholder={y.name}
                  name={y.prop}
                  onChange={this.handleChange(y.prop)}
                  value={this.state[y.prop]}
                  style={{
                    color: green[500],
                    marginTop: 20,
                    width: "70%",
                  }}
                  multiline={true}
                  rows={5}
                  rowsMax={5}
                  variant="outlined"
                  required={pProps.data.length ? false : y.required}
                />
              ) : y.type === "numberOnly" ? (
                <TextField
                  disabled={y.disabled}
                  placeholder={y.name}
                  name={y.prop}
                  type="number"
                  onChange={this.handleChange(y.prop)}
                  required={pProps.data.length ? false : y.required}
                  value={this.state[y.prop]}
                />
              ) : y.type === "auto" ? (
                <Autocomplete
                  id={y.prop}
                  options={y.options}
                  required={y.required}
                  getOptionLabel={(options) => options.fullName}
                  onChange={(e, value) =>
                    this.handleAutocompleteChange(y.prop, value)
                  }
                  renderInput={(params) => (
                    <TextField {...params} value={this.state[y.prop]} />
                  )}
                />
              ) : (
                <TextField
                  disabled={y.disabled}
                  placeholder={y.name}
                  type={y.type}
                  name={y.prop}
                  required={pProps.data.length ? false : y.required}
                  onChange={this.handleChange(y.prop)}
                  value={this.state[y.prop]}
                />
              )}
            </TableCell>
          );
        })}
        <TableCell style={style.TableCell}>
          <IconButton onClick={() => this.addTableRow(this.state)}>
            <AddIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}
export default InlineCreateForm;
