import * as React from "react";
import { connect } from "react-redux";

import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import {
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Radio,
    Checkbox,
    CheckboxProps,
    Dialog,
    DialogActions,
    DialogContent
} from '@material-ui/core'
import { RadioProps } from '@material-ui/core/Radio'
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AlertDialog from "../../../components/AlertDialog";
import {
    catchError,
    headersVehicleWarranty,
    headersMortgageWarranty,
    headersPropertyWarranty
} from "../../../utils";
import { fetchWarranties as fetchAll } from '../../../utils/axios';
import { Theme } from '@material-ui/core/styles';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import WarrantyView from "../WarrantyView";
import WarrantyForm from "../WarrantyForm";

const styles = createStyles({
    checkboxLabel: {
        color: green[500],
        fontSize: "medium",
    },
    errorInput: {
        color: 'red'
    },
    controls: {
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
    },
    formControl: {
        marginTop: 20,
        minWidth: 300,
        width: 300,
    },
    greenButton: {
        backgroundColor: green[500],
        color: "white",
    },
    input: {
        left: 40,
        position: "absolute",
        width: 1,
    },
    paper: {
        padding: 20,
    },
    root: {
        overflowY: "auto",
        padding: 20,
    },
    fileText: {
        fontSize: 14,
        position: "absolute",
        right: 32,
    },
    textField: {
        display: "block",
        marginTop: 20,
        width: "90%",
    },
});
const warrantyTypes = {
    "mortgage": "Garantia hipotecaria",
    "vehicle_warranty": "Garantia vehicular",
    "property_warranty": "Garantia de bienes"
}
const GreenRadio = withStyles({
    checked: {},
    root: {
        "&$checked": { color: green[600] },
        color: green[400],
    },
})((props: RadioProps) => <Radio color="default" {...props} />);

const GreenCheckbox = withStyles({
    checked: {},
    root: {
        '&$checked': { color: green[600] },
        color: green[400],
    },
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
            borderBottom: "none"

        },
    }),
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        root: {

            borderBottom: "none"
        }
    }),
)(TableCell);



function Row(props: any) {
    const { warranty, openForm} = props;
    const headers = {
        "mortgage": headersMortgageWarranty,
        "vehicle_warranty": headersVehicleWarranty,
        "property_warranty": headersPropertyWarranty
    }

    const jsonToArray = (json: { [key: string]: any }): any[] => {

        try {

            const array: any[] = [];

            for (const prop in json) {
                if (json.hasOwnProperty(prop)) {
                    array.push({
                        propertyDescription: json[prop].propertyDescription || '',
                        propertyReferenceNumber: json[prop].propertyReferenceNumber || '',
                        propertyAmount: json[prop].propertyAmount || '',
                        propertyDate: json[prop].propertyDate || '',
                        propertyDocumentType: json[prop].propertyDocumentType || '',
                        vehicleLicensePlate: json[prop].vehicleLicensePlate || '',
                        vehicleType: json[prop].vehicleType || '',
                        vehicleBrand: json[prop].vehicleBrand || '',
                        vehicleModel: json[prop].vehicleModel || '',
                        vehicleYear: json[prop].vehicleYear || '',
                        vehicleRegistration: json[prop].vehicleRegistration || '',
                        vehicleColor: json[prop].vehicleColor || '',
                        vehicleChassis: json[prop].vehicleChassis || '',
                        houseDescription: json[prop].houseDescription || ''
                    });
                }
            }

            return array;
        } catch (err) {
            return [];
        }

    }

    const details = jsonToArray(warranty.warrantyDetails)
    return (
        <React.Fragment>
            <TableRow >
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={props.onClick}
                    >
                        {props.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center" component="th" scope="row">{warranty?.debtorName}</TableCell>
                <TableCell align="center">{warrantyTypes[warranty.warrantyType]}</TableCell>
                <TableCell align="center">
                    <IconButton
                        onClick={openForm}
                    >
                        <AddIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={props.isOpen} timeout="auto" unmountOnExit={true}>
                        <Box margin={1}>
                            <Table aria-label="outlay">
                                <TableHead className="table-headers">
                                    <TableRow>
                                        {headers[warranty.warrantyType].map((opt: any, indx: any) => {
                                            return (
                                                <TableCell
                                                    key={indx}
                                                    style={{ borderBottom: 'none' }}
                                                >
                                                    {opt.name}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {details.map((detail: any, indx: any) => {
                                        return (
                                            <React.Fragment key={indx}>
                                                <StyledTableRow
                                                    hover={true}
                                                    role="checkbox"
                                                    aria-checked={props.checked}
                                                    tabIndex={-1}
                                                    key={indx}
                                                >
                                                    {detail.houseDescription ? <StyledTableCell>{detail.houseDescription}</StyledTableCell> : null}
                                                    {detail.propertyDescription ? <StyledTableCell>{detail.propertyDescription}</StyledTableCell> : null}
                                                    {detail.propertyReferenceNumber ? <StyledTableCell>{detail.propertyReferenceNumber}</StyledTableCell> : null}
                                                    {detail.propertyAmount ? <StyledTableCell>{detail.propertyAmount.toLocaleString()}</StyledTableCell> : null}
                                                    {detail.propertyDate ? <StyledTableCell>{detail.propertyDate}</StyledTableCell> : null}
                                                    {detail.propertyDocumentType ? <StyledTableCell>{detail.propertyDocumentType}</StyledTableCell> : null}
                                                    {detail.vehicleType ? <StyledTableCell>{detail.vehicleType}</StyledTableCell> : null}
                                                    {detail.vehicleBrand ? <StyledTableCell>{detail.vehicleBrand}</StyledTableCell> : null}
                                                    {detail.vehicleModel ? <StyledTableCell>{detail.vehicleModel}</StyledTableCell> : null}
                                                    {detail.vehicleYear ? <StyledTableCell>{detail.vehicleYear}</StyledTableCell> : null}
                                                    {detail.vehicleRegistration ? <StyledTableCell>{detail.vehicleRegistration}</StyledTableCell> : null}
                                                    {detail.vehicleColor ? <StyledTableCell>{detail.vehicleColor}</StyledTableCell> : null}
                                                    {detail.vehicleChassis ? <StyledTableCell>{detail.vehicleChassis}</StyledTableCell> : null}
                                                    {detail.vehicleLicensePlate ? <StyledTableCell>{detail.vehicleLicensePlate}</StyledTableCell> : null}
                                                    
                                                    {warranty.warrantyType !== 'property_warranty' ?
                                                        <StyledTableCell padding="checkbox">
                                                            <GreenRadio
                                                                value={`${warranty.uniqueId}${indx}`}
                                                                checked={props.checked.includes(`${warranty.uniqueId}${indx}`)}
                                                                onClick={
                                                                    () => props.handleChecked(
                                                                        `${warranty.uniqueId}${indx}`,
                                                                        warranty.uniqueId,
                                                                        warranty.warrantyType
                                                                    )
                                                                }
                                                            />
                                                        </StyledTableCell>
                                                    :
                                                        <StyledTableCell padding="checkbox">
                                                            <GreenCheckbox 
                                                                value={`${warranty.uniqueId}${indx}`}
                                                                checked={props.checked.includes(`${warranty.uniqueId}${indx}`)}
                                                                onClick={
                                                                    () => props.handleChecked(
                                                                        `${warranty.uniqueId}${indx}`,
                                                                        warranty.uniqueId,
                                                                        warranty.warrantyType
                                                                    )
                                                                }
                                                            />
                                                        </StyledTableCell>
                                                    }

                                                </StyledTableRow>
                                            </React.Fragment>

                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

interface IWarrantyTableState {
    [key: string]: string | number | boolean | string[] | IBankAccount[];

    dialog: boolean;
    dialogTitle: string;
    errorMessage: string;
    loading: boolean;
    formDialog: boolean;
    warrantyUniqueId: string;
    warranties: string[];
    warrantiesError: string;
}
interface IWarrantyTableProps {
    classes: {
        paper: string;
        root: string;
        checkboxLabel: string;
        errorInput?: string;
    }
    debtors?: string[];
    user: IUser;
    checked: any
    handleChecked?(details: any, lookupValue: any, type: any, warranty?: string, event?: any): void;

}
class WarrantyTable extends React.Component<IWarrantyTableProps, IWarrantyTableState> {
    public state: IWarrantyTableState = {
        warranties: [],
        warrantiesError: '',
        dialog: false,
        dialogTitle: "",
        errorMessage: "",
        loading: false,
        open: false,
        status: "",
        statusError: "",
        currSelectionIndex: -1,
        isRowOpen: false,
        formDialog: false,
        warrantyUniqueId: ''
    };

    constructor(props: IWarrantyTableProps) {
        super(props);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.toogleCollapseLevel = this.toogleCollapseLevel.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.cleanAutoComplete = this.cleanAutoComplete.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.emptyFunction = this.emptyFunction.bind(this);
        this.closeFormDialog = this.closeFormDialog.bind(this);
        this.openFormDialog = this.openFormDialog.bind(this);
    }
    public toogleCollapseLevel(rowIndex: any) {

        if (rowIndex === this.state.currSelectionIndex && this.state.isRowOpen) {
            this.setState({
                isRowOpen: false
            })
        } else {
            this.setState({
                isRowOpen: true,
                currSelectionIndex: rowIndex
            })
        }
    }

    public cleanAutoComplete(optionList: any) {
        if (optionList.length) {
            let cleanList = ""
            for (const i of optionList) {
                cleanList += `${(i)}|`
            }
            return cleanList
        }
        return ''
    }

    public componentDidMount() {
        if (this.props.debtors){
            this.setState({ loading: true });
            fetchAll({ token: this.props.user.token, limit: 20, offset: 0, debtor: this.cleanAutoComplete(this.props.debtors) }).then((res: any) => {
                this.setState({ warranties: res.data.results });
                this.setState({ loading: false });
            }).catch((err: any) => {
                catchError(err, this)
            });
        }
    }

    public componentDidUpdate(prevProps: any, prevState: any){
        if (prevState.formDialog !== this.state.formDialog && this.props.debtors){
            fetchAll({ token: this.props.user.token, limit: 20, offset: 0, debtor: this.cleanAutoComplete(this.props.debtors) }).then((res: any) => {
                this.setState({ warranties: res.data.results });
                this.setState({ loading: false });
            }).catch((err: any) => {
                catchError(err, this)
            });
        }
    }

    public handleSelectChange(key: any) {
        return (event: any) => {
            this.setState({
                [key]: event.target.value,
                [key + "Error"]: "",
            });
        };
    }

    public handleDialogClose() {
        this.setState({ dialog: false });
    }

    public openFormDialog(warrantyId?: string) {
        this.setState({ 
            formDialog: true,
            warrantyUniqueId: warrantyId || ''
        });
      }

    public closeFormDialog() {
    this.setState({ formDialog: false });
    }

    public emptyFunction() {
        return null;
    }

    public render() {
        const { classes } = this.props;
        return (
            <div>
                {/* <form className={classes.root}> */}
                <form>
                    <AlertDialog
                        open={this.state.dialog}
                        message={this.state.errorMessage}
                        title={this.state.dialogTitle}
                        handleClose={this.handleDialogClose}
                    />
                    {this.state.warranties.length > 0 ?
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead >
                                    <TableRow>
                                        <TableCell>
                                            <IconButton onClick={() => this.openFormDialog()} >
                                                <AddIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">Nombre de cliente</TableCell>
                                        <TableCell align="center">Tipo de garantia</TableCell>
                                        <TableCell align="center">Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.warranties.map((warranty: any, index: any) => (
                                        <Row token={this.props.user.token}
                                            checked={this.props.checked}
                                            handleChecked={this.props.handleChecked}
                                            key={index} warranty={warranty}
                                            isOpen={this.state.isRowOpen && index === this.state.currSelectionIndex}
                                            onClick={(ev?: any) => this.toogleCollapseLevel(index)} 
                                            openForm={() => this.openFormDialog(warranty.uniqueId)}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :                
                        <TableContainer component={Paper}>
                             <Table aria-label="collapsible table">
                                <TableBody>
                                    <TableCell className={classes.errorInput}>No hay garantias relacionadas con este deudor</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => this.openFormDialog()} >
                                            <AddIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    <Dialog
                        open={this.state.formDialog}
                        fullScreen={true}
                        onClose={this.closeFormDialog}
                        >
                        <DialogActions>
                            <IconButton
                                style={{backgroundColor: green[500], color: "white"}}
                                onClick={this.closeFormDialog}
                            >
                            <ArrowBackIcon />
                            </IconButton>
                        </DialogActions>
                        <DialogContent style={{ position: "relative" }}>
                            {this.state.warrantyUniqueId === '' ?
                                <WarrantyForm changeSection={this.emptyFunction} />
                                :
                                <WarrantyView changeSection={this.emptyFunction} warrantyId={this.state.warrantyUniqueId}/>
                            }
                        </DialogContent>
                    </Dialog>

                </form>
            </div>
        );
    }
}

function mapStateToProps(state: IState) {
    return {
        user: state.user,
    };
}
export default withStyles(styles)(
    connect(mapStateToProps)(WarrantyTable)
);