import * as React from 'react';
import green from '@material-ui/core/colors/green';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { fetchInstitutions as fetch } from '../../utils/axios';

import { connect } from "react-redux";

const styles: any = createStyles({
    label: {
        color: green[500]
    }
});

interface IAutoCompleteProps {
    user: any;
    value: any;
    label: string;
    classes: {
        [key: string]: string;
    }
    handleSelectChange(e: any, newValue: any): void;
}

interface IAutocompleteState {
    [key: string]: any;
    institutions: any;
}

class InstitutionAutoComplete extends React.Component<IAutoCompleteProps, IAutocompleteState>{
    public state: IAutocompleteState = {
        institutions: [],
    };

    public componentDidMount() {

        fetch({ token: this.props.user.token, limit: 20, offset: 0 }).then((res: any) => {
            this.setState({ institutions: res.data.results })
        }).catch((err: any) => {
            console.log(err);
        })
    }

    public render() {
        const filterOptions = createFilterOptions({
            stringify: (option: IInstitution) => option.name! + option.abbreviation!,
        });

        return (
            <Autocomplete
                id="filter-demo"
                multiple={true}
                onChange={this.props.handleSelectChange}
                options={this.state.institutions}
                getOptionLabel={(option) => `${option.name} - ${option.abbreviation}`}
                filterOptions={filterOptions}
                value={this.props.value}
                fullWidth={true}
                renderInput={(params) => <TextField {...params} label={this.props.label}
                    InputLabelProps={{
                        style: styles.label,
                        shrink: true,
                    }} />}
            />
        )
    }
}

function mapStateToProps(state: IState) {
    return {
        user: state.user,
    };
}

export default withStyles(styles)(connect(mapStateToProps)(InstitutionAutoComplete));