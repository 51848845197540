
import { CLIENT_AFFIDAVIT_OF_FUNDS_TYPES } from '../actions';

export default function(state: IClientAffidavitOfFunds[] = [], action: IAction<IClientAffidavitOfFunds[]>): IClientAffidavitOfFunds[] {
    switch(action.type) {
        case CLIENT_AFFIDAVIT_OF_FUNDS_TYPES.FETCH_ALL:
            return action.payload || [];
        case CLIENT_AFFIDAVIT_OF_FUNDS_TYPES.FETCH:
            return state.map(clientAffidavitsOfFunds => (action.payload !== undefined && clientAffidavitsOfFunds.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: clientAffidavitsOfFunds);
        case CLIENT_AFFIDAVIT_OF_FUNDS_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case CLIENT_AFFIDAVIT_OF_FUNDS_TYPES.UPDATE:
            return state.map(clientAffidavitsOfFunds => (action.payload !== undefined && clientAffidavitsOfFunds.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : clientAffidavitsOfFunds);
        case CLIENT_AFFIDAVIT_OF_FUNDS_TYPES.DELETE:
            return state.filter(clientAffidavitsOfFunds => action.payload !== undefined && clientAffidavitsOfFunds.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}

