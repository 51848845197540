
import { AFFIDAVIT_OF_FUNDS_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case AFFIDAVIT_OF_FUNDS_TYPES.FETCH_ALL:
            return action.payload || [];
        case AFFIDAVIT_OF_FUNDS_TYPES.FETCH:
            return state.map(affidavitOfFunds => (action.payload !== undefined && affidavitOfFunds.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: affidavitOfFunds);
        case AFFIDAVIT_OF_FUNDS_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case AFFIDAVIT_OF_FUNDS_TYPES.UPDATE:
            return state.map(affidavitOfFunds => (action.payload !== undefined && affidavitOfFunds.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : affidavitOfFunds);
        case AFFIDAVIT_OF_FUNDS_TYPES.DELETE:
            return state.filter(affidavitOfFunds => action.payload !== undefined && affidavitOfFunds.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}
