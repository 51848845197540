import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  CardContent,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import {
  errorHandling,
  dateFormat,
} from "src/components/SharedComponents/CustomHooks";
import {
  AutocompleteField,
  CheckboxField,
  TextFieldComponent,
} from "src/components/SharedComponents/Fields";
import NumberFormat from "src/components/SharedComponents/NumberFormat";
import QuotationSelectInput from "src/containers/QuotationFiles/QuotationSelectInput";
import {
  fetchQuotation,
  fetchTicket,
  getCreditDebtCapacity,
  getCreditPayCapacity,
  printAnalystTemplate,
  updateQuotation,
  ticketInfo,
  fetchDebtCapacities,
  fetchPaymentCapacities,
  fetchQuota,
  fetchTime,
  fetchInterests,
} from "src/utils/axios";
import { transactionTypes } from "src/utils";
import { DebtCapacityTable, PayCapacityTable } from "./../../Tables";
import SettlementFormsTable from "src/components/TicketWorkflows/Outlay/Form";
import {
  StepStatus,
  approvalStates,
  approveQuotation,
  cancelTicketDocuments,
  getQuotationData,
  handleAddQuotation,
  handleNextStep,
  handleRemoveQuotation,
  handleViewQuotation,
  useCheckStepStatus,
  ticketHistory,
  ticketHistoryGeneral,
  ViewQuotation,
  transaction_types,
  DisbursementGeneralBadges,
  ViewComponent,
  ViewQuotationText,
  SettlementTableActions,
  validateSettlementTotal,
  getBankAccounts,
  getClientBankAccounts,
  handleState,
  handleModify,
  handleDelete,
} from "./../../TicketGeneralMethods";
import { red } from "@material-ui/core/colors";

export default function CreditAnalysis(props) {
  const scores = ["A", "B", "C", "D", "E"];
  const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);

  const [creditorAccounts, setCreditorAccounts] = useState([]);
  const [clientBankAccounts, setClientBankAccounts] = useState([]);
  const [participant, setParticipant] = useState(
    props.ticket.participantDetail || {}
  );
  const [creditVerifyOptions, setCreditVerifyOptions] = useState([]);
  const [debtCapacity, setDebtCapacity] = useState([]);
  const [debtCapacities, setDebtCapacities] = useState([]);
  const [payCapacity, setPayCapacity] = useState([]);
  const [suggested, setSuggested] = useState(0);
  const [settlementData, setSettlementData] = useState(0);
  const [settlementInfo, setSettlementInfo] = useState({});
  const [quotationFee, setQuotationFee] = useState(0);
  const [ticketInfoData, setTicketInfoData] = useState([]);
  const [payCapacityTableData, setPayCapacityTableData] = useState([]);
  const [approveStates] = useState(approvalStates());
  const [quotationData, setQuotationData] = useState({});
  const [formIds, setFormIds] = useState({});
  const [recomendation, setRecomendation] = useState("");
  const [canApproveQuotation, setApproveQuotation] = useState(false);
  const [approvalObj, setApprovalObj] = useState({});
  const [selectedApproveStatus, setSelectedApproveStatus] = useState(
    "PENDIENTE"
  );
  const [creditVerifyMandatory, setCreditVerifyMandatory] = useState(true);
  const [checkValidation, setCheckValidation] = useState({
    exceptionValidation: false,
    validDocs: false,
  });
  const [hasPermission, setHasPermission] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [approvalRange, setApprovalRange] = useState([]);
  const regularLoans = [
    "simple_loan",
    "constant_fees",
    "decreasing_fees",
    "salary_advance",
  ];

  const getCreditVerifyData = () => {
    const creditVerifyData = props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.filter((item) => item.type === "creditVerify");
    setCreditVerifyOptions(creditVerifyData);
  };

  const getInterest = async () => {
    try {
      const [na, sl] = await Promise.all([
        fetchInterests({ token: props.token, calculatorType: "ap_lvl_na" }),
        fetchInterests({ token: props.token, calculatorType: "ap_lvl_sl" }),
      ]);
      const descNA = na?.data[0]?.description || [];
      const descSL = sl?.data[0]?.description || [];
      const limits = props.ticket.affiliatedInstitution ? descNA : descSL;
      setApprovalRange(limits);
    } catch (err) {
      console.error("Error al obtener nivel de aprobacion NA", err);
    }
  };

  const getTicketRequirements = () => {
    getCreditVerifyData();
    let forms = {};
    props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.filter((item) => item.type === "form")
      .forEach((item) => {
        forms = { ...forms, [item.name]: item.value };
      });
    const recm = props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.find((item) => item.name === "recomendation").value;
    setFormIds(forms);
    setRecomendation(recm);

    props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.forEach((r) => {
        if (checkValidation.hasOwnProperty(r.name)) {
          setCheckValidation((prev) => ({ ...prev, [r.name]: r.value }));
        }
      });
  };

  const handleCreditVerify = (type, option, event, value) => {
    switch (type) {
      case "comment":
        option.value.comment = event.target.value;
        break;
      case "state":
        option.value.state = !option.value.state;
        break;
      case "score":
        option.value.score = value;
        break;
      default:
        break;
    }
    setCreditVerifyOptions([...creditVerifyOptions]);
  };

  const getTicket = () => {
    const endpoint = fetchTicket(props.token, props.ticket.uniqueId);
    endpoint
      .then((response) => {
        // setCurrentTicket(response.data);
      })
      .catch((errors) => {
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
  };

  const validateRol = () => {
    return approvalRange.some((vr) => props.roles.includes(vr.level));
  };
  const checkPermission = () => {
    if (!hasPermission) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `No tiene los permisos correspondientes para realizar esta acción.`
      );
      return false;
    }
    return true;
  };

  const handleCheckValidation = (type, event, value) => {
    if (!checkPermission()) {
      return;
    }
    setCheckValidation({ ...checkValidation, [type]: value });
  };

  const handleRecomendationChange = (event) => {
    setRecomendation(event.target.value);
  };

  const handleFileRequirements = (steps) => {
    ticketHistory(steps, props).then((response) => {
      const history = response;
      props.modifyTicketStep(steps, props.ticket, history, undefined);
    });
  };

  const handleDenyTicket = async () => {
    const cancellationReason =
      "--CANCELADO POR DENEGACIÓN PROPUESTA DE TICKET--";
    await cancelTicketDocuments(cancellationReason, props);
    const time = await fetchTime().then((response) => response.data);
    const removeT = time.substr(0, time.indexOf("T"));
    const formmattedDate = dateFormat(new Date(removeT));
    const dataToModify = {
      status: "rejected",
      cancellationReason: "DENEGADA LA PROPUESTA DEL TICKET",
      cancelledBy: props.loggedUser,
      cancelledDate: formmattedDate,
    };
    approvalObj.status = "denied";
    const historyDescription = `Se ha modificado el estado del ticket a: Denegado`;
    ticketHistoryGeneral(props, historyDescription).then((response) => {
      const history = response;
      props.modifyTicket(dataToModify, history);
    });
    props.handleToggleSimpleDialog();
  };

  const getCurrentQuotation = () => {
    const currentQuotation = props.ticket.steps
      .find((item) => item.step === "eval_forms_settlement")
      .requirements.find((item) => item.name === "quotation").value;
    return formIds.quotation || currentQuotation;
  };

  const fetchQuotationData = async () => {
    const currQuotation = getCurrentQuotation();
    const data = await getQuotationData(props, currQuotation);
    setQuotationData(data);
  };

  const handleSelectQuotation = async (id) => {
    const previousStepQuotation = props.ticket.steps
      .find((item) => item.step === "eval_forms_settlement")
      .requirements.find((item) => item.name === "quotation").value;
    if (previousStepQuotation === id) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `La cotización seleccionada ya está en usando en el paso anterior.`
      );
      return;
    }
    setApproveQuotation(false);
    const currQuotation = getCurrentQuotation();
    const newQuotation = await getQuotationData(props, id);
    setQuotationData(newQuotation);
    handleAddQuotation(setFormIds, newQuotation.uniqueId);
    if (
      newQuotation.uniqueId !== currQuotation &&
      newQuotation.quotationStatus === "approved"
    ) {
      modifyApprovalObj("", newQuotation.uniqueId);
      props.ticket.steps
        .find((item) => item.step === props.currentStep)
        .requirements.find((item) => item.name === "quotation").value =
        newQuotation.uniqueId;

      ticketHistoryGeneral(props, "").then((response) => {
        const history = response;
        props.modifyTicketStep(
          props.ticket.steps,
          props.ticket,
          history,
          undefined
        );
      });
    }
  };

  const Approve = () => {
    const amount = Number(quotationData.originalAmount);
    const limitObjs = validateQuotationAmountUser();

    if (!limitObjs || limitObjs.length === 0) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `No tiene los permisos correspondientes para realizar esta acción.`
      );
      return false;
    }

    const approve = limitObjs.some(
      (limitObj) =>
        amount >= limitObj.from && (amount <= limitObj.to || limitObj.to === "")
    );

    if (!approve) {
      const exceededLimits = limitObjs
        .map(
          (limitObj) =>
            `${limitObj.from.toLocaleString("en-US", {
              style: "currency",
              currency: "DOP",
            })} - ${limitObj.to.toLocaleString("en-US", {
              style: "currency",
              currency: "DOP",
            })}`
        )
        .join("/ ");

      props.handleToggleSimpleDialog(
        "simple-dialog",
        `El monto de la cotización se encuentra fuera de su límite de aprobacion de ${exceededLimits}. 
          Para proceder debe tener los permisos correspondientes`
      );
      return false;
    }

    return true;
  };

  const modifyApprovalObj = async (action, quotationId) => {
    if (!Approve()) {
      return;
    }

    const id = typeof quotationId === "string" ? quotationId : "";
    const currentQuotation = id || getCurrentQuotation();
    const state = approveStates.find((item) => item.value === action);
    approvalObj.status = state ? action : "";

    if (action === "reclassified") {
      try {
        const response = await fetchQuotation(props.token, currentQuotation);
        const quotation = response.data;
        const cancelReason = "--CANCELADO POR RECLASIFICACIÓN--";
        quotation.quotationStatus = "cancelled";
        quotation.cancelReason = cancelReason;
        const updateResponse = await updateQuotation(
          props.token,
          quotation.uniqueId,
          quotation
        );
        setQuotationData(updateResponse.data);
        approvalObj.quotation = "";
      } catch (errors) {
        errorHandling(errors, props.handleToggleSimpleDialog);
      }
    } else if (approvalObj.quotation !== currentQuotation) {
      approvalObj.quotation = currentQuotation;
    }

    // quick fix, recomendation is deleted before is saved.
    const step = props.ticket.steps.find(
      (item) => item.step === props.currentStep
    );
    if (step) {
      const requirement = step.requirements.find(
        (item) => item.name === "recomendation"
      );
      if (requirement) {
        requirement.value = recomendation || "";
      }
    }
    // end of quick fix :U

    const historyDescription = `Se ha modificado el objeto de aprobación a: ${JSON.stringify(
      approvalObj
    )}`;
    const dataToModify = {
      steps: props.ticket.steps,
    };

    if (action === "reclassified") {
      dataToModify.status = "reclassified";
    } else if (props.ticket.status !== "approval_pending") {
      dataToModify.status = "approval_pending";
    }

    try {
      const response = await ticketHistoryGeneral(props, historyDescription);
      const history = response;
      props.modifyTicket(dataToModify, history);
    } catch (err) {
      console.error("Error al modificar el historial del ticket", err);
    }
  };

  const handleApproveQuotation = () => {
    if (!Approve()) {
      return;
    }
    modifyApprovalObj("");
    const pastQuotation = props.ticket.steps
      .find((item) => item.step === "eval_forms_settlement")
      .requirements.find((item) => item.name === "quotation").value;
    if (pastQuotation === quotationData.uniqueId) {
      approveQuotation(props, quotationData, undefined, setQuotationData);
      return;
    }
    approveQuotation(props, quotationData, setFormIds, setQuotationData);

    props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.find((item) => item.name === "quotation").value =
      quotationData.uniqueId;

    ticketHistoryGeneral(props, "").then((response) => {
      const history = response;
      props.modifyTicketStep(
        props.ticket.steps,
        props.ticket,
        history,
        undefined
      );
    });
  };

  const getTicketInfo = async (participant) => {
    if (participant?.rohiId) {
      const endpoint = ticketInfo({
        token: props.token,
        associateNumber: participant.rohiId,
      });
      await endpoint
        .then((response) => {
          const ticketInfoData = response.data.map((item) => {
            return {
              capacidad_endeudamiento: Number(item.capacidad_endeudamiento),
              capacidad_pagos_info: item.capacidad_pagos_info,
              detalle_atrasos: item.detalle_atrasos,
              detalle_financiamientos: item.detalle_financiamientos,
              informacion_atrasos: item.informacion_atrasos[0],
              nombre_socio: item.nombre_socio,
              numero_identificacion: item.numero_identificacion,
              total_atraso: item.total_atraso,
            };
          });
          setTicketInfoData(ticketInfoData);
        })
        .catch((errors) => {
          props.setLoading(false);
          errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }
  };

  const getDebtCapacity = async () => {
    const endpoint = getCreditDebtCapacity({
      accessToken: props.token,
      clientUniqueId: props.ticket.customer,
      quotationUniqueId: getCurrentQuotation(),
    });
    await endpoint
      .then((response) => {
        setDebtCapacity(response.data);
      })
      .catch((errors) => {
        props.setLoading(false);
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
  };

  const getSettlementData = () => {
    const creditMethodTableData = props.ticket.steps
      .find((item) => item.step === "eval_forms_settlement")
      .requirements.find((item) => item.name === "creditMethodTables").value;

    const data = creditMethodTableData || settlementInit;
    if (data && data.creditMethods) {
      // REMOVE CASH FROM CREDIT METHODS AND SAVED VALUES;
      data.creditMethods = data.creditMethods.filter(
        (item) => item.value !== "cash"
      );
      delete data.cash;
    }
    setSettlementData(data || {});
  };

  const getDebtCapacities = async () => {
    const product = regularLoans.includes(quotationData.productType)
      ? "regular_loan"
      : quotationData.productType;
    const endpoint = fetchDebtCapacities({
      token: props.token,
      customer: props.ticket.customer,
      productType: product,
    });
    await endpoint
      .then((response) => {
        let currSuggestion = 0;
        response.data.forEach(
          (item) => (currSuggestion += item.modifierData.suggested)
        );
        setSuggested(parseFloat(currSuggestion).toFixed(2));
        setDebtCapacities(response.data);
      })
      .catch((errors) => {
        props.setLoading(false);
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
  };

  const getPayCapacity = async () => {
    const endpoint = fetchPaymentCapacities({
      token: props.token,
      customer: props.ticket.customer,
      // quotationUniqueId: quotationUniqueId
    });
    await endpoint
      .then((response) => {
        setPayCapacity(response.data);
      })
      .catch((errors) => {
        props.setLoading(false);
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
  };

  const getQuotationFee = () => {
    if (quotationData?.uniqueId) {
      const endpoint = fetchQuota({
        quotation_id: quotationData.uniqueId,
        token: props.token,
      });
      endpoint.then((response) => {
        let fee = Array.isArray(response.data)
          ? response.data[0]
          : response.data;
        if (typeof fee === "string") {
          fee = fee.replaceAll(",", "");
        }
        setQuotationFee(fee);
      });
    }
  };

  const initialFetch = async () => {
    props.setLoading(true);
    await Promise.all([
      getTicketInfo(props.ticket.participantDetail || null),
      getDebtCapacity(),
      getDebtCapacities(),
      getPayCapacity(),
      getInterest(),
    ]).then();
    // await getTicketInfo();
    // await getDebtCapacity();
    // await getDebtCapacities();
    // await getPayCapacity();
    props.setLoading(false);
  };

  /*
  JOSMEL DIJO QUE CADA ROL SOLO APROBARA DENTRO DEL RANGO DEFINIDO PARA SU ROL EN EL POOL DE TASA
  ES DECIR, NO PODRA APROBAR POR DEBAJO NI POR ARRIBA DE DICHO RANGO
  */
  const validateQuotationAmountUser = () => {
    if (approvalRange) {
      const appObj = approvalRange.filter((app) =>
        props.roles.includes(app.level)
      );
      return appObj;
    }
    return [];
  };

  const validateQuotationAmount = () => {
    if (quotationData.uniqueId) {
      const limitObjs = validateQuotationAmountUser();

      if (Approve()) {
        setApproveQuotation(Approve());
      } else {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `No tiene los permisos correspondientes para realizar esta acción`
        );
      }
    }
  };

  const getPayCapacityData = () => {
    let capacidadPagoData =
      ticketInfoData.length > 0 ? ticketInfoData[0].capacidad_pagos_info : [];

    const debtCapacitiesClean = debtCapacities.filter(
      (item) => item.modifierData.productType !== "regular_loan"
    );
    let tableData = {};
    let debt = [];
    let pay = [];

    debtCapacitiesClean.forEach((item) => {
      debt.push(item);
    });
    payCapacity.forEach((item) => {
      pay.push(item);
    });
    tableData = {
      rohi: capacidadPagoData,
      debt,
      pay,
    };

    const result = [];

    const constructTable = (row, type) => {
      const debtPayData = type === "debt" ? row.modifierData : row.data;
      const rohiProduct = tableData.rohi?.find(
        (rc) =>
          rc.tipo_capacidad_pago === transaction_types[debtPayData.productType]
      );
      let data = {};
      if (rohiProduct) {
        data.tipoCapacidadPago = rohiProduct.tipo_capacidad_pago;
        data.totalCapacidadPagos = rohiProduct.total_capacidad_pagos;
        data.totalAhorros = rohiProduct.total_ahorros;
        data.totalPrestamos = rohiProduct.total_prestamos;
      } else {
        data.tipoCapacidadPago = transaction_types[debtPayData.productType];
        data.totalCapacidadPagos = 0;
        data.totalAhorros = 0;
        data.totalPrestamos = 0;
      }
      data.capacidad =
        type === "debt"
          ? Array.isArray(debtPayData.debtCapacity)
            ? debtPayData.debtCapacity[0]
            : debtPayData.debtCapacity
          : debtPayData.paymentCapacity;
      data.disponible = data.capacidad - data.totalCapacidadPagos;
      return data;
    };
    tableData.debt.forEach((item) => {
      const newData = constructTable(item, "debt");
      result.push(newData);
    });
    tableData.pay.forEach((item) => {
      const newData = constructTable(item, "pay");
      result.push(newData);
    });
    tableData.rohi.forEach((rd) => {
      const foundRow = result.find(
        (r) => r.tipoCapacidadPago === rd.tipo_capacidad_pago
      );
      let data = {};
      if (!foundRow) {
        data = {
          tipoCapacidadPago: rd.tipo_capacidad_pago,
          totalCapacidadPagos: rd.total_capacidad_pagos,
          capacidad: 0,
          disponible: -rd.total_capacidad_pagos,
          totalAhorros: rd.total_ahorros,
          totalPrestamos: rd.total_prestamos,
        };
        result.push(data);
      }
    });
    return result;
  };

  const generateTemplate = () => {
    props.setLoading(true);
    const endpoint = printAnalystTemplate(props.token, props.ticket.uniqueId);
    endpoint
      .then((response) => {
        const file = new Blob([response.data], { type: "text/html" });
        const fileURL = URL.createObjectURL(file);
        const NewWindow = window.open();
        NewWindow.location.href = fileURL;
        props.setLoading(false);
      })
      .catch((errors) => {
        props.setLoading(false);
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
  };

  const handleCreditVerifyMandatory = () => {
    setCreditVerifyMandatory(!creditVerifyMandatory);
  };

  const handleCompleteStep = async () => {
    let proceed = true;
    const ticketReqs = (step) =>
      props.ticket.steps.find(
        (item) => item.step === (step || props.currentStep)
      ).requirements;
    const reqs = props.ticket.steps.find(
      (item) => item.step === props.currentStep
    ).requirements;
    const documentReviewer = props.roles.includes(
      "supervisor documentos pendientes"
    );
    if (documents.length > 2 && !documentReviewer) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `La cantidad de documentos pendientes no puede superar 2.`
      );
      return;
    }
    reqs.find((item) => item.name === "quotation").value = formIds[`quotation`];
    reqs.find((item) => item.name === "recomendation").value = recomendation;

    props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.forEach((r) => {
        if (checkValidation.hasOwnProperty(r.name)) {
          r.value = checkValidation[r.name] || "";
        }
      });

    const checkStatus = props.ticket.steps.find((item) => item.approvalObj)
      .approvalObj.status;
    if (checkStatus !== "approved") {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `La propuesta aún no ha sido aprobada.`
      );
      return;
    }

    if (!debtCapacity.length || !payCapacity.length) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `La capacidad de endeudamiento y de pago deben tener información.`
      );
      return;
    }

    const creditVerifyList = props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.find(
        (item) =>
          item.type === "creditVerify" && item.value.state && item.value.score
      );

    const recomendationValue = props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.find((item) => item.name === "recomendation" && item.value);

    if (!recomendationValue) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `Debe digitar una recomendación.`
      );
      return;
    }

    if (creditVerifyMandatory && !creditVerifyList) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `Debe completar al menos una opción en Lista de Verificación de Crédito.`
      );
      return;
    }

    // remove verify list in case option checked and then unchecked.
    props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.filter((item) => item.type === "creditVerify")
      .forEach((item) => {
        if (!item.value.state) {
          item.value = { comment: "", score: "", state: false };
        }
      });

    let history = false;
    let statusLabel = "";
    // CHECK IF NOTARIAL PAY IS SELECTED IN STEP 1, THEN SET TICKET STATUS TO REG PENDING.
    const foundReq = ticketReqs("eval_forms_settlement")?.find(
      (item) => item.name === "signDocuments"
    );
    if (props.ticket.affiliatedInstitutionRnc === "430298557") {
      props.ticket.status = "rrhh_pending";
      statusLabel = "Pendiente de Aprobacion RRHH";
    } else if (foundReq && foundReq.value === "notarialPayEqualLoan") {
      props.ticket.status = "registration_pending";
      statusLabel = "Pendiente Registro";
    } else {
      // IF NOT, CHECK IF INSTITUTION IS AFFILIATED, THEN SET RELEVANT STATES.
      const isAffiliated = props.ticket.affiliatedInstitution;
      if (isAffiliated) {
        props.ticket.status = "rrhh_pending";
        statusLabel = "Pendiente de Aprobacion RRHH";
      } else if (quotationData?.productType === "salary_advance") {
        props.ticket.status = "registration_pending";
        statusLabel = "Pendiente Registro";
      } else {
        props.ticket.status = "sign_pending";
        statusLabel = "Pendiente de Firma";
      }
    }
    const historyDescription = `Se ha modificado el estado del ticket a: ${statusLabel}`;
    history = await ticketHistoryGeneral(props, historyDescription).then(
      (response) => response
    );
    handleNextStep(
      proceed,
      props,
      props.ticket.steps,
      undefined,
      false,
      history
    );
  };

  const initBankAccounts = async () => {
    const ba = await getBankAccounts(props.token);
    const cba = await getClientBankAccounts({
      token: props.token,
      owner: props.ticket.customer,
    });
    setCreditorAccounts(ba.creditorAccounts);
    setClientBankAccounts(cba.clientBankAccounts);
  };

  useEffect(() => {
    const isRoValid = validateRol();
    setHasPermission(isRoValid);
  }, [approvalRange]);

  useEffect(() => {
    initialFetch();
    initBankAccounts();
    fetchQuotationData();
  }, [props.reloadTicket]);

  useEffect(() => {
    getTicketRequirements();

    setApprovalObj(
      props.ticket.steps.find((item) => item.approvalObj).approvalObj
    );
  }, [props.ticket, props.reloadTicket]);

  useEffect(() => {
    if (formIds.quotation && !quotationData.uniqueId) {
      fetchQuotationData();
    }
  }, [formIds.quotation]);

  useEffect(() => {
    if (approvalObj.hasOwnProperty("quotation")) {
      const status = approveStates.find(
        (item) => item.value === approvalObj.status
      );
      setSelectedApproveStatus(status ? status.name : "PENDIENTE");
    }
  }, [approvalObj]);

  useEffect(() => {
    getQuotationFee();
  }, [quotationData]);

  useEffect(() => {
    if (quotationData.uniqueId) {
      const quotationProductType = transactionTypes.find(
        (tt) => tt.value === quotationData.productType
      );
      const data = getPayCapacityData();
      const isRegular = regularLoans.includes(quotationProductType?.value);
      const filteredData = data.filter(
        (pc) =>
          pc.tipoCapacidadPago ===
          (isRegular ? "PRESTAMO REGULAR" : quotationProductType?.rohi)
      );
      setPayCapacityTableData([...filteredData]);
    }
    getSettlementData();
  }, [payCapacity, debtCapacities, ticketInfoData, quotationData]);

  useEffect(() => {
    if (settlementData) {
      const quoteData = quotationData && quotationData.requestAmount;
      const data = validateSettlementTotal(quoteData || null, settlementData);
      setSettlementInfo({ ...data });
    }
  }, [settlementData, quotationData]);

  return (
    <div style={{ padding: "16px" }}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        direction="column"
        style={{ margin: "1rem 0" }}
      >
        <StepStatus
          {...props}
          completed={stepStatus}
          handleNextStep={handleCompleteStep}
          ticketCompleted={props.ticketCompleted}
          roles={props.roles}
          showPrevBtn={true}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          style={{ margin: "1rem 4px" }}
        >
          {/* <ViewQuotation onClick={handleViewQuotation.bind(this, props)} /> */}
          <ViewQuotationText
            {...props}
            handleViewQuotation={handleViewQuotation}
            quotationData={quotationData}
            setFormIds={setFormIds}
          />
          <div style={{ margin: "0 10px" }} />
          <ViewComponent
            onClick={() =>
              props.handleToggleComponent({
                open: props.ticket?.uniqueId,
                name: "loanApproval",
                id: props.ticket?.uniqueId,
                fullScreen: true,
              })
            }
            title="aprobaciones"
          />
        </Grid>
        <Paper
          style={{ padding: ".5rem 1rem", marginRight: "1rem", maxWidth: 400 }}
        >
          <Typography variant="subtitle1" style={{ color: "#424242" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{ color: "#424242", fontSize: 15, fontWeight: "bold" }}
              >
                <span style={{ fontWeight: "normal" }}>ESTADO:&nbsp;</span>
                {selectedApproveStatus}
              </span>
            </div>
          </Typography>
        </Paper>

        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#424242",
            marginLeft: 5,
            marginTop: "2rem",
          }}
        >
          VALIDACIONES GENERALES
        </Typography>
        <Divider style={{ marginBottom: "2rem" }} />
        <CheckboxField
          xs={12}
          alignItems="flex-end"
          check={checkValidation.exceptionValidation}
          handleChange={handleCheckValidation.bind(this, "exceptionValidation")}
          color="primary"
          textColor="#43A047"
          label="VALIDACIÓN DE EXCEPCIÓN"
          disabled={props.ticketCompleted}
        />

        <CheckboxField
          xs={12}
          alignItems="flex-end"
          check={checkValidation.validDocs}
          handleChange={handleCheckValidation.bind(this, "validDocs")}
          color="primary"
          textColor="#43A047"
          label="DOCUMENTOS VALIDADOS"
          disabled={props.ticketCompleted}
        />
        <Divider style={{ margin: "2rem 0" }} />
        <DisbursementGeneralBadges
          ticketInfoData={ticketInfoData || []}
          customer={props.ticket?.customer}
          token={props.token}
          documents={documents}
          setDocuments={setDocuments}
        />

        {/* <Typography
            variant={"subtitle1"}
            className={"section-header"}
            style={{
              fontWeight: "bold",
              color: "#424242",
              marginLeft: 5,
              marginTop: "2rem",
            }}
          >
            CAPACIDAD DE ENDEUDAMIENTO
          </Typography>
          <Divider style={{ marginBottom: "2rem" }} /> */}
        <DebtCapacityTable
          data={ticketInfoData}
          debtCapacity={debtCapacity}
          suggested={Number(suggested)}
          quotationData={quotationData}
          quotationFee={quotationFee}
          ticketStep={props.currentStep}
          settlementData={settlementData}
        />
        {/* <Typography
            variant={"subtitle1"}
            className={"section-header"}
            style={{
              fontWeight: "bold",
              color: "#424242",
              marginLeft: 5,
              marginTop: "2rem",
            }}
          >
            CAPACIDAD DE PAGO
          </Typography>
          <Divider style={{ marginBottom: "2rem" }} /> */}
        <PayCapacityTable
          data={payCapacityTableData}
          quotationFee={quotationFee}
          ticketStep={props.currentStep}
        />

        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#424242",
            marginLeft: 5,
            marginTop: "2rem",
          }}
        >
          CONFIRMACIÓN DE FORMAS DE LIQUIDACIÓN
        </Typography>
        <Divider style={{ marginBottom: "2rem" }} />
        <SettlementTableActions
          {...props}
          showActions={false}
          isEditing={false}
          amountTransfer={settlementInfo.transferTotal}
          total={settlementInfo.total}
          settlementData={settlementData}
          step={"credit_formalization"}
        />
        {settlementData && (
          <SettlementFormsTable
            token={props.token}
            handleToggleSimpleDialog={props.handleToggleSimpleDialog}
            rohiId={participant.rohiId || null}
            state={settlementData}
            clientId={[props.ticket.customer]}
            originAccounts={
              creditorAccounts.length > 0
                ? creditorAccounts.map((item) => {
                    return {
                      label: item.name,
                      value: item.number,
                      type: item.accountType,
                    };
                  })
                : []
            }
            clientAccounts={clientBankAccounts}
            destinyAccounts={
              clientBankAccounts?.length > 0
                ? clientBankAccounts.map((item) => {
                    return {
                      name: item.name,
                      value: item.number,
                    };
                  })
                : []
            }
            fromTicket={true}
            readOnly={true}
            enableReference={false}
            // enableReference={true}
            ticketCompleted={props.ticketCompleted}
            handleState={handleState.bind(
              this,
              setSettlementData,
              settlementData
            )}
            handleAdd={handleModify.bind(
              this,
              setSettlementData,
              settlementData
            )}
            handleReplace={handleModify.bind(
              this,
              setSettlementData,
              settlementData
            )}
            handleEdit={handleModify.bind(
              this,
              setSettlementData,
              settlementData
            )}
            handleDelete={handleDelete.bind(
              this,
              setSettlementData,
              settlementData
            )}
          />
        )}

        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#424242",
            marginLeft: 5,
            marginTop: "2rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: "1rem" }}>
            LISTA DE VERIFICACIÓN DE CRÉDITO
          </span>
          <CheckboxField
            xs={6}
            alignItems="flex-end"
            check={creditVerifyMandatory}
            handleChange={handleCreditVerifyMandatory}
            color="primary"
            textColor="#43A047"
            label={"LLENADO OBLIGATORIO"}
            disabled={Boolean(props.ticketCompleted)}
          />
        </Typography>
        <Divider style={{ marginBottom: "2rem" }} />
        <Grid container item xs={8} direction="column">
          {creditVerifyOptions.map((item, index) => {
            return (
              <div
                key={`${item.name}-${index}`}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <CheckboxField
                  xs={12}
                  alignItems="flex-end"
                  check={Boolean(item.value.state)}
                  handleChange={handleCreditVerify.bind(this, "state", item)}
                  color="primary"
                  textColor="#43A047"
                  label={item.text}
                  disabled={Boolean(props.ticketCompleted)}
                />
                {item.value.state && (
                  <>
                    <AutocompleteField
                      xs={6}
                      containerClass="field-container"
                      fieldClass="fields"
                      typeVariant="subtitle1"
                      typeClass="field-label"
                      fieldLabel="Calificación"
                      fieldID="credit-analysis-score"
                      fieldInnerLabel="Seleccione..."
                      fieldVariant="outlined"
                      value={item.value.score || null}
                      handleChange={handleCreditVerify.bind(
                        this,
                        "score",
                        item
                      )}
                      options={scores}
                      // getOptionLabel={(option) => option.name}
                      multiple={false}
                      disabled={Boolean(props.ticketCompleted)}
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <TextFieldComponent
                        xs={6}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Comentario"
                        fieldID="credit-analysis-comment"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        placeholder="Escriba algún comentario..."
                        margin="dense"
                        fullWidth
                        value={item.value.comment || ""}
                        onChange={handleCreditVerify.bind(
                          this,
                          "comment",
                          item
                        )}
                        multiline={true}
                        rows={4}
                        disabled={Boolean(props.ticketCompleted)}
                      />
                      <Paper
                        component={Grid}
                        item
                        xs={6}
                        style={{ margin: "1rem 0 0 1rem", height: "auto" }}
                      >
                        <List>
                          <ListItem>
                            {/* <ListItemAvatar>
                                    <Grade style={{color:item.required ? '#f76248' : 'transparent', fontSize:18}} />
                                  </ListItemAvatar> 
                              */}
                            <ListItemText primary={"ARCHIVOS ADJUNTOS"} />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                onClick={props.handleToggleDialog.bind(
                                  this,
                                  "manage-files-dialog",
                                  {
                                    id: props.ticket.uniqueId,
                                    requirementName: item.name,
                                    ticketId: props.ticket.uniqueId,
                                    step: props.currentStep,
                                    allReqs: true,
                                    ticketSteps: props.ticket.steps,
                                    handleFileRequirements: handleFileRequirements,
                                  }
                                )}
                              >
                                <AttachFile />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </List>
                      </Paper>
                    </div>
                  </>
                )}
                {index < creditVerifyOptions.length - 1 && item.value.state && (
                  <Divider style={{ margin: "2rem 0 2rem 0" }} />
                )}
              </div>
            );
          })}
        </Grid>
        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#424242",
            marginLeft: 5,
            marginTop: "2rem",
          }}
        >
          RECOMENDACIÓN DEL ANALISTA
        </Typography>
        <Divider style={{ marginBottom: "2rem" }} />
        <TextFieldComponent
          xs={12}
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Recomendación"
          fieldID="credit-analysis-recomendation"
          fieldClass="fields"
          fieldVariant="outlined"
          placeholder="Escriba una recomendación..."
          margin="dense"
          fullWidth
          value={recomendation}
          onChange={handleRecomendationChange}
          multiline={true}
          rows={8}
          disabled={Boolean(props.ticketCompleted)}
        />

        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#424242",
            marginLeft: 5,
            marginTop: "2rem",
          }}
        >
          NUEVA COTIZACIÓN
        </Typography>
        <Divider style={{ marginBottom: "2rem" }} />

        <QuotationSelectInput
          client={props.ticket.customer}
          ticketApprovedQuotation={
            approvalObj.status === "approved" || props.ticketCompleted
          }
          ticketSettlementStep={true}
          quotationData={quotationData}
          selectedQuotationsId={
            (formIds[`quotation`] && [formIds[`quotation`]]) || []
          }
          // addQuotation={handleAddQuotation.bind(this, setFormIds)}
          addQuotation={handleSelectQuotation}
          removeQuotation={handleRemoveQuotation.bind(this, setFormIds)}
        />

        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          direction="row"
          spacing={2}
          style={{ marginLeft: 4 }}
        >
          <Button
            variant="contained"
            className={
              props.ticketCompleted
                ? "action-method-btn disabled"
                : "action-method-btn cancel"
            }
            disableElevation
            style={{ height: 35 }}
            onClick={props.handleToggleSimpleDialog.bind(
              this,
              "confirm-dialog",
              "proceed-cancel-ticket",
              { callback: handleDenyTicket }
            )}
            disabled={Boolean(props.ticketCompleted)}
          >
            DENEGAR PROPUESTA
          </Button>

          {!canApproveQuotation && !["approved"].includes(approvalObj.status) && (
            <Button
              variant="contained"
              className={
                quotationData.quotationStatus !== "on_process" ||
                props.ticketCompleted
                  ? "action-method-btn disabled"
                  : "action-method-btn"
              }
              disableElevation
              style={{ height: 35 }}
              disabled={
                quotationData.quotationStatus !== "on_process" ||
                props.ticketCompleted
              }
              onClick={handleApproveQuotation}
            >
              APROBAR COTIZACIÓN
            </Button>
          )}

          {quotationData.quotationStatus === "approved" ? (
            <>
              {canApproveQuotation ||
              ["approved", "reclassified"].includes(approvalObj.status) ? (
                <>
                  <Button
                    variant="contained"
                    disableElevation
                    style={{ height: 35 }}
                    onClick={modifyApprovalObj.bind(this, "reclassified")}
                    // props.roles.includes('oficial de negocio') ||
                    className={
                      ["approved", "reclassified"].includes(
                        approvalObj.status
                      ) || props.ticketCompleted
                        ? "action-method-btn disabled"
                        : "action-method-btn--blue"
                    }
                    // props.roles.includes('oficial de negocio') ||
                    disabled={
                      ["approved", "reclassified"].includes(
                        approvalObj.status
                      ) || props.ticketCompleted
                    }
                  >
                    RECLASIFICAR
                  </Button>

                  <Button
                    variant="contained"
                    disableElevation
                    style={{ height: 35 }}
                    onClick={modifyApprovalObj.bind(this, "approved")}
                    // props.roles.includes('oficial de negocio') ||
                    className={
                      ["approved", "reclassified"].includes(
                        approvalObj.status
                      ) || props.ticketCompleted
                        ? "action-method-btn disabled"
                        : "action-method-btn--blue"
                    }
                    // props.roles.includes('oficial de negocio') ||
                    disabled={
                      ["approved", "reclassified"].includes(
                        approvalObj.status
                      ) || props.ticketCompleted
                    }
                  >
                    ENVIAR MÓDULO APROBACIÓN
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  // props.roles.includes('oficial de negocio') ||
                  className={
                    approvalObj.status === "pending_approval" ||
                    props.ticketCompleted
                      ? "action-method-btn disabled"
                      : "action-method-btn"
                  }
                  // props.roles.includes('oficial de negocio') ||
                  disableElevation
                  style={{ height: 35 }}
                  disabled={
                    approvalObj.status === "pending_approval" ||
                    props.ticketCompleted
                  }
                  onClick={validateQuotationAmount}
                >
                  VALIDAR COTIZACIÓN
                </Button>
              )}
            </>
          ) : (
            <Button style={{ visibility: "hidden", height: 35 }}>
              DON'T SHOW ME
            </Button>
          )}
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent="flex-end"
          style={{ marginTop: "3rem" }}
        >
          <Button
            variant="contained"
            className={
              !["approved", "denied"].includes(approvalObj.status)
                ? "action-method-btn disabled"
                : "action-method-btn"
            }
            disableElevation
            style={{ height: 35 }}
            disabled={!["approved", "denied"].includes(approvalObj.status)}
            onClick={generateTemplate}
          >
            generar plantilla de analista
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
