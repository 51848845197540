import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
// import TableBody from '@material-ui/core/TableBody';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { deleteProviderContact, fetchProviderContacts } from "../../../actions";
import {
  deleteProviderContact as deleteContact,
  fetchProviderContacts as fetch,
} from "../../../utils/axios";
import ProviderContactForm from "../ProviderContactForm";
import ProviderContactSearchSection from "../ProviderContactSearchSection";

const styles: any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

interface IProviderContactSelectInputProps {
  classes: {
    button: string;
    root: string;
  };

  user: IUser;
  providerContacts: IProviderContact[];
  providerId: string;
  refreshProvider(): void;
  fetchProviderContacts(
    providerContacts: IProviderContact[]
  ): IAction<IProviderContact[]>;
  deleteProviderContact(uniqueId: string): IAction<IProviderContact[]>;
}
interface IProviderContactSelectInputState {
  formDialog: boolean;
  loading: boolean;
  open: boolean;
  providerContacts: IProviderContact[];
  selectedProviderContactId: string;
}

class ProviderContactSelectInput extends React.Component<
  IProviderContactSelectInputProps,
  IProviderContactSelectInputState
> {
  public state: IProviderContactSelectInputState = {
    formDialog: false,
    loading: false,
    open: false,
    providerContacts: [],
    selectedProviderContactId: "",
  };
  constructor(props: IProviderContactSelectInputProps) {
    super(props);

    this.findProviderContacts = this.findProviderContacts.bind(this);
    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  public componentDidMount() {
    this.setState({ loading: true });
    fetch({ providerId: this.props.providerId, token: this.props.user.token })
      .then((res) => {
        this.props.fetchProviderContacts(
          res.data.results ? res.data.results : res.data
        );

        this.setState({
          loading: false,
          providerContacts: (res.data.results
            ? res.data.results
            : res.data) as IProviderContact[],
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public openDialogHandler() {
    this.setState({ open: true, selectedProviderContactId: "" });
  }
  public closeDialogHandler() {
    this.setState({ open: false });
  }
  public openFormDialog() {
    this.setState({ formDialog: true });
  }
  public closeFormDialog() {
    this.setState({ formDialog: false });
  }
  public emptyFunction() {
    return null;
  }

  public handleItemClick(providerContactId: string) {
    this.setState({ loading: true });

    fetch({ providerId: this.props.providerId, token: this.props.user.token })
      .then((res) => {
        this.props.fetchProviderContacts(
          res.data.results ? res.data.results : res.data
        );

        this.setState(
          {
            loading: false,
            open: false,
            // providerContacts: res.data as IProviderContact[],
          },
          () => {
            this.props.refreshProvider();
          }
        );
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleEditItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.setState(
        {
          selectedProviderContactId: id,
        },
        () => this.openFormDialog()
      );
      // deleteContact(this.props.user.token, id).then(() => this.props.refreshProvider());
    };
  }
  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      if (confirm(translations.ARE_YOU_SURE)) {
        deleteContact(this.props.user.token, id).then(() =>
          this.props.deleteProviderContact(id)
        );
      }
    };
  }

  public findProviderContacts(uniqueId: string) {
    const curProviderContact:
      | IProviderContact
      | undefined = this.props.providerContacts.find(
      (provider) => provider.uniqueId === uniqueId
    );

    if (curProviderContact) {
      return (
        <TableRow key={curProviderContact.uniqueId}>
          <TableCell>{curProviderContact.referenceCode}</TableCell>
          <TableCell>{curProviderContact.firstName}</TableCell>
          <TableCell>{curProviderContact.position}</TableCell>
          <TableCell>
            <IconButton
              onClick={this.handleEditItem(
                curProviderContact.uniqueId as string
              )}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={this.handleDeleteItem(
                curProviderContact.uniqueId as string
              )}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    } else {
      return null;
    }
  }
  public fetchData(params: IFetchProviderContactParams) {
    params.limit = undefined;
    params.offset = 0;

    this.setState({ loading: true });

    fetch(params)
      .then((res) => {
        this.props.fetchProviderContacts(
          (res.data.results ? res.data.results : res.data) as IProviderContact[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }
  public render() {
    const { classes, providerContacts } = this.props;
    const { formDialog, open, loading } = this.state;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={this.closeDialogHandler}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.openFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: 600, width: 280 }}>
            <ProviderContactSearchSection
              loading={loading}
              handleClick={this.handleItemClick}
              fetchData={this.fetchData}
              pagination={false}
              providerId={this.props.providerId}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={formDialog}
          fullScreen={true}
          onClose={this.closeFormDialog}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <ProviderContactForm
              changeSection={this.emptyFunction}
              providerId={this.props.providerId}
              providerContactId={this.state.selectedProviderContactId}
            />
          </DialogContent>
        </Dialog>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.REFERENCE_NUMBER}</TableCell>
                <TableCell>{translations.FULL_NAME}</TableCell>
                <TableCell>{translations.POSITION}</TableCell>
                <TableCell>
                  <IconButton onClick={this.openFormDialog}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providerContacts.map((providerContact) =>
                this.findProviderContacts(providerContact.uniqueId as string)
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    providerContacts: state.providerContacts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchProviderContacts, deleteProviderContact })(
    ProviderContactSelectInput
  )
);
