import React from "react";
import {
  Button,
  TextField,
  createStyles,
} from "@material-ui/core";
import { translations } from "src/utils";
import MoneyInput from "src/components/TextMaskInputComponents/moneyInput";

const styles = createStyles({
  clean: {
    background: "#F44636",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  formControl: {
    margin: "0px 4px",
  },
  save: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  textField: {
    margin: "0px 4px",
    width: "46%",
  },
});

class LastDepositForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: "",
      date: "",
      errors: {},
      id: 0,
      proportion: "0",
    };
    this.initialValues = {
      ...this.state,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { recordForEdit } = this.props;
    if (recordForEdit != null) {
      this.setState({
        ...recordForEdit,
      });
    }
  }

  handleCurrencyChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || "" });
    };
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleSelectChange(key) {
    return (event) => {
      this.setState({
        [key]: event.target.value,
      });
    };
  }

  handleReset() {
    this.setState({ ...this.initialValues });
  }

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };
    if ("amount" in fieldValues) {
      if (fieldValues.amount > this.props.salary) {
        temp.amount = "El monto no debe ser mayor al salario.";
      } else {
        temp.amount = fieldValues.amount ? "" : "Este campo es requerido.";
      }
    }
    if ("date" in fieldValues) {
      temp.date = fieldValues.date ? "" : "Este campo es requerido.";
    }

    this.setState({
      errors: { ...temp },
    });

    if (fieldValues === this.state) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const state = {
      amount: this.state.amount,
      date: this.state.date,
      id: this.state.id,
      proportion: this.state.proportion,
    };
    if (this.validate()) {
      this.props.addOrEdit(state);
    }
  };

  render() {
    const classes = styles;
    const { recordForEdit } = this.props;
    const { errors } = this.state;

    return (
      <form style={{ width: "auto" }}>
        <TextField
          label={translations.AMOUNT_2}
          onChange={this.handleCurrencyChange("amount")}
          error={errors.amount ? true : false}
          helperText={errors.amount}
          value={this.state.amount}
          fullWidth={true}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: MoneyInput,
          }}
        />
        <br />
        <br />
        <TextField
          label={translations.DEPOSIT_DATE}
          value={this.state.date}
          type="date"
          error={errors.date ? true : false}
          helperText={errors.date}
          onChange={this.handleChange("date")}
          fullWidth={true}
          inputProps={{max: new Date().toISOString().split("T")[0]}}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {recordForEdit ? (
          <>
            <br />
            <br />
            <TextField
              label="Proporcion"
              value={`${this.state.proportion} %`}
              disabled={true}
              onChange={this.handleChange("proportion")}
              fullWidth={true}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </>
        ) : null}
        <br />
        <br />
        <div>
          <Button style={classes.save} onClick={this.handleSubmit}>
            Guardar
          </Button>
          <Button style={classes.clean} onClick={this.handleReset}>
            Limpiar
          </Button>
        </div>
      </form>
    );
  }
}
export default LastDepositForm;
