
import * as React from 'react';
import { connect } from 'react-redux';

import { createStyles, withStyles } from '@material-ui/core/styles';

import AccountingRecordSection from '../AccountingRecords/AccountingRecordSection';
import AcquaintanceshipCompanyContract from '../AcquaintanceshipCompanyContract/Section';
import AcquaintanceshipPersonContract from '../AcquaintanceshipPersonContract/Section';
import AddendumContractSection from '../AddendumContract/AddendumContractSection';
import BankAccountSection from '../BankAccount/BankAccountSection';
import BankReconciliation from '../BankReconciliation/View';
import ChartOfAccounts from '../ChartOfAccounts/ChartOfAccountsSection';
import BlackListSection from '../BlackList/BlackListSection';
import CheckSection from '../Check/CheckSection';
import CreditCardSection from '../CreditCard/CreditCardSection';
import CreditorSection from '../Creditor/CreditorSection';
// import DebitAuthorizationContractSection from '../DebitAuthorizationContract/DebitAuthorizationContractSection';
import AutomaticDebitAuthorizationContractSection from '../AutomaticDebitAuthorizationContract/AutomaticDebitAuthorizationContractSection';
import CreditReviewAuthorization from '../CreditReviewAuthorization/CreditReviewAuthorizationSection';
import DepositBankAccountSection from '../DepositBankAccount/DepositBankAccountSection';
import ExpiryView from '../Expiry/View';
import FinancialInstitutionSection from '../FinancialInstitution/FinancialInstitutionSection';
import FlierSecetion from '../Flier/FlierSection';
import InternationalBlackListSection from '../InternationalBlackList/InternationalBlackListSection';
import LoanContractSection from '../LoanContract/LoanContractSection';
import LeadsView from '../Leads/View';
import NationalitySection from '../Nationality/NationalitySection';
import NotarialPayContractSection from '../NotarialPayContract/NotarialPayContractSection';
import OutlayCashSection from '../OutlayCash/OutlayCashSection';
import OutlayCheckSection from '../OutlayCheck/OutlayCheckSection';
import OutlaySection from '../Outlay/OutlaySection';
import PepsListSection from '../PepsList/PepsListSection';
import OFACAndONUListSection from '../OFACAndONUList/OFACAndONUListSection';
import PaymentView from '../Pay/View';
import PayReceiptView from '../PayReceipt/View';
import PrivateInstitutionSection from '../PrivateInstitution/PrivateInstitutionSection';
import ProfessionsSection from '../Profession/ProfessionSection';
import ProviderSection from '../Provider/ProviderSection';
import PublicInstitutionSection from '../PublicInstitution/PublicInstitutionSection';
import QuotationSection from '../QuotationFiles/QuotationSection';
// import TicketsSection from '../TicketsDocs/TicketsSection';
import AcquaintanceshipProviderContract from '../AcquaintanceshipProviderContract/Section';
// import MembershipApplicationSection from '../MembershipApplication/Section';
import MembershipApplicationSection from '../MembershipAdmission/Section';
import SavingsAccountSection from '../SavingsAccount/Section';
import DebitCardOrPassbookAccountContractSection from '../DebitCardOrPassbookAccountContract/Section';
import CancellationMembershipSection from '../CancellationMembership/Section';
import InputCertificationSection from '../InputCertification/Section';
import FinancialCertificatesSection from '../FinancialCertificates/Section';
import WarrantySection from '../Warranty/WarrantySection';
import InterestSection from '../Interest/InterestSection';
import PayrollDeductionSection from '../PayrollDeduction/Section';
import AffidavitOfFundsSection from '../AffidavitOfFunds/Section';
import ClientAffidavitOfFundsSection from '../ClientAffidavitOfFunds/Section'
import ComplianceRegistrationFormSection from '../ComplianceRegistrationForm/Section'
// import ExpirationSection from '../Expiration/ExpirationSection/index';
import PledgeContractWithoutDivestment from '../PledgeContractWithoutDivestment/Section'
import AutomaticWithdrawalAuthorizationContract from '../AutomaticWithdrawalAuthorizationContract/Section'
import ActSection from '../Act/ActSection';
import ApprovementAdmissionView from '../ApprovementAdmission/View'
import ApprovementLoanView from '../ApprovementLoan/View'
import Calculators from '../../components/Calculators';
// RECLAMACIONES
import ClaimView from '../Claim/View';
import AccountStatus from '../../containers/AccountStatus';
import ElectronicMeansServiceContract from '../ElectronicMeansServiceContract/Section';
import BusinessAgentView from '../BusinessRepresentatives/View';
//REPORTS
import DelayReports from '../Reports/DelayReports/delayreport';
import DiscountReports from '../DiscountReports/view';
import TransactionReports from '../Reports/TransactionsReports/View'
import InsuranceReport from '../Reports/InsuranceReport/View';
import ClientsMainSection from '../Participant/ClientsMainSection';


const styles: any = createStyles({
    root: {
        bottom: 0,
        boxSizing: 'border-box',
        height: '100vh',
        margin: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingLeft: 250,
        position: 'static',
        top: 0,
    }
});

interface IMainSectionProps {
    classes: {
        root: string
    }
    selectedSection: number
}

const sections: JSX.Element[] = [
    // Documents
    <div key={0}><p style={{ display: 'none' }}>none</p></div>,
    <LoanContractSection key={1} />,
    <NotarialPayContractSection key={2} />,

    <div key={3}><p style={{ display: 'none' }}>none</p></div>,
    <AcquaintanceshipPersonContract key={4} />,
    <AcquaintanceshipCompanyContract key={5} />,
    <AcquaintanceshipProviderContract key={6} />,
    <MembershipApplicationSection key={7} />,

    <AddendumContractSection key={8} />,
    <AutomaticDebitAuthorizationContractSection key={9}/>,
    <PayrollDeductionSection key={10} />,
    <Calculators key={11} />,
    <QuotationSection key={12} />,
    <CreditReviewAuthorization key={13} />,
    <CancellationMembershipSection key={14} />,

    <div key={15}><p style={{ display: 'none' }}>none</p></div>,
    <DebitCardOrPassbookAccountContractSection key={16} />,
    <SavingsAccountSection key={17} />,
    <FinancialCertificatesSection key={18} />,
    <InputCertificationSection key={19} />,

    // Participants
    <div key={20}><p style={{ display: 'none' }}>none</p></div>,
    <ClientsMainSection key={21} />,
    <CreditorSection key={22} />,
    <ProviderSection key={23} />,

    // Institutions
    <div key={24}><p style={{ display: 'none' }}>none</p></div>,
    <FinancialInstitutionSection key={25} />,
    <PrivateInstitutionSection key={26} />,
    <PublicInstitutionSection key={27} />,

    // Bank Accounts
    <div key={28}><p style={{ display: 'none' }}>none</p></div>,
    <BankAccountSection key={29} />,
    <DepositBankAccountSection key={30} />,

    // Transactions
    <div key={31}><p style={{ display: 'none' }}>none</p></div>,
    <div key={32}><p style={{ display: 'none' }}>none</p></div>,
    <AccountingRecordSection key={33} />,
    <ChartOfAccounts key={34} />,
    <BankReconciliation key={35}/>,

    <div key={36}><p style={{ display: 'none' }}>none</p></div>,
    <OutlayCheckSection key={37} />,
    <OutlayCashSection key={38} />,

    <div key={39}><p style={{ display: 'none' }}>none</p></div>,
    <OutlaySection key={40} />,
    <ExpiryView key={41}/>,
    <PaymentView key={42}/>,

    // Settings
    <div key={43}><p style={{ display: 'none' }}>none</p></div>,
    <NationalitySection key={44} />,
    <CreditCardSection key={45} />,
    <FlierSecetion key={46} />,
    <CheckSection key={47} />,

    <div key={48}><p style={{ display: 'none' }}>none</p></div>,
    <BlackListSection key={49} />,
    <InternationalBlackListSection key={50}/>,
    <PepsListSection key={51}/>,
    <OFACAndONUListSection key={52}/>,

    <ProfessionsSection key={53} />,
    <WarrantySection key={54} />,
    <InterestSection key={55} />,

    <div key={56}><p style={{ display: 'none' }}>none</p></div>,
    <AffidavitOfFundsSection key={57} />,
    <ClientAffidavitOfFundsSection key={58} />,
    <ComplianceRegistrationFormSection key={59} />,
    <PledgeContractWithoutDivestment key={60} />,
    <AutomaticWithdrawalAuthorizationContract key={61} />,

    <div key={62}><p style={{ display: 'none' }}>none</p></div>,
    <ActSection key={63} />,
    <ApprovementAdmissionView key={64}/>,
    <ApprovementLoanView key={65}/>,
    <PayReceiptView key={66} />,

    // RECLAMACIONES
    <div key={67}><p style={{ display: 'none' }}>none</p></div>,
    <ClaimView key={68} />,
    
    <AccountStatus key={69} />,
    // LEADS
    <div key={70}><p style={{ display: 'none' }}>none</p></div>,
    <LeadsView key={71} />,
    <ElectronicMeansServiceContract key={72} />,
    <BusinessAgentView key={73} />,

    //REPORTS
    <div key={74}><p style={{display: 'none'}}>none</p></div>,
    <DelayReports key={75}/>,
    <DiscountReports key={76}/>,
    <TransactionReports key={77}/>,
    <InsuranceReport key={78}/>
];


function MainSection(props: IMainSectionProps) {
    const { classes, selectedSection } = props;
    return (
        <div className={classes.root}>
            {sections[selectedSection]===undefined?sections.find(m => m.key === selectedSection):sections[selectedSection]}
        </div>
    );
}

function mapStateTopProps(state: IState) {
    return {
        selectedSection: state.selectedSection
    }
}

export default connect(mapStateTopProps) (
    withStyles(styles)(MainSection)
);