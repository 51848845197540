import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createNationality } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import { createNationality as create } from "../../../utils/axios";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface INationalityFormProps {
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    formControl: string;
    greenButton: string;
    input: string;
    paper: string;
    root: string;
    textField: string;
  };

  user: IUser;
  createNationality(nationality: INationality): IAction<INationality[]>;
  changeSection(sectionNumber: number): void;
}
interface INationalityFormState {
  [key: string]: string | string[] | number | boolean;

  name: string;
  nameError: string;

  denomynForMale: string;
  denomynForMaleError: string;

  denomynForFemale: string;
  denomynForFemaleError: string;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
}
class NationalityForm extends React.Component<
  INationalityFormProps,
  INationalityFormState
> {
  public state: INationalityFormState = {
    denomynForFemale: "",
    denomynForFemaleError: "",
    denomynForMale: "",
    denomynForMaleError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    name: "República Dominicana",
    nameError: "",
  };

  constructor(props: INationalityFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);

    this.handleAddBank = this.handleAddBank.bind(this);
    this.handleDeleteBank = this.handleDeleteBank.bind(this);

    this.handleAddParticipant = this.handleAddParticipant.bind(this);
    this.handleDeleteParticipant = this.handleDeleteParticipant.bind(this);
    this.handleCheckInputChange = this.handleCheckInputChange.bind(this);
  }

  public handleCheckInputChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public handleAddBank(id: string) {
    this.setState({ bank: [id] });
  }
  public handleDeleteBank(id: string) {
    this.setState({ bank: [] });
  }
  public handleAddParticipant(id: string) {
    this.setState({ owner: [id] });
  }
  public handleDeleteParticipant(id: string) {
    this.setState({ owner: [] });
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    const nationality: INationality = {
      denomynForFemale: titleCaseHandler(this.state.denomynForFemale),
      denomynForMale: titleCaseHandler(this.state.denomynForMale),
      name: titleCaseHandler(this.state.name),
    };

    this.setState({ loading: true });

    create(this.props.user.token, nationality)
      .then((res) => {
        this.props.createNationality(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, translations.NATIONALITY);
        this.handleReset();
      })
      .catch((err: any) => {
        catchError(err, this);
      });
  }
  public handleReset() {
    this.setState({
      denomynForFemale: "",
      denomynForFemaleError: "",
      denomynForMale: "",
      denomynForMaleError: "",
      name: "",
      nameError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.NATIONALITIES}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={titleCaseHandler(this.state.name)}
                onChange={this.handleChange("name")}
                fullWidth={true}
                required={true}
                error={this.state.nameError !== ""}
                helperText={this.state.nameError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />

              <TextField
                label={translations.DEMOMYN_MALE}
                className={classes.textField}
                value={titleCaseHandler(this.state.denomynForMale)}
                onChange={this.handleChange("denomynForMale")}
                fullWidth={true}
                required={true}
                error={this.state.denomynForMaleError !== ""}
                helperText={this.state.denomynForMaleError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />

              <TextField
                label={translations.DEMOMYN_FEMALE}
                className={classes.textField}
                value={titleCaseHandler(this.state.denomynForFemale)}
                onChange={this.handleChange("denomynForFemale")}
                fullWidth={true}
                required={true}
                error={this.state.denomynForFemaleError !== ""}
                helperText={this.state.denomynForFemaleError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createNationality })(NationalityForm)
);
