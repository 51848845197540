import React, { useState } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import {
  SearchComponent,
  TextFieldComponent,
} from "src/components/SharedComponents/Fields";
import { ClearAll } from "@material-ui/icons";
import { catchError, participantType } from "src/utils";
import { AutocompleteField } from "src/components/SharedComponents/Fields";

const defaultFilterData = {
  associateType: null,
  associate: null,
  rohiId: "",
  institution: null,
  representative: null,
  profession: null,
};

function Filter(props) {
  const [filterData, setFilterData] = useState(defaultFilterData);

  const filterFieldHandler = (field, event, val) => {
    let value = val ? val : event && event.target ? event.target.value : event;
    setFilterData({ ...filterData, [field]: value });
  };

  const clearFilters = () => {
    setFilterData(defaultFilterData);
    props.setFilters({});
  };

  const applyFilters = () => {
    const fields = {
      participantType: filterData.associateType?.value,
      identificationNumber: filterData.associate?.identificationNumber,
      rohiId: filterData.rohiId,
      work: filterData.institution?.name,
      businessAgents: filterData.representative?.relatedUser,
      profession: filterData.profession?.label
    };
    Object.keys(fields).forEach((key) => {
      if (!fields[key] || fields[key].length < 1) {
        delete fields[key];
      }
    });

    props.setFilters(fields);
  };

  return (
    <Grid className="filters-container">
      <Grid item container spacing={3} direction="row">
        <AutocompleteField
          xs={4}
          containerClass="field-container"
          fieldClass="fields"
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Tipo de socio"
          fieldID="filter-field-priority"
          fieldInnerLabel="Seleccione..."
          fieldVariant="outlined"
          value={filterData.associateType}
          handleChange={filterFieldHandler.bind(this, "associateType")}
          options={participantType}
          getOptionLabel={(option) => option.label}
          limitTags={1}
        />
        <SearchComponent
          xs={4}
          containerClass="field-container"
          fieldLabel="Socio"
          fieldID="filter-field-participant"
          fieldInnerLabel="Seleccione el socio"
          value={filterData.associate}
          handleChange={filterFieldHandler.bind(this, "associate")}
          optionType={"participants"}
          clearOnBlur={false}
          limitTags={1}
        />
        <TextFieldComponent
          xs={4}
          fullWidth
          fieldLabel="Rohi ID"
          fieldID="field-rohiID"
          placeholder="Ingrese el ID de Rohi"
          value={filterData.rohiId}
          onChange={filterFieldHandler.bind(this, "rohiId")}
        />
        <SearchComponent
          xs={4}
          containerClass="field-container"
          fieldLabel="Trabajo"
          fieldID="filter-field-work"
          fieldInnerLabel="Lugar de trabajo"
          handleChange={filterFieldHandler.bind(this, "institution")}
          onInputChange={() => {}}
          optionType={"institutions"}
          clearOnBlur={false}
          limitTags={1}
        />
        <AutocompleteField
          xs={4}
          containerClass="field-container"
          fieldClass="fields"
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Representante"
          fieldID="filter-field-priority"
          fieldInnerLabel="Seleccione..."
          fieldVariant="outlined"
          value={filterData.representative}
          handleChange={filterFieldHandler.bind(this, "representative")}
          options={props.representatives}
          getOptionLabel={(option) => option.name}
          limitTags={1}
        />
        <AutocompleteField
          xs={4}
          containerClass="field-container"
          fieldClass="fields"
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Profesión"
          fieldID="filter-field-priority"
          fieldInnerLabel="Seleccione..."
          fieldVariant="outlined"
          value={filterData.profession}
          handleChange={filterFieldHandler.bind(this, "profession")}
          options={props.professions}
          getOptionLabel={(option) => option.label}
          limitTags={1}
        />
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginTop: "35px", justifyContent: "space-between" }}
      >
        <Button
          disableElevation
          className="filter-clear-btn"
          onClick={clearFilters.bind(this, false)}
        >
          limpiar
          <ClearAll style={{ marginLeft: 8, fontSize: "19px" }} />
        </Button>
        <Button
          disableElevation
          className="filter-apply-btn"
          onClick={applyFilters}
        >
          aplicar filtros
        </Button>
      </Grid>
    </Grid>
  );
}

export default Filter;
