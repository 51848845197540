import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AccountSelection } from './../AccountSelection';
import { AccountDetailView } from './../AccountDetail';
import { ToolbarComponent } from './../../../components/SharedComponents/Toolbar';
import { ReconciliationDialog } from './../Dialogs';
import './../index.css';

//create the data

// banks account data
const bankAccounts = [
    {
        enterprise_name: 'Leo Muebles',
        enterprise_id: 1,
        total_balance: '2,000,000.00',
        bank_accounts: [
            {
                id: 1,
                bank_id: 1,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '150,000.20',
                bank_name: 'Scotiabank',
            },
            {
                id: 2,
                bank_id: 2,
                account: '000000000',
                status: 'Conciliado',
                balance: '210,000.10',
                bank_name: 'Banco Popular Dominicano'
            },
            {
                id: 3,
                bank_id: 3,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
            {
                id: 4,
                bank_id: 4,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
            {
                id: 5,
                bank_id: 5,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
            {
                id: 6,
                bank_id: 6,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
            {
                id: 7,
                bank_id: 6,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
            {
                id: 8,
                bank_id: 6,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
            {
                id: 9,
                bank_id: 6,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
        ]
    },
    {
        enterprise_name: 'República Digital Eduación',
        enterprise_id: 2,
        total_balance: '4,130,560.87',
        bank_accounts: [
            {
                id: 4,
                bank_id: 4,
                account: '000000000',
                status: 'Conciliado',
                balance: '10,000.20',
                bank_name: 'Scotiabank',
            },
            {
                id: 5,
                bank_id: 5,
                account: '000000000',
                status: 'Conciliado',
                balance: '210,000.10',
                bank_name: 'Banco Popular Dominicano'
            },
            {
                id: 6,
                bank_id: 6,
                account: '000000000',
                status: 'Conciliado',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
        ]
    },
    {
        enterprise_name: 'Inversiones España',
        enterprise_id: 3,
        total_balance: '1,359,487.19',
        bank_accounts: [
            {
                id: 7,
                bank_id: 7,
                account: '000000000',
                status: 'Conciliado',
                balance: '150,000.20',
                bank_name: 'Scotiabank',
            },
            {
                id: 8,
                bank_id: 8,
                account: '000000000',
                status: 'Conciliado',
                balance: '210,000.10',
                bank_name: 'Banco Popular Dominicano'
            },
            {
                id: 9,
                bank_id: 9,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
        ]
    },
    {
        enterprise_name: 'Organization Enterprise',
        enterprise_id: 4,
        total_balance: '1,359,487.19',
        bank_accounts: [
            {
                id: 7,
                bank_id: 7,
                account: '000000000',
                status: 'Conciliado',
                balance: '150,000.20',
                bank_name: 'Scotiabank',
            },
            {
                id: 8,
                bank_id: 8,
                account: '000000000',
                status: 'Conciliado',
                balance: '210,000.10',
                bank_name: 'Banco Popular Dominicano'
            },
            {
                id: 9,
                bank_id: 9,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
        ]
    },
    {
        enterprise_name: 'Jujutsu Kaisen',
        enterprise_id: 5,
        total_balance: '1,359,487.19',
        bank_accounts: [
            {
                id: 7,
                bank_id: 7,
                account: '000000000',
                status: 'Conciliado',
                balance: '150,000.20',
                bank_name: 'Scotiabank',
            },
            {
                id: 8,
                bank_id: 8,
                account: '000000000',
                status: 'Conciliado',
                balance: '210,000.10',
                bank_name: 'Banco Popular Dominicano'
            },
            {
                id: 9,
                bank_id: 9,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
        ]
    },
    {
        enterprise_name: 'IRRC GGF',
        enterprise_id: 6,
        total_balance: '1,359,487.19',
        bank_accounts: [
            {
                id: 7,
                bank_id: 7,
                account: '000000000',
                status: 'Conciliado',
                balance: '150,000.20',
                bank_name: 'Scotiabank',
            },
            {
                id: 8,
                bank_id: 8,
                account: '000000000',
                status: 'Conciliado',
                balance: '210,000.10',
                bank_name: 'Banco Popular Dominicano'
            },
            {
                id: 9,
                bank_id: 9,
                account: '000000000',
                status: 'Pendiente por conciliar',
                balance: '93,000.42',
                bank_name: 'Banreservas'
            },
        ]
    },
];

// book movement data
const accountData = [
    {
        bank_account: '000000000',
        bank_name: 'some name lol',
        account_status: 'Pendiente por conciliar',
        account_balance: '10,120,100.55',
        transactions: [
            {
                status:'En tránsito',
                reference: '1111111111',
                date: '10/01/2020',
                amount: '10,580.34',
                amount_type: 'débito',
                financial_status: 'Conciliado',
                accounting_status: 'N/A',
                balance: '10,000.00',
                concept: 'ALGUN CONCEPTO PREDETERMINADO'
            },
            {
                status:'Conciliado',
                reference: '1111111112',
                date: '20/01/2020',
                amount: '10,580.34',
                amount_type: 'crédito',
                financial_status: 'Pendiente',
                accounting_status: 'N/A',
                balance: '10,000.00',
                concept: 'ALGUN CONCEPTO PREDETERMINADO 5'
            },
            {
                status:'Conciliado',
                reference: '1111111113',
                date: '30/01/2020',
                amount: '10,580.34',
                amount_type: 'crédito',
                financial_status: 'Conciliado',
                accounting_status: 'N/A',
                balance: '10,000.00',
                concept: 'ALGUN CONCEPTO PREDETERMINADO 93'
            },
            {
                status:'En tránsito',
                reference: '1111111114',
                date: '30/01/2020',
                amount: '10,580.34',
                amount_type: 'débito',
                financial_status: 'Pendiente',
                accounting_status: 'N/A',
                balance: '37,310.00',
                concept: 'ALGUN CONCEPTO PREDETERMINADO 2'
            },
            {
                status:'En tránsito',
                reference: '1111111115',
                date: '30/01/2020',
                amount: '10,580.34',
                amount_type: 'débito',
                financial_status: 'Pendiente',
                accounting_status: 'N/A',
                balance: '9,940.00',
                concept: 'ALGUN CONCEPTO PREDETERMINADO'
            },
        ],

    },
];

const retreivedData = [
    {
        status:'En tránsito',
        reference: '1111111111',
        date: '10/01/2020',
        amount: '10,580.34',
        amount_type: 'débito',
    },
    {
        status:'Conciliado',
        reference: '1111111112',
        date: '20/01/2020',
        amount: '10,580.34',
        amount_type: 'crédito',
    },
    {
        status:'Conciliado',
        reference: '1111111113',
        date: '30/01/2020',
        amount: '10,580.34',
        amount_type: 'crédito',
    },
    {
        status:'En tránsito',
        reference: '1111111114',
        date: '30/01/2020',
        amount: '10,580.34',
        amount_type: 'débito',
    },
    {
        status:'En tránsito',
        reference: '1111111115',
        date: '30/01/2020',
        amount: '10,580.34',
        amount_type: 'débito',
    },
];

function BankReconciliation() {

    // const execRetreiveTranJoins = (action, data) => {
    //     //do stuff to retreive data from backend
    //     // get data based on action: 
    //         // Reconcile => get possible transactions;
    //         // Restore => get reconciled transactions;
    //     return retreivedData;
    // }

    const [showDetail, setShowDetail] = useState(false);
    const [selectedAccountData, setSelectedAccountData] = useState({});
    const handleDetailToggle = (selectedAccount) => {
        setShowDetail(!showDetail);
        const data = accountData.find(item => item.bank_account === selectedAccount.account);
        setSelectedAccountData(data);
    }

    const [selectedDialog, setDialog] = useState('');
    // const [dialogData, setDialogData] = useState([]);
    let dialog = undefined;
    const handleDialogToggle = (action, data) => {
        // if(data) {
        //     // const info = retreiveTranJoins(dialogName, data.reference);
        //     // setDialogData(info);
        //     const dialogData = retreivedData;
        // }
        //await for the response to be received...
        if(['reconcile', 'restore'].includes(action)) {
            dialog =
                <ReconciliationDialog
                    open={true}
                    handleDialogToggle={handleDialogToggle}
                    // handleConfirmDialog={handleConfirmDialog}
                    // data={dialogData}
                    data={retreivedData}
                    actionType={action}
                />;
        }else{
            dialog = undefined;
        }
        setDialog(dialog);
    }
    

    return(
        <div style={{padding:'20px 20px 10% 20px'}}>
            <ToolbarComponent 
                typeVariant="h6"
                typeClass="title-text"
                toolbarTitle="Conciliación Bancaria"
                btnVariant="contained"
                goBackColor="#424242"
                goBack={handleDetailToggle}
                showGoBack={showDetail}
            />
            <div style={{
                padding: '1%', border:'1px solid #FAFAFA', 
                borderTop:'none', backgroundColor:'#FAFAFA',
                marginTop:2
            }}
            >
                {
                    showDetail ? 
                        <AccountDetailView
                            accountData={selectedAccountData}
                            handleDialogToggle={handleDialogToggle}
                        />
                    : 
                        <AccountSelection 
                            accountsData={bankAccounts} 
                            handleShowDetail={handleDetailToggle}
                        />
                }
            </div>
            { selectedDialog }
        </div>
    )
}

function mapStateToProps(state) {
    return {
      user: state.user,
    };
}
export default connect(mapStateToProps)(BankReconciliation);