import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GeneralTable } from "src/components/SharedComponents/Table";
import {
  fetchBusinessRepresentatives,
  submitRohiAgents,
} from "src/utils/axios";
import {
  SuccessDialog,
  ErrorDialog,
  SimpleConfirmDialog,
} from "../../../components/SharedComponents/Dialogs";
import {
  errorHandling,
  handleToggleDialog as handleDialog,
  handleToggleSimpleDialog as handleSimpleDialog,
} from "../../../components/SharedComponents/CustomHooks";
import LoadingDialog from "../../../components/SharedComponents/LoadingScreen";
import { ToolbarComponent } from "src/components/SharedComponents/Toolbar";
import { Button, IconButton } from "@material-ui/core";
import { Add, Settings, Delete } from "@material-ui/icons";
import { Filters } from "../Filters";
import { green } from "@material-ui/core/colors";
import { TableSearch } from "src/components/SharedComponents/Fields";
import { BusinessAgentsTable } from "../Tables";
import {
  BusinessAgentDialog,
  ConfirmDialogComponent,
  MassAssignmentDialog,
} from "../Dialogs";

function BusinessAgentView(props) {
  const limitRequest = { limit: 25 };
  const dialogTypes = ["business-agent"];
  const [businessAgents, setBusinessAgents] = useState([]);
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});

  const handleToggleDialog = handleDialog.bind(this, setDialog, dialog);
  const handleToggleSimpleDialog = handleSimpleDialog.bind(
    this,
    setSimpleDialog
  );

  const getBusinessAgents = (data) => {
    setLoading(true);
    const endpoint = fetchBusinessRepresentatives({
      ...data,
      token: props.user.token,
    });
    endpoint
      .then((response) => {
        const data = response.data.results
          ? response.data.results
          : response.data;
        setBusinessAgents(data);
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const submitRohiAgent = (data) => {
    setLoading(true);
    const endpoint = submitRohiAgents(props.user.token, data.uniqueId);
    endpoint
      .then((response) => {
        handleToggleSimpleDialog("success-dialog");
        getBusinessAgents();
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const handleOnCreate = () => {
    getBusinessAgents(selectedFilters);
  };

  const applyFilters = (fields) => {
    let data = fields;
    if (Object.keys(fields).length === 0) {
      data = limitRequest;
    }
    getBusinessAgents(data);
    setSelectedFilters({ ...data });
  };

  useEffect(() => {
    getBusinessAgents(limitRequest);
  }, []);

  return (
    <div style={{ padding: "20px 20px 10% 20px" }}>
      <LoadingDialog open={loading} />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle="Representantes de Negocios"
        btnVariant="contained"
        toolbarActions={
          <>
            <Button
              variant="contained"
              className={"action-method-btn"}
              size="small"
              disableElevation
              onClick={() => handleToggleDialog("business-agent")}
            >
              <Add
                style={{ fontSize: 20, color: "#fff", marginRight: ".2rem" }}
              />
              crear representante
            </Button>
            <Button
              variant="contained"
              className={"action-method-btn"}
              size="small"
              disableElevation
              onClick={() =>
                handleToggleDialog("business-agent-mass-assignment")
              }
            >
              <Add
                style={{ fontSize: 20, color: "#fff", marginRight: ".2rem" }}
              />
              reasignar representantes
            </Button>
          </>
        }
      />
      <div
        style={{
          padding: "1%",
          border: "1px solid #EEEEEE",
          borderTop: "none",
        }}
      >
        <div style={{ margin: "10px 0" }}></div>
        <BusinessAgentsTable
          data={businessAgents}
          handleToggleDialog={handleToggleDialog}
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          handleSubmitAgent={submitRohiAgent}
        />
      </div>
      {dialog["business-agent"]?.open ? (
        <BusinessAgentDialog
          open={true}
          handleToggleDialog={handleToggleDialog}
          handleSimpleDialog={handleToggleSimpleDialog}
          // modify={dialog.name === 'business-agent-modify' ? true : false}
          modify={dialog["business-agent"].data ? true : false}
          // data={dialog.data}
          data={dialog["business-agent"].data}
          handleAgent={{
            onCreate: handleOnCreate,
            onUpdate: handleOnCreate,
          }}
          setLoading={setLoading}
          token={props.user.token}
        />
      ) : null}
      {dialog["business-agent-mass-assignment"]?.open && (
        <MassAssignmentDialog
          open={true}
          handleToggleDialog={handleToggleDialog}
          handleConfirm={handleToggleSimpleDialog}
          token={props.user.token}
          setLoading={setLoading}
        />
      )}
      {simpleDialog.name === "confirm-dialog" ? (
        <ConfirmDialogComponent
          open={true}
          data={simpleDialog}
          handleToggle={handleToggleSimpleDialog}
        />
      ) : null}

      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}
      {simpleDialog.name === "error-dialog" ? (
        <ErrorDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps)(BusinessAgentView);
