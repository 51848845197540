import { ATTORNEY_TYPES } from '../actions';

const initialState: IParticipant[] = []; 
 
export default function(state: IParticipant[] = initialState, action: IAction<IParticipant[]>): IParticipant[] {
    switch(action.type) {
        case ATTORNEY_TYPES.FETCH_ALL:
            return action.payload || [];
        case ATTORNEY_TYPES.FETCH:
            return state.map(attorney =>(action.payload !== undefined && attorney.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: attorney);
        case ATTORNEY_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case ATTORNEY_TYPES.UPDATE:
            return state.map(attorney => (action.payload !== undefined && attorney.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : attorney);
        case ATTORNEY_TYPES.DELETE:
            return state.filter( attorney => action.payload !== undefined && attorney.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}

// need to fix FETCH AND update