import { BLACKLIST_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case BLACKLIST_TYPES.FETCH_ALL:
            return action.payload || [];
        case BLACKLIST_TYPES.FETCH:
            return state.map(blacklist => (action.payload !== undefined && blacklist.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: blacklist);
        case BLACKLIST_TYPES.CREATE: 
            return state.concat(action.payload || []);
        case BLACKLIST_TYPES.UPDATE:
            return state.map(blacklist => (action.payload !== undefined && blacklist.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : blacklist);
        case BLACKLIST_TYPES.DELETE:
            return state.filter(blacklist => action.payload !== undefined && blacklist.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}