import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
// import Fab from '@material-ui/core/Fab';
import { createStyles, withStyles } from "@material-ui/core/styles";
// import AddIcon from '@material-ui/icons/Add';

import { fetchProfessions } from "../../../actions";
import DefaultSection from "../../../components/DefaultSection";
import Loading from "../../../components/Loading";
import { fetchProfessions as fetchAll } from "../../../utils/axios";
import ProfessionForm from "../ProfessionForm";
import ProfessionSearchSection from "../ProfessionSearchSection";
import ProfessionView from "../ProfessionView";

const styles = createStyles({
  addButton: {
    backgroundColor: green[500],
    bottom: 10,
    color: "white",
    position: "fixed",
    right: 10,
  },
  rightSide: {
    alignItems: "center",
    border: "1px solid transparent",
    boxSizing: "border-box",
    display: "block",
    height: "100%",
    justifyContent: "center",
    marginLeft: 280,
    position: "relative",
  },
  root: {
    boxSizing: "border-box",
    height: "100%",
    margin: 0,
    padding: 0,
  },
});

class ProfessionsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedId: undefined,
      selectedSection: 0,
    };

    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    fetchAll({ token: this.props.user.token, limit: 20, offset: 0 })
      .then((res) => {
        this.props.fetchProfessions(
          res.data.results ? res.data.results : res.data
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  handleChangeSection(sectionNumber, professionId) {
    if (professionId) {
      this.setState({ selectedId: professionId });
    }

    this.setState({ selectedSection: sectionNumber });
  }
  handleClickItem(id) {
    if (id === "none") {
      return;
    }

    this.setState(
      {
        selectedId: id,
        selectedSection: 1,
      },
      () => {
        this.setState({ selectedSection: 2 });
      }
    );
  }
  handleAddClick() {
    this.setState({
      selectedSection: 3,
    });
  }
  fetchData(params) {
    this.setState({ loading: true });
    fetchAll(params)
      .then((res) => {
        this.props.fetchProfessions(
          res.data.results ? res.data.results : res.data
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  render() {
    const { classes } = this.props;
    const { loading, selectedId, selectedSection } = this.state;
    return (
      <div className={classes.root}>
        <ProfessionSearchSection
          loading={loading}
          handleClick={this.handleClickItem}
          fetchData={this.fetchData}
          pagination={true}
        />
        <div className={classes.rightSide}>
          {selectedSection === 0 ? (
            <DefaultSection
              message={translations.PROFESSION_DEFAULT_SECTION_MESSAGE}
              key={0}
            />
          ) : null}
          {selectedSection === 1 ? (
            <Loading message={translations.LOADING} key={1} />
          ) : null}
          {selectedSection === 2 ? (
            <ProfessionView
              changeSection={this.handleChangeSection}
              professionId={selectedId ? selectedId : ""}
            />
          ) : null}
          {selectedSection === 3 ? (
            <ProfessionForm changeSection={this.handleChangeSection} />
          ) : null}
          {/* {(selectedSection !== 3) ?
                        <Fab className={classes.addButton} onClick={this.handleAddClick}>
                            <AddIcon />
                        </Fab>
                        : null} */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    professions: state.professions,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchProfessions })(ProfessionsSection)
);
