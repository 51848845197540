import { AxiosError } from "axios";
import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createParticipant } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import attorneyNumberMaskInput from "../../../components/TextMaskInputComponents/attorneyNumberMaskInput";
import idNumberMaskInput from "../../../components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "../../../components/TextMaskInputComponents/passportMaskInput";
import phoneMaskInput from "../../../components/TextMaskInputComponents/phoneMaskInput";
import {
  affiliateState,
  affiliateType,
  catchError,
  category,
  civilStatus,
  creditMethod,
  distributionChannel,
  dwellingTypes,
  educationLevel,
  gender,
  IDENTIFICATION_TYPE,
  nationalitiesToOptions,
  participantType,
  pepRelationChoices,
  personTypes,
  prefix,
  resetError,
  successHandler,
  titleCaseHandler,
  toOptions,
  transactionAmount,
  translations,
} from "../../../utils/";
import {
  createParticipant as create,
  fetchNationalities,
  fetchProfessions,
} from "../../../utils/axios";
import Address from "../../../components/Address/Address";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { AutocompleteField } from "src/components/SharedComponents/Fields";
// import MoneyInput from "src/components/TextMaskInputComponents/moneyInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    display: "none",
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  checkboxLabelReactSelect: {
    color: green[500],
    fontSize: "small",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    overflowy: "auto",
    padding: 20,
    paddingBottom: 64,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface IAttorneyFormProps {
  classes: { [key: string]: string };

  user: IUser;
  createParticipant(participant: IParticipant): IAction<IParticipant[]>;
  changeSection(sectionNumber: number): void;
  fetchClients?: (params: any) => void;
}
interface IAttorneyFormState {
  [key: string]:
    | string
    | string[]
    | number
    | boolean
    | IOptionType
    | IOptionType[];
  additionalPhone: string;
  additionalPhoneError: string;
  affiliateState: string;
  affiliateStateError: string;
  affiliateType: string;
  affiliateTypeError: string;
  attorneyNumber: string;
  attorneyNumberError: string;
  birthDate: string;
  birthDateError: string;
  category: string;
  cellPhone: string;
  cellPhoneError: string;
  city: string;
  cityError: string;
  civilStatus: string;
  civilStatusError: string;
  country: string;
  countryError: string;
  dialog: boolean;
  dialogTitle: string;
  distributionChannel: string;
  educationLevel: string;
  educationLevelError: string;
  email: string;
  emailError: string;
  errorMessage: string;
  fullName: string;
  fullNameError: string;
  gender: string;
  genderError: string;
  homePhone: string;
  homePhoneError: string;
  identificationNumber: string;
  identificationNumberError: string;
  identificationType: string;
  identificationTypeError: string;
  loading: boolean;
  nationality: IOptionType;
  nationalityError: string;
  nationalityOptions: IOptionType[];
  nickName: string;
  nickNameError: string;
  participantType: string;
  participantTypeError: string;
  pepPosition: string;
  pepOcupationDate: string;
  pepWorkInstitution: string;
  pepRelation: string;
  personKind: string;
  prefix: string;
  prefixError: string;
  profession: IOptionType;
  professionOptions: IOptionType[];
  reference: string;
  referenceError: string;
  region: string;
  regionError: string;
  sector: string;
  sectorError: string;
  state: string;
  stateError: string;
  secondCitizenship: string;
  secondCitizenshipId: string;
  transactionAmount: string;
  transactionalBehavior: string;
  workPhone: string;
  workPhoneError: string;
  workPhoneExtension: string;
  workPhoneExtensionError: string;
  vehicleColor: string;
  vehicleColorError: string;
  vehicleBrand: string;
  vehicleBrandError: string;
  vehicleModel: string;
  vehicleModelError: string;
  vehicleYear: number;
  vehicleYearError: string;
  visaNumber: number;
  hasDebtorAVehicle: false;
  hasDebtorAVehicleError: string;
  hasDebtorVisa: false;
  hasDebtorVisaError: string;
  hasAccessToCoopvirtual: boolean;
  hasAccessToCoopvirtualError: string;
  vehicleFinancialStatus: string;
  vehicleFinancialStatusError: string;
  // houseMonthlyPaymentError: string,
  // houseMonthlyPayment: number,
  // houseMonthlyPaymentCurrency: string,
  // houseMonthlyPaymentCurrencyError: string,
  dwellingType: string;
}
class Form extends React.Component<IAttorneyFormProps, IAttorneyFormState> {
  public state: IAttorneyFormState = {
    additionalPhone: "",
    additionalPhoneError: "",
    affiliateState: "not_affiliate",
    affiliateStateError: "",
    affiliateType: "service_user_affiliate",
    affiliateTypeError: "",
    attorneyNumber: "",
    attorneyNumberError: "",
    birthDate: "",
    birthDateError: "",
    category: "",
    cellPhone: "",
    cellPhoneError: "",
    city: "",
    cityError: "",
    civilStatus: "single",
    civilStatusError: "",
    country: "República Dominicana",
    countryError: "",
    dialog: false,
    dialogTitle: "",
    distributionChannel: "",
    educationLevel: "none",
    educationLevelError: "",
    email: "",
    emailError: "",
    errorMessage: "",
    fullName: "",
    fullNameError: "",
    gender: "m",
    genderError: "",
    homePhone: "",
    homePhoneError: "",
    identificationNumber: "",
    identificationNumberError: "",
    identificationType: "national",
    identificationTypeError: "",
    loading: false,
    nationality: { label: "", value: "" },
    nationalityError: "",
    nationalityOptions: [],
    nickName: "",
    nickNameError: "",
    participantType: "client",
    participantTypeError: "",
    pepPosition: "",
    pepOcupationDate: "",
    pepWorkInstitution: "",
    pepRelation: "",
    personKind: "",
    prefix: "mister",
    prefixError: "",
    profession: { label: "", value: "" },
    professionOptions: [],
    reference: "",
    referenceError: "",
    region: "",
    regionError: "",
    sector: "",
    sectorError: "",
    state: "",
    stateError: "",
    secondCitizenship: "",
    secondCitizenshipError: "",
    secondCitizenshipId: "",
    secondCitizenshipIdError: "",
    transactionalBehavior: "",
    transactionAmount: "",
    workPhone: "",
    workPhoneError: "",
    workPhoneExtension: "",
    workPhoneExtensionError: "",
    vehicleColor: "",
    vehicleColorError: "",
    vehicleBrand: "",
    vehicleBrandError: "",
    vehicleModel: "",
    vehicleModelError: "",
    vehicleYear: 0,
    vehicleYearError: "",
    visaNumber: 0,
    hasDebtorAVehicle: false,
    hasDebtorAVehicleError: "",
    hasDebtorVisa: false,
    hasDebtorVisaError: "",
    hasAccessToCoopvirtual: false,
    hasAccessToCoopvirtualError: "",
    vehicleFinancialStatus: "financed",
    vehicleFinancialStatusError: "",
    // houseMonthlyPaymentError: "",
    // houseMonthlyPayment: 0,
    // houseMonthlyPaymentCurrency: "DOP",
    // houseMonthlyPaymentCurrencyError: "",
    dwellingType: "",
  };

  constructor(props: IAttorneyFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }

  public componentDidMount() {
    this.setState({ loading: false });
    fetchNationalities({ token: this.props.user.token })
      .then((res) => {
        this.setState(
          {
            nationalityOptions: nationalitiesToOptions(
              res.data.results ? res.data.results : res.data
            ),
          },
          () => {
            this.setState((prevState) => ({
              nationality: prevState.nationalityOptions[0],
            }));
          }
        );
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando  nacionalidades",
          loading: false,
        });
      });

    fetchProfessions({ token: this.props.user.token })
      .then((res) => {
        this.setState({
          professionOptions: toOptions(
            res.data.results ? res.data.results : res.data
          ),
        });
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando  profesiones",
          loading: false,
        });
      });
  }

  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }

  handleSelectChange(event: any, key: any) {
    if (key === "password" || key === "passwordConfirm") {
      if (/^(\d{4}|\d{6})$/.test(event.target.value)) {
        this.setState({
          [key]: event.target.value,
          [key + "Error"]: "",
        });
      } else {
        this.setState({
          [key]: event.target.value,
          [key +
          "Error"]: "El pin debe ser numerico y de cuatro (4) caracteres.",
        });
      }
      return false;
    }
    this.setState({
      [key]: event.target.value,
      [key + "Error"]: "",
    });
    return;
  }

  public handleSelect(key: string, value: string) {
    this.setState({
      [key]: value,
    });
  }
  handleCheckBoxChange(event: any, key: any) {
    this.setState({ [key]: event.target.checked });
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    let participant: IParticipant = {
      address: {
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        region: this.state.region,
        reference: this.state.reference,
        sector: titleCaseHandler(this.state.sector),
      },
      additionalPhone: this.state.additionalPhone,
      affiliateState: this.state.affiliateState,
      affiliateType: this.state.affiliateType,
      attorneyNumber: this.state.attorneyNumber,
      birthDate: this.state.birthDate,
      category: this.state.category,
      cellPhone: this.state.cellPhone,
      civilStatus: this.state.civilStatus,
      distributionChannel: this.state.distributionChannel,
      educationLevel: this.state.educationLevel,
      email: this.state.email,
      fullName: this.state.fullName,
      gender: this.state.gender,
      homePhone: this.state.homePhone,
      identificationNumber: this.state.identificationNumber.replace(/-/g, ""),
      identificationType: this.state.identificationType,
      nationality: this.state.nationality.value,
      secondCitizenship: this.state.secondCitizenship,
      secondCitizenshipId: this.state.secondCitizenshipId,
      transactionAmount: this.state.transactionAmount,
      transactionalBehaviour: this.state.transactionalBehavior,
      participantType: this.state.participantType,
      pepPosition: this.state.pepPosition,
      pepOcupationDate: this.state.pepOcupationDate,
      pepWorkInstitution: this.state.pepWorkInstitution,
      pepRelation: this.state.pepRelation,
      personKind: this.state.personKind,
      prefix: this.state.prefix,
      profession: this.state.profession.value,
      workPhone: this.state.workPhone,
      workPhoneExtension: this.state.workPhoneExtension,
      hasDebtorAVehicle: this.state.hasDebtorAVehicle,
      vehicleColor: this.state.vehicleColor,
      vehicleBrand: this.state.vehicleBrand,
      vehicleModel: this.state.vehicleModel,
      vehicleYear: this.state.vehicleYear,
      vehicleFinancialStatus: this.state.vehicleFinancialStatus,
      hasDebtorVisa: this.state.hasDebtorVisa,
      visaNumber: this.state.visaNumber,
      hasAccessToCoopvirtual: this.state.hasAccessToCoopvirtual,
      houseInfo: this.state.dwellingType,
      // houseMonthlyPayment: this.state.houseMonthlyPayment,
      // houseMonthlyPaymentCurrency: this.state.houseMonthlyPaymentCurrency,
    };

    this.setState({ loading: true });

    const removeEmptyValues = (obj: IParticipant) => {
      Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') {
          removeEmptyValues(obj[key]); 
        } else if (obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    };

    participant = removeEmptyValues(participant);

    create(this.props.user.token, participant)
      .then((res) => {
        this.props.createParticipant(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, translations.PARTICIPANT);
        this.handleReset();
        if(this.props.fetchClients){
          this.props.fetchClients({limit:100});
        }
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }
  public handleReset() {
    this.setState((prevState) => ({
      additionalPhone: "",
      affiliateState: "not_affiliate",
      affiliateStateError: "",
      affiliateType: "service_user_affiliate",
      affiliateTypeError: "",
      attorneyNumber: "",
      birthDate: "",
      category: "",
      cellPhone: "",
      city: "",
      civilStatus: "single",
      country: "República Dominicana",
      distributionChannel: "",
      educationLevel: "none",
      educationLevelError: "",
      email: "",
      fullName: "",
      gender: "m",
      homePhone: "",
      identificationNumber: "",
      identificationType: "national",
      nationality: { label: "", value: "" },
      secondCitizenship: "",
      secondCitizenshipId: "",
      nickName: "",
      nickNameError: "",
      participantType: "client",
      participantTypeError: "",
      pepPosition: "",
      pepOcupationDate: "",
      pepWorkInstitution: "",
      pepRelation: "",
      personKind: "",
      prefix: "mister",
      profession: { label: "", value: "" },
      reference: "",
      region: "",
      sector: "",
      state: "",
      transactionAmount: "",
      transactionalBehavior: "",
      workPhone: "",
      workPhoneExtension: "",
      vehicleColor: "",
      vehicleColorError: "",
      vehicleBrand: "",
      vehicleBrandError: "",
      vehicleModel: "",
      vehicleModelError: "",
      vehicleYear: 0,
      vehicleYearError: "",
      hasDebtorAVehicle: false,
      hasDebtorAVehicleError: "",
      hasDebtorVisa: false,
      visaNumber: 0,
      hasDebtorVisaError: "",
      hasAccessToCoopvirtual: false,
      hasAccessToCoopvirtualError: "",
      vehicleFinancialStatus: "financed",
      vehicleFinancialStatusError: "",
      // houseMonthlyPaymentError: "",
      // houseMonthlyPayment: 0,
      // houseMonthlyPaymentCurrency: "DOP",
      // houseMonthlyPaymentCurrencyError: "",
      dwellingType: "",
    }));
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public getIdentificationInput(identificationType: string) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }
  public render() {
    const { classes } = this.props;

    return (
      <form
        id="ClientForm"
        onSubmit={this.handleSubmit}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Typography
                variant={"h6"}
                className={"section-header"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#4caf50",
                  margin: 0,
                  padding: 0,
                  textTransform: "uppercase",
                }}
              >
                Datos personales
              </Typography>
              <Divider style={{ margin: "0 0 2rem 0" }} />
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    select
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.PARTICIPANT_TYPE}
                    value={this.state.participantType}
                    onChange={(event) =>
                      this.handleSelectChange(event, "participantType")
                    }
                    error={this.state.participantTypeError !== ""}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "participantType",
                      name: "participantType",
                    }}
                  >
                    {participantType.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {this.state.participantType == "attorney" && (
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={translations.ATTORNEY_NUMBER}
                      value={this.state.attorneyNumber}
                      onChange={(event) =>
                        this.handleSelectChange(event, "attorneyNumber")
                      }
                      error={this.state.attorneyNumberError !== ""}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                      InputProps={{
                        inputComponent: attorneyNumberMaskInput,
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.NAME}
                    value={this.state.fullName}
                    onChange={this.handleChange("fullName")}
                    error={this.state.fullNameError !== ""}
                    helperText={this.state.fullNameError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.IDENTIFICATION_TYPE}
                    value={this.state.identificationType}
                    onChange={(event) =>
                      this.handleSelectChange(event, "identificationType")
                    }
                    error={this.state.identificationTypeError !== ""}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "identificationType",
                      name: "identificationType",
                    }}
                  >
                    {IDENTIFICATION_TYPE.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.IDENTIFICATION}
                    value={this.state.identificationNumber}
                    onChange={this.handleChange("identificationNumber")}
                    error={this.state.identificationNumberError !== ""}
                    helperText={this.state.identificationNumberError}
                    InputProps={this.getIdentificationInput(
                      this.state.identificationType
                    )}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.BIRTHDATE}
                    value={this.state.birthDate}
                    type="date"
                    onChange={this.handleChange("birthDate")}
                    error={this.state.birthDateError !== ""}
                    helperText={this.state.birthDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    select
                    label={translations.TITLE}
                    variant="outlined"
                    value={this.state.prefix}
                    onChange={(event) =>
                      this.handleSelectChange(event, "prefix")
                    }
                    error={this.state.prefixError !== ""}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "prefix",
                      name: "prefix",
                    }}
                  >
                    {prefix.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <AutocompleteField
                    xs={3}
                    fullWidth
                    margin="none"
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldID="nationality"
                    fieldInnerLabel="Nacionalidad"
                    fieldVariant="outlined"
                    style={ styles.autocomplete }
                    value={this.state.nationality}
                    handleChange={(event:string, value:string) =>
                      this.handleSelect("nationality", value)
                    }
                    options={this.state.nationalityOptions}
                    getOptionLabel={(option:IOptionType) => option.label}
                    limitTags={3}
                  />
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    required
                    select
                    variant="outlined"
                    label={translations.GENDER}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    value={this.state.gender}
                    onChange={(event) =>
                      this.handleSelectChange(event, "gender")
                    }
                    error={this.state.genderError !== ""}
                    inputProps={{
                      id: "gender",
                      name: "gender",
                    }}
                  >
                    {gender.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    select
                    label={translations.CIVIL_STATUS}
                    variant="outlined"
                    value={this.state.civilStatus}
                    onChange={(event) =>
                      this.handleSelectChange(event, "civilStatus")
                    }
                    error={this.state.civilStatusError !== ""}
                    inputProps={{
                      id: "civilStatus",
                      name: "civilStatus",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {civilStatus.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    select
                    variant="outlined"
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    label={translations.AFFILIATE_STATE}
                    value={this.state.affiliateState}
                    onChange={(event) =>
                      this.handleSelectChange(event, "affiliateState")
                    }
                    error={this.state.affiliateStateError !== ""}
                    inputProps={{
                      id: "affiliateState",
                      name: "affiliateState",
                    }}
                  >
                    {affiliateState.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    required
                    fullWidth
                    label={translations.AFFILIATE_TYPE}
                    value={this.state.affiliateType}
                    onChange={(event) =>
                      this.handleSelectChange(event, "affiliateType")
                    }
                    error={this.state.affiliateTypeError !== ""}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "affiliateType",
                      name: "affiliateType",
                    }}
                    variant="outlined"
                  >
                    {affiliateType.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.EDUCATION_LEVEL}
                    value={this.state.educationLevel}
                    onChange={(event) =>
                      this.handleSelectChange(event, "educationLevel")
                    }
                    error={this.state.educationLevelError !== ""}
                    inputProps={{
                      id: "educationLevel",
                      name: "educationLevel",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {educationLevel.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label={translations.DWELLING_TYPE}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    value={this.state.dwellingType}
                    onChange={(event) =>
                      this.handleSelectChange(event, "dwellingType")
                    }
                    inputProps={{
                      name: "dwellingType",
                    }}
                  >
                    {dwellingTypes.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.SECOND_NATIONALITY}
                    value={this.state.secondCitizenship}
                    onChange={this.handleChange(" secondCitizenship")}
                    error={this.state.secondCitizenshipError !== ""}
                    helperText={this.state.secondCitizenshipError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.SECOND_NATIONALITY_ID}
                    value={this.state.secondCitizenshipId}
                    onChange={this.handleChange("  secondCitizenshipId")}
                    error={this.state.secondCitizenshipIdError !== ""}
                    helperText={this.state.secondCitizenshipIdError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <AutocompleteField
                    xs={3}
                    fullWidth
                    margin="none"
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldID="profession"
                    fieldInnerLabel={translations.PROFESSION}
                    fieldVariant="outlined"
                    value={this.state.profession}
                    handleChange={(event:string, value:string) =>
                      this.handleSelect("profession", value)
                    }
                    options={this.state.professionOptions}
                    getOptionLabel={(option:IOptionType) => option.label}
                    limitTags={3}
                  />
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label="Posee acceso a COOPVIRTUAL"
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.hasAccessToCoopvirtual}
                        onChange={(event) =>
                          this.handleCheckBoxChange(
                            event,
                            "hasAccessToCoopvirtual"
                          )
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label={translations.DEBTOR_VEHICLE}
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.hasDebtorAVehicle}
                        onChange={(event) =>
                          this.handleCheckBoxChange(event, "hasDebtorAVehicle")
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  display: this.state.hasDebtorAVehicle ? "block" : "none",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={translations.VEHICLE_BRAND}
                      value={titleCaseHandler(this.state.vehicleBrand)}
                      onChange={this.handleChange("vehicleBrand")}
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleBrandError !== ""}
                      helperText={this.state.vehicleBrandError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={translations.VEHICLE_MODEL}
                      value={titleCaseHandler(this.state.vehicleModel)}
                      onChange={this.handleChange("vehicleModel")}
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleModelError !== ""}
                      helperText={this.state.vehicleModelError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={translations.VEHICLE_YEAR}
                      value={this.state.vehicleYear}
                      onChange={this.handleChange("vehicleYear")}
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleYearError !== ""}
                      helperText={this.state.vehicleYearError}
                      InputProps={{
                        inputComponent: attorneyNumberMaskInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={translations.VEHICLE_COLOR}
                      value={titleCaseHandler(this.state.vehicleColor)}
                      onChange={this.handleChange("vehicleColor")}
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleColorError !== ""}
                      helperText={this.state.vehicleColorError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{ style: styles.checkboxLabel }}
                      error={this.state.vehicleFinancialStatusError !== ""}
                      label={translations.VEHICLE_FINANCIAL_STATUS}
                      value={this.state.vehicleFinancialStatus}
                      onChange={(event) =>
                        this.handleSelectChange(event, "vehicleFinancialStatus")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      inputProps={{
                        id: "vehicleFinancialStatus",
                        name: "vehicleFinancialStatus",
                      }}
                    >
                      <MenuItem value="financed" key={1}>
                        {translations.FINANCED}
                      </MenuItem>
                      <MenuItem value="paid" key={2}>
                        {translations.PAID}
                      </MenuItem>
                      <MenuItem value="not own" key={3}>
                        {translations.NOT_OWN}
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label={"El deudor posee visado?"}
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.hasDebtorVisa}
                        onChange={(event) =>
                          this.handleCheckBoxChange(event, "hasDebtorVisa")
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  display: this.state.hasDebtorVisa ? "block" : "none",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={"Número de visado"}
                      value={this.state.visaNumber}
                      onChange={this.handleChange("visaNumber")}
                      required={this.state.hasDebtorVisa ? true : false}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                </Grid>
              </div>

              <Typography
                variant={"h6"}
                className={"section-header"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#4caf50",
                  margin: "1rem 0 0",
                  padding: 0,
                  textTransform: "uppercase",
                }}
              >
                Datos de contacto
              </Typography>
              <Divider style={{ margin: "0 0 2rem 0" }} />

              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.CELL_PHONE}
                    value={this.state.cellPhone}
                    onChange={this.handleChange("cellPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.cellPhoneError !== ""}
                    helperText={this.state.cellPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.HOME_PHONE}
                    value={this.state.homePhone}
                    onChange={this.handleChange("homePhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.homePhoneError !== ""}
                    helperText={this.state.homePhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.ADDITIONAL_PHONE} 
                    value={this.state.additionalPhone}
                    onChange={this.handleChange("additionalPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.additionalPhoneError !== ""}
                    helperText={this.state.additionalPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.EMAIL}
                    placeholder="mycompany@email.com"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChange("email")}
                    error={this.state.emailError !== ""}
                    helperText={this.state.emailError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.WORK_PHONE}
                    value={this.state.workPhone}
                    onChange={this.handleChange("workPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.workPhoneError !== ""}
                    helperText={this.state.workPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.WORK_PHONE_EXTENSION}
                    value={this.state.workPhoneExtension}
                    onChange={this.handleChange("workPhoneExtension")}
                    error={this.state.workPhoneExtensionError !== ""}
                    helperText={this.state.workPhoneExtensionError}
                    InputProps={{
                      inputComponent: attorneyNumberMaskInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>

              <Address
                handleSelect={this.handleSelect}
                country={this.state.country}
                title={"Datos de residencia"}
                countryError={this.state.countryError}
                reference={this.state.reference}
                referenceError={this.state.referenceError}
                state={this.state.state}
                stateError={this.state.stateError}
                city={this.state.city}
                cityError={this.state.cityError}
                region={this.state.region}
                regionError={this.state.regionError}
                sector={this.state.sector}
                sectorError={this.state.sectorError}
                handleChange={this.handleChange}
              />

              <Typography
                variant={"h6"}
                className={"section-header"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#4caf50",
                  margin: "1rem 0 0",
                  padding: 0,
                  textTransform: "uppercase",
                }}
              >
                Datos de Cumplimiento
              </Typography>
              <Divider style={{ margin: "0 0 2rem 0" }} />

              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.TRANSACTIONAL_BEHAVIOUR}
                    value={this.state.transactionalBehavior}
                    onChange={(event) =>
                      this.handleSelectChange(event, "transactionalBehavior")
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "transactionalBehavior",
                      name: "transactionalBehavior",
                    }}
                  >
                    {creditMethod.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={"Tipo de persona"}
                    value={this.state.personKind}
                    onChange={(event) =>
                      this.handleSelectChange(event, "personKind")
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "personKind",
                      name: "personKind",
                    }}
                  >
                    {personTypes.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={"Canal de distribución"}
                    value={this.state.distributionChannel}
                    onChange={(event) =>
                      this.handleSelectChange(event, "distributionChannel")
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "distributionChannel",
                      name: "distributionChannel",
                    }}
                  >
                    {distributionChannel.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={"Monto de transacciones"}
                    value={this.state.transactionAmount}
                    onChange={(event) =>
                      this.handleSelectChange(event, "transactionAmount")
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "transactionAmount",
                      name: "transactionAmount",
                    }}
                  >
                    {transactionAmount.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={"Categoría"}
                    value={this.state.category}
                    onChange={(event) =>
                      this.handleSelectChange(event, "category")
                    }
                    error={this.state.participantTypeError !== ""}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "category",
                      name: "category",
                    }}
                  >
                    {category.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              {(this.state.category == "pep" ||
                this.state.category == "pepRelation") && (
                <Grid container spacing={4}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={"Cargo, rango o posición"}
                      value={this.state.pepPosition}
                      onChange={this.handleChange("pepPosition")}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={"Fecha de ocupación"}
                      value={this.state.pepOcupationDate}
                      type="date"
                      onChange={this.handleChange("pepOcupationDate")}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={"Institución a la que pertenece"}
                      value={this.state.pepWorkInstitution}
                      onChange={this.handleChange("pepWorkInstitution")}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label={"Parentesco"}
                      value={this.state.pepRelation}
                      onChange={(event) =>
                        this.handleSelectChange(event, "pepRelation")
                      }
                      InputLabelProps={{ style: styles.checkboxLabel }}
                      inputProps={{
                        id: "pepRelation",
                        name: "pepRelation",
                      }}
                    >
                      {pepRelationChoices.map((opt, indx) => (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              )}
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  form="ClientForm"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createParticipant })(Form)
);
