import { AxiosError } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';
import FileUploadSelectInput from 'src/containers/FileUpload/FileUploadSelectInput';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import {
    deleteNotarialPayContract, fetchNotarialPayContract, updateNotarialPayContract
} from '../../../actions';
import AlertDialog from '../../../components/AlertDialog';
import Loading from '../../../components/Loading';
import attorneyNumberMaskInput from '../../../components/TextMaskInputComponents/attorneyNumberMaskInput';
import moneyInput from '../../../components/TextMaskInputComponents/moneyInput';
import {
    catchError, debtorsValidation, resetError, successHandler,
    titleCaseHandler, translations, periodicity, notarialTypes, weekDays, filesGeneration
} from '../../../utils/';
import {
    deleteNotarialPayContract as deleteNP, fetchNotarialPayContract as fetchNP,
    updateNotarialPayContract as updateNP
} from '../../../utils/axios';
import LoanContractSelectInput from '../../LoanContract/LoanContractSelectInput';
import AttorneySelectInput from '../../Attorney/AttorneySelectInput';
import WarrantyTable from '../../Warranty/WarrantySelectInput/warrantyTable'
import { fetchLoanContract as fetch } from "../../../utils/axios";



const styles: any = createStyles({
    attachmentIcon: {
        color: green[500],
        fontSize: 50,
        position: 'absolute',
        right: 25,
        top: 25,
        transform: 'rotate(90deg)'
    },
    checkboxLabel: {
        color: green[500],
    },
    controls: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    deleteButton: {
        backgroundColor: red[500],
        color: 'white'
    },
    errorInput: {
        color: 'red'
    },
    formControl: {
        marginTop: 17,
        minWidth: 300,
        width: 300
    },
    greenButton: {
        backgroundColor: green[500],
        color: 'white'
    },
    half: {
        width: '50%'
    },
    paper: {
        padding: 20
    },
    root: {
        height: '90vh',
        overflowY: 'auto',
        padding: 20,
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: '50%'
    }
});

interface INotarialPayContractViewProps {
    notarialPayContracts: INotarialPayContract[];
    notarialPayContractId: string;
    classes: {
        attachmentIcon: string;
        checkboxLabel: string;
        controls: string;
        deleteButton: string;
        errorInput: string;
        formControl: string;
        greenButton: string;
        half: string;
        paper: string;
        root: string;
        textField: string;
    };

    user: IUser;
    changeSection(sectionNumber: number): void;
    deleteNotarialPayContract(uniqueId: string): IAction<INotarialPayContract[]>;
    fetchNotarialPayContract(notarialPayContract: INotarialPayContract): IAction<INotarialPayContract[]>;
    updateNotarialPayContract(notarialPayContract: INotarialPayContract): IAction<INotarialPayContract[]>;
}
const GreenSwitch = withStyles({
    switchBase: {
        color: green[300],
        '&$checked': {
            color: green[500],
        },
        '&$checked + $track': {
            backgroundColor: green[500],
        },
    },
    checked: {},
    track: {},
})(Switch);
interface INotarialPayContractViewState {
    [key: string]: string | number | boolean | string[] | IBankAccount[];

    NP: string;
    actNumber: string;
    actNumberError: string;

    contractDate: string;
    contractDateError: string;

    dialog: boolean;
    dialogTitle: string;
    errorMessage: string;

    interestRate: string;
    interestRateError: string;

    loading: boolean;

    loanContract: string[];
    loanContractError: string;

    loanContracts: any[];
    loanContractsError: string;

    notarialAmount: number;
    notarialAmountError: string;

    status: string;

    title: string;
    titleError: string;

    uniqueId: string;

    attorney: string[];
    attorneyError: string;

    term: string;
    termError: string;

    fees: number;
    feesError: string;

    hasFees: boolean;
    hasFeesError: string;

    printActNumber: boolean;
    printActNumberError: string;

    periodicity: string;
    periodicityError: string;

    notarialPayType: string;
    notarialPayTypeError: string;

    propertyDescription: string;
    propertyDescriptionError: string;

    modifyDefaultInterests: boolean;
    modifyDefaultInterestsError: string;

    destinationDescription: string;
    destinationDescriptionError: string;

    destination: string;
    destinationError: string;

    defaultInterest: string;
    defaultInterestError: string;

    currDetailIndex: string;
    selected: boolean;
    currSelected: any;

    debtors: any;
    debtorsError: string;

    secondPayDate: string | number;
    secondPayDateError: string;

    firstPayDate: string | number;
    firstPayDateError: string;

    firstExpirationDate: string | number;
    firstExpirationDateError: string;

    lastExpirationDate: string | number;
    lastExpirationDateError: string;

    modifyWarranty: boolean;
    modifyWarrantyError: string;

    checked: string[]
    disabledPoint: boolean;


}
class NotarialPayContractView extends React.Component<INotarialPayContractViewProps, INotarialPayContractViewState> {
    public state: INotarialPayContractViewState = {
        NP: '',
        actNumber: '',
        actNumberError: '',
        contractDate: new Date().toISOString().split('T')[0],
        contractDateError: '',
        dialog: false,
        dialogTitle: '',
        errorMessage: '',
        interestRate: '5.5',
        interestRateError: '',
        loading: false,
        disabledPoint: false,
        loanContract: [],
        loanContractError: '',
        loanContracts: [],
        loanContractsError: '',
        notarialAmount: 0,
        notarialAmountError: '',
        status: 'pending_signature',
        title: '',
        titleError: '',
        uniqueId: '',
        attorney: [],
        attorneyError: "",
        fees: 0,
        feesError: "",
        term: "",
        termError: "",
        hasFees: false,
        hasFeesError: "",
        printActNumber: false,
        printActNumberError: "",
        contractFee: 0,
        contractFeeError: "",
        notarialPayType: "",
        notarialPayTypeError: "",
        propertyDescription: "",
        propertyDescriptionError: "",
        periodicity: "",
        periodicityError: "",
        modifyDefaultInterests: false,
        modifyDefaultInterestsError: "",
        currDetailIndex: "",
        selected: false,
        currSelected: [],
        destinationDescription: "",
        destinationDescriptionError: "",
        destination: "",
        destinationError: "",
        defaultInterest: "3.0",
        defaultInterestError: "",
        firstExpirationDate: new Date().toISOString().split('T')[0],
        firstExpirationDateError: "",
        lastExpirationDate: "",
        lastExpirationDateError: "",
        firstPayDate: "",
        firstPayDateError: "",
        lastPayDate: "",
        lastPayDateError: "",
        debtors: [],
        debtorsError: "",
        secondPayDate: "",
        secondPayDateError: "",
        modifyWarranty: false,
        modifyWarrantyError: "",
        checked: []
    }
    constructor(props: INotarialPayContractViewProps) {
        super(props);

        this.handleAddLoanContract = this.handleAddLoanContract.bind(this);
        this.handleDeleteLoanContract = this.handleDeleteLoanContract.bind(this);

        // core events
        this.changeStatus = this.changeStatus.bind(this);
        this.generateFile = this.generateFile.bind(this);


        // attorney
        this.handleAddAttorney = this.handleAddAttorney.bind(this);
        this.handleDeleteAttorney = this.handleDeleteAttorney.bind(this);

        this.handleChanges = this.handleChanges.bind(this);


        this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChecked = this.handleChecked.bind(this);

        this.handleDialogClose = this.handleDialogClose.bind(this);
    }
    public handleChecked(
        indx: string,
        warranty: string,
        warrantyType: string
    ) {
        if (warrantyType === 'property_warranty'){
            let warranties = this.state.checked
            if (warranties.every(val => val.slice(0,-1) === warranty)){
                if (warranties.includes(indx)){
                    warranties = warranties.filter(wId => wId !== indx)
                } else {
                    warranties = warranties.concat(indx)
                }
            } else {
                warranties = []
                warranties = warranties.concat(indx)
            }
            this.setState({
                warranty,
                checked: warranties
            })
        } else {
            this.setState({
                warranty,
                checked: [indx]
            })
        }
    }

    public setDetailIndex(index: string) {
        this.setState({ currDetailIndex: index });
    }
    public handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };
    public handleAddAttorney(id: string) {
        const attorney: string[] = [id];
        this.setState({ attorney });
    }
    public handleDeleteAttorney(id: string) {
        this.setState({ attorney: [] });
    }
    public handleAddLoanContract(contract: any) {
        this.setState({
            loanContract: [contract.uniqueId || ''],
            notarialAmount: contract.firstOutlayAmount || 0
        });
        this.setState({ debtors: contract.debtors || '' })

    }
    public handleDeleteLoanContract(id: string) {
        this.setState({
            loanContract: [],
            notarialAmount: 0
        });
    }
    public async generateFile() {
        this.setState({ loading: true });
        if (await debtorsValidation(this, this.props.user.token, this.state.loanContract, true)) {
            const url = `/lending/notarial-pay-contracts/${this.state.uniqueId}/print`;
            await filesGeneration(url, this.props.user.token);
            this.setState({ loading: false });
        }
    }
    public changeStatus(status: string) {
        return () => {
            this.setState({ status });
        }
    }
   
    public async componentDidMount() {
        

        await fetchNP(this.props.user.token, this.props.notarialPayContractId).then(res => {
            const curNotarialPayContract = res.data as INotarialPayContract;
            

            this.setState({
                NP: `lending/notarial-pay-contracts/${curNotarialPayContract.uniqueId}/upload_attachment/`,
                actNumber: curNotarialPayContract.actNumber || '',
                hasFees: curNotarialPayContract.hasFees || false,
                printActNumber: curNotarialPayContract.printActNumber || false,
                contractDate: curNotarialPayContract.contractDate || '',
                dialog: false,
                dialogTitle: '',
                errorMessage: '',
                destination: curNotarialPayContract.destination || '',
                interestRate: curNotarialPayContract.interestRate || '',
                loading: false,
                loanContract: [curNotarialPayContract.loanContract || ''],
                notarialAmount: curNotarialPayContract.notarialAmount || 0,
                attorney: [curNotarialPayContract.notarialAttorney || ''],
                status: curNotarialPayContract.status || '',
                saved_status: curNotarialPayContract.status || '',
                defaultInterest: curNotarialPayContract.moratoriumInterest || '',
                title: curNotarialPayContract.title || '',
                uniqueId: curNotarialPayContract.uniqueId || '',
                fees: curNotarialPayContract.quota || 0,
                term: curNotarialPayContract.deadline || '',
                notarialPayType: curNotarialPayContract.typeNpc || '',
                periodicity: curNotarialPayContract.periodicity || "",
                firstExpirationDate: curNotarialPayContract.firstPaymentDate || "",
                lastExpirationDate: curNotarialPayContract.lastPaymentDate || "",
                firstPayDate: curNotarialPayContract.firstPaymentDay || "",
                secondPayDate: curNotarialPayContract.secondPaymentDay || "",
                checked: curNotarialPayContract.warranty || [] ,
                warranty: curNotarialPayContract.warranties || "",
                modifyWarranty: curNotarialPayContract.modifyWarranty || false,
            });

        }).catch(err => {
            catchError(err, this)
        });


        await fetch(this.props.user.token, this.state.loanContract[0])
            .then((res) => {
                this.setState({
                    loading: false,
                    debtors: res.data.debtors,
                    debtorsError: "",
                });
            })
            .catch((err) => {
                catchError(err, this);
            });
    }

    public handleCurrencyInputChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>, maskedValue?: string, floatValue?: string) => {
            this.setState({ [key]: floatValue || '' });
        }
    }
    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                [key]: event.currentTarget.value,
                [key + 'Error']: ''
            });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({ [key]: event.target.value });
        }
    }

    public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        resetError(this);
        const { state } = this;
        if (!state.loanContract.length) {
            this.setState({
                dialog: true,
                dialogTitle: 'Disculpe',
                errorMessage: 'Debe de seleccionar un contrato de línea de credito'
            })
        }
        else if (state.loanContract.length && this.state.modifyWarranty && this.state.checked.length < 0) {
            this.setState({
                dialog: true,
                dialogTitle: 'Disculpe',
                errorMessage: 'Debe de seleccionar una garantía'
            })
        }
        else if (!state.attorney.length) {
            this.setState({
                dialog: true,
                dialogTitle: 'Disculpe',
                errorMessage: 'Debe de seleccionar un abogado'
            })
        } else {
            const notarialPayContract: INotarialPayContract = {
                actNumber: state.actNumber,
                contractDate: state.contractDate,
                deadline: state.term,
                destination: state.destinationDescription ? state.destinationDescription : state.destination,
                firstPaymentDate: state.firstExpirationDate,
                lastPaymentDate: state.lastExpirationDate,
                interestRate: state.interestRate,
                loanContract: (state.loanContract[0] || ''),
                moratoriumInterest: state.defaultInterest,
                notarialAmount: (state.notarialAmount.toString().trim() === "") ? 0 : state.notarialAmount,
                notarialAttorney: state.attorney[0],
                firstPaymentDay: state.firstPayDate,
                secondPaymentDay: state.secondPayDate,
                periodicity: state.periodicity,
                hasFees: state.hasFees,
                printActNumber: state.printActNumber,
                modifyWarranty: state.modifyWarranty,
                status: state.status,
                quota: state.fees || 0.00,
                title: titleCaseHandler(state.title),
                typeNpc: state.notarialPayType,
                warranties: state.warranty || '',
                warranty: !state.checked ? [] : state.checked,

            };

            this.setState({ loading: true });

            updateNP(this.props.user.token, this.state.uniqueId, notarialPayContract).then(res => {
                successHandler(res, this, translations.NOTARIAL_PAY_CONTRACT)
                this.props.updateNotarialPayContract(res.data as INotarialPayContract);
            }).catch((err: AxiosError) => {
                catchError(err, this);
            });
        }


    }

    public componentDidUpdate(prevProps: any, prevState: any) {
        if ( prevState.periodicity !== this.state.periodicity ) {
            if (this.state.periodicity === 'single_payment'){
                this.setState({
                    disabledPoint: true,
                    term: '1',
                    hasFees: false,
                    fees: 0
                });
            }else{
                this.setState({
                    disabledPoint: false,
                });

            }
        }
    }

    public handleDelete() {
        if (confirm(translations.ARE_YOU_SURE)) {

            this.setState({ loading: true });
            deleteNP(this.props.user.token, this.props.notarialPayContractId).then(res => {
                this.props.changeSection(0);
                this.props.deleteNotarialPayContract(this.props.notarialPayContractId);
            }).catch((err: AxiosError) => {
                catchError(err, this)
            })
        }
    }
    public handleDialogClose() {
        this.setState({ dialog: false });
    }
    public render() {
        const { classes } = this.props;
        const { saved_status } = this.state;

        return (
            <form onSubmit={this.handleSubmit} className={classes.root}>
                <AlertDialog
                    open={this.state.dialog}
                    message={this.state.errorMessage}
                    title={this.state.dialogTitle}
                    handleClose={this.handleDialogClose}
                />
                <Paper className={classes.paper}>
                    <FileUploadSelectInput
                        contractId={this.state.uniqueId}
                        url={this.state.NP}
                        description='notarial-pay' />
                    <Typography variant="h3">{translations.NOTARIAL_PAY_CONTRACT}</Typography>
                    {this.state.loading ?
                        <Loading message={translations.LOADING} />
                        : <div>
                            <Button 
                                className={`${classes.greenButton} generate-print-document`} 
                                onClick={this.generateFile} 
                                style={{ marginRight: 10, marginTop: 25 }}
                                // disabled={saved_status === "cancelled" ? true: false}
                            >
                                <DocumentIcon />
                                &nbsp;
                                {translations.GENERATE_DOCUMENT}
                            </Button>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="status" shrink={true}>{translations.STATUS}</InputLabel>
                                <Select
                                    value={this.state.status}
                                    onChange={this.handleSelectChange('status')}
                                    required={true}
                                    disabled={saved_status === "cancelled" ? true: false}
                                    native={true}
                                    inputProps={{
                                        id: 'status',
                                        name: 'status',
                                    }}

                                >
                                    <option value="pending_signature">{translations.PENDING_SIGNATURE}</option>
                                    <option value="internal_signature_pending">{translations.INTERNAL_SIGNATURE_PENDING}</option>
                                    <option value="in_legalization_process">{translations.IN_LEGALIZATION_PROCESS}</option>
                                    <option value="completed">{translations.COMPLETED}</option>
                                    <option value="archived">{translations.ARCHIVED}</option>
                                    <option value="cancelled">{translations.CANCELLED}</option>
                                </Select>
                            </FormControl>
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    label={translations.TITLE}
                                    className={classes.textField}
                                    value={titleCaseHandler(this.state.title)}
                                    onChange={this.handleChange('title')}
                                    fullWidth={true}
                                    required={true}
                                    disabled={saved_status === "cancelled" ? true: false}
                                    error={this.state.titleError !== ''}
                                    helperText={this.state.titleError}
                                    InputLabelProps={{ style: styles.checkboxLabel }}
                                />
                                <TextField
                                    label={translations.CONTRACT_DATE}
                                    className={classes.textField}
                                    style={{ marginLeft: 20 }}
                                    disabled={saved_status === "cancelled" ? true: false}
                                    value={this.state.contractDate}
                                    type="date"
                                    onChange={this.handleChange('contractDate')}
                                    required={true}
                                    error={this.state.contractDateError !== ''}
                                    helperText={this.state.contractDateError}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.checkboxLabel
                                    }}
                                />

                            </div>
                            <br />
                            <div style={{ display: 'flex' }}>
                                <FormControlLabel
                                    disabled={this.state.disabledPoint || saved_status === "cancelled" ? true : false}
                                    control={<GreenSwitch checked={this.state.hasFees} onChange={this.handleChanges} name="hasFees" />}
                                    label="¿Tiene cuotas?"
                                />
                                <FormControlLabel
                                    disabled={saved_status === "cancelled" ? true: false}
                                    control={<GreenSwitch checked={this.state.printActNumber} onChange={this.handleChanges} name="printActNumber" />}
                                    label="¿Imprimir número de acta?"
                                />
                                <FormControlLabel
                                    disabled={saved_status === "cancelled" ? true: false}
                                    control={<GreenSwitch checked={this.state.modifyWarranty} onChange={this.handleChanges} name="modifyWarranty" />}
                                    label="¿Modificar garantía?"
                                />
                                <FormControlLabel
                                    disabled={saved_status === "cancelled" ? true: false}
                                    control={<GreenSwitch checked={this.state.modifyDefaultInterests} onChange={this.handleChanges} name="modifyDefaultInterests" />}
                                    label="¿Desea modificar los intereses moratorios?"
                                />
                            </div>
                            <br />
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={4}>
                                    <FormControl style={{ width: 'fit-content' }} className={classes.formControl} error={this.state.destinationError !== ''}>
                                        <InputLabel style={styles.checkboxLabel} htmlFor="destination" >{translations.DESTINATION}</InputLabel>
                                        <Select
                                            disabled={saved_status === "cancelled" ? true: false}
                                            value={this.state.destination}
                                            onChange={this.handleSelectChange('destination')}
                                            required={true}
                                            inputProps={{
                                                id: 'destination',
                                                name: 'destination',
                                            }}
                                        >
                                            <MenuItem value={translations.WORKING_CAPITAL}>{translations.WORKING_CAPITAL}</MenuItem>
                                            <MenuItem value={translations.CONSUMER_FINANCING}>
                                                {translations.CONSUMER_FINANCING}
                                            </MenuItem>
                                            <MenuItem value={translations.COLLEGE_CREDIT}>{translations.COLLEGE_CREDIT}</MenuItem>
                                            <MenuItem value={translations.DEBT_REFINANCING}>{translations.DEBT_REFINANCING}</MenuItem>
                                            <MenuItem value={translations.REAL_STATE_PROPERTY}>{translations.REAL_STATE_PROPERTY}</MenuItem>
                                            <MenuItem value={translations.MOTOR_VEHICLE}>{translations.MOTOR_VEHICLE}</MenuItem>
                                            <MenuItem value={translations.OTHERS}>{translations.OTHERS}</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>
                                {this.state.destination === translations.OTHERS ?
                                    <Grid item={true} xs={4}>
                                        <TextField
                                            className={classes.textField}
                                            label={translations.DESCRIPTION}
                                            value={this.state.destinationDescription}
                                            onChange={this.handleChange('destinationDescription')}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            required={true}
                                            error={this.state.destinationDescriptionError !== ''}
                                            helperText={this.state.destinationDescriptionError}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                        />
                                    </Grid>
                                    : null}
                                {this.state.hasFees ?
                                    <Grid item={true} xs={4}>
                                        <TextField
                                            className={classes.textField}
                                            label={translations.FEE}
                                            value={this.state.fees}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            onChange={this.handleCurrencyInputChange('fees')}
                                            required={true}
                                            error={this.state.feesError !== ''}
                                            InputProps={{
                                                inputComponent: moneyInput
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                        />
                                    </Grid>
                                    : null}
                            </Grid>

                            <Grid container={true} spacing={2}>
                                {!this.state.disabledPoint?
                                    <Grid item={true} xs={4}>
                                        <TextField
                                            label="Primera fecha de vencimiento"
                                            className={classes.textField}
                                            value={this.state.firstExpirationDate}
                                            type="date"
                                            disabled={saved_status === "cancelled" ? true: false}
                                            onChange={this.handleChange('firstExpirationDate')}
                                            required={true}
                                            error={this.state.firstExpirationDateError !== ''}
                                            helperText={this.state.firstExpirationDateError}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                        />
                                    </Grid>
                                :
                                <></>
                                }
                                <Grid item={true} xs={4}>
                                    <TextField
                                        label="Última fecha de vencimiento"
                                        className={classes.textField}
                                        value={this.state.lastExpirationDate}
                                        type="date"
                                        disabled={saved_status === "cancelled" ? true: false}
                                        onChange={this.handleChange('lastExpirationDate')}
                                        required={true}
                                        error={this.state.lastExpirationDateError !== ''}
                                        helperText={this.state.lastExpirationDateError}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.checkboxLabel
                                        }}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={4}>
                                    <FormControl style={{ width: 'fit-content', marginTop: '35px' }} className={classes.formControl} error={this.state.periodicityError !== ''}>
                                        <InputLabel style={styles.checkboxLabel} htmlFor="periodicity" >{translations.PERIODICITY}</InputLabel>
                                        <Select
                                            value={this.state.periodicity}
                                            onChange={this.handleSelectChange('periodicity')}
                                            required={true}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            inputProps={{
                                                id: 'periodicity',
                                                name: 'periodicity',
                                            }}
                                        >
                                            {periodicity.map((opt: any, index: any) => {
                                                return (
                                                    <MenuItem key={index} value={opt.value}>{opt.label}</MenuItem>)
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={4}>
                                    <FormControl style={{ width: 'fit-content', marginTop: '35px' }} className={classes.formControl} error={this.state.notarialPayTypeError !== ''}>
                                        <InputLabel style={styles.checkboxLabel} htmlFor="notarialPayType" >{translations.NOTARIAL_PAY_TYPE}</InputLabel>
                                        <Select
                                            value={this.state.notarialPayType}
                                            onChange={this.handleSelectChange('notarialPayType')}
                                            required={true}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            inputProps={{
                                                id: 'notarialPayType',
                                                name: 'notarialPayType',
                                            }}
                                            defaultValue={"Garantia Solidaria"}
                                            >
                                            {notarialTypes.map((opt: any, index: any) => {
                                                return (
                                                    <MenuItem key={index} value={opt.label}>{opt.label}</MenuItem>)
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        label={`${translations.INTEREST_RATE}: ${this.state.interestRate}%`}
                                        className={classes.textField}
                                        value={this.state.interestRate}
                                        type="number"
                                        disabled={saved_status === "cancelled" ? true: false}
                                        onChange={this.handleChange('interestRate')}
                                        required={true}
                                        error={this.state.interestRateError !== ''}
                                        helperText={this.state.interestRateError}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.checkboxLabel
                                        }}
                                        inputProps={{
                                            max: 100,
                                            min: 0,
                                            step: 0.01
                                        }}
                                    />
                                </Grid>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        label={translations.TIME_LIMIT}
                                        className={classes.textField}
                                        value={this.state.term}
                                        type="number"
                                        onChange={this.handleChange('term')}
                                        required={true}
                                        disabled={this.state.disabledPoint || saved_status === "cancelled" ? true: false}
                                        error={this.state.termError !== ''}
                                        helperText={this.state.termError}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.checkboxLabel
                                        }}
                                        inputProps={{
                                            max: 100,
                                            min: 0,
                                            step: 0.01
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {this.state.periodicity === "biweekly" ? (
                                <Grid container={true} spacing={2}>
                                    <Grid item={true} xs={4}>
                                        <TextField
                                            className={classes.textField}
                                            label={`Primer ${translations.PAYMENT_DAY}`}
                                            value={this.state.firstPayDate}
                                            onChange={this.handleChange('firstPayDate')}
                                            required={true}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            error={this.state.firstPayDateError !== ''}
                                            helperText={this.state.firstPayDateError}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                            type="number"
                                            inputProps={{
                                                max: 31,
                                                min: 1,
                                                step: 1
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={4}>
                                        <TextField
                                            className={classes.textField}
                                            label={`Segundo ${translations.PAYMENT_DAY}`}
                                            value={this.state.secondPayDate}
                                            onChange={this.handleChange('secondPayDate')}
                                            required={true}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            error={this.state.secondPayDateError !== ''}
                                            helperText={this.state.secondPayDateError}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                            type="number"
                                            inputProps={{
                                                max: 31,
                                                min: 1,
                                                step: 1
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={4}>
                                        <TextField
                                            label={translations.ACT_NUMBER}
                                            className={classes.textField}
                                            value={this.state.actNumber}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            onChange={this.handleChange('actNumber')}
                                            error={this.state.actNumberError !== ''}
                                            helperText={this.state.actNumberError}
                                            InputProps={{
                                                inputComponent: attorneyNumberMaskInput
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) : this.state.periodicity === "weekly" ? (

                                <Grid container={true} spacing={2}>
                                    <Grid item={true} xs={4}>
                                        <FormControl style={{ width: 'fit-content' }} className={classes.formControl} error={this.state.notarialTypeError !== ''}>
                                            <InputLabel style={styles.checkboxLabel} htmlFor="notarialType" >{translations.PAYMENT_DAY}</InputLabel>
                                            <Select
                                                label="sd"
                                                disabled={saved_status === "cancelled" ? true: false}
                                                value={this.state.firstPayDate}
                                                onChange={this.handleSelectChange('firstPayDate')}
                                            >
                                                {weekDays.map((day, index) => {
                                                    return (
                                                        <MenuItem value={day.value} key={index}>
                                                            {day.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={4}>
                                        <TextField
                                            label={translations.ACT_NUMBER}
                                            className={classes.textField}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            value={this.state.actNumber}
                                            onChange={this.handleChange('actNumber')}
                                            error={this.state.actNumberError !== ''}
                                            helperText={this.state.actNumberError}
                                            InputProps={{
                                                inputComponent: attorneyNumberMaskInput
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={4}>
                                        <TextField
                                            label={translations.NOTARIAL_AMOUNT}
                                            value={this.state.notarialAmount}
                                            onChange={this.handleCurrencyInputChange('notarialAmount')}
                                            required={true}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            style={{ marginTop: '23px' }}
                                            error={this.state.notarialAmountError !== ''}
                                            helperText={this.state.notarialAmountError}
                                            InputProps={{
                                                inputComponent: moneyInput
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) :
                                <Grid container={true} spacing={2}>
                                    <Grid item={true} xs={4}>
                                        <TextField
                                            className={classes.textField}
                                            label={`Primer ${translations.PAYMENT_DAY}`}
                                            value={this.state.firstPayDate}
                                            onChange={this.handleChange('firstPayDate')}
                                            required={true}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            type='number'
                                            error={this.state.firstPayDateError !== ''}
                                            helperText={this.state.firstPayDateError}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                            inputProps={{
                                                max: 31,
                                                min: 1,
                                                step: 1
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={4}>
                                        <TextField
                                            label={translations.ACT_NUMBER}
                                            className={classes.textField}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            value={this.state.actNumber}
                                            onChange={this.handleChange('actNumber')}
                                            error={this.state.actNumberError !== ''}
                                            helperText={this.state.actNumberError}
                                            InputProps={{
                                                inputComponent: attorneyNumberMaskInput
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={4}>
                                        <TextField
                                            label={translations.NOTARIAL_AMOUNT}
                                            value={this.state.notarialAmount}
                                            disabled={saved_status === "cancelled" ? true: false}
                                            onChange={this.handleCurrencyInputChange('notarialAmount')}
                                            required={true}
                                            style={{ marginTop: '23px' }}
                                            error={this.state.notarialAmountError !== ''}
                                            helperText={this.state.notarialAmountError}
                                            InputProps={{
                                                inputComponent: moneyInput
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        label={`${translations.DEFAULT_INTERESTS}: ${this.state.defaultInterest}%`}
                                        className={classes.textField}
                                        disabled={this.state.modifyDefaultInterests ? false : true || saved_status === "cancelled" ? true: false}
                                        value={this.state.defaultInterest}
                                        onChange={this.handleChange('defaultInterest')}
                                        error={this.state.defaultInterestError !== ''}
                                        helperText={this.state.defaultInterestError}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.checkboxLabel
                                        }}
                                        type="number"
                                        inputProps={{
                                            max: 100,
                                            min: 0,
                                            step: 0.01
                                        }}
                                    />
                                </Grid>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        label={translations.NOTARIAL_AMOUNT}
                                        value={this.state.notarialAmount}
                                        onChange={this.handleCurrencyInputChange('notarialAmount')}
                                        required={true}
                                        disabled={saved_status === "cancelled" ? true: false}
                                        style={{ marginTop: '23px', display: this.state.periodicity === "biweekly" ? "inline" : "none" }}
                                        error={this.state.notarialAmountError !== ''}
                                        helperText={this.state.notarialAmountError}
                                        InputProps={{
                                            inputComponent: moneyInput
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.checkboxLabel
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container={true} spacing={1}>
                                <Grid item={true} xs={6}>
                                    <Typography variant="h6" className={classes.checkboxLabel}>{translations.ATTORNEY}</Typography>
                                    <AttorneySelectInput
                                        selectedAttorneysId={this.state.attorney}
                                        addAttorney={this.handleAddAttorney}
                                        deleteAttorney={this.handleDeleteAttorney}
                                    />
                                    <p className={classes.errorInput}>{this.state.attorneyError}</p>
                                </Grid>
                                <Grid item={true} xs={6}>
                                    <Typography variant="h6" className={classes.checkboxLabel}>{translations.NEW_LOAN_CONTRACT}</Typography>
                                    <LoanContractSelectInput
                                        selectedLoanContractsId={this.state.loanContract}
                                        add={this.handleAddLoanContract}
                                        delete={this.handleDeleteLoanContract}
                                    />
                                    <p className={classes.errorInput}>{this.state.loanContractError}</p>
                                </Grid>
                            </Grid>
                            {this.state.debtors.length && this.state.modifyWarranty ?
                                <Grid item={true}>
                                    <Typography variant="h6" className={classes.checkboxLabel}>{translations.WARRANTIES}</Typography>
                                    <WarrantyTable checked={this.state.checked}
                                        handleChecked={this.handleChecked}
                                        debtors={this.state.debtors} />
                                </Grid>
                                : null
                            }
                            <br />
                            <div className={classes.controls} >
                                <Button 
                                    variant="contained" 
                                    onClick={this.handleDelete} 
                                    className={classes.deleteButton}
                                    disabled={saved_status === "cancelled" ? true: false} 
                                >
                                    <DeleteIcon />
                                    &nbsp;
                                    {translations.DELETE}
                                </Button>
                                <Button 
                                    type="submit" 
                                    variant="contained" 
                                    disabled={saved_status === "cancelled" ? true: false} 
                                    className={classes.greenButton} 
                                    style={{ marginLeft: 10 }}
                                >
                                    <SaveIcon />
                                    &nbsp;
                                    {translations.SAVE}
                                </Button>
                            </div>
                        </div>
                    }
                </Paper>
            </form>
        );
    }
}

function mapStateToProps(state: IState) {
    return {
        notarialPayContracts: state.notarialPayContracts,
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps, { deleteNotarialPayContract, fetchNotarialPayContract, updateNotarialPayContract })(NotarialPayContractView)
);
