import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";

import { fetchProviders } from "../../../actions";
import { fetchProviders as fetch } from "../../../utils/axios";
import ProviderForm from "../ProviderForm";
import ProviderSearchSection from "../ProviderSearchSection";

const styles: any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

interface IProviderSelectInputProps {
  classes: {
    button: string;
    root: string;
  };
  user: IUser;
  providers: IProvider[];
  selectedProviderId: string[];
  addProvider(providerId: string): void;
  deleteProvider(providerId: string): void;
  fetchProviders(providers: IProvider[]): IAction<IProvider[]>;
}
interface IProviderSelectInputState {
  formDialog: boolean;
  loading: boolean;
  open: boolean;
  providers: IProvider[];
}

class ProviderSelectInput extends React.Component<
  IProviderSelectInputProps,
  IProviderSelectInputState
> {
  public state: IProviderSelectInputState = {
    formDialog: false,
    loading: false,
    open: false,
    providers: [],
  };
  constructor(props: IProviderSelectInputProps) {
    super(props);

    this.findProviders = this.findProviders.bind(this);
    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  public componentDidMount() {
    this.setState({ loading: true });

    fetch({ token: this.props.user.token })
      .then((res) => {
        this.props.fetchProviders(
          res.data.results ? res.data.results : res.data
        );

        this.setState({
          loading: false,
          providers: (res.data.results
            ? res.data.results
            : res.data) as IProvider[],
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public openDialogHandler() {
    this.setState({ open: true });
  }
  public closeDialogHandler() {
    this.setState({ open: false });
  }
  public openFormDialog() {
    this.setState({ formDialog: true });
  }
  public closeFormDialog() {
    this.setState({ formDialog: false });
  }
  public emptyFunction() {
    return null;
  }
  public handleItemClick(providerId: string) {
    this.setState({ loading: true });

    fetch({ token: this.props.user.token })
      .then((res) => {
        this.props.fetchProviders(
          res.data.results ? res.data.results : res.data
        );

        this.setState(
          {
            loading: false,
            open: false,
            providers: (res.data.results
              ? res.data.results
              : res.data) as IProvider[],
          },
          () => {
            this.props.addProvider(providerId);
          }
        );
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.props.deleteProvider(id);
    };
  }
  public findProviders(uniqueId: string) {
    const curProvider: IProvider | undefined = this.state.providers.find(
      (provider) => provider.uniqueId === uniqueId
    );
    if (curProvider) {
      return (
        <TableRow key={curProvider.uniqueId}>
          <TableCell>{curProvider.fullName}</TableCell>
          <TableCell>{curProvider.identificationNumber}</TableCell>
          <TableCell>
            {curProvider.providerType === "company"
              ? translations.COMPANY
              : curProvider.providerType === "person"
              ? translations.PERSON
              : null}
          </TableCell>
          <TableCell>
            <IconButton
              onClick={this.handleDeleteItem(
                curProvider.uniqueId ? curProvider.uniqueId : ""
              )}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    } else {
      return null;
    }
  }
  public fetchData(params: IFetchProviderParams) {
    params.limit = undefined;
    params.offset = 0;

    this.setState({ loading: true });
    fetch(params)
      .then((res) => {
        this.props.fetchProviders(
          (res.data.results ? res.data.results : res.data) as IProvider[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }
  public render() {
    const { classes } = this.props;
    const { formDialog, open, loading } = this.state;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={this.closeDialogHandler}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.openFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: 600, width: 280 }}>
            <ProviderSearchSection
              loading={loading}
              handleClick={this.handleItemClick}
              fetchData={this.fetchData}
              pagination={false}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={formDialog}
          fullScreen={true}
          onClose={this.closeFormDialog}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <ProviderForm changeSection={this.emptyFunction} />
          </DialogContent>
        </Dialog>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.NAME}</TableCell>
                <TableCell>{translations.IDENTIFICATION}</TableCell>
                <TableCell>{translations.TYPE}</TableCell>
                <TableCell>
                  <IconButton
                    className={classes.button}
                    onClick={this.openDialogHandler}
                  >
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.selectedProviderId.map((providerId) =>
                this.findProviders(providerId)
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    providers: state.providers,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchProviders })(ProviderSelectInput)
);
