import React, { useState, useEffect, useRef } from 'react';
import {
    Grid, Dialog, DialogContent, Button, Card,
    CardContent, Typography, TextField, Slide,
    Toolbar, AppBar, Divider, List, ListItem, ListItemText,
    DialogActions, IconButton, ListItemIcon, TextareaAutosize,
    Collapse
} from '@material-ui/core';
import { AutocompleteField, TextFieldComponent, DateField, NumericTextField, SearchComponent} from './../../../components/SharedComponents/Fields';
import {
    ConfirmDialog, DialogHeader, DialogFooter,
} from './../../../components/SharedComponents/Dialogs';
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import AddBoxIcon from '@material-ui/icons/AddBox';

import { fetchClaimDetail as fetchDetail, createAcquaintanceshipCompanyContract as create, createFileAttachment, deleteFileAttachment,
    createDetailFileAttachment, createClaimFileAttachment } from '../../../utils/axios';
import { DateTimePicker } from "@material-ui/pickers";
import { FileSelection } from '../../../components/SharedComponents/FileSelection';
import { dateFormat, errorHandling } from './../../../components/SharedComponents/CustomHooks';
import NumberFormat from './../../../components/SharedComponents/NumberFormat';
import { ClaimTable, CollapsibleTable } from './../Tables';
import { makeStyles } from '@material-ui/core/styles';

import jwtDecode from 'jwt-decode';
import { OpenWithRounded } from '@material-ui/icons';

import {BundledEditor} from './../../../components/SharedComponents/BundledEditor.js';
import { components } from 'react-select';
import { gridColumnLookupSelector } from '@material-ui/data-grid';


function ConfirmDialogComponent(props) {
    const data = props.data.data;
    let dialogConfirmContent = '';
    switch (props.data.msg) {
        case 'file-upload':
            dialogConfirmContent = '¿Desea subir el archivo seleccionado?';
            break;
        case 'proceed-payment':
            dialogConfirmContent = '¿Desea realizar el pago especificado?';
            break;
        case 'proceed-delete':
            dialogConfirmContent =
                `La reclamacion del cliente: ${data.row.client}, de fecha: ${data.row.dateOfCreation.split('T')[0]} será eliminada.`;
            break;
        case 'proceed-delete-file':
            dialogConfirmContent =
                `Esta seguro que desea eliminar el archivo?`;
            break;
        default:
            dialogConfirmContent = '...';
    }

    return (
        <ConfirmDialog
            open={props.open}
            handleToggle={props.handleToggle}
            confirmCallback={data.callback}
            data={dialogConfirmContent}
        />
    )
}

function ClaimsDetailDialog(props) {
    const useStyles = makeStyles((theme) => ({
        container: {
          display: 'flex',
          flexWrap: 'wrap',
        },
        textField: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          width: 200,
        },
    }));
    
    const defaultFieldsValue = {
        status: null,
        dateOfCreation: '',
        dueDate: '',
        department: null,
        comment: "",
        typeOfAction: null,
        uniqueId: "",
        claimParent: "",
        claimDetailFiles: [],
    }

    const classes = useStyles();
    const action = props.data.dateOfCreation ? 'update' : 'create';
    const [fields, setField] = useState(defaultFieldsValue);
    const [errorFields, setErrorFields] = useState({}); // the error field obj (to tell the field if there's an error)
    const [error, setError] = useState(true); // general error variable. Show if there's a field with an error.
    const [rows, setRows] = useState(2);
    const [file, setFile] = useState('');
    const [detailStatus, setDetailStatus] = useState(props.listData.billingClaimDetailsStatus);

    const nonRequiredFields = ['dueDate', 'comment', 'claimDetailFiles', 'claimParent']
    const nonFillableField =  ['uniqueId']

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const getFiltersOptions = (option) => {
        return (
            (option ? option : [])
        );
    }

    const validateRequiredFields = (type, fieldsToCheck, value) => { // validate fields errors
        let newErrorState = { ...errorFields };
        const fieldCheck = (key, value) => {
            if (nonRequiredFields.includes(key)) {
                return newErrorState[key] = false;
            }
            // || Object.keys(value).length === 0
            return newErrorState[key] =
                (value === '' || value === null || value === undefined ) ? true : false;
        }
        
        const checkAllFields= () =>{
            let nonVerifiableFields = [...nonRequiredFields, ...nonFillableField];

            let verifiableFields = Object.keys(fieldsToCheck)
                .filter(x => nonVerifiableFields.includes(x)===false)

            verifiableFields
                .map(key => {
                    return fieldCheck(key, fieldsToCheck[key]);
            });
        }
        
        if (type === 'all') {
            checkAllFields()
        } else if (type === 'single') {
            fieldCheck(fieldsToCheck, value);
        }

        const hasErr = Object.keys(newErrorState).find(item => newErrorState[item]);
        setError(Boolean(hasErr));
        setErrorFields({ ...newErrorState });
    }

    const handleFieldChange = (field, event, value) => {
        let newVal;
        
        switch (field) {
            case  'dueDate':
            case 'dateOfCreation':
                newVal = event;
                break;
            case 'comment':
                newVal = value.startContent;
                break;
            case 'department':
                newVal = value;     
                break;
            case 'status':
                newVal = value;
                break;
            case 'claimDetailFiles':
                newVal = event;
                break;
            case 'typeOfAction':
                newVal = value;
                break;
            case 'claimParent':
                newVal = value;
                break;
            default:
                newVal = event.target ? event.target.value : event.value;
                break;
        }
        validateRequiredFields('single', field, newVal);
        setField(prevState => ({ ...prevState, [field]: newVal })); //this way the state of the object property updates.
    }

    const handleEditRow = () => {
        let editData = { uniqueId: props.uniqueIdsDetailParent };
        Object.keys(fields).forEach(key => {
            switch (key) {
                case 'status':
                    let statusList= props.data.department  === 'billing'? props.listData.billingClaimDetailsStatus:props.listData.nonBillingclaimDetailsStatus
                    editData[key] = statusList.find(item => item.value === props.data[key]);
                    break;
                case 'department':
                    editData[key] = props.listData.departments.find(item => item.value === props.data[key]);
                    break;
                case 'comment':
                    editData[key] = props.data[key];
                    break;
                case 'uniqueId':
                    editData[key] = props.data[key];
                    break;
                case 'typeOfAction':
                    editData[key] = props.listData.typeOfActions.find(item => item.value === props.data[key]);
                    break;
                case 'claimParent':
                    editData[key] = props.listData.claims.find(item => item.uniqueId === props.data[key]);
                    break;
                case 'dateOfCreation':
                    editData[key] = props.data.dateOfCreation.split('-04:00')[0] || ''
                    break;
                case 'dueDate':
                    editData[key] = props.data[key];
                    break;
                default:
                    editData[key] = props.data[key];
                    break;
            }
            
        });
        setField({ ...editData });
    }

    const getDateHour = () => {
        let date = new Date();
        return date;
    }

    const handleSaveBtn = () => {
        if (validateRequiredFields('all', fields)) { return }
        const data = {
            dateOfCreation: fields.dateOfCreation,
            status: fields.status.value,
            department: fields.department.value,
            typeOfAction: fields.typeOfAction.value,
            description: fields.description,
            comment: fields.comment,
            dueDate: fields.dueDate || null,
            claimDetailFiles:fields.claimDetailFiles?fields.claimDetailFiles:[],
            claimParent: fields.claimParent?fields.claimParent.uniqueId || null:null,
            uniqueId: fields.uniqueId || ""
        }
        const getresponse = (data) => setField(prevState => ({ ...prevState, ['uniqueId']:  data.uniqueId}))
        props.handleClaimDetail(action, data, props.uniqueIdsDetailParent, props.setCreatedRow, getresponse);
        setErrorFields({})
        setError(false)
    }

    const uploadFile = () => {
        props.setLoading(true);
        props.handleToggleSimpleDialog(false)
        let files = Array.from(file);
        let errors = {}
        let i = 0;
        let createDocuments = [];
        
        
        files.forEach((item, index) => {
            createDocuments[index] = new Promise ((resolve, reject) => {
                let formData = new FormData();
                formData.append("file", item);
                formData.append("name", item.name);
                const endpoint = createDetailFileAttachment(props.token, fields.uniqueId, formData);
                endpoint.then((response) => {
                    ++i;
                    setFile(null);
                    resolve(response.data);
                }).catch(error => {
                    setFile(null);
                    reject(error);
                    errors[item.name] = error;
                });
            })
        });
    
        createDocuments.forEach(p => p.then((response) => {
            if (files.length === i) {
                props.setLoading(false);
                props.handleToggleSimpleDialog('success-dialog');
                handleSaveBtn();
            } 
           
            if (Object.keys(errors).length > 0 && files.length === i) {
                props.handleToggleSimpleDialog(undefined)
                errorHandling(errors, props.handl);
                props.setLoading(false);
            }
        }));

        createDocuments.forEach(p => p.catch((error) => {
            if (Object.keys(error).length > 0 && files.length === i) {
                props.handleToggleSimpleDialog(undefined)
                errorHandling(error, props.handleToggleSimpleDialog);
                props.setLoading(false);
            }
        }));
        
    }
    
    const handleDeleteFile = (row) => {        
        props.setLoading(true);
        deleteFileAttachment(props.token, row.uniqueId)
        .then(() => {
            setField(prevState => ({ ...prevState, ['claimDetailFiles']: prevState.claimDetailFiles.filter(item => item.uniqueId !== row.uniqueId) }))
            props.setLoading(false);
            props.handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        }, [value]); 
        return ref.current;
    }

    useEffect(() => {
        if (typeof props.data !== 'undefined' && props.data.uniqueId) {
            handleEditRow();
        } else{
            setField(prevState => ({ ...prevState, ['dateOfCreation']: new Date() }))
        }
    }, [props.data]);

    useEffect(() =>{
        setDetailStatus((fields.department?fields.department.value: "") === 'billing'?  props.listData.billingClaimDetailsStatus: props.listData.nonBillingclaimDetailsStatus)
    }, [fields.department])
    
    
    const prevFields = usePrevious(fields);
    useEffect(() => {
        if(prevFields){
            if(JSON.stringify(prevFields) !== JSON.stringify(fields)) {
                setError(false)
            }
           
            if (validateRequiredFields('all', fields)) { return }
        }
    }, [fields])

    return (
        <Dialog
            fullWidth
            fullScreen
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle={"Detalle"}
                handleClick={() => props.setOpen(false)}
            />
            <DialogContent style={{ margin: '2% 0', }}>
            <div style={{ padding: '20px 20px 10% 20px' }}>
            <Grid container direction="row" spacing={2} style={{ padding: '0 1%' }}>
                <AutocompleteField
                    xs={6}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Caso relacionado"
                    fieldID="autocomplete-due-modify-status"
                    fieldInnerLabel="Seleccione un caso relacionado"
                    fieldVariant="outlined"
                    value={fields.claimParent ? fields.claimParent : null}
                    handleChange={handleFieldChange.bind(this, 'claimParent')}
                    options={getFiltersOptions(props.listData.claims)}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.value === value.value}
                    error={errorFields.claimParent}
                    helperText={errorFields.claimParent ? "Seleccione un caso relacionado" : null}
                    limitTags={1}
                />
                <AutocompleteField
                    xs={6}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Departamento"
                    fieldID="autocomplete-due-modify-status"
                    fieldInnerLabel="Seleccione un departamento"
                    fieldVariant="outlined"
                    value={fields.department ? fields.department : null}
                    handleChange={handleFieldChange.bind(this, 'department')}
                    options={getFiltersOptions(props.listData.departments)}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.value === value.value}
                    error={errorFields.department}
                    helperText={errorFields.department ? "Seleccione un departamento" : null}
                    limitTags={1}
                />               
            </Grid>
            <Grid container direction="row" spacing={2} style={{ padding: '0 1%' }}>
                <AutocompleteField
                    xs={6}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Estado"
                    fieldID="autocomplete-due-modify-status"
                    fieldInnerLabel="Seleccione un estatus"
                    fieldVariant="outlined"
                    value={fields.status ? fields.status : null}
                    handleChange={handleFieldChange.bind(this, 'status')}
                    options={getFiltersOptions(detailStatus)}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.value === value.value}
                    error={errorFields.status}
                    helperText={errorFields.status ? "Seleccione un estado" : null}
                    limitTags={1}
                />
                <AutocompleteField
                    xs={6}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Tipo de accion"
                    fieldID="autocomplete-due-modify-status"
                    fieldInnerLabel="Seleccione un tipo de accion"
                    fieldVariant="outlined"
                    value={fields.typeOfAction ? fields.typeOfAction : null}
                    handleChange={handleFieldChange.bind(this, 'typeOfAction')}
                    options={getFiltersOptions(props.listData.typeOfActions)}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.value === value.value}
                    error={errorFields.typeOfAction}
                    helperText={errorFields.typeOfAction ? "Seleccione un tipo de accion" : null}
                    limitTags={1}
                    fullWidth={true}
                />
               
            </Grid>
            <Grid container direction="row" spacing={2} style={{ padding: '0 1%' }}>
                <DateField
                    xs={6}
                    containerClass="field-container"
                    id="date"
                    label="Fecha de reclamo"
                    InputLabelProps={{ shrink: true }}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Fecha del detalle   "
                    type="datetime"
                    inputVariant="outlined"
                    value={fields.dateOfCreation?fields.dateOfCreation: null}
                    handleChange={handleFieldChange.bind(this, 'dateOfCreation')}
                    error={errorFields.dateOfCreation}
                    helperText={errorFields.dateOfCreation ? "Seleccione una fecha" : null}
                    format="dd/MM/yyyy HH:mm"
                    fullWidth={true}
                    minDate={new Date(props.claim.dateOfCreation)}
                    maxDate={props.claim.dueDate?new Date(props.claim.dueDate):undefined}
                />

                <DateField
                    xs={6}
                    containerClass="field-container"
                    id="dueDate"
                    label="Fecha de vencimiento"
                    InputLabelProps={{ shrink: true }}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Fecha de vencimiento"
                    type="datetime"
                    inputVariant="outlined"
                    value={fields.dueDate?fields.dueDate: null}
                    handleChange={handleFieldChange.bind(this, 'dueDate')}
                    error={errorFields.dueDate}
                    helperText={errorFields.dueDate ? "Seleccione una fecha" : null}
                    format="dd/MM/yyyy HH:mm"
                    fullWidth={true}
                    minDate={new Date(fields.dateOfCreation)}
                    maxDate={props.claim.dueDate?new Date(props.claim.dueDate):undefined}
                />
            </Grid>
            {
                fields.uniqueId ?
                <Grid container direction="row" spacing={2} style={{ padding: '0 1%' }}>
                    <Typography
                        variant="subtitle1"
                        className="field-label"
                    >Cargar Archivo</Typography>
                    <FileSelection
                        xs={12}
                        file={file}
                        savedFiles={fields.claimDetailFiles}
                        setFile={setFile}
                        removeFile={handleDeleteFile}
                        loading={props.setLoading}
                        fileExtensions=".pdf,.png,.jpg,.jpeg"
                        inputId="input-file-detail"
                        uploadFile={props.handleToggleSimpleDialog.bind(this, 'confirm-dialog', 'file-upload', {callback: uploadFile.bind(this)})}
                        multiple={true}
                        token={props.token}
                        handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                    />
                </Grid>
                : null
            }
            
            <br/>
            <Grid container direction="row" spacing={2} style={{ padding: '0 1%' }}>
                <BundledEditor
                    onBlur={() => setField(prevState => ({ ...prevState, ['comment']: editorRef.current.getContent()}))}
                    onInit={(evt, editor) => editorRef.current = editor}    
                    initialValue={fields.comment}
                    init={{
                        placeholder:'Escriba un comentario...',
                        height: 500,
                        width: '100%',
                        menubar: false,
                        plugins: [
                            'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
                            'searchreplace', 'table', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        content_langs: [
                            { title: 'English', code: 'en' },
                            { title: 'Spanish', code: 'es' },
                        ]
                    }}
                />
            </Grid>
            <Grid container justifyContent="flex-end" style={{ marginTop: '3%', paddingRight: '1%' }}>
                    <Button
                        variant="contained"
                        className={error ? "action-method-btn disabled" : "action-method-btn save"}
                        disableElevation
                        size="small"
                        onClick={handleSaveBtn}
                        disabled={error}
                    >
                        guardar detalle
                    </Button>
            </Grid>
        </div>
            </DialogContent>
            </Dialog>
    )
}

function Claims(props) {
    const useStyles = makeStyles((theme) => ({
        container: {
          display: 'flex',
          flexWrap: 'wrap',
        },
        textField: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          width: 200,
        },
    }));

    const classes = useStyles();
    const editorRef = useRef(null);

    const defaultFieldsValue = {
        status: null,
        // client: null,
        dateOfCreation: '',
        dueDate: '',
        department: null,
        comments: "",
        description: "",
        typeOfCase: null,
        uniqueId: "",
        clientDetail: null,
        claimFiles: [],
    }
    const action = props.modify ? 'update' : 'create';
    const [fields, setField] = useState(defaultFieldsValue);
    const [errorFields, setErrorFields] = useState({}); // the error field obj (to tell the field if there's an error)
    const [error, setError] = useState(false); // general error variable. Show if there's a field with an error.
    const [file, setFile] = useState('');
    const [nonRequiredFields, setNonRequiredFields] = useState(['comments', 'claimFiles', 'claimParent', 'clientDetail', 'dueDate']);
    const nonFillableField =  'uniqueId'

    const getFiltersOptions = (option) => {
        return (
            (option ? option : [])
        );
    }

    const validateRequiredFields = (type, fieldsToCheck, value) => { // validate fields errors
        let newErrorState = { ...errorFields };

        const fieldCheck = (key, value) => {
            if (nonRequiredFields.includes(key)) {
                return newErrorState[key] = false;
            }          
            return newErrorState[key] =
                (value === '' || value === null || value === undefined ) ? true : false;
        }

        const checkAllFields= () =>{
            let nonVerifiableFields = [...nonRequiredFields, nonFillableField];
            let verifiableFields = Object.keys(fieldsToCheck).filter(x => nonVerifiableFields.includes(x)===false);
            
            verifiableFields
                .map(key => {
                    return fieldCheck(key, fieldsToCheck[key]);
            });
        }

        if (type === 'all') {   
                checkAllFields()
        } else if (type === 'single') {
            fieldCheck(fieldsToCheck, value)
        }

        const hasErr = Object.keys(newErrorState).find(item => newErrorState[item]);
        setError(Boolean(hasErr));
        setErrorFields({ ...newErrorState });
    }

    const handleFieldChange = (field, event, value) => {
        let newVal;
        
        switch (field) {
            case 'dateOfCreation':
                newVal = event
                break;
            case 'dueDate':
                newVal = event
                break;
            case 'department':
                newVal = value;
                break;
            case 'status':
                newVal = value;
                break;
            case 'clientDetail':
                newVal = event || null;
                break;
            case 'typeOfCase':
                if(value && value.value === 'general' ){
                    setField(prevState => ({ ...prevState, ['clientDetail']: null }))
                    !nonRequiredFields.includes('clientDetail') && setNonRequiredFields([...nonRequiredFields, 'clientDetail'])
                    errorFields['clientDetail'] = false;
                    setErrorFields({...errorFields});
                }else{
                    setNonRequiredFields([...nonRequiredFields.filter(x => x !== 'clientDetail')]);
                }
                newVal = value;
                break;
            case 'description':
                newVal = event.target.value;
                break;
            case 'comments':
                newVal = event.target.value;
                break;
            default:
                newVal = event.target ? event.target.value : event.value;
                break;
        }
        validateRequiredFields('single', field, field==='dateOfCreation' ||field==='dueDate'?value:newVal);
        setField(prevState => ({ ...prevState, [field]: newVal })); //this way the state of the object property updates.
    }

    const handleEditRow = () => {
        let editData = { uniqueId: props.data.uniqueId };
        Object.keys(fields).forEach(key => {
            switch (key) {
                case 'status':
                    editData[key] = props.listData.status.find(item => item.value === props.data[key]);
                    break;
                case 'department':
                    editData[key] = props.listData.departments.find(item => item.value === props.data[key]);
                    break;
                case 'description':
                    editData[key] = props.data[key];
                    break;
                case 'comments':
                    editData[key] = props.data[key];
                    break;
                case 'uniqueId':
                    editData[key] = props.data[key];
                    break;
                case 'typeOfCase':
                    editData[key] = props.listData.typeOfCases.find(item => item.value === props.data[key]);
                    break;
                case 'dateOfCreation':
                    editData[key] = props.data.dateOfCreation.split('-04:00')[0] || ''
                    break;
                case 'dueDate':
                    editData[key] = props.data[key];
                    break;
                case 'claimFiles':
                    editData[key] = props.data[key];
                    break;
                default:
                    editData[key] = props.data[key];
                    break;
            }
            
        });
        validateRequiredFields('all', editData);
        setField({ ...editData });
    }

    const handleSaveBtn = () => {
        if (validateRequiredFields('all', fields)) { return }
        
        const data = {
            dateOfCreation: fields.dateOfCreation,
            dueDate: fields.dueDate || null,
            client: fields.clientDetail?fields.clientDetail.value || fields.clientDetail.uniqueId || null: null,
            status: fields.status.value,
            department: fields.department?fields.department.value:"none",
            typeOfCase: fields.typeOfCase.value,
            description: fields.description,
            comments:  fields.comments,
            uniqueId: fields.uniqueId || "",
        }
        props.handleClaim(action, data);
        setErrorFields({})
        setError(false)
    }

    const getDateHour = () => {
        let date = new Date();
        let year = date.getFullYear().toString().padStart(2, '0');
        let month = date.getMonth().toString().padStart(2, '0');
        let day = date.getDate() - 1;
        day = day.toString().padStart(2, '0');

        let hour = date.getHours().toString().padStart(2, '0');
        let minute = date.getMinutes().toString().padStart(2, '0');
        let second = date.getSeconds().toString().padStart(2, '0');
        let millisecond = date.getMilliseconds().toString().padStart(3, '0');
        let response = `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`;
        return response;
    }

    const uploadFile = () => {
        props.setLoading(true);
        props.handleSimpleDialog(false)        
        let files = Array.from(file);
        let errors = {}
        let i = 0;
        let createDocuments = [];
        
        
        files.forEach((item, index) => {
            createDocuments[index] = new Promise ((resolve, reject) => {
                let formData = new FormData();
                formData.append("file", item);
                formData.append("name", item.name);
                const endpoint = createClaimFileAttachment(props.token, fields.uniqueId, formData);
                endpoint.then((response) => {
                    ++i;
                    setFile(null);
                    resolve(response.data);
                }).catch(error => {
                    setFile(null);
                    reject(error);
                    errors[item.name] = error;
                });
            })
        });
    
        createDocuments.forEach(p => p.then((response) => {
            if (files.length === i) {
                props.setLoading(false);
                props.handleSimpleDialog('success-dialog');
                handleSaveBtn();
            } 
           
            if (Object.keys(errors).length > 0 && files.length === i) {
                props.handleSimpleDialog(undefined)
                errorHandling(errors, props.handleToggleSimpleDialog);
                props.setLoading(false);
            }
        }));

        createDocuments.forEach(p => p.catch((error) => {
            if (Object.keys(error).length > 0 && files.length === i) {
                props.handleSimpleDialog(undefined)
                errorHandling(error, props.handleToggleSimpleDialog);
                props.setLoading(false);
            }
        }));
        
    }
    
    const handleDeleteFile = (row) => {        
        props.setLoading(true);
        deleteFileAttachment(props.token, row.uniqueId)
        .then(() => {
            setField(prevState => ({ ...prevState, ['claimFiles']: prevState.claimFiles.filter(item => item.uniqueId !== row.uniqueId) }))
            props.setLoading(false);
            props.handleSimpleDialog('success-dialog');
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleSimpleDialog);
        });
    }

    useEffect(() => {
        if (props.modify || props.data) {
            handleEditRow();
        } else {
            setField(prevState => ({ ...prevState,
                ['dateOfCreation']: new Date(), 
                ['status']: {name: 'Activo', value: 'active'}})
            );
        }
    }, [props.data]);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        }, [value]); 
        return ref.current;
    }

    const prevFields = usePrevious(fields);

    useEffect(() => {
        if(prevFields){
            if(JSON.stringify(prevFields) !== JSON.stringify(fields)) {
                setError(false)
            }
            if (validateRequiredFields('all', fields)) { return }       
        }
    }, [fields])

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle={props.modify?`Editar reclamacion: ${props.data.caseNumber}`: "Nueva reclamacion"}
                handleClick={props.handleToggleDialog.bind(this, undefined)}
            />
            <DialogContent style={{ margin: '2% 0', }}>
                <div style={{ backgroundColor:'#FAFAFAFA', margin:'0 50px', padding:'20px'}}>
                    <Typography variant="overline" display="block" gutterBottom style={{fontSize:25, color:'#5d5e5e'}}>
                        INFORMACIÓN GENERAL DE LA RECLAMACIÓN
                    </Typography>
                    <Divider style={{margin:'10px 0 20px 0'}} />
                    <Grid container direction="row" spacing={2} style={{ padding: '0 1%' }}>
                        <DateField
                            xs={4}
                            containerClass="field-container"
                            id="date"
                            label="Fecha de reclamo"
                            InputLabelProps={{ shrink: true }}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Fecha de reclamo"
                            type="datetime"
                            inputVariant="outlined"
                            value={fields.dateOfCreation?fields.dateOfCreation: null}
                            handleChange={handleFieldChange.bind(this, 'dateOfCreation')}
                            error={errorFields.dateOfCreation}
                            helperText={errorFields.dateOfCreation ? "Seleccione una fecha" : null}
                            format="dd/MM/yyyy HH:mm"
                            fullWidth={true}
                        />
                        <DateField
                            xs={4}
                            containerClass="field-container"
                            id="date"
                            label="Fecha de vencimiento"
                            InputLabelProps={{ shrink: true }}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Fecha de vencimiento"
                            type="datetime"
                            inputVariant="outlined"
                            value={fields.dueDate?fields.dueDate: null}
                            handleChange={handleFieldChange.bind(this, 'dueDate')}
                            error={errorFields.dueDate}
                            helperText={errorFields.dueDate ? "Seleccione una fecha" : null}
                            format="dd/MM/yyyy HH:mm"
                            fullWidth={true}
                            showTodayButto={true}
                        />
                        <SearchComponent 
                            xs={4}
                            containerClass="field-container"
                            fieldLabel="Socios"
                            fieldID="claim-filter-client"
                            fieldInnerLabel="Seleccione..."
                            handleChange={handleFieldChange.bind(this, 'clientDetail')}
                            // onInputChange={handleTypedPersonChange}
                            optionType={'participants'}
                            clearOnBlur={false}
                            limitTags={1}
                            savedValue={fields.clientDetail||null}
                            error={errorFields.clientDetail}
                            disabled={(fields.typeOfCase? fields.typeOfCase.value : '') === 'specific' ?false:true}
                            // multiple={true}
                            // helperText={errorFields.document ? "Debe introducir un valor." : null} 
                            // disabled={!newTicketData.type}
                        />
                        
                    </Grid>
                    <Grid container direction="row" spacing={2} style={{ padding: '0 1%' }}>
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Departamento"
                            fieldID="autocomplete-due-modify-status"
                            fieldInnerLabel="Seleccione un departamento"
                            fieldVariant="outlined"
                            value={fields.department ? fields.department : null}
                            handleChange={handleFieldChange.bind(this, 'department')}
                            options={getFiltersOptions(props.listData.departments)}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.value === value.value}
                            error={errorFields.department}
                            helperText={errorFields.department ? "Seleccione un departamento" : null}
                            limitTags={1}
                            />

                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Estado"
                            fieldID="autocomplete-due-modify-status"
                            fieldInnerLabel="Seleccione un estatus"
                            fieldVariant="outlined"
                            value={fields.status ? fields.status : null}
                            handleChange={handleFieldChange.bind(this, 'status')}
                            options={getFiltersOptions(props.listData.status)}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.value === value.value}
                            error={errorFields.status}
                            helperText={errorFields.status ? "Seleccione un estado" : null}
                            limitTags={1}
                            disabled={props.modify?false:true}
                        />

                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Tipo de caso"
                            fieldID="autocomplete-due-modify-status"
                            fieldInnerLabel="Seleccione un tipo de caso"
                            fieldVariant="outlined"
                            value={fields.typeOfCase ? fields.typeOfCase : null}
                            handleChange={handleFieldChange.bind(this, 'typeOfCase')}
                            options={getFiltersOptions(props.listData.typeOfCases)}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.value === value.value}
                            error={errorFields.typeOfCase}
                            helperText={errorFields.typeOfCase ? "Seleccione un tipo de caso" : null}
                            limitTags={1}
                        />

                    </Grid>
                    <Grid container direction="row" spacing={2} style={{ padding: '0 1%' }}>
                        <TextFieldComponent
                            xs={12}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Descripción"
                            fieldID="autocomplete-due-modify-description"
                            fieldInnerLabel="Digite un descripción"
                            fieldVariant="outlined"
                            value={fields.description || ''}
                            onChange={handleFieldChange.bind(this, 'description')}
                            helperText={errorFields.description ? "Digite la descripción" : null}
                            error={errorFields.description}
                            multiline={true}
                            fullWidth={true}
                            rows={5}
                        />
                    </Grid>
                    <br/>
                    <Grid container direction="row" spacing={2} style={{ padding: '0 1%' }}>
                        <BundledEditor
                            onBlur={() => setField(prevState => ({ ...prevState, ['comments']: editorRef.current.getContent()}))}
                            onInit={(evt, editor) => editorRef.current = editor}                    
                            initialValue={fields.comments}
                            init={{
                                placeholder:'Escriba un comentario...',
                                height: 300,
                                width: '100%',
                                menubar: false,
                                plugins: [
                                    'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
                                    'searchreplace', 'table', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                content_langs: [
                                    { title: 'English', code: 'en' },
                                    { title: 'Spanish', code: 'es' },
                                ]
                            }}
                        />
                        
                    </Grid>
                    {
                        props.data ? 
                        <Grid container direction="row" spacing={2} style={{ padding: '0 1%' }} >
                            <Typography
                                variant="subtitle1"
                                className="field-label"
                            >Cargar Archivo</Typography>
                            <FileSelection
                                xs={12}
                                file={file}
                                savedFiles={fields.claimFiles}
                                setFile={setFile}
                                removeFile={handleDeleteFile}
                                loading={props.setLoading}
                                fileExtensions=".pdf,.png,.jpg,.jpeg"
                                inputId="input-file-selection-payment"
                                uploadFile={props.handleSimpleDialog.bind(this, 'confirm-dialog', 'file-upload', {callback: uploadFile})}
                                multiple={true}
                                token={props.token}
                                handleToggleSimpleDialog={props.handleSimpleDialog}
                            />
                        </Grid>
                        : null
                    }
                    
                    <Grid container justifyContent="flex-end" style={{ marginTop: '3%', paddingRight: '1%' }}>
                        <Button
                            variant="contained"
                            className={error? "action-method-btn disabled" : "action-method-btn save"}
                            disableElevation
                            size="small"
                            onClick={handleSaveBtn}
                            disabled={error}
                        >
                            guardar cambios
                        </Button>
                    </Grid>
                    {
                        props.modify ?
                        <>
                            <Typography variant="overline" display="block" style={{fontSize:25, color:'#5d5e5e', marginTop:'20px'}}>
                                DETALLE DE LA RECLAMACIÓN
                            </Typography>
                            <Divider style={{margin:'0px 0 20px 0'}} />
                            <CollapsibleTable
                                handleToggleSimpleDialog={props.handleSimpleDialog  }
                                setLoading={props.setLoading}
                                token={props.token}
                                getClaim={props.getClaim}
                                handleClaimDetail={props.handleClaimDetail}
                                claimDetails={props.row}
                                listData={{
                                    claims:props.listData.claims,
                                    nonBillingclaimDetailsStatus:props.listData.nonBillingclaimDetailsStatus,
                                    billingClaimDetailsStatus:props.listData.billingClaimDetailsStatus,
                                    departments:props.listData.departments,
                                    typeOfActions:props.listData.typeOfActions,
                            }}/>
                        </>
                        :
                            null
                        }
                </div>                
            </DialogContent>
            <DialogFooter/>
        </Dialog>
    );
}


export { Claims, ConfirmDialogComponent, ClaimsDetailDialog }