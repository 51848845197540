import * as React from "react";
import axios from "axios";

import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import CheckBox from "@material-ui/core/Checkbox";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import DocumentIcon from "@material-ui/icons/FileCopyOutlined";

import phoneMaskInput from "src/components/TextMaskInputComponents/phoneMaskInput";

import {
  deleteAcquaintanceshipProviderContract,
  fetchAcquaintanceshipProviderContract,
  updateAcquaintanceshipProviderContract,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import moneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import {
  catchError,
  filesGeneration,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import {
  API_KEY,
  deleteAcquaintanceshipProviderContract as deleteAPRF,
  fetchAcquaintanceshipProviderContract as fetchAPRF,
  updateAcquaintanceshipProviderContract as updateAPRF,
} from "../../../utils/axios";
import ProviderSelectInput from "../../Provider/ProviderSelectInput";
import BankAndCooperativeInfoSelectInput from "../../../components/AcquaintanceshipProviderSelectInputs/BankAndCooperativeInfo";
import AffiliatesCompaniesSelectInput from "../../../components/AcquaintanceshipProviderSelectInputs/AffiliatesCompanies";
import ShareholderOrMajorityPartnerSelectInput from "../../../components/AcquaintanceshipProviderSelectInputs/ShareholderOrMajorityPartner";
import MainCustomersSelectInput from "../../../components/AcquaintanceshipProviderSelectInputs/MainCustomers";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 52,
    top: 25,
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 264,
    width: 264,
    marginRight: "1%",
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provider: [],
      status: "pending_signature",
      bankAccountInformation: [],
      affiliatesCompanies: [],
      shareholderOrMajorityPartner: [],
      mainCustomers: [],
      dialog: false,
      errorMessage: "",
      dialogTitle: "",
      title: "",
      titleError: "",
      tradeName: "",
      tradeNameError: "",
      isTheCompanyTypeProvider: false,
      branch: "",
      branchError: "",
      merchantRecordNumber: "",
      merchantRecordNumberError: "",
      merchantRecordExpirationDate: new Date().toISOString().split("T")[0],
      merchantRecordExpirationDateError: "",
      uniqueId: "",
      phoneNumber: "",
      phoneNumberError: "",
      email: "",
      emailError: "",
      notificationPlace: "",
      notificationPlaceError: "",

      relationshipPurpose: "",
      relationshipPurposeError: "",
      productsOrServicesOffered: "",
      productsOrServicesOfferedError: "",
      salesOrRevenue: "",
      salesOrRevenueError: "",
      operationsAmount: "",
      operationsAmountError: "",

      address: "",
      addressError: "",
      accountType: "",
      accountTypeError: "",
      currencyType: "",
      currencyTypeError: "",
      institutionType: "",
      institutionTypeError: "",
      institution: "",
      institutionError: "",

      pepMember: false,
      position: "",
      positionError: "",
      entryDate: "",
      entryDateError: "",
      pepInstitution: "",
      pepInstitutionError: "",

      pepMemberKinship: false,
      position2: "",
      position2Error: "",
      entryDate2: "",
      entryDate2Error: "",
      pepInstitution2: "",
      pepInstitution2Error: "",
    };

    // provider
    this.handleAddProvider = this.handleAddProvider.bind(this);
    this.handleDeleteProvider = this.handleDeleteProvider.bind(this);

    // Handle Items From List
    this.handleAddItemToList = this.handleAddItemToList.bind(this);
    this.handleDeleteItemFormList = this.handleDeleteItemFormList.bind(this);

    // core events
    this.changeStatus = this.changeStatus.bind(this);

    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.generateFile = this.generateFile.bind(this);
  }
  async generateFile() {
    // this.setState({ loading: true });
    const url= `/lending/acquaintanceship-provider-contracts/${this.state.uniqueId}/print/`
    await filesGeneration(url, this.props.user.token);
  }
  handleAddProvider(id) {
    const curProvider = this.props.providers.find(
      (item) => item.uniqueId === id
    );
    if (curProvider) {
      switch (curProvider.providerType) {
        case "company":
          this.setState({ isTheCompanyTypeProvider: true });
          break;
        default:
          this.setState({ isTheCompanyTypeProvider: false });
          break;
      }
    }
    this.setState({ provider: [id] });
  }
  handleDeleteProvider(id) {
    this.setState({ provider: [] });
  }
  handleAddItemToList(newValue, key) {
    this.setState((prevState) => ({
      [key]: [...prevState[key], newValue],
    }));
  }

  handleDeleteItemFormList(index, key) {
    this.setState((prevState) => ({
      [key]: prevState[key].filter((value, i) => i !== index),
    }));
  }
  handleCheckBoxChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  changeStatus(status) {
    return () => {
      this.setState({ status });
    };
  }
  componentDidMount() {
    fetchAPRF(this.props.user.token, this.props.id)
      .then((res) => {
        const contract = res.data;

        this.setState({
          title: contract.title,
          status: contract.status,
          provider: contract.provider ? [contract.provider] : [],
          affiliatesCompanies: contract.affiliatesCompanies,
          shareholderOrMajorityPartner: contract.shareholderOrMajorityPartner,
          bankAccountInformation: contract.bankAccountInformation,
          tradeName: contract.tradeName,
          isTheCompanyTypeProvider: contract.isTheCompanyTypeProvider,
          branch: contract.branch,
          merchantRecordNumber: contract.merchantRecordNumber,
          merchantRecordExpirationDate: contract.merchantRecordExpirationDate,
          uniqueId: contract.uniqueId,
          phoneNumber: contract.phoneNumber,
          email: contract.email,
          notificationPlace: contract.notificationPlace,
          relationshipPurpose:
            contract.comertialDescription.relationshipPurpose,
          productsOrServicesOffered:
            contract.comertialDescription.productsOrServicesOffered,
          salesOrRevenue: contract.comertialDescription.salesOrRevenue,
          operationsAmount: contract.comertialDescription.operationsAmount,
          mainCustomers: contract.mainCustomers,
          address: contract.bankAccountInformation.address,
          accountType: contract.bankAccountInformation.accountType,
          currencyType: contract.bankAccountInformation.currencyType,
          institutionType: contract.bankAccountInformation.institutionType,
          institution: contract.bankAccountInformation.institution,
          pepMember: contract.peps.pepMember.pepMember,
          position: contract.peps.pepMember.position,
          entryDate: contract.peps.pepMember.entryDate,
          pepInstitution: contract.peps.pepMember.pepInstitution,
          pepMemberKinship: contract.peps.pepMemberKinship.pepMemberKinship,
          position2: contract.peps.pepMemberKinship.position2,
          entryDate2: contract.peps.pepMemberKinship.entryDate2,
          pepInstitution2: contract.peps.pepMemberKinship.pepInstitution2,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  handleSelectChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.value });
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    resetError(this);

    const contract = {
      comertialDescription: {
        relationshipPurpose: this.state.relationshipPurpose,
        productsOrServicesOffered: this.state.productsOrServicesOffered,
        salesOrRevenue: this.state.salesOrRevenue,
        operationsAmount: this.state.operationsAmount,
      },
      title: this.state.title,
      peps: {
        pepMember: {
          pepMember: this.state.pepMember,
          position: this.state.position,
          entryDate: this.state.entryDate,
          pepInstitution: this.state.pepInstitution,
        },
        pepMemberKinship: {
          pepMemberKinship: this.state.pepMemberKinship,
          position2: this.state.position2,
          entryDate2: this.state.entryDate2,
          pepInstitution2: this.state.pepInstitution2,
        },
      },
      provider: this.state.provider[0] || "",
      shareholderOrMajorityPartner:
        this.state.shareholderOrMajorityPartner || "",
      bankAccountInformation: this.state.bankAccountInformation || "",
      mainCustomers: this.state.mainCustomers || "",
      affiliatesCompanies: this.state.affiliatesCompanies || "",
      tradeName: this.state.tradeName,
      isTheCompanyTypeProvider: this.state.isTheCompanyTypeProvider,
      branch: this.state.branch,
      merchantRecordNumber: this.state.merchantRecordNumber,
      merchantRecordExpirationDate: this.state.merchantRecordExpirationDate,
      uniqueId: this.state.uniqueId,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      notificationPlace: this.state.notificationPlace,
      status: this.state.status,
    };

    this.setState({ loading: true });

    updateAPRF(this.props.user.token, this.state.uniqueId, contract)
      .then((res) => {
        this.props.updateAcquaintanceshipProviderContract(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, translations.APRF);
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteAPRF(this.props.user.token, this.props.id)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteAcquaintanceshipProviderContract(this.props.id);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  handleDialogClose() {
    this.setState({ dialog: false });
  }
  render() {
    const { classes } = this.props;
    const cometialDescriptionTextHaders = [
      {
        value: "relationshipPurpose",
        label: "Proposito de la relación con Coopbarcelona",
      },
      {
        value: "productsOrServicesOffered",
        label: "Productos o Servicios Ofrecidos",
      },
    ];
    const cometialDescriptionNumHaders = [
      {
        value: "salesOrRevenue",
        label: "Ventas o Ingresos (Promedio Mensual)",
      },
      {
        value: "operationsAmount",
        label: "Monto Operaciones con Coopbarcelona",
      },
    ];

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">{translations.APRF}</Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={classes.greenButton}
                onClick={this.generateFile}
                style={{ marginRight: 10, marginTop: 25, marginBottom: 20 }}
              >
                <DocumentIcon />
                &nbsp;
                {translations.GENERATE_DOCUMENT}
              </Button>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="status" shrink={true}>
                  {translations.STATUS}
                </InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                  required={true}
                  inputProps={{
                    id: "status",
                    name: "status",
                  }}
                >
                  <MenuItem value="pending_signature">
                    {translations.PENDING_SIGNATURE}
                  </MenuItem>
                  <MenuItem value="internal_signature_pending">
                    {translations.INTERNAL_SIGNATURE_PENDING}
                  </MenuItem>
                  <MenuItem value="completed">
                    {translations.COMPLETED}
                  </MenuItem>
                  <MenuItem value="archived">{translations.ARCHIVED}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label={translations.TITLE}
                className={classes.textField}
                value={titleCaseHandler(this.state.title)}
                onChange={this.handleChange("title")}
                fullWidth={true}
                required={true}
                error={this.state.titleError !== ""}
                helperText={this.state.titleError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {translations.PROVIDERS}
              </Typography>
              <ProviderSelectInput
                selectedProviderId={this.state.provider}
                addProvider={this.handleAddProvider}
                deleteProvider={this.handleDeleteProvider}
              />
              <br />
              <br />
              {this.state.isTheCompanyTypeProvider ? (
                <div>
                  <TextField
                    label={translations.TRADE_NAME}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.tradeName)}
                    onChange={this.handleChange("tradeName")}
                    fullWidth={true}
                    required={true}
                    error={this.state.tradeNameError !== ""}
                    helperText={this.state.tradeNameError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                  <TextField
                    label={"Sucursal"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.branch)}
                    onChange={this.handleChange("branch")}
                    fullWidth={true}
                    required={true}
                    error={this.state.branchError !== ""}
                    helperText={this.state.branchError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                  <TextField
                    label={"Numero de registro mercantil"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.merchantRecordNumber)}
                    onChange={this.handleChange("merchantRecordNumber")}
                    fullWidth={true}
                    required={true}
                    error={this.state.merchantRecordNumberError !== ""}
                    helperText={this.state.merchantRecordNumberError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                  <TextField
                    label={"Fecha de venciiento registro mercantil"}
                    className={classes.textField}
                    value={titleCaseHandler(
                      this.state.merchantRecordExpirationDate
                    )}
                    onChange={this.handleChange("merchantRecordExpirationDate")}
                    fullWidth={true}
                    required={true}
                    type="date"
                    error={this.state.merchantRecordExpirationDateError !== ""}
                    helperText={this.state.merchantRecordExpirationDateError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </div>
              ) : null}
              <TextField
                label={translations.PHONE_NUMBER}
                className={classes.textField}
                value={titleCaseHandler(this.state.phoneNumber)}
                onChange={this.handleChange("phoneNumber")}
                fullWidth={true}
                placeholder="8090000000"
                InputProps={{
                  inputComponent: phoneMaskInput,
                }}
                required={true}
                error={this.state.phoneNumberError !== ""}
                helperText={this.state.phoneNumberError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.EMAIL}
                className={classes.textField}
                value={this.state.email}
                onChange={this.handleChange("email")}
                fullWidth={true}
                required={true}
                error={this.state.emailError !== ""}
                helperText={this.state.emailError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <br />

              <FormControl
                className={classes.formControl}
                error={this.state.notificationPlaceError !== ""}
              >
                <InputLabel htmlFor="notificationPlace" shrink={true}>
                  {"Lugar de notificacion"}
                </InputLabel>
                <Select
                  value={this.state.notificationPlace}
                  onChange={this.handleSelectChange("notificationPlace")}
                  required={true}
                  native={true}
                  inputProps={{
                    id: "notificationPlace",
                    name: "notificationPlace",
                  }}
                >
                  <option value="home">{translations.HOME}</option>
                  <option value="work">{translations.WORK}</option>
                  <option value="email">{translations.EMAIL}</option>
                </Select>
              </FormControl>
              <br />
              <br />
              <br />
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {"Descripcion comercial"}
              </Typography>
              {cometialDescriptionTextHaders.map((opt, index) => {
                return (
                  <TextField
                    label={opt.label}
                    key={index}
                    className={classes.textField}
                    value={this.state[opt.value]}
                    onChange={this.handleChange(opt.value)}
                    fullWidth={true}
                    multiline={true}
                    error={this.state[opt.value + "Error"] !== ""}
                    helperText={this.state[opt.value + "Error"]}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                );
              })}
              {cometialDescriptionNumHaders.map((opt, index) => {
                return (
                  <TextField
                    label={opt.label}
                    key={index}
                    className={classes.textField}
                    value={this.state[opt.value]}
                    onChange={this.handleCurrencyInputChange(opt.value)}
                    fullWidth={true}
                    multiline={true}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                    error={this.state[opt.value + "Error"] !== ""}
                    helperText={this.state[opt.value + "Error"]}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                      shrink: true,
                    }}
                  />
                );
              })}
              <br />
              <br />
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {"Principales Clientes y el pais a los que vende"}
              </Typography>

              <MainCustomersSelectInput
                tableLimit={3}
                customers={this.state.mainCustomers}
                add={this.handleAddItemToList}
                delete={this.handleDeleteItemFormList}
              />

              <br />
              <br />
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {
                  "Empresas afiliadas, subsidiaadas y compañias relacionadas al negocio o los propietarios del negocio"
                }
              </Typography>
              <AffiliatesCompaniesSelectInput
                tableLimit={3}
                companies={this.state.affiliatesCompanies}
                add={this.handleAddItemToList}
                delete={this.handleDeleteItemFormList}
              />
              <br />
              <br />
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {
                  "Informacion de cuentas bancarias y cooperativas en otras instituciones"
                }
              </Typography>
              <BankAndCooperativeInfoSelectInput
                tableLimit={3}
                bankAccounts={this.state.bankAccountInformation}
                add={this.handleAddItemToList}
                delete={this.handleDeleteItemFormList}
              />
              <br />
              <br />
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {"Accionista y/o socio mayoritario"}
              </Typography>
              <ShareholderOrMajorityPartnerSelectInput
                tableLimit={3}
                partners={this.state.shareholderOrMajorityPartner}
                add={this.handleAddItemToList}
                delete={this.handleDeleteItemFormList}
              />
              <br />
              <br />
              <br />
              <FormControlLabel
                label={
                  "¿Existe alguna persona políticamente expuesta y/o figura pública dentro de los socios o consejo directivo?"
                }
                control={
                  <CheckBox
                    color="primary"
                    checked={this.state.pepMember}
                    onChange={this.handleCheckBoxChange("pepMember")}
                  />
                }
                value="pepMember"
              />
              {this.state.pepMember ? (
                <div>
                  <TextField
                    label={"Cargo, Rango o Posición en Gobierno"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.position)}
                    onChange={this.handleChange("position")}
                    fullWidth={true}
                    required={true}
                    disabled={!this.state.pepMember}
                    error={this.state.positionError !== ""}
                    helperText={this.state.positionError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                  <TextField
                    label={"Fecha desde que ocupa el cargo, Rango o Posición"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.entryDate)}
                    onChange={this.handleChange("entryDate")}
                    fullWidth={true}
                    required={true}
                    disabled={!this.state.pepMember}
                    type={"date"}
                    error={this.state.entryDateError !== ""}
                    helperText={this.state.entryDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                  <br />
                  <TextField
                    label={"Institución a la que pertenece"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.pepInstitution)}
                    onChange={this.handleChange("pepInstitution")}
                    fullWidth={true}
                    required={true}
                    disabled={!this.state.pepMember}
                    error={this.state.pepInstitutionError !== ""}
                    helperText={this.state.pepInstitutionError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                </div>
              ) : null}
              <br />
              <br />
              <br />
              <FormControlLabel
                label={
                  "¿Existe alguna persona dentro de los socios o consejo directivo con algún parentesco a una persona persona politicamente expuesta y/o figura pública?"
                }
                control={
                  <CheckBox
                    color="primary"
                    checked={this.state.pepMemberKinship}
                    onChange={this.handleCheckBoxChange("pepMemberKinship")}
                  />
                }
                value="pepMemberKinship"
              />
              {this.state.pepMemberKinship ? (
                <div>
                  <TextField
                    label={"Cargo, Rango o Posición en Gobierno"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.position2)}
                    onChange={this.handleChange("position2")}
                    fullWidth={true}
                    required={true}
                    error={this.state.position2Error !== ""}
                    helperText={this.state.position2Error}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                  <TextField
                    label={"Fecha desde que ocupa el cargo, Rango o Posición"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.entryDate2)}
                    onChange={this.handleChange("entryDate2")}
                    fullWidth={true}
                    required={true}
                    type={"date"}
                    error={this.state.entryDate2Error !== ""}
                    helperText={this.state.entryDate2Error}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                  <br />
                  <TextField
                    label={"Institución a la que pertenece"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.pepInstitution2)}
                    onChange={this.handleChange("pepInstitution2")}
                    fullWidth={true}
                    required={true}
                    error={this.state.pepInstitution2Error !== ""}
                    helperText={this.state.pepInstitution2Error}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                </div>
              ) : null}

              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    acquaintanceshipProviderContracts: state.acquaintanceshipProviderContracts,
    providers: state.providers,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteAcquaintanceshipProviderContract,
    fetchAcquaintanceshipProviderContract,
    updateAcquaintanceshipProviderContract,
  })(View)
);
