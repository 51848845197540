import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createCreditCard } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import accountNumberMaskInput from "../../../components/TextMaskInputComponents/accountNumberMaskInput";
import attorneyNumberMaskInput from "../../../components/TextMaskInputComponents/attorneyNumberMaskInput";
import cvcMaskInput from "../../../components/TextMaskInputComponents/cvcMaskInput";
import {
  cardNetwork,
  cardType,
  catchError,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import { createCreditCard as create } from "../../../utils/axios";
import FinancialInstitutionSelectInput from "../../FinancialInstitution/FinancialInstitutionSelectInput";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface ICreditCardFormProps {
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    formControl: string;
    greenButton: string;
    input: string;
    paper: string;
    root: string;
    textField: string;
  };

  user: IUser;
  createCreditCard(creditCard: ICreditCard): IAction<ICreditCard[]>;
  changeSection(sectionNumber: number): void;
}
interface ICreditCardFormState {
  [key: string]: string | string[] | number | boolean;

  name: string;
  nameError: string;

  number: string;
  numberError: string;

  cvc: string;
  cvcError: string;

  cardType: string;
  cardTypeError: string;

  cardNetwork: string;
  cardNetworkError: string;

  password: string;
  passwordError: string;

  expirationDate: string;
  expirationDateError: string;

  owner: string[];
  ownerError: string;

  bank: string[];
  bankError: string;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
}
class CreditCardForm extends React.Component<
  ICreditCardFormProps,
  ICreditCardFormState
> {
  public state: ICreditCardFormState = {
    bank: [],
    bankError: "",
    cardNetwork: "Visa",
    cardNetworkError: "",
    cardType: "Debit",
    cardTypeError: "",
    cvc: "",
    cvcError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    expirationDate: "",
    expirationDateError: "",
    loading: false,
    name: "",
    nameError: "",
    number: "",
    numberError: "",
    owner: [],
    ownerError: "",
    password: "",
    passwordError: "",
  };

  constructor(props: ICreditCardFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);

    this.handleAddBank = this.handleAddBank.bind(this);
    this.handleDeleteBank = this.handleDeleteBank.bind(this);

    this.handleAddOwner = this.handleAddOwner.bind(this);
    this.handleDeleteOwner = this.handleDeleteOwner.bind(this);
  }
  public handleAddBank(id: string) {
    this.setState({ bank: [id] });
  }
  public handleDeleteBank(id: string) {
    this.setState({ bank: [] });
  }
  public handleAddOwner(id: string) {
    this.setState({ owner: [id] });
  }
  public handleDeleteOwner(id: string) {
    this.setState({ owner: [] });
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    const creditCard: ICreditCard = {
      bank: this.state.bank[0],
      cardNetwork: this.state.cardNetwork.replace(/_/g, " "),
      cardType: this.state.cardType,
      cvc: this.state.cvc,
      expirationDate: this.state.expirationDate,
      name: titleCaseHandler(this.state.name),
      number: this.state.number,
      owner: this.state.owner,
      password: this.state.password,
    };

    this.setState({ loading: true });

    create(this.props.user.token, creditCard)
      .then((res) => {
        this.props.createCreditCard(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, translations.CREDIT_CARD);
        this.handleReset();
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }
  public handleReset() {
    this.setState({
      bank: [],
      bankError: "",
      cardNetwork: "Visa",
      cardNetworkError: "",
      cardType: "Debit",
      cardTypeError: "",
      cvc: "",
      cvcError: "",
      expirationDate: "",
      expirationDateError: "",
      name: "",
      nameError: "",
      number: "",
      numberError: "",
      owner: [],
      ownerError: "",
      password: "",
      passwordError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.PAYROLL_CARD}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="cardNetwork" shrink={true}>
                  {translations.CARD_NETWORK}
                </InputLabel>
                <Select
                  value={this.state.cardNetwork}
                  onChange={this.handleSelectChange("cardNetwork")}
                  required={true}
                  native={true}
                  error={this.state.cardNetworkError !== ""}
                  inputProps={{
                    id: "cardNetwork",
                    name: "cardNetwork",
                  }}
                >
                  {cardNetwork.map((cn, indx) => {
                    return (
                      <option value={cn.value} key={indx}>
                        {cn.label}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="cardType" shrink={true}>
                  {translations.CARD_TYPE}
                </InputLabel>
                <Select
                  value={this.state.cardType}
                  onChange={this.handleSelectChange("cardType")}
                  native={true}
                  error={this.state.cardTypeError !== ""}
                  inputProps={{
                    id: "cardType",
                    name: "cardType",
                  }}
                >
                  {cardType.map((cn, indx) => {
                    return (
                      <option value={cn.value} key={indx}>
                        {cn.label}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={titleCaseHandler(this.state.name)}
                onChange={this.handleChange("name")}
                fullWidth={true}
                required={true}
                error={this.state.nameError !== ""}
                helperText={this.state.nameError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <TextField
                label={translations.ACCOUNT_NUMBER}
                className={classes.textField}
                value={this.state.number}
                onChange={this.handleChange("number")}
                fullWidth={true}
                required={true}
                error={this.state.numberError !== ""}
                helperText={this.state.numberError}
                InputProps={{
                  inputComponent: accountNumberMaskInput,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />

              <TextField
                label={translations.CVC}
                className={classes.textField}
                value={this.state.cvc}
                onChange={this.handleChange("cvc")}
                fullWidth={true}
                required={true}
                error={this.state.cvcError !== ""}
                helperText={this.state.cvcError}
                InputProps={{
                  inputComponent: cvcMaskInput,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.WITHDRAWAL_PIN}
                className={classes.textField}
                value={this.state.password}
                onChange={this.handleChange("password")}
                fullWidth={true}
                required={true}
                error={this.state.passwordError !== ""}
                helperText={this.state.passwordError}
                InputProps={{
                  inputComponent: attorneyNumberMaskInput,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.EXPIRATION_DATE}
                className={classes.textField}
                value={this.state.expirationDate}
                type="date"
                onChange={this.handleChange("expirationDate")}
                required={true}
                error={this.state.expirationDateError !== ""}
                helperText={this.state.expirationDateError}
                InputLabelProps={{
                  shrink: true,
                  style: styles.checkboxLabel,
                }}
              />
              <br />
              <div style={{ width: "50%" }}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.BANK}
                </Typography>
                <FinancialInstitutionSelectInput
                  selectedInstitutionsId={this.state.bank}
                  addInstitution={this.handleAddBank}
                  deleteInstituion={this.handleDeleteBank}
                />
                <p style={{ color: "red" }}>{this.state.bankError}</p>
              </div>
              <br />
              <div style={{ width: "100%" }}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.CARD_OWNER}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.owner}
                  addParticipant={this.handleAddOwner}
                  deleteParticipant={this.handleDeleteOwner}
                />
                <p style={{ color: "red" }}>{this.state.ownerError}</p>
              </div>
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createCreditCard })(CreditCardForm)
);
