import { Button, Divider, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { errorHandling } from "src/components/SharedComponents/CustomHooks";
import { CheckboxField } from "src/components/SharedComponents/Fields";
import { Edit } from "@material-ui/icons";
import SettlementFormsTable from "src/components/TicketWorkflows/Outlay/Form";
import {
  allWarranty,
  finalities,
  periodicity,
  transactionTypes,
} from "src/utils";
import {
  fetchRohiOutlay,
  fetchTicket,
  postRohiTicketOutlay,
  printOutlayTemplate,
} from "src/utils/axios";
import { DisbursementTable } from "./../../Tables";
import NumberFormat from "src/components/SharedComponents/NumberFormat";
import {
  getBankAccounts,
  getClientBankAccounts,
  getParticipant,
  getQuotationData,
  handleDelete,
  handleManageChecks,
  handleModify,
  handleNextStep,
  handleState,
  handleViewQuotation,
  StepStatus,
  ticketHistory,
  ticketHistoryGeneral,
  validateCreditMethodTablesEmpty,
  ViewQuotation,
  ViewQuotationText,
  SettlementTableActions,
  validateSettlementTotal,
  useCheckStepStatus,
} from "./../../TicketGeneralMethods";
import { settlementInit } from "./../../TicketGeneralMethods/consts";

export default function CreditFormalization(props) {
  const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);
  const [settlementData, setSettlementData] = useState(null);
  const [signDecision, setSignDecision] = useState(null);
  const [creditor, setCreditor] = useState({});
  const [participant, setParticipant] = useState(
    props.ticket.participantDetail || {}
  );
  const [creditorAccounts, setCreditorAccounts] = useState([]);
  const [clientBankAccounts, setClientBankAccounts] = useState([]);
  const [rohiOutlays, setRohiOutlays] = useState([]);
  const [quotationData, setQuotationData] = useState({});
  const [outlayRelationStatus, setOutlayRelationStatus] = useState(
    Boolean(
      props.ticket.steps.find((item) => item.approvalObj).approvalObj
        .outlayRelation
    )
  );
  const [outlaySettlement, setOutlaySettlement] = useState(false);
  const [checkValidation, setCheckValidation] = useState({
    outlaySettle: false,
    rrhhApproved: false,
    correctCreditMethods: false,
    signDocs: false,
    registered: false,
    verifiedDocs: false,
  });
  const [selected, setSelected] = useState([]);
  const [approvalObj, setApprovalObj] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [settlementInfo, setSettlementInfo] = useState({});

  const verifyCheckValidationOrder = (key) => {
    const isAffiliated = props.ticket.affiliatedInstitution;
    const checkValOrder = {
      rrhhApproved: 1,
      signDocs: 2,
      verifiedDocs: 3,
      // correctCreditMethods: 4,
      registered: 4,
      outlaySettle: 5,
      docsRecollected: 6,
    };
    const currCheckOrder = checkValOrder[key];
    const getPrevNextKey = (option) => {
      let currKey = "";
      const condition =
        option === "next" &&
        currCheckOrder + 1 < Object.keys(checkValOrder).length
          ? 1
          : option === "prev" && currCheckOrder - 1 > 0
          ? -1
          : null;
      if (condition) {
        currKey = Object.keys(checkValOrder).find(
          (property) => checkValOrder[property] === currCheckOrder + condition
        );
      }
      return currKey;
    };
    const prevKey = getPrevNextKey("prev");
    const nextKey = getPrevNextKey("next");
    return {
      currentOrder: currCheckOrder,
      prevOderKey: !isAffiliated && prevKey === "rrhhApproved" ? "" : prevKey,
      nextOrderKey: nextKey,
    };
  };

  const getSettlementData = (step) => {
    const creditMethodTableData = props.ticket.steps
      .find((item) => item.step === step)
      .requirements.find((item) => item.name === "creditMethodTables").value;
    return creditMethodTableData;
  };
  const getTicketRequirements = () => {
    const data =
      getSettlementData(props.currentStep) ||
      getSettlementData("eval_forms_settlement") ||
      settlementInit;

    if (data && data.creditMethods) {
      // REMOVE CASH FROM CREDIT METHODS AND SAVED VALUES;
      data.creditMethods = data.creditMethods.filter(
        (item) => item.value !== "cash"
      );
      delete data.cash;
    }
    const ticketState = props.ticket.status;
    const savedCheckValidation = props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.find((item) => item.name === "checkValidation")?.value;
    if (savedCheckValidation) {
      setCheckValidation({ ...savedCheckValidation });
    } else {
      setCheckValidation({
        ...checkValidation,
        outlaySettle: ticketState === "liquidated" ? true : false,
      });
    }
    setSettlementData(data);
  };

  const getTicket = () => {
    props.setLoading(true);
    const endpoint = fetchTicket(props.token, props.ticket.uniqueId);
    endpoint
      .then((response) => {
        props.setLoading(false);
      })
      .catch((errors) => {
        errorHandling(errors, props.handleToggleSimpleDialog);
        props.setLoading(false);
      });
  };

  const getRohiOutlay = () => {
    props.setLoading(true);
    const endpoint = fetchRohiOutlay({
      token: props.token,
      clientId: participant.rohiId,
    });
    endpoint
      .then((response) => {
        const data = response.data;
        data.forEach((item) => {
          item.id = item.id_desembolso;
        });
        setRohiOutlays(data);
        props.setLoading(false);
      })
      .catch((errors) => {
        errorHandling(errors, props.handleToggleSimpleDialog);
        props.setLoading(false);
      });
  };

  const handleTicketUpdate = (refreshTicket) => {
    ticketHistory(props.ticket.steps, props).then((response) => {
      const history = response;
      props.modifyTicketStep(
        props.ticket.steps,
        props.ticket,
        history,
        refreshTicket
      );
    });
  };

  const handleDisbursementSelect = () => {
    const disbursement = selected[0];
    const reqFields = [
      { outlay: "tipo_producto", quotation: "productType" },
      { outlay: "periodicidad", quotation: "periodicity" },
      { outlay: "tasa_interes", quotation: "interestRate" },
      { outlay: "tasa_seguro", quotation: "insuranceAmount" },
      // {outlay: 'monto_financiar', quotation: 'requestAmount'},
      { outlay: "monto_solicitado", quotation: "originalAmount" },
      { outlay: "fecha_inicio_pago", quotation: "firstInstallmentDate" },
      { outlay: "finalidad", quotation: "finality" },
    ];
    let noMatch = [];
    props.handleToggleSimpleDialog();
    const outlay = rohiOutlays.find(
      (item) => item.id_desembolso === disbursement.id_desembolso
    );
    reqFields.forEach((item) => {
      let value, warrant, product;
      const field = item.outlay.replaceAll("_", " ");
      switch (item.quotation) {
        case "productType":
          warrant = allWarranty.find(
            (tt) => tt.value === quotationData["warrantyType"]
          ).rohi;
          product = transactionTypes.find(
            (tt) => tt.value === quotationData["productType"]
          ).rohi;
          value = [...allWarranty, ...transactionTypes].find(
            (tt) =>
              tt.value === quotationData["warrantyType"] ||
              tt.value === quotationData["productType"]
          ).rohi;
          noMatch =
            warrant !== outlay[item.outlay]
              ? product !== outlay[item.outlay]
                ? noMatch.concat("Configuración de producto")
                : noMatch
              : noMatch;
          break;
        case "periodicity":
          value = periodicity.find(
            (pf) => pf.value === quotationData[item.quotation]
          ).label;
          noMatch =
            value !== outlay[item.outlay] ? noMatch.concat(field) : noMatch;
          break;
        case "interestRate":
          value = Number((quotationData[item.quotation] * 12).toFixed(2));
          noMatch =
            value !== Number(outlay[item.outlay])
              ? noMatch.concat(field)
              : noMatch;
          break;
        case "firstInstallmentDate":
          const qTime = quotationData[item.quotation];
          const oTime = outlay[item.outlay];
          noMatch =
            qTime !== oTime.substring(0, oTime.indexOf("T"))
              ? noMatch.concat(field)
              : noMatch;
          break;
        case "insuranceAmount":
          value = Number((quotationData[item.quotation] * 12).toFixed(6));
          noMatch =
            value !== Number(outlay[item.outlay])
              ? noMatch.concat(field)
              : noMatch;
          break;
        case "finality":
          value = finalities.find(
            (fin) => fin.value === quotationData[item.quotation]
          ).label;
          noMatch =
            value !== outlay[item.outlay] ? noMatch.concat(field) : noMatch;
          break;
        default:
          noMatch =
            quotationData[item.quotation] !== outlay[item.outlay]
              ? noMatch.concat(field)
              : noMatch;
          break;
      }
    });

    if (noMatch.length > 0) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        <span>
          {`Los siguientes campos del desembolso no coinciden con la cotización: `}
          <strong>{noMatch.join(", ")}</strong>
        </span>
      );
      return;
    }

    props.setLoading(true);
    const approvalData = props.ticket.steps.find((item) => item.approvalObj)
      .approvalObj;
    const obj = {
      quotationId: quotationData.uniqueId,
      ticketId: props.ticket.uniqueId,
      outlayId: disbursement.id_desembolso,
      // actDate: approvalData.actDate,
      // actNumber: Number(approvalData.actNumber),
      // approvalInstance: approvalData.approvalInstance,
      actDate: null,
      actNumber: null,
      approvalInstance: null,
      administrativeExpensesRate: Number(
        quotationData.administrativeExpensesRate
      ),
      administrativeExpensesAmount: Number(
        quotationData.administrativeExpensesAmount
      ),
    };
    const endpoint = postRohiTicketOutlay(props.token, obj);
    endpoint
      .then((response) => {
        props.handleToggleSimpleDialog("simple-dialog", response.data);
        // props.ticket.steps.find(item => item.approvalObj).approvalObj.outlayRelation = 'completed';
        approvalData.outlayRelation = "completed";
        approvalData.relationOutlayId = disbursement.id_desembolso;
        handleTicketUpdate(getTicket);
        props.setLoading(false);
      })
      .catch((errors) => {
        errorHandling(errors, props.handleToggleSimpleDialog);
        props.setLoading(false);
      });
  };

  const changeTicketStatus = (dataToModify, label) => {
    if (dataToModify && label) {
      ticketHistoryGeneral(props, label).then((response) => {
        const history = response;
        props.modifyTicket(dataToModify, history);
      });
    }
  };
  const handleCheckValidation = (type, uncheck) => {
    // let dataToModify = null;
    // let historyDescription = "";
    let check = false;
    let dataToModify = null;
    let historyDescription = "";
    const canUpdateState = ![
      "pending_settlement",
      "liquidated",
      "registration_pending",
    ].includes(props.ticket.status);
    const approvalData = props.ticket.steps.find((item) => item.approvalObj)
      .approvalObj;
    const checkValObj = props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.find((item) => item.name === "checkValidation")?.value;

    const checkVal = Boolean(!checkValidation[type]);
    switch (type) {
      case "outlaySettle":
        if (!approvalData.relationOutlayId) {
          props.handleToggleSimpleDialog(
            "simple-dialog",
            "Para marcar este estado debe completar la relación."
          );
          return;
        }
        if (
          !checkValidation.outlaySettle &&
          props.roles.includes("contabilidad")
        ) {
          if (props.ticket.affiliatedInstitution) {
            dataToModify = {
              status: "document_collection_pending",
            };
            historyDescription = `Se ha modificado el estado del ticket a: Pendiente de Recolección`;
          } else {
            dataToModify = {
              status: "liquidated",
            };
            historyDescription = `Se ha modificado el estado del ticket a: Liquidado`;
          }
          check = true;
        }
        break;
      // if(!props.fromLeads) {
      //   props.handleToggleSimpleDialog(
      //     "simple-dialog",
      //     "Esta opción solo se puede marcar desde el módulo de Leads."
      //   );
      //   return;
      // }
      // if(!props.roles.includes("rrhh")) {
      //   props.handleToggleSimpleDialog(
      //     "simple-dialog",
      //     "Esta opción solo se puede marcarla las personas con rol de RRHH."
      //   );
      //   return;
      // }
      case "rrhhApproved":
        if (props.roles.includes("rrhh_module")) {
          if (
            quotationData?.productType === "salary_advance" ||
            quotationData?.warrantyType === "colabSalaryAdvance" ||
            signDecision === "notarialPayEqualLoan"
          ) {
            dataToModify = {
              status: "registration_pending",
            };
            historyDescription =
              "Se ha modificado el estado del ticket a: Pendiente de Registro.";
            check = true;
          } else {
            dataToModify = {
              status: "sign_pending",
            };
            historyDescription =
              "Se ha modificado el estado del ticket a: Pendiente de Firma.";
            check = true;
          }
        }
        break;
      case "signDocs":
        if (props.roles.includes("oficial de negocio")) {
          dataToModify = {
            status: "sign_review_pending",
          };
          historyDescription =
            "Se ha modificado el estado del ticket a: Pendiente Revisión Firma";
          check = true;
        }
        break;
      case "verifiedDocs":
        if (props.roles.includes("analista de credito")) {
          dataToModify = {
            status: "registration_pending",
          };
          historyDescription =
            "Se ha modificado el estado del ticket a: Pendiente Registro";
          check = true;
        }
        break;
      // case "correctCreditMethods":
      //   if(props.roles.includes("oficial de negocio")) {
      //     dataToModify = {
      //       status: "registration_pending",
      //     }
      //     historyDescription = "Se ha modificado el estado del ticket a: Pendiente Registro";
      //     check = true;
      //   }
      //   break;
      case "registered":
        if (!approvalData.relationOutlayId) {
          props.handleToggleSimpleDialog(
            "simple-dialog",
            "Para marcar este estado debe completar la relación."
          );
          return;
        }
        if (props.roles.includes("contabilidad")) {
          check = true;
          dataToModify = {
            status: "pending_settlement",
          };
          historyDescription = `Se ha modificado el estado del ticket a: Pendiente de Liquidación`;
        }
        break;
      case "docsRecollected":
        if (props.roles.includes("analista de credito")) {
          dataToModify = {
            status: "documents_collected",
          };
          historyDescription =
            "Se ha modificado el estado del ticket a: Documentos Recolectados";
          check = true;
        }
        break;
      default:
        break;
    }
    if (!check) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        "No tiene los permisos para realizar esta acción."
      );
      return;
    }
    const checkOrderObj = verifyCheckValidationOrder(type);
    // adding registered previous check bypass in case of quotation type is salary advance
    const canCheck =
      ((quotationData?.productType === "salary_advance" ||
        quotationData?.warrantyType === "colabSalaryAdvance") &&
        type === "registered") ||
      checkValidation[checkOrderObj.prevOderKey] ||
      checkOrderObj.prevOderKey === "";
    // in case quotation is salary advance, ignore "signdocs" and "verifieddocs".
    if (!canCheck) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        "Debe marcar el check anterior para proceder."
      );
      return;
    }
    // if deselecting a check and the next is checked, show a message and return.
    // adding rrhhapproved next check bypass in case of quotation type is salary advance
    if (
      (quotationData?.productType === "salary_advance" ||
        quotationData?.warrantyType === "colabSalaryAdvance") &&
      type !== "rrhhApproved" &&
      !checkVal &&
      checkValidation[checkOrderObj.nextOrderKey] &&
      !uncheck
    ) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        "Debe desmarcar el check siguiente para proceder."
      );
      return;
    }
    // Si intentan desmarcar "Registrado" luego de que se hizo la relación.
    if (type === "registered" && !checkVal && approvalData.relationOutlayId) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        "La relación del desembolso ya ha sido realizada, favor contactar con el Dept. de Tecnología para deshacer la relación."
      );
    }
    if (check && !uncheck) {
      setCheckValidation({ ...checkValidation, [type]: checkVal });
      if (checkValObj) {
        checkValObj[type] = checkVal;
      } else {
        const addCheckValObj = {
          name: "checkValidation",
          value: {
            [type]: checkVal,
          },
        };
        props.ticket.steps
          .find((item) => item.step === props.currentStep)
          .requirements.push(addCheckValObj);
      }
    }
    if (!checkVal && !uncheck) {
      // when there's no other state to check behind, use default states on step change.
      if (checkOrderObj.prevOderKey === "rrhhApproved") {
        dataToModify = {
          status: "rrhh_pending",
        };
      } else if (!checkOrderObj.prevOderKey) {
        dataToModify = {
          status: "sign_pending",
        };
      } else {
        handleCheckValidation(checkOrderObj.prevOderKey, true);
      }
    }
    dataToModify = {
      ...(dataToModify || {}),
      steps: props.ticket.steps,
    };
    historyDescription =
      historyDescription || `Se ha modificado la validación del ticket ${type}`;
    changeTicketStatus(dataToModify, historyDescription);
  };

  const modifySettlementTables = () => {
    setIsEditing(true);
  };

  const generateTemplate = () => {
    props.setLoading(true);
    const endpoint = printOutlayTemplate(props.token, props.ticket.uniqueId);
    endpoint
      .then((response) => {
        const file = new Blob([response.data], { type: "text/html" });
        const fileURL = URL.createObjectURL(file);
        const NewWindow = window.open();
        NewWindow.location.href = fileURL;
        props.setLoading(false);
      })
      .catch((errors) => {
        props.setLoading(false);
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
  };

  const initQuotationData = async () => {
    const currentQuotation = props.ticket.steps.find((item) => item.approvalObj)
      .approvalObj.quotation;
    const qData = await getQuotationData(props, currentQuotation);
    setQuotationData(qData);
  };

  const initBankAccounts = async () => {
    const ba = await getBankAccounts(props.token);
    const cba = await getClientBankAccounts({
      token: props.token,
      owner: props.ticket.customer,
    });
    setCreditor(ba.creditor);
    setCreditorAccounts(ba.creditorAccounts);
    setClientBankAccounts(cba.clientBankAccounts);
  };

  const handleCompleteStep = async () => {
    const tableEmpty = await validateCreditMethodTablesEmpty(settlementData);
    if (tableEmpty) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `Todas las tablas de formas de liquidación seleccionadas deben tener al menos 1 registro.`
      );
      return;
    }
    const checksNoReference =
      settlementData.checks.find((item) => !item.referenceNumber) && "cheques";
    const banksNoReference =
      settlementData.bankTransfers.find((item) => !item.reference) &&
      "transferencias bancarias";
    const certsNoReference =
      settlementData.financialCertificates.find(
        (item) => !item.referenceNumber
      ) && "certificados financieros";
    const validateReference =
      checksNoReference || banksNoReference || certsNoReference;
    const validateRelation = !Boolean(
      props.ticket.steps.find((item) => item.approvalObj).approvalObj
        .outlayRelation
    );
    if (validateReference) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `Hay ${validateReference} que no tienen referencia colocada.`
      );
      return;
    }
    // SET SETTLEMENT TABLES TOTAL CALCULATION
    if (settlementInfo.text && settlementInfo.text !== "no_data") {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `El total de formas de liquidación ${settlementInfo.text} al monto solicitado.`
      );
      return;
    }
    if (validateRelation) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `Aún no se ha completado la relación de desembolso`
      );
      return;
    }
    const checkActNumber = props.ticket.steps.find((item) => item.approvalObj)
      .approvalObj.actNumber;
    if (!checkActNumber) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `El ticket no tiene número de acta asignado.`
      );
      return;
    }
    const checkTicketStatus = props.ticket.status === "liquidated";
    if (!checkTicketStatus) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `Debe marcar el desembolso como liquidado.`
      );
      return;
    }
    await handleManageChecks(
      creditor,
      props,
      settlementData.checks,
      [],
      creditorAccounts,
      {},
      props.currentStep
    );
    props.ticket.steps
      .find((item) => item.step === props.currentStep)
      .requirements.forEach((item) => {
        switch (item.name) {
          case "creditMethodTables":
            item.value = settlementData;
            break;
          default:
            break;
        }
      });
    await handleNextStep(
      true,
      props,
      props.ticket.steps,
      getTicket,
      "finalStep"
    );
    // generateTemplate();
    props.handleToggleSimpleDialog();
  };

  useEffect(() => {
    initBankAccounts();
    initQuotationData();
    const ticketReqs = (step) =>
      props.ticket.steps.find(
        (item) => item.step === (step || props.currentStep)
      ).requirements;
    setSignDecision(
      ticketReqs("eval_forms_settlement")?.find(
        (item) => item.name === "signDocuments"
      )?.value || ""
    );
  }, []);

  useEffect(() => {
    getTicketRequirements();
    // getParticipant(props, setParticipant);
  }, [props.reloadTicket]);

  useEffect(() => {
    const outlayRelation = Boolean(
      props.ticket.steps.find((item) => item.approvalObj).approvalObj
        .outlayRelation
    );
    setOutlayRelationStatus(outlayRelation);
    setApprovalObj(
      props.ticket.steps.find((item) => item.approvalObj).approvalObj
    );
  }, [props.ticket, props.reloadTicket]);

  useEffect(() => {
    if (participant.rohiId) {
      getRohiOutlay();
    }
  }, [participant, props.reloadTicket]);

  useEffect(() => {
    if (approvalObj.relationOutlayId) {
      setSelected([approvalObj.relationOutlayId]);
    }
  }, [approvalObj]);

  useEffect(() => {
    if (checkValidation.rrhhApproved || checkValidation.correctCreditMethods) {
      setIsEditing(false);
    }
  }, [checkValidation, props.reloadTicket]);

  useEffect(() => {
    if (settlementData) {
      const quoteData = quotationData && quotationData.requestAmount;
      const data = validateSettlementTotal(quoteData || null, settlementData);
      setSettlementInfo({ ...data });
    }
  }, [settlementData, quotationData]);

  return (
    <div style={{ padding: "16px" }}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        direction="column"
        style={{ margin: "1rem 0" }}
      >
        <StepStatus
          completed={stepStatus}
          finalStep={true}
          handleNextStep={props.handleToggleSimpleDialog.bind(
            this,
            "confirm-dialog",
            "proceed-complete-ticket",
            { callback: handleCompleteStep }
          )}
          ticketCompleted={props.ticketCompleted}
          roles={props.roles}
        />
        <ViewQuotationText
          {...props}
          handleViewQuotation={handleViewQuotation}
          quotationData={quotationData}
        />
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        direction="column"
        style={{ margin: "1rem 0" }}
      >
        <Grid item container xs={12} justifyContent="flex-start">
          <Button
            variant="contained"
            className={
              props.ticket.status !== "processed"
                ? "action-method-btn disabled"
                : "action-method-btn"
            }
            disableElevation
            style={{ height: 35 }}
            disabled={props.ticket.status !== "processed"}
            onClick={generateTemplate}
          >
            generar ficha
          </Button>
        </Grid>
        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#424242",
            marginLeft: 5,
            marginTop: "2rem",
          }}
        >
          VALIDACIONES GENERALES
        </Typography>
        <Divider style={{ marginBottom: "2rem" }} />
        {props.ticket.affiliatedInstitution && (
          <CheckboxField
            xs={12}
            alignItems="flex-end"
            check={Boolean(checkValidation.rrhhApproved)}
            handleChange={() => handleCheckValidation("rrhhApproved")}
            style={{ margin: "1rem 0" }}
            color="primary"
            textColor="#43A047"
            label="APROBADO POR RECURSOS HUMANOS"
            disabled={props.ticketCompleted}
          />
        )}
        {quotationData?.productType !== "salary_advance" &&
        quotationData?.warrantyType !== "colabSalaryAdvance" ? (
          <>
            <CheckboxField
              xs={12}
              alignItems="flex-end"
              check={Boolean(checkValidation.signDocs)}
              handleChange={() => handleCheckValidation("signDocs")}
              style={{ margin: "1rem 0" }}
              color="primary"
              textColor="#43A047"
              label="DOCUMENTACIÓN FIRMADA"
              disabled={props.ticketCompleted}
            />
            <CheckboxField
              xs={12}
              alignItems="flex-end"
              check={Boolean(checkValidation.verifiedDocs)}
              handleChange={() => handleCheckValidation("verifiedDocs")}
              style={{ margin: "1rem 0" }}
              color="primary"
              textColor="#43A047"
              label="DOCUMENTACIÓN VERIFICADA"
              disabled={props.ticketCompleted}
            />
          </>
        ) : null}
        {/* <CheckboxField
            xs={12}
            alignItems="flex-end"
            check={Boolean(checkValidation.correctCreditMethods)}
            handleChange={() => handleCheckValidation("correctCreditMethods")}
            style={{ margin: "1rem 0" }}
            color="primary"
            textColor="#43A047"
            label="MÉTODOS DE CRÉDITO CORRECTOS"
            disabled={props.ticketCompleted}
          /> */}
        <CheckboxField
          xs={12}
          alignItems="flex-end"
          check={Boolean(checkValidation.registered)}
          handleChange={() => handleCheckValidation("registered")}
          style={{ margin: "1rem 0" }}
          color="primary"
          textColor="#43A047"
          label="REGISTRADO"
          disabled={props.ticketCompleted}
        />
        <CheckboxField
          xs={12}
          alignItems="flex-end"
          check={Boolean(checkValidation.outlaySettle)}
          handleChange={() => handleCheckValidation("outlaySettle")}
          style={{ margin: "1rem 0" }}
          color="primary"
          textColor="#43A047"
          label="DESEMBOLSO LIQUIDADO"
          disabled={props.ticketCompleted}
        />
        {props.ticket.affiliatedInstitution && (
          <CheckboxField
            xs={12}
            alignItems="flex-end"
            check={Boolean(checkValidation.docsRecollected)}
            handleChange={() => handleCheckValidation("docsRecollected")}
            style={{ margin: "1rem 0" }}
            color="primary"
            textColor="#43A047"
            label="DOCUMENTOS RECOLECTADOS"
            disabled={props.ticketCompleted}
          />
        )}
        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#424242",
            marginLeft: 5,
            marginTop: "2rem",
          }}
        >
          CONFIRMACIÓN DE FORMAS DE LIQUIDACIÓN
        </Typography>
        <Divider style={{ marginBottom: "2rem" }} />
        <SettlementTableActions
          {...props}
          enableEdit={
            props.ticket.status === "liquidated" ||
            stepStatus ||
            props.ticketCompleted ||
            isEditing
          }
          enableSave={
            props.ticket.status === "liquidated" ||
            stepStatus ||
            props.ticketCompleted ||
            !isEditing
          }
          showActions={!Boolean(checkValidation?.correctCreditMethods)}
          isEditing={isEditing}
          modifySettlementTables={modifySettlementTables}
          amountTransfer={settlementInfo.transferTotal}
          total={settlementInfo.total}
          settlementData={settlementData}
          step={"credit_formalization"}
        />
        {settlementData && (
          <SettlementFormsTable
            token={props.token}
            handleToggleSimpleDialog={props.handleToggleSimpleDialog}
            rohiId={participant.rohiId || null}
            state={settlementData}
            clientId={[props.ticket.customer]}
            originAccounts={
              creditorAccounts.length > 0
                ? creditorAccounts.map((item) => {
                    return {
                      label: item.name,
                      value: item.number,
                      type: item.accountType,
                    };
                  })
                : []
            }
            clientAccounts={clientBankAccounts}
            destinyAccounts={
              clientBankAccounts?.length > 0
                ? clientBankAccounts.map((item) => {
                    return {
                      name: item.name,
                      value: item.number,
                    };
                  })
                : []
            }
            fromTicket={true}
            readOnly={
              checkValidation.correctCreditMethods ||
              props.ticket.status === "liquidated" ||
              !isEditing ||
              props.ticketCompleted
            }
            enableReference={
              // !checkValidation.correctCreditMethods ||
              // props.ticket.status !== 'liquidated' ||
              // isEditing ||
              !props.ticketCompleted
            }
            // enableReference={true}
            ticketCompleted={props.ticketCompleted}
            handleState={handleState.bind(
              this,
              setSettlementData,
              settlementData
            )}
            handleAdd={handleModify.bind(
              this,
              setSettlementData,
              settlementData
            )}
            handleReplace={handleModify.bind(
              this,
              setSettlementData,
              settlementData
            )}
            handleEdit={handleModify.bind(
              this,
              setSettlementData,
              settlementData
            )}
            handleDelete={handleDelete.bind(
              this,
              setSettlementData,
              settlementData
            )}
          />
        )}
        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#424242",
            marginLeft: 5,
            marginTop: "2rem",
          }}
        >
          DESEMBOLSOS PENDIENTES DE RELACIÓN
        </Typography>
        <Divider style={{ marginBottom: "2rem" }} />
        {approvalObj.relationOutlayId && (
          <Typography
            variant={"subtitle1"}
            className={"title-text"}
            style={{ margin: "1rem 0" }}
          >
            <span>ID DE DESEMBOLSO SELECCIONADO:&nbsp;</span>
            <strong>{approvalObj.relationOutlayId}</strong>
          </Typography>
        )}
        <DisbursementTable
          data={rohiOutlays}
          selected={selected}
          setSelected={setSelected}
          // handleDisbursementSelect={handleDisbursementSelect}
          handleToggleSimpleDialog={props.handleToggleSimpleDialog}
          relationStatus={outlayRelationStatus}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="flex-end"
        style={{ marginTop: "3rem" }}
      >
        <Button
          variant="contained"
          className={
            selected.length < 1 || outlayRelationStatus
              ? "action-method-btn disabled"
              : "action-method-btn"
          }
          disableElevation
          style={{ height: 35 }}
          disabled={selected.length < 1 || outlayRelationStatus}
          onClick={props.handleToggleSimpleDialog.bind(
            this,
            "confirm-dialog",
            "proceed-complete-outlays",
            { callback: handleDisbursementSelect }
          )}
        >
          realizar relación
        </Button>
      </Grid>
    </div>
  );
}
