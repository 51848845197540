import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  IconButton,
  Divider,
  Paper,
  TableContainer,
  Toolbar,
  DialogActions,
  Button,
} from "@material-ui/core";
import {
  ArrowBack,
  CloseOutlined,
  Description,
  Settings,
  Visibility,
} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { TextFieldComponent } from "../../../components/SharedComponents/Fields";
import { GeneralTable } from "../../../components/SharedComponents/Table";
import { PaymentDetailDialog } from "./PaymentDetailDialog";
import AmortizationTable from "src/components/Calculators/AmortizationTable";
import { fetchQuotation, fetchTicket } from "src/utils/axios";
import TicketView from "src/containers/TicketManagement/View";

function TableToolbar(props) {
  return (
    <Toolbar>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          className="section-header"
          style={{ fontWeight: "bold", paddingLeft: 16 }}
        >
          {props.title}
        </Typography>
      </div>
    </Toolbar>
  );
}

function LoanDialog(props) {
  const [modalData, setModalData] = useState({});

  const [dialog, setDialog] = useState({ name: "", data: null });
  const [formDialog, setFormDialog] = useState(false);
  const [quotationId, setQuotationId] = useState(props.modalData.loans_details[0].quotation_number || false);
  const [quotationReferenceCode, setQuotationReferenceCode] = useState(false);
  const [ticketReferenceCode, setTicketReferenceCode] = useState(false);
  const [openTicketDialog, setOpenTicketDialog] = useState(false);

  const actions = (row) => {
    return (
      <>
        <div style={{ alignItems: "center" }}>
          <IconButton onClick={() => handleModal(row.payment_details[0])}>
            <Settings style={{ fontSize: 22, color: green[500] }} />
          </IconButton>
        </div>
      </>
    );
  };

  const closeFormDialog = () => {
    setFormDialog(false);
  };

  const openFormDialog = () => {
    setQuotationId(props.modalData.loans_details[0].quotation_number);
    setFormDialog(true);
  };

  const getQuotation = () => {
    const endpoint = fetchQuotation(props.token, quotationId);
    endpoint
      .then((response) => {
        const quotation = response.data;
        setQuotationReferenceCode(quotation.referenceCode);
      })
      .catch((err) => {
        setQuotationReferenceCode('---');
        console.error(err);
      });
  };

  const getTicket = () => {
    const endpoint = fetchTicket(props.token, props.modalData.loans_details[0].ticket_number);
    endpoint
      .then((response) => {
        const ticket = response.data;
        setTicketReferenceCode(ticket.referenceCode);
      })
      .catch((err) => {
        setTicketReferenceCode('---');
        console.error(err);
      });
  }

  const emptyFunction = () => {
    return null;
  };

  const setCurrency = (value) => {
    let data = parseFloat(value).toFixed(2);
    return Number(data).toLocaleString("en-US", {
      style: "currency",
      currency: "DOP",
    });
  };

  const columnsPayments = [
    {
      id: "transaction_number",
      label: "Número transaction".toUpperCase(),
      minWidth: 100,
      align: "center",
    },
    {
      id: "payment_date",
      label: "Fecha pago".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "beginning_balance",
      label: "Balance inicial".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.beginning_balance),
    },
    {
      id: "principal_paid",
      label: "Principal".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.principal_paid),
    },
    {
      id: "interest_paid",
      label: "Interes".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.interest_paid),
    },
    {
      id: "insurrance_paid",
      label: "Seguro".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.insurrance_paid),
    },
    {
      id: "arrears_paid",
      label: "Atraso".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.arrears_paid),
    },
    {
      id: "other_charges_paid",
      label: "Otros cargos".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.other_charges_paid),
    },
    {
      id: "total_amount_paid",
      label: "Total pago".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.total_amount_paid),
    },
    {
      id: "ending_balance",
      label: "Balance final".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.ending_balance),
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      element: actions,
    },
  ];

  const fields = [
    {
      id: "transaction_reference",
      label: "Referencia transacción".toUpperCase(),
      value: modalData.transaction_reference || "",
    },
    {
      id: "receipt_number",
      label: "Número recibo".toUpperCase(),
      value: modalData.receipt_number || "",
    },
    {
      id: "payment_type",
      label: "Tipo pago".toUpperCase(),
      value: modalData.payment_type || "",
    },
    {
      id: "accounting_entry",
      label: "Asiento contable".toUpperCase(),
      value: modalData.accounting_entry || "",
    },
    {
      id: "accounting_account",
      label: "Cuenta contable".toUpperCase(),
      value: modalData.payment_form_info
        ? modalData.payment_form_info[0].accounting_account
        : "",
    },
    {
      id: "payment_form",
      label: "Forma pago".toUpperCase(),
      value: modalData.payment_form_info
        ? modalData.payment_form_info[0].payment_form
        : "",
    },
    {
      id: "total_amount_paid",
      label: "Total pago".toUpperCase(),
      value: modalData.payment_form_info
        ? Number(
            modalData.payment_form_info[0].total_amount_paid
          ).toLocaleString("en-US", {
            style: "currency",
            currency: "DOP",
          })
        : "",
    },
  ];
  const columnsArrearsPayments = [
    {
      id: "due_date",
      label: "Fecha vencimiento".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => new Date(row.due_date).toISOString().split("T")[0],
    },
    {
      id: "principal_due",
      label: "Principal".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.principal_due),
    },
    {
      id: "interest_due",
      label: "Interes".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.interest_due),
    },
    {
      id: "insurrance_due",
      label: "Seguro".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.insurrance_due),
    },
    {
      id: "arrear_due",
      label: "Mora".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.arrear_due),
    },
    {
      id: "total_amount_due",
      label: "Total vencido".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.total_amount_due),
    },
    {
      id: "total_amount_paid",
      label: "Total pagado".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.total_amount_paid),
    },
    {
      id: "savings_due",
      label: "Total ahorro".toUpperCase(),
      minWidth: 100,
      align: "center",
      element: (row) => setCurrency(row.savings_due),
    },
  ];

  const handleToggleDialog = (dialog, data) => {
    const selectedDialog = dialog ? dialog : "";
    const dialogData = data && (data.uniqueId || data.id) ? data : null;
    const obj = { name: selectedDialog, data: dialogData };
    setDialog({ ...obj });
  };

  const handleModal = (data) => {
    setModalData(data);

    setDialog({ name: "payment" });
  };

  const handleTicketDialog = () => {
    setOpenTicketDialog(!openTicketDialog);
  }

  useEffect(() => {
    getQuotation();
    getTicket();
  }, []);

  return (
    <>
      <Dialog
        fullScreen={true}
        onClose={(e, reason) =>
          reason === "backdropClick" && e.preventDefault()
        }
        className="dialog-container"
        open={props.open}
      >
        <DialogTitle style={{ backgroundColor: green[500] }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography
              component={"span"}
              variant="h5"
              className="dialog-title"
              style={{ color: "white" }}
            >
              {props.client.fullName} - {props.client.rohiId}
            </Typography>
            <IconButton
              onClick={props.handleToggleDialog.bind(this, undefined)}
            >
              <CloseOutlined className="dialog-header-close-btn" />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ position: "relative" }}>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3 className="modal-title">DETALLE DEL PRESTAMO</h3>
              </Grid>
              <Divider />
              {props.fields.map((item, index) => {
                return (
                  <TextFieldComponent
                    xs={3}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel={item.label}
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={item.value ? item.value : quotationReferenceCode}
                    inputProps={
                      item.inputProps && {
                        endAdornment: (
                          <IconButton
                            style={{ padding: 0, margin: 0 }}
                            onClick={() => openFormDialog()}
                            disabled={quotationReferenceCode === '---' ? true : false}
                          >
                            <Visibility
                              style={{
                                fontSize: 22,
                                color: quotationReferenceCode === '---' ? 'grey' : green[500],
                              }}
                            />
                          </IconButton>
                        ),
                      }
                    }
                    disabled={true}
                  />
                );
              })}
              <Button
                style={{marginTop: 20, width: 'auto', height: 35, borderRadius:5, backgroundColor: ticketReferenceCode === '---' ? '#808080' : '#43A047', color:'#FFFFFF', fontSize:15}}
                onClick={() => handleTicketDialog()}
                disabled={ticketReferenceCode === '---'}>
                  Ver ticket ({ticketReferenceCode})
              </Button>
            </Grid>

            <Paper>
              <div style={{ width: "100%", marginTop: 60 }}>
                <TableContainer style={{ width: "100%", marginTop: "5%" }}>
                  <GeneralTable
                    columns={columnsPayments}
                    data={
                      props.modalData.loans_details[0].payment_details || []
                    }
                    toolbar={<TableToolbar title={"PAGOS REALIZADOS"} />}
                  />
                </TableContainer>
              </div>
            </Paper>
            <br />
            <br />
            <br />
            <Paper>
              {props.modalData.loans_details[0].arrears_details ? (
                <Grid container spacing={2} style={{ padding: "3%" }}>
                  <TextFieldComponent
                    xs={4}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Total cuotas pendientes"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      props.modalData.loans_details[0].arrears_details
                        ? props.modalData.loans_details[0].arrears_details[0]
                            .quotes_pending
                        : ""
                    }
                    disabled={true}
                  />
                  <TextFieldComponent
                    xs={4}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Total pendiente"
                    fieldID="requestNumber"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin="dense"
                    fullWidth
                    value={
                      props.modalData.loans_details[0].arrears_details
                        ? Number(
                            props.modalData.loans_details[0].arrears_details[0]
                              .amount_due_pending
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "DOP",
                          })
                        : ""
                    }
                    disabled={true}
                  />
                </Grid>
              ) : null}
              <div style={{ width: "100%" }}>
                <TableContainer style={{ width: "100%" }}>
                  <GeneralTable
                    columns={columnsArrearsPayments}
                    data={
                      props.modalData.loans_details[0].arrears_details
                        ? props.modalData.loans_details[0].arrears_details[0]
                            .amount_due_details
                        : []
                    }
                    toolbar={<TableToolbar title={"VENCIMIENTOS ATRASADOS"} />}
                  />
                </TableContainer>
              </div>
            </Paper>
            <br />
            <br />
            <br />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={formDialog} fullScreen={true} onClose={closeFormDialog}>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: green[500], color: "white" }}
            onClick={closeFormDialog}
          >
            <ArrowBack />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ position: "relative" }}>
          <AmortizationTable
            changeSection={emptyFunction}
            quotationId={quotationId}
          />
        </DialogContent>
      </Dialog>

      {dialog.name === "payment" ? (
        <PaymentDetailDialog
          open={true}
          client={props.client}
          handleToggleDialog={handleToggleDialog}
          fields={fields}
          modalData={modalData}
        />
      ) : null}
      <Dialog
        fullScreen={true}
        onClose={(e, reason) =>
          reason === "backdropClick" && e.preventDefault()
        }
        className="dialog-container"
        open={openTicketDialog}
      >
        <DialogTitle style={{ backgroundColor: green[500] }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography
              component={"span"}
              variant="h5"
              className="dialog-title"
              style={{ color: "white" }}
            >
              {props.client.fullName} - {props.client.rohiId}
            </Typography>
            <IconButton
              onClick={handleTicketDialog}
            >
              <CloseOutlined className="dialog-header-close-btn" />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ position: "relative" }}>
          <TicketView id={props.modalData.loans_details[0].ticket_number}/>
        </DialogContent>
      </Dialog>
    </>
  );
}

export { LoanDialog };
