import React,{ useEffect, useState, useRef } from 'react';
import { Grid, Typography, IconButton, Button, Toolbar, Stepper, Step, StepLabel, StepButton } from '@material-ui/core';
import { Add, Edit, Visibility, Delete, HighlightOff, Description, Remove } from '@material-ui/icons';
import { GeneralTable } from './../../../components/SharedComponents/Table';
import NumberFormat from './../../../components/SharedComponents/NumberFormat'

import {
    Box, Collapse, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper,
    makeStyles
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';
import { ClaimsDetailDialog } from '../Dialogs'
import ListAltIcon from '@material-ui/icons/ListAlt';
import TablePagination from '@material-ui/core/TablePagination';

function ClaimTableToolbar(props) {
    return (
        <Toolbar>
            <Grid container item direction="row" spacing={5} alignItems="center">
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{ fontWeight: 'bold', paddingLeft: 16 }}
                >
                    {props.order ? props.title : "Reclamaciones"}
                </Typography>
            </Grid>
        </Toolbar>
    );
}

function ClaimTable(props) {
    const actions = (row) => {
        return (
            <Grid container direciton="row" justifyContent="space-between">
                <IconButton
                    onClick={props.handleToggleDialog.bind(this, 'claim-modify', row)}
                    >
                    <ListAltIcon ListAltIcon style={{ fontSize: 22, color: '#616161' }} />
                </IconButton>
                <IconButton
                    onClick={
                        props.handleToggleSimpleDialog
                            .bind(this, 'confirm-dialog', 'proceed-delete', { callback: props.handleDelete.bind(this, row), row: row })
                    }
                >
                    <Delete style={{ fontSize: 22, color: '#616161' }} />
                </IconButton>
            </Grid>
        );
    };

    const status = (row) => {
        const statusColors = [
            {bgColor: '#43A047', color: '#FFF', state:'closed', name: 'Cerrado'}, 
            {bgColor: '#f44336', color: '#FFF', state:'canceled', name: 'Cancelado'}, 
            {bgColor: '#ef6c00', color: '#FFF', state:'delayed', name: 'Atrasado'}, 
            {bgColor: '#1ea0f7', color: '#FFF', state:'active', name: 'Activo'}, 
        ]

        const coloredLabel = statusColors.find(item => item.state === row.status);

        return(
            <Button
                variant="contained"
                disableElevation
                style={{
                    minWidth:120, height: 25, borderRadius:20,
                    backgroundColor: coloredLabel.bgColor,
                    color: coloredLabel.color,
                    fontSize:12,
                }}
            >
                {coloredLabel.name}
            </Button>
        )
    }

    const claimTypeOfCases = (row) => {
        return (
            <span>
                {
                    props.typeOfCases.length > 0 ?
                        props.typeOfCases.find(item => item.value === row.typeOfCase).name
                        : row.typeOfCase
                }
            </span>
        )
    }

    const claimStatus = (row) => {
        return (
            <span>
                {
                    props.status.length > 0 ?
                        props.status.find(item => item.value === row.status).name
                        : row.status
                }
            </span>
        )
    }

    const claimDepartment = (row) => {
        return (
            <span>
                {
                    props.departments.length > 0 ?
                        props.departments.find(item => item.value === row.department).name
                        : row.department
                }
            </span>
        )
    }

    const removeFromList = (row) => {
        return (
            <Grid container direciton="row" justifyContent="space-between">
                <IconButton
                    onClick={props.removeClaim.bind(this, row)}
                >
                    <HighlightOff style={{ fontSize: 22, color: '#616161' }} />
                </IconButton>
            </Grid>
        );
    }

    const date = (column, row) => {
        return (
            <span>
                {
                    row[column]?row[column].split('T')[0]:''
                }
            </span>
        )
    }

    const columns = [
        { id: 'client', label: 'CLIENTE', minWidth: 190 },
        { id: 'status', label: 'ESTADO', element: status, minWidth: 100 },
        { id: 'createdBy', label: 'CREADO POR', minWidth: 150 },
        { id: 'caseNumber', label: 'NÚMERO DE CASO', minWidth: 150 },
        { id: 'department', label: 'DEPARTAMENTO', element: claimDepartment, minWidth: 150 },
        { id: 'typeOfCase', label: 'TIPO DE CASO', element: claimTypeOfCases, minWidth: 150 },
        { id: 'dateOfCreation', label: 'FECHA DE CREACIÓN', element: date.bind(this, 'dateOfCreation'), minWidth: 100 },
        { id: 'dueDate', label: 'FECHA DE VENCIMIENTO', element: date.bind(this, 'dueDate'),   minWidth: 100 },
        { id: 'action', label: 'ACCIÓN', align: 'center', minWidth: 170, element: props.order ? removeFromList : actions },
    ];

    return (
        <GeneralTable
            columns={columns}
            data={props.data}
            rowsPerPage={10}
            toolbar={
                <ClaimTableToolbar
                    title={props.title}
                    order={props.order}
                    handleDelete={props.handleDelete}
                    claimsQuantity={props.data.length}
                />
            }
        />
    );
}

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row(props) {
    const { row } = props;
    const [openDialog, setOpenDialog] = React.useState(props.open);
    const classes = useRowStyles();
    const [selectedRow, setSelectedRow] = React.useState({});
    const [currentRow, setCurrentRow] = React.useState(row)
    const [data, setData] = React.useState({})

    let status;
    let dateOfCreation;
    let department;
    let typeOfAction;

    const loadData = () =>{
        if (currentRow.status){
            let allStatus = [...props.listData.billingClaimDetailsStatus, ...props.listData.nonBillingclaimDetailsStatus];
            setData({
                status : allStatus.find(item => item.value === currentRow.status).name,
                typeOfAction:props.listData.typeOfActions.find(item => item.value === currentRow.typeOfAction).name,
                department:props.listData.departments.find(item => item.value === currentRow.department).name  || '',
                dateOfCreation:currentRow.dateOfCreation.split('T')[0],
            })
        }
    }

    useEffect(() =>{
        setCurrentRow(currentRow)
        setOpenDialog(props.open)
        loadData()
    }, [currentRow])
    

    const statusColor = (row) => {
            const statusColors = [ 
                {bgColor: '#023e8a', color: '#FFF', state:'Entregado', name: 'Entregado'}, 
                {bgColor: '#344e41', color: '#FFF', state:'Contactado', name: 'Contactado'}, 
                {bgColor: '#d4a373', color: '#FFF', state:'Localizado', name: 'Localizado'}, 
                {bgColor: '#f44336', color: '#FFF', state:'Cancelado', name: 'Cancelado'}, 
                {bgColor: '#43A047', color: '#FFF', state:'Cerrado', name: 'Cerrado'}, 
                {bgColor: '#1ea0f7', color: '#FFF', state:'En proceso', name: 'En proceso'}, 
                {bgColor: '#996af7', color: '#FFF', state:'Reasignado', name: 'Reasignado'}, 
                {bgColor: '#ef6c00', color: '#FFF', state:'Atrasado', name: 'Atrasado'}, 
            ]
            const coloredLabel = statusColors.find(item => item.state === row.status) ;
        return(
            <Button
                variant="contained"
                disableElevation
                style={{
                    minWidth:120, height: 25, borderRadius:20,
                    backgroundColor: coloredLabel.bgColor,
                    color: coloredLabel.color,
                    fontSize:12,
                }}
            >
                {coloredLabel.name}
            </Button>
            
        )
    }
    return (
        <React.Fragment>
            <TableRow className={classes.root} >
                <TableCell component="th" scope="row">
                    {data.dateOfCreation}
                </TableCell>
                <TableCell align="right">{ data.department }</TableCell>
                <TableCell align="right">{ data.status? statusColor(data): ""}</TableCell>
                <TableCell align="right">{ data.typeOfAction }</TableCell>
                <TableCell align="right">
                    <IconButton onClick={() => {setOpenDialog(!openDialog)}}>
                        <ListAltIcon ListAltIcon style={{ fontSize: 22, color: '#616161' }} />
                    </IconButton>
                    <IconButton onClick={props.remove.bind(this, props.id)}>
                        <Remove style={{ fontSize: 22, color: '#616161' }} />
                    </IconButton>
                </TableCell>
            </TableRow>
            { openDialog ?
            < ClaimsDetailDialog
                setCreatedRow={setCurrentRow}
                open={openDialog}
                setOpen={setOpenDialog}
                data={currentRow}
                getClaim={props.getClaim}
                handleClaimDetail={props.handleClaimDetail}
                handleFieldChange={props.handleFieldChange}
                modify={true}
                uniqueIdsDetailParent={props.uniqueIdsDetailParent}
                listData={{
                    claims: props.listData.claims,
                    status: props.listData.status,
                    nonBillingclaimDetailsStatus: props.listData.nonBillingclaimDetailsStatus,
                    billingClaimDetailsStatus: props.listData.billingClaimDetailsStatus,
                    departments: props.listData.departments,
                    typeOfActions: props.listData.typeOfActions,
                }}
                claim={props.claim}
                token={props.token}
                setLoading={props.setLoading}
                handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                />: null
            }
        </React.Fragment>
    );
}

function CollapsibleTable(props) {
    const defaultFieldsValue = {
        status: null,
        dateOfCreation: "",
        department: null,
        comment: "",
        time: "",
        typeOfAction: null,
        uniqueId: "",
        dateOfCreation: "",
        open: true
    }
    const [details, setDetails] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = React.useState([])
    const [activeStep, setActiveStep] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, details.length - page * rowsPerPage);

    const removeRow = (id, i) => {setDetails([...details.filter((c, i) => id !== i)])}

    const handleStep = (step, uniqueId) => {
        setActiveStep(step);
        const updatedDetail = [...details.map((c) => {
            if(uniqueId === c.uniqueId) {
                c.open = true;
                return c;
            }
            c.open = false;
            return c;
        })]
        setDetails(updatedDetail);
    };


    useEffect(() => {
        if(props.claimDetails){
            setDetails(props.claimDetails.serializedDetails.map(item => {item.open = false; return item}));
            setStatus([...props.listData.nonBillingclaimDetailsStatus, ...props.listData.billingClaimDetailsStatus]);
        }

    }, []);

    return (<>
        <TableContainer component={Paper} style={{margin:'0 8px'}}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>Fecha de creación</TableCell>
                        <TableCell align="right">Departamento</TableCell>
                        <TableCell align="right">Estado</TableCell>
                        <TableCell align="right">Tipo de accion</TableCell>
                        <TableCell align="right">
                            {!Boolean(props.claimDetails.status === "canceled" || props.claimDetails.status === "closed") ? (
                                <IconButton onClick={() => { setDetails([defaultFieldsValue, ...details]);}} >
                                    <Add style={{ fontSize: 22, color: "#616161" }} />
                                </IconButton>
                            ) : null}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {details
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, id) => (
                        <Row
                            id={id}
                            remove={removeRow}
                            open={row.open}
                            getClaim={props.getClaim}
                            handleClaimDetail={props.handleClaimDetail}
                            key={row.uniqueId}
                            listData={{
                                departments:props.listData.departments,
                                typeOfActions:props.listData.typeOfActions,
                                claims:props.listData.claims,
                                nonBillingclaimDetailsStatus:props.listData.nonBillingclaimDetailsStatus,
                                billingClaimDetailsStatus:props.listData.billingClaimDetailsStatus,
                            }}
                            uniqueIdsDetailParent={{"uniqueIds": props.claimDetails.details, "uniqueId": props.claimDetails.uniqueId}}
                            row={row}
                            token={props.token}
                            setLoading={props.setLoading}
                            handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                            claim={props.claimDetails}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={details.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Filas por página:"}
        />
    </>
        
    );
}

export { ClaimTable,  CollapsibleTable }