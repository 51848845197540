import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  AutocompleteField,
  CheckboxField,
  NumericTextField,
} from "src/components/SharedComponents/Fields";
import { conceptOptions, paymentMethodsLeads } from "src/utils";

export default function AffiliationTermsForm({
  rFields,
  fields,
  section,
  errorFields,
  onChange,
  handleSelectValue,
  getFiltersOptions,
  institution,
  setDisabledLead,
  expand,
  calcDistributionChart,
}) {
  const [totalMonthlyDiscount, setTotalMonthlyDiscount] = useState(0);
  const [totalFirstDeposit, setTotalFirstDeposit] = useState(0);
  const [distributionPanel, setDistributionPanel] = useState([]);
  const [panelInfo, setPanelInfo] = useState({
    headers: [],
    distributionData: [],
  });
  const [hasWarranty, setHasWarranty] = useState(false);
  const [totalWarranty, setTotalWarranty] = useState(0);
  const [initialData, setInitialData] = useState();

  useEffect(() => {
    const getInitialData = async () => {
      if (expand === "affiliationTerms") {
        const data = await calcDistributionChart();
        setInitialData(data);
      }
    };
    getInitialData();
  }, [expand]);

  useEffect(() => {
    setPanelInfo(initialData);
  }, [initialData]);

  useEffect(() => {
    if (initialData) {
      const distributionData = panelInfo?.distributionData;
      let monthlyDiscountValue = 0;
      //if client select discount monthly obligations, substract them from fields.amountToSave first
      if (fields.discountObligations) {
        const monthlyObligations = distributionData.reduce((total, item) => {
          return total + parseFloat(item.secuentDiscount);
        }, 0);
        monthlyDiscountValue =
          (parseFloat(fields.amountToSave) || 0) -
          parseFloat(monthlyObligations);
      } else {
        monthlyDiscountValue = parseFloat(fields.amountToSave) || 0;
      }
      const ahorroAVistaIndex = distributionData.findIndex(
        (item) => item.name === "onSight"
      );
      const ahorroAVista = distributionData.find(
        (item) => item.name === "onSight"
      )?.secuentDiscount;

      let updatedDistributionPanel = [];

      if (ahorroAVistaIndex !== -1) {
        //change de value of the onSight option in secuentDiscount to add the monthly discount if given.
        updatedDistributionPanel = distributionData.map((item) => ({
          ...item,
        }));
        updatedDistributionPanel[ahorroAVistaIndex].secuentDiscount =
          ahorroAVista && monthlyDiscountValue > 0
            ? ahorroAVista + monthlyDiscountValue
            : monthlyDiscountValue
            ? monthlyDiscountValue
            : ahorroAVista;
      }
      const validateWarranty = updatedDistributionPanel.find(
        (item) => item.warranty >= 0
      );
      setHasWarranty(validateWarranty);
      //check if warranty exist in any of the objects of distributionData and add it to every object so the table does not look incomplete.
      if (validateWarranty) {
        const modifiedDistributionPanel = updatedDistributionPanel.map(
          (info) => ({ ...info, warranty: info.warranty ?? 0 })
        );
        setDistributionPanel(modifiedDistributionPanel);
      } else {
        setDistributionPanel(updatedDistributionPanel);
      }
    }
  }, [fields.amountToSave, fields.discountObligations, panelInfo]);

  useEffect(() => {
    const initialDepositTotal = distributionPanel.reduce((total, item) => {
      return total + parseFloat(item.firstDiscount);
    }, 0);

    const totalMonthlyDiscount = distributionPanel.reduce((total, item) => {
      return total + parseFloat(item.secuentDiscount);
    }, 0);

    if (hasWarranty) {
      const warrantyTotal = distributionPanel.reduce((total, item) => {
        return total + item.warranty;
      }, 0);

      setTotalWarranty(warrantyTotal);
    }

    const findNegative = distributionPanel.find(
      (item) => item.secuentDiscount < 0
    );

    if (findNegative) {
      setTotalMonthlyDiscount(findNegative?.secuentDiscount);
      setDisabledLead(true);
    } else {
      setTotalMonthlyDiscount(totalMonthlyDiscount);
      setDisabledLead(false);
    }

    setTotalFirstDeposit(initialDepositTotal);
  }, [fields.amountToSave, distributionPanel, hasWarranty]);

  useEffect(() => {
    const onSightItem = distributionPanel.find(
      (item) => item.name === "onSight"
    );
    const contributionsItem = distributionPanel.find(
      (item) => item.name === "contributions"
    );

    const cuotaOnSight = parseFloat(onSightItem?.secuentDiscount || 0);
    const cuotaContributions = parseFloat(
      contributionsItem?.secuentDiscount || 0
    );

    onChange("any", "cuotaOnSight", cuotaOnSight);
    onChange("any", "cuotaContributions", cuotaContributions);
  }, [distributionPanel]);

  return (
    <Grid container spacing={3} className="form-wrapper-fields-container">
      <NumericTextField
        xs={4}
        fullWidth
        fieldLabel={`Descuento mensual ${
          rFields.includes("amountToSave") ? "(*)" : ""
        }`}
        fieldID="leads-field-amountToSave"
        placeholder="$0.00"
        prefix="$"
        value={fields.amountToSave}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator={true}
        onChange={onChange.bind(this, "numeric", "amountToSave")}
        error={Boolean(errorFields[section]?.amountToSave)}
        helperText={
          errorFields[section]?.amountToSave
            ? errorFields[section]?.amountToSave
            : null
        }
      />
      <CheckboxField
        xs={4}
        label={`¿Descontar obligaciones de ahorro del monto mensual? ${
          rFields.includes("discountObligations") ? "(*)" : ""
        }`}
        alignItems="flex-end"
        check={fields.discountObligations || false}
        handleChange={onChange.bind(this, "check", "discountObligations")}
        color="primary"
        textColor="#43A047"
      />
      {institution?.state !== "affiliate" && (
        <AutocompleteField
          xs={4}
          fieldLabel={`Forma de pago ${
            rFields.includes("wayToPay") ? "(*)" : ""
          }`}
          fieldID="leads-field-wayToPay"
          fieldInnerLabel="Seleccione una forma de pago"
          value={
            handleSelectValue(fields.wayToPay, paymentMethodsLeads, "value") ||
            null
          }
          handleChange={onChange.bind(this, "autocomplete", "wayToPay")}
          options={getFiltersOptions(paymentMethodsLeads)}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          error={Boolean(errorFields[section]?.wayToPay)}
          helperText={
            errorFields[section]?.wayToPay
              ? errorFields[section]?.wayToPay
              : null
          }
          multiple={false}
        />
      )}
      <TableContainer component={Paper}>
        <Typography
          variant={"subtitle1"}
          className="section-header"
          style={{ fontWeight: "bold", color: "#5e5e5e", padding: "5px 15px" }}
        >
          CUADRO DE DISTRIBUCIÓN
        </Typography>
        <Table fullWidth>
          <TableHead>
            <TableRow>
              {panelInfo?.headers?.map((columna) => (
                <TableCell key={columna.value} style={{ fontWeight: "bold" }}>
                  {columna.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {distributionPanel.map((info, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    {conceptOptions.find((item) => item.value === info.name)
                      ?.name || info.name}
                  </TableCell>
                  <TableCell>
                    {parseFloat(info.firstDiscount).toLocaleString("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      style: "currency",
                    }) || "-"}
                  </TableCell>
                  <TableCell
                    style={{
                      color: info.secuentDiscount < 0 ? "red" : "#212121",
                    }}
                  >
                    {parseFloat(info.secuentDiscount).toLocaleString("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      style: "currency",
                    }) || "-"}
                  </TableCell>
                  {info.warranty >= 0 ? (
                    <TableCell>
                      {parseFloat(info.warranty).toLocaleString("en-US", {
                        currency: "USD",
                        minimumFractionDigits: 2,
                        style: "currency",
                      }) || "-"}
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell>
                {parseFloat(totalFirstDeposit).toLocaleString("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  style: "currency",
                }) || "-"}
              </TableCell>
              <TableCell
                style={{ color: totalMonthlyDiscount < 0 ? "red" : "#212121" }}
              >
                {parseFloat(totalMonthlyDiscount).toLocaleString("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  style: "currency",
                }) || "-"}
              </TableCell>
              {distributionPanel.find((item) => item.warranty > 0) ? (
                <TableCell>
                  {parseFloat(totalWarranty).toLocaleString("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                    style: "currency",
                  }) || "-"}
                </TableCell>
              ) : null}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {totalMonthlyDiscount < 0 && (
        <Typography color="secondary">
          {" "}
          El descuento mensual no puede ser menor a la suma de las obligaciones
          mensuales.
        </Typography>
      )}
    </Grid>
  );
}
