import * as React from "react";
import { quotationStatus, transactionTypes, translations } from "src/utils";
// import { fetchParticipants } from "src/utils/axios";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { createStyles, withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { SearchComponent } from "../../../components/SharedComponents/Fields";

const styles: any = createStyles({
  formControl: {
    marginTop: 20,
    width: "100%",
  },
  formControlClient: {
    marginTop: 20,
    width: "100%",
  },
  greenButton: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    animationName: "mui-auto-fill-cancel",
    background: "none",
    border: 0,
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    boxSizing: "content-box",
    color: "currentColor",
    display: "block",
    font: "inherit",
    height: "1.1875em",
    margin: 0,
    minWidth: 0,
    padding: "6px 0 7px",
    transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    width: "100%",
  },
  label: {
    color: green[500],
  },
  redButton: {
    "&:hover": {
      backgroundColor: red[200],
    },
    backgroundColor: red[500],
    color: "white",
  },
  textField: {
    marginTop: 20,
    minWidth: 190,
    width: "50%",
  },
});

interface IQuotationSearchFilterProps {
  token: string;
  open: boolean;
  classes: {
    [key: string]: string;
  };
  handleClose(): void;
  search(params: IQuotationParams): void;
}

interface IQuotationSearchFilterState {
  [key: string]: string | string[] | boolean | any | IParticipant[];

  client: any;
  customer_name: any;
  newClient: boolean;
  // clients: IParticipant[];
  quotation_date_from: string;
  quotation_date_to: string;
  quotation_status: any[];
  product_type: any[];
}

class QuotationSearchFilter extends React.Component<
  IQuotationSearchFilterProps,
  IQuotationSearchFilterState
> {
  public state: IQuotationSearchFilterState = {
    client: [],
    customer_name: [],
    // clients: [],
    newClient: false,
    quotation_date_from: "",
    quotation_date_to: new Date().toISOString().split("T")[0],
    quotation_status: [],
    product_type: [],
  };
  constructor(props: IQuotationSearchFilterProps) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.cleanAutoComplete = this.cleanAutoComplete.bind(this);
  }

  // public componentDidMount() {
  //   fetchParticipants({
  //     token: this.props.token,
  //   }).then((res: any) => {
  //     this.setState({ clients: res.data.results || [] });
  //   });
  // }

  public handleSearch() {
    const params: IQuotationParams = {
      client: this.state.client,
      // .map((item: IParticipant) => item.uniqueId)
      // .join(",")
      customerName: this.state.customer_name,
      quotationDateFrom: this.state.quotation_date_from,
      quotationDateTo: this.state.quotation_date_to,
      quotationStatus: this.cleanAutoComplete(this.state.quotation_status),
      productType: this.cleanAutoComplete(this.state.product_type),
      token: this.props.token,
    };

    this.props.search(params);
    this.props.handleClose();
  }
  public handleClear() {
    this.setState({
      client: [],
      customer_name: [],
      quotation_date_from: "",
      quotation_date_to: new Date().toISOString().split("T")[0],
      quotation_status: [],
      product_type: [],
      reference_code: "",
    });
    this.props.search({ token: this.props.token });
    this.props.handleClose();
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.currentTarget.value });
    };
  }
  public cleanAutoComplete(optionList: any) {
    if (optionList.length) {
      let cleanList = "";
      for (const i of optionList) {
        cleanList += `${i.value},`;
      }
      return cleanList;
    }
    return "";
  }
  public handleCheckboxChange(key: string) {
    /* Handle the state of CheckBox components */
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.checked,
      });
    };
  }

  public handleAutocompleteChange(key: any, obj: any, val: any) {
    /* Handle the state of Autocomplete components */
    const data = obj || val;

    this.setState({
      [key]: data.value ? data.value : "",
    });
  }

  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public render() {
    const { classes, handleClose, open } = this.props;
    const handleChange2 = (key: string, val: string) =>
      this.setState({ [key]: val });

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12}>
              <SearchComponent
                xs={12}
                containerClass="field-container"
                fieldClass={{ style: styles.label }}
                typeClass={styles.label}
                fieldID="client"
                fieldInnerLabel={translations.DEBTOR}
                handleChange={this.handleAutocompleteChange.bind(
                  this,
                  "client"
                )}
                optionType={"participants"}
                variant="standard"
                limitTags={1}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel
                  htmlFor="quotation_status"
                  style={styles.label}
                  shrink={true}
                >
                  {translations.STATUS}
                </InputLabel>
                <Autocomplete
                  style={{ marginTop: "16px" }}
                  multiple={true}
                  options={quotationStatus}
                  value={this.state.quotation_status}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, newValue: any) =>
                    handleChange2("quotation_status", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item={true} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel
                  htmlFor="product_type"
                  style={styles.label}
                  shrink={true}
                >
                  {translations.PRODUCT_TYPE}
                </InputLabel>
                <Autocomplete
                  style={{ marginTop: "16px" }}
                  multiple={true}
                  options={transactionTypes}
                  value={this.state.product_type}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, newValue: any) =>
                    handleChange2("product_type", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                label={`Fecha ${translations.FROM}`}
                value={this.state.quotation_date_from}
                type="date"
                fullWidth={true}
                onChange={this.handleChange("quotation_date_from")}
                InputLabelProps={{
                  shrink: true,
                  style: styles.label,
                }}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <TextField
                label={`Fecha ${translations.TO}`}
                value={this.state.quotation_date_to}
                type="date"
                onChange={this.handleChange("quotation_date_to")}
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                  style: styles.label,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={classes.redButton} onClick={this.handleClear}>
            <ClearIcon />
            &nbsp;
            {`${translations.CLEAR} ${translations.SEARCH}`}
          </Button>
          <Button className={classes.greenButton} onClick={this.handleSearch}>
            <SearchIcon />
            &nbsp;
            {translations.SEARCH}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(QuotationSearchFilter);
