import React, { useState } from 'react';
import { 
        Grid, Typography, Paper, Tabs, Tab, Button,
        Card, CardContent, InputBase
} from '@material-ui/core';
import { AttachFile, Publish } from '@material-ui/icons';
import { MovementTable } from './../Tables/index';
import { ConfirmDialogComponent } from './../Dialogs';
import { withStyles } from '@material-ui/core/styles';
import * as XLSX from "xlsx";

const StyledTabs = withStyles({
    indicator: {
      backgroundColor: '#4CAF50',
    }
})(Tabs);
  

function AccountDetailHeader(props) {

    return(
        <div style={{padding:'0 0 15px 0'}}>
            <Grid container justify="space-evenly" spacing={3}>
                <Grid container item xs={4}>
                    <Card style={{width:'100%'}}>
                        <CardContent style={{marginTop:'5px'}}>
                            <Typography
                                variant="h6"
                                style={{color:'#424242'}}
                            >
                                {props.bankName}: * 
                                {props.bankAccount.substring(props.bankAccount.length - 4)}
                                <br/>
                                <Typography 
                                    variant="overline"
                                    style={{color:'#616161'}}
                                >Banco</Typography>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid container item xs={4}>
                    <Card style={{width:'100%'}}>
                        <CardContent style={{marginTop:'5px'}}>
                            <Typography
                                variant="h6"
                                style={{color:'#424242'}}
                            >
                                ${props.accountBalance}
                                <br/>
                                <Typography 
                                    variant="overline"
                                    style={{color:'#616161'}}
                                >Balance</Typography>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid container item xs={4}>
                    <Card style={{width:'100%'}}>
                        <CardContent style={{marginTop:'5px'}}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 'bold',
                                    color: props.accountStatus === 'Pendiente por conciliar' ? '#E53935' : '#43A047'
                                }}
                            >
                                {props.accountStatus}
                                <br/>
                                <Typography 
                                    variant="overline"
                                    style={{color:'#616161'}}
                                >Estado</Typography>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

function BankMovementHeader(props) {

    const [file, setFile] = useState('');
    const handleFileChange = event => {
        setFile(event.target.files[0]);
    }
    return(
        <Grid container justify="flex-end" style={{margin:'5px 0'}}>
            <Grid container xs={12} justify="flex-end" item>
                <div style={{backgroundColor:'#FFFFFF', padding:10, border: '1px solid #EEEEEE'}}>
                        <input
                            type="file"
                            accept=".csv,.xls,.xlsx"
                            className="bank-movement-file-upload"
                            id="bank-movement-file-upload"
                            onChange={handleFileChange}
                            style={{display:'none'}}
                        />
                        <label htmlFor="bank-movement-file-upload">
                            <Button 
                                variant="contained" 
                                style={{backgroundColor: '#43A047', color:'#FFFFFF', fontSize:'11px', marginRight:10}}
                                component="span"
                                disableElevation
                            >
                                <AttachFile style={{margin:'0 5 0 -5', fontSize:'15px'}}/>
                                Elegir
                            </Button>
                            <InputBase 
                                value={file.name || 'Seleccione un archivo'}
                                style={{color:'#616161'}}
                            />
                            <Button
                                onClick={props.uploadFile.bind(this, file)}
                                variant="contained" 
                                style={{backgroundColor: '#43A047', color:'#FFFFFF', fontSize:'11px', marginLeft:10}}
                                disableElevation
                            >
                                cargar
                                <Publish style={{margin:'0 -5 0 5', fontSize:'15px'}}/>
                            </Button>
                        </label>
                </div>
            </Grid>
        </Grid>
    )
}

function BookMovementHeader(props) {
    return(
        <Grid container justify="flex-end" style={{margin:'5px 0'}}>
            <Grid container xs={12} justify="flex-end" item>
                <div style={{backgroundColor:'#FFFFFF', padding:10, border: '1px solid #EEEEEE'}}>
                    <Typography
                        variant="subtitle1"
                        style={{color:'#424242',fontWeight:'bold'}}
                    >
                        <Typography 
                            variant="overline"
                            style={{color:'#616161', fontSize:'14px'}}
                        >Balance en libro:&nbsp;</Typography>
                        ${!props.account_book_balance ? '0.00' : props.account_book_balance}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    )
}


// function BankMovement(props) {

//     return(
//         <div>
//             <BankMovementHeader />
//             <MovementTable 
//                 data={props.bookData}
//             />
//         </div>
//     )

// }

function AccountDetailView(props) {

    const [movementType, setMovementType] = useState(0);
    const handleTabChange = (event, value) => {
        setMovementType(value);

    };

    const [fileData, setFileData] = useState([]);
    const readFile = (file) => {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
  
        fileReader.onload = (e) => {
          const content = e.target.result;
          const workbook = XLSX.read(content, { type: "binary", cellDates: true });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const data = XLSX.utils.sheet_to_json(worksheet);
          resolve(data);
        };
  
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      promise.then((data) => {
        setFileData(data);
      });
    };

    const [selectedConfirmDialog, setConfirmDialog] = useState('');
    let dialogConfirm = undefined; 
    const handleConfirm = (file) => {
        setConfirmDialog('');
        readFile(file);
    }
    const handleConfirmDialog = (action, file) => {
        if(action === 'load-file') {
            dialogConfirm = 
                <ConfirmDialogComponent
                    actionType={action}
                    handleToggle={handleConfirmDialog}
                    handleConfirm={handleConfirm.bind(this, file)}
                />;
        }else {
            dialogConfirm = undefined;
        }
        setConfirmDialog(dialogConfirm);
    }

    return(
        <div>
            <AccountDetailHeader 
                bankName={props.accountData.bank_name}
                bankAccount={props.accountData.bank_account}
                accountStatus={props.accountData.account_status}
                accountBalance={props.accountData.account_balance}
            />
            <Paper square>
                <StyledTabs
                    value={movementType}
                    onChange={handleTabChange}
                    style={{color:'#2E7D32'}}
                >
                    <Tab label="Movimientos Bancarios" />
                    <Tab label="Movimientos en Libro" />
                </StyledTabs>
            </Paper>

            <Grid container>
                {
                    movementType ?
                        <div>
                            <BookMovementHeader />
                            <MovementTable 
                                data={props.accountData.transactions}
                                handleDialogToggle={props.handleDialogToggle}
                                movementType="libro"
                            />
                        </div>
                    : 
                        <div>
                            {/* the data is with the uploaded the file */}
                            <BankMovementHeader 
                                uploadFile={handleConfirmDialog.bind(this, 'load-file')}
                            />
                            <MovementTable 
                                data={fileData}
                                handleDialogToggle={props.handleDialogToggle}
                                movementType="banco"
                            />
                        </div>
                }
            </Grid>
            { selectedConfirmDialog }
        </div>
    )
}

export { AccountDetailView }