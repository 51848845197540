import React, { useState, useEffect } from 'react';
import { Grid, Typography, IconButton, Button, Toolbar, Popover} from '@material-ui/core';
import { Edit, MoreHoriz, Image, DeleteOutline, CloseOutlined, Warning, Done } from '@material-ui/icons';
import { GeneralTable } from './../../../components/SharedComponents/Table';
import NumberFormat from './../../../components/SharedComponents/NumberFormat';
import { getFileAttachment, printPaymentReceiptDocument } from "../../../utils/axios";
import { TableSearch } from './../../../components/SharedComponents/Fields';
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';
import { statusColors } from '../GeneralMethods';

function LeadsToolbar(props) {
    return(
        <Toolbar>
            <Grid container item direction="row" spacing={5} alignItems="center">
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{fontWeight: 'bold', paddingLeft:16}}
                >
                    Solicitudes
                </Typography>
                {
                    (props.selected && props.selected.length) ?
                    <Button
                        variant="outlined"
                        style={{fontSize: '10px', color:'#e34f4f', borderColor:'#e34f4f', backgroundColor:'#fff', marginLeft: 16 }}
                        disableElevation
                        size="small"
                        onClick={
                            props.handleToggleSimpleDialog
                            .bind(this, 'confirm-dialog', 'proceed-delete-multiple', {callback: props.handleDeleteMultipleLead})
                        }
                    >
                        <DeleteOutline style={{ fontSize: 15, color: '#e34f4f', margin: '0 .5rem .1rem 0' }} />
                        denegar solicitudes
                    </Button> : null
                }
            </Grid>
        </Toolbar>
    );
}

function BlackListPopover(props) {
    return(
        <Popover
            // style={{width:'70%'}}
            id={`leads-blacklist-popover`}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleToggle}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            elevation={4}
        >
            <Grid container style={{width:500, padding:'15px 24px', border:'1px solid #cfcfcf', borderRadius:5}}>
                <Grid item container justifyContent="space-between" alignItems="center">
                    <Typography
                        variant="h6"
                        style={{color:'#424242'}}
                    >
                        Blacklists
                    </Typography>
                    <IconButton onClick={props.handleToggle}>
                        <CloseOutlined style={{color:'#424242'}} />
                    </IconButton>
                </Grid>
                {
                    props.data.map((blacklist, index) => (
                        <div key={index} style={{
                            padding:'.8rem', backgroundColor:'#ededed', borderRadius:4, 
                            display:'flex', alignItems:'center', justifyContent:'center',
                            width:'100%', margin:'1rem 0', position: 'relative',
                        }}>
                            
                            {
                                (blacklist === 'n/a' && props.data.length === 1) ? 
                                <>
                                    <Done style={{color: '#43a047', position: 'absolute', top:'25%', left: '1rem'}}/>
                                    {"NO PERTENECE A NINGÚN BLACKLIST" }
                                </>
                                :
                                <>
                                    <Warning style={{color: '#ffbf66', position: 'absolute', top:'25%', left: '1rem'}}/>
                                    <span style={{color: '#eb5252'}}>
                                        {blacklist}
                                    </span>
                                </>
                                
                            }
                        </div>
                    ))
                }
            </Grid>
        </Popover>
    )
}

export function LeadsTable(props) {
    const [tableData, setTableData] = useState([]);
    const [openBlacklist, setOpenBlacklist] = useState({});

    const handleOpenBlacklist = (key, event) => {
        openBlacklist[key] ? setOpenBlacklist({...openBlacklist, [key]: null}) : setOpenBlacklist({...openBlacklist, [key]: event.currentTarget})
    }
    const details = (row) => {
        return(
            <IconButton
                onClick={props.handleToggleDialog.bind(this, 'leads', row)}
                size="medium"
            >
                <Edit style={{color:'#286bd1'}} />
            </IconButton>
        )
    }    
    const status = (row) => {
        const coloredLabel = statusColors.find(item =>(item.state === (row._state || row.state)) || item.state === 'default');
        return(
            <Button
                variant="contained"
                disableElevation
                disabled
                style={{
                    minWidth:120, height: 25, borderRadius:20,
                    backgroundColor: coloredLabel.bgColor,
                    color: coloredLabel.color,
                    fontSize:12,
                }}
            >
                {coloredLabel.name}
            </Button>
        )
    };
    const fileAttachment = (row, value) => {
        const openFile = (uid) => {
            if (uid !== undefined){
                props.setLoading(true);
                const endpoint = getFileAttachment(props.user.token, uid);
                endpoint.then((res) => {
                    const dbuff = new Uint8Array(res.data.file).buffer
                    const data = new Blob([dbuff], {type: res.data.type});
                    const fileURL = URL.createObjectURL(data);
                    const NewWindow = window.open();
                    NewWindow.location.href = fileURL;
                    props.setLoading(false);
                }).catch(errors => {
                    props.setLoading(false);
                    errorHandling(errors, props.handleToggleSimpleDialog);
                });
              }
        }
        return(
            <Grid container direciton="row" justifyContent="center">
                <IconButton
                    onClick={openFile.bind(this, value)} // a dialog showing the fileAttachment
                    disabled={!value}
                >
                    <Image style={{fontSize:22, color: value ? '#43a047' : '#616161'}} />
                </IconButton>
            </Grid>
        )
    }
    const exists = (row, value) => {
        return(
            <span>{value ? "SÍ" : "NO"}</span>
        )
    }
    const date = (row, value) => {
        // const formattedDate = value.substring(0, value.lastIndexOf("-")).replace("T", " ");
        const formattedDate = value.substring(0, value.indexOf("T"));
        return(
            <span>{formattedDate}</span>
        )
    }
    const getBlackList = (blackLists) => {
        const list = [];
        blackLists.forEach(key => {
            switch(key) {
                case 'isInternationalBlacklisted':
                    list.push('LISTA NACIONAL/INTERNACIONAL');
                    break;
                case 'isPepBlacklisted':
                    list.push('LISTA PEP');
                    break;
                case 'isOfacBlacklisted':
                    list.push('LISTA OFACA/ONU');
                    break;
                case 'isLocalBlacklisted':
                    list.push('BLACKLIST LOCAL');
                    break;
                default:
                    break;
            }
        })
        return list;
    }
    const blacklist = (row, value) => {
        let list = Object.keys(value || {}).filter(key => value[key] === true);
        let formattedLists = [];
        if(list.length) {
            formattedLists = getBlackList(list);
        }
        return(
            <div style={{textAlign:'center'}}>
                <IconButton
                    onClick={handleOpenBlacklist.bind(this, row.uniqueId)}
                    size="medium"
                >
                    {/* <MoreHoriz style={{color:'#286bd1'}} /> */}
                    {
                        list.length ?
                            <Warning style={{color: '#ffbf66'}}/>
                        :
                            <Done style={{color: '#43a047'}}/>
                    }
                </IconButton>
                <BlackListPopover
                    data={formattedLists?.length ? formattedLists : ["n/a"]}
                    handleToggle={handleOpenBlacklist.bind(this, row.uniqueId)}
                    open={Boolean(openBlacklist[row.uniqueId])}
                    anchorEl={openBlacklist[row.uniqueId]}
                />
            </div>
        )
    }
    const amount = (row, value) => {
        return(
            <NumberFormat
                value={value}
                prefix={'$'}
            />
        )
    }
    const columns = [
        // {id:'checkbox', label:'SELECCIÓN', minWidth: 70 },
        {id:'referenceCode', label: 'CÓDIGO SOLICITUD', align:'center', minWidth: 150},
        {id:'fullName', label: 'NOMBRE CLIENTE', align:'center', minWidth: 150},
        {id:'identificationNumber', label: 'DOCUMENTO', align:'center', minWidth: 150},
        {id:'created', label: 'FECHA', align:'center', minWidth: 180, element: date},
        {id:'dmsExists', label: 'EXISTE DMS', align:'center', minWidth: 150, element: exists,},
        {id:'blacklist', label: 'LIBRE BLACKLIST', align:'center', minWidth: 200, element: blacklist,},
        // {id:'fileAttachment', label: 'ADJUNTO', align:'center', minWidth: 120, element: fileAttachment},
        {id:'details', label: 'DETALLE', align:'center', minWidth: 200, element: details},
        {id:'status', label: 'ESTADO', align:'center', minWidth: 200, element: status},
        // {id:'print', label: 'PLANTILLA', align:'center', minWidth: 120, element: printReceipt},
    ];
    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);
    return(
        <GeneralTable
            columns={columns}
            data={tableData}
            selected={props.selected}
            setSelected={props.setSelected}
            handleSelected={props.handleSelected}
            toolbar={
                <Grid container justifyContent='space-between' alignItems='center'>
                    <LeadsToolbar
                        selected={props.selected}
                        handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                        handleDeleteMultiplePayment={props.handleDeleteMultiplePayment}
                    />
                    <TableSearch
                        data={props.data}
                        setTableData={setTableData}
                        searchFields={['fullName', 'identificationNumber', 'referenceCode']}
                    />
                </Grid>
            }
        />
    );
}