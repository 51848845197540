import * as React from 'react';
import MaskedInput from 'react-text-mask';

import { InputBaseComponentProps } from '@material-ui/core/InputBase';

const phoneMaskInput = (props: InputBaseComponentProps) => {
    const { inputRef, ...others } = props;
  
    return (
      <MaskedInput
        {...others}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        guide={false}
        mask= {['+', '1','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask={false}
      />
    );
}

export default phoneMaskInput;