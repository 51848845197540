import './TmsDashboard.css'
import Header from '../Header/Header';
import TicketManagement from '../../containers/TicketManagement/View';
import Modal from '../UI/Modal';
import React from 'react';
import { clearStorage, getToken } from '../../utils';
import { verifyToken } from '../../utils/axios'
import { connect } from 'react-redux';
import { faPowerOff, faFileAlt, faRobot } from '@fortawesome/free-solid-svg-icons';
import { logOff, logUser } from '../../actions';

class TmsDashboard extends React.Component {

  constructor(props) {
    super(props);

    const token = getToken();
    if (token) {
      const user = { token };
      this.state = { user: user }
      this.props.logUser(user)
    } else {
      this.props.history.push('/');
    }

    this.handleLogOff = this.handleLogOff.bind(this);
    this.handleDMS = this.handleDMS.bind(this);
    this.handleBOT = this.handleBOT.bind(this);
    this.logOff = this.logOff.bind(this);
  }

  componentDidMount() {
    verifyToken(getToken())
      .catch(() => {
        this.props.logOff();
        clearStorage();
        this.props.history.push('/');
      });
  }

  handleLogOff() {
    this.setState({ confirmLogoff: !this.state.confirmLogoff })
  }

  handleDMS() {
    this.props.history.push('/dashboard')
  }

  handleBOT() {
    this.props.history.push('/bot')
  }

  logOff() {
    this.props.logOff();
    clearStorage();
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="TmsDashboard">
        {this.state.confirmLogoff ?
          <Modal onCloseModal={this.handleLogOff}>
            <div>
              <span>Estas seguro que deseas salir?</span>
              <div className="logOff-modal-buttons">
                <button className="logOff-cancel" onClick={this.handleLogOff}>Cancelar</button>
                <button onClick={this.logOff}>Si, estoy seguro</button>
              </div>
            </div>
          </Modal> : null}
        <Header options={[
          { action: this.handleDMS, icon: faFileAlt, title: 'DMS' },
          { action: this.handleBOT, icon: faRobot, title: 'BOT' },
          { action: this.handleLogOff, icon: faPowerOff, title: 'Cerrar sesión' },
        ]} />
        <TicketManagement />
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, { logOff, logUser })(TmsDashboard);