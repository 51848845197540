import "../Calculators.css";
import axios from "axios";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CancelIcon from "@material-ui/icons/Cancel";
import MoneyInput from "src/components/TextMaskInputComponents/moneyInput";
import React, { Component } from "react";
import idNumberMaskInput from "src/components/TextMaskInputComponents/idNumberMaskInput";
import interestRateInput from "src/components/TextMaskInputComponents/interestRateInput";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";
import {
  API_KEY,
  createQuotation as createQ,
  fetchAssignees,
  fetchBlacklists,
  fetchParticipantBalance,
  fetchQuotation,
  simpleLoanCalulator,
  updateQuotationStatus as updateQ,
} from "src/utils/axios";
import { createQuotation, updateQuotation } from "src/actions";
import {
  faCalculator,
  faSave,
  faBroom,
  faDownload,
  faClosedCaptioning,
} from "@fortawesome/free-solid-svg-icons";
import MuiAlert from "@material-ui/lab/Alert";

import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  createStyles,
  Checkbox,
  MenuItem,
  FormControlLabel,
  withStyles,
  Modal,
  RadioGroup,
  Radio,
  FormLabel,
  InputAdornment,
  FormHelperText,
  Typography,
  Snackbar,
  Grid,
  Switch,
  FormGroup,
} from "@material-ui/core";
import {
  IDENTIFICATION_TYPE,
  amortizationTable,
  termReductionOptions,
  collateralTypes,
  headersLoan,
  headersPCP,
  jointTypes,
  loanTypes,
  transactionTypeAP,
  translations,
  weekDays,
  quotationStatus,
  clientTypes,
  cancelQuotation,
  catchError,
  resetError,
  financialConfig,
  staggeredOptions,
  COMPANIES,
  liquidTypes,
  paymentMethods,
  finalities,
  filesGeneration,
} from "src/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PcpTable from "./PcpTable";
import phoneMaskInput from "src/components/TextMaskInputComponents/phoneMaskInput";
import passportMaskInput from "src/components/TextMaskInputComponents/passportMaskInput";
import { green } from "@material-ui/core/colors";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import * as algebra from "algebra.js";
import { SearchComponent } from "../../SharedComponents/Fields";
import { Dialpad } from "@material-ui/icons";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#4CAF50",
      },
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
      color: "red",
    },
    "& input:focus + fieldset, & select:focus + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& label.Mui-focused": {
      color: "green",
    },
    margin: 8,
  },
})(TextField);

const GreenRadio = withStyles({
  checked: {},
  root: {
    "&$checked": { color: green[600] },
    color: green[400],
  },
})((props) => <Radio color="default" {...props} />);

const styles = createStyles({
  autoComplete: {
    color: "#4CAF50",
    margin: 8,
    width: "40%",
  },
  calculate: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  disabled: {
    background: "#bdbdbd",
    color: "white",
  },
  clean: {
    background: "#F44636",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  download: {
    background: "#37C0EC",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  formControl: {
    margin: 8,
  },
  gridFormControl: {
    margin: 8,
    width: "100%",
  },
  pcp: {
    backgroundColor: green[500],
    color: "white",
  },
  save: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
});

class AmortizationCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addClient: false,
      alertMessage: "",
      amortizationAmount: 0,
      affiliationAmount: 0,
      cancelModal: false,
      cancelationReason: "",
      client: "",
      clientEmail: "",
      clientName: "",
      clientNumber: "",
      clientType: "regular",
      newClientType: false,
      commissionAmount: "",
      commissionRate: 0,
      administrativeExpensesType: "discounted",
      administrativeExpensesAmount: "",
      administrativeExpensesRate: "",
      contactMethod: "",
      company: "COOPERATIVA BARCELONA",
      dueDate: "",
      editIdx: -1,
      editAffiliation: false,
      emittedBy: "",
      errors: {},
      productType: "",
      financialConfig: "",
      firstInstallmentDate: "",
      fractionationType: "",
      givenWarranty: 0,
      hasFractionation: false,
      hasSpp: false,
      identificationNumber: "",
      identificationType: "",
      insuranceAmount: 0,
      insuranceType: "prorated",
      interestRate: 0,
      loading: false,
      loanType: "",
      openAlert: false,
      originalAmount: "",
      otherFees: "",
      savingFees: "",
      savingsWarranty: 0,
      contributionFees: "",
      outlayDate: new Date().toISOString().split("T")[0],
      paymentDay: "",
      pcpData: [],
      pcpDialog: false,
      periodicity: "monthly",
      quotationDate: new Date().toISOString().split("T")[0],
      quotationStatus: "on_process",
      referenceCode: "",
      requestAmount: 0,
      saveButton: false,
      secondPaymentDay: 0,
      severity: "info",
      tableContent: [],
      timeLimit: "",
      transferAmount: "",
      warrantyType: "",
      openCalculate: false,
      paymentMethod: "",
      finality: "",
    };

    this.initialState = {
      addClient: false,
      alertMessage: "",
      amortizationAmount: 0,
      cancelModal: false,
      cancelationReason: "",
      client: "",
      clientEmail: "",
      clientName: "",
      clientNumber: "",
      clientType: "regular",
      newClientType: false,
      commissionAmount: "",
      commissionRate: "",
      administrativeExpensesType: "discounted",
      administrativeExpensesAmount: "",
      administrativeExpensesRate: "",
      contactMethod: "",
      company: "COOPERATIVA BARCELONA",
      dialog: "",
      dialogTitle: "",
      dueDate: "",
      editIdx: -1,
      errorMessage: "",
      errors: {},
      productType: "",
      financialConfig: "",
      firstInstallmentDate: "",
      fractionationType: "",
      hasFractionation: false,
      hasSpp: false,
      identificationNumber: "",
      identificationType: "",
      insuranceAmount: 0,
      insuranceType: "prorated",
      interestRate: "",
      loading: false,
      loanType: "",
      openAlert: false,
      originalAmount: "",
      otherFees: "",
      savingFees: "",
      contributionFees: "",
      outlayDate: new Date().toISOString().split("T")[0],
      paymentDay: "",
      pcpData: [],
      pcpDialog: false,
      periodicity: "monthly",
      quotationDate: new Date().toISOString().split("T")[0],
      quotationStatus: "on_process",
      referenceCode: "",
      requestAmount: "",
      saveButton: false,
      secondPaymentDay: 0,
      severity: "info",
      tableContent: [],
      timeLimit: "",
      transferAmount: "",
      warrantyType: "",
      paymentMethod: "",
      finality: "",
    };

    this.calculateTable = this.calculateTable.bind(this);
    this.fillClientInfo = this.fillClientInfo.bind(this);
    this.fillDisabledFields = this.fillDisabledFields.bind(this);
    this.generateFile = this.generateFile.bind(this);
    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTD = this.handleChangeTD.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleStartEdit = this.handleStartEdit.bind(this);
    this.handleStopEdit = this.handleStopEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.switchAddClient = this.switchAddClient.bind(this);
    this.switchState = this.switchState.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.validateClient = this.validateClient.bind(this);
    this.toggleChecked = this.toggleChecked.bind(this);
    this.calcWarranty = this.calcWarranty.bind(this);
  }

  async calcWarranty() {
    const { client, clientName, requestAmount } = this.state;
    const { token } = this.props.user;

    try {
      if (client) {
        const res = await fetchParticipantBalance(token, client);
        const participantBalance = res.data;

        if (participantBalance) {
          const { balance_prestamos, balance_ahorros } = participantBalance;
          const totalLoanBalance = parseFloat(balance_prestamos) + parseFloat(requestAmount);
          const warrantyAmount = (totalLoanBalance * 0.05);
          const adjustedSavingsBalance = parseFloat(balance_ahorros) - warrantyAmount;

          if (adjustedSavingsBalance >= 0) {
            this.setState({ savingsWarranty: 0 });
          } else {
            this.setState({ savingsWarranty: Math.abs(adjustedSavingsBalance) });
          }
        } else {
          const warrantyAmount = requestAmount * 0.05;
          this.setState({ savingsWarranty: warrantyAmount });
        }
      } else if (clientName) {
        const warrantyAmount = requestAmount * 0.05;
        this.setState({ savingsWarranty: warrantyAmount });
      } else {
        this.printMessage(
          'Debe seleccionar al participante para poder calcular la garantía.',
          'warning'
        );
      }
    } catch (error) {
      this.printMessage(error.message, 'warning');
    }
  }

  componentDidMount() {
    const { quotationId } = this.props;
    const { token } = this.props.user;

    this.setState({
      financialConfig: "",
    });

    const loggedUser = jwtDecode(token).user_id;
    fetchAssignees({ token: token, id: loggedUser })
      .then((res) => {
        let data = res.data.results ? res.data.results : res.data;
        try {
          this.setState((state) => {
            const user = data.find((user) => user.id === loggedUser);
            state.emittedBy = user.username;
            state.roles = user.groups;
            return state;
          });
        } catch (e) {
          this.printMessage(
            "Algo a salido mal al tratar de cargar los asistentes",
            "warning"
          );
        }
      })
      .catch((e) =>
        this.printMessage(
          "Algo salio mal, favor informar al dpto. de tecnología",
          "warning"
        )
      );

    if (quotationId) {
      fetchQuotation(token, quotationId)
        .then((res) => {
          const curQuotation = { ...res.data };
          this.setState({ ...curQuotation }, () => {
            if (!curQuotation.client) {
              this.switchState("addClient");
            }
            this.calculateTable(curQuotation, token);
          });
        })
        .catch((err) => {
          this.printMessage(err, "error");
        });
    }
  }

  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || 0 });
    };
  }

  toggleChecked() {
    this.setState({ editAffiliation: !this.state.editAffiliation });
  }

  handleSelectChange(key) {
    /* Handle the state of Select components */
    return (event) => {
      if (key === "quotationStatus" && event.target.value === "cancelled") {
        this.switchState("cancelModal");
      } else if (
        key === "productType" &&
        transactionTypeAP.some((val) => val.value === event.target.value)
      ) {
        this.setState({ periodicity: "single_payment" });
      } else if (
        key === "productType" &&
        transactionTypeAP.some((val) => val.value !== event.target.value)
      ) {
        this.setState({ periodicity: "monthly" });
      } else if (key === "financialConfig") {
        this.setState({ productType: "" });

        if (event.target.value === "toExpiration") {
          this.setState({ pcpData: [], hasSpp: false });
        }
      } else if (key === "loanType") {
        this.setState({ warrantyType: "" });
      }
      this.setState({
        [key]: event.target.value,
      });
    };
  }

  handleAutocompleteChange(key, obj) {
    /* Handle the state of Autocomplete components */
    if (obj) {
      if (key === "client") {
        this.fillClientInfo(obj);
      } else {
        this.setState({
          [key]: obj.value,
        });
      }
    }
  }

  switchAddClient() {
    this.setState({
      addClient: !this.state.addClient,
      client: "",
      clientEmail: "",
      clientName: "",
      clientNumber: "",
      identificationNumber: "",
      identificationType: "",
      rohiId: "",
    });
  }

  getIdentificationInput(identificationType) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }

  fillClientInfo(data) {
    if (data) {
      this.setState({
        client: data.value ? data.value : "",
        identificationNumber: data.identificationNumber
          ? data.identificationNumber
          : "",
        identificationType: data.identificationType
          ? data.identificationType
          : "",
        rohiId: data.rohiId ? data.rohiId : "",
      });
    }
  }

  switchState(key) {
    this.setState({
      [key]: !this.state[key],
    });
  }

  saveCancelationReason() {
    return (event) => {
      this.switchState("cancelModal");
      this.handleSubmit(event);
    };
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleCheckboxChange(key) {
    /* Handle the state of CheckBox components */
    return (event) => {
      this.setState({
        [key]: event.currentTarget.checked,
      });
    };
  }

  handleRemove = (i) => {
    this.setState((state) => {
      state.pcpData = state.pcpData.filter((row, j) => j !== i);
      return state;
    });
  };

  handleStartEdit = (i) => {
    this.setState({ editIdx: i });
  };

  handleStopEdit = () => {
    this.setState({ editIdx: -1 });
  };

  handleChangeTD = (i, x) => {
    this.setState((state) => {
      state.pcpData = state.pcpData.map((row, j) => (j === i ? x : row));
      return state;
    });
    this.handleStopEdit();
  };

  handleSave(data) {
    this.setState((state) => {
      state.pcpData = data;
      return state;
    });
  }

  handleReset() {
    this.setState({ ...this.initialState });
  }

  validateFields() {
    /* Handle validations before saving */
    const ignoreThese = [
      "addclient",
      "commissionAmount",
      "administrativeExpensesAmount",
      "dueDate",
      "editIdx",
      "errors",
      "firstInstallmentDate",
      "originalAmount",
      "otherFees",
      "periodicity",
      "quotationDate",
      "transferAmount",
    ];
    for (let field of Object.keys(this.state)) {
      if (ignoreThese.includes(field)) {
        continue;
      }
      //  Validar que los campos no esten vacíos
      if (!this.state[field]) {
        this.setState({ [field + "_error"]: true });
      }
    }
  }

  numberToFraction(amount) {
    // Next 12 lines are cribbed from https://stackoverflow.com/a/23575406.
    let gcd = function(a, b) {
      if (b < 0.0000001) {
        return a;
      }
      return gcd(b, Math.floor(a % b));
    };
    let len = amount.toString().length - 2;
    let denominator = Math.pow(10, len);
    let numerator = amount * denominator;
    let divisor = gcd(numerator, denominator);
    numerator /= divisor;
    denominator /= divisor;

    let result = {
      numerator: Math.floor(numerator),
      denominator: Math.floor(denominator),
    };

    return result;
  }

  fillDisabledFields() {
    let s = { ...this.state };

    s.errors = {};

    s.requestAmount = parseFloat(s.requestAmount) || 0.0;
    s.commissionRate = parseFloat(s.commissionRate) || 0.0;
    s.administrativeExpensesRate =
      parseFloat(s.administrativeExpensesRate) || 0.0;
    s.otherFees = parseFloat(s.otherFees) || 0.0;
    s.savingFees = parseFloat(s.savingFees) || 0.0;
    s.contributionFees = parseFloat(s.contributionFees) || 0.0;
    s.savingsWarranty = parseFloat(s.savingsWarranty) || 0.0;
    s.givenWarranty = parseFloat(s.givenWarranty) || 0.0;
    s.affiliationAmount = parseFloat(s.affiliationAmount) || 0.0;

    s.commissionAmount =
      (s.commissionRate / 100) * s.requestAmount + s.otherFees;
    s.transferAmount = s.requestAmount;

    // Calculo con efecto de gastos administrativos
    let totalRequest = s.requestAmount + (s.savingsWarranty - s.givenWarranty) + s.affiliationAmount;
    let result = totalRequest/((100 - s.administrativeExpensesRate)/100);

    s.originalAmount =
      s.administrativeExpensesType === "discounted"
        ? Math.ceil(result / 5) * 5
        : result;
    s.administrativeExpensesAmount =
      s.originalAmount - s.otherFees - totalRequest;

    // Funcion interna para darle formato a las fechas
    const formatDate = (dateObj) => {
      // Agregar un 0 en caso de que mes o dia sean menor a 9
      const month =
        dateObj.getMonth() + 1 <= 9
          ? `0${dateObj.getMonth() + 1}`
          : dateObj.getMonth() + 1;
      const day =
        dateObj.getDate() <= 9 ? `0${dateObj.getDate()}` : dateObj.getDate();

      return `${dateObj.getFullYear()}-${month}-${day}`;
    };

    // Si la fecha de cotizacion no se especifica
    // poner el valor del dia actual por defecto
    if (!s.quotationDate) {
      s.quotationDate = formatDate(new Date());
    }

    // calculo fecha caducidad
    let dt = new Date(`${s.outlayDate}:`);
    dt.setUTCMonth(dt.getMonth() + (parseInt(s.timeLimit, 10) || 0));
    dt.setDate(s.paymentDay);
    s.dueDate = formatDate(dt);

    // Calculo fecha primera cuota
    dt = new Date(`${s.outlayDate}:`);
    switch (s.periodicity) {
      case "monthly":
        dt.setDate(s.paymentDay);
        dt.setUTCMonth(dt.getMonth() + 1);
        s.firstInstallmentDate = formatDate(dt);
        break;
      case "weekly":
        dt.setUTCDate(dt.getDate() + 7);
        s.firstInstallmentDate = formatDate(dt);
        dt = new Date(`${s.outlayDate}:`);
        dt.setUTCDate(dt.getDate() + (parseInt(s.timeLimit, 10) || 0) * 4 * 7);
        s.dueDate = formatDate(dt);
        break;
      case "biweekly":
        if (dt.getDate() > s.secondPaymentDay) {
          dt.setUTCMonth(dt.getMonth() + 1);
        } else if (dt.getDate() > s.paymentDay) {
          dt.setDate(s.secondPaymentDay);
        } else {
          dt.setDate(s.paymentDay);
        }
        s.firstInstallmentDate = formatDate(dt);
        break;
      case "bimonthly":
        dt.setDate(s.paymentDay);
        dt.setUTCMonth(dt.getMonth() + 2);
        s.firstInstallmentDate = formatDate(dt);
        break;
      case "quarterly":
        dt.setDate(s.paymentDay);
        dt.setUTCMonth(dt.getMonth() + 3);
        s.firstInstallmentDate = formatDate(dt);
        break;
      case "biannual":
        dt.setDate(s.paymentDay);
        dt.setUTCMonth(dt.getMonth() + 3);
        s.firstInstallmentDate = formatDate(dt);
        break;
      case "single_payment":
        dt = new Date(`${s.paymentDay}:`);
        s.firstInstallmentDate = formatDate(dt);
        s.dueDate = formatDate(dt);
        break;
      default:
        // By default, same behaviour as monthly
        dt.setDate(s.paymentDay);
        dt.setUTCMonth(dt.getMonth() + 1);
        s.firstInstallmentDate = formatDate(dt);
        break;
    }

    // Clean '%' from interest rate
    s.interestRate =
      typeof s.interestRate === "string"
        ? s.interestRate.replace("%", "")
        : s.interestRate;

    // timeLimit validation
    switch (s.productType) {
      case "decreasing_fees":
        // Si la configuracion es cuotas decresientes el plazo es igual al (monto original/monto de amortizacion),
        // redondeado hacia arriba
        s.timeLimit = Math.ceil(s.originalAmount / s.amortizationAmount);
        break;
      case "simple_loan":
        break;
      case "constant_fees":
        break;
      default:
        // Si la configuracion no es simple o de cuotas el plazo es 1
        s.timeLimit = "1";
        break;
    }
    return s;
  }

  async generateFile() {
    const url = `/outlay/quotation/${this.state.uniqueId}/print`;
    await filesGeneration(url, this.props.user.token);
  }

  printMessage(msg, severity) {
    severity === "success"
      ? this.setState({
          alertMessage: "Operación realizada exitosamente",
          openAlert: true,
          severity: "success",
        })
      : severity === "error"
      ? this.setState({
          alertMessage:
            "💀 Error al realizar la operacion, favor verificar e intentar de nuevo 💀",
          openAlert: true,
          severity: "error",
        })
      : this.setState({
          alertMessage: `💀 ${msg} 💀`,
          openAlert: true,
          severity: "warning",
        });
  }

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };

    // Verificar que el monto solicitado sea mayor a 0
    temp.requestAmount =
      fieldValues.requestAmount > 0.0
        ? ""
        : "El monto solicitado debe ser mayor a 0";

    // Verificar que el y/o los dias de pago esten entre el rango del 1 al 30
    if (fieldValues.periodicity !== "weekly") {
      if (fieldValues.periodicity === "biweekly") {
        temp.secondPaymentDay =
          fieldValues.secondPaymentDay >= 1 &&
          fieldValues.secondPaymentDay <= 30
            ? ""
            : "El dia de pago digitado debe ser entre 1 y 30";
      } else if (fieldValues.periodicity === "single_payment") {
        temp.paymentDay =
          fieldValues.paymentDay !== ""
            ? ""
            : "El dia de pago digitado debe ser una fecha";
      } else {
        temp.paymentDay =
          fieldValues.paymentDay >= 1 && fieldValues.paymentDay <= 30
            ? ""
            : "El dia de pago digitado debe ser entre 1 y 30";
      }
    }
    // Si la periodicida es bimensual el plazo en meses debe ser par
    if (fieldValues.periodicity === "bimonthly") {
      temp.timeLimit =
        fieldValues.timeLimit % 2 === 0 ? "" : "El dia seleccionado no es par";
    }

    // Si la periodicida es trimestra el plazo en meses, la division de este entre 3 debe ser par
    if (fieldValues.periodicity === "quarterly") {
      temp.timeLimit =
        fieldValues.timeLimit % 3 === 0 ? "" : "El dia seleccionado no es par";
    }

    // La suma de los PCP en el caso de los préstamos con configuración financiera
    // Simple no debería pasar el monto original (solicitado + efecto comisión).
    if (fieldValues.hasSpp && this.state.productType === "simple_loan") {
      let totalAmount = 0;
      const amounts = this.state.pcpData.map((opt) =>
        parseFloat(opt.pcpAmount)
      );
      for (let item of amounts) {
        totalAmount = totalAmount + item;
      }
      temp.hasSpp =
        totalAmount <= this.state.originalAmount
          ? ""
          : "El monto de los PCP excede el Monto Original";
    }

    // La fecha de los PCP no debería estar fuera del rango de fecha de desembolso (fecha de inicio periodo 0)
    // y la fecha de caducidad (fecha de vencimiento del último periodo).
    if (fieldValues.hasSpp) {
      let date = [];
      const dates = this.state.pcpData.map((opt) => opt.pcpDate);
      for (let item of dates) {
        if (
          new Date(item) > new Date(this.state.outlayDate) &&
          new Date(item) < new Date(this.state.dueDate)
        ) {
          date.push(true);
        } else {
          date.push(false);
        }
      }
      temp.hasSpp = date.includes(false)
        ? "La fecha de los PCP no debería estar fuera del rango permitido"
        : "";
    }

    // Validar si tiene fraccionamiento y hacer requerido especificar
    if (fieldValues.hasFractionation) {
      temp.fractionationType =
        fieldValues.fractionationType === "" ? "Campo Requerido" : "";
    }
    /* Handle validations before saving */
    const fields = [
      "administrativeExpensesType",
      "productType",
      "insuranceType",
      "interestRate",
      "loanType",
      "outlayDate",
      "paymentDay",
      "periodicity",
      "timeLimit",
    ];

    for (let field of Object.keys(fieldValues)) {
      if (fields.includes(field)) {
        //  Validar que los campos no esten vacíos
        if (!this.state[field]) {
          temp[field] = "Campo Requerido";
        }
      }
    }

    this.setState({
      errors: { ...temp },
    });

    if (fieldValues === this.state) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  async validateBlackList() {
    const { token } = this.props.user;
    let params = {};

    if (this.state.client === "") {
      params = {
        token: token,
        fullName: this.state.clientName,
      };
    } else {
      params = {
        token: token,
        customer: this.state.client,
      };
    }

    try {
      const res = await fetchBlacklists(params);
      return res.data || [];
    } catch (err) {
      this.printMessage(err, "error");
      return [];
    }
  }

  async validateClient() {
    const blacklisted = await this.validateBlackList();
    let isBlacklisted = undefined;

    const cleanIdentificationNumber = this.state.identificationNumber.replace(
      /-/g,
      ""
    );

    if (this.state.client !== "") {
      isBlacklisted = blacklisted.find(
        (client) => client.customer === this.state.client
      );
    } else {
      isBlacklisted = blacklisted.find((client) =>
        client.temporaryInfo
          ? client.temporaryInfo.identificationNumber ===
            cleanIdentificationNumber
          : false
      );
    }

    if (isBlacklisted) {
      this.setState({
        errors: {
          client: "Este cliente se encuentra en el blacklist",
        },
      });
      return false;
    } else {
      return true;
    }
  }

  calculateTable(state, token, btnCalc) {
    //  create Json object with formatted data
    const options = JSON.stringify({
      add_principal: parseFloat(state.otherFees) || 0,
      saving_fees: parseFloat(state.savingFees) || 0,
      contribution_fees: parseFloat(state.contributionFees) || 0,
      commission_rate: parseFloat(state.commissionRate),
      administrative_expenses_rate: parseFloat(
        state.administrativeExpensesRate
      ),
      administrative_expenses_amount: parseFloat(
        state.administrativeExpensesAmount
      ),
      administrative_expenses_type: state.administrativeExpensesType,
      disbursement_date: state.outlayDate,
      fractionation_type: state.hasFractionation
        ? state.fractionationType
        : null,
      insurance_amount: parseFloat(state.insuranceAmount),
      insurance_type: state.insuranceType,
      interest_rate: parseFloat(state.interestRate),
      loan_amount: parseFloat(state.requestAmount),
      loan_type: state.productType,
      payment_day:
        state.periodicity === "weekly"
          ? weekDays.find((elem) => elem.value === state.paymentDay)
            ? state.paymentDay
            : "MONDAY"
          : state.periodicity === "biweekly"
          ? [
              parseInt(state.paymentDay, 10),
              parseInt(state.secondPaymentDay, 10),
            ]
          : state.paymentDay,
      pcp: state.hasSpp ? state.pcpData : [],
      periodicity: state.periodicity,
      periods: parseInt(state.timeLimit, 10),
      principal_amount: parseFloat(this.state.amortizationAmount),
      original_amount: parseFloat(this.state.originalAmount),
      paymentMethod: state.paymentMethod,
      finality: state.finality,
    });
    if (
      btnCalc === "buttonCalc" &&
      this.props.paymentTicket &&
      (!state.hasSpp || !state.pcpData.length)
    ) {
      this.props.handleToggleSimpleDialog(
        "simple-dialog",
        `Debe marcar PCP y seleccionar al menos 1.`
      );
      return;
    }
    // Call API And display data on screen
    simpleLoanCalulator(options, token)
      .then((res) => {
        const loanCalc = {
          saveButton: true,
          tableContent: res.data,
          dueDate: res.data[res.data.length - 1][1],
          firstInstallmentDate: res.data[1][1],
        };
        this.setState(loanCalc);
        if (this.props.paymentTicket && btnCalc === "buttonCalc") {
          const data = {
            tableData: loanCalc.tableContent || [],
            pcp: state.pcpData || [],
          };
          this.props.handleQuotationData(data);
        }
      })
      .catch((err) => console.error(err));
  }

  handleTable() {
    const { token } = this.props.user;
    const { state } = this;

    this.setState(
      // Get new state object and pass it to set state
      this.fillDisabledFields(),
      () => {
        // Call back the validate the actual state
        if (this.validate()) {
          this.calculateTable(state, token, "buttonCalc");
          this.switchState("openCalculate");
        }
      }
    );
  }

  async handleSubmit(e) {
    resetError(this);
    e.preventDefault();
    const { token } = this.props.user;
    let curQuotation = {
      amortizationAmount: parseFloat(this.state.amortizationAmount).toFixed(2),
      affiliationAmount: parseFloat(this.state.affiliationAmount).toFixed(2),
      clientType: this.state.clientType,
      commissionAmount: parseFloat(this.state.commissionAmount).toFixed(2),
      administrativeExpensesAmount: parseFloat(
        this.state.administrativeExpensesAmount
      ).toFixed(2),
      commissionRate: this.state.commissionRate,
      administrativeExpensesRate: this.state.administrativeExpensesRate,
      administrativeExpensesType: this.state.administrativeExpensesType,
      contactMethod: this.state.contactMethod,
      company: "COOPERATIVA BARCELONA",
      dueDate: this.state.dueDate,
      emittedBy: this.state.emittedBy,
      expirationDate: this.state.expirationDate,
      financialConfig: this.state.financialConfig,
      productType: this.state.productType,
      firstInstallmentDate: this.state.firstInstallmentDate,
      fractionationType: this.state.hasFractionation
        ? this.state.fractionationType
        : null,
      givenWarranty: parseFloat(this.state.givenWarranty).toFixed(2),
      hasFractionation: this.state.hasFractionation,
      hasSpp: this.state.hasSpp,
      insuranceAmount: this.state.insuranceAmount,
      insuranceType: this.state.insuranceType,
      interestRate: this.state.interestRate,
      loanType: this.state.loanType,
      originalAmount: parseFloat(this.state.originalAmount).toFixed(2),
      otherFees: parseFloat(this.state.otherFees).toFixed(2),
      savingFees: parseFloat(this.state.savingFees).toFixed(2),
      savingsWarranty: parseFloat(this.state.savingsWarranty).toFixed(2),
      contributionFees: parseFloat(this.state.contributionFees).toFixed(2),
      outlayDate: this.state.outlayDate,
      paymentDay: this.state.paymentDay,
      pcpData: this.state.pcpData,
      periodicity: this.state.periodicity,
      quotationDate: this.state.quotationDate,
      requestAmount: parseFloat(this.state.requestAmount).toFixed(2),
      secondPaymentDay: this.state.secondPaymentDay,
      timeLimit: this.state.timeLimit,
      transferAmount: parseFloat(this.state.transferAmount).toFixed(2),
      warrantyType: this.state.warrantyType
        ? this.state.warrantyType
        : "solidarity",
      paymentMethod: this.state.paymentMethod,
      finality: this.state.finality,
    };
    if (this.props.quotationId) {
      curQuotation = {
        ...curQuotation,
        cancelationReason:
          this.state.quotationStatus === "cancelled"
            ? this.state.cancelationReason
            : "",
        client: this.state.client,
        clientEmail: this.state.clientEmail,
        clientName: this.state.clientName,
        clientNumber: this.state.clientNumber,
        clientType: this.state.clientType,
        identificationNumber: this.state.identificationNumber,
        identificationType: this.state.identificationType,
        quotationStatus: this.state.quotationStatus,

        referenceCode: this.state.referenceCode,
      };

      updateQ(token, this.props.quotationId, curQuotation)
        .then((res) => {
          this.printMessage(translations.QUOTATION, "success");
          this.props.updateQuotation(
            res.data.results ? res.data.results : res.data
          );
        })
        .catch((err) => {
          catchError(err, this);
        });
    } else {
      if (!this.state.client) {
        curQuotation = {
          ...curQuotation,
          clientEmail: this.state.clientEmail,
          clientName: this.state.clientName,
          clientNumber: this.state.clientNumber,
          identificationNumber: this.state.identificationNumber,
          identificationType: this.state.identificationType,
        };
      } else {
        curQuotation = {
          ...curQuotation,
          client: this.state.client,
          identificationNumber: this.state.identificationNumber,
          identificationType: this.state.identificationType,
        };
      }

      if (await this.validateClient()) {
        createQ(token, curQuotation)
          .then((res) => {
            this.printMessage(translations.QUOTATION, "success");
            this.props.createQuotation(
              res.data.results ? res.data.results : res.data
            );
            this.handleReset();
          })
          .catch((err) => {
            catchError(err, this);
          });
      }
    }
  }

  render() {
    const classes = styles;
    const { errors, openAlert, alertMessage, severity } = this.state;
    const { quotationId } = this.props;

    const renderInput = (campo) => {
      const params = {
        disabled: campo.disabled,
        error: errors[campo.value] ? true : false,
        helperText: errors[campo.value],
        id: campo.value,
        label: campo.label,
        onChange: this.handleChange(campo.value),
        required: campo.required,
        style: { width: "100%" },
        value: this.state[campo.value],
        variant: "outlined",
      };
      switch (campo.type) {
        case "n":
          if (
            campo.value === "paymentDay" &&
            transactionTypeAP.some(
              (val) =>
                val.value === this.state.productType &&
                val.value !== "salary_advance"
            )
          ) {
            return (
              <CssTextField
                {...params}
                disabled={
                  (this.state.quotationStatus !== "on_process"
                    ? true
                    : campo.disabled) || this.props.paymentTicket
                }
                inputProps={{ min: new Date().toISOString().split("T")[0] }}
                defaultValue={new Date().toISOString().split("T")[0]}
                InputLabelProps={{ shrink: true }}
                type="date"
              />
            );
          }

          if (
            campo.value === "paymentDay" &&
            this.state.periodicity === "weekly"
          ) {
            return (
              <CssTextField
                label={campo.label}
                select={true}
                fullWidth={true}
                disabled={
                  (this.state.quotationStatus !== "on_process"
                    ? true
                    : campo.disabled) || this.props.paymentTicket
                }
                SelectProps={{ native: true }}
                value={this.state[campo.value]}
                onChange={this.handleSelectChange(campo.value)}
                inputProps={{
                  name: campo.value,
                }}
              >
                {weekDays.map((day, index) => {
                  return (
                    <option value={day.value} key={index}>
                      {day.label}
                    </option>
                  );
                })}
              </CssTextField>
            );
          }

          return (
            <CssTextField
              {...params}
              disabled={
                (this.state.quotationStatus !== "on_process"
                  ? true
                  : campo.value === "timeLimit"
                  ? this.state.productType === "simple_loan" ||
                    this.state.productType === "constant_fees"
                    ? false
                    : true
                  : false) || this.props.paymentTicket
              }
              type="number"
            />
          );
        case "n2":
          if (this.state.periodicity === "biweekly") {
            return (
              <CssTextField
                {...params}
                disabled={
                  (this.state.quotationStatus !== "on_process"
                    ? true
                    : campo.disabled) || this.props.paymentTicket
                }
                type="number"
              />
            );
          } else {
            return <CssTextField {...params} disabled={true} type="number" />;
          }
        case "t":
          return (
            <CssTextField
              {...params}
              disabled={
                (this.state.quotationStatus !== "on_process"
                  ? true
                  : campo.disabled) || this.props.paymentTicket
              }
            />
          );
        case "cbx":
          if (campo.value === "periodicity") {
            let index = campo.options.findIndex(
              (item) => item.value === "single_payment"
            );

            if (this.state.financialConfig !== "toExpiration" && index !== -1) {
              campo.options.splice(index, 1);
            } else if (
              this.state.financialConfig === "toExpiration" &&
              index === -1
            ) {
              campo.options.push({
                value: "single_payment",
                label: translations.SINGLE_PAYMENT,
              });
            }
          }

          return (
            <Autocomplete
              style={{ width: "100%" }}
              options={campo.options}
              getOptionLabel={(option) => option.label}
              value={
                this.state[campo.value]
                  ? campo.options.find(
                      (item) => item.value === this.state[campo.value]
                    )
                  : null
              }
              id={campo.value}
              disabled={
                (this.state.quotationStatus !== "on_process"
                  ? true
                  : campo.value === "periodicity" &&
                    transactionTypeAP.some(
                      (val) => val.value === this.state.productType
                    )
                  ? true
                  : campo.disabled) || this.props.paymentTicket
              }
              onChange={(e, value) =>
                this.handleAutocompleteChange(campo.value, value)
              }
              renderInput={(props) => (
                <CssTextField
                  {...props}
                  label={campo.label}
                  variant="outlined"
                />
              )}
            />
          );
        case "com":
          return (
            <Autocomplete
              style={{ width: "100%" }}
              options={
                campo.value === "administrativeExpensesType" &&
                (this.state.productType === "simple_loan" ||
                  this.state.productType === "constant_fees" ||
                  this.state.productType === "decreasing_fees")
                  ? campo.options
                  : campo.options.filter((opt) => opt.value !== "postponed")
              }
              getOptionLabel={(option) => option.label}
              value={
                this.state[campo.value]
                  ? campo.options.find(
                      (item) => item.value === this.state[campo.value]
                    )
                  : null
              }
              id={campo.value}
              disabled={
                (this.state.quotationStatus !== "on_process"
                  ? true
                  : campo.disabled) || this.props.paymentTicket
              }
              onChange={(e, value) => {
                this.handleAutocompleteChange(campo.value, value);
                this.setState({ administrativeExpensesRate: 0 });
              }}
              renderInput={(props) => (
                <CssTextField
                  {...props}
                  label={campo.label}
                  variant="outlined"
                />
              )}
            />
          );
        case "fract":
          return (
            <Autocomplete
              style={{ width: "100%" }}
              options={
                campo.value === "fractionationType" &&
                this.state.productType === "simple_loan"
                  ? campo.options.filter(
                      (opt) =>
                        opt.value !== "discounted" &&
                        opt.value !== "capitalized"
                    )
                  : campo.value === "fractionationType" &&
                    this.state.productType === "salary_advance"
                  ? campo.options.filter(
                      (opt) =>
                        opt.value !== "discounted" &&
                        opt.value !== "postponed" &&
                        opt.value !== "capitalized"
                    )
                  : campo.options
              }
              getOptionLabel={(option) => option.label}
              id={campo.value}
              value={
                this.state[campo.value]
                  ? campo.options.find(
                      (item) => item.value === this.state[campo.value]
                    )
                  : null
              }
              disabled={
                (this.state.quotationStatus !== "on_process"
                  ? true
                  : campo.disabled
                  ? !this.state[campo.disabled]
                  : campo.disabled) || this.props.paymentTicket
              }
              onChange={(e, value) =>
                this.handleAutocompleteChange(campo.value, value)
              }
              renderInput={(props) => (
                <CssTextField
                  {...props}
                  label={campo.label}
                  variant="outlined"
                  error={errors[campo.value] ? true : false}
                  helperText={errors[campo.value]}
                />
              )}
            />
          );
        case "f":
          if (campo.value === "outlayDate") {
            return (
              <CssTextField
                {...params}
                InputLabelProps={{ shrink: true }}
                disabled={
                  (this.state.quotationStatus !== "on_process"
                    ? true
                    : campo.disabled) || this.props.paymentTicket
                }
                inputProps={{ max: this.state.paymentDay }}
                type="date"
              />
            );
          } else {
            return (
              <CssTextField
                {...params}
                InputLabelProps={{ shrink: true }}
                disabled={
                  (this.state.quotationStatus !== "on_process"
                    ? true
                    : campo.disabled) || this.props.paymentTicket
                }
                type="date"
              />
            );
          }
        case "cb":
          return (
            <FormControlLabel
              {...params}
              style={{ margin: "auto" }}
              error="false"
              disabled={
                (this.state.quotationStatus !== "on_process"
                  ? true
                  : campo.disabled
                  ? this.state.financialConfig === campo.disabled
                    ? true
                    : false
                  : campo.value === "hasFractionation" &&
                    transactionTypeAP.some(
                      (opt) => opt.value === this.state.productType
                    )) || this.props.paymentTicket
              }
              control={
                <Checkbox
                  className="checkbox"
                  checked={this.state[campo.value]}
                />
              }
              onChange={this.handleCheckboxChange(campo.value)}
              labelPlacement="start"
            />
          );
        case "%":
          if (
            campo.value === "administrativeExpensesRate" &&
            this.state.administrativeExpensesType === "condonated"
          ) {
            return (
              <CssTextField
                {...params}
                disabled={true}
                value={this.state[campo.value]}
                required={false}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: interestRateInput,
                }}
              />
            );
          } else {
            return (
              <CssTextField
                {...params}
                disabled={
                  (this.state.quotationStatus !== "on_process"
                    ? true
                    : campo.disabled) || this.props.paymentTicket
                }
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: interestRateInput,
                }}
              />
            );
          }

        case "$":
          return (
            <CssTextField
              {...params}
              disabled={
                (this.state.quotationStatus !== "on_process"
                  ? true
                  : campo.disabled) || this.props.paymentTicket
              }
              onChange={this.handleCurrencyInputChange(campo.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: MoneyInput,
              }}
            />
          );
        default:
          break;
      }
    };

    const list = () => (
      <React.Fragment>
        {[
          "Monto a financiar",
          "Frecuencia vencimiento",
          "Primera cuota",
          "Pago de Capital Programado",
        ].map((header, indx) => (
          <React.Fragment key={indx}>
            <Grid container={true} className={classes.half} spacing={2}>
              <h3 style={{ margin: "10px 0px" }}>{header}</h3>
              <hr style={{ width: "inherit" }} />
              {amortizationTable.map(
                (campo, index) =>
                  campo.category === indx + 1 && (
                    <Grid
                      item={true}
                      xs={campo.category === 4 ? 4 : 3}
                      key={index}
                    >
                      {renderInput(campo)}
                    </Grid>
                  )
              )}
            </Grid>
          </React.Fragment>
        ))}
        <Grid item={true} xs={4}>
          <FormHelperText style={{ width: "195px", color: "red" }}>
            {errors.hasSpp}
          </FormHelperText>
        </Grid>
        <Grid item={true} xs={12}>
          {this.state.hasSpp ? (
            <div>
              <PcpTable
                create={
                  this.state.quotationStatus === "on_process" ? true : false
                }
                actions={
                  this.state.quotationStatus === "on_process" ? true : false
                }
                tableLimit={10}
                data={this.state.pcpData}
                editIdx={this.state.editIdx}
                handleChange={this.handleChangeTD}
                handleRemove={this.handleRemove}
                handleSave={this.handleSave}
                handleStartEdit={this.handleStartEdit}
                handleStopEdit={this.handleStopEdit}
                header={
                  this.state.productType !== "constant_fees"
                    ? headersPCP
                    : [...headersPCP, ...termReductionOptions]
                }
              />
            </div>
          ) : null}
        </Grid>
      </React.Fragment>
    );

    return (
      <div className="quotation-container">
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={() => this.switchState("openAlert")}
        >
          <MuiAlert
            onClose={() => this.switchState("openAlert")}
            severity={severity}
          >
            {alertMessage}
          </MuiAlert>
        </Snackbar>
        <Modal
          open={this.state.cancelModal}
          onClose={() => this.switchState("cancelModal")}
        >
          <div className="pcp-table-container">
            <label>Explique la razon? </label>
            <Select
              id="cancelationReason"
              style={{ width: 250 }}
              value={this.state.cancelationReason}
              onChange={(e) => {
                this.setState({ cancelationReason: e.target.value });
              }}
              disabled={this.props.paymentTicket}
            >
              {cancelQuotation.map((opt, indx) => {
                return (
                  <MenuItem value={opt.value} key={indx}>
                    {opt.label}
                  </MenuItem>
                );
              })}
            </Select>
            <Button
              onClick={this.saveCancelationReason()}
              disabled={this.props.paymentTicket}
            >
              {translations.SAVE}
            </Button>
          </div>
        </Modal>
        <Typography variant="h4">
          {`${!quotationId ? "Solicitud de" : ""} ${translations.QUOTATION}`}
        </Typography>
        <div>
          <section className="header-section1">
            <CssTextField
              label={translations.QUOTATION_NUMBER}
              disabled={true}
              onChange={this.handleChange("referenceCode")}
              value={this.state.referenceCode}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* <FormControl 
              variant="outlined"
              style={classes.formControl}
              error={errors.company ? true : false}
            >
              <InputLabel
                id="company"
              >
                {translations.COMPANY}
              </InputLabel>
              <Select
                label={translations.COMPANY}
                id="company"
                disabled={
                  (this.state.quotationStatus !== "on_process"
                    ? true
                    : false) || this.props.paymentTicket
                }
                style={{ width: 250 }}
                onChange={this.handleSelectChange("company")}
                value={this.state.company}
              >
                {COMPANIES.map((opt, indx) => {
                  return (
                    <MenuItem value={opt} key={indx}>
                      {opt}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{errors.company}</FormHelperText>
            </FormControl> */}
            {quotationId ? (
              <FormControl
                variant="outlined"
                style={classes.formControl}
                error={errors.quotationStatus ? true : false}
              >
                <InputLabel id="quotationStatus">
                  {translations.STATUS}
                </InputLabel>
                <Select
                  label={translations.STATUS}
                  id="quotationStatus"
                  disabled={
                    this.state.quotationStatus === "cancelled" ||
                    this.props.paymentTicket ||
                    !this.state.roles?.includes("EditQuotation")
                  }
                  style={{ width: 250 }}
                  onChange={this.handleSelectChange("quotationStatus")}
                  value={this.state.quotationStatus}
                >
                  {quotationStatus.map((status, indx) => {
                    return (
                      <MenuItem value={status.value} key={indx}>
                        {status.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.quotationStatus}</FormHelperText>
              </FormControl>
            ) : null}
            <CssTextField
              type="date"
              label={translations.QUOTATION_DATE}
              variant="outlined"
              disabled={
                (quotationId ? true : false) || this.props.paymentTicket
              }
              onChange={this.handleChange("quotationDate")}
              value={this.state.quotationDate}
              inputProps={{ max: new Date().toISOString().split("T")[0] }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </section>
          <section className="header-section2">
            {quotationId ? (
              <>
                <CssTextField
                  style={{ width: "40%" }}
                  disabled={
                    (quotationId ? true : false) || this.props.paymentTicket
                  }
                  label={translations.PARTICIPANT}
                  value={this.state.participantName}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <CssTextField
                  style={{ width: 190 }}
                  label={`ROHI ID`}
                  disabled={
                    (quotationId ? true : !this.state.addClient) ||
                    this.props.paymentTicket
                  }
                  value={this.state.rohiId}
                  onChange={this.handleChange("rohiId")}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </>
            ) : !this.state.addClient ? (
              <>
                <SearchComponent
                  xs={4}
                  containerClass="field-container"
                  fieldClass="fields"
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  // fieldLabel={translations.PARTICIPANT}
                  fieldID="client"
                  fieldInnerLabel="Nombre del participante"
                  fieldVariant="outlined"
                  handleChange={this.handleAutocompleteChange.bind(
                    this,
                    "client"
                  )}
                  optionType={"participants"}
                  limitTags={1}
                  error={errors.client ? true : false}
                  helperText={errors.client}
                  disabled={
                    (quotationId ? true : false) || this.props.paymentTicket
                  }
                />
                <CssTextField
                  style={{ width: 190 }}
                  label={`ROHI ID`}
                  disabled={
                    (quotationId ? true : !this.state.addClient) ||
                    this.props.paymentTicket
                  }
                  value={this.state.rohiId}
                  onChange={this.handleChange("rohiId")}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </>
            ) : (
              <CssTextField
                style={{ width: "40%" }}
                disabled={
                  (quotationId ? true : false) || this.props.paymentTicket
                }
                label={translations.PARTICIPANT}
                value={this.state.clientName}
                error={errors.client ? true : false}
                helperText={errors.client}
                onChange={this.handleChange("clientName")}
                variant="outlined"
              />
            )}

            <FormControl
              variant="outlined"
              style={classes.formControl}
              errors={errors.identificationType}
            >
              <InputLabel id="identificationType">
                {translations.IDENTIFICATION_TYPE}
              </InputLabel>
              <Select
                id="identificationType"
                value={this.state.identificationType}
                style={{ width: 200 }}
                label={translations.IDENTIFICATION_TYPE}
                disabled={
                  (quotationId ? true : !this.state.addClient) ||
                  this.props.paymentTicket
                }
                onChange={this.handleSelectChange("identificationType")}
              >
                <MenuItem value=""></MenuItem>
                {IDENTIFICATION_TYPE.map((opt, indx) => {
                  return (
                    <MenuItem value={opt.value} key={indx}>
                      {opt.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{errors.identificationType}</FormHelperText>
            </FormControl>
            <CssTextField
              style={{ width: 190 }}
              label={translations.IDENTIFICATION}
              disabled={
                (quotationId ? true : !this.state.addClient) ||
                this.props.paymentTicket
              }
              value={this.state.identificationNumber}
              onChange={this.handleChange("identificationNumber")}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={this.getIdentificationInput(
                this.state.identificationType
              )}
            />

            {/*
               <Autocomplete
                id="clients"
                options={this.state.clients}
                getOptionLabel={(option) => option.fullName}
                style={classes.autoComplete}
                value={
                  this.state.client
                    ? this.state.clients.find(
                        (item) => item.uniqueId === this.state.client
                      )
                    : null
                }
                onChange={(e, value) =>
                  this.handleAutocompleteChange("client", value)
                }
                disabled={quotationId ? true : false}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    error={
                      errors.client
                        ? true
                        : false
                    }
                    helperText={
                      errors.client
                    }
                    label={translations.PARTICIPANT}
                    variant="outlined"
                  />
                )}
               />
            */}
            {this.state.addClient ? (
              <section>
                <FormControl
                  variant="outlined"
                  style={classes.formControl}
                  error={errors.contactMethod ? true : false}
                >
                  <InputLabel id="contactMethod">
                    {translations.CONTACT_METHOD}
                  </InputLabel>
                  <Select
                    id="contactMethod"
                    value={this.state.contactMethod}
                    style={{ width: 190 }}
                    disabled={
                      (quotationId ? true : false) || this.props.paymentTicket
                    }
                    label={translations.CONTACT_METHOD}
                    onChange={this.handleSelectChange("contactMethod")}
                  >
                    <MenuItem value="email">{translations.EMAIL}</MenuItem>
                    <MenuItem value="phone">
                      {translations.PHONE_NUMBER}
                    </MenuItem>
                    <MenuItem value="both">{translations.BOTH}</MenuItem>
                  </Select>
                  <FormHelperText>{errors.contactMethod}</FormHelperText>
                </FormControl>
                {this.state.contactMethod === "email" ||
                this.state.contactMethod === "both" ? (
                  <CssTextField
                    style={{ width: 250 }}
                    label={translations.EMAIL}
                    disabled={
                      (quotationId ? true : false) || this.props.paymentTicket
                    }
                    value={this.state.clientEmail}
                    onChange={this.handleChange("clientEmail")}
                    variant="outlined"
                  />
                ) : null}
                {this.state.contactMethod === "phone" ||
                this.state.contactMethod === "both" ? (
                  <CssTextField
                    style={{ width: 250 }}
                    label={translations.PHONE_NUMBER}
                    value={this.state.clientNumber}
                    disabled={
                      (quotationId ? true : false) || this.props.paymentTicket
                    }
                    onChange={this.handleChange("clientNumber")}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                  />
                ) : null}
              </section>
            ) : null}
            <FormControl
              style={classes.formControl}
              error={errors.clientType ? true : false}
            >
              <FormLabel component="legend">{`Tipo de Participante`}</FormLabel>
              <RadioGroup
                style={{ display: "flex" }}
                value={this.state.clientType}
                onChange={this.handleChange("clientType")}
              >
                {clientTypes.map((item, indx) => {
                  return (
                    <FormControlLabel
                      style={{ height: "26px" }}
                      key={indx}
                      value={item.value}
                      control={
                        <GreenRadio
                          disabled={
                            quotationId ? true : this.state.newClientType
                          }
                        />
                      }
                      label={item.label}
                      disabled={this.props.paymentTicket}
                    />
                  );
                })}
              </RadioGroup>
              <FormHelperText>{errors.clientType}</FormHelperText>
            </FormControl>

            <IconButton
              onClick={() => {
                this.switchAddClient();
                this.setState({
                  newClientType: !this.state.newClientType,
                  clientType: this.state.addClient ? "regular" : "new regular",
                });
              }}
              disabled={
                (quotationId ? true : false) || this.props.paymentTicket
              }
            >
              {!this.state.addClient ? <AddCircleIcon /> : <CancelIcon />}
            </IconButton>

            <IconButton onClick={() => this.switchState("openCalculate")}>
              <FontAwesomeIcon icon={faCalculator} />
            </IconButton>
            {this.state.saveButton ? (
              <IconButton
                onClick={this.handleSubmit}
                disabled={
                  (this.state.quotationStatus === "cancelled" ? true : false) ||
                  this.props.paymentTicket
                }
              >
                <FontAwesomeIcon icon={faSave} />
              </IconButton>
            ) : null}

            {quotationId ? (
              <IconButton onClick={this.generateFile}>
                <FontAwesomeIcon icon={faDownload} />
              </IconButton>
            ) : null}
          </section>
        </div>
        <div>
          <Modal
            open={this.state.openCalculate}
            onClose={() => this.switchState("openCalculate")}
          >
            <div className="calculator-container">
              <Grid container={true} className={classes.half} spacing={2}>
                <Grid item={true} xs={12}>
                  <Typography
                    variant="h4"
                    style={{ textAlign: "center", marginBottom: "10px" }}
                  >
                    {`${
                      !quotationId ? "Calculadora de" : ""
                    } Tabla de Amortización`}
                  </Typography>
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl
                    variant="outlined"
                    style={classes.gridFormControl}
                    error={errors.financialConfig ? true : false}
                  >
                    <InputLabel id="financialConfig">
                      {translations.FINANCIAL_CONFIG}
                    </InputLabel>
                    <Select
                      id="financialConfig"
                      value={this.state.financialConfig}
                      style={{ width: "100%" }}
                      disabled={
                        (this.state.quotationStatus !== "on_process"
                          ? true
                          : false) || this.props.paymentTicket
                      }
                      label={translations.FINANCIAL_CONFIG}
                      onChange={this.handleSelectChange("financialConfig")}
                    >
                      {financialConfig.map((opt, indx) => {
                        return (
                          <MenuItem value={opt.value} key={indx}>
                            {opt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{errors.financialConfig}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl
                    variant="outlined"
                    style={classes.gridFormControl}
                    error={errors.productType ? true : false}
                  >
                    <InputLabel id="productType">
                      {translations.PRODUCT_TYPE}
                    </InputLabel>
                    <Select
                      id="productType"
                      value={this.state.productType}
                      style={{ width: "100%" }}
                      disabled={
                        (this.state.quotationStatus !== "on_process"
                          ? true
                          : false) || this.props.paymentTicket
                      }
                      label={translations.PRODUCT_TYPE}
                      onChange={this.handleSelectChange("productType")}
                    >
                      {this.state.financialConfig === "justInterest" ? (
                        <MenuItem value={"simple_loan"}>
                          {translations.SIMPLE_LOAN}
                        </MenuItem>
                      ) : this.state.financialConfig === "staggered" ? (
                        staggeredOptions.map((opt, indx) => {
                          return (
                            <MenuItem value={opt.value} key={indx}>
                              {opt.label}
                            </MenuItem>
                          );
                        })
                      ) : (
                        [
                          <MenuItem value={"salary_advance"} key={9999999}>
                            {translations.SALARY_ADVANCE}
                          </MenuItem>,
                          transactionTypeAP.map((opt, indx) => {
                            return (
                              <MenuItem value={opt.value} key={indx}>
                                {opt.label}
                              </MenuItem>
                            );
                          }),
                        ]
                      )}
                    </Select>
                    <FormHelperText>{errors.productType}</FormHelperText>
                  </FormControl>
                </Grid>
                {this.state.productType === "decreasing_fees" ? (
                  <Grid item={true} xs={3}>
                    <CssTextField
                      style={{ width: "100%" }}
                      disabled={
                        (this.state.quotationStatus !== "on_process"
                          ? true
                          : false) || this.props.paymentTicket
                      }
                      label="Monto de Amortización"
                      value={this.state.amortizationAmount}
                      variant="outlined"
                      onChange={this.handleCurrencyInputChange(
                        "amortizationAmount"
                      )}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputComponent: MoneyInput,
                      }}
                    />
                  </Grid>
                ) : null}
                <Grid item={true} xs={3}>
                  <FormControl
                    variant="outlined"
                    style={classes.gridFormControl}
                  >
                    <InputLabel
                      id="loanType"
                      error={errors.loanType ? true : false}
                    >
                      {translations.WARRANTY_CLASSIFICATION}
                    </InputLabel>
                    <Select
                      id="loanType"
                      label={translations.WARRANTY_CLASSIFICATION}
                      onChange={this.handleSelectChange("loanType")}
                      disabled={
                        (this.state.quotationStatus !== "on_process"
                          ? true
                          : false) || this.props.paymentTicket
                      }
                      style={{ width: "100%" }}
                      value={this.state.loanType}
                    >
                      {this.state.financialConfig === "toExpiration" ? (
                        <MenuItem value={"jointGuarantee"}>
                          Garantía solidaria
                        </MenuItem>
                      ) : (
                        loanTypes.map((opt, indx) => (
                          <MenuItem key={indx} value={opt.value}>
                            {opt.label}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    <FormHelperText>{errors.loanType}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl
                    variant="outlined"
                    style={classes.gridFormControl}
                    error={errors.warrantyType ? true : false}
                  >
                    <InputLabel id="warrantyType">
                      {translations.WARRANTY_TYPES}
                    </InputLabel>
                    <Select
                      id="warrantyType"
                      value={this.state.warrantyType}
                      style={{ width: "100%" }}
                      disabled={
                        this.state.quotationStatus !== "on_process" ||
                        this.state.loanType === ""
                          ? true
                          : false
                      }
                      label={translations.WARRANTY_TYPES}
                      onChange={this.handleSelectChange("warrantyType")}
                    >
                      {this.state.loanType === "jointGuarantee"
                        ? jointTypes.map((opt, indx) => {
                            return (
                              <MenuItem value={opt.value} key={indx}>
                                {opt.label}
                              </MenuItem>
                            );
                          })
                        : this.state.loanType === "collateralGuarantee"
                        ? collateralTypes.map((opt, indx) => {
                            return (
                              <MenuItem value={opt.value} key={indx}>
                                {opt.label}
                              </MenuItem>
                            );
                          })
                        : this.state.loanType === "liquidGuarantee"
                        ? liquidTypes.map((opt, indx) => {
                            return (
                              <MenuItem value={opt.value} key={indx}>
                                {opt.label}
                              </MenuItem>
                            );
                          })
                        : this.state.loanType === "mortgageGuarantee"
                        ? [{ value: "mortgage", label: "Hipotecaria" }].map(
                            (opt, indx) => {
                              return (
                                <MenuItem value={opt.value} key={indx}>
                                  {opt.label}
                                </MenuItem>
                              );
                            }
                          )
                        : null}
                    </Select>
                    <FormHelperText>{errors.warrantyType}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    style={{ width: "100%" }}
                    error={errors.requestAmount ? true : false}
                    label={translations.REQUEST_AMOUNT}
                    disabled={
                      (this.state.quotationStatus !== "on_process"
                        ? true
                        : false) || this.props.paymentTicket
                    }
                    value={this.state.requestAmount}
                    helperText={errors.requestAmount}
                    variant="outlined"
                    onChange={this.handleCurrencyInputChange("requestAmount")}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    style={{ width: "100%" }}
                    type="number"
                    label="Tasa de cuota de seguro"
                    disabled={
                      (this.state.quotationStatus !== "on_process"
                        ? true
                        : false) || this.props.paymentTicket
                    }
                    value={this.state.insuranceAmount}
                    variant="outlined"
                    onChange={this.handleChange("insuranceAmount")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    label={translations.INTEREST_RATE}
                    style={{ width: "100%" }}
                    value={this.state.interestRate}
                    error={errors.interestRate ? true : false}
                    helperText={errors.interestRate}
                    disabled={
                      (this.state.quotationStatus !== "on_process"
                        ? true
                        : false) || this.props.paymentTicket
                    }
                    variant="outlined"
                    onChange={this.handleChange("interestRate")}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: interestRateInput,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    label={translations.OTHER_INSURANCE}
                    style={{ width: "100%" }}
                    value={this.state.commissionRate}
                    error={errors.commissionRate ? true : false}
                    helperText={errors.commissionRate}
                    disabled={
                      (this.state.quotationStatus !== "on_process"
                        ? true
                        : false) || this.props.paymentTicket
                    }
                    variant="outlined"
                    onChange={this.handleChange("commissionRate")}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: interestRateInput,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    label={"Garantía de ahorro"}
                    style={{ width: "100%" }}
                    value={this.state.savingsWarranty}
                    error={errors.savingsWarranty ? true : false}
                    helperText={errors.savingsWarranty}
                    disabled
                    variant="outlined"
                    onChange={this.handleCurrencyInputChange("savingsWarranty")}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          xs={2}
                          variant="outlined"
                          disableElevation
                          style={{
                            borderRadius: 25,
                            backgroundColor: green[500],
                            color: "white",
                            fontWeight: "bold",
                          }}
                          onClick={this.calcWarranty}
                        >
                          <Dialpad style={{ fontSize: 15 }} />
                        </IconButton>
                      ),
                      inputComponent: MoneyInput,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    style={{ width: "100%" }}
                    error={errors.givenWarranty ? true : false}
                    label={"Monto de garantía entregado"}
                    disabled={
                      (this.state.quotationStatus !== "on_process"
                        ? true
                        : !this.state.savingsWarranty) || this.props.paymentTicket
                    }
                    value={this.state.givenWarranty}
                    helperText={errors.givenWarranty}
                    variant="outlined"
                    onChange={this.handleCurrencyInputChange("givenWarranty")}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl
                    variant="outlined"
                    style={classes.gridFormControl}
                    error={errors.paymentMethod ? true : false}
                  >
                    <InputLabel id="paymentMethod">
                      {translations.PAYMENT_METHOD}
                    </InputLabel>
                    <Select
                      id="paymentMethod"
                      value={this.state.paymentMethod}
                      style={{ width: "100%" }}
                      disabled={
                        (this.state.quotationStatus !== "on_process"
                          ? true
                          : false) || this.props.paymentTicket
                      }
                      label={translations.PAYMENT_METHOD}
                      onChange={this.handleSelectChange("paymentMethod")}
                    >
                      {paymentMethods.map((opt, indx) => {
                        return (
                          <MenuItem value={opt.value} key={indx}>
                            {opt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{errors.paymentMethod}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl
                    variant="outlined"
                    style={classes.gridFormControl}
                    error={errors.finality ? true : false}
                  >
                    <InputLabel id="finality">
                      {translations.FINALITY}
                    </InputLabel>
                    <Select
                      id="finality"
                      value={this.state.finality}
                      style={{ width: "100%" }}
                      disabled={
                        (this.state.quotationStatus !== "on_process"
                          ? true
                          : false) || this.props.paymentTicket
                      }
                      label={translations.FINALITY}
                      onChange={this.handleSelectChange("finality")}
                    >
                      {finalities.map((opt, indx) => {
                        return (
                          <MenuItem value={opt.value} key={indx}>
                            {opt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{errors.finality}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <CssTextField
                    label={"Monto de afiliación"}
                    style={{ width: "100%" }}
                    value={this.state.affiliationAmount}
                    error={errors.affiliationAmount ? true : false}
                    helperText={errors.affiliationAmount}
                    disabled={
                      (this.state.quotationStatus !== "on_process"
                        ? true
                        : !this.state.editAffiliation) || this.props.paymentTicket
                    }
                    variant="outlined"
                    onChange={this.handleCurrencyInputChange(
                      "affiliationAmount"
                    )}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                  />
                </Grid>
                <Grid item={true}>
                  <FormGroup style={{ marginLeft: 12 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.editAffiliation}
                          onChange={this.toggleChecked}
                          color="primary"
                        />
                      }
                      label="Editar afiliación"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              {list()}
              <div className="action-section">
                <div className="action-button">
                  <>
                    <Button
                      style={
                        (!quotationId ||
                          this.state.quotationStatus === "on_process") &&
                        !this.props.paymentTicket
                          ? classes.clean
                          : classes.disabled
                      }
                      disabled={
                        (!quotationId ||
                        this.state.quotationStatus === "on_process"
                          ? false
                          : true) || this.props.paymentTicket
                      }
                      onClick={this.handleReset}
                    >
                      <FontAwesomeIcon icon={faBroom} />
                      &nbsp; Limpiar
                    </Button>

                    <Button
                      style={
                        !quotationId ||
                        this.state.quotationStatus === "on_process"
                          ? classes.calculate
                          : classes.disabled
                      }
                      disabled={
                        !quotationId ||
                        this.state.quotationStatus === "on_process"
                          ? false
                          : true
                      }
                      onClick={this.handleTable}
                    >
                      <FontAwesomeIcon icon={faCalculator} />
                      &nbsp; Calcular
                    </Button>
                    <Button
                      style={
                        !quotationId ||
                        this.state.quotationStatus === "on_process"
                          ? classes.clean
                          : classes.disabled
                      }
                      disabled={
                        !quotationId ||
                        this.state.quotationStatus === "on_process"
                          ? false
                          : true
                      }
                      onClick={() => this.switchState("openCalculate")}
                    >
                      <FontAwesomeIcon icon={faClosedCaptioning} />
                      &nbsp; Cerrar
                    </Button>
                  </>
                </div>
              </div>
            </div>
          </Modal>
          <div className="table-section">
            <TableContainer component={Paper} className="amort-table">
              <Table size="small" stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    {headersLoan.map((opt, indx) => {
                      return (
                        <TableCell
                          style={{ color: "white", background: "#4caf50" }}
                          key={indx}
                        >
                          {opt.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.tableContent.map((opt, indx) => {
                    if (indx > 0) {
                      return (
                        <TableRow key={indx}>
                          {opt.map((campo, key) => (
                            <TableCell key={key}>{campo}</TableCell>
                          ))}
                        </TableRow>
                      );
                    }
                    return false;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    createQuotation,
    updateQuotation,
  })(AmortizationCalculator)
);
