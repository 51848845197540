import axios from "axios";
import * as React from "react";
import { connect } from "react-redux";
import FileUploadSelectInput from "src/containers/FileUpload/FileUploadSelectInput";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import DocumentIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {
  deleteMembershipApplication,
  fetchMembershipApplication,
  updateMembershipApplication,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import StakeHoldersSelectInput from "../../../components/StakeHoldersSelectInput";
import {
  catchError,
  debtorsValidation,
  filesGeneration,
  jsonToStakeHolderArray,
  resetError,
  stakeHolderArrayToJson,
  successHandler,
  translations,
} from "../../../utils";
import {
  API_KEY,
  deleteMembershipApplication as deleteMA,
  fetchMembershipApplication as fetchMA,
  updateMembershipApplication as updateMA,
} from "../../../utils/axios";
import CreditorSelectInput from "../../Creditor/CreditorSelectInput";
import DepositBankAccountSelectInput from "../../DepositBankAccount/DepositBankAccountSelectInput";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

class View extends React.Component {
  state = {
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    creditor: [],
    creditorError: "",
    debtor: [],
    debtorError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    familyMembersInfo: [],
    familyMembersInfoError: "",
    financialAccounts: [],
    financialAccountsError: "",
    loading: false,
    MA: "",
    title: "",
    titleError: "",
    status: "pending_signature",
    uniqueId: "",
  };
  constructor(props) {
    super(props);

    // creditor
    this.handleAddCreditor = this.handleAddCreditor.bind(this);
    this.handleDeleteCreditor = this.handleDeleteCreditor.bind(this);

    // client
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // financial Accounts
    this.handleAddBankAccount = this.handleAddBankAccount.bind(this);
    this.handleDeleteBankAccount = this.handleDeleteBankAccount.bind(this);

    // stakeholder
    this.handleAddStakeHolder = this.handleAddStakeHolder.bind(this);
    this.handleDeleteStakeHolder = this.handleDeleteStakeHolder.bind(this);

    // core events
    this.generateFile = this.generateFile.bind(this);
    this.changeStatus = this.changeStatus.bind(this);

    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }
  handleAddStakeHolder(stakeholder) {
    this.setState((prevState) => ({
      familyMembersInfo: [...prevState.familyMembersInfo, stakeholder],
    }));
  }
  handleDeleteStakeHolder(index) {
    this.setState((prevState) => ({
      familyMembersInfo: prevState.familyMembersInfo.filter(
        (stakeholder, i) => i !== index
      ),
    }));
  }
  handleAddCreditor(id) {
    this.setState({ creditor: [id] });
  }
  handleDeleteCreditor(id) {
    this.setState({ creditor: [] });
  }
  handleAddClient(id) {
    this.setState({ debtor: [id] });
  }
  handleDeleteClient(id) {
    this.setState({ debtor: [] });
  }

  handleAddBankAccount(id) {
    const found = this.state.financialAccounts.find((faId) => faId === id);

    if (!found) {
      this.setState((prevState) => ({
        financialAccounts: [...prevState.financialAccounts, id],
      }));
    }
  }
  handleDeleteBankAccount(id) {
    this.setState((prevState) => ({
      financialAccounts: prevState.financialAccounts.filter(
        (faId) => faId !== id
      ),
    }));
  }

  handleCheckBoxChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  async generateFile() {
    this.setState({ loading: true });
    if (
      await debtorsValidation(
        this,
        this.props.user.token,
        this.state.debtor,
        false
      )
    ) {
      const url = `/lending/membership-application/${this.state.uniqueId}/print`;
      await filesGeneration(url, this.props.user.token)
      this.setState({ loading: false });
    }
  }
  changeStatus(status) {
    return () => {
      this.setState({ status });
    };
  }

  componentDidMount() {
    fetchMA(this.props.user.token, this.props.id)
      .then((res) => {
        const contract = res.data;
        const familyMembersInfo = jsonToStakeHolderArray(
          contract.familyMembersInfo || {}
        );

        this.setState({
          status: contract.status || "",
          contractDate: contract.contractDate || "",
          creditor: contract.creditor ? [contract.creditor] : [],
          debtor: contract.debtor ? [contract.debtor] : [],
          dialog: false,
          dialogTitle: "",
          familyMembersInfo,
          financialAccounts: contract.financialAccounts || [],
          loading: false,
          MA: `lending/membership-application/${contract.uniqueId}/upload_attachment/`,
          title: contract.title || "",
          uniqueId: contract.uniqueId || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  handleCurrencyInputChange(key) {
    return (event, maskedValue = "", floatValue = "") => {
      this.setState({ [key]: floatValue });
    };
  }
  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  handleSelectChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.value });
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    resetError(this);
    const { state } = this;
    if (state.familyMembersInfo.length > 0) {
      let count = 0;
      for (let member of state.familyMembersInfo) {
        count += parseInt(member.proportion, 0);
      }
      if (count <= 0 || count > 100 || count < 100) {
        this.setState({
          familyMembersInfoError:
            "La suma de las proporciónes debe ser mayor a 0% y no pasar de 100%",
        });
      } else {
        const familyMembersInfo = stakeHolderArrayToJson(
          state.familyMembersInfo
        );

        const contract = {
          contractDate: state.contractDate,
          creditor: state.creditor[0] || "",
          debtor: state.debtor[0] || "",
          familyMembersInfo,
          financialAccounts: state.financialAccounts,
          title: state.title,
          status: state.status,
        };

        this.setState({ loading: true });

        updateMA(this.props.user.token, this.state.uniqueId, contract)
          .then((res) => {
            this.props.updateMembershipApplication(
              res.data.results ? res.data.results : res.data
            );
            successHandler(res, this, translations.MA);
          })
          .catch((err) => {
            catchError(err, this);
          });
      }
    } else {
      this.setState({ familyMembersInfoError: "Este campo es requerido" });
    }
  }

  handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteMA(this.props.user.token, this.props.id)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteMembershipApplication(this.props.id);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  handleDialogClose() {
    this.setState({ dialog: false });
  }
  render() {
    const { classes } = this.props;
    return (
      <form
        onSubmit={this.handleSubmit}
        noValidate={true}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <FileUploadSelectInput
            contractId={this.state.uniqueId}
            url={this.state.MA}
            description="MA"
          />
          <Typography variant="h4" className={classes.title}>
            {translations.MA}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={`${classes.greenButton} generate-print-document`}
                onClick={this.generateFile}
                style={{ marginRight: 10, marginTop: 25 }}
              >
                <DocumentIcon />
                &nbsp;
                {translations.GENERATE_DOCUMENT}
              </Button>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="status" shrink={true}>
                  {translations.STATUS}
                </InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                  required={true}
                  inputProps={{
                    id: "status",
                    name: "status",
                  }}
                >
                  <MenuItem value="pending_signature">
                    {translations.PENDING_SIGNATURE}
                  </MenuItem>
                  <MenuItem value="completed">
                    {translations.COMPLETED}
                  </MenuItem>
                  <MenuItem value="archived">{translations.ARCHIVED}</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={this.state.title}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.CREDITORS}
                </Typography>
                <CreditorSelectInput
                  selectedCreditorsId={this.state.creditor}
                  addCreditor={this.handleAddCreditor}
                  deleteCreditor={this.handleDeleteCreditor}
                />
                <p className={classes.errorInput}>{this.state.creditorError}</p>
              </div>
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.PARTNERS}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtor}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.BANK_ACCOUNTS}
                </Typography>
                <DepositBankAccountSelectInput
                  selectedDepositBankAccountsId={this.state.financialAccounts}
                  addDepositBankAccount={this.handleAddBankAccount}
                  deleteDepositBankAccount={this.handleDeleteBankAccount}
                />
                <p className={classes.errorInput}>
                  {this.state.financialAccountsError}
                </p>
              </div>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {"Beneficiarios en caso de fallecimiento"}
                </Typography>
                <StakeHoldersSelectInput
                  stakeHolders={this.state.familyMembersInfo}
                  add={this.handleAddStakeHolder}
                  delete={this.handleDeleteStakeHolder}
                  hasProportion={true}
                />
                <p className={classes.errorInput}>
                  {this.state.familyMembersInfoError}
                </p>
              </div>

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    membershipApplications: state.membershipApplications,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteMembershipApplication,
    fetchMembershipApplication,
    updateMembershipApplication,
  })(View)
);
