import { INTERNATIONAL_BLACKLIST_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case INTERNATIONAL_BLACKLIST_TYPES.FETCH_ALL:
            return action.payload || [];
        case INTERNATIONAL_BLACKLIST_TYPES.FETCH:
            return state.map(internationalBlackList => (action.payload !== undefined && internationalBlackList.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: internationalBlackList);
        case INTERNATIONAL_BLACKLIST_TYPES.CREATE: 
            return state.concat(action.payload || []);
        case INTERNATIONAL_BLACKLIST_TYPES.UPDATE:
            return state.map(internationalBlackList => (action.payload !== undefined && internationalBlackList.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : internationalBlackList);
        case INTERNATIONAL_BLACKLIST_TYPES.DELETE:
            return state.filter(internationalBlackList => action.payload !== undefined && internationalBlackList.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}