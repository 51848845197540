import axios from "axios";
import * as React from "react";
import { connect } from "react-redux";
import FileUploadSelectInput from "src/containers/FileUpload/FileUploadSelectInput";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import DocumentIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@material-ui/core";

import {
  deletehAcquaintanceshipAff,
  fetchAcquaintanceshipAff,
  updateAcquaintanceshipAff,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  debtorsValidation,
  jsonToStakeHolderArray,
  resetError,
  stakeHolderArrayToJson,
  secondaryIncomeMonthlyActivity,
  successHandler,
  translations,
  salaryStructures,
  affiliationPurpose,
  paymentMethodsLeads,
  economicSectors,
  titleCaseHandler,
  filesGeneration,
} from "../../../utils";
import {
  API_KEY,
  deletehAcquaintanceshipAff as deleteMA,
  fetchAcquaintanceshipAff as fetchMA,
  updateAcquaintanceshipAff as updateMA,
} from "../../../utils/axios";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
import {
  AutocompleteField,
  TextFieldComponent,
  NumericTextField,
  CheckboxField,
  DateField,
} from "../../../components/SharedComponents/Fields";
import { dateFormat } from "src/components/SharedComponents/CustomHooks";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

class View extends React.Component {
  state = {
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    debtor: [],
    debtorError: "",
    salaryStructure: null,
    affiliationPurpose: null,
    wayToPay: null,
    initialDeposit: "",
    amountOfFees: "",
    amountToSave: "",
    salaryStructureError: "",
    affiliationPurposeError: "",
    wayToPayError: "",
    initialDepositError: "",
    amountOfFeesError: "",
    amountToSaveError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    MA: "",
    title: "",
    titleError: "",
    status: "pending_signature",
    uniqueId: "",
    economicSector: null,
    economicSectorError: "",
    averageIncome: "",
    averageIncomeError: "",
    participant: null,
    hasSecondaryIncome: false,
    secondaryIncome: "N/A",
    secondaryIncomeActivity: "",
    hasSecondCitizenship: false,
    secondCitizenship: "",
    secondCitizenshipId: "",
    isPep: false,
    pepPosition: "",
    pepOcupationDate: null,
    pepWorkInstitution: "",
  };
  constructor(props) {
    super(props);

    // creditor
    this.handleAddCreditor = this.handleAddCreditor.bind(this);
    this.handleDeleteCreditor = this.handleDeleteCreditor.bind(this);

    // client
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // financial Accounts
    this.handleAddBankAccount = this.handleAddBankAccount.bind(this);
    this.handleDeleteBankAccount = this.handleDeleteBankAccount.bind(this);

    // stakeholder
    this.handleAddStakeHolder = this.handleAddStakeHolder.bind(this);
    this.handleDeleteStakeHolder = this.handleDeleteStakeHolder.bind(this);

    // core events
    this.generateFile = this.generateFile.bind(this);
    this.changeStatus = this.changeStatus.bind(this);

    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }
  handleAddStakeHolder(stakeholder) {
    this.setState((prevState) => ({
      familyMembersInfo: [...prevState.familyMembersInfo, stakeholder],
    }));
  }
  handleDeleteStakeHolder(index) {
    this.setState((prevState) => ({
      familyMembersInfo: prevState.familyMembersInfo.filter(
        (stakeholder, i) => i !== index
      ),
    }));
  }
  handleAddCreditor(id) {
    this.setState({ creditor: [id] });
  }
  handleDeleteCreditor(id) {
    this.setState({ creditor: [] });
  }
  handleAddClient(id) {
    this.setState({ debtor: [id] });
  }
  handleDeleteClient(id) {
    this.setState({ debtor: [] });
  }

  handleAddBankAccount(id) {
    const found = this.state.financialAccounts.find((faId) => faId === id);

    if (!found) {
      this.setState((prevState) => ({
        financialAccounts: [...prevState.financialAccounts, id],
      }));
    }
  }
  handleDeleteBankAccount(id) {
    this.setState((prevState) => ({
      financialAccounts: prevState.financialAccounts.filter(
        (faId) => faId !== id
      ),
    }));
  }

  handleCheckBoxChange(key) {
    return (event) => {
      switch (key) {
        case "hasSecondaryIncome":
          this.setState({
            [key]: event.target.checked,
            pepPosition: "",
            pepOcupationDate: null,
            pepWorkInstitution: "",
          });
          break;
        case "hasSecondCitizenship":
          this.setState({
            [key]: event.target.checked,
            secondCitizenship: "",
            secondCitizenshipId: "",
          });
          break;
        case "isPep":
          this.setState({
            [key]: event.target.checked,
            secondaryIncome: "N/A",
            secondaryIncomeActivity: "",
          });
        default:
          break;
      }
    };
  }

  async generateFile() {
    this.setState({ loading: true });
    if (
      await debtorsValidation(
        this,
        this.props.user.token,
        this.state.debtor,
        false
      )
    ) {
      const url = `/lending/acquaintanceship-affiliate-contracts/${this.state.uniqueId}/print`;
      await filesGeneration(url, this.props.user.token);
      this.setState({ loading: false });
    }
  }
  changeStatus(status) {
    return () => {
      this.setState({ status });
    };
  }

  componentDidMount() {
    fetchMA(this.props.user.token, this.props.id)
      .then((res) => {
        const contract = res.data;
        const familyMembersInfo = jsonToStakeHolderArray(
          contract.familyMembersInfo || {}
        );

        this.setState({
          status: contract.status || "",
          contractDate: contract.contractDate || "",
          debtor: contract.debtor ? [contract.debtor] : [],
          salaryStructure: contract.salaryStructure || null,
          affiliationPurpose: contract.affiliationPurpose || null,
          wayToPay: contract.wayToPay || null,
          initialDeposit: contract.initialDeposit || "",
          amountOfFees: contract.amountOfFees || "",
          amountToSave: contract.amountToSave || "",
          economicSector: contract.economicSector || null,
          hasSecondaryIncome: contract.hasSecondaryIncome || false,
          secondaryIncome: contract.secondaryIncome || "N/A",
          secondaryIncomeActivity: contract.secondaryIncomeActivity || null,
          hasSecondCitizenship: contract.hasSecondCitizenship || false,
          secondCitizenship: contract.secondCitizenship || "",
          secondCitizenshipId: contract.secondCitizenshipId || "",
          isPep: contract.isPep || false,
          pepPosition: contract.pepPosition || "",
          pepOcupationDate: contract.pepOcupationDate || null,
          pepWorkInstitution: contract.pepWorkInstitution || "",
          dialog: false,
          dialogTitle: "",
          loading: false,
          MA: `lending/acquaintanceship-affiliate-contracts/${contract.uniqueId}/upload_attachment/`,
          title: contract.title || "",
          uniqueId: contract.uniqueId || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  handleCurrencyInputChange(key) {
    return (event, maskedValue = "", floatValue = "") => {
      this.setState({ [key]: floatValue });
    };
  }
  handleChange(key, event, value) {
    this.setState({
      [key]: value,
      [key + "Error"]: "",
    });
  }
  handleSelectChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.value });
    };
  }

  getFiltersOptions = (option) => {
    return option ? option : [];
  };

  handleSubmit(event) {
    event.preventDefault();
    resetError(this);
    const { state } = this;
    const contract = {
      contractDate: state.contractDate,
      debtor: state.debtor[0] || "",
      title: state.title,
      // salaryStructure: state.salaryStructure,
      affiliationPurpose:
        state.affiliationPurpose?.value || state.affiliationPurpose || null,
      wayToPay: state.wayToPay?.value || state.wayToPay || null,
      economicSector:
        state.economicSector?.value || state.economicSector || null,
      initialDeposit: state.initialDeposit || 0,
      amountOfFees: state.amountOfFees || 0,
      amountToSave: state.amountToSave || 0,
      hasSecondaryIncome: state.hasSecondaryIncome || false,
      secondaryIncome:
        state.secondaryIncome?.value || state.secondaryIncome || "N/A",
      secondaryIncomeActivity:
        state.secondaryIncomeActivity?.value ||
        state.secondaryIncomeActivity ||
        null,
      hasSecondCitizenship: state.hasSecondCitizenship || false,
      secondCitizenship: state.secondCitizenship || "",
      secondCitizenshipId: state.secondCitizenshipId || "",
      isPep: state.isPep || false,
      pepPosition: state.pepPosition || "",
      pepOcupationDate: dateFormat(state.pepOcupationDate) || null,
      pepWorkInstitution: state.pepWorkInstitution || "",
    };

    this.setState({ loading: true });

    updateMA(this.props.user.token, this.state.uniqueId, contract)
      .then((res) => {
        this.props.updateAcquaintanceshipAff(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, translations.MA);
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteMA(this.props.user.token, this.props.id)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deletehAcquaintanceshipAff(this.props.id);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  handleSelectValue = (value, options, property) => {
    // handle logic of multi select
    let result = value;
    if (typeof result !== "object") {
      result = options.find((option) => option[property] === value);
    }
    if (Array.isArray(value)) {
      const checkIfContainsObject = value.find(
        (item) => typeof item === "object"
      );
      if (!checkIfContainsObject) {
        result = options.filter((option) => value.includes(option[property]));
      }
    }
    return result;
  };
  handleDialogClose() {
    this.setState({ dialog: false });
  }
  render() {
    const { classes } = this.props;
    return (
      <form
        onSubmit={this.handleSubmit}
        noValidate={true}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <FileUploadSelectInput
            contractId={this.state.uniqueId}
            url={this.state.MA}
            description="ACQUA"
          />
          <Typography variant="h4" className={classes.title}>
            {translations.MA}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={`${classes.greenButton} generate-print-document`}
                onClick={this.generateFile}
                style={{ marginRight: 10, marginTop: 25 }}
              >
                <DocumentIcon />
                &nbsp;
                {translations.GENERATE_DOCUMENT}
              </Button>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="status" shrink={true}>
                  {translations.STATUS}
                </InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                  required={true}
                  inputProps={{
                    id: "status",
                    name: "status",
                  }}
                >
                  <MenuItem value="pending_signature">
                    {translations.PENDING_SIGNATURE}
                  </MenuItem>
                  <MenuItem value="completed">
                    {translations.COMPLETED}
                  </MenuItem>
                  <MenuItem value="archived">{translations.ARCHIVED}</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={(e) =>
                    this.handleChange("title", e, e.target.value)
                  }
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={(e) =>
                    this.handleChange("contractDate", e, e.target.value)
                  }
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.PARTNERS}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtor}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                  handleSetParticipant={(data) =>
                    this.setState({ participant: data })
                  }
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>
              <br />
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {"INFORMACIONES LABORALES / FINANCIERAS"}
              </Typography>
              <Grid
                container
                item
                xs={12}
                spacing={3}
                style={{ padding: ".5rem 0rem" }}
              >
                <AutocompleteField
                  xs={4}
                  fieldLabel={`Sector económico`}
                  fieldID="leads-field-day-type"
                  fieldInnerLabel="Seleccione un sector"
                  value={
                    this.handleSelectValue(
                      this.state.economicSector,
                      economicSectors,
                      "value"
                    ) || null
                  }
                  handleChange={(e, value) =>
                    this.handleChange("economicSector", e, value)
                  }
                  getOptionLabel={(option) => option.value}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  options={this.getFiltersOptions(economicSectors)}
                  error={this.state.economicSectorError !== ""}
                  helperText={this.state.economicSectorError}
                  multiple={false}
                  // disabled={!this.state.participant?.workProfileObj}
                />
                {/* <AutocompleteField
                                    xs={4}
                                    fieldLabel= {`Estructura salarial`}
                                    fieldID="leads-field-day-type"
                                    fieldInnerLabel="Seleccione tipo de jornada"
                                    value={
                                        this.handleSelectValue(this.state.salaryStructure, salaryStructures, 'value') || null
                                    }
                                    handleChange={(e, value) => this.handleChange('salaryStructure', e, value)}
                                    getOptionLabel={(option) => option.label }
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    options={this.getFiltersOptions(salaryStructures)}
                                    error={this.state.salaryStructureError !== ''}
                                    helperText={this.state.salaryStructureError}
                                    multiple={false}
                                /> */}
                {/* {
                                    this.state.participant?.workInstitutionRegistry === "000000000" &&
                                    <NumericTextField
                                        xs={4}
                                        fullWidth
                                        fieldLabel={`Ingreso promedio`}
                                        fieldID="leads-field-averageIncome"
                                        placeholder="Ej: 34,000.00"
                                        value={this.state.averageIncome || ''}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        // isAllowed={({ value }) => value >= 100}
                                        onChange={(e, value) => this.handleChange('averageIncome', e, e.value)}
                                        error={this.state.averageIncomeError !== ''}
                                        helperText={this.state.averageIncomeError}
                                    />
                                } */}

                <AutocompleteField
                  xs={4}
                  fieldLabel={`Propósito de afiliación`}
                  fieldID="leads-field-affiliationPurpose"
                  fieldInnerLabel="Seleccione un propósito"
                  value={
                    this.handleSelectValue(
                      this.state.affiliationPurpose,
                      affiliationPurpose,
                      "value"
                    ) || null
                  }
                  handleChange={(e, value) =>
                    this.handleChange("affiliationPurpose", e, value)
                  }
                  options={this.getFiltersOptions(affiliationPurpose)?.filter(
                    (ap) => ap.value !== "financial_certificate"
                  )}
                  getOptionLabel={(option) => option.label}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  error={this.state.affiliationPurposeError !== ""}
                  helperText={this.state.affiliationPurposeError}
                  multiple={false}
                  limitTags={1}
                  // disabled={!this.state.participant?.workProfileObj}
                />
                {/* AGREGAR PRODCUTOS FINANCIEROS */}
                <AutocompleteField
                  xs={4}
                  fieldLabel={`Métodos de pago`}
                  fieldID="leads-field-wayToPay"
                  fieldInnerLabel="Seleccione un método de pago"
                  value={
                    this.handleSelectValue(
                      this.state.wayToPay,
                      paymentMethodsLeads,
                      "value"
                    ) || null
                  }
                  handleChange={(e, value) =>
                    this.handleChange("wayToPay", e, value)
                  }
                  options={this.getFiltersOptions(paymentMethodsLeads)}
                  getOptionLabel={(option) => option.label}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  error={this.state.wayToPayError !== ""}
                  helperText={this.state.wayToPayError}
                  multiple={false}
                  // disabled={!this.state.participant?.workProfileObj}
                />

                <NumericTextField
                  xs={4}
                  fullWidth
                  fieldLabel={`Depósito inicial`}
                  fieldID="leads-field-initialDeposit"
                  placeholder="Ej: 1,500.00"
                  value={this.state.initialDeposit || ""}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  // isAllowed={({ value }) => value >= 100}
                  onChange={(e, value) =>
                    this.handleChange("initialDeposit", e, e.value)
                  }
                  error={this.state.initialDepositError !== ""}
                  helperText={this.state.initialDepositError}
                  // disabled={!this.state.participant?.workProfileObj}
                />

                {this.state.wayToPay?.value === "payroll_deduction" ||
                this.state.wayToPay === "payroll_deduction" ? (
                  <>
                    <NumericTextField
                      xs={4}
                      fullWidth
                      fieldLabel={`Cuota de ahorro`}
                      fieldID="leads-field-amountOfFees"
                      // placeholder="Ej: 3,200.50"
                      placeholder="Ej: 10"
                      value={this.state.amountOfFees || ""}
                      decimalScale={0}
                      // decimalScale={2}
                      // fixedDecimalScale={true}
                      // thousandSeparator={true}
                      onChange={(e, value) =>
                        this.handleChange("amountOfFees", e, e.value)
                      }
                      error={this.state.amountOfFeesError !== ""}
                      helperText={this.state.amountOfFeesError}
                      // disabled={!this.state.participant?.workProfileObj}
                    />

                    <NumericTextField
                      xs={4}
                      fullWidth
                      fieldLabel={`Cantidad de ahorro`}
                      fieldID="leads-field-amountToSave"
                      placeholder="Ej: $50,000.20"
                      decimalScale={2}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      value={this.state.amountToSave || ""}
                      onChange={(e, value) =>
                        this.handleChange("amountToSave", e, e.value)
                      }
                      error={this.state.amountToSaveError !== ""}
                      helperText={this.state.amountToSaveError}
                      // disabled={!this.state.participant?.workProfileObj}
                    />
                  </>
                ) : null}
              </Grid>
              <br />
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {"INFORMACIONES CUMPLIMIENTO"}
              </Typography>
              <Grid
                container
                item
                xs={12}
                spacing={3}
                style={{ padding: ".5rem 0rem" }}
              >
                {/* Nacionalidad Secundaria */}
                <CheckboxField
                  xs={12}
                  label={`Tiene otra nacionalidad`}
                  alignItems="flex-end"
                  check={this.state.hasSecondCitizenship || false}
                  handleChange={this.handleCheckBoxChange(
                    "hasSecondCitizenship"
                  )}
                  color="primary"
                  textColor="#43A047"
                />
                {this.state.hasSecondCitizenship && (
                  <>
                    <TextFieldComponent
                      xs={6}
                      fullWidth
                      fieldLabel={`Segunda Nacionalidad`}
                      fieldID="leads-field-secondCitizenship"
                      placeholder="Ej: Venezolano"
                      value={this.state.secondCitizenship || ""}
                      onChange={(e) =>
                        this.handleChange(
                          "secondCitizenship",
                          e,
                          e.target.value
                        )
                      }
                    />
                    <TextFieldComponent
                      xs={6}
                      fullWidth
                      fieldLabel={`ID Segunda Nacionalidad`}
                      fieldID="leads-field-secondCitizenshipId"
                      placeholder="Ej: RT0939019394"
                      value={this.state.secondCitizenshipId || ""}
                      onChange={(e) =>
                        this.handleChange(
                          "secondCitizenshipId",
                          e,
                          e.target.value
                        )
                      }
                    />
                  </>
                )}

                <CheckboxField
                  xs={12}
                  label={`Tiene ingreso secundario`}
                  alignItems="flex-end"
                  check={this.state.hasSecondaryIncome || false}
                  handleChange={this.handleCheckBoxChange("hasSecondaryIncome")}
                  color="primary"
                  textColor="#43A047"
                />

                {this.state.hasSecondaryIncome && (
                  <>
                    <AutocompleteField
                      xs={6}
                      fieldLabel={`Actividad Económica Secundaria`}
                      fieldID="leads-field-secondaryIncomeActivity"
                      fieldInnerLabel="Seleccione una actividad"
                      value={
                        this.handleSelectValue(
                          this.state.secondaryIncomeActivity,
                          economicSectors,
                          "value"
                        ) || null
                      }
                      handleChange={(e, value) =>
                        this.handleChange("secondaryIncomeActivity", e, value)
                      }
                      options={this.getFiltersOptions(economicSectors)}
                      getOptionLabel={(option) => option.value}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      multiple={false}
                    />
                    <AutocompleteField
                      xs={6}
                      fieldLabel={`Ingresos Actividad Económica Secundaria`}
                      fieldID="leads-field-secondaryIncome"
                      fieldInnerLabel="Seleccione una opción"
                      value={
                        this.handleSelectValue(
                          this.state.secondaryIncome,
                          secondaryIncomeMonthlyActivity,
                          "value"
                        ) || null
                      }
                      handleChange={(e, value) =>
                        this.handleChange("secondaryIncome", e, value)
                      }
                      options={this.getFiltersOptions(
                        secondaryIncomeMonthlyActivity
                      )}
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      multiple={false}
                    />
                  </>
                )}

                <CheckboxField
                  xs={12}
                  label={`¿Es usted, o alguien relacionado, a una persona políticamente expuesta y/o figura pública?`}
                  alignItems="flex-end"
                  check={this.state.isPep || false}
                  handleChange={this.handleCheckBoxChange("isPep")}
                  color="primary"
                  textColor="#43A047"
                />
                {this.state.isPep && (
                  <>
                    <TextFieldComponent
                      xs={6}
                      fullWidth
                      fieldLabel={`Cargo, rango o posición`}
                      fieldID="pep-rank-position"
                      placeholder="Ej: Almirante de Flota"
                      value={this.state.pepPosition || ""}
                      onChange={(e, value) =>
                        this.handleChange("pepPosition", e, e.target.value)
                      }
                    />
                    <DateField
                      xs={6}
                      fullWidth
                      fieldLabel={`Fecha de ocupación cargo, rango o posición`}
                      dateFieldID="pep-rank-date"
                      dateFieldLabel={`Ej: 20/09/${new Date().getFullYear() -
                        10}`}
                      value={this.state.pepOcupationDate || null}
                      handleChange={(e, value) =>
                        this.handleChange("pepOcupationDate", e, e)
                      }
                    />
                    <TextFieldComponent
                      xs={6}
                      fullWidth
                      fieldLabel={`Institución a la que pertenece`}
                      fieldID="pep-rank-institution"
                      placeholder="Ej: Marina"
                      value={this.state.pepWorkInstitution || ""}
                      onChange={(e, value) =>
                        this.handleChange(
                          "pepWorkInstitution",
                          e,
                          e.target.value
                        )
                      }
                    />
                  </>
                )}
              </Grid>

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    membershipAcquaintanceship: state.membershipAcquaintanceship,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deletehAcquaintanceshipAff,
    fetchAcquaintanceshipAff,
    updateAcquaintanceshipAff,
  })(View)
);
