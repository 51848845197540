import * as React from "react";
import { connect } from "react-redux";
import { translations } from "src/utils";

import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { blue, green, grey, orange, red } from "@material-ui/core/colors";
import { createStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FiberManualRecordIcon from "@material-ui/icons/Brightness1";
import FilterListIcon from "@material-ui/icons/FilterList";
import TuneIcon from "@material-ui/icons/Tune";

import FilterDialog from "../../../components/FilterDialog";
import QuotationSearchFilter from "../QuotationSearchFilter";

const styles: any = createStyles({
  dateLabel: {
    bottom: "40%",
    color: grey[400],
    fontSize: "small",
    position: "absolute",
    right: 0,
    textDecoration: "underline",
  },
  filterButton: {
    width: "15%",
  },
  paginationGroup: {
    border: "1px solid rgba(25,25,25,0.2)",
    bottom: 0,
    display: "flex",
    height: 50,
    justifyContent: "center",
    justifyItems: "center",
    position: "absolute",
    textAlign: "center",
    width: 278,
  },
  root: {
    borderRightColor: "rgba(25,25,25,0.2)",
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    bottom: 51,
    boxSizing: "border-box",
    overflowY: "auto",
    padding: 10,
    paddingLeft: 15,
    position: "absolute",
    top: 65,
    width: 280,
  },
  textField: {
    width: "70%",
  },
});

interface IQuotationSearchSectionProps {
  classes: {
    [key: string]: string;
  };

  loading: boolean;
  quotations: IQuotation[];
  user: IUser;
  pagination: boolean;
  client?: string;
  handleClick(id: string): void;
  fetchData(params: IQuotationParams): void;
  advanceSearch(queryParameters: IQuotationParams): void;
}
interface IQuotationSearchSectionState {
  advanceFilter: boolean;
  clients: IParticipant[];
  filterField: string;
  limit: number;
  offset: number;
  open: boolean;
  searchValue: string;
  searching: boolean;
}

class SearchSection extends React.Component<
  IQuotationSearchSectionProps,
  IQuotationSearchSectionState
> {
  public state: IQuotationSearchSectionState = {
    advanceFilter: false,
    clients: [],
    filterField: "customerName",
    limit: 20,
    offset: 0,
    open: false,
    searchValue: "",
    searching: false,
  };
  constructor(props: IQuotationSearchSectionProps) {
    super(props);

    this.openDialog = this.openDialog.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.openFilterDialog = this.openFilterDialog.bind(this);
    this.closeFilterDialog = this.closeFilterDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.mapFunction = this.mapFunction.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.search = this.search.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
  }
  public openDialog() {
    this.setState({ open: true });
  }

  public handleDialogClose(value: string) {
    this.setState({
      filterField: value,
      open: false,
    });
  }
  public openFilterDialog() {
    this.setState({ advanceFilter: true });
  }
  public closeFilterDialog() {
    this.setState({ advanceFilter: false });
  }
  public search(params: IQuotationParams) {
    this.props.advanceSearch(params);
  }
  public handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!this.state.searching || event.currentTarget.value === "") {
      const params: IQuotationParams = {
        limit: this.state.limit,
        offset: 0,
        token: this.props.user.token,
      };
      if (this.props.client) {
        params.client = this.props.client;
      }
      params[this.state.filterField] = event.currentTarget.value;
      this.setState(
        {
          offset: 0,
          searchValue: event.currentTarget.value,
          searching: true,
        },
        () => {
          this.props.fetchData(params);
          setTimeout(() => this.setState({ searching: false }), 500);
        }
      );
    } else {
      this.setState({ searchValue: event.currentTarget.value });
    }
  }

  public mapFunction(quotation: IQuotation) {
    let statusColor: string = blue[500];
    let statusText: string = translations.ON_PROCESS;

    switch (quotation.quotationStatus) {
      case "approved":
        statusColor = green[500];
        statusText = translations.APPROVED;
        break;
      case "cancelled":
        statusColor = red[500];
        statusText = translations.CANCELLED;
        break;
      case "used":
        statusColor = orange[500];
        statusText = translations.USED;
        break;
    }

    if (quotation.uniqueId === "none") {
      return (
        <ListItem
          dense={true}
          divider={true}
          key={quotation.uniqueId}
          button={true}
        >
          <ListItemText
            primary={quotation.participantName || quotation.clientName}
          />
        </ListItem>
      );
    }

    return (
      <ListItem
        title={statusText}
        dense={true}
        divider={true}
        key={quotation.uniqueId}
        button={true}
        onClick={this.selectItem(quotation.uniqueId || "")}
      >
        <FiberManualRecordIcon style={{ color: statusColor }} />
        <ListItemText
          primary={quotation.referenceCode}
          secondary={quotation.participantName || quotation.clientName}
        />
        <div style={styles.dateLabel}>{quotation.quotationDate}</div>
      </ListItem>
    );
  }
  public selectItem(quotationId: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      if (quotationId !== "none") {
        this.props.handleClick(quotationId);
      }
    };
  }
  public fetchData() {
    this.props.fetchData({ token: this.props.user.token });
  }

  public prev() {
    if (this.state.offset !== 0) {
      this.setState((prevState) => {
        const params: IQuotationParams = {
          limit: prevState.limit,
          offset: prevState.offset - 20,
          token: this.props.user.token,
        };
        params[this.state.filterField] = this.state.searchValue;

        this.props.fetchData(params);

        return {
          offset: prevState.offset - 20,
        };
      });
    }
  }

  public next() {
    this.setState((prevState) => {
      const params: IQuotationParams = {
        limit: prevState.limit,
        offset: prevState.offset + 20,
        token: this.props.user.token,
      };
      params[this.state.filterField] = this.state.searchValue;

      this.props.fetchData(params);

      return {
        offset: prevState.offset + 20,
      };
    });
  }
  public render() {
    const { classes, quotations, loading, pagination } = this.props;

    return (
      <div>
        <div className={classes.root}>
          <FilterDialog
            options={[
              { caption: translations.CLIENT, value: "customerName" },
              { caption: translations.REFERENCE, value: "referenceCode" },
            ]}
            open={this.state.open}
            onClose={this.handleDialogClose}
            selectedValue={this.state.filterField}
          />

          <QuotationSearchFilter
            search={this.search}
            token={this.props.user.token}
            open={this.state.advanceFilter}
            handleClose={this.closeFilterDialog}
          />
          <div>
            <TextField
              className={classes.textField}
              value={this.state.searchValue}
              onChange={this.handleChange}
              placeholder={translations.SEARCH}
            />
            <IconButton
              className={classes.filterButton}
              onClick={this.openDialog}
            >
              <FilterListIcon />
            </IconButton>
            <IconButton
              className={classes.filterButton}
              onClick={this.openFilterDialog}
            >
              <TuneIcon />
            </IconButton>
          </div>
          <div>
            <List>
              {loading ? (
                <ListItem>
                  <CircularProgress
                    style={{ color: green[500] }}
                    thickness={7}
                  />
                  <ListItemText primary={translations.LOADING} />
                </ListItem>
              ) : quotations.length === 0 ? (
                [{ title: translations.NOT_FOUND, uniqueId: "none" }].map(
                  this.mapFunction
                )
              ) : (
                quotations.map(this.mapFunction)
              )}
            </List>
          </div>
        </div>
        {!loading && pagination ? (
          <div className={classes.paginationGroup}>
            <IconButton onClick={this.prev}>
              <ArrowBackIcon />
            </IconButton>
            <IconButton>{this.state.offset / 20 + 1}</IconButton>
            <IconButton onClick={this.next}>
              <ArrowForwardIcon />
            </IconButton>
          </div>
        ) : null}
      </div>
    );
  }
}
function mapStateToProps(state: IState) {
  return {
    quotations: state.quotations,
    user: state.user,
  };
}
export default withStyles(styles)(connect(mapStateToProps)(SearchSection));
