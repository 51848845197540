import axios from 'axios';
import { errorHandling } from './CustomHooks';
import { debtorsValidation } from '../../utils';
import { API_KEY } from '../../utils/axios';

const documentsPrintUrls = () => {
    return {
        memebershipApplFormId: '/lending/membership-application/',
        acquaintanceFormId: '/lending/acquaintanceship-person-contracts/',
        creditReviewAuthId: '/lending/credit-review-authorization/',
        inputCertificate: '/lending/input-certification-form/',
        savingAccount: '/lending/savings-account-form/',
    }
}

export const generateDocument = async (props, document) => {
    const url = documentsPrintUrls()[document.name];
    if (url) {
        if( document.name === 'memebershipApplFormId'  && !(await debtorsValidation(this, props.token, [props.debtor], false)) ) {
            return;
        }
        props.setLoading(true);
        axios({
            headers: {
                Authorization: 'Bearer ' + props.token
            },
            method: 'get',
            responseType: "text/html",
            url: `${API_KEY}${url}${document.value}/print`
        }).then(response => {
            const file = new Blob([response.data], { type: "text/html" });
            const fileURL = URL.createObjectURL(file);
            const NewWindow = window.open();
            NewWindow.location.href = fileURL;
            props.setLoading(false);

        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }
}