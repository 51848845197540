import * as React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface IChartOfAccountsFormProps {
    classes: {
        root: string;
        formContainer: string;
        formHeader: string;
        actionsSection: string;
        saveButton: string;
    };

    user: IUser;
}

interface IChartOfAccountsFormState {
    controlAccount: string;
    title: string;
    number: number;
    description: string;
    isControlAccount: boolean;
    canRecieveTransactions: boolean;
}

/* TEST DATA */
const testData = [
    { id: 100000, account: 'Activos', group: 'Activos' },
    { id: 110000, account: 'Activos Corrientes', group: 'Activos' },
    { id: 110100, account: 'Efectivo en caja y banco', group: 'Activos' },
    { id: 110200, account: 'Cuentas por cobrar clientes', group: 'Activos' },
    { id: 110300, account: 'Otras cuentas por cobrar', group: 'Activos' },
    { id: 110400, account: 'Inventario', group: 'Activos' },
    { id: 110600, account: 'Gastos pagados por adelantado', group: 'Activos' },
    { id: 110700, account: 'Otros activos corrientes', group: 'Activos' },
    { id: 110800, account: 'Dividendos a cuentas entregado en el ejercicio', group: 'Activos' },
    { id: 120000, account: 'ACTIVOS FIJOS', group: 'Activos' },
    { id: 130100, account: 'Inversiones en depositos', group: 'Activos' },
    { id: 130200, account: 'Inversiones en acciones', group: 'Activos' },
    { id: 130300, account: 'Otras inversiones', group: 'Activos' },
    { id: 140000, account: 'Otros activos no amortizables', group: 'Activos' },
    { id: 140100, account: 'Impuesto / Renta diferido o anticipado', group: 'Activos' },
    { id: 140200, account: 'Otros activos amortizables', group: 'Activos' },
    { id: 150000, account: 'Provisiones para riesgos y gastos', group: 'Activos' },
    { id: 150100, account: 'Para cuentas incobrables', group: 'Activos' },
    { id: 150200, account: 'Otras provisiones', group: 'Activos' },

    { id: 200000, account: 'Pasivos', group: 'Pasivos' },
    { id: 210000, account: 'Acreedores a corto plazo', group: 'Pasivos' },
    { id: 210100, account: 'Prestamos', group: 'Pasivos' },
    { id: 210200, account: 'Cuentas por pagar', group: 'Pasivos' },
    { id: 210300, account: 'Impuestos por pagar', group: 'Pasivos' },
    { id: 210400, account: 'Otras cuentas', group: 'Pasivos' },
    { id: 210500, account: 'Cobros anticipados', group: 'Pasivos' },

    { id: 600000, account: 'Gastos', group: 'Gastos' },
    { id: 610000, account: 'Gastos de personal', group: 'Gastos' },
    { id: 610100, account: 'Sueldos y salarios', group: 'Gastos' },
    { id: 610200, account: 'Retribuciones complementarias', group: 'Gastos' },
    { id: 610300, account: 'Seguros', group: 'Gastos' },
    { id: 610400, account: 'otros seguros', group: 'Gastos' },
    { id: 610500, account: 'Aporte Seguridad social', group: 'Gastos' },
    { id: 610600, account: 'Aporte infotep', group: 'Gastos' },
    { id: 620000, account: 'Gastos por trabajos, suministros y servicios', group: 'Gastos' },
    { id: 620100, account: 'Honorarios profesionales(pers.fisicas)', group: 'Gastos' },
    { id: 620200, account: 'Honorarios profesionales(pers.Morales)', group: 'Gastos' },
    { id: 620300, account: 'Honorarios por servicios del exterior', group: 'Gastos' },
    { id: 620400, account: 'Honorarios por servicios del exterior(personas fisicas y morales)', group: 'Gastos' },
    { id: 620500, account: 'Seguridad, mensaj., trans.Y otros serv. (pers.fisica)', group: 'Gastos' },
    { id: 620600, account: 'Seguridad, mensaj., trans.Y otros serv. (pers.Morales)', group: 'Gastos' },
    { id: 620700, account: 'Otros gastos por trabajos, suministros y servicios', group: 'Gastos' },
]

class ChartOfAccountsForm extends React.Component<IChartOfAccountsFormProps, IChartOfAccountsFormState> {

    public state: IChartOfAccountsFormState = {
        controlAccount: '',
        title: '',
        number: 0,
        description: '',
        isControlAccount: true,
        canRecieveTransactions: false
    }

    constructor(props: IChartOfAccountsFormProps) {
        super(props);
    }

    public render() {
        const { classes } = this.props;
        const { isControlAccount, canRecieveTransactions } = this.state;
        return (
            <div className={classes.formContainer}>
                <div className={classes.formHeader}>
                    <h2>Nueva cuenta</h2>
                </div>
                <Autocomplete
                    options={testData}
                    getOptionLabel={(option) => `${option.id} - ${option.account}`}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Cuenta control" variant="outlined" />}
                />
                <TextField
                    label="Numero de cuenta"
                    type="number"
                    inputProps={{ maxLength: 6 }}
                />
                <TextField
                    label="Título"
                    rowsMax={4}
                />
                <TextField
                    label="Descripción"
                    multiline={true}
                    rowsMax={4}
                />
                <Divider/>
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={isControlAccount} name="gilad" />}
                            label="Es una cuenta control?"
                        />
                        <FormControlLabel
                            control={<Switch checked={canRecieveTransactions} name="jason" />}
                            label="Se puede recibir transacciones?"
                        />
                    </FormGroup>
                </FormControl>

                <div className={classes.actionsSection}>
                    <Button className={classes.saveButton} size="small">
                        <SaveIcon />
                        Guardar
                    </Button>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state: IState) {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(ChartOfAccountsForm)