import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  fetchResponsibleAnalysts,
  fetchInstitutions,
  fetchSpecificOrders,
} from "./../../utils/axios";
import { ExpiryStates } from "./DataListTypes";
import { isArray } from "lodash";
// import { ErrorDialog } from './Dialogs';

export const useFetchFilters = (token, loading, dialog) => {
  const [fetchedData, setData] = useState({ filterData: {}, loading: true });
  const getResponsibles = fetchResponsibleAnalysts(token);
  const getPrivateWorks = fetchInstitutions({
    token: token,
    institutionType: "private",
  });
  const getPublicWorks = fetchInstitutions({
    token: token,
    institutionType: "public",
  });
  const getBanks = fetchInstitutions({
    token: token,
    institutionType: "financial",
  });
  const getExpiryStates = ExpiryStates();
  const customOrder = fetchSpecificOrders(token);

  useEffect(() => {
    loading(true);
    setData({ filterData: fetchedData.filterData, loading: true });
    axios
      .all([
        getResponsibles,
        getPrivateWorks,
        getPublicWorks,
        getBanks,
        customOrder,
      ])
      .then(
        axios.spread((...responses) => {
          const data = {
            responsibles: responses[0].data,
            works: responses[1].data.concat(responses[2].data).map((item) => {
              return (({ abbreviation, name, uniqueId }) => ({
                abbreviation,
                name,
                uniqueId,
              }))(item);
            }), //this is to select some properties of the object
            banks: responses[3].data.map((item) => {
              return (({ abbreviation, name, uniqueId }) => ({
                abbreviation,
                name,
                uniqueId,
              }))(item);
            }), //this is to select some properties of the object.
            states: getExpiryStates,
            customOrder: responses[4].data,
          };
          setData({ filterData: data, loading: false });
          loading(false);
        })
      )
      .catch((errors) => {
        loading(false);
        errorHandling(errors, dialog);
      });
  }, []);
  return fetchedData.filterData || [];
};

// export const useHandleCheckbox = () => {

//     const [selected, setSelected] = useState([]);
//     const useHandleSelectAllRows = (event, setSelected) => {
//         if (event.target.checked) {
//           const newSelecteds = clientsData.map(item => item.uniqueId);
//           setSelected(newSelecteds);
//           return;
//         }
//         setSelected([]);
//     }
//     const useHandleSelectRow = (event, key, setSelected, selected) => {
//         const selectedIndex = selected.indexOf(key);
//         let newSelected = [];

//         if (selectedIndex === -1) {
//             newSelected = newSelected.concat(selected, key);
//         } else if (selectedIndex === 0) {
//             newSelected = newSelected.concat(selected.slice(1));
//         } else if (selectedIndex === selected.length - 1) {
//             newSelected = newSelected.concat(selected.slice(0, -1));
//         } else if (selectedIndex > 0) {
//             newSelected = newSelected.concat(
//             selected.slice(0, selectedIndex),
//             selected.slice(selectedIndex + 1),
//             );
//         }
//         setSelected(newSelected);
//     }
//     const useIsSelected = (reference) => selected.indexOf(reference) !== -1;

// }

export const useHandleSelectAllRows = (setSelected, tableData, event) => {
  if (event.target.checked) {
    //   const newSelecteds = tableData.map(item => item.uniqueId || item.id);
    const newSelecteds = tableData.map((item) => item);
    setSelected(newSelecteds);
    return;
  }
  setSelected([]);
};

export const useHandleSelectRow = (
  event,
  key,
  setSelected,
  selected,
  row,
  column
) => {
  // const selectedIndex = selected.indexOf(key);
  const selectedIndex = selected.findIndex((item) => item[column] === key);
  let newSelected = [];
  if (selectedIndex === -1) {
    // newSelected = newSelected.concat(selected, key);
    newSelected = newSelected.concat(selected, row);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }
  setSelected(newSelected);
};

// export const useIsSelected = (reference, selected) => selected.indexOf(reference) !== -1;
export const useIsSelected = (val, column, selected) =>
  selected.findIndex((item) => item[column] === val) !== -1;

export const dateFormat = (date) => {
  if (date) {
    const [month, day, year] = date.toLocaleDateString().split("/");
    return year + "-" + month + "-" + day;
  }
};

export const dateFormatApprobation = (date) => {
  if (date) {
    const [day, month, year] = date.toLocaleDateString().split("/");
    return year + "-" + month + "-" + day;
  }
};

export const errorHandling = (error, dialog) => {
  const errMsg = error?.response ? error.response.data : false;
  let msg = [];
  if (!errMsg || errMsg.length > 150) {
    msg = [
      "Ha ocurrido un error técnico, favor contactar al departamento de tecnología.",
    ];
  } else if (typeof errMsg === "object") {
    Object.keys(errMsg).forEach((key) => {
      if (Array.isArray(errMsg[key])) {
        msg = [...msg, ...errMsg[key]];
      } else {
        msg.push(errMsg[key]);
      }
    });
  } else {
    msg = [errMsg];
  }
  dialog("error-dialog", msg);
};

export const useIsMounted = () => {
  const ref = React.useRef(false);
  const [, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    ref.current = true;
    setIsMounted(true);
    return () => (ref.current = false);
  }, []);
  return () => ref.current;
};

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1
      .toUpperCase()
      .replace("-", "")
      .replace("_", "");
  });
};

export const handleToggleDialog = (setUpdate, currentDialogs, dialog, data) => {
  // const selectedDialog = dialog ? dialog : '';
  const dialogData = data && (data.uniqueId || data.id) ? data : null;
  // const obj = {name: selectedDialog, data: dialogData};
  const obj = {
    [dialog]: {
      open: !Boolean(currentDialogs[dialog]?.open),
      data: dialogData,
    },
    // name: selectedDialog, data: dialogData
  };
  setUpdate({ ...currentDialogs, ...obj });
};

export const handleToggleSimpleDialog = (setUpdate, dialog, msg, data) => {
  const obj = { name: dialog ? dialog : "", msg: msg, data: data };
  setUpdate({ ...obj });
};

export const validateRequiredFields = (
  fields,
  requiredFields,
  errorFields,
  setErrorFields,
  setError,
  type,
  fieldToCheck,
  value
) => {
  // validate fields errors
  let newErrorState = { ...errorFields };
  const fieldCheck = (key, value) => {
    newErrorState[key] =
      value === "" ||
      value === null ||
      value === undefined ||
      Object.keys(value).length === 0
        ? true
        : false;
  };
  if (type === "all") {
    requiredFields.forEach((key) => {
      fieldCheck(key, fields[key]);
    });
  } else if (type === "single" && requiredFields.includes(fieldToCheck)) {
    fieldCheck(fieldToCheck, value);
  }
  const hasErr = Object.keys(newErrorState).find((item) => newErrorState[item]);
  setError(Boolean(hasErr));
  setErrorFields({ ...newErrorState });
  return Boolean(hasErr);
};

export const makeid = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getFormatedStringFromDays = (numberOfDays) => {
  const years = Math.floor(numberOfDays / 365);
  const months = Math.floor((numberOfDays % 365) / 30);
  const days = Math.floor((numberOfDays % 365) % 30);

  const yearsDisplay =
    years > 0 ? years + (years == 1 ? " año, " : " años, ") : "";
  const monthsDisplay =
    months > 0 ? months + (months == 1 ? " mes, " : " meses, ") : "";
  const daysDisplay = days > 0 ? days + (days == 1 ? " día" : " días") : "";
  return yearsDisplay + monthsDisplay + daysDisplay;
};
