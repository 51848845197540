import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { ToolbarComponent } from './../../../components/SharedComponents/Toolbar';
import { PaymentTable } from './../Tables';
import { PaymentDialog, ConfirmDialogComponent } from './../Dialogs';
import { SuccessDialog, ErrorDialog, SimpleConfirmDialog} from '../../../components/SharedComponents/Dialogs';
import { Filters } from './../Filters';
import {
    fetchPayments, updatePayment, deletePayment, fetchParticipants,
    fetchCreditors, createPayment, createPaymentVoucher, fetchPaymentReport,
    fetchPaymentReportCsv, fetchInstitutions, fetchResponsibleAnalysts, fetchCreditorAccounts
} from './../../../utils/axios';
import { PaymentTypes } from '../../../components/SharedComponents/DataListTypes';
import LoadingDialog from '../../../components/SharedComponents/LoadingScreen';
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';
import axios from 'axios';
import './../index.css';

function PaymentView(props) {

    const limitRequest = {limit: 25};
    const dialogTypes = ['payment','payment-modify'];
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({name: '', data: null});
    const [simpleDialog, setSimpleDialog] = useState({name: '', data: null, callback: null});
    const [payments, setPayments] = useState([]);
    const [creditors, setCreditors] = useState([]);
    const [payTypes, setPayTypes] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [institutions, setInstitutions] = useState({});
    const [responsibles, setResponsibles] = useState([]);
    const [selected, setSelected] = useState([]);

    const handleToggleDialog = (dialog, data) => {
        const selectedDialog = dialog ? dialog : '';
        const dialogData = (data && (data.uniqueId || data.id)) ? data : null;
        const obj = {name: selectedDialog, data: dialogData};
        setDialog({...obj});
    }

    const handleToggleSimpleDialog = (dialog, msg, data) => {
        const obj = {name:  dialog ? dialog : '', msg: msg, data: data};
        setSimpleDialog({...obj});
    }

    const getPayments = (data) => {
        setLoading(true);
        const endpoint = fetchPayments({...data, token: props.user.token});
        endpoint.then(response => {
            const pTypes = PaymentTypes();
            const paymentsInfo = response.data.results ? response.data.results : response.data;
            const formattedData = paymentsInfo.map(pi => {
                const paymentType = pTypes.find(pt => pt.value === pi.paymentType);
                const obj = {
                    ...pi,
                    creditor: pi.creditorName || '---',
                    creditorId: pi.creditor ||  '---',
                    paymentType: paymentType ? paymentType.name : '---',
                }
                return obj;
            });
            setPayments(formattedData);
            setLoading(false);
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const uploadPaymentVoucher = (data) => {
        let formData = new FormData();
        formData.append("file", data.file);
        const endpoint = createPaymentVoucher(props.user.token, data.uniqueId, formData);
        endpoint.then(() => {
            getPayments(selectedFilters);
            setLoading(false);
            handleToggleSimpleDialog('success-dialog');
            handleToggleDialog(undefined);
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const createUpdatePayment = (data, action) => {
        setLoading(true);
        const endpoint = action === 'update' ? updatePayment : createPayment;
        endpoint({...data, token: props.user.token}).then((response) => {
            if(data.file) {
                uploadPaymentVoucher({...data, uniqueId: response.data.uniqueId});
            }else {
                getPayments(selectedFilters);
                setLoading(false);
                handleToggleSimpleDialog('success-dialog');
                handleToggleDialog(undefined);
            }
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const deleteSinglePayment = (payment) => {
        setLoading(true);
        const endpoint = deletePayment(props.user.token, payment.uniqueId);
        endpoint.then(() => {
            getPayments(selectedFilters);
            setLoading(false);
            handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }
    const handleDeleteMultiplePayment = () => {
        const endpoints = selected.map(item => deletePayment(props.user.token, item.uniqueId));
        axios.all(endpoints).then(() => {
            getPayments(selectedFilters);
            setSelected([]);
            setLoading(false);
            handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }
    const handleSelected = (selection) => {
        setSelected(selection);
    }
    const getInstitutions = () => {
        setLoading(true);
        const getPrivateWorks = fetchInstitutions({ token: props.user.token, institutionType: 'private' });
        const getPublicWorks = fetchInstitutions({ token: props.user.token, institutionType: 'public' });
        const getBanks = fetchCreditorAccounts({ token: props.user.token });
        axios.all([getPrivateWorks, getPublicWorks, getBanks]).then(axios.spread((...responses) => {
            const institutions = {
                works: (responses[0].data.concat(responses[1].data, responses[2].data)).map(item => {
                    return {
                        abbreviation: item.abbreviation,
                        name: item.name,
                        fullName: item.abbreviation + ": " + item.name,
                        uniqueId: item.uniqueId,
                        rohiId: item.rohiId
                    }
                }), //this is to select some properties of the object
                banks: (responses[2].data).map(item => {
                    return {
                        name: item.name,
                        accountNumber: item.accountNumber,
                    }
                }), //this is to select some properties of the object.
            }
            setInstitutions(institutions);
            setLoading(false);
        })).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }
    const getCreditors = () => {
        setLoading(true);
        const endpoint = fetchCreditors({token: props.user.token});
        endpoint.then(response => {
            const data = response.data.map(item => {
                const creditorName = item.type === 'legal' ?
                    item.institutionDetail.abbreviation : item.participantDetail.fullName
                const detail = item.participantDetail;
                return {
                    name: creditorName ? creditorName : '--',
                    identificationNumber: detail ? detail.identificationNumber : '--',
                    uniqueId: item.uniqueId
                }
            });
            setCreditors(data);
            setLoading(false);
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }
    const generateReport = () => {
            setLoading(true);
            const endpoint = fetchPaymentReport(props.user.token, payments);
            endpoint.then(response => {
                const file = new Blob([response.data], { type: "text/html" });
                const fileURL = URL.createObjectURL(file);
                const NewWindow = window.open();
                NewWindow.location.href = fileURL;
                setLoading(false);
            }).catch(errors => {
                setLoading(false);
                errorHandling(errors, handleToggleSimpleDialog);
            });
    }
    const getResponsibles = () => {
        const endpoint = fetchResponsibleAnalysts(props.user.token);
        endpoint.then(response => {
            const data = response.data.map(item => {
                return {
                    fullName: item.fullName,
                    uniqueId: item.uniqueId,
                }
            });
            setResponsibles(data);
        }).catch(errors => {
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }
    const generateReportCsv = () => {
            setLoading(true);
            const endpoint = fetchPaymentReportCsv(props.user.token, payments);
            endpoint.then(response => {
                const file = new Blob([response.data], { type: "text/csv" });
                setLoading(true);
                saveAs(file, `Reporte_pagos.csv`);

                setLoading(false);
            }).catch(errors => {
                setLoading(false);
                errorHandling(errors, handleToggleSimpleDialog);
            });
    }

    const applyFilters = (fields) => {
        let data = fields;
        if(Object.keys(fields).length === 0){
            data = limitRequest;
        }
        getPayments(data);
        setSelectedFilters({...data});
    }

    useEffect(() => {
        const pTypes = PaymentTypes();
        setPayTypes(pTypes);
        getInstitutions();
        getCreditors();
        getResponsibles();
        getPayments(limitRequest);
    }, []);

  return(
    <div style={{padding:'20px 20px 10% 20px'}}>
        <LoadingDialog open={loading}/>
        <ToolbarComponent
            typeVariant="h6"
            typeClass="title-text"
            toolbarTitle="Gestión de pagos"
            btnVariant="contained"
            toolbarActions={
                <Button
                    variant="contained"
                    className={"action-method-btn"}
                    size="small"
                    disableElevation
                    onClick={handleToggleDialog.bind(this, 'payment')}
                >
                    <Add style={{fontSize:20, color:'#fff', marginRight:'.2rem'}} />
                    realizar un pago
                </Button>
            }
        />
        <div style={{padding:'1%', border:'1px solid #EEEEEE', borderTop:'none'}}>
            <Filters
                handleToggleSimpleDialog={handleToggleSimpleDialog}
                applyFilters={applyFilters}
                listData={{
                    payTypes: payTypes, creditors: creditors,
                    works: institutions.works, banks: institutions.banks,
                    responsibles
                }}
            />
            <div style={{margin:'10px 0'}}></div>
            <PaymentTable
                data={payments}
                user={props.user}
                handleToggleDialog={handleToggleDialog}
                handleToggleSimpleDialog={handleToggleSimpleDialog}
                handleDelete={deleteSinglePayment}
                payTypes={payTypes}
                creditors={creditors}
                handleReport={generateReport}
                handleReportCsv={generateReportCsv}
                handleDeleteMultiplePayment={handleDeleteMultiplePayment}
                selected={selected}
                setSelected={setSelected}
                handleSelected={handleSelected}
                setLoading={setLoading}
            />
        </div>
        {
            dialogTypes.includes(dialog.name) ?
                <PaymentDialog
                    open={true}
                    handleToggleDialog={handleToggleDialog}
                    handleSimpleDialog={handleToggleSimpleDialog}
                    modify={dialog.name === 'payment-modify' ? true : false}
                    data={dialog.data}
                    listData={{payTypes: payTypes, creditors: creditors}}
                    handlePayment={createUpdatePayment}
                    setLoading={setLoading}
                    token={props.user.token}
                /> : null
        }
        {
            simpleDialog.name === 'confirm-dialog' ?
            <ConfirmDialogComponent
                    open={true}
                    data={simpleDialog}
                    handleToggle={handleToggleSimpleDialog}
                /> : null
        }
        {
            simpleDialog.name === 'simple-dialog' ?
                <SimpleConfirmDialog
                    open={true}
                    handleToggle={handleToggleSimpleDialog}
                    data={simpleDialog.msg}
                /> : null
        }
        {
            simpleDialog.name === 'success-dialog' ?
                <SuccessDialog
                    open={true}
                    handleToggle={handleToggleSimpleDialog}
                />: null
        }
        {
            simpleDialog.name === 'error-dialog' ?
                <ErrorDialog
                    open={true}
                    handleToggle={handleToggleSimpleDialog}
                    data={simpleDialog.msg}
                /> : null
        }
        {/* {dialog} */}
      </div>
  );
}

function mapStateToProps(state) {
    return {
      user: state.user,
    };
}
export default connect(mapStateToProps)(PaymentView);