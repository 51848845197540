import React, { useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Popup from "../../../UI/Popup";
import VacationDatesForm from "./VacationDatesForm";

export default function VacationDatesTable(props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const { data, headers } = props;

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addOrEdit = (row) => {
    if (row.id === 0) {
      row.id = data.length + 1;
      data.push(row);
    } else {
      let recordIndex = data.findIndex((x) => x.id === row.id);
      data[recordIndex] = { ...row };
    }
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  return (
    <>
      <Paper>
        <Table style={{ textAlignLast: "center" }}>
          <TableHead>
            <TableRow>
              {headers.map((item, indx) => {
                return <TableCell key={indx}>{item.name}</TableCell>;
              })}
              <TableCell>
                <IconButton
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, indx) => (
              <TableRow key={item.id}>
                <TableCell>{item.departureDate}</TableCell>
                <TableCell>{item.entryDate}</TableCell>
                <TableCell>{item.daysTaken}</TableCell>
                <TableCell>
                  <IconButton onClick={() => openInPopup(item)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => props.handleRemove(indx, "vacationDates")}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Popup title="Fechas de Vacaciones" open={openPopup} setOpen={setOpenPopup}>
        <VacationDatesForm addOrEdit={addOrEdit} recordForEdit={recordForEdit} />
      </Popup>
    </>
  );
}
