import React from 'react';
import {
    Popper, Grow, Paper, ClickAwayListener, MenuList,
    MenuItem, Badge, Divider, Toolbar, Grid, Typography, Button
} from '@material-ui/core';
import {History, List, Assignment, PersonAdd, ListAlt, Settings} from '@material-ui/icons';
import './../index.css';

function HeaderMenu(props) {

    return(
        <Popper
            style={{width:'250px', marginRight:25, zIndex:10}}
            open={Boolean(props.menuAnchorEl)}
            anchorEl={props.menuAnchorEl}
            role={undefined}
            transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
            <Paper>
                <ClickAwayListener onClickAway={props.handleCloseMenu}>
                    <MenuList id="menu-list-grow">
                        {/* <MenuItem onClick={props.handleToggleDialog.bind(this, 'history')} style={{padding:'15px'}}>
                            <History style={{fontSize:20, marginRight:5}} />
                            <Badge variant="dot" badgeContent={5} color="error">
                                Historial Transaccional&nbsp;&nbsp;
                            </Badge>
                        </MenuItem>
                        <Divider /> */}
                        {/* <MenuItem onClick={props.handleToggleDialog.bind(this, 'bot-init-config')} style={{padding:'15px'}}>
                            <PermDataSetting style={{fontSize:20, marginRight:5}} /> Configuraciones de inicio
                        </MenuItem>
                        <Divider /> */}
                        <MenuItem onClick={props.handleToggleDialog.bind(this, 'assignment')} style={{padding:'15px'}}>
                            <Assignment style={{fontSize:20, marginRight:5}} /> Asignación de clientes
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={props.handleToggleDialog.bind(this, 'manage-responsibles')} style={{padding:'15px'}}>
                            <PersonAdd style={{fontSize:20, marginRight:5}} /> Gestión de responsables
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={props.handleSecondaryDialog.bind(this, 'manage-orders')} style={{padding:'15px'}}>
                            <ListAlt style={{fontSize:20, marginRight:5}} /> Ordenes personalizados
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={props.handleSecondaryDialog.bind(this, 'infrastructure')} style={{padding:'15px'}}>
                            <Settings style={{fontSize:20, marginRight:5}} /> Infraestructura
                        </MenuItem>
                    </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    );
}

class ChargingToolbar extends React.Component {

    state={
        menuAnchorEl: null,
    }
    handleCloseMenu = () => {
        this.setState({
            menuAnchorEl: null,
        })
    }
    handleClickMenu = (event) => {
        this.setState({
            menuAnchorEl: event.currentTarget,
        })
    }
    render() {
        return(
            <Paper>
            <Toolbar className="app-bar">
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography
                        variant={this.props.typeVariant}
                        className={this.props.typeClass}
                        style={{textAlign:'left'}}
                    >
                        {this.props.toolbarTitle}
                    </Typography>

                    <Button
                        onClick={this.handleClickMenu}
                        style={{backgroundColor:'#F5F5F5'}}
                        // style={{backgroundColor:'transparent'}}
                    >
                        {/* <Badge
                            badgeContent={5} color="error"
                            anchorOrigin={{horizontal: 'right', vertical:'top'}}
                        > */}
                        <List style={{color:'#424242', fontSize:25}}/>
                        {/* </Badge> */}
                    </Button>
                    <HeaderMenu
                        menuAnchorEl={this.state.menuAnchorEl}
                        handleCloseMenu={this.handleCloseMenu}
                        handleToggleDialog={this.props.handleToggleDialog}
                        handleSecondaryDialog={this.props.handleSecondaryDialog}
                    />
                </Grid>
            </Toolbar>
            </Paper>
        );
    }
}

export { ChargingToolbar };