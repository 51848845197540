import * as React from 'react';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { SearchComponent } from "../../../components/SharedComponents/Fields";

import { translations, signatureTypes } from '../../../utils';


const styles: any = createStyles({
    formControl: {
        marginTop: 20
    },
    greenButton: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    label: {
        color: green[500]
    },
    redButton: {
        '&:hover': {
            backgroundColor: red[200]
        },
        backgroundColor: red[500],
        color: 'white'
    },
    textField: {
        marginTop: 10,
        minWidth: 190
    },
    gridFormControl: {
        margin: 8,
        width: '100%',
    },
});

interface ISearchFilterProps {
    token: string;

    open: boolean;
    classes: {
        formControl: any;
        greenButton: any;
        redButton: any;
        label: any;
        textField: any;
        gridFormControl: any;
    };
    handleClose(): void;
    search(params: IFetchDebitCardOrPassbookAccountContractParams): void;
}

interface ISearchFilterState {
    [key: string]: string | number | any;
    contract_date_from: string;
    contract_date_to: string;
    due_date_from: string;
    due_date_to: string;
    certificate_number: string;
    status: any;
    secondary_debtors_unique_id: any;
    main_debtor_unique_id: any;
    title: string;
    sign_type: string;
    collection_type: string;
}

class SearchFilter extends React.Component<ISearchFilterProps, ISearchFilterState>{
    public state: ISearchFilterState = {
        contract_date_from: '',
        contract_date_to: new Date().toISOString().split('T')[0],
        secondary_debtors_unique_id: [],
        main_debtor_unique_id: [],
        status: [],
        certificate_number: '',
        due_date_from: '',
        due_date_to: '',
        title: '',
        sign_type: 'joint',
        collection_type: 'due_date',
    };
    constructor(props: ISearchFilterProps) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }
    public handleSearch() {
        const params = {
            dueDateFrom: this.state.due_date_from,
            dueDateTo: this.state.due_date_to,
            contractDateFrom: this.state.contract_date_from,
            contractDateTo: this.state.contract_date_to,
            certificateNumber: this.state.certificate_number,
            mainDebtorUniqueId: this.state.main_debtor_unique_id,
            secondaryDebtorsUniqueId: this.state.secondary_debtors_unique_id,
            status: this.state.status.map((item: any) => item.value).join(','),
            title: this.state.title,
            signType: this.state.sign_type,
            paymentType: this.state.collection_type,
            token: this.props.token
        };
        this.props.search(params);
        this.props.handleClose();
    }
    public handleClear() {
        this.setState({
            contract_date_from: '',
            contract_date_to: new Date().toISOString().split('T')[0],
            debtor_unique_id: [],
            status: [],
            certificate_number: '',
            due_date_from: '',
            due_date_to: '',
            title: '',
            sign_type: 'joint',
            collection_type: 'due_date',
        });
        this.props.search({ token: this.props.token })
        this.props.handleClose();
    }
    public handleChange(key: any) {
        return (event: any) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }
    public handleSelectChange(key: any) {
        return (event: any) => {
            this.setState({ [key]: event.target.value });
        }
    }
    public handleAutocompleteChange(key: any, obj: any, val: any) {
        /* Handle the state of Autocomplete components */
        const data = obj || val;

        this.setState({
            [key]: data.value ? data.value : ""
        });

    }
    public render() {
        const { classes, handleClose, open } = this.props;

        const handleMultipleSelection = (key: string, val: string) =>
            this.setState({ [key]: val });

        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12}>
                            <SearchComponent
                                xs={12}
                                containerClass="field-container"
                                fieldClass={{ style: styles.label }}
                                typeClass={styles.label}
                                fieldID="client"
                                fieldInnerLabel={translations.DEBTOR}
                                handleChange={this.handleAutocompleteChange.bind(this, "main_debtor_unique_id")}
                                optionType={'participants'}
                                variant="standard"
                                limitTags={1}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <SearchComponent
                                xs={12}
                                containerClass="field-container"
                                fieldClass={{ style: styles.label }}
                                typeClass={styles.label}
                                fieldID="client"
                                fieldInnerLabel={"Miembro(s) Secundario(s)"}
                                handleChange={this.handleAutocompleteChange.bind(this, "secondary_debtors_unique_id")}
                                optionType={'participants'}
                                variant="standard"
                                limitTags={1}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Autocomplete
                                style={{ marginTop: "16px" }}
                                multiple={true}
                                options={[
                                    { value: "in_process", label: translations.PENDING_SIGNATURE },
                                    { value: "legalized", label: translations.IN_LEGALIZATION_PROCESS },
                                    { value: "archived", label: translations.ARCHIVED },
                                ]}
                                getOptionLabel={(option: any) => option.label}
                                onChange={(e, newValue: any) =>
                                    handleMultipleSelection("status", newValue)
                                }
                                value={this.state.status}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" label={translations.STATUS} />
                                )}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <FormControl className={classes.gridFormControl} >

                                <InputLabel
                                    id="signatureType"
                                >
                                    Tipo de firma
                                </InputLabel>
                                <Select
                                    id="signatureType"
                                    label={"Tipo de firma"}
                                    onChange={this.handleSelectChange("sign_type")}
                                    value={this.state.sign_type}
                                >
                                    {signatureTypes.map((opt, indx) => (
                                        <MenuItem key={indx} value={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <FormControl className={classes.gridFormControl}
                            >
                                <InputLabel htmlFor="collectionType" shrink={true}>Forma de liquidación</InputLabel>
                                <Select
                                    value={this.state.collection_type}
                                    onChange={this.handleSelectChange('collection_type')}
                                    label={"Forma de liquidación"}
                                    required={true}
                                    inputProps={{
                                        id: 'collectionType',
                                        name: 'collectionType',
                                    }}
                                >
                                    <MenuItem value="due_date">A vencimiento</MenuItem>
                                    <MenuItem value="withdrawable">Retirables</MenuItem>
                                    <MenuItem value="capitalizable">Capitalizable</MenuItem>
                                    <MenuItem value="liquidable   ">Liquidable</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={"Fecha de vencimiento desde"}
                                value={this.state.due_date_from}
                                fullWidth={true}
                                type="date"
                                onChange={this.handleChange('due_date_from')}
                                InputLabelProps={{
                                    style: styles.label,
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={"Fecha de vencimiento hasta"}
                                type="date"
                                value={this.state.due_date_to}
                                fullWidth={true}
                                onChange={this.handleChange('due_date_to')}
                                InputLabelProps={{
                                    style: styles.label,
                                    shrink: true
                                }}

                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.FROM}`}
                                value={this.state.contract_date_from}
                                type="date"
                                fullWidth={true}
                                onChange={this.handleChange('contract_date_from')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.TO}`}
                                value={this.state.contract_date_to}
                                type="date"
                                fullWidth={true}
                                onChange={this.handleChange('contract_date_to')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.redButton} onClick={this.handleClear}>
                        <ClearIcon />
                        &nbsp;
                        {`${translations.CLEAR} ${translations.SEARCH}`}
                    </Button>
                    <Button className={classes.greenButton} onClick={this.handleSearch}>
                        <SearchIcon />
                        &nbsp;
                        {translations.SEARCH}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}


export default withStyles(styles)(SearchFilter);
