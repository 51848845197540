import React from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  IconButton,
  Box,
  Tab,
  TableContainer,
  Toolbar,
  Button,
  Paper,
  DialogActions,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { GeneralTable } from "../../../components/SharedComponents/Table";
import { OutlayTemplate } from "../Filters/OutlayTemplate";
import { NumericTextField } from "src/components/SharedComponents/Fields";
import { RegularLoansTemplate2 } from "../Filters/RegularLoansTemplate";

function TableToolbar(props) {
  const handleChangeRows = (event) => {
    props.setRowsPerPage(event.floatValue);
  };

  return (
    <Toolbar>
      <Grid
        container
        spacing={2}
        direction="row"
        style={{
          paddingTop: "15px",
        }}
      >
        <Grid item xs={12}>
          <div>{props.filters}</div>
        </Grid>
        <Grid
          container
          spacing={2}
          direction="row"
          style={{ alignItems: "center" }}
        >
          <Grid item xs={props.rowsPerPage ? 6 : 12}>
            <Typography
              variant="h6"
              className="section-header"
              style={{ fontWeight: "bold", paddingLeft: 16, width: "inherit" }}
            >
              {props.title}
            </Typography>
          </Grid>
          {props.rowsPerPage ? (
            <Grid
              item
              xs={6}
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <NumericTextField
                xs={4}
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Desembolsos por pagina"
                fieldID="requestNumber"
                fieldClass="fields"
                fieldVariant="outlined"
                margin="dense"
                fullWidth
                value={props.numRowsPerPage}
                onChange={handleChangeRows}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Toolbar>
  );
}

function TemplateDialog(props) {
  const [value, setValue] = React.useState("1");
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [selected, setSelected] = React.useState([]);
  const [date, setDate] = React.useState({ start: null, end: null });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelected([]);
  };

  const setCurrency = (value) => {
    let data = parseFloat(value).toFixed(2);
    return Number(data).toLocaleString("en-US", {
      style: "currency",
      currency: "DOP",
    });
  };

  const columnsSavingAccounts2 = [
    { id: "checkbox" },
    {
      id: "accountNumber",
      label: "Número producto".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "accountType",
      label: "Tipo producto".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "accountBalance",
      label: "Balance".toUpperCase(),
      type: "number",
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.accountBalance),
    },
    {
      id: "availableAccountBalance",
      label: "Balance disponible".toUpperCase(),
      type: "number",
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.availableAccountBalance),
    },
  ];

  const columnsSavingAccounts = [
    {
      id: "accountNumber",
      label: "Número producto".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "accountType",
      label: "Tipo producto".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "accountBalance",
      label: "Balance".toUpperCase(),
      type: "number",
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.accountBalance),
    },
    {
      id: "availableAccountBalance",
      label: "Balance disponible".toUpperCase(),
      type: "number",
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.availableAccountBalance),
    },
  ];

  const columnsInvestmentCertificate = [
    {
      id: "accountNumber",
      label: "Número cuenta".toUpperCase(),
      minWidth: 200,
      align: "center",
    },
    {
      id: "creationDate",
      label: "Fecha apertura".toUpperCase(),
      minWidth: 200,
      align: "center",
    },
    {
      id: "accountType",
      label: "Tipo cuenta".toUpperCase(),
      minWidth: 200,
      align: "center",
    },
    {
      id: "accountBalance",
      label: "Saldo".toUpperCase(),
      minWidth: 200,
      align: "center",
      element: (row) => setCurrency(row.accountBalance),
    },
    {
      id: "apertureBalance",
      label: "Monto apertura".toUpperCase(),
      minWidth: 200,
      align: "center",
      element: (row) => setCurrency(row.apertureBalance),
    },
  ];

  const columnsOutlays = [
    {
      id: "loanNumber",
      label: "Número de Préstamo".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "loanStatus",
      label: "estado".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "disbursementDate",
      label: "fecha".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "productType",
      label: "tipo de producto".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "paymentDay",
      label: "dia de pago".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "periodicity",
      label: "frecuencia".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "loanOutstandingBalance",
      label: "saldo".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.loanOutstandingBalance),
    },
  ];

  const columnsLoans = [
    {
      id: "product_type",
      label: "Tipo producto".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "loan_oustanding_balalance",
      label: "Saldo".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.loan_oustanding_balalance),
    },
    {
      id: "w_intr",
      label: "Tasa interés".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => `${row.w_intr}%`,
    },
    {
      id: "next_amount_due",
      label: "Proximo pago".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.next_amount_due),
    },
    {
      id: "total_pcp_amount",
      label: "Total PCP".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.total_pcp_amount),
    },
  ];

  const columnsLoans2 = [
    { id: "checkbox" },
    {
      id: "product_type",
      label: "Tipo producto".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "loan_oustanding_balalance",
      label: "Saldo".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.loan_oustanding_balalance),
    },
    {
      id: "w_intr",
      label: "Tasa interés".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => `${row.w_intr}%`,
    },
    {
      id: "next_amount_due",
      label: "Proximo pago".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.next_amount_due),
    },
    {
      id: "total_pcp_amount",
      label: "Total PCP".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.total_pcp_amount),
    },
  ];

  const columnsArr = [
    {
      id: "product_name",
      label: "Tipo producto".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "loan_number",
      label: "Número de prestamo".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "quotes_due",
      label: "cuotas vencidas".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "loan_amount_due",
      label: "total principal atrasado".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.loan_amount_due),
    },
    {
      id: "total_pending",
      label: "Total pendiente".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.total_pending),
    },
  ];

  const datacab = props.loans[0]
    ? props.loans[0].loansGeneralInfo[1]
      ? props.loans[0].loansGeneralInfo[1].product_type_details
      : []
    : [];

  const dataPr = props.loans[0]
    ? props.loans[0].loansGeneralInfo[0].product_type_details
    : [];

  let regulares = [];
  let compras = [];
  let totalLoans = [];

  const data = dataPr.map((item) => {
    {
      const regularesAll = item.product_details.map((item2) => {
        return {
          id: "prestamos",
          product_type: item.product_type,
          total_loan_stock: item.total_loan_stock,
          total_next_amount_due: item.total_next_amount_due,
          total_pcp_amount: item.total_pcp_amount,
          w_intr: parseFloat(item.w_intr).toFixed(2),

          loan_number: item2.loan_number,
          disbursement_date: item2.disbursement_date,
          loan_amount: item2.loan_amount,
          loan_oustanding_balalance: item2.loan_oustanding_balalance,
          interest_rate: item2.interest_rate,
          loan_periods: item2.loan_periods,
          payment_frequency: item2.payment_frequency,
          payment_day: item2.payment_day,
          next_payment_due: item2.next_payment_due,
          next_amount_due: item2.next_amount_due,
          loans_details: item2.loans_details,
        };
      });

      regularesAll
        ? regularesAll.map((i) => {
            regulares.push(i);
          })
        : [];
    }
  });
  const data2 = datacab.map((item) => {
    {
      const comprasAll = item.product_details
        ? item.product_details.map((item2) => {
            return {
              id: "prestamos",
              product_type: item.product_type,
              total_loan_stock: item.total_loan_stock,
              total_next_amount_due: item.total_next_amount_due,
              total_pcp_amount: item.total_pcp_amount,
              w_intr: parseFloat(item.w_intr).toFixed(2),

              loan_number: item2.loan_number,
              disbursement_date: item2.disbursement_date,
              loan_amount: item2.loan_amount,
              loan_oustanding_balalance: item2.loan_oustanding_balalance,
              interest_rate: item2.interest_rate,
              loan_periods: item2.loan_periods,
              payment_frequency: item2.payment_frequency,
              payment_day: item2.payment_day,
              next_payment_due: item2.next_payment_due,
              next_amount_due: item2.next_amount_due,
              loans_details: item2.loans_details,
            };
          })
        : null;

      comprasAll
        ? comprasAll.map((i) => {
            compras.push(i);
          })
        : [];
    }
  });

  totalLoans =
    regulares && compras
      ? regulares.concat(compras).map((val, index) => {
          return {
            ...val,
            uniqueId: index + 1,
          };
        })
      : [];

  const investmentCertificate = props.investmentCertificate.filter(
    (item) => item.accountBalance > 0
  );

  const outlays = [];
  const loanNumbers = [];

  props.outlays.forEach((item) => {
    if (!loanNumbers.includes(item.loanNumber)) {
      outlays.push(item);
      loanNumbers.push(item.loanNumber);
    }
  });

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={props.open}
    >
      <DialogTitle style={{ backgroundColor: green[500] }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography
            component={"span"}
            variant="h5"
            className="dialog-title"
            style={{ color: "white" }}
          >
            Selecciona tu plantilla
          </Typography>
          <IconButton onClick={props.handleToggleDialog.bind(this, undefined)}>
            <CloseOutlined className="dialog-header-close-btn" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent
        style={{
          position: "relative",
          height: "60vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <div>
          <Grid container spacing={2}>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    centered
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: green[500],
                      },
                    }}
                  >
                    <Tab label="Estado de cuenta" value="1" />
                    <Tab label="Estado de cuenta detallado" value="2" />
                    <Tab label="Desembolsos" value="3" />
                    <Tab label="ATRASOS" value="5" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Paper>
                    <TableContainer style={{ width: "100%" }}>
                      <GeneralTable
                        columns={columnsSavingAccounts}
                        data={props.savingAccounts.filter(
                          (item) =>
                            item.savingsClassType === "CUENTAS DE AHORRO"
                        )}
                        toolbar={<TableToolbar title={"CUENTAS DE AHORRO"} />}
                      />
                    </TableContainer>
                  </Paper>
                  <br />
                  <br />
                  <Paper>
                    <TableContainer style={{ width: "100%" }}>
                      <GeneralTable
                        columns={columnsSavingAccounts}
                        data={props.savingPlans}
                        toolbar={<TableToolbar title={"PLANES DE AHORRO"} />}
                      />
                    </TableContainer>
                  </Paper>
                  <br />
                  <br />
                  <Paper>
                    <TableContainer style={{ width: "100%" }}>
                      <GeneralTable
                        columns={columnsInvestmentCertificate}
                        data={investmentCertificate}
                        toolbar={
                          <TableToolbar title={"CERTIFICADOS DE INVERSION"} />
                        }
                      />
                    </TableContainer>
                  </Paper>
                  <br />
                  <br />
                  <Paper>
                    <TableContainer style={{ width: "100%" }}>
                      <GeneralTable
                        columns={columnsLoans}
                        data={regulares}
                        toolbar={<TableToolbar title={"PRESTAMOS REGULARES"} />}
                      />
                    </TableContainer>
                  </Paper>
                  <br />
                  <br />
                  <Paper>
                    <TableContainer style={{ width: "100%" }}>
                      <GeneralTable
                        columns={columnsLoans}
                        data={compras}
                        toolbar={
                          <TableToolbar title={"COMPRA ANTICIPADA DE BONOS"} />
                        }
                      />
                    </TableContainer>
                  </Paper>
                </TabPanel>
                <TabPanel value="2">
                  <RegularLoansTemplate2 setDate={setDate} />
                  <br />
                  <br />
                  <Paper>
                    <div style={{ width: "100%" }}>
                      <TableContainer style={{ width: "100%" }}>
                        <GeneralTable
                          columns={columnsSavingAccounts2}
                          data={props.savingAccounts}
                          selected={selected}
                          setSelected={setSelected}
                          toolbar={<TableToolbar title={"CUENTAS DE AHORRO"} />}
                        />
                      </TableContainer>
                    </div>
                  </Paper>
                  <br />
                  <br />
                  <Paper>
                    <div style={{ width: "100%" }}>
                      <TableContainer style={{ width: "100%" }}>
                        <GeneralTable
                          columns={columnsLoans2}
                          selected={selected}
                          setSelected={setSelected}
                          data={totalLoans}
                          toolbar={<TableToolbar title={"PRESTAMOS"} />}
                        />
                      </TableContainer>
                    </div>
                  </Paper>
                </TabPanel>
                <TabPanel value="3">
                  <Paper>
                    <div style={{ width: "100%" }}>
                      <TableContainer style={{ width: "100%" }}>
                        <GeneralTable
                          columns={columnsOutlays}
                          data={outlays}
                          toolbar={
                            <TableToolbar
                              title={"DESEMBOLSOS"}
                              rowsPerPage={true}
                              numRowsPerPage={rowsPerPage}
                              setRowsPerPage={setRowsPerPage}
                              filters={
                                <OutlayTemplate
                                  data={outlays}
                                  dataIntact={props.outlaysIntact}
                                  setOutlay={props.setOutlay}
                                />
                              }
                            />
                          }
                        />
                      </TableContainer>
                    </div>
                  </Paper>
                </TabPanel>
                <TabPanel value="5">
                  <Paper>
                    <div style={{ width: "100%" }}>
                      <TableContainer style={{ width: "100%" }}>
                        <GeneralTable
                          columns={columnsArr}
                          data={props.arr[0] ? props.arr[0].loansDetails : []}
                          toolbar={<TableToolbar title={"ATRASOS"} />}
                        />
                      </TableContainer>
                    </div>
                  </Paper>
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            props.printTemplates(value, selected, date, rowsPerPage)
          }
          variant="contained"
          style={{
            backgroundColor: green[500],
            color: "#FFFFFF",
            fontSize: "12px",
            marginLeft: 10,
            display: "inline-block",
          }}
          disableElevation
        >
          Imprimir plantilla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { TemplateDialog };
