import React, { useState, useEffect } from 'react';
import {
    Grid, Typography, IconButton, Button, Divider,
    List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction,
    ListSubheader, Paper
} from '@material-ui/core';
import { 
    TextFieldComponent, AutocompleteField, CheckboxField,
} from '../../../components/SharedComponents/Fields';
import { 
    Add, Print, AttachFile, Grade, 
    Info, DeleteOutline, FiberManualRecord,
} from '@material-ui/icons';
import AcquaintanceMembershipForm from '../../AcquaintanceshipPersonContract/Form';
import AcquaintanceDetail from '../../AcquaintanceshipPersonContract/View';
import InputCertificationForm from '../../InputCertification/Form';
import InputCertificationDetail from '../../InputCertification/View';
import SavingAccountForm from '../../SavingsAccount/Form';
import SavingAccountDetail from '../../SavingsAccount/View';
// import AccountRequestTable from '../../SavingsAccount/SelectInput';
import SavingCertificateInput from '../../../components/SavingsAccountAndCertificatesSelectInput';
import MemebershipApplDetail from '../../MembershipApplication/View';
import CreditReviewAuthDetail from '../../CreditReviewAuthorization/CreditReviewAuthorizationView';
import AccountManagementContractForm from '../../DebitCardOrPassbookAccountContract/Form';
import AccountManagementContractDetail from '../../DebitCardOrPassbookAccountContract/View';
import axios from 'axios';
import { 
    fetchAcquaintanceshipPersonContract as fetchAPC,
    fetchTicket, createMembershipApplication,
    fetchParticipant, createCreditReviewAuthorization, submitRohiParticipant,
    uploadDBAToRohi, fetchAcquaintanceshipPersonContract as fetchAPF,
    fetchBankAccounts
} from '../../../utils/axios';
import { 
    StepStatus, TabsStyled, TabStyled, 
    handleNextStep, ticketHistory, FormWrapper, ticketHistoryGeneral,
} from '../TicketGeneralMethods';
import { PaymentMethodsTable } from './../Tables';
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';
import { generateDocument } from '../../../components/SharedComponents/DocumentPrint';

const getBankAccounts = (token, setUpdate, creditorId) => {
    const endpoint = fetchBankAccounts({token: token, owner: creditorId});
    endpoint.then(response => {
        const data = response.data.map(item => {
            return {
                name: item.name + " - " + item.number,
                value: item.uniqueId,
            }
        })
        setUpdate(data);
    });
}

const handleGetBankAccounts = async (props, setBankAccounts) => {
    const accFormId = props.ticket.steps.find(item => item.step === 'data_adquisition')
    .requirements.find(item => item.name === 'acquaintanceFormId').value;
    const creditor = await fetchAPF(props.token, accFormId).then(res => res.data.creditor);
    getBankAccounts(props.token, setBankAccounts, creditor);
}

function DataAdquisition(props) {
    const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);
    const [formId, setFormId] = useState(null);
    const [expand, setExpand] = useState('acquaintanceForm');

    const handleClickExpand = (form, event, isExpanded) => {
        setExpand(isExpanded ? form : false);
    }

    const handleSaveForm = async (id, data) => {
        props.ticket.steps.find(item => item.step === props.currentStep).requirements
        .find(item => item.name === 'acquaintanceFormId').value = id;

        props.ticket.customer = data.debtor;

        ticketHistory(props.ticket.steps, props).then(response => {
            const history = response;
            props.modifyTicketStep(props.ticket.steps, props.ticket, history);
        });
        setFormId(id);
    }

    const handleCompleteStep = async () => {
        // verify the requirements
        let proceed = false;
        let error = false;
        let storedForms = {};
        let emptyForm = false;
        let membershipId = '';
        let creditReviewAuthId = '';

        props.ticket.steps.find(item => item.step === 'requirements_gathering').requirements
        .filter(item => item.type === 'form').forEach(item => {
            storedForms = {...storedForms, [item.name]: item.value}
            if(!item.value) {
                emptyForm = true;
            }
        });

        if(formId && emptyForm) {
            // GET ACQUAINTANCE FORM DATA AND VALIDATE FOR DEBTOR FINANCIAL AND WORK PROFILE.
            props.setLoading(true);
            const formData = await fetchAPC(props.token, formId).then(response => response.data);
            const debtorData = await fetchParticipant(props.token, formData.debtor).then(response => response.data);
            if(debtorData.financialProfileObj && debtorData.workProfileObj) {
                proceed = true;
            }else {
                props.setLoading(false);
                props.handleToggleSimpleDialog('simple-dialog', 
                   `El deudor debe tener perfil de trabajo y perfil financiero para poder continuar.`);
                return;
            }
            // SET MEMBERSHIP FORM DATA.
            const membershipApplData = {
                contractDate: formData.contractDate,
                creditor: formData.creditor,
                debtor: formData.debtor,
                familyMembersInfo: formData.beneficiaryInfo,
                financialAccounts: formData.financialAccounts,
                title: `AS - ${formData.title}`,
            };

            // SET CREDIT REVIEW AUTH FORM DATA.
            const creditReviewAuthData = {
                contractDate: formData.contractDate,
                debtor: formData.debtor,
                title: `ARC - ${formData.title}`,
                status: 'pending_signature',
                potentialDebtor: {
                    fullName: '',
                    cellPhone: '',
                    email: '',
                    identificationType: '',
                    identificationNumber: '',
                    address: {
                        country: '',
                        state: '',
                        sector: '',
                        reference: '',
                        city: '',
                        region: '',
                    }
                },
            }
            if(!storedForms.memebershipApplFormId) {
                // CREATE MEMBERSHIP FORM
                membershipId = await 
                createMembershipApplication(props.token, membershipApplData)
                .then(response => response.data.uniqueId)
                .catch((errors) => { 
                    error = true; 
                    errorHandling(errors, props.handleToggleSimpleDialog)
                });
            }

            if(!storedForms.creditReviewAuthId){
                // CREATE CREDIT REVIEW AUTH FORM
                creditReviewAuthId = await
                createCreditReviewAuthorization(props.token, creditReviewAuthData)
                .then(response => response.data.uniqueId)
                .catch((errors) => { 
                    error = true; 
                    errorHandling(errors, props.handleToggleSimpleDialog)
                });
            }

            if(error) {
                props.setLoading(false);
                props.handleToggleSimpleDialog('simple-dialog', `Ha ocurrido un error creando los formularios.`);
                return;
            }

            if(emptyForm) {
                // STORAGE IDs ON REQUIREMENT STEPS
                props.ticket.steps.find(item => item.step === 'requirements_gathering').requirements
                .forEach(item => {
                    switch(item.name) {
                        case 'memebershipApplFormId':
                            item.value = membershipId ? membershipId : item.value;
                            break;
                        case 'creditReviewAuthId':
                            item.value = creditReviewAuthId ? creditReviewAuthId : item.value;
                            break;
                        default:
                            break;
                    };
                });
                ticketHistory(props.ticket.steps, props).then(async (response) => {
                    const history = response;
                    await props.modifyTicketStep(props.ticket.steps, props.ticket, history);
                    handleNextStep(proceed, props);
                });
            }
            return;
        }
        handleNextStep(proceed, props);
    }

    useEffect(() => {
        if(formId) {
            return;
        }
        const currentStep = props.ticket.steps.find(item => item.step === props.currentStep);
        const id = currentStep.requirements.find(item => item.name === 'acquaintanceFormId').value;
        if(id) {
            setFormId(id);
        }
    }, [formId, props.reloadTicket]);

    return(
        <div style={{padding: '16px'}}>
            <Grid container item xs={12} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <StepStatus
                    roles={props.roles} 
                    completed={stepStatus}
                    handleNextStep={handleCompleteStep}
                    ticketCompleted={props.ticketCompleted}
                />
                <FormWrapper 
                    title={"Conocimiento de persona"}
                    name={'acquaintanceForm'}
                    contentType={'Formulario'}
                    ticketCompleted={props.ticketCompleted}
                    form={
                        <>
                            {formId &&
                            <AcquaintanceDetail
                                id={formId}
                            />}
                            <div style={{display:formId?'none':'block'}}>
                                <AcquaintanceMembershipForm
                                    fromTicket={true}
                                    handleSaveCallBack={handleSaveForm}
                                />
                            </div>
                        </>
                    }
                    handleExpand={handleClickExpand}
                    expand={expand === 'acquaintanceForm'}
                />

            </Grid>
        </div>
    )
}
function Requirements(props) {
    const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);
    const [formIds, setFormIds] = useState({});
    const [currentTicket, setCurrentTicket] = useState({});
    const [requirements, setRequirements] = useState([]);
    const [expand, setExpand] = useState('');

    const handleClickExpand = (form, event, isExpanded) => {
        setExpand(isExpanded ? form : false);
    }

    const getStepRequirements = () => {
        const reqs =
            props.ticket.steps
            .find(item => item.fileRequirements).fileRequirements
            .filter(item => item.step === props.currentStep);
        setRequirements(reqs);
    }

    const handleFileRequirements = (steps, refreshTicketData) => {
        ticketHistory(steps, props).then((response) => {
            const history = response;
            // fromDialog property lets refresh files when are uploaded.
            props.modifyTicketStep(steps, props.ticket, history, {fromDialog:true, reload: refreshTicketData});
        });
    }
    
    const getTicket = () => {
        const endpoint = fetchTicket(props.token, props.ticket.uniqueId);
        endpoint.then(response => {
            setCurrentTicket(response.data);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    const handleCompleteStep = () => {
        // verify the requirements
        let proceed = false;
        const req = 
            currentTicket.steps
            .find(item => item.fileRequirements).fileRequirements
            .filter(item => item.step === props.currentStep && item.required)
            .find(item => item.value.length < 1);
        
        if(!req) {
            proceed = true;
        }else {
            proceed = false;
        }
        handleNextStep(proceed, props);
    }

    const handleDocumentPrint = (key, event) => {
        event.stopPropagation();
        const printBtn = document.querySelector(`.accordion-content-wrapper.${key} form .generate-print-document`);
        if(printBtn) {
            printBtn.click();
        }
    }

    useEffect(() => {
        getStepRequirements();
        getTicket();
    }, [props.reloadTicket]);

    useEffect(() => {
        setCurrentTicket(props.ticket);
        let forms = {};
        const currentStep = props.ticket.steps.find(item => item.step === props.currentStep);
        currentStep.requirements.filter(item => item.type === 'form').forEach(item => {
            forms = {...forms, [item.name]: item.value}
        });
        setFormIds(forms);
    }, [props.ticket, props.reloadTicket]);


    return(
        <div style={{padding: '16px'}}>
            <Grid container item xs={12} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <StepStatus
                    roles={props.roles} 
                    completed={stepStatus}
                    handleNextStep={handleCompleteStep}
                    ticketCompleted={props.ticketCompleted}
                />
            </Grid>

            <Grid container item xs={12} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <Paper style={{border: '1px solid #ebebeb', borderRadius:5, width:'30rem', marginBottom:'2rem'}}>
                    <List
                        subheader={
                            <ListSubheader component="div" id="requirements-list" style={{fontSize:20}}>
                                Lista de requerimientos
                            </ListSubheader>
                        }
                    >   
                        {
                            requirements.length > 0 && requirements.map((item, index) => {
                                return(
                                    <div key={index}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Grade style={{color:item.required ? '#f76248' : 'transparent', fontSize:18}} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={item.text}
                                            />
                                            <ListItemSecondaryAction onClick={props.handleToggleDialog.bind(this, 'manage-files-dialog', {
                                                id: props.ticket.uniqueId,
                                                requirementName: item.text,
                                                ticketSteps: props.ticket.steps,
                                                ticketId: props.ticket.uniqueId,
                                                step: props.currentStep,
                                                handleFileRequirements: handleFileRequirements,
                                            })}
                                            
                                            >
                                                <IconButton edge="end" aria-label="delete">
                                                    <AttachFile/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {
                                            (index+1) !== requirements.length &&
                                            <Divider />
                                        }
                                    </div>
                                )
                            })
                        }
                    </List>
                </Paper>
                
                <FormWrapper 
                    title={"Admisión de socio"}
                    name={'membershipAppl'}
                    contentType={'Formulario'}
                    ticketCompleted={props.ticketCompleted}
                    form={
                        formIds.memebershipApplFormId ?
                        <MemebershipApplDetail 
                            id={formIds.memebershipApplFormId}
                        />
                        :
                        <Typography
                            variant={"subtitle1"}
                            className={"section-header"}
                            style={{
                                fontWeight: 'bold', color:'#424242', fontStyle:'italic', 
                                marginBottom: '2rem', display:'flex', alignItems:'center'
                            }}
                        >
                            <Info style={{color: '#1fbded', fontSize:25, marginRight:'.7rem',}} />
                            No se encontró el formulario
                        </Typography>
                    }
                    headerAction={
                        <Button
                            variant="contained"
                            disableElevation
                            style={{
                                backgroundColor:'#FFFFFF', border: '1px solid #43A047', color: '#43A047',
                                height:25, 
                            }}
                            onClick={handleDocumentPrint.bind(this, 'membershipAppl')}
                        >
                            <Print style={{fontSize:17, color:'#43A047', marginRight:'.2rem'}} />
                        </Button>
                    }
                    handleExpand={handleClickExpand}
                    expand={expand === 'membershipAppl'}
                />
                <FormWrapper 
                    title={"Autorización de revisión crediticia"}
                    name={'creditReviewAuth'}
                    contentType={'Formulario'}
                    ticketCompleted={props.ticketCompleted}
                    form={
                        formIds.creditReviewAuthId ?
                        <CreditReviewAuthDetail
                            creditReviewAuthorizationId={formIds.creditReviewAuthId}
                        />
                        :
                        <Typography
                            variant={"subtitle1"}
                            className={"section-header"}
                            style={{
                                fontWeight: 'bold', color:'#424242', fontStyle:'italic', 
                                marginBottom: '2rem', display:'flex', alignItems:'center'
                            }}
                        >
                            <Info style={{color: '#1fbded', fontSize:25, marginRight:'.7rem',}} />
                            No se encontró el formulario
                        </Typography>
                    }
                    headerAction={
                        <Button
                            variant="contained"
                            disableElevation
                            style={{
                                backgroundColor:'#FFFFFF', border: '1px solid #43A047', color: '#43A047',
                                height:25, 
                            }}
                            onClick={handleDocumentPrint.bind(this, 'creditReviewAuth')}
                        >
                            <Print style={{fontSize:17, color:'#43A047', marginRight:'.2rem'}} />
                        </Button>
                    }
                    handleExpand={handleClickExpand}
                    expand={expand === 'creditReviewAuth'}
                />
            </Grid>
        </div>
    )
}
function MembershipAnalysis(props) {
    const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);
    const approveOptions = [
        {name: 'Aprobado', value:'approved'},
        {name: 'Denegado', value:'denied'},
        {name: 'Debida Diligencia', value:'diligence'},
        {name: 'Requisito Extra', value:'extra_req'},
    ];
    const [currentTicket, setCurrentTicket] = useState({});
    const [requirements, setRequirements] = useState([]);
    const [recomendation, setRecomendation] = useState('');
    const [approve, setApprove] = useState(null);
    const [description, setDescription] = useState('');
    const [isRequired, setIsRequired] = useState(false);
    const [approvalObj, setApprovalObj] = useState({});

    const getStepRequirements = (ticketData) => {
        // get and set requirements fields and files.
        const reqs = 
            ticketData.steps
            .find(item => item.fileRequirements).fileRequirements
            .sort((a,b) => (a.required < b.required) ? 1 : -1);
        const fields =
            ticketData.steps.find(item => item.step === props.currentStep).requirements
        const comment = fields.find(item => item.name === 'comment').value || '';
        const approvalState = fields.find(item => item.name === 'approveState').value || null;
        setRecomendation(comment);
        setApprove(approvalState);
        setRequirements(reqs);
    }

    const getTicket = () => {
        const endpoint = fetchTicket(props.token, props.ticket.uniqueId);
        endpoint.then(response => {
            setCurrentTicket(response.data);
            getStepRequirements(response.data);
        })
        // .catch(errors => {
        //     errorHandling(errors, props.handleToggleSimpleDialog);
        // });
    }

    // const handleToggleFiles = (requirement) => {
    //     // with ticket uniqueId get the files of the specified requirement
    // }

    const clearFields = () => {
        setDescription('');
        setIsRequired(false);
    }

    const handleFileRequirements = (steps, refreshTicket) => {
        ticketHistory(steps, props).then((response) => {
            const history = response;
            props.modifyTicketStep(steps, props.ticket, history, refreshTicket);
            // possible solution if user change required fields
            // if(stepStatus) {
            //     // when is completed, force user to re-complete the step,
            //     // in case they deleted any requirements
            //     handleCompleteStep('re-check');
            // }
        });
    }

    const changeRequirement = (type, value) => {
        const ticketSteps = [...currentTicket.steps];
        ticketSteps.find(item => item.step === props.currentStep).requirements
        .forEach(item => {
            if(item.name === type) {
                item.value = value;
            }
        });
    }

    const handleRecomendationChange = (event) => {
        setRecomendation(event.target.value);
        changeRequirement('comment', event.target.value);
    }
    const handleApproveChange = (event, value) => {
        setApprove(value);
        changeRequirement('approveState', value);
    }

    const handleDescription = (event) => {
        setDescription(event.target.value);
    }
    const handleIsRequired = (event, value) => {
        setIsRequired(value);
    }

    const handleDeleteRequirement = (req) => {
        const modifyReqs = [...currentTicket.steps];
        const reqIndex = requirements.findIndex(item => item.text === req.text);
        modifyReqs.find(item => item.fileRequirements).fileRequirements.splice(reqIndex, 1);
        handleFileRequirements(modifyReqs, getTicket);
        // .splice(reqIndex, 1);
        // const fileIndex = files.findIndex(item => item.uniqueId === fileId);

        // modifyFiles.find(item => item.fileRequirements).fileRequirements
        //     .filter(item => item.step === props.data.step)
        //     .find(item => item.text === props.data.requirementName)
        //     .value.splice(fileIndex, 1);
        // props.data.handleFileRequirements(modifyFiles, getTicket);
    }

    const handleAddRequirement = () => {
        const checkSameName = requirements.find(item => item.text === description);
        if(checkSameName) {
            props.handleToggleSimpleDialog('simple-dialog', 'Ya existe un requerimiento con el nombre digitado.');
            return;
        }
        const requirement = {
            step: props.currentStep,
            text: description,
            required: Boolean(isRequired),
            value: [],
        }
        const ticketSteps = [...currentTicket.steps];
        ticketSteps.find(item => item.fileRequirements).fileRequirements.push(requirement);
        ticketSteps.find(item => item.step === props.currentStep).requirements
        .forEach(item => {
            switch(item.name) {
                case 'comment':
                    item.value = recomendation;
                    break;
                case 'approveState':
                    item.value = approve;
                    break;
                default:
                    break;
            }
        });
        handleFileRequirements(ticketSteps, getTicket);
        clearFields();
    }
    
    const handleDenyTicket = async () => {
        props.ticket.steps.find(item => item.approvalObj).approvalObj.status = 'denied';
        if(approve.value === 'denied') {
            const dataToModify = {
                status: 'cancelled',
                cancellationReason: 'DENEGADO EN ANÁLISIS DE ADMISIÓN',
            }
            const historyDescription = `Se ha modificado el estado del ticket a: Cancelado`;
            ticketHistoryGeneral(props, historyDescription).then(response => {
                const history = response;
                dataToModify.cancelledDate = history.time;
                props.modifyTicket(dataToModify, history);
            });
            props.handleToggleSimpleDialog();
        }
    }

    const createDBAInRohi = async () => {
        const acquaintanceForm = props.ticket.steps.find(item => item.step === 'data_adquisition').requirements
        .find(item => item.name === 'acquaintanceFormId').value;
        const accounts = await fetchAPF(props.token, acquaintanceForm).then(res => res.data.financialAccounts) || [];
        if(accounts.length > 0) {
            const endpoints = accounts.map(item => uploadDBAToRohi(props.token, item, 'barcelona'));
            await axios.all(endpoints).then(() => {
                //
            }).catch(errors => {
                props.setLoading(false);
                errorHandling(errors, props.handleToggleSimpleDialog);
            });
        }
        props.handleToggleSimpleDialog('success-dialog');
        props.setLoading(false);
    }

    const createDebtorRohi = async () => {
        props.setLoading(true);
        submitRohiParticipant(props.token, props.ticket.customer, 'barcelona').then(async () => {
            approvalObj.debtorCreatedRohi = true;
            const historyDescription = `Se ha modificado el objeto de aprobación a: ${JSON.stringify(approvalObj)}`;
            ticketHistoryGeneral(props, historyDescription).then(response => {
                const history = response;
                props.modifyTicketStep(props.ticket.steps, props.ticket, history, undefined);
            });
            await createDBAInRohi();
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    const handleCompleteStep = () => {
        let proceed = false;
        if(!approve || !recomendation) {
            props.handleToggleSimpleDialog('simple-dialog', 'Debe llenar todos los requerimientos.');
            return;
        }
        if(!approvalObj.debtorCreatedRohi) {
            props.handleToggleSimpleDialog('simple-dialog', 'Debe crear el deudor en ROHI.');
            return;
        }
        currentTicket.steps.find(item => item.approvalObj).approvalObj.status = approve.value;
        const step = [...currentTicket.steps];
        // step.find(item => item.step === props.currentStep).requirements
        // .forEach(item => {
        //     switch(item.name) {
        //         case 'comment':
        //             item.value = recomendation;
        //             break;
        //         case 'approveState':
        //             item.value = approve;
        //             break;
        //         default:
        //             break;
        //     }
        // });
        const requirementsState = 
            currentTicket.steps.find(item => item.fileRequirements).fileRequirements
            .find(item => item.required && item.value.length < 1);
        
        if(requirementsState || !recomendation || !approve.value) {
            proceed = false;
        }else {
            proceed = true;
        }
        // possible solution if user change required fields
        // put reCheck in argument list
        // if(reCheck) {
            //     handleNextStep(proceed, props);    
            // }
        // handleFileRequirements(step, getTicket);
        // handleNextStep(proceed, props);
        handleNextStep(proceed, props, step, getTicket);
    }

    useEffect(() => {
        setCurrentTicket(props.ticket);
        getStepRequirements(props.ticket);
        setApprovalObj(
            props.ticket.steps.find(item => item.approvalObj).approvalObj
        );
        // getTicket();
    }, [props.ticket, props.reloadTicket]);

    return(
        <div style={{padding: '16px'}}>
            <Grid container item xs={12} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <StepStatus
                    roles={props.roles} 
                    completed={stepStatus}
                    handleNextStep={
                        approve && approve.value === 'denied' ? 
                        props.handleToggleSimpleDialog
                        .bind(this, 'confirm-dialog', 'proceed-cancel-ticket', {callback: handleDenyTicket})
                        :
                        handleCompleteStep
                    }
                    ticketCompleted={props.ticketCompleted}
                />

                <Grid item container direction="column" style={{marginBottom:'1rem', maxWidth:700}}>
                    <Grid item container xs={12} justifyContent="flex-start" style={{marginBottom:'1rem'}}>
                        <Button
                            variant="contained"
                            className={
                                !approve ||
                                (approve && approve.value !== 'approved') ||
                                approvalObj.debtorCreatedRohi ||
                                props.ticketCompleted ?
                                    "action-method-btn disabled" : "action-method-btn"
                            }
                            disableElevation
                            style={{height:35}}
                            disabled={
                                !approve ||
                                (approve && approve.value !== 'approved') ||
                                approvalObj.debtorCreatedRohi ||
                                props.ticketCompleted
                            }
                            onClick={createDebtorRohi}
                        >
                            crear cliente en rohi
                        </Button>
                    </Grid>

                    <TextFieldComponent
                        xs={12}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Recomendación"
                        fieldID="analysis-adm-recomendation"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        placeholder="Recomendación..."
                        margin='dense'
                        fullWidth
                        value={recomendation}
                        onChange={handleRecomendationChange}
                        multiline={true}
                        rows={8}
                        disabled={props.ticketCompleted}
                    />
                    {/* <Grid container item justifyContent="flex-end">
                        <Button
                            variant="contained"
                            className={"action-method-btn"}
                            disableElevation
                            style={{height:30, marginLeft:'.5rem', marginTop:5}}
                        >
                            <Print style={{fontSize:17, color:'#fff', marginRight:'.2rem'}} />
                            generar plantilla
                        </Button>
                    </Grid> */}
                </Grid>
                <div style={{maxWidth:340}}>
                    <AutocompleteField
                        xs={12}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Aprobación"
                        fieldID="analysis-adm-approve"
                        fieldInnerLabel="Seleccione..."
                        fieldVariant="outlined"
                        value={approve}
                        handleChange={handleApproveChange}
                        options={approveOptions}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.value === value.value}
                        disabled={props.ticketCompleted}
                    />
                </div>

            </Grid>
            {
                (approve && ['diligence', 'extra_req'].includes(approve.value)) &&
                    <Grid container item xs={12} justifyContent="space-between" direction="column" style={{margin: '2rem 0 1rem 0'}}>
                        <Typography
                            variant={"h6"}
                            className={"section-header"}
                            style={{fontWeight: 'bold', color:'#878787'}}
                        >
                            Requerimientos
                        </Typography>
                        <Divider style={{margin:'1rem 0'}}/>

                        <Grid 
                            container item xs={12} spacing={2}
                            justifyContent="flex-start" direction="row" 
                            alignItems="flex-end" style={{marginBottom:'1rem', maxWidth:712}}
                        >
                            <TextFieldComponent
                                xs={6}
                                typeVariant="subtitle1"
                                typeClass="field-label"
                                fieldLabel="Título"
                                fieldID="analysis-adm-new-req-title"
                                fieldClass="fields"
                                fieldVariant="outlined"
                                placeholder="Título requerimiento..."
                                margin='dense'
                                fullWidth
                                value={description}
                                onChange={handleDescription}
                                disabled={props.ticketCompleted}
                            />
                            <CheckboxField
                                xs={3}
                                alignItems="flex-end"
                                check={isRequired}
                                handleChange={handleIsRequired}
                                color="primary"
                                textColor="#43A047"
                                label="REQUERIDO"
                                disabled={props.ticketCompleted}
                            />
                            <Grid container item xs={3} justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    className={"action-method-btn"}
                                    disableElevation
                                    style={{height:30, marginLeft:'.5rem', marginBottom:8}}
                                    onClick={handleAddRequirement}
                                    disabled={props.ticketCompleted}
                                >
                                    <Add style={{fontSize:17, color:'#fff', marginRight:'.2rem'}} />
                                    agregar
                                </Button>
                            </Grid>
                        </Grid>

                        <Paper style={{maxWidth:700}}>
                            <List
                                subheader={
                                    <ListSubheader component="div" id="requirements-list" style={{fontSize:20}}>
                                        Lista de requerimientos
                                    </ListSubheader>
                                }
                            >   
                                {
                                    requirements.length > 0 && requirements.map((item, index) => {
                                        return(
                                            <div key={index}>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Grade style={{color:item.required ? '#f76248' : 'transparent', fontSize:18}} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={item.text}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton 
                                                            edge="end" 
                                                            onClick={props.handleToggleDialog.bind(this, 'manage-files-dialog', {
                                                            id: props.ticket.uniqueId,
                                                            requirementName: item.text,
                                                            ticketId: props.ticket.uniqueId,
                                                            step: props.currentStep,
                                                            allReqs: true,
                                                            handleFileRequirements: handleFileRequirements,
                                                        })}>
                                                            <AttachFile/>
                                                        </IconButton>
                                                        {
                                                            item.step === props.currentStep ?
                                                            <IconButton edge="end" onClick={handleDeleteRequirement.bind(this, item)}>
                                                                <DeleteOutline />
                                                            </IconButton>
                                                            :
                                                            <IconButton edge="end" style={{color: 'transparent'}}>
                                                                <FiberManualRecord />
                                                            </IconButton>
                                                        }

                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                {
                                                    (index+1) !== requirements.length &&
                                                    <Divider />
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </List>
                        </Paper>
                    </Grid>
                }
        </div>
    )
}
function ClientEnrol(props) {

    // const [paymentMethod, setPaymentMethod] = useState(null);
    const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);
    const [forms, setForms] = useState({});
    const [expand, setExpand] = useState(false);
    const [selectedContract, setSelectedContract] = useState('savings_account');
    const [selectedContractIds, setSelectedContractIds] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    // const paymentMethods = [
    //     {name: 'Transferencia', value:'transfer'},
    //     {name: 'Cheque', value:'check'},
    //     {name: 'Efectivo', value:'cash'},
    // ];
    const handleSelectContract = (type, event) => {
        setSelectedContract(event.target.value);
    }

    // const handlePaymentMethod = (event, value) => {
    //     setPaymentMethod(value);
    // }

    const handleClickExpand = (form, event, isExpanded) => {
        setExpand(isExpanded ? form : false);
    }

    const handleGetStepInfo = () => {
        let forms = {}
        props.ticket.steps
            .find(item => item.step === props.currentStep).requirements
            .filter(item => item.type === 'form')
            .forEach(item => {
                forms[item.name] = item.value;
            });
        
        const paymentMethods = 
            props.ticket.steps.find(item => item.step === props.currentStep).requirements
            .filter(item => item.type === 'text').find(item => item.name === 'paymentMethod').value
        
        setTableData((paymentMethods && paymentMethods.length) ? paymentMethods : []);
        setForms(forms);
    }

    const getTicket = () => {
        const endpoint = fetchTicket(props.token, props.ticket.uniqueId);
        endpoint.then(response => {
            handleGetStepInfo(response.data);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    const handleFileRequirements = (steps, refreshTicket) => {
        ticketHistory(steps, props).then((response) => {
            const history = response;
            props.modifyTicketStep(steps, props.ticket, history, refreshTicket);
        });
    }

    const handleSaveForm = (formName, id, deleting) => {
        props.ticket.steps.find(item => item.step === props.currentStep).requirements
        .forEach(item => {
            if(item.name === formName && formName === 'savingAccountExtra') {
                const contract = item.value.find(sa => sa.name === selectedContract);
                if(item.value.length > 0 && contract) {
                    if(!contract.value.includes(id)) {
                        contract.value.push(id);
                    }
                    else if(contract.value.includes(id) && deleting) {
                        const arrIndex = contract.value.indexOf(id);
                        if(arrIndex !== -1) { contract.value.splice(arrIndex, 1); }
                    }
                    return;
                }
                const data = {
                    name: selectedContract,
                    value: [id],
                }
                item.value.push(data);
                return;
            }
            if(item.name === formName) {
                item.value = id;
            }
        });
        handleFileRequirements(props.ticket.steps, getTicket);
    }

    const handleDeleteForm = (id) => {
        handleSaveForm('savingAccountExtra', id, true);
    }

    const handleCompleteStep = () => {
        // verify the requirements

        if(tableData.length < 1) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe seleccionar algún método de pago.`);
            return;
        }
        const fieldNotSet = tableData.find(item => (!item.paymentType || !item.amount));
        if(fieldNotSet) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe seleccionar algún método de pago y colocarle un monto`);
            return;
        }

        let proceed = false;
        props.ticket.steps.find(item => item.step === props.currentStep).requirements
        .find(item => item.name === 'paymentMethod').value = tableData;

        const requirementsState = 
            props.ticket.steps.find(item => item.step === props.currentStep).requirements
            .find(item => item.required && !item.value);

        proceed = requirementsState ? false : true;
        // handleFileRequirements(step, getTicket);
        handleNextStep(proceed, props, props.ticket.steps, getTicket);
    }

    useEffect(() => {
        getTicket();

    }, [props.reloadTicket]);

    useEffect(() => {
        handleGetStepInfo();
        handleGetBankAccounts(props, setBankAccounts);
    }, [props.ticket, props.reloadTicket]);

    useEffect(() => {
        if(Object.keys(forms).length > 0) {
            const data = 
                forms.savingAccountExtra
                .filter(item => item.name === selectedContract)
                .map(item => item.value)[0] || [];
            setSelectedContractIds([...data]);
        }
    }, [forms, selectedContract]);

    return(
        <div style={{padding: '16px'}}>
            <Grid container item xs={12} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <StepStatus
                    roles={props.roles} 
                    completed={stepStatus}
                    handleNextStep={handleCompleteStep}
                    ticketCompleted={props.ticketCompleted}
                />
            </Grid>

            <Grid container item xs={12} justifyContent="space-between" direction="column" style={{margin: '2rem 0 1rem 0'}}>
                <FormWrapper 
                    title={"Certificado de aportación"}
                    name={'inputCertificate'}
                    contentType={'Formulario'}
                    ticketCompleted={props.ticketCompleted}
                    form=
                    {
                        <>
                            {
                                forms.inputCertificate &&
                                <InputCertificationDetail
                                    id={forms.inputCertificate}
                                />
                            }
                            <div style={{display:forms.inputCertificate?'none':'block'}}>
                                <InputCertificationForm 
                                    handleSaveCallBack={handleSaveForm.bind(this, 'inputCertificate')}
                                    debtor={[props.ticket.customer]}
                                />
                            </div>
                        </>
                    }
                    handleExpand={handleClickExpand}
                    expand={expand === 'inputCertificate'}
                />
                <FormWrapper 
                    title={"Cuenta de ahorro a la vista"}
                    name={'savingAccount'}
                    contentType={'Formulario'}
                    ticketCompleted={props.ticketCompleted}
                    form={
                        <>
                            {
                                forms.savingAccount &&
                                <SavingAccountDetail 
                                    savingsAccountContractId={forms.savingAccount}
                                    fromTicketType='in_sight'
                                />
                            }
                            <div style={{display:forms.savingAccount?'none':'block'}}>
                                <SavingAccountForm 
                                    handleSaveCallBack={handleSaveForm.bind(this, 'savingAccount')}
                                    debtor={[props.ticket.customer]}
                                    fromTicketType='in_sight'
                                />
                            </div>
                        </>
                    }
                    handleExpand={handleClickExpand}
                    expand={expand === 'savingAccount'}
                />
                <FormWrapper 
                    title={"Contrato de manejo de cuenta"}
                    name={'accountManagementContract'}
                    contentType={'Formulario'}
                    ticketCompleted={props.ticketCompleted}
                    form={
                        <>
                            {
                                forms.accountManagementContract &&
                                <AccountManagementContractDetail
                                    id={forms.accountManagementContract}
                                />
                            }
                            <div style={{display:forms.accountManagementContract?'none':'block'}}>
                                <AccountManagementContractForm 
                                    handleSaveCallBack={handleSaveForm.bind(this, 'accountManagementContract')}
                                    debtor={[props.ticket.customer]}
                                />
                            </div>
                        </>
                    }
                    handleExpand={handleClickExpand}
                    expand={expand === 'accountManagementContract'}
                />
                <FormWrapper 
                    title={"Tabla solicitud de cuentas"}
                    name={'savingAccountExtra'}
                    contentType={'Cuentas extra'}
                    ticketCompleted={props.ticketCompleted}
                    form={
                        forms.savingAccount ?
                        <SavingCertificateInput
                            // handleSelectChange={handleSaveForm.bind(this, 'savingAccountExtra')}
                            // handleSelectChange={handleSaveForm.bind(this, 'savingAccountExtra')}
                            type={selectedContract}
                            addContract={handleSaveForm.bind(this, 'savingAccountExtra')}
                            deleteContract={handleDeleteForm}
                            handleSelectChange={handleSelectContract}
                            selectedId={selectedContractIds}
                        />
                        :
                        <Typography
                            variant={"subtitle1"}
                            className={"section-header"}
                            style={{
                                fontWeight: 'bold', color:'#424242', fontStyle:'italic', 
                                marginBottom: '2rem', display:'flex', alignItems:'center'
                            }}
                        >
                            <Info style={{color: '#1fbded', fontSize:25, marginRight:'.7rem',}} />
                            Debe llenar el formulario de cuentas de ahorro
                        </Typography>
                    }
                    handleExpand={handleClickExpand}
                    expand={expand === 'savingAccountExtra'}
                />
                <div style={{marginTop:'1rem'}} />
                <PaymentMethodsTable
                    data={tableData}
                    setTableData={setTableData}
                    bankAccounts={bankAccounts}
                    disableFields={true}
                    disabled={
                        props.ticketCompleted || 
                        props.ticket.steps.find(item => item.step === 'client_enrol').status === 'completed'
                    }
                />
                {/* <AutocompleteField
                    xs={4}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Forma de pago"
                    fieldID="client-enrol-payment-method"
                    fieldInnerLabel="Seleccione..."
                    fieldVariant="outlined"
                    value={paymentMethod}
                    handleChange={handlePaymentMethod}
                    options={paymentMethods}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.value === value.value}
                    disabled={props.ticketCompleted}
                /> */}
            </Grid>
        </div>
    )
}
function PaymentValidation(props) {

    const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);
    const [paymentVerified, setPaymentVerified] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    const handlePaymentVerified = (event, value) => {
        setPaymentVerified(value);
    }

    const getStepRequirements = () => {
        const ticket = {...props.ticket};
        const payment =
            ticket.steps.find(item => item.step === props.currentStep)
            .requirements.find(item => item.name === 'paymentVerified').value;

        let currStepPayment = 
            ticket.steps.find(item => item.step === props.currentStep)
            .requirements.find(item => item.name === 'referenceNumber').value;

        let clientEnrolPayment = 
            ticket.steps.find(item => item.step === 'client_enrol')
            .requirements.find(item => item.name === 'paymentMethod').value;
        
        currStepPayment = currStepPayment && typeof currStepPayment === 'object' ? currStepPayment : [];
        clientEnrolPayment = clientEnrolPayment && typeof clientEnrolPayment === 'object' ? clientEnrolPayment.length > 0 && clientEnrolPayment : [];
        const paymentMethod = currStepPayment.length > 0 ? currStepPayment : clientEnrolPayment || [];
        setTableData(paymentMethod);
        setPaymentVerified(payment);
    }

    const getTicket = () => {
        const endpoint = fetchTicket(props.token, props.ticket.uniqueId);
        endpoint.then(response => {
            //
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    const handleCompleteStep = () => {
        // verify the requirements
        let proceed = false;

        if(tableData.length < 1) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe seleccionar algún método de pago.`);
            return;
        }
        const fieldNotSet = tableData.find(item => (!item.paymentType || !item.amount || !item.referenceNumber));
        if(fieldNotSet) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe llenar todos los campos del método seleccionado`);
            return;
        }

        props.ticket.steps.find(item => item.step === props.currentStep).requirements
        .forEach(item => {
            switch(item.name) {
                case 'paymentVerified':
                    item.value = paymentVerified;
                    break;
                case 'referenceNumber':
                    item.value = tableData;
                    break;
                default:
                    break;
            }
        });
        const requirementsState = 
            props.ticket.steps.find(item => item.step === props.currentStep).requirements
            .find(item => item.required && !item.value);

        proceed = requirementsState ? false : true;
        handleNextStep(proceed, props, props.ticket.steps, getTicket);
    }
    useEffect(() => {
        // getTicket();
        getStepRequirements();
        handleGetBankAccounts(props, setBankAccounts);
    }, [props.reloadTicket]);
    return(
        <div style={{padding: '16px'}}>
            <Grid container item xs={12} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <StepStatus
                    roles={props.roles} 
                    completed={stepStatus}
                    handleNextStep={handleCompleteStep}
                    ticketCompleted={props.ticketCompleted}
                />
            </Grid>

            <div style={{display:'flex', flexDirection: 'row', marginBottom:'1rem'}}>
                <CheckboxField
                    xs={12}
                    alignItems="flex-end"
                    check={paymentVerified}
                    handleChange={handlePaymentVerified}
                    color="primary"
                    textColor="#43A047"
                    label="PAGO VERIFICADO"
                    // TEMPORARILY REMOVED DISABLE WHEN STEP COMPLETE DUE TO OLD TICKET STRUCTURE
                    // disabled={props.ticketCompleted || stepStatus}
                    disabled={props.ticketCompleted}
                />
            </div>
            {
                paymentVerified ?
                    <PaymentMethodsTable
                        data={tableData}
                        setTableData={setTableData}
                        bankAccounts={bankAccounts}
                        // TEMPORARILY REMOVED DISABLE WHEN STEP COMPLETE DUE TO OLD TICKET STRUCTURE
                        // disabled={props.ticketCompleted || stepStatus}
                        disabled={props.ticketCompleted}
                    />
                :
                    <div style={{width:'100%', height:250, backgroundColor:'#f7f7f7', borderRadius:4}} />
            }
        </div>
    )
}
function DocumentsIssuance(props) {

    const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);
    const [printDocument, setPrintDocument] = useState(null);
    const [requirements, setRequirements] = useState([]);
    const [currentTicket ,setCurrentTicket] = useState(props.ticket);

    let printOptions = [{text: 'Imprimir Plantilla de Firmas', value: '/Templates/tarjeta_de_firma.pdf', download: true}];
    props.ticket.steps.map(item => item.requirements).forEach(item => {
        if(item) {
            const data = item.filter(r => (r.type === 'form' && typeof r.value === 'string'));
            printOptions = printOptions.concat(data);
        }
    });

    const handlePrintOptions = (event, value) => {
        setPrintDocument(value);
    }

    const getTicket = () => {
        const endpoint = fetchTicket(props.token, props.ticket.uniqueId);
        endpoint.then(response => {
            setCurrentTicket(response.data);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    const handleFileRequirements = (steps, refreshTicket) => {
        ticketHistory(steps, props).then((response) => {
            const history = response;
            props.modifyTicketStep(steps, props.ticket, history, refreshTicket);
        });
    }

    const handleDeleteRequirement = (req) => {
        const modifyReqs = [...currentTicket.steps];
        const reqIndex = requirements.findIndex(item => item.text === req.text);
        modifyReqs.find(item => item.fileRequirements).fileRequirements.splice(reqIndex, 1);
        handleFileRequirements(modifyReqs, getTicket);
    }

    const getStepRequirements = () => {
        // get and set requirements fields and files.
        const reqs = 
            props.ticket.steps
            .find(item => item.fileRequirements).fileRequirements
            .sort((a,b) => (a.required < b.required) ? 1 : -1);
        setRequirements(reqs);
    }

    const downloadTemplate = () => {
        let link = document.createElement('a');
        const fileName = printDocument.value.split("/").pop();
        link.download = fileName;
        link.href = printDocument.value;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleCompleteStep = async () => {
        props.handleToggleSimpleDialog();
        const checkActNumber = props.ticket.steps.find(item => item.approvalObj).approvalObj.actNumber;
        if(!checkActNumber) {
            props.handleToggleSimpleDialog('simple-dialog', `El ticket no tiene número de acta asignado.`);
            return;
        }
        await handleNextStep(true, props, props.ticket.steps, getTicket, 'finalStep');
        props.handleToggleSimpleDialog();
    }

    useEffect(() => {
        getStepRequirements();
    }, [props.reloadTicket]);

    useEffect(() => {
    }, [props.ticket, props.reloadTicket]);

    return(
        <div style={{padding: '16px'}}>
            <Grid container item xs={12} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <StepStatus
                    completed={stepStatus}
                    roles={props.roles}
                    finalStep={true}
                    handleNextStep={
                        props.handleToggleSimpleDialog
                        .bind(this, 'confirm-dialog', 'proceed-complete-ticket', {callback: handleCompleteStep})
                    }
                    ticketCompleted={props.ticketCompleted}
                    
                />
            </Grid>

            <Grid container item xs={8} justifyContent="space-between" direction="column" style={{margin: '2rem 0 1rem 0'}}>
                <Typography
                    variant={"subtitle1"}
                    className={"section-header"}
                    style={{fontWeight: 'bold', color:'#878787', display:'flex', alignItems:'center'}}
                >
                    Firma de libro de seguimiento
                </Typography>
                <Divider style={{margin:'1rem 0'}}/>

                <Typography
                    variant={"subtitle1"}
                    className={"section-header"}
                    style={{
                        fontWeight: 'bold', color:'#424242', fontStyle:'italic', 
                        marginBottom: '2rem', display:'flex', alignItems:'center'
                    }}
                >
                    <Info style={{color: '#1fbded', fontSize:25, marginRight:'.7rem',}} />
                    Recuerde firmar el libro de seguimiento.
                </Typography>

                <Typography
                    variant={"subtitle1"}
                    className={"section-header"}
                    style={{fontWeight: 'bold', color:'#878787'}}
                >
                    Validación de documentos creados y pendientes
                </Typography>
                <Divider style={{margin:'1rem 0'}}/>
                <Paper style={{border: '1px solid #ebebeb', borderRadius:5, margin:'1rem 0', width:'40rem'}}>
                    <List
                        subheader={
                            <ListSubheader component="div" id="requirements-list" style={{fontSize:20}}>
                                Lista de requerimientos
                            </ListSubheader>
                        }
                    >   
                        {
                            requirements.length > 0 && requirements.map((item, index) => {
                                return(
                                    <div key={index}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Grade style={{color:item.required ? '#f76248' : 'transparent', fontSize:18}} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={item.text}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton 
                                                    edge="end" 
                                                    onClick={props.handleToggleDialog.bind(this, 'manage-files-dialog', {
                                                        id: props.ticket.uniqueId,
                                                        requirementName: item.text,
                                                        ticketId: props.ticket.uniqueId,
                                                        step: props.currentStep,
                                                        allReqs: true,
                                                        handleFileRequirements: handleFileRequirements,
                                                    })}
                                            >
                                                    <AttachFile/>
                                                </IconButton>
                                                {
                                                    item.step === props.currentStep ?
                                                    <IconButton edge="end" onClick={handleDeleteRequirement.bind(this, item)}>
                                                        <DeleteOutline />
                                                    </IconButton>
                                                    :
                                                    <IconButton edge="end" style={{color: 'transparent'}}>
                                                        <FiberManualRecord />
                                                    </IconButton>
                                                }

                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {
                                            (index+1) !== requirements.length &&
                                            <Divider />
                                        }
                                    </div>
                                )
                            })
                        }
                    </List>
                </Paper>
                <Typography
                    variant={"subtitle1"}
                    className={"section-header"}
                    style={{fontWeight: 'bold', color:'#878787'}}
                >
                    Impresión final de documentos
                </Typography>
                <Divider style={{margin:'1rem 0'}}/>

                <Grid item container xs={12} direction="row" alignItems="flex-end" style={{marginBottom:'1rem'}}>
                    <AutocompleteField
                        xs={6}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Documentos"
                        fieldID="analysis-adm-approve"
                        fieldInnerLabel="Seleccione..."
                        fieldVariant="outlined"
                        value={printDocument || null}
                        handleChange={handlePrintOptions}
                        options={printOptions}
                        getOptionLabel={(option) => option.text}
                        getOptionSelected={(option, value) => option.value === value.value}
                    />
                    <Button
                        variant="contained"
                        className={
                            !printDocument ?
                            "action-method-btn disabled" : "action-method-btn"
                        }
                        disableElevation
                        style={{width:'10rem', height:40, marginLeft:'.5rem'}}
                        onClick={
                            printDocument && printDocument.download ?
                            downloadTemplate
                            :
                            generateDocument.bind(this, 
                                {
                                    debtor: props.ticket.customer,
                                    token: props.token,
                                    setLoading: props.setLoading, 
                                    handleToggleSimpleDialog: props.handleToggleSimpleDialog,
                                }, 
                                printDocument
                            )
                        }
                        disabled={!printDocument}
                    >
                        <Print style={{fontSize:20, color:'#fff', marginRight:'.2rem'}} />
                        imprimir
                    </Button>
                </Grid>
                
            </Grid>
        </div>
    )
}
function AdmissionRequestDetail(props) {
    return(
        <div>
            <TabsStyled
                value={props.currentTab}
                onChange={props.handleTabChange}
                variant="scrollable"
                scrollButtons="on"
                aria-label="ticket-step-tabs"
            >
                <TabStyled label="Adquisición de datos" />
                <TabStyled label="Levantamiento requisitos" disabled={ props.disabledTabs.length > 0 && props.findStep('requirements_gathering')} />
                <TabStyled label="Análisis para admisión" disabled={props.disabledTabs.length > 0 && props.findStep('membership_analysis')} />
                <TabStyled label="Enrolamiento socio y cuentas" disabled={props.disabledTabs.length > 0 && props.findStep('client_enrol')} />
                <TabStyled label="Validación de pago" disabled={props.disabledTabs.length > 0 && props.findStep('payment_validation')} />
                <TabStyled label="Emisión y entrega documentos" disabled={props.disabledTabs.length > 0 && props.findStep('documents_issuance')} />
            </TabsStyled>
            {
                props.currentTab === 0 &&
                <DataAdquisition 
                    {...props}
                    currentStep={'data_adquisition'}
                />
            }
            {
                props.currentTab === 1 &&
                <Requirements
                    {...props}
                    currentStep={'requirements_gathering'}
                />
            }
            {
                props.currentTab === 2 &&
                <MembershipAnalysis 
                    {...props}
                    currentStep={'membership_analysis'}
                />
            }
            {
                props.currentTab === 3 &&
                <ClientEnrol
                    {...props}
                    currentStep={'client_enrol'}
                />
            }
            {
                props.currentTab === 4 &&
                <PaymentValidation
                    {...props}
                    currentStep={'payment_validation'}
                />
            }
            {
                props.currentTab === 5 &&
                <DocumentsIssuance
                    {...props}
                    currentStep={'documents_issuance'}
                />
            }
        </div>
    )
}

export { AdmissionRequestDetail }