import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { ToolbarComponent } from "src/components/SharedComponents/Toolbar";
import Filters from "../filters";
import DiscountsTable from "../tables";
import { errorHandling } from "src/components/SharedComponents/CustomHooks";
import { API_KEY, fetchInstitutions, http } from "src/utils/axios";
import LoadingDialog from "src/components/SharedComponents/LoadingScreen";
import { paymentFrequency, translations } from "src/utils";
import { ErrorDialog, SimpleConfirmDialog } from "src/components/SharedComponents/Dialogs";

const useStyles = makeStyles({
  root: {
    padding: "20px 20px 10% 20px",
  },
  content: {
    padding: "1%",
    border: "1px solid #EEEEEE",
    borderTop: "none",
  },
  filters: {
    marginTop: "35px",
    justifyContent: "space-between",
  },
});

export const institutionTypes = [
    { value: "financial", label: translations.FINANCIAL },
    { value: "private", label: translations.PRIVATE },
    { value: "public", label: translations.PUBLIC },
  ];

function DiscountReports(props) {
  const classes = useStyles();
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({})
  const [simpleDialog, setSimpleDialog] = useState("")

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : '', msg: msg, data: data };
    setSimpleDialog({ ...obj });
    }

  const getInstitutions = async (filters) => {
    setLoading(true);
    const filter = filters ? filters.uniqueId : null;
    try {
      const response = await fetchInstitutions({
        token: props.user.token,
        limit: 100,
        intitutionsId: filter,
      });
      const institutions = response.data.results ? response.data.results : response.data
      const formattedData = institutions.map((inst)=>{
        const pFreq = paymentFrequency.find(pf => pf.value === inst.paymentFrequency);
        const instType = institutionTypes.find(it => it.value === inst.institutionType);
        const obj = {
            ...inst,
            paymentFrequency: pFreq ? pFreq.label : "",
            institutionType: instType ? instType.label : ""
        }
        return obj;
      })
      setInstitutions(formattedData);
      setLoading(false);
    } catch (errors) {
      setLoading(false);
      console.error(errors);
    }
  };

  const ValidatedData = () => {
    const missingRoHiIdPatrono = selected.some(
      (institution) => !institution.rohiIdPatrono && institution.institutionType === "Financieras"
    );
      
    const missingRoHiId = selected.some(
      (institution) => !institution.rohiId && institution.institutionType !== "Financieras"
    );
  
    if (missingRoHiIdPatrono  || missingRoHiId) {
      handleToggleSimpleDialog(
        "simple-dialog",
        "Una o varias de las instituciones seleccionadas no poseen ID Patrono o Rohi ID."
      );
      return false;
    }
  
    const missingVencDate =
      !selectedFilters.venc_date || selectedFilters.venc_date.trim() === "";
  
    if (missingVencDate) {
      handleToggleSimpleDialog(
        "simple-dialog",
        "Debe llenar el filtro de fecha de vencimiento."
      );
      return false;
    }
  
    return true;
  };
  
  const getRequestData = () => {
    const idPatronoValues = selected.map(item => item.rohiIdPatrono).join(',');
    const rohiId = selected.map(item => item.rohiId).join(',');
    const isFinancial = selected.some(item => item.institutionType === 'Financieras');
    const vencDateValue = selectedFilters.venc_date || '';
  
    const idPatronoOrRohiId = isFinancial ? idPatronoValues : rohiId;
  
    const body = {
      id_patrono: idPatronoOrRohiId,
      venc_date: vencDateValue,
    };
  
    return body;
  };
  

  const generateFile = async () => {
    if (ValidatedData()) {
      try {
        const requestData = getRequestData();
        const response = await http.post(`/utility/rohi-payroll-payments/`, requestData, {
          headers: {
            Authorization: 'Bearer ' + props.user.token,
          },
        });
  
        const file = new Blob([response.data], { type: response.headers['content-type'] });
        const fileURL = URL.createObjectURL(file);
  
        const NewWindow = window.open();
        if (NewWindow) {
          NewWindow.location.href = fileURL;
        } else {
          console.error('No se pudo abrir una nueva ventana.');
        }
      } catch (error) {
        console.error(error);
  
        // Verificar si el error tiene un mensaje del servidor
        const errorMessage = error.response ? error.response.data : 'Hubo un error en la solicitud.';
  
        handleToggleSimpleDialog(
          "simple-dialog",
          errorMessage
        );
      }
    }
  };
  

  const applyFilters = (filters) =>{
    getInstitutions(filters);
    setSelectedFilters(filters);
  }

  const handleSelected = (selection) => {
    setSelected(selection);
  };

  useEffect(() => {
    getInstitutions();
  }, []);

  return (
    <Grid className={classes.root}>
      <LoadingDialog open={loading} />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle="Reporte de descuentos por nómina"
        btnVariant="contained"
      />
      <Grid className={classes.content}>
        <Filters classes={classes.filters} applyFilters={applyFilters}/>
        <div style={{margin:'15px 0'}}></div>
        <DiscountsTable
          data={institutions}
          selected={selected}
          setSelected={setSelected}
          handleSelected={handleSelected}
          generateFile={generateFile}
        />
      </Grid>

      {
               simpleDialog.name === 'simple-dialog' ?
               <SimpleConfirmDialog
                   open={true}
                   handleToggle={handleToggleSimpleDialog}
                   data={simpleDialog.msg}
               /> : null
            }
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(DiscountReports);
