import * as React from 'react';
import { translations, operatorList } from 'src/utils';
import { connect } from "react-redux";


import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';

import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';

import { notarialPayStatus } from '../../../utils'
import moneyInput from '../../../components/TextMaskInputComponents/moneyInput';
import attorneyNumberMaskInput from '../../../components/TextMaskInputComponents/attorneyNumberMaskInput'

import { SearchComponent } from "../../../components/SharedComponents/Fields";

import ParticipantAutoComplete from 'src/components/Filters/ParticipantAutoComplete';



const styles: any = createStyles({
    formControl: {
        marginTop: 20
    },
    greenButton: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    label: {
        color: green[500]
    },
    redButton: {
        '&:hover': {
            backgroundColor: red[200]
        },
        backgroundColor: red[500],
        color: 'white'
    },
    textField: {
        marginTop: 10,
        minWidth: 190
    }
});

interface INotarialPayContractSearchFilterProps {
    token: string;
    user: any;
    open: boolean;
    classes: {
        [key: string]: string;
    }
    handleClose(): void;
    search(params: IFetchNotarialPayContractParams): void;
}

interface INotarialPayContractSearchFilterState {
    [key: string]: string | number | any;
    act_number: string;
    attorney_unique_id: any;
    contract_date_from: string;
    contract_date_to: string;
    creditor_unique_id: any;
    debtor_unique_id: any;
    act_number_from: string;
    act_number_to: string;
    amount_from: any;
    amount_to: any;
    amount: any;
    amount_options: string;
    status: any;
    title: string;
}

class NotarialPayContractSearchFilter extends React.Component<INotarialPayContractSearchFilterProps, INotarialPayContractSearchFilterState>{
    public state: INotarialPayContractSearchFilterState = {
        act_number: '',
        attorney_unique_id: [],
        contract_date_from: '',
        contract_date_to: new Date().toISOString().split('T')[0],
        creditor_unique_id: [],
        debtor_unique_id: [],
        act_number_from: '',
        act_number_to: '',
        amount_from: '',
        amount_to: '',
        amount: '',
        amount_options: 'equal',
        status: [],
        title: '',
    };
    constructor(props: INotarialPayContractSearchFilterProps) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    public handleSearch() {
        const params: IFetchNotarialPayContractParams = {
            attorneyUniqueId: this.state.attorney_unique_id.map((item: IParticipant) => item.uniqueId).join(','),
            contractDateFrom: this.state.contract_date_from,
            contractDateTo: this.state.contract_date_to,
            creditorUniqueId: this.state.creditor_unique_id.map((item: ICreditor) => item.uniqueId).join(','),
            debtorUniqueId: this.state.debtor_unique_id,
            actNumberTo: this.state.act_number_to,
            actNumberFrom: this.state.act_number_from,
            amount: this.state.amount,
            amountTo: this.state.amount_to,
            amountFrom: this.state.amount_from,
            status: this.state.status.map((item: any) => item.value).join(','),
            title: this.state.title,
            token: this.props.token,
        };
        this.props.search(params);
        this.props.handleClose();
    }
    public handleClear() {
        this.setState({
            attorney_unique_id: [],
            contract_date_from: '',
            contract_date_to: new Date().toISOString().split('T')[0],
            creditor_unique_id: [],
            debtor_unique_id: [],
            act_number_from: '',
            act_number_to: '',
            amount: '',
            amount_from: '',
            amount_to: '',
            status: [],
            title: '',
        });
        this.props.search({ token: this.props.token })
        this.props.handleClose();
    }
    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({ [key]: event.target.value });
        }
    }
    public handleCurrencyInputChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>, maskedValue?: string, floatValue?: string) => {
            this.setState({ [key]: floatValue || '' });
        }
    }

    public handleAutocompleteChange(key: any, obj: any, val: any) {
        /* Handle the state of Autocomplete components */
        const data = obj || val;

        this.setState({
            [key]: data.value ? data.value : ""
        });

    }
    public render() {
        const { classes, handleClose, open } = this.props;

        const handleChange2 = (key: string, val: string) =>
            this.setState({ [key]: val });

        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12}>
                            <SearchComponent
                                xs={12}
                                containerClass="field-container"
                                fieldClass={{ style: styles.label }}
                                typeClass={styles.label}
                                fieldID="client"
                                fieldInnerLabel={translations.DEBTOR}
                                handleChange={this.handleAutocompleteChange.bind(this, "debtor_unique_id")}
                                optionType={'participants'}
                                variant="standard"
                                limitTags={1}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                label={translations.TITLE}
                                value={this.state.title}
                                fullWidth={true}
                                onChange={this.handleChange('title')}
                                InputLabelProps={{
                                    style: styles.label,
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <FormControl fullWidth={true}>
                                <InputLabel
                                    htmlFor="notarial_status"
                                    style={styles.label}
                                    shrink={true}
                                >
                                    {translations.STATUS}
                                </InputLabel>
                                <Autocomplete
                                    style={{ marginTop: "16px" }}
                                    multiple={true}
                                    options={notarialPayStatus}
                                    value={this.state.status}
                                    getOptionLabel={(option: any) => option.label}
                                    onChange={(e, newValue: any) =>
                                        handleChange2("status", newValue)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <ParticipantAutoComplete
                                handleSelectChange={(e, newValue: any) =>
                                    handleChange2("creditor_unique_id", newValue)
                                }
                                value={this.state.creditor_unique_id}
                                type={"creditor"} label={translations.CREDITOR} />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <ParticipantAutoComplete
                                handleSelectChange={(e, newValue: any) =>
                                    handleChange2("attorney_unique_id", newValue)
                                }
                                value={this.state.attorney_unique_id}
                                type={"attorney"} label={translations.ATTORNEY} />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.ACT_NUMBER} ${translations.FROM}`}
                                value={this.state.act_number_from}
                                fullWidth={true}
                                onChange={this.handleChange('act_number_from')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                                InputProps={{
                                    inputComponent: attorneyNumberMaskInput
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.ACT_NUMBER} ${translations.TO}`}
                                value={this.state.act_number_to}
                                fullWidth={true}
                                onChange={this.handleChange('act_number_to')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                                InputProps={{
                                    inputComponent: attorneyNumberMaskInput
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={4}>
                            <InputLabel htmlFor="amount_options" shrink={true}>{translations.NOTARIAL_AMOUNT}</InputLabel>
                            <Select
                                style={{ width: "100%" }}
                                value={this.state.amount_options}
                                onChange={this.handleSelectChange('amount_options')}
                                inputProps={{
                                    id: 'amount_options',
                                    name: 'amount_options'
                                }}
                            >
                                {operatorList.map((item, index) => {
                                    return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                })}
                            </Select>
                        </Grid>
                        {this.state.amount_options === "equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.amount}
                                    style={{ marginTop: "16px" }}
                                    onChange={this.handleCurrencyInputChange('amount')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                        {this.state.amount_options === "between" ?
                            <>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        value={this.state.amount_from}
                                        style={{ marginTop: "16px" }}
                                        onChange={this.handleCurrencyInputChange('amount_from')}
                                        InputProps={{
                                            inputComponent: moneyInput,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.label
                                        }}
                                    />
                                </Grid>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        value={this.state.amount_to}
                                        style={{ marginTop: "16px" }}
                                        onChange={this.handleCurrencyInputChange('amount_to')}
                                        InputProps={{
                                            inputComponent: moneyInput,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.label
                                        }}
                                    />
                                </Grid>
                            </> : null
                        }
                        {this.state.amount_options === "greater_than_equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.amount_from}
                                    style={{ marginTop: "16px" }}
                                    onChange={this.handleCurrencyInputChange('amount_from')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                        {this.state.amount_options === "low_than_equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.amount_to}
                                    style={{ marginTop: "16px" }}
                                    onChange={this.handleCurrencyInputChange('amount_to')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.FROM}`}
                                value={this.state.contract_date_from}
                                fullWidth={true}
                                type="date"
                                onChange={this.handleChange('contract_date_from')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.TO}`}
                                value={this.state.contract_date_to}
                                type="date"
                                fullWidth={true}
                                onChange={this.handleChange('contract_date_to')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.redButton} onClick={this.handleClear}>
                        <ClearIcon />
                        &nbsp;
                        {`${translations.CLEAR} ${translations.SEARCH}`}
                    </Button>
                    <Button className={classes.greenButton} onClick={this.handleSearch}>
                        <SearchIcon />
                        &nbsp;
                        {translations.SEARCH}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}
function mapStateToProps(state: IState) {
    return {
        user: state.user,
    };
}

export default withStyles(styles)(connect(mapStateToProps)(NotarialPayContractSearchFilter));