import React, { useEffect, useState, useRef } from "react";
import {
  Button, Card, CardContent, Divider, Grid, Paper,
  TableContainer, Toolbar, Tooltip, Typography
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import {
  Edit, Info, Save
} from "@material-ui/icons";
import axios from "axios";
import ClientDocuments from "src/components/ClientDocuments";
import { errorHandling } from "src/components/SharedComponents/CustomHooks";
import {
  RadioGroupComponent, TextFieldComponent, CheckboxField
} from "src/components/SharedComponents/Fields";
import NumberFormat from "src/components/SharedComponents/NumberFormat";
import { GeneralTable } from "src/components/SharedComponents/Table";
import SettlementFormsTable from "src/components/TicketWorkflows/Outlay/Form";
import AddendumForm from "src/containers/AddendumContract/AddendumContractForm";
import AddendumDetail from "src/containers/AddendumContract/AddendumContractView";
import AutoDebitAuthForm from "src/containers/AutomaticDebitAuthorizationContract/AutomaticDebitAuthorizationContractForm";
import AutoDebitAuthDetail from "src/containers/AutomaticDebitAuthorizationContract/AutomaticDebitAuthorizationContractView";
import DebitAuthorizationForm from "src/containers/AutomaticWithdrawalAuthorizationContract/Form";
import DebitAuthorizationDetail from "src/containers/AutomaticWithdrawalAuthorizationContract/View";
import CheckForm from "src/containers/Check/CheckForm";
import CheckDetail from "src/containers/Check/CheckView";
import FlyerForm from "src/containers/Flier/FlierForm";
import FlyerDetail from "src/containers/Flier/FlierView";
import LoanContractForm from "src/containers/LoanContract/LoanContractForm";
import LoanContractDetail from "src/containers/LoanContract/LoanContractView";
import NotarialPayContractForm from "src/containers/NotarialPayContract/NotarialPayContractForm";
import NotarialPayContractDetail from "src/containers/NotarialPayContract/NotarialPayContractView";
import PayrollDeductionForm from "src/containers/PayrollDeduction/Form";
import PayrollDeductionDetail from "src/containers/PayrollDeduction/View";
import SavingAccountForm from "src/containers/SavingsAccount/Form";
import SavingAccountDetail from "src/containers/SavingsAccount/View";
import {
  fetchOutlayCheck, fetchPotentialMemberValidation, getCreditDebtCapacity, 
  ticketInfo, simpleLoanCalulator, fetchQuota
} from "src/utils/axios";
import { 
  useCheckStepStatus, getClientBankAccounts, getParticipant, getQuotationData, 
  handleDelete, handleManageChecks, handleModify, handleNextStep, handleState, 
  handleViewQuotation, StepStatus, ticketHistory, ticketHistoryGeneral, transaction_types, 
  validateCreditMethodTablesEmpty, validateSettlementTotal, ViewQuotation, FormWrapper, 
  CustomTooltip, DisbursementGeneralBadges, getBankAccounts, ViewQuotationText, 
  SettlementTableActions, getPayCapacityData, getDebtCapacities, getPayCapacity
} from "./../../TicketGeneralMethods";
import { settlementInit } from "./../../TicketGeneralMethods/consts";
import { LoanTransactionTable, DebtCapacityTable, PayCapacityTable } from "./../../Tables";

const detalle_solicitud = [
  {
    label: "CLASIFICACION".toUpperCase(),
    minWidth: 160,
    align: "center",
    element: (row) => row.clasificacion,
  },
  {
    label: "MONTO FINANCIAMIENTO".toUpperCase(),
    minWidth: 160,
    align: "center",
    element: (row) =>
      Number(row.monto_financiamiento).toLocaleString("en-US", {
        style: "currency",
        currency: "DOP",
      }),
  },
  {
    label: "MONTO A PAGAR".toUpperCase(),
    minWidth: 160,
    align: "center",
    element: (row) =>
      Number(row.monto_a_pagar).toLocaleString("en-US", {
        style: "currency",
        currency: "DOP",
      }),
  },

  {
    label: "TASA".toUpperCase(),
    minWidth: 160,
    align: "center",
    element: (row) => row.tasa,
  },
  {
    label: "PLAZO".toUpperCase(),
    minWidth: 160,
    align: "center",
    element: (row) => row.plazo,
  },
];

function TableToolbar(props) {
  return (
    <Toolbar>
      <Grid
        container
        spacing={2}
        direction="row"
        style={{
          paddingTop: "15px",
        }}
      >
        <Grid
          container
          spacing={2}
          direction="row"
          style={{ alignItems: "center" }}
        >
          <Grid item xs={props.rowsPerPage ? 6 : 12}>
            <Typography
              variant="h6"
              className="section-header"
              style={{
                fontWeight: "bold",
                paddingLeft: 16,
                width: "inherit",
              }}
            >
              {props.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

function TableDataComponent(props) {

  const initialData = {
    payCapacityTableData: [], payCapacity: [], suggested: 0, 
    debtCapacities: [], debtCapacity: [], ticketInfoData: [], generalAmounts: {}, 
    potencialDisponible: 0, documentationDetail: [], requestDetailTableData: []
  }
  const [state, setState] = useState(initialData);

  const {
    payCapacityTableData, payCapacity, suggested, 
    debtCapacities, debtCapacity, ticketInfoData,
    generalAmounts, potencialDisponible, documentationDetail,
    requestDetailTableData
  } = state;

  useEffect(() => {
    getTicketInfo();
  }, []);

  useEffect(() => {
    if (props.debtCapacities) {
      handleSetGeneralState("debtCapacities", props.debtCapacities);
    }
    if (props.suggested) {
      handleSetGeneralState("suggested", props.suggested);
    }
    if (props.payCapacity) {
      handleSetGeneralState("payCapacity", props.payCapacity);
    }
    if(props.debtCapacity){
      handleSetGeneralState("debtCapacity", props.debtCapacity);
    }
  }, [props.debtCapacities, props.payCapacity, props.debtCapacity, props.suggested]);

  useEffect(() => {
    getDataAmounts();
  }, [debtCapacity, props.quotationData]);

  useEffect(() => {
    const data = getPayCapacityData(ticketInfoData, debtCapacities, payCapacity);
    handleSetGeneralState("payCapacityTableData", [...data]);
  }, [payCapacity, debtCapacities, ticketInfoData]);

  useEffect(() => {
    const docDetail = ticketInfoData.length > 0 && ticketInfoData[0].detalle_documentacion ? 
    ticketInfoData[0].detalle_documentacion.filter( (item) =>
      item.tipo_producto === transaction_types[props.quotationData.productType]
    ) : [];
    handleSetGeneralState("documentationDetail", docDetail);
  }, [ticketInfoData, props.quotationData]);

  useEffect(() => {
    handleRequestDetailTable();
  }, [documentationDetail, props.quotationData, props.quotationFee, props.settlementData]);

  useEffect(() => {
    const data = 
      (debtCapacity.length > 0 ? debtCapacity[0]?.approvedAmount : 0) -
      (ticketInfoData.length > 0 ? ticketInfoData[0].capacidad_endeudamiento : 0) -
      (["simple_loan", "constant_fees", "decreasing_fees"].includes(props.quotationData.productType) ? props.quotationData.originalAmount : 0);
    handleSetGeneralState("potencialDisponible", data);
  }, [debtCapacity, ticketInfoData, props.quotationData]);

  useEffect(() => {
    const mlc = debtCapacity.length > 0 ? debtCapacity[0]?.approvedAmount : "0";
    const mas = ticketInfoData.length > 0 ? ticketInfoData[0].capacidad_endeudamiento : "0";
    let maf = [
      "simple_loan", "constant_fees", "decreasing_fees", "salary_advance"
    ].includes(props.quotationData.productType) ? props.quotationData.originalAmount : props.quotationFee;
    const dif = Number(mlc) - Number(mas) - Number(maf);
    handleSetGeneralState("generalAmounts", {mlc,mas,maf,dif});
  }, [debtCapacity, ticketInfoData, props.quotationData, props.quotationFee, props.reloadTicket]);

  const handleSetGeneralState = (key, value) => {
    setState(ps => ({...ps, [key]: value}));
  }

  const getDataAmounts = () => {
    let data = [];
    if (debtCapacity.length > 0 && props.quotationData.uniqueId) {
      data = [
        {
          title: "Monto actual de la línea",
          value: debtCapacity[0]?.approvedAmount,
        },
        { title: "Total adeudado", value: debtCapacity[0].totalAmountOwed },
        { title: "Monto a financiar", value: props.quotationData.originalAmount },
        {
          title: "Diferencia",
          value:
            debtCapacity[0]?.approvedAmount -
            debtCapacity[0].totalAmountOwed -
            props.quotationData.originalAmount,
        },
      ];
    } else {
      data = [
        { title: "Monto actual de la línea" },
        { title: "Total adeudado" },
        { title: "Monto a financiar" },
        { title: "Diferencia" },
      ];
    }
    handleSetGeneralState("dataAmounts", data)
  };

  const getTicketInfo = async () => {
    const endpoint = ticketInfo({
      token: props.token,
      associateNumber: props.ticket.participantDetail.rohiId,
    });
    await endpoint.then((response) => {
        const ticketInfoData = response.data.map((item) => {
          return {
            detalle_documentacion: item.detalle_documentacion,
            capacidad_endeudamiento: Number(item.capacidad_endeudamiento),
            capacidad_pagos_info: item.capacidad_pagos_info,
            detalle_atrasos: item.detalle_atrasos ,
            detalle_financiamientos: item.detalle_financiamientos,
            informacion_atrasos: item.informacion_atrasos[0],
            nombre_socio: item.nombre_socio,
            numero_identificacion: item.numero_identificacion,
            total_atraso: item.total_atraso,
          };
        });
        handleSetGeneralState("ticketInfoData", ticketInfoData)
      }).catch((errors) => {
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
  };
  const handleRequestDetailTable = () => {
    let methodTable = null;
    let hasDisbursementExpiration = false;
    methodTable = props.settlementData;
    hasDisbursementExpiration = Object.keys(methodTable?.expiration || {}).length || Object.keys(methodTable?.outlay || {}).length;
    const tableData = [
      // Rohi
      documentationDetail.length > 0
        ? {
            clasificacion: "MONTOS ADEUDADOS (MA)",
            monto_financiamiento:
              documentationDetail[0].saldo_adeudado_producto,
            monto_a_pagar: documentationDetail[0].cuota_prestamo,
            tasa: documentationDetail[0].ultima_tasa_interes,
            plazo: documentationDetail[0].plazo_promedio,
          }
        : {
            clasificacion: "MONTOS ADEUDADOS (MA)",
            monto_financiamiento: 0,
            monto_a_pagar: 0,
            tasa: "---",
            plazo: "---",
          },
      // COTIZACION
      {
        clasificacion: "DATOS COTIZACION (DC)",
        monto_financiamiento: props.quotationData.originalAmount,
        monto_a_pagar: props.quotationFee,
        tasa: props.quotationData.interestRate,
        plazo: props.quotationData.timeLimit,
      },
      // TOTAL
      {
        clasificacion: "TOTAL DOCUMENTACIÓN",
        monto_financiamiento:
          hasDisbursementExpiration ? props.quotationData.originalAmount :
          (documentationDetail.length > 0 ? documentationDetail[0].saldo_adeudado_producto : 0) +
          Number(props.quotationData.originalAmount),
        monto_a_pagar:
          hasDisbursementExpiration ? props.quotationFee :
          (documentationDetail.length > 0 ? Number(documentationDetail[0].cuota_prestamo) : 0) + Number(props.quotationFee),
        tasa:
          documentationDetail.length > 0 ? documentationDetail[0].ultima_tasa_interes : props.quotationData.interestRate,
        plazo: 
          props.quotationData.timeLimit,
      },
    ];
    handleSetGeneralState("requestDetailTableData", tableData);
  }
  return(
    <Grid
      container
      item
      xs={12}
      justifyContent="flex-start"
      direction="column"
    >
      <Divider style={{ margin: "2rem 0" }} />
      <DisbursementGeneralBadges 
        ticketInfoData={ticketInfoData || []}
        customer={props.ticket?.customer}
        token={props.token}
      />
      <DebtCapacityTable 
        data={ticketInfoData}
        debtCapacity={debtCapacity}
        suggested={Number(suggested)}
      />
      <div style={{margin:'1rem 0'}} />
      <PayCapacityTable
        data={payCapacityTableData}
      />
      <Typography
        variant={"subtitle1"}
        className={"section-header"}
        style={{
          fontWeight: "bold",
          color: "#424242",
          marginLeft: 5,
          marginTop: "2rem",
        }}
      >
        TRANSACCIONES DE PRÉSTAMOS
      </Typography>
      <Divider style={{ marginBottom: "2rem" }} />
      <LoanTransactionTable
        ticketInfoData={ticketInfoData}
        token={props.token}
        handleToggleComponent={props.handleToggleComponent}
      />
      <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#424242",
            marginLeft: 5,
            marginTop: "2rem",
          }}
      >
        CAPACIDAD DE ENDEUDAMIENTO
      </Typography>
      <Divider style={{ marginBottom: "2rem" }} />
      <Grid
        item
        container
        xs={12}
        direction="row"
        justifyContent="space-between"
        spacing={2}
        style={{margin: '0 0 2rem 0'}}
      >
        <Paper key={1} style={{width:'23%'}}>
          <CardContent style={{ marginTop: "5px" }}>
            <Typography variant="subtitle1" style={{ color: "#424242" }}>
              <div style={{ display: "table" }}>
                <NumberFormat
                  prefix={"$"}
                  value={generalAmounts.mlc || 0}
                />
              </div>
              <Typography
                variant="overline"
                style={{ color: "#616161", fontSize: "11px" }}
              >
                Monto Linea de Credito
              </Typography>
            </Typography>
          </CardContent>
        </Paper>
        <Paper key={2} style={{width:'23%'}}>
          <CardContent style={{ marginTop: "5px" }}>
            <Typography variant="subtitle1" style={{ color: "#424242" }}>
              <div style={{ display: "table" }}>
                <NumberFormat
                  prefix={"$"}
                  value={generalAmounts.mas || 0}
                />
              </div>
              <Typography
                variant="overline"
                style={{ color: "#616161", fontSize: "11px" }}
              >
                Monto Adeudado Socio
              </Typography>
            </Typography>
          </CardContent>
        </Paper>
        <Paper key={3} style={{width:'23%'}}>
          <CardContent style={{ marginTop: "5px" }}>
            <Typography variant="subtitle1" style={{ color: "#424242" }}>
              <div style={{ display: "table" }}>
                <NumberFormat
                  prefix={"$"}
                  value={generalAmounts.maf || 0}
                />
              </div>
              <Typography
                variant="overline"
                style={{ color: "#616161", fontSize: "11px" }}
              >
                Monto A Financiar
              </Typography>
            </Typography>
          </CardContent>
        </Paper>
        <Paper
          key={4}
          style={{
            width: "23%",
            backgroundColor: potencialDisponible < 0 && red[500],
          }}
        >
          <CardContent style={{ marginTop: "5px" }}>
            <Typography
              variant="subtitle1"
              style={{
                color: potencialDisponible < 0 ? "white" : "#424242",
              }}
            >
              <div style={{ display: "table" }}>
                <NumberFormat
                  prefix={"$"}
                  value={generalAmounts.dif || 0}
                />
              </div>
              <Typography
                variant="overline"
                style={{
                  color: potencialDisponible < 0 ? "white" : "#616161",
                  fontSize: "11px",
                }}
              >
                Potencial Disponible
              </Typography>
            </Typography>
          </CardContent>
        </Paper>
      </Grid>
      <Paper style={{ width: "100%" }}>
        <TableContainer style={{ width: "100%" }}>
          <GeneralTable
            columns={detalle_solicitud}
            data={requestDetailTableData}
            toolbar={<TableToolbar title={"Detalle solicitud"} />}
          />
        </TableContainer>
      </Paper>
    </Grid>
  )
}

export default function ContractValidation(props) {
    const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);
    const getCheckObj = props.ticket.steps
      .find((item) => item.step === "credit_formalization")
      .requirements
      .find((item) => item.name === "checkValidation")?.value;

    const initialState = { 
      dataAmounts: [], questionaryData: [], settlementData: null,
      creditor: {}, creditorAccounts: [], quotationData: {}, 
      clientBankAccounts: [], isEditing: false, previousChecks: null,
      settlementInfo: {}, documents: [], isInBlacklist: false, quotationFee: 0, 
      expand: false, checkValidation: getCheckObj || {},
        
    };

    const [state, setState] = useState(initialState);
    const { 
      dataAmounts, questionaryData, settlementData, creditor, 
      creditorAccounts, debtCapacity, quotationData, clientBankAccounts, 
      isEditing, previousChecks, settlementInfo, documents, isInBlacklist, 
      quotationFee, expand, checkValidation, payCapacity, debtCapacities, suggested
    } = state;

    const [formIds, setFormIds] = useState({});

    useEffect(() => {
      initialFetch();
    }, [props.reloadTicket]);
    
    useEffect(() => {
      getQuestionaryData();
      getTicketRequirements();
    }, [props.reloadTicket, props.ticket]);

  
    useEffect(() => {
      if (settlementData) {
        const quoteData = quotationData && quotationData.requestAmount;
        const data = validateSettlementTotal(quoteData || null, settlementData);
        handleSetGeneralState("settlementInfo", data);
      }
    }, [settlementData, quotationData]);
  
    useEffect(() => {
      fetchQuotationData();
    }, [formIds.quotation]);

    useEffect(() => {
      getQuotationFee();
    }, [quotationData])

    const participant = props.ticket.participantDetail || {};
    const currentTicketStep = props.ticket.steps.find(
      (item) => item.step === props.currentStep
    ).requirements;
  
    const getTicketRequirements = () => {
      const creditMethodTableData = props.ticket.steps
        .find((item) => item.step === "eval_forms_settlement")
        .requirements.find((item) => item.name === "creditMethodTables").value;

      let forms = {};
      currentTicketStep
        .filter((item) => item.type === "form")
        .forEach((item) => {
          forms = { ...forms, [item.name]: item.value };
        });
  
      const data = creditMethodTableData || settlementInit;
      if(data && data.creditMethods) {
        // REMOVE CASH FROM CREDIT METHODS AND SAVED VALUES;
        data.creditMethods = data.creditMethods.filter(item => item.value !== "cash");
        delete data.cash;
      }
      setState(ps => ({
        ...ps,
        settlementData: data || {},
        previousChecks: [...(data?.checks || [])],
      }));
      setFormIds(forms);
    };
    
    const handleSetGeneralState = (key, value) => {
      setState(ps => ({...ps, [key]: value}));
    }
    const handleClickExpand = (form, event, isExpanded) => {
      setState(ps => ({...ps, expand: isExpanded ? form : false}))
    };
  
    const handleChangeAnswer = (question, event) => {
      question.value.answer = event.target.value;
      getQuestionaryData();
    };

    const fetchQuotationData = async () => {
      const possibleQuitations = props.ticket.steps
        .find( (item) => item.step === "credit_analysis" || item.step === "eval_forms_settlement")
        .requirements.find((item) => item.name === "quotation").value;
  
      const currQuotation = possibleQuitations || "";
      if (currQuotation) {
        const data = await getQuotationData(props, currQuotation);
        handleSetGeneralState("quotationData", data);
      }
    }
  
    const getDebtCapacity = async () => {
      const endpoint = getCreditDebtCapacity({
        accessToken: props.token,
        clientUniqueId: props.ticket.customer,
      });
      await endpoint.then((response) => {
          handleSetGeneralState("debtCapacity", response.data)
          props.setLoading(false);
      }).catch((errors) => {
        props.setLoading(false);
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
    };
  
    const getQuestionaryData = () => {
      const data = props.ticket.steps
        .find((item) => item.step === props.currentStep)
        .requirements.filter((item) => item.type === "questionary");
      handleSetGeneralState("questionaryData", data);
    };
  
    const handleFileRequirements = async (steps) => {
      await ticketHistory(steps, props).then(async (response) => {
        const history = response;
        await props.modifyTicketStep(steps, props.ticket, history, undefined);
      });
    };
  
    const handleSaveForm = (formName, question, id) => {
      const uniqueId = typeof id === "string" ? id : id.uniqueId;
      if (question === "no_question") {
        props.ticket.steps
          .find((item) => item.step === props.currentStep)
          .requirements.find((item) => item.name === formName).value = uniqueId;
        setFormIds(ps => ({...ps, [formName]: uniqueId}));

      } else {
        question.value.forms.find(
          (item) => item.name === formName
        ).value = uniqueId;
      }
      handleFileRequirements(props.ticket.steps);
    };
  
    const verifyChecksStatus = async () => {
      const checks = settlementData.checks;
      const checkOptionSelected = settlementData.creditMethods.find(
        (item) => item.value === "check"
      );
      let checkStatus = true;
      if (checkOptionSelected && checks.length > 0) {
        props.setLoading(true);
        const endpoints = checks.map((item) =>
          fetchOutlayCheck(props.token, item.uniqueId)
        );
        await axios
          .all(endpoints)
          .then(
            axios.spread((...responses) => {
              const data = responses
                .map((item) => item.data)
                .filter((item) => item.status !== "completed");
              checkStatus = data.length < 1;
              props.setLoading(false);
            })
          )
          .catch((errors) => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleSimpleDialog);
          });
      }
      return checkStatus;
    };
  
    const modifySettlementTables = () => {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `Una vez completado el paso no podrá volver a agregar o modificar el valor de estas tablas.`
      );
      handleSetGeneralState("isEditing", true);
    };
  
    const blackLists = async () => {
      const endpoint = fetchPotentialMemberValidation(props.token, {participant: props.ticket.customer});
      await endpoint.then((response) => {
        if (response.data.blacklist.length > 0) {
          handleSetGeneralState("isInBlacklist", true)
        }
      });
    };

    const getQuotationFee = () => {
      if(quotationData?.uniqueId) {
        const endpoint = fetchQuota({ quotation_id: quotationData.uniqueId, token: props.token });
        endpoint.then((response) => {
          let fee = Array.isArray(response.data) ? response.data[0] : response.data;
          if(typeof fee === 'string') {
            fee = fee.replaceAll(",","");
          }
          handleSetGeneralState("quotationFee", fee);
        });
      }
    };

    const initialFetch = async () => {
      props.setLoading(true);
      await Promise.all([
        getDebtCapacities(props),
        getPayCapacity(props),
        blackLists(),
        getDebtCapacity(),
        getBankAccounts(props.token),
        getClientBankAccounts({token: props.token,  owner: props.ticket.customer}),
      ]).then(([debtCapacities, payCapacity, blackList, debtCapacity, bankAcc, clientBankAcc]) => {
        setState(ps => ({
          ...ps,
          ...debtCapacities,
          ...suggested,
          ...debtCapacity,
          ...payCapacity,
          ...bankAcc,
          ...clientBankAcc
        }));
      });
      props.setLoading(false);
    }

    const handleCheckValidation = (type) => {
      let checkValObj = getCheckObj;
      const checkVal = Boolean(!checkValidation[type]);

        if(checkValObj) {
          checkValObj[type] = checkVal;
        }else {
          checkValObj = {
            name: 'checkValidation',
            value: {
              [type]: checkVal
            },
          }
          props.ticket.steps
          .find(item => item.step === "credit_formalization")
          .requirements.push(checkValObj);
        }
      handleSetGeneralState("checkValidation", checkValObj);
    }

    const handleCompleteStep = async () => {
  
      // VERIFY IF SELECTED SETTLEMENT TABLES ARE EMPTY
      const tableEmpty = await validateCreditMethodTablesEmpty(settlementData);
      if (tableEmpty) {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `Todas las tablas de formas de liquidación seleccionadas deben tener al menos 1 registro.`
        );
        return;
      }
      //  MANAGE CHECKS (SAVE, UPDATE, DELETE)
      if ((previousChecks && previousChecks.length) || settlementData.checks.length) {
        const currentCheckData = 
          settlementData.creditMethods.find((item) => item.value === "check")
          ? settlementData.checks
          : [];
        await handleManageChecks(
          creditor,
          props,
          currentCheckData,
          previousChecks,
          creditorAccounts,
          participant
        );
      }
      // await handleFileRequirements(props.ticket.steps);
      // GET TICKET STEP REQUIREMENTS
      const requirementsState = props.ticket.steps
        .find((item) => item.step === props.currentStep)
        .requirements.find(
          (item) => item.value.hasOwnProperty("answer") && !item.value.answer
        );
      // SET SETTLEMENT TABLES TOTAL CALCULATION
      if (settlementInfo.text && settlementInfo.text !== "no_data") {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `El total de formas de liquidación ${settlementInfo.text} al monto solicitado.`
        );
        return;
      }
      if(requirementsState) {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `Debe marcar las opciones del CUESTIONARIO DE VALIDACIÓN`
        );
        return;
      }
      if(settlementInfo?.text === "no_data") {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `Debe ingresar información en los campos de formas de liquidación seleccionadas.`
        );
        return;
      }
      if(!checkValidation.correctCreditMethods) {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `Debe marcar el check "MÉTODOS DE CRÉDITO CORRECTOS" para proceder.`
        );
        return;
      }
      let history = false;
      let statusLabel = '';
      const specialCase = props.ticket.steps
        .find((item) => item.step === "eval_forms_settlement")
        .requirements.find(item => item.name === 'specialCase')?.value;
      if(specialCase) {
        props.ticket.status = "exception_validation_pending";
        statusLabel="Pendiente de Validación de Excepción";
      }else{
        props.ticket.status = "approval_pending";
        statusLabel="Pendiente de Aprobación";
      }
      const historyDescription = `Se ha modificado el estado del ticket a: ${statusLabel}`;
      history = await ticketHistoryGeneral(props, historyDescription).then((response) => response);
      handleNextStep(
        true,
        props,
        props.ticket.steps,
        undefined,
        false,
        history
      );
    };
  
    return (
      <div style={{ padding: "16px" }}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          direction="column"
          style={{ margin: "1rem 0" }}
        >
          <StepStatus
            completed={stepStatus}
            handleNextStep={handleCompleteStep}
            ticketCompleted={props.ticketCompleted}
            roles={props.roles}
          />
          {/* <ViewQuotation onClick={handleViewQuotation.bind(this, props)} /> */}
          <ViewQuotationText 
              {...props}
              handleViewQuotation={handleViewQuotation}
              quotationData={quotationData}
              setFormIds={setFormIds}
          />
        </Grid>

        <TableDataComponent 
          {...props}
          debtCapacity={debtCapacity}
          suggested={suggested}
          debtCapacities={debtCapacities}
          payCapacity={payCapacity}
          quotationFee={quotationFee}
          quotationData={quotationData}
        />
  
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          direction="column"
          style={{ margin: "2rem 0 1rem 0" }}
        >
          <div style={{ marginTop: "2rem" }} />
          <FormWrapper
            title={"Cuenta de ahorro funcional"}
            name={"savingAccount"}
            contentType={"Formulario"}
            ticketCompleted={props.ticketCompleted}
            form={
              <>
                {formIds.savingAccount && (
                  <SavingAccountDetail
                    savingsAccountContractId={formIds.savingAccount}
                    fromTicketType={"functional"}
                  />
                )}
                <div
                  style={{ display: formIds.savingAccount ? "none" : "block" }}
                >
                  <SavingAccountForm
                    handleSaveCallBack={handleSaveForm.bind(
                      this,
                      "savingAccount",
                      "no_question"
                    )}
                    debtor={[props.ticket.customer]}
                    fromTicketType={"functional"}
                  />
                </div>
              </>
            }
            handleExpand={handleClickExpand}
            expand={expand === "savingAccount"}
          />
          <Typography
            variant={"subtitle1"}
            className={"section-header"}
            style={{
              fontWeight: "bold",
              color: "#424242",
              marginLeft: 5,
              marginTop: "2rem",
            }}
          >
            CUESTIONARIO DE VALIDACIÓN
          </Typography>
          <Divider style={{ marginBottom: "2rem" }} />
          <Grid container item xs={12}>
            {questionaryData.map((item, index) => {
              const answer = item.value.answer;
              const promissoryNote = item.value.forms.find(
                (item) => item.name === "promissoryNote"
              );
              const addendum = item.value.forms.find(
                (item) => item.name === "addendum"
              );
              const autoElectronicWithdraw = item.value.forms.find(
                (item) => item.name === "autoElectronicWithdraw"
              );
              const autoDebitAuth = item.value.forms.find(
                (item) => item.name === "autoDebitAuth"
              );
              const payrollDeduction = item.value.forms.find(
                (item) => item.name === "payrollDeduction"
              );
              const electronicDebit = item.value.forms.find(
                (item) => item.name === "electronicDebit"
              );
              const paymentFlyer = item.value.forms.find(
                (item) => item.name === "paymentFlyer"
              );
              const check = item.value.forms.find(
                (item) => item.name === "check"
              );
              const loanContract = item.value.forms.find(
                (item) => item.name === "loanContract"
              );
              return (
                <Paper
                  key={`${item.name}-${index}`}
                  style={{
                    margin: "1rem 0",
                    padding: "1rem .5rem 1rem 2rem",
                    width: "100%",
                  }}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    direction={"row"}
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant={"body1"}
                      style={{ color: "#424242", width: "70%" }}
                    >
                      {item.text}
                    </Typography>
                    <RadioGroupComponent
                      typeVariant="subtitle1"
                      typeClass="field-label"
                      fieldLabel="Seleccione una opción"
                      fieldID="radio-field-bot-init-option"
                      fieldClass="fields"
                      row={true}
                      value={item.value.answer}
                      options={[
                        { text: "SI", value: "si" },
                        { text: "NO", value: "no" },
                      ]}
                      onChange={handleChangeAnswer.bind(this, item)}
                      disabled={Boolean(props.ticketCompleted)}
                    />
                  </Grid>
                  {/* QUESTIONARY CONTENT */}
                  <Grid item container xs={12}>
                    {answer === "si" && promissoryNote && (
                      <FormWrapper
                        title={"Pagaré"}
                        name={`promissoryNote-${index}`}
                        contentType={"Formulario"}
                        ticketCompleted={
                          props.ticketCompleted 
                          // "DISABLED" NOTARIAL PAY WHEN "DOCUMENTOS VERIFICADOS" IS CHECKED
                          || Boolean(getCheckObj?.verifiedDocs)
                        }
                        form={
                          // expand === `promissoryNote-${index}` &&
                          <>
                            {promissoryNote && promissoryNote.value && (
                              <NotarialPayContractDetail
                                notarialPayContractId={promissoryNote.value}
                              />
                            )}
                            <div
                              style={{
                                display:
                                  promissoryNote && promissoryNote.value
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <NotarialPayContractForm
                                handleSaveCallBack={handleSaveForm.bind(
                                  this,
                                  "promissoryNote",
                                  item
                                )}
                              />
                            </div>
                          </>
                        }
                        handleExpand={handleClickExpand}
                        expand={expand === `promissoryNote-${index}`}
                      />
                    )}
                    {answer === "si" && addendum && (
                      <FormWrapper
                        title={"Addendum"}
                        name={`addendum-${index}`}
                        contentType={"Formulario"}
                        ticketCompleted={props.ticketCompleted}
                        form={
                          // expand === `addendum-${index}` &&
                          <>
                            {addendum && addendum.value && (
                              <AddendumDetail
                                addendumContractId={addendum.value}
                              />
                            )}
                            <div
                              style={{
                                display:
                                  addendum && addendum.value ? "none" : "block",
                              }}
                            >
                              <AddendumForm
                                handleSaveCallBack={handleSaveForm.bind(
                                  this,
                                  "addendum",
                                  item
                                )}
                              />
                            </div>
                          </>
                        }
                        handleExpand={handleClickExpand}
                        expand={expand === `addendum-${index}`}
                      />
                    )}
                    {answer === "si" && autoElectronicWithdraw && (
                      <FormWrapper
                        title={"Autorización de retiro via electrónica"}
                        name={`autoElectronicWithdraw-${index}`}
                        contentType={"Formulario"}
                        ticketCompleted={props.ticketCompleted}
                        form={
                          // expand === `autoElectronicWithdraw-${index}` &&
                          <>
                            {autoElectronicWithdraw &&
                              autoElectronicWithdraw.value && (
                                <DebitAuthorizationDetail
                                  id={autoElectronicWithdraw.value}
                                />
                              )}
                            <div
                              style={{
                                display:
                                  autoElectronicWithdraw &&
                                  autoElectronicWithdraw.value
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <DebitAuthorizationForm
                                handleSaveCallBack={handleSaveForm.bind(
                                  this,
                                  "autoElectronicWithdraw",
                                  item
                                )}
                                debtor={[props.ticket.customer]}
                              />
                            </div>
                          </>
                        }
                        handleExpand={handleClickExpand}
                        expand={expand === `autoElectronicWithdraw-${index}`}
                      />
                    )}
                    {answer === "si" && autoDebitAuth && (
                      <FormWrapper
                        title={"Autorización de débito automático"}
                        name={`autoDebitAuth-${index}`}
                        contentType={"Formulario"}
                        ticketCompleted={props.ticketCompleted}
                        form={
                          // expand === `autoDebitAuth-${index}` &&
                          <>
                            {autoDebitAuth && autoDebitAuth.value && (
                              <AutoDebitAuthDetail
                                automaticDebitAuthorizationContractId={
                                  autoDebitAuth.value
                                }
                              />
                            )}
                            <div
                              style={{
                                display:
                                  autoDebitAuth && autoDebitAuth.value
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <AutoDebitAuthForm
                                handleSaveCallBack={handleSaveForm.bind(
                                  this,
                                  "autoDebitAuth",
                                  item
                                )}
                              />
                            </div>
                          </>
                        }
                        handleExpand={handleClickExpand}
                        expand={expand === `autoDebitAuth-${index}`}
                      />
                    )}
                    {answer === "si" && payrollDeduction && (
                      <FormWrapper
                        title={"Autorización descuento por nómina"}
                        name={`payrollDeduction-${index}`}
                        contentType={"Formulario"}
                        ticketCompleted={props.ticketCompleted}
                        form={
                          // expand === `payrollDeduction-${index}` &&
                          <>
                            {payrollDeduction && payrollDeduction.value && (
                              <PayrollDeductionDetail
                                id={payrollDeduction.value}
                              />
                            )}
                            <div
                              style={{
                                display:
                                  payrollDeduction && payrollDeduction.value
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <PayrollDeductionForm
                                handleSaveCallBack={handleSaveForm.bind(
                                  this,
                                  "payrollDeduction",
                                  item
                                )}
                                debtor={[props.ticket.customer]}
                              />
                            </div>
                          </>
                        }
                        handleExpand={handleClickExpand}
                        expand={expand === `payrollDeduction-${index}`}
                      />
                    )}
                    {answer === "si" && check && (
                      <FormWrapper
                        title={"Cheque"}
                        name={`check-${index}`}
                        contentType={"Formulario"}
                        ticketCompleted={props.ticketCompleted}
                        form={
                          // expand === `payrollDeduction-${index}` &&
                          <>
                            {check && check.value && (
                              <CheckDetail checkId={check.value} />
                            )}
                            <div
                              style={{
                                display: check && check.value ? "none" : "block",
                              }}
                            >
                              <CheckForm
                                handleSaveCallBack={handleSaveForm.bind(
                                  this,
                                  "check",
                                  item
                                )}
                                debtor={[props.ticket.customer]}
                              />
                            </div>
                          </>
                        }
                        handleExpand={handleClickExpand}
                        expand={expand === `check-${index}`}
                      />
                    )}
                    {answer === "si" && paymentFlyer && (
                      <FormWrapper
                        title={"Volante de Pago"}
                        name={`paymentFlyer-${index}`}
                        contentType={"Formulario"}
                        ticketCompleted={props.ticketCompleted}
                        form={
                          // expand === `payrollDeduction-${index}` &&
                          <>
                            {paymentFlyer && paymentFlyer.value && (
                              <FlyerDetail flierId={paymentFlyer.value} />
                            )}
                            <div
                              style={{
                                display:
                                  paymentFlyer && paymentFlyer.value
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <FlyerForm
                                handleSaveCallBack={handleSaveForm.bind(
                                  this,
                                  "paymentFlyer",
                                  item
                                )}
                                debtor={[props.ticket.customer]}
                              />
                            </div>
                          </>
                        }
                        handleExpand={handleClickExpand}
                        expand={expand === `paymentFlyer-${index}`}
                      />
                    )}
                    {answer === "si" && loanContract && (
                      <FormWrapper
                        title={"Contrato de préstamo"}
                        name={`loanContract-${index}`}
                        contentType={"Formulario"}
                        ticketCompleted={props.ticketCompleted}
                        form={
                          <>
                            {loanContract && loanContract.value && (
                              <LoanContractDetail
                                loanContractId={loanContract.value}
                              />
                            )}
                            <div
                              style={{
                                display:
                                  loanContract && loanContract.value
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <LoanContractForm
                                handleSaveCallBack={handleSaveForm.bind(
                                  this,
                                  "loanContract",
                                  item
                                )}
                                debtor={[props.ticket.customer]}
                              />
                            </div>
                          </>
                        }
                        handleExpand={handleClickExpand}
                        expand={expand === `loanContract-${index}`}
                      />
                    )}
                    {answer === "si" && electronicDebit && (
                      <Typography
                        variant={"subtitle1"}
                        className={"section-header"}
                        style={{
                          fontWeight: "bold",
                          color: "#424242",
                          fontStyle: "italic",
                          marginBottom: "2rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Info
                          style={{
                            color: "#1fbded",
                            fontSize: 25,
                            marginRight: ".7rem",
                          }}
                        />
                        Recordar la autorización de débito electrónico
                      </Typography>
                    )}
                  </Grid>
                </Paper>
              );
            })}
          </Grid>
          <Typography
            variant={"subtitle1"}
            className={"section-header"}
            style={{
              fontWeight: "bold",
              color: "#424242",
              marginLeft: 5,
              marginTop: "2rem",
            }}
          >
            CONFIRMACIÓN DE FORMAS DE LIQUIDACIÓN
          </Typography>
          <Divider style={{ marginBottom: "2rem" }} />
          <SettlementTableActions
            {...props}
            enableEdit={stepStatus || props.ticketCompleted || isEditing}
            enableSave={stepStatus || props.ticketCompleted || !isEditing}
            isEditing={isEditing}
            showActions={true}
            modifySettlementTables={modifySettlementTables}
            amountTransfer={quotationData?.requestAmount}
            // total={settlementInfo.total}
            total={settlementInfo.transferTotal}
            settlementData={settlementData}
            step={"eval_forms_settlement"}
          />
          {settlementData && (
            <SettlementFormsTable
              token={props.token}
              handleToggleSimpleDialog={props.handleToggleSimpleDialog}
              rohiId={participant.rohiId || null}
              state={settlementData}
              clientId={[props.ticket.customer]}
              originAccounts={
                creditorAccounts.length > 0
                  ? creditorAccounts.map((item) => {
                      return {
                        label: item.name,
                        value: item.number,
                        type: item.accountType,
                      };
                    })
                  : []
              }
              clientAccounts={clientBankAccounts}
              destinyAccounts={
                clientBankAccounts?.length > 0
                  ? clientBankAccounts.map((item) => {
                      return {
                        name: item.name,
                        value: item.number,
                      };
                    })
                  : []
              }
              fromTicket={true}
              readOnly={!isEditing || props.ticketCompleted}
              handleState={handleState.bind(
                this,
                handleSetGeneralState.bind(this, "settlementData"),
                settlementData
              )}
              handleAdd={handleModify.bind(
                this,
                handleSetGeneralState.bind(this, "settlementData"),
                settlementData
              )}
              handleReplace={handleModify.bind(
                this,
                handleSetGeneralState.bind(this, "settlementData"),
                settlementData
              )}
              handleEdit={handleModify.bind(
                this,
                handleSetGeneralState.bind(this, "settlementData"),
                settlementData
              )}
              handleDelete={handleDelete.bind(
                this,
                handleSetGeneralState.bind(this, "settlementData"),
                settlementData
              )}
            />
          )}
          <div style={{margin: '2rem 0'}}/>
          <CheckboxField
            xs={12}
            alignItems="flex-end"
            check={Boolean(checkValidation.correctCreditMethods)}
            handleChange={() => handleCheckValidation("correctCreditMethods")}
            style={{ margin: "1rem 0" }}
            color="primary"
            textColor="#43A047"
            label="MÉTODOS DE CRÉDITO CORRECTOS"
            disabled={props.ticketCompleted}
          />
        </Grid>
      </div>
    );
}