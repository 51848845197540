import { Button, Grid } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useEffect, useState } from "react";
import {
  AutocompleteField,
  NumericTextField,
  TextFieldComponent,
} from "src/components/SharedComponents/Fields";
import { liquidablePaymentTypes, settlementWays } from "src/utils";
import { fetchFilterInterest } from "src/utils/axios";
import { BankAccountDialog } from "../Dialogs";

export default function CertificateDataForm({
  rFields,
  fields,
  setBankInfo,
  bankInfo,
  onChange,
  errorFields,
  section,
  getFiltersOptions,
  handleSelectValue,
  token,
}) {
  const [timeoutId, settimeoutId] = useState(null);
  const [open, setOpen] = useState(false);
  const limitedPaymentTypes = liquidablePaymentTypes.slice(0, 2)

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    const certAmount = fields.certAmount || fields.financialCertificate?.amount;
    const certTerm = fields.certTerm || fields.financialCertificate?.term;
    const payType = fields.payType || fields.financialCertificate?.paymentType;
  
    const id = setTimeout(() => {
      if (certAmount >= 5000 && certTerm >= 1) {
        try {
          const params = {
            calculator_type: "financial_certificates",
            amount: certAmount,
            time_limit: certTerm,
            token: token,
          };
          const response = fetchFilterInterest(params);
          response.then((res) => {
            const interest =
              payType !== "capitalizable"
                ? res.data.otherWayInterestRate
                : res.data.capitalizedInterestRate;
            onChange("any", "interest", interest);
          });
        } catch (error) {
          console.error("Error al obtener tasa de interes");
          console.error(error);
        }
      } else {
        onChange("any", "interest", "");
      }
    }, 1000);
  
    settimeoutId(id);
  
    return () => clearTimeout(id);
  }, [fields.certAmount, fields.certTerm, fields.payType, fields.financialCertificate?.amount, fields.financialCertificate?.term, fields.financialCertificate?.paymentType]);
  

  return (
    <Grid container spacing={3} className="form-wrapper-fields-container">
      <BankAccountDialog
        open={open}
        token={token}
        handleClose={handleClose}
        accountInfo={fields}
        fields={bankInfo}
        setFields={setBankInfo}
        section={section}
        errorFields={errorFields}
      />
      <NumericTextField
        xs={4}
        fullWidth
        fieldLabel={`Monto ${rFields.includes("certAmount") ? "(*)" : ""}`}
        fieldID="leads-field-amount"
        placeholder="$0.00"
        prefix="$"
        value={fields.certAmount || fields.financialCertificate?.amount || null}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator={true}
        onChange={onChange.bind(this, "numeric", "certAmount")}
        error={Boolean(errorFields[section]?.amount)}
        helperText={
          errorFields[section]?.amount ? errorFields[section]?.amount : null
        }
      />
      <NumericTextField
        xs={4}
        fullWidth
        fieldLabel={`Plazo ${rFields.includes("certTerm") ? "(*)" : ""}`}
        fieldID="leads-field-term"
        placeholder="3"
        value={fields.certTerm || fields.financialCertificate?.term || null}
        decimalScale={0}
        onChange={onChange.bind(this, "numeric", "certTerm")}
        error={Boolean(errorFields[section]?.term)}
        helperText={
          errorFields[section]?.term ? errorFields[section]?.term : null
        }
      />
      <AutocompleteField
        xs={4}
        fieldLabel={`Forma de liquidación ${
          rFields.includes("payType") ? "(*)" : ""
        }`}
        fieldID="leads-field-payType"
        fieldInnerLabel="Seleccione una opción"
        value={
          handleSelectValue(
            fields.payType || fields.financialCertificate?.paymentType,
            settlementWays,
            "value"
          ) || null
        }
        handleChange={onChange.bind(this, "other", "payType")}
        options={getFiltersOptions(settlementWays)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.payType)}
        helperText={
          errorFields[section]?.payType ? errorFields[section]?.payType : null
        }
        multiple={false}
      />
      {(fields.payType == "liquidable" ||
        fields.financialCertificate?.paymentType === "liquidable") && (
        <TextFieldComponent
          xs={4}
          fullWidth
          fieldLabel={`Cuenta de banco ${
            rFields.includes("bankAccount") ? "(*)" : ""
          }`}
          fieldID="leads-field-bankAccount"
          placeholder="Agregar cuenta"
          value={bankInfo.bankAccount || fields.bankaccount?.number || ""}
          disabled
          InputProps={{
            endAdornment: !fields.uniqueId_bankaccount && (
              <Button
                xs={2}
                variant="outlined"
                disableElevation
                style={{
                  borderRadius: 3,
                  borderColor: green[500],
                  color: green[500],
                  fontWeight: "bold",
                  fontSize: 10,
                }}
                onClick={() => setOpen(true)}
              >
                Agregar
              </Button>
            ),
          }}
        />
      )}
      <NumericTextField
        xs={4}
        fullWidth
        fieldLabel={`Tasa ${rFields.includes("interest") ? "(*)" : ""}`}
        fieldID="leads-field-interest"
        suffix="%"
        value={
          fields.interest || fields.financialCertificate?.interestRate || ""
        }
        onChange={onChange.bind(this, "numeric", "interest")}
        error={Boolean(errorFields[section]?.interest)}
        disabled
        helperText={
          errorFields[section]?.interest ? errorFields[section]?.interest : null
        }
      />
      <AutocompleteField
        xs={4}
        fieldLabel={`Forma de pago ${
          rFields.includes("certWayToPay") ? "(*)" : ""
        }`}
        fieldID="leads-field-certWayToPay"
        fieldInnerLabel="Seleccione una opción"
        value={
          handleSelectValue(
            fields.certWayToPay || fields.certificatePaymentMethod,
            limitedPaymentTypes,
            "value"
          ) || null
        }
        handleChange={onChange.bind(this, "other", "certWayToPay")}
        options={getFiltersOptions(limitedPaymentTypes)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.certWayToPay)}
        helperText={
          errorFields[section]?.certWayToPay
            ? errorFields[section]?.certWayToPay
            : null
        }
        multiple={false}
      />
    </Grid>
  );
}
