import * as React from 'react';
import { translations } from 'src/utils';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

const styles:any = createStyles({
    formControl: {
        marginTop: 20
    },
    greenButton: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    label: {
        color: green[500]
    },
    redButton: {
        '&:hover': {
            backgroundColor: red[200]
        },
        backgroundColor: red[500],
        color: 'white'
    },
    textField: {
        marginTop: 10,
        minWidth: 190
    }
});

interface IOutlayCashtSearchFilterProps {
    token: string;
    
    open: boolean;
    classes: {
        [key: string]: string;
    }
    handleClose(): void;
    search(params: IFetchOutlayCashParams): void;
}

interface IOutlayCashtSearchFilterState {
    [key: string]: string | any | number;
    date_from: string;
    date_to: string;
    reference_number: string;
    status: string;
    title: string;
}

class OutlayCashtSearchFilter extends React.Component<IOutlayCashtSearchFilterProps, IOutlayCashtSearchFilterState>{
    public state: IOutlayCashtSearchFilterState = {
       
        date_from: '',
        date_to: new Date().toISOString().split('T')[0],
        reference_number: "",
        status: '',
        title: "",
    };
    constructor(props: IOutlayCashtSearchFilterProps) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }
    public handleSearch() {
        let dateTo: any = new Date(this.state.date_to)
        dateTo.setDate(dateTo.getDate() + 1);
        dateTo = dateTo.toISOString().slice(0,10);
        const params: IFetchOutlayCashParams = {
        
            dateFrom: this.state.date_from,
            dateTo,
            status: this.state.status,
            title: this.state.title,
            token: this.props.token,
            
        };
        this.props.search(params);
        this.props.handleClose();
    }
    public handleClear() {
        this.setState({
            date_from: '',
            date_to: new Date().toISOString().split('T')[0],
            reference_number: "",
            status: '',
            title: "",
        });
        this.props.search({token: this.props.token})
        this.props.handleClose();
    }
    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({ [key]: event.target.value });
        }
    }
    public handleCurrencyInputChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>, maskedValue?: string, floatValue?: string) => {
            this.setState({ [key]: floatValue || '' });
        }
    }
    public render() {
        const { classes, handleClose, open } = this.props;
        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="status" shrink={true}>{translations.STATUS}</InputLabel>
                        <Select
                            value={this.state.status}
                            onChange={this.handleSelectChange('status')}
                            native={true}
                            inputProps={{
                                id: 'status',
                                name: 'status'
                            }}
                        >
                            <option value="">{translations.ALL}</option>
                            <option value="in_transit">{ translations.OUTLAY_CHECK_STATUS_IN_TRANSIT}</option>
                            <option value="cancelled">{ translations.OUTLAY_CHECK_STATUS_CANCELLED}</option>
                            <option value="completed">{ translations.OUTLAY_CHECK_STATUS_COMPLETED}</option>
                            
                        </Select>
                    </FormControl>
                    
                    <br />
                    <br />
                    <TextField
                        label={translations.FROM}
                        className={classes.textField}
                        value={this.state.date_from}
                        style={{ width: '50%' }}
                        type="date"
                        onChange={this.handleChange('date_from')}
                        InputLabelProps={{
                            shrink: true,
                            style: styles.label
                        }}
                    />
                    <TextField
                        label={translations.TO}
                        className={classes.textField}
                        value={this.state.date_to}
                        style={{ width: '50%' }}
                        type="date"
                        onChange={this.handleChange('date_to')}
                        InputLabelProps={{
                            shrink: true,
                            style: styles.label
                        }}
                    />
                    <br />
                    <br />
                </DialogContent>
                <DialogActions>
                    <Button className={classes.redButton} onClick={this.handleClear}>
                        <ClearIcon />
                        &nbsp;
                        {`${translations.CLEAR} ${translations.SEARCH}`}
                    </Button>
                    <Button className={classes.greenButton} onClick={this.handleSearch}>
                        <SearchIcon />
                        &nbsp;
                        {translations.SEARCH}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}


export default withStyles(styles)(OutlayCashtSearchFilter);