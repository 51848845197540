import React from 'react';
import { Toolbar, Grid, Typography, IconButton, Paper, Divider } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons'

function ToolbarComponent(props) {
    return(
        <Paper>
            <Toolbar className="app-bar">
                <Grid 
                    container 
                    direction="row"
                    justifyContent={props.toolbarActions ? "space-between" : "flex-start"} 
                    alignItems="center"
                >
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                        {
                            props.showGoBack &&
                                <IconButton 
                                    onClick={props.goBack}
                                    style={{color: props.goBackColor}}
                                >
                                    <ArrowBack />
                                </IconButton>
                        }
                        <Typography 
                            variant={props.typeVariant} 
                            className={props.typeClass}
                        >
                            {props.toolbarTitle}
                        </Typography>
                        {
                            props.showGoBack && <Divider style={{color:'#424242'}} orientation='vertical' />
                        }
                    </div>
                    {props.toolbarActions}
                </Grid>
            </Toolbar>
        </Paper>
    );
}

export { ToolbarComponent }