import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations, institutionStatus } from "src/utils";

import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";

import { fetchFinancialInstitutions } from "../../../actions";
import { fetchInstitutions, fetchInstitution } from "../../../utils/axios";
import InstitutionForm from "../FinancialInstitutionForm";
import InstitutionSearchSection from "../FinancialInstitutionSearchSection";

const styles: any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
    width: "100%",
  },
});

interface IFindInstitutionProps {
  token: string;
  institutionId: string;
  functionProps: any;
}

const FindInstitution: React.FC<IFindInstitutionProps> = ({
  token,
  institutionId,
  functionProps,
}) => {
  const { handleDeleteItem, handleSetInstitution } = functionProps;
  const [curInstitution, setCurInstitution] = React.useState({
    uniqueId: "",
    name: "",
    registry: "",
    abbreviation: "",
    employerCode: "",
    state: "",
  });

  React.useEffect(() => {
    if (institutionId) {
      fetchInstitution(token, institutionId)
        .then((res) => {
          setCurInstitution(res.data.results ? res.data.results : res.data);
          if (handleSetInstitution)
            handleSetInstitution(
              res.data.results ? res.data.results : res.data
            );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return curInstitution && institutionId ? (
    <TableRow key={curInstitution.uniqueId}>
      <TableCell>{curInstitution.name || "---"}</TableCell>
      <TableCell>{curInstitution.registry || "---"}</TableCell>
      <TableCell>{curInstitution.abbreviation || "---"}</TableCell>
      <TableCell>{curInstitution.employerCode || "---"}</TableCell>
      <TableCell>
        {institutionStatus.find((item) => item.value === curInstitution.state)
          ?.label || "---"}
      </TableCell>
      <TableCell>
        <IconButton
          onClick={handleDeleteItem(
            curInstitution.uniqueId ? curInstitution.uniqueId : ""
          )}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ) : null;
};
interface IInstitutionSelectInputProps {
  classes: {
    button: string;
    root: string;
  };

  user: IUser;
  institutions: IInstitution[];
  selectedInstitutionsId: string[];
  addInstitution(institutionId: string): void;
  deleteInstituion(institutionId: string): void;
  fetchFinancialInstitutions(
    institutions: IInstitution[]
  ): IAction<IInstitution[]>;
  handleSetInstitution?: () => void;
}
interface IInstitutionSelectInputState {
  institutions: IInstitution[];
  formDialog: boolean;
  open: boolean;
  loading: boolean;
}

class InstitutionSelectInput extends React.Component<
  IInstitutionSelectInputProps,
  IInstitutionSelectInputState
> {
  public state: IInstitutionSelectInputState = {
    formDialog: false,
    institutions: [],
    loading: false,
    open: false,
  };
  constructor(props: IInstitutionSelectInputProps) {
    super(props);

    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
  }
  public componentDidMount() {
    this.setState({ loading: true });

    fetchInstitutions({
      token: this.props.user.token,
      institutionType: "financial",
      limit: 20,
      offset: 0,
    })
      .then((res) => {
        this.props.fetchFinancialInstitutions(
          res.data.results ? res.data.results : res.data
        );

        this.setState({
          institutions: (res.data.results
            ? res.data.results
            : res.data) as IInstitution[],
          loading: false,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public openDialogHandler() {
    this.setState({ open: true });
  }
  public closeDialogHandler() {
    this.setState({ open: false });
  }
  public openFormDialog() {
    this.setState({ formDialog: true });
  }
  public closeFormDialog() {
    this.setState({ formDialog: false });
  }
  public emptyFunction() {
    return null;
  }

  public handleItemClick(institutionId: string) {
    this.setState({ loading: true });

    fetchInstitutions({
      token: this.props.user.token,
      institutionType: "financial",
      limit: 20,
      offset: 0,
    })
      .then((res) => {
        this.props.fetchFinancialInstitutions(
          res.data.results ? res.data.results : res.data
        );

        this.setState(
          {
            institutions: (res.data.results
              ? res.data.results
              : res.data) as IInstitution[],
            loading: false,
            open: false,
          },
          () => {
            this.props.addInstitution(institutionId);
          }
        );
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.props.deleteInstituion(id);
    };
  }

  public fetchData(params: IFetchInstitutionParams) {
    params.limit = 20;
    params.offset = 0;

    this.setState({ loading: true });
    fetchInstitutions(params)
      .then((res) => {
        this.props.fetchFinancialInstitutions(
          (res.data.results ? res.data.results : res.data) as IInstitution[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public render() {
    const { classes } = this.props;
    const { formDialog, open, loading } = this.state;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={this.closeDialogHandler}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.openFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: 600, width: 280 }}>
            <InstitutionSearchSection
              loading={loading}
              handleClick={this.handleItemClick}
              fetchData={this.fetchData}
              pagination={false}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={formDialog}
          fullScreen={true}
          onClose={this.closeFormDialog}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <InstitutionForm changeSection={this.emptyFunction} />
          </DialogContent>
        </Dialog>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.NAME}</TableCell>
                <TableCell>{translations.REGISTRY}</TableCell>
                <TableCell>{translations.ABBREVIATION}</TableCell>
                <TableCell>{translations.EMPLOYER_CODE}</TableCell>
                <TableCell>{translations.STATUS}</TableCell>
                <TableCell>
                  <IconButton onClick={this.openDialogHandler}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.selectedInstitutionsId.map((institutionId) => (
                <FindInstitution
                  key={institutionId}
                  token={this.props.user.token}
                  institutionId={institutionId}
                  functionProps={{
                    handleDeleteItem: this.handleDeleteItem,
                    handleSetInstitution: this.props.handleSetInstitution,
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    institutions: state.financialInstitutions,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchFinancialInstitutions })(
    InstitutionSelectInput
  )
);
