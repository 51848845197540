import { Grid, IconButton } from "@material-ui/core";
import { DeleteForever, Edit, Send } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import NumberFormat from "src/components/SharedComponents/NumberFormat";
import { GeneralTable } from "src/components/SharedComponents/Table";

function HumanResourcesTable(props) {
  const [tableData, setTableData] = useState([]);

  // const handleOpenExpiryInfo = (key, event) => {
  //     openExpiryInfo[key] ? setOpenExpiryInfo({...openExpiryInfo, [key]: null}) : setOpenExpiryInfo({...openExpiryInfo, [key]: event.currentTarget})
  // }

  const actions = (row) => {
    return (
      <Grid container direciton="row" justifyContent="space-between">
        <IconButton
          onClick={props.handleToggleDialog.bind(this, "resource-modify", row)}
          size="medium"
        >
          <Edit style={{ color: "#286bd1" }} />
        </IconButton>
        {!row.idTuNomina ? (
          <IconButton onClick={props.sendToTuNomina.bind(this, row)}>
            <Send style={{ color: "#286bd1" }} />
          </IconButton>
        ) : null}

        <IconButton onClick={props.handleDelete.bind(this, row.uniqueId)}>
          <DeleteForever style={{ color: "#286bd1" }} />
        </IconButton>
      </Grid>
    );
  };
  // const amount = (column, row) => {
  //   return <NumberFormat value={row[column]} />;
  // };

  // const expiryStates = (row) => {
  //   return (
  //     <span>
  //       {props.states.length > 0
  //         ? props.states.find((item) => item.value === row.expirationStatus)
  //             .name
  //         : row.expirationStatus}
  //     </span>
  //   );
  // };

  useEffect(() => {
    setTableData(props.data);
  }, [props.data]);

  const columns = [
    { id: "referenceCode", label: "ID DMS", minWidth: 70 },
    {
      id: "idTuNomina",
      label: "ID TuNomina",
      minWidth: 70,
      // defaultValue: "-----",
    },
    { id: "fullName", label: "Nombre", minWidth: 70 },
    { id: "identificationNumber", label: "Identificación", minWidth: 70 },
    {
      id: "email",
      label: "Correo Electrónico",
      minWidth: 70,
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      minWidth: 70,
      element: actions,
    },
  ];
  return (
    <GeneralTable
      columns={columns}
      data={tableData}
      // selected={props.selected}
      // setSelected={props.setSelected}
      // handleSelected={props.handleSelected}

      // toolbar={
      //   <Grid container justifyContent="space-between" alignItems="center">
      //     <ExpiryTableToolbar
      //       title={props.title}
      //       order={props.order}
      //       handleReport={props.handleReport}
      //       handleReportCsv={props.handleReportCsv}
      //       expiriesQuantity={props.data.length}
      //       selected={props.selected}
      //       handleToggleSimpleDialog={props.handleToggleSimpleDialog}
      //       handleDeleteMultipleExpiries={props.handleDeleteMultipleExpiries}
      //     />
      //     <TableSearch
      //       data={props.data}
      //       setTableData={setTableData}
      //       searchFields={["client"]}
      //     />
      //   </Grid>
      // }
    />
  );
}

export { HumanResourcesTable };
