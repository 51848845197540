
import * as React from "react";
import { connect } from "react-redux";
import FileUploadSelectInput from "src/containers/FileUpload/FileUploadSelectInput";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import DocumentIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {
  deletePayrollDeduction,
  fetchPayrollDeduction,
  updatePayrollDeduction,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  debtorsValidation,
  filesGeneration,
  resetError,
  successHandler,
  translations,
} from "../../../utils";
import {
  deletePayrollDeduction as deleteMA,
  fetchPayrollDeduction as fetchMA,
  updatePayrollDeduction as updateMA,
} from "../../../utils/axios";
import CreditorSelectInput from "../../Creditor/CreditorSelectInput";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
import AttorneySelectInput from "../../Attorney/AttorneySelectInput";
import InstitutionSelectInput from "../../Institutions/InstitutionsSelectInput";
import CompanyRepresentativeSelectInput from "../../CompanyRepresentative/SelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

interface IViewProps {
  payrollDeductionForms: IPayrollDeduction[];
  classes: {
    attachmentIcon: any;
    checkboxLabel: any;
    controls: any;
    deleteButton: any;
    errorInput: any;
    formControl: any;
    greenButton: any;
    half: any;
    paper: any;
    root: any;
    textField: any;
    title: any;
  };
  user: IUser;
  id: any;
  changeSection(sectionNumber: number): void;
  deletePayrollDeduction(uniqueId: string): IAction<IPayrollDeduction[]>;
  fetchPayrollDeduction(
    inputCertificationContract: IPayrollDeduction
  ): IAction<IPayrollDeduction[]>;
  updatePayrollDeduction(
    inputCertificationContract: IPayrollDeduction
  ): IAction<IPayrollDeduction[]>;
}
interface IViewState {
  [key: string]: string | number | boolean | string[] | undefined;

  status: string;

  MA: string;
  contractDate: string;
  contractDateError: string;

  dialog: boolean;
  dialogTitle: string;
  title: string;
  errorMessage: string;
  debtor: string[];
  debtorError: string;
  creditor: string[];
  creditorError: string;
  loading: boolean;

  attorney: string[]; // done
  attorneyError: string;

  institution: string[]; // done
  institutionError: string;

  companyRepresentative: string[]; // done
  companyRepresentativeError: string;

  uniqueId: string;
}

class View extends React.Component<IViewProps, IViewState> {
  public state: IViewState = {
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    creditor: [],
    creditorError: "",
    debtor: [],
    attorney: [], // done
    attorneyError: "",
    institution: [], // done
    institutionError: "",
    companyRepresentative: [], // done
    companyRepresentativeError: "",
    debtorError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    MA: "",
    title: "",
    titleError: "",
    status: "pending_signature",
    uniqueId: "",
  };
  constructor(props: IViewProps) {
    super(props);

    // creditor
    this.handleAddCreditor = this.handleAddCreditor.bind(this);
    this.handleDeleteCreditor = this.handleDeleteCreditor.bind(this);

    // client
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);
    // attorney
    this.handleAddAttorney = this.handleAddAttorney.bind(this);
    this.handleDeleteAttorney = this.handleDeleteAttorney.bind(this);

    // institution
    this.handleAddInstitution = this.handleAddInstitution.bind(this);
    this.handleDeleteInstitution = this.handleDeleteInstitution.bind(this);
    // institution
    this.handleAddCompanyRepresentative = this.handleAddCompanyRepresentative.bind(
      this
    );
    this.handleDeleteCompanyRepresentative = this.handleDeleteCompanyRepresentative.bind(
      this
    );

    // core events
    this.generateFile = this.generateFile.bind(this);
    this.changeStatus = this.changeStatus.bind(this);

    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }
  public handleAddCompanyRepresentative(id: string) {
    this.setState({ companyRepresentative: [id] });
  }
  public handleDeleteCompanyRepresentative(id: string) {
    this.setState({ companyRepresentative: [] });
  }
  public handleAddInstitution(id: string) {
    this.setState({ institution: [id] });
  }
  public handleDeleteInstitution(id: string) {
    this.setState({ institution: [] });
  }
  public handleAddAttorney(id: string) {
    this.setState({ attorney: [id] });
  }
  public handleDeleteAttorney(id: string) {
    this.setState({ attorney: [] });
  }
  public handleAddCreditor(id: any) {
    this.setState({ creditor: [id] });
  }
  public handleDeleteCreditor(id: any) {
    this.setState({ creditor: [] });
  }
  public handleAddClient(id: any) {
    this.setState({ debtor: [id] });
  }
  public handleDeleteClient(id: any) {
    this.setState({ debtor: [] });
  }

  public handleCheckBoxChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  public async generateFile() {
    this.setState({ loading: true });
    if (
      await debtorsValidation(
        this,
        this.props.user.token,
        this.state.debtor,
        false
      )
    ) {
      const url = `/lending/payroll-deduction-form/${this.state.uniqueId}/print`;
      await filesGeneration(url, this.props.user.token);
      this.setState({ loading: false });
    }
  }
  public changeStatus(status: any) {
    return () => {
      this.setState({ status });
    };
  }

  public componentDidMount() {
    fetchMA(this.props.user.token, this.props.id)
      .then((res) => {
        const contract: IPayrollDeduction = res.data;
        this.setState({
          status: contract.status || "",
          contractDate: contract.contractDate || "",
          creditor: contract.creditor ? [contract.creditor] : [],
          debtor: contract.debtor ? [contract.debtor] : [],
          dialog: false,
          dialogTitle: "",
          loading: false,
          MA: `lending/payroll-deduction-form/${contract.uniqueId}/upload_attachment/`,
          title: contract.title || "",
          attorney: contract.notarialAttorney
            ? [contract.notarialAttorney]
            : [],
          institution: contract.workPlace ? [contract.workPlace] : [],
          companyRepresentative: contract.companyRepresentative
            ? [contract.companyRepresentative]
            : [],
          uniqueId: contract.uniqueId || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleCurrencyInputChange(key: any) {
    return (event: any, maskedValue = "", floatValue = "") => {
      this.setState({ [key]: floatValue });
    };
  }
  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    if (!this.state.companyRepresentative.length) {
      this.setState({ companyRepresentativeError: "Este campo es requerido" });
    } else if (!this.state.institution.length) {
      this.setState({ institutionError: "Este campo es requerido" });
    } else if (!this.state.attorney.length) {
      this.setState({ attorneyError: "Este campo es requerido" });
    } else if (!this.state.title) {
      this.setState({ titleError: "Este campo es requerido" });
    } else {
      const contract: IPayrollDeduction = {
        contractDate: state.contractDate,
        creditor: state!.creditor[0] || "",
        debtor: state.debtor[0] || "",
        title: state.title,
        status: state.status,
        notarialAttorney: state.attorney[0] || "",
        workPlace: state.institution[0] || "",
        companyRepresentative: state.companyRepresentative[0] || "",
      };

      this.setState({ loading: true });

      updateMA(this.props.user.token, this.state.uniqueId, contract)
        .then((res) => {
          this.props.updatePayrollDeduction(
            res.data.results ? res.data.results : res.data
          );
          successHandler(res, this, translations.MA);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }

  public handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteMA(this.props.user.token, this.props.id)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deletePayrollDeduction(this.props.id);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        noValidate={true}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <FileUploadSelectInput
            contractId={this.state.uniqueId}
            url={this.state.MA}
            description="PDF"
          />
          <Typography variant="h4" className={classes.title}>
            {translations.PAYROLL_DEDUCTION_CONTRACT}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={classes.greenButton}
                onClick={this.generateFile}
                style={{ marginRight: 10, marginTop: 25 }}
              >
                <DocumentIcon />
                &nbsp;
                {translations.GENERATE_DOCUMENT}
              </Button>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="status" shrink={true}>
                  {translations.STATUS}
                </InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                  required={true}
                  inputProps={{
                    id: "status",
                    name: "status",
                  }}
                >
                  <MenuItem value="pending_signature">
                    {translations.PENDING_SIGNATURE}
                  </MenuItem>
                  <MenuItem value="internal_signature_pending">
                    {translations.INTERNAL_SIGNATURE_PENDING}
                  </MenuItem>
                  <MenuItem value="in_legalization_process">
                    {translations.IN_LEGALIZATION_PROCESS}
                  </MenuItem>
                  <MenuItem value="completed">
                    {translations.COMPLETED}
                  </MenuItem>
                  <MenuItem value="archived">{translations.ARCHIVED}</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={this.state.title}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.CREDITORS}
                </Typography>
                <CreditorSelectInput
                  selectedCreditorsId={this.state.creditor}
                  addCreditor={this.handleAddCreditor}
                  deleteCreditor={this.handleDeleteCreditor}
                />
                <p className={classes.errorInput}>{this.state.creditorError}</p>
              </div>
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.PARTNERS}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtor}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.ATTORNEY}
                </Typography>
                <AttorneySelectInput
                  selectedAttorneysId={this.state.attorney}
                  addAttorney={this.handleAddAttorney}
                  deleteAttorney={this.handleDeleteAttorney}
                />
                <p className={classes.errorInput}>{this.state.attorneyError}</p>
              </div>
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  Empresa donde labora
                </Typography>
                <InstitutionSelectInput
                  selectedInstitutionId={this.state.institution}
                  addInstitution={this.handleAddInstitution}
                  deleteInstitution={this.handleDeleteInstitution}
                />
                <p className={classes.errorInput}>
                  {this.state.institutionError}
                </p>
              </div>
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.COMPANY_REPRESENTATIVE}
                </Typography>
                <CompanyRepresentativeSelectInput
                  selectedCompanyRepresentativeId={
                    this.state.companyRepresentative
                  }
                  addCompanyRepresentative={this.handleAddCompanyRepresentative}
                  deleteCompanyRepresentative={
                    this.handleDeleteCompanyRepresentative
                  }
                />
                <p className={classes.errorInput}>
                  {this.state.companyRepresentativeError}
                </p>
              </div>
              <br />
              <br />

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    payrollDeductionForms: state.payrollDeductionForms,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deletePayrollDeduction,
    fetchPayrollDeduction,
    updatePayrollDeduction,
  })(View)
);
