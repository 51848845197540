import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LoadingDialog from "src/components/SharedComponents/LoadingScreen";
import { ToolbarComponent } from "src/components/SharedComponents/Toolbar";
import { HumanResourcesTable } from "../Table";
import { HumanResource } from "../Dialogs";
import {
  fetchHumanResources,
  createHumanResources,
  updateHumanResources,
  fetchPositions,
  createHRTuNomina,
  deleteHumanResources,
} from "src/utils/axios";
import { errorHandling } from "src/components/SharedComponents/CustomHooks";
import {
  SuccessDialog,
  ErrorDialog,
  SimpleConfirmDialog,
} from "../../../components/SharedComponents/Dialogs";

function HumanResourcesView(props) {
  const [loading, setLoading] = useState(false);
  const [humanResources, setHumanResources] = useState([]);
  const [options, setOptions] = useState([]);
  const [dialog, setDialog] = useState({ name: "", data: null });
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });
  const limitRequest = { limit: 25, institution: props.institution };

  const handleToggleDialog = (dialog, data) => {
    const selectedDialog = dialog ? dialog : "";
    const dialogData = data && (data.uniqueId || data.id) ? data : null;
    const obj = { name: selectedDialog, data: dialogData };
    setDialog({ ...obj });
  };
  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };
  const createUpdateHumanResource = (action, data) => {
    data.institution = props.institution;
    setLoading(true);
    const endpoint =
      action === "update" ? updateHumanResources : createHumanResources;
    endpoint({ ...data, token: props.user.token })
      .then((response) => {
        handleToggleSimpleDialog("success-dialog");
        handleToggleDialog(undefined);
        getHumanResources(limitRequest);
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };
  const handleDeleteHumanResource = (data) => {
    setLoading(true);
    const endpoint = deleteHumanResources(props.user.token, data);
    endpoint
      .then((response) => {
        handleToggleSimpleDialog("simple-dialog", "Usuario de recursos humanos eliminado correctamente");
        handleToggleDialog(undefined);
        getHumanResources(limitRequest);
        setLoading(false);
      }).catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  }
  const getHumanResources = async (data) => {
    setLoading(true);
    const endpoint = fetchHumanResources({ ...data, token: props.user.token });
    await endpoint
      .then((res) => {
        const resData = res.data.results || res.data;
        setHumanResources(resData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandling(error, handleToggleSimpleDialog);
      });
  };
  const getGeneralData = (data) => {
    setLoading(true);
    const endpoint = fetchPositions({ token: props.user.token });
    endpoint
      .then((res) => {
        const resData = res.data.results || res.data;

        setOptions({ position: resData });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorHandling(error, handleToggleSimpleDialog);
      });
  };
  const GetIdTuNomina = (data) => {
    setLoading(true);
    const request = {
      identificationNumber: data.identificationNumber || "",
      position: data.position || "",
      institution: data.institution || "",
      fullName: data.fullName || "",
      identificationType: data.identificationType || "",
      cellPhone: data.cellPhone || "",
      workPhone: data.workPhone || "",
      workPhoneExtension: data.workPhoneExtension || "",
      email: data.email || "",
      sendMails: data.sendMails || false,
      idTuNomina: data.idTuNomina || "",
      uniqueId: data.uniqueId || null,
    };

    const endpoint = createHRTuNomina({ ...request, token: props.user.token });
    endpoint
      .then((res) => {
        handleToggleSimpleDialog("success-dialog");
        handleToggleDialog(undefined);
        getHumanResources(limitRequest);
        setLoading(false);
      }).catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  }
  useEffect(() => {
    getHumanResources(limitRequest);
    getGeneralData();
  }, []);

  return (
    <div>
      <LoadingDialog open={loading} />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle="Recursos Humanos"
        btnVariant="contained"
        toolbarActions={
          <div>
            <Button
              variant="contained"
              className={"action-method-btn"}
              size="small"
              disableElevation
              onClick={handleToggleDialog.bind(this, "resource")}
            >
              <Add
                style={{ fontSize: 20, color: "#fff", marginRight: ".2rem" }}
              />
              Crear Recurso Humano
            </Button>
          </div>
        }
      />
      <div
        style={{
          padding: "1%",
          border: "1px solid #EEEEEE",
          borderTop: "none",
        }}
      >
        <HumanResourcesTable
          data={humanResources}
          handleToggleDialog={handleToggleDialog}
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          handleDelete={handleDeleteHumanResource}
          sendToTuNomina={GetIdTuNomina}
          setLoading={setLoading}
        />
      </div>
      {["resource", "resource-modify"].includes(dialog.name) ? (
        <HumanResource
          open={true}
          handleToggleDialog={handleToggleDialog}
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          options={options}
          token={props.user.token}
          handleSave={createUpdateHumanResource}
          modify={dialog.name === "resource-modify"}
          data={dialog.data}
          setLoading={setLoading}
        />
      ) : null}
      {/* {simpleDialog.name === "confirm-dialog" ? (
        <Confirm
          open={true}
          data={simpleDialog}
          handleToggle={handleToggleSimpleDialog}
        />
      ) : null} */}
      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}
      {simpleDialog.name === "error-dialog" ? (
        <ErrorDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
    </div>
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(HumanResourcesView);
