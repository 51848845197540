
import { INPUT_CERTIFICATION_CONTRACT_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case INPUT_CERTIFICATION_CONTRACT_TYPES.FETCH_ALL:
            return action.payload || [];
        case INPUT_CERTIFICATION_CONTRACT_TYPES.FETCH:
            return state.map(inputCertficationContract => (action.payload !== undefined && inputCertficationContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: inputCertficationContract);
        case INPUT_CERTIFICATION_CONTRACT_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case INPUT_CERTIFICATION_CONTRACT_TYPES.UPDATE:
            return state.map(inputCertficationContract => (action.payload !== undefined && inputCertficationContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : inputCertficationContract);
        case INPUT_CERTIFICATION_CONTRACT_TYPES.DELETE:
            return state.filter(inputCertficationContract => action.payload !== undefined && inputCertficationContract.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}