import './Dashboard.css';
import * as React from 'react';
import Header from '../../components/Header/Header';
import MainSection from '../MainSection';
import Menu from '../../components/Menu';
import Modal from '../../components/UI/Modal/index';
import { connect } from 'react-redux';
import { faClipboardList, faPowerOff, faRobot } from '@fortawesome/free-solid-svg-icons';
import { getToken, clearStorage } from 'src/utils';
import { logOff, logUser } from '../../actions';
import { verifyToken } from 'src/utils/axios';


const styles = {
  root:{
      boxSizing: 'border-box',
      height: '100vh',
      margin: 0,
      overflowY: 'hidden',
      padding: 0,
      width: '100%'
  }
}

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    const token = getToken();
    if (token) {
      const user = { token };
      this.state = { user: user }
      this.props.logUser(user)
    } else {
      this.props.history.push('/');
    }

    this.handleTMS = this.handleTMS.bind(this);
    this.handleBOT = this.handleBOT.bind(this);
    this.handleLogOff = this.handleLogOff.bind(this);
    this.logOff = this.logOff.bind(this);
  }

  componentDidMount() {
    verifyToken(getToken())
      .catch(() => {
        this.props.logOff();
        clearStorage();
        this.props.history.push('/');
      });
  }

  handleTMS() {
    this.props.history.push('/tmsdashboard')
  }
  handleBOT() {
    this.props.history.push('/bot')
  }

  logOff() {
    this.props.logOff();
    clearStorage();
    this.props.history.push('/');
  }

  handleLogOff() {
    this.setState({ confirmLogoff: !this.state.confirmLogoff })
  }

  render() {
    return (
      <div style={styles.root}>
        {this.state.confirmLogoff ?
          <Modal onCloseModal={this.handleLogOff}>
            <div>
              <span>Estas seguro que deseas salir?</span>
              <div className="logOff-modal-buttons">
                <button className="logOff-cancel" onClick={this.handleLogOff}>Cancelar</button>
                <button onClick={this.logOff}>Si, estoy seguro</button>
              </div>
            </div>
          </Modal> : null}
        <Header options={[
          { action: this.handleTMS, icon: faClipboardList, title: 'TMS' },
          { action: this.handleBOT, icon: faRobot, title: 'BOT' },
          { action: this.handleLogOff, icon: faPowerOff, title: 'Cerrar sesión' },
        ]} />
        <Menu />
        <MainSection />

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, { logOff, logUser })(Dashboard);