import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export default function Popup(props) {
  const {
    children,
    setOpen,
    open,
    dialogText,
    title,
    funcion,
    onCloseValue,
  } = props;

  return (
    <Dialog open={open} maxWidth={props.maxWidth || "lg"} fullScreen={props.fullScreen}>
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton
            onClick={() => {
              setOpen
                ? setOpen(false)
                : onCloseValue
                ? funcion(onCloseValue)
                : funcion();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ position: "relative" }} dividers>
        {dialogText ? (
          <DialogContentText>{dialogText}</DialogContentText>
        ) : null}
        {children[0] ? children[0] : children}
      </DialogContent>
      {children[1] ? <DialogActions>{children[1]}</DialogActions> : null}
    </Dialog>
  );
}
