import React, { useEffect, useState } from "react";
import style from "./style.css";
import { connect } from "react-redux";
import { Clear } from "@material-ui/icons";
import { Button, Icon, Tab, TablePagination } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { fetchDelayReports } from "src/utils/axios";
import { catchError } from "src/utils";
import LoadingDialog from "src/components/SharedComponents/LoadingScreen";
import { TextField, MenuItem, InputLabel } from "@material-ui/core";
import Filter from "./filter";
import { Autocomplete } from "@material-ui/lab";
import { ToolbarComponent } from "src/components/SharedComponents/Toolbar";
import { API_KEY } from "src/utils/axios";
import axios from "axios";

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: 300,
    overflow: "auto",
    margin: 10,
  },

  table: {
    minWidth: 650,
  },

  menuItem: {
    maxWidth: 300,
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#006400",
    color: theme.palette.common.white,
    fontSize: 16,
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    textAlign: "center",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    maxHeight: "200px",
    overflow: "auto",
  },
}))(TableRow);

const col = [
  { key: "number", header: "No. Socio" },
  { key: "name", header: "Nombre" },
  { key: "email", header: "Correo" },
  { key: "cellphone", header: "Telefono" },
  { key: "uniqueLoanNumbers", header: "No. de Prestamos" },
  { key: "maxDayPast", header: "Dias de Atraso" },
  { key: "totalSavingDue", header: "Ahorro Pendiente" },
  { key: "totalDue", header: "Monto Vencimiento" },
  { key: "otherCharge", header: "Mora" },
  { key: "totalPending", header: "Total Pendiente" },
  { key: "representative", header: "Representante" },
];

function delayreport(props) {
  const classes = useStyles();
  let associateNumber = 1;
  let totalRep = 0;
  const token = props.user.token;
  const [delayReports, setDelayReports] = useState();
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const applyFilters = (fields) => {
    setSelectedFilters(fields);
    setCurrentPage(0);
    fetchReports(fields, null);
  };

  const fetchReports = async (fields, limit) => {
    try {
      setLoading(true);
      const response = await fetchDelayReports(token, limit, 1, fields);
      const datos = response.data.data;
      setDelayReports(datos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      catchError(error);
    }
  };

  useEffect(() => {
    fetchReports(null, 30);
  }, []);

  const handleDownloadPDF = () => {
    setLoading(true);
    const params = {};
    for (const key in selectedFilters) {
      if (selectedFilters[key] !== "") {
        params[key] = selectedFilters[key];
      }
    }
    axios({
      headers: {
        Authorization: "Bearer " + token,
      },
      method: "get",
      url: `${API_KEY}/outlay/outlay/delay_report_pdf/`,
      params,
      responseType: "blob",
    })
      .then((res) => {
        const fileUrl = URL.createObjectURL(
          res.data.results ? res.data.results : res.data
        );
        window.open(fileUrl, "_blank");
      })
      .catch((err) => {
        catchError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <div style={{ padding: "20px 20px 10% 20px" }}>
      <LoadingDialog open={isLoading} />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle="Reporte de atrasos"
        btnVariant="contained"
      />
      <div
        style={{
          padding: "1%",
          border: "1px solid #EEEEEE",
          borderTop: "none",
        }}
      >
        <Filter applyFilters={applyFilters} token={token} />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: 0,
          }}
        >
          <TextField
            style={{ width: "300px" }}
            label="Buscar"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchValue && (
                    <IconButton onClick={() => setSearchValue("")} edge="end">
                      <Clear />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{ marginTop: 2 }}>
          {delayReports?.institution
            ?.slice(
              currentPage * itemsPerPage,
              currentPage * itemsPerPage + itemsPerPage
            )
            .map((inst) => (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: 0,
                  }}
                >
                  <Button
                    variant="contained"
                    className={"action-method-btn"}
                    size="medium"
                    onClick={handleDownloadPDF}
                  >
                    Descargar PDF
                  </Button>
                  <Typography
                    key={inst.institutionName}
                    variant="text"
                    component="h3"
                  >
                    {inst && inst.institutionFullName}
                  </Typography>
                  <h4>
                    Total pendiente:{" "}
                    {inst.associates
                      .reduce((sum, associate) => {
                        return sum + parseFloat(associate.totalPending);
                      }, 0)
                      .toLocaleString("en-US", {
                        currency: "USD",
                      })}
                  </h4>
                </div>
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                >
                  <Table
                    stickyHeader
                    className={classes.table}
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        {col.map((columna) => (
                          <StyledTableCell
                            key={columna.key}
                            className="centerText"
                          >
                            {columna.header}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inst.associates
                        .filter((associate) =>
                          associate.name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        )
                        .map((associate) => (
                          <StyledTableRow key={associate.id}>
                            <StyledTableCell>
                              {associateNumber++}
                            </StyledTableCell>
                            <StyledTableCell>
                              {associate.name || "--"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {associate.email || "--"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {associate.cellphone || "--"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {associate.uniqueLoanNumbers || "--"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {associate.maxDaysPast || "--"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {parseFloat(
                                associate.totalSavingDue
                              ).toLocaleString("en-US", {
                                currency: "USD",
                                minimumFractionDigits: 2,
                              }) || "--"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {parseFloat(associate.totalDue).toLocaleString(
                                "en-US",
                                {
                                  currency: "USD",
                                }
                              ) || "--"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {parseFloat(associate.otherCharge).toLocaleString(
                                "en-US",
                                {
                                  currency: "USD",
                                }
                              ) || "--"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {parseFloat(
                                associate.totalPending
                              ).toLocaleString("en-US", {
                                currency: "USD",
                              }) || "--"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {associate.representativeName || "--"}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={delayReports?.institution?.length || 0}
                  page={currentPage}
                  rowsPerPage={itemsPerPage}
                  onPageChange={handleChangePage}
                />
              </>
            ))}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(delayreport);
