import * as React from "react";
import { translations } from "src/utils";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

const styles: any = createStyles({
  formControl: {
    marginTop: 20,
  },
  greenButton: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  label: {
    color: green[500],
  },
  redButton: {
    "&:hover": {
      backgroundColor: red[200],
    },
    backgroundColor: red[500],
    color: "white",
  },
  textField: {
    marginTop: 10,
    minWidth: 190,
  },
});

interface IAccountingRecordSearchFilterProps {
  token: string;
  open: boolean;
  classes: {
    [key: string]: string;
  };
  handleClose(): void;
  search(params: IAccountingRecordParams): void;
}

interface IAccountingRecordSearchFilterState {
  [key: string]: string | string[];

  accounting_id: string;
  financial_id: string;
  outlay_id: string;
  record_data: string[];
  record_status: string;
  reference_code: string;
  ticket_id: string;
  transaction_date_from: string;
  transaction_date_to: string;
}

class AccountingRecordSearchFilter extends React.Component<
  IAccountingRecordSearchFilterProps,
  IAccountingRecordSearchFilterState
> {
  public state: IAccountingRecordSearchFilterState = {
    accounting_id: "",
    financial_id: "",
    outlay_id: "",
    record_data: [],
    record_status: "",
    reference_code: "",
    ticket_id: "",
    transaction_date_from: "",
    transaction_date_to: new Date().toISOString().split("T")[0],
  };
  constructor(props: IAccountingRecordSearchFilterProps) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }
  public handleSearch() {
    const params: IAccountingRecordParams = {
      accountingId: this.state.accounting_id,
      financialId: this.state.financial_id,
      outlayId: this.state.outlay_id,
      recordData: this.state.record_data,
      recordStatus: this.state.record_status,
      referenceCode: this.state.reference_code,
      ticketId: this.state.ticket_id,
      token: this.props.token,
      transactionDateFrom: this.state.transaction_date_from,
      transactionDateTo: this.state.transaction_date_to,
    };
    this.props.search(params);
    this.props.handleClose();
  }
  public handleClear() {
    this.setState({
      accounting_id: "",
      financial_id: "",
      limit: "",
      offset: "",
      outlay_id: "",
      record_data: [],
      record_status: "",
      reference_code: "",
      ticket_id: "",
      transaction_date_from: "",
      transaction_date_to: new Date().toISOString().split("T")[0],
    });
    this.props.search({ token: this.props.token });
    this.props.handleClose();
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.currentTarget.value });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ [key]: event.target.value });
    };
  }
  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public render() {
    const { classes, handleClose, open } = this.props;
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <TextField
            label="Id Contable"
            value={this.state.accounting_id}
            fullWidth={true}
            onChange={this.handleChange("accounting_id")}
            InputLabelProps={{
              style: styles.label,
            }}
          />
          <TextField
            label="Id Financiero"
            value={this.state.financial_id}
            fullWidth={true}
            onChange={this.handleChange("financial_id")}
            InputLabelProps={{
              style: styles.label,
            }}
          />
          <TextField
            label="Id Desembolso"
            value={this.state.outlay_id}
            fullWidth={true}
            onChange={this.handleChange("outlay_id")}
            InputLabelProps={{
              style: styles.label,
            }}
          />
          <TextField
            label="Estados"
            value={this.state.record_status}
            fullWidth={true}
            onChange={this.handleChange("record_status")}
            InputLabelProps={{
              style: styles.label,
            }}
          />
          <TextField
            label={`Numero de ${translations.REFERENCE}`}
            value={this.state.reference_code}
            fullWidth={true}
            onChange={this.handleChange("reference_code")}
            InputLabelProps={{
              style: styles.label,
            }}
          />
          <TextField
            label={translations.TICKET_NUMBER}
            value={this.state.ticket_id}
            fullWidth={true}
            onChange={this.handleChange("ticket_id")}
            InputLabelProps={{
              style: styles.label,
            }}
          />
          <TextField
            label={`${translations.TRANSACTIONS} ${translations.FROM}`}
            className={classes.textField}
            value={this.state.transaction_date_from}
            style={{ width: "50%" }}
            type="date"
            onChange={this.handleChange("transaction_date_from")}
            InputLabelProps={{
              shrink: true,
              style: styles.label,
            }}
          />
          <TextField
            label={`${translations.TRANSACTIONS} ${translations.TO}`}
            className={classes.textField}
            value={this.state.transaction_date_to}
            style={{ width: "50%" }}
            type="date"
            onChange={this.handleChange("transaction_date_to")}
            InputLabelProps={{
              shrink: true,
              style: styles.label,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button className={classes.redButton} onClick={this.handleClear}>
            <ClearIcon />
            &nbsp;
            {`${translations.CLEAR} ${translations.SEARCH}`}
          </Button>
          <Button className={classes.greenButton} onClick={this.handleSearch}>
            <SearchIcon />
            &nbsp;
            {translations.SEARCH}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AccountingRecordSearchFilter);
