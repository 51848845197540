import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import { createPledgeContractWithoutDivestment } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  translations,
  weekDays,
  periodicity,
} from "../../../utils";
import {
  createPledgeContractWithoutDivestment as create,
  fetchWarranty,
} from "../../../utils/axios";
import CreditorSelectInput from "../../Creditor/CreditorSelectInput";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
import AttorneySelectInput from "../../Attorney/AttorneySelectInput";
import WarrantyTable from "../../Warranty/WarrantySelectInput/warrantyTable";

import MoneyInput from "../../../components/TextMaskInputComponents/moneyInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

interface IFormProps {
  classes: {
    attachmentIcon: any;
    checkboxLabel: any;
    controls: any;
    deleteButton: any;
    errorInput: any;
    formControl: any;
    greenButton: any;
    half: any;
    paper: any;
    root: any;
    textField: any;
    title: any;
  };
  user: IUser;
  changeSection(sectionNumber: number): void;
  createPledgeContractWithoutDivestment(
    payrollDeductionForm: IPledgeContractWithoutDivestment
  ): IAction<IPledgeContractWithoutDivestment[]>;
}
interface IFormState {
  [key: string]: string | number | boolean | string[] | any | undefined;

  contractDate: string;
  contractDateError: string;

  dialog: boolean;
  dialogTitle: string;
  title: string;
  errorMessage: string;
  debtor: string[];
  debtorError: string;
  creditor: string[];
  creditorError: string;
  loading: boolean;

  attorney: string[];
  attorneyError: string;

  warrants: string[];
  warrantsError: string;

  interestRate: number;
  interestRateError: string;

  warranty: string;
  warrantyError: string;

  spouse: string[];
  spouseError: string;

  warranties: string[];
  warrantiesError: string;

  loanContractDate: string;
  loanContractDateError: string;

  gpsCompany: string;
  gpsCompanyError: string;

  periodicity: any;
  periodicityError: string;

  amount: any;
  amountError: string;

  firstPaymentDay: string;
  firstPaymentDayError: string;

  secondPaymentDay: string;
  secondPaymentDayError: string;

  destination: string;
  destinationError: string;
  destinationDescription: string;
  destinationDescriptionError: string;
  warrantyObj: any;
  warrantyIndex: string[];
  warrantyIndexError: string;

  term: number;
  termError: string;

  firstPaymentDate: string;
  firstPaymentDateError: string;

  lastPaymentDate: string;
  lastPaymentDateError: string;

  vehicleAmount: any;
  vehicleAmountError: string;

  vehicleInsuranceAmount: any;
  vehicleInsuranceAmountError: string;
}

class Form extends React.Component<IFormProps, IFormState> {
  public state: IFormState = {
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    creditor: [],
    creditorError: "",
    loanContractDate: new Date().toISOString().split("T")[0],
    loanContractDateError: "",
    debtor: [],
    attorney: [],
    attorneyError: "",
    warrants: [],
    warrantsError: "",
    debtorError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    PCWD: "",
    title: "",
    titleError: "",
    destination: "",
    destinationError: "",
    destinationDescription: "",
    destinationDescriptionError: "",
    interestRate: 0,
    interestRateError: "",
    spouse: [],
    spouseError: "",
    warranties: [],
    warrantiesError: "",
    warranty: "",
    warrantyError: "",
    gpsCompany: "",
    gpsCompanyError: "",
    periodicity: "",
    periodicityError: "",
    firstPaymentDay: "",
    firstPaymentDayError: "",
    secondPaymentDay: "",
    secondPaymentDayError: "",

    amount: "",
    amountError: "",
    warrantyIndex: [],
    warrantyIndexError: "",
    warrantyObj: {},
    term: 0,
    termError: "",

    firstPaymentDate: new Date().toISOString().split("T")[0],
    firstPaymentDateError: "",
    lastPaymentDate: new Date().toISOString().split("T")[0],
    lastPaymentDateError: "",
    vehicleAmount: "",
    vehicleAmountError: "",
    vehicleInsuranceAmount: "",
    vehicleInsuranceAmountError: "",
  };

  constructor(props: IFormProps) {
    super(props);

    // creditor
    this.handleAddCreditor = this.handleAddCreditor.bind(this);
    this.handleDeleteCreditor = this.handleDeleteCreditor.bind(this);

    // debtor
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // attorney
    this.handleAddAttorney = this.handleAddAttorney.bind(this);
    this.handleDeleteAttorney = this.handleDeleteAttorney.bind(this);

    // warrant
    this.handleAddWarrant = this.handleAddWarrant.bind(this);
    this.handleDeleteWarrant = this.handleDeleteWarrant.bind(this);

    // wife || spouse
    this.handleAddWife = this.handleAddWife.bind(this);
    this.handleDeleteWife = this.handleDeleteWife.bind(this);

    // core event
    this.handleChecked = this.handleChecked.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  public handleCurrencyInputChange(key: any) {
    return (event: any, maskedValue = "", floatValue = "") => {
      this.setState({ [key]: floatValue });
    };
  }
  public handleChecked(indx: string, warranty: string, warrantyType: string) {
    if (warrantyType === "property_warranty") {
      let warranties = this.state.warrantyIndex;
      if (warranties.every((val) => val.slice(0, -1) === warranty)) {
        if (warranties.includes(indx)) {
          warranties = warranties.filter((wId) => wId !== indx);
        } else {
          warranties = warranties.concat(indx);
        }
      } else {
        warranties = [];
        warranties = warranties.concat(indx);
      }
      this.setState({
        warranty,
        warrantyIndex: warranties,
      });
    } else {
      this.setState({
        warranty,
        warrantyIndex: [indx],
      });
    }
  }

  public handleAddWarrant(id: string) {
    const warrants = this.state.warrants.filter(
      (warrantId) => warrantId !== id
    );
    warrants.push(id);
    this.setState({ warrants });
  }
  public handleDeleteWarrant(id: string) {
    const warrants = this.state.warrants.filter(
      (warrantId) => warrantId !== id
    );
    this.setState({ warrants });
  }
  public handleAddWife(id: string) {
    this.setState({ spouse: [id] });
  }
  public handleDeleteWife(id: string) {
    this.setState({ spouse: [""] });
  }
  public handleAddCompanyRepresentative(id: string) {
    this.setState({ companyRepresentative: [id] });
  }
  public handleDeleteCompanyRepresentative(id: string) {
    this.setState({ companyRepresentative: [] });
  }
  public handleAddInstitution(id: string) {
    this.setState({ institution: [id] });
  }
  public handleDeleteInstitution(id: string) {
    this.setState({ institution: [] });
  }
  public handleAddAttorney(id: string) {
    this.setState({ attorney: [id] });
  }
  public handleDeleteAttorney(id: string) {
    this.setState({ attorney: [] });
  }
  public handleAddCreditor(id: any) {
    this.setState({ creditor: [id] });
  }
  public handleDeleteCreditor(id: any) {
    this.setState({ creditor: [] });
  }
  public handleAddClient(id: any) {
    this.setState({ debtor: [id] });
  }
  public handleDeleteClient(id: any) {
    this.setState({ debtor: [] });
  }
  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleCheckBoxChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public handleSelectChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.value });
    };
  }
  // public async componentDidMount() {
  //     await fetchWarranties({ token: this.props.user.token, limit: 20, offset: 0 }).then(res => {
  //         this.setState({ warranties: res.data.results })
  //     })
  // }

  public handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    if (!this.state.creditor.length) {
      this.setState({ creditorError: "Este campo es requerido" });
    } else if (!this.state.debtor.length) {
      this.setState({ debtorError: "Este campo es requerido" });
    } else if (!this.state.attorney.length) {
      this.setState({ attorneyError: "Este campo es requerido" });
    } else if (!this.state.warranty) {
      this.setState({ warrantyError: "Este campo es requerido" });
    } else if (
      state.warrantyObj?.warrantyType === "vehicle_warranty" &&
      state.gpsCompany === ""
    ) {
      this.setState({ gpsCompanyError: "Este campo es requerido" });
    } else if (
      state.warrantyObj?.warrantyType === "vehicle_warranty" &&
      state.vehicleAmount === 0
    ) {
      this.setState({ vehicleAmountError: "Este campo es requerido" });
    } else if (
      state.warrantyObj?.warrantyType === "vehicle_warranty" &&
      state.vehicleInsuranceAmount === 0
    ) {
      this.setState({ vehicleInsuranceAmountError: "Este campo es requerido" });
    } else if (state.warrantyObj?.warrantyType === "mortgage") {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: `No se puede guardar este documento, ya que el tipo de garantia seleccionado no se admite ese tipo de documento`,
        loading: false,
      });
    } else {
      const contract: IPledgeContractWithoutDivestment = {
        contractDate: state.contractDate,
        creditor: state!.creditor[0] || "",
        debtors: state.debtor || "",
        title: state.title,
        attorney: state.attorney[0] || "",
        destination:
          state.destination === "Otros"
            ? state.destinationDescription
            : state.destination,
        loanContractDate: state.loanContractDate,
        spouse: state.spouse[0] || "",
        warrants: state.warrants || [],
        gpsCompany: state.gpsCompany || "",
        warranty: state.warranty || "",
        interestRate: state.interestRate || 0,
        periodicity: state.periodicity,
        firstPaymentDay: state.firstPaymentDay,
        secondPaymentDay: state.secondPaymentDay,
        amount: state.amount,
        warrantyIndex: !state.warrantyIndex ? [] : state.warrantyIndex,
        term: state.term || 0,
        firstPaymentDate: state.firstPaymentDate || "",
        lastPaymentDate: state.lastPaymentDate || "",
        vehicleAmount: state.vehicleAmount || 0,
        vehicleInsuranceAmount: state.vehicleInsuranceAmount || 0,
      };

      this.setState({ loading: true });

      create(this.props.user.token, contract)
        .then((res) => {
          this.props.createPledgeContractWithoutDivestment(
            res.data.results ? res.data.results : res.data
          );
          successHandler(res, this, translations.PCWD);
          this.handleReset();
        })
        .catch((err: any) => {
          catchError(err, this);
        });
    }
  }

  // public componentDidUpdate(prevProps: any, prevState: any) {
  //     if(prevState.warranty !== this.state.warranty){
  //        this.setState({
  //             warrantyObj: this.state.warranties.find((warranty: any) => warranty.uniqueId === this.state.warranty)
  //        })
  //     }
  // }

  public async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.warranty !== this.state.warranty) {
      await fetchWarranty(this.props.user.token, this.state.warranty).then(
        (res) => {
          this.setState({
            warrantyObj: res.data,
          });
        }
      );
    }
  }

  public handleReset() {
    this.setState({
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      creditor: [],
      creditorError: "",
      debtor: [],
      debtorError: "",
      familyMembersInfo: [],
      familyMembersInfoError: "",
      financialAccounts: [],
      financialAccountsError: "",
      loading: false,
      title: "",
      titleError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;

    return (
      <form name="PCWD" onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            Contrato de Prenda sin Desapoderamiento
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <TextField
                    label={translations.TITLE}
                    fullWidth={true}
                    value={this.state.title}
                    onChange={this.handleChange("title")}
                    required={true}
                    error={this.state.titleError !== ""}
                    helperText={this.state.titleError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Fecha de creación del contrato"}
                    fullWidth={true}
                    value={this.state.contractDate}
                    type="date"
                    onChange={this.handleChange("contractDate")}
                    required={true}
                    error={this.state.contractDateError !== ""}
                    helperText={this.state.contractDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <FormControl
                    fullWidth={true}
                    error={this.state.destinationError !== ""}
                  >
                    <InputLabel
                      style={styles.checkboxLabel}
                      htmlFor="destination"
                    >
                      {translations.DESTINATION}
                    </InputLabel>
                    <Select
                      value={this.state.destination}
                      onChange={this.handleSelectChange("destination")}
                      required={true}
                      inputProps={{
                        id: "destination",
                        name: "destination",
                      }}
                    >
                      <MenuItem value={translations.WORKING_CAPITAL}>
                        {translations.WORKING_CAPITAL}
                      </MenuItem>
                      <MenuItem value={translations.CONSUMER_FINANCING}>
                        {translations.CONSUMER_FINANCING}
                      </MenuItem>
                      <MenuItem value={translations.COLLEGE_CREDIT}>
                        {translations.COLLEGE_CREDIT}
                      </MenuItem>
                      <MenuItem value={translations.DEBT_REFINANCING}>
                        {translations.DEBT_REFINANCING}
                      </MenuItem>
                      <MenuItem value={translations.REAL_STATE_PROPERTY}>
                        {translations.REAL_STATE_PROPERTY}
                      </MenuItem>
                      <MenuItem value={translations.MOTOR_VEHICLE}>
                        {translations.MOTOR_VEHICLE}
                      </MenuItem>
                      <MenuItem value={translations.OTHERS}>
                        {translations.OTHERS}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Fecha de la linea de credito"}
                    fullWidth={true}
                    value={this.state.loanContractDate}
                    type="date"
                    onChange={this.handleChange("loanContractDate")}
                    required={true}
                    error={this.state.loanContractDateError !== ""}
                    helperText={this.state.loanContractDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Fecha de inicio del contrato"}
                    fullWidth={true}
                    value={this.state.firstPaymentDate}
                    type="date"
                    onChange={this.handleChange("firstPaymentDate")}
                    required={true}
                    error={this.state.firstPaymentDateError !== ""}
                    helperText={this.state.firstPaymentDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Fecha de finalización del contrato"}
                    fullWidth={true}
                    value={this.state.lastPaymentDate}
                    type="date"
                    onChange={this.handleChange("lastPaymentDate")}
                    required={true}
                    error={this.state.lastPaymentDateError !== ""}
                    helperText={this.state.lastPaymentDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                {this.state.warrantyObj?.warrantyType === "vehicle_warranty" ? (
                  <Grid item={true} xs={4}>
                    <TextField
                      label="Monto de la poliza"
                      error={this.state.vehicleInsuranceAmountError !== ""}
                      helperText={this.state.vehicleInsuranceAmounttError}
                      value={this.state.vehicleInsuranceAmount}
                      onChange={this.handleCurrencyInputChange(
                        "vehicleInsuranceAmount"
                      )}
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                      InputProps={{ inputComponent: MoneyInput }}
                    />
                  </Grid>
                ) : null}
                {this.state.warrantyObj?.warrantyType === "vehicle_warranty" ? (
                  <Grid item={true} xs={4}>
                    <TextField
                      label={"Compañia de GPS"}
                      fullWidth={true}
                      value={this.state.gpsCompany}
                      onChange={this.handleChange("gpsCompany")}
                      required={true}
                      error={this.state.gpsCompanyError !== ""}
                      helperText={this.state.gpsCompanyError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                ) : null}
                {this.state.warrantyObj?.warrantyType === "vehicle_warranty" ? (
                  <Grid item={true} xs={4}>
                    <TextField
                      label="Monto del vehiculo"
                      required={true}
                      fullWidth={true}
                      value={this.state.vehicleAmount}
                      error={this.state.vehicleAmountError !== ""}
                      helperText={this.state.vehicleAmountError}
                      onChange={this.handleCurrencyInputChange("vehicleAmount")}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                      InputProps={{ inputComponent: MoneyInput }}
                    />
                  </Grid>
                ) : null}
                <Grid item={true} xs={4}>
                  <TextField
                    fullWidth={true}
                    label={translations.TERM}
                    value={this.state.term}
                    onChange={this.handleChange("term")}
                    required={true}
                    error={this.state.termError !== ""}
                    helperText={this.state.termError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    type="number"
                    inputProps={{
                      max: 100,
                      min: 1,
                      step: 1,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label="Monto del desembolso"
                    fullWidth={true}
                    value={this.state.amount}
                    onChange={this.handleCurrencyInputChange("amount")}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    InputProps={{ inputComponent: MoneyInput }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <FormControl
                    fullWidth={true}
                    error={this.state.periodicityError !== ""}
                  >
                    <InputLabel
                      style={styles.checkboxLabel}
                      htmlFor="periodicity"
                    >
                      {translations.PERIODICITY}
                    </InputLabel>
                    <Select
                      value={this.state.periodicity}
                      onChange={this.handleSelectChange("periodicity")}
                      required={true}
                      inputProps={{
                        id: "periodicity",
                        name: "periodicity",
                      }}
                    >
                      {periodicity.map((opt: any, index: any) => {
                        return (
                          <MenuItem key={index} value={opt.value}>
                            {opt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={`${translations.INTEREST_RATE}: ${this.state.interestRate}%`}
                    fullWidth={true}
                    value={this.state.interestRate}
                    type="number"
                    onChange={this.handleChange("interestRate")}
                    required={true}
                    error={this.state.interestRateError !== ""}
                    helperText={this.state.interestRateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 100,
                      min: 0,
                      step: 0.01,
                    }}
                  />
                </Grid>

                {this.state.periodicity === "biweekly" ? (
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={4}>
                      <TextField
                        fullWidth={true}
                        label={`Primer ${translations.PAYMENT_DAY}`}
                        value={this.state.firstPaymentDay}
                        onChange={this.handleChange("firstPaymentDay")}
                        required={true}
                        error={this.state.firstPaymentDayError !== ""}
                        helperText={this.state.firstPaymentDayError}
                        InputLabelProps={{
                          shrink: true,
                          style: styles.checkboxLabel,
                        }}
                        type="number"
                        inputProps={{
                          max: 31,
                          min: 1,
                          step: 1,
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={4}>
                      <TextField
                        fullWidth={true}
                        label={`Segundo ${translations.PAYMENT_DAY}`}
                        value={this.state.secondPaymentDay}
                        onChange={this.handleChange("secondPaymentDay")}
                        required={true}
                        error={this.state.secondPaymentDayError !== ""}
                        helperText={this.state.secondPaymentDayError}
                        InputLabelProps={{
                          shrink: true,
                          style: styles.checkboxLabel,
                        }}
                        type="number"
                        inputProps={{
                          max: 31,
                          min: 1,
                          step: 1,
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : this.state.periodicity === "weekly" ? (
                  <Grid item={true} xs={4}>
                    <FormControl
                      fullWidth={true}
                      error={this.state.firstPaymentDayError !== ""}
                    >
                      <InputLabel
                        style={styles.checkboxLabel}
                        htmlFor="firstPaymentDay"
                      >
                        {translations.PAYMENT_DAY}
                      </InputLabel>
                      <Select
                        label="sd"
                        value={this.state.firstPaymentDay}
                        onChange={this.handleSelectChange("firstPaymentDay")}
                      >
                        {weekDays.map((day, index) => {
                          return (
                            <MenuItem value={day.value} key={index}>
                              {day.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item={true} xs={4}>
                    <TextField
                      fullWidth={true}
                      label={`Primer ${translations.PAYMENT_DAY}`}
                      value={this.state.firstPaymentDay}
                      onChange={this.handleChange("firstPaymentDay")}
                      required={true}
                      type="number"
                      error={this.state.firstPaymentDayError !== ""}
                      helperText={this.state.firstPaymentDayError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                      inputProps={{
                        max: 31,
                        min: 1,
                        step: 1,
                      }}
                    />
                  </Grid>
                )}
                {this.state.destination === translations.OTHERS ? (
                  <Grid item={true} xs={4}>
                    <TextField
                      fullWidth={true}
                      label={translations.DESCRIPTION}
                      value={this.state.destinationDescription}
                      onChange={this.handleChange("destinationDescription")}
                      required={true}
                      error={this.state.destinationDescriptionError !== ""}
                      helperText={this.state.destinationDescriptionError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.CREDITORS}
                  </Typography>
                  <CreditorSelectInput
                    selectedCreditorsId={this.state.creditor}
                    addCreditor={this.handleAddCreditor}
                    deleteCreditor={this.handleDeleteCreditor}
                  />
                  <p className={classes.errorInput}>
                    {this.state.creditorError}
                  </p>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.PARTNERS}
                  </Typography>
                  <ParticipantSelectInput
                    selectedParticipantId={this.state.debtor}
                    addParticipant={this.handleAddClient}
                    deleteParticipant={this.handleDeleteClient}
                  />
                  <p className={classes.errorInput}>{this.state.debtorError}</p>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.ATTORNEY}
                  </Typography>
                  <AttorneySelectInput
                    selectedAttorneysId={this.state.attorney}
                    addAttorney={this.handleAddAttorney}
                    deleteAttorney={this.handleDeleteAttorney}
                  />
                  <p className={classes.errorInput}>
                    {this.state.attorneyError}
                  </p>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.WARRANT}
                  </Typography>
                  <ParticipantSelectInput
                    selectedParticipantId={this.state.warrants}
                    addParticipant={this.handleAddWarrant}
                    deleteParticipant={this.handleDeleteWarrant}
                  />
                  <p className={classes.errorInput}>
                    {this.state.warrantsError}
                  </p>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.WIFE}
                  </Typography>
                  <ParticipantSelectInput
                    selectedParticipantId={this.state.spouse}
                    addParticipant={this.handleAddWife}
                    deleteParticipant={this.handleDeleteWife}
                  />
                  <p className={classes.errorInput}>{this.state.spouseError}</p>
                </Grid>

                {this.state.debtor.length ? (
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      {translations.WARRANTIES}
                    </Typography>
                    <WarrantyTable
                      checked={this.state.warrantyIndex}
                      handleChecked={this.handleChecked}
                      debtors={this.state.debtor}
                    />
                    <p className={classes.errorInput}>
                      {this.state.warrantyError}
                    </p>
                  </Grid>
                ) : null}
              </Grid>
              <br />
              <br />
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createPledgeContractWithoutDivestment })(Form)
);
