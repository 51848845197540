import React from 'react';
import {
    Dialog, DialogContent, Button, Grid, Toolbar, Typography, IconButton
} from '@material-ui/core';
import { 
    ConfirmDialog, DialogHeader, DialogFooter, 
} from './Dialogs';
import { Delete, Add, Settings } from '@material-ui/icons';
import { GeneralTable } from './Table';
import NumberFormat from './NumberFormat';
import { paymentTypes } from '../../utils';
import PaymentCalculator from '../Calculators/PaymentTable';

export function PaymentNegotiationTable(props) {

    const amount = (row, value) => {
        return(
            <NumberFormat
                prefix={'$'}
                value={value}
            />
        )
    }
    const date = (row) => {
        return(
            <span>
                {row.created ? row.created.substring(0, row.created.indexOf('T')) : '---'}
                {/* {row.date.substring(0, row.date.indexOf('T'))} */}
                {/* {row.date} */}
            </span>
        )
    }
    const type = (row) => {
        const type = paymentTypes.find(item => item.value === row.paymentType);
        const pType = type ? type.label : '---';
        return(
            <span>
                {pType}
            </span>
        )
    }

    const actions = (row) => {
        return(
            <Grid container item direciton="row" justifyContent="space-evenly" spacing={5}>
                <IconButton
                    onClick={props.handleToggleDialog.bind(
                            this, 'payment-calculator', 
                            {
                                id:true, row: row, participant: props.participant,
                                paymentType: props.paymentType && props.paymentType.value
                            }
                        )
                    }
                >
                    <Settings style={{fontSize:22, color:'#616161'}} />
                </IconButton>
                {
                    !props.disabled && 
                    <IconButton
                        onClick={
                            props.handleToggleSimpleDialog.bind(
                                this, 'confirm-dialog', 'confirm-delete-payment', 
                                {callback: () => props.deletePayment(row.uniqueId)}
                            )
                        }
                    >
                        <Delete style={{fontSize:22, color:'#616161'}} />
                    </IconButton>
                }
            </Grid>
        );
    };

    const columns = [
        {id:'checkbox', label:'SELECCIÓN', minWidth: 70 },
        {id:'referenceCode', label: 'REFERENCIA', minWidth: 150},
        {id:'created', label: 'FECHA', minWidth: 150, element: date},
        {id:'totalToPay', label: 'MONTO A PAGAR', minWidth: 120, element: amount},
        {id:'paymentType', label: 'METODOLOGÍA DE PAGO', minWidth: 190, element: type},
        {id:'action', label: 'ACCIÓN', align: 'center', minWidth: 200, element: actions},
    ];
    return(
        <GeneralTable
            columns={columns}
            data={props.data}
            limitSelection={props.limitSelection}
            removeSelectAll={props.removeSelectAll}
            handleSelected={props.handleSelected}
            selected={props.selected}
            setSelected={props.setSelected}
            disableSelection={props.disableSelection}
            toolbar={
                <Toolbar>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Typography
                            variant="subtitle1"
                            className="section-header"
                            style={{fontWeight: 'bold'}}
                        >
                            PAGOS CALCULADOS
                        </Typography>
                        {
                            !props.readOnly ? 
                            <Button 
                                variant="outlined"
                                disableElevation
                                style={{borderRadius:3, color: props.paymentType ? '#424242' : '#CCCCCC', fontSize:12}}
                                onClick={props.handleToggleDialog.bind(
                                        this, 'payment-calculator',
                                        {
                                            id: true, participant: props.participant, callback: props.setPaymentInfo, 
                                            paymentType: props.paymentType && props.paymentType.value
                                        }
                                    )
                                }
                                disabled={!props.paymentType || props.disabled}
                            >
                                <Add style={{fontSize:17, marginRight:'.5rem'}} />
                                agregar negociación
                            </Button>
                            : null
                        }
                    </Grid>
                </Toolbar>
            }
            
        />
    );
}

export function PaymentCalculatorDialog(props) {
    const participant = props.data && props.data.participant;
    const clientData = participant && participant.fullName;
    let paymentCalcData = null;
    let filters = {
        rohiId: participant && participant.rohiId,
        uniqueId: participant && participant.uniqueId,
        paymentType: props.data?.row?.paymentType || '',
    };
    let actionType = 'new';
    if(props.data.hasOwnProperty('row')) {
        actionType = 'detail'
        paymentCalcData = props.data.row;
    }
    return(
        <Dialog
            fullWidth
            maxWidth="xl"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Negociación de pagos"
                handleClick={props.handleToggleDialog.bind(this, 'payment-calculator')}
            />

            <DialogContent style={{margin:'2% 0'}}>
                <PaymentCalculator
                    data={paymentCalcData ? paymentCalcData.expirationDetails : []} // detail
                    clientData={clientData} // detail
                    filters={filters} // detail
                    setCalculatorInfo={(props.data && props.data.callback) && props.data.callback}
                    readOnly={actionType === 'detail'}
                    fromTicket={'paymentReceipt'}
                />
            </DialogContent>

            <DialogFooter 
                dialogFooterText="Cerrar"
                handleClick={props.handleToggleDialog}
            />
        </Dialog>
    )
}