import * as React from 'react';
import { operatorList, translations } from 'src/utils';
import { fetchParticipants } from 'src/utils/axios';
import { fetchAssignees } from 'src/utils/axios';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
// import Checkbox from '@material-ui/core/Checkbox';
import moneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import { Grid, MenuItem, Typography } from '@material-ui/core';
import { AutocompleteField, SearchComponent } from 'src/components/SharedComponents/Fields';
import { NumericMinMax } from 'src/components/SharedComponents/Fields';
import { DateField } from 'src/components/SharedComponents/Fields';
import { dateFormat } from 'src/components/SharedComponents/CustomHooks';


// const statusOptions = [
//     { value: 'on_process', label:translations.OUTLAY_STATUS_ON_PROCESS},
//     { value: 'processed', label:translations.OUTLAY_STATUS_PROCESSED },
//     { value: 'reconciled', label:translations.OUTLAY_STATUS_RECONCILED },
//     { value: 'validated', label:translations.OUTLAY_STATUS_VALIDATED },
//     { value: 'cancelled', label:translations.OUTLAY_STATUS_CANCELLED },
// ]
// const outlayStatusOptions = [
//     { value: 'active', label:translations.ACTIVE},
//     { value: 'refunded', label:translations.REFUNDED },
// ]
const productTypeDisplay = [
    { value: 'simple_loan', label:translations.SIMPLE_LOAN},
    { value: 'salary_advance', label:translations.SALARY_ADVANCE },
    { value: 'constant_fees', label:translations.CONSTANT_FEES_LOAN },
    { value: 'decreasing_fees', label:translations.DECREASING_FEES_LOAN },
    { value: 'advance_purchases', label:translations.ADVANCE_PURCHASES },
    { value: 'vacational_bonus', label:translations.VACATIONAL_BONUS },
    { value: 'antiquity_incentive', label:translations.ANTIQUITY_INCENTIVE },
    { value: 'antiquity_bonus', label:translations.ANTIQUITY_BONUS },
    { value: 'october_bonus_1', label:translations.OCTOBER_BONUS_1 },
    { value: 'october_bonus_2', label:translations.OCTOBER_BONUS_2 },
    { value: 'easter_royalty', label:translations.EASTER_ROYALTY },
    // { value: 'personal_loan', label:translations.PERSONAL_LOAN },
    { value: 'school_supplies', label:translations.SCHOOL_SUPPLIES },
    { value: 'irregular_bonus', label:translations.IRREGULAR_BONUS },
    { value: 'anniversary_bonus', label:translations.ANNIVERSARY_BONUS },
    { value: 'annual_bonus', label:translations.ANNUAL_BONUS },
]


const styles = createStyles({
    greenButton: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    redButton: {
        '&:hover': {
            backgroundColor: red[200]
        },
        backgroundColor: red[500],
        color: 'white'
    },
    textField: {
      "& .MuiOutlinedInput-root": {
        height: 40,
        width: "90%", 
        marginTop: "16px",
        backgroundColor: "white",
      },
    },
});

class OutlaySearchFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {    
        clients: [],
        assignees: {},
        oficial: null,
        product_type: '',
        customer: [],
        transaction_date_from: null,
        transaction_date_to: null,
        act_date_from: '',
        transfered_amount_from: '',
        transfered_amount_to: '',
        original_amount_from: '',
        original_amount_to: '',
        original_amount: '',
        open: false
    }; 
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.cleanAutoComplete = this.cleanAutoComplete.bind(this);
    }
    componentDidMount() {
        fetchParticipants({
          limit: 20,
          offset: 0,  
          token: this.props.token,
        }).then((res) => {
          this.setState({ clients: res.data.results});
        })
        .catch((err) => {
            this.printMessage(err, "error");
        });
        fetchAssignees({
          token: this.props.token,
        }).then((res) => {
          const data = res.data;
          const username = data.map((user) => {
            const name = user.firstName;
            const lastName = user.lastName.split(' ');
            const username = name + ' ' + lastName[0]
            return username;
          });
          this.setState({
            assignees: username
          });
        }).catch((err) =>{
          this.printMessage(err, "error");
        });
        
        
      }
    handleSearch() {
        const tipoTransaccion = this.state.product_type;
        const params = {
            tipo_transaccion: tipoTransaccion.label,
            nombre_socio: this.state.customer,
            nombre_oficial: this.state.oficial,
            transactionDateFrom: dateFormat(this.state.transaction_date_from),
            transactionDateTo: dateFormat(this.state.transaction_date_to),
            min_transferAmount: Number(this.state.transfered_amount_from? this.state.transfered_amount_from : ''),
            max_transferAmount: Number(this.state.transfered_amount_to? this.state.transfered_amount_to : ''),
            monto_solicitado_min: Number(this.state.original_amount_from? this.state.original_amount_from: ''),
            monto_solicitado_max: Number(this.state.original_amount_to? this.state.original_amount_to: ''),
        };
        Object.keys(params).forEach(key => {
          if(!params[key] || params[key].length < 1) {
              delete params[key];
          }
      });
      // console.log(params)
        this.props.search(params);
    }
    handleClear() {
        this.setState({
            // transaction_status: [],
            // status: [],
            product_type: '',
            oficial: null,
            customer: [],
            // creditor: '',
            // reference_code: '',
            transaction_date_from: null,
            transaction_date_to: null,
            transfered_amount_from: '',
            transfered_amount_to: '',
            transfered_amount: '',
            original_amount_from: '',
            original_amount_to: '',
            original_amount: '',
            original_amount_options: 'None',
            transfer_amount_options: 'None',
            // is_refianceable: false
        });
        this.props.search({offset: 100})
    }
    handleChange = (field, val) => {
    return (event) => {
      const value = event.currentTarget.value;
      this.setState({ [field]: value });
    }};
    handleOficialChange(event, value) {
      if (value !== null) {
        const selectedOption = value;
        this.setState({ oficial: selectedOption });
      } else {
        this.setState({ oficial: null });
      }
    }
    cleanAutoComplete(optionList) {
        // if length of list is not 0
        if (optionList.length) {
            let cleanList = ""
            for(let i of optionList) {
                // take every object, return only the value
                cleanList += `${(i.value)},`
            }
            return cleanList
        }
        
        // else, return default value
        return ''
    }
    handleAutocompleteChange(key, obj, val) {
        /* Handle the state of Autocomplete components */
        const data = obj || val;
    
        this.setState({
          [key]: data.fullName ? data.fullName : ""
        });
    
      }

    render() {
        const { open, handleClose,classes } = this.props;
        const handleCurrencyInputChange = (key, event, value) => {
          this.setState({ [key]: event.value || '' }, () => {
            const { original_amount_from, original_amount_to } = this.state;
          });
        };
        
        const handleChange2 = (key, val) => (
            this.setState({ [key]: val })
        )
        const filterOptions = createFilterOptions({
            stringify: (option) => option.fullName + option.identificationNumber,
        });
        const approbationInstanceList = [
            { value: "Gerente general" },
            { value: "Consejo de administracion" },
            { value: "Analista de credito" },
            { value: "Oficial de cumpliento" },
        ]
        return (
          <Grid className="filters-container" style={{ margin: 20 }}>
            <Grid item container spacing={3} direction="row">
              <AutocompleteField
                xs={4}
                fieldLabel="Tipo de Producto"
                fieldInnerLabel="Seleccione tipo de producto"
                limitTags={1}
                options={productTypeDisplay}
                getOptionLabel={(option) => option.label}
                value={this.state.product_type}
                handleChange={(e, newValue) =>
                  handleChange2("product_type", newValue)
                }
              />
              <AutocompleteField
                xs={4}
                containerClass="field-container"
                fieldClass="fields"
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Oficial"
                fieldID="filter-field-priority"
                fieldInnerLabel="Nombre del oficial"
                fieldVariant="outlined"
                value={this.state.oficial}
                options={this.state.assignees}
                getOptionLabel={(option) => option}
                handleChange={this.handleOficialChange.bind(this)}
                limitTags={1}
              />
              <SearchComponent
                xs={4}
                containerClass="field-container"
                fieldClass="fields"
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Socio"
                fieldID="client"
                fieldInnerLabel="Nombre del socio"
                fieldVariant="standard"
                handleChange={this.handleAutocompleteChange.bind(
                  this,
                  "customer"
                )}
                optionType={"participants"}
                limitTags={1}
                disabled={this.props.disabled}
              />
              <NumericMinMax
                xs={4}
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Monto Original"
                fieldID="filter-field-paid-amount"
                fieldClass="fields"
                fieldVariant="outlined"
                margin="dense"
                fullWidth
                prefix="$"
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                valueMin={
                  this.state.original_amount_from
                    ? this.state.original_amount_from
                    : ""
                }
                valueMax={
                  this.state.original_amount_to
                    ? this.state.original_amount_to
                    : ""
                }
                onChangeMin={(event, value) => handleCurrencyInputChange("original_amount_from", event, value)}
                onChangeMax={(event, value) => handleCurrencyInputChange("original_amount_to", event, value)}
              />
              <NumericMinMax
                xs={4}
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Monto Transferido"
                fieldID="filter-field-paid-amount"
                fieldClass="fields"
                fieldVariant="outlined"
                margin="dense"
                fullWidth
                prefix="$"
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                valueMin={
                  this.state.transfered_amount_from
                    ? this.state.transfered_amount_from
                    : ""
                }
                valueMax={
                  this.state.transfered_amount_to
                    ? this.state.transfered_amount_to
                    : ""
                }
                onChangeMin={(event, value) => handleCurrencyInputChange("transfered_amount_from", event, value)}
                onChangeMax={(event, value) => handleCurrencyInputChange("transfered_amount_to", event, value)}
              />
              <DateField
                xs
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Desde"
                dateFieldID="filter-date-start"
                fullWidth={true}
                dateFieldClass="date-field"
                inputVariant="outlined"
                dateFieldLabel="Fecha inicio"
                format="dd/MM/yyyy"
                value={this.state.transaction_date_from}
                handleChange={(value) => handleChange2("transaction_date_from", value)}
              />
              <DateField
                xs
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Hasta"
                dateFieldID="filter-date-end"
                fullWidth={true}
                dateFieldClass="date-field"
                inputVariant="outlined"
                dateFieldLabel="Fecha fin"
                format="dd/MM/yyyy"
                value={this.state.transaction_date_to}
                handleChange={(value) => handleChange2("transaction_date_to", value)}
              />
            </Grid>
            <Grid
              container
              direction="row"
              style={{ marginTop: "35px", justifyContent: "space-between" }}
            >
              <Button className={classes.redButton} onClick={this.handleClear}>
                <ClearIcon />
                &nbsp;
                {`${translations.CLEAR} ${translations.SEARCH}`}
              </Button>
              <Button
                className={classes.greenButton}
                onClick={this.handleSearch}
              >
                <SearchIcon />
                &nbsp;
                {translations.SEARCH}
              </Button>
            </Grid>
          </Grid>
        );
    }

}

export default withStyles(styles)(OutlaySearchFilter);