import { ASSIGNEE_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case ASSIGNEE_TYPES.FETCH_ALL:
            return action.payload || [];
        case ASSIGNEE_TYPES.FETCH:
            return state.map(assignee => (action.payload !== undefined && assignee.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : assignee);
        default:
            return state;
    }
}