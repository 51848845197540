import { AUTHENTICATION_TYPES } from '../actions';

export default function(state: Partial<IUser> = {}, action: IAction<IUser>){
    switch(action.type){
        case AUTHENTICATION_TYPES.LOG_OFF:
            return {}
        case AUTHENTICATION_TYPES.LOG_USER:
            return action.payload
        default:
            return state
    }
}
 


