import { Divider, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import {
  AutocompleteField,
  DateField,
  TextFieldComponent,
} from "src/components/SharedComponents/Fields";
import ExtraPaymentTable from "src/components/Ticket/ExtraPayTable/ExtraPaymentTable";
import {
  category,
  creditMethod,
  pepRelationChoices,
  personTypes,
} from "src/utils";

export default function complianceDataForm({
  fields,
  rFields,
  onChange,
  section,
  getFiltersOptions,
  handleSelectValue,
  errorFields,
}) {
  const [editIdx, setEditIdx] = useState(-1);

  const headersAR = [
    { name: "Descripción", prop: "description", type: "text" },
  ];

  const handleStartEdit = (i) => {
    setEditIdx(i);
  };

  const handleStopEdit = () => {
    setEditIdx(-1);
  };

  const handleRemove = (i) => {
    const updatedData = [...fields.affidavitSources];
    updatedData.splice(i, 1);
    onChange("any", "affidavitSources", updatedData);
  };

  const handleSave = (data) => {
    onChange("any", "affidavitSources", data);
  };

  const handleChangeTableData = (i, newData) => {
    const updatedData = fields.affidavitSources.map((row, j) =>
      j === i ? newData : row
    );
    onChange("any", "affidavitSources", updatedData);
    handleStopEdit();
  };

  return (
    <Grid container spacing={3} className="form-wrapper-fields-container">
      <AutocompleteField
        xs={4}
        fieldLabel={`Comportamiento transaccional ${rFields.includes("transactionalBehavior") ? "(*)" : ""}`}
        fieldID="leads-field-transactionalBehavior"
        fieldInnerLabel="Seleccione una opción"
        value={handleSelectValue(fields.transactionalBehavior, creditMethod, "value") || null}
        handleChange={onChange.bind(this, "autocomplete", "transactionalBehavior")}
        options={getFiltersOptions(creditMethod)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.transactionalBehavior)}
        helperText={
          errorFields[section]?.transactionalBehavior ? errorFields[section]?.transactionalBehavior : null
        }
        multiple={false}
      />
      <AutocompleteField
        xs={4}
        fieldLabel={`Tipo de persona ${rFields.includes("personKind") ? "(*)" : ""}`}
        fieldID="leads-field-personKind"
        fieldInnerLabel="Seleccione una opción"
        value={handleSelectValue(fields.personKind, personTypes, "value") || null}
        handleChange={onChange.bind(this, "autocomplete", "personKind")}
        options={getFiltersOptions(personTypes)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.personKind)}
        helperText={
          errorFields[section]?.personKind ? errorFields[section]?.personKind : null
        }
        multiple={false}
      />
      <AutocompleteField
        xs={4}
        fieldLabel={`Categoría ${rFields.includes("category") ? "(*)" : ""}`}
        fieldID="leads-field-category"
        fieldInnerLabel="Seleccione una opción"
        value={handleSelectValue(fields.category, category, "value") || null}
        handleChange={onChange.bind(this, "autocomplete", "category")}
        options={getFiltersOptions(category)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.category)}
        helperText={
          errorFields[section]?.category ? errorFields[section]?.category : null
        }
        multiple={false}
      />
      {fields.category?.value == "pep" ||
      fields.category?.value == "pepRelation" ? (
        <Grid container spacing={2}>
          <TextFieldComponent
            xs={3}
            fullWidth
            fieldLabel={`Cargo ${rFields.includes("PepPosition") ? "(*)" : ""}`}
            fieldID="lead-info-PepPosition"
            placeholder="Ingrese el cargo"
            value={fields.PepPosition}
            onChange={onChange.bind(this, "text", "PepPosition")}
            error={Boolean(errorFields[section]?.PepPosition)}
            helperText={
              errorFields[section]?.PepPosition
                ? errorFields[section]?.PepPosition
                : null
            }
          />
          <DateField
            xs={3}
            fullWidth
            fieldLabel={`Fecha ${rFields.includes("PepDate") ? "(*)" : ""}`}
            dateFieldID="lead-info-PepDate"
            value={fields.PepDate ? fields.PepDate + " " : null}
            handleChange={onChange.bind(this, "date", "PepDate")}
            error={Boolean(errorFields[section]?.PepDate)}
            helperText={
              errorFields[section]?.PepDate
                ? errorFields[section]?.PepDate
                : null
            }
          />
          <TextFieldComponent
            xs={3}
            fullWidth
            fieldLabel={`Institución ${
              rFields.includes("PepInstitution") ? "(*)" : ""
            }`}
            fieldID="lead-info-PepInstitution"
            placeholder="Ingrese la institución"
            value={fields.PepInstitution}
            onChange={onChange.bind(this, "text", "PepInstitution")}
            error={Boolean(errorFields[section]?.PepInstitution)}
            helperText={
              errorFields[section]?.PepInstitution
                ? errorFields[section]?.PepInstitution
                : null
            }
          />
          <AutocompleteField
            xs={3}
            fieldLabel={`Parentesco ${
              rFields.includes("PepRelation") ? "(*)" : ""
            }`}
            fieldID="leads-field-PepRelation"
            fieldInnerLabel="Seleccione una opción"
            value={
              handleSelectValue(
                fields.PepRelation,
                pepRelationChoices,
                "value"
              ) || null
            }
            handleChange={onChange.bind(this, "autocomplete", "PepRelation")}
            options={getFiltersOptions(pepRelationChoices)}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.value === value.value}
            error={Boolean(errorFields[section]?.PepRelation)}
            helperText={
              errorFields[section]?.PepRelation
                ? errorFields[section]?.PepRelation
                : null
            }
            multiple={false}
          />
        </Grid>
      ) : null}

      <div style={{ width: "100%", margin: "1rem 0 1rem 0" }}>
        <Typography
          variant={"subtitle1"}
          className="section-header"
          style={{ fontWeight: "bold", color: "#5e5e5e" }}
        >
          PROCEDENCIA DE FONDOS
        </Typography>
        <Divider />
      </div>
      <Grid item={true} xs={12}>
        <Typography variant="h6">Fuentes</Typography>
        <ExtraPaymentTable
          actions={true}
          create={true}
          data={fields.affidavitSources || []}
          editIdx={editIdx}
          handleChange={handleChangeTableData}
          handleRemove={handleRemove}
          handleSave={handleSave}
          handleStartEdit={handleStartEdit}
          handleStopEdit={handleStopEdit}
          header={headersAR}
          tableLimit={5}
        />
      </Grid>
    </Grid>
  );
}
