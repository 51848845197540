import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import { Grid, Button, Paper } from "@material-ui/core";
import { Add, CloudDownload } from "@material-ui/icons";
import { ToolbarComponent } from "./../../../components/SharedComponents/Toolbar";
import { FileSelection } from "../../../components/SharedComponents/FileSelection";
import { ExpiryTable } from "./../Tables";
import {
  ExpiryPayDialog,
  Expiry,
  ExpiryRohi,
  ConfirmDialogComponent,
} from "./../Dialogs";
import { PaymentDialog } from "../../Pay/Dialogs";
import {
  SuccessDialog,
  ErrorDialog,
  SimpleConfirmDialog,
} from "../../../components/SharedComponents/Dialogs";
import { Filters } from "./../Filters";
import {
  fetchExpiriesOrdered as fetchExpiries,
  createExpiries,
  createExpiry,
  updateExpiry,
  deleteExpiry,
  fetchParticipants,
  fetchCreditors,
  createPayment,
  createPaymentVoucher,
  fetchInstitutions,
  createRohiExpirations,
  createExpiriesUrl,
  fetchExpirationReportCsv,
  fetchExpirationReport,
  fetchResponsibleAnalysts,
  fetchCreditorAccounts,
} from "./../../../utils/axios";
import LoadingDialog from "../../../components/SharedComponents/LoadingScreen";
import {
  PaymentTypes,
  ExpiryStates,
} from "../../../components/SharedComponents/DataListTypes";
import { errorHandling } from "../../../components/SharedComponents/CustomHooks";
import axios from "axios";
import "./../index.css";
import { fetchBankAccount } from "src/actions";

function ExpiryView(props) {
  const limitRequest = { limit: 25 };
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({ name: "", data: null });
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });
  const [expiries, setExpiries] = useState([]);
  const [reportExpiries, setReportExpiries] = useState([]);
  const [file, setFile] = useState(null);
  const [states, setStates] = useState([]);
  const [creditors, setCreditors] = useState([]);
  const [payTypes, setPayTypes] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [creditorAccounts, setCreditorAccounts] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [institutions, setInstitutions] = useState({});
  const [rohiExpiries, setRohiExpiries] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleToggleDialog = (dialog, data) => {
    const selectedDialog = dialog ? dialog : "";
    const dialogData = data && (data.uniqueId || data.id) ? data : null;
    const obj = { name: selectedDialog, data: dialogData };
    setDialog({ ...obj });
  };

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };

  const getExpiries = (data) => {
    setLoading(true);
    const endpoint = fetchExpiries({ ...data, token: props.user.token });
    endpoint
      .then((response) => {
        const resData = response.data.results || response.data;
        setReportExpiries(resData);
        const formattedData = resData.map((item) => {
          const clientDetail = item.clientDetail;
          return {
            ...item,
            client: clientDetail.fullName,
            clientDocument: clientDetail.identificationNumber,
            clientId: clientDetail.uniqueId,
            clientRohiId: clientDetail.rohiId,
            work: clientDetail.workProfileInstitution,
            bank: clientDetail.financialProfileInstitution,
            clientDetail: null,
          };
        });
        setExpiries(formattedData);
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };
  const uploadExpiries = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("file", file);
    const endpoint = createExpiries(props.user.token, formData);
    endpoint
      .then((response) => {
        getExpiries(selectedFilters);
        setFile(null);
        setLoading(false);
        if (response.data) {
          handleToggleSimpleDialog(
            "simple-dialog",
            "El proceso fue ejecutado correctamente, pero: " + response.data
          );
        } else {
          handleToggleSimpleDialog("success-dialog");
        }
      })
      .catch((errors) => {
        setFile(null);
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };
  const createUpdateExpiry = (action, data) => {
    setLoading(true);
    const endpoint = action === "update" ? updateExpiry : createExpiry;
    endpoint({ ...data, token: props.user.token })
      .then((response) => {
        if (response.data.warning) {
          handleToggleSimpleDialog(
            "simple-dialog",
            "El monto digitado es menor al monto actual del vencimiento."
          );
        } else {
          handleToggleSimpleDialog("success-dialog");
          handleToggleDialog(undefined);
          getExpiries(selectedFilters);
        }
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };
  const uploadPaymentVoucher = (data) => {
    let formData = new FormData();
    formData.append("file", data.file);
    const endpoint = createPaymentVoucher(props.user.token, data.id, formData);
    endpoint
      .then(() => {
        setLoading(false);
        getExpiries(selectedFilters);
        handleToggleSimpleDialog("success-dialog");
        handleToggleDialog(undefined);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };
  const createSinglePayment = (data) => {
    setLoading(true);
    const endpoint = createPayment({ ...data, token: props.user.token });
    endpoint
      .then((response) => {
        if (data.file) {
          uploadPaymentVoucher({ file: data.file, id: response.data.uniqueId });
        } else {
          getExpiries(selectedFilters);
          setLoading(false);
          handleToggleSimpleDialog("success-dialog");
          handleToggleDialog(undefined);
        }
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const deleteSingleExpiry = (expiry) => {
    setLoading(true);
    const endpoint = deleteExpiry(props.user.token, expiry.uniqueId);
    endpoint
      .then(() => {
        getExpiries(selectedFilters);
        setLoading(false);
        handleToggleSimpleDialog("success-dialog");
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const handleDeleteMultipleExpiries = () => {
    const data = selected.map((item) => item.uniqueId);
    const endpoint = deleteExpiry(props.user.token, data);
    endpoint
      .then(() => {
        getExpiries(selectedFilters);
        setSelected([]);
        setLoading(false);
        handleToggleSimpleDialog("success-dialog");
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const fetchRohiExpiries = (options) => {
    setLoading(true);
    const endpoint = createRohiExpirations(props.user.token, options);
    endpoint
      .then((res) => {
        setRohiExpiries(res.data.results ? res.data.results : res.data);
        setLoading(false);
        handleToggleSimpleDialog("rohi-upload");
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const getCreditors = () => {
    setLoading(true);
    const endpoint = fetchCreditors({ token: props.user.token });
    endpoint
      .then((response) => {
        const data = response.data.map((item) => {
          const creditorName =
            item.type === "legal"
              ? item.institutionDetail.abbreviation
              : item.participantDetail.fullName;
          return {
            name: creditorName ? creditorName : "--",
            uniqueId: item.uniqueId,
          };
        });
        setCreditors(data);
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };
  const getInstitutions = () => {
    setLoading(true);
    const getPrivateWorks = fetchInstitutions({
      token: props.user.token,
      institutionType: "private",
    });
    const getPublicWorks = fetchInstitutions({
      token: props.user.token,
      institutionType: "public",
    });
    const getBanks = fetchInstitutions({
      token: props.user.token,
      institutionType: "financial",
    });
    axios
      .all([getPrivateWorks, getPublicWorks, getBanks])
      .then(
        axios.spread((...responses) => {
          const institutions = {
            works: responses[0].data
              .concat(responses[1].data, responses[2].data)
              .map((item) => {
                return {
                  abbreviation: item.abbreviation,
                  name: item.name,
                  fullName: item.abbreviation + ": " + item.name,
                  uniqueId: item.uniqueId,
                  rohiId: item.rohiId,
                  institutionType: item.institutionType,
                  rohiIdPatrono: item.rohiIdPatrono || null,
                };
              }), //this is to select some properties of the object
            banks: responses[2].data.map((item) => {
              return {
                abbreviation: item.abbreviation,
                name: item.name,
                fullName: item.abbreviation + ": " + item.name,
                uniqueId: item.uniqueId,
                rohiId: item.rohiId,
              };
            }), //this is to select some properties of the object.
          };
          setInstitutions(institutions);
          setLoading(false);
        })
      )
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };
  const getResponsibles = () => {
    const endpoint = fetchResponsibleAnalysts(props.user.token);
    endpoint
      .then((response) => {
        const data = response.data.map((item) => {
          return {
            fullName: item.fullName,
            uniqueId: item.uniqueId,
          };
        });
        setResponsibles(data);
      })
      .catch((errors) => {
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };
  const getCreditorAccounts = () => {
    const endpoint = fetchCreditorAccounts({ token: props.user.token });
    endpoint
      .then((response) => {
        setCreditorAccounts(response.data);
      })
      .catch((errors) => {
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };
  const generateReport = () => {
    setLoading(true);
    const endpoint = fetchExpirationReport(props.user.token, reportExpiries);
    endpoint
      .then((response) => {
        const file = new Blob([response.data], { type: "text/html" });
        const fileURL = URL.createObjectURL(file);
        const NewWindow = window.open();
        NewWindow.location.href = fileURL;
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const generateReportCsv = () => {
    setLoading(true);
    const endpoint = fetchExpirationReportCsv(props.user.token, reportExpiries);
    endpoint
      .then((response) => {
        const file = new Blob([response.data], { type: "text/csv" });
        setLoading(true);
        saveAs(file, `Reporte_vencimientos.csv`);
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const handleCreation = () => {
    setLoading(true);
    createExpiriesUrl(props.user.token, rohiExpiries)
      .then((response) => {
        setLoading(false);
        if (response.data) {
          handleToggleSimpleDialog(
            "simple-dialog",
            "El proceso fue ejecutado correctamente, pero: " + response.data
          );
        } else {
          handleToggleSimpleDialog("success-dialog");
        }
      })
      .catch((errors) => {
        setFile(null);
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const handleSelected = (selection) => {
    setSelected(selection);
  };

  const applyFilters = (fields) => {
    let data = fields;
    if (Object.keys(fields).length === 0) {
      data = limitRequest;
    }
    getExpiries(data);
    setSelectedFilters({ ...data });
  };

  useEffect(() => {
    setPayTypes(PaymentTypes());
    setStates(ExpiryStates());
    getCreditors();
    getInstitutions();
    getExpiries(limitRequest);
    getResponsibles();
    getCreditorAccounts();
  }, []);

  return (
    <div style={{ padding: "20px 20px 10% 20px" }}>
      <LoadingDialog open={loading} />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle="Gestión de vencimientos"
        btnVariant="contained"
        toolbarActions={
          <div>
            <Button
              variant="contained"
              className={"action-method-btn"}
              disableElevation
              size="small"
              style={{ marginRight: "2rem" }}
              onClick={handleToggleDialog.bind(this, "rohi-upload")}
            >
              <CloudDownload
                style={{ fontSize: 20, color: "#fff", marginRight: ".5rem" }}
              />
              cargar vencimientos rohi
            </Button>

            <Button
              variant="contained"
              className={"action-method-btn"}
              size="small"
              disableElevation
              onClick={handleToggleDialog.bind(this, "expiry")}
            >
              <Add
                style={{ fontSize: 20, color: "#fff", marginRight: ".2rem" }}
              />
              crear un vencimiento
            </Button>
          </div>
        }
      />
      <div
        style={{
          padding: "1%",
          border: "1px solid #EEEEEE",
          borderTop: "none",
        }}
      >
        <Filters
          applyFilters={applyFilters}
          listData={{
            states: states,
            // clients: clients,
            works: institutions.works,
            banks: institutions.banks,
            creditors: creditors,
            creditorAccounts,
            responsibles,
          }}
          handleToggleSimpleDialog={handleToggleSimpleDialog}
        />
        {/* <Grid container justifyContent="space-between" style={{margin:'3rem 0px'}}> */}
        <Grid
          container
          justifyContent="flex-end"
          style={{ margin: "2rem 0px" }}
        >
          <FileSelection
            justifyContent="flex-end"
            xs={4}
            file={file}
            setFile={setFile}
            fileExtensions=".csv,.xls,.xlsx"
            inputId="input-file-selection-expiration-upload"
            uploadFile={handleToggleSimpleDialog.bind(
              this,
              "confirm-dialog",
              "file-upload",
              { callback: uploadExpiries }
            )}
          />
        </Grid>
        <ExpiryTable
          data={expiries}
          listData={{ works: institutions.works }}
          handleToggleDialog={handleToggleDialog}
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          handleDelete={deleteSingleExpiry}
          handleReport={generateReport}
          handleReportCsv={generateReportCsv}
          states={states}
          selected={selected}
          setSelected={setSelected}
          handleSelected={handleSelected}
          handleDeleteMultipleExpiries={handleDeleteMultipleExpiries}
        />
      </div>
      {/* {
                dialog.name === 'expiry-pay' ?
                    <ExpiryPayDialog
                        open={true}
                        handleToggleDialog={handleToggleDialog}
                        handleSimpleDialog={handleToggleSimpleDialog}
                        data={dialog.data}
                        token={props.user.token}
                        handlePayment={createSinglePayment}
                        listData={{ 
                            payTypes: payTypes, 
                            creditors: creditors, 
                            // clients: clients 
                        }}
                    /> : null
            } */}
      {dialog.name === "expiry-pay" ? (
        <PaymentDialog
          open={true}
          handleToggleDialog={handleToggleDialog}
          handleSimpleDialog={handleToggleSimpleDialog}
          modify={false}
          hasExpiry={true}
          data={dialog.data}
          removeExpirySelection={true}
          listData={{ payTypes: payTypes, creditors: creditors }}
          handlePayment={createSinglePayment}
          setLoading={setLoading}
          token={props.user.token}
        />
      ) : null}
      {["expiry", "expiry-modify"].includes(dialog.name) ? (
        <Expiry
          open={true}
          handleToggleDialog={handleToggleDialog}
          handleSimpleDialog={handleToggleSimpleDialog}
          handleExpiry={createUpdateExpiry}
          data={dialog.data}
          token={props.user.token}
          modify={dialog.name === "expiry-modify"}
          listData={{
            states: states,
            // clients: clients
          }}
          setLoading={setLoading}
        />
      ) : null}
      {dialog.name === "rohi-upload" && (
        <ExpiryRohi
          open={true}
          handleToggleDialog={handleToggleDialog}
          handleSimpleDialog={handleToggleSimpleDialog}
          handleRohiUpload={fetchRohiExpiries}
          handleCreation={handleCreation}
          data={dialog.data}
          rohiExpiries={rohiExpiries}
          listData={{ works: institutions.works }}
        />
      )}
      {simpleDialog.name === "confirm-dialog" ? (
        <ConfirmDialogComponent
          open={true}
          data={simpleDialog}
          handleToggle={handleToggleSimpleDialog}
        />
      ) : null}
      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}
      {simpleDialog.name === "error-dialog" ? (
        <ErrorDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps)(ExpiryView);
