import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { faCalculator } from "@fortawesome/free-solid-svg-icons";

import { createSavingsAccountContract } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import moneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import phoneMaskInput from "../../../components/TextMaskInputComponents/phoneMaskInput";
import Dialog from "../Dialog";
import CoopSanCalculator from "../../../components/Calculators/CoopSanTable";
import Calculator from "../../../components/Calculators/PlannedSavingsTable";

import {
  catchError,
  resetError,
  successHandler,
  translations,
  signatureTypes,
  collectionTypes,
  savingsAccountTypes,
  titleCaseHandler,
  debtorsValidation,
} from "../../../utils";
import {
  createSavingsAccountContract as create,
  fetchInterests,
} from "../../../utils/axios";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#4CAF50",
      },
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
      color: "red",
    },
    "& input:focus + fieldset, & select:focus + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& label.Mui-focused": {
      color: "green",
    },
    margin: 8,
  },
})(TextField);

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  gridFormControl: {
    margin: 8,
    width: "100%",
  },
  backButton: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
});

interface ISavingsAccountContractFormProps {
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    errorInput: string;
    formControl: string;
    greenButton: string;
    half: string;
    paper: string;
    root: string;
    backButton: string;
    gridFormControl: string;
    textField: string;
  };
  user: IUser;
  debtor?: string[];
  fromTicketType?: string;
  handleSaveCallBack?(uid: string): void;
  createSavingsAccountContract(
    savingsAccountContract: ISavingsAccountContract
  ): IAction<ISavingsAccountContract[]>;
  changeSection(sectionNumber: number): void;
}
interface ISavingsAccountContractFormState {
  [key: string]: string | number | boolean | string[] | any | null;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;

  title: string;
  titleError: string;

  type: string;
  typeError: string;

  signatureType: string;
  signatureTypeError: string;

  collectionType: string;
  collectionTypeError: string;

  mainDebtor: string[];
  mainDebtorError: string;

  secondaryDebtors: string[];
  secondaryDebtorsError: string;

  contractDate: string;
  contractDateError: string;

  quota: number;
  quotaError: string;

  term: number;
  termError: string;

  accountNumber: number | null;
  passbookNumber: number | null;

  goal: number;
  goalError: string;

  calculatorModal: boolean;
  calculator: string;

  // Child Info
  fullName: string;
  fullNameError: string;

  email: string;
  emailError: string;

  cellPhone: string;
  cellPhoneError: string;

  age: number;
  ageError: string;
}
class SavingsAccountContractForm extends React.Component<
  ISavingsAccountContractFormProps,
  ISavingsAccountContractFormState
> {
  public state: ISavingsAccountContractFormState = {
    calculatorModal: false,
    dialog: false,
    calculator: "",
    dialogTitle: "",
    errorMessage: "",
    title: "",
    titleError: "",
    type: this.props.fromTicketType || "functional",
    typeError: "",
    signatureType: "joint",
    signatureTypeError: "",
    collectionType: "payroll_deduction",
    collectionTypeError: "",
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    mainDebtor: [],
    mainDebtorError: "",
    secondaryDebtors: [],
    secondaryDebtorsError: "",
    loading: false,
    periodicity: "monthly",
    periodicityError: "",
    term: 12,
    termError: "",
    accountNumber: null,
    passbookNumber: null,
    quota: 0.0,
    quotaError: "",
    goal: 0.0,
    goalError: "",
    // Child Info
    cellPhone: "",
    cellPhoneError: "",
    fullName: "",
    fullNameError: "",
    email: "",
    emailError: "",
    age: 0,
    ageError: "",
  };

  constructor(props: ISavingsAccountContractFormProps) {
    super(props);

    // client
    this.handleAddMainClient = this.handleAddMainClient.bind(this);
    this.handleAddSecondaryClient = this.handleAddSecondaryClient.bind(this);
    this.handleDeleteMainClient = this.handleDeleteMainClient.bind(this);
    this.handleDeleteSecondaryClient = this.handleDeleteSecondaryClient.bind(
      this
    );

    // core event
    this.handleChange = this.handleChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
  }
  public handleAutocompleteChange(key: any, obj: any) {
    /* Handle the state of Autocomplete components */
    if (obj) {
      this.setState({
        [key]: obj.value,
      });
    }
  }
  public openDialog() {
    this.setState({ calculatorModal: true });
  }
  public closeDialog() {
    this.setState({
      calculatorModal: false,
    });
  }

  public componentDidMount() {
    const { token } = this.props.user;

    const options = {
      limit: 20,
      token,
      offset: 0,
      calculatorType: "coopsan",
    };
    fetchInterests(options).then((res) => {
      if (res.data.results.length === 0) {
        this.setState({
          termError: "No existe configuración de interés para Ahorros CoopSan",
        });
      } else {
        const data = res.data.results[0];
        this.setState({
          periods: data.description.sort(
            (a: any, b: any) => a.consecutiveQuotas - b.consecutiveQuotas
          ),
        });
      }
    });
    this.setState({
      loading: false,
      mainDebtor: this.state.mainDebtor.length
        ? this.state.mainDebtor
        : this.props.debtor || [],
      type: this.props.fromTicketType || "functional",
    });
  }
  public handleCheckBoxChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public handleAddMainClient(id: any) {
    this.setState({ mainDebtor: [id] });
  }
  public handleDeleteMainClient(id: any) {
    this.setState({ mainDebtor: [] });
  }
  public handleAddSecondaryClient(id: string) {
    const secondaryDebtors = this.state.secondaryDebtors.filter(
      (debtorId: any) => debtorId !== id
    );
    secondaryDebtors.push(id);
    this.setState({ secondaryDebtors });
  }
  public handleDeleteSecondaryClient(id: string) {
    const secondaryDebtors = this.state.secondaryDebtors.filter(
      (debtorId: any) => debtorId !== id
    );
    this.setState({ secondaryDebtors });
  }

  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    if (this.state.mainDebtor.length === 0) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "El socio es requerido.",
      });
    } else if (
      (!this.state.quota || !this.state.goal) &&
      (this.state.type === "coopsan" || this.state.type === "planned")
    ) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "Completar todos los campos requeridos.",
      });
    } else if (!this.state.quota && this.state.type === "functional") {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "Completar todos los campos requeridos.",
      });
    } else {
      const savingsAccountContract: ISavingsAccountContract = {
        title: state.title,
        openingDate: state.contractDate,
        mainDebtor: state.mainDebtor[0],
        secondaryDebtors: state.secondaryDebtors,
        type: state.type,
        signatureType: state.signatureType,
        collectionType: state.collectionType,
        goal: state.goal || 0.0,
        quota: state.quota || 0.0,
        accountNumber: state.accountNumber || null,
        passbookNumber: state.passbookNumber || null,
        term: state.term || 12,
        periodicity: state.periodicity || "",
        childInfo: {
          fullName: state.fullName || "",
          cellPhone: state.cellPhone || "",
          email: state.email || "",
          age: state.age || "",
        },
      };

      this.setState({ loading: true });

      if (
        await debtorsValidation(
          this,
          this.props.user.token,
          state.mainDebtor,
          false
        )
      ) {
        create(this.props.user.token, savingsAccountContract)
          .then((res) => {
            this.props.createSavingsAccountContract(
              res.data.results ? res.data.results : res.data
            );
            if (this.props.handleSaveCallBack) {
              this.props.handleSaveCallBack(res.data.uniqueId);
            }
            successHandler(res, this, translations.LOAN_CONTRACT);
            this.handleReset();
          })
          .catch((err: AxiosError) => {
            catchError(err, this);
          });
      }
    }
  }
  public handleReset() {
    this.setState({
      dialog: false,
      dialogTitle: "",
      errorMessage: "",
      title: "",
      titleError: "",
      type: this.props.fromTicketType || "functional",
      typeError: "",
      signatureType: "joint",
      signatureTypeError: "",
      collectionType: "payroll_deduction",
      collectionTypeError: "",
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      mainDebtor: [],
      mainDebtorError: "",
      secondaryDebtors: [],
      secondaryDebtorsError: "",
      loading: false,
      periodicity: "monthly",
      periodicityError: "",
      term: 0,
      termError: "",
      accountNumber: null,
      passbookNumber: null,
      quota: 0.0,
      quotaError: "",
      goal: 0.0,
      goalError: "",
      // Child Info
      cellPhone: "",
      cellPhoneError: "",
      fullName: "",
      fullNameError: "",
      email: "",
      emailError: "",
      age: 0,
      ageError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <FontAwesomeIcon
            style={{
              height: 45,
              position: "absolute",
              right: 48,
              width: 30,
              padding: 10,
              color: green[500],
              top: 145,
            }}
            onClick={this.openDialog}
            className={classes.backButton}
            icon={faCalculator}
            color="green"
            id="Calculator"
          />
          <Typography variant="h3">
            Nueva solicitud de cuenta de ahorro
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Dialog
                handleClose={this.closeDialog}
                openDialog={this.state.calculatorModal}
              >
                <div className="quotation-container">
                  <section className="header-section1">
                    <Autocomplete
                      style={{ width: 250 }}
                      options={[
                        {
                          value: "planned savings",
                          label: translations.PLANNED_SAVINGS,
                        },
                        { value: "coopsan", label: "Ahorros CoopSan" },
                      ]}
                      getOptionLabel={(option) => option.label}
                      value={
                        this.state.calculator
                          ? [
                              {
                                value: "planned savings",
                                label: translations.PLANNED_SAVINGS,
                              },
                              { value: "coopsan", label: "Ahorros CoopSan" },
                            ].find(
                              (item: any) =>
                                item.value === this.state.calculator
                            )
                          : null
                      }
                      id={"calculator"}
                      disabled={false}
                      onChange={(e, value) =>
                        this.handleAutocompleteChange("calculator", value)
                      }
                      renderInput={(props) => (
                        <CssTextField
                          {...props}
                          label={"Elegir calculadora"}
                          variant="outlined"
                        />
                      )}
                    />
                  </section>
                  {this.state.calculator !== "" ? (
                    <section className="header-section2">
                      {this.state.calculator === "planned savings" ? (
                        <Calculator />
                      ) : this.state.calculator === "coopsan" ? (
                        <CoopSanCalculator />
                      ) : null}
                    </section>
                  ) : null}
                </div>
              </Dialog>
              <div style={{ display: "flex" }}>
                <CssTextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <CssTextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate || ""}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.PARTNER} Principal
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.mainDebtor}
                  addParticipant={this.handleAddMainClient}
                  deleteParticipant={this.handleDeleteMainClient}
                />
                <p className={classes.errorInput}>
                  {this.state.mainDebtorError}
                </p>
              </div>
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.PARTNERS}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.secondaryDebtors}
                  addParticipant={this.handleAddSecondaryClient}
                  deleteParticipant={this.handleDeleteSecondaryClient}
                />
                <p className={classes.errorInput}>
                  {this.state.secondaryDebtorsError}
                </p>
              </div>
              <br />
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={2}>
                  <CssTextField
                    variant="outlined"
                    label={translations.ACCOUNT_NUMBER}
                    value={this.state.accountNumber || ""}
                    onChange={this.handleChange("accountNumber")}
                    disabled={true}
                    type="number"
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>

                <Grid item={true} xs={2}>
                  <FormControl
                    variant="outlined"
                    className={classes.gridFormControl}
                  >
                    <InputLabel
                      id="collectionType"
                      error={this.state.collectionTypeError ? true : false}
                    >
                      {translations.DESTINATION}
                    </InputLabel>
                    <Select
                      id="collectionType"
                      label={translations.DESTINATION}
                      onChange={this.handleSelectChange("collectionType")}
                      style={{ width: "100%" }}
                      required={true}
                      value={this.state.collectionType}
                    >
                      {collectionTypes.map((opt, indx) => (
                        <MenuItem key={indx} value={opt.value}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {this.state.collectionTypeError}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={2}>
                  <FormControl
                    variant="outlined"
                    className={classes.gridFormControl}
                  >
                    <InputLabel
                      id="signatureType"
                      error={this.state.signatureTypeError ? true : false}
                    >
                      Tipo de firma
                    </InputLabel>
                    <Select
                      id="signatureType"
                      label={"Tipo de firma"}
                      onChange={this.handleSelectChange("signatureType")}
                      style={{ width: "100%" }}
                      required={true}
                      value={this.state.signatureType}
                    >
                      {signatureTypes.map((opt, indx) => (
                        <MenuItem key={indx} value={opt.value}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {this.state.signatureTypeError}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={2}>
                  <FormControl
                    variant="outlined"
                    className={classes.gridFormControl}
                  >
                    <InputLabel
                      id="type"
                      error={this.state.typeError ? true : false}
                    >
                      {translations.TYPE}
                    </InputLabel>
                    <Select
                      id="type"
                      label={translations.TYPE}
                      onChange={this.handleSelectChange("type")}
                      style={{ width: "100%" }}
                      value={this.props.fromTicketType || this.state.type}
                      required={true}
                    >
                      {savingsAccountTypes
                        .filter((item) =>
                          this.props.fromTicketType
                            ? item.value === this.props.fromTicketType
                            : item
                        )
                        .map((opt, indx) => (
                          <MenuItem key={indx} value={opt.value}>
                            {opt.label}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{this.state.typeError}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              {this.state.type === "planned" ? (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={2}>
                    <CssTextField
                      variant="outlined"
                      style={{ width: "100%" }}
                      label={translations.TERM}
                      value={this.state.term || ""}
                      type="number"
                      onChange={this.handleChange("term")}
                      required={true}
                      error={this.state.termError !== ""}
                      helperText={this.state.termError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                      inputProps={{
                        max: 100,
                        min: 0,
                        step: 1,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={2}>
                    <FormControl
                      variant="outlined"
                      className={classes.gridFormControl}
                    >
                      <InputLabel shrink={true}>
                        {translations.PERIODICITY}
                      </InputLabel>
                      <Select
                        label={translations.PERIODICITY}
                        id="periodicity"
                        onChange={this.handleSelectChange("periodicity")}
                        value={this.state.periodicity}
                        style={{ width: "100%" }}
                      >
                        <MenuItem value={"monthly"}>
                          {translations.MONTHLY}
                        </MenuItem>
                        <MenuItem value={"biweekly"}>
                          {translations.BIWEEKLY}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={2}>
                    <CssTextField
                      variant="outlined"
                      label={"Cuota"}
                      value={this.state.quota || ""}
                      onChange={this.handleCurrencyInputChange("quota")}
                      style={{ width: "100%" }}
                      required={true}
                      error={this.state.quotaError !== ""}
                      helperText={this.state.quotaError}
                      InputProps={{
                        inputComponent: moneyInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={2}>
                    <CssTextField
                      variant="outlined"
                      label={"Meta"}
                      value={this.state.goal || ""}
                      onChange={this.handleCurrencyInputChange("goal")}
                      style={{ width: "100%" }}
                      required={true}
                      error={this.state.goalError !== ""}
                      helperText={this.state.goalError}
                      InputProps={{
                        inputComponent: moneyInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                </Grid>
              ) : this.state.type === "coopsan" ? (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={2}>
                    <FormControl
                      variant="outlined"
                      className={classes.gridFormControl}
                      error={this.state.termError ? true : false}
                    >
                      <InputLabel id="term">{translations.TERM}</InputLabel>
                      <Select
                        label={translations.TERM}
                        id="term"
                        required={true}
                        disabled={false}
                        style={{ width: "100%" }}
                        onChange={this.handleSelectChange("term")}
                        value={this.state.term}
                      >
                        {this.state.periods.map((opt: any, indx: any) => {
                          return (
                            <MenuItem value={opt.consecutiveQuotas} key={indx}>
                              {opt.consecutiveQuotas}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>{this.state.termError}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={2}>
                    <FormControl
                      variant="outlined"
                      className={classes.gridFormControl}
                    >
                      <InputLabel shrink={true}>
                        {translations.PERIODICITY}
                      </InputLabel>
                      <Select
                        label={translations.PERIODICITY}
                        id="periodicity"
                        onChange={this.handleSelectChange("periodicity")}
                        value={this.state.periodicity}
                        style={{ width: "100%" }}
                      >
                        <MenuItem value={"monthly"}>
                          {translations.MONTHLY}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={2}>
                    <CssTextField
                      variant="outlined"
                      label={"Cuota"}
                      value={this.state.quota || ""}
                      onChange={this.handleCurrencyInputChange("quota")}
                      style={{ width: "100%" }}
                      required={true}
                      error={this.state.quotaError !== ""}
                      helperText={this.state.quotaError}
                      InputProps={{
                        inputComponent: moneyInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={2}>
                    <CssTextField
                      variant="outlined"
                      label={"Meta"}
                      value={this.state.goal || ""}
                      onChange={this.handleCurrencyInputChange("goal")}
                      style={{ width: "100%" }}
                      required={true}
                      error={this.state.goalError !== ""}
                      helperText={this.state.goalError}
                      InputProps={{
                        inputComponent: moneyInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                </Grid>
              ) : this.state.type === "youth" ? (
                <div>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    Información del niño
                  </Typography>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={2}>
                      <CssTextField
                        variant="outlined"
                        label={translations.FULL_NAME}
                        value={this.state.fullName || ""}
                        onChange={this.handleChange("fullName")}
                        style={{ width: "100%" }}
                        required={true}
                        error={this.state.fullNameError !== ""}
                        helperText={this.state.fullNameError}
                        InputLabelProps={{
                          style: styles.checkboxLabel,
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={2}>
                      <CssTextField
                        variant="outlined"
                        label={translations.EMAIL}
                        value={this.state.email || ""}
                        onChange={this.handleChange("email")}
                        style={{ width: "100%" }}
                        type="email"
                        error={this.state.emailError !== ""}
                        helperText={this.state.emailError}
                        InputLabelProps={{
                          style: styles.checkboxLabel,
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={2}>
                      <CssTextField
                        variant="outlined"
                        label={translations.CELL_PHONE}
                        value={this.state.cellPhone || ""}
                        type="phone"
                        placeholder="+1(809) 000-0000"
                        onChange={this.handleChange("cellPhone")}
                        error={this.state.cellPhoneError !== ""}
                        style={{ width: "100%" }}
                        helperText={this.state.cellPhoneError}
                        InputProps={{
                          inputComponent: phoneMaskInput,
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={2}>
                      <CssTextField
                        variant="outlined"
                        label={translations.AGE}
                        value={this.state.age || ""}
                        onChange={this.handleChange("age")}
                        style={{ width: "100%" }}
                        required={true}
                        error={this.state.ageError !== ""}
                        helperText={this.state.ageError}
                        InputLabelProps={{
                          style: styles.checkboxLabel,
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={2}>
                      <CssTextField
                        variant="outlined"
                        label={"Número de libreta"}
                        value={this.state.passbookNumber || ""}
                        onChange={this.handleChange("passbookNumber")}
                        disabled={true}
                        style={{ width: "100%" }}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                          style: styles.checkboxLabel,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              ) : this.state.type === "functional" ? (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={2}>
                    <CssTextField
                      variant="outlined"
                      label={"Cuota"}
                      value={this.state.quota || ""}
                      onChange={this.handleCurrencyInputChange("quota")}
                      style={{ width: "100%" }}
                      required={true}
                      error={this.state.quotaError !== ""}
                      helperText={this.state.quotaError}
                      InputProps={{
                        inputComponent: moneyInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={2}>
                    <CssTextField
                      variant="outlined"
                      label={"Número de libreta"}
                      value={this.state.passbookNumber || ""}
                      onChange={this.handleChange("passbookNumber")}
                      disabled={true}
                      style={{ width: "100%" }}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createSavingsAccountContract })(
    SavingsAccountContractForm
  )
);
