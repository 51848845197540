import { Button, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { dateFormat } from 'src/components/SharedComponents/CustomHooks';
import { DateField } from 'src/components/SharedComponents/Fields'

function Filters(props) {
    const { classes, endpoint, title } = props;
    const [date, setDate] = useState({start: null, end: new Date()});
    const handleDateChange = (type, dateInfo) => {
        setDate({...date, [type]: dateInfo});
    }
    const ApplyFilters = (format) => {
        const fields = {
            date_start: dateFormat(date.start),
            date_end: dateFormat(date.end),
            type: format,
            endpoint: endpoint,
            title: title
        }

        props.applyFilters(fields);
    }
  return (
    <Grid className="filters-container">
        <Typography variant='h6'>{title}</Typography>
        <div style={{margin:'15px 0'}}></div>
      <Grid item container spacing={2} direction="row">
      <DateField
          xs={6}
          typeVariant="subtitle1"
          typeClass="field-label"
          dateFieldID="filter-date-start"
          fullWidth={true}
          dateFieldClass="date-field"
          inputVariant="outlined"
          dateFieldLabel="Fecha inicio"
          format="yyyy/MM/dd"
          value={date.start}
          handleChange={handleDateChange.bind(this, "start")}
        />
        <DateField
          xs={6}
          typeVariant="subtitle1"
          typeClass="field-label"
          dateFieldID="filter-date-end"
          fullWidth={true}
          dateFieldClass="date-field"
          inputVariant="outlined"
          dateFieldLabel="Fecha fin"
          format="yyyy/MM/dd"
          value={date.end}
          handleChange={handleDateChange.bind(this, "end")}
        />
      </Grid>
      <Grid
        container
        direction="row"
        className={classes}
      >
        <Button
          disableElevation
          variant="contained" 
          onClick={() => ApplyFilters('PDF')}
        >
          Generar PDF
        </Button>
        <Button
          disableElevation
          variant="contained"
          className={"action-method-btn"}
          onClick={() => ApplyFilters('EXCEL')}
        >
          Descargar Excel
        </Button>
      </Grid>
    </Grid>
  )
}

export default Filters