
import { ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES } from '../actions';

export default function(state: IAcquaintanceshipPersonContract[] = [], action: IAction<IAcquaintanceshipPersonContract[]>): IAcquaintanceshipPersonContract[] {
    switch(action.type) {
        case ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES.FETCH_ALL:
            return action.payload || [];
        case ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES.FETCH:
            return state.map(APC => (action.payload !== undefined && APC.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: APC);
        case ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES.UPDATE:
            return state.map(APC => (action.payload !== undefined && APC.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : APC);
        case ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES.DELETE:
            return state.filter(APC => action.payload !== undefined && APC.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}