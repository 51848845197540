
import { OCCUPATION_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case OCCUPATION_TYPES.FETCH_ALL:
            return action.payload || [];
        case OCCUPATION_TYPES.FETCH:
            return state.map(occupation => (action.payload !== undefined && occupation.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: occupation);
        case OCCUPATION_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case OCCUPATION_TYPES.UPDATE:
            return state.map(occupation => (action.payload !== undefined && occupation.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : occupation);
        case OCCUPATION_TYPES.DELETE:
            return state.filter(occupation => action.payload !== undefined && occupation.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}
