import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createAccountingRecord } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import MoneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import {
  catchError,
  resetError,
  successHandler,
  translations,
} from "../../../utils";
import { createAccountingRecord as create } from "../../../utils/axios";
import AccountingTable from "../AccountingRecordsTable/AccountingTable";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    marginTop: 20,
    width: "70%",
  },
  textField_2: {
    display: "flex",
    marginTop: 20,
    width: "30%",
  },
});

interface IAccountingRecordsFormProps {
  classes: {
    [key: string]: string;
  };

  user: IUser;
  createAccountingRecord(
    accountingRecord: IAccountingRecord
  ): IAction<IAccountingRecord[]>;
  changeSection(sectionNumber: number): void;
}
interface IAccountingRecordsFormState {
  [key: string]: string | number | boolean | any;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;

  clients: [];
  editIdx: number;

  accountingId: string;
  creditTotal: number;
  debitTotal: number;
  difference: number;
  financialId: string;
  notes: string;
  outlayId: string;
  recordData: any;
  recordStatus: string;
  referenceCode: string;
  ticketId: string;
  transactionDate: string;
}
class AccountingRecordForm extends React.Component<
  IAccountingRecordsFormProps,
  IAccountingRecordsFormState
> {
  public state: IAccountingRecordsFormState = {
    accountingId: "",
    clients: [],
    creditTotal: 0,
    debitTotal: 0,
    dialog: false,
    dialogTitle: "",
    difference: 0,
    editIdx: -1,
    errorMessage: "",
    financialId: "",
    loading: false,
    notes: "",
    outlayId: "",
    recordData: [],
    recordStatus: "",
    referenceCode: "",
    ticketId: "",
    transactionDate: "",
  };

  constructor(props: IAccountingRecordsFormProps) {
    super(props);

    // this.calculateTotals = this.calculateTotals.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTableData = this.handleChangeTableData.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleStartEdit = this.handleStartEdit.bind(this);
    this.handleStopEdit = this.handleStopEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }

  public calculateTotals() {
    const debits = this.state.recordData.map((x: any) => x.debit);
    const credits = this.state.recordData.map((x: any) => x.credit);
    let newDebits = 0;
    let newCredits = 0;
    let diff = 0;

    for (const item of debits) {
      newDebits = newDebits + item;
    }
    for (const item of credits) {
      newCredits = newCredits + item;
    }
    diff = newDebits - newCredits;

    this.setState({
      creditTotal: newCredits,
      debitTotal: newDebits,
      difference: diff,
    });
  }

  public handleRemove = (i: any) => {
    this.setState({
      recordData: this.state.recordData.filter((row: any, j: any) => j !== i),
    });
    this.calculateTotals();
  };

  public handleStartEdit = (i: any) => {
    this.setState({ editIdx: i });
    this.calculateTotals();
  };

  public handleStopEdit = () => {
    this.setState({ editIdx: -1 });
    this.calculateTotals();
  };

  public handleChangeTableData = (i: any, x: any) => {
    this.setState({
      recordData: this.state.recordData.map((row: any, j: any) =>
        j === i ? x : row
      ),
    });
    this.handleStopEdit();
    this.calculateTotals();
  };

  public handleSave(data: any) {
    this.setState({
      recordData: data,
    });
    this.calculateTotals();
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    const accountingRecord: IAccountingRecord = {
      accountingId: state.accountingId,
      creditTotal: state.creditTotal,
      debitTotal: state.debitTotal,
      difference: state.difference,
      financialId: state.financialId,
      notes: state.notes,
      outlayId: state.outlayId,
      recordData: state.recordData,
      recordStatus: state.recordStatus,
      referenceCode: state.referenceCode,
      ticketId: state.ticketId,
      transactionDate: state.transactionDate,
    };

    this.setState({ loading: true });

    create(this.props.user.token, accountingRecord)
      .then((res) => {
        this.props.createAccountingRecord(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, "Asientos contable");
        this.handleReset();
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }

  public handleReset() {
    this.setState({
      accountingId: "",
      creditTotal: 0,
      debitTotal: 0,
      dialog: false,
      dialogTitle: "",
      difference: 0,
      errorMessage: "",
      financialId: "",
      loading: false,
      notes: "",
      outlayId: "",
      recordData: [],
      recordStatus: "",
      referenceCode: "",
      ticketId: "",
      transactionDate: "",
      uniqueId: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }

  public render() {
    const { classes } = this.props;
    const headersAR = [
      { name: "Cuenta", prop: "account", type: "text" },
      {
        name: "Tercero",
        prop: "thirdMember",
        type: "text",
      },
      { name: "Debito", prop: "debit", type: "number" },
      { name: "Credito", prop: "credit", type: "number" },
    ];

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.ACCOUNTING_RECORDS}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  label="Id Financiero"
                  variant="outlined"
                  type="number"
                  className={classes.textField_2}
                  value={this.state.financialId}
                  onChange={this.handleChange("financialId")}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label="Fecha de Transaccion"
                  variant="outlined"
                  type="date"
                  className={classes.textField_2}
                  value={this.state.transactionDate}
                  onChange={this.handleChange("transactionDate")}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  label="Id de Desembolso"
                  variant="outlined"
                  type="number"
                  className={classes.textField_2}
                  value={this.state.outlayId}
                  onChange={this.handleChange("outlayId")}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label="Numero de ticket"
                  variant="outlined"
                  type="number"
                  className={classes.textField_2}
                  value={this.state.ticketId}
                  onChange={this.handleChange("ticketId")}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label="Id Contable"
                  variant="outlined"
                  type="number"
                  className={classes.textField_2}
                  value={this.state.accountingId}
                  onChange={this.handleChange("accountingId")}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
              </div>
              <br />
              <AccountingTable
                actions={true}
                create={true}
                tableLimit={20}
                data={this.state.recordData}
                editIdx={this.state.editIdx}
                handleChange={this.handleChangeTableData}
                handleRemove={this.handleRemove}
                handleSave={this.handleSave}
                handleStartEdit={this.handleStartEdit}
                handleStopEdit={this.handleStopEdit}
                header={headersAR}
              />
              <div style={{ display: "flex" }}>
                <div
                  style={{ display: "flex", flexFlow: "column", width: "50%" }}
                >
                  <TextField
                    label="Numero de referencia"
                    variant="outlined"
                    type="number"
                    className={classes.textField}
                    value={this.state.referenceCode}
                    onChange={this.handleChange("referenceCode")}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <TextField
                    label="Notas"
                    variant="outlined"
                    className={classes.textField}
                    multiline={true}
                    rows={5}
                    rowsMax={5}
                    value={this.state.notes}
                    onChange={this.handleChange("notes")}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </div>
                <div
                  style={{ display: "flex", flexFlow: "column", width: "50%" }}
                >
                  <TextField
                    disabled={true}
                    label="Total de Debito"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.debitTotal}
                    onChange={this.handleChange("debitTotal")}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    InputProps={{ inputComponent: MoneyInput }}
                  />
                  <TextField
                    disabled={true}
                    label="Total de credito"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.creditTotal}
                    onChange={this.handleChange("creditTotal")}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    InputProps={{ inputComponent: MoneyInput }}
                  />
                  <TextField
                    disabled={true}
                    label="Diferencia Debito y Credito"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.difference}
                    onChange={this.handleChange("difference")}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    InputProps={{ inputComponent: MoneyInput }}
                  />
                </div>
              </div>
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={this.state.difference !== 0 ? true : false}
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createAccountingRecord })(AccountingRecordForm)
);
