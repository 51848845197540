import * as React from "react";
import { connect } from "react-redux";

import { Button, FormControl } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createProfession } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  translations,
} from "../../../utils";
import { createProfession as create } from "../../../utils/axios";

const styles = createStyles({
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  button: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    width: "90%",
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

class ProfessionsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      professionName: "",
      professionNameError: "",

      dialog: false,
      dialogTitle: "",
      errorMessage: "",
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.openDialog = this.openDialog.bind(this);
  }
  openDialog() {
    this.setState({ open: true });
  }
  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  handleSubmit(event) {
    event.preventDefault();
    resetError(this);

    const profession = {
      name: this.state.professionName,
    };

    this.setState({ loading: true });

    create(this.props.user.token, profession)
      .then((res) => {
        this.props.createProfession(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, translations.PROFESSION);
        this.handleReset();
      })
      .catch((err) => {
        console.debug(err);
        catchError(err, this);
      });
  }
  handleReset() {
    this.setState({
      professionName: "",
    });
  }
  handleDialogClose() {
    this.setState({ dialog: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  label={translations.PROFESSION}
                  className={classes.textField}
                  value={this.state.professionName}
                  onChange={this.handleChange("professionName")}
                  fullWidth={true}
                  required={true}
                  error={this.state.professionNameError !== ""}
                  helperText={this.state.professionNameError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <p style={{ color: "red" }}>{this.state.professionNameError}</p>
              </FormControl>

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
        <br />
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createProfession })(ProfessionsForm)
);
