export const settlementInit = {
  bankTransfers: [],
  checks: [],
  creditMethods: [],
  creditorAccounts: [],
  expiration: {},
  financialCertificates: [],
  newClientFullName: "",
  participantInfo: "",
  outlay: {},
  requestAmount: "",
  savingsAccounts: [],
};

export const disbursementSteps = [
  {
    label: "Evaluación y formas de liquidación",
    value: "eval_forms_settlement",
  },
  {
    label: "Elaboración y validación de contratos",
    value: "contract_validation",
  },
  { label: "Análisis de crédito", value: "credit_analysis" },
  { label: "Formalización de crédito", value: "credit_formalization" },
];

export const transaction_types = {
  regular_loan: "PRESTAMO REGULAR",
  simple_loan: "ORDINARIO SIMPLE",
  constant_fees: "ORDINARIO ESCALONADO",
  decreasing_fees: "ORDINARIO ESCALONADO",
  vacational_bonus: "BONO VACACIONAL (BCRD)",
  vacational_bonus: "BONO VACACIONAL (MH)",
  antiquity_incentive: "INCENTIVO POR ANTIGÜEDAD (BCRD)",
  antiquity_bonus: "PREMIO POR ANTIGÜEDAD (BCRD)",
  october_bonus_1: "DE ANIVERSARIO I (BCRD)",
  october_bonus_2: "DE ANIVERSARIO II (BCRD)",
  easter_royalty: "NAVICOOP BCRD",
  personal_loan: "PRESTAMO PERSONAL",
  school_supplies: "BONOCOOP (BCRD)",
  irregular_bonus: "PRESTAMOS BONO IRREGULAR",
  anniversary_bonus: "ANIVERSARIO (MH)",
  annual_bonus: "BONIFICACION ANUAL",
  salary_advance: "ORDINARIO EXPRESO",
};

export const prevStepsDisbursement = [
  {
    label: "Evaluación y formas de liquidación",
    value: "eval_forms_settlement",
  },
  {
    label: "Elaboración y validación de contratos",
    value: "contract_validation",
  },
];

export const ticketStates = [
  {
    bgColor: "#1ea0f7",
    color: "#FFF",
    state: "new_request",
    name: "Nuevo",
    fullName: "Nuevo",
  },
  {
    bgColor: "#5273f7",
    color: "#FFF",
    state: "liquidated",
    name: "Liquidado",
    fullName: "Liquidado",
  },
  {
    bgColor: "#ffeb3b",
    color: "#424242",
    state: "pending_settlement",
    name: "Pend. Liquidación",
    fullName: "Pendiente liquidación",
  },
  {
    bgColor: "#825FE3",
    color: "#FFF",
    state: "payment_reconciled",
    name: "Pago Conciliado",
    fullName: "Pago conciliado",
  },
  {
    bgColor: "#f44336",
    color: "#FFF",
    state: "delayed",
    name: "Atrasado",
    fullName: "Atrasado",
  },
  {
    bgColor: "#43A047",
    color: "#FFF",
    state: "processed",
    name: "Procesado",
    fullName: "Procesado",
  },
  {
    bgColor: "#ef6c00",
    color: "#FFF",
    state: "cancelled",
    name: "Cancelado",
    fullName: "Cancelado",
  },
  {
    bgColor: "#AFDE6D",
    color: "#FFF",
    state: "document_pending",
    name: "Pend. Documentación",
    fullName: "Pendiente Documentación",
  },
  {
    bgColor: "#66D9B1",
    color: "#FFF",
    state: "exception_validation_pending",
    name: "P. Valid. Excepción",
    fullName: "Pendiente Validación Excepción",
  },
  {
    bgColor: "#F7AE54",
    color: "#FFF",
    state: "approval_pending",
    name: "Pend. Aprobación",
    fullName: "Pendiente Aprobación",
  },
  {
    bgColor: "#E3974B",
    color: "#FFF",
    state: "rrhh_pending",
    name: "P. Aprob. RRHH",
    fullName: "Pendiente de Aprobacion RRHH",
  },
  {
    bgColor: "#8461CF",
    color: "#FFF",
    state: "sign_pending",
    name: "Pend. Firma",
    fullName: "Pendiente de Firma",
  },
  {
    bgColor: "#5B83BA",
    color: "#FFF",
    state: "registration_pending",
    name: "Pend. Registro",
    fullName: "Pendiente de Registro",
  },
  {
    bgColor: "#5BA9BA",
    color: "#FFF",
    state: "review_pending",
    name: "Pend. Revisión",
    fullName: "Pendiente de Revisión",
  },
  {
    bgColor: "#5e94b8",
    color: "#424242",
    state: "document_collection_pending",
    name: "Pend. Recolección",
    fullName: "Pendiente de Recolección",
  },
  {
    bgColor: "#2c6f9c",
    color: "#FFF",
    state: "documents_collected",
    name: "Docs. Recolectados",
    fullName: "Documentos Recolectados",
  },
  {
    bgColor: "#6dcfba",
    color: "#424242",
    state: "sign_review_pending",
    name: "P. Revisión Firma",
    fullName: "Pendiente Revisión Firma",
  },
  {
    bgColor: "#d6e075",
    color: "#424242",
    state: "credit_confirm_pending",
    name: "P. Confirm. Créditos",
    fullName: "Pendiente Confirmación Créditos",
  },
  {
    bgColor: "#ef6c00",
    color: "#FFF",
    state: "rejected",
    name: "Rechazado",
    fullName: "Rechazado",
  },
  {
    bgColor: "#ef6c00",
    color: "#FFF",
    state: "rejected_rrhh",
    name: "Rech. RRHH",
    fullName: "Rechazado por RRHH",
  },
];

export const disbursementRequest = [
  {
    approvalObj: {
      status: "",
      quotation: "",
      actNumber: "",
      actDate: "",
      approvalInstance: "",
      outlayRelation: "",
      relationOutlayId: "",
    },
  },
  {
    fileRequirements: [
      {
        text: "creditHistory",
        value: [],
        required: false,
        step: "credit_analysis",
      },
      {
        text: "ecoWorkSectorEval",
        value: [],
        required: false,
        step: "credit_analysis",
      },
      {
        text: "thirdPartyPayHistory",
        value: [],
        required: false,
        step: "credit_analysis",
      },
      {
        text: "individualCapacityManagement",
        value: [],
        required: false,
        step: "credit_analysis",
      },
    ],
  },
  // {
  //     step: 'request_reception',
  //     requirements: [
  //         {
  //             type: 'text',
  //             name: 'debtor',
  //             text: 'Cliente',
  //             value: '',
  //             required: true,
  //         },
  //         {
  //             type: 'text',
  //             name: 'creditor',
  //             text: 'Acreedor',
  //             value: '',
  //             required: true,
  //         },
  //         {
  //             type: 'text',
  //             name: 'amount',
  //             text: 'Monto',
  //             value: '',
  //             required: true,
  //         },
  //     ],
  //     status: '',
  //     order: 0,
  // },
  {
    step: "eval_forms_settlement",
    requirements: [
      {
        type: "form",
        name: "quotation",
        text: "Cotización",
        value: "",
        required: false,
      },
      {
        type: "object",
        name: "creditMethodTables",
        text: "Tabla métodos de crédito",
        value: null,
        required: false,
      },
      {
        type: "form",
        name: "warranty",
        text: "Garantía",
        value: "",
        required: false,
      },
    ],
    status: "",
    order: 0,
  },
  {
    step: "contract_validation",
    requirements: [
      {
        type: "form",
        name: "savingAccount",
        text: "Cuenta de ahorro funcional",
        value: "",
        required: false,
      },
      {
        type: "bool",
        name: "docsValidated",
        text: "Documentos validados",
        value: false,
        required: true,
      },
      {
        type: "bool",
        name: "signPending",
        text: "Documentación pendiente de firma",
        value: false,
        required: false,
      },
      {
        type: "questionary",
        name: "clientNew",
        text: "¿El cliente es nuevo?",
        value: {
          answer: "",
          forms: [
            { name: "loanContract", value: "" },
            { name: "promissoryNote", value: "" },
            { name: "autoDebitAuth", value: "" },
            { name: "autoElectronicWithdraw", value: "" },
            { name: "paymentFlyer", value: "" },
            { name: "check", value: "" },
          ],
        },
        required: true,
      },
      {
        type: "questionary",
        name: "clientUpdate",
        text:
          "¿El cliente tuvo actualización de datos de contacto Dirección/Celular/Correo electrónico?",
        value: {
          answer: "",
          forms: [
            { name: "promissoryNote", value: "" },
            { name: "addendum", value: "" },
          ],
        },
        required: true,
      },
      {
        type: "questionary",
        name: "clientUpdate",
        text:
          "¿Se actualizara la tasa de interes para los prestamos del socio?",
        value: {
          answer: "",
          forms: [
            { name: "promissoryNote", value: "" },
            { name: "addendum", value: "" },
          ],
        },
        required: true,
      },
      {
        type: "questionary",
        name: "requestedAmount",
        text:
          "¿El monto solicitado excede el monto de endeudamiento disponible?",
        value: {
          answer: "",
          forms: [
            { name: "promissoryNote", value: "" },
            { name: "addendum", value: "" },
          ],
        },
        required: true,
      },
      {
        type: "questionary",
        name: "productNewConfig",
        text:
          "¿Nuevo producto con nueva configuración financiera/cambio de tasa/solicitud con garantía?",
        value: { answer: "", forms: [{ name: "promissoryNote", value: "" }] },
        required: true,
      },
      {
        type: "questionary",
        name: "workInstitutionChange",
        text: "¿El cliente cambió de institución laboral?",
        value: {
          answer: "",
          forms: [
            { name: "autoDebitAuth", value: "" },
            { name: "payrollDeduction", value: "" },
          ],
        },
        required: true,
      },
      {
        type: "questionary",
        name: "financialInstitutionChange",
        text:
          "¿El cliente cambió de institución financiera o cambió su método de cobro?",
        value: {
          answer: "",
          forms: [
            { name: "autoElectronicWithdraw", value: "" },
            { name: "paymentFlyer", value: "" },
            { name: "check", value: "" },
          ],
        },
        required: true,
      },
    ],
    status: "",
    order: 1,
  },
  {
    step: "credit_analysis",
    requirements: [
      {
        type: "creditVerify",
        name: "creditHistory",
        text: "Historial de crédito",
        value: { state: false, comment: "", score: "" },
        required: true,
      },
      {
        type: "creditVerify",
        name: "ecoWorkSectorEval",
        text: "Evaluación de sector económico laboral",
        value: { state: false, comment: "", score: "" },
        required: true,
      },
      {
        type: "creditVerify",
        name: "thirdPartyPayHistory",
        text: "Historial de pago con terceros",
        value: { state: false, comment: "", score: "" },
        required: true,
      },
      {
        type: "creditVerify",
        name: "individualCapacityManagement",
        text: "Capacidad de gestión del individuo",
        value: { state: false, comment: "", score: "" },
        required: true,
      },
      {
        type: "text",
        name: "recomendation",
        text: "Recomendación",
        value: "",
        required: true,
      },
      {
        type: "form",
        name: "quotation",
        text: "Cotización",
        value: "",
        required: false,
      },
    ],
    status: "",
    order: 2,
  },

  {
    step: "credit_formalization",
    requirements: [
      {
        type: "object",
        name: "creditMethodTables",
        text: "Tabla métodos de crédito",
        value: null,
        required: false,
      },
      {
        type: "object",
        name: "checkValidation",
        text: "Validación de checkboxs",
        value: {},
        required: false,
      },
    ],
    status: "",
    order: 3,
  },
];
