import * as React from "react";
import { connect } from "react-redux";
import { translations } from "src/utils";

import { faPercent, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Green from "@material-ui/core/colors/green";
import { createStyles, withStyles } from "@material-ui/core/styles";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AssignmentIcon from '@material-ui/icons/Assignment'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import ViewListIcon from '@material-ui/icons/ViewList';
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BusinessIcon from "@material-ui/icons/Business";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DescriptionIcon from "@material-ui/icons/Description";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FlagIcon from "@material-ui/icons/Flag";
import FolderIcon from "@material-ui/icons/Folder";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SettingIcon from "@material-ui/icons/Settings";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import SwapIcon from "@material-ui/icons/SwapHoriz";
import CatalogIcon from "@material-ui/icons/ImportContacts";
import AssessmentIcon from "@material-ui/icons/Assessment";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import MoneyIcon from "@material-ui/icons/Money";
import { InsertInvitation, LocalAtm, MonetizationOn, Payment, VerifiedUser } from "@material-ui/icons";
import TimerOffIcon from '@material-ui/icons/TimerOff';
import { changeSection } from "../../actions";

const styles: any = createStyles({
  bottomList: {
    backgroundColor: "white",
    bottom: 0,
    maxWidth: 360,
    position: "absolute",
    width: "100%",
  },
  menuIcon: {
    color: Green[500],
  },
  nestedListItem: {
    paddingLeft: 25,
  },
  root: {
    overflowY: "scroll",
    overflowX: "hidden",
    paddingBottom: "10vh",
  },
  topList: {
    backgroundColor: "white",
    maxWidth: 360,
    paddingTop: 0,
  },
});

interface IMenuListProps {
  classes: {
    bottomList: string;
    menuIcon: string;
    nestedListItem: string;
    root: string;
    topList: string;
  };
  selectedSection: number;
  changeSection(index: number): IAction<number>;
}

class MenuList extends React.Component<IMenuListProps> {
  constructor(props: IMenuListProps) {
    super(props);
    this.handleListItemClick = this.handleListItemClick.bind(this);
  }
  public handleListItemClick(event: React.MouseEvent<HTMLElement>) {
    const id: number = parseInt(event.currentTarget.id, 10);
    this.props.changeSection(id);
  }
  public render() {
    const { classes, selectedSection } = this.props;

    const collapse = {
      documents: {
        all: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 69, 72],
        knowledgeForm: [3, 4, 5, 6, 7],
        savings: [15, 16, 17, 18, 19],
        compliance: [56, 57, 58, 59],
        approvement: [62, 63, 64, 65],
      },

      participants: {
        all: [20, 21, 22, 23],
      },

      leads: [70, 71],

      institutions: [24, 25, 26, 27],

      claims: [67, 68],

      reports: [74, 75, 76, 77, 78],

      bankAccounts: [28, 29, 30],

      transactions: {
        all: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 66],
        contability: [32, 33, 34, 35],
        banks: [36, 37, 38],
        loans: [39, 40, 41, 42, 66],
      },

      settings: {
        all: [43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 73],
        blacklist: [48, 49, 50, 51, 52],
      },
    };


    return (
      <div className={classes.root}>
        <List component="nav" className={classes.topList}>
          <ListItem
            id="0"
            button={true}
            selected={selectedSection === 0}
            onClick={this.handleListItemClick}
          >
            <ListItemIcon>
              <FolderIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary={translations.DOCUMENTS} />
          </ListItem>
          <Collapse
            in={collapse.documents.all.includes(selectedSection)}
            timeout="auto"
            unmountOnExit={true}
          >
            <List component="nav" disablePadding={true}>
              <Divider />
              <ListItem
                id="1"
                button={true}
                selected={selectedSection === 1}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={translations.LOAN_CONTRACTS} />
              </ListItem>
              <Divider />
              <ListItem
                id="2"
                button={true}
                selected={selectedSection === 2}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={translations.NOTARIAL_PAY} />
              </ListItem>
              <Divider />
              <ListItem
                id="3"
                button={true}
                selected={selectedSection === 3}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={translations.KNOWLEDGE_FORM} />
                {selectedSection === 3 ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={collapse.documents.knowledgeForm.includes(selectedSection)}
                timeout="auto"
                unmountOnExit={true}
              >
                <List component="nav" disablePadding={true}>
                  <ListItem
                    id="4"
                    button={true}
                    selected={selectedSection === 4}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.PERSON} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="5"
                    button={true}
                    selected={selectedSection === 5}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.COMPANY} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="6"
                    button={true}
                    selected={selectedSection === 6}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.PROVIDER} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="7"
                    button={true}
                    selected={selectedSection === 7}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.MA} />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />
              <ListItem
                id="8"
                button={true}
                selected={selectedSection === 8}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={translations.ADDENDUM} />
              </ListItem>
              <Divider />
              <ListItem
                id="9"
                button={true}
                selected={selectedSection === 9}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText
                  primary={translations.AUTOMATIC_DEBIT_AUTHORIZATION}
                />
              </ListItem>
              <Divider />
              <ListItem
                id="10"
                button={true}
                selected={selectedSection === 10}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText
                  primary={translations.PAYROLL_DEDUCTION_CONTRACT}
                />
              </ListItem>
              <Divider />
              <ListItem
                id="60"
                button={true}
                selected={selectedSection === 60}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={"Contrato de prenda sin desapoderamiento"} />
              </ListItem>
              <Divider />
              <ListItem
                id="11"
                button={true}
                selected={selectedSection === 11}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={"Calculadoras"} />
              </ListItem>
              <Divider />
              <ListItem
                id="12"
                button={true}
                selected={selectedSection === 12}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={translations.QUOTATION} />
              </ListItem>
              <Divider />
              <ListItem
                id="13"
                button={true}
                selected={selectedSection === 13}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText
                  primary={translations.CREDIT_REVIEW_AUTHORIZATION}
                />
              </ListItem>
              <Divider />
              <ListItem
                id="14"
                button={true}
                selected={selectedSection === 14}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={"Retiro de la cooperativa"} />
              </ListItem>
              <Divider />
                  <ListItem
                    id="61"
                    button={true}
                    selected={selectedSection === 61}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Autorización de retiro via electrónica"} />
                  </ListItem>
              <Divider />
              <ListItem
                id="15"
                button={true}
                selected={selectedSection === 15}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={translations.SAVINGS_ACCOUNT} />
                {selectedSection === 15 ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={collapse.documents.savings.includes(selectedSection)}
                timeout="auto"
                unmountOnExit={true}
              >
                <List component="nav" disablePadding={true}>
                  <ListItem
                    id="16"
                    button={true}
                    selected={selectedSection === 16}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translations.ACCOUNT_MANAGEMENT_CONTRACT}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="17"
                    button={true}
                    selected={selectedSection === 17}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translations.SAVINGS_ACCOUNT_CONTRACTS}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="18"
                    button={true}
                    selected={selectedSection === 18}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Certificados financieros"} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="19"
                    button={true}
                    selected={selectedSection === 19}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Certificados de aportaciones"} />
                  </ListItem>
                  <Divider />
                </List>
              </Collapse>
              <Divider />
              <ListItem
                id="56"
                button={true}
                selected={selectedSection === 56}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={"Cumplimiento"} />
                {selectedSection === 56 ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={collapse.documents.compliance.includes(selectedSection)}
                timeout="auto"
                unmountOnExit={true}
              >
                <List component="nav" disablePadding={true}>
                  <ListItem
                    id="57"
                    button={true}
                    selected={selectedSection === 57}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.AFFIDAVIT_FUNDS} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="58"
                    button={true}
                    selected={selectedSection === 58}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translations.CLINET_AFFIDAVIT_FUNDS}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="59"
                    button={true}
                    selected={selectedSection === 59}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Formulario de Cumplimiento"} />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />
              <ListItem
                id="62"
                button={true}
                selected={selectedSection === 62}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={"Aprobación"} />
                {selectedSection === 62 ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={collapse.documents.approvement.includes(selectedSection)}
                timeout="auto"
                unmountOnExit={true}
              >
                <List component="nav" disablePadding={true}>
                  <ListItem
                    id="63"
                    button={true}
                    selected={selectedSection === 63}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Actas"} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="64"
                    button={true}
                    selected={selectedSection === 64}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Admisión"} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="65"
                    button={true}
                    selected={selectedSection === 65}
                    onClick={this.handleListItemClick}
                    className={classes.nestedListItem}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Prestamos"}
                    />
                  </ListItem>
                </List>
              </Collapse>
            </List>
            <ListItem
                id="69"
                button={true}
                selected={selectedSection === 69}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={'Estados de cuenta'} />
              </ListItem>
            <ListItem
                id="72"
                button={true}
                selected={selectedSection === 72}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={'Contrato de servicios eléctronicos'} />
              </ListItem>
          </Collapse>
          <Divider />
          <ListItem
            id="20"
            button={true}
            selected={selectedSection === 20}
            onClick={this.handleListItemClick}
          >
            <ListItemIcon>
              <PeopleIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary={translations.PARTICIPANTS} />
          </ListItem>
          <Collapse
            in={collapse.participants.all.includes(selectedSection)}
            timeout="auto"
            unmountOnExit={true}
          >
            <List component="nav" disablePadding={true}>
              <ListItem
                id="21"
                button={true}
                selected={selectedSection === 21}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={translations.CLIENTS} />
              </ListItem>
              <Divider />
              <ListItem
                id="22"
                button={true}
                selected={selectedSection === 22}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={translations.CREDITORS} />
              </ListItem>
            </List>
            <Divider />
            <ListItem
              id="23"
              button={true}
              selected={selectedSection === 23}
              onClick={this.handleListItemClick}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={translations.PROVIDERS} />
            </ListItem>
            <Divider />
          </Collapse>
          <Divider />

          {/* LEADS */}
          <ListItem
            id="70"
            button={true}
            selected={selectedSection === 70}
            onClick={this.handleListItemClick}
          >
            <ListItemIcon>
              <AssignmentIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary={translations.LEADS} />
          </ListItem>
          <Collapse
            in={collapse.leads.includes(selectedSection)}
            timeout="auto"
            unmountOnExit={true}
          >
            <List component="nav" disablePadding={true}>
              <ListItem
                id="71"
                button={true}
                selected={selectedSection === 71}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={translations.LEADS_REQUESTS_MANAGEMENT} />
              </ListItem>
            </List>
          </Collapse>
          <Divider />

          {/* INSTITUTIONS */}
          <ListItem
            id="24"
            button={true}
            selected={selectedSection === 24}
            onClick={this.handleListItemClick}
          >
            <ListItemIcon>
              <BusinessIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary={translations.INSTITUTIONS} />
          </ListItem>
          <Collapse
            in={collapse.institutions.includes(selectedSection)}
            timeout="auto"
            unmountOnExit={true}
          >
            <List component="nav" disablePadding={true}>
              <ListItem
                id="25"
                button={true}
                selected={selectedSection === 25}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary={translations.FINANCIAL} />
              </ListItem>
              <Divider />
              <ListItem
                id="26"
                button={true}
                selected={selectedSection === 26}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary={translations.PRIVATE} />
              </ListItem>
              <Divider />
              <ListItem
                id="27"
                button={true}
                selected={selectedSection === 27}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary={translations.PUBLIC} />
              </ListItem>
            </List>
          </Collapse>
          <Divider />

           {/* CLAIMS */}
          <ListItem
            id="67"
            button={true}
            selected={selectedSection === 67}
            onClick={this.handleListItemClick}
          >
            <ListItemIcon>
              <SpeakerNotesIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary={translations.CLAIMS} />
          </ListItem>
          <Collapse
            in={collapse.claims.includes(selectedSection)}
            timeout="auto"
            unmountOnExit={true}
          >
            <List component="nav" disablePadding={true}>
              <ListItem
                id="68"
                button={true}
                selected={selectedSection === 68}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <ViewListIcon />
                </ListItemIcon>
                <ListItemText primary={translations.CLAIMS} />
              </ListItem>
              <Divider />
            </List>
          </Collapse>
          <Divider style={{ marginTop: 1 }} />

          {/*REPORTS*/}
            <ListItem
            id="74"
            button={true}
            selected={selectedSection === 74}
            onClick={this.handleListItemClick}
          >
            <ListItemIcon>
              <AssessmentIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary={"Reportes"} />
          </ListItem>

          <Collapse
            in={collapse.reports.includes(selectedSection)}
            timeout="auto"
            unmountOnExit={true}
          >

          <List component="nav" disablePadding={true}>
              <ListItem
                id="75"
                button={true}
                selected={selectedSection === 75}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <TimerOffIcon />
                </ListItemIcon>
                <ListItemText primary={"Reportes de Atraso"} />
              </ListItem>
              <ListItem
                id="76"
                button={true}
                selected={selectedSection === 76}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <MonetizationOn />
                </ListItemIcon>
                <ListItemText primary={"Reportes de Descuento"} />
              </ListItem>
              <ListItem
                id="77"
                button={true}
                selected={selectedSection === 77}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <LocalAtm />
                </ListItemIcon>
                <ListItemText primary={"Transacciones en Efectivo"} />
              </ListItem>
              <ListItem
                id="78"
                button={true}
                selected={selectedSection === 78}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <VerifiedUser />
                </ListItemIcon>
                <ListItemText primary={"Reportes de Seguro"} />
              </ListItem>
              <Divider />
            </List>
          </Collapse>
            
          <Divider style={{ marginTop: 1 }} />
            
          <ListItem
            id="28"
            button={true}
            selected={selectedSection === 28}
            onClick={this.handleListItemClick}
          >
            <ListItemIcon>
              <AccountBalanceWalletIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary="Cuentas bancarias" />
          </ListItem>
          <Collapse
            in={collapse.bankAccounts.includes(selectedSection)}
            timeout="auto"
            unmountOnExit={true}
          >
            <List component="nav" disablePadding={true}>
              <ListItem
                id="29"
                button={true}
                selected={selectedSection === 29}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText primary={translations.BANK_ACCOUNTS} />
              </ListItem>

              <Divider />
              <ListItem
                id="30"
                button={true}
                selected={selectedSection === 30}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText primary={translations.DEPOSIT_ACCOUNTS} />
              </ListItem>
            </List>
          </Collapse>
          <Divider />
          <ListItem
            id="31"
            button={true}
            selected={selectedSection === 31}
            onClick={this.handleListItemClick}
          >
            <ListItemIcon>
              <AttachMoneyIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary={translations.TRANSACTIONS} />
          </ListItem>
          <Divider />
          <Collapse
            in={collapse.transactions.all.includes(selectedSection)}
            timeout="auto"
            unmountOnExit={true}
          >
            <List component="nav" disablePadding={true}>
              <ListItem
                id="32"
                button={true}
                selected={selectedSection === 32}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary={"Contabilidad"} />
                {selectedSection === 32 ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={collapse.transactions.contability.includes(selectedSection)}
                timeout="auto"
                unmountOnExit={true}
              >
                <List component="nav" disablePadding={true}>
                  <ListItem
                    id="33"
                    button={true}
                    selected={selectedSection === 33}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <SwapIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.ACCOUNTING_RECORDS} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="34"
                    button={true}
                    selected={selectedSection === 34}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <CatalogIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.CHART_OF_ACCOUNTS} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="35"
                    button={true}
                    selected={selectedSection === 35}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.BANK_RECONCILIATION} />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />
              <ListItem
                id="36"
                button={true}
                selected={selectedSection === 36}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <LocationCityIcon />
                </ListItemIcon>
                <ListItemText primary={translations.BANK} />
                {selectedSection === 36 ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={collapse.transactions.banks.includes(selectedSection)}
                timeout="auto"
                unmountOnExit={true}
              >
                <List component="nav" disablePadding={true}>
                  <ListItem
                    id="37"
                    button={true}
                    selected={selectedSection === 37}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.CHECK} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="38"
                    button={true}
                    selected={selectedSection === 38}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.CASH} />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />
              <ListItem
                id="39"
                button={true}
                selected={selectedSection === 39}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <ListItemText primary={"Prestamos"} />
                {selectedSection === 39 ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={collapse.transactions.loans.includes(selectedSection)}
                timeout="auto"
                unmountOnExit={true}
              >
                <List component="nav" disablePadding={true}>
                  <ListItem
                    id="40"
                    button={true}
                    selected={selectedSection === 40}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.OUTLAY} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="41"
                    button={true}
                    selected={selectedSection === 41}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <InsertInvitation />
                    </ListItemIcon>
                    <ListItemText primary={translations.EXPIRY_VIEW} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="42"
                    button={true}
                    selected={selectedSection === 42}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <Payment />
                    </ListItemIcon>
                    <ListItemText primary={translations.PAYMENT_VIEW} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="66"
                    button={true}
                    selected={selectedSection === 66}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary={translations.PAYMENT_RECEIPT} />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Collapse>
          <Divider />
          <ListItem
            id="43"
            button={true}
            selected={selectedSection === 43}
            onClick={this.handleListItemClick}
          >
            <ListItemIcon>
              <SettingIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary={translations.SETTING} />
          </ListItem>
          <Collapse
            in={collapse.settings.all.includes(selectedSection)}
            timeout="auto"
            unmountOnExit={true}
          >
            <List component="nav" disablePadding={true}>
              <ListItem
                id="44"
                button={true}
                selected={selectedSection === 44}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <FlagIcon />
                </ListItemIcon>
                <ListItemText primary={translations.NATIONALITIES} />
              </ListItem>
              <Divider />
              <ListItem
                id="45"
                button={true}
                selected={selectedSection === 45}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <CreditCardIcon />
                </ListItemIcon>
                <ListItemText primary={translations.PAYROLL_CARD} />
              </ListItem>
              <Divider />
              <ListItem
                id="46"
                button={true}
                selected={selectedSection === 46}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary={translations.FLIER} />
              </ListItem>
              <Divider />
              <ListItem
                id="47"
                button={true}
                selected={selectedSection === 47}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <SubtitlesIcon />
                </ListItemIcon>
                <ListItemText primary={translations.CHECK} />
              </ListItem>
              <Divider />
              <ListItem
                id="48"
                button={true}
                selected={selectedSection === 48}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <ListItemText primary={"BlackList"} />
                {selectedSection === 48 ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse
                in={collapse.settings.blacklist.includes(selectedSection)}
                timeout="auto"
                unmountOnExit={true}
              >
                <List component="nav" disablePadding={true}>
                  <ListItem
                    id="49"
                    button={true}
                    selected={selectedSection === 49}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faUserSlash} />
                    </ListItemIcon>
                    <ListItemText primary="Blacklist" />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="50"
                    button={true}
                    selected={selectedSection === 50}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faUserSlash} />
                    </ListItemIcon>
                    <ListItemText primary="BlackList nacional e internacional" />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="51"
                    button={true}
                    selected={selectedSection === 51}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faUserSlash} />
                    </ListItemIcon>
                    <ListItemText primary="Peps list" />
                  </ListItem>
                  <Divider />
                  <ListItem
                    id="52"
                    button={true}
                    selected={selectedSection === 52}
                    onClick={this.handleListItemClick}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faUserSlash} />
                    </ListItemIcon>
                    <ListItemText primary="OFAC y ONU list" />
                  </ListItem>
                </List>
              </Collapse>
              <Divider />
              <ListItem
                id="53"
                button={true}
                selected={selectedSection === 53}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Profesiones" />
              </ListItem>
              <Divider />
              <ListItem
                id="54"
                button={true}
                selected={selectedSection === 54}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Garantías" />
              </ListItem>
              <Divider />
              <ListItem
                id="73"
                button={true}
                selected={selectedSection === 73}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Representantes de Negocios" />
              </ListItem>
              <Divider />
              <ListItem
                id="55"
                button={true}
                selected={selectedSection === 55}
                onClick={this.handleListItemClick}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faPercent} />
                </ListItemIcon>
                <ListItemText primary="Tasa de interes pasiva" />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </div>
    );
  }
}

function mapStateToPros(state: IState) {
  return {
    selectedSection: state.selectedSection,
  };
}

export default connect(mapStateToPros, { changeSection })(
  withStyles(styles)(MenuList)
);
