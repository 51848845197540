import * as React from 'react';

import Buttom from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import ArrowbackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';

import { titleCaseHandler, translations } from '../../utils';

const styles = createStyles({
    button: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white',
    },
    checkboxLabel: {
        color: green[500],
        fontSize: 'medium'
    },
    formControl: {
        marginTop: 20,
        minWidth: 300,
        width: 300
    },
    root: {
        marginBottom: 5,
        marginTop: 5
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: 500

    }
});

class SelectInput extends React.Component {
    constructor(props) {
        super(props);
    this.state = {
        name: '',
        nameError: '',
        open: false,
    };


        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.openDialogHandler = this.openDialogHandler.bind(this);
        this.closeDialogHandler = this.closeDialogHandler.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.map = this.map.bind(this);
    }
    handleChange(key) {
        return (event) => {
            this.setState({
                [key]: event.currentTarget.value,
                [key + 'Error']: ''
            });
        }
    }
    handleSelectChange(key) {
        return (event) => {
            this.setState({
                [key]: event.target.value,
                [key + 'Error']: ''
            });
        }
    }
    openDialogHandler() {
        this.setState({ open: true });
    }
    closeDialogHandler() {
        this.setState({ open: false });
    }
    handleAdd(event) {

        let valid = true;
        const errorMessage = "Este campo no puede estar vacio";

        if (this.state.name.trim() === '') {
            valid = false;
            this.setState({ nameError: errorMessage });
        }

        if (valid) {
            this.props.add({
                name: this.state.name,
            }, "affiliatesCompanies");

            this.setState({ open: false, name: ''});
        }

    }
    handleDelete(index) {
        return (event) => {
            this.props.delete(index,"affiliatesCompanies");
        }
    }
    map(companie, index) {
        return (
            <TableRow key={index}>
                <TableCell>{companie.name}</TableCell>
                <TableCell align={'right'}>
                    <IconButton onClick={this.handleDelete(index)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }

    render() {
        const { classes, companies } = this.props;
        const { open } = this.state;

        return (
            <div className={classes.root}>
                <Dialog open={open} onClose={this.closeDialogHandler} >
                    <DialogActions>
                        <IconButton className={classes.button} onClick={this.closeDialogHandler}>
                            <ArrowbackIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent >
                                
                                <TextField
                                    label={translations.NAME}
                                    className={classes.textField}
                                    value={titleCaseHandler(this.state.name)}
                                    onChange={this.handleChange("name")}
                                    fullWidth={true}
                                    error={this.state.nameError !== ''}
                                    helperText={this.state.nameError}
                                    InputLabelProps={{ style: styles.checkboxLabel }}
                                />
                                        
                    </DialogContent>
                    <DialogActions>
                        <Buttom onClick={this.handleAdd} className={classes.button}>
                            {translations.SAVE}
                        </Buttom>
                    </DialogActions>
                </Dialog>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{translations.NAME}</TableCell>
                                <TableCell align={'right'}>
                                {companies.length < this.props.tableLimit ?
                                    <IconButton className={classes.button} onClick={this.openDialogHandler}>
                                        <AddIcon />
                                    </IconButton>
                                : "Acciones"}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companies.map(this.map)}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(SelectInput); 
