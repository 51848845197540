import React, { useState, useEffect } from 'react';
import {
    Grid, Typography, IconButton, Button
} from '@material-ui/core';
import { 
    Close, Tune, ClearAll
} from '@material-ui/icons';
import { 
    AutocompleteField, DateField, NumericMinMax , SearchComponent
} from './../../../components/SharedComponents/Fields';
import { dateFormat } from './../../../components/SharedComponents/CustomHooks';

function FiltersHeader(props) {
    return (
        <Grid container justifyContent='space-between' alignItems="center">
            <Typography
                variant={props.typeVariant}
                className={props.typeClass}
                style={{fontWeight: 'bold'}}
            >
                {props.filtersTitle}
            </Typography>
            <IconButton 
                className={props.btnClass}
                onClick={props.toggleFilter}
                disabled={props.disableClose}
            >
                {
                    props.openFilter ? 
                    <Close
                        className={props.iconClass}     
                    />
                    :
                    <Tune
                        className={props.iconClass}
                    />
                }
            </IconButton>
        </Grid>
    )
}

function Filters(props) {
    const [status, setStatus] = useState([]);
    const [typeOfCase, setTypeOfCase] = useState([]);
    const [department, setDepartment] = useState([]);
    const [date, setDate] = useState({start: null, end: null});
    const [openFilter, setOpenFilter] = useState(true);
    const [client, setClient] = useState(null);

    const clearFilters = () => {
        setTypeOfCase([]);
        setDepartment([]);
        setStatus([]);
        setClient([]);
        setDate({start: null, end: null});
    }

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    }
    const handleStatusChange = (event, value) => {
        setStatus(value);
    }

    const handleDepartmentChange = (event, value) => {
        setDepartment(value);
    }

    const handleTypeOfCaseChange = (event, value) => {
        setTypeOfCase(value);
    }

    // const handleClientChange = (key, obj, val) => {
    //     const data = obj || val || key;
        
    //     setClients(data.value ? data.value : "");
        
    //     let myPromise = new Promise(function() {
    //         console.log(data);
    //     })
    //     myPromise().then(()=>{console.log("SD")}).catch(()=>{console.log(sd)})
       
    // }
    const handleClientChange = (value) => {
        setClient(value);
    }

    // useEffect(()=>{
    //     console.log(clients)
    // },[clients])

    const handleDateChange = (type, dateInfo, value) => {
        setDate({...date, [type]: dateInfo});
    }

    const getFiltersOptions = (option) => {
        return(
            (option ? option : [])
        );
    }
    const applyFilters = () => {
        if (date.start && !date.end) {
            props.handleToggleSimpleDialog('simple-dialog', "Debe seleccionar ambas fechas.");
            return;
        } else if (!date.start && date.end) {
            props.handleToggleSimpleDialog('simple-dialog', "Debe seleccionar ambas fechas.");
            return;
        }
        const fields = {
            status: status.map(item => {return item.value}).join(','),
            department: department.map(item => {return item.value}).join(','),
            typeOfCase: typeOfCase.map(item => {return item.value}).join(','),
            clientUniqueId: client? client.value : "",
            creationDateFrom: dateFormat(date.start) || "",
            creationDateTo: dateFormat(date.end) || ""
        };
        Object.keys(fields).forEach(key => {
            if(!fields[key]) {
                delete fields[key];
            }
        });
        props.applyFilters(fields);
    }

    return(
        <Grid container className="filters-container">
            <FiltersHeader
                typeVariant="subtitle1"
                typeClass="section-header"
                filtersTitle="Filtros"
                btnClass={("filter-btn-close ") + props.hideClass}
                iconClass="filter-icon-close"
                toggleFilter={toggleFilter}
                openFilter={openFilter}
                disableClose={props.disableCloseBtn}
            />
            <Grid container className={openFilter ? "filter-content" : "filter-content collided"}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <SearchComponent 
                            xs={4}
                            containerClass="field-container"
                            fieldLabel="Socios"
                            fieldID="claim-filter-client"
                            fieldInnerLabel="Seleccione..."
                            handleChange={handleClientChange}
                            optionType={'participants'}
                            clearOnBlur={false}
                            limitTags={1}
                            savedValue={props.selectedFilters.client.name?props.selectedFilters.client:null}
                        />
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Estados"
                            fieldID="filter-field-status"
                            fieldInnerLabel="Estados"
                            fieldVariant="outlined"
                            value={status}
                            handleChange={handleStatusChange}
                            options={getFiltersOptions(props.listData.status)}
                            getOptionLabel={(option) => option.name}
                            multiple={true}
                            limitTags={1}
                        />
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Departamentos"
                            fieldID="filter-field-department"
                            fieldInnerLabel="Departamentos"
                            fieldVariant="outlined"
                            value={department}
                            handleChange={handleDepartmentChange}
                            options={getFiltersOptions(props.listData.departments)}
                            getOptionLabel={(option) => option.name}
                            multiple={true}
                            limitTags={1}
                        />
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <AutocompleteField
                            xs={4}
                            containerClass="field-container"
                            fieldClass="fields"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Tipo de casos"
                            fieldID="filter-field-type-of-case"
                            fieldInnerLabel="Tipo de casos"
                            fieldVariant="outlined"
                            value={typeOfCase}
                            handleChange={handleTypeOfCaseChange}
                            options={getFiltersOptions(props.listData.typeOfCases)}
                            getOptionLabel={(option) => option.name}
                            multiple={true}
                            limitTags={1}
                            />
                        <DateField
                            containerClass="field-container"
                            id="date"
                            label="Fecha de reclamo"
                            InputLabelProps={{ shrink: true }}
                            typeVariant="subtitle1"
                            fieldClass="fields"
                            typeClass="field-label"
                            dateFieldClass="fields"
                            fieldLabel="Fecha de creación (inicio)"
                            xs={4}
                            type="datetime"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            fullWidth={true}
                            value={date.start}
                            dateType="start"
                            handleChange={handleDateChange.bind(this, "start")}
                            />
                        <DateField
                            id="date"
                            label="Fecha de reclamo"
                            InputLabelProps={{ shrink: true }}
                            typeVariant="subtitle1"
                            dateFieldClass="fields"
                            typeClass="field-label"
                            fieldLabel="Fecha de creación (final)"
                            xs={4}
                            type="datetime"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            fullWidth={true}
                            value={date.end}
                            dateType="end"
                            handleChange={handleDateChange.bind(this, "end")}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent='space-between' style={{marginTop:'35px'}}>

                    <Button
                        disableElevation
                        className="filter-clear-btn"
                        onClick={clearFilters.bind(this, false)}
                    >
                        limpiar
                        <ClearAll style={{marginLeft:8, fontSize: '19px'}}/>
                    </Button>

                    <Button
                        disableElevation
                        className="filter-apply-btn"
                        onClick={applyFilters}
                    >
                        aplicar filtros
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    );
}
export { Filters }