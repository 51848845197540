import React from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  IconButton,
  Divider,
  TableContainer,
  Toolbar,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { TextFieldComponent } from "../../../components/SharedComponents/Fields";
import { CollapsibleTable } from "../Tables/inflex";

function TableToolbar(props) {
  return (
    <Toolbar>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          className="section-header"
          style={{ fontWeight: "bold", paddingLeft: 16 }}
        >
          {props.title}
        </Typography>
      </div>
    </Toolbar>
  );
}

const setCurrency = (value) => {
  let data = parseFloat(value).toFixed(2);
  return Number(data).toLocaleString("en-US", {
    style: "currency",
    currency: "DOP",
  });
};

function PCPDialog(props) {
  const columnsPCP = [
    {
      id: "collapse",
      label: "",
      width: 10,
      align: "center",
      isCollapse: (row) => row.collapseData.length,
    },
    {
      id: "product_type_destiny",
      label: "Tipo producto".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "pcp_amount",
      label: "Monto PCP".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.pcp_amount),
    },
    {
      id: "pcp_total_due",
      label: "Total vencido".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.pcp_total_due),
    },
  ];

  const columnsPCPCollaps = [
    {
      id: "loan_number",
      label: "Número de prestamo".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    // {
    //   id: "disbursement_date",
    //   label: "Fecha Desembolso".toUpperCase(),
    //   minWidth: 160,
    //   align: "center",
    // },
    {
      id: "product_type",
      label: "Tipo Transacción".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "loan_outstanding_balance",
      label: "Saldo principal".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.loan_outstanding_balance),
    },
    {
      id: "due_date",
      label: "Fecha Vencimiento".toUpperCase(),
      minWidth: 160,
      align: "center",
    },
    {
      id: "pcp_amount",
      label: "Monto".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.pcp_amount),
    },
    {
      id: "total_pcp_due",
      label: "Total vencido".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => setCurrency(row.total_pcp_due),
    },
  ];

  const data = props.data.map((item) => {
    return {
      ...item,
      collapseData: item.pcp_details.sort((a, b) => {
        return new Date(a.due_date) - new Date(b.due_date);
      }),
    };
  });

  return (
    <Dialog
      maxWidth={"lg"}
      fullWidth={true}
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={props.open}
    >
      <DialogTitle style={{ backgroundColor: green[500] }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography
            component={"span"}
            variant="h5"
            className="dialog-title"
            style={{ color: "white" }}
          >
            PCP
          </Typography>
          <IconButton onClick={() => props.setOpen(!props.open)}>
            <CloseOutlined className="dialog-header-close-btn" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ position: "relative", marginBottom: "5%" }}>
        <div>
          <Grid container spacing={2}>
            <Divider />
            <TableContainer style={{ width: "100%" }}>
              <CollapsibleTable
                columns={columnsPCP}
                columnsCollapse={columnsPCPCollaps}
                data={data}
                toolbar={<TableToolbar title={"DETALLE PCP"} />}
              />
            </TableContainer>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export { PCPDialog };
