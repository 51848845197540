import * as searchDataAPI from "./searchDataApi";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";

// Authentication types
export const AUTHENTICATION_TYPES: IAuthenticationActionTypes = {
  LOG_OFF: "LOG_OFF",
  LOG_USER: "LOG_USER",
};
// Authentication Action Creators
export function logUser(user: IUser): IAction<IUser> {
  return {
    payload: user,
    type: AUTHENTICATION_TYPES.LOG_USER,
  };
}
export function logOff(): IAction {
  return {
    type: AUTHENTICATION_TYPES.LOG_OFF,
  };
}

// Selected section  types
export const SELECTED_SECTION_TYPES: ISelectedActionTypes = {
  CHANGE: "CHANGE",
};

// Selected section Action creators
export function changeSection(index: number): IAction<number> {
  return {
    payload: index,
    type: SELECTED_SECTION_TYPES.CHANGE,
  };
}

// Bank
export const BANK_TYPES: IActionTypes = {
  CREATE: "BANK_CREATE",
  DELETE: "BANK_DELETE",
  FETCH: "BANK_FETCH",
  FETCH_ALL: "BANK_FETCH_ALL",
  UPDATE: "BANK_UPDATE",
};
// Bank Actions creators
export function fetchBanks(banks: IBank[]): IAction<IBank[]> {
  return {
    payload: banks,
    type: BANK_TYPES.FETCH_ALL,
  };
}
export function fetchBank(bank: IBank): IAction<IBank[]> {
  return {
    payload: [bank],
    type: BANK_TYPES.FETCH,
  };
}
export function createBank(bank: IBank): IAction<IBank[]> {
  return {
    payload: [bank],
    type: BANK_TYPES.CREATE,
  };
}
export function updateBank(bank: IBank): IAction<IBank[]> {
  return {
    payload: [bank],
    type: BANK_TYPES.UPDATE,
  };
}
export function deleteBank(uniqueId: string): IAction<IBank[]> {
  return {
    payload: [{ uniqueId }],
    type: BANK_TYPES.DELETE,
  };
}

// company
export const COMPANY_TYPES: IActionTypes = {
  CREATE: "COMPANY_CREATE",
  DELETE: "COMPANY_DELETE",
  FETCH: "COMPANY_FETCH",
  FETCH_ALL: "COMPANY_FETCH_ALL",
  UPDATE: "COMPANY_UPDATE",
};
// company Action Creators
export function fetchCompanies(companies: ICompany[]): IAction<ICompany[]> {
  return {
    payload: companies,
    type: COMPANY_TYPES.FETCH_ALL,
  };
}
export function fetchCompany(company: ICompany): IAction<ICompany[]> {
  return {
    payload: [company],
    type: COMPANY_TYPES.FETCH,
  };
}
export function createCompany(company: ICompany): IAction<ICompany[]> {
  return {
    payload: [company],
    type: COMPANY_TYPES.CREATE,
  };
}
export function updateCompany(company: ICompany): IAction<ICompany[]> {
  return {
    payload: [company],
    type: COMPANY_TYPES.UPDATE,
  };
}
export function deleteCompany(uniqueId: string): IAction<ICompany[]> {
  return {
    payload: [{ uniqueId }],
    type: COMPANY_TYPES.DELETE,
  };
}

// person
export const PERSON_TYPES: IActionTypes = {
  CREATE: "PERSON_CREATE",
  DELETE: "PERSON_DELETE",
  FETCH: "PERSON_FETCH",
  FETCH_ALL: "PERSON_FETCH_ALL",
  UPDATE: "PERSON_UPDATE",
};
// person Actions creators
export function fetchPersons(persons: IPerson[]): IAction<IPerson[]> {
  return {
    payload: persons,
    type: PERSON_TYPES.FETCH_ALL,
  };
}
export function fetchPerson(person: IPerson): IAction<IPerson[]> {
  return {
    payload: [person],
    type: PERSON_TYPES.FETCH,
  };
}
export function createPerson(person: IPerson): IAction<IPerson[]> {
  return {
    payload: [person],
    type: PERSON_TYPES.CREATE,
  };
}
export function updatePerson(person: IPerson): IAction<IPerson[]> {
  return {
    payload: [person],
    type: PERSON_TYPES.UPDATE,
  };
}
export function deletePerson(uniqueId: string): IAction<IPerson[]> {
  return {
    payload: [{ uniqueId }],
    type: PERSON_TYPES.DELETE,
  };
}

// new Changes

// participant
export const PARTICIPANT_TYPES: IActionTypes = {
  CREATE: "PARTICIPANT_TYPES_CREATE",
  DELETE: "PARTICIPANT_TYPES_DELETE",
  FETCH: "PARTICIPANT_TYPES_FETCH",
  FETCH_ALL: "PARTICIPANT_TYPES_FETCH_ALL",
  UPDATE: "PARTICIPANT_TYPES_UPDATE",
};
// participant action creators
export function fetchParticipants(
  participants: IParticipant[]
): IAction<IParticipant[]> {
  return {
    payload: participants,
    type: PARTICIPANT_TYPES.FETCH_ALL,
  };
}
export function fetchParticipant(
  participant: IParticipant
): IAction<IParticipant[]> {
  return {
    payload: [participant],
    type: PARTICIPANT_TYPES.FETCH,
  };
}
export function createParticipant(
  participant: IParticipant
): IAction<IParticipant[]> {
  return {
    payload: [participant],
    type: PARTICIPANT_TYPES.CREATE,
  };
}
export function updateParticipant(
  participant: IParticipant
): IAction<IParticipant[]> {
  return {
    payload: [participant],
    type: PARTICIPANT_TYPES.UPDATE,
  };
}
export function deleteParticipant(uniqueId: string): IAction<IParticipant[]> {
  return {
    payload: [{ uniqueId }],
    type: PARTICIPANT_TYPES.DELETE,
  };
}

// Institution
export const INSTITUTIONS_TYPES: IActionTypes = {
  CREATE: "INSTITUTIONS_TYPES_CREATE",
  DELETE: "INSTITUTIONS_TYPES_DELETE",
  FETCH: "INSTITUTIONS_TYPES_FETCH",
  FETCH_ALL: "INSTITUTIONS_TYPES_FETCH_ALL",
  UPDATE: "INSTITUTIONS_TYPES_UPDATE",
};
// Institution action creators
export function fetchInstitutions(
  institution: IInstitution[]
): IAction<IInstitution[]> {
  return {
    payload: institution,
    type: INSTITUTIONS_TYPES.FETCH_ALL,
  };
}
export function fetchInstitution(
  institution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [institution],
    type: INSTITUTIONS_TYPES.FETCH,
  };
}
export function createInstitution(
  institution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [institution],
    type: INSTITUTIONS_TYPES.CREATE,
  };
}
export function updateInstitution(
  institution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [institution],
    type: INSTITUTIONS_TYPES.UPDATE,
  };
}
export function deleteInstitution(uniqueId: string): IAction<IInstitution[]> {
  return {
    payload: [{ uniqueId }],
    type: INSTITUTIONS_TYPES.DELETE,
  };
}

// attorney types
export const ATTORNEY_TYPES: IActionTypes = {
  CREATE: "ATTORNEY_CREATE",
  DELETE: "ATTORNEY_DELETE",
  FETCH: "ATTORNEY_FETCH",
  FETCH_ALL: "ATTORNEY_FETCH_ALL",
  UPDATE: "ATTORNEY_UPDATE",
};

// attorney Actions creators
export function fetchAttorneys(
  attorneys: IParticipant[]
): IAction<IParticipant[]> {
  return {
    payload: attorneys,
    type: ATTORNEY_TYPES.FETCH_ALL,
  };
}
export function fetchAttorney(attorney: IParticipant): IAction<IParticipant[]> {
  return {
    payload: [attorney],
    type: ATTORNEY_TYPES.FETCH,
  };
}
export function createAttorney(
  attorney: IParticipant
): IAction<IParticipant[]> {
  return {
    payload: [attorney],
    type: ATTORNEY_TYPES.CREATE,
  };
}
export function updateAttorney(
  attorney: IParticipant
): IAction<IParticipant[]> {
  return {
    payload: [attorney],
    type: ATTORNEY_TYPES.UPDATE,
  };
}
export function deleteAttorney(uniqueId: string): IAction<IParticipant[]> {
  return {
    payload: [{ uniqueId }],
    type: ATTORNEY_TYPES.DELETE,
  };
}

// client
export const CLIENT_TYPES: IActionTypes = {
  CREATE: "CLIENT_CREATE",
  DELETE: "CLIENT_DELETE",
  FETCH: "CLIENT_FETCH",
  FETCH_ALL: "CLIENT_FETCH_ALL",
  UPDATE: "CLIENT_UPDATE",
};
// client action creators
export function fetchClients(clients: IParticipant[]): IAction<IParticipant[]> {
  return {
    payload: clients,
    type: CLIENT_TYPES.FETCH_ALL,
  };
}
export function fetchClient(client: IParticipant): IAction<IParticipant[]> {
  return {
    payload: [client],
    type: CLIENT_TYPES.FETCH,
  };
}
export function createClient(client: IParticipant): IAction<IParticipant[]> {
  return {
    payload: [client],
    type: CLIENT_TYPES.CREATE,
  };
}
export function updateClient(client: IParticipant): IAction<IParticipant[]> {
  return {
    payload: [client],
    type: CLIENT_TYPES.UPDATE,
  };
}
export function deleteClient(uniqueId: string): IAction<IParticipant[]> {
  return {
    payload: [{ uniqueId }],
    type: CLIENT_TYPES.DELETE,
  };
}

// company representative
export const COMPANY_REPRESENTATIVE_TYPES: IActionTypes = {
  CREATE: "COMPANY_REPRESENTATIVE_CREATE",
  DELETE: "COMPANY_REPRESENTATIVE_DELETE",
  FETCH: "COMPANY_REPRESENTATIVE_FETCH",
  FETCH_ALL: "COMPANY_REPRESENTATIVE_FETCH_ALL",
  UPDATE: "COMPANY_REPRESENTATIVE_UPDATE",
};

// company representative action creators
export function fetchCompanyRepresentatives(
  companyRepresentatives: IParticipant[]
): IAction<IParticipant[]> {
  return {
    payload: companyRepresentatives,
    type: COMPANY_REPRESENTATIVE_TYPES.FETCH_ALL,
  };
}
export function fetchCompanyRepresentative(
  companyRepresentative: IParticipant
): IAction<IParticipant[]> {
  return {
    payload: [companyRepresentative],
    type: COMPANY_REPRESENTATIVE_TYPES.FETCH,
  };
}
export function createCompanyRepresentative(
  companyRepresentative: IParticipant
): IAction<IParticipant[]> {
  return {
    payload: [companyRepresentative],
    type: COMPANY_REPRESENTATIVE_TYPES.CREATE,
  };
}
export function updateCompanyRepresentative(
  companyRepresentative: IParticipant
): IAction<IParticipant[]> {
  return {
    payload: [companyRepresentative],
    type: COMPANY_REPRESENTATIVE_TYPES.UPDATE,
  };
}
export function deleteCompanyRepresentative(
  uniqueId: string
): IAction<IParticipant[]> {
  return {
    payload: [{ uniqueId }],
    type: COMPANY_REPRESENTATIVE_TYPES.DELETE,
  };
}

// creditor
export const CREDITOR_TYPES: IActionTypes = {
  CREATE: "CREDITOR_CREATE",
  DELETE: "CREDITOR_DELETE",
  FETCH: "CREDITOR_FETCH",
  FETCH_ALL: "CREDITOR_FETCH_ALL",
  UPDATE: "CREDITOR_UPDATE",
};

// creditors action creators
export function fetchCreditors(
  creditors: IParticipant[]
): IAction<IParticipant[]> {
  return {
    payload: creditors,
    type: CREDITOR_TYPES.FETCH_ALL,
  };
}
export function fetchCreditor(creditor: IParticipant): IAction<IParticipant[]> {
  return {
    payload: [creditor],
    type: CREDITOR_TYPES.FETCH,
  };
}
export function createCreditor(
  creditor: IParticipant
): IAction<IParticipant[]> {
  return {
    payload: [creditor],
    type: CREDITOR_TYPES.CREATE,
  };
}
export function updateCreditor(
  creditor: IParticipant
): IAction<IParticipant[]> {
  return {
    payload: [creditor],
    type: CREDITOR_TYPES.UPDATE,
  };
}
export function deleteCreditor(uniqueId: string): IAction<IParticipant[]> {
  return {
    payload: [{ uniqueId }],
    type: CREDITOR_TYPES.DELETE,
  };
}

// private institutions
export const PRIVATE_INSTITUTION_TYPES: IActionTypes = {
  CREATE: "PRIVATE_INSTITUTION_CREATE",
  DELETE: "PRIVATE_INSTITUTION_DELETE",
  FETCH: "PRIVATE_INSTITUTION_FETCH",
  FETCH_ALL: "PRIVATE_INSTITUTION_FETCH_ALL",
  UPDATE: "PRIVATE_INSTITUTION_UPDATE",
};
// private institutions action creators
export function fetchPrivateInstitutions(
  privateInstitutions: IInstitution[]
): IAction<IInstitution[]> {
  return {
    payload: privateInstitutions,
    type: PRIVATE_INSTITUTION_TYPES.FETCH_ALL,
  };
}
export function fetchPrivateInstitution(
  privateInstitution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [privateInstitution],
    type: PRIVATE_INSTITUTION_TYPES.FETCH,
  };
}
export function createPrivateInstitution(
  privateInstitution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [privateInstitution],
    type: PRIVATE_INSTITUTION_TYPES.CREATE,
  };
}
export function updatePrivateInstitution(
  privateInstitution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [privateInstitution],
    type: PRIVATE_INSTITUTION_TYPES.UPDATE,
  };
}
export function deletePrivateInstitution(
  uniqueId: string
): IAction<IInstitution[]> {
  return {
    payload: [{ uniqueId }],
    type: PRIVATE_INSTITUTION_TYPES.DELETE,
  };
}

// public institutions
export const PUBLIC_INSTITUTION_TYPES: IActionTypes = {
  CREATE: "PUBLIC_INSTITUTION_CREATE",
  DELETE: "PUBLIC_INSTITUTION_DELETE",
  FETCH: "PUBLIC_INSTITUTION_FETCH",
  FETCH_ALL: "PUBLIC_INSTITUTION_FETCH_ALL",
  UPDATE: "PUBLIC_INSTITUTION_UPDATE",
};
// public institutions action creators
export function fetchPublicInstitutions(
  publicInstitutions: IInstitution[]
): IAction<IInstitution[]> {
  return {
    payload: publicInstitutions,
    type: PUBLIC_INSTITUTION_TYPES.FETCH_ALL,
  };
}
export function fetchPublicInstitution(
  publicInstitution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [publicInstitution],
    type: PUBLIC_INSTITUTION_TYPES.FETCH,
  };
}
export function createPublicInstitution(
  publicInstitution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [publicInstitution],
    type: PUBLIC_INSTITUTION_TYPES.CREATE,
  };
}
export function updatePublicInstitution(
  publicInstitution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [publicInstitution],
    type: PUBLIC_INSTITUTION_TYPES.UPDATE,
  };
}
export function deletePublicInstitution(
  uniqueId: string
): IAction<IInstitution[]> {
  return {
    payload: [{ uniqueId }],
    type: PUBLIC_INSTITUTION_TYPES.DELETE,
  };
}

// financial institutions
export const FINANCIAL_INSTITUTION_TYPES: IActionTypes = {
  CREATE: "FINANCIAL_INSTITUTION_CREATE",
  DELETE: "FINANCIAL_INSTITUTION_DELETE",
  FETCH: "FINANCIAL_INSTITUTION_FETCH",
  FETCH_ALL: "FINANCIAL_INSTITUTION_FETCH_ALL",
  UPDATE: "FINANCIAL_INSTITUTION_UPDATE",
};
// financial institutions action creators
export function fetchFinancialInstitutions(
  financialInstitutions: IInstitution[]
): IAction<IInstitution[]> {
  return {
    payload: financialInstitutions,
    type: FINANCIAL_INSTITUTION_TYPES.FETCH_ALL,
  };
}
export function fetchFinancialInstitution(
  financialInstitution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [financialInstitution],
    type: FINANCIAL_INSTITUTION_TYPES.FETCH,
  };
}
export function createFinancialInstitution(
  financialInstitution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [financialInstitution],
    type: FINANCIAL_INSTITUTION_TYPES.CREATE,
  };
}
export function updateFinancialInstitution(
  financialInstitution: IInstitution
): IAction<IInstitution[]> {
  return {
    payload: [financialInstitution],
    type: FINANCIAL_INSTITUTION_TYPES.UPDATE,
  };
}
export function deleteFinancialInstitution(
  uniqueId: string
): IAction<IInstitution[]> {
  return {
    payload: [{ uniqueId }],
    type: FINANCIAL_INSTITUTION_TYPES.DELETE,
  };
}
// deposit bank accounts
export const DEPOSIT_BANK_ACCOUNT_TYPES: IActionTypes = {
  CREATE: "DEPOSIT_BANK_ACCOUNT_CREATE",
  DELETE: "DEPOSIT_BANK_ACCOUNT_DELETE",
  FETCH: "DEPOSIT_BANK_ACCOUNT_FETCH",
  FETCH_ALL: "DEPOSIT_BANK_ACCOUNT_FETCH_ALL",
  UPDATE: "DEPOSIT_BANK_ACCOUNT_UPDATE",
};
// client action creators
export function fetchDepositBankAccounts(
  depositBankAccounts: IDepositBankAccount[]
): IAction<IDepositBankAccount[]> {
  return {
    payload: depositBankAccounts,
    type: DEPOSIT_BANK_ACCOUNT_TYPES.FETCH_ALL,
  };
}
export function fetchDepositBankAccount(
  depositBankAccount: IDepositBankAccount
): IAction<IDepositBankAccount[]> {
  return {
    payload: [depositBankAccount],
    type: DEPOSIT_BANK_ACCOUNT_TYPES.FETCH,
  };
}
export function createDepositBankAccount(
  depositBankAccount: IDepositBankAccount
): IAction<IDepositBankAccount[]> {
  return {
    payload: [depositBankAccount],
    type: DEPOSIT_BANK_ACCOUNT_TYPES.CREATE,
  };
}
export function updateDepositBankAccount(
  depositBankAccount: IDepositBankAccount
): IAction<IDepositBankAccount[]> {
  return {
    payload: [depositBankAccount],
    type: DEPOSIT_BANK_ACCOUNT_TYPES.UPDATE,
  };
}
export function deleteDepositBankAccount(
  uniqueId: string
): IAction<IDepositBankAccount[]> {
  return {
    payload: [{ uniqueId }],
    type: DEPOSIT_BANK_ACCOUNT_TYPES.DELETE,
  };
}
// deposit bank accounts
export const FILE_ATTACHMENT_TYPES: IActionTypes = {
  CREATE: "FILE_ATTACHMENT_CREATE",
  DELETE: "FILE_ATTACHMENT_DELETE",
  FETCH: "FILE_ATTACHMENT_FETCH",
  FETCH_ALL: "FILE_ATTACHMENT_FETCH_ALL",
  UPDATE: "FILE_ATTACHMENT_UPDATE",
};
// client action creators
export function fetchFileAttachments(
  fileAttachments: IFileAttachment[]
): IAction<IFileAttachment[]> {
  return {
    payload: fileAttachments,
    type: FILE_ATTACHMENT_TYPES.FETCH_ALL,
  };
}
export function fetchFileAttachment(
  fileAttachment: IFileAttachment
): IAction<IFileAttachment[]> {
  return {
    payload: [fileAttachment],
    type: FILE_ATTACHMENT_TYPES.FETCH,
  };
}
export function deleteFileAttachment(
  uniqueId: string
): IAction<IFileAttachment[]> {
  return {
    payload: [{ uniqueId }],
    type: FILE_ATTACHMENT_TYPES.DELETE,
  };
}

// bank accounts
export const BANK_ACCOUNT_TYPES: IActionTypes = {
  CREATE: "BANK_ACCOUNT_CREATE",
  DELETE: "BANK_ACCOUNT_DELETE",
  FETCH: "BANK_ACCOUNT_FETCH",
  FETCH_ALL: "BANK_ACCOUNT_FETCH_ALL",
  UPDATE: "BANK_ACCOUNT_UPDATE",
};

// bank account action creators
export function fetchBankAccounts(
  bankAccounts: IBankAccount[]
): IAction<IBankAccount[]> {
  return {
    payload: bankAccounts,
    type: BANK_ACCOUNT_TYPES.FETCH_ALL,
  };
}
export function fetchBankAccount(
  bankAccount: IBankAccount
): IAction<IBankAccount[]> {
  return {
    payload: [bankAccount],
    type: BANK_ACCOUNT_TYPES.FETCH,
  };
}
export function createBankAccount(
  bankAccount: IBankAccount
): IAction<IBankAccount[]> {
  return {
    payload: [bankAccount],
    type: BANK_ACCOUNT_TYPES.CREATE,
  };
}
export function updateBankAccount(
  bankAccount: IBankAccount
): IAction<IBankAccount[]> {
  return {
    payload: [bankAccount],
    type: BANK_ACCOUNT_TYPES.UPDATE,
  };
}
export function deleteBankAccount(uniqueId: string): IAction<IBankAccount[]> {
  return {
    payload: [{ uniqueId }],
    type: BANK_ACCOUNT_TYPES.DELETE,
  };
}

// loan contract
export const LOAN_CONTRACT_TYPES: IActionTypes = {
  CREATE: "LOAN_CONTRACT_CREATE",
  DELETE: "LOAN_CONTRACT_DELETE",
  FETCH: "LOAN_CONTRACT_FETCH",
  FETCH_ALL: "LOAN_CONTRACT_FETCH_ALL",
  UPDATE: "LOAN_CONTRACT_UPDATE",
};
// loan contract Action Creators
export function fetchLoanContracts(
  loanContracts: ILoanContract[]
): IAction<ILoanContract[]> {
  return {
    payload: loanContracts,
    type: LOAN_CONTRACT_TYPES.FETCH_ALL,
  };
}
export function fetchLoanContract(
  loanContract: ILoanContract
): IAction<ILoanContract[]> {
  return {
    payload: [loanContract],
    type: LOAN_CONTRACT_TYPES.FETCH,
  };
}
export function createLoanContract(
  loanContract: ILoanContract
): IAction<ILoanContract[]> {
  return {
    payload: [loanContract],
    type: LOAN_CONTRACT_TYPES.CREATE,
  };
}
export function updateLoanContract(
  loanContract: ILoanContract
): IAction<ILoanContract[]> {
  return {
    payload: [loanContract],
    type: LOAN_CONTRACT_TYPES.UPDATE,
  };
}
export function deleteLoanContract(uniqueId: string): IAction<ILoanContract[]> {
  return {
    payload: [{ uniqueId }],
    type: LOAN_CONTRACT_TYPES.DELETE,
  };
}

// nationalities
export const NATIONALITY_TYPES: IActionTypes = {
  CREATE: "NATIONALITY_CREATE",
  DELETE: "NATIONALITY_DELETE",
  FETCH: "NATIONALITY_FETCH",
  FETCH_ALL: "NATIONALITY_FETCH_ALL",
  UPDATE: "NATIONALITY_UPDATE",
};
// nationality Action Creators
export function fetchNationalities(
  nationalities: INationality[]
): IAction<INationality[]> {
  return {
    payload: nationalities,
    type: NATIONALITY_TYPES.FETCH_ALL,
  };
}
export function fetchNationality(
  nationality: INationality
): IAction<INationality[]> {
  return {
    payload: [nationality],
    type: NATIONALITY_TYPES.FETCH,
  };
}
export function createNationality(
  nationality: INationality
): IAction<INationality[]> {
  return {
    payload: [nationality],
    type: NATIONALITY_TYPES.CREATE,
  };
}
export function updateNationality(
  nationality: INationality
): IAction<INationality[]> {
  return {
    payload: [nationality],
    type: NATIONALITY_TYPES.UPDATE,
  };
}
export function deleteNationality(uniqueId: string): IAction<INationality[]> {
  return {
    payload: [{ uniqueId }],
    type: NATIONALITY_TYPES.DELETE,
  };
}

// noratial pay contract
export const NOTARIAL_PAY_CONTRACT_TYPES: IActionTypes = {
  CREATE: "NOTARIAL_PAY_CONTRACT_CREATE",
  DELETE: "NOTARIAL_PAY_CONTRACT_DELETE",
  FETCH: "NOTARIAL_PAY_CONTRACT_FETCH",
  FETCH_ALL: "NOTARIAL_PAY_CONTRACT_FETCH_ALL",
  UPDATE: "NOTARIAL_PAY_CONTRACT_UPDATE",
};
// noratial pay contract Action Creators
export function fetchNotarialPayContracts(
  notarialPayContracts: INotarialPayContract[]
): IAction<INotarialPayContract[]> {
  return {
    payload: notarialPayContracts,
    type: NOTARIAL_PAY_CONTRACT_TYPES.FETCH_ALL,
  };
}
export function fetchNotarialPayContract(
  notarialPayContract: INotarialPayContract
): IAction<INotarialPayContract[]> {
  return {
    payload: [notarialPayContract],
    type: NOTARIAL_PAY_CONTRACT_TYPES.FETCH,
  };
}
export function createNotarialPayContract(
  notarialPayContract: INotarialPayContract
): IAction<INotarialPayContract[]> {
  return {
    payload: [notarialPayContract],
    type: NOTARIAL_PAY_CONTRACT_TYPES.CREATE,
  };
}
export function updateNotarialPayContract(
  notarialPayContract: INotarialPayContract
): IAction<INotarialPayContract[]> {
  return {
    payload: [notarialPayContract],
    type: NOTARIAL_PAY_CONTRACT_TYPES.UPDATE,
  };
}
export function deleteNotarialPayContract(
  uniqueId: string
): IAction<INotarialPayContract[]> {
  return {
    payload: [{ uniqueId }],
    type: NOTARIAL_PAY_CONTRACT_TYPES.DELETE,
  };
}

// creditCard
export const CREDIT_CARD_TYPES: IActionTypes = {
  CREATE: "CREDIT_CARD_CREATE",
  DELETE: "CREDIT_CARD_DELETE",
  FETCH: "CREDIT_CARD_FETCH",
  FETCH_ALL: "CREDIT_CARD_FETCH_ALL",
  UPDATE: "CREDIT_CARD_UPDATE",
};
// credit card action creators
export function fetchCreditCards(
  creditCards: ICreditCard[]
): IAction<ICreditCard[]> {
  return {
    payload: creditCards,
    type: CREDIT_CARD_TYPES.FETCH_ALL,
  };
}
export function fetchCreditCard(
  creditCard: ICreditCard
): IAction<ICreditCard[]> {
  return {
    payload: [creditCard],
    type: CREDIT_CARD_TYPES.FETCH,
  };
}
export function createCreditCard(
  creditCard: ICreditCard
): IAction<ICreditCard[]> {
  return {
    payload: [creditCard],
    type: CREDIT_CARD_TYPES.CREATE,
  };
}
export function updateCreditCard(
  creditCard: ICreditCard
): IAction<ICreditCard[]> {
  return {
    payload: [creditCard],
    type: CREDIT_CARD_TYPES.UPDATE,
  };
}
export function deleteCreditCard(uniqueId: string): IAction<ICreditCard[]> {
  return {
    payload: [{ uniqueId }],
    type: CREDIT_CARD_TYPES.DELETE,
  };
}

// flier
export const FLIER_TYPES: IActionTypes = {
  CREATE: "FLIER_CREATE",
  DELETE: "FLIER_DELETE",
  FETCH: "FLIER_FETCH",
  FETCH_ALL: "FLIER_FETCH_ALL",
  UPDATE: "FLIER_UPDATE",
};
// flier action creators
export function fetchFliers(fliers: IFlier[]): IAction<IFlier[]> {
  return {
    payload: fliers,
    type: FLIER_TYPES.FETCH_ALL,
  };
}
export function fetchFlier(flier: IFlier): IAction<IFlier[]> {
  return {
    payload: [flier],
    type: FLIER_TYPES.FETCH,
  };
}
export function createFlier(flier: IFlier): IAction<IFlier[]> {
  return {
    payload: [flier],
    type: FLIER_TYPES.CREATE,
  };
}
export function updateFlier(flier: IFlier): IAction<IFlier[]> {
  return {
    payload: [flier],
    type: FLIER_TYPES.UPDATE,
  };
}
export function deleteFlier(uniqueId: string): IAction<IFlier[]> {
  return {
    payload: [{ uniqueId }],
    type: FLIER_TYPES.DELETE,
  };
}

// check
export const CHECK_TYPES: IActionTypes = {
  CREATE: "CHECK_CREATE",
  DELETE: "CHECK_DELETE",
  FETCH: "CHECK_FETCH",
  FETCH_ALL: "CHECK_FETCH_ALL",
  UPDATE: "CHECK_UPDATE",
};
// check action creators
export function fetchChecks(checks: ICheck[]): IAction<ICheck[]> {
  return {
    payload: checks,
    type: CHECK_TYPES.FETCH_ALL,
  };
}
export function fetchCheck(check: ICheck): IAction<ICheck[]> {
  return {
    payload: [check],
    type: CHECK_TYPES.FETCH,
  };
}
export function createCheck(check: ICheck): IAction<ICheck[]> {
  return {
    payload: [check],
    type: CHECK_TYPES.CREATE,
  };
}
export function updateCheck(check: ICheck): IAction<ICheck[]> {
  return {
    payload: [check],
    type: CHECK_TYPES.UPDATE,
  };
}
export function deleteCheck(uniqueId: string): IAction<ICheck[]> {
  return {
    payload: [{ uniqueId }],
    type: CHECK_TYPES.DELETE,
  };
}

// check
export const OUTLAY_CHECK_TYPES: IActionTypes = {
  CREATE: "OUTLAY_CHECK_CREATE",
  DELETE: "OUTLAY_CHECK_DELETE",
  FETCH: "OUTLAY_CHECK_FETCH",
  FETCH_ALL: "OUTLAY_CHECK_FETCH_ALL",
  UPDATE: "OUTLAY_CHECK_UPDATE",
};
// outlay checks
export function fetchOutlayChecks(
  checks: IOutlayCheck[]
): IAction<IOutlayCheck[]> {
  return {
    payload: checks,
    type: OUTLAY_CHECK_TYPES.FETCH_ALL,
  };
}
export function fetchOutlayCheck(check: IOutlayCheck): IAction<IOutlayCheck[]> {
  return {
    payload: [check],
    type: OUTLAY_CHECK_TYPES.FETCH,
  };
}
export function createOutlayCheck(
  check: IOutlayCheck
): IAction<IOutlayCheck[]> {
  return {
    payload: [check],
    type: OUTLAY_CHECK_TYPES.CREATE,
  };
}
export function updateOutlayCheck(
  check: IOutlayCheck
): IAction<IOutlayCheck[]> {
  return {
    payload: [check],
    type: OUTLAY_CHECK_TYPES.UPDATE,
  };
}
export function deleteOutlayCheck(uniqueId: string): IAction<IOutlayCheck[]> {
  return {
    payload: [{ uniqueId }],
    type: OUTLAY_CHECK_TYPES.DELETE,
  };
}

// check
export const OUTLAY_CASH_TYPES: IActionTypes = {
  CREATE: "OUTLAY_CASH_CREATE",
  DELETE: "OUTLAY_CASH_DELETE",
  FETCH: "OUTLAY_CASH_FETCH",
  FETCH_ALL: "OUTLAY_CASH_FETCH_ALL",
  UPDATE: "OUTLAY_CASH_UPDATE",
};
// outlay cash
export function fetchOutlayCashes(
  cashes: IOutlayCash[]
): IAction<IOutlayCash[]> {
  return {
    payload: cashes,
    type: OUTLAY_CASH_TYPES.FETCH_ALL,
  };
}
export function fetchOutlayCash(cash: IOutlayCash): IAction<IOutlayCash[]> {
  return {
    payload: [cash],
    type: OUTLAY_CASH_TYPES.FETCH,
  };
}
export function createOutlayCash(cash: IOutlayCash): IAction<IOutlayCash[]> {
  return {
    payload: [cash],
    type: OUTLAY_CASH_TYPES.CREATE,
  };
}
export function updateOutlayCash(cash: IOutlayCash): IAction<IOutlayCash[]> {
  return {
    payload: [cash],
    type: OUTLAY_CASH_TYPES.UPDATE,
  };
}
export function deleteOutlayCash(uniqueId: string): IAction<IOutlayCash[]> {
  return {
    payload: [{ uniqueId }],
    type: OUTLAY_CASH_TYPES.DELETE,
  };
}
// outlay
export const OUTLAY_TYPES: IActionTypes = {
  CREATE: "OUTLAY_CREATE",
  DELETE: "OUTLAY_DELETE",
  FETCH: "OUTLAY_FETCH",
  FETCH_ALL: "OUTLAY_FETCH_ALL",
  UPDATE: "OUTLAY_UPDATE",
};
export function fetchOutlays(outlays: IOutlay[]): IAction<IOutlay[]> {
  return {
    payload: outlays,
    type: OUTLAY_TYPES.FETCH_ALL,
  };
}
export function fetchOutlay(outlay: IOutlay): IAction<IOutlay[]> {
  return {
    payload: [outlay],
    type: OUTLAY_TYPES.FETCH,
  };
}
export function createOutlay(outlay: IOutlay): IAction<IOutlay[]> {
  return {
    payload: [outlay],
    type: OUTLAY_TYPES.CREATE,
  };
}
export function updateOutlay(outlay: IOutlay): IAction<IOutlay[]> {
  return {
    payload: [outlay],
    type: OUTLAY_TYPES.UPDATE,
  };
}
export function deleteOutlay(uniqueId: string): IAction<IOutlay[]> {
  return {
    payload: [{ uniqueId }],
    type: OUTLAY_TYPES.DELETE,
  };
}

// Addendum
export const ADDENDUM_CONTRACTS_TYPES: IActionTypes = {
  CREATE: "ADDENDUM_CONTRACTS_CREATE",
  DELETE: "ADDENDUM_CONTRACTS_DELETE",
  FETCH: "ADDENDUM_CONTRACTS_FETCH",
  FETCH_ALL: "ADDENDUM_CONTRACTS_FETCH_ALL",
  UPDATE: "ADDENDUM_CONTRACTS_UPDATE",
};
// action creators
export function fetchAddendumContracts(
  addendumContractS: IAddendumContract[]
): IAction<IAddendumContract[]> {
  return {
    payload: addendumContractS,
    type: ADDENDUM_CONTRACTS_TYPES.FETCH_ALL,
  };
}
export function fetchAddendumContract(
  addendumContract: IAddendumContract
): IAction<IAddendumContract[]> {
  return {
    payload: [addendumContract],
    type: ADDENDUM_CONTRACTS_TYPES.FETCH,
  };
}
export function createAddendumContract(
  addendumContract: IAddendumContract
): IAction<IAddendumContract[]> {
  return {
    payload: [addendumContract],
    type: ADDENDUM_CONTRACTS_TYPES.CREATE,
  };
}
export function updateAddendumContract(
  addendumContract: IAddendumContract
): IAction<IAddendumContract[]> {
  return {
    payload: [addendumContract],
    type: ADDENDUM_CONTRACTS_TYPES.UPDATE,
  };
}
export function deleteAddendumContract(
  uniqueId: string
): IAction<IAddendumContract[]> {
  return {
    payload: [{ uniqueId }],
    type: ADDENDUM_CONTRACTS_TYPES.DELETE,
  };
}

// DebitAuthorizationContract
export const DEBIT_AUTHORIZATION_CONTRACTS_TYPES: IActionTypes = {
  CREATE: "DEBIT_AUTHORIZATION_CONTRACTS_CREATE",
  DELETE: "DEBIT_AUTHORIZATION_CONTRACTS_DELETE",
  FETCH: "DEBIT_AUTHORIZATION_CONTRACTS_FETCH",
  FETCH_ALL: "DEBIT_AUTHORIZATION_CONTRACTS_FETCH_ALL",
  UPDATE: "DEBIT_AUTHORIZATION_CONTRACTS_UPDATE",
};
// action creators
export function fetchDebitAuthorizationContracts(
  debitAuthorizationContracts: IDebitAuthorizationContract[]
): IAction<IDebitAuthorizationContract[]> {
  return {
    payload: debitAuthorizationContracts,
    type: DEBIT_AUTHORIZATION_CONTRACTS_TYPES.FETCH_ALL,
  };
}
export function fetchDebitAuthorizationContract(
  debitAuthorizationContract: IDebitAuthorizationContract
): IAction<IDebitAuthorizationContract[]> {
  return {
    payload: [debitAuthorizationContract],
    type: DEBIT_AUTHORIZATION_CONTRACTS_TYPES.FETCH,
  };
}
export function createDebitAuthorizationContract(
  debitAuthorizationContract: IDebitAuthorizationContract
): IAction<IDebitAuthorizationContract[]> {
  return {
    payload: [debitAuthorizationContract],
    type: DEBIT_AUTHORIZATION_CONTRACTS_TYPES.CREATE,
  };
}
export function updateDebitAuthorizationContract(
  debitAuthorizationContract: IDebitAuthorizationContract
): IAction<IDebitAuthorizationContract[]> {
  return {
    payload: [debitAuthorizationContract],
    type: DEBIT_AUTHORIZATION_CONTRACTS_TYPES.UPDATE,
  };
}
export function deleteDebitAuthorizationContract(
  uniqueId: string
): IAction<IDebitAuthorizationContract[]> {
  return {
    payload: [{ uniqueId }],
    type: DEBIT_AUTHORIZATION_CONTRACTS_TYPES.DELETE,
  };
}

// AutomaticDebitAuthorizationContract
export const AUTOMATIC_DEBIT_AUTHORIZATION_CONTRACTS_TYPES: IActionTypes = {
  CREATE: "AUTOMATIC_DEBIT_AUTHORIZATION_CONTRACTS_CREATE",
  DELETE: "AUTOMATIC_DEBIT_AUTHORIZATION_CONTRACTS_DELETE",
  FETCH: "AUTOMATIC_DEBIT_AUTHORIZATION_CONTRACTS_FETCH",
  FETCH_ALL: "AUTOMATIC_DEBIT_AUTHORIZATION_CONTRACTS_FETCH_ALL",
  UPDATE: "AUTOMATIC_DEBIT_AUTHORIZATION_CONTRACTS_UPDATE",
};
// action creators
export function fetchAutomaticDebitAuthorizationContracts(
  automaticDebitAuthorizationContracts: IAutomaticDebitAuthorizationContract[]
): IAction<IAutomaticDebitAuthorizationContract[]> {
  return {
    payload: automaticDebitAuthorizationContracts,
    type: AUTOMATIC_DEBIT_AUTHORIZATION_CONTRACTS_TYPES.FETCH_ALL,
  };
}
export function fetchAutomaticDebitAuthorizationContract(
  automaticDebitAuthorizationContract: IAutomaticDebitAuthorizationContract
): IAction<IAutomaticDebitAuthorizationContract[]> {
  return {
    payload: [automaticDebitAuthorizationContract],
    type: AUTOMATIC_DEBIT_AUTHORIZATION_CONTRACTS_TYPES.FETCH,
  };
}
export function createAutomaticDebitAuthorizationContract(
  automaticDebitAuthorizationContract: IAutomaticDebitAuthorizationContract
): IAction<IAutomaticDebitAuthorizationContract[]> {
  return {
    payload: [automaticDebitAuthorizationContract],
    type: AUTOMATIC_DEBIT_AUTHORIZATION_CONTRACTS_TYPES.CREATE,
  };
}
export function updateAutomaticDebitAuthorizationContract(
  automaticDebitAuthorizationContract: IAutomaticDebitAuthorizationContract
): IAction<IAutomaticDebitAuthorizationContract[]> {
  return {
    payload: [automaticDebitAuthorizationContract],
    type: AUTOMATIC_DEBIT_AUTHORIZATION_CONTRACTS_TYPES.UPDATE,
  };
}
export function deleteAutomaticDebitAuthorizationContract(
  uniqueId: string
): IAction<IAutomaticDebitAuthorizationContract[]> {
  return {
    payload: [{ uniqueId }],
    type: AUTOMATIC_DEBIT_AUTHORIZATION_CONTRACTS_TYPES.DELETE,
  };
}

// AcquaintanceshipCompanyContract
export const ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES: IActionTypes = {
  CREATE: "ACQUAINTANCESHIP_COMPANY_CONTRACT_CREATE",
  DELETE: "ACQUAINTANCESHIP_COMPANY_CONTRACT_DELETE",
  FETCH: "ACQUAINTANCESHIP_COMPANY_CONTRACT_FETCH",
  FETCH_ALL: "ACQUAINTANCESHIP_COMPANY_CONTRACT_FETCH_ALL",
  UPDATE: "ACQUAINTANCESHIP_COMPANY_CONTRACT_UPDATE",
};
// action creators
export function fetchAcquaintanceshipCompanyContracts(
  acquaintanceshipCompanyContracts: IAcquaintanceshipCompanyContract[]
): IAction<IAcquaintanceshipCompanyContract[]> {
  return {
    payload: acquaintanceshipCompanyContracts,
    type: ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES.FETCH_ALL,
  };
}
export function fetchAcquaintanceshipCompanyContract(
  acquaintanceshipCompanyContract: IAcquaintanceshipCompanyContract
): IAction<IAcquaintanceshipCompanyContract[]> {
  return {
    payload: [acquaintanceshipCompanyContract],
    type: ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES.FETCH,
  };
}
export function createAcquaintanceshipCompanyContract(
  acquaintanceshipCompanyContract: IAcquaintanceshipCompanyContract
): IAction<IAcquaintanceshipCompanyContract[]> {
  return {
    payload: [acquaintanceshipCompanyContract],
    type: ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES.CREATE,
  };
}
export function updateAcquaintanceshipCompanyContract(
  acquaintanceshipCompanyContract: IAcquaintanceshipCompanyContract
): IAction<IAcquaintanceshipCompanyContract[]> {
  return {
    payload: [acquaintanceshipCompanyContract],
    type: ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES.UPDATE,
  };
}
export function deleteAcquaintanceshipCompanyContract(
  uniqueId: string
): IAction<IAcquaintanceshipCompanyContract[]> {
  return {
    payload: [{ uniqueId }],
    type: ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES.DELETE,
  };
}

// AcquaintanceshipPersonContract
export const ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES: IActionTypes = {
  CREATE: "ACQUAINTANCESHIP_PERSON_CONTRACT_CREATE",
  DELETE: "ACQUAINTANCESHIP_PERSON_CONTRACT_DELETE",
  FETCH: "ACQUAINTANCESHIP_PERSON_CONTRACT_FETCH",
  FETCH_ALL: "ACQUAINTANCESHIP_PERSON_CONTRACT_FETCH_ALL",
  UPDATE: "ACQUAINTANCESHIP_PERSON_CONTRACT_UPDATE",
};
// action creators
export function fetchAcquaintanceshipPersonContracts(
  acquaintanceshipPersonContracts: IAcquaintanceshipPersonContract[]
): IAction<IAcquaintanceshipPersonContract[]> {
  return {
    payload: acquaintanceshipPersonContracts,
    type: ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES.FETCH_ALL,
  };
}
export function fetchAcquaintanceshipPersonContract(
  acquaintanceshipPersonContract: IAcquaintanceshipPersonContract
): IAction<IAcquaintanceshipPersonContract[]> {
  return {
    payload: [acquaintanceshipPersonContract],
    type: ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES.FETCH,
  };
}
export function createAcquaintanceshipPersonContract(
  acquaintanceshipPersonContract: IAcquaintanceshipPersonContract
): IAction<IAcquaintanceshipPersonContract[]> {
  return {
    payload: [acquaintanceshipPersonContract],
    type: ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES.CREATE,
  };
}
export function updateAcquaintanceshipPersonContract(
  acquaintanceshipPersonContract: IAcquaintanceshipPersonContract
): IAction<IAcquaintanceshipPersonContract[]> {
  return {
    payload: [acquaintanceshipPersonContract],
    type: ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES.UPDATE,
  };
}
export function deleteAcquaintanceshipPersonContract(
  uniqueId: string
): IAction<IAcquaintanceshipPersonContract[]> {
  return {
    payload: [{ uniqueId }],
    type: ACQUAINTANCESHIP_PERSON_CONTRACT_TYPES.DELETE,
  };
}

// Ticket types
export const TICKET_TYPES: IActionTypes = {
  CREATE: "TICKET_CREATE",
  DELETE: "TICKET_DELETE",
  FETCH: "TICKET_FETCH",
  FETCH_ALL: "TICKET_FETCH_ALL",
  UPDATE: "TICKET_UPDATE",
};

// Ticket Action Creators
export const fetchTickets = (tickets: ITicket[]) => {
  return {
    payload: tickets,
    type: TICKET_TYPES.FETCH_ALL,
  };
};

export const fetchTicket = (ticket: ITicket): IAction<ITicket[]> => {
  return {
    payload: [ticket],
    type: TICKET_TYPES.FETCH,
  };
};
export const createTicket = (ticket: ITicket): IAction<ITicket[]> => {
  return {
    payload: [ticket],
    type: TICKET_TYPES.CREATE,
  };
};
export const updateTicket = (ticket: ITicket): IAction<ITicket[]> => {
  return {
    payload: [ticket],
    type: TICKET_TYPES.UPDATE,
  };
};
export const deleteTicket = (uniqueId: string): IAction<ITicket[]> => {
  return {
    payload: [{ uniqueId }],
    type: TICKET_TYPES.DELETE,
  };
};

// Quotation types
export const QUOTATION_TYPES: IActionTypes = {
  CREATE: "QUOTATION_CREATE",
  DELETE: "QUOTATION_DELETE",
  FETCH: "QUOTATION_FETCH",
  FETCH_ALL: "QUOTATION_FETCH_ALL",
  UPDATE: "QUOTATION_UPDATE",
};

// Quotation Actions
export const fetchQuotations = (quotations: IQuotation[]) => {
  return {
    payload: quotations,
    type: QUOTATION_TYPES.FETCH_ALL,
  };
};

export const fetchQuotation = (
  quotation: IQuotation
): IAction<IQuotation[]> => {
  return {
    payload: [quotation],
    type: QUOTATION_TYPES.FETCH,
  };
};
export const createQuotation = (
  quotation: IQuotation
): IAction<IQuotation[]> => {
  return {
    payload: [quotation],
    type: QUOTATION_TYPES.CREATE,
  };
};
export const updateQuotation = (
  quotation: IQuotation
): IAction<IQuotation[]> => {
  return {
    payload: [quotation],
    type: QUOTATION_TYPES.UPDATE,
  };
};
export const deleteQuotation = (uniqueId: string): IAction<IQuotation[]> => {
  return {
    payload: [{ uniqueId }],
    type: QUOTATION_TYPES.DELETE,
  };
};

// Blacktlist types
export const BLACKLIST_TYPES: IActionTypes = {
  CREATE: "BLACKLIST_CREATE",
  DELETE: "BLACKLIST_DELETE",
  FETCH: "BLACKLIST_FETCH",
  FETCH_ALL: "BLACKLIST_FETCH_ALL",
  UPDATE: "BLACKLIST_UPDATE",
};

// Blacktlist Actions
export const fetchBlacklists = (blacklists: IBlacklist[]) => {
  return {
    payload: blacklists,
    type: BLACKLIST_TYPES.FETCH_ALL,
  };
};

export const fetchBlacklist = (
  blacklist: IBlacklist
): IAction<IBlacklist[]> => {
  return {
    payload: [blacklist],
    type: BLACKLIST_TYPES.FETCH,
  };
};
export const createBlacklist = (
  blacklist: IBlacklist
): IAction<IBlacklist[]> => {
  return {
    payload: [blacklist],
    type: BLACKLIST_TYPES.CREATE,
  };
};
export const updateBlacklist = (
  blacklist: IBlacklist
): IAction<IBlacklist[]> => {
  return {
    payload: [blacklist],
    type: BLACKLIST_TYPES.UPDATE,
  };
};
export const deleteBlacklist = (uniqueId: string): IAction<IBlacklist[]> => {
  return {
    payload: [{ uniqueId }],
    type: BLACKLIST_TYPES.DELETE,
  };
};
// Blacktlist types
export const DEBT_CAPACITY_TYPE: IActionTypes = {
  CREATE: "DEBT_CAPACITY_CREATE",
  DELETE: "DEBT_CAPACITY_DELETE",
  FETCH: "DEBT_CAPACITY_FETCH",
  FETCH_ALL: "DEBT_CAPACITY_FETCH_ALL",
  UPDATE: "DEBT_CAPACITY_UPDATE",
};

// Blacktlist Actions
export const fetchDebtCapacities = (debtCapacities: IDebtCapacity[]) => {
  return {
    payload: debtCapacities,
    type: DEBT_CAPACITY_TYPE.FETCH_ALL,
  };
};

export const fetchDebtCapacity = (
  debtCapacity: IDebtCapacity
): IAction<IDebtCapacity[]> => {
  return {
    payload: [debtCapacity],
    type: DEBT_CAPACITY_TYPE.FETCH,
  };
};
export const createDebtCapacity = (
  debtCapacity: IDebtCapacity
): IAction<IDebtCapacity[]> => {
  return {
    payload: [debtCapacity],
    type: DEBT_CAPACITY_TYPE.CREATE,
  };
};
export const updateDebtCapacity = (
  debtCapacity: IDebtCapacity
): IAction<IDebtCapacity[]> => {
  return {
    payload: [debtCapacity],
    type: DEBT_CAPACITY_TYPE.UPDATE,
  };
};
export const deleteDebtCapacity = (
  uniqueId: string
): IAction<IDebtCapacity[]> => {
  return {
    payload: [{ uniqueId }],
    type: DEBT_CAPACITY_TYPE.DELETE,
  };
};
// Blacktlist types
export const PAYMENT_CAPACITY_TYPES: IActionTypes = {
  CREATE: "PAYMENT_CAPACITY_CREATE",
  DELETE: "PAYMENT_CAPACITY_DELETE",
  FETCH: "PAYMENT_CAPACITY_FETCH",
  FETCH_ALL: "PAYMENT_CAPACITY_FETCH_ALL",
  UPDATE: "PAYMENT_CAPACITY_UPDATE",
};

// Blacktlist Actions
export const fetchPaymentCapacities = (
  paymentCapacities: IPaymentCapacity[]
) => {
  return {
    payload: paymentCapacities,
    type: PAYMENT_CAPACITY_TYPES.FETCH_ALL,
  };
};

export const fetchPaymentCapacity = (
  paymentCapacity: IPaymentCapacity
): IAction<IPaymentCapacity[]> => {
  return {
    payload: [paymentCapacity],
    type: PAYMENT_CAPACITY_TYPES.FETCH,
  };
};
export const createPaymentCapacity = (
  paymentCapacity: IPaymentCapacity
): IAction<IPaymentCapacity[]> => {
  return {
    payload: [paymentCapacity],
    type: PAYMENT_CAPACITY_TYPES.CREATE,
  };
};
export const updatePaymentCapacity = (
  paymentCapacity: IPaymentCapacity
): IAction<IPaymentCapacity[]> => {
  return {
    payload: [paymentCapacity],
    type: PAYMENT_CAPACITY_TYPES.UPDATE,
  };
};
export const deletePaymentCapacity = (
  uniqueId: string
): IAction<IPaymentCapacity[]> => {
  return {
    payload: [{ uniqueId }],
    type: PAYMENT_CAPACITY_TYPES.DELETE,
  };
};

// AccountingRecords types
export const ACCOUNTING_RECORD_TYPES: IActionTypes = {
  CREATE: "ACCOUNTING_RECORD_CREATE",
  DELETE: "ACCOUNTING_RECORD_DELETE",
  FETCH: "ACCOUNTING_RECORD_FETCH",
  FETCH_ALL: "ACCOUNTING_RECORD_FETCH_ALL",
  UPDATE: "ACCOUNTING_RECORD_UPDATE",
};

// Accounting Records Actions
export const fetchAccountingRecords = (
  accountingRecords: IAccountingRecord[]
) => {
  return {
    payload: accountingRecords,
    type: ACCOUNTING_RECORD_TYPES.FETCH_ALL,
  };
};

export const fetchAccountingRecord = (
  accountingRecord: IAccountingRecord
): IAction<IAccountingRecord[]> => {
  return {
    payload: [accountingRecord],
    type: ACCOUNTING_RECORD_TYPES.FETCH,
  };
};
export const createAccountingRecord = (
  accountingRecord: IAccountingRecord
): IAction<IAccountingRecord[]> => {
  return {
    payload: [accountingRecord],
    type: ACCOUNTING_RECORD_TYPES.CREATE,
  };
};
export const updateAccountingRecord = (
  accountingRecord: IAccountingRecord
): IAction<IAccountingRecord[]> => {
  return {
    payload: [accountingRecord],
    type: ACCOUNTING_RECORD_TYPES.UPDATE,
  };
};
export const deleteAccountingRecord = (
  uniqueId: string
): IAction<IAccountingRecord[]> => {
  return {
    payload: [{ uniqueId }],
    type: ACCOUNTING_RECORD_TYPES.DELETE,
  };
};

// AccountingRecords types
export const CHARGING_METHOD_TYPES: IActionTypes = {
  CREATE: "CHARGING_METHOD_CREATE",
  DELETE: "CHARGING_METHOD_DELETE",
  FETCH: "CHARGING_METHOD_FETCH",
  FETCH_ALL: "CHARGING_METHOD_FETCH_ALL",
  UPDATE: "CHARGING_METHOD_UPDATE",
};

// Accounting Records Actions
export const fetchChargingMethods = (chargingMethods: IAccountingRecord[]) => {
  return {
    payload: chargingMethods,
    type: CHARGING_METHOD_TYPES.FETCH_ALL,
  };
};

export const fetchChargingMethod = (
  chargingMethod: IChargingMethod
): IAction<IChargingMethod[]> => {
  return {
    payload: [chargingMethod],
    type: CHARGING_METHOD_TYPES.FETCH,
  };
};
export const createChargingMethod = (
  chargingMethod: IChargingMethod
): IAction<IChargingMethod[]> => {
  return {
    payload: [chargingMethod],
    type: CHARGING_METHOD_TYPES.CREATE,
  };
};
export const updateChargingMethod = (
  chargingMethod: IChargingMethod
): IAction<IChargingMethod[]> => {
  return {
    payload: [chargingMethod],
    type: CHARGING_METHOD_TYPES.UPDATE,
  };
};
export const deleteChargingMethod = (
  uniqueId: string
): IAction<IChargingMethod[]> => {
  return {
    payload: [{ uniqueId }],
    type: CHARGING_METHOD_TYPES.DELETE,
  };
};

// Assignee types
export const ASSIGNEE_TYPES = {
  FETCH: "ASSIGNEE_FETCH",
  FETCH_ALL: "ASSIGNEE_FETCH_ALL",
};

// Assignee Action Creators
export const fetchAssignees = (
  assignees: IAssignee[]
): IAction<IAssignee[]> => {
  return {
    payload: assignees,
    type: ASSIGNEE_TYPES.FETCH_ALL,
  };
};

export const fetchAssignee = (assignee: IAssignee): IAction<IAssignee[]> => {
  return {
    payload: [assignee],
    type: ASSIGNEE_TYPES.FETCH,
  };
};

// Assignee types
export const TIME_TYPE = {
  FETCH: "TIME_FETCH",
};

export const fetchTime = (time: ITime): IAction<ITime[]> => {
  return {
    payload: [time],
    type: TIME_TYPE.FETCH,
  };
};

// ChartOfAccounts types
export const CHART_OF_ACCOUNTS_TYPES: IActionTypes = {
  CREATE: "CHART_OF_ACCOUNTS_CREATE",
  DELETE: "CHART_OF_ACCOUNTS_DELETE",
  FETCH: "CHART_OF_ACCOUNTS_FETCH",
  FETCH_ALL: "CHART_OF_ACCOUNTS_FETCH_ALL",
  UPDATE: "CHART_OF_ACCOUNTS_UPDATE",
};

// Accounting Records Actions
export const fetchChartOfAccounts = (accountingRecords: IChartOfAccount[]) => {
  return {
    payload: accountingRecords,
    type: CHART_OF_ACCOUNTS_TYPES.FETCH_ALL,
  };
};

export const fetchChartOfAccount = (
  accountingRecord: IChartOfAccount
): IAction<IChartOfAccount[]> => {
  return {
    payload: [accountingRecord],
    type: CHART_OF_ACCOUNTS_TYPES.FETCH,
  };
};
export const createChartOfAccount = (
  accountingRecord: IChartOfAccount
): IAction<IChartOfAccount[]> => {
  return {
    payload: [accountingRecord],
    type: CHART_OF_ACCOUNTS_TYPES.CREATE,
  };
};
export const updateChartOfAccount = (
  accountingRecord: IChartOfAccount
): IAction<IChartOfAccount[]> => {
  return {
    payload: [accountingRecord],
    type: CHART_OF_ACCOUNTS_TYPES.UPDATE,
  };
};
export const deleteChartOfAccount = (
  uniqueId: string
): IAction<IChartOfAccount[]> => {
  return {
    payload: [{ uniqueId }],
    type: CHART_OF_ACCOUNTS_TYPES.DELETE,
  };
};

// Provider
export const PROVIDER_TYPES: IActionTypes = {
  CREATE: "PROVIDER_CREATE",
  DELETE: "PROVIDER_DELETE",
  FETCH: "PROVIDER_FETCH",
  FETCH_ALL: "PROVIDER_FETCH_ALL",
  UPDATE: "PROVIDER_UPDATE",
};
// Providers
export function fetchProviders(provider: IProvider[]) {
  return {
    payload: provider,
    type: PROVIDER_TYPES.FETCH_ALL,
  };
}
export function fetchProvider(provider: IProvider): IAction<IProvider[]> {
  return {
    payload: [provider],
    type: PROVIDER_TYPES.FETCH,
  };
}
export function createProvider(provider: IProvider): IAction<IProvider[]> {
  return {
    payload: [provider],
    type: PROVIDER_TYPES.CREATE,
  };
}
export function updateProvider(provider: IProvider): IAction<IProvider[]> {
  return {
    payload: [provider],
    type: PROVIDER_TYPES.UPDATE,
  };
}
export function deleteProvider(uniqueId: string): IAction<IProvider[]> {
  return {
    payload: [{ uniqueId }],
    type: PROVIDER_TYPES.DELETE,
  };
}

// Provider
export const PROVIDER_CONTACT_TYPES: IActionTypes = {
  CREATE: "PROVIDER_CONTACT_CREATE",
  DELETE: "PROVIDER_CONTACT_DELETE",
  FETCH: "PROVIDER_CONTACT_FETCH",
  FETCH_ALL: "PROVIDER_CONTACT_FETCH_ALL",
  UPDATE: "PROVIDER_CONTACT_UPDATE",
};
// Providers
export function fetchProviderContacts(provider: IProviderContact[]) {
  return {
    payload: provider,
    type: PROVIDER_CONTACT_TYPES.FETCH_ALL,
  };
}
export function fetchProviderContact(
  provider: IProviderContact
): IAction<IProviderContact[]> {
  return {
    payload: [provider],
    type: PROVIDER_CONTACT_TYPES.FETCH,
  };
}
export function createProviderContact(
  provider: IProviderContact
): IAction<IProviderContact[]> {
  return {
    payload: [provider],
    type: PROVIDER_CONTACT_TYPES.CREATE,
  };
}
export function updateProviderContact(
  provider: IProviderContact
): IAction<IProviderContact[]> {
  return {
    payload: [provider],
    type: PROVIDER_CONTACT_TYPES.UPDATE,
  };
}
export function deleteProviderContact(
  uniqueId: string
): IAction<IProviderContact[]> {
  return {
    payload: [{ uniqueId }],
    type: PROVIDER_CONTACT_TYPES.DELETE,
  };
}
// AcquaintanceshipProviderContract
export const ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES: IActionTypes = {
  CREATE: "ACQUAINTANCESHIP_PROVIDER_CONTRACT_CREATE",
  DELETE: "ACQUAINTANCESHIP_PROVIDER_CONTRACT_DELETE",
  FETCH: "ACQUAINTANCESHIP_PROVIDER_CONTRACT_FETCH",
  FETCH_ALL: "ACQUAINTANCESHIP_PROVIDER_CONTRACT_FETCH_ALL",
  UPDATE: "ACQUAINTANCESHIP_PROVIDER_CONTRACT_UPDATE",
};
// action creators
export function fetchAcquaintanceshipProviderContracts(
  acquaintanceshipProviderContracts: IAcquaintanceshipProviderContract[]
): IAction<IAcquaintanceshipProviderContract[]> {
  return {
    payload: acquaintanceshipProviderContracts,
    type: ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES.FETCH_ALL,
  };
}
export function fetchAcquaintanceshipProviderContract(
  acquaintanceshipProviderContract: IAcquaintanceshipProviderContract
): IAction<IAcquaintanceshipProviderContract[]> {
  return {
    payload: [acquaintanceshipProviderContract],
    type: ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES.FETCH,
  };
}
export function createAcquaintanceshipProviderContract(
  acquaintanceshipProviderContract: IAcquaintanceshipProviderContract
): IAction<IAcquaintanceshipProviderContract[]> {
  return {
    payload: [acquaintanceshipProviderContract],
    type: ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES.CREATE,
  };
}
export function updateAcquaintanceshipProviderContract(
  acquaintanceshipProviderContract: IAcquaintanceshipProviderContract
): IAction<IAcquaintanceshipProviderContract[]> {
  return {
    payload: [acquaintanceshipProviderContract],
    type: ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES.UPDATE,
  };
}
export function deleteAcquaintanceshipProviderContract(
  uniqueId: string
): IAction<IAcquaintanceshipProviderContract[]> {
  return {
    payload: [{ uniqueId }],
    type: ACQUAINTANCESHIP_PROVIDER_CONTRACT_TYPES.DELETE,
  };
}

export const EXPIRATION_TYPES: IActionTypes = {
  CREATE: "EXPIRATION_CREATE",
  DELETE: "EXPIRATION_DELETE",
  FETCH: "EXPIRATION_FETCH",
  FETCH_ALL: "EXPIRATION_FETCH_ALL",
  UPDATE: "EXPIRATION_UPDATE",
};

// Expiration
export function fetchExpirations(
  expirations: IExpiration[]
): IAction<IExpiration[]> {
  return {
    payload: expirations,
    type: EXPIRATION_TYPES.FETCH_ALL,
  };
}
export function fetchExpiration(
  expiration: IExpiration
): IAction<IExpiration[]> {
  return {
    payload: [expiration],
    type: EXPIRATION_TYPES.FETCH,
  };
}
export function createExpiration(
  expiration: IExpiration
): IAction<IExpiration[]> {
  return {
    payload: [expiration],
    type: EXPIRATION_TYPES.CREATE,
  };
}
export function updateExpiration(
  expiration: IExpiration
): IAction<IExpiration[]> {
  return {
    payload: [expiration],
    type: EXPIRATION_TYPES.UPDATE,
  };
}
export function deleteExpiration(uniqueId: string): IAction<IExpiration[]> {
  return {
    payload: [{ uniqueId }],
    type: EXPIRATION_TYPES.DELETE,
  };
}

// Participants By Expiration
export const PARTICIPANTBYEXPIRATION_TYPES: IActionTypes = {
  CREATE: "PARTICIPANTBYEXPIRATION_CREATE",
  DELETE: "PARTICIPANTBYEXPIRATION_DELETE",
  FETCH: "PARTICIPANTBYEXPIRATION_FETCH",
  FETCH_ALL: "PARTICIPANTBYEXPIRATION_FETCH_ALL",
  UPDATE: "PARTICIPANTBYEXPIRATION_UPDATE",
};
export function fetchParticipantsbyActiveExpirations(
  participantsbyExpirations: IParticipant[]
): IAction<IParticipant[]> {
  return {
    payload: participantsbyExpirations,
    type: PARTICIPANTBYEXPIRATION_TYPES.FETCH_ALL,
  };
}

// Membership Application
export const MEMBERSHIP_APPLICATION_TYPES: IActionTypes = {
  CREATE: "MEMBERSHIP_APPLICATION_CREATE",
  DELETE: "MEMBERSHIP_APPLICATION_DELETE",
  FETCH: "MEMBERSHIP_APPLICATION_FETCH",
  FETCH_ALL: "MEMBERSHIP_APPLICATION_FETCH_ALL",
  UPDATE: "MEMBERSHIP_APPLICATION_UPDATE",
};
// Membership Application
export function fetchMembershipApplications(
  merbershipapplication: IMembershipApplication[]
) {
  return {
    payload: merbershipapplication,
    type: MEMBERSHIP_APPLICATION_TYPES.FETCH_ALL,
  };
}
export function fetchMembershipApplication(
  merbershipapplication: IMembershipApplication
): IAction<IMembershipApplication[]> {
  return {
    payload: [merbershipapplication],
    type: MEMBERSHIP_APPLICATION_TYPES.FETCH,
  };
}
export function createMembershipApplication(
  merbershipapplication: IMembershipApplication
): IAction<IMembershipApplication[]> {
  return {
    payload: [merbershipapplication],
    type: MEMBERSHIP_APPLICATION_TYPES.CREATE,
  };
}
export function updateMembershipApplication(
  merbershipapplication: IMembershipApplication
): IAction<IMembershipApplication[]> {
  return {
    payload: [merbershipapplication],
    type: MEMBERSHIP_APPLICATION_TYPES.UPDATE,
  };
}
export function deleteMembershipApplication(
  uniqueId: string
): IAction<IMembershipApplication[]> {
  return {
    payload: [{ uniqueId }],
    type: MEMBERSHIP_APPLICATION_TYPES.DELETE,
  };
}
// Profession
export const PROFESSION_TYPES: IActionTypes = {
  CREATE: "PROFESSION_CONTACT_CREATE",
  DELETE: "PROFESSION_CONTACT_DELETE",
  FETCH: "PROFESSION_CONTACT_FETCH",
  FETCH_ALL: "PROFESSION_CONTACT_FETCH_ALL",
  UPDATE: "PROFESSION_CONTACT_UPDATE",
};

// Professions
export function fetchProfessions(profession: IProfession[]) {
  return {
    payload: profession,
    type: PROFESSION_TYPES.FETCH_ALL,
  };
}
export function fetchProfession(
  profession: IProfession
): IAction<IProfession[]> {
  return {
    payload: [profession],
    type: PROFESSION_TYPES.FETCH,
  };
}
export function createProfession(
  profession: IProfession
): IAction<IProfession[]> {
  return {
    payload: [profession],
    type: PROFESSION_TYPES.CREATE,
  };
}
export function updateProfession(
  profession: IProfession
): IAction<IProfession[]> {
  return {
    payload: [profession],
    type: PROFESSION_TYPES.UPDATE,
  };
}
export function deleteProfession(uniqueId: string): IAction<IProfession[]> {
  return {
    payload: [{ uniqueId }],
    type: PROFESSION_TYPES.DELETE,
  };
}

// Occupation
export const OCCUPATION_TYPES: IActionTypes = {
  CREATE: "OCCUPATION_CONTACT_CREATE",
  DELETE: "OCCUPATION_CONTACT_DELETE",
  FETCH: "OCCUPATION_CONTACT_FETCH",
  FETCH_ALL: "OCCUPATION_CONTACT_FETCH_ALL",
  UPDATE: "OCCUPATION_CONTACT_UPDATE",
};
// Occupations
export function fetchOccupations(occupation: IOccupation[]) {
  return {
    payload: occupation,
    type: OCCUPATION_TYPES.FETCH_ALL,
  };
}
export function fetchOccupation(
  occupation: IOccupation
): IAction<IOccupation[]> {
  return {
    payload: [occupation],
    type: OCCUPATION_TYPES.FETCH,
  };
}
export function createOccupation(
  occupation: IOccupation
): IAction<IOccupation[]> {
  return {
    payload: [occupation],
    type: OCCUPATION_TYPES.CREATE,
  };
}
export function updateOccupation(
  occupation: IOccupation
): IAction<IOccupation[]> {
  return {
    payload: [occupation],
    type: OCCUPATION_TYPES.UPDATE,
  };
}
export function deleteOccupation(uniqueId: string): IAction<IOccupation[]> {
  return {
    payload: [{ uniqueId }],
    type: OCCUPATION_TYPES.DELETE,
  };
}

// CancellationMembershipContract
export const CANCELLATION_MEMBERSHIP_CONTRACT_TYPES: IActionTypes = {
  CREATE: "CANCELLATION_MEMBERSHIP_CONTRACT_CREATE",
  DELETE: "CANCELLATION_MEMBERSHIP_CONTRACT_DELETE",
  FETCH: "CANCELLATION_MEMBERSHIP_CONTRACT_FETCH",
  FETCH_ALL: "CANCELLATION_MEMBERSHIP_CONTRACT_FETCH_ALL",
  UPDATE: "CANCELLATION_MEMBERSHIP_CONTRACT_UPDATE",
};
// CancellationMembershipContracts
export function fetchCancellationMembershipContracts(
  cancellationMembershipContracts: ICancellationMembershipContract[]
) {
  return {
    payload: cancellationMembershipContracts,
    type: CANCELLATION_MEMBERSHIP_CONTRACT_TYPES.FETCH_ALL,
  };
}
export function fetchCancellationMembershipContract(
  cancellationMembershipContracts: ICancellationMembershipContract
): IAction<ICancellationMembershipContract[]> {
  return {
    payload: [cancellationMembershipContracts],
    type: CANCELLATION_MEMBERSHIP_CONTRACT_TYPES.FETCH,
  };
}
export function createCancellationMembershipContract(
  cancellationMembershipContracts: ICancellationMembershipContract
): IAction<ICancellationMembershipContract[]> {
  return {
    payload: [cancellationMembershipContracts],
    type: CANCELLATION_MEMBERSHIP_CONTRACT_TYPES.CREATE,
  };
}
export function updateCancellationMembershipContract(
  cancellationMembershipContracts: ICancellationMembershipContract
): IAction<ICancellationMembershipContract[]> {
  return {
    payload: [cancellationMembershipContracts],
    type: CANCELLATION_MEMBERSHIP_CONTRACT_TYPES.UPDATE,
  };
}
export function deleteCancellationMembershipContract(
  uniqueId: string
): IAction<ICancellationMembershipContract[]> {
  return {
    payload: [{ uniqueId }],
    type: CANCELLATION_MEMBERSHIP_CONTRACT_TYPES.DELETE,
  };
}
// DebitCardOrPassbookAccountContract
export const DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES: IActionTypes = {
  CREATE: "DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_CREATE",
  DELETE: "DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_DELETE",
  FETCH: "DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_FETCH",
  FETCH_ALL: "DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_FETCH_ALL",
  UPDATE: "DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_UPDATE",
};
// DebitCardOrPassbookAccountContracts
export function fetchDebitCardOrPassbookAccountContracts(
  debitCardOrPassbookAccountContracts: IDebitCardOrPassbookAccountContract[]
) {
  return {
    payload: debitCardOrPassbookAccountContracts,
    type: DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES.FETCH_ALL,
  };
}
export function fetchDebitCardOrPassbookAccountContract(
  debitCardOrPassbookAccountContracts: IDebitCardOrPassbookAccountContract
): IAction<IDebitCardOrPassbookAccountContract[]> {
  return {
    payload: [debitCardOrPassbookAccountContracts],
    type: DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES.FETCH,
  };
}
export function createDebitCardOrPassbookAccountContract(
  debitCardOrPassbookAccountContracts: IDebitCardOrPassbookAccountContract
): IAction<IDebitCardOrPassbookAccountContract[]> {
  return {
    payload: [debitCardOrPassbookAccountContracts],
    type: DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES.CREATE,
  };
}
export function updateDebitCardOrPassbookAccountContract(
  debitCardOrPassbookAccountContracts: IDebitCardOrPassbookAccountContract
): IAction<IDebitCardOrPassbookAccountContract[]> {
  return {
    payload: [debitCardOrPassbookAccountContracts],
    type: DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES.UPDATE,
  };
}
export function deleteDebitCardOrPassbookAccountContract(
  uniqueId: string
): IAction<IDebitCardOrPassbookAccountContract[]> {
  return {
    payload: [{ uniqueId }],
    type: DEBIT_CARD_OR_PASSBOOK_ACCOUNT_CONTRACT_TYPES.DELETE,
  };
}

// SavingsAccountContract
export const SAVINGS_CONTRACT_TYPES: IActionTypes = {
  CREATE: "SAVINGS_CONTRACT_CREATE",
  DELETE: "SAVINGS_CONTRACT_DELETE",
  FETCH: "SAVINGS_CONTRACT_FETCH",
  FETCH_ALL: "SAVINGS_CONTRACT_FETCH_ALL",
  UPDATE: "SAVINGS_CONTRACT_UPDATE",
};
// SavingsAccountContracts
export function fetchSavingsAccountContracts(
  savingsAccountContracts: ISavingsAccountContract[]
) {
  return {
    payload: savingsAccountContracts,
    type: SAVINGS_CONTRACT_TYPES.FETCH_ALL,
  };
}
export function fetchSavingsAccountContract(
  savingsAccountContracts: ISavingsAccountContract
): IAction<ISavingsAccountContract[]> {
  return {
    payload: [savingsAccountContracts],
    type: SAVINGS_CONTRACT_TYPES.FETCH,
  };
}
export function createSavingsAccountContract(
  savingsAccountContracts: ISavingsAccountContract
): IAction<ISavingsAccountContract[]> {
  return {
    payload: [savingsAccountContracts],
    type: SAVINGS_CONTRACT_TYPES.CREATE,
  };
}
export function updateSavingsAccountContract(
  savingsAccountContracts: ISavingsAccountContract
): IAction<ISavingsAccountContract[]> {
  return {
    payload: [savingsAccountContracts],
    type: SAVINGS_CONTRACT_TYPES.UPDATE,
  };
}
export function deleteSavingsAccountContract(
  uniqueId: string
): IAction<ISavingsAccountContract[]> {
  return {
    payload: [{ uniqueId }],
    type: SAVINGS_CONTRACT_TYPES.DELETE,
  };
}

// InputCertificationContract
export const INPUT_CERTIFICATION_CONTRACT_TYPES: IActionTypes = {
  CREATE: "INPUT_CERTIFICATION_CONTRACT_CREATE",
  DELETE: "INPUT_CERTIFICATION_CONTRACT_DELETE",
  FETCH: "INPUT_CERTIFICATION_CONTRACT_FETCH",
  FETCH_ALL: "INPUT_CERTIFICATION_CONTRACT_FETCH_ALL",
  UPDATE: "INPUT_CERTIFICATION_CONTRACT_UPDATE",
};
// InputCertificationContracts
export function fetchInputCertificationContracts(
  inputCertificationContracts: IInputCertificationContract[]
) {
  return {
    payload: inputCertificationContracts,
    type: INPUT_CERTIFICATION_CONTRACT_TYPES.FETCH_ALL,
  };
}
export function fetchInputCertificationContract(
  inputCertificationContracts: IInputCertificationContract
): IAction<IInputCertificationContract[]> {
  return {
    payload: [inputCertificationContracts],
    type: INPUT_CERTIFICATION_CONTRACT_TYPES.FETCH,
  };
}
export function createInputCertificationContract(
  inputCertificationContracts: IInputCertificationContract
): IAction<IInputCertificationContract[]> {
  return {
    payload: [inputCertificationContracts],
    type: INPUT_CERTIFICATION_CONTRACT_TYPES.CREATE,
  };
}
export function updateInputCertificationContract(
  inputCertificationContracts: IInputCertificationContract
): IAction<IInputCertificationContract[]> {
  return {
    payload: [inputCertificationContracts],
    type: INPUT_CERTIFICATION_CONTRACT_TYPES.UPDATE,
  };
}
export function deleteInputCertificationContract(
  uniqueId: string
): IAction<IInputCertificationContract[]> {
  return {
    payload: [{ uniqueId }],
    type: INPUT_CERTIFICATION_CONTRACT_TYPES.DELETE,
  };
}

// FinancialCertificationContract
export const FINANCIAL_CERTIFICATION_CONTRACT_TYPES: IActionTypes = {
  CREATE: "FINANCIAL_CERTIFICATION_CONTRACT_CREATE",
  DELETE: "FINANCIAL_CERTIFICATION_CONTRACT_DELETE",
  FETCH: "FINANCIAL_CERTIFICATION_CONTRACT_FETCH",
  FETCH_ALL: "FINANCIAL_CERTIFICATION_CONTRACT_FETCH_ALL",
  UPDATE: "FINANCIAL_CERTIFICATION_CONTRACT_UPDATE",
};
// FinancialCertificationContracts
export function fetchFinancialCertificationContracts(
  financialCertificationContracts: IFinancialCertificationContract[]
) {
  return {
    payload: financialCertificationContracts,
    type: FINANCIAL_CERTIFICATION_CONTRACT_TYPES.FETCH_ALL,
  };
}
export function fetchFinancialCertificationContract(
  financialCertificationContracts: IFinancialCertificationContract
): IAction<IFinancialCertificationContract[]> {
  return {
    payload: [financialCertificationContracts],
    type: FINANCIAL_CERTIFICATION_CONTRACT_TYPES.FETCH,
  };
}
export function createFinancialCertificationContract(
  financialCertificationContracts: IFinancialCertificationContract
): IAction<IFinancialCertificationContract[]> {
  return {
    payload: [financialCertificationContracts],
    type: FINANCIAL_CERTIFICATION_CONTRACT_TYPES.CREATE,
  };
}
export function updateFinancialCertificationContract(
  financialCertificationContracts: IFinancialCertificationContract
): IAction<IFinancialCertificationContract[]> {
  return {
    payload: [financialCertificationContracts],
    type: FINANCIAL_CERTIFICATION_CONTRACT_TYPES.UPDATE,
  };
}
export function deleteFinancialCertificationContract(
  uniqueId: string
): IAction<IFinancialCertificationContract[]> {
  return {
    payload: [{ uniqueId }],
    type: FINANCIAL_CERTIFICATION_CONTRACT_TYPES.DELETE,
  };
}
// CreditReviewAuthorization
export const CREDIT_REVIEW_AUTHORIZATION_TYPES: IActionTypes = {
  CREATE: "CREDIT_REVIEW_AUTHORIZATION_CREATE",
  DELETE: "CREDIT_REVIEW_AUTHORIZATION_DELETE",
  FETCH: "CREDIT_REVIEW_AUTHORIZATION_FETCH",
  FETCH_ALL: "CREDIT_REVIEW_AUTHORIZATION_FETCH_ALL",
  UPDATE: "CREDIT_REVIEW_AUTHORIZATION_UPDATE",
};
// CreditReviewAuthorizations
export function fetchCreditReviewAuthorizations(
  creditReviewAuthorization: ICreditReviewAuthorization[]
) {
  return {
    payload: creditReviewAuthorization,
    type: CREDIT_REVIEW_AUTHORIZATION_TYPES.FETCH_ALL,
  };
}
export function fetchCreditReviewAuthorization(
  creditReviewAuthorization: ICreditReviewAuthorization
): IAction<ICreditReviewAuthorization[]> {
  return {
    payload: [creditReviewAuthorization],
    type: CREDIT_REVIEW_AUTHORIZATION_TYPES.FETCH,
  };
}
export function createCreditReviewAuthorization(
  creditReviewAuthorization: ICreditReviewAuthorization
): IAction<ICreditReviewAuthorization[]> {
  return {
    payload: [creditReviewAuthorization],
    type: CREDIT_REVIEW_AUTHORIZATION_TYPES.CREATE,
  };
}
export function updateCreditReviewAuthorization(
  creditReviewAuthorization: ICreditReviewAuthorization
): IAction<ICreditReviewAuthorization[]> {
  return {
    payload: [creditReviewAuthorization],
    type: CREDIT_REVIEW_AUTHORIZATION_TYPES.UPDATE,
  };
}
export function deleteCreditReviewAuthorization(
  uniqueId: string
): IAction<ICreditReviewAuthorization[]> {
  return {
    payload: [{ uniqueId }],
    type: CREDIT_REVIEW_AUTHORIZATION_TYPES.DELETE,
  };
}
// Warranty
export const WARRANTY_TYPES: IActionTypes = {
  CREATE: "WARRANTY_CONTACT_CREATE",
  DELETE: "WARRANTY_CONTACT_DELETE",
  FETCH: "WARRANTY_CONTACT_FETCH",
  FETCH_ALL: "WARRANTY_CONTACT_FETCH_ALL",
  UPDATE: "WARRANTY_CONTACT_UPDATE",
};

export function fetchWarranties(warranty: IWarranty[]) {
  return {
    payload: warranty,
    type: WARRANTY_TYPES.FETCH_ALL,
  };
}
export function fetchWarranty(warranty: IWarranty): IAction<IWarranty[]> {
  return {
    payload: [warranty],
    type: WARRANTY_TYPES.FETCH,
  };
}
export function createWarranty(warranty: IWarranty): IAction<IWarranty[]> {
  return {
    payload: [warranty],
    type: WARRANTY_TYPES.CREATE,
  };
}
export function updateWarranty(warranty: IWarranty): IAction<IWarranty[]> {
  return {
    payload: [warranty],
    type: WARRANTY_TYPES.UPDATE,
  };
}
export function deleteWarranty(uniqueId: string): IAction<IWarranty[]> {
  return {
    payload: [{ uniqueId }],
    type: WARRANTY_TYPES.DELETE,
  };
}
// Interest
export const INTEREST_TYPES: IActionTypes = {
  CREATE: "INTEREST_CREATE",
  DELETE: "INTEREST_DELETE",
  FETCH: "INTEREST_FETCH",
  FETCH_ALL: "INTEREST_FETCH_ALL",
  UPDATE: "INTEREST_UPDATE",
};
// Interests
export function fetchInterests(interest: IInterest[]) {
  return {
    payload: interest,
    type: INTEREST_TYPES.FETCH_ALL,
  };
}
export function fetchInterest(interest: IInterest): IAction<IInterest[]> {
  return {
    payload: [interest],
    type: INTEREST_TYPES.FETCH,
  };
}
export function createInterest(interest: IInterest): IAction<IInterest[]> {
  return {
    payload: [interest],
    type: INTEREST_TYPES.CREATE,
  };
}
export function updateInterest(interest: IInterest): IAction<IInterest[]> {
  return {
    payload: [interest],
    type: INTEREST_TYPES.UPDATE,
  };
}
export function deleteInterest(uniqueId: string): IAction<IInterest[]> {
  return {
    payload: [{ uniqueId }],
    type: INTEREST_TYPES.DELETE,
  };
}
// InternationalBlackList types
export const INTERNATIONAL_BLACKLIST_TYPES: IActionTypes = {
  CREATE: "INTERNATIONAL_BLACKLIST_CREATE",
  DELETE: "INTERNATIONAL_BLACKLIST_DELETE",
  FETCH: "INTERNATIONAL_BLACKLIST_FETCH",
  FETCH_ALL: "INTERNATIONAL_BLACKLIST_FETCH_ALL",
  UPDATE: "INTERNATIONAL_BLACKLIST_UPDATE",
};

// InternationalBlackList Actions
export const fetchInternationalBlackLists = (
  internationalBlackLists: IInternationalBlackList[]
) => {
  return {
    payload: internationalBlackLists,
    type: INTERNATIONAL_BLACKLIST_TYPES.FETCH_ALL,
  };
};

export const fetchInternationalBlackList = (
  internationalBlackList: IInternationalBlackList
): IAction<IInternationalBlackList[]> => {
  return {
    payload: [internationalBlackList],
    type: INTERNATIONAL_BLACKLIST_TYPES.FETCH,
  };
};
export const createInternationalBlackList = (
  internationalBlackList: IInternationalBlackList
): IAction<IInternationalBlackList[]> => {
  return {
    payload: [internationalBlackList],
    type: INTERNATIONAL_BLACKLIST_TYPES.CREATE,
  };
};
export const updateInternationalBlackList = (
  internationalBlackList: IInternationalBlackList
): IAction<IInternationalBlackList[]> => {
  return {
    payload: [internationalBlackList],
    type: INTERNATIONAL_BLACKLIST_TYPES.UPDATE,
  };
};
export const deleteInternationalBlackList = (
  uniqueId: string
): IAction<IInternationalBlackList[]> => {
  return {
    payload: [{ uniqueId }],
    type: INTERNATIONAL_BLACKLIST_TYPES.DELETE,
  };
};

// InternationalBlackList types
export const PEPS_LIST_TYPES: IActionTypes = {
  CREATE: "PEPS_LIST_CREATE",
  DELETE: "PEPS_LIST_DELETE",
  FETCH: "PEPS_LIST_FETCH",
  FETCH_ALL: "PEPS_LIST_FETCH_ALL",
  UPDATE: "PEPS_LIST_UPDATE",
};
// PepsList Actions
export const fetchPepsLists = (pepsLists: IPepsList[]) => {
  return {
    payload: pepsLists,
    type: PEPS_LIST_TYPES.FETCH_ALL,
  };
};

export const fetchPepsList = (pepsList: IPepsList): IAction<IPepsList[]> => {
  return {
    payload: [pepsList],
    type: PEPS_LIST_TYPES.FETCH,
  };
};
export const createPepsList = (pepsList: IPepsList): IAction<IPepsList[]> => {
  return {
    payload: [pepsList],
    type: PEPS_LIST_TYPES.CREATE,
  };
};
export const updatePepsList = (pepsList: IPepsList): IAction<IPepsList[]> => {
  return {
    payload: [pepsList],
    type: PEPS_LIST_TYPES.UPDATE,
  };
};
export const deletePepsList = (uniqueId: string): IAction<IPepsList[]> => {
  return {
    payload: [{ uniqueId }],
    type: PEPS_LIST_TYPES.DELETE,
  };
};

// OFACAndONUList types
export const OFAC_AND_ONU_LIST_TYPES: IActionTypes = {
  CREATE: "OFAC_AND_ONU_LIST_CREATE",
  DELETE: "OFAC_AND_ONU_LIST_DELETE",
  FETCH: "OFAC_AND_ONU_LIST_FETCH",
  FETCH_ALL: "OFAC_AND_ONU_LIST_FETCH_ALL",
  UPDATE: "OFAC_AND_ONU_LIST_UPDATE",
};
// PepsList Actions
export const fetchOFACAndONULists = (OFACAndONUList: IOFACAndONUList[]) => {
  return {
    payload: OFACAndONUList,
    type: OFAC_AND_ONU_LIST_TYPES.FETCH_ALL,
  };
};

export const fetchOFACAndONUList = (
  pepsList: IOFACAndONUList
): IAction<IOFACAndONUList[]> => {
  return {
    payload: [pepsList],
    type: OFAC_AND_ONU_LIST_TYPES.FETCH,
  };
};
export const createOFACAndONUList = (
  OFACAndONUList: IOFACAndONUList
): IAction<IOFACAndONUList[]> => {
  return {
    payload: [OFACAndONUList],
    type: OFAC_AND_ONU_LIST_TYPES.CREATE,
  };
};
export const updateOFACAndONUList = (
  OFACAndONUList: IOFACAndONUList
): IAction<IOFACAndONUList[]> => {
  return {
    payload: [OFACAndONUList],
    type: OFAC_AND_ONU_LIST_TYPES.UPDATE,
  };
};
export const deleteOFACAndONUList = (
  uniqueId: string
): IAction<IOFACAndONUList[]> => {
  return {
    payload: [{ uniqueId }],
    type: OFAC_AND_ONU_LIST_TYPES.DELETE,
  };
};

// ayroll Deductions
export const PAYROLL_DEDUCTION_TYPES: IActionTypes = {
  CREATE: "PAYROLL_DEDUCTION_CREATE",
  DELETE: "PAYROLL_DEDUCTION_DELETE",
  FETCH: "PAYROLL_DEDUCTION_FETCH",
  FETCH_ALL: "PAYROLL_DEDUCTION_FETCH_ALL",
  UPDATE: "PAYROLL_DEDUCTION_UPDATE",
};

export function fetchPayrollDeductions(payrollDeduction: IPayrollDeduction[]) {
  return {
    payload: payrollDeduction,
    type: PAYROLL_DEDUCTION_TYPES.FETCH_ALL,
  };
}
export function fetchPayrollDeduction(
  payrollDeduction: IPayrollDeduction
): IAction<IPayrollDeduction[]> {
  return {
    payload: [payrollDeduction],
    type: PAYROLL_DEDUCTION_TYPES.FETCH,
  };
}
export function createPayrollDeduction(
  payrollDeduction: IPayrollDeduction
): IAction<IPayrollDeduction[]> {
  return {
    payload: [payrollDeduction],
    type: PAYROLL_DEDUCTION_TYPES.CREATE,
  };
}
export function updatePayrollDeduction(
  payrollDeduction: IPayrollDeduction
): IAction<IPayrollDeduction[]> {
  return {
    payload: [payrollDeduction],
    type: PAYROLL_DEDUCTION_TYPES.UPDATE,
  };
}
export function deletePayrollDeduction(
  uniqueId: string
): IAction<IPayrollDeduction[]> {
  return {
    payload: [{ uniqueId }],
    type: PAYROLL_DEDUCTION_TYPES.DELETE,
  };
}

// Affidavit Of Funds
export const AFFIDAVIT_OF_FUNDS_TYPES: IActionTypes = {
  CREATE: "AFFIDAVIT_OF_FUNDS_CREATE",
  DELETE: "AFFIDAVIT_OF_FUNDS_DELETE",
  FETCH: "AFFIDAVIT_OF_FUNDS_FETCH",
  FETCH_ALL: "AFFIDAVIT_OF_FUNDS_FETCH_ALL",
  UPDATE: "AFFIDAVIT_OF_FUNDS_UPDATE",
};

export function fetchAffidavitsOfFunds(affidavitsOfFunds: IAffidavitOfFunds[]) {
  return {
    payload: affidavitsOfFunds,
    type: AFFIDAVIT_OF_FUNDS_TYPES.FETCH_ALL,
  };
}
export function fetchAffidavitOfFunds(
  affidavitsOfFunds: IAffidavitOfFunds
): IAction<IAffidavitOfFunds[]> {
  return {
    payload: [affidavitsOfFunds],
    type: AFFIDAVIT_OF_FUNDS_TYPES.FETCH,
  };
}
export function createAffidavitOfFunds(
  affidavitsOfFunds: IAffidavitOfFunds
): IAction<IAffidavitOfFunds[]> {
  return {
    payload: [affidavitsOfFunds],
    type: AFFIDAVIT_OF_FUNDS_TYPES.CREATE,
  };
}
export function updateAffidavitOfFunds(
  affidavitsOfFunds: IAffidavitOfFunds
): IAction<IAffidavitOfFunds[]> {
  return {
    payload: [affidavitsOfFunds],
    type: AFFIDAVIT_OF_FUNDS_TYPES.UPDATE,
  };
}
export function deleteAffidavitOfFunds(
  uniqueId: string
): IAction<IAffidavitOfFunds[]> {
  return {
    payload: [{ uniqueId }],
    type: AFFIDAVIT_OF_FUNDS_TYPES.DELETE,
  };
}

// Client Affidavit Of Funds
export const CLIENT_AFFIDAVIT_OF_FUNDS_TYPES: IActionTypes = {
  CREATE: "CLIENT_AFFIDAVIT_OF_FUNDS_CREATE",
  DELETE: "CLIENT_AFFIDAVIT_OF_FUNDS_DELETE",
  FETCH: "CLIENT_AFFIDAVIT_OF_FUNDS_FETCH",
  FETCH_ALL: "CLIENT_AFFIDAVIT_OF_FUNDS_FETCH_ALL",
  UPDATE: "CLIENT_AFFIDAVIT_OF_FUNDS_UPDATE",
};

export function fetchClientAffidavitsOfFunds(
  clientAffidavitsOfFunds: IClientAffidavitOfFunds[]
) {
  return {
    payload: clientAffidavitsOfFunds,
    type: CLIENT_AFFIDAVIT_OF_FUNDS_TYPES.FETCH_ALL,
  };
}
export function fetchClientAffidavitOfFunds(
  clientAffidavitsOfFunds: IClientAffidavitOfFunds
): IAction<IClientAffidavitOfFunds[]> {
  return {
    payload: [clientAffidavitsOfFunds],
    type: CLIENT_AFFIDAVIT_OF_FUNDS_TYPES.FETCH,
  };
}
export function createClientAffidavitOfFunds(
  clientAffidavitsOfFunds: IClientAffidavitOfFunds
): IAction<IClientAffidavitOfFunds[]> {
  return {
    payload: [clientAffidavitsOfFunds],
    type: CLIENT_AFFIDAVIT_OF_FUNDS_TYPES.CREATE,
  };
}
export function updateClientAffidavitOfFunds(
  clientAffidavitsOfFunds: IClientAffidavitOfFunds
): IAction<IClientAffidavitOfFunds[]> {
  return {
    payload: [clientAffidavitsOfFunds],
    type: CLIENT_AFFIDAVIT_OF_FUNDS_TYPES.UPDATE,
  };
}
export function deleteClientAffidavitOfFunds(
  uniqueId: string
): IAction<IClientAffidavitOfFunds[]> {
  return {
    payload: [{ uniqueId }],
    type: CLIENT_AFFIDAVIT_OF_FUNDS_TYPES.DELETE,
  };
}
// Compliance Registration Form
export const COMPLIANCE_REGISTRATION_FORM_TYPES: IActionTypes = {
  CREATE: "COMPLIANCE_REGISTRATION_FORM_CREATE",
  DELETE: "COMPLIANCE_REGISTRATION_FORM_DELETE",
  FETCH: "COMPLIANCE_REGISTRATION_FORM_FETCH",
  FETCH_ALL: "COMPLIANCE_REGISTRATION_FORM_FETCH_ALL",
  UPDATE: "COMPLIANCE_REGISTRATION_FORM_UPDATE",
};

export function fetchComplianceRegistrationForms(
  complianceRegistrationForms: IComplianceRegistrationForm[]
) {
  return {
    payload: complianceRegistrationForms,
    type: COMPLIANCE_REGISTRATION_FORM_TYPES.FETCH_ALL,
  };
}
export function fetchComplianceRegistrationForm(
  complianceRegistrationForms: IComplianceRegistrationForm
): IAction<IComplianceRegistrationForm[]> {
  return {
    payload: [complianceRegistrationForms],
    type: COMPLIANCE_REGISTRATION_FORM_TYPES.FETCH,
  };
}
export function createComplianceRegistrationForm(
  complianceRegistrationForms: IComplianceRegistrationForm
): IAction<IComplianceRegistrationForm[]> {
  return {
    payload: [complianceRegistrationForms],
    type: COMPLIANCE_REGISTRATION_FORM_TYPES.CREATE,
  };
}
export function updateComplianceRegistrationForm(
  complianceRegistrationForms: IComplianceRegistrationForm
): IAction<IComplianceRegistrationForm[]> {
  return {
    payload: [complianceRegistrationForms],
    type: COMPLIANCE_REGISTRATION_FORM_TYPES.UPDATE,
  };
}
export function deleteComplianceRegistrationForm(
  uniqueId: string
): IAction<IComplianceRegistrationForm[]> {
  return {
    payload: [{ uniqueId }],
    type: COMPLIANCE_REGISTRATION_FORM_TYPES.DELETE,
  };
}

// Acts
export const ACT_TYPES: IActionTypes = {
  CREATE: "ACT_CREATE",
  DELETE: "ACT_DELETE",
  FETCH: "ACT_FETCH",
  FETCH_ALL: "ACT_FETCH_ALL",
  UPDATE: "ACT_UPDATE",
};
// Occupations
export function fetchActs(act: IAct[]) {
  return {
    payload: act,
    type: ACT_TYPES.FETCH_ALL,
  };
}
export function fetchAct(act: IAct): IAction<IAct[]> {
  return {
    payload: [act],
    type: ACT_TYPES.FETCH,
  };
}
export function createAct(act: IAct): IAction<IAct[]> {
  return {
    payload: [act],
    type: ACT_TYPES.CREATE,
  };
}
export function updateAct(act: IAct): IAction<IAct[]> {
  return {
    payload: [act],
    type: ACT_TYPES.UPDATE,
  };
}
export function deleteAct(uniqueId: string): IAction<IAct[]> {
  return {
    payload: [{ uniqueId }],
    type: ACT_TYPES.DELETE,
  };
}
// Compliance Registration Form
export const PLEDGE_CONTRACT_WITHOUT_DIVESTMENT_TYPES: IActionTypes = {
  CREATE: "PLEDGE_CONTRACT_WITHOUT_DIVESTMENT_CREATE",
  DELETE: "PLEDGE_CONTRACT_WITHOUT_DIVESTMENT_DELETE",
  FETCH: "PLEDGE_CONTRACT_WITHOUT_DIVESTMENT_FETCH",
  FETCH_ALL: "PLEDGE_CONTRACT_WITHOUT_DIVESTMENT_FETCH_ALL",
  UPDATE: "PLEDGE_CONTRACT_WITHOUT_DIVESTMENT_UPDATE",
};

export function fetchPledgeContractsWithoutDivestment(
  PledgeContractWithoutDivestment: IPledgeContractWithoutDivestment[]
) {
  return {
    payload: PledgeContractWithoutDivestment,
    type: PLEDGE_CONTRACT_WITHOUT_DIVESTMENT_TYPES.FETCH_ALL,
  };
}
export function fetchPledgeContractWithoutDivestment(
  PledgeContractWithoutDivestment: IPledgeContractWithoutDivestment
): IAction<IPledgeContractWithoutDivestment[]> {
  return {
    payload: [PledgeContractWithoutDivestment],
    type: PLEDGE_CONTRACT_WITHOUT_DIVESTMENT_TYPES.FETCH,
  };
}
export function createPledgeContractWithoutDivestment(
  PledgeContractWithoutDivestment: IPledgeContractWithoutDivestment
): IAction<IPledgeContractWithoutDivestment[]> {
  return {
    payload: [PledgeContractWithoutDivestment],
    type: PLEDGE_CONTRACT_WITHOUT_DIVESTMENT_TYPES.CREATE,
  };
}
export function updatePledgeContractWithoutDivestment(
  PledgeContractWithoutDivestment: IPledgeContractWithoutDivestment
): IAction<IPledgeContractWithoutDivestment[]> {
  return {
    payload: [PledgeContractWithoutDivestment],
    type: PLEDGE_CONTRACT_WITHOUT_DIVESTMENT_TYPES.UPDATE,
  };
}
export function deletePledgeContractWithoutDivestment(
  uniqueId: string
): IAction<IPledgeContractWithoutDivestment[]> {
  return {
    payload: [{ uniqueId }],
    type: PLEDGE_CONTRACT_WITHOUT_DIVESTMENT_TYPES.DELETE,
  };
}
// Automatic Withdrawal Authorization Contract
export const AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES: IActionTypes = {
  CREATE: "AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_CREATE",
  DELETE: "AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_DELETE",
  FETCH: "AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_FETCH",
  FETCH_ALL: "AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_FETCH_ALL",
  UPDATE: "AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_UPDATE",
};

export function fetchAutomaticWithdrawalAuthorizationContracts(
  automaticWithdrawalAuthorizationContracts: IAutomaticWithdrawalAuthorizationContract[]
) {
  return {
    payload: automaticWithdrawalAuthorizationContracts,
    type: AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES.FETCH_ALL,
  };
}
export function fetchAutomaticWithdrawalAuthorizationContract(
  automaticWithdrawalAuthorizationContracts: IAutomaticWithdrawalAuthorizationContract
): IAction<IAutomaticWithdrawalAuthorizationContract[]> {
  return {
    payload: [automaticWithdrawalAuthorizationContracts],
    type: AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES.FETCH,
  };
}
export function createAutomaticWithdrawalAuthorizationContract(
  automaticWithdrawalAuthorizationContracts: IAutomaticWithdrawalAuthorizationContract
): IAction<IAutomaticWithdrawalAuthorizationContract[]> {
  return {
    payload: [automaticWithdrawalAuthorizationContracts],
    type: AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES.CREATE,
  };
}
export function updateAutomaticWithdrawalAuthorizationContract(
  automaticWithdrawalAuthorizationContracts: IAutomaticWithdrawalAuthorizationContract
): IAction<IAutomaticWithdrawalAuthorizationContract[]> {
  return {
    payload: [automaticWithdrawalAuthorizationContracts],
    type: AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES.UPDATE,
  };
}
export function deleteAutomaticWithdrawalAuthorizationContract(
  uniqueId: string
): IAction<IAutomaticWithdrawalAuthorizationContract[]> {
  return {
    payload: [{ uniqueId }],
    type: AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES.DELETE,
  };
}
// These are our action types
export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_ERROR = "SEARCH_ERROR";
export const CLEAR_SEARCH = "CLEAR_SEARCH";

export function searchRequest(query: any, optionType: string) {
  return {
    type: SEARCH_REQUEST,
    payload: query,
    optiontype: optionType,
  };
}

export function searchSuccess(payload: any, optionType: string) {
  return {
    type: SEARCH_SUCCESS,
    payload,
    optiontype: optionType,
  };
}

export function searchError(error: any) {
  return {
    type: SEARCH_ERROR,
    error,
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
    payload: {},
  };
}

export function search(query: any, token: string, optionType: string) {
  return async (dispatch: any) => {
    dispatch(searchRequest(query, optionType));
    try {
      const response = await searchDataAPI.searchAPI(query, token, optionType);
      dispatch(searchSuccess(response.data, optionType));
    } catch (error) {
      dispatch(searchError(error));
    }
  };
}

const debouncedSearchAPI = debounce(
  async (query: any, token: any, optionType: any) => {
    return await searchDataAPI.searchAPI(query, token, optionType);
  },
  500,
  { leading: true }
);

export function debouncedSearch(query: any, token: string, optionType: string) {
  return async (dispatch: any) => {
    dispatch(searchRequest(query, optionType));
    try {
      const response = await debouncedSearchAPI(query, token, optionType);
      dispatch(searchSuccess(response && response.data, optionType));
    } catch (error) {
      console.log(error);
      dispatch(searchError(error));
    }
  };
}

const throttledSearchAPI = throttle(
  async (query: any, token: any, optionType: any) => {
    return await searchDataAPI.searchAPI(query, token, optionType);
  },
  600,
  { leading: true }
);

export function throttledSearch(query: any, token: string, optionType: string) {
  return async (dispatch: any) => {
    dispatch(searchRequest(query, optionType));
    try {
      const response = await throttledSearchAPI(query, token, optionType);
      dispatch(searchSuccess(response && response.data, optionType));
    } catch (error) {
      console.log(error);
      dispatch(searchError(error));
    }
  };
}

// financial institutions
export const HUMAN_RESOURCES_REP_TYPES: IActionTypes = {
  CREATE: "HUMAN_RESOURCES_REP_CREATE",
  DELETE: "HUMAN_RESOURCES_REP_DELETE",
  FETCH: "HUMAN_RESOURCES_REP_FETCH",
  FETCH_ALL: "HUMAN_RESOURCES_REP_FETCH_ALL",
  UPDATE: "HUMAN_RESOURCES_REP_UPDATE",
};
// financial institutions action creators
export function fetchHumanResourcess(
  humanResourcess: IHumanResources[]
): IAction<IHumanResources[]> {
  return {
    payload: humanResourcess,
    type: HUMAN_RESOURCES_REP_TYPES.FETCH_ALL,
  };
}
export function fetchHumanResources(
  humanResources: IHumanResources
): IAction<IHumanResources[]> {
  return {
    payload: [humanResources],
    type: HUMAN_RESOURCES_REP_TYPES.FETCH,
  };
}
export function createHumanResources(
  humanResources: IHumanResources
): IAction<IHumanResources[]> {
  return {
    payload: [humanResources],
    type: HUMAN_RESOURCES_REP_TYPES.CREATE,
  };
}
export function updateHumanResources(
  humanResources: IHumanResources
): IAction<IHumanResources[]> {
  return {
    payload: [humanResources],
    type: HUMAN_RESOURCES_REP_TYPES.UPDATE,
  };
}
export function deleteHumanResources(
  uniqueId: string,
  token: string
): IAction<IHumanResources[]> {
  return {
    payload: [{ uniqueId, token }],
    type: HUMAN_RESOURCES_REP_TYPES.DELETE,
  };
}

// Claims
export const CLAIM_TYPES: IActionTypes = {
  CREATE: "CLAIM_CREATE",
  DELETE: "CLAIM_DELETE",
  FETCH: "CLAIM_FETCH",
  FETCH_ALL: "CLAIM_FETCH_ALL",
  UPDATE: "CLAIM_UPDATE",
};

export function fetchClaims(Claims: IClaim[]) {
  return {
    payload: Claims,
    type: CLAIM_TYPES.FETCH_ALL,
  };
}
export function fetchClaim(Claims: IClaim): IAction<IClaim[]> {
  return {
    payload: [Claims],
    type: CLAIM_TYPES.FETCH,
  };
}
export function createClaim(Claims: IClaim): IAction<IClaim[]> {
  return {
    payload: [Claims],
    type: CLAIM_TYPES.CREATE,
  };
}
export function updateClaim(Claims: IClaim): IAction<IClaim[]> {
  return {
    payload: [Claims],
    type: CLAIM_TYPES.UPDATE,
  };
}
export function deleteClaim(uniqueId: string): IAction<IClaim[]> {
  return {
    payload: [{ uniqueId }],
    type: CLAIM_TYPES.DELETE,
  };
}

// Electronic Means Service Contract
export const ELECTRONIC_MEANS_SERVICE_TYPES: IActionTypes = {
  CREATE: "ELECTRONIC_MEANS_SERVICE_CREATE",
  DELETE: "ELECTRONIC_MEANS_SERVICE_DELETE",
  FETCH: "ELECTRONIC_MEANS_SERVICE_FETCH",
  FETCH_ALL: "ELECTRONIC_MEANS_SERVICE_FETCH_ALL",
  UPDATE: "ELECTRONIC_MEANS_SERVICE_UPDATE",
};

export function fetchElectronicMeansServiceContracts(
  electronicMeansServiceContracts: IElectronicMeansServiceContract[]
) {
  return {
    payload: electronicMeansServiceContracts,
    type: ELECTRONIC_MEANS_SERVICE_TYPES.FETCH_ALL,
  };
}
export function fetchElectronicMeansServiceContract(
  electronicMeansServiceContracts: IElectronicMeansServiceContract
): IAction<IElectronicMeansServiceContract[]> {
  return {
    payload: [electronicMeansServiceContracts],
    type: ELECTRONIC_MEANS_SERVICE_TYPES.FETCH,
  };
}
export function createElectronicMeansServiceContract(
  electronicMeansServiceContracts: IElectronicMeansServiceContract
): IAction<IElectronicMeansServiceContract[]> {
  return {
    payload: [electronicMeansServiceContracts],
    type: ELECTRONIC_MEANS_SERVICE_TYPES.CREATE,
  };
}
export function updateElectronicMeansServiceContract(
  electronicMeansServiceContracts: IElectronicMeansServiceContract
): IAction<IElectronicMeansServiceContract[]> {
  return {
    payload: [electronicMeansServiceContracts],
    type: ELECTRONIC_MEANS_SERVICE_TYPES.UPDATE,
  };
}
export function deleteElectronicMeansServiceContract(
  uniqueId: string
): IAction<IElectronicMeansServiceContract[]> {
  return {
    payload: [{ uniqueId }],
    type: ELECTRONIC_MEANS_SERVICE_TYPES.DELETE,
  };
}

// Electronic Means Service Contract
export const MEMBERSHIP_ACQUAINTANCESHIP_TYPES: IActionTypes = {
  CREATE: "MEMBERSHIP_ACQUAINTANCESHIP_CREATE",
  DELETE: "MEMBERSHIP_ACQUAINTANCESHIP_DELETE",
  FETCH: "MEMBERSHIP_ACQUAINTANCESHIP_FETCH",
  FETCH_ALL: "MEMBERSHIP_ACQUAINTANCESHIP_FETCH_ALL",
  UPDATE: "MEMBERSHIP_ACQUAINTANCESHIP_UPDATE",
};

export function fetchAcquaintanceshipAffs(
  acquaintanceshipContract: IAcquaintanceshipAffInterface[]
) {
  return {
    payload: acquaintanceshipContract,
    type: MEMBERSHIP_ACQUAINTANCESHIP_TYPES.FETCH_ALL,
  };
}
export function fetchAcquaintanceshipAff(
  acquaintanceshipContract: IAcquaintanceshipAffInterface
): IAction<IAcquaintanceshipAffInterface[]> {
  return {
    payload: [acquaintanceshipContract],
    type: MEMBERSHIP_ACQUAINTANCESHIP_TYPES.FETCH,
  };
}
export function createAcquaintanceshipAff(
  acquaintanceshipContract: IAcquaintanceshipAffInterface
): IAction<IAcquaintanceshipAffInterface[]> {
  return {
    payload: [acquaintanceshipContract],
    type: MEMBERSHIP_ACQUAINTANCESHIP_TYPES.CREATE,
  };
}
export function updateAcquaintanceshipAff(
  acquaintanceshipContract: IAcquaintanceshipAffInterface
): IAction<IAcquaintanceshipAffInterface[]> {
  return {
    payload: [acquaintanceshipContract],
    type: MEMBERSHIP_ACQUAINTANCESHIP_TYPES.UPDATE,
  };
}
export function deletehAcquaintanceshipAff(
  acquaintanceshipContract: IAcquaintanceshipAffInterface
): IAction<IAcquaintanceshipAffInterface[]> {
  return {
    payload: [acquaintanceshipContract],
    type: MEMBERSHIP_ACQUAINTANCESHIP_TYPES.DELETE,
  };
}
