import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createBankAccount } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import accountNumberMaskInput from "../../../components/TextMaskInputComponents/accountNumberMaskInput";
import {
  bankAccountTypes,
  catchError,
  resetError,
  successHandler,
  translations,
} from "../../../utils";
import { createBankAccount as create } from "../../../utils/axios";
import FinancialInstitutionSelectInput from "../../FinancialInstitution/FinancialInstitutionSelectInput";
import CreditorSelectInput from "src/containers/Creditor/CreditorSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface IBankAccountFormProps {
  classes: { [key: string]: string };
  user: IUser;
  creditors: ICreditor[];
  financialInstitutions: IInstitution[];
  createBankAccount(bankAccount: IBankAccount): IAction<IBankAccount[]>;
  changeSection(sectionNumber: number): void;
}

interface IBankAccountFormState {
  [key: string]: string | string[] | number | boolean;

  bank: string[];
  bankError: string;

  owner: string[];
  ownerError: string;

  name: string;
  nameError: string;

  currencyType: string;
  currencyTypeError: string;

  ledgerAccount: string;
  ledgerAccountError: string;

  number: string;
  numberError: string;

  isMainAccount: boolean;
  isMainAccountError: string;

  accountType: string;
  accountTypeError: string;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
}
class BankAccountForm extends React.Component<
  IBankAccountFormProps,
  IBankAccountFormState
> {
  public state: IBankAccountFormState = {
    accountType: "saving",
    accountTypeError: "",
    bank: [],
    bankError: "",
    currencyType: "DOP",
    currencyTypeError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    isMainAccount: false,
    isMainAccountError: "",
    ledgerAccount: "",
    ledgerAccountError: "",
    loading: false,
    name: "",
    nameError: "",
    number: "",
    numberError: "",
    owner: [],
    ownerError: "",
  };

  constructor(props: IBankAccountFormProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);

    this.handleAddBank = this.handleAddBank.bind(this);
    this.handleDeleteBank = this.handleDeleteBank.bind(this);

    this.handleAddCreditor = this.handleAddCreditor.bind(this);
    this.handleDeleteCreditor = this.handleDeleteCreditor.bind(this);
    this.handleCheckInputChange = this.handleCheckInputChange.bind(this);
  }
  public handleCheckInputChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public handleAddBank(id: string) {
    this.setState({ bank: [id] });
  }
  public handleDeleteBank(id: string) {
    this.setState({ bank: [] });
  }
  public handleAddCreditor(id: string) {
    this.setState({ owner: [id] });
  }
  public handleDeleteCreditor(id: string) {
    this.setState({ owner: [] });
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    const bankAccount: IBankAccount = {
      accountType: this.state.accountType,
      bank: this.state.bank[0] || "",
      currencyType: this.state.currencyType,
      isMainAccount: this.state.isMainAccount,
      ledgerAccount: this.state.ledgerAccount,
      number: this.state.number,
      owner: this.state.owner[0] || "",
    };

    this.setState({ loading: true });

    create(this.props.user.token, bankAccount)
      .then((res) => {
        this.props.createBankAccount(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, translations.BANK_ACCOUNT);
        this.handleReset();
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }
  public handleReset() {
    this.setState({
      accountType: "saving",
      accountTypeError: "",
      bank: [],
      bankError: "",
      currencyType: "DOP",
      currencyTypeError: "",
      isMainAccount: false,
      ledgerAccount: "",
      ledgerAccountError: "",
      name: "",
      nameError: "",
      number: "",
      numberError: "",
      owner: [],
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.BANK_ACCOUNT}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {translations.CREDITORS}
              </Typography>
              <div style={{ width: "100%" }}>
                <CreditorSelectInput
                  addCreditor={this.handleAddCreditor}
                  deleteCreditor={this.handleDeleteCreditor}
                  selectedCreditorsId={this.state.owner}
                />
              </div>
              <p style={{ color: "red" }}>{this.state.ownerError}</p>
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {translations.BANKS}
              </Typography>
              <div style={{ width: "50%" }}>
                <FinancialInstitutionSelectInput
                  addInstitution={this.handleAddBank}
                  deleteInstituion={this.handleDeleteBank}
                  selectedInstitutionsId={this.state.bank}
                />
              </div>
              <p style={{ color: "red" }}>{this.state.bankError}</p>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="accountType" shrink={true}>
                  {translations.ACCOUNT_TYPE}
                </InputLabel>
                <Select
                  value={this.state.accountType}
                  onChange={this.handleSelectChange("accountType")}
                  required={true}
                  style={{ width: 250 }}
                  native={true}
                  error={this.state.accountTypeError !== ""}
                  inputProps={{
                    id: "accountType",
                    name: "accountType",
                  }}
                >
                  {bankAccountTypes.map((opt, key) => {
                    return (
                      <option key={key} value={opt.value}>
                        {opt.label}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="currencyType" shrink={true}>
                  {translations.CURRENCY}
                </InputLabel>
                <Select
                  value={this.state.currencyType}
                  onChange={this.handleSelectChange("currencyType")}
                  required={true}
                  style={{ width: 250 }}
                  native={true}
                  error={this.state.currencyTypeError !== ""}
                  inputProps={{
                    id: "currencyType",
                    name: "currencyType",
                  }}
                >
                  <option value="DOP">{translations.DOP}</option>
                  <option value="USD">{translations.USD}</option>
                </Select>
              </FormControl>
              <TextField
                label={translations.ACCOUNT_NUMBER}
                className={classes.textField}
                value={this.state.number}
                onChange={this.handleChange("number")}
                required={true}
                error={this.state.numberError !== ""}
                helperText={this.state.numberError}
                InputProps={{
                  inputComponent: accountNumberMaskInput,
                }}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="ledgerAccount" shrink={true}>
                  {translations.LEDGER_ACCOUNT}
                </InputLabel>
                <Select
                  value={this.state.ledgerAccount}
                  onChange={this.handleSelectChange("ledgerAccount")}
                  required={true}
                  style={{ width: 250 }}
                  native={true}
                  error={this.state.ledgerAccountError !== ""}
                  inputProps={{
                    id: "ledgerAccount",
                    name: "ledgerAccount",
                  }}
                >
                  <option value="accountList">Lista catalogo de cuentas</option>
                </Select>
              </FormControl>
              <br />
              <br />
              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={this.state.name}
                disabled={true}
                error={this.state.nameError !== ""}
                helperText={this.state.nameError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isMainAccount}
                    onChange={this.handleCheckInputChange("isMainAccount")}
                    value="isMainAccount"
                  />
                }
                label={translations.IS_MAIN_ACCOUNT}
              />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    financialInstitutions: state.financialInstitutions,
    creditors: state.creditors,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createBankAccount })(BankAccountForm)
);
