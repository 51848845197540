import React, { useState } from "react";
import { Grid, Button, Typography, IconButton } from "@material-ui/core";
import {
  DateField,
  SelectField,
  TextFieldComponent,
} from "./../../../components/SharedComponents/Fields";
import { green } from "@material-ui/core/colors";
import { Close, Tune } from "@material-ui/icons";

function FiltersHeader(props) {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Typography
        variant={props.typeVariant}
        className={props.typeClass}
        style={{ fontWeight: "bold" }}
      >
        {props.filtersTitle}
      </Typography>
      <IconButton
        className={props.btnClass}
        onClick={props.toggleFilter}
        disabled={props.disableClose}
      >
        {props.openFilter ? (
          <Close className={props.iconClass} />
        ) : (
          <Tune className={props.iconClass} />
        )}
      </IconButton>
    </Grid>
  );
}

function DetailsFilters(props) {
  const [amountStart, setAmountStart] = useState("");
  const [amountEnd, setAmountEnd] = useState("");

  const [type, setType] = useState("");
  const [date, setDate] = useState({ start: null, end: null });

  const [openFilter, setOpenFilter] = useState(true);

  const test = ["Debito", "Credito"];

  const handleChange = (key) => {
    return (event) => {
      if (key === "amountStart") {
        setAmountStart(event.currentTarget.value);
      } else if (key === "amountEnd") {
        setAmountEnd(event.currentTarget.value);
      }
    };
  };

  const handleDateChange = (type, dateInfo, value) => {
    setDate({ ...date, [type]: dateInfo });
  };

  const applyFilters = () => {
    const filteredObj = props.detailsData.filter(
      (item) =>
        (amountStart ? Number(amountStart) <= item.endingBalance : true) &&
        (amountEnd ? Number(amountEnd) >= item.endingBalance : true) &&
        (type === "Credito" ? 0 === Number(item.debit) : true) &&
        (type === "Debito" ? 0 === Number(item.credit) : true) &&
        (date.start ? date.start <= new Date(item.transactionDate) : true) &&
        (date.end ? date.end >= new Date(item.transactionDate) : true)
    );

    if (
      amountStart === "" &&
      amountEnd === "" &&
      type === "" &&
      date.start === null &&
      date.end === null
    ) {
      props.setDetailsData(props.detailsData);
    } else {
      props.setDetailsData(filteredObj);
    }
  };

  const clearFilters = () => {
    setAmountStart("");
    setAmountEnd("");
    setType("");
    setDate({ start: null, end: null });
    props.setDetailsData(props.detailsData);
  };

  const handleCreditorChange = (event) => {
    setType(event.target.value);
  };

  const switchState = (key) => {
    setOpenFilter(!openFilter);
  };

  return (
    <Grid container className="filters-container">
      <FiltersHeader
        typeVariant="subtitle1"
        typeClass="section-header"
        filtersTitle="FILTROS"
        iconClass="filter-icon-close"
        toggleFilter={switchState}
        openFilter={openFilter}
        disableClose={props.disableCloseBtn}
        btnClass={`filter-btn-close`}
      />
      <Grid
        container
        className={openFilter ? "filter-content" : "filter-content collided"}
      >
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2}>
            <DateField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Fecha inicio"
              dateFieldID="filter-date-start"
              fullWidth={true}
              dateFieldClass="date-field"
              inputVariant="outlined"
              dateFieldLabel="Fecha inicio de transacción"
              format="dd/MM/yyyy"
              value={date.start}
              handleChange={handleDateChange.bind(this, "start")}
            />
            <DateField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Fecha corte"
              dateFieldID="filter-date-end"
              fullWidth={true}
              dateFieldClass="date-field"
              inputVariant="outlined"
              dateFieldLabel="Fecha corte de transacción"
              format="dd/MM/yyyy"
              value={date.end}
              handleChange={handleDateChange.bind(this, "end")}
            />
            <SelectField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Tipo"
              fieldID="requestNumber"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={type || ""}
              onChangeHandler={handleCreditorChange}
              options={test}
            />
            <TextFieldComponent
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Monto desde (Balance final)"
              fieldID="requestNumber"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={amountStart}
              onChange={handleChange("amountStart")}
            />
            <TextFieldComponent
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Monto hasta (Balance final)"
              fieldID="requestNumber"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={amountEnd}
              onChange={handleChange("amountEnd")}
            />
          </Grid>
        </Grid>
        {/* FILTERS CONTENT APPLY BUTTON */}
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ marginTop: "35px" }}
        >
          <Button
            onClick={clearFilters}
            variant="contained"
            style={{
              backgroundColor: green[500],
              color: "#FFFFFF",
              fontSize: "12px",
              marginLeft: 10,
              display: "inline-block",
            }}
            disableElevation
          >
            limpiar
          </Button>
          <Button
            onClick={applyFilters}
            variant="contained"
            style={{
              backgroundColor: green[500],
              color: "#FFFFFF",
              fontSize: "12px",
              marginLeft: 10,
              display: "inline-block",
            }}
            disableElevation
          >
            aplicar filtros
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export { DetailsFilters };
