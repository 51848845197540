import React, { useEffect, useState } from "react";
import { errorHandling } from "src/components/SharedComponents/CustomHooks";
import { ConfirmDialog, DialogHeader, DialogFooter } from "src/components/SharedComponents/Dialogs";
import { SearchComponent, SwitchComponent, TextFieldComponent } from "src/components/SharedComponents/Fields";
import { createBusinessRepresentative, fetchBusinessRepresentatives, fetchParticipants, updateBusinessRepresentative } from "src/utils/axios";
import { 
    Grid, Dialog, DialogContent, Button, 
    Card, CardContent, Typography, Divider
} from '@material-ui/core';
import { Filters as ChargingFilters } from '../Filters';
import { DueAssignmentTable } from "../Tables";


export function ConfirmDialogComponent(props) {
    const data = props.data.data;
    let dialogConfirmContent = '';
    switch(props.data.msg) {
        case 'proceed-submit-agent':
            dialogConfirmContent=
                `¿Está seguro que desea enviar el representante a ROHI?.`;
            break;
        default:
            dialogConfirmContent='...';
    }
    return(
        <ConfirmDialog 
            open={props.open}
            handleToggle={props.handleToggle}
            confirmCallback={data.callback}
            data={dialogConfirmContent}
        />
    )
}

export function BusinessAgentDialog(props) {
    return(
        <Dialog   
            fullWidth
            maxWidth="lg"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Representante de Negocios"
                handleClick={props.handleToggleDialog.bind(this, 'business-agent')}
            />
            <DialogContent style={{margin:'2% 0'}}>
                <BusinessAgentContent 
                    {...props}
                />
            </DialogContent>
                <DialogFooter />
        </Dialog>
    )
}

export function BusinessAgentContent(props) {
    const defaultFieldsValue = {
        name : '',
        isActive: false,
        relatedUser:'',
        rohiId: '',
    };
    const rFields = [
        'name',
        'relatedUser',
    ];
    // const action = props.modify ? 'update' : 'create';
    const [fields, setField] = useState(defaultFieldsValue);
    const [errorFields, setErrorFields] = useState({}); // the error field obj (to tell the field if there's an error)
    const [error, setError] = useState(false); // general error variable. Show if there's a field with an error.
    const [file, setFile] = useState('');
    const [requiredFields, setRequiredFields] = useState(rFields);
    const [paymentNegotiation, setPaymentNegotiation] = useState([]);
    const [selected, setSelected] = useState([]);
    const [agentData, setAgentData] = useState({});

    const getFiltersOptions = (option) => {
        return(
            (option ? option : [])
        );
    }

    const validateRequiredFields = (type, fieldToCheck, value) => { // validate fields errors
        let newErrorState = {...errorFields};
        const fieldCheck = (key, value) => {
            newErrorState[key] = 
            (value === '' || value === null || value === undefined || Object.keys(value).length === 0) ? true : false;
        }
        if(type === 'all') {
            requiredFields.forEach(key => {
                fieldCheck(key, fields[key]);
            });
        }else if(type === 'single' && requiredFields.includes(fieldToCheck)) {
            fieldCheck(fieldToCheck, value);
        }
        const hasErr = Object.keys(newErrorState).find(item => newErrorState[item]);
        setError(Boolean(hasErr));
        setErrorFields({...newErrorState});
        return Boolean(hasErr);
    }

    const handleFieldChange = (field, event, value, type) => {
        let newVal;
        switch(field) {
            case 'isActive':
                newVal = value;
                break
            default:
                newVal = event.target ? event.target.value : event.value;
                break;
        }
        validateRequiredFields('single', field, newVal);
        setField(prevState=>({ ...prevState, [field]: newVal})); //this way the state of the object property updates.
    }
    
    const handleEditRow = () => {
        let editData = {uniqueId: props.data?.uniqueId};
        Object.keys(fields).forEach(key => {
            switch(key){
                case 'relatedUser':
                    editData[key] = {
                        name: props.data.relatedUser,
                        value: props.data.relatedUser,
                    };
                    break;
                default:
                    editData[key] = props.data[key];
                    break;
            }
            
        });
        setField({...editData});
    }
   
  
    const createUpdateBusinessAgent = (data, action) => {
        props.setLoading(true);
        const endpoint = action === 'update' ? updateBusinessRepresentative : createBusinessRepresentative;
    
        endpoint(props.token, data).then(response => {
            const afterRequest = action === 'update' ? props.handleAgent?.onUpdate : props.handleAgent?.onCreate;
            if(afterRequest) {
                afterRequest(response.data);
            }
            props.setLoading(false);
            props.handleSimpleDialog('success-dialog');
            props.handleToggleDialog('business-agent');
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleSimpleDialog);
        });
    }

    const handleSaveBtn = () => {
        if(validateRequiredFields('all')) { return }
        let data = {};
        if(agentData?.uniqueId) {
            data = {
                ...agentData,
                name: fields.name,
                relatedUser: fields.relatedUser?.value,
                isActive: fields.isActive
            }
        }else {
            data = {
                ...fields,
                name: fields.name,
                relatedUser: fields.relatedUser,
                isActive: fields.isActive,
                rohiId: fields?.rohiId ? Number(fields.rohiId) : null,
            }
        }
     
        // Object.keys(data).forEach(key => {
        //     if(!data[key]) {
        //         delete data[key];
        //     }
        // });
        const action = data.uniqueId ? 'update' : 'create';
        createUpdateBusinessAgent(data, action);
    }

    useEffect(() => {
        if(props.modify && Object.keys(props.data).length) {
            handleEditRow();
        }
        setAgentData({...props.data});
    }, [props.data]);
    
    return(
        <>
            <Grid container direction="row" spacing={2} style={{padding:'0 16px'}}>
                <TextFieldComponent 
                    xs={5}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Nombre (*)"
                    fieldID="business-agent-name-add"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin='dense'
                    fullWidth
                    placeholder="Digite el nombre del representante..."
                    value={fields.name || ''}
                    onChange={handleFieldChange.bind(this, 'name')}
                    error={errorFields.name}
                    helperText={errorFields.name ? "Introduzca un nombre" : null}
                />
                <SwitchComponent
                    xs={2}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Estado"
                    fieldID="business-agent-isActive-add"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin='dense'
                    fullWidth
                    value={fields.isActive}
                    onChange={handleFieldChange.bind(this, 'isActive')}
                />
                <SearchComponent
                    xs={5}
                    containerClass="field-container"
                    fieldLabel="Usuario de sistema"
                    fieldID="business-agent-related-user-add"
                    fieldInnerLabel="Selecione un usuario"
                    savedValue={props.modify ? fields.relatedUser : null}
                    onInputChange={() => { }}
                    handleChange={handleFieldChange.bind(this, 'relatedUser')}
                    optionType={'users'}
                    clearOnBlur={false}
                    limitTags={1}
                    disabled={props.modify}
                />
            </Grid>
            <Grid container justifyContent="flex-end" style={{padding: '32px 16px 0 0'}}>
                <Button 
                    variant="contained" 
                    className={error ? "action-method-btn disabled" : "action-method-btn save"}
                    disableElevation
                    size="small"
                    onClick={handleSaveBtn}
                    disabled={error}
                >
                    {agentData.uniqueId ? "modificar representante" : 'crear representante'}
                </Button>
            </Grid>
        </>
    );
}

export function MassAssignmentDialogContent(props) {
    const [clientsData, setClientsData] = useState([]);
    const [businessAgents, setBusinessAgents] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    // get clients method
    const getClients = (filters) => {
        props.setLoading(true);
        const endpoint = fetchParticipants({...filters, token: props.token});
        endpoint.then(response => {
            const cleanData = response.data.filter(item => item.workProfileObj);
            const data = cleanData.map(item => {
                return {
                    businessAgent: item.businessAgentName || 'SIN INFORMACIÓN',
                    work: item.workProfileInstitution || 'SIN INFORMACIÓN',
                    fullName: item.fullName || 'SIN INFORMACIÓN',
                    identificationNumber: item.identificationNumber || 'SIN INFORMACIÓN',
                    uniqueId: item.uniqueId,
                    // rating: item.financialProfileObj.rating || 'SIN INFORMACIÓN',
                    // bank: item.financialProfileInstitution || 'SIN INFORMACIÓN',
                }
            });
            setClientsData(data);
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleConfirm);
        });
    }
    const getBusinessAgents = () => {
        props.setLoading(true);
        const endpoint = fetchBusinessRepresentatives({token: props.token});
        endpoint.then(response => {

            setBusinessAgents(response.data);
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleConfirm);
        });
    }

    // apply filters btn.
        // Gets the filter fields selected data, then pass it as parameters
        // in the get clients method and fetch the data in the table.
        // this participant endpoint has differente property names.
    const applyFilters = (fields) => {
        const data = {
            ...fields,
            businessAgents: fields.business_agents,
            work: fields.work_institution,
            fullName: fields.client,
        }
        Object.keys(data).forEach(key => {
            if(!data[key] || data[key].length === 0) {
                delete data[key];
            }
        });
        getClients(data);
        setSelectedFilters({...data});
    }

    useEffect(() => {
        getBusinessAgents();
    }, []);

    return(
        <Grid container direction="row">
            <div style={{marginBottom:'2%', width:'100%'}}>
                <ChargingFilters
                    hideClass="hide"
                    disableCloseBtn={true}
                    applyFilters={applyFilters}
                    setLoading={props.setLoading}
                    token={props.token}
                />
            </div>
            <DueAssignmentTable
                businessAgents={businessAgents}
                data={clientsData}
                handleToggleConfirm={props.handleToggleConfirm}
                handleClients={getClients.bind(this, selectedFilters)}
                setLoading={props.setLoading}
                token={props.token}
            />

        </Grid>
    );
}

export function MassAssignmentDialog(props) {

    const [selectedBusinessAgent, setBusinessAgent] = useState('');
    const [selectedOrder, setOrder] = useState('');

    const handleBusinessAgentChange = (event) => {
        setBusinessAgent(event.target.value);
    }

    const handleOrderChange = (event) => {
        setOrder(event.target.value);
    }
    return(
        <Dialog

            fullWidth
            maxWidth="lg"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Reasignación de clientes"
                handleClick={props.handleToggleDialog.bind(this, 'business-agent-mass-assignment')}
            />
            <DialogContent style={{marginTop: '2%', marginBottom:'2%'}}>
                <MassAssignmentDialogContent
                    businessAgent={selectedBusinessAgent}
                    handleToggleConfirm={props.handleConfirm}
                    handleBusinessAgent={handleBusinessAgentChange}
                    setLoading={props.setLoading}
                    token={props.token}
                />
            </DialogContent>
        </Dialog>
    )
}