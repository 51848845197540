import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import accountingRecordsReducer from "./accountingRecordsReducer";
import actsReducer from "./actsReducer";
import acquaintanceshipCompanyContractsReducer from "./acquaintanceshipCompanyContractsReducer";
import acquaintanceshipPersonContractsReducer from "./acquaintanceshipPersonContractsReducer";
import acquaintanceshipProviderContractsReducer from "./acquaintanceshipProviderContractsReducer";
import addendumContractsReducer from "./addendumContractsReducer";
import assigneeReducer from "./assigneeReducer";
import attorneysReducer from "./attorneysReducer";
import searchReducer from "./autocompleteDebounceReducer.js";
import bankAccountsReducer from "./bankAccountsReducer";
import banksReducer from "./banksReducer";
import blacklistReducer from "./blacklistReducer";
import chargingMethodReducer from "./chargingMethodReducer";
import checksReducer from "./checksReducer";
import clientsReducer from "./clientsReducer";
import companyRepresentativesReducer from "./companyRepresentativesReducer";
import creditCardsReducer from "./creditCardsReducer";
import creditorsReducer from "./creditorsReducer";
import debitAuthorizationContractsReducer from "./debitAuthorizationContractsReducer";
import automaticDebitAuthorizationContractsReducer from "./automaticDebitAuthorizationContractsReducer";
import debtCapacityReducer from "./debtCapacityReducer";
import depositBankAccountsReducer from "./depositBankAccountsReducer";
import expirationReducer from "./expirationReducer";
import fileAttachmentReducer from "./fileAttachmentReducer";
import financialInstitutionsReducer from "./financialInstitutionsReducer";
import fliersReducer from "./fliersReducer";
import institutionsReducer from "./institutionsReducer";
import internationalBlackListReducer from "./internationalBlackListReducer";
import loanContractsReducer from "./loanContractsReducer";
import creditReviewAuthorizationsReducer from "./creditReviewAuthorizationsReducer";
import membershipApplicationsReducer from "./membershipApplicationsReducer";
import nationalitiesReducer from "./nationalitiesReducer";
import notarialPayContractsReducer from "./notarialPayContractsReducer";
import occupationsReducer from "./occupationsReducer";
import outlaycashesReducer from "./outlaycashesReducer";
import outlaychecksReducer from "./outlaychecksReducer";
import outlaysReducer from "./outlaysReducer";
import participantsReducer from "./participantsReducer";
import paymentCapacityReducer from "./paymentCapacityReducer";
import participantsbyExpirationsReducer from "./participantsbyExpirationsReducer";
import privateInstitutionsReducer from "./privateInstitutionsReducer";
import professionsReducer from "./professionsReducer";
import providerContactReducer from "./providerContactReducer";
import providersReducer from "./providersReducer";
import publicInstitutionReducer from "./publicInstitutionsReducer";
import quotationReducer from "./quotationReducer";
import selectedSectionReducer from "./selectedSectionReducer";
import ticketReducer from "./ticketReducer";
import userReducer from "./userReducer";
import cancellationMembershipReducer from "./cancellationMembershipReducer";
import debitCardOrPassbookAccountReducer from "./debitCardOrPassbookAccountReducer";
import savingsAccountContractReducer from "./savingsAccountReducer";
import inputCertificationReducer from "./inputCertificationReducer";
import financialCertificationReducer from "./financialCertificateContractsReducer";
import warrantyReducer from "./warrantyReducer";
import interestReducer from "./interestReducer";
import payrollDeductionFormsReducer from "./payrollDeductionFormReducer";
import affidavitsOfFundsReducer from "./affidavitOfFundsReducer";
import clientAffidavitsOfFundsReducer from "./clientAffidavitOfFunds";
import complianceRegistrationFormsReducer from "./complianceRegistrarionFormsReducer";
import pledgeContractWithoutDivestmentReducer from "./pledgeContractsWithoutDivesment";
import automaticWithdrawalAuthorizationContractsReducer from "./automaticWithdrawalAuthorizationContractsReducer";
import claimsReducer from "./claimsReducer";
import electronicMeansServiceContractsReducer from "./electronicMeansServiceContractsReducer";
import membershipAcquaintanceship from "./membershipAcquaintanceship";
import humanResourcesRepReducer from "./humanResourcesReducer";

const rootReducer = combineReducers({
  accountingRecords: accountingRecordsReducer,
  acts: actsReducer,
  acquaintanceshipCompanyContracts: acquaintanceshipCompanyContractsReducer,
  acquaintanceshipPersonContracts: acquaintanceshipPersonContractsReducer,
  acquaintanceshipProviderContracts: acquaintanceshipProviderContractsReducer,
  addendumContracts: addendumContractsReducer,
  automaticWithdrawalAuthorizationContracts: automaticWithdrawalAuthorizationContractsReducer,
  asignees: assigneeReducer,
  autocompleteDebunce: searchReducer,
  affidavitsOfFunds: affidavitsOfFundsReducer,
  clientAffidavitsOfFunds: clientAffidavitsOfFundsReducer,
  complianceRegistrationForms: complianceRegistrationFormsReducer,
  attorneys: attorneysReducer,
  bankAccounts: bankAccountsReducer,
  banks: banksReducer,
  blacklist: blacklistReducer,
  chargingMethods: chargingMethodReducer,
  checks: checksReducer,
  clients: clientsReducer,
  companyRepresentatives: companyRepresentativesReducer,
  creditCards: creditCardsReducer,
  creditors: creditorsReducer,
  claim: claimsReducer,
  debitAuthorizationContracts: debitAuthorizationContractsReducer,
  debitCardOrPassbookAccountContracts: debitCardOrPassbookAccountReducer,
  automaticDebitAuthorizationContracts: automaticDebitAuthorizationContractsReducer,
  debtCapacities: debtCapacityReducer,
  depositBankAccounts: depositBankAccountsReducer,
  electronicMeansServiceContracts: electronicMeansServiceContractsReducer,
  expirations: expirationReducer,
  fileAttachment: fileAttachmentReducer,
  financialInstitutions: financialInstitutionsReducer,
  fliers: fliersReducer,
  interests: interestReducer,
  institutions: institutionsReducer,
  inputCertificationContracts: inputCertificationReducer,
  financialCertificationContracts: financialCertificationReducer,
  internationalBlackLists: internationalBlackListReducer,
  loanContracts: loanContractsReducer,
  creditReviewAuthorizations: creditReviewAuthorizationsReducer,
  membershipApplications: membershipApplicationsReducer,
  membershipAcquaintanceship: membershipAcquaintanceship,
  nationalities: nationalitiesReducer,
  notarialPayContracts: notarialPayContractsReducer,
  occupations: occupationsReducer,
  outlayCashes: outlaycashesReducer,
  outlayChecks: outlaychecksReducer,
  outlays: outlaysReducer,
  HumanResources: humanResourcesRepReducer,
  participants: participantsReducer,
  participantsbyExpirations: participantsbyExpirationsReducer,
  payrollDeductionForms: payrollDeductionFormsReducer,
  paymentCapacities: paymentCapacityReducer,
  privateInstitutions: privateInstitutionsReducer,
  cancellationMembershipContracts: cancellationMembershipReducer,
  professions: professionsReducer,
  pledgeContractsWithoutDivestment: pledgeContractWithoutDivestmentReducer,
  providerContacts: providerContactReducer,
  providers: providersReducer,
  publicInstitutions: publicInstitutionReducer,
  quotations: quotationReducer,
  savingsAccountContracts: savingsAccountContractReducer,
  selectedSection: selectedSectionReducer,
  tickets: ticketReducer,
  user: userReducer,
  warranties: warrantyReducer,
});
export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
