
import { ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES } from '../actions';

export default function(state: IAcquaintanceshipCompanyContract[] = [], action: IAction<IAcquaintanceshipCompanyContract[]>): IAcquaintanceshipCompanyContract[] {
    switch(action.type) {
        case ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES.FETCH_ALL:
            return action.payload || [];
        case ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES.FETCH:
            return state.map(ACC => (action.payload !== undefined && ACC.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: ACC);
        case ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES.UPDATE:
            return state.map(ACC => (action.payload !== undefined && ACC.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : ACC);
        case ACQUAINTANCESHIP_COMPANY_CONTRACT_TYPES.DELETE:
            return state.filter(ACC => action.payload !== undefined && ACC.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}