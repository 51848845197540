import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { ToolbarComponent } from "./../../../components/SharedComponents/Toolbar";
import { ApprovementTable, TicketsTable } from "./../Tables";
import { ConfirmDialogComponent } from "./../Dialogs";
import {
  SuccessDialog,
  ErrorDialog,
  SimpleConfirmDialog,
} from "../../../components/SharedComponents/Dialogs";
import { Filters } from "./../Filters";
import {
  fetchLoanApprobation,
  updateLoanApprobation,
  fetchApprobationActs,
  fetchParticipants,
  fetchTicket,
  fetchTickets,
  createApprobationAct,
  generateLoanApprobations,
  fetchQuotation as fetchQ,
  updateQuotation as updateQ,
} from "./../../../utils/axios";
import { allWarranty, financialConfig } from "src/utils";
import LoadingDialog from "../../../components/SharedComponents/LoadingScreen";
import { errorHandling } from "../../../components/SharedComponents/CustomHooks";
import "./../index.css";
import { Button, Divider, Grid, Modal } from "@material-ui/core";
import {
  NumericTextField,
  DateField,
  TextFieldComponent,
} from "../../../components/SharedComponents/Fields";

function ApprovementLoanView(props) {
  const limitRequest = { limit: 10 };
  const { token } = props.user;
  const [loading, setLoading] = useState(false);

  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });
  const [clients, setClients] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [loanApprobations, setLoanApprobations] = useState([]);
  const [acts, setActs] = useState([]);

  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [quotation, setQuotation] = useState({});
  const [ticket, setTicket] = useState({});
  const [originalAmount, setOriginalAmount] = useState(0);
  const [comments, setComments] = useState("");

  useEffect(() => {
    getTickets();
    getClients();
    getActs();
  }, []);

  useEffect(() => {
    if (Object.keys(modalData).length > 0) {
      getQuotation();
      setOriginalAmount(modalData.originalAmount || "");
      getTicket();
      setComments(modalData.comments || "");
    }
  }, [modalData]);

  const getClients = () => {
    setLoading(true);
    const endpoint = fetchParticipants({
      participantType: "client",
      token: token,
    });

    endpoint
      .then((response) => {
        const data = response.data.map((item) => {
          return {
            name: item.fullName,
            idNumber: item.identificationNumber,
            uniqueId: item.uniqueId,
          };
        });
        setClients(data);
        setLoading(false);
      })
      .catch((errors) => {
        errorHandling(errors, handleToggleSimpleDialog);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTickets = useCallback(async (data) => {
    setLoading(true);
    try {
      let ticketsData = [];

      if (!data) {
        let endpoint = await fetchTickets({
          requestType: "outlay",
          token: token,
          filterFinishedTickets: true,
        });
        ticketsData = endpoint.data.filter((x) => x.status !== "cancelled");
      } else {
        ticketsData = data.filter((x) => x.status !== "cancelled");
      }

      for (let item of ticketsData) {
        const approvalObj =
          item.steps.find((x) => (x = x.approvalObj)).approvalObj || {};

        item.approvalObjStatus = approvalObj.status || "";
        item.actNumber = approvalObj.actNumber || "";
        item.participantName = item.participantDetail.fullName || "N/A";
      }
      ticketsData = ticketsData.filter((x) => x.actNumber === "");
      ticketsData = ticketsData.filter(
        (x) => x.approvalObjStatus !== "reclassified"
      );

      setTickets(ticketsData);
      setLoading(false);
    } catch (err) {
      console.log(err);
      errorHandling(err, handleToggleSimpleDialog);
    }
  }, []);

  const getActs = () => {
    setLoading(true);
    const endpoint = fetchApprobationActs({
      token: token,
      type: "loan_approbation",
    });
    endpoint
      .then((response) => {
        const data = response.data.map((item) => {
          return {
            actNumber: item.actNumber,
            uniqueId: item.uniqueId,
          };
        });
        setActs(data);
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const getLoanApprobations = useCallback(async (data) => {
    setLoading(true);
    try {
      let loan = await fetchLoanApprobation(token, data ? data : {});
      const loanData = loan.data.results ? loan.data.results : loan.data;

      const dataToUse = loanData.map((item) => {
        return {
          ...item,
          ticketReferenceCode: item.ticketRefCode,
          participantName: item.participantName,
        };
      });

      for (let element of loanData) {
        const act = await fetchApprobationActs({
          token: token,
          loan_approbation: element.uniqueId,
        });
        if (act.data.length > 0) {
          element.actNumber = act.data[0].actNumber;
          element.actUniqueId = act.data[0].uniqueId;
          element.actDate = act.data[0].creationDate;
        }
        element.disabled = true;
      }
      setLoanApprobations(dataToUse);
    } catch (err) {
      console.log(err);
      errorHandling(err, handleToggleSimpleDialog);
    } finally {
      setLoading(false);
    }
  }, []);

  const generateNewApprobations = () => {
    setLoading(true);

    const endpoint = generateLoanApprobations(token, {
      tickets: selected,
    });
    endpoint
      .then((response) => {
        setLoanApprobations(response.data);
        setLoading(false);
        handleToggleSimpleDialog("success-dialog");
        let list = [...tickets];
        for (let element of selected) {
          list = list.filter((ticket) => ticket !== element);
        }

        setTickets(list);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const createAct = () => {
    setLoading(true);
    let approbations = [];
    for (let item of loanApprobations) {
      const statuses = ["approved", "known", "denied"];
      if (statuses.includes(item.status)) {
        approbations.push(item.uniqueId);
      }
    }

    const act = {
      creation_date: new Date().toISOString().split("T")[0],
      loan_approbation: approbations,
      type: "loan_approbation",
    };

    const endpoint = createApprobationAct(token, act);
    endpoint
      .then((response) => {
        generateNewApprobations();
        handleToggleSimpleDialog("success-dialog");
      })
      .catch((errors) => {
        errorHandling(errors, handleToggleSimpleDialog);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAutoCompleteChange = (newVal, currRow, row) => {
    const approbation = {
      uniqueId: row.uniqueId,
      responsibleForApproval: currRow.value,
      status: row.status,
    };
    const endpoint = updateLoanApprobation(token, approbation);
    endpoint
      .then((response) => {
        generateNewApprobations();
        handleToggleSimpleDialog("success-dialog");
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const removeApprobationhandle = (uniqueId, that) => {
    const currApprobations = loanApprobations.filter(
      (item) => item.uniqueId !== uniqueId
    );

    let dataToSend = [...tickets];
    const ticketRemoved = selected.filter(
      (item) => item.uniqueId === modalData.ticketId
    );

    dataToSend.push(ticketRemoved[0]);

    Promise.resolve()
      .then(() => setLoanApprobations(currApprobations))
      .then(() => setOpenModal(false))
      .then(() => getTickets(dataToSend))
      .then(() => setSelected([]))
      .then(() => handleToggleSimpleDialog("success-dialog"));
  };

  const getQuotation = () => {
    fetchQ(token, modalData.quotationId)
      .then((res) => {
        setQuotation(res.data.results ? res.data.results : res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTicket = () => {
    fetchTicket(token, modalData.ticketId)
      .then((res) => {
        setTicket(res.data.results ? res.data.results : res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeAmount = () => {
    return (event) => {
      let data = { ...quotation, originalAmount: event.value };
      setOriginalAmount(event.value);
      setQuotation(data);
    };
  };

  const handleChangeComment = () => {
    return (event) => {
      let data = { ...ticket, comments: event.target.value };
      setComments(event.target.value);
      setTicket(data);
    };
  };

  const handleSubmit = () => {
    updateQ(token, quotation.uniqueId, quotation)
      .then((res) => {
        generateNewApprobations();
        setOpenModal(false);
      })
      .catch((err) => {
        console.log(err);
      });

    const approbation = {
      uniqueId: modalData.uniqueId,
      comments: comments,
    };
    const endpoint = updateLoanApprobation(token, approbation);
    endpoint
      .then((response) => {
        generateNewApprobations();
        handleToggleSimpleDialog("success-dialog");
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };

  const applyFilters = (fields) => {
    let data = fields;
    if (Object.keys(fields).length === 0) {
      data = limitRequest;
    }
    getLoanApprobations(data);
  };

  const handleModal = (data) => {
    let dataToSend = {
      ...data,
      financialConfig: financialConfig.find(
        (item) => item.value === data.financialConfig
      ).label,
      warrantyType: allWarranty.find((item) => item.value === data.warrantyType)
        .label,
    };
    setModalData(dataToSend);
    setOpenModal(true);
  };

  const updateLoanApprobationStatus = (token, options) => {
    try {
      const endpoint = updateLoanApprobation(token, options);
      endpoint
        .then((response) => {
          if (options.status !== "postpone") {
            generateNewApprobations();
          }
          setOpenModal(false);
          setLoading(false);
        })
        .catch((errors) => {
          setLoading(false);
          errorHandling(errors, handleToggleSimpleDialog);
        });
    } catch (err) {
      console.log(err);
      errorHandling(err, handleToggleSimpleDialog);
    }
  };

  return (
    <div style={{ padding: "20px 20px 10% 20px" }}>
      <LoadingDialog open={loading} />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle="Solicitudes de Prestamo"
        btnVariant="contained"
      />
      <div
        style={{
          padding: "1%",
          border: "1px solid #EEEEEE",
          borderTop: "none",
        }}
      >
        <Filters
          applyFilters={applyFilters}
          listData={{
            clients: clients,
            tickets: tickets,
            acts: acts,
          }}
        />

        <TicketsTable
          data={tickets}
          selected={selected}
          setSelected={setSelected}
          title={"Tickets"}
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          generateApprobations={generateNewApprobations}
        />
        <ApprovementTable
          data={loanApprobations}
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          createAct={createAct}
          handleModal={handleModal}
          handleAutoCompleteChange={handleAutoCompleteChange}
        />
      </div>

      {simpleDialog.name === "confirm-dialog" ? (
        <ConfirmDialogComponent
          open={true}
          data={simpleDialog}
          handleToggle={handleToggleSimpleDialog}
        />
      ) : null}

      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}

      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}

      {simpleDialog.name === "error-dialog" ? (
        <ErrorDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="calculator-container">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-title">Datos del socio</h3>
              <Divider />
            </Grid>
            <TextFieldComponent
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Nombre del socio"
              fieldID="participantName"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={modalData.participantName}
              disabled={true}
            />
            <TextFieldComponent
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Institución laboral"
              fieldID="workInstitution"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={modalData ? modalData.partWorkProfInsititution : ""}
              disabled={true}
            />
            <NumericTextField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Tiempo laboral"
              fieldID="workTime"
              fieldClass="fields"
              margin="dense"
              fieldVariant="outlined"
              fullWidth
              allowLeadingZeros={false}
              thousandSeparator={true}
              decimalScale={0}
              fixedDecimalScale={true}
              value={modalData ? modalData.partEmploymentLength : ""}
              disabled={true}
            />
            <TextFieldComponent
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Cargo"
              fieldID="position"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={modalData ? modalData.partPosition : ""}
              disabled={true}
            />
            <NumericTextField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Salario"
              fieldID="salary"
              fieldClass="fields"
              margin="dense"
              fieldVariant="outlined"
              fullWidth
              prefix="$"
              allowLeadingZeros={false}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              value={modalData ? modalData.partSalary : ""}
              disabled={true}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-title">Datos de la solicitud</h3>
              <Divider />
            </Grid>
            <DateField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Fecha/Hora"
              dateFieldID="filter-last-outlay-date"
              fullWidth={true}
              dateFieldClass="date-field"
              inputVariant="outlined"
              dateFieldLabel="Fecha/Hora"
              format="dd/MM/yyyy"
              value={modalData.requestDate}
              dateType="participantPaymentDay"
              disabled={true}
            />
            <TextFieldComponent
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Número de solicitud"
              fieldID="requestNumber"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={modalData.ticketReferenceCode}
              disabled={true}
            />
            <NumericTextField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Monto solicitado"
              fieldID="requestAmount"
              fieldClass="fields"
              margin="dense"
              fieldVariant="outlined"
              fullWidth
              prefix="$"
              allowLeadingZeros={false}
              thousandSeparator={true}
              decimalScale={0}
              fixedDecimalScale={true}
              value={modalData.requestedAmount}
              disabled={true}
            />
            <NumericTextField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Monto aprobado"
              fieldID="approvedAmount"
              fieldClass="fields"
              margin="dense"
              fieldVariant="outlined"
              fullWidth
              prefix="$"
              allowLeadingZeros={false}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              value={originalAmount}
              onChange={handleChangeAmount(modalData)}
            />
            <NumericTextField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Tasa de interes"
              fieldID="interest"
              fieldClass="fields"
              margin="dense"
              fieldVariant="outlined"
              fullWidth
              suffix="%"
              allowLeadingZeros={false}
              thousandSeparator={true}
              decimalScale={0}
              fixedDecimalScale={true}
              value={modalData.interestRate}
              disabled={true}
            />
            <TextFieldComponent
              xs={4}
              fieldClass="fields"
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Configuración financiera"
              fieldID="financialConfig"
              fieldInnerLabel="Configuración financiera"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={modalData.financialConfig}
              disabled={true}
            />
            <TextFieldComponent
              xs={8}
              fieldClass="fields"
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Tipo de credito"
              fieldID="financialConfig"
              fieldInnerLabel="Tipo de credito"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={modalData.warrantyType}
              disabled={true}
            />
            <NumericTextField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Plazo"
              fieldID="interest"
              fieldClass="fields"
              margin="dense"
              fieldVariant="outlined"
              fullWidth
              allowLeadingZeros={false}
              thousandSeparator={true}
              decimalScale={0}
              fixedDecimalScale={true}
              value={modalData.timeLimit || ""}
              disabled={true}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-title">Otros Datos</h3>
              <Divider />
            </Grid>
            <DateField
              xs={4}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Fecha de acta"
              dateFieldID="actDate"
              fullWidth={true}
              dateFieldClass="date-field"
              inputVariant="outlined"
              dateFieldLabel="Fecha de acta"
              format="dd/MM/yyyy"
              value={modalData.requestDate}
              dateType="actDate"
              disabled={true}
            />
            <TextFieldComponent
              xs={8}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Responsable de aprobación"
              fieldID="approbationResponsable"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              value={modalData.responsibleForApproval}
              disabled={true}
            />
            <TextFieldComponent
              xs={6}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Recomendación del analista"
              fieldID="recomendation"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              multiline={true}
              rows={4}
              value={modalData.analysRecomendation}
              disabled={true}
            />
            <TextFieldComponent
              xs={6}
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Comentarios"
              fieldID="comments"
              fieldClass="fields"
              fieldVariant="outlined"
              margin="dense"
              fullWidth
              multiline={true}
              rows={4}
              value={comments}
              onChange={handleChangeComment(modalData)}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-title">Acciones</h3>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                className={"action-method-btn save"}
                style={{ marginRight: 5 }}
                onClick={() => handleSubmit()}
              >
                Guardar
              </Button>
              <Button
                className={
                  modalData.status === "pending_approval" ||
                  modalData.status === "postpone"
                    ? "action-method-btn save"
                    : "action-method-btn disabled"
                }
                style={{ marginRight: 5 }}
                disabled={
                  modalData.status === "pending_approval" ||
                  modalData.status === "postpone"
                    ? false
                    : true
                }
                onClick={() =>
                  updateLoanApprobationStatus(token, {
                    uniqueId: modalData.uniqueId,
                    status: "approved",
                  })
                }
              >
                Aprobar
              </Button>
              <Button
                className={
                  modalData.status === "approved" ||
                  modalData.status === "denied" ||
                  modalData.status === "postpone"
                    ? "action-method-btn save"
                    : "action-method-btn disabled"
                }
                style={{ marginRight: 5 }}
                disabled={
                  modalData.status === "approved" ||
                  modalData.status === "denied" ||
                  modalData.status === "postpone"
                    ? false
                    : true
                }
                onClick={() =>
                  updateLoanApprobationStatus(token, {
                    uniqueId: modalData.uniqueId,
                    status: "known",
                  })
                }
              >
                Conocer
              </Button>
              <Button
                className={"action-method-btn save"}
                style={{ marginRight: 5 }}
                onClick={() =>
                  updateLoanApprobationStatus(
                    token,
                    { uniqueId: modalData.uniqueId, status: "postpone" },
                    removeApprobationhandle(modalData.uniqueId)
                  )
                }
              >
                Posponer
              </Button>
              <Button
                className={"action-method-btn save"}
                style={{ marginRight: 5 }}
                onClick={() =>
                  updateLoanApprobationStatus(token, {
                    uniqueId: modalData.uniqueId,
                    status: "reclassified",
                  })
                }
              >
                Reclasificar
              </Button>
              <Button
                className={
                  modalData.status === "pending_approval" ||
                  modalData.status === "postpone"
                    ? "action-method-btn--blue"
                    : "action-method-btn disabled"
                }
                style={{ marginRight: 5 }}
                disabled={
                  modalData.status === "pending_approval" ||
                  modalData.status === "postpone"
                    ? false
                    : true
                }
                onClick={() =>
                  updateLoanApprobationStatus(token, {
                    uniqueId: modalData.uniqueId,
                    status: "denied",
                  })
                }
              >
                Denegar
              </Button>
              <Button
                className={"action-method-btn cancel"}
                onClick={() => setOpenModal(false)}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps)(ApprovementLoanView);
