// import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
// import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {Card, CardContent, Dialog, DialogContent, Divider, createMuiTheme, ThemeProvider } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import { Details, Schedule } from "@material-ui/icons";
// import Badge from '@material-ui/core/Badge'; 
import Grid from '@material-ui/core/Grid'; 

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,} from '@material-ui/core';

import Loading from "../../../components/Loading";
import AlertDialog from "../../../components/AlertDialog";
import { translations, termReduction, transactionTypeAP, fractionationType, catchError } from "../../../utils";
import {
  API_KEY,
  fetchNewOutlays as fetchO,
  fetchParticipant as fetchP,
  fetchTicket as fetchT
} from "../../../utils/axios";
import GetAppIcon from '@material-ui/icons/GetApp';

import QuotationDialog from "./quotationDialog"
import axios from "axios";
import TicketDialog from "./ticketDialog";
import { FilterList } from "@material-ui/icons";

const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        "&.Mui-disabled": {
          backgroundColor: "#d8d4d4", // Color gris
        },
      },
    },
  },
});

const styles = createStyles({
  anchorTopRight: {
    top: "130px",
    right: "65px",
},
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input2: {
    height: 1
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "90%",
  },
  item: {
    marginBottom: 18
  },
});

const GreenTypography = withStyles({
  root: {
    color: "#4caf50"
  }
})(Typography);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#c9c6c6",
    fontWeight: "bold",
    fontSize: 18
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

class OutlayView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachmentUrl: "",
      client: [],
      errorMessage: "",
      loading: false,
      openCancel: false,
      openEditTicket: false,
      openQuotationDialog: false,
      openNext: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOpenEditTicket = this.handleOpenEditTicket.bind(this);
    this.handleCloseEditTicket = this.handleCloseEditTicket.bind(this);
    this.handleOpenQuotationDialog = this.handleOpenQuotationDialog.bind(this);
    this.handleCloseQuotationDialog = this.handleCloseQuotationDialog.bind(
      this
    );
    this.generateOutlayTemplate = this.generateOutlayTemplate.bind(this);
  }


  handleOpenEditTicket() {
    this.setState({ openEditTicket: true });
  }
  handleCloseEditTicket() {
    this.setState({ openEditTicket: false });
  }
  handleOpenQuotationDialog() {
    this.setState({ openQuotationDialog: true });
  }
  handleCloseQuotationDialog() {
    this.setState({ openQuotationDialog: false });
  }

  generateOutlayTemplate() {
    this.setState({ loading: true });
    axios({
      headers: {
        Authorization: "Bearer " + this.props.user.token,
        "Content-Type": "text/html",
      },
      method: "get",
      url: `${API_KEY}/outlay/outlay/render_html`,
      params: {
        id_dbs: this.props.outlayId,
      },
    })
      .then((res) => {
        const file = new Blob([res.data], { type: "text/html" });
        const fileURL = URL.createObjectURL(file);
        const NewWindow = window.open();
        NewWindow.location.href = fileURL;
      })
      .catch((err) => {
        catchError(err, this, translations.OUTLAY);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }

  componentDidMount() {
    if (this.props.outlayId !== '') {
      this.setState({ loading: true });
      let token = this.props.user.token;
      fetchO(token, {
        id_dbs: this.props.outlayId,
      })
        .then((res) => {
          const curOutlay = res.data.results ? res.data.results : res.data;
          this.setState({
            loading: false,
            client: curOutlay,
          });
        })
        .catch((error) => {
          console.error("Error al realizar la solicitud:", error);
          this.setState({ loading: false });
        });
    }
  }

  capitalize(str) {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
 

  render() {
    const { classes, open, handleClose } = this.props;
    const { client, liquidationForm, pcpData } = this.state;

    if (!client || client.length === 0) {
      return <Loading message={translations.LOADING} />; 
    }

    return (
      client &&
      client.map((socio) => {
        return (
          <form key={socio.idDbs} className={classes.root}>
            <QuotationDialog
              handleClose={this.handleCloseQuotationDialog}
              open={this.state.openQuotationDialog}
              token={this.props.user.token}
              outlayId={this.props.outlayId}
              quotationCode={socio.idCotizacionDms}
            />
            <TicketDialog
              handleClose={this.handleCloseEditTicket}
              open={this.state.openEditTicket}
              token={this.props.user.token}
              outlayId={this.props.outlayId}
              uniqueId={socio.idTicketDms}
            />
          <ThemeProvider theme={theme}>
            <Paper className={classes.paper}>
              <GreenTypography
                variant={"h6"}
                className={"section-header"}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <FilterList style={{ fontSize: 20, marginRight: ".3rem" }} />
                {`${translations.OUTLAY}`}
                <IconButton
                  // className={classes.button}
                  onClick={this.generateOutlayTemplate}
                >
                  <GetAppIcon />
                </IconButton>
              </GreenTypography>
              <Divider style={{ margin: "1rem 0" }} />
                <div>
                  <Grid style={{ margin: "30px 0px" }}>
                    <Grid
                      container
                      direction="row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <>
                          <Typography>Creado Por</Typography>
                          <TextField
                            value={socio.nombreOficial}
                            key={socio.nombreOficial}
                            style={{ width: "180px" }}
                            variant="outlined"
                            disabled={true}
                            InputProps={{
                              classes: { input: this.props.classes.input2 },
                            }}
                          />
                        </>
                      </div>
                      <div>
                        <>
                          <Typography>{translations.TICKET}</Typography>
                          <TextField
                            value={socio.ticketCode}
                            key={socio.ticketCode}
                            style={{ width: "180px" }}
                            variant="outlined"
                            disabled={true}
                            InputProps={{
                              classes: { input: this.props.classes.input2 },
                            }}
                          />
                          <IconButton
                            // className={classes.button}
                            onClick={this.handleOpenEditTicket}
                          >
                            <LinkIcon />
                          </IconButton>
                        </>
                      </div>
                      <div>
                        <>
                          <Typography>{translations.QUOTATION}</Typography>
                          <TextField
                            value={socio.quotationCode}
                            key={socio.quotationCode}
                            style={{ width: "180px" }}
                            variant="outlined"
                            disabled={true}
                            InputProps={{
                              classes: { input: this.props.classes.input2 },
                            }}
                          />
                          <IconButton
                            // className={classes.button}
                            onClick={this.handleOpenQuotationDialog}
                          >
                            <LinkIcon />
                          </IconButton>
                        </>
                      </div>
                      <div style={{ marginRight: 50 }}>
                        <>
                          <Typography>{translations.STATUS}</Typography>
                          <TextField
                            value={socio.dbsStatus}
                            key={socio.dbsStatus}
                            style={{ width: "180px" }}
                            variant="outlined"
                            disabled={true}
                            InputProps={{
                              classes: { input: this.props.classes.input2 },
                            }}
                          />
                        </>
                      </div>
                    </Grid>
                  </Grid>
                  <GreenTypography
                    variant={"h6"}
                    className={"section-header"}
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Details
                      style={{ fontSize: 20, marginRight: ".3rem" }}
                    />
                    Detalle
                  </GreenTypography>
                  <Divider style={{ margin: "1rem 0" }} />
                  <Grid item container spacing={3}>
                    <Grid item xs={8}>
                      <Typography>{translations.CLIENT}</Typography>
                      <TextField
                        value={socio.nombreSocio}
                        key={socio.nombreSocio}
                        style={{ width: "690px" }}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Fecha desembolso</Typography>
                      <TextField
                        value={socio.fechaOtorgamiento}
                        key={socio.fechaOtorgamiento}
                        style={{ width: "300px" }}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Fecha inicio</Typography>
                      <TextField
                        value={socio.fechaInicio}
                        key={socio.fechaInicio}
                        style={{ width: "300px" }}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Fecha caducidad</Typography>
                      <TextField
                        value={socio.expirationDate}
                        key={socio.expirationDate}
                        style={{ width: "300px" }}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Primera Cuota</Typography>
                      <TextField
                        value={socio.cuota && socio.cuota?.map((cuota) => cuota)}
                        key={socio.cuota && socio.cuota?.map((cuota) => cuota)}
                        style={{ width: "300px" }}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Plazo</Typography>
                      <TextField
                        value={socio.plazo}
                        key={socio.plazo}
                        style={{ width: "300px" }}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Tasa de interes</Typography>
                      <TextField
                        value={`${socio.tasaMensual}%`}
                        key={socio.tasaMensual}
                        style={{ width: "300px" }}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Tipo de prestamo</Typography>
                      <TextField
                        value={this.capitalize(socio.tipoTransaccion)}
                        key={socio.tipoTransaccion}
                        style={{ width: "300px" }}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Clasificacion de prestamos</Typography>
                      <TextField
                        value={this.capitalize(socio.clasificacionTransaccion)}
                        key={socio.clasificacionTransaccion}
                        style={{ width: "300px" }}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Tipo de fraccionamiento</Typography>
                      <TextField
                        value={
                          fractionationType.find(
                            (z) => z.value === socio.tipoFraccionamiento || {}
                          ).label || ''
                        }
                        key={socio.tipoFraccionamiento}
                        style={{ width: "300px" }}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Monto Prestamo</Typography>
                      <TextField
                        value={parseFloat(socio.montoSolicitado).toLocaleString(
                          "en-US",
                          {
                            currency: "USD",
                          }
                        )}
                        key={socio.montoSolicitado}
                        style={{ width: "300px" }}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>Formas de liquidacion</Typography>
                      <TextField
                        value={
                          "Ahorros: " +
                          parseFloat(
                            socio.liquidationForm.ahorros
                          ).toLocaleString("en-US", {
                            currency: "USD",
                          }) +
                          " | " +
                          "Transferencia: " +
                          parseFloat(
                            socio.liquidationForm.electronico
                          ).toLocaleString("en-US", {
                            currency: "USD",
                          }) +
                          " | " +
                          "ReproCap: " +
                          parseFloat(
                            socio.liquidationForm.reprogCap
                          ).toLocaleString("en-US", {
                            currency: "USD",
                          })
                        }
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                          classes: { input: this.props.classes.input2 },
                        }}
                        style={{ width: "690px" }}
                      />
                    </Grid>
                  </Grid>
                  {this.state.pcpData !== [] ? (
                    <div>
                      <GreenTypography
                    variant={"h6"}
                    className={"section-header"}
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Schedule
                      style={{ fontSize: 20, marginRight: ".3rem" }}
                    />
                    Cuotas programadas
                  </GreenTypography>
                  <Divider style={{ margin: "1rem 0" }} />
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align={"center"}>
                                {translations.DATE}
                              </StyledTableCell>
                              <StyledTableCell align={"center"}>
                                {translations.AMOUNT_2}
                              </StyledTableCell>
                              <StyledTableCell align={"center"}>
                                {"Tipo de Bono"}
                              </StyledTableCell>
                              <StyledTableCell align={"center"}>
                                Tipo de Reduccion
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {socio.pcpData &&
                              socio.pcpData.map((item, indx) => {
                                return (
                                  <StyledTableRow key={indx}>
                                    <StyledTableCell align={"center"}>
                                      {item.pcpDate}
                                    </StyledTableCell>
                                    <StyledTableCell align={"center"}>
                                      {item.pcpAmount.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                      })}
                                    </StyledTableCell>
                                    <StyledTableCell align={"center"}>
                                      {
                                        transactionTypeAP.find(
                                          (z) =>
                                            z.value === item.sppTransactionType
                                        ).label
                                      }
                                    </StyledTableCell>
                                    <StyledTableCell align={"center"}>
                                      {
                                        termReduction.find(
                                          (x) => x.value === item.termReduction
                                        ).label
                                      }
                                    </StyledTableCell>
                                  </StyledTableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ) : null}
                </div>
            </Paper>
            </ThemeProvider>
          </form>
        );
      })
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(connect(mapStateToProps, {})(OutlayView));
