import * as React from "react";
import { connect } from "react-redux";
import { catchError, filesGeneration, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import GetApp from "@material-ui/icons/GetApp";

import {
  fetchSavingsAccountContracts,
  fetchFinancialCertificationContracts,
} from "../../actions";
import {
  fetchSavingsAccountContracts as fetchSA,
  fetchFinancialCertificationContracts as fetchFC,
} from "../../utils/axios";
import SavingsAccountSearchSection from "../../containers/SavingsAccount/SearchSection";
import SavingsAccountForm from "../../containers/SavingsAccount/Form";
import FinancialCertificateSearchSection from "../../containers/FinancialCertificates/SearchSection";
import FinancialCertificateForm from "../../containers/FinancialCertificates/Form";

const styles: any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

interface ISelectInputProps {
  classes: {
    button: string;
    root: string;
  };

  user: IUser;
  data: any[];
  datas: any[];
  selectedId: string[];
  type: string;
  addContract(Id: string): void;
  deleteContract(Id: string): void;
  handleSelectChange(params: any): any;
  fetchSavingsAccountContracts(params: any): IAction<any[]>;
  fetchFinancialCertificationContracts(params: any): IAction<any[]>;
}
interface ISelectInputState {
  [key: string]: string | number | boolean | string[] | any | undefined;

  // data: any[];
  formDialog: boolean;
  loading: boolean;
  open: boolean;
  id: string;
  debtors: any;
}

class SelectInput extends React.Component<
  ISelectInputProps,
  ISelectInputState
> {
  public state: ISelectInputState = {
    // data: [],
    formDialog: false,
    id: "",
    loading: false,
    open: false,
    debtors: [],
  };
  constructor(props: ISelectInputProps) {
    super(props);

    this.generateFile = this.generateFile.bind(this);
    this.findData = this.findData.bind(this);
    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  public componentDidMount() {
    this.setState({ loading: true });

    // if (this.props.type === 'savings_account') {
    fetchSA({ token: this.props.user.token })
      .then((res) => {
        this.props.fetchSavingsAccountContracts(
          res.data.results ? res.data.results : res.data
        );
        this.setState({
          // data: res.data as any[],
          loading: false,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
    // }
    // else if (this.props.type === 'financial_certificate') {
    fetchFC({ token: this.props.user.token })
      .then((res) => {
        this.props.fetchFinancialCertificationContracts(
          res.data.results ? res.data.results : res.data
        );
        this.setState({
          // data: res.data as any[],
          loading: false,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });

    // }
  }
  public openDialogHandler() {
    if (this.props.type === "savings_account") {
      fetchSA({ token: this.props.user.token })
        .then((res) => {
          this.props.fetchSavingsAccountContracts(
            res.data.results ? res.data.results : res.data
          );
          this.setState({
            data: res.data.results ? res.data.results : (res.data as any[]),
            loading: false,
          });
        })
        .catch((err) => {
          catchError(err, this);
        });
    } else if (this.props.type === "financial_certificate") {
      fetchFC({ token: this.props.user.token })
        .then((res) => {
          this.props.fetchFinancialCertificationContracts(
            res.data.results ? res.data.results : res.data
          );
          this.setState({
            data: res.data.results ? res.data.results : (res.data as any[]),
            loading: false,
          });
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
    this.setState({ open: true });
  }
  public closeDialogHandler() {
    this.setState({ open: false });
  }
  public openFormDialog() {
    this.setState({ formDialog: true });
  }
  public closeFormDialog() {
    this.setState({ formDialog: false });
  }
  public emptyFunction() {
    return null;
  }
  public handleItemClick(id: string) {
    this.props.addContract(id);
    this.setState({ open: false });
  }
  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.props.deleteContract(id);
    };
  }
  public findData(uniqueId: string) {
    const curData: any | undefined =
      this.props.type === "savings_account"
        ? this.props.data.find((client) => client.uniqueId === uniqueId)
        : this.props.datas.find((client) => client.uniqueId === uniqueId);
    const types: any = {
      coopsan: "coopSan",
      youth: "Infantil",
      functional: "Funcional",
      institutional: "Institutional",
      in_sight: "A la vista",
      planned: "Planificado",
    };
    if (curData) {
      return (
        <TableRow key={curData.uniqueId}>
          <TableCell>
            {curData.title || curData?.mainMemberName || ""}
          </TableCell>
          <TableCell>{curData.contractDate || curData.openingDate}</TableCell>
          {this.props.type === "savings_account" ? (
            <TableCell>{types[curData.type!] || ""} </TableCell>
          ) : null}
          <TableCell>
            <IconButton
              style={{ margin: "0 1rem" }}
              onClick={this.generateFile.bind(this, {
                type: this.props.type,
                id: curData.uniqueId,
              })}
            >
              <GetApp />
            </IconButton>
            <IconButton
              onClick={this.handleDeleteItem(
                curData.uniqueId ? curData.uniqueId : ""
              )}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    } else {
      return null;
    }
  }
  public fetchData(params: IFetchParticipantParams) {
    params.limit = undefined;
    params.offset = 0;

    this.setState({ loading: true });

    if (this.props.type === "savings_account") {
      fetchSA(params)
        .then((res: any) => {
          this.props.fetchSavingsAccountContracts(
            res.data.results ? res.data.results : (res.data as any[])
          );
          this.setState({ loading: false });
        })
        .catch((err: any) => {
          catchError(err, this);
        });
    } else if (this.props.type === "financial_certificate") {
      fetchFC(params)
        .then((res: any) => {
          this.props.fetchFinancialCertificationContracts(
            res.data.results ? res.data.results : (res.data as any[])
          );
          this.setState({ loading: false });
        })
        .catch((err: any) => {
          catchError(err, this);
        });
    }
  }
  public async generateFile(obj: any) {
    let url = "";
    switch (obj.type) {
      case "savings_account":
        url = `/lending/savings-account-form/${obj.id}/print/`;
        break;
      case "financial_certificate":
        url = `/lending/financial-certificate-form/${obj.id}/print/`;
        break;
      default:
        break;
    }
    this.setState({ loading: true });
    await filesGeneration(url, this.props.user.token)
  }
  public render() {
    const { classes } = this.props;
    const { formDialog, loading, open } = this.state;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={this.closeDialogHandler}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.openFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          {this.props.type === "savings_account" ? (
            <DialogContent style={{ height: 400, width: 280 }}>
              <SavingsAccountSearchSection
                advanceSearch={this.emptyFunction}
                loading={loading}
                handleClick={this.handleItemClick}
                fetchData={this.fetchData}
                pagination={false}
              />
            </DialogContent>
          ) : this.props.type === "financial_certificate" ? (
            <DialogContent style={{ height: 400, width: 280 }}>
              <FinancialCertificateSearchSection
                advanceSearch={this.emptyFunction}
                loading={loading}
                handleClick={this.handleItemClick}
                fetchData={this.fetchData}
                pagination={false}
              />
            </DialogContent>
          ) : null}
        </Dialog>
        <Dialog
          open={formDialog}
          fullScreen={true}
          onClose={this.closeFormDialog}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          {this.props.type === "savings_account" ? (
            <DialogContent style={{ position: "relative" }}>
              <SavingsAccountForm
                changeSection={this.emptyFunction}
                handleSaveCallBack={this.emptyFunction}
              />
            </DialogContent>
          ) : this.props.type === "financial_certificate" ? (
            <DialogContent style={{ position: "relative" }}>
              <FinancialCertificateForm
                changeSection={this.emptyFunction}
                handleSaveCallBack={this.emptyFunction}
              />
            </DialogContent>
          ) : null}
        </Dialog>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {this.props.type === "savings_account"
                    ? translations.TITLE
                    : translations.CLIENT}
                </TableCell>
                <TableCell>{translations.CONTRACT_DATE}</TableCell>
                {this.props.type === "savings_account" ? (
                  <TableCell>{translations.TYPE}</TableCell>
                ) : null}
                <TableCell>
                  <FormControl style={{ width: "490px" }}>
                    <InputLabel htmlFor="type" shrink={true}>
                      Tipo de documento
                    </InputLabel>
                    <Select
                      value={this.props.type}
                      onChange={this.props.handleSelectChange.bind(
                        this,
                        "type"
                      )}
                      required={true}
                      inputProps={{
                        id: "type",
                        name: "type",
                      }}
                    >
                      <MenuItem value="savings_account">
                        {translations.SAVINGS_ACCOUNT_CONTRACTS}
                      </MenuItem>
                      <MenuItem value="financial_certificate">
                        Solicitudes de Certificado de Financieros
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <IconButton onClick={this.openDialogHandler}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.selectedId.map((clientId) => this.findData(clientId))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    data: state.savingsAccountContracts,
    datas: state.financialCertificationContracts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    fetchSavingsAccountContracts,
    fetchFinancialCertificationContracts,
  })(SelectInput)
);
