import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Typography,
  DialogTitle,
  IconButton,
  InputBase,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { AttachFile, Save, CloseOutlined } from "@material-ui/icons";
import {
  ConfirmDialog,
  ErrorDialog,
} from "./../../../components/SharedComponents/Dialogs";
import { grey, green } from "@material-ui/core/colors";
import { IDENTIFICATION_TYPE, translations } from "src/utils";
import {
  getFileAttachment,
  createFileAttachment,
  updateInternationalBlackList,
  fetchNationalities,
} from "src/utils/axios";
import { SuccessDialog } from "../../../components/SharedComponents/Dialogs";
import Loading from "../../../components/SharedComponents/LoadingScreen";
import NationalityForm from "src/containers/Nationality/NationalityForm";
import idNumberMaskInput from "src/components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "src/components/TextMaskInputComponents/passportMaskInput";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";
import { errorHandling } from "../../../components/SharedComponents/CustomHooks";

function ConfirmDialogComponent(props) {
  const data = props.data.data;
  let dialogConfirmContent = "";
  switch (props.data.msg) {
    case "file-upload":
      dialogConfirmContent = "¿Desea subir el archivo seleccionado?";
      break;
    case "add-nationality":
      dialogConfirmContent = `${props.msg}`;
      break;
    case "proceed-delete":
      dialogConfirmContent = "¿Esta seguro que desea eliminar esta evidencia?";
      break;
    case "delete":
      dialogConfirmContent = "¿Esta seguro que desea eliminar esta registro?";
      break;
    default:
      dialogConfirmContent = "...";
  }
  return (
    <ConfirmDialog
      open={props.open}
      handleToggle={props.handleToggle}
      confirmCallback={data.callback}
      data={dialogConfirmContent}
    />
  );
}

function PreviewDialog(props) {
  const [url, setUrl] = useState("");
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };

  const handleViewFile = () => {
    if (props.data.newsEvidence) {
      const endpoint = getFileAttachment(props.token, props.data.newsEvidence);
      endpoint
        .then((res) => {
          const dbuff = new Uint8Array(res.data.file).buffer;
          const data = new Blob([dbuff], { type: res.data.type });
          const fileURL = URL.createObjectURL(data);
          setUrl(fileURL);
        })
        .catch((errors) => {
          errorHandling(errors, handleToggleSimpleDialog);
        });
    }
  };

  useEffect(() => {
    handleViewFile();
  }, []);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={(e, reason) =>
          reason === "backdropClick" && e.preventDefault()
        }
        className="dialog-container"
        open={props.open}
      >
        <DialogTitle style={{ backgroundColor: green[500] }}>
          <Grid container justify="space-between" alignItems="center">
            <Typography
              component={"span"}
              variant="h5"
              className="dialog-title"
            >
              Evidencia
            </Typography>
            <IconButton
              onClick={props.handleToggleDialog.bind(this, undefined)}
            >
              <CloseOutlined className="dialog-header-close-btn" />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent
          style={{
            margin: "2% 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              id: "preview",
              width: "47%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: grey[200],
              borderRadius: 20,
              overflow: "hidden",
              margin: "10% 0px 0px",
              minHeight: 500,
            }}
          >
            <img
              alt="newsEvidence"
              src={url}
              style={{
                objectFit: "cover",
                maxWidth: "100%",
                minWidth: "-webkit-fill-available",
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}
      {simpleDialog.name === "error-dialog" ? (
        <ErrorDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
    </div>
  );
}

function EvidenceDialog(props) {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };

  const setEvidence = (id) => {
    const newData = {
      newsEvidence: id,
    };
    const endpoint = updateInternationalBlackList(
      props.token,
      props.data.id,
      newData
    );
    endpoint
      .then(() => {
        handleToggleSimpleDialog("success-dialog");
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const uploadFile = () => {
    setLoading(true);
    let formData = new FormData();
    const name = props.data.id;
    formData.append("file", image);
    formData.append("name", name);
    const endpoint = createFileAttachment(props.token, formData);
    endpoint
      .then((response) => {
        setEvidence(response.data.uniqueId);
        props.refresh();
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  useEffect(() => {
    if (image) {
      setImage(image);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  return (
    <div>
      <Loading open={loading} />
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={(e, reason) =>
          reason === "backdropClick" && e.preventDefault()
        }
        className="dialog-container"
        open={props.open}
      >
        <DialogTitle style={{ backgroundColor: green[500] }}>
          <Grid container justify="space-between" alignItems="center">
            <Typography
              component={"span"}
              variant="h5"
              className="dialog-title"
            >
              Cargar evidencia
            </Typography>
            <IconButton
              onClick={props.handleToggleDialog.bind(this, undefined)}
            >
              <CloseOutlined className="dialog-header-close-btn" />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ margin: "2% 0" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                id: "preview",
                width: "47%",
                height: 550,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: grey[200],
                borderRadius: 20,
                overflow: "hidden",
              }}
            >
              <img
                alt="preview"
                src={preview}
                style={{
                  objectFit: "cover",
                  maxWidth: "100%",
                  minWidth: "-webkit-fill-available",
                }}
              />
            </div>
          </div>
          <div style={{ width: "100%", margin: 8 }}>
            <input
              type="file"
              id="file1"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(event) => {
                const file = event.target.files[0];
                setImage(file);
              }}
            />
            <div
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <div style={{ maxWidth: "65%", display: "inline-block" }}>
                <label htmlFor="file1">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#43A047",
                      color: "#FFFFFF",
                      fontSize: "12px",
                      display: "inline-block",
                    }}
                    component="span"
                    disableElevation
                  >
                    <AttachFile
                      style={{ margin: " 0 5 -4 -5", fontSize: "16px" }}
                    />
                    Elegir
                  </Button>
                </label>

                <InputBase
                  value={image ? image.name : "Seleccione un archivo"}
                  style={{
                    color: "#616161",
                    paddingLeft: 5,
                    display: "inline-block",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={uploadFile}
            style={{
              backgroundColor: green[500],
              color: "white",
              marginLeft: 10,
            }}
          >
            <Save />
            &nbsp; Guardar
          </Button>
        </DialogActions>
      </Dialog>
      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}
    </div>
  );
}

function NationalityDialog(props) {
  return (
    <Dialog
      fullScreen={true}
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={props.open}
    >
      <DialogTitle style={{ backgroundColor: green[500] }}>
        <Grid container justify="space-between" alignItems="center">
          <Typography component={"span"} variant="h5" className="dialog-title">
            Nacionalidades
          </Typography>
          <IconButton onClick={props.handleToggleDialog.bind(this, undefined)}>
            <CloseOutlined className="dialog-header-close-btn" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ position: "relative" }}>
        <NationalityForm />
      </DialogContent>
    </Dialog>
  );
}

function EditDialog(props) {
  const [countries, setCountries] = useState([]);

  // const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    fullName: "",
    nickName: "",
    identificationType: "",
    identificationNumber: "",
    nationality: "",
    crimeCommitted: "",
    newsLink: "",
  });
  // const [simpleDialog, setSimpleDialog] = useState({ name: '', data: null, callback: null });

  // const handleToggleSimpleDialog = (dialog, msg, data) => {
  //     const obj = { name: dialog ? dialog : '', msg: msg, data: data };
  //     setSimpleDialog({ ...obj });
  // }

  const handleChange = (key) => {
    return (event) => {
      setFormValues({ ...formValues, [key]: event.currentTarget.value });
    };
  };

  const handleSelectChange = (key) => {
    return (event) => {
      setFormValues({ ...formValues, [key]: event.target.value });
    };
  };

  const getIdentificationInput = (identificationType) => {
    switch (identificationType) {
      case "Cedula":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "Pasaporte":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  };

  const getNationalities = () => {
    fetchNationalities({ token: props.token })
      .then((res) => {
        setCountries(res.data.results ? res.data.results : res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getData = () => {
    setFormValues({
      ...formValues,
      fullName: props.data.fullName,
      nickName: props.data.nickName,
      newsLink: props.data.newsLink,
      identificationType: props.data.identificationType,
      identificationNumber: props.data.identificationNumber,
      nationality: props.data.nationality,
      crimeCommitted: props.data.crimeCommitted,
    });
  };

  //   const handleReset = () => {
  //     setFormValues({
  //       ...formValues,
  //       fullName: '',
  //       nickName: '',
  //       newsLink: '',
  //       identificationType: '',
  //       identificationNumber: '',
  //       nationality: '',
  //       crimeCommitted: ''
  //     })
  //   }

  //   const updateRecord = () => {
  //     setLoading(true);

  //     let newData = {
  //         fullName: formValues.fullName,
  //         nickName: formValues.nickName,
  //         newsLink: formValues.newsLink,
  //         identificationType: formValues.identificationType,
  //         identificationNumber: formValues.identificationNumber,
  //         nationality: formValues.nationality,
  //         crimeCommitted: formValues.crimeCommitted,
  //       }

  //     const endpoint = updateInternationalBlackList(props.token, props.data.id, newData);
  //     endpoint.then(() => {
  //         getData()
  //         props.refresh();
  //         setLoading(false);

  //     }).catch(errors => {
  //         setLoading(false);
  //         errorHandling(errors, handleToggleSimpleDialog);
  //     });
  // }

  useEffect(() => {
    getNationalities();
    getData();
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={props.open}
    >
      <DialogTitle style={{ backgroundColor: green[500] }}>
        <Grid container justify="space-between" alignItems="center">
          <Typography component={"span"} variant="h5" className="dialog-title">
            Editar
          </Typography>
          <IconButton onClick={props.handleToggleDialog.bind(this, undefined)}>
            <CloseOutlined className="dialog-header-close-btn" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ position: "relative" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "47%" }}>
            <div style={{ width: "100%" }}>
              <TextField
                style={{ margin: 8, width: "100%" }}
                label={translations.FULL_NAME}
                value={formValues.fullName}
                onChange={handleChange("fullName")}
                variant="outlined"
                required={true}
              />
            </div>
            <div style={{ width: "100%" }}>
              <TextField
                style={{ margin: 8, width: "100%" }}
                label={translations.NICKNAME}
                value={formValues.nickName}
                onChange={handleChange("nickName")}
                variant="outlined"
              />
            </div>
            <div style={{ width: "100%" }}>
              <FormControl
                style={{ margin: 8, width: "47%" }}
                variant="outlined"
              >
                <InputLabel id="identificationType">
                  {translations.IDENTIFICATION_TYPE}
                </InputLabel>
                <Select
                  id="identificationType"
                  value={formValues.identificationType}
                  style={{ width: "100%" }}
                  required={true}
                  label={translations.IDENTIFICATION_TYPE}
                  onChange={handleSelectChange("identificationType")}
                >
                  {IDENTIFICATION_TYPE.map((opt, indx) => {
                    return (
                      <MenuItem value={opt.label} key={indx}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <TextField
                style={{ margin: 8, width: "100%" }}
                label={translations.IDENTIFICATION}
                value={formValues.identificationNumber}
                onChange={handleChange("identificationNumber")}
                required={true}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={getIdentificationInput(
                  formValues.identificationType
                )}
              />
            </div>
            <FormControl style={{ margin: 8, width: "47%" }} variant="outlined">
              <InputLabel htmlFor="nationality">
                {translations.NATIONALITY}
              </InputLabel>
              <Select
                style={{ width: "100%" }}
                onChange={handleSelectChange("nationality")}
                required={true}
                value={formValues.nationality}
                inputProps={{
                  id: "nationality",
                  name: "nationality",
                }}
              >
                {countries.map((opt, index) => {
                  return (
                    <MenuItem key={index} value={opt.name}>
                      {opt.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <br />
            <TextField
              label={"Crimen cometido"}
              style={{ margin: 8, width: "100%" }}
              onChange={handleChange("crimeCommitted")}
              value={formValues.crimeCommitted}
              variant="outlined"
              multiline={true}
              required={true}
              rows={4}
              rowsMax={4}
            />
            <div style={{ width: "100%" }}>
              <TextField
                style={{ margin: 8, width: "100%" }}
                label={"Link de evidencia"}
                value={formValues.newsLink}
                onChange={handleChange("newsLink")}
                variant="outlined"
              />
            </div>
          </div>
          <div />
          <br />
        </div>
      </DialogContent>
      {/* <DialogActions>
                <Button
                variant="contained"
                onClick={handleReset}
                style={{ backgroundColor: red[500], color: "white", }}
                >
                <Replay />
                &nbsp;
                {translations.RESET}
                </Button>
                <Button
                    variant="contained"
                    onClick={updateRecord}
                    style={{ backgroundColor: green[500], color: "white", marginLeft: 10 }}
                >
                    <Save />
                    &nbsp;
                    Guardar
                </Button>
            </DialogActions> */}
    </Dialog>
  );
}

export {
  PreviewDialog,
  ConfirmDialogComponent,
  EvidenceDialog,
  NationalityDialog,
  EditDialog,
};
