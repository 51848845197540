import { AxiosError } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Autocomplete from "@material-ui/lab/Autocomplete";


import {
    faCalculator
} from '@fortawesome/free-solid-svg-icons';


import { deleteSavingsAccountContract, fetchSavingsAccountContract, updateSavingsAccountContract } from '../../../actions';
import AlertDialog from '../../../components/AlertDialog';
import Loading from '../../../components/Loading';
import moneyInput from '../../../components/TextMaskInputComponents/moneyInput';
import phoneMaskInput from '../../../components/TextMaskInputComponents/phoneMaskInput';
import {
    catchError,
    resetError,
    successHandler,
    titleCaseHandler, translations,
    savingsAccountTypes, signatureTypes,
    collectionTypes,
    debtorsValidation,
    filesGeneration
} from '../../../utils';
import {
    deleteSavingsAccountContract as deleteSA, fetchSavingsAccountContract as fetchSA,
    updateSavingsAccountContract as updateSA
} from '../../../utils/axios';
import ParticipantSelectInput from '../../Participant/ParticipantSelectInput';
import FileUploadSelectInput from '../../FileUpload/FileUploadSelectInput';
import Dialog from '../Dialog';
import Calculator from '../../../components/Calculators/PlannedSavingsTable'
import CoopSanCalculator from "../../../components/Calculators/CoopSanTable";

const CssTextField = withStyles({
    root: {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#4CAF50",
            },
        },
        "& input:invalid + fieldset": {
            borderColor: "red",
            borderWidth: 2,
            color: "red",
        },
        "& input:focus + fieldset, & select:focus + fieldset": {
            borderColor: "green",
            borderWidth: 2,
        },
        "& label.Mui-focused": {
            color: "green",
        },
        margin: 8,
    },

})(TextField);

const styles: any = createStyles({
    checkboxLabel: {
        color: green[500],
    },
    controls: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    deleteButton: {
        backgroundColor: red[500],
        color: 'white'
    },
    errorInput: {
        color: 'red'
    },
    formControl: {
        marginTop: 17,
        minWidth: 300,
        width: 300
    },
    greenButton: {
        backgroundColor: green[500],
        color: 'white'
    },
    half: {
        width: '100%'
    },
    paper: {
        padding: 20
    },
    root: {
        height: '90vh',
        overflowY: 'auto',
        padding: 20,
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: '50%'
    },
    gridFormControl: {
        margin: 8,
        width: '100%',
    }, backButton: {
        "&:hover": {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        }
    }


});

interface ISavingsAccountContractViewProps {
    savingsAccountContractId: string;
    classes: {
        attachmentIcon: string;
        attachmentIconButton: string;
        checkboxLabel: string;
        controls: string;
        deleteButton: string;
        errorInput: string;
        formControl: string;
        greenButton: string;
        half: string;
        paper: string;
        root: string;
        backButton: string;
        gridFormControl: string;
        textField: string;
    };

    user: IUser;
    fromTicketType?: string;
    changeSection(sectionNumber: number): void;
    deleteSavingsAccountContract(uniqueId: string): IAction<ISavingsAccountContract[]>;
    fetchSavingsAccountContract(savingsAccountContract: ISavingsAccountContract): IAction<ISavingsAccountContract[]>;
    updateSavingsAccountContract(savingsAccountContract: ISavingsAccountContract): IAction<ISavingsAccountContract[]>;
}
interface ISavingsAccountContractViewState {
    [key: string]: string | number | boolean | string[] | null | undefined;

    SA: string;
    dialog: boolean;
    dialogTitle: string;
    errorMessage: string;
    loading: boolean;
    status: string;
    uniqueId: string;


    title: string;
    titleError: string;

    type: string;
    typeError: string;

    signatureType: string;
    signatureTypeError: string;

    collectionType: string;
    collectionTypeError: string;

    mainDebtor: string[];
    mainDebtorError: string;

    secondaryDebtors: string[];
    secondaryDebtorsError: string;

    contractDate: string;
    contractDateError: string;

    accountNumber: number | null;
    accountNumberError: string;

    passbookNumber: number | null;
    passbookNumberError: string;

    quota: number;
    quotaError: string;

    term: number;
    termError: string;

    periodicity: string;
    periodicityError: string;

    goal: number;
    goalError: string;

    calculatorModal: boolean;

    // Child Info
    fullName: string;
    fullNameError: string;

    email: string;
    emailError: string;

    cellPhone: string;
    cellPhoneError: string;

    age: number;
    ageError: string;

    fp: any;
    wp: any;
    calculator: string;

}
class SavingsAccountContractView extends React.Component<ISavingsAccountContractViewProps, ISavingsAccountContractViewState> {
    public state: ISavingsAccountContractViewState = {
        SA: '',
        calculator: "",
        calculatorModal: false,
        dialog: false,
        dialogTitle: '',
        errorMessage: '',
        loading: false,
        status: 'pending_signature',
        uniqueId: '',
        title: '',
        titleError: '',
        accountNumber: null,
        accountNumberError: '',
        passbookNumber: null,
        passbookNumberError: '',
        type: '',
        typeError: '',
        signatureType: '',
        signatureTypeError: '',
        collectionType: '',
        collectionTypeError: '',
        contractDate: new Date().toISOString().split('T')[0],
        contractDateError: '',
        mainDebtor: [],
        mainDebtorError: '',
        secondaryDebtors: [],
        secondaryDebtorsError: '',
        periodicity: "monthly",
        periodicityError: "",
        term: 12,
        termError: '',
        quota: 0.0,
        quotaError: "",
        goal: 0.0,
        goalError: "",
        // Child Info 
        cellPhone: "",
        cellPhoneError: "",
        fullName: "",
        fullNameError: "",
        email: "",
        emailError: "",
        age: 0,
        ageError: "",
        fp: [],
        wp: []
    }
    constructor(props: ISavingsAccountContractViewProps) {
        super(props);

        // client
        this.handleAddMainClient = this.handleAddMainClient.bind(this);
        this.handleAddSecondaryClient = this.handleAddSecondaryClient.bind(this);
        this.handleDeleteMainClient = this.handleDeleteMainClient.bind(this);
        this.handleDeleteSecondaryClient = this.handleDeleteSecondaryClient.bind(this);

        // core events
        this.generateFile = this.generateFile.bind(this);
        this.changeStatus = this.changeStatus.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);

        this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    }
    public handleAutocompleteChange(key: any, obj: any) {
        /* Handle the state of Autocomplete components */
        if (obj) {
            this.setState({
                [key]: obj.value,
            });
        }
    }
    public openDialog() {
        this.setState({ calculatorModal: true });
    }
    public closeDialog() {
        this.setState({
            calculatorModal: false
        });
    }

    public handleCurrencyInputChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>, maskedValue?: string, floatValue?: string) => {
            this.setState({ [key]: floatValue || '' });
        }
    }

    public handleCheckBoxChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ [key]: event.target.checked });
        };
    }
    public handleAddMainClient(id: any) {
        this.setState({ mainDebtor: [id] });
    }
    public handleDeleteMainClient(id: any) {
        this.setState({ mainDebtor: [] });
    }
    public handleAddSecondaryClient(id: string) {
        const secondaryDebtors = this.state.secondaryDebtors.filter((debtorId: any) => debtorId !== id);
        secondaryDebtors.push(id);
        this.setState({ secondaryDebtors });
    }
    public handleDeleteSecondaryClient(id: string) {
        const secondaryDebtors = this.state.secondaryDebtors.filter((debtorId: any) => debtorId !== id);
        this.setState({ secondaryDebtors });
    }

    public async generateFile() {
        this.setState({ loading: true });
        if (await debtorsValidation(this, this.props.user.token, this.state.mainDebtor, false)) {
            const url = `/lending/savings-account-form/${this.state.uniqueId}/print`;
            await filesGeneration(url, this.props.user.token);
            this.setState({ loading: false });
        }
    }
    public changeStatus(status: string) {
        return () => {
            this.setState({ status });
        }
    }

    public componentDidMount() {

        fetchSA(this.props.user.token, this.props.savingsAccountContractId).then(res => {

            const curSavingsAccountContract = res.data as ISavingsAccountContract;

            this.setState({
                SA: `lending/savings-account-form/${curSavingsAccountContract.uniqueId}/upload_attachment/`,
                contractDate: curSavingsAccountContract.openingDate || '',
                mainDebtor: [curSavingsAccountContract.mainDebtor!] || [],
                secondaryDebtors: curSavingsAccountContract.secondaryDebtors! || [],
                dialog: false,
                dialogTitle: '',
                errorMessage: '',
                loading: false,
                passbookNumber: curSavingsAccountContract.passbookNumber || null,
                accountNumber: curSavingsAccountContract.accountNumber || null,
                status: curSavingsAccountContract.status || '',
                title: curSavingsAccountContract.title || '',
                uniqueId: curSavingsAccountContract.uniqueId || '',
                type: curSavingsAccountContract.type || '',
                signatureType: curSavingsAccountContract.signatureType || '',
                collectionType: curSavingsAccountContract.collectionType || '',
                term: curSavingsAccountContract.term || 0,
                quota: curSavingsAccountContract.quota || 0.0,
                goal: curSavingsAccountContract.goal || 0.0,
                fullName: curSavingsAccountContract.childInfo ? curSavingsAccountContract.childInfo.fullName : "",
                cellPhone: curSavingsAccountContract.childInfo ? curSavingsAccountContract.childInfo.cellPhone : "",
                email: curSavingsAccountContract.childInfo ? curSavingsAccountContract.childInfo.email : "",
                age: curSavingsAccountContract.childInfo ? curSavingsAccountContract.childInfo.age : "",

            });

        }).catch(err => {
            catchError(err, this)
        });

    }

    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({ [key]: event.target.value });
        }
    }

    public async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        resetError(this);
        const { state } = this;

        if (state.mainDebtor.length === 0) {
            this.setState({
                dialog: true,
                dialogTitle: 'Disculpe',
                errorMessage: 'El deudor es requerido',
            });

        }
        else if ((!this.state.quota || !this.state.goal) && (this.state.type === 'coopsan' || this.state.type === 'planned')) {
            this.setState({
                dialog: true,
                dialogTitle: 'Disculpe',
                errorMessage: 'Completar todos los campos requeridos.',
            });

        }
        else if (!this.state.quota && this.state.type === 'functional') {
            this.setState({
                dialog: true,
                dialogTitle: 'Disculpe',
                errorMessage: 'Completar todos los campos requeridos.',
            });
        } else if (state.mainDebtor.length !== 0) {
            const savingsAccountContract: ISavingsAccountContract = {
                title: state.title,
                openingDate: state.contractDate,
                mainDebtor: state.mainDebtor[0],
                secondaryDebtors: state.secondaryDebtors,
                type: state.type,
                accountNumber: state.accountNumber || null,
                passbookNumber: state.passbookNumber || null,
                status: state.status,
                signatureType: state.signatureType,
                collectionType: state.collectionType,
                goal: state.goal || 0.0,
                quota: state.quota || 0.0,
                term: state.term || 12,
                periodicity: state.periodicity || 'monthly',
                childInfo: {
                    fullName: state.fullName || '',
                    cellPhone: state.cellPhone || '',
                    email: state.email || '',
                    age: state.age || '',
                }

            };
            this.setState({ loading: true });

            updateSA(this.props.user.token, this.state.uniqueId, savingsAccountContract).then(res => {
                this.props.updateSavingsAccountContract(res.data as ISavingsAccountContract);
                successHandler(res, this, translations.LOAN_CONTRACT)
            }).catch((error: AxiosError) => {
                catchError(error, this)
            });
        } else {
            this.setState({
                dialog: true,
                dialogTitle: 'Error',
                errorMessage: translations.PLEASE_SELECT_AT_LEAST,
                loading: false
            });
        }
    }
    public handleDelete() {
        if (confirm(translations.ARE_YOU_SURE)) {

            this.setState({ loading: true });
            deleteSA(this.props.user.token, this.props.savingsAccountContractId).then(res => {
                this.props.changeSection(0);
                this.props.deleteSavingsAccountContract(this.props.savingsAccountContractId);
            }).catch((err: AxiosError) => {
                catchError(err, this)
            })
        }
    }
    public handleDialogClose() {
        this.setState({ dialog: false });
    }
    public render() {
        const { classes } = this.props;

        return (
            <form onSubmit={this.handleSubmit} className={classes.root}>
                <AlertDialog
                    open={this.state.dialog}
                    message={this.state.errorMessage}
                    title={this.state.dialogTitle}
                    handleClose={this.handleDialogClose}
                />
                <Paper className={classes.paper}>
                    <FileUploadSelectInput
                        contractId={this.state.uniqueId}
                        url={this.state.SA}
                        description='SA'
                    />
                    <FontAwesomeIcon
                        style={{
                            height: 45,
                            position: "absolute",
                            right: 48,
                            width: 30,
                            padding: 10,
                            color: green[500],
                            top: 145
                        }}
                        onClick={this.openDialog}
                        icon={faCalculator}
                        className={classes.backButton}
                        color="green"
                        id="Calculator" />
                    <Typography variant="h3">Solicitud de cuenta de ahorro</Typography>
                    {this.state.loading ?
                        <Loading message={translations.LOADING} />
                        : <div>
                            <Dialog
                                handleClose={this.closeDialog}
                                openDialog={this.state.calculatorModal}>
                                <div className="quotation-container">
                                    <section className="header-section1">
                                        <Autocomplete
                                            style={{ width: 250 }}
                                            options={[{ value: "planned savings", label: translations.PLANNED_SAVINGS },
                                            { value: "coopsan", label: "Ahorros CoopSan" }]}
                                            getOptionLabel={(option) => option.label}
                                            value={
                                                this.state.calculator
                                                    ? [{ value: "planned savings", label: translations.PLANNED_SAVINGS },
                                                    { value: "coopsan", label: "Ahorros CoopSan" }].find(
                                                        (item: any) => item.value === this.state.calculator
                                                    )
                                                    : null
                                            }
                                            id={'calculator'}
                                            disabled={false}
                                            onChange={(e, value) =>
                                                this.handleAutocompleteChange('calculator', value)
                                            }
                                            renderInput={(props) => (
                                                <CssTextField
                                                    {...props}
                                                    label={"Elegir calculadora"}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </section>
                                    {this.state.calculator !== "" ? (
                                        <section className="header-section2">
                                            {this.state.calculator === "planned savings" ? (
                                                <Calculator />
                                            ) : this.state.calculator === "coopsan" ? (
                                                <CoopSanCalculator />
                                            ) : null}
                                        </section>
                                    ) : null}
                                </div>
                            </Dialog>
                            <Button className={classes.greenButton} onClick={this.generateFile} style={{ marginRight: 10, marginTop: 25 }}>
                                <DocumentIcon />
                                &nbsp;
                                {translations.GENERATE_DOCUMENT}
                            </Button>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="status" shrink={true}>{translations.STATUS}</InputLabel>
                                <Select
                                    value={this.state.status}
                                    onChange={this.handleSelectChange('status')}
                                    required={true}
                                    inputProps={{
                                        id: 'status',
                                        name: 'status',
                                    }}

                                >
                                    <MenuItem value="pending_signature">{translations.PENDING_SIGNATURE}</MenuItem>
                                    <MenuItem value="completed">{translations.COMPLETED}</MenuItem>
                                    <MenuItem value="archived">{translations.ARCHIVED}</MenuItem>
                                </Select>
                            </FormControl>
                            <div style={{ display: 'flex' }}>
                                <CssTextField
                                    label={translations.TITLE}
                                    className={classes.textField}
                                    value={titleCaseHandler(this.state.title)}
                                    onChange={this.handleChange('title')}
                                    fullWidth={true}
                                    required={true}
                                    error={this.state.titleError !== ''}
                                    helperText={this.state.titleError}
                                    InputLabelProps={{ style: styles.checkboxLabel }}
                                />
                                <CssTextField
                                    label={translations.CONTRACT_DATE}
                                    className={classes.textField}
                                    style={{ marginLeft: 20 }}
                                    value={this.state.contractDate}
                                    type="date"
                                    onChange={this.handleChange('contractDate')}
                                    required={true}
                                    error={this.state.contractDateError !== ''}
                                    helperText={this.state.contractDateError}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.checkboxLabel
                                    }}
                                />
                            </div>
                            <br />
                            <div className={classes.half}>
                                <Typography variant="h6" className={classes.checkboxLabel}>
                                    {translations.PARTNER} Principal
                                </Typography>
                                <ParticipantSelectInput
                                    selectedParticipantId={this.state.mainDebtor}
                                    addParticipant={this.handleAddMainClient}
                                    deleteParticipant={this.handleDeleteMainClient}
                                />
                                <p className={classes.errorInput}>{this.state.mainDebtorError}</p>
                            </div>
                            <div className={classes.half}>
                                <Typography variant="h6" className={classes.checkboxLabel}>
                                    Miembro(s) Secundario(s)
                                </Typography>
                                <ParticipantSelectInput
                                    selectedParticipantId={this.state.secondaryDebtors}
                                    addParticipant={this.handleAddSecondaryClient}
                                    deleteParticipant={this.handleDeleteSecondaryClient}
                                />
                                <p className={classes.errorInput}>{this.state.secondaryDebtorsError}</p>
                            </div>
                            <br />
                            <Grid container={true} spacing={3}>
                                <Grid item={true} xs={2}>
                                    <CssTextField
                                        variant="outlined"
                                        label={translations.ACCOUNT_NUMBER}
                                        style={{ width: "100%" }}
                                        required={this.state.status === "completed" ? true : false}
                                        value={this.state.accountNumber || ''}
                                        type="number"
                                        onChange={this.handleChange('accountNumber')}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.checkboxLabel
                                        }}
                                    />
                                </Grid>
                                <Grid item={true} xs={2}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.gridFormControl}

                                    >
                                        <InputLabel
                                            id="collectionType"
                                            error={this.state.collectionTypeError ? true : false}
                                        >
                                            {"Forma de Cobro"}
                                        </InputLabel>
                                        <Select
                                            id="collectionType"
                                            label={"Forma de Cobro"}
                                            onChange={this.handleSelectChange("collectionType")}
                                            style={{ width: "100%" }}
                                            value={this.state.collectionType}
                                        >
                                            {collectionTypes.map((opt, indx) => (
                                                <MenuItem key={indx} value={opt.value}>
                                                    {opt.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{this.state.collectionTypeError}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={2}>

                                    <FormControl variant="outlined"
                                        className={classes.gridFormControl}
                                    >
                                        <InputLabel
                                            id="signatureType"
                                            error={this.state.signatureTypeError ? true : false}
                                        >
                                            Tipo de firma
                                        </InputLabel>
                                        <Select
                                            id="signatureType"
                                            label={"Tipo de firma"}
                                            onChange={this.handleSelectChange("signatureType")}
                                            style={{ width: "100%" }}
                                            value={this.state.signatureType}
                                        >
                                            {signatureTypes.map((opt, indx) => (
                                                <MenuItem key={indx} value={opt.value}>
                                                    {opt.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{this.state.signatureTypeError}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={2}>
                                    <FormControl variant="outlined"
                                        className={classes.gridFormControl}
                                    >
                                        <InputLabel
                                            id="type"
                                            error={this.state.typeError ? true : false}
                                        >
                                            {translations.TYPE}
                                        </InputLabel>
                                        <Select
                                            id="type"
                                            label={translations.TYPE}
                                            onChange={this.handleSelectChange("type")}
                                            style={{ width: "100%" }}
                                            value={this.state.type}
                                        >
                                            {savingsAccountTypes.filter(item => this.props.fromTicketType ? item.value === this.props.fromTicketType : item).map((opt, indx) => (
                                                <MenuItem key={indx} value={opt.value}>
                                                    {opt.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{this.state.typeError}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <br />
                            {this.state.type === "planned" || this.state.type === "coopsan" ?
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={2}>
                                        <CssTextField
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                            label={translations.TERM}
                                            value={this.state.term || ''}
                                            type="number"
                                            onChange={this.handleChange('term')}
                                            required={true}
                                            error={this.state.termError !== ''}
                                            helperText={this.state.termError}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                            inputProps={{
                                                max: 100,
                                                min: 0,
                                                step: 1
                                            }}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={2}>
                                        <FormControl className={classes.gridFormControl}
                                            variant="outlined">
                                            <InputLabel
                                                shrink={true}
                                            >{translations.PERIODICITY}</InputLabel>
                                            <Select
                                                id="periodicity"
                                                onChange={this.handleSelectChange("periodicity")}
                                                value={this.state.periodicity}
                                                label={translations.PERIODICITY}
                                                style={{ width: "100%" }}
                                            >
                                                <MenuItem value={"monthly"}>
                                                    {translations.MONTHLY}
                                                </MenuItem>
                                                {this.state.type === "planned" ?
                                                    <MenuItem value={"biweekly"}>
                                                        {translations.BIWEEKLY}
                                                    </MenuItem> : null}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={2}>
                                        <CssTextField
                                            variant="outlined"
                                            label={"Cuota"}
                                            value={this.state.quota || ''}
                                            onChange={this.handleCurrencyInputChange('quota')}
                                            style={{ width: "100%" }}
                                            required={true}
                                            error={this.state.quotaError !== ''}
                                            helperText={this.state.quotaError}
                                            InputProps={{
                                                inputComponent: moneyInput
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                        />

                                    </Grid>
                                    <Grid item={true} xs={2}>
                                        <CssTextField
                                            variant="outlined"
                                            label={"Meta"}
                                            value={this.state.goal || ''}
                                            onChange={this.handleCurrencyInputChange('goal')}
                                            style={{ width: "100%" }}
                                            required={true}
                                            error={this.state.goalError !== ''}
                                            helperText={this.state.goalError}
                                            InputProps={{
                                                inputComponent: moneyInput
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: styles.checkboxLabel
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                : this.state.type === "youth" ?
                                    <div>
                                        <Typography variant="h6" className={classes.checkboxLabel}>Información del niño</Typography>
                                        <Grid container={true} spacing={3}>
                                            <Grid item={true} xs={2}>
                                                <CssTextField
                                                    variant="outlined"
                                                    label={translations.FULL_NAME}
                                                    value={this.state.fullName || ''}
                                                    onChange={this.handleChange("fullName")}
                                                    style={{ width: "100%" }}
                                                    required={true}
                                                    error={this.state.fullNameError !== ""}
                                                    helperText={this.state.fullNameError}
                                                    InputLabelProps={{
                                                        style: styles.checkboxLabel,
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item={true} xs={2}>
                                                <CssTextField
                                                    variant="outlined"
                                                    label={translations.EMAIL}
                                                    value={this.state.email || ''}
                                                    onChange={this.handleChange("email")}
                                                    style={{ width: "100%" }}
                                                    type="email"
                                                    error={this.state.emailError !== ""}
                                                    helperText={this.state.emailError}
                                                    InputLabelProps={{
                                                        style: styles.checkboxLabel,
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item={true} xs={2}>
                                                <CssTextField
                                                    variant="outlined"
                                                    label={translations.CELL_PHONE}
                                                    value={this.state.cellPhone || ''}
                                                    type="phone"
                                                    placeholder="+1(809) 000-0000"
                                                    onChange={this.handleChange('cellPhone')}
                                                    error={this.state.cellPhoneError !== ''}
                                                    style={{ width: "100%" }}
                                                    helperText={this.state.cellPhoneError}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        inputComponent: phoneMaskInput
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item={true} xs={2}>
                                                <CssTextField
                                                    variant="outlined"
                                                    label={translations.AGE}
                                                    value={this.state.age || ''}
                                                    onChange={this.handleChange("age")}
                                                    style={{ width: "100%" }}
                                                    required={true}
                                                    error={this.state.ageError !== ""}
                                                    helperText={this.state.ageError}
                                                    InputLabelProps={{
                                                        style: styles.checkboxLabel,
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item={true} xs={2}>
                                                <CssTextField
                                                    variant="outlined"
                                                    label={"Número de libreta"}
                                                    value={this.state.passbookNumber || ''}
                                                    type="number"
                                                    required={this.state.status === "completed" ? true : false}
                                                    onChange={this.handleChange('passbookNumber')}
                                                    style={{ width: "100%" }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        style: styles.checkboxLabel
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    : this.state.type === "functional" ? <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={2}>
                                            <CssTextField
                                                variant="outlined"

                                                label={"Cuota"}
                                                value={this.state.quota || ''}
                                                onChange={this.handleCurrencyInputChange('quota')}
                                                style={{ width: "100%" }}
                                                required={true}
                                                error={this.state.quotaError !== ''}
                                                helperText={this.state.quotaError}
                                                InputProps={{
                                                    inputComponent: moneyInput
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: styles.checkboxLabel
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                        : <Grid container={true} spacing={3}>
                                            <Grid item={true} xs={2}>
                                                <CssTextField
                                                    variant="outlined"
                                                    label={"Número de libreta"}
                                                    value={this.state.passbookNumber || ''}
                                                    onChange={this.handleChange('passbookNumber')}
                                                    required={this.state.status === "completed" ? true : false}
                                                    style={{ width: "100%" }}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        style: styles.checkboxLabel
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                            }
                            <br />


                            <div className={classes.controls}>
                                <Button variant="contained" onClick={this.handleDelete} className={classes.deleteButton}>
                                    <DeleteIcon />
                                    &nbsp;
                                    {translations.DELETE}
                                </Button>
                                <Button type="submit" variant="contained" className={classes.greenButton} style={{ marginLeft: 10 }}>
                                    <SaveIcon />
                                    &nbsp;
                                    {translations.SAVE}
                                </Button>
                            </div>
                        </div>
                    }
                </Paper>
            </form>
        );
    }
}

function mapStateToProps(state: IState) {
    return {
        savingsAccountContracts: state.savingsAccountContracts,
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps, { deleteSavingsAccountContract, fetchSavingsAccountContract, updateSavingsAccountContract })(SavingsAccountContractView)
);
