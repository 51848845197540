import * as React from "react";
import { connect } from "react-redux";
import FileUploadSelectInput from "src/containers/FileUpload/FileUploadSelectInput";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import DocumentIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {
  deleteAffidavitOfFunds,
  fetchAffidavitOfFunds,
  updateAffidavitOfFunds,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  debtorsValidation,
  filesGeneration,
  resetError,
  successHandler,
  translations,
} from "../../../utils";
import {
  deleteAffidavitOfFunds as deleteMA,
  fetchAffidavitOfFunds as fetchMA,
  updateAffidavitOfFunds as updateMA,
} from "../../../utils/axios";
import CompanyRepresentativeSelectInput from "../../CompanyRepresentative/SelectInput";
import InstitutionSelectInput from "../../Institutions/InstitutionsSelectInput";
import ExtraPaymentTable from "../../../components/Ticket/ExtraPayTable/ExtraPaymentTable";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

interface IViewProps {
  affidavitsOfFunds: IAffidavitOfFunds[];
  classes: {
    attachmentIcon: any;
    checkboxLabel: any;
    controls: any;
    deleteButton: any;
    errorInput: any;
    formControl: any;
    greenButton: any;
    half: any;
    paper: any;
    root: any;
    textField: any;
    title: any;
  };
  user: IUser;
  id: any;
  changeSection(sectionNumber: number): void;
  deleteAffidavitOfFunds(uniqueId: string): IAction<IAffidavitOfFunds[]>;
  fetchAffidavitOfFunds(
    affidavitOfFunds: IAffidavitOfFunds
  ): IAction<IAffidavitOfFunds[]>;
  updateAffidavitOfFunds(
    affidavitOfFunds: IAffidavitOfFunds
  ): IAction<IAffidavitOfFunds[]>;
}
interface IViewState {
  [key: string]: string | number | boolean | string[] | undefined;

  editIdx: number;

  status: string;

  MA: string;
  contractDate: string;
  contractDateError: string;

  dialog: boolean;
  dialogTitle: string;
  title: string;
  errorMessage: string;
  debtor: string[];
  debtorError: string;
  loading: boolean;

  city: string; // done
  cityError: string;

  institution: string[]; // done
  institutionError: string;

  sources: string[]; // done
  sourcesError: string;

  uniqueId: string;
}

class View extends React.Component<IViewProps, IViewState> {
  public state: IViewState = {
    editIdx: -1,
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    debtor: [],
    city: "", // done
    cityError: "",
    institution: [], // done
    institutionError: "",
    sources: [], // done
    sourcesError: "",
    debtorError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    MA: "",
    title: "",
    titleError: "",
    status: "pending_signature",
    uniqueId: "",
  };
  constructor(props: IViewProps) {
    super(props);

    // client
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // institution
    this.handleAddInstitution = this.handleAddInstitution.bind(this);
    this.handleDeleteInstitution = this.handleDeleteInstitution.bind(this);

    // core events
    this.generateFile = this.generateFile.bind(this);
    this.changeStatus = this.changeStatus.bind(this);

    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleStartEdit = this.handleStartEdit.bind(this);
    this.handleStopEdit = this.handleStopEdit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeTableData = this.handleChangeTableData.bind(this);
  }

  public handleChangeTableData = (i: any, x: any) => {
    this.setState((state) => ({
      sources: state.sources.map((row: any, j: any) => (j === i ? x : row)),
    }));
    this.handleStopEdit();
  };

  public handleStopEdit = () => {
    this.setState({ editIdx: -1 });
  };

  public handleRemove = (i: any) => {
    this.setState({
      sources: this.state.sources.filter((row: any, j: any) => j !== i),
    });
  };

  public handleSave(data: any) {
    this.setState({
      sources: data,
    });
  }

  public handleStartEdit = (i: any) => {
    this.setState({ editIdx: i });
  };
  public handleAddInstitution(id: string) {
    this.setState({ institution: [id] });
  }
  public handleDeleteInstitution(id: string) {
    this.setState({ institution: [] });
  }
  public handleAddClient(id: any) {
    this.setState({ debtor: [id] });
  }
  public handleDeleteClient(id: any) {
    this.setState({ debtor: [] });
  }

  public handleCheckBoxChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  public async generateFile() {
    this.setState({ loading: true });
    if (
      await debtorsValidation(
        this,
        this.props.user.token,
        this.state.debtor,
        false
      )
    ) {
      const url = `/lending/affidavit-of-funds/${this.state.uniqueId}/print/`
      await filesGeneration(url, this.props.user.token)
      this.setState({ loading: false });
    }
  }
  public changeStatus(status: any) {
    return () => {
      this.setState({ status });
    };
  }

  public componentDidMount() {
    fetchMA(this.props.user.token, this.props.id)
      .then((res) => {
        const contract: IAffidavitOfFunds = res.data;

        this.setState({
          status: contract.status || "",
          contractDate: contract.contractDate || "",
          debtor: contract.companyRepresentative
            ? [contract.companyRepresentative]
            : [],
          dialog: false,
          dialogTitle: "",
          loading: false,
          MA: `lending/affidavit-of-funds/${contract.uniqueId}/upload_attachment/`,
          title: contract.title || "",
          uniqueId: contract.uniqueId || "",
          city: contract.city || "",
          sources: contract.sources || [],
          institution: contract.institution ? [contract.institution] : [],
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleCurrencyInputChange(key: any) {
    return (event: any, maskedValue = "", floatValue = "") => {
      this.setState({ [key]: floatValue });
    };
  }
  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);
    const { state } = this;
    if (!this.state.city) {
      this.setState({ cityError: "Este campo es requerido" });
    } else if (!this.state.sources.length) {
      this.setState({ sourcesError: "Este campo es requerido" });
    } else {
      const contract: IAffidavitOfFunds = {
        contractDate: state.contractDate,
        companyRepresentative: state.debtor[0] || "",
        institution: state.institution[0] || "",
        city: state.city,
        title: state.title,
        sources: state.sources,
        status: state.status,
      };

      this.setState({ loading: true });

      updateMA(this.props.user.token, this.state.uniqueId, contract)
        .then((res) => {
          this.props.updateAffidavitOfFunds(
            res.data.results ? res.data.results : res.data
          );
          successHandler(
            res,
            this,
            "Declaración de procedencia de Fondos Persona Juridica"
          );
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }

  public handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteMA(this.props.user.token, this.props.id)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteAffidavitOfFunds(this.props.id);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    const headersAR = [
      { name: "Descripción", prop: "description", type: "text" },
    ];

    return (
      <form
        onSubmit={this.handleSubmit}
        noValidate={true}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <FileUploadSelectInput
            contractId={this.state.uniqueId}
            url={this.state.MA}
            description="AF"
          />
          <Typography variant="h4" className={classes.title}>
            Declaración de procedencia de Fondos Persona Juridica
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={classes.greenButton}
                onClick={this.generateFile}
                style={{ marginRight: 10, marginTop: 25 }}
              >
                <DocumentIcon />
                &nbsp;
                {translations.GENERATE_DOCUMENT}
              </Button>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="status" shrink={true}>
                  {translations.STATUS}
                </InputLabel>
                <Select
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                  required={true}
                  inputProps={{
                    id: "status",
                    name: "status",
                  }}
                >
                  <MenuItem value="pending_signature">
                    {translations.PENDING_SIGNATURE}
                  </MenuItem>
                  <MenuItem value="internal_signature_pending">
                    {translations.INTERNAL_SIGNATURE_PENDING}
                  </MenuItem>
                  <MenuItem value="completed">Completado</MenuItem>
                  <MenuItem value="archived">{translations.ARCHIVED}</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={this.state.title}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
                <TextField
                  label={translations.CITY}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.city}
                  onChange={this.handleChange("city")}
                  required={true}
                  error={this.state.cityError !== ""}
                  helperText={this.state.cityError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.COMPANY_REPRESENTATIVE}
                </Typography>
                <CompanyRepresentativeSelectInput
                  selectedCompanyRepresentativeId={this.state.debtor}
                  addCompanyRepresentative={this.handleAddClient}
                  deleteCompanyRepresentative={this.handleDeleteClient}
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.INSTITUTION}
                </Typography>
                <InstitutionSelectInput
                  selectedInstitutionId={this.state.institution}
                  addInstitution={this.handleAddInstitution}
                  deleteInstitution={this.handleDeleteInstitution}
                />
                <p className={classes.errorInput}>
                  {this.state.institutionError}
                </p>
              </div>
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  Fuentes
                </Typography>
                <ExtraPaymentTable
                  actions={true}
                  create={true}
                  data={this.state.sources}
                  editIdx={this.state.editIdx}
                  handleChange={this.handleChangeTableData}
                  handleRemove={this.handleRemove}
                  handleSave={this.handleSave}
                  handleStartEdit={this.handleStartEdit}
                  handleStopEdit={this.handleStopEdit}
                  header={headersAR}
                  tableLimit={5}
                />
                <p className={classes.errorInput}>{this.state.sourcesError}</p>
              </div>

              <br />
              <br />

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    affidavitsOfFunds: state.affidavitsOfFunds,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteAffidavitOfFunds,
    fetchAffidavitOfFunds,
    updateAffidavitOfFunds,
  })(View)
);
