import * as React from "react";
import { connect } from "react-redux";

import {
  AppBar,
  Button,
  Paper,
  Tab,
  Tabs,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import AlertDialog from "../../AlertDialog";
import Loading from "../../Loading";

import { catchError, translations } from "../../../utils";

import Popup from "../../UI/Popup";
import Panel from "./Panel";
import CalculatorForm from "./CalculatorForm";
import {
  fetchDebtCapacities,
  fetchInstitution,
  fetchPaymentCapacities,
  fetchWorkProfileByOwner,
} from "src/utils/axios";

const styles = createStyles({
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formButton: {
    background: "white",
    border: "1px solid",
    borderRadius: "10px",
    color: "#4caf50",
    fontWeight: "bold",
    margin: "15px",
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

class AvailabilityCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availabilityTypes: [],
      clientInstitutionData: {},
      clientWPData: {},
      dialog: false,
      dialogTitle: "",
      errorMessage: "",
      isMounted: false,
      loading: false,
      openForm: false,
      tab: 0,
      updateMethods: false,
    };

    this.initialState = { ...this.state };

    this.changeOccurs = this.changeOccurs.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.switchModal = this.switchModal.bind(this);
  }

  componentDidMount() {
    const { token } = this.props.user;
    this.setState({ loading: false, isMounted: true });
    fetchDebtCapacities({ token: token, customer: this.props.ownerId })
      .then((res) => {
        try {
          fetchPaymentCapacities({ token: token, customer: this.props.ownerId })
            .then((result) => {
              this.setState({
                availabilityTypes: [...res.data, ...result.data],
              });
            })
            .catch((err) => {
              catchError(err, this);
            });
        } catch (error) {
          catchError(error, this);
        }
      })
      .catch((err) => {
        catchError(err, this);
      });

    fetchWorkProfileByOwner(this.props.user.token, this.props.ownerId)
      .then((res) => {
        this.setState({ clientWPData: res.data[0] }, () => {
          try {
            fetchInstitution(this.props.user.token, res.data[0].institution)
              .then((result) => {
                this.setState({ clientInstitutionData: result.data });
              })
              .catch((err) => {
                this.setState({
                  dialog: true,
                  dialogTitle: "Advertencia",
                  errorMessage:
                    "El cliente no posee institucion en su perfil de trabajo",
                });
              });
          } catch (err) {
            this.setState({
              dialog: true,
              dialogTitle: "Advertencia",
              errorMessage: "El cliente no posee perfil de trabajo",
            });
          }
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.updateMethods !== this.state.updateMethods) {
      this.setState({ loading: true });
      fetchDebtCapacities({
        customer: this.props.ownerId,
        token: this.props.user.token,
      })
        .then((res) => {
          try {
            fetchPaymentCapacities({
              customer: this.props.ownerId,
              token: this.props.user.token,
            })
              .then((result) => {
                this.setState({
                  availabilityTypes: [...res.data, ...result.data],
                  loading: false,
                });
              })
              .catch((err) => {
                catchError(err, this);
              });
          } catch (error) {
            catchError(error, this);
          }
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  changeOccurs() {
    this.setState({
      updateMethods: !this.state.updateMethods,
    });
  }

  switchModal(key) {
    this.setState({
      [key]: !this.state[key],
    });
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleChangeTab(key) {
    return (event, newValue) => {
      this.setState({
        [key]: newValue,
      });
    };
  }

  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || "" });
    };
  }

  handleReset() {
    this.setState({ ...this.initialState });
  }

  handleDialogClose() {
    this.setState({ dialog: false });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">
            {translations.AVAILABILITY_CALCULATOR}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Button
                className={classes.formButton}
                onClick={() => this.switchModal("openForm")}
                startIcon={<AddCircleIcon />}
                variant="contained"
              >
                Agregar Metodo
              </Button>
              <Popup
                title="Calculo de Disponibilidad"
                open={this.state.openForm}
                funcion={this.switchModal}
                onCloseValue="openForm"
              >
                <CalculatorForm
                  changeOccurs={this.changeOccurs}
                  clientInfo={this.state.clientWPData}
                  clientInst={this.state.clientInstitutionData}
                  ownerId={this.props.ownerId}
                />
              </Popup>
              <br />
              <br />
              <AppBar position="static" color="default">
                <Tabs
                  value={this.state.tab}
                  onChange={this.handleChangeTab("tab")}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {this.state.availabilityTypes.map((method, index) => {
                    return <Tab label={method.referenceCode} key={index} />;
                  })}
                </Tabs>
              </AppBar>
              {this.state.availabilityTypes.map((method, indx) => {
                return (
                  <Panel
                    changeOccurs={this.changeOccurs}
                    clientInfo={this.state.clientWPData}
                    clientInst={this.state.clientInstitutionData}
                    data={method}
                    index={indx}
                    key={indx}
                    value={this.state.tab}
                  />
                );
              })}
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps)(AvailabilityCalculator)
);
