import { QUOTATION_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case QUOTATION_TYPES.FETCH_ALL:
            return action.payload || [];
        case QUOTATION_TYPES.FETCH:
            return state.map(quotation => (action.payload !== undefined && quotation.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: quotation);
        case QUOTATION_TYPES.CREATE: 
            return state.concat(action.payload || []);
        case QUOTATION_TYPES.UPDATE:
            return state.map(quotation => (action.payload !== undefined && quotation.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : quotation);
        case QUOTATION_TYPES.DELETE:
            return state.filter(quotation => action.payload !== undefined && quotation.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}