import React from "react";

import {
    Grid,
    Typography,
    Button,
    Toolbar,
    IconButton
} from "@material-ui/core";
import { Description, Settings } from "@material-ui/icons";
import { GeneralTable } from "./../../../components/SharedComponents/Table";
import { approbationStates } from "./../../../utils/index";
import { AutocompleteField } from "./../../../components/SharedComponents/Fields";
import NumberFormat from './../../../components/SharedComponents/NumberFormat';
import {
} from "./../../../utils/axios";


function TableToolbar(props) {
    return (
        <Toolbar>
            <Grid container item direction="row" spacing={5} alignItems="center">
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{ fontWeight: "bold", paddingLeft: 16 }}
                >
                    {props.title || "Solicitudes"}
                    <Button
                        variant="contained"
                        className={
                            props.dataQuantity < 1
                                ? "action-method-btn disabled"
                                : "action-method-btn save"
                        }
                        style={{ marginLeft: ".8rem", fontSize: "10px" }}
                        disableElevation
                        size="small"
                        disabled={props.dataQuantity < 1}
                        onClick={props.onClick}
                    >
                        <Description
                            style={{ fontSize: 15, color: "#fff", margin: "0 .5rem .1rem 0" }}
                        />
                        {props.buttonTitle}
                    </Button>
                </Typography>
            </Grid>
        </Toolbar>
    );
}

const date = (column, row) => {
    const value = row[column] ? row[column].split("T")[0] : "";
    return <span>{value}</span>;
};

const ticketStatus = (column, row) => {
    if (row[column] !== "") {
      const values = approbationStates.filter((x) => x.value === row[column])[0].label || "";
      return <span>{values}</span>;
    }else {
      const values = '---'
      return <span>{values}</span>;
    }
  };

const ticketsColumns = [
    { id: "checkbox" },
    { id: "participantName", label: "NOMBRE", minWidth: 100 },
    { id: "referenceCode", label: "NUMERO DE TICKET", minWidth: 150, align: "center" },
    { id: "ticketStatus", label: "ESTADO", minWidth: 150, element: ticketStatus.bind(this, "ticketStatus"), },
];

function ApprovementTable(props) {
    const actions = (row) => {
        return (
            <>
                <div style={{ alignItems: 'center' }}>
                    <IconButton
                        onClick={() => props.handleModal(row)}
                    >
                        <Settings style={{ fontSize: 22, color: '#616161' }} />
                    </IconButton>
                </div>
            </>
        );
    };

    const status = (column, row) => {
        const values = approbationStates.filter((x) => x.value === row[column])[0].label || "";
        return <span>{values}</span>;
    };

    const responsibleSelector = (column, row) => {
        const options = [
            { value: "Gerente general" },
            { value: "Consejo de administracion" },
            { value: "Analista de credito" },
            { value: "Comite de credito" },
        ]
        return (
            <div style={{ marginTop: 10 }}>
                <AutocompleteField
                    xs={12}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldID="filter-field-status"
                    fieldVariant="outlined"
                    value={options.find((x) => x.value === row.responsibleForApproval)}
                    handleChange={(e, value) => props.handleAutoCompleteChange(e, value, row)}
                    options={options}
                    getOptionLabel={(option) => option.value || ""}
                    limitTags={1}
                />
            </div>
        );
    };

    const amount = (column, row) => {
        return (
            <NumberFormat
                value={row[column]}
                prefix="$"
            />
        )
    }

    const approvementColumns = [

        {
            id: "actNumber",
            label: "NÚMERO DE ACTA",
            minWidth: 100,
            align: "center"
        },
        {
            id: "requestDate",
            label: "FECHA DE SOLICITUD",
            minWidth: 80,
            align: "center",
            element: date.bind(this, "requestDate")
        },
        {
            id: "ticketReferenceCode",
            label: "NÚMERO DE SOLICITUD",
            minWidth: 100,
            align: "center"
        },
        {
            id: "participantName",
            label: "NOMBRE",
            minWidth: 100,
            align: "center"
        },
        {
            id: 'requestedAmount',
            label: 'MONTO SOLICITADO',
            minWidth: 100,
            align: "center",
            element: amount.bind(this, "requestedAmount")
        },
        {
            id: "responsibleForApproval",
            label: "RESPONSABLE DE LA APROBACION",
            minWidth: 100,
            align: "center",
            element: responsibleSelector.bind(this, "responsibleForApproval")
        },
        {
            id: "ticketStatus",
            label: "ESTADO DE LA SOLICITUD",
            minWidth: 100,
            align: "center",
            element: status.bind(this, "status")
        },
        {
            id: "action",
            label: "ACCIÓN",
            align: "center",
            element: actions
        }
    ];

    return (
        <GeneralTable
            columns={approvementColumns}
            data={props.data}
            toolbar={
                <TableToolbar
                    title={props.title}
                    buttonTitle={"Generar Acta"}
                    onClick={props.createAct}
                    dataQuantity={props.data.length}
                />
            }
        />
    );
}

function TicketsTable(props) {
    return (
        <GeneralTable
            columns={ticketsColumns}
            data={props.data}
            selected={props.selected}
            setSelected={props.setSelected}
            toolbar={
                <TableToolbar
                    title={props.title}
                    buttonTitle={"Generar Aprobaciones"}
                    onClick={props.generateApprobations}
                    dataQuantity={props.data.length}
                />
            }
        />
    );
}

export { ApprovementTable, TicketsTable };
