import * as React from 'react';
import { translations, loanContractStatus, operatorList } from 'src/utils';
import { connect } from "react-redux";


import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import { MenuItem } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import ParticipantAutoComplete from 'src/components/Filters/ParticipantAutoComplete';
import { SearchComponent } from "../../../components/SharedComponents/Fields";

import moneyInput from '../../../components/TextMaskInputComponents/moneyInput';


const styles: any = createStyles({
    formControl: {
        marginTop: 20
    },
    greenButton: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    label: {
        color: green[500]
    },
    redButton: {
        '&:hover': {
            backgroundColor: red[200]
        },
        backgroundColor: red[500],
        color: 'white'
    },
    textField: {
        marginTop: 10,
        minWidth: 190
    }
});

interface ILoanContractSearchFilterProps {
    token: string;
    user: any;
    open: boolean;
    classes: {
        [key: string]: string;
    }
    handleClose(): void;
    search(params: IFetchLoanContractParams): void;
}

interface ILoanContractSearchFilterState {
    [key: string]: string | number | any;
    title: string;
    status: any;
    debtor_unique_id: any;
    creditor_unique_id: any
    attorney_unique_id: any;
    contract_date_from: string;
    contract_date_to: string;
    first_outlay_date_from: string;
    first_outlay_date_to: string;
    first_outlay_amount: number;
    first_outlay_amount_from: number;
    first_outlay_amount_to: number;
    credit_line_amount_from: number;
    credit_line_amount_to: number;
    credit_line_amount: number;
    debtors: any;
    creditors: any;
    attorneys: any;
    credit_line_amount_options: any;
    first_outlay_amount_options: any;

}

class LoanContractSearchFilter extends React.Component<ILoanContractSearchFilterProps, ILoanContractSearchFilterState>{
    public state: ILoanContractSearchFilterState = {
        contract_date_from: '',
        contract_date_to: new Date().toISOString().split('T')[0],

        credit_line_amount_options: "equal",
        credit_line_amount_from: 0,
        credit_line_amount_to: 0,
        credit_line_amount: 0,

        attorney_unique_id: [],
        creditor_unique_id: [],
        debtor_unique_id: [],

        first_outlay_amount_options: "equal",
        first_outlay_amount_from: 0,
        first_outlay_amount_to: 0,
        first_outlay_amount: 0,

        first_outlay_date_from: '',
        first_outlay_date_to: new Date().toISOString().split('T')[0],

        status: [],
        title: '',

        debtors: [],
        creditors: [],
        attorneys: [],
    };

    constructor(props: ILoanContractSearchFilterProps) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }


    public handleSearch() {
        const params: IFetchLoanContractParams = {
            contractDateFrom: this.state.contract_date_from,
            contractDateTo: this.state.contract_date_to,

            creditLineAmount: this.state.credit_line_amount === 0 ? '' : this.state.credit_line_amount.toString(),
            creditLineAmountFrom: this.state.credit_line_amount_from === 0 ? '' : this.state.credit_line_amount_from.toString(),
            creditLineAmountTo: this.state.credit_line_amount_to === 0 ? '' : this.state.credit_line_amount_to.toString(),

            attorneyUniqueId: this.state.attorney_unique_id.map((item: IParticipant) => item.uniqueId).join(','),
            creditorUniqueId: this.state.creditor_unique_id.map((item: ICreditor) => item.participantDetail?.uniqueId).join(','),
            debtorUniqueId: this.state.debtor_unique_id,

            firstOutlayAmount: this.state.first_outlay_amount === 0 ? '' : this.state.first_outlay_amount.toString(),
            firstOutlayAmountFrom: this.state.first_outlay_amount_from === 0 ? '' : this.state.first_outlay_amount_from.toString(),
            firstOutlayAmountTo: this.state.first_outlay_amount_to === 0 ? '' : this.state.first_outlay_amount_to.toString(),

            firstOutlayDateFrom: this.state.first_outlay_date_from,
            firstOutlayDateTo: this.state.first_outlay_date_to,

            status: this.state.status.map((item: any) => item.value).join(','),
            title: this.state.title,

            token: this.props.token
        };
        this.props.search(params);
        this.props.handleClose();
    }
    public handleClear() {
        this.setState({
            contract_date_from: '',
            contract_date_to: new Date().toISOString().split('T')[0],

            credit_line_amount_options: "equal",
            credit_line_amount_from: 0,
            credit_line_amount_to: 0,
            credit_line_amount: 0,

            attorney_unique_id: [],
            creditor_unique_id: [],
            debtor_unique_id: [],

            first_outlay_amount_options: "equal",
            first_outlay_amount_from: 0,
            first_outlay_amount_to: 0,
            first_outlay_amount: 0,

            first_outlay_date_from: '',
            first_outlay_date_to: new Date().toISOString().split('T')[0],

            status: [],
            title: '',

            debtors: [],
            creditors: [],
            attorneys: [],
        });
        this.props.search({ token: this.props.token })
        this.props.handleClose();
    }
    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({ [key]: event.target.value });
        }
    }
    public handleCurrencyInputChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>, maskedValue?: string, floatValue?: string) => {
            this.setState({ [key]: floatValue || '' });
        }
    }

    public handleAutocompleteChange(key: any, obj: any, val: any) {
        /* Handle the state of Autocomplete components */
        const data = obj || val;

        this.setState({
            [key]: data.value ? data.value : ""
        });

    }

    public render() {
        const { classes, handleClose, open } = this.props;

        const handleMultipleSelection = (key: string, val: string) =>
            this.setState({ [key]: val });
        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12}>
                            <SearchComponent
                                xs={12}
                                containerClass="field-container"
                                fieldClass={{ style: styles.label }}
                                typeClass={styles.label}
                                fieldID="client"
                                fieldInnerLabel={translations.DEBTOR}
                                handleChange={this.handleAutocompleteChange.bind(this, "debtor_unique_id")}
                                optionType={'participants'}
                                variant="standard"
                                limitTags={1}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                label={translations.TITLE}
                                value={this.state.title}
                                fullWidth={true}
                                onChange={this.handleChange('title')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <FormControl fullWidth={true}>
                                <InputLabel
                                    htmlFor="notarial_status"
                                    style={styles.label}
                                    shrink={true}
                                >
                                    {translations.STATUS}
                                </InputLabel>
                                <Autocomplete
                                    style={{ marginTop: "32px" }}
                                    multiple={true}
                                    value={this.state.status}
                                    options={loanContractStatus}
                                    getOptionLabel={(option: any) => option.label}
                                    onChange={(e, newValue: any) =>
                                        handleMultipleSelection("status", newValue)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <ParticipantAutoComplete
                                handleSelectChange={(e, newValue: any) =>
                                    handleMultipleSelection("creditor_unique_id", newValue)
                                }
                                value={this.state.creditor_unique_id}
                                type={"creditor"} label={translations.CREDITOR} />
                        </Grid>

                        <Grid item={true} xs={12}>
                            <ParticipantAutoComplete
                                handleSelectChange={(e, newValue: any) =>
                                    handleMultipleSelection("attorney_unique_id", newValue)
                                }
                                value={this.state.attorney_unique_id}
                                type={"attorney"} label={translations.ATTORNEY} />
                        </Grid>

                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.FROM}`}
                                className={classes.textField}
                                value={this.state.contract_date_from}
                                type="date"
                                onChange={this.handleChange('contract_date_from')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.TO}`}
                                className={classes.textField}
                                value={this.state.contract_date_to}
                                type="date"
                                onChange={this.handleChange('contract_date_to')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.FIRST_OUTLAY_DATE} ${translations.FROM}`}
                                className={classes.textField}
                                value={this.state.first_outlay_date_from}
                                type="date"
                                onChange={this.handleChange('first_outlay_date_from')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.FIRST_OUTLAY_DATE} ${translations.TO}`}
                                className={classes.textField}
                                value={this.state.first_outlay_date_to}
                                type="date"
                                onChange={this.handleChange('first_outlay_date_to')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={4}>
                            <InputLabel htmlFor="first_outlay_amount_options" shrink={true}>{translations.FIRST_OUTLAY_AMOUNT}</InputLabel>
                            <Select
                                style={{ width: "100%" }}
                                value={this.state.first_outlay_amount_options}
                                onChange={this.handleSelectChange('first_outlay_amount_options')}
                                inputProps={{
                                    id: 'first_outlay_amount_options',
                                    name: 'first_outlay_amount_options'
                                }}
                            >
                                {operatorList.map((item, index) => {
                                    return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                })}
                            </Select>
                        </Grid>
                        {this.state.first_outlay_amount_options === "equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.first_outlay_amount}
                                    style={{ marginTop: "32px" }}
                                    onChange={this.handleCurrencyInputChange('first_outlay_amount')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                        {this.state.first_outlay_amount_options === "between" ?
                            <>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        value={this.state.first_outlay_amount_from}
                                        style={{ marginTop: "32px" }}
                                        onChange={this.handleCurrencyInputChange('first_outlay_amount_from')}
                                        InputProps={{
                                            inputComponent: moneyInput,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.label
                                        }}
                                    />
                                </Grid>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        value={this.state.first_outlay_amount_to}
                                        style={{ marginTop: "32px" }}
                                        onChange={this.handleCurrencyInputChange('first_outlay_amount_to')}
                                        InputProps={{
                                            inputComponent: moneyInput,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.label
                                        }}
                                    />
                                </Grid>
                            </> : null
                        }
                        {this.state.first_outlay_amount_options === "greater_than_equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.first_outlay_amount_from}
                                    style={{ marginTop: "32px" }}
                                    onChange={this.handleCurrencyInputChange('first_outlay_amount_from')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                        {this.state.first_outlay_amount_options === "low_than_equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.first_outlay_amount_to}
                                    style={{ marginTop: "32px" }}
                                    onChange={this.handleCurrencyInputChange('first_outlay_amount_to')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                        <Grid item={true} xs={4}>
                            <InputLabel htmlFor="credit_line_amount_options" shrink={true}>{translations.CREDIT_LINE_AMOUNT}</InputLabel>
                            <Select
                                style={{ width: "100%" }}
                                value={this.state.credit_line_amount_options}
                                onChange={this.handleSelectChange('credit_line_amount_options')}
                                inputProps={{
                                    id: 'credit_line_amount_options',
                                    name: 'credit_line_amount_options'
                                }}
                            >
                                {operatorList.map((item, index) => {
                                    return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                })}
                            </Select>
                        </Grid>
                        {this.state.credit_line_amount_options === "equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.credit_line_amount}
                                    style={{ marginTop: "32px" }}
                                    onChange={this.handleCurrencyInputChange('credit_line_amount')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                        {this.state.credit_line_amount_options === "between" ?
                            <>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        value={this.state.credit_line_amount_from}
                                        style={{ marginTop: "32px" }}
                                        onChange={this.handleCurrencyInputChange('credit_line_amount_from')}
                                        InputProps={{
                                            inputComponent: moneyInput,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.label
                                        }}
                                    />
                                </Grid>
                                <Grid item={true} xs={4}>
                                    <TextField
                                        value={this.state.credit_line_amount_to}
                                        style={{ marginTop: "32px" }}
                                        onChange={this.handleCurrencyInputChange('credit_line_amount_to')}
                                        InputProps={{
                                            inputComponent: moneyInput,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: styles.label
                                        }}
                                    />
                                </Grid>
                            </> : null
                        }
                        {this.state.credit_line_amount_options === "greater_than_equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.credit_line_amount_from}
                                    style={{ marginTop: "32px" }}
                                    onChange={this.handleCurrencyInputChange('credit_line_amount_from')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                        {this.state.credit_line_amount_options === "low_than_equal" ?
                            <Grid item={true} xs={6}>
                                <TextField
                                    value={this.state.credit_line_amount_to}
                                    style={{ marginTop: "32px" }}
                                    onChange={this.handleCurrencyInputChange('credit_line_amount_to')}
                                    InputProps={{
                                        inputComponent: moneyInput,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label
                                    }}
                                />
                            </Grid> : null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.redButton} onClick={this.handleClear}>
                        <ClearIcon />
                        &nbsp;
                        {`${translations.CLEAR} ${translations.SEARCH}`}
                    </Button>
                    <Button className={classes.greenButton} onClick={this.handleSearch}>
                        <SearchIcon />
                        &nbsp;
                        {translations.SEARCH}
                    </Button>
                </DialogActions>
            </Dialog >
        );
    }
}

function mapStateToProps(state: IState) {
    return {
        user: state.user,
    };
}

export default withStyles(styles)(connect(mapStateToProps)(LoanContractSearchFilter));