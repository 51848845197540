import React from "react";
import {
  ConfirmDialog, SimpleDialog
} from "./../../../components/SharedComponents/Dialogs";
import {
  Typography, 
  TextField
} from '@material-ui/core';

function ConfirmDialogComponent(props) {
  const data = props.data.data;
  let dialogConfirmContent = "";
  switch (props.data.msg) {
    case "proceed-deny":
      dialogConfirmContent = `La aprobacion con el cliente: ${data.row.participantName
        }, de fecha: ${data.row.requestDate} será denegada.`;
      break;
    case "proceed-delete":
      dialogConfirmContent = `La aprobacion con el cliente: ${data.row.participantName
        }, de fecha: ${data.row.requestDate} será pospuesta.`;
      break;
    default:
      dialogConfirmContent = "...";
  }
  return (
    <ConfirmDialog
      open={props.open}
      handleToggle={props.handleToggle}
      confirmCallback={data.callback}
      data={dialogConfirmContent}
    />
  );
}

function ActNumberDialogComponent(props) {
  const handleChange = (event) => {
    props.setState(event.currentTarget.value)
  }
  const data = props.data.msg;

  return (
    <SimpleDialog
      open={props.open}
      dialogTypeClass="confirm-dialog"
      handleToggle={props.handleToggle}
      dialogTitle="Número de acta"
      dialogContent={
        <>
          <Typography variant="subtitle1">
            Digite el número de acta
          </Typography>
          <TextField
            type="number"
            onChange={(event) =>  handleChange(event)}
            value={props.state} />
        </>
      }
      dialogConfirmText="Aceptar"
      dialogCancelText="Cancelar"
      handleConfirm={data.callback}

    />
  );

}

export { ConfirmDialogComponent, ActNumberDialogComponent };
