import * as React from "react";
import { connect } from "react-redux";

import {
  AppBar,
  Button,
  FormControl,
  InputLabel,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
  createStyles,
  withStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import AlertDialog from "../../components/AlertDialog";
import Loading from "../../components/Loading";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import {
  SITUATION_OPTIONS,
  catchError,
  resetError,
  successHandler,
  translations,
  fiscalConfigTypes,
  ratingTypes,
} from "../../utils";
import {
  createFinancialProfile,
  deleteFinancialProfile,
  fetchBankAccounts,
  fetchChargingMethod,
  fetchCreditors,
  fetchFinancialProfileByOwner,
  updateFinancialProfile,
  fetchResponsibleAnalysts,
} from "../../utils/axios";

import Popup from "../../components/UI/Popup";
import FinancialMethodForm from "./FinancialMethodForm";
import TabPanel from "./TabPanel";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 50,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  checkboxLabelReactSelect: {
    color: green[500],
    fontSize: "small",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formButton: {
    background: "white",
    border: "1px solid",
    borderRadius: "10px",
    color: "#4caf50",
    fontWeight: "bold",
    margin: "15px",
  },
  formControl: {
    marginTop: 20,
    // minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    padding: "10px 0px",
  },
  table: {
    minWidth: 650,
  },
});

class FinancialProfile extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      assignee: "",
      banksAccounts: [],
      chargingMethods: [],
      creditor: "",
      creditorAccounts: [],
      creditorList: [],
      dialog: false,
      dialogTitle: "",
      errorMessage: "",
      fiscalConfig: "",
      isMounted: false,
      loading: false,
      mainChargingMethod: "",
      openForm: false,
      originAccount: "",
      owner: "",
      rating: "",
      situation: "active",
      // dwellingType: "",
      tab: 0,
      uniqueId: "",
      updateMethods: false,
      documents : [],
      users: [],
    };
    this.initialState = { ...this.state };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.switchModal = this.switchModal.bind(this);
    this.fillCreditorOptions = this.fillCreditorOptions.bind(this);
    this.changeOccurs = this.changeOccurs.bind(this);
  }

  componentDidMount() {
    if (this.props.ownerId.length < 1) {return}
    this.setState({ loading: true, isMounted: true });
    
    if (this.props.ownerId && this.props.ownerId !== "") {
      fetchChargingMethod(this.props.user.token, this.props.ownerId)
        .then((res) => {
          this.setState({
            chargingMethods: res.data,
          });
        })
        .catch((e) => console.error(e));

      fetchFinancialProfileByOwner(this.props.user.token, this.props.ownerId)
        .then((res) => {
          if (res.data && res.data.length !== 0 && this.state.isMounted) {
            const curProfile = res.data[0];
            this.setState({
              FP: `crm/financialprofile/${curProfile.uniqueId}/upload_code_card/`,
              assignee: curProfile.assignee || "",
              fiscalConfig: curProfile.fiscalConfig || "",
              loading: false,
              mainChargingMethod: curProfile.mainChargingMethod || "",
              originAccount: curProfile.originAccount || "",
              owner: curProfile.owner || "",
              profileExist: true,
              rating: curProfile.rating || "",
              // dwellingType: curProfile.dwellingType || "",
              situation: curProfile.situation || "",
              uniqueId: curProfile.uniqueId || "",
            });
          } else if (this.state.isMounted) {
            this.setState({
              loading: false,
              profileExist: false,
            });
          }
        })
        .catch((err) => {
          if (this.state.isMounted) {
            catchError(err, this);
          }
        });
    }

    fetchCreditors({ token: this.props.user.token, isRepresentative: true })
      .then((res) => {
        this.setState({
          creditorList: res.data,
        });
      })
      .catch((e) => console.error(e));

    // Fetch list of Client Accounts
    fetchBankAccounts({ token: this.props.user.token })
      .then((res) => {
        this.setState({ banksAccounts: res.data });
        try {
          const creditor = res.data.filter(
            (x) => x.uniqueId === this.state.originAccount
          )[0].owner;
          this.fillCreditorOptions(creditor);
        } catch (error) {
          return;
        }
      })
      .catch((e) => console.error(e));

    fetchResponsibleAnalysts(this.props.user.token)
      .then((res) => {
        this.setState({
          users: res.data,
        });
      })
      .catch((e) => catchError(e, this));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.ownerId.length < 1) {return false}
    if (prevState.updateMethods !== this.state.updateMethods) {
      this.setState({ loading: true });
      fetchChargingMethod(this.props.user.token, this.props.ownerId)
        .then((res) => {
          this.setState({
            chargingMethods: res.data,
            loading: false
          });
        })
        .catch((e) => catchError(e, this));
    }
  }

  changeOccurs() {
    this.setState({
      updateMethods: !this.state.updateMethods,
    });
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  fillCreditorOptions(creditorId) {
    this.setState({
      creditor: creditorId,
      creditorAccounts: this.state.banksAccounts.filter(
        (acc) => acc.owner === creditorId
      ),
    });
  }

  switchModal(key) {
    this.setState({
      [key]: !this.state[key],
    });
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }

  handleChangeTab(key) {
    return (event, newValue) => {
      this.setState({
        [key]: newValue,
      });
    };
  }

  handleSelectChange(key) {
    return (event) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }

  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || "" });
    };
  }

  handleReset() {
    this.setState({ ...this.initialState });
  }

  handleSubmit(event) {
    resetError(this);
    let profile = {
      assignee: this.state.assignee,
      fiscalConfig: this.state.fiscalConfig,
      mainChargingMethod: this.state.mainChargingMethod,
      originAccount: this.state.originAccount || "",
      owner: this.props.ownerId || "",
      rating: this.state.rating,
      // dwellingType: this.state.dwellingType,
      situation: this.state.situation,
    };

    const { situation } = this.state;

    if (situation.trim() === "") {
      profile = Object.assign(profile, { situation: null });
    }

    this.setState({ loading: true });

    if (this.state.profileExist) {
      updateFinancialProfile(
        this.props.user.token,
        this.state.uniqueId,
        profile
      )
        .then((res) => {
          successHandler(res, this, translations.FINANCIAL_PROFILE);
        })
        .catch((err) => {
          catchError(err, this);
        });
    } else {
      profile.owner = this.props.ownerId;

      createFinancialProfile(this.props.user.token, profile)
        .then((res) => {
          if (this.props.handleSaveCallBack) {
            this.props.handleSaveCallBack(res.data.uniqueId);
          }
          successHandler(res, this, translations.FINANCIAL_PROFILE);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }

  handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE) && this.state.profileExist) {
      this.setState({ loading: true });

      deleteFinancialProfile(this.props.user.token, this.state.uniqueId)
        .then((res) => {
          // check secret questions
          this.handleReset();
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }

  handleDialogClose() {
    this.setState({ dialog: false });
  }


  render() {
    const { classes } = this.props;

    const NavBar = (chargingMethods) => {
      return (
        <>
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.tab}
              onChange={this.handleChangeTab("tab")}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {chargingMethods.map((method, index) => {
                return <Tab label={method.label} id={index} key={index} />;
              })}
            </Tabs>
          </AppBar>
          {chargingMethods.map((method, indx) => {
            return (
              <TabPanel
                changeOccurs={this.changeOccurs}
                data={method}
                value={this.state.tab}
                index={indx}
                key={indx}
              />
            );
          })}
        </>
      );
    };

    return (
      <div className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">
            {`${!this.state.profileExist ? "Crear" : ""} ${translations.FINANCIAL_PROFILE}`}
          </Typography>
          <br/>
          <br/>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.SITUATION}
                    value={this.state.situation}
                    onChange={this.handleSelectChange("situation")}
                    inputProps={{
                      name: "situation",
                    }}
                  >
                    {SITUATION_OPTIONS.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label={translations.CREDITOR}
                    value={this.state.creditor}
                    onChange={(e) => this.fillCreditorOptions(e.target.value)}
                    inputProps={{
                      name: "creditor",
                    }}
                  >
                    {this.state.creditorList.map((creditor, indx) => {
                        const creditorName = creditor.participantDetail ? creditor.participantDetail.fullName : "---";
                        const creditorInst = creditor.institutionDetail ? creditor.institutionDetail.abbreviation : "---";
                        return(
                        <MenuItem
                          value={creditor.uniqueId}
                          key={indx}
                        >
                          {creditor.type === 'physical' ? creditorName : creditorInst}
                        </MenuItem>
                        )
                      })}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.DESTINATION_ACCOUNT}
                    value={this.state.originAccount}
                    onChange={this.handleSelectChange("originAccount")}
                    inputProps={{
                      name: "originAccount",
                    }}
                  >
                    {this.state.creditorAccounts.map((account, indx) => {
                      return (
                        <MenuItem value={account.uniqueId} key={indx}>
                          {account.name} - {account.number}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label="Gestor de Cobro"
                    value={this.state.assignee}
                    onChange={this.handleSelectChange("assignee")}
                    inputProps={{
                      name: "assignee",
                    }}
                  >
                    {this.state.users.map((part, indx) => {
                      return (
                        <MenuItem value={part.fullName} key={indx}>
                          {part.fullName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label={translations.FISCAL_CONFIG}
                    value={this.state.fiscalConfig}
                    onChange={this.handleSelectChange("fiscalConfig")}
                    inputProps={{
                      name: "fiscalConfig",
                    }}
                  >
                    {fiscalConfigTypes.map((item, indx) => {
                      return (
                        <MenuItem value={item.value} key={indx}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    select
                    label="Rating"
                    value={this.state.rating}
                    onChange={this.handleSelectChange("rating")}
                    inputProps={{
                      name: "rating",
                    }}
                  >
                    {ratingTypes.map((item, indx) => {
                      return (
                        <MenuItem value={item.value} key={indx}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Metodo de Cobro Principal"
                    value={this.state.mainChargingMethod}
                    onChange={this.handleSelectChange("mainChargingMethod")}
                    inputProps={{
                      name: "mainChargingMethod",
                    }}
                  >
                    {this.state.chargingMethods.map((mainMethod, indx) => {
                      return (
                        <MenuItem value={mainMethod.uniqueId} key={indx}>
                          {`${mainMethod.label}`}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </Grid>
              <Button
                className={classes.formButton}
                onClick={() => this.switchModal("openForm")}
                startIcon={<AddCircleIcon />}
                variant="contained"
              >
                Agregar Metodo
              </Button>
              <Popup
                title="Metodos de Credito"
                open={this.state.openForm}
                funcion={this.switchModal}
                onCloseValue="openForm"
              >
                <FinancialMethodForm
                  ownerId={this.props.ownerId}
                  changeOccurs={this.changeOccurs}
                />
              </Popup>

              <div className={classes.root}>
                {NavBar(this.state.chargingMethods)}
              </div>

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  disabled={true}
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  onClick={this.handleSubmit}
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(FinancialProfile));