import React from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  IconButton,
  Divider,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { TextFieldComponent } from "../../../components/SharedComponents/Fields";

function PaymentDetailDialog(props) {
  return (
    <Dialog
      maxWidth={"lg"}
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={props.open}
    >
      <DialogTitle style={{ backgroundColor: green[500] }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography
            component={"span"}
            variant="h5"
            className="dialog-title"
            style={{ color: "white" }}
          >
            {props.client.fullName}
          </Typography>
          <IconButton onClick={props.handleToggleDialog.bind(this, undefined)}>
            <CloseOutlined className="dialog-header-close-btn" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ position: "relative", marginBottom: "5%" }}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="modal-title">Detalle del pago</h3>
            </Grid>
            <Divider />
            {props.fields.map((item, index) => {
              return (
                <TextFieldComponent
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel={item.label}
                  fieldID="requestNumber"
                  fieldClass="fields"
                  fieldVariant="outlined"
                  margin="dense"
                  fullWidth
                  value={item.value}
                  disabled={true}
                />
              );
            })}
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export { PaymentDetailDialog };
