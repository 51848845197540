import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import {
  AutocompleteField,
  TextFieldComponent,
  NumericTextField,
  SearchAutocompleteField,
} from "./../../../components/SharedComponents/Fields";
import phoneMaskInput from "../../../components/TextMaskInputComponents/phoneMaskInput";
import { FileSelection } from "./../../../components/SharedComponents/FileSelection";
import {
  createFileAttachment,
  fetchCities,
  fetchFileAttachments,
  fetchRegions,
  fetchStates,
} from "src/utils/axios";
import {
  handleUploadFileEndpoint,
  getPossibleFileAttachment,
} from "../GeneralMethods";

export default function ContactForm({
  rFields,
  fields,
  onChange,
  errorFields,
  options,
  section,
  getFiltersOptions,
  handleSelectValue,
  token,
  setLoading,
  handleSimpleDialog,
  leadUniqueId,
  disabled,
}) {
  const [filteredLocations, setFilteredLocations] = useState({});
  const [file, setFile] = useState("");
  const [savedFile, setSavedFile] = useState(fields.addressFile || null);

  const handleFilterLocations = () => {
    const regions = options.regions?.filter((item) =>
      fields.country?.rohiId ? item.country === fields.country?.rohiId : item
    );
    const states = options.states?.filter((item) =>
      fields.region?.rohiId ? item.region === fields.region?.rohiId : item
    );
    const cities = options.cities?.filter((item) =>
      fields.province?.rohiId ? item.state === fields.province?.rohiId : item
    );
    return { regions, states, cities };
  };
  const getCurrentFile = async () => {
    const options = {
      token,
      file: fields.addressFile,
      field: "addressFile",
      leadUniqueId,
    };
    const currFile = await getPossibleFileAttachment(options);
    if (currFile) {
      onChange("any", "addressFile", currFile);
    }
  };
  // // method not used since we pass the file directly to the property.
  // // keeping it here for later use.
  // const handleUploadFileEndpoint = () => {
  //     let formData = new FormData();
  //     formData.append("file", file);
  //     formData.append("name", `address-file-${leadUniqueId}`);
  //     return createFileAttachment(token, formData);
  // }
  // // method not used since we pass the file directly to the property.
  // // keeping it here for later use.
  // const getPossibleFileAttachment = async () => {
  //     if(!fields.addressFile) {
  //         let currSavedFile = null;
  //         const name = `address-file-${leadUniqueId}`;
  //         const endpoint = fetchFileAttachments({token: token, name});
  //         await endpoint.then(res => {
  //             currSavedFile = res.data?.length ? res.data[res.data.length - 1] : null;
  //         });
  //         if(currSavedFile) {
  //             onChange('any', 'addressFile', currSavedFile);
  //         }
  //     }
  // }

  // const handleChangeLocation = () => {
  //     if(fields.region && !fields.country) {
  //         const selectedCountry = options.nationalities.find(item => item.rohiId === fields.region?.country);
  //         onChange('any', 'country', selectedCountry || null);
  //     }
  //     if(fields.province && !fields.region) {
  //         const selectedRegions = options.regions.find(item => item.rohiId === fields.province?.region);
  //         onChange('any', 'regions', selectedRegions || null);
  //     }
  //     if(fields.city && !fields.province) {
  //         const selectedProvince = options.states.find(item => item.rohiId === fields.city?.state);
  //         onChange('any', 'province', selectedProvince || null);
  //     }
  // }

  useEffect(() => {
    getCurrentFile();
  }, []);
  useEffect(() => {
    const filters = handleFilterLocations();
    setFilteredLocations(filters);
  }, [fields.country, fields.region, fields.province, fields.city]);

  useEffect(() => {
    if (savedFile?.uniqueId !== fields.addressFile?.uniqueId) {
      // onChange('any', 'addressFile', savedFile);
      onChange("any", "addressFile", savedFile);
    }
  }, [savedFile]);

  // useEffect(() => {
  //     if(file) {
  //         onChange('any', 'addressFile', file);
  //     }
  // }, [file]);

  // useEffect(() => {
  //     handleChangeLocation();
  // }, [filteredLocations])

  return (
    <Grid container spacing={3} className="form-wrapper-fields-container">
      <AutocompleteField
        xs={4}
        fieldLabel={`País ${rFields.includes("country") ? "(*)" : ""}`}
        fieldID="leads-field-contactCountr"
        fieldInnerLabel="Seleccione una opción"
        value={
          handleSelectValue(fields.country, options.nationalities, "name") ||
          null
          // fields.country || null
        }
        handleChange={(e, val) => {
          onChange("any", "country", val);
          onChange("any", "province", null);
          onChange("any", "region", null);
          onChange("any", "city", null);
        }}
        options={getFiltersOptions(options.nationalities || [])}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        error={Boolean(errorFields[section]?.country)}
        helperText={
          errorFields[section]?.country ? errorFields[section]?.country : null
        }
        multiple={false}
        disabled={disabled}
      />
      <SearchAutocompleteField
        xs={4}
        fieldLabel={`Región ${rFields.includes("region") ? "(*)" : ""}`}
        fieldID="leads-field-contactReg"
        fieldInnerLabel="Seleccione una opción"
        endpoint={fetchRegions}
        token={token}
        prev={{ region: fields.country?.name }}
        value={
          // fields.region || null
          handleSelectValue(fields.region, options.regions, "name") || null
        }
        handleChange={(e, val) => {
          onChange("any", "region", val);
          onChange("any", "province", null);
          onChange("any", "city", null);
        }}
        options={filteredLocations.regions || []}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) =>
          option.uniqueId === value.uniqueId
        }
        error={Boolean(errorFields[section]?.region)}
        helperText={
          errorFields[section]?.region ? errorFields[section]?.region : null
        }
        multiple={false}
        disabled={disabled}
      />

      <SearchAutocompleteField
        xs={4}
        fieldLabel={`Provincia ${rFields.includes("province") ? "(*)" : ""}`}
        fieldID="leads-field-contactProv"
        fieldInnerLabel="Seleccione una opción"
        endpoint={fetchStates}
        token={token}
        prev={{ region: fields.region?.name }}
        value={
          handleSelectValue(fields.province || fields.state, options.states, "name") || null
        }
        handleChange={(e, val) => {
          onChange("any", "province", val);
          onChange("any", "city", null);
        }}
        options={filteredLocations.states || []}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) =>
          option.uniqueId === value.uniqueId
        }
        error={Boolean(errorFields[section]?.province)}
        helperText={
          errorFields[section]?.province ? errorFields[section]?.province : null
        }
        multiple={false}
        disabled={disabled}
      />
      <SearchAutocompleteField
        xs={4}
        fieldLabel={`Ciudad ${rFields.includes("city") ? "(*)" : ""}`}
        fieldID="leads-field-contactCit"
        fieldInnerLabel="Seleccione una opción"
        endpoint={fetchCities}
        token={token}
        prev={{ state: fields.province?.name }}
        value={
          // fields.city || null
          handleSelectValue(fields.city, options.cities, "name") || null
        }
        handleChange={(e, val) => onChange("any", "city", val)}
        options={filteredLocations.cities || []}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) =>
          option.uniqueId === value.uniqueId
        }
        error={Boolean(errorFields[section]?.city)}
        helperText={
          errorFields[section]?.city ? errorFields[section]?.city : null
        }
        multiple={false}
        disabled={disabled}
      />
      <TextFieldComponent
        xs={4}
        fullWidth
        fieldLabel={`Sector ${rFields.includes("sector") ? "(*)" : ""}`}
        fieldID="leads-field-contactSect"
        placeholder="Digite un sector"
        value={fields.sector || ""}
        onChange={onChange.bind(this, "text", "sector")}
        error={Boolean(errorFields[section]?.sector)}
        helperText={
          errorFields[section]?.sector ? errorFields[section]?.sector : null
        }
        disabled={disabled}
      />
      <TextFieldComponent
        xs={4}
        fullWidth
        fieldLabel={`Dirección ${rFields.includes("reference") ? "(*)" : ""}`}
        fieldID="leads-field-contactAddr"
        placeholder="Ej: Calle Pablo III Esq. Trebol #44"
        value={fields.reference || ""}
        onChange={onChange.bind(this, "text", "reference")}
        error={Boolean(errorFields[section]?.reference)}
        helperText={
          errorFields[section]?.reference
            ? errorFields[section]?.reference
            : null
        }
        disabled={disabled}
      />
      <TextFieldComponent
        xs={4}
        fullWidth
        fieldLabel={`Teléfono celular ${
          rFields.includes("cellPhone") ? "(*)" : ""
        }`}
        fieldID="leads-field-mobile-number"
        placeholder="Ej: +1(809)000-0001"
        value={fields.cellPhone || ""}
        onChange={onChange.bind(this, "text", "cellPhone")}
        error={Boolean(errorFields[section]?.cellPhone)}
        helperText={
          errorFields[section]?.cellPhone
            ? errorFields[section]?.cellPhone
            : null
        }
        type="phone"
        InputProps={{
          inputComponent: phoneMaskInput,
        }}
        disabled={disabled}
      />
      <TextFieldComponent
        xs={4}
        fullWidth
        fieldLabel={`Teléfono residencia ${
          rFields.includes("homePhone") ? "(*)" : ""
        }`}
        fieldID="leads-field-home-number"
        placeholder="Ej: +1(809)000-0002"
        value={fields.homePhone || ""}
        onChange={onChange.bind(this, "text", "homePhone")}
        error={Boolean(errorFields[section]?.homePhone)}
        helperText={
          errorFields[section]?.homePhone
            ? errorFields[section]?.homePhone
            : null
        }
        type="phone"
        InputProps={{
          inputComponent: phoneMaskInput,
        }}
        disabled={disabled}
      />
      <TextFieldComponent
        xs={4}
        fullWidth
        fieldLabel={`Teléfono (Otro) ${
          rFields.includes("additionalPhone") ? "(*)" : ""
        }`}
        fieldID="leads-field-extra-number"
        placeholder="Ej: +1(809)000-0003"
        value={fields.additionalPhone || ""}
        onChange={onChange.bind(this, "text", "additionalPhone")}
        error={Boolean(errorFields[section]?.additionalPhone)}
        helperText={
          errorFields[section]?.additionalPhone
            ? errorFields[section]?.additionalPhone
            : null
        }
        type="phone"
        InputProps={{
          inputComponent: phoneMaskInput,
        }}
        disabled={disabled}
      />

      <TextFieldComponent
        xs={4}
        fullWidth
        fieldLabel={`Correo electrónico personal ${
          rFields.includes("owner_email") ? "(*)" : ""
        }`}
        fieldID="leads-field-owner_email"
        placeholder="Ej: dumbledore@gmail.com"
        value={fields.owner_email || ""}
        onChange={onChange.bind(this, "text", "owner_email")}
        error={Boolean(errorFields[section]?.owner_email)}
        helperText={
          errorFields[section]?.owner_email
            ? errorFields[section]?.owner_email
            : null
        }
        disabled={disabled}
      />
      <Grid
        item
        container
        direction="row"
        xs={8}
        spacing={2}
        alignItems="flex-end"
        style={{ padding: "0 16px", marginTop: "1rem", flexWrap: "nowrap" }}
      >
        <div style={{ paddingLeft: 8 }}>
          <Typography variant="subtitle1" className="field-label">
            Archivo Dirección
          </Typography>
          <FileSelection
            xs={12}
            file={file}
            inputId={"input-file-address"}
            setFile={setFile}
            savedFile={
              typeof fields.addressFile === "string"
                ? { uniqueId: fields.addressFile }
                : fields.addressFile
            }
            setSavedFile={setSavedFile}
            showSavedFile={true}
            uploadFileEndpoint={() =>
              handleUploadFileEndpoint({ token, file, leadUniqueId })
            }
            fileExtensions=".pdf,.png,.jpg,.jpeg"
            token={token}
            setLoading={setLoading}
            handleSimpleDialog={handleSimpleDialog}
            disabled={disabled}
          />
        </div>
      </Grid>
    </Grid>
  );
}
