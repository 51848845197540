import * as React from 'react';

import Typography from '@material-ui/core/Typography';

interface IDefaultSectionProps {
    message: string;
}

const DefaultSection = (props: IDefaultSectionProps) => (
    <Typography 
        style={{ textAlign: 'center', marginTop: '30%' }} 
        color="inherit" variant="h6"
    >
        {props.message}
    </Typography>
);

export default DefaultSection;