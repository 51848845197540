import React, { useState, useEffect } from 'react';
import { Grid, Typography, IconButton, Button, Toolbar} from '@material-ui/core';
import { Edit, Delete, Image, Description, DeleteOutline, Receipt } from '@material-ui/icons';
import { GeneralTable } from './../../../components/SharedComponents/Table';
import NumberFormat from './../../../components/SharedComponents/NumberFormat';
import { getFileAttachment, printPaymentReceiptDocument } from "../../../utils/axios";
import { TableSearch } from './../../../components/SharedComponents/Fields';
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';

function PaymentReciptToolbar(props) {
    return(
        <Toolbar>
            <Grid container item direction="row" spacing={5} alignItems="center">
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{fontWeight: 'bold', paddingLeft:16}}
                >
                    Recibos de pago
                </Typography>
                {
                    (props.selected && props.selected.length) ?
                    <Button
                        variant="outlined"
                        style={{fontSize: '10px', color:'#e34f4f', borderColor:'#e34f4f', backgroundColor:'#fff', marginLeft: 16 }}
                        disableElevation
                        size="small"
                        onClick={
                            props.handleToggleSimpleDialog
                            .bind(this, 'confirm-dialog', 'proceed-delete-multiple', {callback: props.handleDeleteMultiplePayment})
                        }
                    >
                        <DeleteOutline style={{ fontSize: 15, color: '#e34f4f', margin: '0 .5rem .1rem 0' }} />
                        eliminar recibos de pago
                    </Button> : null
                }
            </Grid>
        </Toolbar>
    );
}

function PaymentReceiptTable(props) {
    const [tableData, setTableData] = useState([]);
    const actions = (row) => {
        return(
            <Grid container direciton="row" justifyContent="space-between">
                <IconButton
                    onClick={props.handleToggleDialog.bind(this, 'payment-receipt', row)}
                >
                    <Edit style={{fontSize:22, color:'#616161'}} />
                </IconButton>
                <IconButton
                    onClick={
                        props.handleToggleSimpleDialog
                        .bind(this, 'confirm-dialog', 'proceed-delete', {callback: props.handleDelete.bind(this, row), row: row})
                    }
                >
                    <Delete style={{fontSize:22, color:'#616161'}} />
                </IconButton>
            </Grid>
        );
    };
    const fileAttachment = (row, value) => {
        const openFile = (uid) => {
            if (uid !== undefined){
                props.setLoading(true);
                const endpoint = getFileAttachment(props.user.token, uid);
                endpoint.then((res) => {
                    const dbuff = new Uint8Array(res.data.file).buffer
                    const data = new Blob([dbuff], {type: res.data.type});
                    const fileURL = URL.createObjectURL(data);
                    const NewWindow = window.open();
                    NewWindow.location.href = fileURL;
                    props.setLoading(false);
                }).catch(errors => {
                    props.setLoading(false);
                    errorHandling(errors, props.handleToggleSimpleDialog);
                });
              }
        }
        return(
            <Grid container direciton="row" justifyContent="center">
                <IconButton
                    onClick={openFile.bind(this, value)} // a dialog showing the fileAttachment
                    disabled={!value}
                >
                    <Image style={{fontSize:22, color: value ? '#43a047' : '#616161'}} />
                </IconButton>
            </Grid>
        )
    }
    const printReceipt = (row) => {
        const getReceiptDoc = () => {
            props.setLoading(true);
            const endpoint = printPaymentReceiptDocument(props.user.token, row.uniqueId);
            endpoint.then(response => {
                const file = new Blob([response.data], { type: "text/html" });
                const fileURL = URL.createObjectURL(file);
                const NewWindow = window.open();
                NewWindow.location.href = fileURL;
                props.setLoading(false);
            }).catch(errors => {
                props.setLoading(false);
                errorHandling(errors, props.handleToggleSimpleDialog);
            });
        }
        return(
            <Grid container direciton="row" justifyContent="center">
                <IconButton
                    onClick={getReceiptDoc}
                >
                    <Receipt style={{fontSize:22, color:'#43a047'}} />
                </IconButton>
            </Grid>
        )
    }
    const amount = (row, value) => {
        return(
            <NumberFormat
                value={value}
                prefix={'$'}
            />
        )
    }
    const columns = [
        {id:'checkbox', label:'SELECCIÓN', minWidth: 70 },
        {id:'participantName', label: 'NOMBRE CLIENTE', minWidth: 150},
        {id:'concept', label: 'CONCEPTO', minWidth: 150},
        {id:'receiptDate', label: 'FECHA', minWidth: 120},
        {id:'receiptAmountCurrency', label: 'DIVISA', minWidth: 150,},
        {id:'receiptAmount', label: 'MONTO', minWidth: 150, element: amount},
        {id:'fileAttachment', label: 'ADJUNTO', align:'center', minWidth: 120, element: fileAttachment},
        {id:'print', label: 'PLANTILLA', align:'center', minWidth: 120, element: printReceipt},
        {id:'action', label: 'ACCIÓN', align:'center', minWidth: 100, element: actions},
    ];
    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);
    return(
        <GeneralTable
            columns={columns}
            data={tableData}
            selected={props.selected}
            setSelected={props.setSelected}
            handleSelected={props.handleSelected}
            toolbar={
                <Grid container justifyContent='space-between' alignItems='center'>
                    <PaymentReciptToolbar
                        selected={props.selected}
                        handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                        handleDeleteMultiplePayment={props.handleDeleteMultiplePayment}
                    />
                    <TableSearch
                        data={props.data}
                        setTableData={setTableData}
                        searchFields={['concept', 'participantName', 'receiptAmount']}
                    />
                </Grid>
            }
        />
    );
}

export { PaymentReceiptTable }