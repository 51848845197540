import * as React from 'react';

import { InputBaseComponentProps } from '@material-ui/core/InputBase';

import TextMaskInput from './';

const idNumberMaskInput = (props: InputBaseComponentProps) => {
    const { inputRef, ...others } = props;
    return (
        <TextMaskInput 
            {...others}
            guide={false}
            mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/ ]}
            showMask={false}
        />
    );
}

export default idNumberMaskInput;