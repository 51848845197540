import * as React from 'react';

import Buttom from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import ArrowbackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';

import { translations } from '../../utils';
import phoneMaskInput from '../TextMaskInputComponents/phoneMaskInput';

const styles:any = createStyles({
    button: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    checkboxLabel: {
        color: green[500],
        fontSize: 'medium'
    },
    formControl: {
        marginTop: 20,
        minWidth: 300,
        width: 300
    },
    root: {
        marginBottom: 5,
        marginTop: 5
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: 500

    }
});

interface ISelectInputProp {
    commercialReferences: ICommercialReferences[];
    classes: { [key: string]: string }
    tableLimit: number;
    add(commercialReference: ICommercialReferences): void;
    delete(index: number): void
}

interface ISelectInputState {
    [key: string]: string | boolean;

    open: boolean;
    phoneNumber: string;
    phoneNumberError: string;
    company: string;
    companyError: string;
}

class SelectInput extends React.Component<ISelectInputProp, ISelectInputState> {
    public state: ISelectInputState = {
        open: false,
        phoneNumber: '',
        phoneNumberError: '',
        company: '',
        companyError: '',
    };

    constructor(props: ISelectInputProp) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.openDialogHandler = this.openDialogHandler.bind(this);
        this.closeDialogHandler = this.closeDialogHandler.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.map = this.map.bind(this);
    }
    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                [key]: event.currentTarget.value,
                [key + 'Error']: ''
            });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({
                [key]: event.target.value,
                [key + 'Error']: ''
            });
        }
    }
    public openDialogHandler() {
        this.setState({ open: true });
    }
    public closeDialogHandler() {
        this.setState({ open: false });
    }
    public handleAdd(event: React.MouseEvent<HTMLElement>) {

        let valid: boolean = true;
        const errorMessage = "Este campo no puede estar vacio";

        if (this.state.company.trim() === '') {
            valid = false;
            this.setState({ companyError: errorMessage });
        }

        if (this.state.phoneNumber.trim() === '') {
            valid = false;
            this.setState({ phoneNumberError: errorMessage });
        }

        if (valid) {
            this.props.add({
                company: this.state.company,
                phoneNumber: this.state.phoneNumber,
            });

            this.setState({ open: false, company: '', phoneNumber: '' });
        }

    }
    public handleDelete(index: number) {
        return (event: React.MouseEvent<HTMLElement>) => {
            this.props.delete(index);
        }
    }
    public map(commercialReference: ICommercialReferences, index: number) {
        return (
            <TableRow key={index}>
                <TableCell>{commercialReference.company}</TableCell>
                <TableCell>{commercialReference.phoneNumber}</TableCell>
                <TableCell>
                    <IconButton onClick={this.handleDelete(index)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }

    public render() {
        const { classes, commercialReferences } = this.props;
        const { open } = this.state;


        return (
            <div className={classes.root}>
                <Dialog open={open} onClose={this.closeDialogHandler} >
                    <DialogActions>
                        <IconButton className={classes.button} onClick={this.closeDialogHandler}>
                            <ArrowbackIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent >
                        <TextField
                            label={translations.COMPANY}
                            className={classes.textField}
                            value={this.state.company}
                            onChange={this.handleChange('company')}
                            error={this.state.companyError !== ''}
                            fullWidth={true}
                            helperText={this.state.companyError}
                            required={true}
                            InputLabelProps={{ style: styles.checkboxLabel }}
                        />
                        <TextField
                            label={translations.PHONE}
                            className={classes.textField}
                            value={this.state.phoneNumber}
                            type="phone"
                            placeholder="+1(809) 000-0000"
                            onChange={this.handleChange('phoneNumber')}
                            error={this.state.phoneNumberError !== ''}
                            fullWidth={true}
                            helperText={this.state.phoneNumberError}
                            required={true}
                            InputProps={{
                                inputComponent: phoneMaskInput
                            }}
                            InputLabelProps={{ style: styles.checkboxLabel }}
                        />
                        <br/>
                        <Buttom onClick={this.handleAdd} className={classes.button}>
                            {translations.SAVE}
                        </Buttom>
                    </DialogContent>
                </Dialog>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{translations.COMPANY}</TableCell>
                                <TableCell>{translations.PHONE}</TableCell>
                                <TableCell align={'right'}>
                                {commercialReferences.length < this.props.tableLimit ?
                                    <IconButton className={classes.button} onClick={this.openDialogHandler}>
                                        <AddIcon />
                                    </IconButton>
                                : "Acciones"}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {commercialReferences.map(this.map)}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(SelectInput); 
