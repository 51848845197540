
import { AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES } from '../actions';

export default function(state: IAutomaticWithdrawalAuthorizationContract[] = [], action: IAction<IAutomaticWithdrawalAuthorizationContract[]>): IAutomaticWithdrawalAuthorizationContract[] {
    switch(action.type) {
        case AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES.FETCH_ALL:
            return action.payload || [];
        case AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES.FETCH:
            return state.map(automaticDebitAuthorizationContract => (action.payload !== undefined && automaticDebitAuthorizationContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: automaticDebitAuthorizationContract);
        case AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES.UPDATE:
            return state.map(automaticDebitAuthorizationContract => (action.payload !== undefined && automaticDebitAuthorizationContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : automaticDebitAuthorizationContract);
        case AUTOMATIC_WITHDRAWAL_AUTHORIZATION_CONTRACT_TYPES.DELETE:
            return state.filter(automaticDebitAuthorizationContract => action.payload !== undefined && automaticDebitAuthorizationContract.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}