import React, { useState, useRef } from "react";
import {
    TabsStyled, TabStyled,
} from "../../TicketGeneralMethods";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Popup from "../../../../components/UI/Popup";
import QuotationForm from "../../../../components/Calculators/AmortizationTable";
import ContractValidation from "./StepContractValidation";
import CreditAnalysis from "./StepCreditAnalysis";
import CreditFormalization from "./StepCreditFormalization";
import EvalFormsSettlement from "./StepEvalFormsSettlement";
import TicketManagementView from "../../View";
import ApprovementLoanView from "src/containers/ApprovementLoan/View"
import { green } from "@material-ui/core/colors";
import { connect } from "react-redux";

const styles = createStyles({
  cardHover: {
    "&:hover": { backgroundColor: green[500], color: "white" },
  },
});

function DmsComponents(props) {
  const components = {
    ticket: TicketManagementView,
    quotation: QuotationForm,
    loanApproval: ApprovementLoanView,
  }
  const Component = components[props?.name];
  return(
      props?.name ?
      <Component 
        {...props}
      /> : ""
  )
}

function DisbursementRequestDetail(props) {
  let childQuotationHandler = useRef(null);
  const [openQuotation, setOpenQuotation] = useState(false);
  const [quotationId, setQuotationId] = useState("");
  const [componentsDialog, setComponentsDialog] = useState(null);

  const handleToggleComponent = (data) => {
    if (data === "close") {
      setComponentsDialog(null);
      return;
    }
    if (!data.open) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `No hay ningún documento seleccionado.`
      );
      return;
    }
    setComponentsDialog(data);
  }

  const handleToggleQuotation = (data, handleQuotation) => {
    if(handleQuotation) {
      childQuotationHandler.current = handleQuotation;
    }
    if (data === "close") {
      if(quotationId && childQuotationHandler.current) {
        childQuotationHandler.current(quotationId);
      }
      setOpenQuotation(!openQuotation);
      return;
    }

    if (!data) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `No hay ninguna cotización seleccionada.`
      );
      return;
    }
    setQuotationId(data);
    setOpenQuotation(!openQuotation);
  };

  return (
    <div>
      <TabsStyled
        value={props.currentTab}
        onChange={props.handleTabChange}
        variant="scrollable"
        scrollButtons="on"
        aria-label="ticket-step-tabs"
      >
        <TabStyled
          label="Evaluación y formas de liquidación"
        />
        <TabStyled
          label="Elaboración y validación de contratos"
          disabled={props.disabledTabs.length > 0 && props.findStep('contract_validation')}
        />
        <TabStyled
          label="Análisis de crédito"
          disabled={props.disabledTabs.length > 0 && props.findStep('credit_analysis')}
        />
        <TabStyled
          label="Formalización de crédito"
          disabled={props.disabledTabs.length > 0 && props.findStep('credit_formalization')}
        />
      </TabsStyled>
      {props.currentTab === 0 && (
        <EvalFormsSettlement
          {...props}
          ticketCompleted={
            props.ticketCompleted || props.stepsSemiCompleted?.includes('eval_forms_settlement')
          }
          currentStep={"eval_forms_settlement"}
          handleToggleQuotation={handleToggleQuotation}
          handleToggleComponent={handleToggleComponent}
        />
      )}
      {props.currentTab === 1 && (
        <ContractValidation
          {...props}
          ticketCompleted={
            props.ticketCompleted || props.stepsSemiCompleted?.includes('contract_validation')
          }
          currentStep={"contract_validation"}
          handleToggleQuotation={handleToggleQuotation}
          handleToggleComponent={handleToggleComponent}
        />
      )}
      {props.currentTab === 2 && (
        <CreditAnalysis
          {...props}
          ticketCompleted={
            props.ticketCompleted || props.stepsSemiCompleted?.includes('credit_analysis')
          }
          currentStep={"credit_analysis"}
          handleToggleQuotation={handleToggleQuotation}
          handleToggleComponent={handleToggleComponent}
        />
      )}
      {props.currentTab === 3 && (
        <CreditFormalization
          {...props}
          ticketCompleted={
            props.ticketCompleted || props.stepsSemiCompleted?.includes('credit_formalization')
          }
          currentStep={"credit_formalization"}
          handleToggleQuotation={handleToggleQuotation}
          handleToggleComponent={handleToggleComponent}
        />
      )}
      <Popup
        open={openQuotation}
        funcion={handleToggleQuotation.bind(this, "close")}
      >
        <QuotationForm quotationId={quotationId} />
      </Popup>
      <Popup
        open={Boolean(componentsDialog?.name)}
        funcion={handleToggleComponent.bind(this, "close")}
        maxWidth={"xl"}
        fullScreen={componentsDialog?.fullScreen}
      >
        <DmsComponents 
          {...componentsDialog}
        />
      </Popup>
    </div>
  );
}

export default withStyles(styles)(connect()(DisbursementRequestDetail));