import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loading from "../../components/SharedComponents/LoadingScreen";

import {
  rohiSavingAccountsInfo,
  rohiSavingAccountsDetails,
  rohiInvestmentCertificate,
  rohiInvestmentCertificateDetails,
  rohiLoans,
  AccountStatusTemplates,
  fetchAssignees,
  rohiOutlay,
  rohiArr,
} from "src/utils/axios";

import {
  Paper,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";

import { green, red } from "@material-ui/core/colors";

import { ConfirmDialogComponent, DetailsDialog } from "./Dialogs/index";
import {
  SuccessDialog,
  ErrorDialog,
  SimpleConfirmDialog,
} from "../../components/SharedComponents/Dialogs";
import ParticipantSelectInput from "src/containers/Participant/ParticipantSelectInput";
import SavingAccounts from "./Products/SavingAccounts";
import { createStyles, withStyles } from "@material-ui/core/styles";
import InvestmentCertificate from "./Products/InvestmentCertificate";
import Loans from "./Products/Loans";
import { LoanDialog } from "./Dialogs/LoanDialog";
import { PaymentDetailDialog } from "./Dialogs/PaymentDetailDialog";
import { ToolbarComponent } from "./../../components/SharedComponents/Toolbar";
import { Receipt } from "@material-ui/icons";
import { TemplateDialog } from "./Dialogs/TemplateDialog";
import jwtDecode from "jwt-decode";
import { errorHandling } from "../../components/SharedComponents/CustomHooks";
import SavingPlans from "./Products/SavingPlans";

const styles = createStyles({
  cardHover: {
    "&:hover": { backgroundColor: green[500], color: "white" },
  },

  cardRed: {
    background: red[500],
    color: "white",
  },
});

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "1.5rem",
    minWidth: "500px",
    backgroundColor: "transparent",
  },
})(Tooltip);

function AccountStatus(props) {
  const [loading, setLoading] = useState(false);
  const [loggedUser, setLoggedUser] = useState(null);
  const [dialog, setDialog] = useState({ name: "", data: null });
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });

  const [client, setClient] = useState(null);
  const [clientId, setClientId] = useState([]);

  const [savingAccounts, setSavingAccounts] = useState([]);
  const [savingPlans, setSavingPlans] = useState([]);
  const [savingAccountsIntact, setSavingAccountsIntact] = useState([]);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [totalAccountBalance, setTotalAccountBalance] = useState(0);
  const [totalAvailableBalance, setTotalAvailableBalance] = useState(0);
  const [spTotalAccountBalance, setSpTotalAccountBalance] = useState(0);
  const [spTotalAvailableBalance, setspTotalAvailableBalance] = useState(0);
  const [savingPlansFee, setSavingPlansFee] = useState(0);
  const [savingFee, setSavingFee] = useState(0);

  const [investmentCertificate, setInvestmentCertificate] = useState([]);
  const [
    investmentCertificateIntact,
    setInvestmentCertificateIntact,
  ] = useState([]);
  const [totalCertificate, setTotalCertificate] = useState(0);
  const [totalAccountBalanceCert, setTotalAccountBalanceCert] = useState(0);
  const [totalAvailableBalanceCert, setTotalAvailableBalanceCert] = useState(0);

  const [loan, setLoan] = useState([]);
  const [loanIntact, setLoanIntact] = useState([]);

  const [outlay, setOutlay] = useState([]);
  const [outlayIntact, setOutlayIntact] = useState([]);

  const [arr, setArr] = useState([]);

  const [detailsData, setDetailsData] = useState([]);
  const [modalData, setModalData] = useState({});

  const handleToggleDialog = (dialog, data) => {
    const selectedDialog = dialog ? dialog : "";
    const dialogData = data && (data.uniqueId || data.id) ? data : null;
    const obj = { name: selectedDialog, data: dialogData };
    setDialog({ ...obj });
  };
  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };

  const fields =
    modalData.id === "savingAccounts"
      ? [
          {
            id: "accountNumber",
            label: "Número producto".toLocaleUpperCase(),
            value: modalData.accountNumber || "",
          },
          {
            id: "accountType",
            label: "Tipo producto".toLocaleUpperCase(),
            value: modalData.accountType || "",
          },
          {
            id: "accountBalance",
            label: "Balance".toLocaleUpperCase(),
            value:
              Number(modalData.accountBalance).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
              }) || "",
          },
          {
            id: "availableAccountBalance",
            label: "Balance disponible".toLocaleUpperCase(),
            value:
              Number(modalData.availableAccountBalance).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "DOP",
                }
              ) || "",
          },
          {
            id: "creationDate",
            label: "Fecha apertura".toLocaleUpperCase(),
            value: modalData.creationDate || "",
          },
          {
            id: "amountPledged",
            label: "Balance pignorado".toLocaleUpperCase(),
            value:
              Number(modalData.amountPledged).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
              }) || "",
          },
          {
            id: "currency",
            label: "Moneda".toLocaleUpperCase(),
            value: modalData.currency || "",
          },
          {
            id: "lastTransaction",
            label: "Fecha ultima transaccion".toLocaleUpperCase(),
            value: modalData.lastTransaction
              ? modalData.lastTransaction.last_transaction_date.substring(
                  0,
                  modalData.lastTransaction.last_transaction_date.indexOf("T")
                )
              : "--",
          },
        ]
      : modalData.id === "investmentCertificate"
      ? [
          {
            id: "accountNumber",
            label: "Número cuenta".toLocaleUpperCase(),
            value: modalData.accountNumber || "",
          },
          {
            id: "creationDate",
            label: "Fecha apertura".toLocaleUpperCase(),
            value: modalData.creationDate || "",
          },
          {
            id: "accountType",
            label: "Tipo cuenta".toLocaleUpperCase(),
            value: modalData.accountType || "",
          },
          {
            id: "accountBalance".toLocaleUpperCase(),
            label: "Saldo",
            value:
              Number(modalData.accountBalance).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
              }) || "",
          },
          {
            id: "apertureBalance".toLocaleUpperCase(),
            label: "Monto apertura",
            value:
              Number(modalData.apertureBalance).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
              }) || "",
          },
        ]
      : [
          {
            id: "loan_status",
            label: "Estado prestamo".toUpperCase(),
            value: modalData.loans_details
              ? modalData.loans_details[0].loan_status
              : "",
          },
          {
            id: "loan_number",
            label: "Número prestamo".toUpperCase(),
            value: modalData.loan_number || "",
          },
          {
            id: "disbursement_date",
            label: "Fecha apertura".toUpperCase(),
            value: modalData.disbursement_date || "",
          },
          {
            id: "loan_amount",
            label: "Monto original".toUpperCase(),
            value:
              Number(modalData.loan_amount).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
              }) || "",
          },
          {
            id: "loan_oustanding_balalance",
            label: "Balance principal".toUpperCase(),
            value:
              Number(modalData.loan_oustanding_balalance).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "DOP",
                }
              ) || "",
          },
          {
            id: "interest_rate",
            label: "Interes".toUpperCase(),
            value: `${parseFloat(modalData.interest_rate).toFixed(2)}%` || "",
          },
          {
            id: "payment_day",
            label: "Dia de pago".toUpperCase(),
            value: modalData.payment_day || "",
          },
          {
            id: "last_payment_date",
            label: "Fecha ultimo pago".toUpperCase(),
            value: modalData.loans_details
              ? modalData.loans_details[0].last_payment_date
                ? modalData.loans_details[0].last_payment_date
                : "---"
              : "---",
          },
          {
            id: "next_payment_due",
            label: "Proxima fecha pago".toUpperCase(),
            value: modalData.next_payment_due
              ? new Date(modalData.next_payment_due).toISOString().split("T")[0]
              : "",
          },
          {
            id: "next_amount_due",
            label: "proximo pago".toUpperCase(),
            value:
              Number(modalData.next_amount_due).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
              }) || "",
          },
          {
            id: "disbursement_fee",
            label: "Gastos administrativos".toUpperCase(),
            value: modalData.loans_details
              ? Number(
                  modalData.loans_details[0].disbursement_fee
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "DOP",
                })
              : "",
          },
          {
            id: "remaining_periods",
            label: "Periodos restantes".toUpperCase(),
            value: modalData.loans_details
              ? modalData.loans_details[0].remaining_periods
              : "",
          },
          {
            id: "accounting_entry",
            label: "Asiento contable".toUpperCase(),
            value: modalData.loans_details
              ? modalData.loans_details[0].accounting_entry
              : "",
          },
          {
            label: "Total Amortizado".toUpperCase(),
            value:
              (
                Number(modalData.loan_amount) -
                Number(modalData.loan_oustanding_balalance)
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "DOP",
              }) || "",
          },
          {
            id: "Saldo_total",
            label: "Saldo Total préstamo".toUpperCase(),
            value: modalData.total_oustanding_balance_due_by_loan
              ? modalData.total_oustanding_balance_due_by_loan.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "DOP",
                  }
                )
              : "",
          },
          {
            id: "quotation",
            label: "Cotización".toUpperCase(),
            inputProps: true,
          },
        ];

  const getSavingAccounts = (associateId) => {
    setLoading(true);
    const endpoint = rohiSavingAccountsInfo({
      associateId: associateId,
      token: props.user.token,
    });
    endpoint
      .then((response) => {
        let saTotalBalance = 0;
        let saTotalAvailableBalance = 0;
        let spTotalBalance = 0;
        let spTotalAvailableBalance = 0;
        let saSavingFee = 0;
        let saSavingPlanFee = 0;
        const savingData = response.data.map((item) => {
          return {
            id: "savingAccounts",
            rohiId: item.rohi_id,
            uniqueId: item.account_number,
            accountNumber: item.account_number,
            accountType: item.account_type,
            savingFee: Number(item.saving_fee),
            accountBalance: Number(item.account_balance),
            availableAccountBalance: Number(item.available_account_balance),
            creationDate: item.creation_date.substring(
              0,
              item.creation_date.indexOf("T")
            ),
            amountPledged: item.amount_pledged,
            currency: item.currency,
            savingsClassType: item.savings_class_type,
            lastTransaction: item.last_transaction
              ? JSON.parse(item.last_transaction)[0]
              : null,
          };
        });

        savingData
          .filter((item) => item.savingsClassType === "CUENTAS DE AHORRO")
          .forEach((element) => {
            saTotalBalance = saTotalBalance += element.accountBalance;
            saTotalAvailableBalance = saTotalAvailableBalance +=
              element.availableAccountBalance;
            saSavingFee = saSavingFee += element.savingFee;
          });

        savingData
          .filter((item) => item.savingsClassType === "PLANES DE AHORRO")
          .forEach((element) => {
            spTotalBalance = spTotalBalance += element.accountBalance;
            spTotalAvailableBalance = spTotalAvailableBalance +=
              element.availableAccountBalance;
            saSavingPlanFee = saSavingPlanFee += element.savingFee;
          });

        setTotalAccounts(response.data.length);

        setSavingFee(parseFloat(saSavingFee).toFixed(2));
        setSavingPlansFee(parseFloat(saSavingPlanFee).toFixed(2));
        setTotalAccountBalance(parseFloat(saTotalBalance).toFixed(2));
        setTotalAvailableBalance(
          parseFloat(saTotalAvailableBalance).toFixed(2)
        );

        setSpTotalAccountBalance(parseFloat(spTotalBalance).toFixed(2));
        setspTotalAvailableBalance(
          parseFloat(spTotalAvailableBalance).toFixed(2)
        );

        setSavingAccounts(
          savingData.filter(
            (item) => item.savingsClassType === "CUENTAS DE AHORRO"
          )
        );
        setSavingPlans(
          savingData.filter(
            (item) => item.savingsClassType === "PLANES DE AHORRO"
          )
        );

        setSavingAccountsIntact(savingData);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        handleToggleSimpleDialog("error-dialog", [
          "No se pudieron obtener las cuentas de ahorro.",
        ]);
      });
  };

  const getInvestmentCertificate = (associateId) => {
    // setLoading(true);
    const endpoint = rohiInvestmentCertificate({
      associateId: associateId,
      token: props.user.token,
    });
    endpoint
      .then((response) => {
        let totalBalance = 0;
        let totalAvailableBalance = 0;
        const investmentCertificate = response.data.map((item) => {
          totalBalance = totalBalance += Number(item.aperture_balance);
          totalAvailableBalance = totalAvailableBalance += Number(
            item.available_account_balance
          );
          return {
            id: "investmentCertificate",
            accountNumber: item.account_number,
            accountType: item.account_type,
            interestRate: item.interest_rate,
            lastInterestPaymentDate: item.last_interest_payment_date,
            accountBalance: Number(item.account_balance),
            apertureBalance: Number(item.aperture_balance),
            creationDate: item.creation_date,
          };
        });
        setTotalAccountBalanceCert(Number(totalBalance));
        setTotalAvailableBalanceCert(Number(totalAvailableBalance));
        setTotalCertificate(response.data.length);

        setInvestmentCertificate(investmentCertificate);
        setInvestmentCertificateIntact(investmentCertificate);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        handleToggleSimpleDialog("error-dialog", [
          "No se pudieron obtener los certificados de inversion.",
        ]);
      });
  };

  const getLoans = (associateId) => {
    const endpoint = rohiLoans({
      associateId: associateId,
      token: props.user.token,
    });
    endpoint
      .then((response) => {
        const loans = response.data.map((item) => {
          return {
            id: "loans",
            totalLoanOutstandingBalance: Number(
              item.total_loan_outstanding_balance
            ),
            totalActiveLoans: item.total_active_loans,
            isAssociateDue: item.is_associate_due,
            loansDueQty: item.loans_due_qty,
            totalQuotesDue: item.total_quotes_due,
            totalArrDue: item.total_arr_due,
            loansGeneralInfo: item.loans_general_info,
            pcpInfo: JSON.parse(item.pcp_info),
          };
        });
        setLoan(loans);
        setLoanIntact(loans);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        handleToggleSimpleDialog("error-dialog", [
          "No se pudieron obtener los prestamos.",
        ]);
      });
  };

  const getArr = (associateId) => {
    const endpoint = rohiArr({
      associateId: associateId,
      token: props.user.token,
    });
    endpoint
      .then((response) => {
        const arr = response.data.map((item) => {
          return {
            id: "arr",
            loansDueQty: item.loans_due_qty,
            totalQuotesDue: item.total_quotes_due,
            totalArrDue: item.total_arr_due,
            loansDetails: JSON.parse(item.loans_details),
            savingsArr: JSON.parse(item.savings_arr),
          };
        });
        setArr(arr);
        // setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        handleToggleSimpleDialog("error-dialog", [
          "No se pudieron obtener los atrasos.",
        ]);
      });
  };

  const getDetail = (data) => {
    if (data.id === "savingAccounts") {
      setLoading(true);
      const endpoint = rohiSavingAccountsDetails({
        savingAccountNumber: data.accountNumber,
        associateId: client.identificationNumber,
        token: props.user.token,
      });
      endpoint
        .then((response) => {
          const detailsData = response.data.map((item) => {
            return {
              id: item.trasanction_reference,
              transactionDate: item.transaction_date,
              transactionNumber: item.trasanction_reference,
              debit: Number(item.debit),
              credit: Number(item.credit),
              endingBalance: Number(item.ending_balance),
              startingBalance: Number(item.starting_balance),
              concept: item.transaction_concept,
              collapseData: [JSON.parse(item.trasanction_details)[0]],
            };
          });
          setDetailsData(detailsData);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          handleToggleSimpleDialog("error-dialog", [
            "No se pudo obtener el detalle de la cuenta.",
          ]);
        });
    } else if (data.id === "investmentCertificate") {
      setLoading(true);
      const endpoint = rohiInvestmentCertificateDetails({
        savingAccountNumber: data.accountNumber,
        associateId: client.identificationNumber,
        token: props.user.token,
      });
      endpoint
        .then((response) => {
          const detailsData = response.data.map((item) => {
            return {
              id: item.trasanction_reference,
              transactionDate: item.transaction_date,
              debit: Number(item.debit),
              credit: Number(item.credit),
              endingBalance: Number(item.ending_balance),
              transactionNumber: item.trasanction_reference,
              startingBalance: Number(item.starting_balance),
              concept: item.transaction_concept,

              collapseData: [JSON.parse(item.trasanction_details)[0]],
            };
          });
          setDetailsData(detailsData);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          handleToggleSimpleDialog("error-dialog", [
            "No se pudo obtener el detalle del certificado de inversion.",
          ]);
        });
    }
  };

  const getUser = () => {
    const userId = jwtDecode(props.user.token).user_id;
    fetchAssignees({ token: props.user.token, id: userId }).then((response) => {
      const data = response.data[0];
      setLoggedUser(data.username);
    });
  };

  const handleAddClient = (id) => {
    setClientId([id]);
  };

  const handleDeleteClient = (id) => {
    setClientId([]);
  };

  const handleClientData = (data) => {
    setClient(data);
  };

  const handleModal = (data, key) => {
    setModalData(data);

    if (key === "loan") {
      handleToggleDialog("loan");
    } else if (key === "payment") {
      handleToggleDialog("payment");
    } else {
      handleToggleDialog("detail");
    }
  };

  const printTemplates = (key, data, date, rowsPerPage) => {
    if (key === "1") {
      setLoading(true);

      const endpoint = AccountStatusTemplates(props.user.token, {
        client: client,
        savingAccounts: savingAccounts,
        savingPlans: savingPlans,
        investmentCertificate: investmentCertificate.filter(
          (item) => item.accountBalance > 0
        ),
        loans: loan[0],
        loggedUser: loggedUser,
        totalAccountBalance: totalAccountBalance,
        totalAvailableBalance: totalAvailableBalance,
        totalAccountBalanceCert: totalAccountBalanceCert,
        totalAvailableBalanceCert: totalAvailableBalanceCert,
        totalLoansAmount: loan[0] ? loan[0].totalLoanOutstandingBalance : 0,
        totalNextAmountDue: loan[0] ? loan[0].totalNextAmountDue : 0,
        key: key,
      });
      endpoint
        .then((response) => {
          const file = new Blob([response.data], { type: "text/html" });
          const fileURL = URL.createObjectURL(file);
          const NewWindow = window.open();
          NewWindow.location.href = fileURL;
          setLoading(false);
        })
        .catch((errors) => {
          setLoading(false);
          handleToggleSimpleDialog("error-dialog", [
            "No se pudo imprimir la plantilla.",
          ]);
        });
    } else if (key === "2") {
      setLoading(true);
      if (data.length < 1) {
        handleToggleSimpleDialog("error-dialog", [
          "Debe seleccionar al menos un record para imprimir la plantilla.",
        ]);
        setLoading(false);
      }

      if (!date.start || !date.end) {
        handleToggleSimpleDialog("error-dialog", [
          "Debe seleccionar ambas fechas.",
        ]);
        setLoading(false);
      } else {
        const detallado = data.filter((item) => item.id === "savingAccounts");

        const mov = data.filter((item) => item.id === "prestamos");

        for (let i of detallado) {
          let from = JSON.stringify(date.start);
          let to = JSON.stringify(date.end);

          from = from.slice(1, 11);
          to = to.slice(1, 11);

          const endpoint = rohiSavingAccountsDetails({
            savingAccountNumber: i.accountNumber,
            fromDate: from,
            toDate: to,
            token: props.user.token,
          });
          endpoint
            .then((response) => {
              const detailsData1 = response.data.map((item) => {
                return {
                  id: item.trasanction_reference,
                  transactionDate: item.transaction_date,
                  transactionNumber: item.trasanction_reference,
                  debit: Number(item.debit),
                  credit: Number(item.credit),
                  endingBalance: Number(item.ending_balance),
                  startingBalance: Number(item.starting_balance),
                  concept: item.transaction_concept,
                  collapseData: [JSON.parse(item.trasanction_details)[0]],
                };
              });

              const endpoint = AccountStatusTemplates(props.user.token, {
                detailsData: detailsData1,
                loggedUser: loggedUser,
                key: "2",
                savingAccounts: i,
                fromDate: from,
                toDate: to,
                client: client,
              });
              endpoint
                .then((response) => {
                  const file = new Blob([response.data], { type: "text/html" });
                  const fileURL = URL.createObjectURL(file);
                  const NewWindow = window.open();
                  NewWindow.location.href = fileURL;
                  setLoading(false);
                })
                .catch((errors) => {
                  setLoading(false);
                  handleToggleSimpleDialog("error-dialog", [
                    "No se pudo imprimir la plantilla.",
                  ]);
                });
            })
            .catch((err) => {
              setLoading(false);
              console.error(err);
            });
        }

        for (let i of mov) {
          const endpoint = AccountStatusTemplates(props.user.token, {
            loan: i,
            loggedUser: loggedUser,
            key: "4",
            client: client,
          });
          endpoint
            .then((response) => {
              const file = new Blob([response.data], { type: "text/html" });
              const fileURL = URL.createObjectURL(file);
              const NewWindow = window.open();
              NewWindow.location.href = fileURL;
              setLoading(false);
            })
            .catch((errors) => {
              setLoading(false);
              handleToggleSimpleDialog("error-dialog", [
                "No se pudo imprimir la plantilla.",
              ]);
            });
        }
      }

      // setLoading(false);
    } else if (key === "3") {
      setLoading(true);

      const outlays = [];
      const loanNumbers = [];

      outlay.forEach((item) => {
        if (!loanNumbers.includes(item.loanNumber)) {
          outlays.push(item);
          loanNumbers.push(item.loanNumber);
        }
      });

      const endpoint = AccountStatusTemplates(props.user.token, {
        outlays: outlays,
        loggedUser: loggedUser,
        key: key,
        rowsPerPage: rowsPerPage,
        client: client,
      });
      endpoint
        .then((response) => {
          const file = new Blob([response.data], { type: "text/html" });
          const fileURL = URL.createObjectURL(file);
          const NewWindow = window.open();
          NewWindow.location.href = fileURL;
          setLoading(false);
        })
        .catch((errors) => {
          setLoading(false);
          handleToggleSimpleDialog("error-dialog", [
            "No se pudo imprimir la plantilla.",
          ]);
        });
    } else if (key === "4") {
      setLoading(true);

      if (data.length < 1) {
        handleToggleSimpleDialog("error-dialog", [
          "Debe seleccionar al menos un prestamo para imprimir la plantilla.",
        ]);
        setLoading(false);
      }

      for (let i of data) {
        const endpoint = AccountStatusTemplates(props.user.token, {
          loan: i,
          loggedUser: loggedUser,
          key: key,
          client: client,
        });
        endpoint
          .then((response) => {
            const file = new Blob([response.data], { type: "text/html" });
            const fileURL = URL.createObjectURL(file);
            const NewWindow = window.open();
            NewWindow.location.href = fileURL;
            setLoading(false);
          })
          .catch((errors) => {
            setLoading(false);
            handleToggleSimpleDialog("error-dialog", [
              "No se pudo imprimir la plantilla.",
            ]);
          });
      }
    } else if (key === "5") {
      setLoading(true);

      const endpoint = AccountStatusTemplates(props.user.token, {
        loggedUser: loggedUser,
        key: key,
        client: client,
        arr: arr,
      });
      endpoint
        .then((response) => {
          const file = new Blob([response.data], { type: "text/html" });
          const fileURL = URL.createObjectURL(file);
          const NewWindow = window.open();
          NewWindow.location.href = fileURL;
          setLoading(false);
        })
        .catch((errors) => {
          setLoading(false);
          handleToggleSimpleDialog("error-dialog", [
            "No se pudo imprimir la plantilla.",
          ]);
        });
    }
  };

  const getOutlay = (associateId) => {
    // setLoading(true);
    const endpoint = rohiOutlay({
      associateId: associateId,
      token: props.user.token,
    });
    endpoint
      .then((response) => {
        const outlays = response.data.map((item) => {
          return {
            id: "outlay",
            associateNumber: item.associate_number,
            loanStatus: item.loan_status,
            disbursementDate: item.disbursement_date,
            productType: item.product_type,
            interestRate: item.interest_rate,
            paymentDay: item.payment_day,
            periodicity: item.periodicity,
            periods: item.periods,
            loanNumber: item.loan_number,
            loanAmount: item.loan_amount,
            loanOutstandingBalance: item.loan_outstanding_balance,
            totalAmountPending: item.total_amount_pending,
            rescheduledLoans: item.rescheduled_loans
              ? JSON.parse(item.rescheduled_loans)
              : null,
            liquidationForm: item.liquidation_form
              ? JSON.parse(item.liquidation_form)[0]
              : JSON.parse(item.liquidation_form),
          };
        });
        setOutlay(outlays);
        setOutlayIntact(outlays);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        handleToggleSimpleDialog("error-dialog", [
          "No se pudieron obtener los desembolsos.",
        ]);
      });
  };

  const restartData = () => {
    setSavingAccounts([]);
    setSavingPlans([]);
    setInvestmentCertificate([]);
    setOutlay([]);
    setLoan([]);
    setArr([]);
    setTotalAccountBalance(0);
    setSavingFee(0);
    setSavingPlansFee(0);
    setTotalAvailableBalance(0);
    setSpTotalAccountBalance(0);
    setspTotalAvailableBalance(0);
    setTotalCertificate(0);
    setTotalAccountBalanceCert(0);
    setTotalAvailableBalanceCert(0);
  };

  useEffect(() => {
    if (client) {
      getSavingAccounts(client.identificationNumber);
      getInvestmentCertificate(client.identificationNumber);
      getOutlay(client.identificationNumber);
      getLoans(client.identificationNumber);
      getArr(client.identificationNumber);
      getUser();
    }
  }, [client]);

  useEffect(() => {
    if (props.data) {
      setClientId([props.data.uniqueId]);
      getSavingAccounts(props.data.identificationNumber);
      getInvestmentCertificate(props.data.identificationNumber);
      getOutlay(props.data.identificationNumber);
      getLoans(props.data.identificationNumber);
      getArr(props.data.identificationNumber);
      getUser();
    }
  }, [props.data]);

  let result = [];

  let total_pr = loan[0]
    ? (loan[0].loansGeneralInfo[0] &&
        loan[0].loansGeneralInfo[0].total_oustanding_balance_due_by_pc) +
      (loan[0].loansGeneralInfo[0] &&
        loan[0].loansGeneralInfo[0].total_amout_pending_by_pc)
    : 0;

  let total_ca = loan[0]
    ? (loan[0].loansGeneralInfo[1]
        ? loan[0].loansGeneralInfo[1].total_oustanding_balance_due_by_pc
        : 0) +
      (loan[0].loansGeneralInfo[1]
        ? loan[0].loansGeneralInfo[1].total_amout_pending_by_pc
        : 0)
    : 0;

  return (
    <div style={{ padding: "20ps, 20px,10px 20px" }}>
      <Paper style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
        <ToolbarComponent
          typeVariant="h5"
          typeClass="title-text"
          toolbarTitle="ESTADOS DE CUENTA"
          btnVariant="contained"
          toolbarActions={
            <div>
              <Button
                variant="contained"
                disableElevation
                size="small"
                style={{
                  marginRight: "2rem",
                  color: clientId.length > 0 || props.data ? "white" : "black",
                  backgroundColor:
                    clientId.length > 0 || props.data ? green[500] : "#f5f5f5",
                }}
                disabled={clientId.length > 0 || props.data ? false : true}
                onClick={() => handleToggleDialog("templates")}
              >
                <Receipt
                  style={{
                    fontSize: 20,
                    marginRight: ".5rem",
                    color:
                      clientId.length > 0 || props.data ? "white" : "black",
                  }}
                />
                Imprimir plantillas
              </Button>
            </div>
          }
        />
        <Loading open={loading} />
        <br />
        <br />
        <>
          <div style={{ width: "100%" }}>
            <ParticipantSelectInput
              selectedParticipantId={clientId}
              addParticipant={handleAddClient}
              addParticipantData={handleClientData}
              deleteParticipant={handleDeleteClient}
              restartData={restartData}
              showClaimColumn={true}
            />
            <br />
            <br />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CustomTooltip
                title={
                  <Card style={{ marginTop: "3%", width: 350 }}>
                    <CardContent>
                      <div>
                        <div>Cuentas: {savingAccounts.length}</div>
                        <Divider />
                        <div>
                          Cuota Ahorro:{" "}
                          {Number(savingFee).toLocaleString("en-US", {
                            style: "currency",
                            currency: "DOP",
                          })}
                        </div>
                        <Divider />
                        <div>
                          Balance:{" "}
                          {Number(totalAccountBalance).toLocaleString("en-US", {
                            style: "currency",
                            currency: "DOP",
                          })}
                        </div>
                        <Divider />
                        <div>
                          Disponible:{" "}
                          {Number(totalAvailableBalance).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "DOP",
                            }
                          )}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                }
              >
                <Card className={props.classes.cardHover}>
                  <CardContent>
                    <h3>CUENTAS</h3>
                  </CardContent>
                </Card>
              </CustomTooltip>
            </Grid>
            <Grid item xs={3}>
              <CustomTooltip
                title={
                  <Card style={{ marginTop: "3%", width: 350 }}>
                    <CardContent>
                      <div>
                        <div>Planes: {savingPlans.length}</div>
                        <Divider />
                        <div>
                          Cuota Ahorro:{" "}
                          {Number(savingPlansFee).toLocaleString("en-US", {
                            style: "currency",
                            currency: "DOP",
                          })}
                        </div>
                        <Divider />
                        <div>
                          Balance:{" "}
                          {Number(spTotalAccountBalance).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "DOP",
                            }
                          )}
                        </div>
                        <Divider />
                        <div>
                          Disponible:{" "}
                          {Number(spTotalAvailableBalance).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "DOP",
                            }
                          )}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                }
              >
                <Card className={props.classes.cardHover}>
                  <CardContent>
                    <h3>PLANES</h3>
                  </CardContent>
                </Card>
              </CustomTooltip>
            </Grid>
            <Grid item xs={3}>
              <CustomTooltip
                title={
                  <Card style={{ marginTop: "3%", width: 450 }}>
                    <CardContent>
                      <div>
                        <div>Certificados: {totalCertificate}</div>
                        <Divider />
                        <div>
                          Balance:{" "}
                          {Number(totalAccountBalanceCert).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "DOP",
                            }
                          ) || ""}
                        </div>
                        <Divider />
                        <div>
                          Disponible:{" "}
                          {Number(totalAvailableBalanceCert).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "DOP",
                            }
                          ) || ""}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                }
              >
                <Card className={props.classes.cardHover}>
                  <CardContent>
                    <h3>CERTIFICADOS</h3>
                  </CardContent>
                </Card>
              </CustomTooltip>
            </Grid>
            <Grid item xs={3}>
              <CustomTooltip
                title={
                  <Card style={{ marginTop: "3%" }}>
                    <CardContent>
                      <div>
                        <div>
                          Cantidad de prestamos activos:{" "}
                          {loan[0] ? loan[0].totalActiveLoans : 0}
                        </div>
                        <Divider />
                        <div>
                          Total de prestamos en atraso:{" "}
                          {loan[0] ? loan[0].loansDueQty : 0}
                        </div>
                        <Divider />
                        <div>
                          Total cuotas pendientes:{" "}
                          {loan[0] ? loan[0].totalQuotesDue : 0}
                        </div>
                        <Divider />
                        <div>
                          Saldo total:{" "}
                          {(total_pr + total_ca).toLocaleString("en-US", {
                            style: "currency",
                            currency: "DOP",
                          })}
                        </div>
                        <Divider />
                        <div>
                          Saldo principal:{" "}
                          {loan[0]
                            ? loan[0].totalLoanOutstandingBalance.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "DOP",
                                }
                              )
                            : 0}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                }
              >
                <Card
                  className={
                    loan[0] && loan[0].isAssociateDue
                      ? props.classes.cardRed
                      : props.classes.cardHover
                  }
                >
                  <CardContent>
                    <h3>PRÉSTAMOS</h3>
                  </CardContent>
                </Card>
              </CustomTooltip>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <SavingAccounts
            savingAccountsIntact={savingAccountsIntact}
            setSavingAccounts={setSavingAccounts}
            tableData={savingAccounts}
            handleModal={handleModal}
          />
          <br />
          <Divider />
          <SavingPlans
            savingAccountsIntact={savingAccountsIntact}
            setSavingAccounts={setSavingAccounts}
            tableData={savingPlans}
            handleModal={handleModal}
          />
          <br />
          <Divider />
          <br />
          <InvestmentCertificate
            investmentCertificateIntact={investmentCertificateIntact}
            setInvestmentCertificate={setInvestmentCertificate}
            tableData={investmentCertificate}
            handleModal={handleModal}
          />
          <br />
          <Divider />
          <br />
          <Loans data={loan} handleModal={handleModal} />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {dialog.name === "detail" ? (
            <DetailsDialog
              open={true}
              handleToggleDialog={handleToggleDialog}
              detailsData={detailsData}
              modalData={modalData}
              client={client}
              getDetail={getDetail}
              fields={fields}
              handleToggleSimpleDialog={handleToggleSimpleDialog}
              type={modalData.id || ""}
            />
          ) : null}
          {dialog.name === "loan" ? (
            <LoanDialog
              open={true}
              client={client}
              handleToggleDialog={handleToggleDialog}
              handleModal={handleModal}
              modalData={modalData}
              fields={fields}
              token={props.user.token}
            />
          ) : null}
          {dialog.name === "payment" ? (
            <PaymentDetailDialog
              open={true}
              client={client}
              handleToggleDialog={handleToggleDialog}
              fields={fields}
              modalData={modalData}
            />
          ) : null}
          {dialog.name === "templates" ? (
            <TemplateDialog
              open={true}
              handleToggleSimpleDialog={handleToggleSimpleDialog}
              savingAccounts={savingAccounts}
              savingPlans={savingPlans}
              savingAccountsIntact={savingAccountsIntact}
              setSavingAccounts={setSavingAccounts}
              investmentCertificate={investmentCertificate}
              investmentCertificateIntact={investmentCertificateIntact}
              setInvestmentCertificate={setInvestmentCertificate}
              loans={loan}
              loansIntact={loanIntact}
              setLoan={setLoan}
              handleToggleDialog={handleToggleDialog}
              printTemplates={printTemplates}
              detailsData={detailsData}
              outlays={outlay}
              outlaysIntact={outlayIntact}
              setOutlay={setOutlay}
              arr={arr}
            />
          ) : null}
          {simpleDialog.name === "confirm-dialog" ? (
            <ConfirmDialogComponent
              open={true}
              data={simpleDialog}
              handleToggle={handleToggleSimpleDialog}
            />
          ) : null}
          {simpleDialog.name === "simple-dialog" ? (
            <SimpleConfirmDialog
              open={true}
              handleToggle={handleToggleSimpleDialog}
              data={simpleDialog.msg}
            />
          ) : null}
          {simpleDialog.name === "success-dialog" ? (
            <SuccessDialog
              open={true}
              handleToggle={handleToggleSimpleDialog}
            />
          ) : null}
          {simpleDialog.name === "error-dialog" ? (
            <ErrorDialog
              open={true}
              handleToggle={handleToggleSimpleDialog}
              data={simpleDialog.msg}
            />
          ) : null}
        </>
      </Paper>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(AccountStatus));
