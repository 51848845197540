import {
  Divider,
  Grid,
  Slider,
  Typography
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";
import {
  AutocompleteField,
  NumericTextField,
  TextFieldComponent,
} from "src/components/SharedComponents/Fields";
import { periodicity, PLANNED_SAVINGS_PERIODICITY, plannedSavingsType } from "src/utils";
import { plannedSavingsCalulator } from "src/utils/axios";

export default function SavingsDataForm({
  rFields,
  fields,
  errorFields,
  onChange,
  section,
  handleSelectValue,
  institution,
  getFiltersOptions,
  token
}) {
  const [disabledField, setDisabledField] = useState("term");
  const [plannedInfo, setPlannedInfo] = useState();
  const [infantData, setInfantData] = useState();
  const [timeoutId, settimeoutId] = useState(null);
  const [calcData, setCalcData] = useState({});

  const formatDate = (dateObj) => {
    // Agregar un 0 en caso de que mes o dia sean menor a 9
    const month =
      dateObj.getMonth() + 1 <= 9
        ? `0${dateObj.getMonth() + 1}`
        : dateObj.getMonth() + 1;
    const day =
      dateObj.getDate() <= 9 ? `0${dateObj.getDate()}` : dateObj.getDate();

    return `${dateObj.getFullYear()}-${month}-${day}`;
  };

  const CalculatePlannedSaving = async (type) => {
    let field = '';
    let calculate = false;
    let params = {}

    switch(type){
      case 'goal':
        field = "plannedGoal"
        calculate = (plannedInfo?.quota || fields.plannedQuota) && (plannedInfo?.term || fields.plannedTerm)
        params = {
          amount: 0,
          cutting_day: fields.plannedPaymentDay || plannedInfo?.paymentDay || 1,
          opening_date: formatDate(new Date()),
          calculation_type: "amount",
          calculator_type: "planned_savings",
          periodicity: fields.periodicity?.value || plannedInfo?.periodicity || "monthly",
          periods: parseFloat(fields.plannedTerm) || parseFloat(plannedInfo?.term) || 0,
          quota: parseFloat(fields.plannedQuota) || parseFloat(plannedInfo?.quota) || 0,
          payment_days: 1
        }
        break;
      case 'quota':
        field = "plannedQuota"
        calculate = (plannedInfo?.goal || fields.plannedGoal) && (plannedInfo?.term || fields.plannedTerm)
        params = {
          amount: parseFloat(fields.plannedGoal)|| parseFloat(plannedInfo?.goal) || 0,
          cutting_day: fields.plannedPaymentDay || plannedInfo?.paymentDay || 1,
          opening_date: formatDate(new Date()),
          calculation_type: "quota",
          calculator_type: "planned_savings",
          periodicity: fields.periodicity?.value || plannedInfo?.periodicity || "monthly",
          periods: parseFloat(fields.plannedTerm) || parseFloat(plannedInfo?.term) || 0,
          quota: 0,
          payment_days: 1
        }
        break;
      case 'term':
        field = "plannedTerm"
        calculate = (plannedInfo?.quota || fields.plannedQuota) && (plannedInfo?.goal || fields.plannedGoal)
        params = {
          amount: parseFloat(fields.plannedGoal)|| parseFloat(plannedInfo?.goal) || 0,
          cutting_day: fields.plannedPaymentDay || plannedInfo?.paymentDay || 1,
          opening_date: formatDate(new Date()),
          calculation_type: "periods",
          calculator_type: "planned_savings",
          periodicity: fields.periodicity?.value || plannedInfo?.periodicity || "monthly",
          periods: 0,
          quota: parseFloat(fields.plannedQuota) || parseFloat(plannedInfo?.quota) || 0,
          payment_days: 1
        }
        break;
      default:
        field = "plannedTerm"
        calculate = (plannedInfo?.quota || fields.plannedQuota) && (plannedInfo?.goal || fields.plannedGoal)
        params = {
          amount: parseFloat(fields.plannedGoal)|| parseFloat(plannedInfo?.goal) || 0,
          cutting_day: fields.plannedPaymentDay || plannedInfo?.paymentDay || 1,
          opening_date: formatDate(new Date()),
          calculation_type: "periods",
          calculator_type: "planned_savings",
          periodicity: fields.periodicity?.value || plannedInfo?.periodicity || "monthly",
          periods: 0,
          quota: parseFloat(fields.plannedQuota) || parseFloat(plannedInfo?.quota) || 0,
          payment_days: 1
        }
        break;
    }

    const data = {
      field: field,
      calculate: calculate,
      params: params
    }

    setCalcData(data)
    
  }

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => {
      if (calcData.calculate) {
        try {
          const params = calcData.params;
          const response = plannedSavingsCalulator(params, token);
          response.then((res) => {
            const fieldcalc = res.data?.result
            onChange("any", calcData.field, fieldcalc);
          });
        } catch (error) {
          console.error("Error al obtener el calculo");
          console.error(error);
        }
      } else {
        onChange("any", calcData.field, "");
      }
    }, 1400);

    settimeoutId(id);

    return () => clearTimeout(id);
  }, [calcData]);



  useEffect(() => {
    if(fields.plannedCalc?.value){
      CalculatePlannedSaving(fields.plannedCalc?.value)
    }
  }, [fields.plannedGoal, fields.plannedQuota, fields.plannedTerm])
  

  useEffect(() => {
    setDisabledField(fields.plannedCalc?.value)
  
  }, [fields.plannedCalc]);

  useEffect(() => {
    let paymentFreq = {}
    if(institution && institution?.paymentFrequency){
      paymentFreq = PLANNED_SAVINGS_PERIODICITY.find(item => item.value === institution.paymentFrequency);
    } else {
      paymentFreq = PLANNED_SAVINGS_PERIODICITY.find(item => item.value === "monthly");
    }
    onChange('any', 'plannedFrequency', paymentFreq)
  }, [institution])

  useEffect(() => {
    if(fields.savingsAccountForm){
      const infantSavings = fields.savingsAccountForm.find(item => item.type === "youth");
      const plannedSavings = fields.savingsAccountForm.find(item => item.type === "planned");
      setPlannedInfo(plannedSavings);
      setInfantData(infantSavings);
    }
  }, [fields])
  

  return (
    <Grid container spacing={3} className="form-wrapper-fields-container">
      <div style={{ width: "100%", margin: "1rem 0 1rem 0" }}>
        <Typography
          variant={"subtitle1"}
          className="section-header"
          style={{ fontWeight: "bold", color: "#5e5e5e" }}
        >
          AHORRO A LA VISTA
        </Typography>
        <Divider />
      </div>

      <NumericTextField
        xs={4}
        fullWidth
        fieldLabel={`Depósito inicial ${
          rFields.includes("initialDeposit") ? "(*)" : ""
        }`}
        fieldID="leads-field-initialDeposit"
        placeholder="$0.00"
        prefix="$"
        value={fields.initialDeposit}
        decimalScale={2}
        thousandSeparator={true}
        fixedDecimalScale={true}
        onChange={onChange.bind(this, "numeric", "initialDeposit")}
        error={Boolean(errorFields[section]?.initialDeposit)}
        helperText={
          errorFields[section]?.initialDeposit
            ? errorFields[section]?.initialDeposit
            : null
        }
      />
      <div style={{ width: "100%", margin: "1rem 0 1rem 0" }}>
        <Typography
          variant={"subtitle1"}
          className="section-header"
          style={{ fontWeight: "bold", color: "#5e5e5e" }}
        >
          AHORRO INFANTIL
        </Typography>
        <Divider />
      </div>
      <Grid
        container
        item
        xs={12}
        spacing={3}
        style={{ padding: ".5rem 1rem" }}
      >
        <Typography
          variant="body1"
          className="section-header"
          style={{ color: "#383838", fontSize: "1.1rem" }}
        >
          {`Cuota: $${fields.infantSavingQuota || infantData?.quota || 0}`}
        </Typography>
        <Slider
          style={{ color: green[500] }}
          defaultValue={0}
          value={fields.infantSavingQuota || infantData?.quota || 0}
          onChange={onChange.bind(this, "check", "infantSavingQuota")}
          aria-labelledby="discrete-slider-small-steps"
          step={500}
          marks
          min={0}
          max={5000}
          valueLabelDisplay="auto"
        />
      </Grid>
      <Typography
        variant="body1"
        className="section-header"
        style={{ color: "#383838", fontSize: "1.1rem" }}
      >
        {"Datos del niño"}
      </Typography>
      <Grid
        container
        item
        xs={12}
        spacing={3}
        style={{ padding: ".5rem 1rem" }}
      >
        <TextFieldComponent
          xs={4}
          fullWidth
          fieldLabel={`Nombre ${rFields.includes("infantName") ? "(*)" : ""}`}
          fieldID="lead-info-infantName"
          placeholder="Ej: Eduardo Perez"
          value={fields.infantName || infantData?.childInfo?.name}
          onChange={onChange.bind(this, "text", "infantName")}
          error={Boolean(errorFields[section]?.infantName)}
          helperText={
            errorFields[section]?.infantName
              ? errorFields[section]?.infantName
              : null
          }
        />
        <TextFieldComponent
          xs={4}
          fullWidth
          fieldLabel={`Edad ${rFields.includes("infantAge") ? "(*)" : ""}`}
          fieldID="lead-info-infantAge"
          placeholder="Ej: 12"
          value={fields.infantAge || infantData?.childInfo?.age}
          onChange={onChange.bind(this, "text", "infantAge")}
          error={Boolean(errorFields[section]?.infantAge)}
          helperText={
            errorFields[section]?.infantAge
              ? errorFields[section]?.infantAge
              : null
          }
        />
      </Grid>

      <div style={{ width: "100%", margin: "1rem 0 1rem 0" }}>
        <Typography
          variant={"subtitle1"}
          className="section-header"
          style={{ fontWeight: "bold", color: "#5e5e5e" }}
        >
          PLAN DE AHORRO
        </Typography>
        <Divider />
      </div>
      <AutocompleteField
        xs={4}
        fieldLabel={`Tipo de Ahorro ${
          rFields.includes("plannedCalc") ? "(*)" : ""
        }`}
        fieldID="leads-field-plannedCalc"
        fieldInnerLabel="Seleccione una opción"
        value={
          handleSelectValue((fields.plannedCalc || "term"), plannedSavingsType, "value") ||
          null
        }
        handleChange={onChange.bind(this, "autocomplete", "plannedCalc")}
        options={getFiltersOptions(plannedSavingsType)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        multiple={false}
      />

      <NumericTextField
        xs={4}
        fullWidth
        fieldLabel={`Meta ${rFields.includes("plannedGoal") ? "(*)" : ""}`}
        fieldID="leads-field-plannedGoal"
        placeholder="$0.00"
        prefix="$"
        value={fields.plannedGoal || plannedInfo?.goal || null}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator={true}
        onChange={onChange.bind(this, "numeric", "plannedGoal")}
        error={Boolean(errorFields[section]?.plannedGoal)}
        helperText={
          errorFields[section]?.plannedGoal
            ? errorFields[section]?.plannedGoal
            : null
        }
        disabled={disabledField === "goal"}
      />
      <NumericTextField
        xs={4}
        fullWidth
        fieldLabel={`Cuota ${rFields.includes("plannedQuota") ? "(*)" : ""}`}
        fieldID="leads-field-plannedQuota"
        placeholder="$0.00"
        prefix="$"
        value={fields.plannedQuota || plannedInfo?.quota || null}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator={true}
        onChange={onChange.bind(this, "numeric", "plannedQuota")}
        error={Boolean(errorFields[section]?.plannedQuota)}
        helperText={
          errorFields[section]?.plannedQuota
            ? errorFields[section]?.plannedQuota
            : null
        }
        disabled={disabledField === "quota"}
      />
      <NumericTextField
        xs={4}
        fullWidth
        fieldLabel={`Plazo ${rFields.includes("plannedTerm") ? "(*)" : ""}`}
        fieldID="leads-field-plannedTerm"
        placeholder="3"
        value={fields.plannedTerm || plannedInfo?.term || null}
        decimalScale={0}
        onChange={onChange.bind(this, "numeric", "plannedTerm")}
        error={Boolean(errorFields[section]?.plannedTerm)}
        helperText={
          errorFields[section]?.plannedTerm
            ? errorFields[section]?.plannedTerm
            : null
        }
        disabled={disabledField === "term"}
      />
      <AutocompleteField
        xs={4}
        fieldLabel={`Frecuencia de pago ${
          rFields.includes("plannedFrequency") ? "(*)" : ""
        }`}
        fieldID="leads-field-plannedFrequency"
        fieldInnerLabel="Seleccione una opción"
        value={
          handleSelectValue((fields.plannedFrequency || plannedInfo?.periodicity), PLANNED_SAVINGS_PERIODICITY, "value") ||
          null
        }
        handleChange={onChange.bind(this, "autocomplete", "plannedFrequency")}
        disabled
        options={getFiltersOptions(PLANNED_SAVINGS_PERIODICITY)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.plannedFrequency)}
        helperText={
          errorFields[section]?.plannedFrequency
            ? errorFields[section]?.plannedFrequency
            : null
        }
        multiple={false}
      />

      {!institution?.paymentDay && (
        <>
        <NumericTextField
          xs={4}
          fullWidth
          fieldLabel={`Dia de pago ${
            rFields.includes("plannedPaymentDay") ? "(*)" : ""
          }`}
          fieldID="leads-field-plannedPaymentDay"
          placeholder="01"
          value={fields.plannedPaymentDay || plannedInfo?.paymentDay || null}
          decimalScale={0}
          onChange={onChange.bind(this, "numeric", "plannedPaymentDay")}
          error={Boolean(errorFields[section]?.plannedPaymentDay)}
          helperText={
            errorFields[section]?.plannedPaymentDay
              ? errorFields[section]?.plannedPaymentDay
              : null
          }
        />
        {(fields.plannedFrequency?.value === 'biweekly' && !institution.secondPaymentDay) && (
          <NumericTextField
          xs={4}
          fullWidth
          fieldLabel={`2do dia de pago ${
            rFields.includes("scndPlannedPaymentDay") ? "(*)" : ""
          }`}
          fieldID="leads-field-scndPlannedPaymentDay"
          placeholder="12"
          value={fields.scndPlannedPaymentDay || plannedInfo?.secondPaymentDay || null}
          decimalScale={0}
          onChange={onChange.bind(this, "numeric", "scndPlannedPaymentDay")}
          error={Boolean(errorFields[section]?.scndPlannedPaymentDay)}
          helperText={
            errorFields[section]?.scndPlannedPaymentDay
              ? errorFields[section]?.scndPlannedPaymentDay
              : null
          }
        />
        )}
       </>
      )}
    </Grid>
  );
}
