import React, { useState, useEffect } from 'react';
import {
    Grid, Typography, IconButton, Button, Toolbar, Paper, Popover, Divider,
    List, ListItem, ListItemText,
} from '@material-ui/core';
import {
    AttachMoney, Edit, Delete, HighlightOff, Description, DeleteOutline,
    CloseOutlined, MoreHoriz
} from '@material-ui/icons';
import { GeneralTable } from './../../../components/SharedComponents/Table';
import NumberFormat from './../../../components/SharedComponents/NumberFormat'
import {
    useHandleSelectAllRows, useHandleSelectRow, useIsSelected
} from '../../../components/SharedComponents/CustomHooks';
import { TableSearch } from './../../../components/SharedComponents/Fields';


function ExpiryTableToolbar(props) {
    return (
        <Toolbar>
            <Grid container item direction="row" spacing={5} alignItems="center">
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{ fontWeight: 'bold', paddingLeft: 16 }}
                >
                    {props.order ? props.title : "Vencimientos"}
                </Typography>
                {
                    props.selected && props.selected.length ?
                    <Button
                        variant="outlined"
                        style={{fontSize: '10px', color:'#e34f4f', borderColor:'#e34f4f', backgroundColor:'#fff', marginLeft: 16 }}
                        disableElevation
                        size="small"
                        onClick={
                            props.handleToggleSimpleDialog
                            .bind(this, 'confirm-dialog', 'proceed-delete-multiple', {callback: props.handleDeleteMultipleExpiries})
                        }
                    >
                        <DeleteOutline style={{ fontSize: 15, color: '#e34f4f', margin: '0 .5rem .1rem 0' }} />
                        eliminar vencimientos
                    </Button>
                    :
                    <>
                        <Button
                            variant="contained"
                            className={props.expiriesQuantity < 1 ? "action-method-btn disabled" : "action-method-btn save"}
                            style={{ marginLeft: '.8rem', fontSize: '10px' }}
                            disableElevation
                            size="small"
                            disabled={props.expiriesQuantity < 1}
                            onClick={props.handleReport}
                        >
                            <Description style={{ fontSize: 15, color: '#fff', margin: '0 .5rem .1rem 0' }} />
                            reporte imprimible
                        </Button>
                        <Button
                            variant="contained"
                            className={props.expiriesQuantity < 1 ? "action-method-btn disabled" : "action-method-btn save"}
                            style={{ marginLeft: '.8rem', fontSize: '10px' }}
                            disableElevation
                            size="small"
                            disabled={props.expiriesQuantity < 1}
                            onClick={props.handleReportCsv}
                        >
                            <Description style={{ fontSize: 15, color: '#fff', margin: '0 .5rem .1rem 0' }} />
                            reporte csv
                        </Button>
                    </>
                }
            </Grid>
        </Toolbar>
    );
}

function ExpiryTable(props) {

    const [tableData, setTableData] = useState([]);
    const [openExpiryInfo, setOpenExpiryInfo] = useState({});

    const handleOpenExpiryInfo = (key, event) => {
        openExpiryInfo[key] ? setOpenExpiryInfo({...openExpiryInfo, [key]: null}) : setOpenExpiryInfo({...openExpiryInfo, [key]: event.currentTarget})
    }

    const actions = (row) => {
        return (
            <Grid container direciton="row" justifyContent="space-between">
                <IconButton
                    onClick={props.handleToggleDialog.bind(this, 'expiry-pay', row)}
                >
                    <AttachMoney style={{ fontSize: 22, color: '#616161' }} />
                </IconButton>
                <IconButton
                    onClick={props.handleToggleDialog.bind(this, 'expiry-modify', row)}
                >
                    <Edit style={{ fontSize: 22, color: '#616161' }} />
                </IconButton>
                <IconButton
                    onClick={
                        props.handleToggleSimpleDialog
                            .bind(this, 'confirm-dialog', 'proceed-delete', { callback: props.handleDelete.bind(this, row), row: row })
                    }
                >
                    <Delete style={{ fontSize: 22, color: '#616161' }} />
                </IconButton>
            </Grid>
        );
    };
    const amount = (column, row) => {
        return (
            <NumberFormat
                value={row[column]}
            />
        )
    }

    const expiryStates = (row) => {
        return (
            <span>
                {
                    props.states.length > 0 ?
                        props.states.find(item => item.value === row.expirationStatus).name
                        : row.expirationStatus
                }
            </span>
        )
    }

    const removeFromList = (row) => {
        return (
            <Grid container direciton="row" justifyContent="space-between">
                <IconButton
                    onClick={props.removeExpiry.bind(this, row)}
                >
                    <HighlightOff style={{ fontSize: 22, color: '#616161' }} />
                </IconButton>
            </Grid>
        );
    }

    const composition = (row) => {
        return(
            <div style={{textAlign:'center'}}>
                <Button
                    variant="contained"
                    disableElevation
                    style={{
                        minWidth:45, height: 25, borderRadius:20,
                        backgroundColor: '#43a047', color: '#FFFFFF',
                        fontSize:12,
                    }}
                    onClick={handleOpenExpiryInfo.bind(this, row.uniqueId)}
                >
                    <MoreHoriz style={{fontSize:20}}/>
                </Button>
                <ExpiryComposition
                    data={row}
                    handleToggle={handleOpenExpiryInfo.bind(this, row.uniqueId)}
                    open={Boolean(openExpiryInfo[row.uniqueId])}
                    anchorEl={openExpiryInfo[row.uniqueId]}
                />
            </div>
        )
    }
    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);

    const columns = [
        { id: 'checkbox', label:'SELECCIÓN', minWidth: 70 },
        { id: 'client', label: 'CLIENTE', minWidth: 190 },
        { id: 'expirationStatus', label: 'ESTADO', element: expiryStates, minWidth: 100 },
        { id: 'amountReceivable', label: 'MONTO VENCIDO', minWidth: 150, element: amount.bind(this, 'amountReceivable') },
        { id: 'amountCharged', label: 'MONTO COBRADO', minWidth: 150, element: amount.bind(this, 'amountCharged') },
        { id: 'expirationDate', label: 'FECHA', minWidth: 120 },
        { id: 'work', label: 'INST. TRABAJO', minWidth: 150 },
        { id: 'bank', label: 'INST. FINANCIERA', minWidth: 150 },
        { id: 'detail', label: 'DETALLE', element: composition, minWidth: 100},
        { id: 'action', label: 'ACCIÓN', align: 'center', minWidth: 170, element: props.order ? removeFromList : actions },
    ];
    return (
        <GeneralTable
            columns={columns}
            data={tableData}
            selected={props.selected}
            setSelected={props.setSelected}
            handleSelected={props.handleSelected}
            toolbar={
                <Grid container justifyContent='space-between' alignItems='center'>
                    <ExpiryTableToolbar
                        title={props.title}
                        order={props.order}
                        handleReport={props.handleReport}
                        handleReportCsv={props.handleReportCsv}
                        expiriesQuantity={props.data.length}
                        selected={props.selected}
                        handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                        handleDeleteMultipleExpiries={props.handleDeleteMultipleExpiries}
                    />
                    <TableSearch
                        data={props.data}
                        setTableData={setTableData}
                        searchFields={['client']}
                    />
                </Grid>
            }
        />
    );
}

function RohiExpiryTable(props) {

    const [openExpiryInfo, setOpenExpiryInfo] = useState({});

    const handleOpenExpiryInfo = (key, event) => {
        openExpiryInfo[key] ? setOpenExpiryInfo({...openExpiryInfo, [key]: null}) : setOpenExpiryInfo({...openExpiryInfo, [key]: event.currentTarget})
    }

    const amount = (column, row) => {
        return (
            <NumberFormat
                value={row[column]}
            />
        )
    }
    const date = (row) => {
        return (
            <span>
                {
                    row.fechaVencimiento.split('T')[0]
                }
            </span>
        )
    }
    const composition = (row) => {
        return(
            <div style={{textAlign:'center'}}>
                <Button
                    variant="contained"
                    disableElevation
                    style={{
                        minWidth:45, height: 25, borderRadius:20,
                        backgroundColor: '#43a047', color: '#FFFFFF',
                        fontSize:12,
                    }}
                    onClick={handleOpenExpiryInfo.bind(this, row.id_dbs)}
                >
                    <MoreHoriz style={{fontSize:20}}/>
                </Button>
                <ExpiryComposition
                    data={row}
                    handleToggle={handleOpenExpiryInfo.bind(this, row.id_dbs)}
                    open={Boolean(openExpiryInfo[row.id_dbs])}
                    anchorEl={openExpiryInfo[row.id_dbs]}
                />
            </div>
        )
    }

    const columns = [
        { id: 'idRohiSocio', label: 'ID ROHI', minWidth: 70 },
        { id: 'nombreSocio', label: 'NOMBRE', minWidth: 100 },
        { id: 'numeroIdentificacion', label: 'NÚMERO DE IDENTIFICACIÓN',  minWidth: 100 },
        { id: 'fechaVencimiento', label: 'FECHA VENCIMIENTO', element: date, minWidth: 100 },
        { id: 'totalVencido', label: 'TOTAL VENCIDO', element: amount.bind(this, 'totalVencido'), minWidth: 100  },
        { id: 'totalPagado', label: 'TOTAL PAGADO', element: amount.bind(this, 'totalPagado'), minWidth: 100  },
        { id: 'detail', label: 'DETALLE', element: composition, minWidth: 100  },
    ];
    return (
        <GeneralTable
            columns={columns}
            data={props.data}
        />
    );
}

function ExpiryComposition(props) {
    return(
        <Popover
            // style={{width:'70%'}}
            id={`expiry-composition-popover`}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleToggle}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            elevation={4}
        >
            <Grid container style={{width:700, padding:'15px 24px', border:'1px solid #cfcfcf', borderRadius:5}}>
                <Grid item container justifyContent="space-between" alignItems="center">
                    <Typography
                        variant="h6"
                        style={{color:'#424242'}}
                    >
                        Detalle/Composición Vencimiento
                    </Typography>
                    <IconButton onClick={props.handleToggle}>
                        <CloseOutlined style={{color:'#424242'}} />
                    </IconButton>
                </Grid>
                {
                    props.data ?

                    <Grid container item>
                        <Grid container item justifyContent='space-between' alignItems='center' direction='row'>
                            <Paper style={{ width:250, padding: '.5rem 1rem', margin: '1rem 0'}}>
                                <Typography
                                    variant="subtitle1"
                                    style={{color:'#424242', display:'flex', flexDirection:'column'}}
                                >
                                    <NumberFormat
                                        value={props.data.montoPrestamo || 0}
                                        prefix={'$'}
                                    />
                                    <Typography
                                        variant="overline"
                                        style={{color:'#424242'}}
                                    >Monto préstamo</Typography>
                                </Typography>
                            </Paper>

                            <Paper style={{ width:250, padding: '.5rem 1rem', margin: '1rem 0'}}>
                                <Typography
                                    variant="subtitle1"
                                    style={{color:'#424242', display:'flex', flexDirection:'column'}}
                                >
                                    <NumberFormat
                                        value={props.data.montoAhorro || 0}
                                        prefix={'$'}
                                    />
                                    <Typography
                                        variant="overline"
                                        style={{color:'#424242'}}
                                    >Monto ahorro</Typography>
                                </Typography>
                            </Paper>
                        </Grid>

                        <Paper style={{width:'95%', padding: '.5rem 1rem', margin: '1rem 0'}}>
                            <Typography
                                variant="overline"
                                style={{color:'#424242'}}
                            >Detalle préstamo</Typography>
                                <Typography
                                    variant="subtitle1"
                                    style={{color:'#424242', display:'flex', flexDirection:'column'}}
                                >
                                {
                                    (props.data.detallePrestamo && props.data.detallePrestamo.length) ? props.data.detallePrestamo.map((item, index) => {
                                        return(
                                            <div key={index}>
                                                <ListItem>
                                                    <Grid container direction='row'>
                                                        <ListItemText
                                                            primary={
                                                                <NumberFormat
                                                                    value={item.principal || 0}
                                                                    prefix={'$'}
                                                                />
                                                            }
                                                            secondary="Principal"
                                                            style={{minWidth:110, flex:'none'}}
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                <NumberFormat
                                                                    value={item.interes || 0}
                                                                    prefix={'$'}
                                                                />
                                                            }
                                                            secondary="Interés"
                                                            style={{minWidth:110, flex:'none'}}
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                <NumberFormat
                                                                    value={item.seguro || 0}
                                                                    prefix={'$'}
                                                                />
                                                            }
                                                            secondary="Seguro"
                                                            style={{minWidth:110, flex:'none'}}
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                <NumberFormat
                                                                    value={item.comision || 0}
                                                                    prefix={'$'}
                                                                />
                                                            }
                                                            secondary="Comisión"
                                                            style={{minWidth:110, flex:'none'}}
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                <NumberFormat
                                                                    value={item.mora || 0}
                                                                    prefix={'$'}
                                                                />
                                                            }
                                                            secondary="Mora"
                                                            style={{minWidth:110, flex:'none'}}
                                                        />
                                                        <ListItemText
                                                            primary={item.idDbs || '---'}
                                                            secondary="ID DBS"
                                                            style={{minWidth:110, flex:'none'}}
                                                        />
                                                        <ListItemText
                                                            primary={item.fuente || '---'}
                                                            secondary="Fuente"
                                                            style={{minWidth:110, flex:'none'}}
                                                        />
                                                    </Grid>
                                                </ListItem>
                                                {
                                                    (index+1) !== props.data.detallePrestamo.length &&
                                                    <Divider />
                                                }
                                            </div>
                                        )
                                    }) : <em style={{color:'#424242'}}>SIN INFORMACIÓN</em>
                                }
                                </Typography>
                        </Paper>
                        <Paper style={{width:'95%', padding: '.5rem 1rem', margin: '1rem 0'}}>
                            <Typography
                                variant="overline"
                                style={{color:'#424242'}}
                            >Detalle ahorro</Typography>
                            <List
                                style={{overflow:'auto', maxHeight:'250px'}}
                            >
                                {
                                    (props.data.detalleAhorro && props.data.detalleAhorro.length) ? props.data.detalleAhorro.map((item, index) => {
                                        return(
                                            <div key={index}>
                                                <ListItem>
                                                    <Grid container justifyContent='space-between' direction='row'>
                                                        <ListItemText
                                                            primary={item.idCuenta}
                                                            secondary="ID Cuenta"
                                                            style={{width:100}}
                                                        />
                                                        <ListItemText
                                                            primary={item.descripcion}
                                                            secondary="Descripción"
                                                            style={{width:210}}
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                <NumberFormat
                                                                    value={item.cuota}
                                                                    prefix={'$'}
                                                                />
                                                            }
                                                            secondary="Cuota"
                                                            style={{width:110}}
                                                        />
                                                    </Grid>
                                                </ListItem>
                                                {
                                                    (index+1) !== props.data.detalleAhorro.length &&
                                                    <Divider />
                                                }
                                            </div>
                                        )
                                    }) : <em style={{color:'#424242'}}>SIN INFORMACIÓN</em>
                                }
                            </List>
                        </Paper>

                        <Grid container item justifyContent='space-between' alignItems='center' direction='row'>
                            <Paper style={{width:165, padding: '.5rem 1rem', margin: '1rem 0'}}>
                                <Typography
                                    variant="subtitle1"
                                    style={{color:'#424242', display:'flex', flexDirection:'column'}}
                                >
                                    <NumberFormat
                                        value={props.data.montoAtrasado || 0}
                                        prefix={'$'}
                                    />
                                    <Typography
                                        variant="overline"
                                        style={{color:'#424242'}}
                                    >Monto atrasado </Typography>
                                </Typography>
                            </Paper>
                            <Paper style={{width:165, padding: '.5rem 1rem', margin: '1rem 0'}}>
                                <Typography
                                    variant="subtitle1"
                                    style={{color:'#424242', display:'flex', flexDirection:'column'}}
                                >
                                    <NumberFormat
                                        value={props.data.cuotasAtrasadas || 0}
                                        prefix={'$'}
                                    />
                                    <Typography
                                        variant="overline"
                                        style={{color:'#424242'}}
                                    >Cuotas atrasadas</Typography>
                                </Typography>
                            </Paper>
                            <Paper style={{width:165, padding: '.5rem 1rem', margin: '1rem 0'}}>
                                <Typography
                                    variant="subtitle1"
                                    style={{color:'#424242', display:'flex', flexDirection:'column'}}
                                >
                                    <NumberFormat
                                        value={props.data.cuotasRestantes || 0}
                                        prefix={'$'}
                                    />
                                    <Typography
                                        variant="overline"
                                        style={{color:'#424242'}}
                                    >Cuotas restantes</Typography>
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                :
                    <em style={{color:'#424242', margin:'1rem 0'}}>SIN INFORMACIÓN</em>
                }
            </Grid>
        </Popover>
    )
}

export { ExpiryTable, RohiExpiryTable }