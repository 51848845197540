import { Button, Dialog, DialogContent, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { dateFormat } from "src/components/SharedComponents/CustomHooks";
import { DialogHeader } from "src/components/SharedComponents/Dialogs";
import { DateField } from "src/components/SharedComponents/Fields";
import { http } from "src/utils/axios";

function RohiDownload(props) {
  const { open, handleToggleDialog, token, handleToggleSimpleDialog, setLoading } = props;
  const [date, setDate] = useState({ end: null });

  const handleDateChange = (type, dateInfo, value) => {
    setDate({ ...date, [type]: dateInfo });
  };

  const downloadExcelReport = async (endDate) => {
    const params = {
      end_date: dateFormat(endDate),
    };
    try {
        setLoading(true);
        const response = await http.post(`/utility/rohi-monthly-insurance/`, params, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        });
      
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
      
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = `reporte-seguros-${params.end_date}.xlsx`;
      
        document.body.appendChild(link);
        link.click();
      
        document.body.removeChild(link);
        setLoading(false);
      } catch (error) {
        console.error("Error al descargar Excel:", error);
        handleToggleSimpleDialog("simple-dialog", "Hubo un error al tratar de obtener los seguros, por favor verifique la fecha.");
        setLoading(false);
      }
      
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={open}
    >
      <DialogHeader
        dialogTitle="Descargar reporte en excel"
        handleClick={handleToggleDialog.bind(this, undefined)}
      />
      <DialogContent>
        <Grid>
          <DateField
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Fecha fin"
            dateFieldID="filter-date-start"
            fullWidth={true}
            dateFieldClass="date-field"
            inputVariant="outlined"
            dateFieldLabel="Seleccionar fecha fin"
            format="yyyy/MM/dd"
            value={date.end}
            handleChange={handleDateChange.bind(this, "end")}
          />
        </Grid>
        <Grid
          container
          style={{ margin: "15px 0 10px", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            className={"action-method-btn"}
            size="small"
            onClick={() => downloadExcelReport(date.end)}
          >
            descargar
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default RohiDownload;
