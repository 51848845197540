import * as React from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import CheckBox from "@material-ui/core/Checkbox";
import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import { MenuItem } from "@material-ui/core";

import { createAcquaintanceshipPersonContract } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import StakeHoldersSelectInput from "../../../components/StakeHoldersSelectInput";
import {
  capitalizeCaseHandler,
  catchError,
  resetError,
  stakeHolderArrayToJson,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import {
  createAcquaintanceshipPersonContract as create,
  fetchAssignees as fetchA,
  fetchNationalities,
} from "../../../utils/axios";
import CreditorSelectInput from "../../Creditor/CreditorSelectInput";
import DepositBankAccountSelectInput from "../../DepositBankAccount/DepositBankAccountSelectInput";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  gridTextField: {
    margin: 8,
    width: "100%",
  },
  gridFormControl: {
    margin: 8,
    width: "100%",
  },
});

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approximateAmountOfTransactionsOnTheCooperative:
        "less than RD$30 thousands",
      approximateAmountOfTransactionsOnTheCooperativeError: "",
      arePreviousQuestionsValidForDebtorRelatives: false,
      arePreviousQuestionsValidForDebtorRelativesDescription: "",
      arePreviousQuestionsValidForDebtorRelativesDescriptionError: "",
      arePreviousQuestionsValidForDebtorRelativesError: "",
      beneficiaryInfo: [],
      beneficiaryInfoError: "",
      beneficiaryIsPersonalRef: false,
      certificateOfDeposits: "",
      certificateOfDepositsError: "",
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      contributionCertificate: "",
      contributionCertificateError: "",
      createdBy: "",
      creditor: [],
      creditorError: "",
      debtor: [],
      debtorError: "",
      dialog: false,
      dialogTitle: "",
      economicActivityInfo: "employee/salaried",
      economicActivityInfoError: "",
      errorMessage: "",
      familyMembersInfo: [],
      familyMembersInfoError: "",
      financialAccounts: [],
      financialAccountsError: "",
      foreignId: "",
      foreignIdError: "",
      hasDebtorAnotherCitizenship: false,
      hasDebtorAnotherCitizenshipError: "",
      accountPurpose: false,
      loading: false,
      secondaryIncome: false,
      loans: "",
      loansError: "",
      loggedUser: "",
      primaryIncomeMonthlyActivity: "less than RD$20 thousands",
      primaryIncomeMonthlyActivityError: "",
      savingsAccount: "",
      savingsAccountError: "",
      secondaryIncomeActivity: "",
      secondaryIncomeActivityError: "",
      secondaryIncomeMonthlyActivity: "N/A",
      secondaryIncomeMonthlyActivityError: "",
      secondCitizenshipCountry: "",
      secondCitizenshipCountryError: "",
      title: "",
      titleError: "",
      willDebtorReceive15kOrMoreInTheAccount: false,
      willDebtorReceive15kOrMoreInTheAccountError: "",
      countries: [],

      pepMember: false,
      pepMemberPosition: "",
      pepMemberPositionError: "",
      pepMemberEntryDate: "",
      pepMemberEntryDateError: "",
      pepMemberInstitution: "",
      pepMemberInstitutionError: "",

      pepMemberKinship: false,
      pepMemberKinshipPosition: "",
      pepMemberKinshipPositionError: "",
      pepMemberKinshipEntryDate: "",
      pepMemberKinshipEntryDateError: "",
      pepMemberKinshipInstitution: "",
      pepMemberKinshipInstitutionError: "",
    };
    // creditor
    this.handleAddCreditor = this.handleAddCreditor.bind(this);
    this.handleDeleteCreditor = this.handleDeleteCreditor.bind(this);

    // debtor
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // stakeholder
    this.handleAddStakeHolder = this.handleAddStakeHolder.bind(this);
    this.handleDeleteStakeHolder = this.handleDeleteStakeHolder.bind(this);
    // stakeholder benef
    this.handleAddStakeHolderBenef = this.handleAddStakeHolderBenef.bind(this);
    this.handleDeleteStakeHolderBenef = this.handleDeleteStakeHolderBenef.bind(
      this
    );

    // financial Accounts
    this.handleAddBankAccount = this.handleAddBankAccount.bind(this);
    this.handleDeleteBankAccount = this.handleDeleteBankAccount.bind(this);

    // core event
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }
  CancelToken = axios.CancelToken;
  source = this.CancelToken.source();
  abortController = new AbortController();

  componentDidMount() {
    fetchA({
      token: this.props.user.token,
      signal: this.abortController.signal,
    }).then((res) => {
      try {
        const loggedUser = res.data.find(
          (user) => user.id === jwtDecode(this.props.user.token).user_id
        ).username;
        this.setState({ loggedUser: loggedUser });
      } catch (error) {
        catchError(error, this);
      }
    });
    fetchNationalities({ token: this.props.user.token })
      .then((res) => {
        this.setState({
          countries: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando nacionalidades",
          loading: false,
        });
      });
  }
  handleAddStakeHolder(stakeholder) {
    this.setState((prevState) => ({
      familyMembersInfo: [...prevState.familyMembersInfo, stakeholder],
    }));
  }
  handleDeleteStakeHolder(index) {
    this.setState((prevState) => ({
      familyMembersInfo: prevState.familyMembersInfo.filter(
        (stakeholder, i) => i !== index
      ),
    }));
  }
  // stakeholder beneficiary
  handleAddStakeHolderBenef(stakeholder) {
    this.setState((prevState) => ({
      beneficiaryInfo: [...prevState.beneficiaryInfo, stakeholder],
    }));
  }
  handleDeleteStakeHolderBenef(index) {
    this.setState((prevState) => ({
      beneficiaryInfo: prevState.beneficiaryInfo.filter(
        (stakeholder, i) => i !== index
      ),
    }));
  }

  handleCheckBoxChange(key) {
    let data = {};
    if (
      key === "beneficiaryIsPersonalRef" &&
      !this.state.beneficiaryIsPersonalRef
    ) {
      data = { familyMembersInfo: this.state.beneficiaryInfo };
    }
    return (event) => {
      this.setState({ ...data, [key]: event.target.checked });
    };
  }
  handleAddBankAccount(id) {
    const found = this.state.financialAccounts.find((faId) => faId === id);

    if (!found) {
      this.setState((prevState) => ({
        financialAccounts: [...prevState.financialAccounts, id],
      }));
    }
  }
  handleDeleteBankAccount(id) {
    this.setState((prevState) => ({
      financialAccounts: prevState.financialAccounts.filter(
        (faId) => faId !== id
      ),
    }));
  }

  handleAddCreditor(id) {
    this.setState({ creditor: [id] });
  }
  handleDeleteCreditor(id) {
    this.setState({ creditor: [] });
  }
  handleAddClient(id) {
    this.setState({ debtor: [id] });
  }
  handleDeleteClient(id) {
    this.setState({ debtor: [] });
  }

  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  handleSelectChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.value });
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    if (!state.familyMembersInfo.length) {
      this.setState({ familyMembersInfoError: "Este campo es requerido" });
      return;
    }
    if (!state.familyMembersInfo.length && this.props.fromTicket) {
      this.setState({ beneficiaryInfoError: "Este campo es requerido" });
      return;
    }
    const familyMembersInfo = stakeHolderArrayToJson(state.familyMembersInfo);
    const beneficiaryInfo = stakeHolderArrayToJson(state.beneficiaryInfo);

    const contract = {
      accountPurpose: {
        savingsAccount: state.savingsAccount,
        certificateOfDeposits: state.certificateOfDeposits,
        contributionCertificate: state.contributionCertificate,
        loans: state.loans,
      },
      hasDebtorPossessedPublicPower: this.state.pepMember,
      hasDebtorPossessedPublicPowerDescription: {
        pepMemberPosition: this.state.pepMemberPosition,
        pepMemberEntryDate: this.state.pepMemberEntryDate,
        pepMemberInstitution: this.state.pepMemberInstitution,
      },
      arePreviousQuestionsValidForDebtorRelatives: this.state.pepMemberKinship,
      arePreviousQuestionsValidForDebtorRelativesDescription: {
        pepMemberKinshipPosition: capitalizeCaseHandler(
          this.state.pepMemberKinshipPosition
        ),
        pepMemberKinshipEntryDate: this.state.pepMemberKinshipEntryDate,
        pepMemberKinshipInstitution: capitalizeCaseHandler(
          this.state.pepMemberKinshipInstitution
        ),
      },
      approximateAmountOfTransactionsOnTheCooperative:
        state.approximateAmountOfTransactionsOnTheCooperative,
      secondCitizenshipCountry: titleCaseHandler(
        state.secondCitizenshipCountry
      ),
      foreignId: titleCaseHandler(state.foreignId),
      contractDate: state.contractDate,
      secondaryIncome: state.secondaryIncome,
      creditor: state.creditor[0] || "",
      createdBy: state.loggedUser,
      debtor: state.debtor[0] || "",
      economicActivityInfo: state.economicActivityInfo,
      familyMembersInfo,
      beneficiaryInfo,
      financialAccounts: state.financialAccounts,
      hasDebtorAnotherCitizenship: state.hasDebtorAnotherCitizenship,
      primaryIncomeMonthlyActivity: state.primaryIncomeMonthlyActivity,
      secondaryIncomeActivity: state.secondaryIncomeActivity,
      secondaryIncomeMonthlyActivity: state.secondaryIncomeMonthlyActivity,
      title: state.title,
      willDebtorReceive15kOrMoreInTheAccount:
        state.willDebtorReceive15kOrMoreInTheAccount,
    };

    this.setState({ loading: true });

    create(this.props.user.token, contract)
      .then((res) => {
        this.props.createAcquaintanceshipPersonContract(
          res.data.results ? res.data.results : res.data
        );
        if (this.props.handleSaveCallBack) {
          this.props.handleSaveCallBack(
            res.data.uniqueId,
            res.data.results ? res.data.results : res.data
          );
        }
        successHandler(res, this, translations.APF);
        this.handleReset();
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  handleReset() {
    this.setState({
      arePreviousQuestionsValidForDebtorRelatives: false,
      arePreviousQuestionsValidForDebtorRelativesDescription: "",
      arePreviousQuestionsValidForDebtorRelativesDescriptionError: "",
      arePreviousQuestionsValidForDebtorRelativesError: "",
      beneficiaryInfo: [],
      beneficiaryInfoError: "",
      beneficiaryIsPersonalRef: false,
      secondCitizenshipCountry: "",
      secondCitizenshipCountryError: "",
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      creditor: [],
      creditorError: "",
      debtor: [],
      debtorError: "",
      economicActivityInfo: "Empleado / Asalariado",
      economicActivityInfoError: "",
      familyMembersInfo: [],
      familyMembersInfoError: "",
      financialAccounts: [],
      financialAccountsError: "",
      foreignId: "",
      foreignIdError: "",
      hasDebtorAnotherCitizenship: false,
      hasDebtorAnotherCitizenshipError: "",
      accountPurpose: false,
      primaryIncomeMonthlyActivity: "Menos de RD$20 mil",
      primaryIncomeMonthlyActivityError: "",
      approximateAmountOfTransactionsOnTheCooperative: "Menos de RD$30 mil",
      approximateAmountOfTransactionsOnTheCooperativeError: "",
      secondaryIncomeActivity: "",
      secondaryIncomeActivityError: "",
      secondaryIncomeMonthlyActivity: "N/A",
      secondaryIncomeMonthlyActivityError: "",
      title: "",
      titleError: "",
      willDebtorReceive15kOrMoreInTheAccount: false,
      willDebtorReceive15kOrMoreInTheAccountError: "",
      countries: [],
      pepMember: false,
      pepMemberPosition: "",
      pepMemberPositionError: "",
      pepMemberEntryDate: "",
      pepMemberEntryDateError: "",
      pepMemberInstitution: "",
      pepMemberInstitutionError: "",

      pepMemberKinship: false,
      pepMemberKinshipPosition: "",
      pepMemberKinshipPositionError: "",
      pepMemberKinshipEntryDate: "",
      pepMemberKinshipEntryDateError: "",
      pepMemberKinshipInstitution: "",
      pepMemberKinshipInstitutionError: "",
    });
  }
  handleDialogClose() {
    this.setState({ dialog: false });
  }

  render() {
    const { classes } = this.props;
    const headers = [
      { value: "savingsAccount", label: "Cuenta de Ahorro" },
      { value: "certificateOfDeposits", label: "Certificado de Depósitos" },
      { value: "contributionCertificate", label: "Certificado de Aportación" },
      { value: "loans", label: "Prestamos" },
    ];
    return (
      <form
        name="APF"
        noValidate={true}
        onSubmit={this.handleSubmit}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">{translations.NEW_APF}</Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.CREDITOR}
                </Typography>
                <CreditorSelectInput
                  selectedCreditorsId={this.state.creditor}
                  addCreditor={this.handleAddCreditor}
                  deleteCreditor={this.handleDeleteCreditor}
                />
                <p className={classes.errorInput}>{this.state.creditorError}</p>
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.DEBTOR}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtor}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                  showClaimColumn={false}
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>
              <FormControlLabel
                label={translations.DEBTOR_ANOTHER_CITIZENSHIP}
                control={
                  <CheckBox
                    color="primary"
                    checked={this.state.hasDebtorAnotherCitizenship}
                    onChange={this.handleCheckBoxChange(
                      "hasDebtorAnotherCitizenship"
                    )}
                  />
                }
                value="hasDebtorAnotherCitizenship"
              />
              {this.state.hasDebtorAnotherCitizenship ? (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={3}>
                    <FormControl className={classes.gridFormControl}>
                      <InputLabel
                        htmlFor="secondCitizenshipCountry"
                        shrink={true}
                      >
                        {translations.CITIZENSHIP_COUNTRY}
                      </InputLabel>
                      <Select
                        value={this.state.secondCitizenshipCountry}
                        onChange={this.handleSelectChange(
                          "secondCitizenshipCountry"
                        )}
                        required={true}
                        style={{ width: "100%" }}
                        error={this.state.secondCitizenshipCountryError !== ""}
                        inputProps={{
                          id: "secondCitizenshipCountry",
                          name: "secondCitizenshipCountry",
                        }}
                      >
                        {this.state.countries.map((opt, index) => {
                          return (
                            <MenuItem key={index} value={opt.name}>
                              {opt.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label={translations.FOREIGN_ID}
                      className={classes.gridTextField}
                      value={titleCaseHandler(this.state.foreignId)}
                      onChange={this.handleChange("foreignId")}
                      required={true}
                      error={this.state.foreignIdError !== ""}
                      helperText={this.state.foreignIdError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                </Grid>
              ) : null}
              <br />
              <br
                style={{
                  display: this.state.hasDebtorAnotherCitizenship
                    ? "block"
                    : "none",
                }}
              />
              {this.props.fromTicket && (
                <div className={classes.half}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {"Beneficiarios en caso de fallecimiento"}
                  </Typography>
                  <StakeHoldersSelectInput
                    stakeHolders={this.state.beneficiaryInfo}
                    add={this.handleAddStakeHolderBenef}
                    delete={this.handleDeleteStakeHolderBenef}
                    hasProportion={true}
                  />
                  <p className={classes.errorInput}>
                    {this.state.beneficiaryInfoError}
                  </p>
                  <FormControlLabel
                    label={"Beneficiario son referencias personales"}
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.beneficiaryIsPersonalRef}
                        onChange={this.handleCheckBoxChange(
                          "beneficiaryIsPersonalRef"
                        )}
                      />
                    }
                    value="beneficiaryIsPersonalRef"
                  />
                </div>
              )}
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.FAMILY_MEMBER_INFO}
                </Typography>
                <StakeHoldersSelectInput
                  stakeHolders={this.state.familyMembersInfo}
                  add={this.handleAddStakeHolder}
                  delete={this.handleDeleteStakeHolder}
                />
                <p className={classes.errorInput}>
                  {this.state.familyMembersInfoError}
                </p>
              </div>
              <br />
              <FormControl
                style={{ width: "fit-content" }}
                className={classes.formControl}
                error={
                  this.state
                    .approximateAmountOfTransactionsOnTheCooperativeError !== ""
                }
              >
                <InputLabel
                  style={{ top: -17 }}
                  htmlFor="approximateAmountOfTransactionsOnTheCooperative"
                >
                  {
                    translations.APPROXIMATE_AMOUNT_OF_TRANSACTIONS_WHICH_DEBTOR_WILL_MAKE_ON_THE_COOPERATIVE
                  }
                </InputLabel>
                <Select
                  style={{ width: "450px" }}
                  value={
                    this.state.approximateAmountOfTransactionsOnTheCooperative
                  }
                  onChange={this.handleSelectChange(
                    "approximateAmountOfTransactionsOnTheCooperative"
                  )}
                  required={true}
                  inputProps={{
                    id: "approximateAmountOfTransactionsOnTheCooperative",
                    name: "approximateAmountOfTransactionsOnTheCooperative",
                  }}
                >
                  <MenuItem value="less than RD$30 thousands">
                    {translations.LESS_30}
                  </MenuItem>
                  <MenuItem value="from RD$30 thousands to RD$80 thousands">
                    {translations.FROM_30_TO_80}
                  </MenuItem>
                  <MenuItem value="from RD$80 thousands to RD$120 thousands">
                    {translations.FROM_80_TO_120}
                  </MenuItem>
                  <MenuItem value="from RD$120 thousands to RD$170 thousands">
                    {translations.FROM_120_TO_170}
                  </MenuItem>
                  <MenuItem value="from RD$170 thousands to RD$200 thousands">
                    {translations.FROM_170_TO_200}
                  </MenuItem>
                  <MenuItem value="more than RD$200 thousands">
                    {translations.MORE_200}
                  </MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <FormControlLabel
                label={translations.DEPOSIT_15K}
                control={
                  <CheckBox
                    color="primary"
                    checked={this.state.willDebtorReceive15kOrMoreInTheAccount}
                    onChange={this.handleCheckBoxChange(
                      "willDebtorReceive15kOrMoreInTheAccount"
                    )}
                  />
                }
                value="willDebtorReceive15kOrMoreInTheAccount"
              />
              <br />
              <br />
              <FormControlLabel
                label={translations.PURPOSE_OF_THE_ACCOUNT_TO_BE_OPENED}
                control={
                  <CheckBox
                    color="primary"
                    checked={this.state.accountPurpose}
                    onChange={this.handleCheckBoxChange("accountPurpose")}
                  />
                }
                value="accountPurpose"
              />
              {this.state.accountPurpose
                ? headers.map((opt, index) => {
                    return (
                      <TextField
                        label={opt.label}
                        key={index}
                        className={classes.textField}
                        value={this.state[opt.value]}
                        onChange={this.handleChange(opt.value)}
                        multiline={true}
                        fullWidth={true}
                        error={this.state[opt.value + "Error"] !== ""}
                        helperText={this.state[opt.value + "Error"]}
                        InputLabelProps={{ style: styles.checkboxLabel }}
                      />
                    );
                  })
                : null}
              <br />
              <br />

              <Typography variant="h6" className={classes.checkboxLabel}>
                {translations.FINANCIAL_INFORMATION}
              </Typography>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={3}>
                  <FormControl
                    className={classes.gridFormControl}
                    error={this.state.economicActivityInfoError !== ""}
                  >
                    <InputLabel htmlFor="economicActivityInfo" shrink={true}>
                      {translations.ECONOMIC_ACTIVITY_INFO}
                    </InputLabel>
                    <Select
                      value={this.state.economicActivityInfo}
                      onChange={this.handleSelectChange("economicActivityInfo")}
                      required={true}
                      style={{ width: "100%" }}
                      inputProps={{
                        id: "economicActivityInfo",
                        name: "economicActivityInfo",
                      }}
                    >
                      <MenuItem value="employee/salaried">
                        {translations.EMPLOYEE_SALARIED}
                      </MenuItem>
                      <MenuItem value="owner/partner">
                        {translations.OWNER_PARTNER}
                      </MenuItem>
                      <MenuItem value="retired/pensioner">
                        {translations.RETIRED_PENSIONER}
                      </MenuItem>
                      <MenuItem value="independent">
                        {translations.INDEPENDENT}
                      </MenuItem>
                      <MenuItem value="lender/investor">
                        {translations.LENDER_INVESTOR}
                      </MenuItem>
                      <MenuItem value="housewife">
                        {translations.HOUSEWIFE}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl
                    className={classes.gridFormControl}
                    error={this.state.primaryIncomeMonthlyActivityError !== ""}
                  >
                    <InputLabel
                      style={{ top: -17 }}
                      htmlFor="primaryIncomeMonthlyActivity"
                      shrink={true}
                    >
                      {translations.PRIMARY_INCOME_MONTHLY_ACTIVITY}
                    </InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      value={this.state.primaryIncomeMonthlyActivity}
                      onChange={this.handleSelectChange(
                        "primaryIncomeMonthlyActivity"
                      )}
                      required={true}
                      inputProps={{
                        id: "primaryIncomeMonthlyActivity",
                        name: "primaryIncomeMonthlyActivity",
                      }}
                    >
                      <MenuItem value="less than RD$20 thousands">
                        {translations.LESS_20}
                      </MenuItem>
                      <MenuItem value="from RD$20 thousands to RD$50 thousands">
                        {translations.FROM_20_TO_50}
                      </MenuItem>
                      <MenuItem value="from RD$50 thousands to RD$100 thousands">
                        {translations.FROM_50_TO_100}
                      </MenuItem>
                      <MenuItem value="more than RD$100 thousands">
                        {translations.MORE_100}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControlLabel
                    label={"Ingresos secundarios"}
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.secondaryIncome}
                        onChange={this.handleCheckBoxChange("secondaryIncome")}
                      />
                    }
                    value="secondaryIncome"
                  />
                </Grid>
              </Grid>
              {this.state.secondaryIncome ? (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={3}>
                    <TextField
                      label={translations.SECONDARY_INCOME_ACTIVITY}
                      value={titleCaseHandler(
                        this.state.secondaryIncomeActivity
                      )}
                      onChange={this.handleChange("secondaryIncomeActivity")}
                      className={classes.gridTextField}
                      multiline={true}
                      error={this.state.secondaryIncomeActivityError !== ""}
                      helperText={this.state.secondaryIncomeActivityError}
                      InputLabelProps={{
                        style: styles.checkboxLabel,
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl
                      className={classes.gridFormControl}
                      error={
                        this.state.secondaryIncomeMonthlyActivityError !== ""
                      }
                    >
                      <InputLabel
                        style={{ top: -17 }}
                        color="primary"
                        htmlFor="secondaryIncomeMonthlyActivity"
                      >
                        {translations.SECONDARY_INCOME_ACTIVITY}
                      </InputLabel>
                      <Select
                        value={this.state.secondaryIncomeMonthlyActivity}
                        onChange={this.handleSelectChange(
                          "secondaryIncomeMonthlyActivity"
                        )}
                        required={true}
                        style={{ width: "100%" }}
                        inputProps={{
                          id: "secondaryIncomeMonthlyActivity",
                          name: "secondaryIncomeMonthlyActivity",
                        }}
                      >
                        <MenuItem value="N/A">
                          {translations.NON_APPLICABLE}
                        </MenuItem>
                        <MenuItem value="less than RD$30 thousands">
                          {translations.LESS_30}
                        </MenuItem>
                        <MenuItem value="from RD$30 thousands to RD$60 thousands">
                          {translations.FROM_30_TO_60}
                        </MenuItem>
                        <MenuItem value="from RD$60 thousands to RD$90 thousands">
                          {translations.FROM_60_TO_90}
                        </MenuItem>
                        <MenuItem value="from RD$90 thousands to RD$120 thousands">
                          {translations.FROM_90_TO_120}
                        </MenuItem>
                        <MenuItem value="more than RD$120 thousands">
                          {translations.MORE_120}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}

              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.BANK_ACCOUNT}
                </Typography>
                <DepositBankAccountSelectInput
                  selectedDepositBankAccountsId={this.state.financialAccounts}
                  addDepositBankAccount={this.handleAddBankAccount}
                  deleteDepositBankAccount={this.handleDeleteBankAccount}
                />
                <p className={classes.errorInput}>
                  {this.state.financialAccountsError}
                </p>
              </div>
              <br />
              <br />
              <FormControlLabel
                label={
                  "¿Existe alguna persona políticamente expuesta y/o figura pública dentro de los socios o consejo directivo?"
                }
                control={
                  <CheckBox
                    color="primary"
                    checked={this.state.pepMember}
                    onChange={this.handleCheckBoxChange("pepMember")}
                  />
                }
                value="pepMember"
              />
              {this.state.pepMember ? (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={3}>
                    <TextField
                      label={"Cargo, Rango o Posición en Gobierno"}
                      className={classes.gridTextField}
                      value={titleCaseHandler(this.state.pepMemberPosition)}
                      onChange={this.handleChange("pepMemberPosition")}
                      fullWidth={true}
                      required={true}
                      error={this.state.pepMemberPositionError !== ""}
                      helperText={this.state.pepMemberPositionError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label={"Fecha desde que ocupa el cargo, Rango o Posición"}
                      className={classes.gridTextField}
                      value={titleCaseHandler(this.state.pepMemberEntryDate)}
                      onChange={this.handleChange("pepMemberEntryDate")}
                      fullWidth={true}
                      required={true}
                      type={"date"}
                      error={this.state.pepMemberEntryDateError !== ""}
                      helperText={this.state.pepMemberEntryDateError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label={"Institución a la que pertenece"}
                      className={classes.gridTextField}
                      value={titleCaseHandler(this.state.pepMemberInstitution)}
                      onChange={this.handleChange("pepMemberInstitution")}
                      fullWidth={true}
                      required={true}
                      error={this.state.pepMemberInstitutionError !== ""}
                      helperText={this.state.pepMemberInstitutionError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                </Grid>
              ) : null}

              <br />
              <br />
              <br />
              <FormControlLabel
                label={
                  "¿Existe alguna persona dentro de los socios o consejo directivo con algún parentesco a una persona persona politicamente expuesta y/o figura pública?"
                }
                control={
                  <CheckBox
                    color="primary"
                    checked={this.state.pepMemberKinship}
                    onChange={this.handleCheckBoxChange("pepMemberKinship")}
                  />
                }
                value="pepMemberKinship"
              />
              {this.state.pepMemberKinship ? (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={3}>
                    <TextField
                      label={"Cargo, Rango o Posición en Gobierno"}
                      className={classes.gridTextField}
                      value={titleCaseHandler(
                        this.state.pepMemberKinshipPosition
                      )}
                      onChange={this.handleChange("pepMemberKinshipPosition")}
                      required={true}
                      error={this.state.pepMemberKinshipPositionError !== ""}
                      helperText={this.state.pepMemberKinshipPositionError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label={"Fecha desde que ocupa el cargo, Rango o Posición"}
                      className={classes.gridTextField}
                      value={titleCaseHandler(
                        this.state.pepMemberKinshipEntryDate
                      )}
                      onChange={this.handleChange("pepMemberKinshipEntryDate")}
                      required={true}
                      type={"date"}
                      error={this.state.pepMemberKinshipEntryDateError !== ""}
                      helperText={this.state.pepMemberKinshipEntryDateError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label={"Institución a la que pertenece"}
                      className={classes.gridTextField}
                      value={titleCaseHandler(
                        this.state.pepMemberKinshipInstitution
                      )}
                      onChange={this.handleChange(
                        "pepMemberKinshipInstitution"
                      )}
                      required={true}
                      error={this.state.pepMemberKinshipInstitutionError !== ""}
                      helperText={this.state.pepMemberKinshipInstitutionError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                </Grid>
              ) : null}
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createAcquaintanceshipPersonContract })(Form)
);
