import * as React from 'react';
import { connect } from 'react-redux';
import { catchError, translations } from 'src/utils';

import green from '@material-ui/core/colors/green';
import { createStyles, withStyles } from '@material-ui/core/styles';

import { fetchOutlays } from '../../../actions';
import DefaultSection from '../../../components/DefaultSection';
import Loading from '../../../components/Loading';
import { fetchNewOutlays as fetchAll } from '../../../utils/axios';
import OutlaySearchSection from '../OutlaySearchSection';
import OutlayView from '../OutlayView';
import OutlaySearchFilter from '../OutlaySearchSection/OutlaySearchFilter';
import { Typography } from '@material-ui/core';
import { ToolbarComponent } from 'src/components/SharedComponents/Toolbar';

const styles = createStyles({
    root: {
        padding: "20px 20px 10% 20px",
    },
    content: {
        padding:'1%', 
        border:'1px solid #EEEEEE', 
        borderTop:'none'
    },
});

class OutlaySection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: false,
        selectedId: undefined,
        selectedSection: 0
    }  
      this.handleAddClick = this.handleAddClick.bind(this);
      this.handleChangeSection = this.handleChangeSection.bind(this);
      this.advanceSearch = this.advanceSearch.bind(this);
      this.fetchData = this.fetchData.bind(this);
    }
    componentDidMount() {
        this.setState({ loading: true });
        fetchAll( this.props.user.token, {offset: 100}).then(res => {
            this.props.fetchOutlays(res.data.results ? res.data.results : res.data ? res.data : '');
            this.setState({ loading: false });
        }).catch(err => {
            catchError(err, this);
        });
    }
    handleChangeSection(sectionNumber) {
        this.setState({ selectedSection: sectionNumber });
    }
    handleAddClick() {
        this.setState({
            selectedSection: 3
        });
    }
    advanceSearch(params) {
        this.setState({ loading: true });
        fetchAll(this.props.user.token, params)
            .then(res => {
                const data = res.data.results ? res.data.results : res.data ? res.data : ''
                this.props.fetchOutlays(data);
                this.setState({ loading: false });
            }).catch(err => {
                catchError(err, this)
            });
        }
    fetchData(params) {
        
        this.setState({ loading: true });
        fetchAll(params)
            .then(res => {
                const data = res.data.results ? res.data.results : res.data
                this.props.fetchOutlays(data);
                this.setState({ loading: false });
            })
            .catch(err => {
                catchError(err, this);
            });
    }
    render() {
        const { classes } = this.props;
        const { loading, selectedId, selectedSection } = this.state;
        return (
          <div className={classes.root}>
            <ToolbarComponent
              typeVariant="h6"
              typeClass="title-text"
              toolbarTitle="Gestión de Desembolsos"
              btnVariant="contained"
            />
            <div className={classes.content}>
            <OutlaySearchFilter
              search={this.advanceSearch}
              token={this.props.user.token}
            />
            <OutlaySearchSection
              advanceSearch={this.advanceSearch}
              loading={loading}
              fetchData={this.fetchData}
              pagination={true}
            />
            </div>
          </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps, { fetchOutlays })(OutlaySection)
);