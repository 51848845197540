import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createOutlayCheck } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import moneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import {
  catchError,
  resetError,
  successHandler,
  translations,
} from "../../../utils";
import { createOutlayCheck as create } from "../../../utils/axios";
import BankAccountSelectInput from "../../BankAccount/BankAccountSelectInput";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "90%",
  },
});

interface IOutlayCheckFormProps {
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    formControl: string;
    greenButton: string;
    input: string;
    paper: string;
    root: string;
    textField: string;
  };

  user: IUser;
  createOutlayCheck(outlayCheck: IOutlayCheck): IAction<IOutlayCheck[]>;
  changeSection(sectionNumber: number): void;
}
interface IOutlayCheckFormState {
  [key: string]: string | string[] | number | boolean;

  account: string[];
  accountError: string;

  amount: string;
  amountError: string;

  client: string[];
  clientError: string;

  concept: string;
  conceptError: string;

  date: string;
  dateError: string;

  operationType: string;
  operationTypeError: string;

  status: string;
  statusError: string;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
}
class OutlayCheckForm extends React.Component<
  IOutlayCheckFormProps,
  IOutlayCheckFormState
> {
  public state: IOutlayCheckFormState = {
    account: [],
    accountError: "",
    amount: "",
    amountError: "",
    client: [],
    clientError: "",
    concept: "",
    conceptError: "",
    date: "",
    dateError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    operationType: "debit",
    operationTypeError: "",
    status: "",
    statusError: "",
  };

  constructor(props: IOutlayCheckFormProps) {
    super(props);

    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);

    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);
    this.handleCheckInputChange = this.handleCheckInputChange.bind(this);

    this.handleAddBankAccount = this.handleAddBankAccount.bind(this);
    this.handleDeleteBankAccount = this.handleDeleteBankAccount.bind(this);
  }
  public handleCheckInputChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public handleAddClient(id: string) {
    this.setState({ client: [id] });
  }
  public handleDeleteClient(id: string) {
    this.setState({ client: [] });
  }
  public handleAddBankAccount(id: string) {
    this.setState({ account: [id] });
  }
  public handleDeleteBankAccount(id: string) {
    this.setState({ account: [] });
  }
  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    const outlayCheck: IOutlayCheck = {
      account: this.state.account[0] || "",
      amount: this.state.amount,
      client: this.state.client[0] || "",
      concept: this.state.concept,
      date: this.state.date,
      operationType: this.state.operationType,
    };

    this.setState({ loading: true });

    create(this.props.user.token, outlayCheck)
      .then((res) => {
        this.props.createOutlayCheck(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, translations.CHECK);
        this.handleReset();
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }
  public handleReset() {
    this.setState({
      account: [],
      accountError: "",
      amount: "",
      amountError: "",
      client: [],
      clientError: "",
      concept: "",
      conceptError: "",
      date: "",
      dateError: "",
      operationType: "debit",
      operationTypeError: "",
      status: "",
      statusError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.NEW_CHECK}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <br />
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {translations.BANK_ACCOUNT}
              </Typography>
              <BankAccountSelectInput
                selectedBankAccountsId={this.state.account}
                bankAccountFilter="checking"
                ownerRegistryNumber="430298557"
                addBankAccount={this.handleAddBankAccount}
                deleteBankAccount={this.handleDeleteBankAccount}
              />
              <p style={{ color: "red" }}>{this.state.accountError}</p>
              <br />
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {translations.CLIENT}
              </Typography>
              <div style={{ width: "100%" }}>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.client}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                />
              </div>
              <p style={{ color: "red" }}>{this.state.clientError}</p>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "50%" }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="operationType" shrink={true}>
                      {translations.OPERATION_TYPES}
                    </InputLabel>
                    <Select
                      value={this.state.operationType}
                      onChange={this.handleSelectChange("operationType")}
                      required={true}
                      native={true}
                      error={this.state.operationTypeError !== ""}
                      inputProps={{
                        id: "operationType",
                        name: "operationType",
                      }}
                    >
                      <option value="debit">{translations.DEBIT}</option>
                      <option value="credit">{translations.CREDIT}</option>
                    </Select>
                  </FormControl>
                  <TextField
                    label={translations.DATE}
                    className={classes.textField}
                    value={this.state.date}
                    type="date"
                    onChange={this.handleChange("date")}
                    error={this.state.dateError !== ""}
                    helperText={this.state.dateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.label,
                    }}
                  />
                  <p style={{ color: "red" }}>{this.state.dateError}</p>
                </div>
                <div style={{ width: "50%" }}>
                  <TextField
                    label={translations.AMOUNT_2}
                    className={classes.textField}
                    value={this.state.amount}
                    onChange={this.handleCurrencyInputChange("amount")}
                    fullWidth={true}
                    required={true}
                    error={this.state.amountError !== ""}
                    helperText={this.state.amountError}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                  <p style={{ color: "red" }}>{this.state.amountError}</p>

                  <TextField
                    label={translations.CONCEPT}
                    className={classes.textField}
                    value={this.state.concept}
                    onChange={this.handleChange("concept")}
                    fullWidth={true}
                    variant="outlined"
                    multiline={true}
                    rows={4}
                    rowsMax={4}
                    required={true}
                    error={this.state.conceptError !== ""}
                    helperText={this.state.conceptError}
                    InputLabelProps={{ style: styles.label }}
                  />
                  <p style={{ color: "red" }}>{this.state.conceptError}</p>
                </div>
              </div>

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
        <br />
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createOutlayCheck })(OutlayCheckForm)
);
