import { Button, Grid } from "@material-ui/core";
import { ClearAll } from "@material-ui/icons";
import React, { useState } from "react";
import { DateField, SearchComponent } from "src/components/SharedComponents/Fields";

const defaultFilterData = {
  institution: null,
  date: null,
};

function Filters(props) {
  const [filterData, setFilterData] = useState(defaultFilterData);
  const { classes } = props;

  const clearFilters = () => {
    setFilterData(defaultFilterData);
  };
  const filterFieldHandler = (field, event, val) => {
    let value = val ? val : event && event.target ? event.target.value : event;
    setFilterData({ ...filterData, [field]: value });
  };

  const applyFilters = () => {
    const institutionPatrono = filterData.institution?.info?.uniqueId;
    const fields = {
      uniqueId: institutionPatrono,
      venc_date: filterData?.date,
    };
    Object.keys(fields).forEach((key) => {
      if (!fields[key] || fields[key].length < 1) {
        delete fields[key];
      }
    });

    props.applyFilters(fields);
  };

  return (
    <Grid className="filters-container">
      <Grid item container spacing={2} direction="row">
        <SearchComponent
          xs={4}
          containerClass="field-container"
          fieldLabel="Institución"
          fieldID="filter-field-enterprises"
          fieldInnerLabel="Filtrar institución"
          value={filterData.institution}
          handleChange={filterFieldHandler.bind(this, "institution")}
          optionType={"institutions"}
          limitTags={1}
        />
        <DateField
          xs={4}
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Fecha de Vencimiento*"
          dateFieldID="filter-date-expiry"
          fullWidth={true}
          dateFieldClass="date-field"
          inputVariant="outlined"
          dateFieldLabel="Seleccionar fecha*"
          format="yyyy/MM/dd"
          value={filterData.date}
          handleChange={filterFieldHandler.bind(this, "date")}
        />
      </Grid>
      <Grid
        container
        direction="row"
        className={classes}
      >
        <Button
          disableElevation
          className="filter-clear-btn"
          onClick={clearFilters.bind(this, false)}
        >
          limpiar
          <ClearAll style={{ marginLeft: 8, fontSize: "19px" }} />
        </Button>
        <Button
          disableElevation
          className="filter-apply-btn"
          onClick={applyFilters}
        >
          aplicar filtros
        </Button>
      </Grid>
    </Grid>
  );
}

export default Filters;
