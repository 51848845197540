import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import {
  deleteDepositBankAccount,
  fetchDepositBankAccount,
  updateDepositBankAccount,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import accountNumberMaskInput from "../../../components/TextMaskInputComponents/accountNumberMaskInput";
import {
  bankAccountTypes,
  catchError,
  resetError,
  rohiDBList,
  successHandler,
  translations,
} from "../../../utils";
import {
  deleteDepositBankAccount as deleteDBA,
  fetchDepositBankAccount as fetchDBA,
  updateDepositBankAccount as updateDBA,
  uploadDBAToRohi
} from "../../../utils/axios";
import FinancialInstitutionSelectInput from "../../FinancialInstitution/FinancialInstitutionSelectInput";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
import { Dialog, DialogContent, DialogActions, MenuItem } from "@material-ui/core";


const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "100%",
  },
});

interface IDepositBankAccountViewProps {
  depositBankAccounts: IDepositBankAccount[];
  depositBankAccountId: string;
  classes: { [key: string]: string  };
  financialInstitutions: IInstitution[];
  participants: IParticipant[];
  user: IUser;
  changeSection(sectionNumber: number): void;
  deleteDepositBankAccount(uniqueId: string): IAction<IDepositBankAccount[]>;
  fetchDepositBankAccount(
    depositBankAccount: IDepositBankAccount
  ): IAction<IDepositBankAccount[]>;
  updateDepositBankAccount(
    depositBankAccount: IDepositBankAccount
  ): IAction<IDepositBankAccount[]>;
}

interface IDepositBankAccountViewState {
  [key: string]: string | string[] | number | boolean | Blob;

  accountType: string;
  accountTypeError: string;

  bank: string[];
  bankError: string;

  dialog: boolean;
  dialogTitle: string;

  errorMessage: string;

  currencyType: string;
  currencyTypeError: string;

  ledgerAccount: string;
  ledgerAccountError: string;

  isMainAccount: boolean;
  isMainAccountError: string;

  isPayrollAccount: boolean;
  isPayrollAccountError: string;

  loading: boolean;

  name: string;
  nameError: string;

  number: string;
  numberError: string;

  owner: string[];
  ownerError: string;

  uniqueId: string;
  
  DBA: string[];
  rohiDB: string;
  rohiDialog: boolean;
}
class DepositBankAccountView extends React.Component<
  IDepositBankAccountViewProps,
  IDepositBankAccountViewState
> {
  public state: IDepositBankAccountViewState = {
    accountType: "cash",
    accountTypeError: "",
    bank: [],
    bankError: "",
    currencyType: "DOP",
    currencyTypeError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    isMainAccount: false,
    isMainAccountError: "",
    isPayrollAccount: false,
    isPayrollAccountError: "",
    ledgerAccount: "",
    ledgerAccountError: "",
    loading: false,
    name: "",
    nameError: "",
    number: "",
    numberError: "",
    owner: [],
    ownerError: "",
    DBA: [],
    uniqueId: "",
    rohiDB: "barcelona",
    rohiDialog: false,
  };
  constructor(props: IDepositBankAccountViewProps) {
    super(props);

    this.handleAddDepositBank = this.handleAddDepositBank.bind(this);
    this.handleAddParticipant = this.handleAddParticipant.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckInputChange = this.handleCheckInputChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteDepositBank = this.handleDeleteDepositBank.bind(this);
    this.handleDeleteParticipant = this.handleDeleteParticipant.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRohiSubmit = this.handleRohiSubmit.bind(this);
    this.handleRohiDialog = this.handleRohiDialog.bind(this);
  }

  public handleCheckInputChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public handleAddDepositBank(id: string) {
    this.setState({ bank: [id] });
  }
  public handleDeleteDepositBank(id: string) {
    this.setState({ bank: [] });
  }
  public handleAddParticipant(id: string) {
    this.setState({ owner: [id] });
  }
  public handleDeleteParticipant(id: string) {
    this.setState({ owner: [] });
  }
  public componentDidMount() {
    this.setState({ loading: true });
    fetchDBA(this.props.user.token, this.props.depositBankAccountId)
      .then((res) => {
        const curDepositBankAccount = res.data as IDepositBankAccount;
        this.setState({
          accountType: curDepositBankAccount.accountType || "cash",
          bank: [curDepositBankAccount.bank || ""],
          currencyType: curDepositBankAccount.currencyType || "",
          isMainAccount: curDepositBankAccount.isMainAccount || false,
          isPayrollAccount: curDepositBankAccount.isPayrollAccount || false,
          ledgerAccount: curDepositBankAccount.ledgerAccount || "",
          loading: false,
          name: curDepositBankAccount.name || "",
          number: curDepositBankAccount.number || "0",
          owner: curDepositBankAccount.owner
            ? [curDepositBankAccount.owner]
            : [],
          uniqueId: curDepositBankAccount.uniqueId || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    const depositBankAccount: IDepositBankAccount = {
      accountType: this.state.accountType,
      bank: this.state.bank[0] || "",
      currencyType: this.state.currencyType,
      isMainAccount: this.state.isMainAccount,
      isPayrollAccount: this.state.isPayrollAccount,
      ledgerAccount: this.state.ledgerAccount,
      number: this.state.number.replace("-", ""),
      owner: this.state.owner[0] || "",
    };

    this.setState({ loading: true });

    updateDBA(this.props.user.token, this.state.uniqueId, depositBankAccount)
      .then((res) => {
        this.props.updateDepositBankAccount(res.data as IDepositBankAccount);
        successHandler(res, this, translations.DEPOSIT_ACCOUNT);
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }
  public handleRohiSubmit(db: string) {
    resetError(this);

    this.setState({ loading: true });

    uploadDBAToRohi(this.props.user.token, this.state.uniqueId, db)
      .then((res) => {
        successHandler(res, this, translations.DEPOSIT_ACCOUNT);
        this.handleRohiDialog()
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
}
  public handleDelete() {
    if (confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });

      deleteDBA(this.props.user.token, this.props.depositBankAccountId)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteDepositBankAccount(this.props.depositBankAccountId);
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public handleRohiDialog() {
    this.setState({ rohiDialog: !this.state.rohiDialog });
  }
  public render() {
    const { classes } = this.props;
    const { rohiDialog } = this.state

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Dialog open={rohiDialog} onClose={this.handleRohiDialog}>
          <DialogContent>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="rohiDB" shrink={true}>
                {"Favor elegir una Base de Datos"}
              </InputLabel>
              <Select
                value={this.state.rohiDB}
                onChange={this.handleSelectChange("rohiDB")}
                error={this.state.rohiDBError !== ""}
                inputProps={{
                  id: "rohiDB",
                  name: "rohiDB",
                }}
              >
                {rohiDBList.map((item, indx) => {
                  return (
                    <MenuItem key={indx} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRohiDialog} className={classes.button}>
              {translations.CANCEL}
            </Button>
            <Button
              onClick={() => this.handleRohiSubmit(this.state.rohiDB)}
              className={classes.button}
            >
              {translations.SAVE}
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.DEPOSIT_ACCOUNTS}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {translations.PARTICIPANT}
              </Typography>
              <div style={{ width: "100%" }}>
                <ParticipantSelectInput
                  addParticipant={this.handleAddParticipant}
                  deleteParticipant={this.handleDeleteParticipant}
                  selectedParticipantId={this.state.owner}
                />
              </div>
              <p style={{ color: "red" }}>{this.state.ownerError}</p>
              <br />
              <Typography variant="h6" className={classes.checkboxLabel}>
                {translations.BANKS}
              </Typography>
              <div style={{ width: "50%" }}>
                <FinancialInstitutionSelectInput
                  addInstitution={this.handleAddDepositBank}
                  deleteInstituion={this.handleDeleteDepositBank}
                  selectedInstitutionsId={this.state.bank}
                />
              </div>
              <p style={{ color: "red" }}>{this.state.bankError}</p>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="accountType" shrink={true}>
                  {translations.ACCOUNT_TYPE}
                </InputLabel>
                <Select
                  value={this.state.accountType}
                  onChange={this.handleSelectChange("accountType")}
                  style={{ width: 250 }}
                  required={true}
                  native={true}
                  error={this.state.accountTypeError !== ""}
                  inputProps={{
                    id: "accountType",
                    name: "accountType",
                  }}
                >
                   {bankAccountTypes.map((opt, key)=>{
                    return <option key={key} value={opt.value}>{opt.label}</option>
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="currencyType" shrink={true}>
                  {translations.CURRENCY}
                </InputLabel>
                <Select
                  value={this.state.currencyType}
                  onChange={this.handleSelectChange("currencyType")}
                  style={{ width: 250 }}
                  required={true}
                  native={true}
                  error={this.state.currencyTypeError !== ""}
                  inputProps={{
                    id: "currencyType",
                    name: "currencyType",
                  }}
                >
                  <option value="DOP">{translations.DOP}</option>
                  <option value="USD">{translations.USD}</option>
                </Select>
              </FormControl>
              <TextField
                label={translations.ACCOUNT_NUMBER}
                className={classes.textField}
                value={this.state.number}
                onChange={this.handleChange("number")}
                disabled={true}
                error={this.state.numberError !== ""}
                helperText={this.state.numberError}
                InputProps={{
                  inputComponent: accountNumberMaskInput,
                }}
                InputLabelProps={{ style: styles.checkboxLabel, shrink: true }}
              />
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="ledgerAccount" shrink={true}>
                  {translations.LEDGER_ACCOUNT}
                </InputLabel>
                <Select
                  value={this.state.ledgerAccount}
                  onChange={this.handleSelectChange("ledgerAccount")}
                  required={true}
                  style={{ width: 250 }}
                  native={true}
                  error={this.state.ledgerAccountError !== ""}
                  inputProps={{
                    id: "ledgerAccount",
                    name: "ledgerAccount",
                  }}
                >
                  <option value="accountList">Lista catalogo de cuentas</option>
                </Select>
              </FormControl>
              <br />
              <br />
              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={this.state.name}
                disabled={true}
                error={this.state.nameError !== ""}
                helperText={this.state.nameError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isMainAccount}
                    onChange={this.handleCheckInputChange("isMainAccount")}
                    value="isMainAccount"
                  />
                }
                label={translations.IS_MAIN_ACCOUNT}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isPayrollAccount}
                    onChange={this.handleCheckInputChange("isPayrollAccount")}
                    value="isPayrollAccount"
                  />
                }
                label={translations.IS_PAYROLL_ACCOUNT}
              />

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                {this.state.uniqueId ? (
                  <Button
                    onClick={this.handleRohiDialog}
                    variant="contained"
                    className={classes.greenButton}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <SaveIcon />
                    &nbsp; Enviar a Rohi
                  </Button>
                ) : null}
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    financialInstitutions: state.financialInstitutions,
    participants: state.participants,
    depositBankAccounts: state.depositBankAccounts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteDepositBankAccount,
    fetchDepositBankAccount,
    updateDepositBankAccount,
  })(DepositBankAccountView)
);
