import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Fab from "@material-ui/core/Fab";
import { createStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import { fetchOutlayCashes } from "../../../actions";
import DefaultSection from "../../../components/DefaultSection";
import Loading from "../../../components/Loading";
import { fetchOutlayCashes as fetchAll } from "../../../utils/axios";
import OutlayCashForm from "../OutlayCashForm";
import OutlayCashSearchSection from "../OutlayCashSearchSection";
import OutlayCashView from "../OutlayCashView";

const styles: any = createStyles({
  addButton: {
    backgroundColor: green[500],
    bottom: 10,
    color: "white",
    position: "fixed",
    right: 10,
  },
  rightSide: {
    alignItems: "center",
    border: "1px solid transparent",
    boxSizing: "border-box",
    display: "block",
    height: "100%",
    justifyContent: "center",
    marginLeft: 280,
    position: "relative",
  },
  root: {
    boxSizing: "border-box",
    height: "100%",
    margin: 0,
    padding: 0,
  },
});

interface IOutlayCashSectionProps {
  classes: {
    addButton: string;
    rightSide: string;
    root: string;
  };

  outlayCashes: IOutlayCash[];
  user: IUser;
  fetchOutlayCashes(outlayCashes: IOutlayCash[]): IAction<IOutlayCash[]>;
}
interface IOutlayCashSectionState {
  loading: boolean;
  selectedId: string | undefined;
  selectedSection: number;
}
class OutlayCashSection extends React.Component<
  IOutlayCashSectionProps,
  IOutlayCashSectionState
> {
  public state: IOutlayCashSectionState = {
    loading: false,
    selectedId: undefined,
    selectedSection: 0,
  };
  constructor(props: IOutlayCashSectionProps) {
    super(props);

    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);
    this.advanceSearch = this.advanceSearch.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  public componentDidMount() {
    this.setState({ loading: true });
    fetchAll({ token: this.props.user.token, limit: 20, offset: 0 })
      .then((res) => {
        this.props.fetchOutlayCashes(
          (res.data.results ? res.data.results : res.data) as IOutlayCash[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleChangeSection(sectionNumber: number) {
    this.setState({ selectedSection: sectionNumber });
  }
  public handleClickItem(id: string) {
    this.setState(
      {
        selectedId: id,
        selectedSection: 1,
      },
      () => {
        this.setState({ selectedSection: 2 });
      }
    );
  }
  public handleAddClick() {
    this.setState({
      selectedSection: 3,
    });
  }
  public advanceSearch(params: IFetchOutlayCashParams) {
    this.setState({ loading: true });
    fetchAll(params)
      .then((res) => {
        this.props.fetchOutlayCashes(
          (res.data.results ? res.data.results : res.data) as IOutlayCash[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public fetchData(params: IFetchOutlayCashParams) {
    this.setState({ loading: true });
    fetchAll(params)
      .then((res) => {
        this.props.fetchOutlayCashes(
          (res.data.results ? res.data.results : res.data) as IOutlayCash[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public render() {
    const { classes } = this.props;
    const { loading, selectedId, selectedSection } = this.state;
    return (
      <div className={classes.root}>
        <OutlayCashSearchSection
          advanceSearch={this.advanceSearch}
          loading={loading}
          handleClick={this.handleClickItem}
          fetchData={this.fetchData}
          pagination={true}
        />
        <div className={classes.rightSide}>
          {selectedSection === 0 ? (
            <DefaultSection
              message={translations.OUTLAY_CASH_DEFAULT_SECTION_MESSAGE}
              key={0}
            />
          ) : null}
          {selectedSection === 1 ? (
            <Loading message={translations.LOADING} key={1} />
          ) : null}
          {selectedSection === 2 ? (
            <OutlayCashView
              changeSection={this.handleChangeSection}
              outlayCashId={selectedId ? selectedId : ""}
            />
          ) : null}
          {selectedSection === 3 ? (
            <OutlayCashForm changeSection={this.handleChangeSection} />
          ) : null}
          {selectedSection !== 3 ? (
            <Fab className={classes.addButton} onClick={this.handleAddClick}>
              <AddIcon />
            </Fab>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    outlayCashes: state.outlayCashes,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchOutlayCashes })(OutlayCashSection)
);
