import React, { useState } from "react";
import { connect } from "react-redux";

import {
  Paper,
  Typography,
  IconButton,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";

import { Settings } from "@material-ui/icons";

import { GeneralTable } from "../../../components/SharedComponents/Table";
import TableContainer from "@material-ui/core/TableContainer";

import { TextFieldComponent } from "./../../../components/SharedComponents/Fields";
import { green } from "@material-ui/core/colors";

function TableToolbar(props) {
  const handleChange = (key) => {
    return (event) => {
      if (key === "accountNumber") {
        props.setProductNumber(event.currentTarget.value);

        const filteredObj = props.investmentCertificateIntact.filter(
          (item) =>
            (event.currentTarget.value
              ? String(item.accountNumber).includes(event.currentTarget.value)
              : true) ||
            (event.currentTarget.value
              ? String(item.accountType).includes(
                  event.currentTarget.value.toUpperCase()
                )
              : true)
        );

        if (event.currentTarget.value === "") {
          props.setInvestmentCertificate(props.investmentCertificateIntact);
        } else {
          props.setInvestmentCertificate(filteredObj);
        }
      }
    };
  };

  return (
    <Toolbar>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          className="section-header"
          style={{ fontWeight: "bold", paddingLeft: 16 }}
        >
          {props.title}
        </Typography>
        <TextFieldComponent
          xs={4}
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Buscar"
          fieldID="requestNumber"
          fieldClass="fields"
          fieldVariant="outlined"
          margin="dense"
          fullWidth
          value={props.productNumber}
          onChange={handleChange("accountNumber")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Settings />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </Toolbar>
  );
}

function InvestmentCertificate(props) {
  const [productNumber, setProductNumber] = useState("");

  const actions = (row) => {
    return (
      <>
        <div style={{ alignItems: "center" }}>
          <IconButton onClick={() => props.handleModal(row)}>
            <Settings style={{ fontSize: 22, color: green[500] }} />
          </IconButton>
        </div>
      </>
    );
  };

  const setCurrency = (value) => {
    let data = parseFloat(value).toFixed(2);
    return Number(data).toLocaleString("en-US", {
      style: "currency",
      currency: "DOP",
    });
  };

  const columnsInvestmentCertificate = [
    {
      id: "accountNumber",
      label: "Número cuenta".toUpperCase(),
      minWidth: 200,
      align: "center",
    },
    {
      id: "creationDate",
      label: "Fecha apertura".toUpperCase(),
      minWidth: 200,
      align: "center",
    },
    {
      id: "accountType",
      label: "Tipo cuenta".toUpperCase(),
      minWidth: 200,
      align: "center",
    },
    {
      id: "accountBalance",
      label: "Saldo".toUpperCase(),
      minWidth: 200,
      align: "center",
      element: (row) => setCurrency(row.accountBalance),
    },
    {
      id: "apertureBalance",
      label: "Monto apertura".toUpperCase(),
      minWidth: 200,
      align: "center",
      element: (row) => setCurrency(row.apertureBalance),
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      element: actions,
    },
  ];

  const data = props.tableData.filter((item) => item.accountBalance > 0);

  return (
    <>
      <Paper>
        <div style={{ width: "100%" }}>
          <TableContainer style={{ width: "100%" }}>
            <GeneralTable
              columns={columnsInvestmentCertificate}
              data={data}
              toolbar={
                <TableToolbar
                  title={"CERTIFICADOS DE INVERSION"}
                  productNumber={productNumber}
                  setProductNumber={setProductNumber}
                  investmentCertificateIntact={
                    props.investmentCertificateIntact
                  }
                  setInvestmentCertificate={props.setInvestmentCertificate}
                />
              }
            />
          </TableContainer>
        </div>
      </Paper>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(InvestmentCertificate);
