
import { PARTICIPANT_TYPES } from '../actions';

export default function(state: IParticipant[] = [], action: IAction<IParticipant[]>): IParticipant[] {
    switch(action.type) {
        case PARTICIPANT_TYPES.FETCH_ALL:
            return action.payload || [];
        case PARTICIPANT_TYPES.FETCH:
            return state.map(participant => (action.payload !== undefined && participant.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: participant);
        case PARTICIPANT_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case PARTICIPANT_TYPES.UPDATE:
            return state.map(participant => (action.payload !== undefined && participant.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : participant);
        case PARTICIPANT_TYPES.DELETE:
            return state.filter(participant => action.payload !== undefined && participant.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}