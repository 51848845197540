import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, FormControl, IconButton } from '@material-ui/core';
import { ArrowBack, Description } from '@material-ui/icons';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NoRowsOverlay } from './../../../components/SharedComponents/Table';
import LoadingDialog from '../../../components/SharedComponents/LoadingScreen';

import { catchError } from '../../../utils';
import { deleteAct } from "../../../actions";
import {
    fetchAct, fetchTicket, printApprobationAct
} from '../../../utils/axios';
import {
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Toolbar
} from '@material-ui/core'
import TicketManagementView from '../../TicketManagement/View'
import CompanyRepresentativeView from 'src/containers/CompanyRepresentative/CompanyRepresentativeView/index.js';
import ClientView from 'src/containers/Client/ClientView/index.js';
import AmortizationTable from "../../../components/Calculators/AmortizationTable";
import { errorHandling } from "../../../components/SharedComponents/CustomHooks";
import { ErrorDialog } from '../../../components/SharedComponents/Dialogs';

const styles = createStyles({
    formControl: {
        marginTop: 20,
        width: '100%'
    },
    deleteButton: {
        backgroundColor: red[500],
        color: "white",
    },
    paper: {
        padding: 20
    },
    root: {
        height: '90vh',
        overflowY: 'auto',
        padding: 20,
    }
});


function ActView(props) {

    const [tableData, setTableData] = useState(props.data);
    const [simpleDialog, setSimpleDialog] = useState({
        name: "",
        data: null,
        callback: null,
    });
    const [loading, setLoading] = useState(false);
    const [formDialog, setFormDialog] = useState(false);
    const [participant, setParticipant] = useState('');
    const [actType, setActType] = useState('');
    const [quotationUId, setQuotationUId] = useState('');
    const [participantType, setParticipantType] = useState('');
    const [ticket, setTicket] = useState([]);
    const [openId, setOpenId] = useState('');

    const getActs = () => {
        setLoading(true);
        const endpoint = fetchAct(props.user.token, props.actId);
        endpoint.then(response => {
            setTableData(response.data.type === "loan_approbation" ? response.data.loanApprobationDetail : response.data.approbationDetail);
            setActType(response.data.type)
            setLoading(false);
        }).catch(errors => {
            console.log(errors)
            // setLoading(false);
            // catchError(errors, this);
        });
    }
    const getTicket = (ticketUniqueId) => {
        const endpoint = fetchTicket(props.user.token, ticketUniqueId);
        endpoint.then(response => {
            setTicket(response.data)
            setOpenId('Ticket')
            openFormDialog()
        }).catch(errors => {
            // catchError(errors, this);
            console.log(errors);
        });
    }

    const getParticipant = (participant, participantType) => {
        setParticipant(participant)
        setOpenId('Participant')
        setParticipantType(participantType)
        openFormDialog()
    }

    const getQuotationId = (quotation) => {
        setOpenId('Quotation')
        setQuotationUId(quotation)
        openFormDialog()
    }

    const openFormDialog = () => {
        setFormDialog(true)
    }
    const closeFormDialog = () => {
        setFormDialog(false)
    }
    const emptyFunction = () => {
        return null;
    }

    const generateTemplate = (uniqueId) => {
        setLoading(true);
        const endpoint = printApprobationAct(props.user.token, uniqueId);
        endpoint.then(response => {
            const file = new Blob([response.data], { type: "text/html" });
            const fileURL = URL.createObjectURL(file);
            const NewWindow = window.open();
            NewWindow.location.href = fileURL;
            setLoading(false);
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const handleToggleSimpleDialog = (dialog, msg, data) => {
        const obj = { name: dialog ? dialog : "", msg: msg, data: data };
        setSimpleDialog({ ...obj });
    };


    useEffect(() => {
        getActs();
    }, []);

    const { classes } = props;
    return (
        <form className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h3" style={{ display: 'inline' }}>{'Actas'}</Typography>
                {loading ?
                    <LoadingDialog open={loading} />
                    : <div>
                        <FormControl className={classes.formControl} >
                            <TableContainer component={Paper}>
                                {actType === "loan_approbation" ?
                                    <Toolbar>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Button
                                                variant="contained"
                                                className={"action-method-btn save"}
                                                style={{ marginLeft: ".8rem", fontSize: "10px" }}
                                                disableElevation
                                                size="small"
                                                onClick={() => generateTemplate(props.actId)}
                                            >
                                                <Description
                                                    style={{ fontSize: 15, color: "#fff", margin: "0 .5rem .1rem 0" }}
                                                />
                                                {"Generar Plantilla"}
                                            </Button>
                                        </div>
                                    </Toolbar> : null
                                }
                                <Table aria-label="collapsible table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Responsable de Aprobación</TableCell>
                                            <TableCell align="center">Numero de Ticket</TableCell>
                                            <TableCell align="center">Nombre del Participante</TableCell>
                                            <TableCell align="center">Id de Participante</TableCell>
                                            {actType === "loan_approbation" ?
                                                <TableCell align="center">Numero de Cotización</TableCell> : null
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData ? tableData.map((row) => (
                                            <TableRow
                                                key={row.uniqueId}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">
                                                    {row.responsibleForApproval}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {
                                                        <Button onClick={() => getTicket(row.ticket)}>
                                                            {row.ticketRefCode}
                                                        </Button>
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    {
                                                        <Button onClick={() => getParticipant(row.participant, row.partType)}>
                                                            {row.participantName}
                                                        </Button>
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    {
                                                        <Button onClick={() => getParticipant(row.participant, row.partType)}>
                                                            {row.participantIdNumber}
                                                        </Button>
                                                    }
                                                </TableCell>
                                                {actType === "loan_approbation" ?
                                                    <TableCell align="center">
                                                        {
                                                            <Button onClick={() => getQuotationId(row.quotationId)}>
                                                                {row.quotationRefCode}
                                                            </Button>
                                                        }
                                                    </TableCell> : null
                                                }
                                            </TableRow>
                                        )) : <NoRowsOverlay colSpan={2} />}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </FormControl>
                    </div>
                }
            </Paper>
            <Dialog
                open={formDialog}
                fullScreen={true}
                onClose={closeFormDialog}
            >
                <DialogActions>
                    <IconButton
                        style={{ backgroundColor: green[500], color: "white" }}
                        onClick={closeFormDialog}
                    >
                        <ArrowBack />
                    </IconButton>
                </DialogActions>
                <DialogContent style={{ position: "relative" }}>
                    {openId === 'Ticket' ? <TicketManagementView id={ticket} />
                        : openId === 'Quotation' ? <AmortizationTable changeSection={emptyFunction} quotationId={quotationUId} />
                            : openId === 'Participant' ?
                                participantType === 'company representative' ? < CompanyRepresentativeView changeSection={emptyFunction} companyRepresentativeId={participant} />
                                    : participantType === 'client' ? <ClientView changeSection={emptyFunction} clientId={participant} />
                                        : null : null
                    }
                </DialogContent>
            </Dialog>
            {simpleDialog.name === "error-dialog" ? (
                <ErrorDialog
                    open={true}
                    handleToggle={handleToggleSimpleDialog}
                    data={simpleDialog.msg}
                />
            ) : null}

        </form>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps, { deleteAct })(ActView)
);