import * as React from 'react';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';

interface IAlertDialogProps {
    message: string;
    open: boolean;
    title: string;
    handleClose(): void;
}

const AlertDialog = (props: IAlertDialogProps) => {
    const { handleClose, message, open, title } = props;
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}  
            >
                <DialogTitle >{title}</DialogTitle>   
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleClose} 
                        autoFocus={true}
                        style={{backgroundColor: green[500], color: 'white' }}
                        >
                            <DoneIcon  color="inherit"/>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog;