import { AxiosError } from "axios";

import * as React from "react";
import { connect } from "react-redux";
import FileUploadSelectInput from "src/containers/FileUpload/FileUploadSelectInput";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CheckBox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { deleteClient, fetchClient, updateClient } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import AvailabilityCalculator from "../../../components/Calculators/AvailabilityCalculator";
import Loading from "../../../components/Loading";
import attorneyNumberMaskInput from "../../../components/TextMaskInputComponents/attorneyNumberMaskInput";
import idNumberMaskInput from "../../../components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "../../../components/TextMaskInputComponents/passportMaskInput";
import phoneMaskInput from "../../../components/TextMaskInputComponents/phoneWithOneMaskInput";
import rncMaskInput from "../../../components/TextMaskInputComponents/rncMaskInput";
import Grid from "@material-ui/core/Grid";
import {
  IDENTIFICATION_TYPE,
  affiliateState,
  affiliateType,
  catchError,
  civilStatus,
  educationLevel,
  gender,
  nationalitiesToOptions,
  prefix,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
  dwellingTypes,
  getRiskMatrix,
  toOptions,
  rohiDBList,
  transactionAmount,
  category,
  distributionChannel,
  personTypes,
  creditMethod,
  participantType,
  pepRelationChoices,
} from "../../../utils";
import {
  deleteParticipant,
  fetchAssignees,
  fetchBusinessRepresentatives,
  fetchNationalities,
  fetchParticipant,
  fetchProfessions,
  generateRiskMatrix,
  http,
  resetSecurityQuestions,
  submitRohiParticipant,
  updateParticipant,
} from "../../../utils/axios";
import FinancialProfile from "../../FinancialProfile/index";
import ClientDocuments from "../../../components/ClientDocuments";
import WorkProfile from "../../WorkProfile";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Address from "../../../components/Address/Address";
// import moneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import JwtDecode from "jwt-decode";
import _, { lt } from "lodash";
import {
  AccountBalance,
  ArrowBack,
  ArrowForwardIos,
  Description,
  Folder,
  LocalAtm,
  Timeline,
  Work,
} from "@material-ui/icons";
import { Restore } from "@material-ui/icons";
import { orange } from "@material-ui/core/colors";
import FilesTable from "./filesTable";
import { AutocompleteField } from "src/components/SharedComponents/Fields";

const styles = createStyles({
  checkboxLabel: {
    color: green[500],
  },
  autocomplete: {
    height: '50px'
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  fileText: {
    fontSize: 14,
    position: "absolute",
    right: 32,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  yellowButton: {
    backgroundColor: orange[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    overflowY: "auto",
    padding: 20,
    paddingBottom: 64,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "25ch",
  },
  div: {
    display: "flex",
    flexWrap: "wrap",
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    color: "#4caf50",
    margin: "0.8rem 0 0 ",
    textTransform: "uppercase",
  },
  cardAction: {
    backgroundColor: "white",
    margin: 1,
    borderRadius: "0 0 5px 5px",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 17,
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    color: "White",
  },
});

class ClientsDetailView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attorneyNumber: 0,
      additionalPhone: "",
      additionalPhoneError: "",
      affiliateState: "not_affiliate",
      affiliateStateError: "",
      affiliateType: "service_user_affiliate",
      affiliateTypeError: "",
      birthDate: "",
      birthDateError: "",
      category: "",
      cellPhone: "",
      cellPhoneError: "",
      city: "",
      cityError: "",
      civilStatus: "single",
      civilStatusError: "",
      clientCF: "",
      clientIF: "",
      clientW: "",
      country: "",
      countryError: "",
      dialog: false,
      dialogTitle: "",
      distributionChannel: "",
      educationLevel: "none",
      educationLevelError: "",
      nickName: "",
      nickNameError: "",
      email: "",
      emailError: "",
      errorMessage: "",
      fullName: "",
      fullNameError: "",
      gender: "m",
      genderError: "",
      homePhone: "",
      homePhoneError: "",
      cooperativeEntryDate: new Date().toISOString().split("T")[0],
      cooperativeEntryDateError: "",
      identificationNumber: "",
      identificationNumberError: "",
      identificationType: "national",
      identificationTypeError: "",
      identificationExpirationDate: "",
      identificationExpirationDateError: "",
      loading: false,
      nationality: { label: "", value: "" },
      nationalityError: "",
      nationalityOptions: [],
      participantType: "",
      pepPosition: "",
      pepOcupationDate: "",
      pepWorkInstitution: "",
      pepRelation: "",
      personKind: "",
      prefix: "mister",
      prefixError: "",
      profession: { label: "", value: "" },
      professionOptions: [],
      reference: "",
      referenceError: "",
      region: "",
      regionError: "",
      rohiDialog: false,
      rohiDB: "barcelona",
      sector: "",
      sectorError: "",
      secondCitizenship: "",
      secondCitizenshipError: "",
      secondCitizenshipId: "",
      secondCitizenshipIdError: "",
      state: "",
      stateError: "",
      transactionalBehavior: "",
      transactionAmount: "",
      uniqueId: "",
      workPhone: "",
      workPhoneError: "",
      workPhoneExtension: "",
      workPhoneExtensionError: "",
      vehicleColor: "",
      vehicleColorError: "",
      vehicleBrand: "",
      vehicleBrandError: "",
      vehicleModel: "",
      vehicleModelError: "",
      vehicleYear: 0,
      vehicleYearError: "",
      hasDebtorAVehicle: false,
      hasDebtorAVehicleError: "",
      hasDebtorVisa: false,
      visaNumber: 0,
      vehicleFinancialStatus: "financed",
      vehicleFinancialStatusError: "",
      // houseMonthlyPaymentError: "",
      // houseMonthlyPayment: 0,
      // houseMonthlyPaymentCurrency: "DOP",
      // houseMonthlyPaymentCurrencyError: "",
      dwellingType: "",
      rohiId: 0,
      rohiIeId: 0,
      rohiMfsId: 0,
      documentOpen: false,
      businessRepresentative: "",
      users: [],
      currentUser: {},
      is_administration: false,
      hasAccessToCoopvirtual: false,
      password: "",
      passwordConfirm: "",
      passwordError: "",
      passwordConfirmError: "",
      currEmail: "",
      dialogs: {
        workProfile: false,
        financialProfile: false,
        availabilityCalc: false,
        documents: false,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.fetchClient = this.fetchClient.bind(this);
    this.handleAddProfession = this.handleAddProfession.bind(this);
    this.handleDeleteProfession = this.handleDeleteProfession.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.openLoading = this.openLoading.bind(this);
    this.documentOpen = this.documentOpen.bind(this);
    this.handleRohiSubmit = this.handleRohiSubmit.bind(this);
    this.handleRohiDialog = this.handleRohiDialog.bind(this);
    this.handleDialogToggle = this.handleDialogToggle.bind(this);
  }

  //this manages the dialogs for docs, workProfile, financialProfile and AvailabilityCalc
  handleDialogToggle = (dialogName, isOpen) => {
    this.setState((prevState) => ({
      dialogs: {
        ...prevState.dialogs,
        [dialogName]: isOpen,
      },
    }));
  };

  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || 0 });
    };
  }

  handleRohiSubmit(db) {
    this.setState({ loading: true });

    const endpoint = submitRohiParticipant(
      this.props.user.token,
      this.props.clientId,
      db
    );
    endpoint
      .then((res) => {
        successHandler(res, this, translations.PARTICIPANT);
        this.handleRohiDialog();
      })
      .catch((errors) => {
        catchError(errors, this);
      })
      .finally(() => this.setState({ loading: false }));
  }

  handleRohiDialog() {
    this.setState({ rohiDialog: !this.state.rohiDialog });
  }

  handleCheckBoxChange(event, key) {
    this.setState({ [key]: event.target.checked });
  }

  handleAddProfession(id) {
    this.setState({ profession: [id] });
  }

  handleDeleteProfession() {
    this.setState({ profession: [] });
  }

  handleReactSelectChange(nationality) {
    this.setState({ nationality });
  }

  toOptions(collection) {
    return collection.map((item) => ({
      debtor: item.debtor || item.debtors,
      label: item.title,
      value: item.uniqueId,
    }));
  }

  async componentDidMount() {
    if (this.props.clientId.length < 1) {
      return false;
    }
    const initialData = this.props.initialData;
    this.setState({
      nationalityOptions: initialData.nationalityOptions,
      professionOptions: initialData.professionOptions,
      currentUser: initialData.currentUser,
      users: initialData.users,
      is_administration: initialData.is_administration,
    });
    this.fetchClient();
  }

  fetchClient() {
    this.props.setLoading(true);
    fetchParticipant(this.props.user.token, this.props.clientId).then((res) => {
      const currClient = res.data;

      this.setState((prevState) => ({
        attorneyNumber: currClient.attorneyNumber || "",
        additionalPhone: currClient.additionalPhone || "",
        affiliateState: currClient.affiliateState || "",
        affiliateType: currClient.affiliateType || "",
        birthDate: currClient.birthDate || "",
        category: currClient.category || "",
        cellPhone: currClient.cellPhone || "",
        city:
          currClient.address !== undefined
            ? currClient.address?.city
            : "Santo Domingo",
        civilStatus: currClient.civilStatus || "single",
        clientCF: `crm/participant/${currClient.uniqueId}/upload_credit_file/`,
        clientIF: `crm/participant/${currClient.uniqueId}/upload_identification_file/`,
        clientW: `crm/participant/${currClient.uniqueId}/upload_warranty_file/`,
        country:
          currClient.address !== undefined
            ? currClient.address?.country
            : "República Dominicana",
        distributionChannel: currClient.distributionChannel || "",
        educationLevel: currClient.educationLevel || "",
        email: currClient.email || "",
        fullName: currClient.fullName || "",
        gender: currClient.gender || "m",
        homePhone: currClient.homePhone || "",
        cooperativeEntryDate: currClient.cooperativeEntryDate || "",
        identificationNumber: currClient.identificationNumber || "",
        identificationType: currClient.identificationType || "national",
        identificationExpirationDate:
          currClient.identificationExpirationDate || "",
        nationality: prevState.nationalityOptions.find(
          (n) => n.value === currClient.nationality
        ) || { label: "", value: "" },
        nickName: currClient.nickName || "",
        participantType: currClient.participantType || "",
        prefix: currClient.prefix || "mister",
        personKind: currClient.personKind || "",
        pepPosition: currClient.pepPosition || "",
        pepOcupationDate: currClient.pepOcupationDate || "",
        pepWorkInstitution: currClient.pepWorkInstitution || "",
        pepRelation: currClient.pepRelation || "",
        state:
          currClient.address !== undefined ? currClient.address?.state : "",
        region:
          currClient.address !== undefined ? currClient.address?.region : "",
        reference:
          currClient.address !== undefined ? currClient.address?.reference : "",
        sector:
          currClient.address !== undefined ? currClient.address?.sector : "",
        secondCitizenship: currClient.secondCitizenship || "",
        secondCitizenshipId: currClient.secondCitizenshipId || "",
        transactionalBehavior: currClient.transactionalBehavior || "",
        transactionAmount: currClient.transactionAmount || "",
        uniqueId: currClient.uniqueId || "",
        workPhone: currClient.workPhone || "",
        workPhoneExtension: currClient.workPhoneExtension || "",
        profession: prevState.professionOptions.find(
          (n) => n.value === currClient.profession
        ) || { label: "", value: "" },
        vehicleColor: currClient.vehicleColor || "",
        vehicleBrand: currClient.vehicleBrand || "",
        vehicleModel: currClient.vehicleModel || "",
        vehicleYear: currClient.vehicleYear || 0,
        hasDebtorAVehicle: currClient.hasDebtorAVehicle || false,
        hasDebtorVisa: currClient.hasDebtorVisa || false,
        visaNumber: currClient.visaNumber || 0,
        vehicleFinancialStatus: currClient.vehicleFinancialStatus || "financed",
        dwellingType: currClient.houseInfo || "",
        rohiId: currClient.rohiId || 0,
        rohiIeId: currClient.rohiIeId || 0,
        rohiMfsId: currClient.rohiMfsId || 0,
        businessRepresentative: currClient.businessRepresentative || "",
        hasAccessToCoopvirtual: currClient.hasAccessToCoopvirtual,
        currEmail: currClient.email,
      }));
      this.props.setLoading(false);
    });
  }

  handleResetQuestions = () => {
    resetSecurityQuestions(this.props.user.token, this.state.uniqueId)
      .then((res) => {
        this.setState({
          dialog: true,
          dialogTitle: "Éxito",
          errorMessage: res.data.message,
        });
      })
      .catch((error) => {
        // Verifica si hay una respuesta en el error
        if (error.response && error.response.data) {
          const { message } = error.response.data;
          console.error("Error al resetear las preguntas:", message);
          this.setState({
            dialog: true,
            dialogTitle: "Error",
            errorMessage: message,
          });
        } else {
          // Si no hay respuesta, muestra un mensaje genérico
          console.error("Error al resetear las preguntas:", error.message);
          this.setState({
            dialog: true,
            dialogTitle: "Error",
            errorMessage:
              "Hubo un problema al intentar reiniciar las preguntas de seguridad.",
          });
        }
      });
  };

  handleChange = (event, key) => {
    if (key === "password" || key === "passwordConfirm") {
      if (/^(\d{4}|\d{6})$/.test(event.target.value)) {
        this.setState({
          [key]: event.target.value,
          [key + "Error"]: "",
        });
      } else {
        this.setState({
          [key]: event.target.value,
          [key +
          "Error"]: "El pin debe ser numerico y de cuatro (4) caracteres.",
        });
      }
      return false;
    }
    this.setState({
      [key]: event.target.value,
      [key + "Error"]: "",
    });
  };

  handleSelectChange(key) {
    return (event) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }

  handleSelect(key, value) {
    this.setState({
      [key]: value,
    });
  }

  //for opening documents inside the documents dialog
  documentOpen(value) {
    this.setState({ documentOpen: value });
  }

  handleSubmit(event) {
    if (this.state.documentOpen) {
      return false;
    }
    event.preventDefault();
    let client = {
      address: {
        country: this.state.country,
        state: this.state.state,
        region: this.state.region,
        city: this.state.city,
        sector: titleCaseHandler(this.state.sector),
        reference: this.state.reference,
      },
      additionalPhone: this.state.additionalPhone,
      affiliateState: this.state.affiliateState,
      affiliateType: this.state.affiliateType,
      birthDate: this.state.birthDate,
      businessRepresentative: this.state.businessRepresentative,
      category: this.state.category,
      cellPhone: this.state.cellPhone,
      civilStatus: this.state.civilStatus,
      distributionChannel: this.state.distributionChannel,
      educationLevel: this.state.educationLevel,
      email: this.state.email,
      fullName: this.state.fullName,
      cooperativeEntryDate: this.state.cooperativeEntryDate,
      gender: this.state.gender,
      homePhone: this.state.homePhone,
      identificationNumber: this.state.identificationNumber.replace(/-/g, ""),
      identificationType: this.state.identificationType,
      nationality: this.state.nationality.value,
      participantType: this.state.participantType,
      pepPosition: this.state.pepPosition,
      pepOcupationDate: this.state.pepOcupationDate,
      pepWorkInstitution: this.state.pepWorkInstitution,
      pepRelation: this.state.pepRelation,
      personKind: this.state.personKind,
      prefix: this.state.prefix,
      profession: this.state.profession.value,
      workPhone: this.state.workPhone,
      workPhoneExtension: this.state.workPhoneExtension,
      transactionalBehavior: this.state.transactionalBehavior,
      transactionAmount: this.state.transactionAmount,
      vehicleColor: this.state.vehicleColor,
      vehicleBrand: this.state.vehicleBrand,
      vehicleModel: this.state.vehicleModel,
      vehicleYear: this.state.vehicleYear,
      hasDebtorAVehicle: this.state.hasDebtorAVehicle,
      hasDebtorVisa: this.state.hasDebtorVisa,
      visaNumber: this.state.visaNumber,
      vehicleFinancialStatus: this.state.vehicleFinancialStatus,
      houseInfo: this.state.dwellingType,
      hasAccessToCoopvirtual: this.state.hasAccessToCoopvirtual,
      currEmail: this.state.currEmail,
    };

    // Eliminar propiedades vacías
    const removeEmptyValues = (obj) => {
      Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === "object") {
          removeEmptyValues(obj[key]); // Recursión para objetos anidados
        } else if (obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    };

    client = removeEmptyValues(client);

    this.setState({ loading: true });
    updateParticipant(this.props.user.token, this.state.uniqueId, client)
      .then((res) => {
        const updatedClient = res.data.results ? res.data.results : res.data;
        this.props.updateClient(updatedClient);
        successHandler(res, this, translations.CLIENT);
        this.setState({
          password: "",
          passwordConfirm: "",
          currEmail: this.state.email,
        });
        this.props.fetchClients(updatedClient);
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  async handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });

      const { uniqueId } = this.state;
      const { token } = this.props.user;

      try {
        const response = await http.delete(
          `/crm/participant/${uniqueId}/delete_with_dependencies/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.setState({ loading: false });
        if (response.status === 200) {
          this.props.reloadClients()
          this.props.changeSelection();
        }
      } catch (error) {
        this.setState({ loading: false });
        console.error("error al eliminar participant: ", error);
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Hubo un error al eliminar el socio.",
        });
      }
    }
  }

  openLoading() {
    this.setState({ dialog: true });
  }

  handleDialogClose() {
    this.setState({ dialog: false });
  }

  getIdentificationInput(identificationType) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }

  render() {
    const { classes, clientId } = this.props;
    const state = this.state;
    const { dialogs } = this.state;
    const { workProfileObj } = this.props.client;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Dialog open={this.state.rohiDialog} onClose={this.handleRohiDialog}>
          <DialogContent>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="rohiDB" shrink={true}>
                {"Favor elegir una Base de Datos"}
              </InputLabel>
              <Select
                value={this.state.rohiDB}
                onChange={this.handleSelectChange("rohiDB")}
                inputProps={{
                  id: "rohiDB",
                  name: "rohiDB",
                }}
              >
                {rohiDBList.map((item, indx) => {
                  return (
                    <MenuItem key={indx} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleRohiDialog}
              className={classes.deleteButton}
            >
              {translations.CANCEL}
            </Button>
            <Button
              onClick={() => this.handleRohiSubmit(this.state.rohiDB)}
              className={classes.greenButton}
            >
              {translations.SAVE}
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className={classes.paper}>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Typography variant={"h6"} className={classes.sectionHeader}>
                Datos personales
              </Typography>
              <Divider style={{ margin: "0 0 2rem 0" }} />
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    label={"Rohi ID Barcelona"}
                    value={this.state.rohiId}
                    disabled={true}
                    fullWidth
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    label={"Rohi ID IE"}
                    value={this.state.rohiIeId}
                    disabled={true}
                    fullWidth
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    label={"Rohi ID MFS"}
                    value={this.state.rohiMfsId}
                    disabled={true}
                    fullWidth
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    required
                    disabled
                    fullWidth
                    variant="outlined"
                    label={translations.PARTICIPANT_TYPE}
                    value={this.state.participantType}
                    onChange={(event) =>
                      this.handleChange(event, "participantType")
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "participantType",
                      name: "participantType",
                    }}
                  >
                    {participantType.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    label={translations.NAME}
                    value={this.state.fullName}
                    onChange={(event) => this.handleChange(event, "fullName")}
                    fullWidth
                    required
                    error={this.state.fullNameError !== ""}
                    helperText={this.state.fullNameError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    inputProps={{
                      id: "identificationType",
                      name: "identificationType",
                    }}
                    select
                    required
                    fullWidth
                    variant="outlined"
                    error={this.state.identificationTypeError !== ""}
                    label={translations.IDENTIFICATION_TYPE}
                    value={this.state.identificationType}
                    onChange={(event) =>
                      this.handleChange(event, "identificationType")
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {IDENTIFICATION_TYPE.map((opt, indx) => (
                      <MenuItem key={indx} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label={translations.IDENTIFICATION}
                    value={this.state.identificationNumber}
                    onChange={(event) =>
                      this.handleChange(event, "identificationNumber")
                    }
                    required={true}
                    error={this.state.identificationNumberError !== ""}
                    helperText={this.state.identificationNumberError}
                    InputProps={this.getIdentificationInput(
                      this.state.identificationType
                    )}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.BIRTHDATE}
                    value={this.state.birthDate}
                    type="date"
                    onChange={(event) => this.handleChange(event, "birthDate")}
                    error={this.state.birthDateError !== ""}
                    helperText={this.state.birthDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    value={this.state.prefix}
                    onChange={(event) => this.handleChange(event, "prefix")}
                    required
                    select
                    fullWidth
                    variant="outlined"
                    error={this.state.prefixError !== ""}
                    label={translations.TITLE}
                    inputProps={{
                      id: "prefix",
                      name: "prefix",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {prefix.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <AutocompleteField
                  xs={3}
                  fullWidth
                  margin="none"
                  containerClass="field-container"
                  fieldClass="fields"
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldID="nationality"
                  fieldInnerLabel="Nacionalidad"
                  fieldVariant="outlined"
                  style={styles.autocomplete}
                  value={this.state.nationality}
                  handleChange={(event, value) =>
                    this.handleSelect("nationality", value)
                  }
                  options={this.state.nationalityOptions}
                  getOptionLabel={(option) => option.label}
                  limitTags={3}
                />
                <Grid item xs={3}>
                  <TextField
                    value={this.state.gender}
                    onChange={(event) => this.handleChange(event, "gender")}
                    error={this.state.genderError !== ""}
                    inputProps={{
                      id: "gender",
                      name: "gender",
                    }}
                    select
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.GENDER}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {gender.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    value={this.state.civilStatus}
                    onChange={(event) =>
                      this.handleChange(event, "civilStatus")
                    }
                    error={this.state.civilStatusError !== ""}
                    inputProps={{
                      id: "civilStatus",
                      name: "civilStatus",
                    }}
                    label={translations.CIVIL_STATUS}
                    variant="outlined"
                    select
                    required
                    fullWidth
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {civilStatus.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    label={translations.COOPERATIVE_ENTRY_DATE}
                    value={this.state.cooperativeEntryDate}
                    type="date"
                    onChange={(event) =>
                      this.handleChange(event, "cooperativeEntryDate")
                    }
                    error={this.state.cooperativeEntryDateError !== ""}
                    helperText={this.state.cooperativeEntryDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.AFFILIATE_STATE}
                    value={this.state.affiliateState}
                    onChange={(event) =>
                      this.handleChange(event, "affiliateState")
                    }
                    error={this.state.affiliateStateError !== ""}
                    inputProps={{
                      id: "affiliateState",
                      name: "affiliateState",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    disabled={!this.state.is_administration}
                  >
                    {affiliateState.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.AFFILIATE_TYPE}
                    value={this.state.affiliateType}
                    onChange={(event) =>
                      this.handleChange(event, "affiliateType")
                    }
                    error={this.state.affiliateTypeError !== ""}
                    inputProps={{
                      id: "affiliateType",
                      name: "affiliateType",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {affiliateType.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    required
                    variant="outlined"
                    label={translations.EDUCATION_LEVEL}
                    value={this.state.educationLevel}
                    onChange={(event) =>
                      this.handleChange(event, "educationLevel")
                    }
                    error={this.state.educationLevelError !== ""}
                    inputProps={{
                      id: "educationLevel",
                      name: "educationLevel",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    fullWidth
                  >
                    {educationLevel.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <AutocompleteField
                  xs={3}
                  fullWidth
                  margin="none"
                  containerClass="field-container"
                  fieldClass="fields"
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldID="profession"
                  fieldInnerLabel={translations.PROFESSION}
                  fieldVariant="outlined"
                  value={this.state.profession}
                  handleChange={(event, value) =>
                    this.handleSelect("profession", value)
                  }
                  options={this.state.professionOptions}
                  getOptionLabel={(option) => option.label}
                  limitTags={3}
                />
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.DWELLING_TYPE}
                    value={this.state.dwellingType}
                    onChange={(event) =>
                      this.handleChange(event, "dwellingType")
                    }
                    inputProps={{
                      name: "dwellingType",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {dwellingTypes.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.SECOND_NATIONALITY}
                    value={this.state.secondCitizenship || ""}
                    onChange={this.handleSelectChange("secondCitizenship")}
                    error={this.state.secondCitizenshipError !== ""}
                    helperText={this.state.secondCitizenshipError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.SECOND_NATIONALITY_ID}
                    value={this.state.secondCitizenshipId || ""}
                    onChange={this.handleSelectChange("secondCitizenshipId")}
                    error={this.state.secondCitizenshipIdError !== ""}
                    helperText={this.state.secondCitizenshipIdError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                {this.state.participantType == "attorney" && (
                  <Grid item xs={3}>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      label={translations.ATTORNEY_NUMBER}
                      value={this.state.attorneyNumber}
                      onChange={(event) =>
                        this.handleChange(event, "attorneyNumber")
                      }
                      InputProps={{
                        inputComponent: attorneyNumberMaskInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={styles.checkboxLabel}
                  >
                    <InputLabel id="businessRepresentative">
                      {"Representante de Negocios"}
                    </InputLabel>
                    <Select
                      value={this.state.businessRepresentative}
                      onChange={this.handleSelectChange(
                        "businessRepresentative"
                      )}
                      disabled={
                        state.currentUser.groups?.includes("EditaRepresentante")
                          ? false
                          : true
                      }
                      inputProps={{
                        id: "businessRepresentative",
                        name: "businessRepresentative",
                      }}
                    >
                      [
                      <MenuItem key={this.state.users.length + 1} value={null}>
                        {`------`}
                      </MenuItem>
                      ,
                      {this.state.users.map((opt, indx) => (
                        <MenuItem key={indx} value={opt.uniqueId}>
                          {`${opt.name}`}
                        </MenuItem>
                      ))}
                      ]
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label="Posee acceso a COOPVIRTUAL"
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.hasAccessToCoopvirtual}
                        onChange={(event) =>
                          this.handleCheckBoxChange(
                            event,
                            "hasAccessToCoopvirtual"
                          )
                        }
                      />
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label={translations.DEBTOR_VEHICLE}
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.hasDebtorAVehicle}
                        onChange={(event) =>
                          this.handleCheckBoxChange(event, "hasDebtorAVehicle")
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  display: this.state.hasDebtorAVehicle ? "block" : "none",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="model"
                      label={translations.VEHICLE_MODEL}
                      value={titleCaseHandler(this.state.vehicleModel)}
                      onChange={(event) =>
                        this.handleChange(event, "vehicleModel")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleModelError !== ""}
                      helperText={this.state.vehicleModelError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="brand"
                      label={translations.VEHICLE_BRAND}
                      value={titleCaseHandler(this.state.vehicleBrand)}
                      onChange={(event) =>
                        this.handleChange(event, "vehicleBrand")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleBrandError !== ""}
                      helperText={this.state.vehicleBrandError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      name="year"
                      variant="outlined"
                      label={translations.VEHICLE_YEAR}
                      value={this.state.vehicleYear}
                      onChange={(event) =>
                        this.handleChange(event, "vehicleYear")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleYearError !== ""}
                      helperText={this.state.vehicleYearError}
                      InputProps={{
                        inputComponent: attorneyNumberMaskInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      name="color"
                      variant="outlined"
                      label={translations.VEHICLE_COLOR}
                      value={titleCaseHandler(this.state.vehicleColor)}
                      onChange={(event) =>
                        this.handleChange(event, "vehicleColor")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleColorError !== ""}
                      helperText={this.state.vehicleColorError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label={translations.VEHICLE_FINANCIAL_STATUS}
                      value={this.state.vehicleFinancialStatus}
                      name="fin_status"
                      onChange={(event) =>
                        this.handleChange(event, "vehicleFinancialStatus")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleFinancialStatusError !== ""}
                      inputProps={{
                        id: "vehicleFinancialStatus",
                        name: "vehicleFinancialStatus",
                      }}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    >
                      <MenuItem value="financed" key={1}>
                        {translations.FINANCED}
                      </MenuItem>
                      <MenuItem value="paid" key={2}>
                        {translations.PAID}
                      </MenuItem>
                      <MenuItem value="not own" key={3}>
                        {translations.NOT_OWN}
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label={"El deudor posee visado?"}
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.hasDebtorVisa}
                        onChange={(event) =>
                          this.handleCheckBoxChange(event, "hasDebtorVisa")
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  display: this.state.hasDebtorVisa ? "block" : "none",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={"Número de visado"}
                      value={this.state.visaNumber}
                      onChange={this.handleSelectChange("visaNumber")}
                      required={this.state.hasDebtorVisa ? true : false}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                </Grid>
              </div>

              <Typography variant={"h6"} className={classes.sectionHeader}>
                Datos de contacto
              </Typography>
              <Divider style={{ margin: "0 0 2rem 0" }} />

              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.CELL_PHONE}
                    value={this.state.cellPhone}
                    onChange={this.handleSelectChange("cellPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.cellPhoneError !== ""}
                    helperText={this.state.cellPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.HOME_PHONE}
                    value={this.state.homePhone}
                    onChange={this.handleSelectChange("homePhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.homePhoneError !== ""}
                    helperText={this.state.homePhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.ADDITIONAL_PHONE}
                    value={this.state.additionalPhone}
                    onChange={this.handleSelectChange("additionalPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.additionalPhoneError !== ""}
                    helperText={this.state.additionalPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.EMAIL}
                    placeholder="mycompany@email.com"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleSelectChange("email")}
                    error={this.state.emailError !== ""}
                    helperText={this.state.emailError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.WORK_PHONE}
                    value={this.state.workPhone}
                    onChange={this.handleSelectChange("workPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.workPhoneError !== ""}
                    helperText={this.state.workPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.WORK_PHONE_EXTENSION}
                    value={this.state.workPhoneExtension}
                    onChange={this.handleSelectChange("workPhoneExtension")}
                    error={this.state.workPhoneExtensionError !== ""}
                    helperText={this.state.workPhoneExtensionError}
                    InputProps={{
                      inputComponent: attorneyNumberMaskInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>

              <Address
                handleSelect={this.handleSelect}
                country={this.state.country}
                title={"Datos de residencia"}
                countryError={this.state.countryError}
                reference={this.state.reference}
                referenceError={this.state.referenceError}
                state={this.state.state}
                stateError={this.state.stateError}
                city={this.state.city}
                cityError={this.state.cityError}
                region={this.state.region}
                regionError={this.state.regionError}
                sector={this.state.sector}
                sectorError={this.state.sectorError}
                handleChange={this.handleChange}
              />

              <Typography variant={"h6"} className={classes.sectionHeader}>
                Datos de cumplimiento
              </Typography>
              <Divider style={{ margin: "0 0 2rem 0" }} />

              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.TRANSACTIONAL_BEHAVIOUR}
                    value={this.state.transactionalBehavior}
                    onChange={(event) =>
                      this.handleChange(event, "transactionalBehavior")
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "transactionalBehavior",
                      name: "transactionalBehavior",
                    }}
                  >
                    {creditMethod.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={"Tipo de persona"}
                    value={this.state.personKind}
                    onChange={(event) => this.handleChange(event, "personKind")}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "personKind",
                      name: "personKind",
                    }}
                  >
                    {personTypes.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={"Canal de distribución"}
                    value={this.state.distributionChannel}
                    onChange={(event) =>
                      this.handleChange(event, "distributionChannel")
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "distributionChannel",
                      name: "distributionChannel",
                    }}
                  >
                    {distributionChannel.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={"Monto de transacciones"}
                    value={this.state.transactionAmount}
                    onChange={(event) =>
                      this.handleChange(event, "transactionAmount")
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "transactionAmount",
                      name: "transactionAmount",
                    }}
                  >
                    {transactionAmount.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={"Categoría"}
                    value={this.state.category}
                    onChange={(event) => this.handleChange(event, "category")}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "category",
                      name: "category",
                    }}
                  >
                    {category.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              {(this.state.category == "pep" ||
                this.state.category == "pepRelation") && (
                <Grid container spacing={4}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={"Cargo, rango o posición"}
                      value={this.state.pepPosition}
                      onChange={this.handleSelectChange("pepPosition")}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={"Fecha de ocupación"}
                      value={this.state.pepOcupationDate}
                      type="date"
                      onChange={this.handleSelectChange("pepOcupationDate")}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={"Institución a la que pertenece"}
                      value={this.state.pepWorkInstitution}
                      onChange={this.handleSelectChange("pepWorkInstitution")}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label={"Parentesco"}
                      value={this.state.pepRelation}
                      onChange={(event) =>
                        this.handleChange(event, "pepRelation")
                      }
                      InputLabelProps={{ style: styles.checkboxLabel }}
                      inputProps={{
                        id: "pepRelation",
                        name: "pepRelation",
                      }}
                    >
                      {pepRelationChoices.map((opt, indx) => (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              )}

              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp; Borrado Permanente
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
                <Button
                  onClick={this.handleResetQuestions}
                  variant="contained"
                  className={classes.yellowButton}
                  style={{ marginLeft: 10 }}
                >
                  <Restore />
                  &nbsp; REINICIAR PREGUNTAS DE SEGURIDAD
                </Button>
                {workProfileObj?.uniqueId ? (
                  <Button
                    onClick={this.handleRohiDialog}
                    variant="contained"
                    className={classes.greenButton}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <SaveIcon />
                    &nbsp; Enviar a Rohi
                  </Button>
                ) : null}
              </div>
            </div>
          )}
        </Paper>

        <div style={{ padding: "10px 0px" }}>
          <FilesTable contractId={clientId} />
        </div>
        <div style={{ padding: "20px 0px" }}>
          <Typography
            variant={"h5"}
            className={"section-header"}
            style={{
              display: "flex",
              alignItems: "center",
              color: "#605F5F",
              fontWeight: "bold",
              margin: "20px",
              textTransform: "uppercase",
            }}
          >
            datos adicionales
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <Card style={{ minWidth: 276, backgroundColor: "#4CAF50" }}>
                <CardContent style={{ minHeight: "70px" }}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    <Folder style={{ marginRight: "7px" }} /> Documentos
                  </Typography>
                </CardContent>
                <CardActions
                  className={classes.cardAction}
                  onClick={() => this.handleDialogToggle("documents", true)}
                >
                  <Button
                    style={{ color: "#4CAF50", fontWeight: "bold" }}
                    size="medium"
                  >
                    Abrir
                  </Button>
                  <IconButton>
                    <ArrowForwardIos style={{ color: "#4CAF50" }} />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card style={{ minWidth: 276, backgroundColor: "#588847" }}>
                <CardContent style={{ minHeight: "70px" }}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    <AccountBalance style={{ marginRight: "7px" }} /> Perfil
                    financiero
                  </Typography>
                </CardContent>
                <CardActions
                  className={classes.cardAction}
                  onClick={() =>
                    this.handleDialogToggle("financialProfile", true)
                  }
                >
                  <Button
                    style={{ color: "#588847", fontWeight: "bold" }}
                    size="medium"
                  >
                    Abrir
                  </Button>
                  <IconButton>
                    <ArrowForwardIos style={{ color: "#588847" }} />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card style={{ minWidth: 276, backgroundColor: "#799759" }}>
                <CardContent style={{ minHeight: "70px" }}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    <LocalAtm style={{ marginRight: "7px" }} /> calculadora de
                    disponibilidad
                  </Typography>
                </CardContent>
                <CardActions
                  className={classes.cardAction}
                  onClick={() =>
                    this.handleDialogToggle("availabilityCalc", true)
                  }
                >
                  <Button
                    style={{ color: "#799759", fontWeight: "bold" }}
                    size="medium"
                  >
                    Abrir
                  </Button>
                  <IconButton>
                    <ArrowForwardIos style={{ color: "#799759" }} />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card style={{ minWidth: 276, backgroundColor: "#748C72" }}>
                <CardContent style={{ minHeight: "70px" }}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    <Work style={{ marginRight: "7px" }} /> Perfil de trabajo
                  </Typography>
                </CardContent>
                <CardActions
                  className={classes.cardAction}
                  onClick={() => this.handleDialogToggle("workProfile", true)}
                >
                  <Button
                    style={{ color: "#748C72", fontWeight: "bold" }}
                    size="medium"
                  >
                    Abrir
                  </Button>
                  <IconButton>
                    <ArrowForwardIos style={{ color: "#748C72" }} />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Dialog
          open={dialogs.workProfile}
          onClose={() => this.handleDialogToggle("workProfile", false)}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton
              onClick={() => this.handleDialogToggle("workProfile", false)}
            >
              <ArrowBack />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <WorkProfile ownerId={clientId} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={dialogs.financialProfile}
          onClose={() => this.handleDialogToggle("financialProfile", false)}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton
              onClick={() => this.handleDialogToggle("financialProfile", false)}
            >
              <ArrowBack />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <FinancialProfile ownerId={clientId} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={dialogs.availabilityCalc}
          onClose={() => this.handleDialogToggle("availabilityCalc", false)}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton
              onClick={() => this.handleDialogToggle("availabilityCalc", false)}
            >
              <ArrowBack />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <AvailabilityCalculator ownerId={clientId} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={dialogs.documents}
          onClose={() => this.handleDialogToggle("documents", false)}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton
              onClick={() => this.handleDialogToggle("documents", false)}
            >
              <ArrowBack />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <ClientDocuments
              debtorId={clientId}
              openLoading={this.openLoading}
              closeLoading={this.handleDialogClose}
              fullName={this.state.fullName}
              documentOpen={this.documentOpen}
            />
          </DialogContent>
        </Dialog>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    clients: state.clients,
    user: state.user,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, { deleteClient, fetchClient, updateClient })(
    ClientsDetailView
  )
);
