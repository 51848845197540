import React, { useState, useEffect } from 'react';
import { 
    Grid, Dialog, DialogContent, Button, 
    Card, CardContent, Typography, Divider
} from '@material-ui/core';
import { 
    AutocompleteField, TextFieldComponent, DateField, 
    NumericTextField, CheckboxField, SearchComponent
} from './../../../components/SharedComponents/Fields';
import { 
    ConfirmDialog, DialogHeader, DialogFooter, 
} from './../../../components/SharedComponents/Dialogs';
import { 
    updatePaymentReceipt, createPaymentReceipt, 
    createPaymentReceiptVoucher, printPaymentReceiptDocument,
    getFileAttachment
 } from './../../../utils/axios';
import { FileSelection } from '../../../components/SharedComponents/FileSelection';
import { dateFormat, errorHandling, camelToSnakeCase } from './../../../components/SharedComponents/CustomHooks';
import NumberFormat from './../../../components/SharedComponents/NumberFormat';
import { PaymentNegotiationTable } from '../../../components/SharedComponents/PaymentNegotiationComponents';
import { FilterList, AttachMoney, RateReview, Image, Print } from '@material-ui/icons';
import { fetchCalculatedPayments } from './../../../utils/axios';

export function ConfirmDialogComponent(props) {
    const data = props.data.data;
    let dialogConfirmContent = '';
    switch(props.data.msg) {
        case 'proceed-delete':
            dialogConfirmContent=
                `El recibo de pago será eliminado.`;
            break;
        case 'proceed-delete-multiple':
            dialogConfirmContent =
                `¿Está seguro que desea eliminar todos los recibos de pago seleccionados?`;
            break;
        default:
            dialogConfirmContent='...';
    }
    return(
        <ConfirmDialog 
            open={props.open}
            handleToggle={props.handleToggle}
            confirmCallback={data.callback}
            data={dialogConfirmContent}
        />
    )
}

export function PaymentReceiptDialog(props) {
    return(
        <Dialog   
            fullWidth
            maxWidth="lg"
            onClose={
                (e, reason) => reason === 'backdropClick' && e.preventDefault()
            }
            className="dialog-container"
            open={props.open}
        >
            <DialogHeader
                dialogTitle="Pago vencimiento"
                handleClick={props.handleToggleDialog.bind(this, 'payment-receipt')}
            />
            <DialogContent style={{margin:'2% 0'}}>
                <PaymentReceiptContent 
                    {...props}
                />
            </DialogContent>
                <DialogFooter />
        </Dialog>
    )
}

export function PaymentReceiptContent(props) {
    const defaultFieldsValue = {
        participant:null,
        concept:'',
        receiptDate:'',
        receiptAmountCurrency:'',
        receiptAmount:'',
        ticket: null,
    };
    const rFields = [
        'participant',
        'concept',
        'receiptDate',
        'receiptAmountCurrency',
        'receiptAmount',
    ];
    // const action = props.modify ? 'update' : 'create';
    const [fields, setField] = useState(defaultFieldsValue);
    const [errorFields, setErrorFields] = useState({}); // the error field obj (to tell the field if there's an error)
    const [error, setError] = useState(false); // general error variable. Show if there's a field with an error.
    const [file, setFile] = useState('');
    const [requiredFields, setRequiredFields] = useState(rFields);
    const [paymentNegotiation, setPaymentNegotiation] = useState([]);
    const [selected, setSelected] = useState([]);
    const [paymentData, setPaymentData] = useState({});

    const getFiltersOptions = (option) => {
        return(
            (option ? option : [])
        );
    }

    const validateRequiredFields = (type, fieldToCheck, value) => { // validate fields errors
        let newErrorState = {...errorFields};
        const fieldCheck = (key, value) => {
            newErrorState[key] = 
            (value === '' || value === null || value === undefined || Object.keys(value).length === 0) ? true : false;
        }
        if(type === 'all') {
            requiredFields.forEach(key => {
                fieldCheck(key, fields[key]);
            });
        }else if(type === 'single' && requiredFields.includes(fieldToCheck)) {
            fieldCheck(fieldToCheck, value);
        }
        const hasErr = Object.keys(newErrorState).find(item => newErrorState[item]);
        setError(Boolean(hasErr));
        setErrorFields({...newErrorState});
        return Boolean(hasErr);
    }

    const handleFieldChange = (field, event, value, type) => {
        let newVal;
        switch(field) {
            case 'receiptDate':
                newVal = dateFormat(event);
                break;
            case 'receiptAmountCurrency':
                newVal = value;
                break;
            case 'participant':
                newVal = event;
                if(newVal) {
                    getCalculatedPayment({owner: newVal?.value});
                }
                break;
            case 'ticket':
                newVal = event;
                break;
            default:
                newVal = event.target ? event.target.value : event.value;
                break;
        }
        validateRequiredFields('single', field, newVal);
        setField(prevState=>({ ...prevState, [field]: newVal})); //this way the state of the object property updates.
    }

    const handleEditRow = () => {
        let editData = {uniqueId: props.data?.uniqueId};
        Object.keys(fields).forEach(key => {
            switch(key){
                case 'receiptDate':
                    editData[key] = props.data[key] + ' '; // the JS date object falls behind 1 day in some specific cases.
                    break;
                case 'receiptAmount':
                    editData[key] = props.data[key] ? props.data[key].toString() : '';
                    break;
                case 'participant':
                    editData[key] = {
                        name: `${props.data.participantName} | ${props.data.participantIdentification}`,
                        fullName: props.data.participantName,
                        value: props.data.participant,
                    };
                    break;
                case 'ticket':
                    if(props.data.ticket) {
                        editData[key] = {
                            name: `${props.data.ticketTitle } - ${props.data.ticketCode}`,
                            value: props.data.ticket,
                        };
                    }
                    break;
                default:
                    editData[key] = props.data[key];
                    break;
            }
            
        });
        setField({...editData});
    }
    const handleSelected = (selection) => {
        setSelected(selection);
    }
    const getCalculatedPayment = (params) => {
        props.setLoading(true);
        const endpoint = fetchCalculatedPayments({...params, token: props.token});
        endpoint.then(response => {
            const info = typeof response.data.length === 'undefined' ? [response.data] : response.data;
            // itarate in every key to change from camelCase to snake_case
            info.forEach(item => {
                item.expirationDetails.forEach(d => {
                    for(const property of Object.keys(d)) {
                        const newKey = camelToSnakeCase(property);
                        if(newKey !== property) {
                            delete Object.assign(d, {[newKey]: d[property]})[property];
                        }
                    }
                })
            }); 
            setPaymentNegotiation([...info]);
            if(props.modify) {
                // true, in case there is no calculatedPayment, take the first payment on list.
                const selection = [...info].find(item => (item.uniqueId === props.data.calculatedPayment) || true );
                setSelected([selection]);
            }
            props.setLoading(false);
        }).catch(errors => {
            errorHandling(errors, props.handleSimpleDialog);
            props.setLoading(false);
        });
    }

    const uploadPaymentReceiptVoucher = (data, action) => {
        let formData = new FormData();
        formData.append("file", data.file);
        const endpoint = createPaymentReceiptVoucher(props.token, data.uniqueId, formData);
        endpoint.then(() => {
            const afterRequest = action === 'update' ? props.handlePayment?.onUpdate : props.handlePayment?.onCreate;
            if(afterRequest) {
                afterRequest();
            }
            props.setLoading(false);
            props.handleSimpleDialog('success-dialog');
            props.handleToggleDialog('payment-receipt');
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleSimpleDialog);
        });
    }

    const createUpdatePaymentRecipt = (data, action) => {
        props.setLoading(true);
        const endpoint = action === 'update' ? updatePaymentReceipt : createPaymentReceipt;
        endpoint({...data, token: props.token}).then(response => {
            if(data.file) {
                uploadPaymentReceiptVoucher({...data, uniqueId: response.data.uniqueId}, action);
            }else {
                const afterRequest = action === 'update' ? props.handlePayment?.onUpdate : props.handlePayment?.onCreate;
                if(afterRequest) {
                    afterRequest(response.data);
                }
                props.setLoading(false);
                props.handleSimpleDialog('success-dialog');
                props.handleToggleDialog('payment-receipt');
            }
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleSimpleDialog);
        });
    }

    const openAttachment = (uid) => {
        if (uid !== undefined){
            props.setLoading(true);
            const endpoint = getFileAttachment(props.token, uid);
            endpoint.then((res) => {
                const dbuff = new Uint8Array(res.data.file).buffer
                const data = new Blob([dbuff], {type: res.data.type});
                const fileURL = URL.createObjectURL(data);
                const NewWindow = window.open();
                NewWindow.location.href = fileURL;
                props.setLoading(false);
            }).catch(errors => {
                props.setLoading(false);
                errorHandling(errors, props.handleSimpleDialog);
            });
          }
    }

    const getPaymentReceiptDocument = () => {
        props.setLoading(true);
        const endpoint = printPaymentReceiptDocument(props.token, props.data.uniqueId);
        endpoint.then(response => {
            const file = new Blob([response.data], { type: "text/html" });
            const fileURL = URL.createObjectURL(file);
            const NewWindow = window.open();
            NewWindow.location.href = fileURL;
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleSimpleDialog);
        });
    }

    const handleSaveBtn = () => {
        if(validateRequiredFields('all')) { return }
        if(!selected.length) {
            props.handleSimpleDialog(
                'simple-dialog', 
                `Debe seleccionar una negociación de pago.`
            );
            return;
        }
        let data = {};
        if(paymentData?.uniqueId) {
            data = {
                ...paymentData,
                concept: fields.concept,
                receiptDate: fields.receiptDate.trim(),
                receiptAmountCurrency: fields.receiptAmountCurrency,
                receiptAmount: fields.receiptAmount,
                file: file,
            }
        }else {
            data = {
                ...fields,
                receiptAmount: Number(fields.receiptAmount),
                participant: fields.participant?.value,
                ticket: fields.ticket?.value,
                calculatedPayment: selected[0]?.uniqueId,
                file: file,
            }
        }
        Object.keys(data).forEach(key => {
            if(!data[key]) {
                delete data[key];
            }
        });
        const action = data.uniqueId ? 'update' : 'create';
        createUpdatePaymentRecipt(data, action);
    }

    useEffect(() => {
        if(props.modify && Object.keys(props.data).length) {
            handleEditRow();
            const params = props.negotiationParams || {owner: props.data.participant}
            getCalculatedPayment(params);
        }
        setPaymentData({...props.data});
    }, [props.data]);

    useEffect(() => {
        if(selected.length) {
            const amount = selected[0]?.totalToPay || '';
            setField({...fields, receiptAmount: amount.toString()});
        }
    }, [selected]);
    return(
        <>
            <Grid container justifyContent='space-between'>
                <Typography
                    variant={"subtitle1"}
                    className={"section-header"}
                    style={{fontWeight: 'bold', color:'#878787', display:'flex', alignItems:'center'}}
                >
                    <AttachMoney style={{fontSize:20, marginRight:'.3rem'}} />
                    DETALLE DEL RECIBO DE PAGO
                </Typography>
                {
                    props.modify &&
                    <Button
                        variant="contained"
                        className={
                            `${
                                !props.data.uniqueId ? "action-method-btn disabled" : "action-method-btn save"
                            } generate-print-document`
                        }
                        disabled={!props.data.uniqueId}
                        disableElevation
                        style={{height:35}}
                        onClick={getPaymentReceiptDocument}
                    >
                        GENERAR DOCUMENTO
                        <Print style={{fontSize:20, marginLeft:'.3rem'}} />
                    </Button>
                }
            </Grid>
            <Divider style={{margin:'1rem 0'}}/>
            <Grid container direction="row" spacing={2} style={{padding:'0 16px'}}>
                <SearchComponent 
                    xs={4}
                    containerClass="field-container"
                    fieldLabel="Participante (*)"
                    fieldID="payment-receipt-participant-add"
                    fieldInnerLabel="Seleccione un participante"
                    handleChange={(event, value) => { handleFieldChange('participant', event, value) }}
                    savedValue={props.modify ? fields.participant : null}
                    optionType={'participants'}
                    clearOnBlur={false}
                    limitTags={1}
                    error={errorFields.participant}
                    helperText={errorFields.participant ? "Seleccione un participante" : null}
                    disabled={props.modify}
                />
                <NumericTextField
                    xs={4}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Monto del recibo (*)"
                    placeholder="Ej: $50,000"
                    fieldID="payment-receipt-amount-add"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    margin='dense'
                    fullWidth
                    prefix="$"
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={fields.receiptAmount || ''}
                    onChange={handleFieldChange.bind(this, 'receiptAmount')}
                    error={errorFields.receiptAmount}
                    helperText={errorFields.receiptAmount ? "Introduzca un monto" : null}
                />
                <AutocompleteField
                    xs={4}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Divisa (*)"
                    fieldID="autocomplete-field-pay-type"
                    fieldInnerLabel="Seleccione una divisa"
                    fieldVariant="outlined"
                    value={fields.receiptAmountCurrency || null}
                    handleChange={handleFieldChange.bind(this, 'receiptAmountCurrency')}
                    options={getFiltersOptions([
                        'DOP',
                        // 'USD', 'CAD', 'EUR'
                    ])}
                    // getOptionLabel={(option) => option.name}
                    // getOptionSelected={(option, value) => option.value === value.value}
                    error={errorFields.receiptAmountCurrency}
                    helperText={errorFields.receiptAmountCurrency ? "Seleccione un tipo de pago" : null}
                    limitTags={2}
                />
            </Grid>
            <Grid container direction="row" spacing={2} style={{padding:'0 24px', marginTop:'1.5rem', marginBottom:'1.5rem'}}>
                <PaymentNegotiationTable
                    data={paymentNegotiation}
                    participant={fields.participant}
                    handleToggleDialog={props.handleToggleDialog}
                    handleToggleSimpleDialog={props.handleSimpleDialog}
                    limitSelection={1}
                    removeSelectAll={true}
                    handleSelected={handleSelected}
                    selected={selected}
                    setSelected={setSelected}
                    disableSelection={props.modify}
                    disabled={false}
                    paymentType={{value: 'extraoridnary', label: "Extraordinario"}}
                />
            </Grid>
            <Grid container direction="row" spacing={2} style={{padding:'0 16px'}}>
                <TextFieldComponent 
                    xs={12}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Concepto (*)"
                    fieldID="payment-receipt-concept-add"
                    fieldClass="fields"
                    fieldVariant="outlined"
                    placeholder="Digite el concepto del recibo de pago..."
                    margin='dense'
                    fullWidth
                    value={fields.concept || ''}
                    onChange={handleFieldChange.bind(this, 'concept')}
                    multiline={true}
                    rows={4}
                    error={errorFields.concept}
                    helperText={errorFields.concept ? "Introduzca un concepto" : null}
                />
                
            </Grid>
            <Grid container direction="row" spacing={2} style={{padding:'0 16px'}}>
                <DateField 
                    xs={6}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Fecha (*)"
                    dateFieldID="payment-receipt-date-add"
                    fullWidth={true}
                    dateFieldClass="date-field"
                    inputVariant="outlined"
                    dateFieldLabel="Fecha del recibo"
                    format="dd/MM/yyyy"
                    value={fields.receiptDate || null}
                    handleChange={handleFieldChange.bind(this, 'receiptDate')}
                    error={errorFields.receiptDate}
                    helperText={errorFields.receiptDate ? "Seleccione una fecha" : null}
                />
                <SearchComponent
                    xs={6}
                    containerClass="field-container"
                    fieldLabel="Ticket"
                    fieldID="payment-receipt-ticket-add"
                    fieldInnerLabel="Seleccione un ticket"
                    savedValue={props.modify ? fields.ticket : null}
                    handleChange={(event, value) => { handleFieldChange('ticket', event, value) }}
                    optionType={'tickets'}
                    clearOnBlur={false}
                    limitTags={1}
                    disabled={props.modify}
                />
            </Grid>
            <Grid item container direction="row" xs={6} spacing={2} alignItems='flex-end' style={{padding:'0 16px', marginTop:'1rem', flexWrap:'nowrap'}}>
                <div style={{paddingLeft:8}}>
                    <Typography
                        variant="subtitle1"
                        className="field-label"
                    >Archivo adjunto</Typography>
                    <FileSelection
                        xs={11}
                        file={file}
                        setFile={setFile}
                        fileExtensions=".pdf,.png,.jpg,.jpeg"
                    />
                </div>
                {
                    props.showAttachmentBtn ?
                    <Button 
                        variant="contained" 
                        className={!paymentData.fileAttachment ? "action-method-btn disabled" : "action-method-btn save"}
                        disableElevation
                        size="small"
                        onClick={openAttachment.bind(this, paymentData.uniqueId)}
                        disabled={!paymentData.fileAttachment}
                        style={{margin:'0 0 3px 1rem'}}
                    >
                        {/* ver adjunto */}
                        <Image style={{fontSize:22, }} />
                    </Button> : null
                }
            </Grid>
            <Grid container justifyContent="flex-end" style={{padding: '32px 16px 0 0'}}>
                <Button 
                    variant="contained" 
                    className={error ? "action-method-btn disabled" : "action-method-btn save"}
                    disableElevation
                    size="small"
                    onClick={handleSaveBtn}
                    disabled={error}
                >
                    {paymentData.uniqueId ? "modificar recibo de pago" : 'crear recibo de pago'}
                </Button>
            </Grid>
        </>
    );
}