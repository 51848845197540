import * as React from "react";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import axios from "axios";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createAcquaintanceshipCompanyContract } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import ComercialReferencesSelectInput from "../../../components/ComercialReferencesSelectInput";

import ShareholderOrMajorityPartnerSelectInput from "../../../components/AcquaintanceshipProviderSelectInputs/ShareholderOrMajorityPartner";

import {
  catchError,
  resetError,
  commercialReferenceArrayToJson,
  successHandler,
  titleCaseHandler,
  translations,
  capitalizeCaseHandler,
  shareholderArrayToJson,
} from "../../../utils";
import {
  createAcquaintanceshipCompanyContract as create,
  fetchAssignees as fetchA,
} from "../../../utils/axios";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
import CreditorSelectInput from "../../Creditor/CreditorSelectInput";
import { Divider } from "@material-ui/core";
import CheckBox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: "100%",
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  gridContainer: {
    flexGrow: 1,
  },
  center: {
    textAlign: "center",
  },
  gridTextField: {
    display: "block",
    marginTop: 20,
    width: "100%",
  },
});

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branch: "regular loan",
      branchError: "",
      businessName: "",
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      creditor: [],
      creditorError: "",
      debtor: [],
      debtorError: "",
      dialog: false,
      dialogTitle: "",
      errorMessage: "",
      infoDestinationError: "",
      loading: false,
      merchantRecordNumberExpirationDate: new Date()
        .toISOString()
        .split("T")[0],
      commercialReferences: [],
      commercialReferencesError: "",
      title: "",
      titleError: "",
      shareHolders: [],
      shareHoldersError: "",
      loggedUser: "",

      pepMember: false,
      pepMemberPosition: "",
      pepMemberPositionError: "",
      pepMemberEntryDate: "",
      pepMemberEntryDateError: "",
      pepMemberInstitution: "",
      pepMemberInstitutionError: "",

      pepMemberKinship: false,
      pepMemberKinshipPosition: "",
      pepMemberKinshipPositionError: "",
      pepMemberKinshipEntryDate: "",
      pepMemberKinshipEntryDateError: "",
      pepMemberKinshipInstitution: "",
      pepMemberKinshipInstitutionError: "",
    };
    // creditor
    this.handleAddCreditor = this.handleAddCreditor.bind(this);
    this.handleDeleteCreditor = this.handleDeleteCreditor.bind(this);

    // client
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // commercial reference
    this.handleAddCommercialReference = this.handleAddCommercialReference.bind(
      this
    );
    this.handleDeleteCommercialReference = this.handleDeleteCommercialReference.bind(
      this
    );

    // core event
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);

    // shareholder
    this.handleAddShareHolder = this.handleAddShareHolder.bind(this);
    this.handleDeleteShareHolder = this.handleDeleteShareHolder.bind(this);
  }
  CancelToken = axios.CancelToken;
  source = this.CancelToken.source();
  abortController = new AbortController();

  componentDidMount() {
    fetchA({
      token: this.props.user.token,
      signal: this.abortController.signal,
    }).then((res) => {
      try {
        const loggedUser = res.data.find(
          (user) => user.id === jwtDecode(this.props.user.token).user_id
        ).username;
        this.setState({ loggedUser: loggedUser });
      } catch (error) {
        catchError(error, this);
      }
    });
  }

  handleAddShareHolder(shareHolder) {
    this.setState((prevState) => ({
      shareHolders: [...prevState.shareHolders, shareHolder],
    }));
  }

  handleDeleteShareHolder(index) {
    this.setState((prevState) => ({
      shareHolders: prevState.shareHolders.filter(
        (shareHolder, i) => i !== index
      ),
    }));
  }
  handleCheckBoxChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  handleAddCreditor(id) {
    this.setState({ creditor: [id] });
  }
  handleDeleteCreditor(id) {
    this.setState({ creditor: [] });
  }
  handleAddClient(id) {
    this.setState({ debtor: [id] });
  }
  handleDeleteClient(id) {
    this.setState({ debtor: [] });
  }
  handleAddCommercialReference(commercialReferences) {
    this.setState((prevState) => ({
      commercialReferences: [
        ...prevState.commercialReferences,
        commercialReferences,
      ],
    }));
  }
  handleDeleteCommercialReference(index) {
    this.setState((prevState) => ({
      commercialReferences: prevState.commercialReferences.filter(
        (commercialReferences, i) => i !== index
      ),
    }));
  }
  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  handleSelectChange(key) {
    return (event) => {
      this.setState({ [key]: event.target.value });
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    resetError(this);
    const { state } = this;
    if (!state.commercialReferences.length) {
      this.setState({ commercialReferencesError: "Este campo es requerido" });
      return;
    } else if (!state.shareHolders.length) {
      this.setState({ shareHoldersError: "Este campo es requerido" });
      return;
    }
    const commercialReference = commercialReferenceArrayToJson(
      state.commercialReferences
    );
    const shareHolders = shareholderArrayToJson(state.shareHolders);

    const contract = {
      peps: this.state.pepMember,
      pepsDescription: {
        pepMemberPosition: this.state.pepMemberPosition,
        pepMemberEntryDate: this.state.pepMemberEntryDate,
        pepMemberInstitution: this.state.pepMemberInstitution,
      },
      areQuestionsValidForRelatives: this.state.pepMemberKinship,
      areQuestionsValidForRelativesDescription: {
        pepMemberKinshipPosition: capitalizeCaseHandler(
          this.state.pepMemberKinshipPosition
        ),
        pepMemberKinshipEntryDate: this.state.pepMemberKinshipEntryDate,
        pepMemberKinshipInstitution: capitalizeCaseHandler(
          this.state.pepMemberKinshipInstitution
        ),
      },
      branch: state.branch,
      contractDate: state.contractDate,
      creditor: state.creditor[0] || "",
      debtor: state.debtor[0] || "",
      infoDestination: state.infoDestination,
      commercialReference: commercialReference,
      title: titleCaseHandler(state.title),
      merchantRecordNumberExpirationDate:
        state.merchantRecordNumberExpirationDate,
      shareholders: shareHolders,
      subsidiary: state.subsidiary,
      createdBy: state.loggedUser,
    };

    this.setState({ loading: true });

    create(this.props.user.token, contract)
      .then((res) => {
        this.props.createAcquaintanceshipCompanyContract(
          res.data.results ? res.data.results : res.data
        );
        if (this.props.handleSaveCallBack) {
          this.props.handleSaveCallBack(res.data.uniqueId);
        }
        successHandler(res, this, translations.ACF);
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  handleReset() {
    this.setState({
      branch: "Prestamo regular",
      branchError: "",
      businessName: "",
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      creditor: [],
      creditorError: "",
      debtor: [],
      debtorError: "",
      infoDestination: "Oficina legal",
      infoDestinationError: "",
      merchantRecordNumberExpirationDate: new Date()
        .toISOString()
        .split("T")[0],
      commercialReferences: [],
      commercialReferencesError: "",
      subsidiary: "",
      title: "",
      titleError: "",
      createdBy: "",
      pepMember: false,
      pepMemberPosition: "",
      pepMemberPositionError: "",
      pepMemberEntryDate: "",
      pepMemberEntryDateError: "",
      pepMemberInstitution: "",
      pepMemberInstitutionError: "",

      pepMemberKinship: false,
      pepMemberKinshipPosition: "",
      pepMemberKinshipPositionError: "",
      pepMemberKinshipEntryDate: "",
      pepMemberKinshipEntryDateError: "",
      pepMemberKinshipInstitution: "",
      pepMemberKinshipInstitutionError: "",
    });
  }
  handleDialogClose() {
    this.setState({ dialog: false });
  }
  render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">{translations.NEW_ACF}</Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.gridTextField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <br />
              <Typography variant="h5" className={classes.checkboxLabel}>
                INFORMACIÓN BÁSICA PERSONAL
              </Typography>
              <Divider />
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={6}>
                  <TextField
                    label={translations.MERCHANT_RECORD_NUMBER_EXPIRATION_DATE}
                    className={classes.gridTextField}
                    value={this.state.merchantRecordNumberExpirationDate}
                    onChange={this.handleChange(
                      "merchantRecordNumberExpirationDate"
                    )}
                    type="date"
                    required={true}
                    multiline={false}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <TextField
                    label={translations.SUBSIDIARY}
                    className={classes.gridTextField}
                    value={this.state.subsidiary}
                    onChange={this.handleChange("subsidiary")}
                    multiline={false}
                    fullWidth={true}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
              </Grid>
              <Grid container={true} spacing={3}></Grid>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      htmlFor="branch"
                      style={styles.checkboxLabel}
                      shrink={true}
                    >
                      {translations.BRANCH}
                    </InputLabel>
                    <Select
                      value={this.state.branch}
                      onChange={this.handleSelectChange("branch")}
                      native={true}
                      error={this.state.branchError !== ""}
                      inputProps={{
                        id: "branch",
                        name: "branch",
                      }}
                    >
                      <option value="regular loan">
                        {translations.REGULAR_LOAN}
                      </option>
                      <option value="factoring">
                        {translations.FACTORING}
                      </option>
                      <option value="rental management">
                        {translations.RENTAL_MANAGMENT}
                      </option>
                      <option value="administration of accounts receivable">
                        {translations.ADMINISTRATION_ACCOUNT_RECEIVABLE}
                      </option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="infoDestination" shrink={true}>
                      {translations.INFO_DESTINATION}
                    </InputLabel>
                    <Select
                      value={this.state.infoDestination}
                      onChange={this.handleSelectChange("infoDestination")}
                      native={true}
                      error={this.state.infoDestinationError !== ""}
                      inputProps={{
                        id: "infoDestination",
                        name: "infoDestination",
                      }}
                    >
                      <option value="legal office">
                        {translations.LEGAL_OFFICE}
                      </option>
                      <option value="main office">
                        {translations.MAIN_OFFICE}
                      </option>
                      <option value="email">{translations.EMAIL}</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container={true} spacing={3}>
                <br />
                <br />
              </Grid>
              <Grid item={true} xs={4}></Grid>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.CREDITORS}
                </Typography>
                <CreditorSelectInput
                  selectedCreditorsId={this.state.creditor}
                  addCreditor={this.handleAddCreditor}
                  deleteCreditor={this.handleDeleteCreditor}
                />
                <p className={classes.errorInput}>{this.state.creditorError}</p>
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.DEBTORS}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtor}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>

              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {"Accionista y/o socio mayoritario"}
                </Typography>
                <ShareholderOrMajorityPartnerSelectInput
                  tableLimit={5}
                  partners={this.state.shareHolders}
                  add={this.handleAddShareHolder}
                  delete={this.handleDeleteShareHolder}
                />
                <p className={classes.errorInput}>
                  {this.state.shareHoldersError}
                </p>
              </div>
              <br />
              <Grid container={true} className={classes.half}>
                <Grid item={true} xs={12}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.COMMERCIAL_REFERENCES}
                  </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  <ComercialReferencesSelectInput
                    tableLimit={5}
                    commercialReferences={this.state.commercialReferences}
                    add={this.handleAddCommercialReference}
                    delete={this.handleDeleteCommercialReference}
                  />
                  <p className={classes.errorInput}>
                    {this.state.commercialReferencesError}
                  </p>
                </Grid>
              </Grid>
              <FormControlLabel
                label={
                  "¿Existe alguna persona políticamente expuesta y/o figura pública dentro de los socios o consejo directivo?"
                }
                control={
                  <CheckBox
                    color="primary"
                    checked={this.state.pepMember}
                    onChange={this.handleCheckBoxChange("pepMember")}
                  />
                }
                value="pepMember"
              />
              {this.state.pepMember ? (
                <div>
                  <TextField
                    label={"Cargo, Rango o Posición en Gobierno"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.pepMemberPosition)}
                    onChange={this.handleChange("pepMemberPosition")}
                    fullWidth={true}
                    required={true}
                    error={this.state.pepMemberPositionError !== ""}
                    helperText={this.state.pepMemberPositionError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                  <TextField
                    label={"Fecha desde que ocupa el cargo, Rango o Posición"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.pepMemberEntryDate)}
                    onChange={this.handleChange("pepMemberEntryDate")}
                    required={true}
                    type={"date"}
                    error={this.state.pepMemberEntryDateError !== ""}
                    helperText={this.state.pepMemberEntryDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                  <br />
                  <TextField
                    label={"Institución a la que pertenece"}
                    className={classes.textField}
                    value={titleCaseHandler(this.state.pepMemberInstitution)}
                    onChange={this.handleChange("pepMemberInstitution")}
                    fullWidth={true}
                    required={true}
                    error={this.state.pepMemberInstitutionError !== ""}
                    helperText={this.state.pepMemberInstitutionError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                </div>
              ) : null}
              <FormControlLabel
                label={
                  "¿Existe alguna persona dentro de los socios o consejo directivo con algún parentesco a una persona persona politicamente expuesta y/o figura pública?"
                }
                control={
                  <CheckBox
                    color="primary"
                    checked={this.state.pepMemberKinship}
                    onChange={this.handleCheckBoxChange("pepMemberKinship")}
                  />
                }
                value="pepMemberKinship"
              />
              {this.state.pepMemberKinship ? (
                <div>
                  <TextField
                    label={"Cargo, Rango o Posición en Gobierno"}
                    className={classes.textField}
                    value={titleCaseHandler(
                      this.state.pepMemberKinshipPosition
                    )}
                    onChange={this.handleChange("pepMemberKinshipPosition")}
                    fullWidth={true}
                    required={true}
                    error={this.state.pepMemberKinshipPositionError !== ""}
                    helperText={this.state.pepMemberKinshipPositionError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                  <TextField
                    label={"Fecha desde que ocupa el cargo, Rango o Posición"}
                    className={classes.textField}
                    value={titleCaseHandler(
                      this.state.pepMemberKinshipEntryDate
                    )}
                    onChange={this.handleChange("pepMemberKinshipEntryDate")}
                    required={true}
                    type={"date"}
                    error={this.state.pepMemberKinshipEntryDateError !== ""}
                    helperText={this.state.pepMemberKinshipEntryDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                  <br />
                  <TextField
                    label={"Institución a la que pertenece"}
                    className={classes.textField}
                    value={titleCaseHandler(
                      this.state.pepMemberKinshipInstitution
                    )}
                    onChange={this.handleChange("pepMemberKinshipInstitution")}
                    fullWidth={true}
                    required={true}
                    error={this.state.pepMemberKinshipInstitutionError !== ""}
                    helperText={this.state.pepMemberKinshipInstitutionError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                  <br />
                </div>
              ) : null}
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createAcquaintanceshipCompanyContract })(Form)
);
