import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Fab from "@material-ui/core/Fab";
import { createStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import { fetchBlacklists } from "../../../actions";
import Loading from "../../../components/Loading";
import { fetchBlacklists as fetchAll } from "../../../utils/axios";
import BlackListForm from "../BlackListForm/index";
import BlackListView from "../BlackListView/index";

const styles = createStyles({
  addButton: {
    backgroundColor: green[500],
    bottom: 10,
    color: "white",
    position: "fixed",
    right: 10,
  },
  rightSide: {
    alignItems: "center",
    border: "1px solid transparent",
    boxSizing: "border-box",
    display: "block",
    height: "100%",
    justifyContent: "center",
    position: "relative",
  },
  root: {
    boxSizing: "border-box",
    height: "100%",
    margin: 0,
    padding: 0,
  },
});

class BlacklistSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedBlacklistedId: undefined,
      selectedSection: 0,
    };

    // this.handleClickItem = this.handleClickItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    fetchAll({ token: this.props.user.token, limit: 20, offset: 0 })
      .then((res) => {
        this.props.fetchBlacklists(
          res.data.results ? res.data.results : res.data
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  handleChangeSection(sectionNumber) {
    this.setState({ selectedSection: sectionNumber });
  }
  // handleClickItem(blacklistedId) {
  //   this.setState(
  //     {
  //       selectedBlacklistedId: blacklistedId,
  //       selectedSection: 1,
  //     },
  //     () => {
  //       this.setState({ selectedSection: 2 });
  //     }
  //   );
  // }
  handleAddClick() {
    this.setState({
      selectedSection: 2,
    });
  }
  fetchData(params) {
    this.setState({ loading: true });
    fetchAll(params)
      .then((res) => {
        this.props.fetchBlacklists(
          res.data.results ? res.data.results : res.data
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  render() {
    const { classes } = this.props;
    const { selectedSection } = this.state;
    return (
      // <div className={classes.root}>
      <div className={classes.rightSide}>
        {selectedSection === 0 ? (
          <BlackListView changeSection={this.handleChangeSection} />
        ) : null}
        {selectedSection === 1 ? (
          <Loading message={translations.LOADING} key={1} />
        ) : null}
        {selectedSection === 2 ? (
          <BlackListForm changeSection={this.handleChangeSection} />
        ) : null}
        {/* {selectedSection === 3 ? (
          
          ) : null} */}
        {selectedSection !== 2 ? (
          <Fab className={classes.addButton} onClick={this.handleAddClick}>
            <AddIcon />
          </Fab>
        ) : null}
      </div>
      // </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    blacklist: state.blacklist,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchBlacklists })(BlacklistSection)
);
