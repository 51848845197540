import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import { createStyles, withStyles } from "@material-ui/core/styles";

import { fetchActs } from "../../../actions";
import DefaultSection from "../../../components/DefaultSection";
import Loading from "../../../components/Loading";
import { fetchActs as fetchAll } from "../../../utils/axios";
import ActSearchSection from "../ActSearchSection";
import ActView from "../ActView";

const styles = createStyles({
  addButton: {
    backgroundColor: green[500],
    bottom: 10,
    color: "white",
    position: "fixed",
    right: 10,
  },
  rightSide: {
    alignItems: "center",
    border: "1px solid transparent",
    boxSizing: "border-box",
    display: "block",
    height: "100%",
    justifyContent: "center",
    marginLeft: 280,
    position: "relative",
  },
  root: {
    boxSizing: "border-box",
    height: "100%",
    margin: 0,
    padding: 0,
  },
});

class ActSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedId: undefined,
      selectedSection: 0,
    };

    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    fetchAll({ token: this.props.user.token, limit: 20, offset: 0 })
      .then((res) => {
        this.props.fetchActs(res.data.results ? res.data.results : res.data);
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  handleChangeSection(sectionNumber, actId) {
    if (actId) {
      this.setState({ selectedId: actId });
    }

    this.setState({ selectedSection: sectionNumber });
  }
  handleClickItem(id) {
    if (id === "none") {
      return;
    }

    this.setState(
      {
        selectedId: id,
        selectedSection: 1,
      },
      () => {
        this.setState({ selectedSection: 2 });
      }
    );
  }
  handleAddClick() {
    this.setState({
      selectedSection: 3,
    });
  }
  fetchData(params) {
    this.setState({ loading: true });
    fetchAll(params)
      .then((res) => {
        this.props.fetchActs(res.data.results ? res.data.results : res.data);
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  render() {
    const { classes } = this.props;
    const { loading, selectedId, selectedSection } = this.state;
    return (
      <div className={classes.root}>
        <ActSearchSection
          loading={loading}
          handleClick={this.handleClickItem}
          fetchData={this.fetchData}
          pagination={true}
        />
        <div className={classes.rightSide}>
          {selectedSection === 0 ? (
            <DefaultSection
              message={translations.ACT_DEFAULT_SECTION_MESSAGE}
              key={0}
            />
          ) : null}
          {selectedSection === 1 ? (
            <Loading message={translations.LOADING} key={1} />
          ) : null}
          {selectedSection === 2 ? (
            <ActView
              changeSection={this.handleChangeSection}
              actId={selectedId ? selectedId : ""}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // occupations: state.occupations,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchActs })(ActSection)
);
