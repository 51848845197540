import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { AutocompleteField } from "src/components/SharedComponents/Fields";
import { FileSelection } from "src/components/SharedComponents/FileSelection";

const months = [
    { value: "01", label: "Enero" },
    { value: "02", label: "Febrero" },
    { value: "03", label: "Marzo" },
    { value: "04", label: "Abril" },
    { value: "05", label: "Mayo" },
    { value: "06", label: "Junio" },
    { value: "07", label: "Julio" },
    { value: "08", label: "Agosto" },
    { value: "09", label: "Septiembre" },
    { value: "10", label: "Octubre" },
    { value: "11", label: "Noviembre" },
    { value: "12", label: "Diciembre" },
  ];
  
  

function UploadFile(props) {

  const filterFieldHandler = (field, event, val) => {
    let value = val ? val : event && event.target ? event.target.value : event;
    props.setFilters({ ...props.filters, [field]: value });
  };

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 4;
    const endYear = currentYear + 12;
    const yearsArray = [];

    for(let i = startYear; i <= endYear; i++){
        yearsArray.push(`${i}`);
    }
    return yearsArray;
  };

  const yearsOptions = getYears();

  return (
    <Grid className="filters-container">
      <Typography variant="h6">Cargar reporte</Typography>
      <div style={{ margin: "15px 0" }}></div>
      <Grid item container spacing={2} direction="row">
        <AutocompleteField
          xs={4}
          containerClass="field-container"
          fieldClass="fields"
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Seleccione el mes"
          fieldID="filter-field-priority"
          fieldInnerLabel="Seleccionar mes..."
          fieldVariant="outlined"
          value={props.filters?.month}
          handleChange={filterFieldHandler.bind(this, "month")}
          options={months}
          getOptionLabel={(option) => option.label}
          limitTags={1}
        />
        <AutocompleteField
          xs={4}
          containerClass="field-container"
          fieldClass="fields"
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Seleccione el año"
          fieldID="filter-field-priority"
          fieldInnerLabel="Seleccionar año..."
          fieldVariant="outlined"
          value={props.filters.year}
          handleChange={filterFieldHandler.bind(this, "year")}
          options={yearsOptions}
          getOptionLabel={(option) => option}
          limitTags={1}
        />
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginTop: "35px", justifyContent: "flex-start" }}
      >
        <FileSelection
            file={props.file}
            setFile={props.setFile}
            fileExtensions=".csv,.xls,.xlsx"
            inputId="input-file-selection-insurance-upload"
            uploadFile={props.UploadReport}
          />
      </Grid>
    </Grid>
  );
}

export default UploadFile;
