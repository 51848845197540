import { Button, Grid, IconButton } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import QuotationDialog from "src/containers/Outlay/OutlayView/quotationDialog";
import { fetchQuotation } from "src/utils/axios";
import {
  CreditType,
  DependentsAmount,
  HousingTypes,
  PLANNED_SAVINGS_PERIODICITY,
  educationLevel,
  settlementDisbursement,
} from "../../../utils/index";
import { BankAccountDialog } from "../Dialogs";
import {
  AutocompleteField,
  CheckboxField,
  NumericTextField,
  TextFieldComponent,
} from "./../../../components/SharedComponents/Fields";

export default function FinancingDataForm({
  rFields,
  fields,
  setBankInfo,
  bankInfo,
  onChange,
  errorFields,
  section,
  getFiltersOptions,
  handleSelectValue,
  institution,
  token,
  calcPrecalification,
  expand,
}) {
  const [open, setOpen] = useState(false);
  const [openQtt, setOpenQtt] = useState(false);
  const [quotationCode, setQuotationCode] = useState("");

  const handleClose = () => setOpen(false);
  const handleCloseQtt = () => setOpenQtt(false);

  useEffect(() => {
    let paymentFreq = {};
    if (institution && institution?.paymentFrequency) {
      paymentFreq = PLANNED_SAVINGS_PERIODICITY.find(
        (item) => item.value === institution.paymentFrequency
      );
    } else {
      paymentFreq = PLANNED_SAVINGS_PERIODICITY.find(
        (item) => item.value === "monthly"
      );
    }
    onChange("any", "periodicity", paymentFreq);
  }, [institution]);

  const GetQuotation = async () => {
    try {
      const res = await fetchQuotation(token, fields.quotation);
      setQuotationCode(res.data?.referenceCode);
    } catch (error) {
      console.error("Error obteniendo la cotizacion: ", error);
    }
  };

  useEffect(() => {
    if (fields.quotation) {
      GetQuotation();
    }
  }, [fields.quotation]);

  useEffect(() => {
    const getPrecalification = async () => {
      const precal = await calcPrecalification();
      onChange("any", "precalification", precal?.data);
    };

    if (expand === "financingData") {
      getPrecalification();
    }
  }, [
    fields.salary,
    fields.entryDate,
    fields.numberOfDependents,
    fields.houseInfo,
    fields.educationLevel,
    expand,
  ]);

  return (
    <Grid container spacing={3} className="form-wrapper-fields-container">
      <BankAccountDialog
        open={open}
        token={token}
        handleClose={handleClose}
        accountInfo={fields}
        fields={bankInfo}
        setFields={setBankInfo}
        section={section}
        errorFields={errorFields}
      />

      <QuotationDialog
        open={openQtt}
        handleClose={handleCloseQtt}
        quotationCode={fields.quotation}
      />

      <AutocompleteField
        xs={4}
        fieldLabel={`Tipo de vivienda ${
          rFields.includes("houseInfo") ? "(*)" : ""
        }`}
        fieldID="leads-field-houseInfo"
        fieldInnerLabel="Seleccione una opción"
        value={
          handleSelectValue(fields.houseInfo, HousingTypes, "value") || null
        }
        handleChange={onChange.bind(this, "autocomplete", "houseInfo")}
        options={getFiltersOptions(HousingTypes)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.houseInfo)}
        helperText={
          errorFields[section]?.houseInfo
            ? errorFields[section]?.houseInfo
            : null
        }
        multiple={false}
      />
      <AutocompleteField
        xs={4}
        fieldLabel={`Cantidad de dependientes ${
          rFields.includes("numberOfDependents") ? "(*)" : ""
        }`}
        fieldID="leads-field-numberOfDependents"
        fieldInnerLabel="Seleccione una opción"
        value={
          handleSelectValue(
            fields.numberOfDependents,
            DependentsAmount,
            "value"
          ) || null
        }
        handleChange={onChange.bind(this, "autocomplete", "numberOfDependents")}
        options={getFiltersOptions(DependentsAmount)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.numberOfDependents)}
        helperText={
          errorFields[section]?.numberOfDependents
            ? errorFields[section]?.numberOfDependents
            : null
        }
        multiple={false}
      />
      <AutocompleteField
        xs={4}
        fieldLabel={`Nivel de Educación ${
          rFields.includes("educationLevel") ? "(*)" : ""
        }`}
        fieldID="leads-field-educationLevel"
        fieldInnerLabel="Seleccione una opción"
        value={
          handleSelectValue(fields.educationLevel, educationLevel, "value") ||
          null
        }
        handleChange={onChange.bind(this, "autocomplete", "educationLevel")}
        options={getFiltersOptions(educationLevel)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.educationLevel)}
        helperText={
          errorFields[section]?.educationLevel
            ? errorFields[section]?.educationLevel
            : null
        }
        multiple={false}
      />
      <NumericTextField
        xs={4}
        fullWidth
        fieldLabel={`Precalificación tentativa ${
          rFields.includes("precalification") ? "(*)" : ""
        }`}
        fieldID="leads-field-precalification"
        placeholder="$0.00"
        prefix="$"
        value={fields.precalification || null}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator={true}
        onChange={onChange.bind(this, "numeric", "precalification")}
        disabled
        error={Boolean(errorFields[section]?.precalification)}
        helperText={
          errorFields[section]?.precalification
            ? errorFields[section]?.precalification
            : null
        }
      />
      <AutocompleteField
        xs={4}
        fieldLabel={`Tipo de Crédito ${
          rFields.includes("creditType") ? "(*)" : ""
        }`}
        fieldID="leads-field-creditType"
        fieldInnerLabel="Seleccione una opción"
        value={
          handleSelectValue(fields.creditType, CreditType, "value") || null
        }
        handleChange={onChange.bind(this, "autocomplete", "creditType")}
        options={getFiltersOptions(CreditType)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.creditType)}
        helperText={
          errorFields[section]?.creditType
            ? errorFields[section]?.creditType
            : null
        }
        multiple={false}
      />
      <NumericTextField
        xs={4}
        fullWidth
        fieldLabel={`Monto ${rFields.includes("amount") ? "(*)" : ""}`}
        fieldID="leads-field-amount"
        placeholder="$0.00"
        prefix="$"
        value={fields.amount || null}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator={true}
        onChange={onChange.bind(this, "numeric", "amount")}
        error={Boolean(errorFields[section]?.amount)}
        helperText={
          errorFields[section]?.amount ? errorFields[section]?.amount : null
        }
      />
      <NumericTextField
        xs={4}
        fullWidth
        fieldLabel={`Plazo ${rFields.includes("term") ? "(*)" : ""}`}
        fieldID="leads-field-term"
        placeholder="3"
        value={fields.term || null}
        decimalScale={0}
        onChange={onChange.bind(this, "numeric", "term")}
        error={Boolean(errorFields[section]?.term)}
        helperText={
          errorFields[section]?.term ? errorFields[section]?.term : null
        }
      />
      {!institution?.paymentDay && (
        <NumericTextField
          xs={4}
          fullWidth
          fieldLabel={`Dia de pago ${
            rFields.includes("paymentDay") ? "(*)" : ""
          }`}
          fieldID="leads-field-paymentDay"
          placeholder="01"
          value={fields.paymentDay || null}
          decimalScale={0}
          onChange={onChange.bind(this, "numeric", "paymentDay")}
          error={Boolean(errorFields[section]?.paymentDay)}
          helperText={
            errorFields[section]?.paymentDay
              ? errorFields[section]?.paymentDay
              : null
          }
        />
      )}
      {!institution?.secondPaymentDay && fields.periodicity === "biweekly" && (
        <NumericTextField
          xs={4}
          fullWidth
          fieldLabel={`Segundo dia de pago ${
            rFields.includes("secondPaymentDay") ? "(*)" : ""
          }`}
          fieldID="leads-field-secondPaymentDay"
          placeholder="01"
          value={fields.secondPaymentDay || null}
          decimalScale={0}
          onChange={onChange.bind(this, "numeric", "secondPaymentDay")}
          error={Boolean(errorFields[section]?.secondPaymentDay)}
          helperText={
            errorFields[section]?.secondPaymentDay
              ? errorFields[section]?.secondPaymentDay
              : null
          }
        />
      )}
      <AutocompleteField
        xs={4}
        fieldLabel={`Frecuencia de pago ${
          rFields.includes("periodicity") ? "(*)" : ""
        }`}
        fieldID="leads-field-periodicity"
        fieldInnerLabel="Seleccione una opción"
        value={
          handleSelectValue(
            fields.periodicity,
            PLANNED_SAVINGS_PERIODICITY,
            "value"
          ) || null
        }
        disabled
        handleChange={onChange.bind(this, "autocomplete", "periodicity")}
        options={getFiltersOptions(PLANNED_SAVINGS_PERIODICITY)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.periodicity)}
        helperText={
          errorFields[section]?.periodicity
            ? errorFields[section]?.periodicity
            : null
        }
        multiple={false}
      />
      <AutocompleteField
        xs={4}
        fieldLabel={`Forma de liquidación ${
          rFields.includes("settlementDisbursment") ? "(*)" : ""
        }`}
        fieldID="leads-field-settlementDisbursment"
        fieldInnerLabel="Seleccione una opción"
        value={
          { value: "office", label: "Cuenta de ahorro" }
          // Temporalmente se colocara office por defecto
          // en caso de cambiar colocar lo comentado debajo

          // handleSelectValue(
          //   fields.settlementDisbursment,
          //   settlementDisbursement,
          //   "value"
          // ) || null
        }
        disabled={true}
        handleChange={onChange.bind(this, "other", "settlementDisbursment")}
        options={getFiltersOptions(settlementDisbursement)}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        error={Boolean(errorFields[section]?.settlementDisbursment)}
        helperText={
          errorFields[section]?.settlementDisbursment
            ? errorFields[section]?.settlementDisbursment
            : null
        }
        multiple={false}
      />
      {(fields.settlementDisbursment?.value === "transfer" ||
        fields.settlementDisbursment === "transfer") && (
        <TextFieldComponent
          xs={4}
          fullWidth
          fieldLabel={`Cuenta de banco ${
            rFields.includes("bankAccount") ? "(*)" : ""
          }`}
          fieldID="leads-field-bankAccount"
          placeholder="Agregar cuenta"
          value={bankInfo.bankAccount || fields.bankaccount?.number || ""}
          disabled
          InputProps={{
            endAdornment: !fields.uniqueId_bankaccount && (
              <Button
                xs={2}
                variant="outlined"
                disableElevation
                style={{
                  borderRadius: 3,
                  borderColor: green[500],
                  color: green[500],
                  fontWeight: "bold",
                  fontSize: 10,
                }}
                onClick={() => setOpen(true)}
              >
                Agregar
              </Button>
            ),
          }}
        />
      )}
      {fields.quotation && (
        <TextFieldComponent
          xs={4}
          fullWidth
          fieldLabel={`Cotización ${
            rFields.includes("quotation") ? "(*)" : ""
          }`}
          fieldID="lead-field-quotation"
          value={quotationCode || "---"}
          error={Boolean(errorFields[section]?.quotation)}
          helperText={
            errorFields[section]?.quotation
              ? errorFields[section]?.quotation
              : null
          }
          disabled
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setOpenQtt(true)} size="small">
                <Visibility
                  style={{
                    color: green[500],
                  }}
                />
              </IconButton>
            ),
          }}
        />
      )}
      <Grid container direction="row">
        <CheckboxField
          xs={6}
          label={`Financiar monto de afiliación ${
            rFields.includes("membershipFinanced") ? "(*)" : ""
          }`}
          check={fields.membershipFinanced || false}
          handleChange={onChange.bind(this, "check", "membershipFinanced")}
          color="primary"
          textColor="#43A047"
        />
        <CheckboxField
          xs={6}
          label={`Financiar garantia de ahorro ${
            rFields.includes("savingsFinanced") ? "(*)" : ""
          }`}
          check={fields.savingsFinanced || false}
          handleChange={onChange.bind(this, "check", "savingsFinanced")}
          color="primary"
          textColor="#43A047"
        />
      </Grid>
    </Grid>
  );
}
