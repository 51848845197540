import React, { useState } from 'react';
import {
    Grid, Typography, Button
} from '@material-ui/core';
import { ClearAll } from '@material-ui/icons';
import { SelectField, SwitchComponent, TextFieldComponent } from './../../../components/SharedComponents/Fields';
import { blacklistReasons } from 'src/utils';

function FiltersHeader(props) {
    return (
        <Grid container justifyContent='space-between' alignItems="center">
            <Typography
                variant={props.typeVariant}
                className={props.typeClass}
                style={{ fontWeight: 'bold' }}
            >
                {props.filtersTitle}
            </Typography>
        </Grid>
    )
}

function Filters(props) {
    const [openFilter, setOpenFilter] = useState(true);
    const [otherReason, setOtherReason] = useState(false);
    const [fullName, setFullName] = useState('');
    const [identificationNumber, setIdentificationNumber] = useState('');
    const [reason, setReason] = useState('');

    const clearFilters = () => {
        setFullName('')
        setIdentificationNumber('')
        setReason('')
        setOtherReason(false)
    }

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    }
    const toggleReason = () => {
        setOtherReason(!otherReason);
        setReason('');
    }


    const handleFullNameChange = (event) => {
        setFullName(event.target.value);
    }

    const handleIdentificationChange = (event) => {
        setIdentificationNumber(event.target.value);
    }

    const handleReasonChange = (event) => {
        const data = (event && event.target) ? event.target.value : event;
        setReason(data);
    }

    const applyFilters = () => {
        const fields = {
            fullName: fullName,
            reason: reason,
            identificationNumber: identificationNumber,
        };
        Object.keys(fields).forEach(key => {
            if (!fields[key]) {
                delete fields[key];
            }
        });
        props.applyFilters(fields);
    }
    return (
        <Grid container className="filters-container">
            <FiltersHeader
                typeVariant="subtitle1"
                typeClass="section-header"
                filtersTitle="Filtros"
                btnClass={`filter-btn-close ${props.hideClass}`}
                iconClass="filter-icon-close"
                toggleFilter={toggleFilter}
                openFilter={openFilter}
                disableClose={props.disableCloseBtn}
            />
            <Grid container className={openFilter ? "filter-content" : "filter-content collided"}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <TextFieldComponent
                            xs={5}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Nombre completo"
                            fieldID="filter-field-reference"
                            fieldClass="fields"
                            fieldVariant="outlined"
                            placeholder="Nombre completo"
                            margin='dense'
                            fullWidth
                            value={fullName}
                            onChange={handleFullNameChange}
                        />
                        <TextFieldComponent
                            xs={5}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Identificacion"
                            fieldID="filter-field-reference"
                            fieldClass="fields"
                            fieldVariant="outlined"
                            placeholder="Identificacion"
                            margin='dense'
                            fullWidth
                            value={identificationNumber}
                            onChange={handleIdentificationChange}
                        />
                        <SwitchComponent
                            xs={2}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Razon Personalizada"
                            fieldID="filter-field-reason"
                            fieldClass="fields"
                            fieldVariant="outlined"
                            margin='dense'
                            fullWidth
                            value={otherReason}
                            onChange={toggleReason}
                        />
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        { !otherReason ? 
                            <SelectField
                                xs={5}
                                typeVariant="subtitle1"
                                typeClass="field-label"
                                fieldLabel="Razon de Bloqueo"
                                fieldID="requestNumber"
                                fieldClass="fields"
                                fieldVariant="outlined"
                                margin="dense"
                                value={reason || ""}
                                onChangeHandler={handleReasonChange}
                                options={blacklistReasons}
                           />
                        : 
                            <TextFieldComponent
                                xs={5}
                                typeVariant="subtitle1"
                                typeClass="field-label"
                                fieldLabel="Razon de bloqueo"
                                fieldID="filter-field-reference"
                                fieldClass="fields"
                                fieldVariant="outlined"
                                placeholder="Razon de Bloqueo"
                                margin='dense'
                                fullWidth
                                value={reason}
                                onChange={handleReasonChange}
                            />
                        }
                    </Grid>
                </Grid>
                {/* FILTERS CONTENT APPLY BUTTON */}
                <Grid container direction="row" justifyContent='space-between' style={{ marginTop: '35px' }}>

                    <Button
                        disableElevation
                        className="filter-clear-btn"
                        onClick={clearFilters.bind(this, false)}
                    >
                        limpiar
                        <ClearAll style={{ marginLeft: 8, fontSize: '19px' }} />
                    </Button>

                    <Button
                        disableElevation
                        className="filter-apply-btn"
                        onClick={applyFilters}
                    >
                        aplicar filtros
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    );
}
export { Filters }