import * as React from 'react';

import Buttom from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import ArrowbackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';

import { translations } from '../../utils';
import idNumberMaskInput from '../TextMaskInputComponents/idNumberMaskInput';
import passportMaskInput from '../TextMaskInputComponents/passportMaskInput';
import rncMaskInput from '../TextMaskInputComponents/rncMaskInput';
import interestRateInput from "src/components/TextMaskInputComponents/interestRateInput";


const styles = createStyles({
    button: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white',
    },
    checkboxLabel: {
        color: green[500],
        fontSize: 'medium'
    },
    formControl: {
        marginTop: 20,
        minWidth: 300,
        width: 300
    },
    root: {
        marginBottom: 5,
        marginTop: 5
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: 500

    }
});

class SelectInput extends React.Component {
    constructor(props) {
        super(props);
    this.state = {
        fullName: '',
        fullNameError: '',
        identificationType: '',
        identificationTypeError: '',
        identificationNumber: '',
        identificationNumberError: '',
        position: '',
        positionError: '',
        percentageOfParticipation: '',
        percentageOfParticipationError: '',
        open: false,
    };


        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.openDialogHandler = this.openDialogHandler.bind(this);
        this.closeDialogHandler = this.closeDialogHandler.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getIdentificationInput = this.getIdentificationInput.bind(this);
        this.map = this.map.bind(this);
    }
    handleChange(key) {
        return (event) => {
            this.setState({
                [key]: event.currentTarget.value,
                [key + 'Error']: ''
            });
        }
    }
    handleSelectChange(key) {
        return (event) => {
            this.setState({
                [key]: event.target.value,
                [key + 'Error']: ''
            });
        }
    }
    openDialogHandler() {
        this.setState({ open: true, fullName: this.state.fullName

        });
    }
    closeDialogHandler() {
        this.setState({ open: false });
    }
    getIdentificationInput(identificationType) {
        switch (identificationType) {
            case "national":
                return {
                    inputComponent: idNumberMaskInput,
                };
            case "RNC":
                return {
                    inputComponent: rncMaskInput,
                };
            case "passport":
                return {
                    inputComponent: passportMaskInput,
                };
            default:
                return {
                    title: "",
                };
        }
    }
    handleAdd(event) {

        let valid = true;
        const errorMessage = "Este campo no puede estar vacio";

        if (this.state.fullName.trim() === '') {
            valid = false;
            this.setState({ fullNameError: errorMessage });
        }

        if (this.state.identificationType.trim() === '') {
            valid = false;
            this.setState({ identificationTypeError: errorMessage });
        }

        if (this.state.identificationNumber.trim() === '') {
            valid = false;
            this.setState({ identificationNumberError: errorMessage });
        }

        if (this.state.position.trim() === '') {
            valid = false;
            this.setState({ positionError: errorMessage });
        }

        if (this.state.percentageOfParticipation.trim() === '') {
            valid = false;
            this.setState({ percentageOfParticipationError: errorMessage });
        }

        if (valid) {
            this.props.add({
                fullName: this.state.fullName,
                identificationType: this.state.identificationType,
                identificationNumber: this.state.identificationNumber,
                position: this.state.position,
                percentageOfParticipation: this.state.percentageOfParticipation
            }, "shareholderOrMajorityPartner");

            this.setState({ open: false, fullName: '', 
            identificationNumber: '',
            identificationType: '',
            position: '',
            percentageOfParticipation: '',
        });
        }

    }
    handleDelete(index) {
        return (event) => {
            this.props.delete(index, "shareholderOrMajorityPartner");
        }
    }
    map(partner, index) {
        return (
            <TableRow key={index}>
                <TableCell>{partner.fullName}</TableCell>
                <TableCell>{partner.identificationNumber}</TableCell>
                <TableCell align={'right'}>
                    <IconButton onClick={this.handleDelete(index)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }

    render() {
        const { classes, partners } = this.props;
        const { open } = this.state;

        return (
            <div className={classes.root}>
                <Dialog open={open} onClose={this.closeDialogHandler} >
                    <DialogActions>
                        <IconButton className={classes.button} onClick={this.closeDialogHandler}>
                            <ArrowbackIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent >
                        <TextField
                            label={translations.FULL_NAME}
                            className={classes.textField}
                            value={this.state.fullName}
                            onChange={this.handleChange("fullName")}
                            fullWidth={true}
                            error={this.state.fullNameError !== ''}
                            helperText={this.state.fullNameError}
                            InputLabelProps={{ style: styles.checkboxLabel }}
                        />
                        <FormControl className={classes.formControl} error={this.state.identificationTypeError !== ''}>
                                <InputLabel htmlFor="identificationType" shrink={true}>{translations.IDENTIFICATION_TYPE}</InputLabel>
                                    <Select
                                        value={this.state.identificationType}
                                        onChange={this.handleSelectChange('identificationType')}
                                        required={true}
                                        native={true}
                                        inputProps={{
                                            id: 'identificationType',
                                            name: 'identificationType',
                                        }}
                                    >
                                        <option value="">{"Seleccione una opcion"}</option>
                                        <option value="national">{translations.NATIONAL}</option>
                                        <option value="RNC">{translations.RNC}</option>
                                        <option value="passport">{translations.PASSPORT}</option>
                                    </Select>
                                </FormControl>
                                

                                <TextField
                                    label={translations.IDENTIFICATION_NUMBER}
                                    className={classes.textField}
                                    value={this.state.identificationNumber}
                                    onChange={this.handleChange("identificationNumber")}
                                    fullWidth={true}
                                    error={this.state.identificationNumberError !== ''}
                                    helperText={this.state.identificationNumberError}
                                    InputLabelProps={{ style: styles.checkboxLabel }}
                                    InputProps={this.getIdentificationInput(
                                        this.state.identificationType
                                    )}
                                />

                                <TextField
                                    label={"Cargo que ocupa"}
                                    className={classes.textField}
                                    value={this.state.position}
                                    onChange={this.handleChange("position")}
                                    fullWidth={true}
                                    error={this.state.positionError !== ''}
                                    helperText={this.state.positionError}
                                    InputLabelProps={{ style: styles.checkboxLabel }}
                                />

                                <TextField
                                    label={"Porcentaje de Participación"}
                                    className={classes.textField}
                                    value={this.state.percentageOfParticipation}
                                    onChange={this.handleChange("percentageOfParticipation")}
                                    fullWidth={true}
                                    error={this.state.percentageOfParticipationError !== ''}
                                    helperText={this.state.percentageOfParticipationError}
                                    InputLabelProps={{ style: styles.checkboxLabel }}
                                    InputProps={{
                                        inputComponent: interestRateInput,
                                      }}
                                />
                                        
                    </DialogContent>
                    <DialogActions>
                        <Buttom onClick={this.handleAdd} className={classes.button}>
                            {translations.SAVE}
                        </Buttom>
                    </DialogActions>
                </Dialog>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{translations.FULL_NAME}</TableCell>
                                <TableCell>{translations.IDENTIFICATION_NUMBER}</TableCell>
                                <TableCell align={'right'}>
                                {partners.length < this.props.tableLimit ?
                                    <IconButton className={classes.button} onClick={this.openDialogHandler}>
                                        <AddIcon />
                                    </IconButton>
                                : "Acciones"}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {partners.map(this.map)}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(SelectInput); 
