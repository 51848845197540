import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createElectronicMeansServiceContract } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import { createElectronicMeansServiceContract as create } from "../../../utils/axios";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
// import CreditorSelectInput from '../../Creditor/CreditorSelectInput';

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

interface IFormProps {
  classes: {
    attachmentIcon: any;
    checkboxLabel: any;
    controls: any;
    errorInput: any;
    formControl: any;
    greenButton: any;
    half: any;
    paper: any;
    root: any;
    textField: any;
    title: any;
  };

  user: IUser;
  handleSaveCallBack?(uid: string): void;
  createElectronicMeansServiceContract(
    electronicMeansServiceContract: IElectronicMeansServiceContract
  ): IAction<IElectronicMeansServiceContract[]>;
  changeSection(sectionNumber: number): void;
}

interface IFormState {
  [key: string]: string | number | boolean | string[] | IBankAccount[];

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;

  title: string;
  titleError: string;

  debtor: string[];
  debtorError: string;

  // creditor: string[];
  // creditorError: string;

  contractDate: string;
  contractDateError: string;
}
class Form extends React.Component<IFormProps, IFormState> {
  public state: IFormState = {
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    debtor: [],
    debtorError: "",
    // creditor: [],
    // creditorError: '',
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    title: "",
    titleError: "",
  };

  constructor(props: IFormProps) {
    super(props);
    // debtor
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // creditor
    // this.handleAddCreditor = this.handleAddCreditor.bind(this);
    // this.handleDeleteCreditor = this.handleDeleteCreditor.bind(this);

    // core event
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }
  // public handleAddCreditor(id: any) {
  //     this.setState({ creditor: [id] });
  // }
  // public handleDeleteCreditor(id: any) {
  //     this.setState({ creditor: [] });
  // }
  public handleAddClient(id: any) {
    this.setState({ debtor: [id] });
  }
  public handleDeleteClient(id: any) {
    this.setState({ debtor: [] });
  }
  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleCheckBoxChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  public handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    const contract = {
      contractDate: state.contractDate,
      debtor: state.debtor[0] || "",
      // creditor: (state.creditor[0] || ''),
      title: state.title,
    };

    this.setState({ loading: true });

    create(this.props.user.token, contract)
      .then((res) => {
        this.props.createElectronicMeansServiceContract(
          res.data.results ? res.data.results : res.data
        );
        successHandler(res, this, "Contrato de servicios electrónicos");
        this.handleReset();
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleReset() {
    this.setState({
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      debtor: [],
      debtorError: "",
      loading: false,
      title: "",
      titleError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;

    return (
      <form
        name="MA"
        noValidate={true}
        onSubmit={this.handleSubmit}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            Nuevo contrato de servicios electrónicos
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.PARTNERS}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtor}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>
              <br />
              {/* <div className={classes.half}>
                                <Typography variant="h6" className={classes.checkboxLabel}>{translations.CREDITORS}</Typography>
                                <CreditorSelectInput
                                    selectedCreditorsId={this.state.creditor}
                                    addCreditor={this.handleAddCreditor}
                                    deleteCreditor={this.handleDeleteCreditor}

                                />
                                <p className={classes.errorInput}>{this.state.creditorError}</p>
                            </div> */}
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createElectronicMeansServiceContract })(Form)
);
