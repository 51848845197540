import * as React from 'react';
import { connect } from 'react-redux';
import { translations } from 'src/utils';
import { fetchParticipants } from 'src/utils/axios';
import { Table, TableHead, TableBody, TableContainer, TableRow, TableCell, Badge, Typography, InputAdornment, Dialog, DialogActions, DialogContent, TablePagination } from '@material-ui/core';
import { List, ListItem, ListItemText, Checkbox, Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import purple from "@material-ui/core/colors/purple";
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FiberManualRecordIcon from '@material-ui/icons/Brightness1';
import FilterListIcon from '@material-ui/icons/FilterList';
import TuneIcon from '@material-ui/icons/Tune';
import FilterDialog from '../../../components/FilterDialog';
import OutlaySearchFilter from "./OutlaySearchFilter";
import axios from 'axios';
import { API_KEY } from "../../../utils/axios";
import { catchError } from 'src/utils';
import { Clear, Description } from '@material-ui/icons';
import OutlayView from '../OutlayView';
import { borderRadius } from 'react-select/lib/theme';

const styles = createStyles({
    paginationGroup: {
        bottom: 0,
        display: 'flex',
        height: 50,
        justifyContent: 'center',
        justifyItems: 'center',
        textAlign: 'center',
    },
    root: {
        bottom: 51,
        boxSizing: 'border-box',
        padding: 10,
        top: 65,
    },
    tableContainer: {
      height: 390,
      overflow: "auto",
    },
    button: {
      '&:hover': {
          backgroundColor: green[200]
      },
      backgroundColor: green[500],
      color: 'white'
  },
  container: {
    border: "1px solid rgba(25,25,25,0.2)",
    borderRadius: 7,
    boxShadow: 2,
  }
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: 16,
    textAlign: "center",
  },
  body: {
    fontSize: 14,
    textAlign: "center",
  },
}))(TableCell);

class OutlaySearchSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      isSelected: false,
      selectedOutlays: [],
      selectedId: "",
      advanceFilter: false,
      fileDownloader: false,
      filterField: "nombre_socio",
      rowsPerPage: 10,
      limit: 50,
      page: 0,
      open: false,
      openOutlay: false,
      searchValue: "",
      filtrar: "",
      searching: false,
      dbsStatus: {
        ACTIVO: "Activo",
        REEMBOLSADO: "Reembolsado",
      },
    };

    this.openDialog = this.openDialog.bind(this);
    this.handleOpenOutlayView = this.handleOpenOutlayView.bind(this);
    this.handleCloseOutlayView = this.handleCloseOutlayView.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.openFilterDialog = this.openFilterDialog.bind(this);
    this.closeFilterDialog = this.closeFilterDialog.bind(this);
    this.openFileDownloader = this.openFileDownloader.bind(this);
    this.closeFileDownloader = this.closeFileDownloader.bind(this);

    this.mapFunction = this.mapFunction.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.search = this.search.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }
  openDialog() {
    this.setState({ open: true });
  }
  handleOpenOutlayView() {
    this.setState({openOutlay: true})
  }
  handleCloseOutlayView() {
    this.setState({openOutlay: false})
  }
  componentDidMount() {
    fetchParticipants({
      page: 1,
      limit: 20,
      participantType: "client",
      token: this.props.user.token,
    }).then((res) => {
      this.setState({ clients: res.data.results });
    });
    this.handleCloseOutlayView()
  }
  handleDialogClose(value) {
    this.setState({
      filterField: value,
      open: false,
    });
  }

  openFilterDialog() {
    this.setState({ advanceFilter: true });
  }
  closeFilterDialog() {
    this.setState({ advanceFilter: false });
  }
  openFileDownloader() {
    this.setState({ fileDownloader: true });
  }
  closeFileDownloader() {
    this.setState({ fileDownloader: false });
  }
  search(params) {
    this.props.advanceSearch(params);
  }


  handleCheckboxChange(id) {
    return (event) => {
      const { selectedOutlays } = this.state;
      const index = selectedOutlays.indexOf(id);

      if (index === -1) {
        this.setState({
          selectedOutlays: [...selectedOutlays, id],
        });
      } else {
        this.setState({
          selectedOutlays: selectedOutlays.filter((item) => item !== id),
        });
      }
    };
  }

  componentDidUpdate(){
    if(this.props.loading && this.state.selectedOutlays.length > 0){
      {this.setState({ selectedOutlays: [], isSelected: false })}
    }
  }

  selectAllCheckboxes(outlays) {
    const { isSelected } = this.state;

    if (isSelected) {
      this.setState({
        selectedOutlays: [],
        isSelected: false,
      });
    } else {
      const allOutlayIds = outlays.map((outlay) => outlay.idDbs);
      this.setState({
        selectedOutlays: allOutlayIds,
        isSelected: true,
      });
    }
  }

  handleDownloadClick = () => {
    const { selectedOutlays } = this.state;

    const idString = selectedOutlays.join(',');
    axios({
      headers: {
        Authorization: "Bearer " + this.props.user.token,
        "Content-Type": "text/html",
      },
      method: "get",
      url: `${API_KEY}/outlay/outlay/render_html`,
      params:{
        id_dbs: idString,
      },
    })
      .then((res) => {
        const file = new Blob([res.data], { type: "text/html" });
        const fileURL = URL.createObjectURL(file);
        const NewWindow = window.open();
        NewWindow.location.href = fileURL;
      })
      .catch((err) => {
        catchError(err, this, translations.OUTLAY);
      })
  }

  

  mapFunction(outlay) {
    let transactionStatus = "";
    let transactionStatusColor = "";
    let wordColor= "";

    switch (outlay.dbsStatus) {
      case "ACTIVO":
        transactionStatusColor = green[500];
        wordColor = 'white';
        break;
      case "REEMBOLSADO":
        transactionStatusColor = yellow[500];
        wordColor= 'black'
        break;
      default:
        transactionStatusColor = blue[500];
        wordColor= 'white';
        break;
    }

    const capitalize = (str)=>{
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const isChecked = this.state.selectedOutlays.includes(outlay.idDbs);
    if (outlay.idDbs === "none") {
      return (
        // <ListItem
        //   divider={true}
        //   key={outlay.idDbs}
        //   button={true}
        //   onClick={this.selectItem(outlay.idDbs || "")}
        // >
        //   <Checkbox
        //     checked={isChecked}
        //     onChange={this.handleCheckboxChange(outlay.idDbs || "")}
        //   />
        //   <ListItemText primary={outlay.title} secondary={transactionStatus} />
        // </ListItem>
        <em style={{color:'#424242', display: 'flex', justifyContent: 'center', margin: 40}}>NO HAY RESULTADOS</em>
      );
    }

    return (
      <TableRow key={outlay.idDbs} style={{height: 40}}>
        <StyledTableCell>
          <Checkbox
            checked={isChecked}
            onChange={this.handleCheckboxChange(outlay.idDbs || "")}
          />
        </StyledTableCell>
        <StyledTableCell>{outlay.nombreSocio}</StyledTableCell>
        <StyledTableCell>
          {parseFloat(outlay.montoSolicitado).toLocaleString("en-US", {
            currency: "USD",
          })}
        </StyledTableCell>
        <StyledTableCell>{outlay.fechaOtorgamiento}</StyledTableCell>
        <StyledTableCell style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              backgroundColor: transactionStatusColor,
              color: wordColor,
              fontWeight: 'bold',
              borderRadius: 18,
              width: 150,
              height: 25,
              paddingTop: 5,
            }}
          >
            {capitalize(outlay.dbsStatus)}
          </p>
        </StyledTableCell>
        <StyledTableCell>
          <Button
            style={{ borderRadius: 18, backgroundColor: "#c9c6c6" }}
            onClick={() => this.selectItem(outlay.idDbs || "")}
          >
            ...
          </Button>
        </StyledTableCell>
      </TableRow>
    );
  }

  selectItem = (id) => {
    this.setState({ selectedId: id });
    this.handleOpenOutlayView();
  };
  
  fetchData() {
    this.props.fetchData({token: this.props.user.token });
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    this.setState({ rowsPerPage: newRowsPerPage });
  };
  render() {
    const { page, rowsPerPage } = this.state;
    const { classes, outlays, loading, pagination } = this.props;
    return (
      <Grid className={classes.container} style={{ margin: 20 }}>
        <Dialog
          open={this.state.openOutlay}
          onClose={this.handleCloseOutlayView}
          fullWidth={true}
          maxWidth={"lg"}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.handleCloseOutlayView}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <OutlayView outlayId={this.state.selectedId} />
          </DialogContent>
        </Dialog>
        <div className={classes.root}>
          <Grid item container spacing={3} justifyContent="flex-end">
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 5px 10px 10px",
              }}
            >
              <Typography style={{ fontWeight: "bold", fontSize: 16 }}>
                Desembolsos
              </Typography>
              <Button
                style={{ marginLeft: "20px" }}
                variant="contained"
                className={
                  this.state.selectedOutlays < 1
                    ? "action-method-btn disabled"
                    : "action-method-btn save"
                }
                size="small"
                onClick={this.handleDownloadClick}
                disabled={this.state.selectedOutlays < 1}
              >
                <Description
                  style={{
                    fontSize: 15,
                    color: "#fff",
                    margin: "0 .5rem .1rem 0",
                  }}
                />
                Generar PDF
              </Button>
              <TextField
                style={{ width: "300px", marginLeft: "auto" }}
                label="Buscar"
                value={this.state.filtrar}
                onChange={(event) =>
                  this.setState({ filtrar: event.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {this.state.filtrar && (
                        <IconButton
                          onClick={() => this.setState({ filtrar: "" })}
                          edge="end"
                        >
                          <Clear />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <div>
            {loading ? (
                <ListItem>
                  <CircularProgress
                    style={{ color: green[500] }}
                    thickness={7}
                  />
                  <ListItemText primary={translations.LOADING} />
                </ListItem>
            ) : outlays.length === 0 ? (
              [{ title: translations.NOT_FOUND, idDbs: "none" }].map(
                this.mapFunction
              )
            ) : (
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <Checkbox
                          checked={this.state.isSelected}
                          onChange={() => this.selectAllCheckboxes(outlays)}
                        />
                      </StyledTableCell>
                      <StyledTableCell>Socio</StyledTableCell>
                      <StyledTableCell>Monto Solicitado</StyledTableCell>
                      <StyledTableCell>Fecha de Otorgamiento</StyledTableCell>
                      <StyledTableCell>Estado</StyledTableCell>
                      <StyledTableCell>Detalles</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {outlays
                      .filter((outlay) =>
                        outlay.nombreSocio
                          .toLowerCase()
                          .includes(this.state.filtrar.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(this.mapFunction)}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
        {!loading && pagination ? (
          <div>
            <TablePagination
              rowsPerPageOptions={[5, 15, 25]}
              component="div"
              count={outlays?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </Grid>
    );
  }
}
function mapStateToProps(state) {
    return {
        outlays: state.outlays,
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps)(OutlaySearchSection)
);