import * as React from "react";
import { connect } from "react-redux";
import { translations } from "src/utils";

import { List, ListItem, ListItemText } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FilterListIcon from "@material-ui/icons/FilterList";

import FilterDialog from "../../../components/FilterDialog";

const styles: any = createStyles({
  filterButton: {
    width: "20%",
  },
  paginationGroup: {
    border: ".5px solid rgba(25,25,25,0.2)",
    bottom: 0,
    display: "flex",
    height: 50,
    justifyContent: "center",
    justifyItems: "center",
    position: "absolute",
    textAlign: "center",
    width: 280,
  },
  root: {
    borderRightColor: "rgba(25,25,25,0.2)",
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    bottom: 51,
    boxSizing: "border-box",
    overflowY: "auto",
    padding: 10,
    paddingLeft: 15,
    position: "absolute",
    top: 65,
    width: 280,
  },
  textField: {
    width: "80%",
  },
});

interface ISearchSectionProps {
  classes: { [key: string]: string };
  loading: boolean;
  bankAccounts: IBankAccount[];
  user: IUser;
  pagination: boolean;
  fetchData(params: IFetchBankAccountParams): void;
  handleClick(bankAccountId: string): void;
}
interface ISearchSectionState {
  filterField: string;
  limit: number;
  offset: number;
  open: boolean;
  searchValue: string;
  searching: boolean;
}
class SearchSection extends React.Component<
  ISearchSectionProps,
  ISearchSectionState
> {
  public state: ISearchSectionState = {
    filterField: "ownerName",
    limit: 20,
    offset: 0,
    open: false,
    searchValue: "",
    searching: false,
  };
  constructor(props: ISearchSectionProps) {
    super(props);

    this.openDialog = this.openDialog.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.mapFunction = this.mapFunction.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
  }
  public openDialog() {
    this.setState({ open: true });
  }
  public handleDialogClose(value: string) {
    this.setState({
      filterField: value,
      open: false,
    });
  }
  public handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!this.state.searching || event.currentTarget.value === "") {
      const params: IFetchBankAccountParams = {
        limit: this.state.limit,
        offset: 0,
        token: this.props.user.token,
      };
      params[this.state.filterField] = event.currentTarget.value;

      this.setState({
        offset: 0,
        searchValue: event.currentTarget.value,
        searching: true,
      });

      this.props.fetchData(params);

      setTimeout(() => this.setState({ searching: false }), 300);
    } else {
      this.setState({ searchValue: event.currentTarget.value });
    }
  }

  public mapFunction(bankAccount: IBankAccount) {
    return (
      <ListItem
        divider={true}
        key={bankAccount.uniqueId}
        button={true}
        onClick={this.selectItem(bankAccount.uniqueId || "")}
      >
        <ListItemText
          primary={bankAccount.name}
          secondary={bankAccount.number}
        />
      </ListItem>
    );
  }
  public selectItem(bankAccountId: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.props.handleClick(bankAccountId);
    };
  }
  public fetchData() {
    this.props.fetchData({ token: this.props.user.token });
  }
  public prev() {
    if (this.state.offset !== 0) {
      this.setState((prevState) => {
        const params: IFetchBankAccountParams = {
          limit: prevState.limit,
          offset: prevState.offset - 20,
          token: this.props.user.token,
        };
        params[this.state.filterField] = this.state.searchValue;

        this.props.fetchData(params);

        return {
          offset: prevState.offset - 20,
        };
      });
    }
  }
  public next() {
    this.setState((prevState) => {
      const params: IFetchBankAccountParams = {
        limit: prevState.limit,
        offset: prevState.offset + 20,
        token: this.props.user.token,
      };
      params[this.state.filterField] = this.state.searchValue;

      this.props.fetchData(params);

      return {
        offset: prevState.offset + 20,
      };
    });
  }
  public render() {
    const { classes, bankAccounts, loading, pagination } = this.props;

    return (
      <div>
        <div className={classes.root}>
          <FilterDialog
            options={[
              { caption: "Nombre del dueño", value: "ownerName" },
              { caption: translations.ACCOUNT_NUMBER, value: "number" },
            ]}
            open={this.state.open}
            onClose={this.handleDialogClose}
            selectedValue={this.state.filterField}
          />
          <div>
            <TextField
              className={classes.textField}
              value={this.state.searchValue}
              onChange={this.handleChange}
              placeholder={translations.SEARCH}
            />
            <IconButton
              className={classes.filterButton}
              onClick={this.openDialog}
            >
              <FilterListIcon />
            </IconButton>
          </div>
          <div>
            <List>
              {loading ? (
                <ListItem>
                  <CircularProgress
                    style={{ color: green[500] }}
                    thickness={7}
                  />
                  <ListItemText primary={translations.LOADING} />
                </ListItem>
              ) : bankAccounts.length === 0 ? (
                [{ name: translations.NOT_FOUND, uniqueId: "none" }].map(
                  this.mapFunction
                )
              ) : (
                bankAccounts.map(this.mapFunction)
              )}
            </List>
          </div>
        </div>
        {!loading && pagination ? (
          <div className={classes.paginationGroup}>
            <IconButton onClick={this.prev}>
              <ArrowBackIcon />
            </IconButton>
            <IconButton>{this.state.offset / 20 + 1}</IconButton>
            <IconButton onClick={this.next}>
              <ArrowForwardIcon />
            </IconButton>
          </div>
        ) : null}
      </div>
    );
  }
}
function mapStateToProps(state: IState) {
  return {
    bankAccounts: state.bankAccounts,
    user: state.user,
  };
}
export default withStyles(styles)(connect(mapStateToProps)(SearchSection));
