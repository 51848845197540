import React, { useState } from 'react';
import {
    Grid, Typography, IconButton, Button
} from '@material-ui/core';
import {
    Close, Tune, ClearAll
} from '@material-ui/icons';
import {
    DateField, TextFieldComponent, AutocompleteField, SearchComponent
} from './../../../components/SharedComponents/Fields';
import { statusColors } from '../GeneralMethods';

function FiltersHeader(props) {

    return (
        <Grid container justifyContent='space-between' alignItems="center">

            <Typography
                variant={props.typeVariant}
                className={props.typeClass}
                style={{fontWeight: 'bold'}}
            >
                {props.filtersTitle}
            </Typography>

            <IconButton
                className={props.btnClass}
                onClick={props.toggleFilter}
                disabled={props.disableClose}
            >
                {
                    props.openFilter ?
                    <Close
                        className={props.iconClass}
                    />
                    :
                    <Tune
                        className={props.iconClass}
                    />
                }

            </IconButton>
        </Grid>
    )
}

function Filters(props) {

    const [fieldData, setFieldData] = useState({});
    const [openFilter, setOpenFilter] = useState(true);
    const clearFilters = () => {
        setFieldData({});
    }

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    }
    const handleFieldData = (field, type, event, value, subtype) => {
        let data;
        switch(type) {
            case 'text':
                data = event.target.value;
                break;
            case 'date':
                data = {...fieldData[field], [subtype]: event};
                // data = event ? dateFormat(event) : "";
                break;
            case 'autocomplete':
                data = value;
                break;
            case 'any':
                data = event;
                break;
            default:
                data = event.target ? event.target.value : event.value;
                break;
        }
        setFieldData({...fieldData, [field]: data});
    }

    const getFiltersOptions = (option) => {
        return(
            (option ? option : [])
        );
    }
    const applyFilters = () => {
        const data = {
            fullname: fieldData.fullname,
            identification: fieldData.identification,
            dateFrom: fieldData.date?.start,
            dateTo: fieldData.date?.end,
            states: fieldData.states ? fieldData.states.map(item => item.state).join(',') : null,
            institution: fieldData.institution?.employerCode,
        };
        Object.keys(data).forEach(key => {
            if(!data[key]) {
                delete data[key];
            }
        });
        props.applyFilters(data);
    }
    return(
        <Grid container className="filters-container">
            <FiltersHeader
                typeVariant="subtitle1"
                typeClass="section-header"
                filtersTitle="Filtros"
                btnClass={("filter-btn-close ") + props.hideClass}
                iconClass="filter-icon-close"
                toggleFilter={toggleFilter}
                openFilter={openFilter}
                disableClose={props.disableCloseBtn}
            />
            <Grid container className={openFilter ? "filter-content" : "filter-content collided"}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <TextFieldComponent 
                            xs={4}
                            fullWidth
                            fieldLabel= {`Nombre del participante`}
                            fieldID="filter-field-fullname"
                            placeholder="Digite un nombre"
                            value={fieldData.fullname || ''}
                            onChange={(event, value) => { handleFieldData('fullname', 'text', event, value) }}
                        />
                        <TextFieldComponent 
                            xs={4}
                            fullWidth
                            fieldLabel= {`Número de documento`}
                            fieldID="filter-field-identification"
                            placeholder="Ej: 402..."
                            value={fieldData.identification || ''}
                            onChange={(event, value) => { handleFieldData('identification', 'text', event, value) }}
                        />
                        <SearchComponent 
                            xs={4}
                            containerClass="field-container"
                            fieldLabel="Empresas"
                            fieldID="leads-field-enterprises"
                            fieldInnerLabel="Seleccione una empresa"
                            handleChange={
                                event => { handleFieldData('institution', 'any', event) }
                            }
                            optionType={"institutions"}
                            limitTags={1} 
                        />
                         <AutocompleteField
                            xs={4}
                            fieldLabel="Estados"
                            fieldID="filter-field-states"
                            fieldInnerLabel="Seleccione estado(s)"
                            value={fieldData.states || []}
                            handleChange={(event, value) => { handleFieldData('states', 'autocomplete', event, value) }}
                            options={getFiltersOptions(statusColors)?.filter(item => item.state !== 'default')}
                            getOptionLabel={(option) => option.fullName}
                            getOptionSelected={(option, value) => option.state === value.state}
                            multiple={true}
                            limitTags={1}
                        />
                        <DateField
                            xs={4}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Fecha inicio"
                            dateFieldID="filter-date-start"
                            fullWidth={true}
                            dateFieldClass="date-field"
                            inputVariant="outlined"
                            dateFieldLabel="Ej: 15/10/2022"
                            format="dd/MM/yyyy"
                            value={(fieldData.date && fieldData.date.start) ? fieldData.date.start : null}
                            dateType="start"
                            handleChange={(event, value) => { handleFieldData('date', 'date', event, value, 'start') }}
                        />
                        <DateField
                            xs={4}
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Fecha fin"
                            dateFieldID="filter-date-end"
                            fullWidth={true}
                            dateFieldClass="date-field"
                            inputVariant="outlined"
                            dateFieldLabel="Ej: 20/10/2022"
                            format="dd/MM/yyyy"
                            value={(fieldData.date && fieldData.date.end) ? fieldData.date.end  : null}
                            dateType="end"
                            handleChange={(event, value) => { handleFieldData('date', 'date', event, value, 'end') }}
                        />
                    </Grid>
                </Grid>
                {/* FILTERS CONTENT APPLY BUTTON */}
                <Grid container direction="row" justifyContent='space-between' style={{marginTop:'35px'}}>

                    <Button
                        disableElevation
                        className="filter-clear-btn"
                        onClick={clearFilters.bind(this, false)}
                    >
                        limpiar
                        <ClearAll style={{marginLeft:8, fontSize: '19px'}}/>
                    </Button>

                    <Button
                        disableElevation
                        className="filter-apply-btn"
                        onClick={applyFilters}
                    >
                        aplicar filtros
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    );
}
export { Filters }