import jwtDecode from 'jwt-decode';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, grey } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';

import { AUTHENTICATION_TYPES, logUser } from '../../actions';
import AlertDialog from '../../components/AlertDialog';
import { translations } from '../../utils/';
import { axiosAuthentication } from '../../utils/axios';
import { setToken } from '../../utils/index';
import logo from './logo.png';

const styles: any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[800],
    },
    backgroundColor: green[500],
    color: "white",
    display: "block",
    margin: "auto",
    marginTop: 40,
    width: 200,
  },
  circularProgress: {
    color: green[500],
    marginTop: 10,
  },
  icon: {
    backgroundColor: grey[800],
    fontSize: 42,
  },
  input: {
    marginTop: 25,
  },
  logo:{
    width: 290,
    height: 209,
    borderRadius: 20
  },
  paper: {
    paddingBottom: 30,
    textAlign: "center",
    width: 290,
    borderRadius: 20
  },
  root: {
    alignContent: "center",
    alignItems: "center",
    background: "linear-gradient(to right, #237A57, #093028)",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
  },
  text: {
    backgroundColor: grey[800],
    color: "white",
    paddingBottom: 40,
    paddingTop: 30,
  },
});

interface ILoginFormProps {
  classes: {
    [name: string]: string;
  };
  logUser(user: IUser): IAction<IUser>;
}
interface ILoginFormState {
  [name: string]: string | boolean;
  error: boolean;
  loading: boolean;
}

class LoginForm extends React.Component<
  ILoginFormProps & RouteComponentProps<any>,
  ILoginFormState
> {
  public state = {
    error: false,
    loading: false,
    password: "", // qwerty29
    username: "", // luissanchez
  };
  constructor(props: ILoginFormProps & RouteComponentProps<any>) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.login = this.login.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }
  public onChange(name: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [name]: event.currentTarget.value,
      });
    };
  }

  public handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    this.setState({ loading: true });

    axiosAuthentication(AUTHENTICATION_TYPES.LOG_USER, this.state)
      .then((res) => this.login(res.data.access, res.data.refresh))
      .catch((err) => {
        this.setState({
          error: true,
          loading: false,
        });
      });
  }

  public login(token: string, refresh: string) {
    const user: IUser = {
      token,
      username: jwtDecode<IUser>(token).username,
      user_id: jwtDecode<IUser>(token).user_id
    };
    setToken(token, refresh);
    this.props.logUser(user);
    this.props.history.push("/tmsdashboard");
  }
  public handleDialogClose() {
    this.setState({ error: false });
  }
  public render() {
    const { classes } = this.props;
    return (
      <form className={classes.root} onSubmit={this.handleSubmit}>
        <AlertDialog
          message={translations.ERROR_LOGIN_MESSAGE}
          title="Error"
          open={this.state.error}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <img src={logo} className={classes.logo} />
          {this.state.loading ? (
            <CircularProgress
              className={classes.circularProgress}
              thickness={7}
            />
          ) : (
            <div>
              <TextField
                autoFocus={true}
                required={true}
                value={this.state.username}
                onChange={this.onChange("username")}
                className={classes.input}
                name="username"
                label={translations.USER_NAME}
              />
              <TextField
                required={true}
                value={this.state.password}
                onChange={this.onChange("password")}
                className={classes.input}
                name="password"
                label={translations.PASSWORD}
                type="password"
              />
              <Button
                variant="contained"
                type="submit"
                className={classes.button}
              >
                {translations.LOG_IN}
              </Button>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

export default connect(null, { logUser })(withStyles(styles)(LoginForm));
