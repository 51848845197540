
import { ELECTRONIC_MEANS_SERVICE_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case ELECTRONIC_MEANS_SERVICE_TYPES.FETCH_ALL:
            return action.payload || [];
        case ELECTRONIC_MEANS_SERVICE_TYPES.FETCH:
            return state.map(electronicMeansServiceContract => (action.payload !== undefined && electronicMeansServiceContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: electronicMeansServiceContract);
        case ELECTRONIC_MEANS_SERVICE_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case ELECTRONIC_MEANS_SERVICE_TYPES.UPDATE:
            return state.map(electronicMeansServiceContract => (action.payload !== undefined && electronicMeansServiceContract.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : electronicMeansServiceContract);
        case ELECTRONIC_MEANS_SERVICE_TYPES.DELETE:
            return state.filter(electronicMeansServiceContract => action.payload !== undefined && electronicMeansServiceContract.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}