import React from "react";

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import moneyInput from '../../../components/TextMaskInputComponents/moneyInput'
import { TableCell, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

class InlineEditForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props.x
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleCurrencyChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || '' });
    }
  }

  handleAutocompleteChange(key, obj) {
    /* Handle the state of Autocomplete components */
    if (obj) {
      this.setState({
        [key]: obj.value
      });
    }
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleSelectChange(key) {
    return (event) => {
      this.setState({
        [key]: event.target.value,
      });
    };
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.table.handleChange(this.props.i, this.state)
  }

  render() {
    const pProps = this.props.table
    const { i, style } = this.props
    return [
      <TableCell style={style.TableCell} key="index-cell">{i + 1}</TableCell>,
      pProps.header.map((y, k) => (
        <TableCell style={style.TableCell} key={`tc-${k}`}>
          {y.type === 'select' ?
            <Select
              disabled={y.disabled}
              onChange={this.handleSelectChange(y.prop)}
              value={this.state[y.prop]}
              native={true}
              inputProps={{
                id: y.prop,
                name: y.prop,
              }}
            >
              <option defaultValue="" >---Elija una opcion---</option>
              {y.options.map((opt, indx) => {
                return <option key={indx} value={opt.value}>{opt.label || opt.name || opt.fullName}</option>
              })}
            </Select>
            :
            y.type === 'number' ?
              <TextField
                disabled={y.disabled}
                placeholder={y.name}
                name={y.prop}
                onChange={this.handleCurrencyChange(y.prop)}
                value={this.state[y.prop]}
                InputProps={{ inputComponent: moneyInput }} />
              : y.type === 'auto' ?
                <Autocomplete
                  id={y.prop}
                  options={y.options}
                  getOptionLabel={(options) => options.fullName}
                  onChange={(e, value) => this.handleAutocompleteChange(y.prop, value)}
                  renderInput={(params) => <TextField {...params} value={this.state[y.prop]} />} />

                :
                <TextField
                  disabled={y.disabled}
                  placeholder={y.name}
                  type={y.type}
                  name={y.prop}
                  onChange={this.handleChange(y.prop)}
                  value={this.state[y.prop]} />
          }
        </TableCell>
      )),
      <TableCell style={style.TableCell} key="icon-cell">
        <IconButton onClick={this.onSubmit}>
          <CheckIcon />
        </IconButton>
        <IconButton onClick={pProps.handleStopEdit}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    ];
  }
}

export default InlineEditForm;
