
import { CLIENT_TYPES } from '../actions';

export default function(state: IParticipant[] = [], action: IAction<IParticipant[]>): IParticipant[] {
    switch(action.type) {
        case CLIENT_TYPES.FETCH_ALL:
            return action.payload || [];
        case CLIENT_TYPES.FETCH:
            return state.map(client => (action.payload !== undefined && client.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: client);
        case CLIENT_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case CLIENT_TYPES.UPDATE:
            return state.map(client => (action.payload !== undefined && client.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : client);
        case CLIENT_TYPES.DELETE:
            return state.filter(client => action.payload !== undefined && client.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}