import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import CheckBox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";

import { createClient } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import attorneyNumberMaskInput from "../../../components/TextMaskInputComponents/attorneyNumberMaskInput";
import idNumberMaskInput from "../../../components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "../../../components/TextMaskInputComponents/passportMaskInput";
import phoneMaskInput from "../../../components/TextMaskInputComponents/phoneMaskInput";
import {
  affiliateState,
  affiliateType,
  catchError,
  civilStatus,
  educationLevel,
  gender,
  IDENTIFICATION_TYPE,
  nationalitiesToOptions,
  prefix,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
  dwellingTypes,
} from "../../../utils";
import { createParticipant, fetchNationalities } from "../../../utils/axios";
import Address from "../../../components/Address/Address";
import ProfessionSelectInput from "../../Profession/ProfessionSelectInput";
import { MenuItem } from "@material-ui/core";
// import moneyInput from '../../../components/TextMaskInputComponents/moneyInput';
import validator from "validator";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    display: "none",
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  checkboxLabelReactSelect: {
    color: green[500],
    fontSize: "small",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    overflowY: "auto",
    padding: 20,
    paddingBottom: 64,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

// interface IClientFormProps {
//   classes: { [key] };

//   user: IUser;
//   newClient?;
//   handleSaveCallback(uid): void;
//   createClient(client: IParticipant): IAction<IParticipant[]>;
//   changeSection(sectionNumber: number): void;
// }

class ClientForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      additionalPhone: "",
      additionalPhoneError: "",
      affiliateState: "not_affiliate",
      affiliateStateError: "",
      affiliateType: "service_user_affiliate",
      affiliateTypeError: "",
      birthDate: "",
      birthDateError: "",
      cellPhone: "",
      cellPhoneError: "",
      city: "",
      cityError: "",
      civilStatus: "single",
      civilStatusError: "",
      country: "República Dominicana",
      countryError: "",
      dialog: false,
      dialogTitle: "",
      email: "",
      emailError: "",
      errorMessage: "",
      educationLevel: "none",
      educationLevelError: "",
      fullName: "",
      fullNameError: "",
      gender: "m",
      genderError: "",
      homePhone: "",
      homePhoneError: "",
      identificationExpirationDate: "",
      identificationExpirationDateError: "",
      identificationNumber: "",
      identificationNumberError: "",
      identificationType: "national",
      identificationTypeError: "",
      loading: false,
      nationality: { label: "", value: "" },
      nationalityError: "",
      nationalityOptions: [],
      nickName: "",
      nickNameError: "",
      prefix: "mister",
      prefixError: "",
      profession: [],
      reference: "",
      referenceError: "",
      region: "",
      regionError: "",
      sector: "",
      sectorError: "",
      state: "",
      stateError: "",
      workPhone: "",
      workPhoneError: "",
      workPhoneExtension: "",
      workPhoneExtensionError: "",
      vehicleColor: "",
      vehicleColorError: "",
      vehicleBrand: "",
      vehicleBrandError: "",
      vehicleModel: "",
      vehicleModelError: "",
      vehicleYear: 0,
      vehicleYearError: "",
      hasDebtorAVehicle: false,
      hasDebtorAVehicleError: "",
      vehicleFinancialStatus: "financed",
      vehicleFinancialStatusError: "",
      // house
      // houseMonthlyPayment: 0,
      // houseMonthlyPaymentError: '',
      // houseMonthlyPaymentCurrency: "DOP",
      // houseMonthlyPaymentCurrencyError: '',
      dwellingType: "",
      // zipCode: "",
      hasAccessToCoopvirtual: false,
      password: "",
      passwordConfirm: "",
      passwordError: "",
      passwordConfirmError: "",
    };

    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.handleAddProfession = this.handleAddProfession.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteProfession = this.handleDeleteProfession.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
  }
  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || 0 });
    };
  }
  handleCheckBoxChange(event, key) {
    this.setState({ [key]: event.target.checked });
  }
  async componentDidMount() {
    this.setState({ loading: false });

    if (this.props.newClient) {
      this.setState(this.props.newClient);
    }
    fetchNationalities({ token: this.props.user.token })
      .then((res) => {
        this.setState(
          {
            nationalityOptions: nationalitiesToOptions(
              res.data.results ? res.data.results : res.data
            ),
          },
          () => {
            this.setState((prevState) => ({
              nationality: prevState.nationalityOptions[0],
            }));
          }
        );
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  handleAddProfession(id) {
    this.setState({ profession: [id] });
  }
  handleDeleteProfession() {
    this.setState({ profession: [] });
  }
  handleReactSelectChange(nationality) {
    this.setState({ nationality });
  }
  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  handleSelectChange(event, key) {
    if (key === "password" || key === "passwordConfirm") {
      if (/^(\d{4}|\d{6})$/.test(event.target.value)) {
        this.setState({
          [key]: event.target.value,
          [key + "Error"]: "",
        });
      } else {
        this.setState({
          [key]: event.target.value,
          [key +
          "Error"]: "El pin debe ser numerico y de cuatro (4) caracteres.",
        });
      }
      return false;
    }
    this.setState({
      [key]: event.target.value,
      [key + "Error"]: "",
    });
  }
  handleSelect(key, value) {
    this.setState({
      [key]: value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    resetError(this);
    event.stopPropagation();

    const client = {
      additionalPhone: this.state.additionalPhone,
      address: {
        country: this.state.country,
        state: this.state.state,
        region: this.state.region,
        city: this.state.city,
        sector: titleCaseHandler(this.state.sector),
        reference: this.state.reference,
      },
      affiliateState: this.state.affiliateState,
      affiliateType: this.state.affiliateType,
      birthDate: this.state.birthDate,
      cellPhone: this.state.cellPhone,
      civilStatus: this.state.civilStatus,
      email: this.state.email,
      educationLevel: this.state.educationLevel,
      fullName: this.state.fullName,
      gender: this.state.gender,
      homePhone: this.state.homePhone,
      identificationExpirationDate:
        this.state.identificationExpirationDate || null,
      identificationNumber: this.state.identificationNumber.replace(/-/g, ""),
      identificationType: this.state.identificationType,
      nationality: this.state.nationality,
      nickName: this.state.nickName,
      participantType: "client",
      prefix: this.state.prefix,
      profession: this.state.profession[0],
      workPhone: this.state.workPhone,
      workPhoneExtension: this.state.workPhoneExtension,
      vehicleColor: this.state.vehicleColor,
      vehicleBrand: this.state.vehicleBrand,
      vehicleModel: this.state.vehicleModel,
      vehicleYear: this.state.vehicleYear,
      hasDebtorAVehicle: this.state.hasDebtorAVehicle,
      vehicleFinancialStatus: this.state.vehicleFinancialStatus,
      houseInfo: this.state.dwellingType,
      // houseMonthlyPayment: this.state.houseMonthlyPayment,
      // houseMonthlyPaymentCurrency: this.state.houseMonthlyPaymentCurrency,
      hasAccessToCoopvirtual: this.state.hasAccessToCoopvirtual,
      password:
        this.state.password.length > 0 ? this.state.password : undefined,
      // zipCode: this.state.zipCode,
    };

    this.setState({ loading: true });

    createParticipant(this.props.user.token, client)
      .then((res) => {
        this.props.createClient(res.data.results ? res.data.results : res.data);
        successHandler(res, this, translations.CLIENT);
        if (this.props.newClient) {
          this.props.handleSaveCallback(res.data.uniqueId);
        }
        this.handleReset();
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  handleReset() {
    this.setState((prevState) => ({
      additionalPhone: "",
      affiliateState: "not_affiliate",
      affiliateType: "service_user_affiliate",
      birthDate: "",
      cellPhone: "",
      city: "",
      civilStatus: "single",
      country: "República Dominicana",
      educationLevel: "none",
      email: "",
      fullName: "",
      gender: "m",
      homePhone: "",
      identificationExpirationDate: "",
      identificationExpirationDateError: "",
      identificationNumber: "",
      identificationType: "national",
      nationality: prevState.nationalityOptions[0],
      prefix: "mister",
      profession: [],
      reference: "",
      region: "",
      sector: "",
      state: "",
      workPhone: "",
      workPhoneExtension: "",
      password: "",
      passwordConfirm: "",
      passwordError: "",
      passwordConfirmError: "",
    }));
  }
  handleDialogClose() {
    this.setState({ dialog: false });
  }
  getIdentificationInput(identificationType) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }
  validatePassword(event) {
    this.setState({
      password: event.target.value,
      passwordError: "",
    });

    if (
      validator.isStrongPassword(event.target.value, {
        minLength: 4,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 4,
        minSymbols: 0,
      })
    ) {
      this.setState({
        passwordError: "",
      });
    } else {
      this.setState({
        passwordError:
          "Debe ser de almenos ocho (8) caracteres, contener almenos un numero, un símbolo y una mayuscula.",
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <form
        id="ClientForm"
        onSubmit={this.handleSubmit}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h3" style={{ display: "inline" }}>
                {translations.NEW_CLIENT}
              </Typography>
            </Grid>
          </Grid>

          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.IDENTIFICATION}
                    value={this.state.identificationNumber}
                    onChange={this.handleChange("identificationNumber")}
                    error={this.state.identificationNumberError !== ""}
                    helperText={this.state.identificationNumberError}
                    InputProps={this.getIdentificationInput(
                      this.state.identificationType
                    )}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.IDENTIFICATION_TYPE}
                    value={this.state.identificationType}
                    onChange={(event) =>
                      this.handleSelectChange(event, "identificationType")
                    }
                    error={this.state.identificationTypeError !== ""}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "identificationType",
                      name: "identificationType",
                    }}
                  >
                    {IDENTIFICATION_TYPE.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.IDENTIFICATION_EXPIRATION_DATE}
                    value={this.state.identificationExpirationDate}
                    type="date"
                    onChange={this.handleChange("identificationExpirationDate")}
                    error={this.state.identificationExpirationDate !== ""}
                    helperText={this.state.identificationExpirationDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.NAME}
                    value={this.state.fullName}
                    onChange={this.handleChange("fullName")}
                    error={this.state.fullNameError !== ""}
                    helperText={this.state.fullNameError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.NICKNAME}
                    value={this.state.nickName}
                    onChange={this.handleChange("nickName")}
                    error={this.state.nickNameError !== ""}
                    helperText={this.state.nickNameError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    select
                    variant="outlined"
                    label={translations.GENDER}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    value={this.state.gender}
                    onChange={(event) =>
                      this.handleSelectChange(event, "gender")
                    }
                    error={this.state.genderError !== ""}
                    inputProps={{
                      id: "gender",
                      name: "gender",
                    }}
                  >
                    {gender.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.EDUCATION_LEVEL}
                    value={this.state.educationLevel}
                    onChange={(event) =>
                      this.handleSelectChange(event, "educationLevel")
                    }
                    error={this.state.educationLevelError !== ""}
                    inputProps={{
                      id: "educationLevel",
                      name: "educationLevel",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {educationLevel.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    select
                    label={translations.CIVIL_STATUS}
                    variant="outlined"
                    value={this.state.civilStatus}
                    onChange={(event) =>
                      this.handleSelectChange(event, "civilStatus")
                    }
                    error={this.state.civilStatusError !== ""}
                    inputProps={{
                      id: "civilStatus",
                      name: "civilStatus",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {civilStatus.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    select
                    label={translations.TITLE}
                    variant="outlined"
                    value={this.state.prefix}
                    onChange={(event) =>
                      this.handleSelectChange(event, "prefix")
                    }
                    error={this.state.prefixError !== ""}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "prefix",
                      name: "prefix",
                    }}
                  >
                    {prefix.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    select
                    variant="outlined"
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    label={translations.AFFILIATE_STATE}
                    value={this.state.affiliateState}
                    onChange={(event) =>
                      this.handleSelectChange(event, "affiliateState")
                    }
                    error={this.state.affiliateStateError !== ""}
                    inputProps={{
                      id: "affiliateState",
                      name: "affiliateState",
                    }}
                  >
                    {affiliateState.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.BIRTHDATE}
                    value={this.state.birthDate}
                    type="date"
                    onChange={this.handleChange("birthDate")}
                    error={this.state.birthDateError !== ""}
                    helperText={this.state.birthDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    select
                    fullWidth
                    variant="outlined"
                    value={this.state.nationality}
                    onChange={(event) =>
                      this.handleSelectChange(event, "nationality")
                    }
                    label={translations.NATIONALITY}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "nationality",
                      name: "nationality",
                    }}
                  >
                    {this.state.nationalityOptions.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    required
                    fullWidth
                    label={translations.AFFILIATE_TYPE}
                    value={this.state.affiliateType}
                    onChange={(event) =>
                      this.handleSelectChange(event, "affiliateType")
                    }
                    error={this.state.affiliateTypeError !== ""}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "affiliateType",
                      name: "affiliateType",
                    }}
                    variant="outlined"
                  >
                    {affiliateType.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.EMAIL}
                    placeholder="mycompany@email.com"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChange("email")}
                    error={this.state.emailError !== ""}
                    helperText={this.state.emailError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label="Posee acceso a COOPVIRTUAL"
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.hasAccessToCoopvirtual}
                        onChange={(event) =>
                          this.handleCheckBoxChange(
                            event,
                            "hasAccessToCoopvirtual"
                          )
                        }
                      />
                    }
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    display: this.state.hasAccessToCoopvirtual
                      ? "block"
                      : "none",
                  }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Pin de acceso"
                    value={this.state.password}
                    onChange={(event) =>
                      this.handleSelectChange(event, "password")
                    }
                    required={this.state.hasAccessToCoopvirtual}
                    error={this.state.passwordError}
                    helperText={this.state.passwordError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "password",
                      name: "password",
                      maxLength: "4",
                      minLength: "4",
                    }}
                    hintText="Pin"
                    type="password"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    display: this.state.hasAccessToCoopvirtual
                      ? "block"
                      : "none",
                  }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Repetir pin"
                    value={this.state.passwordConfirm}
                    onChange={(event) =>
                      this.handleSelectChange(event, "passwordConfirm")
                    }
                    required={this.state.hasAccessToCoopvirtual}
                    error={
                      this.state.passwordConfirmError !== "" ||
                      this.state.password !== this.state.passwordConfirm
                    }
                    helperText={
                      this.state.password !== this.state.passwordConfirm
                        ? "Las contraseñas no coinciden."
                        : this.state.passwordConfirmError
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "passwordConfirm",
                      name: "passwordConfirm",
                      maxLength: "4",
                      minLength: "4",
                    }}
                    hintText="Repetir contraseña"
                    type="password"
                    disabled={this.state.password.length < 1}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label={translations.DEBTOR_VEHICLE}
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.hasDebtorAVehicle}
                        onChange={(event) =>
                          this.handleCheckBoxChange(event, "hasDebtorAVehicle")
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  display: this.state.hasDebtorAVehicle ? "block" : "none",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={translations.VEHICLE_BRAND}
                      value={titleCaseHandler(this.state.vehicleBrand)}
                      onChange={this.handleChange("vehicleBrand")}
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleBrandError !== ""}
                      helperText={this.state.vehicleBrandError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={translations.VEHICLE_MODEL}
                      value={titleCaseHandler(this.state.vehicleModel)}
                      onChange={(event) => this.handleChange("vehicleModel")}
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleModelError !== ""}
                      helperText={this.state.vehicleModelError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={translations.VEHICLE_YEAR}
                      value={this.state.vehicleYear}
                      onChange={this.handleChange("vehicleYear")}
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleYearError !== ""}
                      helperText={this.state.vehicleYearError}
                      InputProps={{
                        inputComponent: attorneyNumberMaskInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={translations.VEHICLE_COLOR}
                      value={titleCaseHandler(this.state.vehicleColor)}
                      onChange={this.handleChange("vehicleColor")}
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleColorError !== ""}
                      helperText={this.state.vehicleColorError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{ style: styles.checkboxLabel }}
                      error={this.state.vehicleFinancialStatusError !== ""}
                      label={translations.VEHICLE_FINANCIAL_STATUS}
                      value={this.state.vehicleFinancialStatus}
                      onChange={(event) =>
                        this.handleSelectChange(event, "vehicleFinancialStatus")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      inputProps={{
                        id: "vehicleFinancialStatus",
                        name: "vehicleFinancialStatus",
                      }}
                    >
                      <MenuItem value="financed" key={1}>
                        {translations.FINANCED}
                      </MenuItem>
                      <MenuItem value="paid" key={2}>
                        {translations.PAID}
                      </MenuItem>
                      <MenuItem value="not own" key={3}>
                        {translations.NOT_OWN}
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label={translations.DWELLING_TYPE}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    value={this.state.dwellingType}
                    onChange={(event) =>
                      this.handleSelectChange(event, "dwellingType")
                    }
                    inputProps={{
                      name: "dwellingType",
                    }}
                  >
                    {dwellingTypes.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.HOUSE_MONTHLY_PAYMENT}
                    value={this.state.houseMonthlyPayment}
                    onChange={this.handleCurrencyInputChange('houseMonthlyPayment')}
                    error={this.state.houseMonthlyPaymentError !== ''}
                    helperText={this.state.houseMonthlyPaymentError}
                    InputProps={{
                      inputComponent: moneyInput
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    required
                    select
                    label="Moneda"
                    variant="outlined"
                    error={this.state.houseMonthlyPaymentCurrencyError !== ''}
                    value={this.state.houseMonthlyPaymentCurrency}
                    onChange={(event)=>this.handleSelectChange(event, 'houseMonthlyPaymentCurrency')}
                    inputProps={{
                      id: 'houseMonthlyPaymentCurrency',
                      name: 'houseMonthlyPaymentCurrency',
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    >
                      <MenuItem value="DOP" key={0}>{translations.DOP}</MenuItem>
                      <MenuItem value="USD" key={1}>{translations.USD}</MenuItem>
                  </TextField>
                </Grid> */}
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.CELL_PHONE}
                    value={this.state.cellPhone}
                    onChange={this.handleChange("cellPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.cellPhoneError !== ""}
                    helperText={this.state.cellPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.HOME_PHONE}
                    value={this.state.homePhone}
                    onChange={this.handleChange("homePhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.homePhoneError !== ""}
                    helperText={this.state.homePhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.WORK_PHONE}
                    value={this.state.workPhone}
                    onChange={this.handleChange("workPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.workPhoneError !== ""}
                    helperText={this.state.workPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.WORK_PHONE_EXTENSION}
                    value={this.state.workPhoneExtension}
                    onChange={this.handleChange("workPhoneExtension")}
                    error={this.state.workPhoneExtensionError !== ""}
                    helperText={this.state.workPhoneExtensionError}
                    InputProps={{
                      inputComponent: attorneyNumberMaskInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.ADDITIONAL_PHONE}
                    value={this.state.additionalPhone}
                    onChange={this.handleChange("additionalPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.additionalPhoneError !== ""}
                    helperText={this.state.additionalPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
              </Grid>

              <Address
                handleSelect={this.handleSelect}
                country={this.state.country}
                title={translations.ADDRESS}
                countryError={this.state.countryError}
                reference={this.state.reference}
                referenceError={this.state.referenceError}
                state={this.state.state}
                stateError={this.state.stateError}
                city={this.state.city}
                cityError={this.state.cityError}
                region={this.state.region}
                regionError={this.state.regionError}
                sector={this.state.sector}
                sectorError={this.state.sectorError}
                handleChange={this.handleChange}
              />
              {/* <TextField
                label={translations.ZIP_CODE}
                className={classes.textField}
                value={this.state.zipCode}
                onChange={this.handleChange("zipCode")}
                type="number"
                fullWidth={true}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              /> */}
              <ProfessionSelectInput
                selectedProfessionId={this.state.profession}
                addProfession={this.handleAddProfession}
                deleteProfession={this.handleDeleteProfession}
                title={translations.PROFESSION}
              />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  form="ClientForm"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createClient })(ClientForm)
);
