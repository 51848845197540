import * as React from "react";
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Fab from "@material-ui/core/Fab";
import { createStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import { fetchWarranties } from "../../../actions";
import DefaultSection from "../../../components/DefaultSection";
import Loading from "../../../components/Loading";
import { fetchWarranties as fetchAll } from "../../../utils/axios";
import WarrantyForm from "../WarrantyForm";
import WarrantySearchSection from "../WarrantySearchSection";
import WarrantyView from "../WarrantyView";

const styles: any = createStyles({
  addButton: {
    backgroundColor: green[500],
    bottom: 10,
    color: "white",
    position: "fixed",
    right: 10,
  },
  rightSide: {
    alignItems: "center",
    border: "1px solid transparent",
    boxSizing: "border-box",
    display: "block",
    height: "100%",
    justifyContent: "center",
    marginLeft: 280,
    position: "relative",
  },
  root: {
    boxSizing: "border-box",
    height: "100%",
    margin: 0,
    padding: 0,
  },
});

interface IWarrantySectionProps {
  classes: {
    addButton: string;
    rightSide: string;
    root: string;
  };

  warranties?: IWarranty[];
  user: IUser;
  fetchWarranties(warranties: IWarranty[]): IAction<IWarranty[]>;
}
interface IWarrantySectionState {
  loading: boolean;
  selectedWarrantyId: string | undefined;
  selectedSection: number;
  limitRequest: any;
}
class WarrantySection extends React.Component<
  IWarrantySectionProps,
  IWarrantySectionState
> {
  public state: IWarrantySectionState = {
    loading: false,
    selectedWarrantyId: undefined,
    selectedSection: 0,
    limitRequest: { limit: 20, offset: 0 },
  };
  constructor(props: IWarrantySectionProps) {
    super(props);

    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.advanceSearch = this.advanceSearch.bind(this);
  }
  public componentDidMount() {
    this.setState({ loading: true });
    fetchAll({ token: this.props.user.token, limit: 20, offset: 0 })
      .then((res) => {
        this.props.fetchWarranties(
          (res.data.results ? res.data.results : res.data) as IWarranty[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleChangeSection(sectionNumber: number) {
    this.setState({ selectedSection: sectionNumber });
  }
  public handleClickItem(warrantyId: string) {
    this.setState(
      {
        selectedWarrantyId: warrantyId,
        selectedSection: 1,
      },
      () => {
        this.setState({ selectedSection: 2 });
      }
    );
  }
  public handleAddClick() {
    this.setState({
      selectedSection: 3,
    });
  }
  public fetchData(params: IFetchWarrantyParams) {
    if (Object.keys(params).length === 0) {
      params = this.state.limitRequest;
    }
    this.setState({ loading: true });
    fetchAll({ ...params, token: this.props.user.token })
      .then((res) => {
        this.props.fetchWarranties(
          (res.data.results ? res.data.results : res.data) as IWarranty[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public advanceSearch(params: any) {
    if (Object.keys(params).length === 0) {
      params = this.state.limitRequest;
    }
    this.setState({ loading: true });
    fetchAll({ ...params, token: this.props.user.token })
      .then((res) => {
        const data = res.data.results ? res.data.results : res.data;
        this.props.fetchWarranties(data as IWarranty[]);
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public render() {
    const { classes } = this.props;
    const { loading, selectedWarrantyId, selectedSection } = this.state;
    return (
      <div className={classes.root}>
        <WarrantySearchSection
          loading={loading}
          handleClick={this.handleClickItem}
          fetchData={this.fetchData}
          pagination={true}
          advanceSearch={this.advanceSearch}
        />
        <div className={classes.rightSide}>
          {selectedSection === 0 ? (
            <DefaultSection
              message={translations.WARRANTY_DEFAULT_SECTION_MESSAGE}
              key={0}
            />
          ) : null}
          {selectedSection === 1 ? (
            <Loading message={translations.LOADING} key={1} />
          ) : null}
          {selectedSection === 2 ? (
            <WarrantyView
              changeSection={this.handleChangeSection}
              warrantyId={selectedWarrantyId ? selectedWarrantyId : ""}
            />
          ) : null}
          {selectedSection === 3 ? (
            <WarrantyForm changeSection={this.handleChangeSection} />
          ) : null}
          {selectedSection !== 3 ? (
            <Fab className={classes.addButton} onClick={this.handleAddClick}>
              <AddIcon />
            </Fab>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    warranties: state.warranties,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchWarranties })(WarrantySection)
);
