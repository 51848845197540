
import { OUTLAY_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case OUTLAY_TYPES.FETCH_ALL:
            return action.payload || [];
        case OUTLAY_TYPES.FETCH:
            return state.map(outlay => (action.payload !== undefined && outlay.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: outlay);
        case OUTLAY_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case OUTLAY_TYPES.UPDATE:
            return state.map(outlay => (action.payload !== undefined && outlay.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : outlay);
        case OUTLAY_TYPES.DELETE:
            return state.filter(outlay => action.payload !== undefined && outlay.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}