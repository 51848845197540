import { AxiosError } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from '@material-ui/core/Paper';
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import BankAccountSelectInput from 'src/containers/BankAccount/BankAccountSelectInput';
import { deleteOutlayCash, fetchOutlayCash, updateOutlayCash } from '../../../actions';
import AlertDialog from '../../../components/AlertDialog';
import Loading from '../../../components/Loading';
import moneyInput from '../../../components/TextMaskInputComponents/moneyInput';
import FileUploadSelectInput from '../../../containers/FileUpload/FileUploadSelectInput';
import { catchError, resetError, translations } from '../../../utils';
import {
    API_KEY,
    deleteOutlayCash as deleteCH, fetchOutlayCash as fetchCH, updateOutlayCash as updateCH
} from '../../../utils/axios';
import ParticipantSelectInput from 'src/containers/Participant/ParticipantSelectInput';


const styles:any = createStyles({
    attachmentIcon: {
        color: green[500],
        fontSize: 50,
        position: 'absolute',
        right: 52,
        top: 25,
    },
    checkboxLabel: {
        color: green[500],
        fontSize: 'medium'
    },
    controls: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    deleteButton: {
        backgroundColor: red[500],
        color: 'white'
    },
    formControl: {

        marginTop: 20,
        minWidth: 300,
        width: 300
    },
    greenButton: {
        backgroundColor: green[500],
        color: 'white'
    },
    input: {
        left: 40,
        position: 'absolute',
        width: 1
    },
    paper: {
        padding: 20
    },
    root: {
        height: '90vh',
        overflowY: 'auto',
        padding: 20,
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: '90%'
    }
});

interface IOutlayCashViewProps {
    outlayCashes: IOutlayCash[];
    outlayCashId: string;
    classes: {
        attachmentIcon: string;
        checkboxLabel: string;
        controls: string;
        deleteButton: string;
        formControl: string;
        greenButton: string;
        input: string;
        paper: string;
        root: string;
        textField: string;
    };

    user: IUser;
    changeSection(sectionNumber: number): void;
    deleteOutlayCash(uniqueId: string): IAction<IOutlayCash[]>;
    fetchOutlayCash(outlayCash: IOutlayCash): IAction<IOutlayCash[]>;
    updateOutlayCash(outlayCash: IOutlayCash): IAction<IOutlayCash[]>;
}

interface IOutlayCashViewState {
    [key: string]: string | string[] | number | boolean | Blob;

    account: string[];
    accountError: string;

    amount: string;
    amountError: string;

    attachmentUrl: string,
    
    concept: string;
    conceptError: string;

    client: string[];
    clientError: string;
    
    date: string;
    dateError: string;

    generateDocUrl: string,

    operationType: string;
    operationTypeError: string;

    referenceNumber: string;
    referenceNumberError: string;

    status: string;
    statusError: string;

    dialog: boolean;
    dialogTitle: string;
    errorMessage: string;
    loading: boolean;
    uniqueId: string;

}
class OutlayCashView extends React.Component<IOutlayCashViewProps, IOutlayCashViewState> {
    public state: IOutlayCashViewState = {
        account: [],
        accountError: '',
        amount: '',
        amountError: '',
        attachmentUrl: '',
        client: [],
        clientError: '',
        concept: '',
        conceptError: '',
        date:'',
        dateError: '',
        dialog: false,
        dialogTitle: '',
        errorMessage: '',
        generateDocUrl: '',
        loading: false,
        operationType:'',
        operationTypeError: '',
        referenceNumber:'',
        referenceNumberError: '',
        status: '',
        statusError: '',
        uniqueId: ''
    }
    constructor(props: IOutlayCashViewProps) {
        super(props);

        this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleAddBankAccount = this.handleAddBankAccount.bind(this);
        this.handleDeleteBankAccount = this.handleDeleteBankAccount.bind(this);
        this.handleAddClient = this.handleAddClient.bind(this);
        this.handleDeleteClient = this.handleDeleteClient.bind(this);

    }
    public handleCheckInputChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ [key]: event.target.checked });
        }
    }

    public handleAddBankAccount(id: string) {
        this.setState({ account: [id] });
    }
    public handleDeleteBankAccount(id: string) {
        this.setState({ account: [] });
    }
    public handleAddClient(id: string) {
        this.setState({ client: [id] });
    }
    public handleDeleteClient(id: string) {
        this.setState({ client: [] });
    }
    public componentDidMount() {

        this.setState({ loading: true });
        fetchCH(this.props.user.token, this.props.outlayCashId).then(res => {

            const curCash = res.data as IOutlayCash;

            this.setState({
                account: curCash.account ? [curCash.account] : [],
                amount: curCash.amount || '',
                attachmentUrl : `outlay/cash/${curCash.uniqueId}/upload_attachment/`,
                client: curCash.client ? [curCash.client] : [],
                concept: curCash.concept || '',
                created: (curCash.created || '').split("T1")[0],
                date: curCash.date || '',
                generateDocUrl : `${API_KEY}/outlay/cash/${curCash.uniqueId}/print/`,
                loading: false,
                operationType: curCash.operationType || '',
                referenceNumber: curCash.referenceNumber || '',
                status: curCash.status || '',
                uniqueId: curCash.uniqueId || ''
            });
        }).catch(err => {
            catchError(err, this);
        });
    }
    public handleCurrencyInputChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>, maskedValue?: string, floatValue?: string) => {
            this.setState({ [key]: floatValue || '' });
        }
    }

    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                [key]: event.currentTarget.value,
                [key + 'Error']: ''
            });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({
                [key]: event.target.value,
                [key + 'Error']: ''
            });
        }
    }
    public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        resetError(this);

        const outlayCash: IOutlayCash = {
            account: (this.state.account[0] || ''),
            amount: this.state.amount,
            client: (this.state.client[0] || ''),
            concept: this.state.concept,
            date: this.state.date,
            status: this.state.status
        };

        this.setState({ loading: true });

        updateCH(this.props.user.token, this.state.uniqueId, outlayCash).then(res => {
            this.setState({
                dialog: true,
                dialogTitle: translations.SUCCESS,
                errorMessage: `${translations.CASH} ${translations.UPDATED}`,
                loading: false
            }, () => this.props.updateOutlayCash(res.data as IOutlayCash));
        }).catch((err: AxiosError) => {
            catchError(err, this);
        });
    }
    public handleDelete() {
        if (confirm(translations.ARE_YOU_SURE)) {

            this.setState({ loading: true });

            deleteCH(this.props.user.token, this.props.outlayCashId).then(res => {
                this.props.changeSection(0);
                this.props.deleteOutlayCash(this.props.outlayCashId);
            }).catch((err: AxiosError) => {
                catchError(err, this)
            })
        }
    }
    public handleDialogClose() {
        this.setState({ dialog: false });
    }
    public render() {
        const { classes } = this.props;
        return (
            <form onSubmit={this.handleSubmit} className={classes.root}>
                <AlertDialog
                    open={this.state.dialog}
                    message={this.state.errorMessage}
                    title={this.state.dialogTitle}
                    handleClose={this.handleDialogClose}
                />
               <Paper className={classes.paper}>
                    <Typography variant="h3" style={{ display: 'inline' }}>{translations.CASH}</Typography>
                    {this.state.loading ?
                        <Loading message={translations.LOADING} />
                        : <div>                                        
                        <FileUploadSelectInput
                        contractId={this.state.uniqueId}
                        url={this.state.attachmentUrl}
                        description="outlay_cash"
                        />
                        {this.state.status === "completed" && this.state.referenceNumber !== "" ?                                    
                               <div>
                                    <a target="_blank" href={this.state.generateDocUrl}>
                                    <ArrowDownward className={classes.attachmentIcon}/>
                                    </a>                            
                                </div>
                               :null
                            }

                            <br />
                            <br />

                            <Typography variant="h6" className={classes.checkboxLabel}>{translations.BANK_ACCOUNT}</Typography>
                            <BankAccountSelectInput
                                selectedBankAccountsId={this.state.account}
                                bankAccountFilter='cash'
                                addBankAccount={this.handleAddBankAccount}
                                deleteBankAccount={this.handleDeleteBankAccount}
                            />
                            <p style={{ color: 'red' }}>{this.state.accountError}</p>
                            <br />
                            <br />
                            <Typography variant="h6" className={classes.checkboxLabel}>{translations.CLIENT}</Typography>
                            <div style={{ width: '100%' }}>
                                <ParticipantSelectInput
                                    selectedParticipantId={this.state.client}
                                    addParticipant={this.handleAddClient}
                                    deleteParticipant={this.handleDeleteClient}
                                />                                
                            </div>
                            <p style={{ color: 'red' }}>{this.state.clientError}</p>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{width: "50%" }}>
                                <TextField
                                    label={translations.CREATED}
                                    className={classes.textField}
                                    value={this.state.created}
                                    type="date"
                                    disabled={true}
                                    error={this.state.dateError !== ""}
                                    helperText={this.state.dateError}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.label,
                                    }}
                                />

                                <TextField
                                    label={translations.REFERENCE_NUMBER}
                                    className={classes.textField}
                                    value={this.state.referenceNumber}
                                    onChange={this.handleChange('referenceNumber')}
                                    fullWidth={true}
                                    disabled={true}
                                    required={true}
                                    error={this.state.referenceNumberError !== ''}
                                    helperText={this.state.referenceNumberError}
                                    InputLabelProps={{ style: styles.label }}
                                />
                                <p style={{ color: 'red' }}>{this.state.referenceNumberError}</p>                            

                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="status" shrink={true}>{translations.STATUS}</InputLabel>
                                    <Select
                                        value={this.state.status}
                                        onChange={this.handleSelectChange('status')}
                                        required={true}
                                        native={true}
                                        error={this.state.operationTypeError !== ''}
                                        inputProps={{
                                            id: 'status',
                                            name: 'status',
                                        }}
                                    >
                                        <option value="in_transit">{ translations.OUTLAY_CHECK_STATUS_IN_TRANSIT}</option>
                                        <option value="cancelled">{ translations.OUTLAY_CHECK_STATUS_CANCELLED}</option>
                                        <option value="completed">{ translations.OUTLAY_CHECK_STATUS_COMPLETED}</option>
                                    </Select>
                                </FormControl>
                                <br />
                                <br />

                                <TextField
                                    label={translations.DATE}
                                    className={classes.textField}
                                    value={this.state.date}
                                    type="date"
                                    onChange={this.handleChange("date")}
                                    required={true}
                                    error={this.state.dateError !== ""}
                                    helperText={this.state.dateError}
                                    InputLabelProps={{
                                    shrink: true,
                                    style: styles.label,
                                    }}
                                />
                                <p style={{ color: 'red' }}>{this.state.dateError}</p>
    
                            </div>                            
                            <div style={{width: "50%" }}>
                                <TextField
                                    label={translations.AMOUNT_2}
                                    className={classes.textField}
                                    value={this.state.amount}
                                    onChange={this.handleCurrencyInputChange('amount')}
                                    fullWidth={true}
                                    required={true}
                                    error={this.state.amountError !== ''}
                                    helperText={this.state.amountError}
                                    InputProps={{
                                        inputComponent: moneyInput
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: styles.checkboxLabel
                                    }}                                />

                                <TextField
                                    label={translations.CONCEPT}
                                    className={classes.textField}
                                    value={this.state.concept}
                                    onChange={this.handleChange('concept')}
                                    fullWidth={true}
                                    variant="outlined"
                                    multiline={true}
                                    rows={5}
                                    rowsMax={5}
                                    required={true}
                                    error={this.state.conceptError !== ''}
                                    helperText={this.state.conceptError}
                                    InputLabelProps={{ style: styles.label }}
                                />
                                <p style={{ color: 'red' }}>{this.state.conceptError}</p>
                            </div>
                        </div>

                            <div className={classes.controls}>
                                <Button variant="contained" onClick={this.handleDelete} className={classes.deleteButton}>
                                    <DeleteIcon />
                                    &nbsp;
                                    {translations.DELETE}
                                </Button>
                                <Button type="submit" variant="contained" className={classes.greenButton} style={{ marginLeft: 10 }}>
                                    <SaveIcon />
                                    &nbsp;
                                    {translations.SAVE}
                                </Button>
                            </div>
                        </div>
                    }
                </Paper>
            </form>
        );
    }
}

function mapStateToProps(state: IState) {
    return {
        outlayCashes: state.outlayCashes,
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps, { deleteOutlayCash, fetchOutlayCash, updateOutlayCash })(OutlayCashView)
);

