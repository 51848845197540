import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import CheckBox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { createClientAffidavitOfFunds } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import { createClientAffidavitOfFunds as create } from "../../../utils/axios";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
import ExtraPaymentTable from "../../../components/Ticket/ExtraPayTable/ExtraPaymentTable";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

interface IFormProps {
  classes: {
    attachmentIcon: any;
    checkboxLabel: any;
    controls: any;
    deleteButton: any;
    errorInput: any;
    formControl: any;
    greenButton: any;
    half: any;
    paper: any;
    root: any;
    textField: any;
    title: any;
  };
  user: IUser;
  changeSection(sectionNumber: number): void;
  createClientAffidavitOfFunds(
    clientAffidavitOfFunds: IClientAffidavitOfFunds
  ): IAction<IClientAffidavitOfFunds[]>;
}
interface IFormState {
  [key: string]: string | number | boolean | string[] | undefined;

  editIdx: number;

  contractDate: string;
  contractDateError: string;

  dialog: boolean;
  dialogTitle: string;
  title: string;
  errorMessage: string;
  debtor: string[];
  debtorError: string;
  loading: boolean;
  isPep: boolean;

  city: string;
  cityError: string;

  pepPosition: string;
  pepPositionError: string;

  sources: string[]; // done
  sourcesError: string;
}

class Form extends React.Component<IFormProps, IFormState> {
  public state: IFormState = {
    editIdx: -1,

    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    debtor: [],
    debtorError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    isPep: false,
    loading: false,
    title: "",
    titleError: "",
    pepPosition: "",
    pepPositionError: "",
    city: "",
    cityError: "",
    sources: [], // done
    sourcesError: "",
  };

  constructor(props: IFormProps) {
    super(props);

    // debtor
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // core event
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleStartEdit = this.handleStartEdit.bind(this);
    this.handleStopEdit = this.handleStopEdit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeTableData = this.handleChangeTableData.bind(this);
  }
  public handleChangeTableData = (i: any, x: any) => {
    this.setState((state) => ({
      sources: state.sources.map((row: any, j: any) => (j === i ? x : row)),
    }));
    this.handleStopEdit();
  };

  public handleStopEdit = () => {
    this.setState({ editIdx: -1 });
  };

  public handleRemove = (i: any) => {
    this.setState({
      sources: this.state.sources.filter((row: any, j: any) => j !== i),
    });
  };

  public handleSave(data: any) {
    this.setState({
      sources: data,
    });
  }

  public handleStartEdit = (i: any) => {
    this.setState({ editIdx: i });
  };
  public handleAddClient(id: any) {
    this.setState({ debtor: [id] });
  }
  public handleDeleteClient(id: any) {
    this.setState({ debtor: [] });
  }
  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleCheckBoxChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  public handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);
    const { state } = this;
    if (!this.state.city) {
      this.setState({ cityError: "Este campo es requerido" });
    } else if (!this.state.debtor.length) {
      this.setState({ debtorError: "Este campo es requerido" });
    } else if (state.isPep && !state.pepPosition) {
      this.setState({ pepPositionError: "Este campo es requerido" });
    } else {
      const contract: IClientAffidavitOfFunds = {
        contractDate: state.contractDate,
        debtor: state.debtor[0] || "",
        title: state.title,
        city: state.city || "",
        pepPosition: state.pepPosition || "",
        isPep: state.isPep || false,
      };

      this.setState({ loading: true });

      create(this.props.user.token, contract)
        .then((res) => {
          this.props.createClientAffidavitOfFunds(
            res.data.results ? res.data.results : res.data
          );
          successHandler(
            res,
            this,
            "Declaración de procedencia de Fondos Persona Física"
          );
          this.handleReset();
        })
        .catch((err: any) => {
          catchError(err, this);
        });
    }
  }
  public handleReset() {
    this.setState({
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      debtor: [],
      debtorError: "",
      loading: false,
      title: "",
      titleError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    const headersAR = [
      { name: "Descripción", prop: "description", type: "text" },
    ];

    return (
      <form
        name="MA"
        noValidate={true}
        onSubmit={this.handleSubmit}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            Declaración de procedencia de Fondos Persona Física
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={3}>
                  <TextField
                    label={translations.CITY}
                    fullWidth={true}
                    value={this.state.city}
                    onChange={this.handleChange("city")}
                    required={true}
                    error={this.state.cityError !== ""}
                    helperText={this.state.cityError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label="¿Persona Expuesta Políticamente?"
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.isPep}
                        onChange={this.handleCheckBoxChange("isPep")}
                      />
                    }
                  />
                </Grid>
                {this.state.isPep ? (
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Posición del PEP"
                      fullWidth={true}
                      value={this.state.pepPosition}
                      onChange={this.handleChange("pepPosition")}
                      required={true}
                      error={this.state.pepPositionError !== ""}
                      helperText={this.state.pepPositionError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                ) : null}
                <Grid item={true} xs={12}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    Fuentes
                  </Typography>
                  <ExtraPaymentTable
                    actions={true}
                    create={true}
                    data={this.state.sources}
                    editIdx={this.state.editIdx}
                    handleChange={this.handleChangeTableData}
                    handleRemove={this.handleRemove}
                    handleSave={this.handleSave}
                    handleStartEdit={this.handleStartEdit}
                    handleStopEdit={this.handleStopEdit}
                    header={headersAR}
                    tableLimit={5}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.PARTNER}
                  </Typography>
                  <ParticipantSelectInput
                    selectedParticipantId={this.state.debtor}
                    addParticipant={this.handleAddClient}
                    deleteParticipant={this.handleDeleteClient}
                  />
                  <p className={classes.errorInput}>{this.state.debtorError}</p>
                </Grid>
              </Grid>

              <br />
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createClientAffidavitOfFunds })(Form)
);
