import * as React from 'react';
import MaskedInput from 'react-text-mask';

interface ITextMaskInput {
    mask: any[];
    pattern?: string;
    guide?: boolean;
    placeholderChar?: string;
    showMask: boolean;
}
const TextMaskInput = (props: ITextMaskInput) => (
  <MaskedInput {...props}/>  
);
export default TextMaskInput;

/* 
    sample
    mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    
*/ 

