import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  IconButton,
  Divider,
  Paper,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import {
  AutocompleteField,
  TextFieldComponent,
  DateField,
  NumericTextField,
  SearchComponent,
  CheckboxField,
} from "./../../../components/SharedComponents/Fields";
import {
  ConfirmDialog,
  DialogHeader,
  DialogFooter,
} from "./../../../components/SharedComponents/Dialogs";
import { FileSelection } from "../../../components/SharedComponents/FileSelection";
import {
  dateFormat,
  errorHandling,
} from "./../../../components/SharedComponents/CustomHooks";
import NumberFormat from "./../../../components/SharedComponents/NumberFormat";
import { RohiExpiryTable } from "./../Tables";
import {
  institutionClassification,
  shareholderArrayToJson,
} from "./../../../utils";
import { fetchRohiSavingAccounts } from "./../../../utils/axios";

function ConfirmDialogComponent(props) {
  const data = props.data.data;
  let dialogConfirmContent = "";
  switch (props.data.msg) {
    case "file-upload":
      dialogConfirmContent = "¿Desea subir el archivo seleccionado?";
      break;
    case "proceed-payment":
      dialogConfirmContent = "¿Desea realizar el pago especificado?";
      break;
    case "proceed-delete":
      dialogConfirmContent = `El vencimiento con cliente: ${data.row.client}, de fecha: ${data.row.expirationDate} será eliminado.`;
      break;
    case "proceed-delete-multiple":
      dialogConfirmContent = `¿Está seguro que desea eliminar todos los vencimientos seleccionados?`;
      break;
    default:
      dialogConfirmContent = "...";
  }
  return (
    <ConfirmDialog
      open={props.open}
      handleToggle={props.handleToggle}
      confirmCallback={data.callback}
      data={dialogConfirmContent}
    />
  );
}

function ExpiryPayDialog(props) {
  const defaultFieldsValue = {
    amountPayed: "",
    paymentType: null,
    creditor: null,
    paymentDate: null,
    expirationId: props.data.uniqueId,
    paymentId: "",
  };
  const notRequiredFields = ["paymentId"];
  // const client = props.listData.clients.find(item => item.name === props.data.client);
  const [fields, setField] = useState(defaultFieldsValue);
  const [errorFields, setErrorFields] = useState({}); // the error field obj (to tell the field if there's an error)
  const [error, setError] = useState(false); // general error variable. Show if there's a field with an error.
  const [file, setFile] = useState("");

  // const clearAllFields = () => {
  //     setField({...defaultFieldsValue});
  //     setErrorFields({...{}});
  //     setError(false);
  // }

  const getFiltersOptions = (option) => {
    return option ? option : [];
  };

  const validateRequiredFields = (type, fieldsToCheck, value) => {
    // validate fields errors
    let newErrorState = { ...errorFields };
    const fieldCheck = (key, value) => {
      if (!notRequiredFields.includes(key)) {
        return (newErrorState[key] =
          value === "" ||
          value === null ||
          value === undefined ||
          Object.keys(value).length === 0
            ? true
            : false);
      }
    };
    if (type === "all") {
      Object.keys(fieldsToCheck).map((key) => {
        return fieldCheck(key, fieldsToCheck[key]);
      });
    } else if (type === "single") {
      fieldCheck(fieldsToCheck, value);
    }
    const hasErr = Object.keys(newErrorState).find(
      (item) => newErrorState[item]
    );
    setError(Boolean(hasErr));
    setErrorFields({ ...newErrorState });
    return Boolean(hasErr);
  };

  const handleFieldChange = (field, event, value) => {
    let newVal;
    switch (field) {
      case "paymentDate":
        newVal = dateFormat(event);
        break;
      case "paymentType":
      case "creditor":
        newVal = value;
        break;
      default:
        newVal = event.target ? event.target.value : event.value;
        break;
    }
    validateRequiredFields("single", field, newVal);
    setField((prevState) => ({ ...prevState, [field]: newVal })); //this way the state of the object property updates.
  };

  const handleSaveBtn = () => {
    if (validateRequiredFields("all", fields)) {
      return;
    }
    const data = {
      ...fields,
      paymentDate: fields.paymentDate,
      amountPayed: Number(fields.amountPayed),
      creditor: fields.creditor.uniqueId,
      paymentType: fields.paymentType.value,
      file: file,
    };
    props.handlePayment(data);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={props.open}
    >
      <DialogHeader
        dialogTitle="Pago vencimiento"
        handleClick={props.handleToggleDialog.bind(this, undefined)}
      />
      <DialogContent style={{ margin: "2% 0" }}>
        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent="space-between"
          style={{ padding: "10px 16px 15px 16px" }}
        >
          <Grid item xs={4}>
            <Card>
              <CardContent style={{ marginTop: "5px" }}>
                <Typography variant="subtitle1" style={{ color: "#424242" }}>
                  <div style={{ display: "table" }}>
                    <NumberFormat value={props.data.amountReceivable || 0} />
                  </div>
                  <Typography
                    variant="overline"
                    style={{ color: "#616161", fontSize: "11px" }}
                  >
                    Monto vencido
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent style={{ marginTop: "5px" }}>
                <Typography variant="subtitle1" style={{ color: "#424242" }}>
                  <div style={{ display: "table" }}>
                    <NumberFormat value={props.data.amountCharged || 0} />
                  </div>
                  <Typography
                    variant="overline"
                    style={{ color: "#616161", fontSize: "11px" }}
                  >
                    Monto pagado
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent style={{ marginTop: "5px" }}>
                <Typography variant="subtitle1" style={{ color: "#424242" }}>
                  <div style={{ display: "table" }}>
                    <span>---</span>
                  </div>
                  <Typography
                    variant="overline"
                    style={{ color: "#616161", fontSize: "11px" }}
                  >
                    Cliente
                  </Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          spacing={2}
          style={{ padding: "0 16px" }}
        >
          <TextFieldComponent
            xs={4}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Referencia"
            fieldID="filter-field-reference"
            fieldClass="fields"
            fieldVariant="outlined"
            placeholder="Ej: RT100001"
            margin="dense"
            fullWidth
            value={fields.paymentId ? fields.paymentId : ""}
            onChange={handleFieldChange.bind(this, "paymentId")}
            // error={errorFields.paymentId}
            // helperText={errorFields.paymentId ? "Introduzca el código de referencia" : null}
          />
          <NumericTextField
            xs={4}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Monto"
            placeholder="Monto a pagar"
            fieldID="text-field-amount"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            fullWidth
            prefix="$"
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            value={fields.amountPayed ? fields.amountPayed : ""}
            onChange={handleFieldChange.bind(this, "amountPayed")}
            error={errorFields.amountPayed}
            helperText={errorFields.amountPayed ? "Introduzca un monto" : null}
          />
          <AutocompleteField
            xs={4}
            containerClass="field-container"
            fieldClass="fields"
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Tipo de pago"
            fieldID="autocomplete-field-pay-type"
            fieldInnerLabel="Seleccione un tipo de pago"
            fieldVariant="outlined"
            value={fields.paymentType ? fields.paymentType : null}
            handleChange={handleFieldChange.bind(this, "paymentType")}
            options={getFiltersOptions(props.listData.payTypes)}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.value === value.value}
            error={errorFields.paymentType}
            helperText={
              errorFields.paymentType ? "Seleccione un tipo de pago" : null
            }
            limitTags={1}
          />
        </Grid>
        <Grid
          container
          direction="row"
          spacing={2}
          style={{ padding: "0 16px" }}
        >
          <AutocompleteField
            xs={4}
            containerClass="field-container"
            fieldClass="fields"
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Acreedor"
            fieldID="autocomplete-field-creditor"
            fieldInnerLabel="Seleccione un acreedor"
            fieldVariant="outlined"
            value={fields.creditor ? fields.creditor : null}
            handleChange={handleFieldChange.bind(this, "creditor")}
            options={getFiltersOptions(props.listData.creditors)}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) =>
              option.uniqueId === value.uniqueId
            }
            error={errorFields.creditor}
            helperText={errorFields.creditor ? "Seleccione un acreedor" : null}
            limitTags={1}
          />
          <DateField
            xs={4}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Fecha"
            dateFieldID="field-payment-date"
            fullWidth={true}
            dateFieldClass="date-field"
            inputVariant="outlined"
            dateFieldLabel="Fecha de pago"
            format="dd/MM/yyyy"
            value={fields.paymentDate ? fields.paymentDate : null}
            handleChange={handleFieldChange.bind(this, "paymentDate")}
            error={errorFields.paymentDate}
            helperText={errorFields.paymentDate ? "Seleccione una fecha" : null}
          />
          <Grid item xs={4}>
            <Typography variant="subtitle1" className="field-label">
              Comprobante
            </Typography>
            <FileSelection
              xs={12}
              file={file}
              setFile={setFile}
              fileExtensions=".pdf,.png,.jpg,.jpeg"
              inputId="input-file-selection-payment"
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          style={{ marginTop: "3%", paddingRight: "16px" }}
        >
          <Button
            variant="contained"
            className={
              error ? "action-method-btn disabled" : "action-method-btn save"
            }
            disableElevation
            size="small"
            onClick={handleSaveBtn}
            disabled={error}
          >
            realizar pago
          </Button>
        </Grid>
      </DialogContent>
      <DialogFooter
      // dialogFooterText="Guardar cambios"
      />
    </Dialog>
  );
}

function ListDetail(props) {
  return (
    <Paper
      style={{
        border: "1px solid #ebebeb",
        borderRadius: 5,
        margin: "1rem 8px",
      }}
    >
      <ListSubheader component="div" style={{ fontSize: 17 }}>
        {props.title}
      </ListSubheader>
      <List
        style={{ overflow: "auto", height: "250px" }}
        key={`list-container-${props.title}`}
      >
        {props.list.length ? (
          props.list.map((item, index) => {
            return (
              <div key={`${props.title}-${index}`}>
                <ListItem>
                  <Grid container direction="row">
                    {Object.keys(item).map((property) => {
                      return item[property].type === "money" ? (
                        <ListItemText
                          key={property}
                          primary={
                            <NumberFormat
                              value={item[property].value}
                              prefix="$"
                            />
                          }
                          secondary={item[property].label}
                          style={{ minWidth: props.columnWidth, flex: "none" }}
                        />
                      ) : (
                        <ListItemText
                          key={property}
                          primary={item[property].value}
                          secondary={item[property].label}
                          style={{ minWidth: props.columnWidth, flex: "none" }}
                        />
                      );
                    })}
                    {/* <ListItemText
                                            primary={item.account}
                                            secondary="ID Cuenta"
                                            style={{width:220}}
                                        />
                                        <ListItemText
                                            primary={item.accountType}
                                            secondary="Descripción"
                                            style={{width:220}}
                                        />
                                        <ListItemText
                                            primary={
                                                <NumberFormat
                                                    value={item.share}
                                                    prefix="$"
                                                />
                                            }
                                            secondary="Cuota"
                                            style={{width:220}}
                                        /> */}
                  </Grid>
                  <ListItemSecondaryAction
                    onClick={props.removeItem.bind(this, item)}
                  >
                    <IconButton edge="end" aria-label="delete">
                      <CloseOutlined />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {index + 1 !== props.list.length && <Divider />}
              </div>
            );
          })
        ) : (
          <i style={{ color: "#424242", padding: "0 20px" }}>SIN REGISTROS</i>
        )}
      </List>
    </Paper>
  );
}

function Expiry(props) {
  const defaultFieldsValue = {
    amountReceivable: "",
    client: null,
    expirationDate: null,
    // expirationDetail: {},
  };
  const requiredFields = ["expirationDate", "client"];
  const action = props.modify ? "update" : "create";
  const [fields, setField] = useState(defaultFieldsValue);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [rohiAccounts, setRohiAccounts] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [loanDetailList, setLoanDetailList] = useState([]);
  const [loanAmount, setLoanAmount] = useState("");
  const [savingAmount, setSavingAmount] = useState("");
  const [loanDetailAmount, setLoanDetailAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [errorFields, setErrorFields] = useState({}); // the error field obj (to tell the field if there's an error)
  const [error, setError] = useState(false); // general error variable. Show if there's a field with an error.

  const getFiltersOptions = (option) => {
    return option ? option : [];
  };

  const validateRequiredFields = (type, fieldsToCheck, value) => {
    // validate fields errors
    let newErrorState = { ...errorFields };
    const fieldCheck = (key, value) => {
      return (newErrorState[key] =
        value === "" ||
        value === null ||
        value === undefined ||
        Object.keys(value).length === 0
          ? true
          : false);
    };
    if (type === "all") {
      // Object.keys(fieldsToCheck).map(key => {
      requiredFields.map((key) => {
        return fieldCheck(key, fieldsToCheck[key]);
      });
    } else if (type === "single") {
      fieldCheck(fieldsToCheck, value);
    }
    const hasErr = Object.keys(newErrorState).find(
      (item) => newErrorState[item]
    );
    setError(Boolean(hasErr));
    setErrorFields({ ...newErrorState });
    return Boolean(hasErr);
  };

  const handleFieldChange = (field, event, value) => {
    let newVal;
    switch (field) {
      case "expirationDate":
        newVal = dateFormat(event);
        break;
      case "expirationStatus":
        newVal = value;
        break;
      case "source":
        newVal = value;
        break;
      case "client":
        newVal = event;
        break;
      default:
        newVal = event.target ? event.target.value : event.value;
        break;
    }
    if (requiredFields.includes(field)) {
      validateRequiredFields("single", field, newVal);
    }
    setField((prevState) => ({ ...prevState, [field]: newVal })); //this way the state of the object property updates.
  };

  const handleEditRow = () => {
    let editData = { uniqueId: props.data.uniqueId };
    Object.keys(fields).forEach((key) => {
      switch (key) {
        case "client":
          editData[key] = {
            name: `${props.data.client} | ${props.data.clientDocument}`,
            value: props.data.clientId,
            rohiId: props.data.clientRohiId,
          };
          break;
        case "amountReceivable":
          // editData[key] = props.data[key].toString();
          setTotalAmount(props.data[key].toString());
          break;
        case "expirationDate":
          editData[key] = props.data[key] + " "; // the JS date object falls behind 1 day in some specific cases.
          break;
        default:
          editData[key] = props.data[key];
          break;
      }
    });
    // CONVERT SPANISH PROPERTY NAMES TO ENG
    // THEN ADD OBJECTS TO FIELDS OBJ.
    const generalProperties = {
      lateAmount: props.data.montoAtrasado || "",
      lateShare: props.data.cuotasAtrasadas || "",
    };
    const lDetail = props.data.detallePrestamo;
    const sDetail = props.data.detalleAhorro;
    const loanDetail =
      lDetail && lDetail.length
        ? lDetail.map((item) => {
            return {
              principal: {
                value: item.principal || "",
                label: "Principal",
                type: "money",
              },
              interes: {
                value: item.interes || "",
                label: "Interés",
                type: "money",
              },
              seguro: {
                value: item.seguro || "",
                label: "Seguro",
                type: "money",
              },
              comision: {
                value: item.comision || "",
                label: "Comisión",
                type: "money",
              },
              mora: { value: item.mora || "", label: "Mora", type: "money" },
              idDbs: { value: item.idDbs || 0, label: "ID DBS", type: "text" },
              fuente: {
                value: item.fuente || 0,
                label: "Fuente",
                type: "text",
              },
            };
          })
        : [];
    const savingDetail =
      sDetail && sDetail.length
        ? sDetail.map((item) => {
            return {
              idCuenta: {
                value: item.idCuenta || "",
                label: "Cuenta",
                type: "text",
              },
              descripcion: {
                value: item.descripcion || "",
                label: "Tipo de cuenta",
                type: "text",
              },
              cuota: { value: item.cuota || "", label: "Cuota", type: "money" },
            };
          })
        : [];
    setLoanAmount(props.data.montoPrestamo);
    setSavingAmount(props.data.montoAhorro);
    setLoanDetailList(loanDetail);
    setAccountList(savingDetail);
    editData = { ...editData, ...generalProperties };

    validateRequiredFields("all", editData);
    setField({ ...editData });
  };

  const getRohiAccounts = () => {
    if (fields.client && fields.client.rohiId) {
      const params = {
        token: props.token,
        clientId: fields.client.rohiId,
      };
      props.setLoading(true);
      const endpoint = fetchRohiSavingAccounts(params);
      endpoint
        .then((response) => {
          if (response.data.length) {
            const accounts = response.data.map((item) => {
              return {
                ...item,
                fullName: `${item.numero_cuenta} -- ${item.tipo_cuenta} -- ${item.nombre_socio}`,
              };
            });
            setRohiAccounts(accounts);
          }
          props.setLoading(false);
        })
        .catch((errors) => {
          props.setLoading(false);
          errorHandling(errors, props.handleSimpleDialog);
        });
    }
  };

  const handleAddLoanDetail = () => {
    const detail = {
      principal: {
        value: fields.principal || 0,
        label: "Principal",
        type: "money",
      },
      interes: { value: fields.interest || 0, label: "Interés", type: "money" },
      seguro: { value: fields.insurance || 0, label: "Seguro", type: "money" },
      comision: {
        value: fields.commission || 0,
        label: "Comisión",
        type: "money",
      },
      mora: { value: fields.arrear || 0, label: "Mora", type: "money" },
      idDbs: { value: fields.idDbs || 0, label: "ID DBS", type: "text" },
      fuente: { value: fields.source || 0, label: "Fuente", type: "text" },
    };
    const newList = loanDetailList.concat(detail);
    setLoanDetailList(newList);
    setField({
      ...fields,
      principal: "",
      interest: "",
      insurance: "",
      commission: "",
      arrear: "",
      source: "",
      idDbs: "",
    });
  };
  const handleAddSaving = () => {
    // const accountAdded = accountList.find(item => item.account === selectedAccount.numero_cuenta);
    // if(accountAdded) {
    //     props.handleSimpleDialog('simple-dialog', 'La cuenta ya está agregada.');
    //     return;
    // }
    const detail = {
      idCuenta: {
        value: selectedAccount.numero_cuenta,
        label: "Cuenta",
        type: "text",
      },
      cuota: { value: fields.share, label: "Cuota", type: "money" },
      descripcion: {
        value: selectedAccount.tipo_cuenta,
        label: "Tipo de cuenta",
        type: "text",
      },
    };
    const newList = accountList.concat(detail);
    setAccountList(newList);
    setField({ ...fields, savingAmount: "", share: "" });
    setSelectedAccount(null);
  };

  const removeItemList = (setUpdate, list, row) => {
    let copy = [...list];
    const index = list.findIndex((item) => item.account === row.account);
    if (index !== -1) {
      copy.splice(index, 1);
      setUpdate(copy);
    }
  };

  const handleSaveBtn = () => {
    if (validateRequiredFields("all", fields)) {
      props.handleSimpleDialog("simple-dialog", "Faltan campos por completar.");
      return;
    }
    if (!Number(loanDetailAmount) && !Number(savingAmount)) {
      props.handleSimpleDialog(
        "simple-dialog",
        "Debe tener al menos 1 detalle de préstamo o ahorro."
      );
      return;
    }
    let detail = {};
    const data = {
      montoPrestamo: Number(loanAmount || 0),
      montoAhorro: Number(savingAmount || 0),
      detallePrestamo: loanDetailList.map((item) => {
        detail = {};
        Object.keys(item).forEach((key) => {
          detail[key] = item[key].value;
        });
        return detail;
      }),
      detalleAhorro: accountList.map((item) => {
        detail = {};
        Object.keys(item).forEach((key) => {
          detail[key] = item[key].value;
        });
        return detail;
      }),
      montoAtrasado: Number(fields.lateAmount || 0),
      cuotasAtrasadas: Number(fields.lateShare || 0),
      expirationDate: fields.expirationDate?.trim() || null,
      amountReceivable: Number(totalAmount || 0),
      client: fields.client.value || null,
      uniqueId: fields.uniqueId || null,
    };
    props.handleExpiry(action, data);
  };

  useEffect(() => {
    if (props.modify) {
      handleEditRow();
    }
  }, []);

  useEffect(() => {
    getRohiAccounts();
  }, [fields.client]);

  useEffect(() => {
    const newLoanAmount =
      Number(fields.principal || 0) +
      Number(fields.interest || 0) +
      Number(fields.insurance || 0) +
      Number(fields.commission || 0) +
      Number(fields.arrear || 0);
    setLoanAmount(newLoanAmount);
  }, [
    fields.principal,
    fields.interest,
    fields.insurance,
    fields.commission,
    fields.arrear,
  ]);

  useEffect(() => {
    const listTotal = loanDetailList.reduce((acc, item) => {
      // const total = Object.keys(item).reduce((a, i) => { return parseFloat(a + (Number(item[i].value) || 0) ) }, 0);
      const total =
        Number(item.principal.value) +
        Number(item.interes.value) +
        Number(item.seguro.value) +
        Number(item.comision.value) +
        Number(item.mora.value);
      return parseFloat(acc + (total || 0));
    }, 0);
    setLoanDetailAmount(listTotal.toFixed(2));
  }, [loanDetailList]);

  useEffect(() => {
    const listTotal = accountList.reduce((acc, item) => {
      return parseFloat(acc + Number(item.cuota.value));
    }, 0);
    setSavingAmount(listTotal.toFixed(2));
  }, [accountList]);

  useEffect(() => {
    const total =
      Number(savingAmount || 0) +
      Number(loanDetailAmount || 0) +
      Number(fields.lateAmount || 0);
    setTotalAmount(total.toFixed(2));
  }, [loanDetailAmount, savingAmount, fields.lateAmount]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={props.open}
    >
      <DialogHeader
        dialogTitle="Vencimientos"
        handleClick={props.handleToggleDialog.bind(this, undefined)}
      />
      <DialogContent style={{ margin: "2% 0" }}>
        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          <SearchComponent
            xs={6}
            containerClass="field-container"
            fieldLabel="Participantes"
            fieldID="expiry-client-new-expiry"
            fieldInnerLabel="Seleccione un participante"
            handleChange={handleFieldChange.bind(this, "client")}
            onInputChange={() => {
              // gg
            }}
            savedValue={fields.client ? fields.client : null}
            optionType={"participants"}
            clearOnBlur={false}
            limitTags={1}
            error={errorFields.client}
            helperText={errorFields.client ? "Seleccione un cliente" : null}
          />
          <DateField
            xs={6}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Fecha"
            dateFieldID="field-due-modify-date"
            fullWidth={true}
            dateFieldClass="date-field"
            inputVariant="outlined"
            dateFieldLabel="Fecha vencimiento"
            format="dd/MM/yyyy"
            value={fields.expirationDate ? fields.expirationDate : null}
            handleChange={handleFieldChange.bind(this, "expirationDate")}
            error={errorFields.expirationDate}
            helperText={
              errorFields.expirationDate ? "Seleccione una fecha" : null
            }
          />
        </Grid>

        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#878787",
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          DETALLE PRÉSTAMO
        </Typography>
        <Divider style={{ margin: "1rem 0" }} />

        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          <NumericTextField
            xs={3}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Principal"
            placeholder="Ej: $4,000"
            fieldID="text-field-amount-principal"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            fullWidth
            prefix="$"
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            value={fields.principal ? fields.principal : ""}
            onChange={handleFieldChange.bind(this, "principal")}
            // error={errorFields.principal}
            // helperText={errorFields.principal ? "Introduzca un monto" : null}
          />
          <NumericTextField
            xs={3}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Interés"
            placeholder="Ej: $500"
            fieldID="text-field-amount-interest"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            fullWidth
            prefix="$"
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            value={fields.interest ? fields.interest : ""}
            onChange={handleFieldChange.bind(this, "interest")}
            // error={errorFields.interest}
            // helperText={errorFields.interest ? "Introduzca un monto" : null}
          />
          <NumericTextField
            xs={3}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Seguro"
            placeholder="Ej: $3,000"
            fieldID="text-field-amount-insurance"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            fullWidth
            prefix="$"
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            value={fields.insurance ? fields.insurance : ""}
            onChange={handleFieldChange.bind(this, "insurance")}
            // error={errorFields.insurance}
            // helperText={errorFields.insurance ? "Introduzca un monto" : null}
          />
          <NumericTextField
            xs={3}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Comisión"
            placeholder="Ej: $6,500"
            fieldID="text-field-amount-commission"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            fullWidth
            prefix="$"
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            value={fields.commission ? fields.commission : ""}
            onChange={handleFieldChange.bind(this, "commission")}
            // error={errorFields.commission}
            // helperText={errorFields.commission ? "Introduzca un monto" : null}
          />
        </Grid>

        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          <NumericTextField
            xs={3}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Mora"
            placeholder="Ej: $1,500"
            fieldID="text-field-amount-arrear"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            fullWidth
            prefix="$"
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            value={fields.arrear ? fields.arrear : ""}
            onChange={handleFieldChange.bind(this, "arrear")}
          />
          <TextFieldComponent
            xs={3}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="ID DBS (*)"
            fieldID="text-field-id-dbs-expiry"
            fieldClass="fields"
            fieldVariant="outlined"
            placeholder="Ej: 115"
            margin="dense"
            fullWidth
            value={fields.idDbs ? fields.idDbs : ""}
            onChange={handleFieldChange.bind(this, "idDbs")}
          />
          <AutocompleteField
            xs={3}
            containerClass="field-container"
            fieldClass="fields"
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Fuentes (*)"
            fieldID="source-selection"
            fieldInnerLabel="Selecciona una fuente"
            fieldVariant="outlined"
            value={fields.source ? fields.source : null}
            handleChange={handleFieldChange.bind(this, "source")}
            options={getFiltersOptions(["BARCELONA", "IE", "MFS"])}
            multiple={false}
          />

          <Grid
            container
            item
            xs={3}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              variant="contained"
              style={{ height: 31 }}
              className={
                !Boolean(Number(loanAmount)) || !fields.idDbs || !fields.source
                  ? "action-method-btn disabled"
                  : "action-method-btn save"
              }
              disableElevation
              size="small"
              disabled={
                !Boolean(Number(loanAmount)) || !fields.idDbs || !fields.source
              }
              onClick={handleAddLoanDetail}
            >
              agregar
            </Button>
          </Grid>
          {/* <NumericTextField
                        xs={4}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Monto préstamo"
                        placeholder="$0.00"
                        fieldID="text-field-amount-loan"
                        fieldClass="fields"
                        fieldVariant="outlined"
                        margin='dense'
                        fullWidth
                        prefix="$"
                        disabled={true}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={loanAmount}
                        // error={errorFields.amountReceivable}
                        // helperText={errorFields.amountReceivable ? "Introduzca un monto" : null}
                    /> */}
        </Grid>
        <ListDetail
          list={loanDetailList}
          columnWidth={150}
          removeItem={removeItemList.bind(
            this,
            setLoanDetailList,
            loanDetailList
          )}
          title="PRÉSTAMOS"
        />

        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#878787",
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          DETALLE AHORRO
        </Typography>
        <Divider style={{ margin: "1rem 0" }} />

        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="flex-end"
          style={{ padding: "0 1%" }}
        >
          <AutocompleteField
            xs={8}
            containerClass="field-container"
            fieldClass="fields"
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Cuentas (*)"
            fieldID="rohi-accounts"
            fieldInnerLabel="Selecciona una cuenta"
            fieldVariant="outlined"
            value={selectedAccount || null}
            handleChange={(event, value) => {
              setSelectedAccount(value);
            }}
            options={getFiltersOptions(rohiAccounts)}
            getOptionLabel={(option) => option.fullName}
            multiple={false}
            noOptionsText={
              !fields.client ? <i>SELECCIONE UN PARTICIPANTE</i> : null
            }
          />
          <NumericTextField
            xs={2}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Cuotas (*)"
            placeholder="Ej: $2,000"
            fieldID="text-field-amount-share"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            prefix="$"
            fullWidth
            thousandSeparator={true}
            fixedDecimalScale={true}
            value={fields.share ? fields.share : ""}
            onChange={handleFieldChange.bind(this, "share")}
            // error={errorFields.interest}
            // helperText={errorFields.interest ? "Introduzca un monto" : null}
          />
          <Grid container item xs={2} justifyContent="flex-end">
            <Button
              variant="contained"
              className={
                !selectedAccount || !fields.share
                  ? "action-method-btn disabled"
                  : "action-method-btn save"
              }
              disableElevation
              size="small"
              disabled={!selectedAccount || !fields.share}
              onClick={handleAddSaving}
            >
              agregar
            </Button>
          </Grid>
        </Grid>
        <ListDetail
          list={accountList}
          columnWidth={220}
          removeItem={removeItemList.bind(this, setAccountList, accountList)}
          title="AHORROS"
        />
        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          <NumericTextField
            xs={4}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Monto ahorro"
            placeholder="$0.00"
            fieldID="text-field-amount-saving"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            fullWidth
            prefix="$"
            disabled={true}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            value={savingAmount}
            // onChange={handleFieldChange.bind(this, 'savingAmount')}
            // error={errorFields.interest}
            // helperText={errorFields.interest ? "Introduzca un monto" : null}
          />
        </Grid>

        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#878787",
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          ATRASOS
        </Typography>
        <Divider style={{ margin: "1rem 0" }} />

        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          <NumericTextField
            xs={4}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Monto atrasado"
            placeholder="Ej: $5,900"
            fieldID="text-field-amount-late"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            fullWidth
            prefix="$"
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            value={fields.lateAmount ? fields.lateAmount : ""}
            onChange={handleFieldChange.bind(this, "lateAmount")}
            // error={errorFields.interest}
            // helperText={errorFields.interest ? "Introduzca un monto" : null}
          />
          <NumericTextField
            xs={4}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Cuotas atrasadas"
            placeholder="Ej: 20"
            fieldID="text-field-late-share"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            decimalScale={0}
            fullWidth
            thousandSeparator={true}
            fixedDecimalScale={true}
            value={fields.lateShare ? fields.lateShare : ""}
            onChange={handleFieldChange.bind(this, "lateShare")}
            // error={errorFields.interest}
            // helperText={errorFields.interest ? "Introduzca un monto" : null}
          />
        </Grid>

        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#878787",
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          TOTAL
        </Typography>
        <Divider style={{ margin: "1rem 0" }} />

        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          <NumericTextField
            xs={4}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Monto vencido"
            placeholder="$0.00"
            fieldID="text-field-amount-past-due"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            fullWidth
            prefix="$"
            disabled={true}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            value={totalAmount || ""}
            // onChange={handleFieldChange.bind(this, 'amountReceivable')}
            // error={errorFields.amountReceivable}
            // helperText={errorFields.amountReceivable ? "Introduzca un monto" : null}
          />
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          style={{ marginTop: "3%", paddingRight: "1%" }}
        >
          <Button
            variant="contained"
            className={
              error ? "action-method-btn disabled" : "action-method-btn save"
            }
            disableElevation
            size="small"
            onClick={handleSaveBtn}
            disabled={error}
          >
            guardar cambios
          </Button>
        </Grid>
      </DialogContent>
      <DialogFooter
      // dialogFooterText="Guardar cambios"
      />
    </Dialog>
  );
}
function ExpiryRohi(props) {
  const [fields, setField] = useState({});
  const [errorFields, setErrorFields] = useState({}); // the error field obj (to tell the field if there's an error)
  const [error, setError] = useState(false); // general error variable. Show if there's a field with an error.

  const getFiltersOptions = (option) => {
    return option ? option : [];
  };

  const handleFieldChange = (field, event, value) => {
    let newVal;
    let type2 = "loansOnly";
    switch (field) {
      case "to_due_date":
      case "from_due_date":
        newVal = dateFormat(event);
        break;
      case "client_id":
        newVal = event;
        break;
      case "workplace_id":
      case "workClassId":
      case "purchaseArrears":
        newVal = value;
        break;
      case "loansOnly":
      case "savingsOnly":
        newVal = value;
        type2 = field === "loansOnly" ? "savingsOnly" : "loansOnly";
        break;
      default:
        newVal = "";
        break;
    }
    setField({ ...fields, [field]: newVal, [type2]: false });
  };

  const validateDates = () => {
    let err = false;
    if (!fields.to_due_date && !fields.from_due_date) {
      err = "Debe seleccionar una fecha de inicio o corte.";
    }
    if (fields.to_due_date && fields.from_due_date) {
      if (Date.parse(fields.to_due_date) < Date.parse(fields.from_due_date)) {
        err = "Fecha de inicio es mayor al corte.";
      } else {
        const getDiff =
          (new Date(fields.to_due_date).getTime() -
            new Date(fields.from_due_date).getTime()) /
          1000 /
          60 /
          60 /
          24;
        if (getDiff + 1 > 5) {
          err = "Rango de fechas no puede pasar de 5 días";
        }
      }
    }
    setErrorFields(err);
    setError(Boolean(err));
    return err;
  };

  const handleSaveBtn = () => {
    const err = validateDates();
    if (err) {
      props.handleSimpleDialog("simple-dialog", err);
      return;
    }
    const data = {
      to_due_date: fields.to_due_date,
      from_due_date: fields.from_due_date,
      // client_id: fields.client_id ? fields.client_id.map(item => item.rohiId).join(',') : null, // HANDLE MULTIPLE CLIENTS
      client_identification:
        fields.client_id && fields.client_id.identificationNumber, // ONLY ONE CLIENT FOR NOW
      workplace_id: fields.workplace_id
        ? fields.workplace_id
            .map((item) =>
              item.institutionType === "financial" && item.rohiIdPatrono
                ? item.rohiIdPatrono
                : item.rohiId
            )
            .join(",")
        : null,
      work_class_id: fields.workClassId
        ? fields.workClassId.map((item) => item.value).join(",")
        : null,
      expiry_calc: fields.loansOnly ? 1 : fields.savingsOnly ? 2 : 0,
      include_bond_arr: fields.purchaseArrears ? 1 : 0,
    };
    Object.keys(data).forEach((key) => {
      if (
        typeof data[key] !== "number" &&
        (!data[key] || data[key].length === 0)
      ) {
        delete data[key];
      }
    });
    props.handleRohiUpload(data);
    // traer toda la lista de vencimientos de Rohi y mostrarlos en la tabla.
  };

  const handleCreation = () => {
    props.handleCreation(props.rohiExpiries);
  };

  useEffect(() => {
    if (fields.to_due_date || fields.from_due_date) {
      validateDates();
    }
  }, [fields.to_due_date, fields.from_due_date]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={(e, reason) => reason === "backdropClick" && e.preventDefault()}
      className="dialog-container"
      open={props.open}
    >
      <DialogHeader
        dialogTitle="Carga vencimientos ROHI"
        handleClick={props.handleToggleDialog.bind(this, undefined)}
      />
      <DialogContent style={{ margin: "2% 0" }}>
        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          {/* <AutocompleteField
                        xs={6}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Cliente"
                        fieldID="autocomplete-due-rohi-upload"
                        fieldInnerLabel="Seleccione un cliente"
                        fieldVariant="outlined"
                        value={fields.client_id || []}
                        handleChange={handleFieldChange.bind(this, 'client_id')}
                        options={getFiltersOptions(props.listData.clients)}
                        getOptionLabel={(option) => option.fullName}
                        multiple={true}
                        limitTags={1}
                    /> */}
          <SearchComponent
            xs={6}
            containerClass="field-container"
            fieldLabel="Participantes"
            fieldID="expiry-client-rohi-expiry"
            fieldInnerLabel="Seleccione un participante"
            handleChange={handleFieldChange.bind(this, "client_id")}
            onInputChange={() => {
              // gg
            }}
            savedValue={fields.client ? fields.client : null}
            optionType={"participants"}
            clearOnBlur={false}
            limitTags={1}
          />
          <AutocompleteField
            xs={6}
            containerClass="field-container"
            fieldClass="fields"
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Institución laboral"
            fieldID="autocomplete-works-rohi-upload"
            fieldInnerLabel="Seleccione una institución"
            fieldVariant="outlined"
            value={fields.workplace_id || []}
            handleChange={handleFieldChange.bind(this, "workplace_id")}
            options={getFiltersOptions(props.listData.works)}
            getOptionLabel={(option) => option.fullName}
            multiple={true}
            limitTags={1}
          />
        </Grid>
        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          <DateField
            xs={6}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Fecha"
            dateFieldID="field-start-date"
            fullWidth={true}
            dateFieldClass="date-field"
            inputVariant="outlined"
            dateFieldLabel="Fecha inicio"
            format="dd/MM/yyyy"
            value={
              fields.from_due_date
                ? fields.from_due_date.replace("-", "/")
                : null
            }
            handleChange={handleFieldChange.bind(this, "from_due_date")}
            error={error}
            helperText={error && errorFields}
          />

          <DateField
            xs={6}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Fecha"
            dateFieldID="field-end-date"
            fullWidth={true}
            dateFieldClass="date-field"
            inputVariant="outlined"
            dateFieldLabel="Fecha corte"
            format="dd/MM/yyyy"
            value={
              fields.to_due_date ? fields.to_due_date.replace("-", "/") : null
            }
            handleChange={handleFieldChange.bind(this, "to_due_date")}
            error={error}
            helperText={error && errorFields}
          />
        </Grid>
        <Grid container direction="row" spacing={2} style={{ padding: "0 1%" }}>
          <AutocompleteField
            xs={6}
            containerClass="field-container"
            fieldClass="fields"
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Clasificación de institución"
            fieldID="filter-field-financial-classification"
            fieldInnerLabel="Seleccione..."
            fieldVariant="outlined"
            value={fields.workClassId || []}
            handleChange={handleFieldChange.bind(this, "workClassId")}
            options={institutionClassification}
            getOptionLabel={(option) => option.label}
            multiple={true}
            limitTags={2}
          />
          <Grid container item xs={6}>
            <CheckboxField
              xs={6}
              alignItems="flex-end"
              check={fields.loansOnly || false}
              handleChange={handleFieldChange.bind(this, "loansOnly")}
              color="primary"
              textColor="#43A047"
              label="SOLO PRESTAMOS"
            />
            <CheckboxField
              xs={6}
              alignItems="flex-end"
              check={fields.savingsOnly || false}
              handleChange={handleFieldChange.bind(this, "savingsOnly")}
              color="primary"
              textColor="#43A047"
              label="SOLO AHORROS"
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={2}
          style={{ padding: "0 1%", marginTop: "1rem" }}
        >
          <Grid container item xs={6}>
            <CheckboxField
              xs={12}
              alignItems="flex-end"
              check={fields.purchaseArrears || false}
              handleChange={handleFieldChange.bind(this, "purchaseArrears")}
              color="primary"
              textColor="#43A047"
              label="INCLUYE ATRASOS COMPRAS"
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" style={{ margin: "2rem 0" }}>
          <Button
            variant="contained"
            className={
              error ? "action-method-btn disabled" : "action-method-btn save"
            }
            disableElevation
            size="small"
            onClick={handleSaveBtn}
            disabled={error}
          >
            cargar vencimientos
          </Button>
        </Grid>

        <RohiExpiryTable data={props.rohiExpiries} />
        <Grid
          container
          justifyContent="flex-end"
          style={{ marginTop: "2rem", paddingRight: "1%" }}
        >
          <Button
            variant="contained"
            className={
              error || !props.rohiExpiries || props.rohiExpiries.length === 0
                ? "action-method-btn disabled"
                : "action-method-btn save"
            }
            disableElevation
            size="small"
            onClick={handleCreation}
            disabled={!props.rohiExpiries || props.rohiExpiries.length === 0}
          >
            continuar
          </Button>
        </Grid>
      </DialogContent>
      <DialogFooter />
    </Dialog>
  );
}

export { ExpiryPayDialog, Expiry, ExpiryRohi, ConfirmDialogComponent };
