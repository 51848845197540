import * as React from 'react';
import { translations } from 'src/utils';

import { Divider, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, withStyles } from '@material-ui/core/styles';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

const styles:any = createStyles({
    root: {
        paddingBottom: 20
    }

});
interface IOption {
    caption: string;
    value: string;
}
interface IFilterDialogProps {
    classes: {
        root: string
    };
    options:  IOption[];
    open: boolean;
    selectedValue: string;
    onClose(selectedValue: string): void;
}

class FilterDialog extends React.Component<IFilterDialogProps> {
    constructor(props: IFilterDialogProps){
        super(props);
        
        this.handleClose = this.handleClose.bind(this);
        this.mapList = this.mapList.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    public handleClose() {
        this.props.onClose(this.props.selectedValue);
    }
    public handleClick(value: string) {
        return (event: React.MouseEvent<HTMLElement>) => {
            this.props.onClose(value);
        }
    }
    public mapList(option: IOption){
        
        const selected: boolean = this.props.selectedValue === option.value;
        return (
            <ListItem 
                selected={selected} 
                divider={true} 
                button={true} 
                onClick={this.handleClick(option.value)} 
                key={option.value}
                >
                    <ListItemAvatar>
                       <Avatar style={{ color: 'white', backgroundColor: green[500] }}>
                           <FiberManualRecord />
                       </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={option.caption} />
            </ListItem>
        );
    }
    public render() {

        const { classes, open, options } = this.props;
        return(
            <Dialog onClose={this.handleClose}  open={open}>
                <DialogTitle id="simple-dialog-title">{translations.SEARCH_BY}</DialogTitle>
                <div className={classes.root}>
                    <List>
                        <Divider />
                        {options.map(this.mapList)}
                    </List>
                </div>
            </Dialog>
        );
    }   
}

export default withStyles(styles)(FilterDialog);