import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, InputAdornment, TextField, Toolbar, Typography } from "@material-ui/core";
import { Telegram, Edit, Search } from "@material-ui/icons";
import { AutocompleteField, TableSearch } from "src/components/SharedComponents/Fields";
import { GeneralTable } from "src/components/SharedComponents/Table";
import { green } from "@material-ui/core/colors";
import { updateParticipantAgent } from "src/utils/axios";
import { errorHandling, useHandleSelectAllRows, useHandleSelectRow, useIsSelected } from "../../../components/SharedComponents/CustomHooks";

function BusinessAgentsTable(props) {
  const [tableData, setTableData] = useState([]);
  const actions = (row) => {
    return (
      <>
        <div style={{ alignItems: "center" }}>
          <IconButton
            onClick={props.handleToggleDialog.bind(this, 'business-agent', row)}
          >
            <Edit style={{ fontSize: 22, color: green[500] }} />
          </IconButton>
          <IconButton
            onClick={
              props.handleToggleSimpleDialog
              .bind(this, 'confirm-dialog', 'proceed-submit-agent', {callback: props.handleSubmitAgent.bind(this, row)})
            }
          >
            <Telegram style={{ fontSize: 22, color: green[500] }} />
          </IconButton>
        </div>
      </>
    );
  };

  const isActiveField = (row) => {
    const activeText = row.isActive ? "Activo" : "Inactivo";
    return (
      <>
        <div>
          <Button
            variant="contained"
            disableElevation
            style={{
              minWidth: 45,
              height: 25,
              borderRadius: 20,
              backgroundColor: row.isActive ? "#43A047" : "#f44336",
              color: "#FFFFFF",
              fontSize: 12,
            }}
          >
            {activeText}
          </Button>
        </div>
      </>
    );
  };
  const columns = [
    {
      id: "name",
      label: "NOMBRE REPRESENTANTE",
      align: "center",
      minWidth: 230,
    },
    {
      id: "isActive",
      label: "ESTADO",
      align: "center",
      minWidth: 230,
      element: isActiveField,
    },
    {
      id: "rohiId",
      label: "ID DE ROHI",
      align: "center",
      minWidth: 230,
      element: (row) => (row.rohiId ? row.rohiId : "---"),
    },
    {
      id: "relatedUser",
      label: "USUARIO SISTEMA",
      align: "center",
      minWidth: 230,
      element: (row) => (row.relatedUser ? row.relatedUser : "---"),
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      minWidth: 150,
      element: actions,
    },
  ];
  useEffect(() => {
    setTableData(props.data);
  }, [props.data]);
  return (
    <GeneralTable
      columns={columns}
      data={tableData}
      toolbar={
          <Grid container justifyContent="flex-end" alignItems="center">
            <TableSearch
              data={props.data}
              setTableData={setTableData}
              searchFields={["name"]}
            />
          </Grid>
        }
    />
  );
}

function DueAssignmentTableToolbar(props) {
  const [search, setSearch] = useState('');
  const [selectedBusinessAgent, setBusinessAgents] = useState(null);
  
  const handleSearch = (event) => {
      const value = event.target.value.toLowerCase();
      const filteredClients = props.originalData.filter(item =>
          item.fullName.toLowerCase().includes(value) ||
          item.work.toLowerCase().includes(value) ||
          item.identificationNumber.toLowerCase().includes(value)
      )
      setSearch(event.target.value);
      props.handleDataChange(filteredClients);
  }

  const handleBusinessAgentChange = (e, val) => {
      setBusinessAgents(val);
  }
  const postAssign = (assign) => {
      props.setLoading(true);
      const endpoint = updateParticipantAgent(assign);
      endpoint.then(() => {
          props.setSelected([]);
          setBusinessAgents(null)
          props.setLoading(false);
          props.handleClients();
          props.handleToggleConfirm('success-dialog');
      }).catch(errors => {
          props.setLoading(false);
          errorHandling(errors, props.handleToggleConfirm);
      });
  }
  const handleAssignClick = () => {
      const assignData = {
          agent: selectedBusinessAgent.uniqueId,
          participants: props.selectedRows.map(item=>item.uniqueId).join('|'),
          token: props.token,
      }
      postAssign(assignData);
  }
  return(
      <Toolbar>
          <Grid container item direction="row" spacing={5} alignItems="center" justifyContent="space-between">
              <Grid item container xs={8}>
                  {
                      props.selectedRows.length > 0 ?
                          <Grid container justifyContent="space-between" spacing={2} style={{marginBottom:5}}>
                              <Grid item container xs={8}>
                                  <AutocompleteField
                                      xs={12}
                                      fieldID="select-assign-business-agent-assignment"
                                      fieldClass="fields"
                                      fieldInnerLabel="Responsable a asignar"
                                      value={selectedBusinessAgent}
                                      handleChange={handleBusinessAgentChange}
                                      options={props.businessAgents}
                                      getOptionLabel={(option) => option.name}
                                      multiple={false}
                                  />
                              </Grid>

                              <Grid item container xs={4} alignItems="flex-end" justifyContent="flex-start">
                                  <Button
                                      disableElevation
                                      className={selectedBusinessAgent ? "assign-expiry-to-business-agent-btn" : "assign-expiry-to-business-agent-btn disabled"}
                                      onClick={handleAssignClick}
                                      disabled={!selectedBusinessAgent}
                                  >
                                      Asignar
                                  </Button>
                              </Grid>
                          </Grid>
                      :
                          <Typography
                              variant="subtitle1"
                              className="section-header"
                              style={{fontWeight: 'bold'}}
                          >
                              Clientes
                          </Typography>
                  }
              </Grid>

              <Grid item container xs={4}>
                  <TextField
                      variant="standard"
                      placeholder="Buscar..."
                      fullWidth
                      value={search}
                      onChange={handleSearch}
                      InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                  <Search style={{color: '#757575'}}/>
                              </InputAdornment>
                          ),
                      }}
                  ></TextField>
              </Grid>
          </Grid>
      </Toolbar>
  );
}

function DueAssignmentTable(props) {

  const [tableData, setTableData] = useState(props.data); // state to mutate the table data
  const [selected, setSelected] = useState([]); // state to manage the selected rows.

  const handleDataSearch = (newData) => {
      setTableData(newData);
  }
  // update the table data every time props.data (the table data) changes.
  useEffect(() => {
      setTableData(props.data);
  }, [props.data]);

  const columns = [
      {id:'checkbox'},
      {id:'businessAgent', label: 'RESPONSABLE', minWidth: 170},
      {id:'fullName', label: 'CLIENTE', minWidth: 170,},
      {id:'identificationNumber', label: 'CÉDULA', minWidth: 120,},
      {id:'work', label: 'INSTITUCIÓN TRABAJO', minWidth: 170},
      // {id:'bank',label: 'INSTITUCIÓN FINANCIERA', minWidth: 190},
      // {id:'rating',label: 'RATING', minWidth: 80,},
  ];

  return(
      <GeneralTable
          columns={columns}
          data={tableData}
          selected={selected}
          handleSelectAllRows={useHandleSelectAllRows}
          handleSelectRow={useHandleSelectRow}
          setSelected={setSelected}
          isSelected={useIsSelected}
          checkbox={true}
          maxHeight={550}
          toolbar={
              <DueAssignmentTableToolbar
                  selectedRows={selected}
                  setSelected={setSelected}
                  originalData={props.data}
                  tableData={tableData}
                  handleDataChange={handleDataSearch}
                  setLoading={props.setLoading}
                  handleToggleConfirm={props.handleToggleConfirm}
                  handleClients={props.handleClients}
                  businessAgents={props.businessAgents}
                  token={props.token}
              />
          }
      />

  );
}

export { BusinessAgentsTable, DueAssignmentTable, DueAssignmentTableToolbar };
