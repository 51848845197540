import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Toolbar,
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Paper,
} from "@material-ui/core";

import { DataGrid } from "@material-ui/data-grid";

import { Edit, Delete, Description } from "@material-ui/icons";

import {
  NumericTextField,
  TextFieldComponent,
  CheckboxField,
  RadioGroupComponent,
  DateField,
  AutocompleteField,
} from "./../../../components/SharedComponents/Fields";
import { GeneralTable } from "./../../../components/SharedComponents/Table";
import NumberFormat from "./../../../components/SharedComponents/NumberFormat";
import { DialogHeader } from "./../../../components/SharedComponents/Dialogs";
import { dateFormat } from "./../../../components/SharedComponents/CustomHooks";

import { translations, checkActions, bankAccountTypes } from "../../../utils/index";
import MoneyInput from "src/components/TextMaskInputComponents/moneyInput";
import { updateOutlayCheck } from "src/utils/axios";
import PaymentCalculator from "./../../../components/Calculators/PaymentTable";
import { makeid } from "./../../../components/SharedComponents/CustomHooks";

const amount = (column, row, row2, readOnly) => {
  const value = column[row] !== undefined ? column[row] : column[row2];
  return <NumberFormat value={value} disabled={readOnly} prefix={"$"} />;
};

const simpleRow = (column, row, row2) => {
  const value = column[row] !== undefined ? column[row] : column[row2];
  return <span>{value}</span>;
};

const dateFormatRow = (row, column) => {
  const date = row[column];
  return (
    <span>
      {date && typeof date === "string"
        ? date.substring(0, date.indexOf("T"))
        : date
        ? dateFormat(date)
        : "---"}
    </span>
  );
};

const actions = (row, deleteRow, openEdit, enableReference) => {
  return (
    <Grid container direciton="row" justifyContent="center" spacing={5}>
      {openEdit && (
        <IconButton onClick={() => openEdit(row)}>
          <Edit style={{ fontSize: 22, color: "#616161" }} />
        </IconButton>
      )}
      {!enableReference && (
        <IconButton onClick={() => deleteRow(row)}>
          <Delete style={{ fontSize: 22, color: "#616161" }} />
        </IconButton>
      )}
    </Grid>
  );
};

function ResponsibleTableToolbar(props) {
  return (
    <Toolbar>
      <Grid container item direction="row" spacing={5} alignItems="center">
        <Typography
          variant="subtitle1"
          className="section-header"
          style={{ fontWeight: "bold", paddingLeft: 16 }}
        >
          {props.title}
          {!props.readOnly && (
            <Button
              variant="contained"
              className={
                props.data < 1
                  ? "action-method-btn disabled"
                  : "action-method-btn save"
              }
              style={{ marginLeft: ".8rem", fontSize: "10px" }}
              disableElevation
              size="small"
              disabled={props.data < 1}
              onClick={() => props.setOpen(true)}
            >
              <Description
                style={{
                  fontSize: 15,
                  color: "#fff",
                  margin: "0 .5rem .1rem 0",
                }}
              />
              + Agregar
            </Button>
          )}
        </Typography>
      </Grid>
    </Toolbar>
  );
}

function Checks(props) {
  const createInitValues = props.paymentTicket
    ? { action: "deposit", isAdministrative: false }
    : {};
  const [open, setOpen] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);
  const [errorFields, setErrorFields] = useState({});
  const [edit, setEdit] = useState(false);
  const [createData, setCreateData] = useState(createInitValues);
  const fields = {
    ...createData,
    // transferAmount: transferAmount,
    // originAccount: originAccount,
    // referenceNumber: referenceNumber,
  };
  let requiredFields = ["transferAmount", "originAccount"];
  const paymentReqFields = [
    "name",
    "action",
    "expirationDate",
    "transferAmount",
  ];
  if (props.paymentTicket) {
    requiredFields = paymentReqFields;
  }

  const validateRequiredFields = (type, fieldToCheck, value) => {
    // validate fields errors
    let newErrorState = { ...errorFields };
    const fieldCheck = (key, value) => {
      if (key === "expirationDate" && value) {
        newErrorState[key] = false;
        return;
      }
      newErrorState[key] = !value || !Object.keys(value).length ? true : false;
      // value === null || value === undefined || value === 0 || value
      //   ? value.toString() === ""
      //   : true || value
      //     ? Object.keys(value).length === 0
      //     : true
      //       ? true
      //       : false;
    };
    if (type === "all") {
      requiredFields.forEach((key) => {
        fieldCheck(key, fields[key]);
      });
    } else if (type === "single" && requiredFields.includes(fieldToCheck)) {
      fieldCheck(fieldToCheck, value);
    }
    const hasErr = Object.keys(newErrorState).find(
      (item) => newErrorState[item]
    );
    setErrorFields({ ...newErrorState });
    return Boolean(hasErr);
  };

  const add = () => {
    if (validateRequiredFields("all")) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `Faltan campos por completar.`
      );
      return;
    }
    props.handleAdd(
      {
        ...createData,
        // transferAmount: transferAmount,
        // originAccount: originAccount,
        // referenceNumber: '',
        // id: props.checks.length + 1,
        id: makeid(6),
      },
      "checks"
    );
    setOpen(false);
    // setTransferAmount("");
    // setOriginAccount("");
    setCreateData(createInitValues);
  };

  const editRow = () => {
    if (validateRequiredFields("all")) {
      props.handleToggleSimpleDialog(
        "simple-dialog",
        `Faltan campos por completar.`
      );
      return;
    }
    let token = localStorage.getItem("token");

    let newArr = [...props.checks];
    let recordIndex = props.checks.findIndex((x) => x.id === currIndex);
    const check = props.checks.find((x) => x.id === currIndex);
    newArr[recordIndex] = {
      ...check,
      ...createData,
      // transferAmount: transferAmount,
      // originAccount: originAccount,
      // referenceNumber: referenceNumber,
    };
    props.handleEdit(newArr, "checks");

    setEdit(false);
    let newCheck = newArr[recordIndex];

    if (newCheck.uniqueId && !props.fromTicket) {
      props.setLoading(true);
      const newAccount = props.creditorAccounts.find(
        (y) => y.number === newCheck.originAccount
      );
      let newInfo = {
        ...newCheck,
        account: newAccount ? newAccount.uniqueId : "",
        transferAmount: newCheck.transferAmount,
        concept: `Desembolso por ${newCheck.transferAmount.toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
          }
        )} de ${props.participantInfo.fullName || props.newClientFullName}`,
      };
      updateOutlayCheck(token, newInfo.uniqueId, newInfo)
        .then((response) => {
          props.handleToggleSimpleDialog("success-dialog");
        })
        .catch((err) => {
          props.setLoading(false);
          props.errorHandling(err, props.handleToggleSimpleDialog);
        })
        .finally(() => props.setLoading(false));
    }

    setOpen(false);
    // setTransferAmount("");
    // setOriginAccount("");
    // setReferenceNumber("");
    setCreateData(createInitValues);
  };

  const openEdit = (row) => {
    setOpen(true);
    setCreateData(row);

    // setTransferAmount(row.transferAmount);
    // setOriginAccount(row.originAccount);
    // setReferenceNumber(row.referenceNumber);
    setEdit(true);
    setCurrIndex(row.id);
  };

  const deleteRow = (row) => {
    let token = localStorage.getItem("token");
    let info = {};

    if (row.uniqueId && !props.fromTicket) {
      info = { ...row, status: "cancelled" };
      props.setLoading(true);
      updateOutlayCheck(token, info.uniqueId, info)
        .then((res) => {
          props.handleToggleSimpleDialog(
            "success-dialog",
            "Solicitud de cheque cancelada"
          );
        })
        .catch((err) => {
          props.setLoading(false);
          props.errorHandling(err, props.handleToggleSimpleDialog);
        })
        .finally(() => props.setLoading(false));
    }

    let recordIndex = props.checks.findIndex((x) => x.id === row.id);
    props.handleDelete(recordIndex, "checks");
  };

  const administrativeEl = (row) => {
    return <span>{row.isAdministrative ? "SI" : "NO"}</span>;
  };

  const methodActionEl = (row) => {
    const action = checkActions.find((item) => item.value === row.action);
    return <span>{!row.action ? "---" : action && action.label}</span>;
  };

  const originAccountEl = (row) => {
    return <span>{row.originAccount ? row.originAccount.value : "---"}</span>;
  };

  const checkColumns = [
    {
      id: "originAccount",
      label: "CUENTA ORIGEN",
      minWidth: 180,
      hide: props.paymentTicket,
      element: originAccountEl,
    },
    {
      id: "name",
      label: "NOMBRE EN CHEQUE",
      minWidth: 180,
      hide: !props.paymentTicket,
    },
    {
      id: "isAdministrative",
      label: "ADMINISTRATIVO",
      minWidth: 150,
      hide: !props.paymentTicket,
      element: administrativeEl,
    },
    {
      id: "expirationDate",
      label: "FECHA",
      minWidth: 90,
      element: (row) => dateFormatRow(row, "expirationDate"),
      hide: !props.paymentTicket,
    },
    {
      id: "action",
      label: "MÉTODO",
      minWidth: 140,
      hide: !props.paymentTicket,
      element: methodActionEl,
    },
    {
      id: "transferAmount",
      label: "MONTO",
      minWidth: 150,
      element: (row) => amount(row, "transferAmount", "", props.readOnly),
    },
    { id: "referenceNumber", label: "REFERENCIA", minWidth: 180 },
    {
      id: "dataAction",
      label: "ACCIÓN",
      align: "center",
      minWidth: 100,
      element: (row) =>
        actions(row, deleteRow, openEdit, props.enableReference),
    },
  ]
    .filter((item) =>
      props.readOnly && !props.enableReference
        ? item.id !== "dataAction" && item.id !== "referenceNumber"
        : !props.enableReference
        ? item.id !== "referenceNumber"
        : props.ticketCompleted && props.enableReference
        ? item.id !== "dataAction"
        : item
    )
    .filter((item) => !item.hide);

  const handleClose = () => {
    setEdit(false);
    setOpen(false);
    setCreateData(createInitValues);
    // setTransferAmount("");
    // setOriginAccount("");
    // setReferenceNumber("");
  };

  const handleChangeData = (field, event, value) => {
    let data;
    switch (field) {
      case "transferAmount":
        data = event.value;
        break;
      case "isAdministrative":
        data = value;
        break;
      case "originAccount":
        data = value;
        break;
      case "expirationDate":
        data = event;
        break;
      default:
        data = event.target.value;
        break;
    }
    setCreateData({ ...createData, [field]: data });
  };

  return (
    <Grid container style={{ margin: ".5rem 0" }}>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        fullWidth
        maxWidth={props.paymentTicket ? "sm" : "sm"}
      >
        <DialogTitle id="responsive-dialog-title">Cheque</DialogTitle>
        <DialogContent>
          {/* <FormControl
            fullWidth
            required
            error={errorFields.originAccount ? true : false}
            disabled={props.enableReference}
          >
            <InputLabel>{translations.ORIGIN_ACCOUNT}</InputLabel>
            <Select
              variant="outlined"
              onChange={(event) => {
                setOriginAccount(event.target.value);
              }}
              value={originAccount}
              inputProps={{
                id: "originAccount",
                name: "originAccount",
              }}
              fullWidth
              label="Cuenta de origen"
            >
              {props.originAccounts.map((opt, indx) => {
                return (
                  <MenuItem key={indx} value={opt.value}>
                    {`${opt.label} - ${opt.value}`}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              {errorFields.originAccount ? "Seleccione una cuenta" : null}
            </FormHelperText>
          </FormControl> */}
          {!props.paymentTicket && (
            <AutocompleteField
              xs={12}
              containerClass="field-container"
              fieldClass="fields"
              typeVariant="subtitle1"
              typeClass="field-label"
              fieldLabel="Cuenta *"
              fieldID="check-add-account"
              fieldInnerLabel="Seleccione..."
              fieldVariant="outlined"
              value={createData.originAccount || null}
              handleChange={handleChangeData.bind(this, "originAccount")}
              options={props.originAccounts}
              getOptionLabel={(option) => `${option.label} - ${option.value}`}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              multiple={false}
              disabled={props.ticketCompleted}
              error={errorFields.originAccount}
            />
          )}
          {props.paymentTicket && (
            <>
              <TextFieldComponent
                xs={12}
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Nombre"
                fieldID="request-recepcion-creditor"
                fieldClass="fields"
                fieldVariant="outlined"
                placeholder="Ej: Manuel Perez"
                margin="dense"
                fullWidth
                value={createData.name || ""}
                onChange={handleChangeData.bind(this, "name")}
                disabled={props.ticketCompleted || props.enableReference}
                error={errorFields.name}
              />
              <TextFieldComponent
                xs={12}
                containerClass="field-container"
                fieldClass="fields"
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel={translations.PARTICIPANT}
                fieldID="client"
                fieldVariant="outlined"
                margin="dense"
                fullWidth
                value={props.clientData}
                disabled={true}
              />
              <CheckboxField
                xs={12}
                alignItems="flex-end"
                check={Boolean(createData.isAdministrative)}
                handleChange={handleChangeData.bind(this, "isAdministrative")}
                color="primary"
                textColor="#43A047"
                label="Es administrativo"
                style={{ margin: ".5rem 0" }}
                disabled={props.ticketCompleted || props.enableReference}
                error={errorFields.isAdministrative}
              />
            </>
          )}
          <NumericTextField
            xs={12}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Monto"
            placeholder="Ej: $50,0000.103"
            fieldID="check-add-amount"
            fieldClass="fields"
            fieldVariant="outlined"
            margin="dense"
            fullWidth
            prefix="$"
            thousandSeparator={true}
            decimalScale={3}
            fixedDecimalScale={true}
            value={createData.transferAmount || ""}
            onChange={handleChangeData.bind(this, "transferAmount")}
            disabled={props.ticketCompleted}
            error={errorFields.transferAmount}
          />
          {props.paymentTicket && (
            <>
              <DateField
                xs={12}
                containerClass="field-container pd-y-2"
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Fecha"
                dateFieldID="new-ticket-date"
                fullWidth={true}
                dateFieldClass="date-field"
                inputVariant="outlined"
                dateFieldLabel="Fecha"
                format="dd/MM/yyyy"
                value={createData.expirationDate || null}
                handleChange={handleChangeData.bind(this, "expirationDate")}
                disabled={props.ticketCompleted || props.enableReference}
                error={errorFields.expirationDate}
              />
              <RadioGroupComponent
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Seleccione una opción"
                fieldID="radio-field-bot-init-option"
                fieldClass="fields"
                row={true}
                value={createData.action || "deposit"}
                options={[
                  { text: "Depósito", value: "deposit" },
                  { text: "Cambio directo", value: "direct_exchange" },
                ]}
                onChange={handleChangeData.bind(this, "action")}
                disabled={props.ticketCompleted || props.enableReference}
                error={errorFields.action}
              />
            </>
          )}
          {/* <TextField
            label={translations.TRANSFER_AMOUNT}
            value={transferAmount}
            required
            fullWidth
            variant="outlined"
            helperText={
              errorFields.transferAmount ? "Introduzca un monto " : null
            }
            error={errorFields.transferAmount}
            disabled={props.enableReference}
            onChange={(event, maskedValue, floatValue) => {
              setTransferAmount(floatValue || 0);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputComponent: MoneyInput }}
          /> */}

          <TextFieldComponent
            xs={12}
            containerClass="field-container"
            fieldClass="fields"
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel={translations.REFERENCE_NUMBER}
            fieldID="check-reference-number"
            placeholder="Referencia del cheque"
            fieldVariant="outlined"
            margin="dense"
            fullWidth
            value={createData.referenceNumber || ""}
            onChange={handleChangeData.bind(this, "referenceNumber")}
            disabled={!props.enableReference}
            error={errorFields.referenceNumber}
          />
          {/* <TextField
            label={translations.REFERENCE_NUMBER}
            value={referenceNumber || ''}
            fullWidth
            variant="outlined"
            helperText={errorFields.referenceNumber ? "Digite la refencia" : null}
            error={errorFields.referenceNumber}
            disabled={!props.enableReference}
            onChange={(event) => {
              setReferenceNumber(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()}>
            Cancelar
          </Button>

          <Button onClick={() => (edit ? editRow() : add())} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <GeneralTable
        columns={checkColumns}
        data={props.checks}
        noPagination={true}
        height={"350px"}
        toolbar={
          <ResponsibleTableToolbar
            readOnly={props.readOnly}
            title={translations.CHECK}
            setOpen={setOpen}
            handleDelete={props.handleDelete}
            handleAdd={props.handleAdd}
            handleEdit={props.handleEdit}
          />
        }
      />
    </Grid>
  );
}

function BankTransfers(props) {
  const requiredFields = [
    "bankAccount",
    "transferAmount",
    // "status",
  ];
  if (props.paymentTicket) {
    requiredFields.push("destinyAccounts");
  }
  const defaultFieldsValue = {
    transferAmount: "",
    bankAccount: "",
    reference: "",
    destinyAccounts: "",
    // status: "hold",
    id: 0,
  };
  const [fields, setField] = useState(defaultFieldsValue);
  const [open, setOpen] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);
  const [errorFields, setErrorFields] = useState({});
  const [edit, setEdit] = useState(false);

  const validateRequiredFields = (type, fieldToCheck, value) => {
    // validate fields errors
    let newErrorState = { ...errorFields };
    const fieldCheck = (key, value) => {
      newErrorState[key] =
        value === null || value === undefined || value === 0 || value
          ? value.toString() === ""
          : true || value
          ? Object.keys(value).length === 0
          : true
          ? true
          : false;
    };
    if (type === "all") {
      requiredFields.forEach((key) => {
        fieldCheck(key, fields[key]);
      });
    } else if (type === "single" && requiredFields.includes(fieldToCheck)) {
      fieldCheck(fieldToCheck, value);
    }
    const hasErr = Object.keys(newErrorState).find(
      (item) => newErrorState[item]
    );
    setErrorFields({ ...newErrorState });
    return Boolean(hasErr);
  };

  const add = () => {
    if (validateRequiredFields("all")) {
      return;
    }
    let account = props.clientAccounts.find(
      (x) => x.number === fields.bankAccount
    );
    // let originAccount = props.originAccounts.find(
    //   (x) => x.number === fields.destinyAccounts
    // );
    props.handleAdd(
      {
        ...fields,
        accountType: account.accountType,
        bank: account.bank,
        bankName: account.bankName,
        // originAccount: originAccount.bank,
        // id: props.bankTransfers.length + 1,
        id: makeid(6),
      },
      "bankTransfers"
    );
    setOpen(false);
    clearAllFields();
  };

  const clearAllFields = () => {
    setField({ ...defaultFieldsValue });
    setErrorFields({ ...{} });
  };

  const columns = [
    { id: "bankAccount", label: "CUENTA ORIGEN", minWidth: 180 },
    {
      id: "accountType",
      label: "TIPO CUENTA",
      minWidth: 180,
      element: (row) => {
        const bank = props.clientAccounts.find(ba => ba.number === row.bankAccount);
        const bankType = bank ? bankAccountTypes.find(bt => bt.value === bank.accountType) : {};
        return bankType?.label?.toUpperCase() || "---";
      }
    },
    {
      id: "bankName",
      label: "BANCO",
      minWidth: 180,
      element: (row) => {
        const bank = props.clientAccounts.find(ba => ba.bank === row.bank);
        return bank?.bankName || "---";
      }
    },
    {
      id: "destinyAccounts",
      label: "CUENTA DESTINO",
      hide: !props.paymentTicket,
      minWidth: 180,
    },
    { id: "reference", label: "REFERENCIA", minWidth: 180 },
    {
      id: "transferAmount",
      label: "MONTO",
      minWidth: 150,
      element: (row) => amount(row, "transferAmount", "", props.readOnly),
    },
    // {
    //   id: "status",
    //   label: "Estado",
    //   minWidth: 180,
    //   element: (row) => translateStatus(row, "status"),
    // },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      minWidth: 100,
      element: (row) =>
        actions(row, deleteRow, openEdit, props.enableReference),
    },
  ]
    .filter((item) =>
      props.readOnly && !props.enableReference
        ? item.id !== "action" && item.id !== "reference"
        : !props.enableReference
        ? item.id !== "reference"
        : props.ticketCompleted && props.enableReference
        ? item.id !== "action"
        : item
    )
    .filter((item) => !item.hide);
  // .filter((item) =>
  //     !props.enableReference ? item.id !== "reference" : props.readOnly ? item.id !== "action" : item
  //   )
  //   .filter((item) => !item.hide);

  const handleClose = () => {
    setEdit(false);
    setOpen(false);
    clearAllFields();
  };

  const editRow = () => {
    if (validateRequiredFields("all")) {
      return;
    }

    let newArr = [...props.bankTransfers];
    let recordIndex = props.bankTransfers.findIndex((x) => x.id === currIndex);
    const bankTransfer = props.bankTransfers.find((x) => x.id === currIndex);
    newArr[recordIndex] = {
      ...bankTransfer,
      transferAmount: fields.transferAmount,
      bankAccount: fields.bankAccount,
      destinyAccounts: fields.destinyAccounts || [],
      reference: fields.reference,
      // status: fields.status,
    };

    props.handleEdit(newArr, "bankTransfers");

    setEdit(false);

    clearAllFields();
    setOpen(false);
  };

  const openEdit = (row) => {
    setOpen(true);
    let editData = {};
    Object.keys(row).forEach((item) => {
      editData[item] = row[item];
    });
    setEdit(true);
    setCurrIndex(row.id);
    setField({ ...editData });
  };

  const deleteRow = (row) => {
    let recordIndex = props.bankTransfers.findIndex((x) => x.id === row.id);
    props.handleDelete(recordIndex, "bankTransfers");
  };

  const handleChange = (field, newVal) => {
    setField((prevState) => ({ ...prevState, [field]: newVal }));
  };
  return (
    <Grid container style={{ margin: ".5rem 0" }}>
      <Dialog open={open} onClose={() => handleClose()}>
        <DialogTitle id="responsive-dialog-title">
          Transferencia bancaria
        </DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            required
            error={errorFields.bankAccount}
            // disabled={props.enableReference}
          >
            <InputLabel>
              {props.paymentTicket
                ? translations.ORIGIN_ACCOUNT
                : translations.DESTINATION_ACCOUNT}
            </InputLabel>
            <Select
              onChange={(event) => {
                handleChange("bankAccount", event.target.value);
              }}
              value={fields.bankAccount}
              inputProps={{
                id: "bankAccount",
                name: "bankAccount",
              }}
              fullWidth
            >
              {props.destinyAccounts.map((opt, indx) => {
                return (
                  <MenuItem key={indx} value={opt.value}>
                    {`${opt.name} - ${opt.value}`}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              {errorFields.bankAccount ? "Seleccione una cuenta" : null}
            </FormHelperText>
          </FormControl>
          {props.paymentTicket && (
            <FormControl
              fullWidth
              required
              error={errorFields.destinyAccounts}
              // disabled={props.enableReference}
            >
              <InputLabel>{translations.DESTINATION_ACCOUNT}</InputLabel>
              <Select
                onChange={(event) => {
                  handleChange("destinyAccounts", event.target.value);
                }}
                value={fields.destinyAccounts}
                inputProps={{
                  id: "destinyAccounts",
                  name: "destinyAccounts",
                }}
                fullWidth
              >
                {props.originAccounts.map((opt, indx) => {
                  return (
                    <MenuItem key={indx} value={opt.value}>
                      {`${opt.label} - ${opt.value}`}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                {errorFields.destinyAccounts ? "Seleccione una cuenta" : null}
              </FormHelperText>
            </FormControl>
          )}
          &nbsp;
          <TextField
            label={translations.TRANSFER_AMOUNT}
            value={fields.transferAmount}
            required
            fullWidth
            helperText={errorFields.transferAmount ? "Digite un monto" : null}
            error={errorFields.transferAmount}
            // disabled={props.enableReference}
            onChange={(event, maskedValue, floatValue) => {
              handleChange("transferAmount", floatValue || 0);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputComponent: MoneyInput }}
          />
          &nbsp;
          <TextField
            label={translations.REFERENCE_NUMBER}
            value={fields.reference}
            fullWidth
            // helperText={errorFields.reference ? "Digite la refencia" : null}
            // error={errorFields.reference}
            disabled={!props.enableReference}
            onChange={(event) => {
              handleChange("reference", event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          &nbsp;
          {/* <FormControl fullWidth required error={errorFields.status}>
            <InputLabel>{translations.STATUS}</InputLabel>
            <Select
              onChange={(event) => {
                handleChange("status", event.target.value);
              }}
              value={fields.status}
              inputProps={{
                id: "status",
                name: "status",
              }}
              fullWidth
            >
              <MenuItem value="hold">En espera</MenuItem>
              <MenuItem value="on_process">En proceso</MenuItem>
              <MenuItem value="complete">Completado</MenuItem>
            </Select>
            <FormHelperText>
              {errorFields.status ? "Seleccione un estado" : null}
            </FormHelperText>
          </FormControl> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()}>
            Cancelar
          </Button>

          <Button onClick={() => (edit ? editRow() : add())} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <GeneralTable
        columns={columns}
        data={props.bankTransfers}
        noPagination={true}
        height={"350px"}
        toolbar={
          <ResponsibleTableToolbar
            readOnly={props.readOnly}
            title={translations.BANK_TRANSFER}
            setOpen={setOpen}
            handleDelete={props.handleDelete}
            handleAdd={props.handleAdd}
            handleEdit={props.handleEdit}
          />
        }
      />
    </Grid>
  );
}
function Cash(props) {
  const requiredFields = ["transferAmount"];
  const defaultFieldsValue = {
    transferAmount: "",
    reference: "",
    id: 0,
  };
  const [fields, setField] = useState(defaultFieldsValue);
  const [open, setOpen] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);
  const [errorFields, setErrorFields] = useState({});
  const [edit, setEdit] = useState(false);

  const validateRequiredFields = (type, fieldToCheck, value) => {
    // validate fields errors
    let newErrorState = { ...errorFields };
    const fieldCheck = (key, value) => {
      newErrorState[key] =
        value === null || value === undefined || value === 0 || value
          ? value.toString() === ""
          : true || value
          ? Object.keys(value).length === 0
          : true
          ? true
          : false;
    };
    if (type === "all") {
      requiredFields.forEach((key) => {
        fieldCheck(key, fields[key]);
      });
    } else if (type === "single" && requiredFields.includes(fieldToCheck)) {
      fieldCheck(fieldToCheck, value);
    }
    const hasErr = Object.keys(newErrorState).find(
      (item) => newErrorState[item]
    );
    setErrorFields({ ...newErrorState });
    return Boolean(hasErr);
  };

  const add = () => {
    if (validateRequiredFields("all")) {
      return;
    }
    props.handleAdd(
      {
        ...fields,
        id: makeid(6),
      },
      "cash"
    );
    setOpen(false);
    clearAllFields();
  };

  const clearAllFields = () => {
    setField({ ...defaultFieldsValue });
    setErrorFields({ ...{} });
  };

  const columns = [
    {
      id: "transferAmount",
      label: "MONTO",
      minWidth: 150,
      element: (row) => amount(row, "transferAmount", "", props.readOnly),
    },
    { id: "reference", label: "REFERENCIA", minWidth: 180 },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      minWidth: 100,
      element: (row) =>
        actions(row, deleteRow, openEdit, props.enableReference),
    },
  ]
    .filter((item) =>
      props.readOnly && !props.enableReference
        ? item.id !== "action" && item.id !== "reference"
        : !props.enableReference
        ? item.id !== "reference"
        : props.ticketCompleted && props.enableReference
        ? item.id !== "action"
        : item
    )
    .filter((item) => !item.hide);

  const handleClose = () => {
    setEdit(false);
    setOpen(false);
    clearAllFields();
  };

  const editRow = () => {
    if (validateRequiredFields("all")) {
      return;
    }
    let newArr = [...props.cash];
    let recordIndex = props.cash.findIndex((x) => x.id === currIndex);
    const currCash = props.cash.find((x) => x.id === currIndex);
    newArr[recordIndex] = {
      ...currCash,
      transferAmount: fields.transferAmount,
      reference: fields.reference,
    };
    props.handleEdit(newArr, "cash");
    setEdit(false);
    clearAllFields();
    setOpen(false);
  };

  const openEdit = (row) => {
    setOpen(true);
    let editData = {};
    Object.keys(row).forEach((item) => {
      editData[item] = row[item];
    });
    setEdit(true);
    setCurrIndex(row.id);
    setField({ ...editData });
  };

  const deleteRow = (row) => {
    let recordIndex = props.cash.findIndex((x) => x.id === row.id);
    props.handleDelete(recordIndex, "cash");
  };

  const handleChange = (field, newVal) => {
    setField((prevState) => ({ ...prevState, [field]: newVal }));
  };
  return (
    <Grid container style={{ margin: ".5rem 0" }}>
      <Dialog open={open} onClose={() => handleClose()}>
        <DialogTitle id="responsive-dialog-title">Efectivo</DialogTitle>
        <DialogContent>
          <TextField
            label={translations.TRANSFER_AMOUNT}
            value={fields.transferAmount}
            required
            fullWidth
            helperText={errorFields.transferAmount ? "Digite un monto" : null}
            error={errorFields.transferAmount}
            // disabled={props.enableReference}
            onChange={(event, maskedValue, floatValue) => {
              handleChange("transferAmount", floatValue || 0);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputComponent: MoneyInput }}
          />
          &nbsp;
          <TextField
            label={translations.REFERENCE_NUMBER}
            value={fields.reference}
            fullWidth
            // helperText={errorFields.reference ? "Digite la refencia" : null}
            // error={errorFields.reference}
            disabled={!props.enableReference}
            onChange={(event) => {
              handleChange("reference", event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          &nbsp;
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()}>
            Cancelar
          </Button>

          <Button onClick={() => (edit ? editRow() : add())} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <GeneralTable
        columns={columns}
        data={props.cash}
        noPagination={true}
        height={"350px"}
        toolbar={
          <ResponsibleTableToolbar
            readOnly={props.readOnly}
            title={translations.CASH}
            setOpen={setOpen}
            handleDelete={props.handleDelete}
            handleAdd={props.handleAdd}
            handleEdit={props.handleEdit}
          />
        }
      />
    </Grid>
  );
}

function SavingsAccounts(props) {
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);

  const deleteRow = (row) => {
    let recordIndex = props.currSavingsAccounts.findIndex(
      (x) => x.numeroCuenta === row.numeroCuenta
    );
    props.handleDelete(recordIndex, "savingsAccounts");
  };
  const handleTransferAmountChange = (newVal, currRow) => {
    let newArr = [...props.currSavingsAccounts];
    let recordIndex = props.currSavingsAccounts.findIndex(
      (x) => x.numeroCuenta === currRow.numeroCuenta
    );
    const savingAccount = props.currSavingsAccounts.find(
      (x) => x.numeroCuenta === currRow.numeroCuenta
    );
    newArr[recordIndex] = {
      ...savingAccount,
      transferAmount: newVal.floatValue,
    };
    props.handleEdit(newArr, "savingsAccounts");
  };
  const amountToCharge = (row) => {
    return (
      <div style={{ marginTop: 10 }}>
        <NumericTextField
          xs={12}
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldID={"text-field-amount-charge-account"}
          fieldClass="fields"
          placeholder="Monto total a cobrar"
          fieldVariant="outlined"
          prefix="$"
          margin="dense"
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          value={row.transferAmount || ""}
          onChange={(e) => handleTransferAmountChange(e, row)}
          disabled={props.readOnly}
        />
      </div>
    );
  };

  const add = () => {
    let newArr = [];
    for (let i of selected) {
      let found = props.currSavingsAccounts.find((x) => x.numeroCuenta === i);
      if (found) {
        newArr.push(found);
      } else {
        let savingAccount = props.savingsAccounts.find((x) => x.id === i);
        newArr.push({
          numeroCuenta: savingAccount.id,
          balanceActual: savingAccount.balance_actual,
          tipoCuenta: savingAccount.tipo_cuenta,
          transferAmount: 0,
        });
      }
    }
    props.handleReplace(newArr, "savingsAccounts");

    setOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "CUENTA",
      width: 180,
    },
    {
      field: "balance_actual",
      headerName: "BALANCE",
      type: "number",
      width: 180,
    },
    { field: "tipo_cuenta", headerName: "TIPO DE CUENTA", width: 200 },
  ];
  const sColumns = [
    {
      id: "numeroCuenta",
      label: "CUENTA",
      width: 180,
    },
    {
      id: "balanceActual",
      label: "BALANCE",
      width: 180,
    },
    { id: "tipoCuenta", label: "TIPO DE CUENTA", width: 200 },
    {
      id: "transferAmount",
      label: "MONTO",
      width: 150,
      element: (row) => amountToCharge(row),
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      minWidth: 100,
      element: (row) => actions(row, deleteRow),
    },
  ].filter((item) =>
    props.readOnly || props.ticketCompleted ? item.id !== "action" : item
  );

  useEffect(() => {
    if (open === true) {
      getSelected();
    }
  }, [open]);

  const getSelected = () => {
    const data = [];
    for (let i of props.currSavingsAccounts) {
      let savingsAccount = props.savingsAccounts.find(
        (x) => x.id === i.numeroCuenta
      );
      data.push(savingsAccount.id);
    }
    setSelected(data);
  };

  return (
    <Grid container style={{ margin: ".5rem 0" }}>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="responsive-dialog-title">Cuenta de ahorro</DialogTitle>
        <DialogContent style={{ height: 350 }}>
          <DataGrid
            loading={props.savingsAccounts === 0}
            rows={props.savingsAccounts}
            columns={columns}
            checkboxSelection={true}
            hideFooter={true}
            pageSize={10}
            selectionModel={selected}
            onSelectionModelChange={(e) => setSelected(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cancelar
          </Button>

          <Button onClick={() => add()} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container direction="row">
        <Grid item xs={12}>
          <GeneralTable
            columns={sColumns}
            data={props.currSavingsAccounts}
            noPagination={true}
            height={"350px"}
            toolbar={
              <ResponsibleTableToolbar
                readOnly={props.readOnly}
                title="Cuentas de ahorro"
                setOpen={setOpen}
                handleDelete={props.handleDelete}
                handleAdd={props.handleAdd}
                handleEdit={props.handleEdit}
              />
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function FinancialCertificates(props) {
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);

  const deleteRow = (row) => {
    let recordIndex = props.currFinancialCertificates.findIndex(
      (x) => x.numeroCuenta === row.numeroCuenta
    );
    props.handleDelete(recordIndex, "financialCertificates");
  };
  const handleTransferAmountChange = (newVal, currRow, type) => {
    let newArr = [...props.currFinancialCertificates];
    let recordIndex = props.currFinancialCertificates.findIndex(
      (x) => x.numeroCuenta === currRow.numeroCuenta
    );
    const savingAccount = props.currFinancialCertificates.find(
      (x) => x.numeroCuenta === currRow.numeroCuenta
    );
    let value = "";
    switch (type) {
      case "referenceNumber":
        value = newVal;
        break;
      case "transferAmount":
        value = newVal.floatValue;
        break;
      default:
        break;
    }
    newArr[recordIndex] = {
      ...savingAccount,
      // transferAmount: newVal.floatValue,
      [type]: value,
    };
    props.handleEdit(newArr, "financialCertificates");
  };

  const date = (row, key) => {
    return <span>{row[key].split("T")[0]}</span>;
  };
  const amountToCharge = (row) => {
    return (
      <div style={{ marginTop: 10 }}>
        <NumericTextField
          xs={12}
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldID={"text-field-amount-charge-account"}
          fieldClass="fields"
          placeholder="Monto a transferir"
          fieldVariant="outlined"
          prefix="$"
          margin="dense"
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          value={row.transferAmount || ""}
          onChange={(e) => handleTransferAmountChange(e, row, "transferAmount")}
          disabled={props.readOnly}
        />
      </div>
    );
  };
  const referenceField = (row) => {
    return (
      <div style={{ marginTop: 10 }}>
        <TextFieldComponent
          xs={12}
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldID={"text-field-reference-field"}
          fieldClass="fields"
          placeholder="Ref...."
          fieldVariant="outlined"
          margin="dense"
          value={row.referenceNumber || ""}
          fullWidth
          // helperText={errorFiel\ds.referenceNumber ? "Digite la refencia" : null}
          // error={errorFields.referenceNumber}
          // disabled={!props.enableReference}
          // disabled={props.readOnly}
          onChange={(e) =>
            handleTransferAmountChange(e.target.value, row, "referenceNumber")
          }
        />
      </div>
    );
  };

  const add = () => {
    let newArr = [];
    for (let i of selected) {
      let found = props.currFinancialCertificates.find(
        (x) => x.numeroCuenta === i
      );
      if (found) {
        newArr.push(found);
      } else {
        let financialCertificate = props.financialCertificates.find(
          (x) => x.id === i
        );
        newArr.push({
          numeroCuenta: financialCertificate.id,
          balanceActual: financialCertificate.balance_actual,
          fechaApertura: financialCertificate.fecha_apertura,
          tipoCuenta: financialCertificate.tipo_cuenta,
          referenceNumber: "",
          transferAmount: 0,
        });
      }
    }
    props.handleReplace(newArr, "financialCertificates");
    setOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "CUENTA",
      width: 180,
    },
    {
      field: "balance_actual",
      headerName: "BALANCE",
      type: "number",
      width: 180,
    },
    { field: "tipo_cuenta", headerName: "TIPO DE CUENTA", width: 200 },
    {
      field: "fecha_apertura",
      headerName: "FECHA APERTURA",
      width: 200,
      renderCell: (cellValues) => {
        return <span>{cellValues.value.split("T")[0]}</span>;
      },
    },
  ];
  const sColumns = [
    {
      id: "numeroCuenta",
      label: "CUENTA",
      width: 180,
    },
    {
      id: "balanceActual",
      label: "BALANCE",
      width: 180,
      element: (row) => amount(row, "balanceActual", "", props.readOnly),
    },
    { id: "tipoCuenta", label: "TIPO DE CUENTA", width: 200 },
    {
      id: "fechaApertura",
      label: "FECHA APERTURA",
      width: 200,
      element: (row) => date(row, "fechaApertura"),
    },
    {
      id: "transferAmount",
      label: "MONTO",
      width: 150,
      element: (row) =>
        props.enableReference
          ? amount(row, "transferAmount", "", props.readOnly)
          : amountToCharge(row),
    },
    {
      id: "referenceNumber",
      label: "REFERENCIA",
      width: 150,
      element: (row) =>
        props.ticketCompleted
          ? simpleRow(row, "referenceNumber")
          : referenceField(row),
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      minWidth: 100,
      element: (row) => actions(row, deleteRow),
    },
  ].filter((item) =>
    props.readOnly && !props.enableReference
      ? item.id !== "action" && item.id !== "referenceNumber"
      : props.enableReference
      ? item.id !== "action"
      : item.id !== "referenceNumber"
  );

  useEffect(() => {
    if (open === true) {
      getSelected();
    }
  }, [open]);

  const getSelected = () => {
    const data = [];
    for (let i of props.currFinancialCertificates) {
      let financialCertificate = props.financialCertificates.find(
        (x) => x.id === i.numeroCuenta
      );
      data.push(financialCertificate.id);
    }
    setSelected(data);
  };

  return (
    <Grid container style={{ margin: ".5rem 0" }}>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="responsive-dialog-title">
          Certificados financieros
        </DialogTitle>
        <DialogContent style={{ height: 350 }}>
          <DataGrid
            loading={props.financialCertificates === 0}
            rows={props.financialCertificates}
            columns={columns}
            checkboxSelection={true}
            hideFooter={true}
            pageSize={10}
            selectionModel={selected}
            onSelectionModelChange={(e) => setSelected(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cancelar
          </Button>

          <Button onClick={() => add()} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container direction="row">
        <Grid item xs={12}>
          <GeneralTable
            columns={sColumns}
            data={props.currFinancialCertificates}
            noPagination={true}
            height={"350px"}
            toolbar={
              <ResponsibleTableToolbar
                readOnly={props.readOnly}
                title="Certificados financieros"
                setOpen={setOpen}
                handleDelete={props.handleDelete}
                handleAdd={props.handleAdd}
                handleEdit={props.handleEdit}
              />
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function Expirations(props) {
  // const openEdit = () => {
  //   setOpen(!open);
  // }

  const deleteRow = (row) => {
    let recordIndex = props.expiration.table.findIndex((x) => x.id_vencimiento === row.id_vencimiento);
    props.handleDelete(recordIndex, "expiration");
  };

  const [open, setOpen] = useState(false);
  const sColumns = [
    {
      id: "idDbsRohi" || "id_dbs_rohi",
      label: "ID DESEMBOLSO ROHI",
      width: 180,
      element: (row) => simpleRow(row, "idDbsRohi", "id_dbs_rohi"),
    },
    {
      id: "idVencimiento" || "id_vencimiento",
      label: "ID VENCIMIENTO",
      width: 180,
      element: (row) => simpleRow(row, "id_vencimiento", "idVencimiento"),
    },
    {
      id: "montoPagar" || "monto_pagar",
      label: "MONTO A PAGAR",
      width: 200,
      element: (row) =>
        amount(row, "monto_pagar", "montoPagar", props.readOnly),
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      minWidth: 100,
      element: (row) => actions(row, deleteRow, undefined, undefined),
    },
  ].filter((item) =>
    props.readOnly || props.ticketCompleted ? item.id !== "action" : item
  );

  return (
    <Grid container style={{ margin: ".5rem 0" }}>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogHeader handleClick={() => setOpen(false)} />

        <DialogContent>
          <PaymentCalculator
            data={props.expiration}
            setCalculatorInfo={props.setExpiration}
            clientData={props.clientData}
            setDialogClose={setOpen}
            filters={{
              rohiId: props.rohiId,
              uniqueId: props.uniqueId,
              paymentType: "conventional",
            }}
            disabled={true}
            fromTicket={props.fromTicket}
          />
        </DialogContent>
      </Dialog>

      <Grid container={true} direction="row" alignItems="center">
        <Grid item xs={12}>
          <GeneralTable
            columns={sColumns}
            data={props.expiration.table || []}
            noPagination={false}
            height={"350px"}
            toolbar={
              <ResponsibleTableToolbar
                readOnly={props.readOnly}
                title="Vencimientos"
                setOpen={setOpen}
                handleEdit={props.handleEdit}
              />
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function Outlays(props) {
  const deleteRow = (row) => {
    let recordIndex = props.outlay.table.findIndex((x) => x.id_dbs_rohi === row.id_dbs_rohi);
    props.handleDelete(recordIndex, "outlay");
  };

  const [open, setOpen] = useState(false);
  const sColumns = [
    {
      id: "idDbsRohi" || "id_dbs_rohi",
      label: "ID DESEMBOLSO ROHI",
      width: 180,
      element: (row) => simpleRow(row, "idDbsRohi", "id_dbs_rohi"),
    },

    {
      id: "totalPagar",
      label: "TOTAL A PAGAR",
      width: 200,
      element: (row) =>
        amount(row, "totalPagar", "total_pagar", props.readOnly),
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      minWidth: 100,
      element: (row) => actions(row, deleteRow, undefined, undefined),
    },
  ].filter((item) =>
    props.readOnly || props.ticketCompleted ? item.id !== "action" : item
  );

  return (
    <Grid container style={{ margin: ".5rem 0" }}>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogHeader handleClick={() => setOpen(false)} />
        <DialogContent>
          <PaymentCalculator
            data={props.outlay}
            setCalculatorInfo={props.setOutlay}
            clientData={props.clientData}
            setDialogClose={setOpen}
            filters={{
              rohiId: props.rohiId,
              uniqueId: props.uniqueId,
              paymentType: "extraordinary",
            }}
            disabled={true}
            fromTicket={props.fromTicket}
          />
        </DialogContent>
      </Dialog>

      <Grid container={true} direction="row" alignItems="center">
        <Grid item xs={12}>
          <GeneralTable
            columns={sColumns}
            data={props.outlay.table || []}
            noPagination={false}
            height={"350px"}
            toolbar={
              <ResponsibleTableToolbar
                readOnly={props.readOnly}
                title="Desembolsos"
                setOpen={setOpen}
                handleEdit={props.handleEdit}
              />
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
export {
  Checks,
  BankTransfers,
  SavingsAccounts,
  Expirations,
  FinancialCertificates,
  Outlays,
  Cash,
};
