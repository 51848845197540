import { AxiosError } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';
import { catchError, translations } from 'src/utils';

import { Button } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import {
    fetchAcquaintanceshipCompanyContracts, fetchAcquaintanceshipPersonContracts,
    fetchAddendumContracts, fetchDebitAuthorizationContracts, fetchLoanContracts,
    fetchNotarialPayContracts
} from '../../utils/axios';

const styles:any = createStyles({
  icon: {
    color: green[500],
    fontSize: 35,
  },
  iconButton: {
    borderRadius: '75%',
  },
  label: {
    color: '#0000008a',
    position: 'relative',
  },
  root: {
    marginBottom: 5,
    marginTop: 5
  }
});

interface IParticipantResumeProps {
  classes: {
    icon: string;
    iconButton: string;
    label: string;
    root: string;
  };
  id: string;
  user: IUser;
}

interface IParticipantResumeState {

  ACF: IAcquaintanceshipCompanyContract[];
  ADD: IAddendumContract[];
  APF: IAcquaintanceshipPersonContract[];
  DA: IDebitAuthorizationContract[];
  LC: ILoanContract[];
  NP: INotarialPayContract[];
  alertDialog: boolean;
  amount: number;
  dialogTitle: string;
  errorMessage: string;
  formDialog: boolean;
  loading: boolean;
  open: boolean;
}

class FileAttachmentSelectInput extends React.Component<IParticipantResumeProps, IParticipantResumeState> {
  public state: IParticipantResumeState = {
    ACF: [],
    ADD: [],
    APF: [],
    DA: [],
    LC: [],
    NP: [],
    alertDialog: false,
    amount: 0,
    dialogTitle: '',
    errorMessage: '',
    formDialog: false,
    loading: false,
    open: false,
  };

  constructor(props: IParticipantResumeProps) {
    super(props);

    this.switchDialogHandler = this.switchDialogHandler.bind(this);
    this.getDocuments = this.getDocuments.bind(this);
    this.loanContracts = this.loanContracts.bind(this);
    this.acfContracts = this.acfContracts.bind(this);
    this.apfContracts = this.apfContracts.bind(this);
    this.addendumContract = this.addendumContract.bind(this);
    this.debitAuthorizationContract = this.debitAuthorizationContract.bind(this);
    this.notarialPayContract = this.notarialPayContract.bind(this);
  }

  public switchDialogHandler() {
    // Cambia el estado del Dialog a True para desplegarlo o a False para ocultarlo
    this.setState({ open: !this.state.open })

    // Tiempo de espera(0.5 seg) para cargar los contratos, esto solo se ejecuta si el dialog se activa
    setTimeout(a => {
      if (this.state.open === true) {
        this.getDocuments();
      }
    }, 500)
  }



  public getDocuments() {
    // Metodo para obtener los contractos
    const { user: token } = this.props;
    Promise.all([
      fetchAcquaintanceshipCompanyContracts(token),
      fetchAcquaintanceshipPersonContracts(token),
      fetchAddendumContracts(token),
      fetchDebitAuthorizationContracts(token),
      fetchLoanContracts(token),
      fetchNotarialPayContracts(token)
    ]).then(res => {
      this.setState({
        ACF: res[0].data,
        ADD: res[2].data,
        APF: res[1].data,
        DA: res[3].data,
        LC: res[4].data,
        NP: res[5].data,
      });
    })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  };

  public loanContracts(clientId: string) {
    // Filtra las lineas de credito por deudor seleccionado y despliega el monto de linea de credito descrita 
    const idLoan = this.state.LC.filter(lc => (lc.debtors ? lc.debtors.includes(clientId) : undefined))
    const mostRecentDate = new Date(Math.max.apply(null, idLoan.map(e => {
      return new Date(e.contractDate || '');
    })));
    const curLoan = idLoan.filter(e => {
      const d = new Date(e.contractDate || '');
      return d.getTime() === mostRecentDate.getTime();
    })[0];

    return (
      <TableRow>
        <TableCell>{translations.LOAN_CONTRACT}</TableCell>
        <TableCell> {curLoan ? 'Si' : 'No'}</TableCell>
        <TableCell>{curLoan ? Number(curLoan.creditLineAmount).toLocaleString() : ''} </TableCell>
      </TableRow>

    )
  }
  public debitAuthorizationContract(clientId: string) {
    const debtorLC = this.state.LC.filter(lc => lc.debtors ? lc.debtors.includes(clientId) : null)
    const idLoan = debtorLC.map(lc => lc.uniqueId)
    const idDebitAuth = this.state.DA.filter(da => da.loanContract ? idLoan.includes(da.loanContract) : undefined)
    const mostRecentDate = new Date(Math.max.apply(null, idDebitAuth.map(e => {
      return new Date(e.contractDate || '');
    })));
    const curDebitAuth = idDebitAuth.filter(e => {
      const d = new Date(e.contractDate || '');
      return d.getTime() === mostRecentDate.getTime();
    })[0];

    return (
      <TableRow>
        <TableCell>{translations.DEBIT_AUTHORIZATION}</TableCell>
        <TableCell> {curDebitAuth ? 'Si' : 'No'}</TableCell>
        <TableCell>{curDebitAuth ? curDebitAuth.contractDate : ''}</TableCell>
      </TableRow>

    )
  }

  public notarialPayContract(clientId: string) {

    const debtorLC = this.state.LC.filter(lc => lc.debtors ? lc.debtors.includes(clientId) : null)
    const idLoan = debtorLC.map(lc => lc.uniqueId)
    const idNotarial = this.state.NP.filter(np => np.loanContract ? idLoan.includes(np.loanContract) : undefined)
    const curNotarial = idNotarial.map(f => f.notarialAmount)

    let finalAmount = 0;

    for (const amount of curNotarial) {
      finalAmount = finalAmount + parseFloat(amount + '')
    }
    return (
      <TableRow>
        <TableCell>{translations.NOTARIAL_PAY}</TableCell>
        <TableCell>{finalAmount !== 0 ? finalAmount.toLocaleString() : ''} </TableCell>
        <TableCell>{idNotarial.length !== 0 ? idNotarial.length : ''} </TableCell>
      </TableRow>
    )
  }



  public addendumContract(clientId: string) {
    const debtorLC = this.state.LC.filter(lc => lc.debtors ? lc.debtors.includes(clientId) : null)
    const idLoan = debtorLC.map(lc => lc.uniqueId)
    const idAddendum = this.state.ADD.filter(add => add.loanContract ? idLoan.includes(add.loanContract) : undefined)

    const mostRecentDate = new Date(Math.max.apply(null, idAddendum.map(e => {
      return new Date(e.modificationDate || '');
    })));
    const curAddedum = idAddendum.filter(e => {
      const d = new Date(e.modificationDate || '');
      return d.getTime() === mostRecentDate.getTime();
    })[0];

    return (
      <TableRow>
        <TableCell>{translations.ADDENDUM}</TableCell>
        <TableCell>{curAddedum ? Number(curAddedum.newOutlayAmount).toLocaleString() : ''}</TableCell>
        <TableCell>{curAddedum ? curAddedum.modificationDate : ''}</TableCell>
      </TableRow>

    )
  }

  public apfContracts(clientId: string) {
    const curAPF = this.state.APF.filter(apf => (apf.debtor ? apf.debtor.includes(clientId) : undefined));
    return (
      <TableRow>
        <TableCell>{translations.APF}</TableCell>
        <TableCell>{curAPF.length === 0 ? 'No' : 'Si'} </TableCell>
        <TableCell>{''}</TableCell>
      </TableRow>

    )
  }
  public acfContracts(clientId: string) {
    const curACF = this.state.ACF.filter(acf => (acf.debtor ? acf.debtor.includes(clientId) : undefined));
    return (
      <TableRow>
        <TableCell>{translations.ACF}</TableCell>
        <TableCell>{curACF.length === 0 ? 'No' : 'Si'} </TableCell>
        <TableCell>{''}</TableCell>
      </TableRow>

    )
  }
  public render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <div className={classes.root}>
        <Button className={classes.iconButton} onClick={this.switchDialogHandler}>
          <AssignmentIndIcon className={classes.icon} />
        </Button>
        <Dialog open={open} onClose={this.switchDialogHandler}>
          <DialogActions style={{ justifyContent: 'center' }}>
            <label className={classes.label}>Resumen de Cliente</label>
          </DialogActions>
          <Paper style={{ minWidth: 300 }}>
            <Table>
              <TableBody>
                {this.loanContracts(this.props.id)}
                {this.apfContracts(this.props.id)}
                {this.acfContracts(this.props.id)}
                {this.notarialPayContract(this.props.id)}
                {this.addendumContract(this.props.id)}
                {this.debitAuthorizationContract(this.props.id)}
              </TableBody>
            </Table>
          </Paper>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user
  };
}
export default withStyles(styles)(connect(mapStateToProps)(FileAttachmentSelectInput));

