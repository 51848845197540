import { connect } from "react-redux";
import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import LoadingDialog from "src/components/SharedComponents/LoadingScreen";
import { ToolbarComponent } from "src/components/SharedComponents/Toolbar";
import { SimpleConfirmDialog } from "src/components/SharedComponents/Dialogs";
import Filters from "../Filters";
import { http } from "src/utils/axios";

const useStyles = makeStyles({
  root: {
    padding: "20px 20px 10% 20px",
  },
  content: {
    padding: "1%",
    border: "1px solid #EEEEEE",
    borderTop: "none",
  },
  filters: {
    marginTop: "35px",
    justifyContent: "space-between",
  },
});

function TransactionReports(props) {
  const classes = useStyles();
  const [simpleDialog, setSimpleDialog] = useState("");
  const [loading, setLoading] = useState(false);

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };

  const ValidatedData = (fields) => {
    if (!fields.date_start || !fields.date_end) {
      handleToggleSimpleDialog(
        "simple-dialog",
        "Asegúrese de ingresar un rango de fechas válido"
      );
      return;
    }
    const formattedFields = {
      start_date: fields.date_start,
      end_date: fields.date_end,
      type: fields.type,
      query_name: fields.endpoint
    };

    return formattedFields;
  };

  const ApplyFilters = (fields) => {
    const formattedFields = ValidatedData(fields);
    const url = "/utility/rohi-transaction-types/"

    if (fields.type === "PDF" && url) {
      setLoading(true);
      http
        .post(`${url}`, formattedFields, {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        })
        .then((response) => {
          const file = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const fileURL = URL.createObjectURL(file);
          const NewWindow = window.open();
          NewWindow.location.href = fileURL;
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error al generar PDF:", error);
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
            handleToggleSimpleDialog("simple-dialog", error.response.data);
          } else {
            handleToggleSimpleDialog(
              "simple-dialog",
              "Error al generar el PDF"
            );
          }
          setLoading(false);
        });
    } else if (fields.type === "EXCEL" && url) {
      setLoading(true);
      http
        .post(`${url}`, formattedFields, {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.download = `${fields.title}.xlsx`;

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error al descargar Excel:", error.message);
          if (
            error.response &&
            error.response.data &&
            typeof error.response.data === "string"
          ) {
            handleToggleSimpleDialog("simple-dialog", error.response.data);
          } else {
            handleToggleSimpleDialog(
              "simple-dialog",
              "Error al descargar el excel."
            );
          }
          setLoading(false);
        });
    } else {
      handleToggleSimpleDialog("simple-dialog", "Tipo de documento no válido");
    }
  };

  return (
    <Grid className={classes.root}>
      <LoadingDialog open={loading} />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle="Reporte de transacciones en efectivo"
        btnVariant="contained"
      />
      <Grid className={classes.content}>
        <Filters
          classes={classes.filters}
          endpoint="savings"
          title="Reportes de Ahorro"
          applyFilters={ApplyFilters}
        />
        <div style={{ margin: "25px 0" }}></div>
        <Filters
          classes={classes.filters}
          endpoint="loans"
          title="Reportes de Préstamos"
          applyFilters={ApplyFilters}
        />
        <div style={{ margin: "25px 0" }}></div>
        <Filters
          classes={classes.filters}
          endpoint="certificates"
          title="Reportes de Certificados"
          applyFilters={ApplyFilters}
        />
        <div style={{ margin: "25px 0" }}></div>
      </Grid>

      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(TransactionReports);
