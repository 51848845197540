import * as React from "react";

import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import CheckBox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ReactSelect from "react-select";
import FormControl from "@material-ui/core/FormControl";

import { createComplianceRegistrationForm } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import moneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import idNumberMaskInput from "../../../components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "../../../components/TextMaskInputComponents/passportMaskInput";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";

import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  titleCaseHandler,
  gender,
  IDENTIFICATION_TYPE,
  translations,
  nationalitiesToOptions,
} from "../../../utils";
import {
  createComplianceRegistrationForm as create,
  fetchNationalities,
} from "../../../utils/axios";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
import ExtraPaymentTable from "../../../components/Ticket/ExtraPayTable/ExtraPaymentTable";
import InstitutionSelectInput from "../../Institutions/InstitutionsSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

interface IFormProps {
  classes: {
    attachmentIcon: any;
    checkboxLabel: any;
    controls: any;
    deleteButton: any;
    errorInput: any;
    formControl: any;
    greenButton: any;
    half: any;
    paper: any;
    root: any;
    textField: any;
    title: any;
  };
  user: IUser;
  changeSection(sectionNumber: number): void;
  createComplianceRegistrationForm(
    complianceRegistrationForm: IComplianceRegistrationForm
  ): IAction<IComplianceRegistrationForm[]>;
}
interface IFormState {
  [key: string]:
    | string
    | number
    | boolean
    | string[]
    | IOptionType[]
    | IOptionType
    | undefined;

  countries: IOptionType[];
  editIdx: number;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;

  city: string;
  cityError: string;

  title: string;

  debtor: string[];
  debtorError: string;

  institution: string[];
  institutionError: string;

  personType: string;
  personTypeError: string;

  productType: string;
  productTypeError: string;

  type: string;
  typeError: string;

  adquiredNationality: IOptionType;
  adquiredNationalityError: string;

  economicActivity: string;
  economicActivityError: string;

  transactionType: string;
  transactionTypeError: string;

  pepPosition: string;
  pepPositionError: string;

  dominicanAmount: number;
  dominicanAmountError: string;

  originalAmount: number;
  originalAmountError: string;

  originalAmountCurrency: string;
  originalAmountCurrencyError: string;

  exchangeRate: number;
  exchangeRateError: string;

  transactionDate: string;
  transactionDateError: string;

  originOfFunds: string;
  originOfFundsError: string;

  description: string;
  descriptionError: string;

  reportPriorityDescription: string;
  reportPriorityDescriptionError: string;

  reportPriority: string;
  reportPriorityError: string;

  reportReason: string;
  reportReasonError: string;

  reportDocumentsDescription: string;
  reportDocumentsDescriptionError: string;

  isIntermediaryDifferent: boolean;
  isIntermediaryDifferentError: string;

  isBeneficiaryDifferent: boolean;
  isBeneficiaryDifferentError: string;

  isInternational: boolean;
  isInternationalError: string;

  countryOfOrigin: string;
  countryOfOriginError: string;

  countryOfDestination: IOptionType;
  countryOfDestinationError: string;

  remittance: string;
  remittanceError: string;

  correspondentEntity: string;
  correspondentEntityError: string;

  financialAccounts: any;
  financialAccountsError: string;

  typeOfInstrument: string;
  typeOfInstrumentError: string;

  originalAmountCurrencyDescription: string;
  originalAmountCurrencyDescriptionError: string;
  // intermediary
  iFirstName: string;
  iLastName: string;
  iNationality: IOptionType;
  iGender: string;
  iAdquiredNationality: string;
  iIdType: string;
  iIdNumber: string;
  iReference: string;
  iCity: string;
  iSector: string;
  iState: string;

  // beneficiary

  bFirstName: string;
  bLastName: string;
  bNationality: IOptionType;
  bGender: string;
  bAdquiredNationality: string;
  bIdType: string;
  bReference: string;
  bCity: string;
  bSector: string;
  bState: string;
}

class Form extends React.Component<IFormProps, IFormState> {
  public state: IFormState = {
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    city: "",
    cityError: "",
    countries: [],

    editIdx: -1,

    pepPosition: "",
    pepPositionError: "",
    title: "",
    titleError: "",
    debtor: [],
    debtorError: "",
    institution: [],
    institutionError: "",
    personType: "",
    personTypeError: "",
    productType: "",
    productTypeError: "",
    type: "",
    typeError: "",
    adquiredNationality: { label: "", value: "" },
    adquiredNationalityError: "",
    economicActivity: "",
    economicActivityError: "",

    transactionType: "",
    transactionTypeError: "",
    originalAmount: 0,
    originalAmountError: "",
    originalAmountCurrency: "",
    originalAmountCurrencyError: "",
    dominicanAmount: 0,
    dominicanAmountError: "",
    exchangeRate: 0,
    exchangeRateError: "",
    transactionDate: "",
    transactionDateError: "",
    originOfFunds: "",
    originOfFundsError: "",
    description: "",
    descriptionError: "",
    originalAmountCurrencyDescription: "",
    originalAmountCurrencyDescriptionError: "",

    isBeneficiaryDifferent: false,
    isBeneficiaryDifferentError: "",
    isIntermediaryDifferent: false,
    isIntermediaryDifferentError: "",
    isInternational: false,
    isInternationalError: "",
    countryOfOrigin: "",
    countryOfOriginError: "",
    countryOfDestination: { label: "", value: "" },
    countryOfDestinationError: "",
    remittance: "",
    remittanceError: "",
    reportDocumentsDescription: "",
    reportDocumentsDescriptionError: "",
    reportReason: "",
    reportReasonError: "",
    reportPriorityDescription: "",
    reportPriorityDescriptionError: "",
    reportPriority: "",
    reportPriorityError: "",
    correspondentEntity: "",
    correspondentEntityError: "",
    typeOfInstrument: "",
    typeOfInstrumentError: "",

    financialAccounts: [],
    financialAccountsError: "",

    // intermediary
    iFirstName: "",
    iLastName: "",
    iNationality: { label: "", value: "" },
    iGender: "",
    iAdquiredNationality: "",
    iIdType: "",
    iIdNumber: "",
    iReference: "",
    iCity: "",
    iSector: "",
    iState: "",

    // beneficiary

    bFirstName: "",
    bLastName: "",
    bNationality: { label: "", value: "" },
    bGender: "",
    bAdquiredNationality: "",
    bIdType: "",
    bIdNumber: "",
    bReference: "",
    bCity: "",
    bSector: "",
    bState: "",
  };

  constructor(props: IFormProps) {
    super(props);

    // debtor
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // institution
    this.handleAddInstitution = this.handleAddInstitution.bind(this);
    this.handleDeleteInstitution = this.handleDeleteInstitution.bind(this);

    // core event
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleStartEdit = this.handleStartEdit.bind(this);
    this.handleStopEdit = this.handleStopEdit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeTableData = this.handleChangeTableData.bind(this);
    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
  }
  public handleAddInstitution(id: string) {
    this.setState({ institution: [id] });
  }
  public handleDeleteInstitution(id: string) {
    this.setState({ institution: [] });
  }
  public handleSelectChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public handleCurrencyInputChange(key: any) {
    return (event: any, maskedValue = "", floatValue = "") => {
      this.setState({ [key]: floatValue });
    };
  }
  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleStartEdit = (i: any) => {
    this.setState({ editIdx: i });
  };

  public handleReactSelectChange(key: string) {
    return (event: any) => {
      this.setState({
        [key]: event,
      });
    };
  }
  public getIdentificationInput(identificationType: string) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }
  public handleChangeTableData = (i: any, x: any) => {
    this.setState((state) => ({
      financialAccounts: state.financialAccounts.map((row: any, j: any) =>
        j === i ? x : row
      ),
    }));
    this.handleStopEdit();
  };

  public handleCheckboxChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.target.checked,
      });
    };
  }
  public handleStopEdit = () => {
    this.setState({ editIdx: -1 });
  };

  public handleRemove = (i: any) => {
    this.setState({
      financialAccounts: this.state.financialAccounts.filter(
        (row: any, j: any) => j !== i
      ),
    });
  };

  public handleSave(data: any) {
    this.setState({
      financialAccounts: data,
    });
  }

  public handleAddClient(id: any) {
    this.setState({ debtor: [id] });
  }
  public handleDeleteClient(id: any) {
    this.setState({ debtor: [] });
  }

  public handleCheckBoxChange(key: any) {
    return (event: any) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public async componentDidMount() {
    await fetchNationalities({ token: this.props.user.token })
      .then((res) => {
        this.setState(
          {
            countries: nationalitiesToOptions(
              res.data.results ? res.data.results : res.data
            ),
          },
          () => {
            this.setState((prevState) => ({
              iNationality: prevState.countries[0],
              adquiredNationality: prevState.countries[0],
              bNationality: prevState.countries[0],
              countryOfDestination: prevState.countries[0],
            }));
          }
        );
      })
      .catch((err) => {
        this.setState({
          dialog: true,
          dialogTitle: "Error",
          errorMessage: "Problema cargando  nacionalidades",
          loading: false,
        });
      });
  }
  public handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);

    const { state } = this;
    if (!this.state.debtor.length && this.state.personType !== "company") {
      this.setState({ debtorError: "Este campo es requerido" });
    } else if (
      !this.state.institution.length &&
      this.state.personType === "company"
    ) {
      this.setState({ institutionError: "Este campo es requerido" });
    } else if (!this.state.financialAccounts.length) {
      this.setState({ financialAccountsError: "Este campo es requerido" });
    } else {
      const contract: IComplianceRegistrationForm = {
        partner: state.debtor[0] || "",
        institution: state.institution[0] || "",
        title: titleCaseHandler(state.title),
        productType: state.productType,
        personType: state.personType,
        type: state.type,
        adquiredNationality: state.adquiredNationality.value,
        economicActivity: state.economicActivity,
        pepPosition: state.pepPosition,

        financialAccounts: state.financialAccounts,
        transactionType: state.transactionType,
        typeOfInstrument: state.typeOfInstrument,
        originalAmountCurrencyDescription:
          state.originalAmountCurrencyDescription,
        originalAmountCurrency: state.originalAmountCurrency,
        originalAmount: state.originalAmount,
        dominicanAmount: state.dominicanAmount,
        exchangeRate: state.exchangeRate,
        transactionDate: state.transactionDate,
        originOfFunds: state.originOfFunds,
        description: state.description,
        isIntermediaryDifferent: state.isIntermediaryDifferent,
        isBeneficiaryDifferent: state.isBeneficiaryDifferent,

        isInternational: state.isInternational,
        countryOfOrigin: state.countryOfOrigin,
        countryOfDestination: state.countryOfDestination.value,
        remittance: state.remittance,
        correspondentEntity: state.correspondentEntity,

        intermediary: {
          firstName: state.iFirstName,
          lastName: state.iLastName,
          nationality: state.iNationality.value,
          adquiredNationality: state.iAdquiredNationality,
          idType: state.iIdType,
          idNumber: state.iIdNumber,
          gender: state.iGender,
          reference: state.iReference,
          city: state.iCity,
          state: state.iState,
          sector: state.iSector,
        },

        beneficiaryOfTheOperation: {
          firstName: state.bFirstName,
          lastName: state.bLastName,
          nationality: state.bNationality.value,
          adquiredNationality: state.bAdquiredNationality,
          idType: state.bIdType,
          idNumber: state.bIdNumber,
          gender: state.bGender,
          reference: state.bReference,
          city: state.bCity,
          state: state.bState,
          sector: state.bSector,
        },

        reportPriority: state.reportPriority,
        reportPriorityDescription: state.reportPriorityDescription,
        reportReason: state.reportReason,
        reportDocumentsDescription: state.reportDocumentsDescription,
      };

      this.setState({ loading: true });

      create(this.props.user.token, contract)
        .then((res) => {
          this.props.createComplianceRegistrationForm(
            res.data.results ? res.data.results : res.data
          );
          successHandler(
            res,
            this,
            "Declaración de procedencia de Fondos Persona Física"
          );
          this.handleReset();
        })
        .catch((err: any) => {
          catchError(err, this);
        });
    }
  }
  public handleReset() {
    this.setState({
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      debtor: [],
      debtorError: "",
      loading: false,
      title: "",
      titleError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.originalAmount !== this.state.originalAmount ||
      prevState.exchangeRate !== this.state.exchangeRate ||
      prevState.originalAmountCurrency !== this.state.originalAmountCurrency
    ) {
      if (this.state.originalAmountCurrency !== "DOP") {
        this.setState({
          dominicanAmount: Math.round(
            this.state.originalAmount * this.state.exchangeRate
          ),
        });
      } else {
        this.setState({
          exchangeRate: 0,
          dominicanAmount: this.state.originalAmount,
        });
      }
    }
  }

  public render() {
    const { classes } = this.props;
    const headersAR = [
      { name: "Banco", prop: "bank", type: "text" },
      {
        name: "N° Cuenta",
        prop: "accountNumber",
        type: "text",
      },
      { name: "Tipo de cuenta", prop: "accountType", type: "text" },
      { name: "Moneda", prop: "currency", type: "text" },
    ];

    return (
      <form name="MA" onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            {"Nuevo Registro de Cumplimiento"}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={3}>
                  <TextField
                    label={translations.TITLE}
                    value={this.state.title}
                    onChange={this.handleChange("title")}
                    fullWidth={true}
                    required={true}
                    error={this.state.titleError !== ""}
                    helperText={this.state.titleError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="personType" shrink={true}>
                      Tipo de persona
                    </InputLabel>
                    <Select
                      value={this.state.personType}
                      onChange={this.handleSelectChange("personType")}
                      required={true}
                      inputProps={{
                        id: "personType",
                        name: "personType",
                      }}
                    >
                      <MenuItem value="physical">Físico</MenuItem>
                      <MenuItem value="pep">PEP</MenuItem>
                      <MenuItem value="company">Jurídico</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {this.state.personType === "pep" ? (
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Posición del PEP"
                      fullWidth={true}
                      value={this.state.pepPosition}
                      onChange={this.handleChange("pepPosition")}
                      required={true}
                      error={this.state.pepPositionError !== ""}
                      helperText={this.state.pepPositionError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                ) : null}
                <Grid item={true} xs={3}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="productType" shrink={true}>
                      Tipo Producto
                    </InputLabel>
                    <Select
                      value={this.state.productType}
                      onChange={this.handleSelectChange("productType")}
                      required={true}
                      inputProps={{
                        id: "productType",
                        name: "productType",
                      }}
                    >
                      <MenuItem value="Cuentas de ahorro">
                        Cuentas de ahorro
                      </MenuItem>
                      <MenuItem value="Certificado de deposito a plazo">
                        Certificado de deposito a plazo
                      </MenuItem>
                      <MenuItem value="Certificado de aportaciones">
                        Certificado de aportaciones
                      </MenuItem>
                      <MenuItem value="Prestamos">Prestamos</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="type" shrink={true}>
                      Tipo de documento
                    </InputLabel>
                    <Select
                      value={this.state.type}
                      onChange={this.handleSelectChange("type")}
                      required={true}
                      inputProps={{
                        id: "type",
                        name: "type",
                      }}
                    >
                      <MenuItem value="RTE">RTE</MenuItem>
                      <MenuItem value="ROS">ROS</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <TextField
                    label="Actividad economica"
                    fullWidth={true}
                    value={this.state.economicActivity}
                    onChange={this.handleChange("economicActivity")}
                    required={true}
                    error={this.state.economicActivityError !== ""}
                    helperText={this.state.economicActivityError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>

                <Grid item={true} xs={3}>
                  <TextField
                    label="Tipo de transaccion"
                    fullWidth={true}
                    value={this.state.transactionType}
                    onChange={this.handleChange("transactionType")}
                    required={true}
                    error={this.state.transactionTypeError !== ""}
                    helperText={this.state.transactionTypeError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <TextField
                    label="Monto Peso Dominicano"
                    value={this.state.dominicanAmount}
                    onChange={this.handleChange("dominicanAmount")}
                    required={true}
                    fullWidth={true}
                    disabled={true}
                    error={this.state.dominicanAmountError !== ""}
                    helperText={this.state.dominicanAmountError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <TextField
                    label="Monto Original"
                    value={this.state.originalAmount}
                    onChange={this.handleCurrencyInputChange("originalAmount")}
                    required={true}
                    fullWidth={true}
                    error={this.state.originalAmountError !== ""}
                    helperText={this.state.originalAmountError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="originalAmountCurrency" shrink={true}>
                      Tipo de moneda
                    </InputLabel>
                    <Select
                      value={this.state.originalAmountCurrency}
                      onChange={this.handleSelectChange(
                        "originalAmountCurrency"
                      )}
                      required={true}
                      inputProps={{
                        id: "originalAmountCurrency",
                        name: "originalAmountCurrency",
                      }}
                    >
                      <MenuItem value="DOP">DOP</MenuItem>
                      <MenuItem value="EUR">EUR</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="other">Otro</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {this.state.originalAmountCurrency === "other" ? (
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Tipo de moneda"
                      fullWidth={true}
                      value={this.state.originalAmountCurrencyDescription}
                      onChange={this.handleChange(
                        "originalAmountCurrencyDescription"
                      )}
                      required={true}
                      error={
                        this.state.originalAmountCurrencyDescriptionError !== ""
                      }
                      helperText={
                        this.state.originalAmountCurrencyDescriptionError
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                ) : null}
                <Grid item={true} xs={3}>
                  <TextField
                    label="Tasa de cambio"
                    disabled={
                      this.state.originalAmountCurrency === "DOP" ? true : false
                    }
                    value={this.state.exchangeRate}
                    type="number"
                    onChange={this.handleChange("exchangeRate")}
                    required={true}
                    fullWidth={true}
                    error={this.state.exchangeRateError !== ""}
                    helperText={this.state.exchangeRateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 100,
                      min: 0,
                      step: 0.01,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <TextField
                    label="Origen de fondos"
                    fullWidth={true}
                    value={this.state.originOfFunds}
                    onChange={this.handleChange("originOfFunds")}
                    required={true}
                    error={this.state.originOfFundsError !== ""}
                    helperText={this.state.originOfFundsError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControl fullWidth={true}>
                    <label
                      htmlFor="nationality"
                      style={styles.checkboxLabelReactSelect}
                    >
                      Nacionalidad adquirida
                    </label>
                    <ReactSelect
                      value={this.state.adquiredNationality}
                      onChange={this.handleReactSelectChange(
                        "adquiredNationality"
                      )}
                      options={this.state.countries}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} xs={3}>
                  <TextField
                    type="datetime-local"
                    fullWidth={true}
                    value={this.state.transactionDate}
                    label={translations.CONTRACT_DATE}
                    required={true}
                    id="dueDate"
                    onChange={this.handleSelectChange("transactionDate")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <TextField
                    label="Descripcion"
                    fullWidth={true}
                    value={this.state.description}
                    onChange={this.handleChange("description")}
                    required={true}
                    error={this.state.descriptionError !== ""}
                    helperText={this.state.descriptionError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <TextField
                    label="Tipo de instrumento"
                    fullWidth={true}
                    value={this.state.typeOfInstrument}
                    onChange={this.handleChange("typeOfInstrument")}
                    required={true}
                    error={this.state.typeOfInstrumentError !== ""}
                    helperText={this.state.typeOfInstrumentError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>

                <Grid item={true} xs={3}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label="¿La transacción es internacional?"
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.isInternational}
                        onChange={this.handleCheckBoxChange("isInternational")}
                      />
                    }
                  />
                </Grid>

                <Grid item={true} xs={3}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label="¿Es el Beneficiario diferente?"
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.isBeneficiaryDifferent}
                        onChange={this.handleCheckBoxChange(
                          "isBeneficiaryDifferent"
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item={true} xs={3}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label="¿Es el intermediario diferente?"
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.isIntermediaryDifferent}
                        onChange={this.handleCheckBoxChange(
                          "isIntermediaryDifferent"
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    Datos bancarios
                  </Typography>
                  <ExtraPaymentTable
                    actions={true}
                    create={true}
                    data={this.state.financialAccounts}
                    editIdx={this.state.editIdx}
                    handleChange={this.handleChangeTableData}
                    handleRemove={this.handleRemove}
                    handleSave={this.handleSave}
                    handleStartEdit={this.handleStartEdit}
                    handleStopEdit={this.handleStopEdit}
                    header={headersAR}
                    tableLimit={3}
                  />
                  <p className={classes.errorInput}>
                    {this.state.financialAccountsError}
                  </p>
                </Grid>

                {this.state.personType === "company" ? (
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      Empresa donde labora
                    </Typography>
                    <InstitutionSelectInput
                      selectedInstitutionId={this.state.institution}
                      addInstitution={this.handleAddInstitution}
                      deleteInstitution={this.handleDeleteInstitution}
                    />
                    <p className={classes.errorInput}>
                      {this.state.institutionError}
                    </p>
                  </Grid>
                ) : (
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      {translations.PARTNER}
                    </Typography>
                    <ParticipantSelectInput
                      selectedParticipantId={this.state.debtor}
                      addParticipant={this.handleAddClient}
                      deleteParticipant={this.handleDeleteClient}
                    />
                    <p className={classes.errorInput}>
                      {this.state.debtorError}
                    </p>
                  </Grid>
                )}
              </Grid>

              {this.state.isIntermediaryDifferent ? (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      Intermediario
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={3}>
                    <TextField
                      label="Nombre"
                      fullWidth={true}
                      value={this.state.iFirstName}
                      onChange={this.handleChange("iFirstName")}
                      required={true}
                      error={this.state.iFirstNameError !== ""}
                      helperText={this.state.iFirstNameError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Apellidos"
                      fullWidth={true}
                      value={this.state.iLastName}
                      onChange={this.handleChange("iLastName")}
                      required={true}
                      error={this.state.iLastNameError !== ""}
                      helperText={this.state.iLastNameError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl fullWidth={true}>
                      <label
                        htmlFor="nationality"
                        style={styles.checkboxLabelReactSelect}
                      >
                        Nacionalidad origen
                      </label>
                      <ReactSelect
                        value={this.state.iNationality}
                        onChange={this.handleReactSelectChange("iNationality")}
                        options={this.state.countries}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl fullWidth={true}>
                      <InputLabel>Nacionalidad adquirida</InputLabel>
                      <Select
                        label="Nacionalidad adquirida"
                        value={this.state.iAdquiredNationality}
                        onChange={this.handleSelectChange(
                          "iAdquiredNationality"
                        )}
                        required={true}
                      >
                        {this.state.countries.map((opt: any, indx: any) => {
                          return (
                            <MenuItem value={opt.value} key={indx}>
                              {opt.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="identificationType" shrink={true}>
                        {translations.IDENTIFICATION_TYPE}
                      </InputLabel>
                      <Select
                        value={this.state.iIdType}
                        onChange={this.handleSelectChange("iIdType")}
                        required={true}
                        error={this.state.iIdTypeError !== ""}
                        inputProps={{
                          id: "iIdType",
                          name: "iIdType",
                        }}
                      >
                        {IDENTIFICATION_TYPE.map((opt, indx) => {
                          return (
                            <MenuItem value={opt.value} key={indx}>
                              {opt.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label={translations.IDENTIFICATION}
                      value={this.state.iIdNumber}
                      onChange={this.handleChange("iIdNumber")}
                      fullWidth={true}
                      required={true}
                      error={this.state.iIdNumberError !== ""}
                      helperText={this.state.iIdNumberError}
                      InputProps={this.getIdentificationInput(
                        this.state.iIdType
                      )}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="gender" shrink={true}>
                        {translations.GENDER}
                      </InputLabel>
                      <Select
                        value={this.state.iGender}
                        onChange={this.handleSelectChange("iGender")}
                        required={true}
                        error={this.state.iGenderError !== ""}
                        inputProps={{
                          id: "iGender",
                          name: "iGender",
                        }}
                      >
                        {gender.map((opt, indx) => {
                          return (
                            <MenuItem value={opt.value} key={indx}>
                              {opt.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Provincia"
                      fullWidth={true}
                      value={this.state.iState}
                      onChange={this.handleChange("iState")}
                      required={true}
                      error={this.state.iStateError !== ""}
                      helperText={this.state.iStateError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Ciudad"
                      fullWidth={true}
                      value={this.state.iCity}
                      onChange={this.handleChange("iCity")}
                      required={true}
                      error={this.state.iCityError !== ""}
                      helperText={this.state.iCityError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Sector"
                      fullWidth={true}
                      value={this.state.iSector}
                      onChange={this.handleChange("iSector")}
                      required={true}
                      error={this.state.iSectorError !== ""}
                      helperText={this.state.iSectorError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Referencia"
                      fullWidth={true}
                      value={this.state.iReference}
                      onChange={this.handleChange("iReference")}
                      required={true}
                      error={this.state.iReferenceError !== ""}
                      helperText={this.state.iReferenceError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                </Grid>
              ) : null}

              {this.state.isBeneficiaryDifferent ? (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      Beneficiario
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Nombre"
                      fullWidth={true}
                      value={this.state.bFirstName}
                      onChange={this.handleChange("bFirstName")}
                      required={true}
                      error={this.state.bFirstNameError !== ""}
                      helperText={this.state.bFirstNameError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Apellidos"
                      fullWidth={true}
                      value={this.state.bLastName}
                      onChange={this.handleChange("bLastName")}
                      required={true}
                      error={this.state.bLastNameError !== ""}
                      helperText={this.state.bLastNameError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl fullWidth={true}>
                      <label
                        htmlFor="nationality"
                        style={styles.checkboxLabelReactSelect}
                      >
                        Nacionalidad origen
                      </label>
                      <ReactSelect
                        value={this.state.bNationality}
                        onChange={this.handleReactSelectChange("bNationality")}
                        options={this.state.countries}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl fullWidth={true}>
                      <InputLabel>Nacionalidad adquirida</InputLabel>
                      <Select
                        label="Nacionalidad adquirida"
                        value={this.state.bAdquiredNationality}
                        onChange={this.handleSelectChange(
                          "bAdquiredNationality"
                        )}
                        required={true}
                      >
                        {this.state.countries.map((opt: any, indx: any) => {
                          return (
                            <MenuItem value={opt.value} key={indx}>
                              {opt.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="identificationType" shrink={true}>
                        {translations.IDENTIFICATION_TYPE}
                      </InputLabel>
                      <Select
                        value={this.state.bIdType}
                        onChange={this.handleSelectChange("bIdType")}
                        required={true}
                        error={this.state.bIdTypeError !== ""}
                        inputProps={{
                          id: "bIdType",
                          name: "bIdType",
                        }}
                      >
                        {IDENTIFICATION_TYPE.map((opt, indx) => {
                          return (
                            <MenuItem value={opt.value} key={indx}>
                              {opt.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label={translations.IDENTIFICATION}
                      value={this.state.bIdNumber}
                      onChange={this.handleChange("bIdNumber")}
                      fullWidth={true}
                      required={true}
                      error={this.state.bIdNumberError !== ""}
                      helperText={this.state.bIdNumberError}
                      InputProps={this.getIdentificationInput(
                        this.state.bIdType
                      )}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="gender" shrink={true}>
                        {translations.GENDER}
                      </InputLabel>
                      <Select
                        value={this.state.bGender}
                        onChange={this.handleSelectChange("bGender")}
                        required={true}
                        error={this.state.bGenderError !== ""}
                        inputProps={{
                          id: "bGender",
                          name: "bGender",
                        }}
                      >
                        {gender.map((opt, indx) => {
                          return (
                            <MenuItem value={opt.value} key={indx}>
                              {opt.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Provincia"
                      fullWidth={true}
                      value={this.state.bState}
                      onChange={this.handleChange("bState")}
                      required={true}
                      error={this.state.bStateError !== ""}
                      helperText={this.state.bStateError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Ciudad"
                      fullWidth={true}
                      value={this.state.bCity}
                      onChange={this.handleChange("bCity")}
                      required={true}
                      error={this.state.bCityError !== ""}
                      helperText={this.state.bCityError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Sector"
                      fullWidth={true}
                      value={this.state.bSector}
                      onChange={this.handleChange("bSector")}
                      required={true}
                      error={this.state.bSectorError !== ""}
                      helperText={this.state.bSectorError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Referencia"
                      fullWidth={true}
                      value={this.state.bReference}
                      onChange={this.handleChange("bReference")}
                      required={true}
                      error={this.state.bReferenceError !== ""}
                      helperText={this.state.bReferenceError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                </Grid>
              ) : null}

              {this.state.type === "ROS" ? (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      Detalles del documento
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      label="Razones del reporte"
                      onChange={this.handleChange("reportReason")}
                      value={this.state.reportReason}
                      style={{
                        color: green[500],
                        width: "100%",
                      }}
                      multiline={true}
                      rows={5}
                      rowsMax={5}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <TextField
                      label="DETALLE TODOS LOS DOCUMENTOS ANEXADOS A ESTE EXPEDIENTE"
                      onChange={this.handleChange("reportDocumentsDescription")}
                      value={this.state.reportDocumentsDescription}
                      style={{
                        color: green[500],
                        width: "100%",
                      }}
                      multiline={true}
                      rows={5}
                      rowsMax={5}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="reportPriority" shrink={true}>
                        Prioridad del documento
                      </InputLabel>
                      <Select
                        value={this.state.reportPriority}
                        onChange={this.handleSelectChange("reportPriority")}
                        required={true}
                        inputProps={{
                          id: "reportPriority",
                          name: "reportPriority",
                        }}
                      >
                        <MenuItem value="Narcotráfico">Narcotráfico</MenuItem>
                        <MenuItem value="Corrupción">Corrupción</MenuItem>
                        <MenuItem value="Financ. Terrorismo">
                          Financ. Terrorismo
                        </MenuItem>
                        <MenuItem value="other">Otros</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {this.state.reportPriority === "other" ? (
                    <Grid item={true} xs={6}>
                      <TextField
                        label="Razones del reporte"
                        onChange={this.handleChange(
                          "reportPriorityDescription"
                        )}
                        value={this.state.reportPriorityDescription}
                        style={{
                          color: green[500],
                          width: "100%",
                        }}
                      />
                    </Grid>
                  ) : null}
                  <br />
                </Grid>
              ) : null}
              {this.state.isInternational ? (
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={12}>
                    <Typography variant="h6" className={classes.checkboxLabel}>
                      Detalles de la transacción internacional
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Remitente"
                      fullWidth={true}
                      value={this.state.remittance}
                      onChange={this.handleChange("remittance")}
                      required={true}
                      error={this.state.remittanceError !== ""}
                      helperText={this.state.remittanceError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <TextField
                      label="Entidad Correspondiente"
                      fullWidth={true}
                      value={this.state.correspondentEntity}
                      onChange={this.handleChange("correspondentEntity")}
                      required={true}
                      error={this.state.correspondentEntityError !== ""}
                      helperText={this.state.correspondentEntityError}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl fullWidth={true}>
                      <InputLabel>Pais de origen</InputLabel>
                      <Select
                        label="Pais de origen"
                        value={this.state.countryOfOrigin}
                        onChange={this.handleSelectChange("countryOfOrigin")}
                        required={true}
                      >
                        {this.state.countries.map((opt: any, indx: any) => {
                          return (
                            <MenuItem value={opt.value} key={indx}>
                              {opt.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={3}>
                    <FormControl fullWidth={true}>
                      <InputLabel>Pais destino</InputLabel>
                      <Select
                        label="Pais de destino"
                        value={this.state.countryOfDestination}
                        onChange={this.handleSelectChange(
                          "countryOfDestination"
                        )}
                        required={true}
                      >
                        {this.state.countries.map((opt: any, indx: any) => {
                          return (
                            <MenuItem value={opt.value} key={indx}>
                              {opt.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <br />
                </Grid>
              ) : null}
              <br />
              <br />
              <br />
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createComplianceRegistrationForm })(Form)
);
