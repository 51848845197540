import React, { useRef, useEffect } from 'react';
import {
    Typography, Button, Accordion, AccordionSummary, AccordionDetails, Divider
} from '@material-ui/core';
import { 
    ExpandMore, Print,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const AccordionStyled = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      overflowAnchor: 'none !important',
      width:'100%',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
})(Accordion);

const AccordionSummaryStyled = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
})(AccordionSummary);

export function FormWrapper(props) {
    const handleDocumentPrint = (event) => {
        event.stopPropagation();
        const printBtn = document.querySelector(`.accordion-content-wrapper.${props.name} .generate-print-document`);
        if(printBtn) {
            printBtn.click();
        }
    }
    return(
        <AccordionStyled
            expanded={props.expand}
            onChange={props.handleExpand.bind(this, props.name)}
            square 
            className="responsible-container"
            style={{...{marginBottom:'1rem'}, ...props.errorStyles}}
            disabled={props.disabled}
        >
            <AccordionSummaryStyled
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                className={props.toolbarClass}
                // style={props.toolbarContentStyle}
            >
                <Typography
                    variant={"subtitle1"} className={"responsible-header-text"}
                >
                    {props.title}
                    <Typography variant="caption" style={{color:'#757575'}}>&nbsp;{props.contentType}</Typography>
                </Typography>
                <div style={{marginLeft:'1rem', display:'flex', alignItems:'center'}}>
                    {
                        !props.headerAction && !props.hideAction ?
                        <Button
                            variant="contained"
                            disableElevation
                            style={{
                                backgroundColor:'#FFFFFF', border: '1px solid #43A047', color: '#43A047',
                                height:25, 
                            }}
                            onClick={props.printDocument || handleDocumentPrint}
                        >
                            <Print style={{fontSize:17, color:'#43A047', marginRight:'.2rem'}} />
                        </Button>
                        :
                        props.headerAction
                    }
                </div>
            </AccordionSummaryStyled>

            <AccordionDetails
                style={{border:'1px solid gba(0, 0, 0, .125)', justifyContent:'center'}}
                className={`accordion-content-wrapper ${props.name}`}
            >
                {
                    props.ticketCompleted &&
                        <div className="disable-overlay"></div>
                }
                {props.form}
            </AccordionDetails>
        </AccordionStyled>
    )
}

export function SectionHeader(props) {
    return(
        <div style={{width:'100%', margin:'2rem 0 1rem 0'}}>
            <Typography
                variant={props.variant || "subtitle1"}
                className="section-header"
                style={{fontWeight: 'bold', color:'#5e5e5e'}}
            >
                {props.title?.toUpperCase()}
            </Typography>
            <Divider/>
        </div>
    )
}