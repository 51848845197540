import * as React from "react";
import { connect } from "react-redux";

import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import {
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Radio
} from '@material-ui/core'
import { RadioProps } from '@material-ui/core/Radio'
import AlertDialog from "../../../components/AlertDialog";
// import { fetchParticipants } from 'src/utils/axios';
import {
    catchError,
} from "../../../utils";
import { fetchChargingMethodResumed as fetchAll } from '../../../utils/axios';
import { Theme } from '@material-ui/core/styles';

const styles = createStyles({
    checkboxLabel: {
        color: green[500],
        fontSize: "medium",
    },
    errorInput: {
        color: 'red'
    },
    root: {
        overflowY: "auto",
        padding: 10,
    },
});
const GreenRadio = withStyles({
    checked: {},
    root: {
        "&$checked": { color: green[600] },
        color: green[400],
    },
})((props: RadioProps) => <Radio color="default" {...props} />);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
            borderBottom: "none"

        },
    }),
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        root: {

            borderBottom: "none"
        }
    }),
)(TableCell);

interface IChargingMethodTableState {
    [key: string]: string | number | boolean | string[] | IBankAccount[];

    dialog: boolean;
    dialogTitle: string;
    errorMessage: string;
    loading: boolean;
    chargingMethods: string[];
    chargingMethodsError: string;
}
interface IChargingMethodTableProps {
    classes: {
        root: string;
        checkboxLabel: string;
        errorInput?: string;
    }
    debtor?: string[];
    user: IUser;
    checked: any
    handleChecked?(chargingMethod?: string, event?: any): void;

}
class ChargingMethodTable extends React.Component<IChargingMethodTableProps, IChargingMethodTableState> {
    public state: IChargingMethodTableState = {
        chargingMethods: [],
        chargingMethodsError: '',
        dialog: false,
        dialogTitle: "",
        errorMessage: "",
        loading: false,
        open: false,
        currSelectionIndex: -1,
        isRowOpen: false
    };

    constructor(props: IChargingMethodTableProps) {
        super(props);
        this.toogleCollapseLevel = this.toogleCollapseLevel.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
    }
    public toogleCollapseLevel(rowIndex: any) {

        if (rowIndex === this.state.currSelectionIndex && this.state.isRowOpen) {
            this.setState({
                isRowOpen: false
            })
        } else {
            this.setState({
                isRowOpen: true,
                currSelectionIndex: rowIndex
            })
        }
    }

    public componentDidMount() {
        this.setState({ loading: true });
        fetchAll(this.props.user.token, this.props.debtor![0]).then((res: any) => {
            this.setState({ chargingMethods: res.data });
            this.setState({ loading: false });
        }).catch((err: any) => {
            catchError(err, this)
        });
    }

    public handleSelectChange(key: any) {
        return (event: any) => {
            this.setState({
                [key]: event.target.value,
                [key + "Error"]: "",
            });
        };
    }

    public handleDialogClose() {
        this.setState({ dialog: false });
    }
    public render() {
        const { classes } = this.props;
        return (
            <div>
                <AlertDialog
                    open={this.state.dialog}
                    message={this.state.errorMessage}
                    title={this.state.dialogTitle}
                    handleClose={this.handleDialogClose}
                />
                {this.state.chargingMethods.length > 0 ?
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead >
                                <TableRow>
                                    <TableCell>Etiqueta</TableCell>
                                    <TableCell >Número de cuenta</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.chargingMethods.map((chargingMethod: any, index: any) => (
                                    <React.Fragment key={index}>
                                        <StyledTableRow
                                            hover={true}
                                            role="checkbox"
                                            aria-checked={this.props.checked}
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            {chargingMethod.label ? <StyledTableCell>{chargingMethod.label}</StyledTableCell> : null}
                                            {chargingMethod.accountNumber ? <StyledTableCell>{chargingMethod.accountNumber}</StyledTableCell> : null}
                                            <StyledTableCell padding="checkbox">
                                                <GreenRadio
                                                    value={chargingMethod.uniqueId}
                                                    checked={this.props.checked === chargingMethod.uniqueId}
                                                    onClick={
                                                        () => this.props.handleChecked && this.props.handleChecked(
                                                            chargingMethod.uniqueId,
                                                        )
                                                    }
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </React.Fragment>))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    :

                    <TableContainer component={Paper}>
                        <p className={classes.errorInput}>No hay metodos de cobro relacionadas con este deudor</p>
                    </TableContainer>
                }
            </div>
        );
    }
}

function mapStateToProps(state: IState) {
    return {
        user: state.user,
    };
}
export default withStyles(styles)(
    connect(mapStateToProps)(ChargingMethodTable)
);