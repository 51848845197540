
import { COMPLIANCE_REGISTRATION_FORM_TYPES } from '../actions';

export default function(state: IComplianceRegistrationForm[] = [], action: IAction<IComplianceRegistrationForm[]>): IComplianceRegistrationForm[] {
    switch(action.type) {
        case COMPLIANCE_REGISTRATION_FORM_TYPES.FETCH_ALL:
            return action.payload || [];
        case COMPLIANCE_REGISTRATION_FORM_TYPES.FETCH:
            return state.map(cRF => (action.payload !== undefined && cRF.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: cRF);
        case COMPLIANCE_REGISTRATION_FORM_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case COMPLIANCE_REGISTRATION_FORM_TYPES.UPDATE:
            return state.map(cRF => (action.payload !== undefined && cRF.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : cRF);
        case COMPLIANCE_REGISTRATION_FORM_TYPES.DELETE:
            return state.filter(cRF => action.payload !== undefined && cRF.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}