import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import SaveIcon from "@material-ui/icons/Save";

import idNumberMaskInput from "src/components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "src/components/TextMaskInputComponents/passportMaskInput";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";
import {
  deleteProvider,
  fetchProvider,
  updateProvider,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  IDENTIFICATION_TYPE,
  resetError,
  translations,
  titleCaseHandler,
} from "../../../utils";
import {
  API_KEY,
  deleteProvider as deletePR,
  fetchProvider as fetchPR,
  updateProvider as updatePR,
} from "../../../utils/axios";
import ProviderContactSelectInput from "../../ProviderContact/ProviderContactSelectInput";
import Address from "../../../components/Address/Address";

const GreenRadio = withStyles({
  checked: {},
  root: {
    "&$checked": { color: green[600] },
    color: green[400],
  },
})((props) => <Radio color="default" {...props} />);

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 52,
    top: 25,
  },
  checkboxLabel: {
    color: green[500],
    fontSize: "medium",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  fileText: {
    fontSize: 14,
    position: "absolute",
    right: 32,
  },
  formControl: {
    marginTop: 20,
    width: "90%",
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  input: {
    left: 40,
    position: "absolute",
    width: 1,
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
  textField2: {
    display: "block",
    marginTop: 20,
    width: "90%",
  },
});

interface IProviderViewProps {
  providers: IProvider[];
  providerId: string;
  classes: { [key: string]: string };

  user: IUser;
  changeSection(sectionNumber: number): void;
  deleteProvider(uniqueId: string): IAction<IProvider[]>;
  fetchProvider(provider: IProvider): IAction<IProvider[]>;
  updateProvider(provider: IProvider): IAction<IProvider[]>;
}

interface IProviderViewState {
  [key: string]: string | string[] | number | boolean | Blob;

  identificationNumber: string;
  identificationNumberError: string;
  identificationType: string;
  identificationTypeError: string;
  fullName: string;
  fullNameError: string;
  city: string;
  cityError: string;
  country: string;
  countryError: string;
  providerType: string;
  providerTypeError: string;
  reference: string;
  referenceError: string;
  sector: string;
  sectorError: string;
  region: string;
  regionError: string;
  state: string;
  stateError: string;
  generateDocUrl: string;
  website: string;
  websiteError: string;
  zipCode: string;
  zipCodeError: string;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;
  uniqueId: string;
}
class ProviderView extends React.Component<
  IProviderViewProps,
  IProviderViewState
> {
  public state: IProviderViewState = {
    city: "",
    cityError: "",
    country: "República Dominicana",
    countryError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    fullName: "",
    fullNameError: "",
    generateDocUrl: "",
    identificationNumber: "",
    identificationNumberError: "",
    identificationType: "national",
    identificationTypeError: "",
    loading: false,
    providerType: "company",
    providerTypeError: "",
    province: "",
    provinceError: "",
    reference: "",
    referenceError: "",
    region: "",
    regionError: "",
    sector: "",
    sectorError: "",
    state: "",
    stateError: "",
    uniqueId: "",
    website: "",
    websiteError: "",
    zipCode: "",
    zipCodeError: "",
  };
  constructor(props: IProviderViewProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.retrieveProvider = this.retrieveProvider.bind(this);
  }
  public handleProviderInputChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  public handleCheckInputChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }

  public componentDidMount() {
    this.retrieveProvider();
  }

  public retrieveProvider() {
    this.setState({ loading: true });
    fetchPR(this.props.user.token, this.props.providerId)
      .then((res) => {
        const curProvider = res.data as IProvider;

        this.setState({
          city:
            curProvider.address !== undefined ? curProvider.address.city : "",
          country:
            curProvider.address !== undefined
              ? curProvider.address.country
              : "República Dominicana",
          fullName: curProvider.fullName as string,
          generateDocUrl: `${API_KEY}/crm/provider/${curProvider.uniqueId}/print/`,
          identificationNumber: curProvider.identificationNumber as string,
          identificationType: curProvider.identificationType as string,
          loading: false,
          providerType: curProvider.providerType as string,
          region:
            curProvider.address !== undefined ? curProvider.address.region : "",
          state:
            curProvider.address !== undefined ? curProvider.address.state : "",
          reference:
            curProvider.address !== undefined
              ? curProvider.address.reference
              : "",
          sector:
            curProvider.address !== undefined ? curProvider.address.sector : "",
          website: curProvider.website as string,
          zipCode: curProvider.zipCode as string,

          uniqueId: curProvider.uniqueId as string,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }

  public handleSelect(key: string, value: string) {
    this.setState({
      [key]: value,
    });
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);

    const provider: IProvider = {
      address: {
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        region: this.state.region,
        reference: this.state.reference,
        sector: titleCaseHandler(this.state.sector),
      },
      fullName: this.state.fullName,
      identificationNumber: this.state.identificationNumber,
      identificationType: this.state.identificationType,
      providerType: this.state.providerType,
      website: this.state.website,
      zipCode: this.state.zipCode,
    };

    this.setState({ loading: true });

    updatePR(this.props.user.token, this.state.uniqueId, provider)
      .then((res) => {
        this.setState(
          {
            dialog: true,
            dialogTitle: translations.SUCCESS,
            errorMessage: `${translations.PROVIDER} ${translations.UPDATED}`,
            loading: false,
          },
          () => this.props.updateProvider(res.data as IProvider)
        );
      })
      .catch((err: AxiosError) => {
        catchError(err, this);
      });
  }
  public handleDelete() {
    if (confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });

      deletePR(this.props.user.token, this.props.providerId)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteProvider(this.props.providerId);
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }

  public getIdentificationInput(identificationType: string) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <a target="_blank" href={this.state.generateDocUrl}>
          <ArrowDownward className={classes.attachmentIcon} />
        </a>
        <Paper className={classes.paper}>
          <Typography variant="h3" style={{ display: "inline" }}>
            {translations.PROVIDER}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <FormControl className={classes.formControl}>
                <FormLabel component="legend">{`Tipo`}</FormLabel>
                <RadioGroup
                  style={{ display: "-webkit-box" }}
                  value={this.state.providerType}
                  onChange={this.handleChange("providerType")}
                >
                  <FormControlLabel
                    value="company"
                    control={<GreenRadio />}
                    label="Empresa"
                  />
                  <FormControlLabel
                    value="person"
                    control={<GreenRadio />}
                    label="Persona"
                  />
                </RadioGroup>
              </FormControl>

              <TextField
                label={translations.NAME}
                className={classes.textField}
                value={this.state.fullName}
                onChange={this.handleChange("fullName")}
                inputProps={{
                  id: "fullName",
                  name: "fullName",
                }}
                fullWidth={true}
                error={this.state.fullNameError !== ""}
                helperText={this.state.fullNameError}
                required={true}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <div style={{ display: "flex" }}>
                <div style={{ width: "25%" }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="identificationType" shrink={true}>
                      {translations.IDENTIFICATION_TYPE}
                    </InputLabel>
                    <Select
                      value={this.state.identificationType}
                      onChange={this.handleSelectChange("identificationType")}
                      required={true}
                      native={true}
                      error={this.state.identificationTypeError !== ""}
                      inputProps={{
                        id: "identificationType",
                        name: "identificationType",
                      }}
                    >
                      {IDENTIFICATION_TYPE.map((opt, indx) => {
                        return (
                          <option value={opt.value} key={indx}>
                            {opt.label}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <br />
                <div style={{ width: "25%" }}>
                  <TextField
                    label={translations.IDENTIFICATION}
                    className={classes.textField2}
                    value={this.state.identificationNumber}
                    onChange={this.handleChange("identificationNumber")}
                    fullWidth={true}
                    required={true}
                    error={this.state.identificationNumberError !== ""}
                    helperText={this.state.identificationNumberError}
                    InputProps={this.getIdentificationInput(
                      this.state.identificationType
                    )}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </div>
              </div>
              <br />
              <br />

              <Address
                city={this.state.city}
                cityError={this.state.cityError}
                country={this.state.country}
                countryError={this.state.countryError}
                handleChange={this.handleChange}
                handleSelect={this.handleSelect}
                reference={this.state.reference}
                referenceError={this.state.referenceError}
                region={this.state.region}
                regionError={this.state.regionError}
                sector={this.state.sector}
                sectorError={this.state.sectorError}
                state={this.state.state}
                stateError={this.state.stateError}
                title={translations.ADDRESS}
              />

              <TextField
                label={translations.ZIP_CODE}
                className={classes.textField}
                value={this.state.zipCode}
                onChange={this.handleChange("zipCode")}
                fullWidth={true}
                error={this.state.zipCodeError !== ""}
                helperText={this.state.zipCodeError}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              />
              <TextField
                label={translations.WEBSITE}
                className={classes.textField}
                value={this.state.website}
                onChange={this.handleChange("website")}
                fullWidth={true}
                error={this.state.websiteError !== ""}
                helperText={this.state.websiteError}
                InputLabelProps={{
                  style: styles.checkboxLabel,
                }}
              />
              <br />
              <br />
              <br />

              <Typography variant="h6" className={classes.checkboxLabel}>
                {translations.CONTACT}
              </Typography>
              <ProviderContactSelectInput
                providerId={this.state.uniqueId}
                refreshProvider={this.retrieveProvider}
              />

              <br />

              <div className={classes.controls}>
                {/* <Button variant="contained" onClick={this.handleDelete} className={classes.deleteButton}>
                                    <DeleteIcon />
                                    &nbsp;
                                    {translations.DELETE}
                                </Button> */}
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    providers: state.providers,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { deleteProvider, fetchProvider, updateProvider })(
    ProviderView
  )
);
