import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver'
import { Grid, Button } from '@material-ui/core';
import { Add, CloudDownload } from '@material-ui/icons';
import { ToolbarComponent } from './../../../components/SharedComponents/Toolbar';
import { FileSelection } from '../../../components/SharedComponents/FileSelection';
import { ClaimTable  } from './../Tables';
import { Claims, ConfirmDialogComponent } from './../Dialogs';
import { SuccessDialog, ErrorDialog, SimpleConfirmDialog } from '../../../components/SharedComponents/Dialogs';
import { Filters } from './../Filters';
import {
    fetchClaims, createClaim,
    updateClaim, fetchParticipants,
    fetchCreditors, createPayment, deleteClaim, fetchClaimDetail, createClaimDetail,
    updateClaimDetail
} from './../../../utils/axios';
import LoadingDialog from '../../../components/SharedComponents/LoadingScreen';
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';

import axios from 'axios';
import './../index.css';
import { optionCSS } from 'react-select/lib/components/Option';

function ClaimView(props) {
    const limitRequest = { limit: 100 };
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ name: '', data: null });
    const [simpleDialog, setSimpleDialog] = useState({ name: '', data: null, callback: null });
    const [clients, setClients] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [claims, setClaims] = useState([]);
    const [assignees, setAssignees] = useState([]);
    const [assignee, setAssignee] = useState('');
    const [errorFields, setErrorFields] = useState({}); // the error field obj (to tell the field if there's an error)
    const [error, setError] = useState(false); // general error variable. Show if there's a field with an error.

    const nonBillingclaimDetailsStatus = [
        { name: 'Atrasado', value: 'delayed' },
        { name: 'Reasignado', value: 'reassigned' },
        { name: 'En proceso', value: "in_process" },
        { name: 'Cerrado', value: 'closed' },
        { name: 'Cancelado', value: 'canceled' },
    ]

    const billingClaimDetailsStatus = [
        { name: 'Localizado', value: 'located' },
        { name: 'Contactado', value: 'contacted' },
        { name: 'Entregado', value: 'delivered' },
        { name: 'En proceso', value: "in_process" },
        { name: 'Cerrado', value: 'closed' },
        
    ]
    
    const status = [
        { name: 'Cancelado', value: 'canceled' },
        { name: 'Cerrado', value: 'closed' },
        { name: 'Activo', value: 'active' },
        { name: 'Atrasado', value: 'delayed' },
    ]

    const typeOfCases = [
        { name: 'General', value: 'general' },
        { name: 'Especifico', value: 'specific' },
    ]

    const typeOfActions = [
        { name: 'Interno', value: 'internal' },
        { name: 'Externo', value: 'external' },
    ]

    const departments = [
        { name: 'Cobros', value: 'billing' },
        { name: 'N/A', value: 'none' },
        { name: 'Contabilidad', value: 'accounting' },
        { name: 'Auditoria', value: 'audit' },
        { name: 'Mensajeria', value: 'courier' },
        { name: 'Tecnologia', value: 'technology' },
        { name: 'Cumplimiento', value: 'compliance' },
        { name: 'Negocios', value: 'business' },
    ]

    const defaultFieldsValue = {
        status: null,
        date: "",
        department: null,
        comment: "",
        time: "",
        typeOfAction: null,
        uniqueId: "",
        dateOfCreation: "",
    }

    const handleToggleDialog = (dialog, data) => {
        const selectedDialog = dialog ? dialog : '';
        const dialogData = (data && (data.uniqueId || data.id)) ? data : null;
        const obj = { name: selectedDialog, data: dialogData };
        setDialog({ ...obj });
    }

    const handleToggleSimpleDialog = (dialog, msg, data) => {
        const obj = { name: dialog ? dialog : '', msg: msg, data: data };
        setSimpleDialog({ ...obj });
    }

    const getClaims = (data, unique_id) => {
        setLoading(true);
        const endpoint = fetchClaims({ ...data, token: props.user.token });
        endpoint.then(response => {
            let cleanData = [];
            cleanData = response.data.results ? response.data.results: response.data;
            // cleanData?null:
            if(cleanData) {
                cleanData = cleanData.map(item => {
                    if(item.clientDetail) {
                        item.clientDetail.name = `${item.clientDetail.name} | ${item.clientDetail.identificationNumber}`;
                    }
                    // item.clientDetail?item.clientDetail.name=`${item.clientDetail.name} | ${item.clientDetail.identificationNumber}` : null;
                    return {
                        client:item.clientDetail? item.clientDetail.name: "N/A",
                        clientDetail: item.clientDetail,
                        status: item.status,
                        description: item.description,
                        typeOfCase: item.typeOfCase,
                        status: item.status,
                        comments: item.comments,
                        dueDate: item.dueDate,
                        dateOfCreation: item.dateOfCreation,
                        createdBy: item.createdBy,
                        department: item.department,
                        details: item.details===undefined?defaultFieldsValue:item.details,
                        serializedDetails: item.serializedDetails===undefined?defaultFieldsValue:item.serializedDetails,
                        uniqueId: item.uniqueId,
                        caseNumber: item.referenceCode,
                        name: item.referenceCode  + ": " + departments.find(department => department.value === item.department).name,
                        claimFiles: item.claimFiles,
                    }
                })
            }
            cleanData.sort(function(a,b){
                return  new Date(a.dueDate) - new Date(b.dueDate);
            });
            cleanData.sort((x, y) => x.status === 'canceled' ? 1 : -1 );
            setClaims(cleanData);
            if(unique_id) {
                handleToggleDialog('claim-modify', cleanData.find(claim => claim.uniqueId === unique_id));
            }
            // unique_id?handleToggleDialog('claim-modify', cleanData.find(claim => claim.uniqueId === unique_id)):null;
            setLoading(false);
        }).catch(errors => {
            setLoading(false);
            console.error(errors);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const formatDate = (dt, f) => {
        let date = new Date(dt);
        let year = date.getFullYear().toString().padStart(2, '0');
        let month = date.getMonth().toString().padStart(2, '0');
        let day = date.getDate() - 1;
        day = day.toString().padStart(2, '0');

        let hour = date.getHours().toString().padStart(2, '0');
        let minute = date.getMinutes().toString().padStart(2, '0');
        let second = date.getSeconds().toString().padStart(2, '0');
        let millisecond = date.getMilliseconds().toString().padStart(3, '0');
        let response = f?`${year}-${month}-${day} ${hour}:${minute}`:`${year}-${month}-${day}`;
        return response;
    }

    const createUpdateClaim = (action, data) => {
        setLoading(true);
        const endpoint = action === 'update' ? updateClaim : createClaim;
        endpoint(props.user.token, data).then(response => {
            if(props.updateCount) {
                props.updateCount();
            }
            // props.updateCount?props.updateCount():null;
            getClaims(selectedFilters, response.data.uniqueId);
        }).catch(errors => {
            setLoading(false);
            console.error(errors);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const createUpdateClaimDetail = (action, data, uniqueIdsDetailParent, setCreatedRow, callback) => {
        setLoading(true);
        const update = action === 'update' ? true : false;
        const endpoint = update ? updateClaimDetail : createClaimDetail;
        endpoint(props.user.token, data).then(response => {
            const uniqueId = response.data.uniqueId;
            setCreatedRow(response.data || response);
            // callback(response.data);
            update?getClaims(selectedFilters):updateClaim(props.user.token, {
                uniqueId: uniqueIdsDetailParent.uniqueId,
                details: [...uniqueIdsDetailParent.uniqueIds, uniqueId],
            }
            ).then(response => {
                getClaims(selectedFilters);
                setLoading(false);
                handleToggleSimpleDialog('success-dialog');
            }).catch(errors => {
                setLoading(false);
                console.error(errors)
                errorHandling(errors, handleToggleSimpleDialog);
            });
            setLoading(false);
        }).catch(errors => {
            setLoading(false);
            console.error(errors)
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const deleteSingleClaim = (claim) => {
        setLoading(true);
        const endpoint = updateClaim(props.user.token, {status: 'canceled', uniqueId: claim.uniqueId});
        endpoint.then(() => {
            getClaims(selectedFilters);
            setLoading(false);
            handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const getClients = () => {
        setLoading(true);
        const endpoint = fetchParticipants({ token: props.user.token });
        endpoint.then(response => {
            const data = response.data.map(item => {
                return {
                    name: item.fullName,
                    fullName: item.fullName + ": " + item.identificationNumber,
                    uniqueId: item.uniqueId,
                }
            });
            setClients(data);
            setLoading(false);
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const applyFilters = (fields) => {
        let data = fields;
        if (Object.keys(fields).length === 0) {
            data = limitRequest;
        }
        getClaims(data);
        setSelectedFilters({ ...data });
    }

    useEffect(() => {
        if (props.client) {
            applyFilters({clientUniqueId: props.client.uniqueId})
        }else if(props.dialog) {
            handleToggleDialog(props.dialog.name);
        }
        else{
            getClaims(limitRequest);
        }
    }, []);

    return (
        <div style={{ padding: '20px 20px 10% 20px' }}>
            <LoadingDialog open={loading} />
                <ToolbarComponent
                    typeVariant="h6"
                    typeClass="title-text"
                    toolbarTitle="Gestión de reclamaciones"
                    btnVariant="contained"
                    toolbarActions={
                        <div>
                            <Button
                                variant="contained"
                                className={"action-method-btn"}
                                size="small"
                                disableElevation
                                onClick={handleToggleDialog.bind(this, 'claims')}
                            >
                                <Add style={{ fontSize: 20, color: '#fff', marginRight: '.2rem' }} />
                                Crear un reclamo
                            </Button>
                        </div>
                    }
                />
            <Filters
                applyFilters={applyFilters}
                listData={{
                    status: status,
                    departments: departments,
                    typeOfCases: typeOfCases,
                }}
                selectedFilters={{...selectedFilters, client: {
                    uniqueId: props.client?props.client.uniqueId:null,
                    name: props.client?props.client.fullName+' | '+props.client.identificationNumber:null,
                    identificationNumber: props.client?props.client.identificationNumber:null,
                }}}
                handleToggleSimpleDialog={handleToggleSimpleDialog}
            />
            <ClaimTable
                data={claims}
                listData={{}}
                handleToggleDialog={handleToggleDialog}
                handleToggleSimpleDialog={handleToggleSimpleDialog}
                handleDelete={deleteSingleClaim}
                status={status}
                departments={departments}
                typeOfCases={typeOfCases}
                modify={true}
                setLoading={setLoading}
            />
            {
                dialog.name === 'claims' ?
                    <Claims
                        open={true}
                        data={dialog.data}
                        listData={{
                            claims: claims,
                            status: status,
                            departments: departments,
                            typeOfCases: typeOfCases,
                        }}
                        token={props.user.token}
                        setLoading={setLoading}
                        handleToggleDialog={handleToggleDialog}
                        handleClaim={createUpdateClaim}
                        handleSimpleDialog={handleToggleSimpleDialog}
                    /> : null
            }
            {
                ['claim-modify'].includes(dialog.name) && dialog.data ?
                    <Claims
                        open={true}
                        setLoading={setLoading}
                        handleSimpleDialog={handleToggleSimpleDialog}
                        handleToggleDialog={handleToggleDialog}
                        handleClaim={createUpdateClaim}
                        getClaim={getClaims}
                        data={dialog.data}
                        token={props.user.token}
                        modify={dialog.name === 'claim-modify'}
                        row={dialog.data}
                        listData={{
                            status: status,
                            claims: claims,
                            departments: departments,
                            typeOfCases: typeOfCases,
                            typeOfActions: typeOfActions,
                            nonBillingclaimDetailsStatus:nonBillingclaimDetailsStatus,
                            billingClaimDetailsStatus:billingClaimDetailsStatus
                        }}
                        handleClaimDetail={createUpdateClaimDetail}
                    /> : null
            }
            {
                simpleDialog.name === 'confirm-dialog' ?
                    <ConfirmDialogComponent
                        open={true}
                        data={simpleDialog}
                        handleToggle={handleToggleSimpleDialog}
                    /> : null
            }
            {
                simpleDialog.name === 'simple-dialog' ?
                    <SimpleConfirmDialog
                        open={true}
                        handleToggle={handleToggleSimpleDialog}
                        data={simpleDialog.msg}
                    /> : null
            }
            {
                simpleDialog.name === 'success-dialog' ?
                    <SuccessDialog
                        open={true}
                        handleToggle={handleToggleSimpleDialog}
                    /> : null
            }
            {
                simpleDialog.name === 'error-dialog' ?
                    <ErrorDialog
                        open={true}
                        handleToggle={handleToggleSimpleDialog}
                        data={simpleDialog.msg}
                    /> : null
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}
export default connect(mapStateToProps)(ClaimView);