import React, {useState, useEffect} from 'react';
import { Typography, Button, Snackbar, Grid, IconButton} from '@material-ui/core';
import { Sync } from '@material-ui/icons';
import { ChargingToolbar } from './../Toolbar';
import { DataLayer } from './../DataLayers';
import {
    ConfirmDialog, MassAssignmentDialog, HistoryDialog,
    ExpiryObjectDialog, BotConfigDialog, FinancialProfileDialog,
    ManageResponsibleDialog, SingleAssingmentDialog, CustomOrderDialog, 
    InfrastructureDialog, ConfirmDialogComponent
} from './../Dialogs';
import { AutocompleteField } from './../../../components/SharedComponents/Fields';
import { SuccessDialog, ErrorDialog} from '../../../components/SharedComponents/Dialogs';
import LoadingDialog from '../../../components/SharedComponents/LoadingScreen';
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';
import { fetchPaymentSettings, fetchExpiry, executeBot, fetchSpecificOrders, stopBot, fetchInfrastructures} from './../../../utils/axios';
import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import axios from 'axios';
import './../index.css';

const expiryObjectData =
[{
    uniqueId: 1,
    responsible_id: 1,
    responsible_name: 'Edward Gonzalez',
    work_id: 1,
    work_name: 'RDE',
    bank_id: 1,
    bank_name: 'BHD',
    client_bank_user: 'g0101',
    client_bank_password: 'gg',
    client_bank_secret_questions: [{
        id: 1,
        question: '¿color favorito?',
        answer: 'gris'
    }],
    order: 1,
    rating: 'A',
    expiry_date: '11/11/2020',
    client_id: 1,
    client_name: 'Fernando De La Rosa',
    client_from_account_info: [
        {id: 1, account:'9600253883', currency:'DOP', client_account_type: 'Ahorros/Corriente'},
        {id: 2, account:'9690254817', currency:'USD', client_account_type: 'Empresarial'},
        {id: 3, account:'4892649861', currency:'CAD', client_account_type: 'Empresarial Canadiense'},
        {id: 4, account:'8280248084', currency:'DOP', client_account_type: 'Ahorros/Corriente'},
        {id: 5, account:'2949294904', currency:'DOP', client_account_type: 'Ahorros/Corriente'},
        {id: 6, account:'9589583952', currency:'DOP', client_account_type: 'Ahorros/Corriente'},
    ],
    no_check: false,
    no_charge: true,
    overdue_amount: 17000.12,
    charged_amount: 14000.46,
    account_balance: 20000.07,
    balance_left: 3000.89,
    status: 'Programado',
    control_config: {
        alert_check_amount: 2000.40,
        alert_charge_amount: 30000.15,
        bot_action: "no_check",
    },
    client_disbursement: [{
        id: 1,
        status: 'completado',
        amount_charged: 7000,
        name: 'disbursement1',
        total: 10000,
    },
    {
        status: 'completado',
        amount_charged: 7000,
        name: 'disbursement2',
        total: "7000"
    }],
    financial_composition: [
        {
            id: 1,
            principal: 'Principal 1!',
            dischargement: 'Desembolso 1!',
            loan_type: 'Tipo de préstamo 1',
            interest: 'Interés 1',
            commission: 'Comisión 1',
            insurance: 'Seguro 1',
            total_due: 'Total exigible 1',
        }
    ]
}];

const rowsTransactionHistory = [
    { id: 1, state:'charged', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in nulla vitae purus euismod venenatis a sit amet tellus. Morbi euismod augue eu erat interdum tristique. Phasellus quis scelerisque justo, a porta diam. Phasellus sapien est, feugiat nam.', date: '10/10/2020'},
    { id: 2, state:'complete', message: 'Hermencio Rajacoco', date: '20/09/2010'},
    { id: 3, state:'error', message: 'Gogiga Gagagigo', date: '25/06/1999'},
    { id: 4, state:'paid', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in nulla vitae purus euismod venenatis a sit amet tellus. Morbi euismod augue eu erat interdum tristique. Phasellus quis scelerisque justo, a porta diam. Phasellus sapien est, feugiat nam.', date: '10/10/2020'},
    { id: 5, state:'paid', message: 'Hermencio Rajacoco', date: '20/09/2010'},
    { id: 6, state:'complete', message: 'Gogiga Gagagigo', date: '25/06/1999'},
    { id: 7, state:'error', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in nulla vitae purus euismod venenatis a sit amet tellus. Morbi euismod augue eu erat interdum tristique. Phasellus quis scelerisque justo, a porta diam. Phasellus sapien est, feugiat nam.', date: '10/10/2020'},
    { id: 8, state:'paid', message: 'Hermencio Rajacoco', date: '20/09/2010'},
    { id: 9, state:'charged', message: 'Gogiga Gagagigo', date: '25/06/1999'},
    { id: 10, state:'paid', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in nulla vitae purus euismod venenatis a sit amet tellus. Morbi euismod augue eu erat interdum tristique. Phasellus quis scelerisque justo, a porta diam. Phasellus sapien est, feugiat nam.', date: '10/10/2020'},
    { id: 11, state:'error', message: 'Hermencio Rajacoco', date: '20/09/2010'},
    { id: 12, state:'charged', message: 'Gogiga Gagagigo', date: '25/06/1999'},
]

const botModeConfig =[
    {text: 'REVISAR', value: 'revision'},
    {text: 'COBRAR', value: 'charge'},
    {text: 'REVISAR Y COBRAR', value: 'revision and charge'},
    {text: 'REVISIÓN FIJA', value: 'fixed revision'},
];

function ChargeView(props){

    const [loading, setLoading] = useState(false);
    // const [speechObj, setSpeechObj] = useState({ obj: {}, allowSpeak: false });
    const [dialog, setDialog] = useState({name: null, data: null});
    const [secondaryDialog, setSecondaryDialog] = useState({name: null, data: null});
    const [confirmDialog, setConfirmDialog] = useState({name: null, data: null, callback: null});
    const [snackbar, setSnackbar] = useState({ open: false, type: '', msg: '' });
    const [orderExpiries, setOrderExpiries] = useState([]);
    const [customOrders, setCustomOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [currentSelectedOrder, setCurrentSelectedOrder] = useState({});
    const [botRunning, setBotRunning] = useState({state: false, runMode: ''});
    const [botRefreshInterval, setBotRefreshInterval] = useState(null);
    const [botQuantity ,setBotQuantity] = useState(0);
    let refreshInterval = null;
    const [infrastructures, setInfrastructures] = useState([]);
    const [simpleDialog, setSimpleDialog] = useState({ name: '', data: null, callback: null });

    const handleToggleSimpleDialog = (dialog, msg, data) => {
        const obj = { name: dialog ? dialog : '', msg: msg, data: data };
        setSimpleDialog({ ...obj });
    }

    // get infrastructure method
    const getInfrastructure = (tableData) => {
        const workInst = tableData.map(item => item.workRegintry).join('|')
        const financialInst = tableData.map(item => item.financialRegintry).join('|')

        const endpoint = fetchInfrastructures(props.user.token, financialInst, workInst);
        endpoint.then(response => {
            const data = response.data.map(item => {
                return {
                    ...item,
                    fullName: `${item.workInstitutionName} - ${item.financialInstitutionName}`,
                }
            })
            setInfrastructures(data)
        })
        .catch(errors => {
            setLoading(false);
            errorHandling(errors, handleConfirm());
        });
    }

    // convert all these functions into components to re-use in order module.
    const setExpiriesObj = (expiries) => {
        return expiries.map((item) => {
            const financialObj = item.clientDetail.financialProfileObj;
            return {
                uniqueId: item.uniqueId,
                order: item.order ? item.order : 0,
                responsible_name: financialObj ? financialObj.assignee : '--',
                financialRegintry: item.clientDetail.financialInstitutionRegistry,
                work: item.clientDetail.workProfileInstitution,
                workRegintry: item.clientDetail.workInstitutionRegistry,
                bank: item.clientDetail.financialProfileInstitution,
                bank_balance: item.accountBalance || '$0.00',
                client: item.clientDetail.fullName,
                client_uniqueId: item.clientDetail.uniqueId,
                client_document: item.clientDetail.identificationNumber,
                client_document_type: item.clientDetail.identificationType,
                assignee: financialObj ? financialObj.assignee : '--',
                due_date: item.expirationDate,
                rating: financialObj ? financialObj.rating : '--',
                overdue_amount: item.amountReceivable,
                charged_amount: item.amountCharged,
                expiration_state: item.expirationStatus,
                remaining_amount: (item.amountReceivable - item.amountCharged),
                charge_config: null,
            }
        });
    }

    const setPaymentSettingObj = (setting) => {
        return {
            irregularAmount: setting.irregularAmount,
            clientAccounts: setting.chargingAccount,
            destinyAccounts: setting.amountPerAccount,
            matchPayroll: setting.matchPayroll,
            captcha: setting.manualFillCaptcha,
            controlAmountNotice: setting.noticeControlAmount,
            controlAmountCharge: setting.chargeControlAmount,
            expiration: setting.expiration,
            bank: setting.bank,
            order: setting.orderNumber,
            botAction: setting.doNotCharge ? 'no_charge' : setting.doNotCheck ? 'no_check' : '',
            checkChargingAccounts: setting.checkChargingAccounts,
            uniqueId: setting.uniqueId,
        }
    }

    const getMultiExpiries = (expirations, formattedSetting, specificOrder, action) => {
        setLoading(true);
        const endpoints = expirations.map(item => fetchExpiry(props.user.token, item));
        axios.all(endpoints).then(axios.spread((...responses) => {
            const data = responses.map(item => item.data)
                .filter(item => item.clientDetail.financialProfileObj && item.clientDetail.workProfileInstitution)
            const cleanData = setExpiriesObj(data);
            let addPaymentSetting = cleanData.map(item => {
                return {
                    ...item,
                    charge_config: formattedSetting.find(cc => cc.expiration === item.uniqueId),
                    order: formattedSetting.find(cc => cc.expiration === item.uniqueId).order,
                    bank: formattedSetting.find(cc => cc.expiration === item.uniqueId).bank,
                    specificOrder: specificOrder,
                }
            }).sort((a,b) => (a.order > b.order) ? 1 : -1);

            if(action === 'refresh') {
                addPaymentSetting = addPaymentSetting.filter(item => item.remaining_amount > 0);
            }

            setOrderExpiries(addPaymentSetting);
            getInfrastructure(addPaymentSetting);

            setLoading(false);
        })).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleConfirmDialog);
        });
    }

    const getAllPaymentSettings = (data, specificOrder, options) => {
        const params = {
            token: props.user.token,
            specificOrder,
        }
        setLoading(true);
        const endpoints = fetchPaymentSettings(params);
        endpoints.then(response => {
            const formattedSetting = response.data.map(item => {
                return setPaymentSettingObj(item)
            });
            const expirations = formattedSetting.map(item => item.expiration);
            getMultiExpiries(expirations, formattedSetting, specificOrder, (options && options.action));
            setLoading(false);
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleConfirmDialog);
        });
    }

    const getSpecificOrders = () => {
        setLoading(true);
        const endpoint = fetchSpecificOrders(props.user.token);
        endpoint.then(response => {
            setCustomOrders(response.data);
            setLoading(false);
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleConfirmDialog);
        });
    }

    const handleCustomOrderChange = (event, value) => {
        setSelectedOrder({...value});
    }

    const loadOrder = () => {
        if(selectedOrder.uniqueId) {
            getAllPaymentSettings(null, selectedOrder.uniqueId);
            setCurrentSelectedOrder(selectedOrder);
        }else {
            handleToggleConfirmDialog('simple-dialog', 'Por favor seleccione un orden personalizado.');
        }
    }

    const refreshOrderInfo = (action, quantity) => {

        if(action === 'refresh' && quantity === 1) {
            refreshInterval = setInterval(() => {
                getAllPaymentSettings(null, currentSelectedOrder.uniqueId, {action: 'refresh'});
            }, 15000);
            setBotRefreshInterval(refreshInterval);
        }else if(action === 'stop' && quantity < 1) {
            clearInterval(botRefreshInterval);
            getAllPaymentSettings(null, currentSelectedOrder.uniqueId);
        }
    }

    const handleToggleDialog = (dialog, data, event) => {
        if(event){event.stopPropagation();}
        let dialogData = (data && (data.uniqueId || data.id)) ? data : null;
        setDialog({...{name: dialog || null, data: dialogData}});
    }

    const handleSecondaryDialog = (dialog, data) => {
        const dialogData = (data && (data.uniqueId || data.id)) ? data : null;
        setSecondaryDialog({...{name: dialog || null, data: dialogData}});
        handleToggleConfirmDialog(undefined);
    }

    const handleToggleConfirmDialog = (dialog, data, confirmCallback) => {
        setConfirmDialog({...{name: dialog, data: data, callback: confirmCallback}});
    }

    function handleConfirm(data) {
        //this should change accordingly, perhaps with a case
        // go to the desired route, etc.
        handleToggleConfirmDialog();
    }

    const toggleSnackOpen = (action) => {
        //just testing the snackbar
        let msg='';
        let type='';
        if(action === 'execute') {
            msg = 'Se ha iniciado el bot!';
            type = 'info';
        }else if(action === 'stop') {
            msg = 'Se ha detenido el bot!';
            type = 'error';
        }

        setSnackbar(
            {
                open: !snackbar.open,
                type: action ? type : snackbar.type,
                msg: action ? msg : snackbar.msg,
            }
        );
    }

    // EXECUTE THE BOT
    const executeBotChargeAction = (data, action, group, botInitConfig, responsible) => {
        if(group === 'login' && action === 'execute') {
            const options = {
                'order': data.specificOrder,
                'sleep_time': botInitConfig.sleep_time.toString(),
                'mode': botInitConfig.mode,
                'financial_inst': data.bank,
                'work_inst': data.work,
                'name': botInitConfig.infrastructure,
                'expiration': data.uniqueId,
                'infrastructure': botInitConfig.infrastructure
            }
            setLoading(true);
            executeBot(props.user.token, options).then(response => {
                setLoading(false);
            }).catch(errors => {
                setLoading(false);
                errorHandling(errors, handleToggleConfirmDialog);
            });
            return
        }else if(group === 'login' && action === 'stop') {
            const options = {
                'order': data.specificOrder,
                'financial_inst': data.bank,
                'work_inst': data.work,
                'name': data.assignee,
                'expiration': data.uniqueId,
            }
            setLoading(true);
            executeBot(props.user.token, options).then(response => {
                setLoading(false);
            }).catch(errors => {
                setLoading(false);
                errorHandling(errors, handleToggleConfirmDialog);
            });
            return
        }

        let works;
        let banks;
        switch(group) {
            case 'all':
                works = [...new Set(data.map(item => item.work))];
                banks = [...new Set(data.map(item => item.bank))];
                break;
            case 'responsible':
                works = data.works.map(item => item.work);
                banks = data.works.reduce((acc, val) => {acc = val; return acc}).banks.map(item=>item.bank);
                break;
            case 'work':
                works = [data.work];
                banks = data.banks.map(item => item.bank);
                break;
            case 'bank':
                works = [data.work];
                banks = [data.bank];
                break;
            default:
                banks = [];
                works = []
                break;
        }

        if(action === 'execute') {
            const selectedMode = botModeConfig.find(item => item.value === botInitConfig.mode);
            const options = {
                'order': currentSelectedOrder.uniqueId,
                'sleep_time': botInitConfig.sleep_time.toString(),
                'mode': botInitConfig.mode,
                'financial_inst': banks.join('|'),
                'work_inst': works.join('|'),
                'name': responsible,
                'infrastructure': botInitConfig.infrastructure
            }
            setLoading(true);
            executeBot(props.user.token, options).then(response => {
                setLoading(false);

                // setBotQuantity(botQuantity+1);
                // refreshOrderInfo('refresh', botQuantity+1);
                // console.log(botQuantity+1);

                const url = response.data.botUrl;
                if(url) {
                    window.open(url);
                }
            }).catch(errors => {
                setLoading(false);
                errorHandling(errors, handleToggleConfirmDialog);
            });

            if(selectedMode) {
                refreshOrderInfo('refresh', botQuantity+1);
                setBotQuantity(botQuantity+1);

                setBotRunning({...{state: true, runMode: selectedMode.text}});
            }

        }else {
            // detenga el bot entonces.
            setBotRunning({...{state: false, runMode: ''}});
            stopBot(props.user.token, {'order': currentSelectedOrder.uniqueId, 'financial_inst': banks.join('|'), 'work_inst': works.join('|'), 'name': responsible});

            refreshOrderInfo('stop', botQuantity-1);
            setBotQuantity(botQuantity-1);
        }

        // if(data.id) {
        //     toggleSnackOpen(action);
        // }
    }

    const reloadOrdersEl = () => {
        return(
            <IconButton
                onClick={getSpecificOrders}
                style={{padding:4}}
            >
                <Sync style={{fontSize:18, color:'rgba(0, 0, 0, 0.54)'}} />
            </IconButton>
        )
    }

    // const responsiveVoice = () => {
    //     if(speechObj.allowSpeak) {
    //         let msg = new SpeechSynthesisUtterance();
    //         let voices = window.speechSynthesis.getVoices();
    //         let selected = voices.find(item => item.name === 'Paulina');
    //         msg.voice = selected;
    //         msg.lang = "es"
    //         msg.text = "El cobro ha sido ejecutado exitosamente";
    //         speechSynthesis.speak(msg);
    //     }
    // }

    useEffect(() => {
        getSpecificOrders();
    }, []);

    return (
        <div style={{padding:'20px 20px 10% 20px'}}>
            <LoadingDialog open={loading}/>
            <ChargingToolbar
                typeVariant="h6"
                typeClass="title-text"
                toolbarTitle="Interfaz de Cobros"
                btnVariant="contained"
                btnClass="btn-style-white"
                btnText="Historial transaccional"
                handleToggleDialog={handleToggleDialog}
                handleSecondaryDialog={handleSecondaryDialog}
            />

            {/* <Button onClick={this.responsiveVoice}>Click me to speak!</Button> */}
            <div style={{padding: '1%', border:'1px solid #EEEEEE', borderTop:'none'}}>

                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={3000}
                    onClose={toggleSnackOpen}
                    anchorOrigin={{ vertical:'top', horizontal:'center' }}
                    key={'snackbar-message'}
                >
                    <MuiAlert
                        variant="filled"
                        onClose={toggleSnackOpen}
                        severity={snackbar.type ? 'info' : snackbar.type}
                    >
                    {snackbar.msg}
                    </MuiAlert>
                </Snackbar>

                {/* <ViewFilters
                    applyFilters={loadOrder}
                /> */}
                <Grid container direciton="row" alignItems="flex-end" spacing={2} style={{margin:'1rem 0 0 4px'}}>
                    <AutocompleteField
                        style={{width:350}}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Ordenes personalizados"
                        fieldID="filter-field-custom-order"
                        fieldInnerLabel="Orden"
                        fieldVariant="outlined"
                        handleChange={handleCustomOrderChange}
                        options={customOrders}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        multiple={false}
                        disabled={Boolean(botQuantity)}
                        insideElement={reloadOrdersEl()}
                    />
                    <Button
                        onClick={loadOrder}
                        variant="contained"
                        className={Boolean(botQuantity) ? "action-method-btn disabled" : "action-method-btn save"}
                        size="small"
                        disableElevation
                        style={{marginBottom:12, height:32}}
                        disabled={Boolean(botQuantity)}
                    >
                        cargar orden
                    </Button>
                </Grid>

                <DataLayer
                    handleToggleDialog={handleToggleDialog}
                    handleToogleConfirm={handleToggleConfirmDialog}
                    data={orderExpiries}
                    infrastructures={infrastructures}
                    specificOrder={currentSelectedOrder.uniqueId}
                    executeBotChargeAction={executeBotChargeAction}
                    botRunning={botRunning.state}
                    botRunningMode={botRunning.runMode}
                    botQuantity={botQuantity}
                    token={props.user.token}
                    setLoading={setLoading}
                />

                {
                    dialog.name === "assignment" &&
                    <MassAssignmentDialog
                        open={true}
                        handleToggleDialog={handleToggleDialog}
                        handleConfirm={handleToggleConfirmDialog}
                        token={props.user.token}
                        setLoading={setLoading}
                    />
                }
                {
                    dialog.name === "single-assignment" &&
                    <SingleAssingmentDialog
                        open={true}
                        handleToggleDialog={handleToggleDialog}
                        handleConfirm={handleToggleConfirmDialog}
                        token={props.user.token}
                    />
                }
                {
                    dialog.name === "history" &&
                    <HistoryDialog
                        open={true}
                        handleToggleDialog={handleToggleDialog}
                        handleConfirm={handleToggleConfirmDialog}
                        data={dialog.data}
                        token={props.user.token}
                    />
                }
                {
                    dialog.name === "bot-config" &&
                    <BotConfigDialog
                        open={true}
                        handleToggleDialog={handleToggleDialog}
                        handleConfirm={handleToggleConfirmDialog}
                        data={dialog.data}
                        token={props.user.token}
                        setLoading={setLoading}
                    />
                }
                {/* {
                    dialog.name === "financial-composition" &&
                    <FinancialCompositionDialog
                        open={true}
                        handleToggleDialog={handleToggleDialog}
                        handleConfirm={handleToggleConfirmDialog}
                        data={expiryObjectData[0]}
                        token={props.user.token}
                    />
                } */}
                {
                    dialog.name === "financial-profile" &&
                    <FinancialProfileDialog
                        open={true}
                        handleToggleDialog={handleToggleDialog}
                        handleConfirm={handleToggleConfirmDialog}
                        data={dialog.data.client_uniqueId}
                        rowData={dialog.data}
                    />
                }
                {
                    dialog.name === "manage-responsibles" &&
                    <ManageResponsibleDialog
                        open={true}
                        handleToggleDialog={handleToggleDialog}
                        handleConfirm={handleToggleConfirmDialog}
                        handleToggleSimpleDialog={handleToggleSimpleDialog}
                        token={props.user.token}
                        setLoading={setLoading}
                    />
                }
                {
                    secondaryDialog.name === "manage-orders" &&
                    <CustomOrderDialog
                        open={true}
                        handleToggleDialog={handleToggleDialog}
                        handleSecondaryDialog={handleSecondaryDialog}
                        handleConfirm={handleToggleConfirmDialog}
                        token={props.user.token}
                        setLoading={setLoading}
                    />
                }

                {
                    secondaryDialog.name === "expiry-obj" &&
                    <ExpiryObjectDialog
                        open={true}
                        data={expiryObjectData}
                        handleSecondaryDialog={handleSecondaryDialog}
                        handleToggleDialog={handleToggleDialog}
                    />
                }

                {
                    secondaryDialog.name === "infrastructure" &&
                    <InfrastructureDialog
                        open={true}
                        handleSecondaryDialog={handleSecondaryDialog}
                        handleConfirm={handleToggleConfirmDialog}
                        token={props.user.token}
                        setLoading={setLoading}
                    />

                }
                {
                    simpleDialog.name === 'confirm-dialog' ?
                    <ConfirmDialogComponent
                        open={true}
                        data={simpleDialog}
                        handleToggle={handleToggleSimpleDialog}
                    /> : null
                }
                {
                    confirmDialog.name === "leave-view" &&
                    <ConfirmDialog
                        open={true}
                        handleToggle={handleToggleConfirmDialog}
                        dialogTitle="Confirmación"
                        dialogContent={
                            <Typography variant="subtitle1" className="field-label">
                                ¿Está seguro que desea salir de la interfaz
                                cobros y dirigirse a la de clientes?
                            </Typography>
                        }
                        dialogConfirmText="Continuar"
                        dialogCancelText="Cancelar"
                        handleConfirm={handleConfirm}
                    />
                }
                {
                    confirmDialog.name === "speech-unsupported" &&
                    <ConfirmDialog
                        open={true}
                        handleToggle={handleToggleConfirmDialog}
                        dialogTitle="¡Aviso!"
                        dialogContent={
                            <Typography
                                variant="subtitle1"
                            >Su navegador no soporta texto a voz. <span role="img" aria-label="crying">😣</span></Typography>
                        }
                        dialogConfirmText="Aceptar"
                    />
                }
                {
                    confirmDialog.name === "confirm-dialog" &&
                    <ConfirmDialog
                        open={true}
                        dialogTypeClass="confirm-dialog"
                        handleToggle={handleToggleConfirmDialog}
                        dialogTitle="Aviso"
                        dialogContent={
                            <Typography variant="subtitle1"
                            >¿Está seguro que desea proceder?</Typography>
                        }
                        dialogConfirmText="Aceptar"
                        dialogCancelText="Cancelar"
                        handleConfirm={confirmDialog.callback}
                    />
                }
                {
                    confirmDialog.name === "simple-dialog" &&
                    <ConfirmDialog
                        open={true}
                        dialogTypeClass="confirm-dialog"
                        handleToggle={handleToggleConfirmDialog}
                        dialogTitle="Aviso"
                        dialogContent={
                            <Typography variant="subtitle1"
                            >
                                {confirmDialog.data}
                            </Typography>
                        }
                        dialogConfirmText="Aceptar"
                        handleConfirm={handleToggleConfirmDialog.bind(this,undefined)}
                    />
                }
                {
                    confirmDialog.name === "confirm-leave" &&
                    <ConfirmDialog
                        open={true}
                        dialogTypeClass="confirm-dialog"
                        handleToggle={handleToggleConfirmDialog}
                        dialogTitle="Aviso"
                        dialogContent={
                            <Typography variant="subtitle1"
                            >
                                {confirmDialog.data}
                            </Typography>
                        }
                        dialogConfirmText="Aceptar"
                        dialogCancelText="Cancelar"
                        handleConfirm={confirmDialog.callback}
                    />
                }
                {
                    confirmDialog.name === "success-dialog" &&
                    <SuccessDialog
                        open={true}
                        handleToggle={handleToggleConfirmDialog}
                    />
                }
                {
                    confirmDialog.name === "error-dialog" &&
                    <ErrorDialog
                        open={true}
                        handleToggle={handleToggleConfirmDialog}
                        data={confirmDialog.data}
                    />
                }
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
      user: state.user,
    };
}
export default connect(mapStateToProps)(ChargeView);