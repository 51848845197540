import { AxiosError } from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { deleteCheck, fetchCheck, updateCheck } from '../../../actions';
import AlertDialog from '../../../components/AlertDialog';
import Loading from '../../../components/Loading';
import accountNumberMaskInput from '../../../components/TextMaskInputComponents/accountNumberMaskInput';
import { catchError, resetError, titleCaseHandler, translations } from '../../../utils';
import {
    deleteCheck as deleteCH, fetchCheck as fetchCH, updateCheck as updateCH
} from '../../../utils/axios';
import ClientSelectInput from '../../Client/ClientSelectInput';

const styles:any = createStyles({
    attachmentIcon: {
        color: green[500],
        fontSize: 50,
        position: 'absolute',
        right: 25,
        top: 25,
        transform: 'rotate(90deg)'
    },
    checkboxLabel: {
        color: green[500],
        fontSize: 'medium'
    },
    controls: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    deleteButton: {
        backgroundColor: red[500],
        color: 'white'
    },
    formControl: {

        marginTop: 20,
        minWidth: 300,
        width: 300
    },
    greenButton: {
        backgroundColor: green[500],
        color: 'white'
    },
    input: {
        left: 40,
        position: 'absolute',
        width: 1
    },
    paper: {
        padding: 20
    },
    root: {
        height: '90vh',
        overflowY: 'auto',
        padding: 20,
    },
    textField: {
        display: 'block',
        marginTop: 20,
        width: '50%'
    }
});

interface ICheckViewProps {
    checks: ICheck[];
    checkId: string;
    classes: {
        attachmentIcon: string;
        checkboxLabel: string;
        controls: string;
        deleteButton: string;
        formControl: string;
        greenButton: string;
        input: string;
        paper: string;
        root: string;
        textField: string;
    };

    user: IUser;
    changeSection(sectionNumber: number): void;
    deleteCheck(uniqueId: string): IAction<ICheck[]>;
    fetchCheck(check: ICheck): IAction<ICheck[]>;
    updateCheck(check: ICheck): IAction<ICheck[]>;
}

interface ICheckViewState {
    [key: string]: string | string[] | number | boolean | Blob;

    name: string;
    nameError: string;

    amount: string;
    amountError: string;

    client: string[];
    clientError: string;

    expirationDate: string;
    expirationDateError: string;

    dialog: boolean;
    dialogTitle: string;
    errorMessage: string;
    loading: boolean;
    uniqueId: string

}
class CheckView extends React.Component<ICheckViewProps, ICheckViewState> {
    public state: ICheckViewState = {
        amount: '',
        amountError: '',
        client: [],
        clientError: '',
        dialog: false,
        dialogTitle: '',
        errorMessage: '',
        expirationDate: '',
        expirationDateError: '',
        loading: false,
        name: '',
        nameError: '',
        uniqueId: ''
    }
    constructor(props: ICheckViewProps) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleAddClient = this.handleAddClient.bind(this);
        this.handleDeleteClient = this.handleDeleteClient.bind(this);

    }
    public handleAddClient(id: string) {
        this.setState({ client: [id] });
    }
    public handleDeleteClient(id: string) {
        this.setState({ client: [] });
    }
    public componentDidMount() {

        this.setState({ loading: true });
        fetchCH(this.props.user.token, this.props.checkId).then(res => {

            const curCheck = res.data as ICheck;

            this.setState({
                amount: curCheck.amount || '',
                client: curCheck.client ? [curCheck.client] : [],
                expirationDate: curCheck.expirationDate || '',
                loading: false,
                name: curCheck.name || '',
                uniqueId: curCheck.uniqueId || ''

            });
        }).catch(err => {
            catchError(err, this)
        });
    }
    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                [key]: event.currentTarget.value,
                [key + 'Error']: ''
            });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({
                [key]: event.target.value,
                [key + 'Error']: ''
            });
        }
    }
    public handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        resetError(this);

        const check: ICheck = {
            amount: this.state.amount,
            client: (this.state.client[0] || ''),
            expirationDate: this.state.expirationDate,
            name: titleCaseHandler(this.state.name)
        };

        this.setState({ loading: true });

        updateCH(this.props.user.token, this.state.uniqueId, check).then(res => {
            this.setState({
                dialog: true,
                dialogTitle: translations.SUCCESS,
                errorMessage: `${translations.CHECK} ${translations.UPDATED}`,
                loading: false
            }, () => this.props.updateCheck(res.data as ICheck));
        }).catch((err: AxiosError) => {
            catchError(err, this);
        });
    }
    public handleDelete() {
        if (confirm(translations.ARE_YOU_SURE)) {

            this.setState({ loading: true });

            deleteCH(this.props.user.token, this.props.checkId).then(res => {
                this.props.changeSection(0);
                this.props.deleteCheck(this.props.checkId);
            }).catch((err: AxiosError) => {
                catchError(err, this)
            })
        }
    }
    public handleDialogClose() {
        this.setState({ dialog: false });
    }
    public render() {
        const { classes } = this.props;
        return (
            <form onSubmit={this.handleSubmit} className={classes.root}>
                <AlertDialog
                    open={this.state.dialog}
                    message={this.state.errorMessage}
                    title={this.state.dialogTitle}
                    handleClose={this.handleDialogClose}
                />
                <Paper className={classes.paper}>

                    <Typography variant="h3" style={{ display: 'inline' }}>{translations.CHECK}</Typography>
                    {this.state.loading ?
                        <Loading message={translations.LOADING} />
                        : <div>
                            <TextField
                                label={translations.NAME}
                                className={classes.textField}
                                value={titleCaseHandler(this.state.name)}
                                onChange={this.handleChange('name')}
                                fullWidth={true}
                                required={true}
                                error={this.state.nameError !== ''}
                                helperText={this.state.nameError}
                                InputLabelProps={{ style: styles.checkboxLabel }}
                            />
                            <TextField
                                label={translations.AMOUNT}
                                className={classes.textField}
                                value={this.state.amount}
                                onChange={this.handleChange('amount')}
                                fullWidth={true}
                                required={true}
                                error={this.state.amountError !== ''}
                                helperText={this.state.amountError}
                                InputProps={{
                                    inputComponent: accountNumberMaskInput
                                }}
                                InputLabelProps={{ 
                                    shrink: true,
                                    style: styles.checkboxLabel 
                                }}
                            />
                            <TextField
                                label={translations.EXPIRATION_DATE}
                                className={classes.textField}
                                value={this.state.expirationDate}
                                type="date"
                                onChange={this.handleChange('expirationDate')}
                                required={true}
                                error={this.state.expirationDateError !== ''}
                                helperText={this.state.expirationDateError}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.checkboxLabel
                                }}
                            />
                            <br />
                            <br />
                            <Typography variant="h6" className={classes.checkboxLabel}>{translations.CLIENT}</Typography>
                            <div style={{ width: '100%' }}>
                                <ClientSelectInput
                                    selectedClientsId={this.state.client}
                                    addClient={this.handleAddClient}
                                    deleteClient={this.handleDeleteClient}

                                />
                            </div>
                            <p style={{ color: 'red ' }}>{this.state.clientError}</p>

                            <div className={classes.controls}>
                                <Button variant="contained" onClick={this.handleDelete} className={classes.deleteButton}>
                                    <DeleteIcon />
                                    &nbsp;
                                    {translations.DELETE}
                                </Button>
                                <Button type="submit" variant="contained" className={classes.greenButton} style={{ marginLeft: 10 }}>
                                    <SaveIcon />
                                    &nbsp;
                                    {translations.SAVE}
                                </Button>
                            </div>
                        </div>
                    }
                </Paper>
            </form>
        );
    }
}

function mapStateToProps(state: IState) {
    return {
        checks: state.checks,
        user: state.user
    }
}
export default withStyles(styles)(
    connect(mapStateToProps, { deleteCheck, fetchCheck, updateCheck })(CheckView)
);
