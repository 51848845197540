import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";

import { createAutomaticDebitAuthorizationContract } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import { createAutomaticDebitAuthorizationContract as create } from "../../../utils/axios";
import LoanContractSelectInput from "../../LoanContract/LoanContractSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "50%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface IFormProps {
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    errorInput: string;
    formControl: string;
    greenButton: string;
    half: string;
    paper: string;
    root: string;
    textField: string;
  };

  user: IUser;
  handleSaveCallBack?(uid: string): void;
  createAutomaticDebitAuthorizationContract(
    automaticDebitAuthorizationContract: IAutomaticDebitAuthorizationContract
  ): IAction<IAutomaticDebitAuthorizationContract[]>;
  changeSection(sectionNumber: number): void;
}
interface IFormState {
  [key: string]: string | number | boolean | string[];

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;

  title: string;
  titleError: string;

  contractDate: string;
  contractDateError: string;

  loanContract: string[];
  loanContractError: string;
}
class Form extends React.Component<IFormProps, IFormState> {
  public state: IFormState = {
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    title: "",
    titleError: "",
    loanContract: [],
    loanContractError: "",
  };

  constructor(props: IFormProps) {
    super(props);

    this.handleAddLoanContract = this.handleAddLoanContract.bind(this);
    this.handleDeleteLoanContract = this.handleDeleteLoanContract.bind(this);

    this.handleAddLoanContract = this.handleAddLoanContract.bind(this);
    this.handleDeleteLoanContract = this.handleDeleteLoanContract.bind(this);

    // core event
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }
  public handleAddLoanContract(contract: ILoanContract) {
    this.setState({ loanContract: [contract.uniqueId || ""] });
  }
  public handleDeleteLoanContract(id: string) {
    this.setState({ loanContract: [] });
  }

  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    if (this.state.loanContract.length === 0) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "Seleccione un contrato de línea de crédito",
      });
    } else {
      const automaticDebitAuthorizationContract: IAutomaticDebitAuthorizationContract = {
        contractDate: state.contractDate,
        loanContract: state.loanContract[0],
        title: titleCaseHandler(state.title),
      };

      this.setState({ loading: true });
      create(this.props.user.token, automaticDebitAuthorizationContract)
        .then((res) => {
          this.props.createAutomaticDebitAuthorizationContract(
            res.data.results ? res.data.results : res.data
          );
          if (this.props.handleSaveCallBack) {
            this.props.handleSaveCallBack(res.data.uniqueId);
          }
          successHandler(res, this, translations.AUTOMATIC_DEBIT_AUTHORIZATION);
          this.handleReset();
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }

  public handleReset() {
    this.setState({
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      loanContract: [],
      loanContractError: "",
      title: "",
      titleError: "",
      debtor: [], // done
      debtorError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">
            {translations.NEW_ÁUTOMATIC_DEBIT_AUTHORIZATION}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={titleCaseHandler(this.state.title)}
                  onChange={this.handleChange("title")}
                  fullWidth={true}
                  required={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.LOAN_CONTRACT}
                </Typography>
                <LoanContractSelectInput
                  selectedLoanContractsId={this.state.loanContract}
                  add={this.handleAddLoanContract}
                  delete={this.handleDeleteLoanContract}
                />
                <p className={classes.errorInput}>
                  {this.state.loanContractError}
                </p>
                <br />
              </div>
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createAutomaticDebitAuthorizationContract })(Form)
);
