
import { CHECK_TYPES } from '../actions';

export default function(state: ICheck[] = [], action: IAction<ICheck[]>): ICheck[] {
    switch(action.type) {
        case CHECK_TYPES.FETCH_ALL:
            return action.payload || [];
        case CHECK_TYPES.FETCH:
            return state.map(check => (action.payload !== undefined && check.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: check);
        case CHECK_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case CHECK_TYPES.UPDATE:
            return state.map(check => (action.payload !== undefined && check.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : check);
        case CHECK_TYPES.DELETE:
            return state.filter(check => action.payload !== undefined && check.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}