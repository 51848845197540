import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  createStyles,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import {
  availabilityTypes,
  formatDayFirst,
  headersDeposit,
  headersVacation,
  translations,
} from "src/utils";
import AlertDialog from "../../AlertDialog";
import { green, orange } from "@material-ui/core/colors";
import LastDepositTable from "./LastDeposit/LastDepositTable";
import VacationDatesTable from "./VacationDates/VacationDatesTable";
import MoneyInput from "src/components/TextMaskInputComponents/moneyInput";
import {
  API_KEY,
  createDebtCapacity as createDebt,
  createPaymentCapacity as createPayment,
} from "../../../utils/axios";
import { createDebtCapacity, createPaymentCapacity } from "src/actions";

const styles = createStyles({
  calculate: {
    background: "#37C0EC",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  checkbox: {
    color: "#4caf50 !important",
    padding: "1px !important",
  },
  clean: {
    background: orange[500],
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  codes: {
    margin: "0px 4px",
  },
  formControl: {
    margin: "0px 4px",
  },
  grid: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateRows: "repeat(10, 60px)",
  },
  paper: {
    padding: 20,
    textAlign: "center",
  },
  root: {
    padding: "10px 0px",
  },
  save: {
    background: green[500],
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  textField: {
    margin: "0px 4px",
    width: "46%",
  },
});

class CalculatorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amountSalary: "",
      bankExpenseFee: 0,
      bankExpenseProduct: {},
      debtCapacity: "",
      dialog: false,
      dialogTitle: "",
      employmentTime: 0,
      errorMessage: "",
      errors: {},
      expirationDate: "",
      productType: "regular_loan",
      lastDeposits: [],
      otherFeesName: "",
      otherFeesQuota: 0,
      paymentCapacity: "",
      percentageCE: "",
      percentageCP: "",
      taxBase: 0,
      vacationDates: [],
      baseAmount: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.assingProportion = this.assingProportion.bind(this);
    this.handlePartialReset = this.handlePartialReset.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
  }

  handleCurrencyChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || 0 });
    };
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleSwitch(key) {
    return (event) => {
      this.setState({
        [key]: !this.state[key],
      });
    };
  }

  handlePartialReset() {
    this.setState({
      amountSalary: "",
      bankExpenseFee: 0,
      bankExpenseProduct: "",
      debtCapacity: "",
      errors: {},
      expirationDate: "",
      lastDeposits: [],
      otherFeesName: "",
      otherFeesQuota: 0,
      paymentCapacity: "",
      percentageCE: "",
      percentageCP: "",
      taxBase: 0,
      vacationDates: [],
      baseAmount: "",
    });
  }

  handleSelectChange(key) {
    return (event) => {
      if (key === "productType") {
        this.handlePartialReset();
      }
      this.setState({
        [key]: event.target.value,
      });
    };
  }

  handleRemove = (i, key) => {
    this.setState((state) => {
      state[key] = state[key].filter((row, j) => j !== i);
      if (key === "lastDeposits") {
        this.assingProportion(state.lastDeposits);
      }
      return state;
    });
  };

  assingProportion = (items) => {
    items.sort((a, b) => new Date(`${b.date}:`) - new Date(`${a.date}:`));
    for (let key of items.keys()) {
      if (items.length === 2) {
        items[key] = { ...items[key], proportion: "50" };
      } else if (items.length >= 3) {
        switch (key) {
          case 0:
            items[key] = { ...items[key], proportion: "60" };
            break;
          case 1:
            items[key] = { ...items[key], proportion: "20" };
            break;
          case 2:
            items[key] = { ...items[key], proportion: "20" };
            break;
          default:
            items[key] = { ...items[key], proportion: "0" };
            break;
        }
      } else {
        items[key] = { ...items[key], proportion: "100" };
      }
    }
    return items;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { changeOccurs, clientInst } = this.props;

    let params = {
      customer: this.props.ownerId,
    };

    let params2 = {};

    switch (this.state.productType) {
      case "regular_loan":
        params = {
          ...params,
          modifier_data: {
            approved_percentage: parseFloat(this.state.percentageCE) / 100,
            debt_capacity: this.state.debtCapacity,
            expiration_date: this.state.expirationDate,
            employment_time_provided: parseFloat(this.state.employmentTime),
            product_type: this.state.productType,
            salary_modifier: parseFloat(this.state.amountSalary),
            suggested: parseFloat(this.state.baseAmount),
          },
        };

        params2 = {
          ...params,
          data: {
            approved_percentage: parseFloat(this.state.percentageCP) / 100,
            bank_expense_product: this.state.bankExpenseProduct,
            bank_expenses: this.state.bankExpenseFee,
            expiration_date: this.state.expirationDate,
            deposits: this.state.lastDeposits,
            employment_time_provided: parseFloat(this.state.employmentTime),
            product_type: this.state.productType,
            institution_type: clientInst.institutionType,
            other_expenses: this.state.otherFeesQuota,
            other_fees_name: this.state.otherFeesName,
            payment_capacity: this.state.paymentCapacity,
          },
        };

        break;
      case "vacational_bonus":
        params = {
          ...params,
          modifier_data: {
            debt_capacity: this.state.debtCapacity,
            employment_time_provided: parseFloat(this.state.employmentTime),
            product_type: this.state.productType,
            expiration_date: this.state.expirationDate,
            suggested: parseFloat(this.state.baseAmount),
          },
          vacation_data: {
            vacation_dates: this.state.vacationDates,
            vacation_proportion: parseFloat(this.state.percentageCE) / 100,
          },
        };

        break;
      case "antiquity_incentive":
        params = {
          ...params,
          modifier_data: {
            approved_percentage: parseFloat(this.state.percentageCE) / 100,
            debt_capacity: this.state.debtCapacity,
            employment_time_provided: parseFloat(this.state.employmentTime),
            product_type: this.state.productType,
            expiration_date: this.state.expirationDate,
            payment_adjustment_required: false,
            suggested: parseFloat(this.state.baseAmount),
          },
        };
        break;
      case "october_bonus_1":
        params = {
          ...params,
          modifier_data: {
            debt_capacity: this.state.debtCapacity,
            employment_time_provided: parseFloat(this.state.employmentTime),
            expiration_date: this.state.expirationDate,
            product_type: this.state.productType,
            suggested: parseFloat(this.state.baseAmount),
            october_bonus_approved_percentage:
              parseFloat(this.state.percentageCE) / 100,
          },
        };
        break;
      case "october_bonus_2":
        params = {
          ...params,
          modifier_data: {
            debt_capacity: this.state.debtCapacity,
            employment_time_provided: parseFloat(this.state.employmentTime),
            expiration_date: this.state.expirationDate,
            product_type: this.state.productType,
            october_bonus_approved_percentage:
              parseFloat(this.state.percentageCE) / 100,
            october_bonus_multiplier: parseFloat(this.state.amountSalary),
            suggested: parseFloat(this.state.baseAmount),
          },
        };
        break;
      case "easter_royalty":
        params = {
          ...params,
          modifier_data: {
            debt_capacity: this.state.debtCapacity,
            easter_royalty_approved_percentage:
              parseFloat(this.state.percentageCE) / 100,
            easter_royalty_multiplier: parseFloat(this.state.amountSalary),
            employment_time_provided: parseFloat(this.state.employmentTime),
            expiration_date: this.state.expirationDate,
            product_type: this.state.productType,
            suggested: parseFloat(this.state.baseAmount),
          },
        };
        break;
      case "annual_bonus":
        params = {
          ...params,
          modifier_data: {
            annual_bonus_approved_percentage:
              parseFloat(this.state.percentageCE) / 100,
            annual_bonus_multiplier: parseFloat(this.state.amountSalary),
            debt_capacity: this.state.debtCapacity,
            employment_time_provided: parseFloat(this.state.employmentTime),
            expiration_date: this.state.expirationDate,
            product_type: this.state.productType,
            suggested: parseFloat(this.state.baseAmount),
          },
        };
        break;
      default:
        params = {
          ...params,
          modifier_data: {
            approved_percentage: parseFloat(this.state.percentageCE) / 100,
            debt_capacity: this.state.debtCapacity,
            employment_time_provided: parseFloat(this.state.employmentTime),
            expiration_date: this.state.expirationDate,
            product_type: this.state.productType,
            tax_base: parseFloat(this.state.taxBase),
            suggested: parseFloat(this.state.taxBase),
          },
        };
        break;
    }
    if (this.validate()) {
      if (this.state.productType === "regular_loan") {
        createPayment(this.props.user.token, params2)
          .then((res) => {
            this.props.createPaymentCapacity(
              res.data.results ? res.data.results : res.data
            );
            changeOccurs();
          })
          .catch((err) => {
            console.error(err, "error");
          });
      }
      createDebt(this.props.user.token, params)
        .then((res) => {
          this.props.createDebtCapacity(
            res.data.results ? res.data.results : res.data
          );
          changeOccurs();
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.nonFieldErrors
          ) {
            // Aquí encontramos el mensaje de error específico
            const errorMessage = err.response.data.nonFieldErrors[0];

            this.setState({
              dialog: true,
              dialogTitle: "Error",
              errorMessage: errorMessage,
              loading: false,
            });
          } else {
            console.error(err, "error");
          }
        });
    }

    this.handlePartialReset();
  };

  calculateMethod = () => {
    const { clientInfo, clientInst } = this.props;
    let url = "";
    let params = {
      company: clientInst.name,
      departure_date: clientInfo.departureDate
        ? formatDayFirst(clientInfo.departureDate)
        : formatDayFirst(new Date().toISOString().split("T")[0]),
      employment_time_provided: clientInfo.reentryDate
        ? parseFloat(this.state.employmentTime)
        : null,
      institution_type: clientInst.institutionType,
      laboral_status: clientInfo.laboralStatus,
      last_entry_date: formatDayFirst(clientInfo.entryDate),
      reentry: clientInfo.reentryDate ? true : false,
      reentry_date: clientInfo.reentryDate
        ? formatDayFirst(clientInfo.reentryDate)
        : null,
      salary: clientInfo.salary,
    };

    switch (this.state.productType) {
      case "regular_loan":
        url = "parameterized_debt_capacity_calculator";
        params = {
          ...params,
          approved_percentage: parseFloat(this.state.percentageCE) / 100,
          client: this.props.ownerId,
          easter_royalty_approved_percentage: 0.8,
          antiquity_incentive_approved_percentage: 0.8,
          easter_royalty_multiplier: 2.5,
          is_vacation_required: false,
          payment_adjustment_required: false,
          salary_modifier: 10,
          vacation_dates: [],
          vacation_proportion: 0.8,
        };
        this.axiosRequest(params, url);

        url = "parameterized_payment_capacity_calculator";
        params = {
          approved_percentage: parseFloat(this.state.percentageCP) / 100,
          bank_expenses: this.state.bankExpenseFee,
          deposits: this.state.lastDeposits,
          institution_type: clientInst.institutionType,
          other_expenses: this.state.otherFeesQuota,
        };
        this.axiosRequest(params, url);

        break;
      case "vacational_bonus":
        url = "parameterized_vacational_bonus_calculator";
        params = {
          ...params,
          vacation_dates: this.state.vacationDates,
          vacation_proportion: parseFloat(this.state.percentageCE) / 100,
        };
        this.axiosRequest(params, url);

        break;
      case "antiquity_incentive":
        url = "parameterized_antiquity_incentive_calculator";
        params = {
          ...params,
          payment_adjustment_required: false,
          antiquity_incentive_approved_percentage:
            parseFloat(this.state.percentageCE) / 100,
        };
        this.axiosRequest(params, url);
        break;
      case "october_bonus_1":
        url = "parameterized_first_october_bonus";
        params = {
          ...params,
          october_bonus_approved_percentage:
            parseFloat(this.state.percentageCE) / 100,
        };
        this.axiosRequest(params, url);
        break;
      case "october_bonus_2":
        url = "parameterized_second_october_bonus";
        params = {
          ...params,
          october_bonus_approved_percentage:
            parseFloat(this.state.percentageCE) / 100,
          october_bonus_multiplier: parseFloat(this.state.amountSalary),
        };
        this.axiosRequest(params, url);
        break;
      case "easter_royalty":
        url = "parameterized_easter_royalty_calculator";
        params = {
          ...params,
          easter_royalty_approved_percentage:
            parseFloat(this.state.percentageCE) / 100,
          easter_royalty_multiplier: parseFloat(this.state.amountSalary),
        };
        this.axiosRequest(params, url);
        break;
      case "annual_bonus":
        url = "parameterized_annual_bonus";
        params = {
          ...params,
          annual_bonus_approved_percentage:
            parseFloat(this.state.percentageCE) / 100,
          annual_bonus_multiplier: parseFloat(this.state.amountSalary),
        };
        this.axiosRequest(params, url);
        break;
      default:
        if (this.validate()) {
          const antipatedPayment =
            parseFloat(this.state.taxBase) *
            (parseFloat(this.state.percentageCE) / 100);
          this.setState({
            debtCapacity: antipatedPayment,
            baseAmount: this.state.taxBase,
          });
        }
        break;
    }
  };

  validate = (fieldValues = this.state) => {
    const between = (x) => (x >= 1 && x <= 100 ? x : 0);
    let temp = {};
    let fields = [];
    switch (this.state.productType) {
      case "regular_loan":
        fields = ["percentageCE", "percentageCP"];
        for (let field of Object.keys(fieldValues)) {
          if (fields.includes(field)) {
            //  Validar que los campos no esten vacíos
            if (!this.state[field]) {
              temp[field] = "Campo Requerido";
            } else if (between(this.state[field]) === 0) {
              //  Validar que los campos este en el rango permitido
              temp[field] = "Porcentaje debe ser mayor a 0 y menor a 100";
            }
          }
        }
        break;
      case "vacational_bonus":
        fields = ["percentageCE", "vacationDates"];
        for (let field of Object.keys(fieldValues)) {
          if (fields.includes(field)) {
            if (!this.state[field]) {
              //  Validar que los campos no esten vacíos
              temp[field] = "Campo Requerido";
            } else if (
              field === "percentageCE" &&
              between(this.state[field]) === 0
            ) {
              //  Validar que los campos este en el rango permitido
              temp[field] = "Porcentaje debe ser mayor a 0 y menor a 100";
            }
          }
        }
        break;
      case "antiquity_incentive":
        fields = ["percentageCE"];
        for (let field of Object.keys(fieldValues)) {
          if (fields.includes(field)) {
            //  Validar que los campos no esten vacíos
            if (!this.state[field]) {
              temp[field] = "Campo Requerido";
            } else if (
              field === "percentageCE" &&
              between(this.state[field]) === 0
            ) {
              //  Validar que los campos este en el rango permitido
              temp[field] = "Porcentaje debe ser mayor a 0 y menor a 100";
            }
          }
        }
        break;
      case "october_bonus_1":
        fields = ["percentageCE", "expirationDate"];
        for (let field of Object.keys(fieldValues)) {
          if (fields.includes(field)) {
            //  Validar que los campos no esten vacíos
            if (!this.state[field]) {
              temp[field] = "Campo Requerido";
            } else if (
              field === "percentageCE" &&
              between(this.state[field]) === 0
            ) {
              //  Validar que los campos este en el rango permitido
              temp[field] = "Porcentaje debe ser mayor a 0 y menor a 100";
            }
          }
        }
        break;
      case "october_bonus_2":
      case "easter_royalty":
      case "annual_bonus":
        fields = ["amountSalary", "expirationDate", "percentageCE"];
        for (let field of Object.keys(fieldValues)) {
          if (fields.includes(field)) {
            //  Validar que los campos no esten vacíos
            if (!this.state[field]) {
              temp[field] = "Campo Requerido";
            } else if (
              field === "percentageCE" &&
              between(this.state[field]) === 0
            ) {
              //  Validar que los campos este en el rango permitido
              temp[field] = "Porcentaje debe ser mayor a 0 y menor a 100";
            }
          }
        }
        break;
      default:
        fields = ["percentageCE", "taxBase", "expirationDate"];
        for (let field of Object.keys(fieldValues)) {
          if (fields.includes(field)) {
            //  Validar que los campos no esten vacíos
            if (!this.state[field]) {
              temp[field] = "Campo Requerido";
            } else if (
              field === "percentageCE" &&
              between(this.state[field]) === 0
            ) {
              //  Validar que los campos este en el rango permitido
              temp[field] = "Porcentaje debe ser mayor a 0 y menor a 100";
            }
          }
        }
        break;
    }

    if (this.props.clientInfo.reentryDate) {
      fields = ["employmentTime"];
      for (let field of Object.keys(fieldValues)) {
        if (fields.includes(field)) {
          //  Validar que los campos no esten vacíos
          if (!this.state[field]) {
            temp[field] = "Campo Requerido";
          }
        }
      }
    }

    this.setState({
      errors: { ...temp },
    });

    if (fieldValues === this.state) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  axiosRequest(params, url) {
    if (this.validate()) {
      axios({
        data: params,
        headers: {
          Authorization: "Bearer " + this.props.user.token,
        },
        method: "post",
        url: `${API_KEY}/outlay/${url}`,
      })
        .then((res) => {
          if (url === "parameterized_payment_capacity_calculator") {
            this.setState({ paymentCapacity: res.data });
          } else {
            this.setState({
              debtCapacity: res.data,
              expirationDate:
                res.data && res.data.length
                  ? res.data[1]
                  : this.state.expirationDate,
              baseAmount:
                res.data && res.data.length
                  ? (100 * res.data[0]) / (this.state.percentageCE || 100)
                  : (100 * res.data) / (this.state.percentageCE || 100),
            });
          }
        })
        .catch((err) => {
          this.setState({
            dialog: true,
            dialogTitle: "Advertencia",
            errorMessage:
              "Verifique que todos los campos necesarios esten llenos",
            loading: false,
          });
        });
    }
  }

  render() {
    const classes = styles;
    const { clientInfo } = this.props;
    const { errors } = this.state;

    const renderMethod = (productType) => {
      switch (productType) {
        case "regular_loan":
          return (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Table style={{ textAlignLast: "center" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Tipo de Disponibilidad</TableCell>
                        <TableCell>Monto Base</TableCell>
                        <TableCell>Total Aplicable</TableCell>
                        <TableCell>Porcentaje Aplicable</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Capacidad de endeudamiento</TableCell>
                        <TableCell>
                          {this.state.baseAmount
                            ? this.state.baseAmount.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                        <TableCell>
                          {this.state.debtCapacity
                            ? this.state.debtCapacity.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.percentageCE}
                            onChange={this.handleChange("percentageCE")}
                            fullWidth={true}
                            type="number"
                            error={errors.percentageCE ? true : false}
                            helperText={errors.percentageCE}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Capacidad de Pago</TableCell>
                        <TableCell>{"----"}</TableCell>
                        <TableCell>
                          {this.state.paymentCapacity
                            ? this.state.paymentCapacity.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.percentageCP}
                            onChange={this.handleChange("percentageCP")}
                            fullWidth={true}
                            type="number"
                            error={errors.percentageCP ? true : false}
                            helperText={errors.percentageCP}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <Paper style={classes.paper}>
                    <Typography>Ultimos depositos</Typography>
                    <LastDepositTable
                      headers={headersDeposit}
                      data={this.state.lastDeposits}
                      salary={this.props.clientInfo.salary}
                      assingProportion={this.assingProportion}
                      handleRemove={this.handleRemove}
                    />
                  </Paper>
                </Grid>
                <Grid item xs>
                  <Paper style={classes.paper}>
                    <Typography>Gastos Bancarios</Typography>
                    <TextField
                      label="Producto"
                      select={true}
                      value={this.state.bankExpenseProduct}
                      SelectProps={{ native: true }}
                      fullWidth={true}
                      onChange={this.handleSelectChange("bankExpenseProduct")}
                      inputProps={{
                        name: "bankExpenseProduct",
                      }}
                    >
                      <option defaultValue="">---Elija una opcion---</option>
                      <option value="loan">Prestamo</option>
                      <option value="card">Tarjeta</option>
                    </TextField>
                    <br />
                    <br />
                    <TextField
                      label={`Cuota`}
                      onChange={this.handleCurrencyChange("bankExpenseFee")}
                      value={this.state.bankExpenseFee}
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors.bankExpenseFee ? true : false}
                      helperText={errors.bankExpenseFee}
                      InputProps={{
                        inputComponent: MoneyInput,
                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs>
                  <Paper style={classes.paper}>
                    <Typography>Otros Gastos</Typography>
                    <TextField
                      label={translations.NAME}
                      onChange={this.handleChange("otherFeesName")}
                      value={this.state.otherFeesName}
                      fullWidth={true}
                    />
                    <br />
                    <br />
                    <TextField
                      label={`Cuota`}
                      onChange={this.handleCurrencyChange("otherFeesQuota")}
                      value={this.state.otherFeesQuota}
                      fullWidth={true}
                      error={errors.otherFeesQuota ? true : false}
                      helperText={errors.otherFeesQuota}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputComponent: MoneyInput,
                      }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </>
          );
        case "vacational_bonus":
          return (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Table style={{ textAlignLast: "center" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Disponibilidad</TableCell>
                        <TableCell>Monto Base</TableCell>
                        <TableCell>Fecha de vencimiento</TableCell>
                        <TableCell>Porcentaje Autorizado</TableCell>
                        <TableCell>Total Aprobado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Capacidad de endeudamiento</TableCell>
                        <TableCell>
                          {this.state.baseAmount
                            ? this.state.baseAmount.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                        <TableCell>
                          {this.state.debtCapacity
                            ? this.state.debtCapacity[1]
                            : "Auto Calculado yyyy-mm-dd"}
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.percentageCE}
                            onChange={this.handleChange("percentageCE")}
                            fullWidth={true}
                            type="number"
                            error={errors.percentageCE ? true : false}
                            helperText={errors.percentageCE}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {this.state.debtCapacity
                            ? this.state.debtCapacity[0].toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <br />
              <br />
              <VacationDatesTable
                headers={headersVacation}
                data={this.state.vacationDates}
                handleRemove={this.handleRemove}
              />
            </>
          );
        case "antiquity_incentive":
          return (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Table style={{ textAlignLast: "center" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Disponibilidad</TableCell>
                        <TableCell>Monto Base</TableCell>
                        <TableCell>Fecha de vencimiento</TableCell>
                        <TableCell>Porcentaje Autorizado</TableCell>
                        <TableCell>Total Aprobado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Capacidad de endeudamiento</TableCell>
                        <TableCell>
                          {this.state.baseAmount
                            ? this.state.baseAmount.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                        <TableCell>
                          {this.state.debtCapacity
                            ? this.state.debtCapacity[1]
                            : "Auto Calculado yyyy-mm-dd"}
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.percentageCE}
                            onChange={this.handleChange("percentageCE")}
                            fullWidth={true}
                            type="number"
                            error={errors.percentageCE ? true : false}
                            helperText={errors.percentageCE}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {this.state.debtCapacity
                            ? this.state.debtCapacity[0].toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </>
          );
        case "october_bonus_1":
          return (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Table style={{ textAlignLast: "center" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Disponibilidad</TableCell>
                        <TableCell>Monto Base</TableCell>
                        <TableCell>Fecha de vencimiento</TableCell>
                        <TableCell>Porcentaje Aprobado</TableCell>
                        <TableCell>Total Aprobado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Capacidad de endeudamiento</TableCell>
                        <TableCell>
                          {this.state.baseAmount
                            ? this.state.baseAmount.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.expirationDate}
                            type="date"
                            onChange={this.handleChange("expirationDate")}
                            fullWidth={true}
                            error={errors.expirationDate ? true : false}
                            helperText={errors.expirationDate}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.percentageCE}
                            onChange={this.handleChange("percentageCE")}
                            fullWidth={true}
                            type="number"
                            error={errors.percentageCE ? true : false}
                            helperText={errors.percentageCE}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {this.state.debtCapacity
                            ? this.state.debtCapacity.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </>
          );
        case "october_bonus_2":
          return (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Table style={{ textAlignLast: "center" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Disponibilidad</TableCell>
                        <TableCell>Monto Base</TableCell>
                        <TableCell>Fecha de vencimiento</TableCell>
                        <TableCell>Cantidad de Salario</TableCell>
                        <TableCell>Porcentaje Aprobado</TableCell>
                        <TableCell>Total Aprobado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Capacidad de endeudamiento</TableCell>
                        <TableCell>
                          {this.state.baseAmount
                            ? this.state.baseAmount.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.expirationDate}
                            type="date"
                            onChange={this.handleChange("expirationDate")}
                            fullWidth={true}
                            error={errors.expirationDate ? true : false}
                            helperText={errors.expirationDate}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.amountSalary}
                            onChange={this.handleChange("amountSalary")}
                            fullWidth={true}
                            type="number"
                            error={errors.amountSalary ? true : false}
                            helperText={errors.amountSalary}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              max: 3,
                              min: 0,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.percentageCE}
                            onChange={this.handleChange("percentageCE")}
                            fullWidth={true}
                            type="number"
                            error={errors.percentageCE ? true : false}
                            helperText={errors.percentageCE}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {this.state.debtCapacity
                            ? this.state.debtCapacity.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </>
          );
        case "easter_royalty":
          return (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Table style={{ textAlignLast: "center" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Disponibilidad</TableCell>
                        <TableCell>Monto Base</TableCell>
                        <TableCell>Fecha de vencimiento</TableCell>
                        <TableCell>Cantidad de Salario</TableCell>
                        <TableCell>Porcentaje Aprobado</TableCell>
                        <TableCell>Total Aprobado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Capacidad de endeudamiento</TableCell>
                        <TableCell>
                          {this.state.baseAmount
                            ? this.state.baseAmount.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.expirationDate}
                            type="date"
                            onChange={this.handleChange("expirationDate")}
                            fullWidth={true}
                            error={errors.expirationDate ? true : false}
                            helperText={errors.expirationDate}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.amountSalary}
                            onChange={this.handleChange("amountSalary")}
                            fullWidth={true}
                            type="number"
                            error={errors.amountSalary ? true : false}
                            helperText={errors.amountSalary}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              max: 3,
                              min: 0,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.percentageCE}
                            onChange={this.handleChange("percentageCE")}
                            fullWidth={true}
                            type="number"
                            error={errors.percentageCE ? true : false}
                            helperText={errors.percentageCE}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {this.state.debtCapacity
                            ? this.state.debtCapacity.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </>
          );
        case "annual_bonus":
          return (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Table style={{ textAlignLast: "center" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Disponibilidad</TableCell>
                        <TableCell>Monto Base</TableCell>
                        <TableCell>Fecha de vencimiento</TableCell>
                        <TableCell>Cantidad de Salario</TableCell>
                        <TableCell>Porcentaje Aprobado</TableCell>
                        <TableCell>Total Aprobado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Capacidad de endeudamiento</TableCell>
                        <TableCell>
                          {this.state.baseAmount
                            ? this.state.baseAmount.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.expirationDate}
                            type="date"
                            onChange={this.handleChange("expirationDate")}
                            fullWidth={true}
                            error={errors.expirationDate ? true : false}
                            helperText={errors.expirationDate}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.amountSalary}
                            onChange={this.handleChange("amountSalary")}
                            fullWidth={true}
                            type="number"
                            error={errors.amountSalary ? true : false}
                            helperText={errors.amountSalary}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              max: 3,
                              min: 0,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.percentageCE}
                            onChange={this.handleChange("percentageCE")}
                            fullWidth={true}
                            type="number"
                            error={errors.percentageCE ? true : false}
                            helperText={errors.percentageCE}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {this.state.debtCapacity
                            ? this.state.debtCapacity.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </>
          );
        default:
          return (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Table style={{ textAlignLast: "center" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Disponibilidad</TableCell>
                        <TableCell>Monto Base</TableCell>
                        <TableCell>Fecha de vencimiento</TableCell>
                        <TableCell>Porcentaje Autorizado</TableCell>
                        <TableCell>Total Aprobado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Capacidad Disponibilidad</TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.taxBase}
                            onChange={this.handleCurrencyChange("taxBase")}
                            fullWidth={true}
                            error={errors.taxBase ? true : false}
                            helperText={errors.taxBase}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputComponent: MoneyInput,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.expirationDate}
                            type="date"
                            onChange={this.handleChange("expirationDate")}
                            fullWidth={true}
                            error={errors.expirationDate ? true : false}
                            helperText={errors.expirationDate}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={this.state.percentageCE}
                            onChange={this.handleChange("percentageCE")}
                            fullWidth={true}
                            type="number"
                            error={errors.percentageCE ? true : false}
                            helperText={errors.percentageCE}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              max: 1,
                              min: 0,
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {this.state.debtCapacity
                            ? this.state.debtCapacity.toLocaleString("en-US")
                            : "Auto Calculado"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </>
          );
      }
    };

    return (
      <form style={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleSwitch("dialog")}
        />
        <FormControl style={classes.formControl}>
          <InputLabel shrink={true}>{translations.PRODUCT_TYPE}</InputLabel>
          <Select
            style={{ width: "16em" }}
            native={true}
            onChange={this.handleSelectChange("productType")}
            value={this.state.productType}
            inputProps={{
              name: "productType",
            }}
          >
            {availabilityTypes.map((method, indx) => {
              return (
                <option value={method.value} key={indx}>
                  {method.label}
                </option>
              );
            })}
          </Select>
        </FormControl>
        {clientInfo.reentryDate ? (
          <TextField
            label="Tiempo de Empleo"
            value={this.state.employmentTime}
            onChange={this.handleChange("employmentTime")}
            type="number"
            error={errors.employmentTime ? true : false}
            helperText={errors.employmentTime}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ) : null}
        <div style={{ padding: "10px 0px" }}>
          {renderMethod(this.state.productType)}
        </div>

        <div style={{ padding: "10px 0px" }}>
          <Button onClick={this.calculateMethod} style={classes.calculate}>
            Calcular
          </Button>
          <Button onClick={this.handleSubmit} style={classes.save}>
            Guardar
          </Button>
          <Button style={classes.clean} onClick={this.handlePartialReset}>
            Limpiar
          </Button>
        </div>
      </form>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps, {
  createDebtCapacity,
  createPaymentCapacity,
})(CalculatorForm);
