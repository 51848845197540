import "../Calculators.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MoneyInput from "src/components/TextMaskInputComponents/moneyInput";
import React, { Component } from "react";
import interestRateInput from "src/components/TextMaskInputComponents/interestRateInput";
import { plannedSavingsCalulator, fetchInterests } from "src/utils/axios";
import { faCalculator, faBroom } from "@fortawesome/free-solid-svg-icons";
import MuiAlert from "@material-ui/lab/Alert";

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  createStyles,
  withStyles,
  Typography,
  Snackbar,
  Grid,
} from "@material-ui/core";
import {
  translations,
  CALCULATION_TYPES,
  PLANNED_SAVINGS_PERIODICITY,
  HEADERS_PLANNED_SAVINGS,
} from "src/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { green } from "@material-ui/core/colors";
import { connect } from "react-redux";
import { AlertTitle } from "@material-ui/lab";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#4CAF50",
      },
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
      color: "red",
    },
    "& input:focus + fieldset, & select:focus + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& label.Mui-focused": {
      color: "green",
    },
    margin: 8,
  },
})(TextField);

const styles = createStyles({
  autoComplete: {
    color: "#4CAF50",
    margin: 8,
    width: "40%",
  },
  calculate: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  calculateDisabled: {
    border: "2px solid",
    borderRadius: 5,
    fontSize: 16,
  },
  clean: {
    background: "#F44636",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  download: {
    background: "#37C0EC",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  formControl: {
    margin: 8,
  },
  gridFormControl: {
    margin: 8,
    width: "100%",
  },
  pcp: {
    backgroundColor: green[500],
    color: "white",
  },
  save: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
});

class PlannedSavingsCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calculationType: "",
      calculatorType: "planned_savings",
      cuttingDay: 3,
      openingDate: new Date().toISOString().split("T")[0],
      periodicity: "monthly",
      quota: 0,
      tableContent: [],
      timeLimit: 0,
      interest: 0,
      amount: 0,
      paymentDay: 0,
      secondPaymentDay: 0,
      minBalance: 0,
      minTimeLimit: 0,
      maxTimeLimit: 0,
      calculate: false,
      errors: {},
    };

    this.initialState = {
      calculationType: "",
      calculatorType: "planned_savings",
      cuttingDay: 3,
      openingDate: new Date().toISOString().split("T")[0],
      periodicity: "monthly",
      quota: 0,
      tableContent: [],
      timeLimit: 0,
      amount: 0,
      paymentDay: 0,
      secondPaymentDay: 0,
      calculate: false,
      errors: {},
    };

    this.calculateTable = this.calculateTable.bind(this);
    this.fillDisabledFields = this.fillDisabledFields.bind(this);
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.switchState = this.switchState.bind(this);
  }

  componentDidMount() {
    const { token } = this.props.user;

    this.setState({ loading: true });

    let options = {
      limit: 20,
      token,
      offset: 0,
      calculatorType: this.state.calculatorType,
    };
    fetchInterests(options).then((res) => {
      if (res.data.results.length === 0) {
        this.setState({
          openAlert: true,
          severity: "error",
          alertTitle: "Ha ocurrido un error",
          alertMessage:
            "Debe crear la configuración de interés para Ahorros Planificados",
          calculate: true,
        });
      } else {
        const data = res.data.results[0];
        this.setState({
          interest: data.description[0].interestRate,
          minBalance: data.minimumBalance,
          minTimeLimit: data.minTimeLimit,
          maxTimeLimit: data.maxTimeLimit,
        });
      }
    });
    this.setState({ loading: false });
  }

  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || "" });
    };
  }

  handleSelectChange(key) {
    /* Handle the state of Select components */
    return (event) => {
      this.setState({
        [key]: event.target.value,
      });
    };
  }

  handleAutocompleteChange(key, obj) {
    /* Handle the state of Autocomplete components */
    if (obj) {
      this.setState({
        [key]: obj.value,
      });
    }
  }

  switchState(key) {
    this.setState({
      [key]: !this.state[key],
    });
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleReset() {
    this.setState({ ...this.initialState });
  }

  fillDisabledFields() {
    let s = { ...this.state };

    s.errors = {};

    s.amount = parseFloat(s.amount) || 0.0;
    s.quota = parseFloat(s.quota) || 0.0;

    // Funcion interna para darle formato a las fechas
    const formatDate = (dateObj) => {
      // Agregar un 0 en caso de que mes o dia sean menor a 9
      const month =
        dateObj.getMonth() + 1 <= 9
          ? `0${dateObj.getMonth() + 1}`
          : dateObj.getMonth() + 1;
      const day =
        dateObj.getDate() <= 9 ? `0${dateObj.getDate()}` : dateObj.getDate();

      return `${dateObj.getFullYear()}-${month}-${day}`;
    };

    // Si la fecha de cotizacion no se especifica
    // poner el valor del dia actual por defecto
    if (!s.openingDate) {
      s.openingDate = formatDate(new Date());
    }

    return s;
  }

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };

    // Verificar que el monto solicitado sea mayor a 0
    if (fieldValues.calculationType !== "amount") {
      temp.amount =
        fieldValues.amount > 0.0 ? "" : "El monto debe ser mayor a 0";
    }

    // Verificar que la cuota sea mayor a la cuota minima
    if (fieldValues.calculationType !== "quota") {
      temp.quota =
        fieldValues.quota >= fieldValues.minBalance
          ? ""
          : "La cuota debe ser mayor o igual a " + fieldValues.minBalance;
    }

    // Verificar que el periodo este en el rango establecido
    if (fieldValues.calculationType !== "periods") {
      temp.timeLimit =
        fieldValues.timeLimit !== "" &&
        fieldValues.timeLimit >= fieldValues.minTimeLimit &&
        fieldValues.timeLimit <= fieldValues.maxTimeLimit
          ? ""
          : "El plazo debe ser entre " +
            fieldValues.minTimeLimit +
            " y " +
            fieldValues.maxTimeLimit;
    }

    // Verificar que los dias de pago sean entre 1 y 30
    if (fieldValues.periodicity === "biweekly") {
      temp.secondPaymentDay =
        fieldValues.secondPaymentDay >= 1 && fieldValues.secondPaymentDay <= 30
          ? ""
          : "El dia de pago digitado debe ser entre 1 y 30";
    }
    temp.paymentDay =
      fieldValues.paymentDay >= 1 && fieldValues.paymentDay <= 30
        ? ""
        : "El dia de pago digitado debe ser entre 1 y 30";

    /* Handle validations before saving */
    const fields = [
      "calculatorType",
      "calculationType",
      "openingDate",
      "periodicity",
    ];

    for (let field of Object.keys(fieldValues)) {
      if (fields.includes(field)) {
        //  Validar que los campos no esten vacíos
        if (!this.state[field]) {
          temp[field] = "Campo Requerido";
        }
      }
    }

    this.setState({
      errors: { ...temp },
    });

    if (fieldValues === this.state) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  calculateTable(state, token) {
    //  create Json object with formatted data
    const options = JSON.stringify({
      periods: parseInt(state.timeLimit, 10),
      calculator_type: state.calculatorType,
      calculation_type: state.calculationType,
      opening_date: state.openingDate,
      amount: parseFloat(state.amount),
      quota: parseFloat(state.quota),
      cutting_day: parseInt(state.cuttingDay, 10),
      periodicity: state.periodicity,
      payment_days:
        state.periodicity === "monthly"
          ? parseInt(state.paymentDay, 10)
          : [
              parseInt(state.paymentDay, 10),
              parseInt(state.secondPaymentDay, 10),
            ].sort((a, b) => a < b),
    });
    // Call API And display data on screen
    plannedSavingsCalulator(options, token)
      .then((res) => {
        const data = res.data;
        this.setState({
          tableContent: data.table,
          quota: data.quota,
          timeLimit: data.periods,
          amount: data.amount,
          interest: data.interestRate,
        });
      })
      .catch((err) => console.error(err));
  }

  handleTable() {
    const { token } = this.props.user;
    const { state } = this;

    this.setState(
      // Get new state object and pass it to set state
      this.fillDisabledFields(),
      () => {
        // Call back the validate the actual state
        if (this.validate()) {
          this.calculateTable(state, token);
          this.switchState("openCalculate");
        }
      }
    );
  }

  render() {
    const classes = styles;
    const {
      errors,
      openAlert,
      alertMessage,
      severity,
      alertTitle,
    } = this.state;

    return (
      <div className="quotation-container">
        <Snackbar
          open={openAlert}
          // autoHideDuration={6000}
          onClose={() => this.switchState("openAlert")}
        >
          <MuiAlert
            onClose={() => this.switchState("openAlert")}
            severity={severity}
          >
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertMessage}
          </MuiAlert>
        </Snackbar>
        <Typography variant="h4">
          {`Calculadora de ${translations.PLANNED_SAVINGS}`}
        </Typography>
        <div>
          <section className="header-section1">
            <Grid container={true} className={classes.half} spacing={2}>
              <Grid item={true} xs={3}>
                <CssTextField
                  style={{ width: "100%" }}
                  type="date"
                  label={translations.OPENING_DATE}
                  variant="outlined"
                  disabled={false}
                  onChange={this.handleChange("openingDate")}
                  value={this.state.openingDate}
                  inputProps={{ max: new Date().toISOString().split("T")[0] }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <Autocomplete
                  style={{ width: "100%" }}
                  options={CALCULATION_TYPES}
                  getOptionLabel={(option) => option.label}
                  value={
                    this.state.calculationType
                      ? CALCULATION_TYPES.find(
                          (item) => item.value === this.state.calculationType
                        )
                      : null
                  }
                  id={"calculationType"}
                  disabled={false}
                  onChange={(e, value) =>
                    this.handleAutocompleteChange("calculationType", value)
                  }
                  renderInput={(props) => (
                    <CssTextField
                      {...props}
                      required={true}
                      error={errors.calculationType ? true : false}
                      helperText={errors.calculationType}
                      label={"Calculo a realizar"}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <CssTextField
                  error={errors.quota ? true : false}
                  helperText={errors.quota}
                  id={"quota"}
                  label={translations.QUOTA}
                  required={
                    this.state.calculationType === "quota" ? false : true
                  }
                  style={{ width: "100%" }}
                  value={this.state.quota}
                  variant="outlined"
                  disabled={
                    this.state.calculationType === "quota" ? true : false
                  }
                  onChange={this.handleCurrencyInputChange("quota")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: MoneyInput,
                  }}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <CssTextField
                  error={errors.amount ? true : false}
                  helperText={errors.amount}
                  id={"amount"}
                  label={"Meta de ahorro"}
                  required={
                    this.state.calculationType === "amount" ? false : true
                  }
                  style={{ width: "100%" }}
                  value={this.state.amount}
                  variant="outlined"
                  disabled={
                    this.state.calculationType === "amount" ? true : false
                  }
                  onChange={this.handleCurrencyInputChange("amount")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: MoneyInput,
                  }}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <CssTextField
                  error={errors.interest ? true : false}
                  helperText={errors.interest}
                  id={"interest"}
                  label={translations.INTEREST}
                  required={true}
                  style={{ width: "100%" }}
                  value={this.state.interest}
                  variant="outlined"
                  disabled={true}
                  onChange={this.handleChange("interest")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: interestRateInput,
                  }}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <CssTextField
                  error={errors.timeLimit ? true : false}
                  helperText={errors.timeLimit}
                  id={"timeLimit"}
                  label={translations.TERM}
                  style={{ width: "100%" }}
                  value={this.state.timeLimit}
                  variant="outlined"
                  required={
                    this.state.calculationType === "periods" ? false : true
                  }
                  disabled={
                    this.state.calculationType === "periods" ? true : false
                  }
                  onChange={this.handleChange("timeLimit")}
                  type="number"
                  inputProps={{
                    min:
                      this.state.calculationType !== "periods"
                        ? this.state.minTimeLimit
                        : 0,
                    max:
                      this.state.calculationType !== "periods"
                        ? this.state.maxTimeLimit
                        : 999999,
                  }}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <Autocomplete
                  style={{ width: "100%" }}
                  options={PLANNED_SAVINGS_PERIODICITY}
                  getOptionLabel={(option) => option.label}
                  value={
                    this.state.periodicity
                      ? PLANNED_SAVINGS_PERIODICITY.find(
                          (item) => item.value === this.state.periodicity
                        )
                      : null
                  }
                  id={"periodicity"}
                  disabled={false}
                  onChange={(e, value) =>
                    this.handleAutocompleteChange("periodicity", value)
                  }
                  renderInput={(props) => (
                    <CssTextField
                      {...props}
                      required={true}
                      error={errors.periodicity ? true : false}
                      helperText={errors.periodicity}
                      label={"Periodicidad"}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <CssTextField
                  variant="outlined"
                  value={this.state.paymentDay}
                  label={translations.PAYMENT_DAY}
                  onChange={this.handleChange("paymentDay")}
                  fullWidth={true}
                  error={errors.paymentDay ? true : false}
                  helperText={errors.paymentDay}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: 30,
                    min: 1,
                    step: 1,
                  }}
                />
              </Grid>
              <Grid item={true} xs={3}>
                <CssTextField
                  variant="outlined"
                  disabled={
                    this.state.periodicity === "biweekly" ? false : true
                  }
                  value={this.state.secondPaymentDay}
                  label={translations.PAYMENT_DAY}
                  onChange={this.handleChange("secondPaymentDay")}
                  fullWidth={true}
                  error={errors.secondPaymentDay ? true : false}
                  helperText={errors.secondPaymentDay}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: 30,
                    min: 1,
                    step: 1,
                  }}
                />
              </Grid>
            </Grid>
            <div className="action-section">
              <div className="action-button">
                <Button style={classes.clean} onClick={this.handleReset}>
                  <FontAwesomeIcon icon={faBroom} />
                  &nbsp; Limpiar
                </Button>
                <Button
                  style={
                    this.state.calculate
                      ? classes.calculateDisabled
                      : classes.calculate
                  }
                  onClick={this.handleTable}
                  disabled={this.state.calculate}
                >
                  <FontAwesomeIcon icon={faCalculator} />
                  &nbsp; Calcular
                </Button>
              </div>
            </div>
          </section>
        </div>
        <div>
          <div className="table-section">
            <TableContainer component={Paper} className="amort-table">
              <Table size="small" stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    {HEADERS_PLANNED_SAVINGS.map((opt, indx) => {
                      return (
                        <TableCell
                          style={{ color: "white", background: "#4caf50" }}
                          key={indx}
                        >
                          {opt.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.tableContent.map((opt, indx) => {
                    if (indx > 0) {
                      return (
                        <TableRow key={indx}>
                          {opt.map((campo, key) => (
                            <TableCell key={key}>{campo}</TableCell>
                          ))}
                        </TableRow>
                      );
                    }
                    return false;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withStyles(styles)(
  connect(mapStateToProps)(PlannedSavingsCalculator)
);
