import "../Calculators.css";
import React, { Component } from "react";
import { ToolbarComponent } from "../../SharedComponents/Toolbar";
import {
  // fetchParticipants,
  fetchLoans,
  calculateNextQuota,
  API_KEY,
  fetchBankAccounts,
  fetchDepositBankAccounts,
} from "src/utils/axios";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Button,
  createStyles,
  withStyles,
  Typography,
  Snackbar,
  Grid,
  IconButton,
  Modal,
  Divider,
} from "@material-ui/core";
import {
  translations,
  financialConfig,
  paymentTypes,
  transactionClass,
  creditMethod,
  transactionTypes,
  periodicity,
  termReduction,
} from "src/utils";
import { green } from "@material-ui/core/colors";
import { connect } from "react-redux";
import { AlertTitle } from "@material-ui/lab";
import LoadingDialog from "../../SharedComponents/LoadingScreen";
import {
  AutocompleteField,
  NumericTextField,
  DateField,
  TextFieldComponent,
  CheckboxField,
  SearchComponent,
} from "../../SharedComponents/Fields";
import { Close, Tune, ClearAll } from "@material-ui/icons";
import { PaymentTable, DetailPaymentTable } from "./table";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import * as algebra from "algebra.js";
import axios from "axios";

const styles = createStyles({
  autoComplete: {
    color: "#4CAF50",
    margin: 8,
    width: "40%",
  },
  calculate: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  calculateDisabled: {
    border: "2px solid",
    borderRadius: 5,
    fontSize: 16,
  },
  clean: {
    background: "#F44636",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  download: {
    background: "#37C0EC",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
  formControl: {
    margin: 8,
  },
  gridFormControl: {
    margin: 8,
    width: "100%",
  },
  pcp: {
    backgroundColor: green[500],
    color: "white",
  },
  save: {
    background: "#4CAF50",
    border: "5px solid",
    borderRadius: 10,
    color: "white",
    fontSize: 16,
  },
});

// Componente de filtros
function FiltersHeader(props) {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Typography
        variant={props.typeVariant}
        className={props.typeClass}
        style={{ fontWeight: "bold" }}
      >
        {props.filtersTitle}
      </Typography>
      <IconButton
        className={props.btnClass}
        onClick={props.toggleFilter}
        disabled={props.disableClose}
      >
        {props.openFilter ? (
          <Close className={props.iconClass} />
        ) : (
          <Tune className={props.iconClass} />
        )}
      </IconButton>
    </Grid>
  );
}

class PaymentCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: "",
      clientId: "",
      clients: [],
      creditMethods: [...creditMethod],
      identificationNumber: "",
      identificationType: "",
      financialConfig: [],
      transactionClass: [],
      productType: [],
      payment:
        this.props.filters && this.props.filters.paymentType
          ? this.props.filters.paymentType
          : "",
      paymentType: "conventional",
      participantPaymentDay: new Date(),
      paymentDay: "",
      paymentMethod: "Efectivo",
      productTypeToSend: "",
      financialConfigToSend: "",
      transactionClassToSend: "",
      totalAmountToPay: 0,
      totalReceivedAmount: 0,
      loans: [],
      loansDetail: [],
      selected: {},
      modalData: {},
      bankAccount: "",
      bankAccounts: [],
      depositBankAccount: "",
      depositBankAccounts: [],
      check: 0,
      loading: false,
      openFilter: true,
      openModal: false,
      disableCloseBtn: false,
      calculate: true,
      hideClass: "",
      errors: {},
    };

    this.initialState = {
      client: "",
      clientId: "",
      identificationNumber: "",
      identificationType: "",
      financialConfig: [],
      transactionClass: [],
      productType: [],
      payment:
        this.props.filters && this.props.filters.paymentType
          ? this.props.filters.paymentType
          : "",
      paymentType: "conventional",
      participantPaymentDay: new Date(),
      paymentDay: "",
      paymentMethod: "cash",
      productTypeToSend: "",
      financialConfigToSend: "",
      transactionClassToSend: "",
      totalAmountToPay: 0,
      totalReceivedAmount: 0,
      loans: [],
      loansDetail: [],
      selected: {},
      modalData: {},
      bankAccount: "",
      bankAccounts: [],
      depositBankAccount: "",
      depositBankAccounts: [],
      check: 0,
      loading: false,
      openFilter: true,
      openModal: false,
      disableCloseBtn: false,
      calculate: true,
      hideClass: "",
      errors: {},
    };

    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.switchState = this.switchState.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDiscount = this.handleDiscount.bind(this);
    this.handleModalData = this.handleModalData.bind(this);
    this.handleFilterAutocompleteChange = this.handleFilterAutocompleteChange.bind(
      this
    );
    this.handleNumericChange = this.handleNumericChange.bind(this);
    this.handleNumericModalChange = this.handleNumericModalChange.bind(this);
    this.handleAutocompleteModalChange = this.handleAutocompleteModalChange.bind(
      this
    );
    this.numberToFraction = this.numberToFraction.bind(this);
    this.calculateConfig = this.calculateConfig.bind(this);
    this.saveConfig = this.saveConfig.bind(this);
  }

  componentDidMount() {
    // const { token } = this.props.user;
    // this.setState({
    //   loading: true,
    // })

    // Carga los participantes
    // fetchParticipants({
    //   participantType: "client",
    //   token: token,
    // }).then((res) => {
    //   this.setState({ clients: res.data });

    //   if (this.props.filters) {
    //     this.handleAutocompleteChange("client", "", {rohiId: this.props.filters.rohiId, uniqueId:this.props.filters.uniqueId});
    //     this.handleAutocompleteChange("paymentType", "", {value: this.props.filters.paymentType});

    //     this.setState({
    //       creditMethods: this.props.filters.paymentType === "conventional" ? [...creditMethod, { value: "capitalization", label: "Capitalizacion"}] : [...creditMethod, { value: "reprogramation", label: "Reprogramacion"}],
    //       paymentMethod: this.props.filters.paymentType === "conventional" ? "Capitalizacion" : "Reprogramacion"
    //     });

    //     this.applyFilters();
    //   }

    // }).catch((err) => {
    //   this.printMessage(err, "error");
    // }).finally(() => {
    //   this.setState({
    //     loading: false,
    //   })
    // });
    if (this.props.filters) {
      this.handleAutocompleteChange("client", "", {
        rohiId: this.props.filters.rohiId,
        uniqueId: this.props.filters.uniqueId,
      });
      this.handleAutocompleteChange("paymentType", "", {
        value: this.props.filters.paymentType,
      });
      this.setState({
        creditMethods:
          this.props.filters.paymentType === "conventional"
            ? [
                ...creditMethod,
                { value: "capitalization", label: "Capitalizacion" },
              ]
            : [
                ...creditMethod,
                { value: "reprogramation", label: "Reprogramacion" },
              ],
        paymentMethod:
          this.props.filters.paymentType === "conventional"
            ? "Capitalizacion"
            : "Reprogramacion",
      });
      this.applyFilters();
    }
    // console.log((this.props.data && this.props.data.length) ? this.props.data : this.state.loansDetail);
    this.setState({
      loansDetail:
        this.props.data && this.props.data.length
          ? this.props.data
          : this.state.loansDetail,
      payment: (this.props.filters && this.props.filters.paymentType) || "",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // Maneja los items seleccionados y la suma para el total
    if (
      prevState.selected !== this.state.selected &&
      (!this.props.data || !this.props.data.length)
    ) {
      // const info = { ...this.state.selected }
      const info =
        this.props.data && this.props.data.length
          ? this.props.data
          : { ...this.state.selected };
      let totalPay = 0;
      let totalReceive = 0;

      let detail = [];

      if (this.state.payment === "extraordinary") {
        detail = Object.values(info);
      } else {
        for (const element in info) {
          if (info.hasOwnProperty(element)) {
            for (let elem of info[element]) {
              elem.id_dbs_rohi = element;
              elem.descuento = 0;
              elem.otros_cargos = 0;
              elem.gastos_legales = 0;

              totalPay += elem.monto_pagar;
              detail.push(elem);
            }
          }
        }
      }
      totalReceive = totalPay;
      this.setState({
        totalAmountToPay: parseFloat(totalPay).toFixed(2),
        totalReceivedAmount: parseFloat(totalReceive).toFixed(2),
        loansDetail: detail,
      });
    }

    if (prevState.loansDetail !== this.state.loansDetail) {
      const info = [...this.state.loansDetail];
      let totalPay = 0;
      let totalReceive = 0;

      if (this.state.payment === "extraordinary") {
        totalPay = info.reduce((a, b) => a + parseFloat(b.total_pagar), 0);
        totalReceive = info.reduce(
          (a, b) => a + parseFloat(b.monto_recibido),
          0
        );

        this.setState({
          totalAmountToPay: parseFloat(totalPay).toFixed(2),
          totalReceivedAmount: parseFloat(totalReceive).toFixed(2),
        });
      } else {
        totalPay = info.reduce((a, b) => a + b.monto_pagar, 0);

        this.setState({
          totalAmountToPay: parseFloat(totalPay).toFixed(2),
        });
      }
    }
  }

  printMessage(msg, severity) {
    // console.error(msg)
    severity === "success"
      ? this.setState({
          alertMessage: "Operación realizada exitosamente",
          openAlert: true,
          severity: "success",
        })
      : severity === "error"
      ? this.setState({
          alertMessage:
            "💀 Error al realizar la operacion, favor verificar e intentar de nuevo 💀",
          openAlert: true,
          severity: "error",
        })
      : this.setState({
          alertMessage: `💀 ${msg} 💀`,
          openAlert: true,
          severity: "warning",
        });
  }

  /* Handle the state of selected */
  handleSelected(key, info) {
    if (this.state.payment === "extraordinary") {
      this.setState({
        selected: info,
      });
    } else {
      this.setState((prevState) => ({
        selected: {
          ...prevState.selected,
          [key]: info,
        },
      }));
    }
  }

  handleAutocompleteChange(key, obj, val) {
    /* Handle the state of Autocomplete components */
    if (val || (key === "client" && obj)) {
      if (key === "productType") {
        let products = [];

        for (let product of val) {
          products.push(product.value);
        }

        this.setState({
          [key]: val,
          productTypeToSend: products.toString(),
        });
      } else if (key === "client") {
        const data = obj || val;
        this.setState({
          [key]: data.rohiId ? data.rohiId : "",
          clientId: data.uniqueId || data.value,
        });
      } else if (key === "paymentMethod") {
        this.setState({
          [key]: val.label,
        });
      } else if (["depositBankAccount", "bankAccount"].includes(key)) {
        this.setState({
          [key]: val.number,
        });
      } else {
        this.setState({
          [key]: val.value,
        });
      }
    }
  }

  handleFilterAutocompleteChange(key, sendKey, obj) {
    if (obj) {
      let elements = [];

      for (let element of obj) {
        elements.push(element.rohi);
      }

      this.setState({
        [key]: obj,
        [sendKey]: elements.toString(),
      });
    }
  }

  switchState(key) {
    this.setState({
      [key]: !this.state[key],
    });
  }

  switchModalState(key) {
    if (key === "condonar_intereses_corridos" && this.state.modalData[key]) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [key]: !this.state.modalData[key],
          intereses_corridos: 0,
          total_intereses_corridos: this.state.modalData
            .monto_intereses_corridos,
          condonar_principal: false,
          monto_condonamiento_principal: 0,
        },
      });
    } else if (key === "agregar_cargos" && this.state.modalData[key]) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [key]: !this.state.modalData[key],
          monto_otros_cargos: 0,
          monto_gastos_legales: 0,
        },
      });
    } else if (key === "condonar_principal" && this.state.modalData[key]) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [key]: !this.state.modalData[key],
          monto_condonamiento_principal: 0,
        },
      });
    } else {
      let data = {
        ...this.state.modalData,
        [key]: !this.state.modalData[key],
      };

      this.setState({
        modalData: data,
      });

      if (this.state.calculate) {
        this.calculateConfig(data);
      }
    }
  }

  handleNumericModalChange(key, event) {
    const data = { ...this.state.modalData, [key]: event.value };
    this.setState({
      modalData: data,
    });
    if (this.state.calculate) {
      this.calculateConfig(data);
    }
  }

  handleAutocompleteModalChange(key, event, obj) {
    const data = { ...this.state.modalData, [key]: obj.value };

    /* Handle the state of Autocomplete components */
    if (obj) {
      this.setState({
        modalData: data,
      });

      if (this.state.calculate) {
        this.calculateConfig(data);
      }
    }
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleReset() {
    if (this.props.disabled) {
      this.setState({
        identificationNumber: "",
        identificationType: "",
        financialConfig: [],
        transactionClass: [],
        productType: [],
        participantPaymentDay: new Date(),
        paymentDay: "",
        productTypeToSend: "",
        financialConfigToSend: "",
        transactionClassToSend: "",
        totalAmountToPay: 0,
        totalReceivedAmount: 0,
        loans: [],
        loansDetail: [],
        selected: {},
        modalData: {},
        bankAccount: "",
        bankAccounts: [],
        depositBankAccount: "",
        depositBankAccounts: [],
        check: 0,
        loading: false,
        openFilter: true,
        openModal: false,
        disableCloseBtn: false,
        calculate: true,
        hideClass: "",
        errors: {},
      });
    } else {
      this.setState({ ...this.initialState });
    }
  }

  handleDateChange = (type, dateInfo, shortDate) => {
    this.setState({
      [type]: dateInfo,
    });
  };

  handleNumericChange(key) {
    return (event) => {
      this.setState({
        [key]: event.value,
      });
    };
  }

  // Manejar los descuentos de los pagos convencionales
  handleDiscount(data) {
    this.setState({
      loansDetail: data,
    });
  }

  // Asignar la data del modal
  handleModalData(data) {
    this.setState({
      modalData: data,
    });
  }

  // Carga los prestamos filtrados
  async applyFilters() {
    const { token } = this.props.user;

    this.setState({
      errors: {},
      loading: true,
      loans: [],
      loansDetail: [],
      modalData: {},
    });

    if ([null, "", undefined].includes(this.state.client)) {
      return this.setState({
        errors: {
          client: "Debe completar este campo",
        },
        loading: false,
      });
    }

    let loansOptions = {
      client_id: this.state.client,
      payment_day: this.state.paymentDay,
      financial_config: this.state.financialConfigToSend,
      loan_class: this.state.transactionClassToSend,
      product_type: this.state.productTypeToSend,
    };

    let depositBankOptions = {
      token: token,
      owner: this.state.clientId,
    };

    let bankOptions = {
      token: token,
      ownerRegistry: "430298557",
    };

    for (let option in loansOptions) {
      if (loansOptions[option] === "") {
        delete loansOptions[option];
      }
    }

    try {
      // Peticiones para cargar los prestamos y las cuentas de banco
      // let loans = [];
      // if(this.props.data && this.props.data.length > 0) {
      //   console.log('?');
      //   console.log(this.props.data);
      //   loans = [...this.props.data];
      // }else {
      //   loans = (await fetchLoans(token, loansOptions)).data;
      // }
      let loans = (await fetchLoans(token, loansOptions)).data;
      let depositBankAccounts = (
        await fetchDepositBankAccounts(depositBankOptions)
      ).data;
      let bankAccounts = (await fetchBankAccounts(bankOptions)).data;

      for (let element of loans) {
        if (element.dia_pago === null || element.detail === null) {
          const index = loans.indexOf(element);
          delete loans[index];
          continue;
        }
        element.total_intereses_corridos = 0;

        // Calcular fecha de vencimiento
        const currentDate = moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const day = currentDate.date();
        const fechaDesembolso = moment(element.fecha_desembolso, "DD-MM-YYYYY");
        const fechaUltimoVencimiento =
          day >= element.dia_pago
            ? currentDate.date(element.dia_pago)
            : currentDate.subtract(1, "month").date(element.dia_pago);
        const fechaVencimiento =
          fechaUltimoVencimiento.isSameOrBefore(fechaDesembolso) ||
          element.configuracion_financiera === "A VENCIMIENTO"
            ? fechaDesembolso
            : fechaUltimoVencimiento;

        // Calcular la tasa de interes segun la periodicidad
        const period = periodicity.find(
          (elem) => elem.label.toUpperCase() === element.periodicidad
        );
        element.period = period;
        element.tasa_interes = parseFloat(
          element.tasa_interes_anual * period.periods
        ).toFixed(2);
        // element.fecha_ultimo_vencimiento = fechaVencimiento.format("DD[/]MM[/]YYYY");
        element.fecha_ultimo_vencimiento = fechaVencimiento;
        element.dias_transcurridos = moment(
          this.state.participantPaymentDay
        ).diff(fechaVencimiento, "days");

        // Calcular intereses corridos
        if (element.configuracion_financiera === "A VENCIMIENTO") {
          // const fechaVencimientoPrestamo = moment(element.detail[0].fecha_vencimiento, "DD-MM-YYYYY").isSameOrBefore(moment(this.state.participantPaymentDay)) ? moment(this.state.participantPaymentDay) : moment(element.detail[0].fecha_vencimiento, "DD-MM-YYYYY");
          // element.dias_transcurridos = fechaVencimientoPrestamo.diff(fechaVencimiento, 'days');
          if (element.tipo_transaccion === "ORDINARIO EXPRESO") {
            element.total_intereses_corridos = parseFloat(
              element.monto_actual *
                (element.tasa_interes / 100) *
                (element.dias_transcurridos / period.days)
            ).toFixed(2);
          } else {
            element.total_intereses_corridos = parseFloat(
              element.monto_actual *
                (element.tasa_interes_anual / 100) *
                (element.dias_transcurridos / period.days)
            ).toFixed(2);
          }
        } else {
          element.total_intereses_corridos = parseFloat(
            element.monto_actual *
              (element.tasa_interes / 100) *
              (element.dias_transcurridos / period.days)
          ).toFixed(2);
        }

        if (element.is_prepaid_interest) {
          const intereses =
            element.total_intereses_corridos - element.prepaid_amount;
          element.total_intereses_corridos = intereses <= 0 ? 0 : intereses;
        }

        // Declarar variables necesarias para la configuración de pagos extraordinarios
        if (this.state.paymentType === "extraordinary") {
          element.agregar_intereses = true;
          element.condonar_intereses_corridos = false;
          element.agregar_cargos = false;
          element.condonar_principal = false;
          element.esta_atrasado = false;
          element.proxima_cuota = 0;
          element.monto_atrasado = 0;
          element.intereses_corridos = 0;
          element.monto_otros_cargos = 0;
          element.monto_gastos_legales = 0;
          element.otros_descuentos = 0;
          element.monto_condonamiento_principal = 0;
          element.total_otros_cargos = 0;
          element.total_gastos_legales = 0;
          element.total_descuentos = 0;
          // element.total_intereses_corridos = 0;
          element.monto_intereses_corridos = 0;
          element.monto_total = 0;
          element.monto_recibido = 0;
          element.monto_aplicado = 0;
          element.total_pagar = 0;
          element.metodologia_pago = "";
          element.seguro = 0;
          element.comision = 0;
          element.gastos_legales = 0;

          // Calcular intereses corridos, monto recibido, monto aplicado y total a pagar

          element.monto_intereses_corridos = element.total_intereses_corridos;
          element.monto_total = parseFloat(
            parseFloat(element.monto_actual) +
              parseFloat(element.total_intereses_corridos)
          ).toFixed(2);

          if (element.configuracion_financiera === "ESCALONADA") {
            element.metodologia_pago = "total_balance";
          }

          for (let detail of element.detail) {
            detail.principal_pagar =
              detail.principal_vencido - detail.principal_pagado;
            detail.interes_pagar =
              element.configuracion_financiera === "A VENCIMIENTO"
                ? parseFloat(element.total_intereses_corridos).toFixed(2)
                : detail.interes_vencido - detail.interes_pagado;
            detail.seguro_pagar = detail.seguro_vencido - detail.seguro_pagado;
            detail.comision_pagar =
              detail.comision_vencida - detail.comision_pagada;
            detail.mora_pagar = detail.mora_no_pagada - detail.mora_pagada;
            if (element.tipo_transaccion === "ORDINARIO EXPRESO") {
              detail.monto_pagar =
                detail.principal_pagar +
                parseFloat(detail.interes_pagar) + 
                detail.seguro_pagar +
                detail.mora_pagar;
            } else {
              detail.monto_pagar =
                detail.principal_pagar +
                parseFloat(detail.interes_pagar) +
                detail.seguro_pagar +
                detail.comision_pagar +
                detail.mora_pagar;
            }
            detail.total_pendiente = detail.monto_pagar;

            // Validar si hay atrasos y asignar el valor
            if (detail.esta_atrasado) {
              if (element.tipo_transaccion === "ORDINARIO EXPRESO") {
                element.monto_atrasado =
                  parseFloat(element.monto_atrasado) +
                  parseFloat(detail.interes_pagar) +
                  detail.mora_pagar;
              } else {
                element.monto_atrasado =
                  parseFloat(element.monto_atrasado) +
                  parseFloat(detail.interes_pagar) + 
                  detail.comision_pagar +
                  detail.mora_pagar;
                element.seguro = element.seguro + detail.seguro_pagar;
              }
              if (!element.esta_atrasado) {
                element.esta_atrasado = true;
              }
            }
          }
          if (element.configuracion_financiera === "A VENCIMIENTO") {
            element.monto_atrasado = 0;
            element.monto_total = element.detail[0].monto_pagar;
            element.mora = element.detail[0].mora_pagar;
            element.seguro = element.detail[0].seguro_pagar;
            if (!element.tipo_transaccion === "ORDINARIO EXPRESO") {
              element.seguro = element.detail[0].seguro_pagar;
              element.comision = element.detail[0].comision_pagar;
            }
          }
          // element.seguro = parseFloat(
          //   element.detail.reduce(
          //     (accumulator, detail) =>
          //       accumulator + (detail.seguro_vencido - detail.seguro_pagado),
          //     0
          //   )
          // ).toFixed(2);
          element.monto_total = parseFloat(
            parseFloat(element.monto_total) + parseFloat(element.monto_atrasado)
          ).toFixed(2);
          element.monto_recibido = element.monto_total;
          element.monto_aplicado = element.monto_actual;
          // element.monto_aplicado = parseFloat(
          //   parseFloat(element.monto_actual) - parseFloat(element.seguro)
          // ).toFixed(2);
          element.total_pagar = element.monto_total;
        } else if (this.state.paymentType === "conventional") {
          for (let detail of element.detail) {
            detail.configuracion_financiera = element.configuracion_financiera;
            detail.principal_pagar =
              detail.principal_vencido - detail.principal_pagado;
            detail.interes_pagar =
              detail.interes_vencido - detail.interes_pagado;
            detail.seguro_pagar = detail.seguro_vencido - detail.seguro_pagado;
            detail.comision_pagar =
              detail.comision_vencida - detail.comision_pagada;
            detail.mora_pagar = detail.mora_no_pagada - detail.mora_pagada;

            detail.monto_pagar =
              detail.principal_pagar +
              parseFloat(detail.interes_pagar) +
              detail.seguro_pagar +
              detail.comision_pagar +
              detail.mora_pagar;
            detail.total_pendiente = detail.monto_pagar;
            detail.tipo_transaccion = element.tipo_transaccion;
            detail.tasa_interes = element.tasa_interes;
          }
        }
      }
      this.setState({
        loans: loans,
        selected: {},
        payment: this.state.paymentType,
        totalAmountToPay: 0,
        depositBankAccounts: depositBankAccounts,
        bankAccounts: bankAccounts,
      });
    } catch (err) {
      this.printMessage(err, "error");
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  // Convertir un numero a fraccion
  numberToFraction(amount) {
    let gcd = function(a, b) {
      if (b < 0.0000001) {
        return a;
      }
      return gcd(b, Math.floor(a % b));
    };
    let len = amount.toString().length - 2;
    let denominator = Math.pow(10, len);
    let numerator = amount * denominator;
    let divisor = gcd(numerator, denominator);
    numerator /= divisor;
    denominator /= divisor;

    let result = {
      numerator: Math.floor(numerator),
      denominator: Math.floor(denominator),
    };

    return result;
  }

  // Calcular la configuracion del modal
  async calculateConfig(data) {
    this.setState({
      calculate: false,
    });

    // Declarar variables
    let condonarPrincipal = false;
    let montoRecibido = 0;
    let montoAplicado = 0;
    let interesesCorridos = 0;
    let totalDescuentos = 0;
    let totalInteresesCorridos = 0;
    let montoInteresesCondonados = 0;
    let montoOtrosCargos = 0;
    let montoGastosLegales = 0;
    let montoPrincipalCondonado = 0;
    let montoAtrasado = 0;
    let total = 0;

    // Calcular monto recibido, monto aplicado e intereses corridos
    if (data.agregar_intereses) {
      const montoAplicadoFraccion = this.numberToFraction(
        parseFloat(data.monto_aplicado)
      );

      const interesesCorridosFraccion =
        data.configuracion_financiera === "A VENCIMIENTO" &&
        !data.tipo_transaccion === "ORDINARIO EXPRESO"
          ? this.numberToFraction(parseFloat(data.tasa_interes_anual) / 100)
          : this.numberToFraction(parseFloat(data.tasa_interes) / 100);

      const factorPeriodicidad = new algebra.Fraction(
        data.dias_transcurridos,
        data.period.days
      );
      const interesesCorridosFormula = new algebra.Fraction(
        interesesCorridosFraccion.numerator,
        interesesCorridosFraccion.denominator || 1
      ).multiply(factorPeriodicidad);

      const montoRecibidoFormula = new algebra.Expression("x");
      const montoAplicadoFormula = new algebra.Fraction(
        montoAplicadoFraccion.numerator,
        montoAplicadoFraccion.denominator || 1
      );

      const interesesCorridosExpresion = montoAplicadoFormula.multiply(
        interesesCorridosFormula
      );

      const primeraExpresion = montoRecibidoFormula;

      // Se suman los intereses mas el monto aplicado y se convierte en fraccion para poder
      // conviertirla en fraccion algebraica y usarla en la equacion. Adicionalmente,
      // se usa de esta forma para evitar que la fraccion no sea divisible entre decimales
      const suma_aplicado_intereses = this.numberToFraction(
        montoAplicadoFormula + interesesCorridosExpresion
      );

      const sumaAplicadoInteresesFormula = new algebra.Fraction(
        suma_aplicado_intereses.numerator,
        suma_aplicado_intereses.denominator || 1
      );

      const segundaExpresion = new algebra.Expression(
        sumaAplicadoInteresesFormula
      );

      const equacion = new algebra.Equation(primeraExpresion, segundaExpresion);
      const montoRecibidoValor = parseFloat(
        equacion
          .solveFor("x")
          .valueOf()
          .toString()
      ).toFixed(2);

      const interesesCorridosValor = parseFloat(
        montoRecibidoValor - data.monto_aplicado
      ).toFixed(2);

      montoRecibido =
        data.configuracion_financiera === "A VENCIMIENTO"
          ? parseFloat(
              parseFloat(montoRecibidoValor) +
                parseFloat(data.detail[0].seguro_pagar) +
                parseFloat(data.detail[0].mora_pagar) +
                parseFloat(data.detail[0].comision_pagar)
            ).toFixed(2)
          : montoRecibidoValor;
      montoAplicado = data.monto_aplicado;
      interesesCorridos = interesesCorridosValor;
    } else {
      const montoRecibidoFraccion =
        data.configuracion_financiera === "A VENCIMIENTO"
          ? this.numberToFraction(
              parseFloat(
                data.monto_recibido -
                  data.detail[0].seguro_pagar -
                  data.detail[0].mora_pagar -
                  data.detail[0].comision_pagar
              )
            )
          : this.numberToFraction(parseFloat(data.monto_recibido));
      const interesesCorridosFraccion =
        data.configuracion_financiera === "A VENCIMIENTO" &&
        !data.tipo_transaccion === "ORDINARIO EXPRESO"
          ? this.numberToFraction(parseFloat(data.tasa_interes_anual) / 100)
          : this.numberToFraction(parseFloat(data.tasa_interes) / 100);

      const factorPeriodicidad = new algebra.Fraction(
        data.dias_transcurridos,
        data.period.days
      );
      const interesesCorridosNoExpresion = new algebra.Fraction(
        interesesCorridosFraccion.numerator,
        interesesCorridosFraccion.denominator || 1
      ).multiply(factorPeriodicidad);

      const montoRecibidoExpresion = new algebra.Fraction(
        montoRecibidoFraccion.numerator,
        montoRecibidoFraccion.denominator || 1
      );
      const montoAplicadoExpresion = new algebra.Expression("x");

      const interesesCorridosExpresion = montoAplicadoExpresion.multiply(
        interesesCorridosNoExpresion
      );
      const primeraExpresion = new algebra.Expression(montoRecibidoExpresion);
      const segundaExpresion = montoAplicadoExpresion.add(
        interesesCorridosExpresion
      );
      const equacion = new algebra.Equation(primeraExpresion, segundaExpresion);
      const montoAplicadoValor = parseFloat(
        equacion
          .solveFor("x")
          .valueOf()
          .toString()
      ).toFixed(2);
      const interesesCorridosValor =
        data.configuracion_financiera === "A VENCIMIENTO" &&
        !data.tipo_transaccion === "ORDINARIO EXPRESO"
          ? parseFloat(
              data.monto_recibido -
                montoAplicadoValor -
                data.detail[0].seguro_pagar -
                data.detail[0].mora_pagar -
                data.detail[0].comision_pagar
            ).toFixed(2)
          : parseFloat(data.monto_recibido - montoAplicadoValor).toFixed(2);

      montoRecibido = data.monto_recibido;
      // montoAplicado = data.configuracion_financiera === "A VENCIMIENTO" ?  montoAplicadoValor - data.detail[0].seguro_pagar - data.detail[0].mora_pagar - data.detail[0].comision_pagar : montoAplicadoValor;
      montoAplicado = montoAplicadoValor;
      interesesCorridos = interesesCorridosValor;
    }

    // Asignar valores si el checkbox fue marcado
    if (data.esta_atrasado) {
      montoAtrasado = data.monto_atrasado;
    }

    if (data.is_prepaid_interest) {
      const intereses = interesesCorridos - data.prepaid_amount;
      totalInteresesCorridos = intereses <= 0 ? 0 : intereses;
      interesesCorridos = totalInteresesCorridos;
      montoRecibido = montoAplicado + interesesCorridos;
    }

    if (data.condonar_intereses_corridos) {
      montoInteresesCondonados =
        parseFloat(data.intereses_corridos) > interesesCorridos
          ? interesesCorridos
          : data.intereses_corridos;
      condonarPrincipal =
        parseFloat(montoInteresesCondonados) < interesesCorridos
          ? false
          : data.condonar_principal;
      totalInteresesCorridos = parseFloat(
        interesesCorridos - montoInteresesCondonados
      ).toFixed(2);
    } else {
      totalInteresesCorridos = interesesCorridos;
    }

    if (data.agregar_cargos) {
      montoOtrosCargos = data.monto_otros_cargos;
      montoGastosLegales = data.monto_gastos_legales;
    }

    if (condonarPrincipal) {
      montoPrincipalCondonado =
        parseFloat(data.monto_condonamiento_principal) > montoAplicado
          ? montoAplicado
          : data.monto_condonamiento_principal;
    }

    // Calcular total de descuentos
    totalDescuentos = parseFloat(
      parseFloat(montoInteresesCondonados) + parseFloat(montoPrincipalCondonado)
    ).toFixed(2);

    // Calcular el total
    if (data.configuracion_financiera === "A VENCIMIENTO") {
      if (data.tipo_transaccion === "ORDINARIO EXPRESO") {
        total = parseFloat(
          parseFloat(montoAplicado) +
            parseFloat(montoAtrasado) +
            parseFloat(interesesCorridos) +
            parseFloat(montoGastosLegales) +
            parseFloat(montoOtrosCargos) -
            parseFloat(totalDescuentos) +
            parseFloat(data.detail[0].mora_pagar)
        ).toFixed(2);
      } else {
        total = parseFloat(
          parseFloat(montoAplicado) +
            parseFloat(montoAtrasado) +
            parseFloat(interesesCorridos) +
            parseFloat(montoGastosLegales) +
            parseFloat(montoOtrosCargos) -
            parseFloat(totalDescuentos) +
            parseFloat(data.detail[0].seguro_pagar) +
            parseFloat(data.detail[0].mora_pagar) +
            parseFloat(data.detail[0].comision_pagar)
        ).toFixed(2);
        // montoRecibido = parseFloat(parseFloat(montoRecibido) + parseFloat(montoAtrasado) + data.detail[0].seguro_pagar + data.detail[0].mora_pagar + data.detail[0].comision_pagar).toFixed(2);
      }
    } else {
      total = parseFloat(
        parseFloat(montoAplicado) +
          parseFloat(montoAtrasado) +
          parseFloat(interesesCorridos) +
          parseFloat(montoGastosLegales) +
          parseFloat(montoOtrosCargos) -
          parseFloat(totalDescuentos)
      ).toFixed(2);
      // montoRecibido = parseFloat(parseFloat(montoRecibido) + parseFloat(montoAtrasado)).toFixed(2);
    }

    // total = parseFloat(parseFloat(montoAplicado) + parseFloat(montoAtrasado) + parseFloat(interesesCorridos) + parseFloat(montoOtrosCargos) - parseFloat(totalDescuentos)).toFixed(2);
    montoRecibido = parseFloat(
      parseFloat(montoRecibido) + parseFloat(montoAtrasado)
    ).toFixed(2);

    // Asignar los valores al modal
    data = {
      ...data,
      monto_recibido: montoRecibido,
      monto_intereses_corridos: interesesCorridos,
      monto_aplicado: montoAplicado,
      monto_condonamiento_principal: montoPrincipalCondonado,
      condonar_principal: condonarPrincipal,
      intereses_corridos: montoInteresesCondonados,
      total_intereses_corridos: totalInteresesCorridos,
      total_otros_cargos: montoOtrosCargos,
      total_gastos_legales: montoGastosLegales,
      total_descuentos: totalDescuentos,
      total_pagar: total,
    };

    data.proxima_cuota = await this.calculateQuota(data);
    this.setState(
      {
        modalData: data,
      },
      () => {
        this.setState({
          calculate: true,
        });
      }
    );
  }

  // Guardar la configuracion realizada en el modal
  saveConfig() {
    let index = this.state.loansDetail.findIndex(
      (value) => value.id_dbs_rohi === this.state.modalData.id_dbs_rohi
    );
    let loans = [...this.state.loansDetail];
    loans.splice(index, 1, { ...this.state.modalData });
    this.setState({
      loansDetail: loans,
      openModal: !this.state.openModal,
    });
  }

  // Calcular la proxima cuota
  async calculateQuota(data) {
    const { token } = this.props.user;
    let nextQuota = 0;

    if (parseFloat(data.monto_aplicado) < parseFloat(data.monto_actual)) {
      const balance = data.monto_actual - data.monto_aplicado;
      const interestRate = data.tasa_interes / 100;
      const interest = balance * interestRate;
      if (data.configuracion_financiera === "SOLO INTERES") {
        if (data.periodos_restantes === 1) {
          nextQuota =
            balance +
            interest +
            data.detail[0].comision_vencida +
            data.detail[0].seguro_vencido;
        } else {
          nextQuota =
            interest +
            data.detail[0].comision_vencida +
            data.detail[0].seguro_vencido;
        }
      } else if (data.configuracion_financiera === "ESCALONADA") {
        if (data.metodologia_pago === "total_balance") {
          return nextQuota;
        } else if (data.metodologia_pago === "term_reduction") {
          const quotaPrincipal =
            data.detail[0].total_vencido -
            interest -
            data.detail[0].comision_vencida -
            data.detail[0].seguro_vencido -
            data.detail[0].mora_pagar;
          const principal = quotaPrincipal > balance ? balance : quotaPrincipal;
          nextQuota =
            principal +
            data.detail[0].comision_vencida +
            data.detail[0].seguro_vencido +
            interest;
        } else if (data.metodologia_pago === "quota_reduction") {
          let options = {
            periods: parseFloat(data.periodos_restantes),
            amount: parseFloat(balance),
            interest: parseFloat(data.tasa_interes),
          };

          try {
            nextQuota =
              (await calculateNextQuota(token, options)).data.nextQuota +
              data.detail[0].comision_vencida +
              data.detail[0].seguro_vencido;
          } catch (err) {
            this.printMessage(err, "error");
          }
        }
      } else if (data.configuracion_financiera === "A VENCIMIENTO") {
        nextQuota = data.monto_total - data.monto_recibido;
      }
    } else {
      nextQuota = 0;
    }
    return parseFloat(nextQuota).toFixed(2);
  }

  generateFile() {
    this.setState({ loading: true });
    const { token } = this.props.user;
    const options = {
      client: this.state.clientId,
      loans: this.state.loansDetail,
      total: this.state.totalAmountToPay,
      print_date: new Date().toISOString().split("T")[0],
      calculate_date: this.state.participantPaymentDay
        .toISOString()
        .split("T")[0],
      payment_type: this.state.paymentType,
      payment_method: this.state.paymentMethod,
      origin_account: this.state.depositBankAccount,
      destination_account: this.state.bankAccount,
      check_number: this.state.check,
    };

    axios({
      headers: {
        Authorization: "Bearer " + token,
      },
      method: "post",
      url: `${API_KEY}/outlay/outlay/payment_calculator_print/`,
      data: options,
    })
      .then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const NewWindow = window.open();
        NewWindow.location.href = fileURL;
      })
      .catch((err) => {
        this.printMessage(err, "error");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleSaveCalcInfo() {
    this.props.setDialogClose(false);
    return {
      total: this.state.totalAmountToPay,
      table: this.state.loansDetail,
    };
  }

  render() {
    const {
      openAlert,
      alertMessage,
      severity,
      alertTitle,
      loading,
    } = this.state;
    const classes = styles;
    const montoEnAtraso =
      this.state.modalData?.configuracion_financiera === "A VENCIMIENTO"
        ? this.state.modalData?.mora
        : this.state.modalData?.monto_atrasado;

    return (
      <div className="quotation-container"> 
        <Snackbar
          open={openAlert}
          // autoHideDuration={6000}
          onClose={() => this.switchState("openAlert")}
        >
          <MuiAlert
            onClose={() => this.switchState("openAlert")}
            severity={severity}
          >
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertMessage}
          </MuiAlert>
        </Snackbar>
        <LoadingDialog open={loading} />
        <ToolbarComponent
          typeVariant="h4"
          typeClass="title-text"
          toolbarTitle="Calculadora de Pagos"
        />
        <div
          style={{
            padding: "1%",
            border: "1px solid #EEEEEE",
            borderTop: "none",
          }}
        >
          <Grid container className="filters-container">
            <FiltersHeader
              typeVariant="subtitle1"
              typeClass="section-header"
              filtersTitle="Filtros"
              btnClass={`filter-btn-close ${this.state.hideClass}`}
              iconClass="filter-icon-close"
              toggleFilter={() => this.switchState("openFilter")}
              openFilter={this.state.openFilter}
              disableClose={this.state.disableCloseBtn}
            />
            <Grid
              container
              className={
                this.state.openFilter
                  ? "filter-content"
                  : "filter-content collided"
              }
            >
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2}>
                  {this.props.clientData ? (
                    <TextFieldComponent
                      xs={4}
                      containerClass="field-container"
                      fieldClass="fields"
                      typeVariant="subtitle1"
                      typeClass="field-label"
                      fieldLabel={translations.PARTICIPANT}
                      fieldID="client"
                      fieldInnerLabel="Nombre del participante"
                      fieldVariant="outlined"
                      margin="dense"
                      fullWidth
                      value={this.props.clientData}
                      disabled={true}
                    />
                  ) : (
                    <SearchComponent
                      xs={4}
                      containerClass="field-container"
                      fieldClass="fields"
                      typeVariant="subtitle1"
                      typeClass="field-label"
                      fieldLabel={translations.PARTICIPANT}
                      fieldID="client"
                      fieldInnerLabel="Nombre del participante"
                      fieldVariant="outlined"
                      handleChange={this.handleAutocompleteChange.bind(
                        this,
                        "client"
                      )}
                      optionType={"participants"}
                      limitTags={1}
                      // error={this.state.errors.client ? true : false}
                      // helperText={this.state.errors.client}
                      disabled={this.props.disabled}
                    />
                  )}
                  {/* <AutocompleteField
                    xs={4}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel={translations.PARTICIPANT}
                    fieldID="client"
                    fieldInnerLabel="Nombre del participante"
                    fieldVariant="outlined"
                    value={
                      this.state.client
                        ? this.state.clients.find(
                          (item) => item.rohiId === this.state.client
                        )
                        : null
                    }
                    handleChange={this.handleAutocompleteChange.bind(this, "client")}
                    options={this.state.clients}
                    getOptionLabel={(option) => option.fullName}
                    error={this.state.errors.client ? true : false}
                    helperText={this.state.errors.client}
                    required={true}
                    multiple={false}
                    limitTags={1}
                    disabled={this.props.disabled}
                  /> */}
                  <AutocompleteField
                    xs={4}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel={translations.FINANCIAL_CONFIG}
                    fieldID="financialConfig"
                    fieldInnerLabel="Seleccionar configuraciones"
                    fieldVariant="outlined"
                    value={this.state.financialConfig}
                    handleChange={(e, value) =>
                      this.handleFilterAutocompleteChange(
                        "financialConfig",
                        "financialConfigToSend",
                        value
                      )
                    }
                    options={financialConfig}
                    getOptionLabel={(option) => option.label}
                    multiple={true}
                    limitTags={3}
                    disabled={this.props.readOnly}
                  />
                  <AutocompleteField
                    xs={4}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel={translations.TRANSACTION_CLASS}
                    fieldID="transactionClass"
                    fieldInnerLabel="Seleccionar clasificaciones"
                    fieldVariant="outlined"
                    value={this.state.transactionClass}
                    handleChange={(e, value) =>
                      this.handleFilterAutocompleteChange(
                        "transactionClass",
                        "transactionClassToSend",
                        value
                      )
                    }
                    options={transactionClass}
                    getOptionLabel={(option) => option.label}
                    multiple={true}
                    limitTags={3}
                    disabled={this.props.readOnly}
                  />
                  <AutocompleteField
                    xs={4}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel={translations.PRODUCT_TYPE}
                    fieldID="productType"
                    fieldInnerLabel="Seleccionar productos"
                    fieldVariant="outlined"
                    value={this.state.productType}
                    handleChange={(e, value) =>
                      this.handleFilterAutocompleteChange(
                        "productType",
                        "productTypeToSend",
                        value
                      )
                    }
                    options={transactionTypes}
                    getOptionLabel={(option) => option.label}
                    multiple={true}
                    limitTags={3}
                    disabled={this.props.readOnly}
                  />
                  <NumericTextField
                    xs={4}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel={translations.PAYMENT_DAY}
                    fieldID="paymentDay"
                    fieldClass="fields"
                    placeholder={translations.PAYMENT_DAY}
                    margin="dense"
                    fieldVariant="outlined"
                    fullWidth
                    isAllowed={(values) => {
                      const { formattedValue, floatValue } = values;
                      return (
                        formattedValue === "" ||
                        (floatValue <= 30 && floatValue >= 1)
                      );
                    }}
                    allowLeadingZeros={false}
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    disabled={this.props.readOnly}
                    value={this.state.paymentDay}
                    onChange={this.handleNumericChange("paymentDay")}
                  />
                  <DateField
                    xs={4}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Fecha de pago"
                    dateFieldID="filter-participat-payment-day"
                    fullWidth={true}
                    dateFieldClass="date-field"
                    inputVariant="outlined"
                    dateFieldLabel="Fecha de pago"
                    format="dd/MM/yyyy"
                    value={this.state.participantPaymentDay}
                    dateType="participantPaymentDay"
                    disablePast={true}
                    clearable={false}
                    handleChange={(fullDate, shortDate) =>
                      this.handleDateChange(
                        "participantPaymentDay",
                        fullDate,
                        shortDate
                      )
                    }
                    disabled={this.props.readOnly}
                  />
                  <AutocompleteField
                    xs={4}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel={translations.PAYMET_TYPE}
                    fieldID="paymentType"
                    fieldInnerLabel={translations.PAYMET_TYPE}
                    fieldVariant="outlined"
                    value={
                      this.state.paymentType
                        ? paymentTypes.find(
                            (item) => item.value === this.state.paymentType
                          )
                        : null
                    }
                    handleChange={this.handleAutocompleteChange.bind(
                      this,
                      "paymentType"
                    )}
                    options={paymentTypes}
                    getOptionLabel={(option) => option.label}
                    multiple={false}
                    limitTags={1}
                    disableClearable={true}
                    disabled={
                      this.props.disabled ||
                      this.props.readOnly ||
                      Boolean(
                        this.props.filters && this.props.filters.paymentType
                      )
                    }
                  />
                </Grid>
              </Grid>
              {/* FILTERS CONTENT APPLY BUTTON */}
              {!this.props.readOnly && (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  style={{ marginTop: "35px" }}
                >
                  <Button
                    disableElevation
                    className="filter-clear-btn"
                    onClick={this.handleReset}
                  >
                    Limpiar
                    <ClearAll style={{ marginLeft: 8, fontSize: "19px" }} />
                  </Button>

                  <Button
                    disableElevation
                    className="filter-apply-btn"
                    onClick={this.applyFilters}
                  >
                    Aplicar filtros
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        {!this.props.readOnly && (
          <div
            style={{
              padding: "1%",
              border: "1px solid #EEEEEE",
              borderTop: "none",
              marginTop: "10px",
            }}
          >
            <PaymentTable
              data={this.state.loans}
              collapse={this.state.payment === "extraordinary" ? false : true}
              paymentType={this.state.payment}
              handleSelected={this.handleSelected}
              title="PRESTAMOS"
              subTitle="VENCIMIENTOS"
              noPagination={true}
              noDetailPagination={false}
            />
          </div>
        )}
        <div
          style={{
            padding: "1%",
            border: "1px solid #EEEEEE",
            borderTop: "none",
            marginTop: "10px",
          }}
        >
          <DetailPaymentTable
            // data={ this.state.loansDetail.length > 0 ? this.state.loansDetail : this.state.loans.detail || [] }
            data={this.state.loansDetail}
            collapse={false}
            paymentType={this.state.payment}
            title={
              this.state.payment === "extraordinary"
                ? "DETALLE DE PRESTAMOS"
                : "DETALLE DE VENCIMIENTOS"
            }
            discount={this.handleDiscount}
            openModal={this.state.openModal}
            switchState={this.switchState}
            handleModalData={this.handleModalData}
            print={true}
            disablePrint={this.state.loansDetail.length > 0 ? false : true}
            generateFile={this.generateFile.bind(this)}
            setDialogClose={this.props.setDialogClose}
            setCalculatorInfo={
              this.props.disabled
                ? () =>
                    this.props.setCalculatorInfo({
                      total: this.state.totalAmountToPay,
                      table: this.state.loansDetail,
                    })
                : ""
            }
            showSave={
              this.props.disabled && !this.props.fromTicket
                ? this.props.disabled
                : false
            }
            readOnly={this.props.readOnly}
          />
          <Modal
            open={this.state.openModal}
            onClose={() => this.switchState("openModal")}
          >
            <div className="calculator-container">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3 className="modal-title">Configuración Financiera</h3>
                  <Divider />
                </Grid>
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel={translations.PAYMENT_DAY}
                  fieldID="paymentDayConfig"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  disabled={true}
                  value={this.state.modalData.dia_pago}
                />
                <TextFieldComponent
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Periodicidad"
                  fieldID="filter-field-periodicity"
                  fieldClass="fields"
                  fieldVariant="outlined"
                  margin="dense"
                  fullWidth
                  value={this.state.modalData.periodicidad}
                  disabled={true}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Saldo actual"
                  fieldID="saldoActual"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={this.state.modalData.monto_actual}
                  disabled={true}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Tasa de interes"
                  fieldID="tasaInteres"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  suffix="%"
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={this.state.modalData.tasa_interes}
                  disabled={true}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Monto recibido"
                  fieldID="montoRecibido"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return (
                      floatValue >= 0 &&
                      floatValue <= parseFloat(this.state.modalData.monto_total)
                    );
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onChange={this.handleNumericModalChange.bind(
                    this,
                    "monto_recibido"
                  )}
                  value={this.state.modalData.monto_recibido}
                  disabled={
                    this.state.modalData.agregar_intereses ||
                    this.state.modalData.esta_atrasado ||
                    this.props.readOnly
                  }
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Monto aplicado"
                  fieldID="interest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return (
                      floatValue >= 0 &&
                      floatValue <=
                        parseFloat(this.state.modalData.monto_actual)
                    );
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onChange={this.handleNumericModalChange.bind(
                    this,
                    "monto_aplicado"
                  )}
                  value={this.state.modalData.monto_aplicado}
                  disabled={
                    !this.state.modalData.agregar_intereses ||
                    this.state.modalData.esta_atrasado ||
                    this.props.readOnly
                  }
                />
                <AutocompleteField
                  xs={4}
                  fieldClass="fields"
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Metodología de Calculo"
                  fieldID="calculateType"
                  fieldInnerLabel="Seleccione la metodología"
                  fieldVariant="outlined"
                  disableClearable={true}
                  value={
                    this.state.modalData.metodologia_pago
                      ? termReduction.find(
                          (item) =>
                            item.value === this.state.modalData.metodologia_pago
                        )
                      : null
                  }
                  handleChange={this.handleAutocompleteModalChange.bind(
                    this,
                    "metodologia_pago"
                  )}
                  options={termReduction}
                  getOptionLabel={(option) => option.label}
                  multiple={false}
                  limitTags={1}
                  containerClass={
                    this.state.modalData.configuracion_financiera !==
                    "ESCALONADA"
                      ? "hide"
                      : "field-container"
                  }
                  disabled={
                    this.state.modalData.esta_atrasado || this.props.readOnly
                  }
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3 className="modal-title">Intereses Corridos</h3>
                  <Divider />
                </Grid>
                <DateField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Fecha del último vencimiento"
                  dateFieldID="filter-last-outlay-date"
                  fullWidth={true}
                  dateFieldClass="date-field"
                  inputVariant="outlined"
                  dateFieldLabel="Fecha del último vencimiento"
                  format="dd/MM/yyyy"
                  value={this.state.modalData.fecha_ultimo_vencimiento}
                  dateType="participantPaymentDay"
                  disabled={true}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Días transcurridos"
                  fieldID="paymentDayConfig"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  disabled={true}
                  value={this.state.modalData.dias_transcurridos}
                />
                <CheckboxField
                  xs={4}
                  check={this.state.modalData.agregar_intereses}
                  handleChange={this.switchModalState.bind(
                    this,
                    "agregar_intereses"
                  )}
                  label="Agregar intereses al monto recibido?"
                  alignItems="flex-end"
                  color="primary"
                  disabled={
                    this.state.modalData.esta_atrasado || this.props.readOnly
                  }
                />
                <CheckboxField
                  xs={4}
                  check={this.state.modalData.condonar_intereses_corridos}
                  handleChange={this.switchModalState.bind(
                    this,
                    "condonar_intereses_corridos"
                  )}
                  label="Condonar interes corrido?"
                  alignItems="flex-end"
                  color="primary"
                  disabled={this.props.readOnly}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Monto interes corrido condonado"
                  fieldID="interest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return (
                      floatValue >= 0 &&
                      floatValue <=
                        this.state.modalData.monto_intereses_corridos
                    );
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onChange={this.handleNumericModalChange.bind(
                    this,
                    "intereses_corridos"
                  )}
                  value={this.state.modalData.intereses_corridos}
                  containerClass={
                    this.state.modalData.condonar_intereses_corridos
                      ? ""
                      : "hide"
                  }
                  disabled={
                    this.state.modalData.monto_intereses_corridos === 0 ||
                    this.props.readOnly
                  }
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Total de intereses corridos"
                  fieldID="totalInterest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 0;
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onChange={this.handleNumericModalChange.bind(
                    this,
                    "monto_intereses_corridos"
                  )}
                  value={this.state.modalData.monto_intereses_corridos}
                  disabled={true}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3 className="modal-title">Otros Cargos</h3>
                  <Divider />
                </Grid>
                <CheckboxField
                  xs={4}
                  check={this.state.modalData.agregar_cargos}
                  handleChange={this.switchModalState.bind(
                    this,
                    "agregar_cargos"
                  )}
                  label="Agregar otros cargos?"
                  alignItems="flex-end"
                  color="primary"
                  disabled={this.props.readOnly}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Monto otros cargos"
                  fieldID="interest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 0;
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onChange={this.handleNumericModalChange.bind(
                    this,
                    "monto_otros_cargos"
                  )}
                  value={this.state.modalData.monto_otros_cargos}
                  containerClass={
                    this.state.modalData.agregar_cargos ? "" : "hide"
                  }
                  disabled={this.props.readOnly}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Monto gastos legales"
                  fieldID="legalcharges"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 0;
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onChange={this.handleNumericModalChange.bind(
                    this,
                    "monto_gastos_legales"
                  )}
                  value={this.state.modalData.monto_gastos_legales}
                  containerClass={
                    this.state.modalData.agregar_cargos ? "" : "hide"
                  }
                  disabled={this.props.readOnly}
                />
                <CheckboxField
                  xs={4}
                  check={this.state.modalData.condonar_principal}
                  handleChange={this.switchModalState.bind(
                    this,
                    "condonar_principal"
                  )}
                  label="Condonar principal?"
                  alignItems="flex-end"
                  color="primary"
                  disabled={
                    this.state.modalData.intereses_corridos <
                      this.state.modalData.monto_intereses_corridos ||
                    !this.state.modalData.condonar_intereses_corridos ||
                    this.props.readOnly
                  }
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Monto condonamiento principal"
                  fieldID="interest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return (
                      floatValue >= 0 &&
                      floatValue <=
                        parseFloat(this.state.modalData.monto_aplicado)
                    );
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onChange={this.handleNumericModalChange.bind(
                    this,
                    "monto_condonamiento_principal"
                  )}
                  value={this.state.modalData.monto_condonamiento_principal}
                  containerClass={
                    this.state.modalData.condonar_principal ? "" : "hide"
                  }
                  disabled={this.props.readOnly}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3 className="modal-title">Totales</h3>
                  <Divider />
                </Grid>
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Monto aplicado"
                  fieldID="interest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 0;
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onChange={this.handleNumericModalChange.bind(
                    this,
                    "monto_aplicado"
                  )}
                  value={this.state.modalData.monto_aplicado}
                  disabled={true}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Total de intereses corridos recibidos"
                  fieldID="interest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 0;
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={this.state.modalData.total_intereses_corridos}
                  disabled={true}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Monto Seguro"
                  fieldID="seguro"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  containerClass={ "field-container" }
                  fixedDecimalScale={true}
                  value={this.state.modalData.seguro}
                  disabled={true}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Descuentos"
                  fieldID="interest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 0;
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={this.state.modalData.total_descuentos}
                  disabled={true}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Otros cargos"
                  fieldID="interest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 0;
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={this.state.modalData.total_otros_cargos}
                  disabled={true}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Gastos legales"
                  fieldID="interest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 0;
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={this.state.modalData.total_gastos_legales}
                  disabled={true}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Monto en atraso"
                  fieldID="interest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 0;
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={montoEnAtraso}
                  disabled={true}
                />
                <NumericTextField
                  xs={4}
                  typeVariant="subtitle1"
                  typeClass="field-label"
                  fieldLabel="Total a pagar"
                  fieldID="interest"
                  fieldClass="fields"
                  margin="dense"
                  fieldVariant="outlined"
                  fullWidth
                  prefix="$"
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue >= 0;
                  }}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={this.state.modalData.total_pagar}
                  disabled={true}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3 className="modal-title">Acciones</h3>
                  <Divider />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Button
                    style={classes.calculate}
                    className={
                      this.props.readOnly ? "action-method-btn disabled" : ""
                    }
                    disabled={this.props.readOnly}
                    onClick={async () => {
                      await this.calculateConfig(this.state.modalData);
                      this.saveConfig();
                    }}
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Guardar
                  </Button>
                  <Button
                    style={classes.clean}
                    onClick={this.switchState.bind(this, "openModal")}
                  >
                    <FontAwesomeIcon icon={faClosedCaptioning} />
                    &nbsp; Cerrar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>
        </div>
        {!this.props.fromTicket && (
          <div
            style={{
              padding: "1%",
              border: "1px solid #EEEEEE",
              borderTop: "none",
              marginTop: "10px",
            }}
          >
            <Grid
              container
              className="filters-container"
              direction="row"
              spacing={2}
            >
              <NumericTextField
                xs={4}
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="Monto recibido total"
                fieldID="receivedAmount"
                fieldClass="fields"
                margin="dense"
                fieldVariant="outlined"
                fullWidth
                prefix="$"
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue >= 0;
                }}
                allowLeadingZeros={false}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                value={this.state.totalReceivedAmount}
                containerClass={
                  this.state.payment === "extraordinary" ? "" : "hide"
                }
                disabled={true}
              />
              <NumericTextField
                xs={4}
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel={"Monto total a pagar"}
                fieldID="totalAmountToPay"
                fieldClass="fields"
                margin="dense"
                fieldVariant="outlined"
                fullWidth
                prefix="$"
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue >= 0;
                }}
                allowLeadingZeros={false}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                value={this.state.totalAmountToPay}
              />
              <AutocompleteField
                xs={4}
                fieldClass="fields"
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel={translations.PAYMENT_METHOD}
                fieldID="client"
                fieldInnerLabel="Seleccione el método de pago"
                fieldVariant="outlined"
                value={
                  this.state.paymentMethod
                    ? this.state.creditMethods.find(
                        (item) => item.label === this.state.paymentMethod
                      )
                    : null
                }
                handleChange={this.handleAutocompleteChange.bind(
                  this,
                  "paymentMethod"
                )}
                options={this.state.creditMethods}
                getOptionLabel={(option) => option.label}
                required={true}
                multiple={false}
                disableClearable={true}
                limitTags={1}
                disabled={this.props.disabled || this.props.readOnly}
              />
              <AutocompleteField
                xs={4}
                fieldClass="fields"
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel={"Cuenta de origen"}
                fieldID="client"
                fieldInnerLabel="Seleccione la cuenta"
                fieldVariant="outlined"
                value={
                  this.state.depositBankAccount
                    ? this.state.depositBankAccounts.find(
                        (item) => item.number === this.state.depositBankAccount
                      )
                    : null
                }
                handleChange={this.handleAutocompleteChange.bind(
                  this,
                  "depositBankAccount"
                )}
                options={this.state.depositBankAccounts}
                getOptionLabel={(option) => option.name + " - " + option.number}
                required={true}
                multiple={false}
                limitTags={1}
                containerClass={
                  this.state.paymentMethod === "Transferencia bancaria"
                    ? ""
                    : "hide"
                }
                disabled={this.props.readOnly}
              />
              <AutocompleteField
                xs={4}
                fieldClass="fields"
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel={"Cuenta de destino"}
                fieldID="client"
                fieldInnerLabel="Seleccione la cuenta"
                fieldVariant="outlined"
                value={
                  this.state.bankAccount
                    ? this.state.bankAccounts.find(
                        (item) => item.number === this.state.bankAccount
                      )
                    : null
                }
                handleChange={this.handleAutocompleteChange.bind(
                  this,
                  "bankAccount"
                )}
                options={this.state.bankAccounts}
                getOptionLabel={(option) => option.name + " - " + option.number}
                required={true}
                multiple={false}
                limitTags={1}
                containerClass={
                  this.state.paymentMethod === "Transferencia bancaria"
                    ? ""
                    : "hide"
                }
                disabled={this.props.readOnly}
              />
              <NumericTextField
                xs={4}
                typeVariant="subtitle1"
                typeClass="field-label"
                fieldLabel="No. Cheque"
                fieldID="check"
                fieldClass="fields"
                placeholder="Escriba el número de cheque"
                margin="dense"
                fieldVariant="outlined"
                fullWidth
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue > 0;
                }}
                allowLeadingZeros={false}
                thousandSeparator={false}
                decimalScale={0}
                fixedDecimalScale={true}
                disabled={this.props.readOnly}
                value={this.state.check}
                onChange={this.handleNumericChange("check")}
                containerClass={
                  this.state.paymentMethod === "Cheque" ? "" : "hide"
                }
              />
            </Grid>
          </div>
        )}
        {this.props.setCalculatorInfo && (
          <Grid
            xs={12}
            item
            container
            justifyContent={"flex-end"}
            style={{ margin: "2rem 0" }}
          >
            <Button
              variant="contained"
              disableElevation
              style={{
                width: 95,
                borderRadius: 3,
                backgroundColor: "#4CAF50",
                color: "#FFFFFF",
                fontSize: 12,
              }}
              className={
                this.props.readOnly ||
                this.state.loansDetail.length < 1 ||
                this.props.fromTicket === "paymentReceipt"
                  ? "action-method-btn disabled"
                  : "action-method-btn"
              }
              disabled={
                this.props.readOnly ||
                this.state.loansDetail.length < 1 ||
                this.props.fromTicket === "paymentReceipt"
              }
              onClick={() =>
                this.props.setCalculatorInfo(
                  ["paymentTicket", "paymentReceipt"].includes(
                    this.props.fromTicket
                  )
                    ? {
                        totalToPay: this.state.totalAmountToPay,
                        loanClass: this.state.transactionClass,
                        financialConfig: this.state.financialConfig,
                        paymentDay: this.state.paymentDay || 0,
                        productType: this.state.productType,
                        paymentType: this.state.paymentType,
                        expirationDetails: this.state.loansDetail,
                      }
                    : this.handleSaveCalcInfo()
                )
              }
            >
              guardar
            </Button>
          </Grid>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(PaymentCalculator));
