import * as React from 'react';

import { Drawer } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

import MenuList from '../../containers/MenuList';

const drawerWidth = 250;

const styles:any = createStyles({
    drawerPaper: {
        bottom: 0,
        padding: 0,
        position: 'absolute',
        top: 65,
        width: drawerWidth
    },
    root: {
        bottom: 0,
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        position: 'fixed',
        top:0
    }
});

interface IMenuProps {
    classes:  {
        border: string,
        content: string;
        drawerPaper: string;
        root: string;
    },
}
function Menu(props: IMenuProps){
    const { classes } = props;
    return (
        <div className={classes.root}>
            <Drawer
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                    
                }}
            >
                <MenuList />
            </Drawer>
        </div>
    );
} 
export default withStyles(styles)(Menu);