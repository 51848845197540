import React, { useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Popup from "../../../UI/Popup";
import LastDepositForm from "./LastDepositForm";

export default function LastDepositTable(props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const { data, headers, salary } = props;

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addOrEdit = (row) => {
    if (row.id === 0) {
      row.id = data.length + 1;
      data.push(row);
      props.assingProportion(data);
    } else {
      let recordIndex = data.findIndex((x) => x.id === row.id);
      data[recordIndex] = { ...row };
      props.assingProportion(data);
    }
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  return (
    <>
      <Paper>
        <Table style={{ textAlignLast: "center" }} size="small">
          <TableHead>
            <TableRow>
              {headers.map((item, indx) => {
                return <TableCell key={indx}>{item.name}</TableCell>;
              })}
              <TableCell>
                <IconButton
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, indx) =>
              indx <= 2 ? (
                <TableRow key={item.id}>
                  <TableCell>{item.amount.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                  <TableCell>{item.date}</TableCell>
                  <TableCell>{`${item.proportion}%`}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => openInPopup(item)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => props.handleRemove(indx, "lastDeposits")}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ) : null
            )}
          </TableBody>
        </Table>
      </Paper>
      <Popup title="Ultimos depositos" open={openPopup} setOpen={setOpenPopup}>
        <LastDepositForm addOrEdit={addOrEdit} salary={salary} recordForEdit={recordForEdit} />
      </Popup>
    </>
  );
}
