import React, { useState, useEffect } from 'react';
import {
    Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination,
    Button, Paper, Grid, Toolbar, Typography, TextField, InputAdornment, IconButton, Popover, Select, MenuItem
} from '@material-ui/core';
import {
    FiberManualRecord, Settings, Visibility, MoreHoriz,
    Search, Edit, DeleteOutline, CloseOutlined,
    CheckCircle, Delete, Link, ArrowDownward, PlayCircleFilled, CheckCircleOutline, PauseCircleFilled,
    EventNote
} from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import { AutocompleteField, NumericTextField, RadioGroupComponent, SearchTextField, TableSearch } from './../../../components/SharedComponents/Fields';
import { GeneralTable } from './../../../components/SharedComponents/Table';
import {
    useHandleSelectAllRows, useHandleSelectRow, useIsSelected
} from '../../../components/SharedComponents/CustomHooks';
import { createInfrastructure, createPods, deleteInfrastructure, deletePods, updateAssignParticipants, updateInfrastructure, updatePaymentSetting, fetchBotLink, updateSpecificOrder } from './../../../utils/axios';
import NumberFormat from './../../../components/SharedComponents/NumberFormat';
import { ExpiryStates, ProductTypeTranslations } from '../../../components/SharedComponents/DataListTypes';
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';
import { expirationStatus } from 'src/utils';
// import { BotChargeAction } from '../DataLayers';

function DataGridTable(props) {
    return(
        <div style={{ height: props.height ? props.height : 'none', width: '100%'}}>
            <DataGrid
                rows={props.rows}
                columns={props.columns}
                pageSize={props.pageSize}
                checkboxSelection={props.checkboxSelection}
                disableSelectionOnClick
                hideFooter={props.hideFooter}
                rowHeight={props.rowHeight}
                className={props.className}
                error={props.error}
                components={props.components}
                onSelectionChange={props.onSelectionChange}
                // autoHeight={true}
            />
        </div>
    );
}

function PlainClientsTable(props) {

    const [tableData, setTableData] = useState(props.data); // state to mutate the table data
    const [selected, setSelected] = useState([]);
    const [openExpiryInfo, setOpenExpiryInfo] = useState({});
    // const [test, setTesting] = useState(document.querySelector('.general-table-container').scrollLeft);
    const expiriesColors = [
        {color: '#43A047', state: 'Complete'},
        {color: '#f44336', state: 'Late'},
        {color: '#ffeb3b', state: 'Partially paid'},
        {color: '#039be5', state: 'Programmed'},
        {color: '#ef6c00', state: 'Error'},
        {color: '#5142f5', state: 'On process'},
    ]
    const handleOpenExpiryInfo = (key, event) => {
        event.stopPropagation();
        openExpiryInfo[key] ? setOpenExpiryInfo({...openExpiryInfo, [key]: null}) : setOpenExpiryInfo({...openExpiryInfo, [key]: event.currentTarget})
    }
    const handleOrderChange = (row, event) => {
        const currentOrder = row.order;
        row.order = event.value;
        row.error = false;
        if(event.value === '') {
            row.error = 'empty'
            props.setError(true);
        } else {
            row.error = false;
            props.setError(false);
        }
        const removeErr = tableData.map(item => {
            if(item.order === currentOrder) {
                item.error = false;
            }
            return item;
        });
        setTableData(removeErr);
    }

    const modifyPaymentSetting = (row) => {
        props.setLoading(true);
        const endpoint = updatePaymentSetting(props.token, {
            "specificOrder": props.specificOrder,
            "expiration": row.uniqueId,
            "bankProfile": row.bankProfile,
            "chargingAccount": !row.charge_config.clientAccounts.uniqueId ? row.charge_config.clientAccounts : row.charge_config.clientAccounts.uniqueId,
            "orderNumber": Number(row.order),
            "irregularAmount": Number(row.charge_config.irregularAmount),
            "matchPayroll": row.charge_config.matchPayroll,
            "manualFillCaptcha": row.charge_config.captcha,
            "noticeControlAmount": Number(row.charge_config.controlAmountNotice),
            "chargeControlAmount": Number(row.charge_config.controlAmountCharge),
            "doNotCheck": row.charge_config.botAction === 'no_check' && true,
            "doNotCharge": row.charge_config.botAction === 'no_charge' && true,
            "amountPerAccount": !row.charge_config.amountPerAccount ? row.charge_config.destinyAccounts : row.charge_config.amountPerAccount,
            "checkChargingAccounts": row.charge_config.checkChargingAccounts,
            "uniqueId": row.charge_config.uniqueId,
        })
        endpoint.then(() => {
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }

    const handleChangeBotAction = (row, event, value) => {
        event.stopPropagation();
        if(typeof event.target.value === 'undefined') {
            // event.preventDefault();
            return;
        }
        // const value = event.target.value;
        // event.preventDefault();
        const newVal =
            event.target.value === row.charge_config.botAction ? "" : event.target.value;

        row.charge_config.botAction = newVal;
        modifyPaymentSetting(row);
    }
    const getBotLink = (paymentSettingId) => {
        props.setLoading(true);
        const endpoint = fetchBotLink(props.token, paymentSettingId);
        endpoint.then(response => {
            const link = response.data.link;
            if(link) {
                window.open(link, '_blank').focus();
            }else {
                props.handleToggleConfirmDialog('simple-dialog', 'No se ha encontrado el enlace del vencimiento seleccionado');
            }
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleConfirmDialog);
        });
    }

    const handleBotLink = (row, event) => {
        event.stopPropagation();
        getBotLink(row.charge_config.uniqueId);
    }

    const orderElement = (row) => {
        return(
            props.order === 'customOrder' ?
                <NumericTextField
                    xs={12}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldID={"text-field-order-custom"+row.uniqueId}
                    fieldClass="fields transparent"
                    placeholder="Orden"
                    fieldVariant="standard"
                    thousandSeparator={true}
                    value={row.order}
                    onChange={handleOrderChange.bind(this, row)}
                    error={Boolean(row.error)}
                    helperText={row.error === "empty" ? "Introduzca un valor" : row.error ? "Este orden ya existe." : null}
                />
            :
            <span>{row.order}</span>
        );
    }
    const financialElement = (row) => {
        return(
            <Button
                variant="contained"
                disableElevation
                style={{width:25, height: 25, borderRadius:20, backgroundColor:'#43A047', color:'#FFFFFF', fontSize:12}}
                onClick={props.handleToggleDialog.bind(this, 'financial-profile', row)}
            >
                <Visibility style={{color:'#FFFFFF', fontSize:18}}/>
            </Button>
        );
    }
    const dueAmount = (row, value) => {
        const irreg = (row.charge_config && row.charge_config.irregularAmount) || 0;
        const isIrregular = Boolean(irreg > 0);
        return (
            props.order !== 'search' ?
            <div>
                <Button
                    variant="contained"
                    disableElevation
                    style={{
                        minWidth:45, height: 25, borderRadius:20,
                        backgroundColor: isIrregular ? '#7b1fa2' : expiriesColors.find(item => item.state === row.expiration_state).color,
                        color: isIrregular ? '#FFFFFF' : row.expiration_state === 'Partially paid' ? '#424242' : '#FFFFFF',
                        fontSize:12,
                    }}
                    onClick={handleOpenExpiryInfo.bind(this, row.uniqueId)}
                >
                    <NumberFormat
                        value={value}
                    />
                </Button>
                <ExpiryObjectInfo
                    data={row}
                    handleToggle={handleOpenExpiryInfo.bind(this, row.uniqueId)}
                    open={Boolean(openExpiryInfo[row.uniqueId])}
                    anchorEl={openExpiryInfo[row.uniqueId]}
                />
            </div>
            :
            <NumberFormat
                value={value}
            />
        )
    }

    const configElement = (row) => {
        return(
            <Grid direction="column" container alignItems="center" style={{position: 'relative'}}>
                {
                    row.charge_config &&
                    <CheckCircle style={{fontSize:18, color:'#43A047', position: 'absolute', top: '-20px', right:'10px'}} />
                }
                <Button
                    variant="contained"
                    disableElevation
                    style={{width:25, height: 25, borderRadius:20, backgroundColor:'#43A047', color:'#FFFFFF', fontSize:12}}
                    onClick={props.handleToggleDialog.bind(this, 'bot-config', row)}
                >
                    <Settings style={{fontSize:18, color:'#FFFFFF'}} />
                    {/* configurar */}
                </Button>
            </Grid>
        );
    }
    const compElement = (row) => {
        return(
            <Button
                variant="contained"
                disableElevation
                style={{width:25, height: 25, borderRadius:20, backgroundColor:'#43A047', color:'#FFFFFF', fontSize:12}}
                onClick={props.handleToggleDialog.bind(this, 'financial-composition', row)}
            >
                <Visibility style={{fontSize:18, color:'#FFFFFF'}} />
                {/* ver */}
            </Button>
        );
    }
    const botActions = (row) => {
        return(
            <div style={{marginTop: 10, textAlign:'center'}}>
                <RadioGroupComponent
                    xs={12}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldID={"radio-field-bot-action"+row.uniqueId}
                    fieldClass="fields"
                    row={true}
                    options={[{text: 'NO REVISAR', value: 'no_check'}, {text: 'NO COBRAR', value: 'no_charge'},]}
                    value={row.charge_config.botAction}
                    onClick={handleChangeBotAction.bind(this, row)}
                    // onChange={handleChangeBotAction}
                />
            </div>
        );
    }
    const accessBotLink = (row) => {
        return(
            <>
            <Button
                variant="contained"
                disableElevation
                style={{width:25, height: 25, borderRadius:20, backgroundColor:'#0277bd', color:'#FFFFFF', fontSize:12}}
                onClick={handleBotLink.bind(this, row)}
            >
                <Link style={{fontSize:18, color:'#FFFFFF'}} />
            </Button>
            {/* <BotChargeAction
                data={row}
                group="login"
                executeBotChargeAction={props.executeBotChargeAction}
                handleToggleDialog={props.handleToggleDialog}
                botRunning={props.botRunning}
                initBtn={
                    <Button
                        variant="contained"
                        disableElevation
                        style={{width:25, height: 25, borderRadius:20, backgroundColor:'#0277bd', color:'#FFFFFF', fontSize:12}}
                        // onClick={props.executeBotChargeAction.bind(this, row, 'login')}
                    >
                        <AccountBalanceWallet style={{fontSize:18, color:'#FFFFFF'}} />
                    </Button>
                }
            /> */}
            </>
        );
    }

    const removeElement = (row) => {
        return(
            <IconButton onClick={props.removeExpiry.bind(this, row)}>
                <CloseOutlined style={{color:'#424242'}} />
            </IconButton>
        );
    }

    const bankBalance = (row) => {
        return(
            <NumberFormat
                value={row.bank_balance}
            />
        )
    }

    // const expirationState = (row) => {
    //     return(
    //         <span>
    //             {
    //                 ExpiryStates().find(item => item.value === row.expiration_state).name
    //             }
    //         </span>
    //     )
    // }

    const botLogs = (row) => {
        return(
            <>
            <Button
                variant="contained"
                disableElevation
                style={{width:25, height: 25, borderRadius:20, backgroundColor:'#767676', color:'#FFFFFF', fontSize:12}}
                onClick={props.handleToggleDialog.bind(this, 'history', row)}
            >
                <EventNote style={{fontSize:18, color:'#FFFFFF'}} />
            </Button>
            </>
        );
    }

    const columns = [
        {id:'checkbox'},
        {id:'remove', label: 'REMOVER', minWidth: 80, element: removeElement, align:'center'},
        {id:'order', label: 'ORDEN', minWidth: 120, element: orderElement, align:'center'},
        {id:'client', label: 'CLIENTE', minWidth: 170,},
        {id:'responsible_name', label: 'RESPONSABLE', minWidth: 170},
        {id:'work', label: 'INSTITUCIÓN TRABAJO', minWidth: 170},
        {id:'bank', label: 'INSTITUCIÓN FINANCIERA', minWidth: 190},
        {id:'due_date', label: 'FECHA DE VENCIMIENTO', minWidth: 190,},
        {id:'overdue_amount', label: 'VENCIMIENTO', minWidth: 110, element: dueAmount, align:'center'},
        {id:'bank_balance', label: 'BALANCE BANCARIO', minWidth: 150, element: bankBalance},
        {id:'bot_actions', label: 'ACCIONES DEL BOT', minWidth: 250, element: botActions, align:'center'},
        {id:'account_access', label: 'ACCEDER ENLACE', minWidth: 170, element: accessBotLink, align:'center'},
        {id:'financial_profile', label: 'PERFIL FINANCIERO', minWidth: 150, element: financialElement, align:'center'},
        {id:'charge_config', label: 'CONFIG. COBRO', minWidth: 170, element: configElement, align:'center'},
        {id:'financial_comp', label: 'COMP. FINANCIERA', minWidth: 170, element: compElement, align:'center'},
        {id:'bot_logs', label: 'EVENTOS BOT', minWidth: 170, element: botLogs, align:'center'}
        // {id:'expiration_state', label: 'ESTADO', element: expirationState, minWidth: 170},
        // {id:'rating', label: 'RATING', minWidth: 80,},
        // {id:'expiry_object', label: 'OBJETO DE VENCIMIENTO', minWidth: 190, element: expiryObj, align:'center'},
    ].filter(item => {
        switch(props.order) {
            case 'customOrder':
                return !['checkbox', 'bot_actions', 'account_access', 'bot_logs'].includes(item.id);
            case 'search':
                return !['remove', 'order', 'financial_profile', 'charge_config', 'financial_comp', 'bot_actions', 'account_access', 'bot_logs'].includes(item.id);
            case 'view':
                return !['checkbox', 'remove', 'charge_config', 'financial_comp'].includes(item.id);
            default:
                return item.id !== 'remove';
        }
    });

    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);
    return(
        <GeneralTable
            columns={columns}
            data={tableData}
            selected={props.selected ? props.selected : selected}
            setSelected={props.setSelected ? props.setSelected : setSelected}
            maxHeight={550}
            rowsPerPage={10}
            toolbar={
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Toolbar>
                        <Grid container item direction="row" spacing={5} alignItems="center">
                            <Typography
                                variant="subtitle1"
                                className="section-header"
                                style={{fontWeight: 'bold', paddingLeft:16}}
                            >
                                {props.title}
                            </Typography>
                        </Grid>
                    </Toolbar>
                    <TableSearch 
                        data={props.data}
                        setTableData={setTableData}
                        searchFields={['client']}
                    />
                </Grid>
            }
        />

    );
}

function ExpiryObjectInfo(props) {
    return(
        <Popover
            // style={{width:'70%'}}
            id={'expiry-object-popover'}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleToggle}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            elevation={4}
        >
            <Grid container style={{width:'350px', padding:'15px 24px', border:'1px solid #cfcfcf', borderRadius:5}}>
                <Grid item container justifyContent="space-between" alignItems="center">
                    <Typography
                        variant="h6"
                        style={{color:'#424242'}}
                    >
                        Objeto de vencimiento
                    </Typography>
                    <IconButton onClick={props.handleToggle}>
                        <CloseOutlined style={{color:'#424242'}} />
                    </IconButton>
                </Grid>
                <Grid>
                    <Paper style={{width:'250px', padding: '.5rem 1rem', margin: '1rem 0'}}>
                        <Typography
                            variant="subtitle1"
                            style={{color:'#424242', display:'flex', flexDirection:'column'}}
                        >
                            <NumberFormat
                                value={props.data.overdue_amount}
                            />
                            <Typography
                                variant="overline"
                                style={{color:'#424242'}}
                            >Monto vencido</Typography>
                        </Typography>
                    </Paper>

                    <Paper style={{width:'250px', padding: '.5rem 1rem', margin: '1rem 0'}}>
                        <Typography
                            variant="subtitle1"
                            style={{color:'#424242', display:'flex', flexDirection:'column'}}
                        >
                            <NumberFormat
                                value={props.data.charged_amount}
                            />
                            <Typography
                                variant="overline"
                                style={{color:'#424242'}}
                            >Monto cobrado</Typography>
                        </Typography>
                    </Paper>
                    <Paper style={{width:'250px', padding: '.5rem 1rem', margin: '1rem 0'}}>
                        <Typography
                            variant="subtitle1"
                            style={{color:'#424242', display:'flex', flexDirection:'column'}}
                        >
                            <NumberFormat
                                value={
                                    Number(props.data.remaining_amount) <= 0 ?
                                    "0" : props.data.remaining_amount
                                }
                            />
                            <Typography
                                variant="overline"
                                style={{color:'#424242'}}
                            >Monto restante</Typography>
                        </Typography>
                    </Paper>
                    <Paper style={{width:'250px', padding: '.5rem 1rem', margin: '1rem 0'}}>
                        <Typography
                            variant="subtitle1"
                            style={{color:'#424242', display:'flex', flexDirection:'column'}}
                        >
                            {
                                ExpiryStates().find(item => item.value === props.data.expiration_state).name
                            }
                            {/* {props.data.expiration_state} */}
                            <Typography
                                variant="overline"
                                style={{color:'#424242'}}
                            >Estado</Typography>
                        </Typography>
                    </Paper>
                </Grid>

            </Grid>
        </Popover>
    )
}

function GroupedClientsTable(props) {

    return(
        <Table className="table-container grouped-clients" size='small'>
            <TableHead className="table-header">
                <TableRow>
                    <TableCell align="center" style={{width:25}}>ORDEN</TableCell>
                    <TableCell align="center">CLIENTE</TableCell>
                    <TableCell align="center">FECHA VENC.</TableCell>
                    <TableCell align="center">RATING</TableCell>
                    <TableCell align="center">VENCIMIENTO</TableCell>
                    <TableCell align="center">CONFIG.</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.data.map((row) => (
                    <TableRow key={row.id} className="table-row">
                        <TableCell scope="row" align="center" style={{width:25}}>
                            {row.order}
                        </TableCell>
                        <TableCell align="center">{row.client_name}</TableCell>
                        <TableCell align="center">{row.expiry_date}</TableCell>
                        <TableCell align="center">{row.rating}</TableCell>
                        <TableCell align="center">
                            {row.client_disbursement.reduce((total, item) => {
                                return total + (parseFloat(item.total) - parseFloat(item.amount_charged))
                            }, 0).toLocaleString()}
                        </TableCell>
                        <TableCell align="center">
                            <Button
                                variant="contained"
                                disableElevation
                                style={{width:95, borderRadius:20, backgroundColor:'#43A047', color:'#FFFFFF', fontSize:11}}
                                onClick={props.toggleExpiryDialog.bind(this, row, true)}
                            >
                                ver más
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

function HistoryTable(props) {
    const [tableData, setTableData] = useState([]); // state to mutate the table data
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);

    const cleanDate = (row) =>
            <span>{new Date(Date.parse(row.created)).toLocaleString()}</span>

    const logStatus = (row) => {
        return(
            <Grid container alignItems="center">
                    <FiberManualRecord className={
                    row.expirationStatus === 'Partially paid' ? "status-yellow" :
                    row.expirationStatus === 'Info' ? "status-blue" :
                    row.expirationStatus === 'Programmed' ? "status-blue" :
                    row.expirationStatus === 'Error' ? "status-red" :
                    row.expirationStatus === 'Late' ? "status-red" :
                    row.expirationStatus === 'Processed' ? "status-green" : "status-blue"
                } style={{fontSize: 40}}/>
                {expirationStatus.find(status => status.value === row.expirationStatus).label}
            </Grid>
        )
    }

    const columns = [
        // {id:'checkbox'},
        {id:'expirationStatus', label: 'ESTADO', minWidth: 50, element: logStatus, align:'left'},
        {id:'message', label: 'MENSAJE', minWidth: 50, align:'left'},
        {id:'created', label: 'FECHA', minWidth: 50, element: cleanDate, align:'right'},
    ]

    return(
        <GeneralTable
            columns={columns}
            data={tableData}
            selected={props.selected ? props.selected : selected}
            setSelected={props.setSelected ? props.setSelected : setSelected}
            maxHeight={550}
            rowsPerPage={10}
            toolbar={
                props.title &&
                <Toolbar>
                    <Grid container item direction="row" spacing={5} alignItems="left">
                        <Typography
                            variant="subtitle1"
                            className="section-header"
                            style={{fontWeight: 'bold', paddingLeft:16}}
                        >
                            {props.title}
                        </Typography>
                    </Grid>
                </Toolbar>
            }
        />
    )
}

function ExpiryObjectTable(props) {

    const configElement = (row) => {
        return(
            <Button
                variant="contained"
                disableElevation
                style={{width:25, height: 25, borderRadius:20, backgroundColor:'#43A047'}}
                onClick={props.handleToggleDialog.bind(this, 'bot-config', row)}
            >
                <Settings style={{fontSize:18, color:'#FFFFFF'}} />
            </Button>
        );
    }
    const compElement = (row) => {
        return(
            <Button
                variant="contained"
                disableElevation
                style={{width:25, height: 25, borderRadius:20, backgroundColor:'#43A047'}}
                onClick={props.handleToggleDialog.bind(this, 'financial-composition', row)}
            >
                <Visibility style={{fontSize:18, color:'#FFFFFF'}} />
            </Button>
        );
    }
    const profileElement = (row) => {
        return(
            <Button
                variant="contained"
                disableElevation
                style={{width:25, height: 25, borderRadius:20, backgroundColor:'#43A047'}}
                onClick={props.handleToggleDialog.bind(this, 'financial-profile', row)}
            >
                <MoreHoriz style={{fontSize:18, color:'#FFFFFF'}} />
            </Button>
        );
    }

    const numberFormat = (row, value) => {
        return (
            <NumberFormat
                value={value}
            />
        )
    }
    const columns = [
        {id:'order', label: 'ORDEN', minWidth: 70},
        {id:'responsible_name', label: 'PARTICIPANTE', minWidth: 170},
        {id:'client_name', label: 'CLIENTE', minWidth: 170},
        {id:'overdue_amount', label: 'MONTO VENCIDO', minWidth: 170, element: numberFormat},
        {id:'account_balance', label: 'BALANCE BANCARIO', minWidth: 170, element: numberFormat},
        {id:'charged_amount', label: 'MONTO COBRADO', minWidth: 170, element: numberFormat},
        {id:'balance_left', label: 'BALANCE RESTANTE', minWidth: 170, element: numberFormat},
        {id:'status', label: 'ESTADO', minWidth: 80},
        {id: 'charge_config', label: 'CONFIG. COBRO', minWidth: 170, element: configElement, align:'center'},
        {id: 'financial_comp', label: 'COMP. FINANCIERA', minWidth: 170, element: compElement, align:'center'},
        {id: 'financial_profile', label: 'PERFIL FINANCIERO', minWidth: 170, element: profileElement, align:'center'},
    ]
    return(
        <GeneralTable
            columns={columns}
            data={props.data}
        />
    );
}

function BotConfigDestinyAccounts(props) {
    const amountToCharge = (row) => {
        return(
            <div style={{marginTop: 10}}>
                <NumericTextField
                    xs={12}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldID={"text-field-amount-charge-account"+row.account}
                    fieldClass="fields"
                    placeholder="Monto total a cobrar"
                    fieldVariant="outlined"
                    prefix="$"
                    margin="dense"
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={row.chargeAmount || 0}
                    error={Boolean(props.difference.state)}
                    onChange={props.handleDestinyChange.bind(this, row, 'chargeAmount')}
                />
            </div>
        )
    }
    const transferType = (row) => {
        return(
            <div style={{marginTop: 10, textAlign:'center'}}>
                <RadioGroupComponent
                    xs={12}
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldID={"radio-field-transfer-type"+row.account}
                    fieldClass="fields"
                    row={true}
                    options={[{text: 'LBTR', value: 'LBTR'}, {text: 'ACH', value: 'ACH'}]}
                    value={row.transactionType}
                    onChange={props.handleDestinyChange.bind(this, row, 'transactionType')}
                />
            </div>
        )
    }

    const productType = (row) => {
        return(
            <span>
                {row.productType ? ProductTypeTranslations().find(item => item.value === row.productType).name : '--'}
            </span>
        )
    }

    const columns = [
        { id: 'beneficiary', label: 'BENEFICIARIO', minWidth: 180 },
        { id: 'finantialInstitution', label: 'INST. FINANCIERA', minWidth: 180 },
        { id: 'currency', label: 'MONEDA', minWidth: 120 },
        { id: 'account', label: 'NO. CUENTA', minWidth: 160 },
        { id: 'productType', label: 'TIPO CUENTA', element: productType, minWidth: 180 },
        { id: 'chargeAmount', label: 'MONTO A COBRAR', align:'center', minWidth: 230, element: amountToCharge },
        { id: 'transactionType', label: 'TIPO DE TRANSFERENCIA', align:'center', minWidth: 230, element: transferType },
    ]
    return(
        <GeneralTable
            columns={columns}
            data={props.data}
            noPagination={true}
            height={'350px'}
        />
    );
}

function DueAssignmentTableToolbar(props) {

    const [search, setSearch] = useState('');
    const [selectedResponsible, setResponsibles] = useState(null);
    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        const filteredClients = props.originalData.filter(item =>
            item.fullName.toLowerCase().includes(value) ||
            item.work.toLowerCase().includes(value) ||
            item.identificationNumber.toLowerCase().includes(value)
        )
        setSearch(event.target.value);
        props.handleDataChange(filteredClients);
    }

    const handleResponsibleChange = (e, val) => {
        setResponsibles(val);
    }
    const postAssign = (assign) => {
        props.setLoading(true);
        const endpoint = updateAssignParticipants(assign);
        endpoint.then(() => {
            props.setSelected([]);
            props.setLoading(false);
            props.handleClients();
            props.handleToggleConfirm('success-dialog');
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleConfirm);
        });
    }
    const handleAssignClick = () => {
        const assignData = {
            responsible_analyst: selectedResponsible.uniqueId,
            participants: props.selectedRows.map(item=>item.uniqueId).join('|'),
            token: props.token,
        }
        postAssign(assignData);
    }

    return(
        <Toolbar>
            <Grid container item direction="row" spacing={5} alignItems="center" justifyContent="space-between">
                <Grid item container xs={8}>
                    {
                        props.selectedRows.length > 0 ?
                            <Grid container justifyContent="space-between" spacing={2} style={{marginBottom:5}}>
                                <Grid item container xs={8}>
                                    <AutocompleteField
                                        xs={12}
                                        fieldID="select-assign-responsible-assignment"
                                        fieldClass="fields"
                                        fieldInnerLabel="Responsable a asignar"
                                        value={selectedResponsible}
                                        handleChange={handleResponsibleChange}
                                        options={props.responsibles}
                                        getOptionLabel={(option) => option.fullName}
                                        multiple={false}
                                    />
                                </Grid>

                                <Grid item container xs={4} alignItems="flex-end" justifyContent="flex-start">
                                    <Button
                                        disableElevation
                                        className={selectedResponsible ? "assign-expiry-to-responsible-btn" : "assign-expiry-to-responsible-btn disabled"}
                                        onClick={handleAssignClick}
                                        disabled={!selectedResponsible}
                                    >
                                        Asignar
                                    </Button>
                                </Grid>
                            </Grid>
                        :
                            <Typography
                                variant="subtitle1"
                                className="section-header"
                                style={{fontWeight: 'bold'}}
                            >
                                Clientes
                            </Typography>
                    }
                </Grid>

                <Grid item container xs={4}>
                    <TextField
                        variant="standard"
                        placeholder="Buscar..."
                        fullWidth
                        value={search}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search style={{color: '#757575'}}/>
                                </InputAdornment>
                            ),
                        }}
                    ></TextField>
                </Grid>
            </Grid>
        </Toolbar>
    );
}

function DueAssignmentTable(props) {

    const [tableData, setTableData] = useState(props.data); // state to mutate the table data
    const [selected, setSelected] = useState([]); // state to manage the selected rows.

    const handleDataSearch = (newData) => {
        setTableData(newData);
    }
    // update the table data every time props.data (the table data) changes.
    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);

    const columns = [
        {id:'checkbox'},
        {id:'asignee', label: 'RESPONSABLE', minWidth: 170},
        {id:'work', label: 'INSTITUCIÓN TRABAJO', minWidth: 170},
        {id:'bank',label: 'INSTITUCIÓN FINANCIERA', minWidth: 190},
        {id:'fullName', label: 'CLIENTE', minWidth: 170,},
        {id:'identificationNumber', label: 'CÉDULA', minWidth: 120,},
        {id:'rating',label: 'RATING', minWidth: 80,},
    ];

    return(
        <GeneralTable
            columns={columns}
            data={tableData}
            selected={selected}
            handleSelectAllRows={useHandleSelectAllRows}
            handleSelectRow={useHandleSelectRow}
            setSelected={setSelected}
            isSelected={useIsSelected}
            checkbox={true}
            maxHeight={550}
            toolbar={
                <DueAssignmentTableToolbar
                    selectedRows={selected}
                    setSelected={setSelected}
                    originalData={props.data}
                    tableData={tableData}
                    handleDataChange={handleDataSearch}
                    setLoading={props.setLoading}
                    handleToggleConfirm={props.handleToggleConfirm}
                    handleClients={props.handleClients}
                    responsibles={props.responsibles}
                    token={props.token}
                />
            }
        />

    );
}
function InfrastructureTable(props) {
    const [tableData, setTableData] = useState(props.data); // state to mutate the table data
    const [selected, setSelected] = useState([]); // state to manage the selected rows.

    const handleDataSearch = (newData) => {
        setTableData(newData);
    }
    const createInfrastructureMethod = () => {
        const data = []
        selected.map(item => {
            const dataN = {
                financialInstitution: item.uniqueId,
                workInstitution: props.inst.uniqueId,
                node: props.node,
            }
            data.push(dataN);
            return item;
        })
        props.setLoading(true);
        const endpoint = createInfrastructure(props.token, data);
        endpoint.then(() => {
            props.refreshInfrastructure()
            props.setBank([]);
            setSelected([]);
            props.setLoading(false);
            props.handleConfirm('success-dialog');
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);

    const columns = [
        {id:'checkbox'},
        {id:'name', label: 'INST. FINANCIERA', minWidth: 170},
        {id:'abbreviation', label: 'ABREVIACION', minWidth: 170}
    ];
    const disabled = props.inst && selected.length > 0 ? false : true

    return(
        <div style={{width: '100%'}}>
            <GeneralTable
                columns={columns}
                data={tableData}
                selected={selected}
                handleSelectAllRows={useHandleSelectAllRows}
                handleSelectRow={useHandleSelectRow}
                setSelected={setSelected}
                isSelected={useIsSelected}
                checkbox={true}
                maxHeight={550}
                toolbar={
                    <InfrastructureToolbar
                        originalData={props.data}
                        name='Instituciones financieras'
                        tableData={tableData}
                        handleDataChange={handleDataSearch}
                    />
                }
            />
            <Button
                variant="contained"
                style={{backgroundColor: disabled ? '#8f8989' : '#43A047', color: '#FFF', fontSize: 12, width:'100%'}}
                disableElevation
                disabled={disabled}
                size="medium"
                onClick={() => createInfrastructureMethod()}
                >
                <ArrowDownward style={{fontSize:12}}/>
                    <span style={{margin:'0 1rem'}}>Guardar</span>
                <ArrowDownward style={{fontSize:12}}/>
            </Button>
        </div>

    );
}
function PlainInfrastructureTable(props) {

    const [tableData, setTableData] = useState(props.data); // state to mutate the table data
    const [editIdx, seteditIdx] = useState(-1);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDataSearch = (newData) => {
        setTableData(newData);
    }

    const startEditing = (i) => {
        seteditIdx( i );
    }
    const stopEditing = (row) => {
        seteditIdx( -1 );
        props.setLoading(true);
        const endpoint = updateSpecificOrder(props.token, row);
        endpoint.then(() => {
            props.refreshInfrastructure()
            props.getPods()
            props.setLoading(false);
            props.handleConfirm('success-dialog');
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    const handleStop = (row) => {
        props.setLoading(true);
        const endpoint = deletePods(props.token, row.uniqueId);
        endpoint.then(() => {
            updateSpecificOrder(props.token, {uniqueId: row.uniqueId, isDeployed: false})
                .then(() => {
                    props.refreshInfrastructure();
                    props.setLoading(false);
                    props.handleConfirm('success-dialog');
            });
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    const handleRemove = (row) => {
        props.setLoading(true);
        if (row.isDeployed === true){
            const endpoint = deletePods(props.token, row.uniqueId);
            endpoint.then(() => {
                updateSpecificOrder(props.token, {...row, isDeployed: false, pods: 0})
                    .then(() => {
                        props.refreshInfrastructure();
                        props.setLoading(false);
                        props.handleConfirm('success-dialog');
                });
            }).catch(errors => {
                props.setLoading(false);
                errorHandling(errors, props.handleConfirm);
            });
        }else{
            const endpoint = updateSpecificOrder(props.token, {...row, pods: 0})
            endpoint.then(() => {
                    props.refreshInfrastructure();
                    props.setLoading(false);
                    props.handleConfirm('success-dialog');
            }).catch(errors => {
                props.setLoading(false);
                errorHandling(errors, props.handleConfirm);
            });
        }
    }
    const handleCreate = (row) => {
        props.setLoading(true);
        const endpoint = createPods(props.token, row.uniqueId);
        endpoint.then(() => {
            updateSpecificOrder(props.token, {...row, isDeployed: true})
                .then(() => {
                    props.refreshInfrastructure();
                    props.setLoading(false);
                    props.handleConfirm('success-dialog');
            });
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleConfirm);
        });
    }
    const handleChange = (e, name, i, row) => {
        const { value } = e.target;
        const indiceElemento = tableData.findIndex(el => el.uniqueId === row.uniqueId )
        const newTableData = [...tableData]
        newTableData[indiceElemento] = {...newTableData[indiceElemento], pods: value}
        setTableData(newTableData)
      };
    useEffect(() => {
        setTableData(props.data.filter(item => item.pods > 0));
        props.getNewAvailablePods()
    }, [props.data]);

    const columns = [
        {value:'name', label: 'NOMBRE'},
        {value:'pods', label: 'PODS'}
    ];

    const row = (x,i) => {
        const currentlyEditing = editIdx === i;
        return (
          <TableRow key={`tr-${i}`}>
            {columns.map((y, k) => (
              <TableCell key={`trc-${k}`}>
                {
                    currentlyEditing && y.value === 'pods' ? (
                    <TextField
                        style={{width: 50}}
                        name={y.label}
                        onChange={e => handleChange(e, y.value, i, x)}
                        value={x[y.value]}
                    />
                    ) : (x[y.value])
                }
              </TableCell>
            ))}
            <TableCell
                style={{width: 25}}
            >
              {currentlyEditing ? (
                <CheckCircleOutline
                    style={{color: 'green'}}
                    onClick={() => stopEditing(x)}
                />
              ) : (
                <Edit
                    style={{color: 'green'}}
                    onClick={() => startEditing(i)}
                />
              )}
            </TableCell>
            <TableCell
                style={{width: 25}}
            >
                {tableData[i].isDeployed &&
                    <Delete
                    style={{color: 'green'}}
                    onClick={
                        props.handleConfirm
                        .bind(
                            this, 
                            'confirm-leave', 
                            'Actualmente se encuentran corriendo los pods, desea continuar?', 
                            handleRemove.bind(this, x)
                        )
                    }
                />}
                {!tableData[i].isDeployed &&
                    <Delete
                    style={{color: 'green'}}
                    onClick={
                        props.handleConfirm
                        .bind(this, 'confirm-dialog', 'proceed-delete', handleRemove.bind(this, x))
                    }
                />}

                {/* <Delete
                    style={{color: 'green'}}
                    onClick={
                        props.handleConfirm
                        .bind(this, 'confirm-dialog', 'proceed-delete', handleRemove.bind(this, x))
                    } 
                /> */}
            </TableCell>
            <TableCell
                style={{width: 25}}
            >
                {tableData[i].isDeployed &&
                    <PauseCircleFilled
                    style={{color: 'green'}}
                    onClick={
                        props.handleConfirm
                        .bind(this, 'confirm-dialog', 'proceed-delete', handleStop.bind(this, x))
                    }
                />}
                {!tableData[i].isDeployed &&
                    <PlayCircleFilled
                    style={{color: 'green'}}
                    onClick={
                        props.handleConfirm
                        .bind(this, 'confirm-dialog', 'proceed-delete', handleCreate.bind(this, x))
                    }
                />}
            </TableCell>
          </TableRow>
        );
      };

      return (
        <Paper style={{ width: '100%' }}>
            <InfrastructureToolbar
                originalData={props.data}
                name='Pods por Ordenes'
                tableData={tableData}
                handleDataChange={handleDataSearch}
            />
            <Table>
                <TableHead>
                    <TableRow>
                    {columns.map((x, i) => (
                        <TableCell key={`thc-${i}`}>{x.label}</TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((x, i) => row(x, i))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage || 5}
                page={page || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Filas por página:"}
                
            />
        </Paper>
      );
}
function InfrastructureToolbar(props) {
    const [search, setSearch] = useState('');

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        const filteredData = props.originalData.filter(item =>  item.name.toLowerCase().includes(value))
        setSearch(event.target.value);
        props.handleDataChange(filteredData);
    }

    return(
        <Toolbar>
            <Grid container justifyContent="space-between" direction="row">
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{fontWeight: 'bold'}}
                >
                    {props.name}
                </Typography>

                <Grid item xs={4}>
                    <TextField
                        variant="standard"
                        placeholder="Buscar..."
                        fullWidth
                        value={search}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search style={{color: '#757575'}}/>
                                </InputAdornment>
                            ),
                        }}
                    ></TextField>
                </Grid>
            </Grid>
        </Toolbar>
    )
}

function ResponsibleTableToolbar(props) {
    return(
        <Toolbar>
            <Grid container item direction="row" spacing={5} alignItems="center">
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{fontWeight: 'bold', paddingLeft:16}}
                >
                    Responsables
                </Typography>
            </Grid>
        </Toolbar>
    );
}

function ResponsibleTable(props) {

    const [tableData, setTableData] = useState([]);

    const actions = (row) => {
        return(
            <Grid container>
                <IconButton
                    onClick={props.handleEditRow.bind(this, row)}
                >
                    <Edit style={{fontSize:22, color:'#616161'}} />
                </IconButton>
                <IconButton
                    onClick={
                        props.handleToggleSimpleDialog
                        .bind(this, 'confirm-dialog', 'confirm-delete-responsible', { callback: props.handleDeleteRow.bind(this, row), row: row })
                    }
                >
                    <DeleteOutline style={{fontSize:22, color:'#616161'}} />
                </IconButton>
            </Grid>

        );
    }

    // const isBot = (row, value) => {
    //     return(<span>{value ? "SI" : "NO"}</span>)
    // }

    const columns = [
        {id:'fullName', label: 'NOMBRE COMPLETO', minWidth: 170},
        {id:'email', label: 'EMAIL', minWidth: 170},
        // {id:'isBot', label: 'ES BOT', minWidth: 170, element: isBot},
        // {id:'ipAddress', label: 'DIRECCIÓN IP', minWidth: 170},
        // {id:'user', label: 'USUARIO', minWidth: 170},
        {id:'actions', label: 'ACCIONES', minWidth: 170, element: actions},
    ]
    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);
    return(
        <GeneralTable
            columns={columns}
            data={tableData}
            toolbar={
                <Grid container justifyContent='space-between' alignItems='center'>
                    <ResponsibleTableToolbar />
                    <SearchTextField 
                        optionType={'responsibleManagement'}
                        setResults={props.setResponsibles}
                    />
                </Grid>
            }
        />
    );
}
function CustomOrdersTable(props) {

    const [tableData, setTableData] = useState(props.data); // state to mutate the table data
    const handleDataSearch = (newData) => {
        setTableData(newData);
    }
    // update the table data every time props.data (the table data) changes.
    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);

    const order = (row) => {
        return(
            <Grid container direciton="row" justifyContent="space-between">
                <IconButton
                    onClick={props.showDetail.bind(this, row)}
                >
                    <Edit style={{fontSize:22, color:'#616161'}} />
                </IconButton>
                <IconButton
                    onClick={
                        row.isDeployed ?
                            props.handleConfirm
                                .bind(this, 'confirm-leave', 'Actualmente se encuentran corriendo los pods, desea continuar?', 
                                props.handleDelete.bind(this, row))
                            : props.handleConfirm
                                .bind(this, 'confirm-dialog', 'proceed-delete', props.handleDelete.bind(this, row))
                    }
                >
                    <Delete style={{fontSize:22, color:'#616161'}} />
                </IconButton>
            </Grid>
        );
    }

    const columns = [
        {id:'name', label: 'NOMBRE', minWidth: 340},
        {id:'orderDate', label: 'FECHA DE CREACIÓN', minWidth: 340},
        {id:'action', label: 'ACCIONES', minWidth: 30, element: order},
    ]
    return(
        <GeneralTable
            columns={columns}
            data={tableData}
            toolbar={
                <CustomOrderToolbar
                    originalData={props.data}
                    tableData={tableData}
                    handleDataChange={handleDataSearch}
                />
            }
        />
    );
}

function CustomOrderToolbar(props) {
    const [search, setSearch] = useState('');
    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        const filteredOrders = props.originalData.filter(item =>
            item.name.toLowerCase().includes(value) ||
            item.orderDate.toLowerCase().includes(value)
        )
        setSearch(event.target.value);
        props.handleDataChange(filteredOrders);
    }

    return(
        <Toolbar>
            <Grid container justifyContent="space-between" direction="row">
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{fontWeight: 'bold'}}
                >
                    Ordenes
                </Typography>

                <Grid item xs={4}>
                    <TextField
                        variant="standard"
                        placeholder="Buscar..."
                        fullWidth
                        value={search}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search style={{color: '#757575'}}/>
                                </InputAdornment>
                            ),
                        }}
                    ></TextField>
                </Grid>
            </Grid>
        </Toolbar>
    )

}

function ExpiryTable(props) {
    const [tableData, setTableData] = useState(props.data);
    const amount = (column, row) => {
        return(
            <NumberFormat
                value={row[column]}
            />
        )
    }
    const client = (row) => {
        return(
            <span>
                {
                    props.clients.length > 0 ?
                        props.clients.find(item => item.uniqueId === row.client).name
                    : '---'
                }
            </span>
        )
    }
    const columns = [
        {id:'checkbox', label:'SELECCIÓN'},
        {id:'expirationRohiId', label: 'ID ROHI', minWidth: 100},
        {id:'client', label: 'CLIENTE', minWidth: 190, element: client},
        {id:'expirationStatus', label: 'ESTADO', minWidth: 100},
        {id:'amountReceivable', label: 'MONTO VENCIDO', minWidth: 150, element: amount.bind(this, 'amountReceivable')},
        {id:'amountCharged', label: 'MONTO COBRADO', minWidth: 150, element: amount.bind(this, 'amountCharged')},
        {id:'expirationDate', label: 'FECHA', minWidth: 120},
    ];
    useEffect(() => {
        setTableData(props.data);
    }, [props.data]);
    return(
        <GeneralTable
            columns={columns}
            data={tableData}
            selected={props.selected}
            setSelected={props.setSelected}
            handleSelectAllRows={useHandleSelectAllRows}
            handleSelectRow={useHandleSelectRow}
            isSelected={useIsSelected}
            checkbox={true}
            toolbar={
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Toolbar>
                        <Grid container item direction="row" spacing={5} alignItems="center">
                            <Typography
                                variant="subtitle1"
                                className="section-header"
                                style={{fontWeight: 'bold', paddingLeft:16}}
                            >
                                Vencimientos
                            </Typography>
                        </Grid>
                    </Toolbar>
                    <TableSearch 
                        data={props.data}
                        setTableData={setTableData}
                        searchFields={['client']}
                    />
                </Grid>
            }
        />
    );
}


export {
        GroupedClientsTable, PlainClientsTable,
        HistoryTable, DataGridTable, ExpiryObjectTable, DueAssignmentTable,
        ResponsibleTable, CustomOrdersTable, ExpiryTable, BotConfigDestinyAccounts, InfrastructureTable,
        PlainInfrastructureTable
    }