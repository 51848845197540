import * as React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton
} from '@material-ui/core'
import {
    Close
} from '@material-ui/icons';
import { createStyles, withStyles } from '@material-ui/core/styles';



const styles: any = createStyles({
    dialogWrapper: {
        padding: '2px',
        position: 'absolute',
        top: '2px'
    },
    dialogTitle: {
        paddingRight: '0px'
    }
});
interface IProps {
    [key: string]: string | number | boolean | string[] | any

    classes: {
        dialogTitle: string;
        dialogWrapper: string;
    };

    title: string;
    chidren: any;
    openDialog: boolean;
    handleClose(): void;

}

class SavingsAccountContractSearchSection extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }
    public render() {
        const { classes } = this.props;

        return (

            <Dialog open={this.props.openDialog} onClose={this.props.handleClose} maxWidth='xl' classes={{ paper: classes.dialogWrapper }}>
                <DialogTitle className={classes.dialogTitle}>
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <Typography variant="h6" component="div" >
                            {this.props.title}
                        </Typography>
                        <IconButton onClick={this.props.handleClose}><Close /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {this.props.children}
                </DialogContent>
            </Dialog>
        )
    }
}

export default withStyles(styles)(SavingsAccountContractSearchSection);