import * as React from 'react';
import { translations, savingsAccountTypes } from 'src/utils';

import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { SearchComponent } from "../../../components//SharedComponents/Fields";



const styles: any = createStyles({
    formControl: {
        marginTop: 20
    },
    greenButton: {
        '&:hover': {
            backgroundColor: green[200]
        },
        backgroundColor: green[500],
        color: 'white'
    },
    label: {
        color: green[500]
    },
    redButton: {
        '&:hover': {
            backgroundColor: red[200]
        },
        backgroundColor: red[500],
        color: 'white'
    },
    textField: {
        marginTop: 10,
        minWidth: 190
    },
    gridFormControl: {
        margin: 8,
        width: '100%',
    },
});

interface ISavingsAccountSearchFilterProps {
    token: string;

    open: boolean;
    classes: {
        [key: string]: string;
    }
    handleClose(): void;
    search(params: IFetchSavingsAccountContractParams): void;
}

interface ISavingsAccountSearchFilterState {
    [key: string]: string | number | any;
    title: string;
    status: any;
    debtor_name: string;
    opening_date_from: string;
    opening_date_to: string;
    secondary_debtors_unique_id: any;
    main_debtor_unique_id: any;
    type: any;
}

class SavingsAccountSearchFilter extends React.Component<ISavingsAccountSearchFilterProps, ISavingsAccountSearchFilterState>{
    public state: ISavingsAccountSearchFilterState = {
        opening_date_from: '',
        opening_date_to: new Date().toISOString().split('T')[0],
        debtor_name: '',
        type: [],
        status: [],
        secondary_debtors_unique_id: [],
        main_debtor_unique_id: [],
        title: ''
    };
    constructor(props: ISavingsAccountSearchFilterProps) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }
    public handleSearch() {
        const params: IFetchSavingsAccountContractParams = {
            openingDateFrom: this.state.opening_date_from,
            openingDateTo: this.state.opening_date_to,
            mainDebtorUniqueId: this.state.main_debtor_unique_id,
            secondaryDebtorsUniqueId: this.state.secondary_debtors_unique_id,
            status: this.state.status.map((item: any) => item.value).join(','),
            type: this.state.type.map((item: any) => item.value).join(','),
            title: this.state.title,
            token: this.props.token
        };
        this.props.search(params);
        this.props.handleClose();
    }
    public handleClear() {
        this.setState({
            opening_date_from: '',
            opening_date_to: '',
            debtor_name: '',
            status: [],
            type: [],
            secondary_debtors_unique_id: [],
            main_debtor_unique_id: [],
            title: ''
        });
        this.props.search({ token: this.props.token })
        this.props.handleClose();
    }
    public handleChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ [key]: event.currentTarget.value });
        }
    }
    public handleSelectChange(key: string) {
        return (event: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({ [key]: event.target.value });
        }
    }
    public handleCurrencyInputChange(key: string) {
        return (event: React.ChangeEvent<HTMLInputElement>, maskedValue?: string, floatValue?: string) => {
            this.setState({ [key]: floatValue || '' });
        }
    }
    public handleAutocompleteChange(key: any, obj: any, val: any) {
        /* Handle the state of Autocomplete components */
        const data = obj || val;

        this.setState({
            [key]: data.value ? data.value : ""
        });

    }
    public render() {

        const { classes, handleClose, open } = this.props;

        const handleMultipleSelection = (key: string, val: string) =>
            this.setState({ [key]: val });

        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12}>
                            <SearchComponent
                                xs={12}
                                containerClass="field-container"
                                fieldClass={{ style: styles.label }}
                                typeClass={styles.label}
                                fieldID="client"
                                fieldInnerLabel={translations.DEBTOR}
                                handleChange={this.handleAutocompleteChange.bind(this, "main_debtor_unique_id")}
                                optionType={'participants'}
                                variant="standard"
                                limitTags={1}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <SearchComponent
                                xs={12}
                                containerClass="field-container"
                                fieldClass={{ style: styles.label }}
                                typeClass={styles.label}
                                fieldID="client"
                                fieldInnerLabel={"Miembro(s) Secundario(s)"}
                                handleChange={this.handleAutocompleteChange.bind(this, "secondary_debtors_unique_id")}
                                optionType={'participants'}
                                variant="standard"
                                limitTags={1}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <TextField
                                label={translations.TITLE}
                                value={this.state.title}
                                fullWidth={true}
                                onChange={this.handleChange('title')}
                                InputLabelProps={{
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Autocomplete
                                style={{ marginTop: "16px" }}
                                multiple={true}
                                value={this.state.status}
                                options={[
                                    { value: "pending_signature", label: translations.PENDING_SIGNATURE },
                                    { value: "completed", label: translations.COMPLETED },
                                    { value: "archived", label: translations.ARCHIVED },
                                ]}
                                getOptionLabel={(option: any) => option.label}
                                onChange={(e, newValue: any) =>
                                    handleMultipleSelection("status", newValue)
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" label={translations.STATUS} InputLabelProps={{
                                        style: styles.label,
                                        shrink: true
                                    }} />
                                )}
                            />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Autocomplete
                                style={{ marginTop: "16px" }}
                                multiple={true}
                                value={this.state.type}
                                options={savingsAccountTypes}
                                getOptionLabel={(option: any) => option.label}
                                onChange={(e, newValue: any) =>
                                    handleMultipleSelection("type", newValue)
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" label={translations.TYPE} InputLabelProps={{
                                        style: styles.label,
                                        shrink: true
                                    }} />
                                )}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.FROM}`}
                                value={this.state.opening_date_from}
                                fullWidth={true}
                                type="date"
                                onChange={this.handleChange('opening_date_from')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <TextField
                                label={`${translations.CONTRACT_DATE} ${translations.TO}`}
                                fullWidth={true}
                                value={this.state.opening_date_to}
                                type="date"
                                onChange={this.handleChange('opening_date_to')}
                                InputLabelProps={{
                                    shrink: true,
                                    style: styles.label
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.redButton} onClick={this.handleClear}>
                        <ClearIcon />
                        &nbsp;
                        {`${translations.CLEAR} ${translations.SEARCH}`}
                    </Button>
                    <Button className={classes.greenButton} onClick={this.handleSearch}>
                        <SearchIcon />
                        &nbsp;
                        {translations.SEARCH}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}


export default withStyles(styles)(SavingsAccountSearchFilter);
