import React, {useEffect} from 'react';
import { Grid } from '@material-ui/core';
import { 
    TextFieldComponent
} from './../../../components/SharedComponents/Fields';
import ClientDocuments from 'src/components/ClientDocuments';
import { translations } from 'src/utils/index';

export default function Documents({
    fields, onChange, customer, leadUniqueId, setLoading, handleSimpleDialog
}){
    return(
        <Grid container spacing={3} className="form-wrapper-fields-container">
            
            <ClientDocuments 
                debtorId={customer || ""}
                leadsUniqueId={leadUniqueId}
                setLoading={setLoading}
                handleSimpleDialog={handleSimpleDialog}
            />
        </Grid>
    )
}