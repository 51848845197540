import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";

import { createCreditReviewAuthorization } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  resetError,
  successHandler,
  translations,
  IDENTIFICATION_TYPE,
  debtorsValidation,
} from "../../../utils";
import { createCreditReviewAuthorization as create } from "../../../utils/axios";
import Address from "../../../components/Address/Address";
import phoneMaskInput from "src/components/TextMaskInputComponents/phoneMaskInput";
import idNumberMaskInput from "src/components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "src/components/TextMaskInputComponents/passportMaskInput";
import rncMaskInput from "src/components/TextMaskInputComponents/rncMaskInput";
import ParticipantSelectInput from "src/containers/Participant/ParticipantSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface ICreditReviewAuthorizationFormProps {
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    errorInput: string;
    formControl: string;
    greenButton: string;
    half: string;
    paper: string;
    root: string;
    textField: string;
  };

  user: IUser;
  handleSaveCallBack?(uid: string): void;
  createCreditReviewAuthorization(
    creditReviewAuthorization: ICreditReviewAuthorization
  ): IAction<ICreditReviewAuthorization[]>;
  changeSection(sectionNumber: number): void;
}
interface ICreditReviewAuthorizationFormState {
  [key: string]: string | number | boolean | string[] | IBankAccount[];

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;

  fp: IFinancialProfile[];
  wp: IWorkProfile[];

  title: string;
  titleError: string;

  debtor: string[];
  debtorError: string;

  contractDate: string;
  contractDateError: string;

  status: string;
  statusError: string;

  fullName: string;
  idType: any;
  idNumber: string;
  email: string;
  cellPhone: string;
  country: string;
  reference: string;
  state: string;
  region: string;
  sector: string;

  newClient: boolean;
}
const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    "&$checked": {
      color: green[500],
    },
    "&$checked + $track": {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

class CreditReviewAuthorizationForm extends React.Component<
  ICreditReviewAuthorizationFormProps,
  ICreditReviewAuthorizationFormState
> {
  public state: ICreditReviewAuthorizationFormState = {
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    debtor: [],
    debtorError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    title: "",
    titleError: "",
    statusError: "",
    wp: [],
    fp: [],
    newClient: false,
    fullName: "",
    idType: "",
    idNumber: "",
    email: "",
    cellPhone: "",
    country: "",
    reference: "",
    state: "",
    region: "",
    sector: "",
    status: "pending_signature",
  };

  constructor(props: ICreditReviewAuthorizationFormProps) {
    super(props);

    // client
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // core event
    this.handleChange = this.handleChange.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleChangesAddress = this.handleChangesAddress.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleState = this.handleState.bind(this);
  }
  public handleState(key: any, newState: any) {
    this.setState((state: any) => {
      state[key] = newState;
      return state;
    });
  }

  public handleSelect(key: string, value: string) {
    this.setState({
      [key]: value,
    });
  }

  public handleChangesAddress(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public getIdentificationInput = (identificationType: any) => {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  };

  public handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };
  public handleAddClient(id: string) {
    const debtor = this.state.debtor.filter((debtorId) => debtorId !== id);
    debtor.push(id);
    this.setState({ debtor });
  }
  public handleDeleteClient(id: string) {
    const debtor = this.state.debtor.filter((debtorId) => debtorId !== id);
    this.setState({ debtor });
  }

  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    const creditReviewAuthorization: ICreditReviewAuthorization = {
      contractDate: this.state.contractDate,
      debtor: this.state.debtor[0],
      potentialDebtor: {
        fullName: this.state.fullName,
        cellPhone: this.state.cellPhone,
        email: this.state.email,
        identificationType: this.state.idType,
        identificationNumber: this.state.idNumber,
        address: {
          country: this.state.country,
          state: this.state.state,
          sector: this.state.sector,
          reference: this.state.reference,
          city: this.state.city,
          region: this.state.region,
        },
      },
      title: this.state.title,
      status: this.state.status,
    };
    event.preventDefault();
    resetError(this);

    if (this.state.debtor.length === 0 && this.state.newClient === false) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "El deudor es requerido",
      });
    } else if (
      this.state.debtor.length !== 0 &&
      this.state.newClient === false
    ) {
      this.setState({ loading: true });

      if (
        await debtorsValidation(
          this,
          this.props.user.token,
          this.state.debtor,
          false
        )
      ) {
        create(this.props.user.token, creditReviewAuthorization)
          .then((res) => {
            this.props.createCreditReviewAuthorization(
              res.data.results ? res.data.results : res.data
            );
            if (this.props.handleSaveCallBack) {
              this.props.handleSaveCallBack(res.data.uniqueId);
            }
            successHandler(res, this, translations.CREDIT_REVIEW_AUTHORIZATION);
            this.handleReset();
          })
          .catch((err: AxiosError) => {
            catchError(err, this);
          });
      }
    } else if (this.state.newClient) {
      create(this.props.user.token, creditReviewAuthorization)
        .then((res) => {
          this.props.createCreditReviewAuthorization(
            res.data.results ? res.data.results : res.data
          );
          if (this.props.handleSaveCallBack) {
            this.props.handleSaveCallBack(res.data.uniqueId);
          }
          successHandler(res, this, translations.CREDIT_REVIEW_AUTHORIZATION);
          this.handleReset();
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    }
  }
  public handleReset() {
    this.setState({
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      debtor: [],
      debtorError: "",
      title: "",
      titleError: "",
      newClient: false,
      fullName: "",
      idType: "",
      idNumber: "",
      email: "",
      cellPhone: "",
      country: "",
      reference: "",
      state: "",
      region: "",
      sector: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">
            {translations.NEW_CREDIT_REVIEW_AUTHORIZATION}
          </Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <TextField
                  label={translations.TITLE}
                  className={classes.textField}
                  value={this.state.title}
                  onChange={this.handleChange("title")}
                  required={true}
                  fullWidth={true}
                  error={this.state.titleError !== ""}
                  helperText={this.state.titleError}
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
                <TextField
                  label={translations.CONTRACT_DATE}
                  className={classes.textField}
                  style={{ marginLeft: 20 }}
                  value={this.state.contractDate}
                  type="date"
                  onChange={this.handleChange("contractDate")}
                  required={true}
                  error={this.state.contractDateError !== ""}
                  helperText={this.state.contractDateError}
                  InputLabelProps={{
                    shrink: true,
                    style: styles.checkboxLabel,
                  }}
                />
              </div>
              <br />
              <FormControlLabel
                control={
                  <GreenSwitch
                    checked={this.state.newClient}
                    onChange={this.handleChanges}
                    name="newClient"
                  />
                }
                label="¿Cliente nuevo?"
              />
              <br />
              <br />
              {this.state.newClient ? (
                <div>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={4}>
                      <TextField
                        label={"Nombre completo"}
                        fullWidth={true}
                        value={this.state.fullName}
                        onChange={this.handleChange("fullName")}
                        required={true}
                        InputLabelProps={{
                          style: styles.checkboxLabel,
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={4}>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor="idType" shrink={true}>
                          {translations.IDENTIFICATION_TYPE}
                        </InputLabel>
                        <Select
                          value={this.state.idType}
                          onChange={this.handleSelectChange("idType")}
                          required={true}
                          error={this.state.idTypeError !== ""}
                          inputProps={{
                            id: "idType",
                            name: "idType",
                          }}
                        >
                          {IDENTIFICATION_TYPE.map((opt, indx) => {
                            return (
                              <MenuItem value={opt.value} key={indx}>
                                {opt.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item={true} xs={4}>
                      <TextField
                        required={true}
                        value={this.state.idNumber || ""}
                        onChange={this.handleChange("idNumber")}
                        label={translations.IDENTIFICATION_NUMBER}
                        placeholder={translations.IDENTIFICATION_NUMBER}
                        InputLabelProps={{ shrink: true }}
                        fullWidth={true}
                        InputProps={this.getIdentificationInput(
                          this.state.idType
                        )}
                      />
                    </Grid>
                    <Grid item={true} xs={4}>
                      <TextField
                        fullWidth={true}
                        required={true}
                        value={this.state.cellPhone}
                        onChange={this.handleChange("cellPhone")}
                        label={translations.CELL_PHONE}
                        placeholder={translations.CELL_PHONE}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputComponent: phoneMaskInput }}
                      />
                    </Grid>
                    <Grid item={true} xs={4}>
                      <TextField
                        fullWidth={true}
                        required={true}
                        value={this.state.email}
                        onChange={this.handleChange("email")}
                        label={translations.EMAIL}
                        placeholder={translations.EMAIL}
                        InputLabelProps={{ shrink: true }}
                        type="email"
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <h3>{translations.ADDRESS}</h3>
                  <Address
                    handleSelect={this.handleSelect}
                    country={this.state.country}
                    countryError={""}
                    reference={this.state.reference}
                    referenceError={""}
                    state={this.state.state}
                    stateError={""}
                    city={this.state.city}
                    cityError={""}
                    region={this.state.region}
                    regionError={""}
                    sector={this.state.sector}
                    sectorError={""}
                    handleChange={this.handleChangesAddress}
                    max={true}
                  />
                  <br />
                </div>
              ) : (
                <div className={classes.half}>
                  <Typography variant="h6" className={classes.checkboxLabel}>
                    {translations.DEBTOR}
                  </Typography>
                  <ParticipantSelectInput
                    selectedParticipantId={this.state.debtor}
                    addParticipant={this.handleAddClient}
                    deleteParticipant={this.handleDeleteClient}
                  />
                  <p className={classes.errorInput}>{this.state.debtorError}</p>
                </div>
              )}
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createCreditReviewAuthorization })(
    CreditReviewAuthorizationForm
  )
);
