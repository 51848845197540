
import { PAYROLL_DEDUCTION_TYPES } from '../actions';

export default function(state = [], action) {
    switch(action.type) {
        case PAYROLL_DEDUCTION_TYPES.FETCH_ALL:
            return action.payload || [];
        case PAYROLL_DEDUCTION_TYPES.FETCH:
            return state.map(payrollDeduction => (action.payload !== undefined && payrollDeduction.uniqueId === action.payload[0].uniqueId) ? action.payload[0]: payrollDeduction);
        case PAYROLL_DEDUCTION_TYPES.CREATE: 
            return state.concat(action.payload || []).reverse();
        case PAYROLL_DEDUCTION_TYPES.UPDATE:
            return state.map(payrollDeduction => (action.payload !== undefined && payrollDeduction.uniqueId === action.payload[0].uniqueId) ? action.payload[0] : payrollDeduction);
        case PAYROLL_DEDUCTION_TYPES.DELETE:
            return state.filter(payrollDeduction => action.payload !== undefined && payrollDeduction.uniqueId !== action.payload[0].uniqueId);
        default:
            return state;
    }
}
