import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReaplyIcon from "@material-ui/icons/Replay";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

import { createLoanContract } from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import moneyInput from "../../../components/TextMaskInputComponents/moneyInput";
import {
  catchError,
  resetError,
  successHandler,
  translations,
  destination,
  loanTypes,
  debtorsValidation,
} from "../../../utils";
import { createLoanContract as create } from "../../../utils/axios";
import AttorneySelectInput from "../../Attorney/AttorneySelectInput";
import BankAccountSelectInput from "../../BankAccount/BankAccountSelectInput";
import CreditorSelectInput from "../../Creditor/CreditorSelectInput";
import DepositBankAccountSelectInput from "../../DepositBankAccount/DepositBankAccountSelectInput";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

interface ILoanContractFormProps {
  classes: {
    attachmentIcon: string;
    checkboxLabel: string;
    controls: string;
    errorInput: string;
    formControl: string;
    greenButton: string;
    half: string;
    paper: string;
    root: string;
    textField: string;
  };

  user: IUser;
  debtor?: string[];
  handleSaveCallBack?(uid: string): void;
  createLoanContract(loanContract: ILoanContract): IAction<ILoanContract[]>;
  changeSection(sectionNumber: number): void;
}
interface ILoanContractFormState {
  [key: string]: string | number | boolean | string[] | IBankAccount[];

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  loading: boolean;

  title: string; // done
  titleError: string; // done

  attorney: string[]; // done
  attorneyError: string;

  creditors: string[]; // done
  creditorsError: string;

  debtors: string[]; // done
  debtorsError: string;

  spouse: string[]; // done
  spouseError: string;

  warrants: string[]; // done
  warrantsError: string;

  witnesses: string[]; // done
  witnessesError: string;

  paymentMethod: string; // done
  paymentMethodError: string;

  chargingMethod: string; // done
  chargingMethodError: string;

  firstOutlayAmountCurrency: string; // done
  firstOutlayAmountCurrencyError: string;

  firstOutlayAmount: number; // done
  firstOutlayAmountError: string;

  term: string; // done
  termError: string;

  interestRate: string; // done
  interestRateError: string;

  administrativeExpensesRate: number; // done
  administrativeExpensesRateError: string;

  insuranceRate: number; // done
  insuranceRateError: string;

  insurance: boolean;

  outlayCommissionRate: string; // done
  outlayCommissionRateError: string;

  creditLineAmountCurrency: string; // done
  creditLineAmountCurrencyError: string;

  creditLineAmount: number; // done
  creditLineAmountError: string;

  contractDate: string; // done
  contractDateError: string;

  firstOutlayDate: string;
  firstOutlayDateError: string;

  destination: string;
  destinationError: string;

  paymentMethodConfigBankAccount: string[];
  paymentMethodConfigBankAccountError: string;

  paymentMethodConfigSecurityInformation: string;
  paymentMethodConfigSecurityInformationError: string;

  chargingMethodConfigBankAccount: string[];
  chargingMethodConfigBankAccountError: string;

  chargingMethodConfigSecurityInformation: string;
  chargingMethodConfigSecurityInformationError: string;

  loanType: string;
  loanTypeError: string;

  // for bankAccounts inputs
  bankAccountError: string;
}
class LoanContractForm extends React.Component<
  ILoanContractFormProps,
  ILoanContractFormState
> {
  public state: ILoanContractFormState = {
    attorney: [], // done
    attorneyError: "",
    bankAccountError: "",
    chargingMethod: "cash", // done
    chargingMethodConfigBankAccount: [],
    chargingMethodConfigBankAccountError: "",
    chargingMethodConfigSecurityInformation: "",
    chargingMethodConfigSecurityInformationError: "",
    loanType: "",
    loanTypeError: "",
    chargingMethodError: "",
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    creditLineAmount: 0,
    creditLineAmountCurrency: "DOP",
    creditLineAmountCurrencyError: "",
    creditLineAmountError: "",
    creditors: [], // done
    creditorsError: "",
    debtors: [], // done
    debtorsError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    firstOutlayAmount: 0,
    firstOutlayAmountCurrency: "DOP",
    firstOutlayAmountCurrencyError: "",
    firstOutlayAmountError: "",
    firstOutlayDate: new Date().toISOString().split("T")[0],
    firstOutlayDateError: "",
    interestRate: "5.5",
    interestRateError: "",
    insuranceRate: 0,
    insuranceRateError: "",
    insurance: true,
    administrativeExpensesRate: 0,
    administrativeExpensesRateError: "",
    destination: "",
    destinationError: "",
    term: "3.0",
    termError: "",
    loading: false,
    outlayCommissionRate: "5.5",
    outlayCommissionRateError: "",
    paymentMethod: "cash", // done
    paymentMethodConfigBankAccount: [],
    paymentMethodConfigBankAccountError: "",
    paymentMethodConfigSecurityInformation: "",
    paymentMethodConfigSecurityInformationError: "",
    paymentMethodError: "",
    spouse: [], // done,
    spouseError: "",
    title: "", // done
    titleError: "", // done
    warrants: [], // done
    warrantsError: "",
    witnesses: [], // done
    witnessesError: "",
  };

  constructor(props: ILoanContractFormProps) {
    super(props);

    // creditor
    this.handleAddCreditor = this.handleAddCreditor.bind(this);
    this.handleDeleteCreditor = this.handleDeleteCreditor.bind(this);

    // client
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // attorney
    this.handleAddAttorney = this.handleAddAttorney.bind(this);
    this.handleDeleteAttorney = this.handleDeleteAttorney.bind(this);

    // witnesess
    this.handleAddWitness = this.handleAddWitness.bind(this);
    this.handleDeleteWitness = this.handleDeleteWitness.bind(this);

    // warrant
    this.handleAddWarrant = this.handleAddWarrant.bind(this);
    this.handleDeleteWarrant = this.handleDeleteWarrant.bind(this);

    // wife || spouse
    this.handleAddWife = this.handleAddWife.bind(this);
    this.handleDeleteWife = this.handleDeleteWife.bind(this);

    // bank accouts
    this.handleAddPayBankAccount = this.handleAddPayBankAccount.bind(this);
    this.handleDeletePaybankAccount = this.handleDeletePaybankAccount.bind(
      this
    );
    this.handleAddCharBankAccount = this.handleAddCharBankAccount.bind(this);
    this.handleDeleteCharBankAccount = this.handleDeleteCharBankAccount.bind(
      this
    );

    // core event
    this.handleChange = this.handleChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }

  public handleCheckBoxChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ [key]: event.target.checked });
    };
  }
  public handleAddCharBankAccount(id: string) {
    this.setState({ chargingMethodConfigBankAccount: [id] });
  }
  public handleDeleteCharBankAccount(id: string) {
    this.setState({ chargingMethodConfigBankAccount: [] });
  }
  public handleAddPayBankAccount(id: string) {
    this.setState({ paymentMethodConfigBankAccount: [id] });
  }
  public handleDeletePaybankAccount(id: string) {
    this.setState({ paymentMethodConfigBankAccount: [] });
  }
  public handleAddCreditor(id: string) {
    const creditors = this.state.creditors.filter(
      (creditorId) => creditorId !== id
    );
    creditors.push(id);
    this.setState({ creditors });
  }
  public handleDeleteCreditor(id: string) {
    const creditors = this.state.creditors.filter(
      (creditorId) => creditorId !== id
    );
    this.setState({ creditors });
  }
  public handleAddClient(id: string) {
    const debtors = this.state.debtors.filter((debtorId) => debtorId !== id);
    debtors.push(id);
    this.setState({ debtors });
  }
  public handleDeleteClient(id: string) {
    const debtors = this.state.debtors.filter((debtorId) => debtorId !== id);
    this.setState({ debtors });
  }
  public handleAddAttorney(id: string) {
    const attorney: string[] = [id];
    this.setState({ attorney });
  }
  public handleDeleteAttorney(id: string) {
    this.setState({ attorney: [] });
  }
  public handleAddWitness(id: string) {
    const witnesses = this.state.witnesses.filter(
      (witnessId) => witnessId !== id
    );
    witnesses.push(id);
    this.setState({ witnesses });
  }
  public handleDeleteWitness(id: string) {
    const witnesses = this.state.witnesses.filter(
      (witnessId) => witnessId !== id
    );
    this.setState({ witnesses });
  }
  public handleAddWarrant(id: string) {
    const warrants = this.state.warrants.filter(
      (warrantId) => warrantId !== id
    );
    warrants.push(id);
    this.setState({ warrants });
  }
  public handleDeleteWarrant(id: string) {
    const warrants = this.state.warrants.filter(
      (warrantId) => warrantId !== id
    );
    this.setState({ warrants });
  }
  public handleAddWife(id: string) {
    this.setState({ spouse: [id] });
  }
  public handleDeleteWife(id: string) {
    this.setState({ spouse: [""] });
  }
  public handleCurrencyInputChange(key: string) {
    return (
      event: React.ChangeEvent<HTMLInputElement>,
      maskedValue?: string,
      floatValue?: string
    ) => {
      this.setState({ [key]: floatValue || 0 });
    };
  }
  public handleChange(key: string) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({ [key]: event.target.value });
    };
  }

  public async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetError(this);
    const { state } = this;

    if (
      this.state.chargingMethodConfigBankAccount.length === 0 ||
      this.state.paymentMethodConfigBankAccount.length === 0
    ) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "La Cuenta de cobro y la Cuenta de Pago son requeridos.",
      });
    } else if (
      this.state.firstOutlayAmount === 0.0 ||
      this.state.creditLineAmount === 0.0 ||
      this.state.destination === ""
    ) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "Llene todos los campos del contrato",
      });
    } else if (
      this.state.debtors.length > 1 &&
      this.state.spouse.length === 1
    ) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "No puede haber más de un deudor y un cónyuge",
      });
    } else if (
      state.debtors.length !== 0 &&
      state.attorney.length !== 0 &&
      state.creditors.length !== 0
    ) {
      const loanContract: ILoanContract = {
        attorney: state.attorney[0], // done
        chargingMethod: state.paymentMethod, // done
        chargingMethodConfig: {
          bankAccountSecurityInformation: this.state
            .chargingMethodConfigSecurityInformation,
          depositBankAccount:
            this.state.chargingMethodConfigBankAccount[0] || "",
        },
        contractDate: state.contractDate,
        warranty: state.loanType,
        creditLineAmount:
          state.creditLineAmount.toString().trim() === ""
            ? 0
            : state.creditLineAmount, // done
        creditLineAmountCurrency: state.creditLineAmountCurrency, // done
        creditors: state.creditors, // done
        debtors: state.debtors, // done
        firstOutlayAmount:
          state.firstOutlayAmount.toString().trim() === ""
            ? 0
            : state.firstOutlayAmount, // done
        firstOutlayAmountCurrency: state.firstOutlayAmountCurrency, //  done
        firstOutlayDate: this.state.firstOutlayDate,
        interestRate: state.interestRate, // done
        insuranceRate: state.insuranceRate, // done
        administrativeExpensesRate: state.administrativeExpensesRate, // done
        destination: state.destination, // done
        outlayCommissionRate: state.outlayCommissionRate,
        paymentMethod: state.paymentMethod, // done
        paymentMethodConfig: {
          bankAccount: this.state.paymentMethodConfigBankAccount[0] || "",
        },
        spouse: this.state.spouse[0], // done
        title: state.title, // done
        warrants: this.state.warrants, // done
        witnesses: this.state.witnesses, // done
        term: this.state.term, // done
      };

      this.setState({ loading: true });

      if (
        await debtorsValidation(
          this,
          this.props.user.token,
          this.state.debtors,
          false
        )
      ) {
        create(this.props.user.token, loanContract)
          .then((res) => {
            this.props.createLoanContract(
              res.data.results ? res.data.results : res.data
            );
            if (this.props.handleSaveCallBack) {
              this.props.handleSaveCallBack(res.data.uniqueId);
            }
            successHandler(res, this, translations.LOAN_CONTRACT);
            this.handleReset();
          })
          .catch((err: AxiosError) => {
            catchError(err, this);
          });
      }
    } else {
      this.setState({
        dialog: true,
        dialogTitle: "Error",
        errorMessage: translations.PLEASE_SELECT_AT_LEAST,
        loading: false,
      });
    }
  }
  public handleReset() {
    this.setState({
      attorney: [], // done
      attorneyError: "",
      bankAccountError: "",
      chargingMethod: "cash", // done
      chargingMethodConfigBankAccount: [],
      chargingMethodConfigBankAccountError: "",
      chargingMethodConfigSecurityInformation: "",
      chargingMethodConfigSecurityInformationError: "",
      chargingMethodError: "",
      contractDate: new Date().toISOString().split("T")[0],
      contractDateError: "",
      creditLineAmount: 0,
      creditLineAmountCurrency: "DOP",
      creditLineAmountCurrencyError: "",
      creditLineAmountError: "",
      creditors: [], // done
      creditorsError: "",
      debtors: [], // done
      debtorsError: "",
      firstOutlayAmount: 0,
      firstOutlayAmountCurrency: "DOP",
      firstOutlayAmountCurrencyError: "",
      firstOutlayAmountError: "",
      firstOutlayDate: new Date().toISOString().split("T")[0],
      firstOutlayDateError: "",
      interestRate: "5.5",
      interestRateError: "",
      insuranceRate: 0,
      insuranceRateError: "",
      term: "3.0",
      termError: "",
      insurance: true,
      administrativeExpensesRate: 0,
      administrativeExpensesRateError: "",
      destination: "",
      destinationError: "",
      outlayCommissionRate: "5.5",
      outlayCommissionRateError: "",
      paymentMethod: "cash", // done
      paymentMethodConfigBankAccount: [],
      paymentMethodConfigBankAccountError: "",
      paymentMethodConfigSecurityInformation: "",
      paymentMethodConfigSecurityInformationError: "",
      loanType: "",
      loanTypeError: "",
      paymentMethodError: "",
      spouse: [], // done
      spouseError: "",
      title: "", // done
      titleError: "", // done
      warrants: [], // done
      warrantsError: "",
      witnesses: [], // done
      witnessesError: "",
    });
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public componentDidMount() {
    this.setState({
      debtors: this.state.debtors.length
        ? this.state.debtors
        : this.props.debtor || [],
    });
  }
  public render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">{translations.NEW_LOAN_CONTRACT}</Typography>
          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <TextField
                label={translations.TITLE}
                className={classes.textField}
                value={this.state.title}
                onChange={this.handleChange("title")}
                fullWidth={true}
                required={true}
                error={this.state.titleError !== ""}
                helperText={this.state.titleError}
                InputLabelProps={{ style: styles.checkboxLabel }}
              />
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.CREDITORS}
                </Typography>
                <CreditorSelectInput
                  selectedCreditorsId={this.state.creditors}
                  addCreditor={this.handleAddCreditor}
                  deleteCreditor={this.handleDeleteCreditor}
                />
                <p className={classes.errorInput}>
                  {this.state.creditorsError}
                </p>
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.DEBTORS}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtors}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                />
                <p className={classes.errorInput}>{this.state.debtorsError}</p>
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.ATTORNEY}
                </Typography>
                <AttorneySelectInput
                  selectedAttorneysId={this.state.attorney}
                  addAttorney={this.handleAddAttorney}
                  deleteAttorney={this.handleDeleteAttorney}
                />
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.WITNESSES}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.witnesses}
                  addParticipant={this.handleAddWitness}
                  deleteParticipant={this.handleDeleteWitness}
                />
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.WARRANT}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.warrants}
                  addParticipant={this.handleAddWarrant}
                  deleteParticipant={this.handleDeleteWarrant}
                />
              </div>
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.WIFE}
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.spouse}
                  addParticipant={this.handleAddWife}
                  deleteParticipant={this.handleDeleteWife}
                />
                <p className={classes.errorInput}>{this.state.spouseError}</p>
              </div>
              <br />
              <Paper style={{ padding: 12, width: "70%" }}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.PAYMENT_SETTINGS}
                </Typography>
                <FormControl
                  className={classes.formControl}
                  error={this.state.paymentMethodError !== ""}
                >
                  <InputLabel htmlFor="paymentMethod" shrink={true}>
                    {translations.PAYMENT_METHOD}
                  </InputLabel>
                  <Select
                    value={this.state.paymentMethod}
                    onChange={this.handleSelectChange("paymentMethod")}
                    required={true}
                    native={true}
                    inputProps={{
                      id: "paymentMethod",
                      name: "paymentMethod",
                    }}
                  >
                    <option value="cash">{translations.CASH}</option>
                    <option value="internet banking">
                      {translations.BANK_TRANSFER}
                    </option>
                    <option value="cheque">{translations.CHECK}</option>
                  </Select>
                </FormControl>
                <p className={classes.errorInput}>
                  {this.state.paymentMethodError}
                </p>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.BANK}
                </Typography>
                <BankAccountSelectInput
                  selectedBankAccountsId={
                    this.state.paymentMethodConfigBankAccount
                  }
                  addBankAccount={this.handleAddPayBankAccount}
                  deleteBankAccount={this.handleDeletePaybankAccount}
                />
                <p className={classes.errorInput}>
                  {this.state.bankAccountError}
                </p>
                <TextField
                  label={translations.SECURITY_INFORMATION}
                  className={classes.textField}
                  style={{ display: "none" }}
                  value={this.state.paymentMethodConfigSecurityInformation}
                  onChange={this.handleChange(
                    "paymentMethodConfigSecurityInformation"
                  )}
                  fullWidth={true}
                  multiline={true}
                  error={
                    this.state.paymentMethodConfigSecurityInformationError !==
                    ""
                  }
                  helperText={
                    this.state.paymentMethodConfigSecurityInformationError
                  }
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
              </Paper>
              <br />
              <Paper style={{ padding: 12, width: "70%" }}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.BILLING_SETTING}
                </Typography>
                <br />
                <Typography variant="h6" className={classes.checkboxLabel}>
                  {translations.BANK}
                </Typography>
                <DepositBankAccountSelectInput
                  selectedDepositBankAccountsId={
                    this.state.chargingMethodConfigBankAccount
                  }
                  addDepositBankAccount={this.handleAddCharBankAccount}
                  deleteDepositBankAccount={this.handleDeleteCharBankAccount}
                />
                <p className={classes.errorInput}>
                  {this.state.bankAccountError}
                </p>
                <TextField
                  label={translations.SECURITY_INFORMATION}
                  className={classes.textField}
                  value={this.state.chargingMethodConfigSecurityInformation}
                  onChange={this.handleChange(
                    "chargingMethodConfigSecurityInformation"
                  )}
                  fullWidth={true}
                  multiline={true}
                  error={
                    this.state.chargingMethodConfigSecurityInformationError !==
                    ""
                  }
                  helperText={
                    this.state.chargingMethodConfigSecurityInformationError
                  }
                  InputLabelProps={{ style: styles.checkboxLabel }}
                />
              </Paper>
              <br />
              <br />
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <TextField
                    label={`${translations.ADMINISTRATION_EXPENSES}: ${this.state.administrativeExpensesRate}%`}
                    fullWidth={true}
                    value={this.state.administrativeExpensesRate}
                    type="number"
                    onChange={this.handleChange("administrativeExpensesRate")}
                    required={true}
                    error={this.state.administrativeExpensesRateError !== ""}
                    helperText={this.state.administrativeExpensesRateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 100,
                      min: 0,
                      step: 0.01,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label={`¿${translations.INSURANCE}?`}
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.insurance}
                        onChange={this.handleCheckBoxChange("insurance")}
                      />
                    }
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={`${translations.INTEREST_RATE}: ${this.state.interestRate}%`}
                    fullWidth={true}
                    value={this.state.interestRate}
                    type="number"
                    onChange={this.handleChange("interestRate")}
                    required={true}
                    error={this.state.interestRateError !== ""}
                    helperText={this.state.interestRateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 100,
                      min: 0,
                      step: 0.01,
                    }}
                  />
                </Grid>
                <Grid
                  item={true}
                  xs={4}
                  style={{ display: this.state.insurance ? "block" : "none" }}
                >
                  <TextField
                    label={`${translations.INSURANCE_RATE}: ${this.state.insuranceRate}%`}
                    fullWidth={true}
                    value={this.state.insuranceRate}
                    type="number"
                    onChange={this.handleChange("insuranceRate")}
                    required={true}
                    error={this.state.insuranceRateError !== ""}
                    helperText={this.state.insuranceRateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 100,
                      min: 0,
                      step: 0.01,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={`${translations.TERM} en meses`}
                    fullWidth={true}
                    value={this.state.term}
                    type="number"
                    onChange={this.handleChange("term")}
                    required={true}
                    error={this.state.termError !== ""}
                    helperText={this.state.termError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 100,
                      min: 0,
                      step: 0.01,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={`${translations.OUTLAY_COMMISSION_RATE}: ${this.state.outlayCommissionRate}%`}
                    fullWidth={true}
                    value={this.state.outlayCommissionRate}
                    type="number"
                    onChange={this.handleChange("outlayCommissionRate")}
                    required={true}
                    error={this.state.outlayCommissionRateError !== ""}
                    helperText={this.state.outlayCommissionRateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                    inputProps={{
                      max: 100,
                      min: 0,
                      step: 0.01,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={translations.FIRST_OUTLAY_DATE}
                    fullWidth={true}
                    value={this.state.firstOutlayDate}
                    type="date"
                    onChange={this.handleChange("firstOutlayDate")}
                    required={true}
                    error={this.state.firstOutlayDateError !== ""}
                    helperText={this.state.firstOutlayDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={translations.CONTRACT_DATE}
                    value={this.state.contractDate}
                    type="date"
                    fullWidth={true}
                    onChange={this.handleChange("contractDate")}
                    required={true}
                    error={this.state.contractDateError !== ""}
                    helperText={this.state.contractDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <FormControl fullWidth={true}>
                    <InputLabel
                      id="destination"
                      error={this.state.destinationError ? true : false}
                    >
                      {translations.DESTINATION}
                    </InputLabel>
                    <Select
                      id="destination"
                      label={translations.DESTINATION}
                      onChange={this.handleSelectChange("destination")}
                      value={this.state.destination}
                    >
                      {destination.map((opt, indx) => (
                        <MenuItem key={indx} value={opt.value}>
                          {opt.value}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {this.state.destinationError}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={4}>
                  <FormControl fullWidth={true}>
                    <InputLabel
                      id="loanType"
                      error={this.state.loanTypeError ? true : false}
                    >
                      {translations.WARRANTY_TYPES}
                    </InputLabel>
                    <Select
                      id="loanType"
                      label={translations.WARRANTY_TYPES}
                      onChange={this.handleSelectChange("loanType")}
                      value={this.state.loanType}
                    >
                      {loanTypes.map((opt, indx) => (
                        <MenuItem key={indx} value={opt.label}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{this.state.loanTypeError}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={translations.FIRST_OUTLAY_AMOUNT}
                    value={this.state.firstOutlayAmount}
                    onChange={this.handleCurrencyInputChange(
                      "firstOutlayAmount"
                    )}
                    required={true}
                    error={this.state.firstOutlayAmountError !== ""}
                    helperText={this.state.firstOutlayAmountError}
                    style={{ width: "86%" }}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                  <FormControl
                    style={{ marginTop: "16px" }}
                    error={this.state.firstOutlayAmountCurrencyError !== ""}
                  >
                    <Select
                      value={this.state.firstOutlayAmountCurrency}
                      onChange={this.handleSelectChange(
                        "firstOutlayAmountCurrency"
                      )}
                      required={true}
                      native={true}
                      inputProps={{
                        id: "firstOutlayAmountCurrency",
                        name: "firstOutlayAmountCurrency",
                      }}
                    >
                      <option value="DOP">{translations.DOP}</option>
                      <option value="USD">{translations.USD}</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={translations.CREDIT_LINE_AMOUNT}
                    value={this.state.creditLineAmount}
                    onChange={this.handleCurrencyInputChange(
                      "creditLineAmount"
                    )}
                    required={true}
                    error={this.state.creditLineAmountError !== ""}
                    style={{ width: "86%" }}
                    helperText={this.state.creditLineAmountError}
                    InputProps={{
                      inputComponent: moneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                  <FormControl
                    style={{ marginTop: "16px" }}
                    error={this.state.creditLineAmountCurrencyError !== ""}
                  >
                    <Select
                      value={this.state.creditLineAmountCurrency}
                      onChange={this.handleSelectChange(
                        "creditLineAmountCurrency"
                      )}
                      required={true}
                      native={true}
                      inputProps={{
                        id: "creditLineAmountCurrency",
                        name: "creditLineAmountCurrency",
                      }}
                    >
                      <option value="DOP">{translations.DOP}</option>
                      <option value="USD">{translations.USD}</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleReset}
                  className={classes.greenButton}
                >
                  <ReaplyIcon />
                  &nbsp;
                  {translations.RESET}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { createLoanContract })(LoanContractForm)
);
