import * as React from "react";
// import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from "react-redux";
import { catchError, translations } from "src/utils";

import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DeleteIcon from "@material-ui/icons/Delete";
import WorkIcon from "@material-ui/icons/Work";

import { fetchCreditors } from "../../../actions";
import { fetchCreditors as fetch } from "../../../utils/axios";
import CreditorSearchSection from "../../Creditor/CreditorSearchSection";
import FinancialProfile from "../../FinancialProfile/index";
import WorkProfile from "../../WorkProfile";
import CreditorForm from "../CreditorForm";

const styles: any = createStyles({
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

interface ICreditorSelectInputProps {
  classes: {
    root: string;
    button: string;
  };
  user: IUser;
  creditors: ICreditor[];
  selectedCreditorsId: string[];
  addCreditor(creditorId: string): void;
  deleteCreditor(creditorId: string): void;
  fetchCreditors(creditors: ICreditor[]): IAction<ICreditor[]>;
}
interface ICreditorSelectInputState {
  creditors: ICreditor[];
  financialProfileDialog: boolean;
  formDialog: boolean;
  id: string;
  loading: boolean;
  open: boolean;
  workProfileDialog: boolean;
}

class CreditorSelectInput extends React.Component<
  ICreditorSelectInputProps,
  ICreditorSelectInputState
> {
  public state: ICreditorSelectInputState = {
    creditors: [],
    financialProfileDialog: false,
    formDialog: false,
    id: "",
    loading: false,
    open: false,
    workProfileDialog: false,
  };
  constructor(props: ICreditorSelectInputProps) {
    super(props);

    this.openFinancialProfile = this.openFinancialProfile.bind(this);
    this.closeFinancialProfile = this.closeFinancialProfile.bind(this);
    this.openWorkProfile = this.openWorkProfile.bind(this);
    this.closeWorkProfile = this.closeWorkProfile.bind(this);
    this.findCreditors = this.findCreditors.bind(this);
    this.openDialogHandler = this.openDialogHandler.bind(this);
    this.closeDialogHandler = this.closeDialogHandler.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.closeFormDialog = this.closeFormDialog.bind(this);
    this.emptyFunction = this.emptyFunction.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  public componentDidMount() {
    this.setState({ loading: true });

    fetch({ token: this.props.user.token })
      .then((res) => {
        this.props.fetchCreditors(
          res.data.results ? res.data.results : res.data
        );

        this.setState({
          creditors: (res.data.results
            ? res.data.results
            : res.data) as ICreditor[],
          loading: false,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public openFinancialProfile(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.setState({ id, financialProfileDialog: true });
    };
  }
  public closeFinancialProfile() {
    this.setState({ financialProfileDialog: false });
  }
  public openWorkProfile(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.setState({ id, workProfileDialog: true });
    };
  }
  public closeWorkProfile() {
    this.setState({ workProfileDialog: false });
  }
  public openDialogHandler() {
    this.setState({ open: true });
  }
  public closeDialogHandler() {
    this.setState({ open: false });
  }
  public openFormDialog() {
    this.setState({ formDialog: true });
  }
  public closeFormDialog() {
    this.setState({ formDialog: false });
  }
  public emptyFunction() {
    return null;
  }
  public handleItemClick(creditorId: string) {
    this.setState({ loading: true });

    fetch({ token: this.props.user.token })
      .then((res) => {
        this.props.fetchCreditors(
          res.data.results ? res.data.results : res.data
        );

        this.setState(
          {
            creditors: (res.data.results
              ? res.data.results
              : res.data) as ICreditor[],
            loading: false,
            open: false,
          },
          () => {
            this.props.addCreditor(creditorId);
          }
        );
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      this.props.deleteCreditor(id);
    };
  }
  public findCreditors(uniqueId: string) {
    const curCreditor: ICreditor | undefined = this.state.creditors.find(
      (creditor) => creditor.uniqueId === uniqueId
    );

    if (curCreditor) {
      return (
        <TableRow key={curCreditor.uniqueId}>
          <TableCell>
            {curCreditor.type === "physical"
              ? curCreditor.participantDetail?.fullName
              : curCreditor.institutionDetail?.name}
          </TableCell>
          <TableCell>
            {curCreditor.type === "physical"
              ? translations.PHYSICAL
              : translations.LEGAL_2}
          </TableCell>
          <TableCell>
            {curCreditor.type === "physical"
              ? curCreditor.participantDetail?.identificationNumber
              : curCreditor.institutionDetail?.registry}
          </TableCell>
          <TableCell>
            <IconButton
              onClick={this.openFinancialProfile(
                curCreditor?.uniqueId ? curCreditor.uniqueId : ""
              )}
            >
              <AssignmentIndIcon />
            </IconButton>
          </TableCell>
          <TableCell>
            <IconButton
              onClick={this.openWorkProfile(
                curCreditor?.uniqueId ? curCreditor.uniqueId : ""
              )}
            >
              <WorkIcon />
            </IconButton>
          </TableCell>
          <TableCell>
            <IconButton
              onClick={this.handleDeleteItem(
                curCreditor.uniqueId ? curCreditor.uniqueId : ""
              )}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    } else {
      return null;
    }
  }
  public fetchData(params: IFetchCreditorParams) {
    params.limit = undefined;
    params.offset = 0;

    this.setState({ loading: true });
    fetch(params)
      .then((res) => {
        this.props.fetchCreditors(
          (res.data.results ? res.data.results : res.data) as ICreditor[]
        );
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  public render() {
    const { classes } = this.props;
    const {
      formDialog,
      financialProfileDialog,
      id,
      loading,
      open,
      workProfileDialog,
    } = this.state;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={this.closeDialogHandler}>
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.openFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: 600, width: 280 }}>
            <CreditorSearchSection
              loading={loading}
              handleClick={this.handleItemClick}
              fetchData={this.fetchData}
              pagination={false}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={formDialog}
          onClose={this.closeFormDialog}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <CreditorForm changeSection={this.emptyFunction} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={financialProfileDialog}
          onClose={this.closeFinancialProfile}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeFinancialProfile}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <FinancialProfile ownerId={id} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={workProfileDialog}
          onClose={this.closeWorkProfile}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton
              className={classes.button}
              onClick={this.closeWorkProfile}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <WorkProfile ownerId={id} />
          </DialogContent>
        </Dialog>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.NAME}</TableCell>
                <TableCell>{translations.TYPE}</TableCell>
                <TableCell>{translations.IDENTIFICATION}</TableCell>
                <TableCell>{translations.FINANCIAL_PROFILE}</TableCell>
                <TableCell>{translations.WORK_PROFILE}</TableCell>
                <TableCell>
                  <IconButton onClick={this.openDialogHandler}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.selectedCreditorsId.map((creditorId) =>
                this.findCreditors(creditorId)
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    creditors: state.creditors,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { fetchCreditors })(CreditorSelectInput)
);
