import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Paper,
  Divider,
  Grid,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import {
  Add,
  Send,
  Delete,
  Loop,
  DeleteOutline,
  Visibility,
  CancelOutlined,
  Timeline,
} from "@material-ui/icons";
import { ToolbarComponent } from "src/components/SharedComponents/Toolbar";
import { TicketTable } from "./../Tables";
import {
  ConfirmDialogComponent,
  DataAdquisitionDialog,
  TicketCreationDialog,
  FileManagementDialog,
  FilesManagementGeneral,
  CancelTicket,
  PaymentCalculatorDialog,
  ParticipantDetail,
  ConfirmPrevTicket,
  ActivityInfoData,
  // TicketExtraManagement
} from "./../Dialogs";
import { AdmissionRequestDetail } from "../TicketDetails/AdmissionRequest";
import DisbursementRequestDetail from "../TicketDetails/DisbursementRequest/index";
import { PaymentDetail } from "../TicketDetails/Payment";

import { ticketHistoryGeneral, handlePrevStep } from "../TicketGeneralMethods";
import { ticketStates } from "../TicketGeneralMethods/consts";
import {
  SuccessDialog,
  ErrorDialog,
  SimpleConfirmDialog,
} from "src/components/SharedComponents/Dialogs";
import { Filters } from "./../Filter";
import {
  fetchAssignees,
  fetchTickets,
  fetchTicket,
  updateTicket,
  fetchParticipant,
  fetchTime,
} from "src/utils/axios";
import { REQUEST_TICKET_TYPES } from "src/utils";
import LoadingDialog from "src/components/SharedComponents/LoadingScreen";
import { errorHandling } from "src/components/SharedComponents/CustomHooks";
import {
  TextFieldComponent,
  SearchComponent,
  CheckboxField,
} from "src/components/SharedComponents/Fields";
import jwtDecode from "jwt-decode";
import "./../index.css";
import { green, orange, red } from "@material-ui/core/colors";
import { set } from "lodash";
import { search } from "src/actions";

function TicketExtraManagement(props) {
  const [extraTicketData, setExtraTicketData] = useState({});
  const [comments, setComments] = useState([]);
  const [cancelObj, setCancelObj] = useState(null);
  const currentStep = props.ticket.currentStep;
  useEffect(() => {
    if (props.ticket) {
      getTicketExtraInfo();
    }
    handleCancelData();
  }, [props.ticket, props.reloadTicket]);

  const ticketStep = (step) =>
    props.ticket.steps.find((item) => item.step === step);

  const checkCurrentStepOrder = () => {
    const verify =
      ticketStep(currentStep)?.order >=
      ticketStep("credit_formalization")?.order;
    return verify;
  };

  const handleExtraTicketData = (field, event, value) => {
    if (event) {
      const data = value
        ? value
        : event && event.target
        ? event.target.value
        : event;
      setExtraTicketData({ ...extraTicketData, [field]: data });
      switch (field) {
        case "reviewer":
        case "assignee":
          const dataToModify = {
            [field]: data.value,
          };
          const history = `Se ha modificado el campo ${field} del ticket a: ${data.name}`;
          ticketHistoryGeneral(props, history).then((response) => {
            const history = response;
            props.modifyTicket(dataToModify, history);
          });
          break;
        default:
          break;
      }
    }
  };

  const getTicketExtraInfo = () => {
    setExtraTicketData({
      ...extraTicketData,
      description: props.ticket.description,
      reviewer: props.ticket.reviewer
        ? { name: props.ticket.reviewer, value: props.ticket.reviewer }
        : null,
      assignee: props.ticket.assignee
        ? { name: props.ticket.assignee, value: props.ticket.assignee }
        : null,
      approvedBy: props.ticket.approvedBy
        ? { name: props.ticket.approvedBy, value: props.ticket.approvedBy }
        : null,
    });
    setComments(props.ticket.comments || []);
  };

  const handleSendComment = async () => {
    if (!extraTicketData.comment) {
      return;
    }
    const ticketComments = props.ticket.comments || [];
    const time = await fetchTime().then((response) => response.data);
    const data = {
      comment: extraTicketData.comment,
      time: time,
      user: props.loggedUser,
    };
    const dataToModify = {
      comments: ticketComments.concat(data),
    };
    props.modifyTicket(dataToModify);
    setExtraTicketData({ ...extraTicketData, comment: "" });
  };

  const handleDescription = () => {
    if (!extraTicketData.description) {
      return;
    }
    const dataToModify = {
      description: extraTicketData.description,
    };
    const historyDescription = `Se ha modificado la descripción del ticket a: ${extraTicketData.description}`;
    ticketHistoryGeneral(props, historyDescription).then((response) => {
      const history = response;
      props.modifyTicket(dataToModify, history);
    });
  };

  const handleDeleteComment = (comment) => {
    const ticketComments = [...props.ticket.comments];
    const index = ticketComments.findIndex(
      (item) =>
        item.time === comment.time &&
        item.comment === comment.comment &&
        item.user === comment.user
    );
    ticketComments.splice(index, 1);
    const dataToModify = {
      comments: ticketComments,
    };
    props.modifyTicket(dataToModify);
    props.handleToggleSimpleDialog();
  };

  const handleCancelData = async () => {
    if (
      ["cancelled", "rejected", "rejected_rrhh"].includes(props.ticket.status)
    ) {
      const word =
        props.ticket.status === "cancelled" ? "Cancelado" : "Rechazado";
      const ingWord =
        props.ticket.status === "cancelled" ? "cancelación" : "rechazo";
      const userParams = {
        username: props.ticket.cancelledBy,
      };
      const user = await props.getUser(userParams);
      const userFullName = (
        (user?.firstName || "") +
        " " +
        (user?.lastName || "")
      ).trim();
      const cancel = [
        {
          label: `${word} por`,
          value: userFullName || props.ticket.cancelledBy || "---",
        },
        {
          label: `Razón ${ingWord}`,
          value: props.ticket.cancellationReason || "---",
        },
        {
          label: `Fecha ${ingWord}`,
          value: props.ticket.cancelledDate || "---",
        },
      ];
      setCancelObj(cancel);
    }
  };

  const statusColors = [
    { bgColor: "#43A047", color: "#FFF", state: "affiliate", name: "Afiliado" },
    {
      bgColor: "#f44336",
      color: "#FFF",
      state: "unaffiliated",
      name: "Desafiliado",
    },
    { bgColor: "#f44336", color: "#FFF", state: "retired", name: "Retirado" },
    {
      bgColor: "#f7c552",
      color: "#FFF",
      state: "liquidated",
      name: "Liquidado",
    },
    {
      bgColor: "#f7c552",
      color: "#FFF",
      state: "passed_away",
      name: "Fallecido",
    },
    {
      bgColor: "#1ea0f7",
      color: "#FFF",
      state: "not_affiliate",
      name: "No socio",
    },
  ];
  const coloredLabel = statusColors.find(
    (item) => item.state === props.ticket.participantDetail?.affiliateState
  );

  const institutionStatus = props.ticket.affiliatedInstitution
    ? {
        bgColor: "#43A047",
        color: "#FFF",
        state: "affiliate",
        name: "Afiliado",
      }
    : {
        bgColor: "#f44336",
        color: "#FFF",
        state: "unaffiliated",
        name: "Desafiliado",
      };

  return (
    <div>
      {/* <ToolbarComponent
                typeVariant="h6"
                typeClass="title-text"
                toolbarTitle="Gestión Extra del Ticket 2"
                btnVariant="contained"
            /> */}
      <Paper square style={{ padding: 16 }}>
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Typography
            variant={"h6"}
            className={"title-text"}
            style={{ textAlign: "right" }}
          >
            <strong>
              {props.ticket &&
                `${props.ticket.participantDetail?.fullName ||
                  "NO ENCONTRADO"} - ${props.ticket.participantDetail?.rohiId ||
                  "0"}`}
            </strong>
          </Typography>
          <IconButton
            onClick={props.handleToggleDialog.bind(this, "participant-details")}
            disabled={!Boolean(props.ticket.participantDetail)}
          >
            <Visibility
              style={{
                fontSize: 22,
                color: props.ticket.participantDetail ? green[500] : "#bdbdbd",
              }}
            />
          </IconButton>
        </div>
        <Divider />
        <div style={{ display: "flex" }}>
          <Typography
            variant={"h6"}
            className={"title-text"}
            style={{ textAlign: "left" }}
          >
            <span>ESTADO DEL CLIENTE:</span>
            <Button
              variant="contained"
              disableElevation
              style={{
                minWidth: 120,
                height: 25,
                borderRadius: 20,
                backgroundColor: coloredLabel?.bgColor,
                color: coloredLabel?.color,
                fontSize: 12,
                marginLeft: 5,
              }}
            >
              {coloredLabel?.name}
            </Button>
          </Typography>
        </div>
        <Divider />
        <div style={{ display: "flex" }}>
          <Typography
            variant={"h6"}
            className={"title-text"}
            style={{ textAlign: "left" }}
          >
            <span>ESTADO DEL EMPRESA:</span>
            <Button
              variant="contained"
              disableElevation
              style={{
                minWidth: 120,
                height: 25,
                borderRadius: 20,
                backgroundColor: institutionStatus?.bgColor,
                color: institutionStatus?.color,
                fontSize: 12,
                marginLeft: 5,
              }}
            >
              {institutionStatus?.name}
            </Button>
          </Typography>
        </div>
        <Divider />
        <br />
        <Grid container item xs={12} direction="column">
          <Grid item container xs={12} justifyContent="space-evenly">
            <Button
              variant="outlined"
              disableElevation
              style={{
                borderRadius: 3,
                borderColor: "#43A047",
                color: "#43A047",
                fontSize: 12,
                marginBottom: "1rem",
              }}
              onClick={props.handleReloadTicket.bind(
                this,
                props.ticket.uniqueId
              )}
            >
              RECARGAR TICKET
              <Loop style={{ fontSize: 17, marginLeft: ".5rem" }} />
            </Button>
            <Button
              variant="outlined"
              disableElevation
              style={{
                borderRadius: 3,
                borderColor: "#1ea0f7",
                color: "#1ea0f7",
                fontSize: 12,
                marginBottom: "1rem",
              }}
              onClick={props.handleToggleDialog.bind(this, "activity-info")}
            >
              REGISTRO DE ACTIVIDAD
              <Timeline style={{ fontSize: 17, marginLeft: ".5rem" }} />
            </Button>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-start"></Grid>

          <SearchComponent
            xs={12}
            containerClass="field-container"
            fieldLabel="Responsable"
            fieldID="ticket-extra-responsible"
            fieldInnerLabel="Seleccione un responsable"
            // handleChange={handlePersonChange}
            handleChange={handleExtraTicketData.bind(this, "assignee")}
            onInputChange={() => {
              // gg
            }}
            savedValue={extraTicketData.assignee || null}
            optionType={"users"}
            clearOnBlur={false}
            limitTags={1}
          />
          {props.roles && props.roles.includes("supervisor") && (
            <SearchComponent
              xs={12}
              containerClass="field-container"
              fieldLabel="Supervisor"
              fieldID="ticket-extra-supervisor"
              fieldInnerLabel="Seleccione un supervisor"
              // handleChange={handlePersonChange}
              handleChange={handleExtraTicketData.bind(this, "reviewer")}
              onInputChange={() => {
                // gg
              }}
              savedValue={extraTicketData.reviewer || null}
              optionType={"users"}
              clearOnBlur={false}
              limitTags={1}
            />
          )}
          {checkCurrentStepOrder() ? (
            <SearchComponent
              xs={12}
              containerClass="field-container"
              fieldLabel="Aprobado por"
              fieldID="ticket-extra-approved-by"
              fieldInnerLabel="Seleccione quién aprobó"
              handleChange={handleExtraTicketData.bind(this, "approvedBy")}
              onInputChange={() => {
                // gg
              }}
              savedValue={extraTicketData.approvedBy || null}
              disabled={true}
              optionType={"users"}
              clearOnBlur={false}
              limitTags={1}
            />
          ) : null}
          <TextFieldComponent
            xs={12}
            containerClass="field-container pd-y-2"
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Descripción"
            fieldID="extra-data-description"
            fieldClass="fields"
            fieldVariant="outlined"
            placeholder="Detalle general del ticket..."
            margin="dense"
            fullWidth
            value={extraTicketData.description || ""}
            onChange={handleExtraTicketData.bind(this, "description")}
            multiline={true}
            rows={6}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              className={"action-method-btn"}
              disableElevation
              style={{ height: 30 }}
              onClick={handleDescription}
            >
              guardar
            </Button>
          </div>
        </Grid>
        <Divider style={{ margin: "1rem 0" }} />

        <Grid
          container
          item
          xs={12}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TextFieldComponent
            xs={8}
            containerClass="field-container pd-y-2"
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Comentario"
            fieldID="extra-data-comment"
            fieldClass="fields"
            fieldVariant="outlined"
            placeholder="Escriba algo..."
            margin="dense"
            fullWidth
            value={extraTicketData.comment || ""}
            onChange={handleExtraTicketData.bind(this, "comment")}
          />
          <Grid item container xs={4} justifyContent="flex-end">
            <Button
              variant="contained"
              className={"action-method-btn"}
              disableElevation
              style={{ height: 30, marginLeft: ".5rem", marginTop: 35 }}
              onClick={handleSendComment}
            >
              <Send
                style={{ fontSize: 17, color: "#fff", marginRight: ".2rem" }}
              />
              Enviar
            </Button>
          </Grid>
        </Grid>

        <Paper style={{ margin: "1rem 0" }}>
          <Typography
            variant="overline"
            style={{ color: "#424242", fontSize: ".9rem", padding: 16 }}
          >
            COMENTARIOS
          </Typography>
          <List>
            {comments.length > 0 ? (
              comments.map((item, index) => {
                return (
                  <div key={index}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar style={{ fontSize: 15, color: "#fff" }}>
                          {item.user.substring(0, 1).toUpperCase()}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.comment}
                        secondary={`${item.time &&
                          item.time.replace("T", " | hora: ")} | usuario: ${
                          item.user
                        }`}
                      />
                      {item.user === props.loggedUser ? (
                        <ListItemSecondaryAction
                          onClick={props.handleToggleSimpleDialog.bind(
                            this,
                            "confirm-dialog",
                            "proceed-comment-delete",
                            { callback: handleDeleteComment.bind(this, item) }
                          )}
                        >
                          <IconButton edge="end" aria-label="delete">
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      ) : null}
                    </ListItem>
                    {index + 1 !== comments.length && <Divider />}
                  </div>
                );
              })
            ) : (
              <Typography
                variant={"subtitle1"}
                className={"section-header"}
                style={{
                  fontWeight: "bold",
                  color: "#5e5e5e",
                  fontStyle: "italic",
                  marginBottom: "2rem",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "1rem",
                }}
              >
                No hay ningún comentario...
              </Typography>
            )}
          </List>
        </Paper>

        <Divider style={{ margin: "1rem 0" }} />
        <FilesManagementGeneral {...props} />

        <Grid item container xs={12}>
          {cancelObj && (
            <div
              style={{
                backgroundColor: "#FAFAFA",
                borderRadius: 3,
                padding: "1rem 1rem 2rem 1rem",
                width: "100%",
                marginTop: "1rem",
                border: "1px solid #ffd4d4",
              }}
            >
              <CancelOutlined
                style={{
                  fontSize: 24,
                  color: "#ff6666",
                  marginLeft: "auto",
                  display: "flex",
                }}
              />
              {cancelObj.map(({ label, value }, index) => {
                return (
                  <Typography
                    key={index}
                    variant={"subtitle1"}
                    className={"section-header"}
                    style={{
                      fontWeight: "bold",
                      color: "#5e5e5e",
                      margin: ".5rem 0",
                    }}
                  >
                    {`${label}: `}
                    <Typography
                      variant="overline"
                      style={{ fontWeight: "normal", fontSize: "0.85rem" }}
                    >
                      {value}
                    </Typography>
                  </Typography>
                );
              })}
            </div>
          )}
          <Button
            disabled={Boolean(props.ticketCompleted)}
            variant="outlined"
            disableElevation
            style={{
              borderRadius: 3,
              borderColor: props.ticketCompleted ? "#828282" : "#ef5350",
              color: props.ticketCompleted ? "#828282" : "#ef5350",
              fontSize: 12,
              marginLeft: "auto",
              marginTop: "1rem",
            }}
            onClick={props.handleToggleDialog.bind(this, "cancel-ticket")}
          >
            CANCELAR TICKET
            <DeleteOutline style={{ fontSize: 17, marginLeft: ".5rem" }} />
          </Button>
        </Grid>
      </Paper>
    </div>
  );
}

function TicketDetail(props) {
  const [currentTab, setCurrentTab] = useState(false);
  const [disabledTabs, setDisabledTabs] = useState([]);
  const [ticketIsInaccessible, setInaccess] = useState(false);

  const handleTabChange = (event, val) => {
    setCurrentTab(val);
  };

  const findStep = (step) => {
    return !Boolean(disabledTabs.find((item) => item.step === step).accessible);
  };

  const getDebtData = (data) => {
    props.setDebtData(data);
  };

  useEffect(() => {
    const data = props.ticket.steps.map((item) => {
      return {
        step: item.step || "",
        accessible: item.status === "in_process" || item.status === "completed",
      };
    });
    setDisabledTabs(data);
  }, [props.ticket]);

  useEffect(() => {
    setCurrentTab(props.currentTab);
  }, [props.currentTab]);

  useEffect(() => {
    setInaccess(props.ticketCompleted);
  }, [props.ticketCompleted]);

  return (
    <div>
      {props.ticket.requestType === "new partner" && (
        <AdmissionRequestDetail
          {...props}
          handleTabChange={handleTabChange}
          setCurrentTab={setCurrentTab}
          findStep={findStep}
          currentTab={currentTab}
          disabledTabs={disabledTabs}
          ticketIsInaccessible={ticketIsInaccessible}
        />
      )}
      {props.ticket.requestType === "outlay" && (
        <DisbursementRequestDetail
          {...props}
          handleTabChange={handleTabChange}
          setCurrentTab={setCurrentTab}
          findStep={findStep}
          currentTab={currentTab}
          disabledTabs={disabledTabs}
          ticketIsInaccessible={ticketIsInaccessible}
          getDebtData={getDebtData}
          stepsSemiCompleted={props.stepsSemiCompleted}
        />
      )}
      {props.ticket.requestType === "payment" && (
        <PaymentDetail
          {...props}
          handleTabChange={handleTabChange}
          setCurrentTab={setCurrentTab}
          findStep={findStep}
          currentTab={currentTab}
          disabledTabs={disabledTabs}
          ticketIsInaccessible={ticketIsInaccessible}
        />
      )}
    </div>
  );
}

function TicketManagementView(props) {
  const semiCompletedSteps = ["credit_formalization"];
  const limitRequest = { limit: 100, filterFinishedTickets: true };
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({ name: "", data: null });
  const [tickets, setTickets] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });
  const [selectedFilters, setSelectedFilters] = useState({ ...limitRequest });
  const [currentTicket, setCurrentTicket] = useState(null);
  const [loggedUser, setLoggedUser] = useState(null);
  const [roles, setRoles] = useState(null);
  const [participant, setParticipant] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [ticketCompleted, setTicketCompleted] = useState(false);
  const [reloadTicket, setReloadTicket] = useState(0);
  const [debtData, setDebtData] = useState({});
  const [stepsSemiCompleted, setStepsSemiCompleted] = useState(null);

  const handleReloadTicket = (id) => {
    getTicket(id);
    setReloadTicket(Date.now());
  };

  const handleToggleDialog = (dialog, data) => {
    const selectedDialog = dialog ? dialog : "";
    const dialogData = data && (data.uniqueId || data.id) ? data : null;
    const obj = { name: selectedDialog, data: dialogData };
    setDialog({ ...obj });
  };

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };

  const check = (param) => {
    const list = [
      { name: "low", value: 1 },
      { name: "mid", value: 2 },
      { name: "high", value: 3 },
      { name: "new_request", value: 1 },
      { name: "liquidated", value: 2 },
      { name: "payment_reconciled", value: 3 },
      { name: "pending_settlement", value: 4 },
      { name: "processed", value: 5 },
      { name: "delayed", value: 6 },
      { name: "cancelled", value: 7 },
    ];
    return list.find((item) => item.name === param).val;
  };

  const getTickets = (params) => {
    const searchParams = params ? params : limitRequest;
    setLoading(true);
    const endpoint = fetchTickets({ ...searchParams, token: props.user.token });
    endpoint
      .then((response) => {
        let data = response.data.results
          ? response.data.results
          : response.data;
        setTickets(data);
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };
  const handleDetailToggle = (ticket) => {
    // when creating ticket, set new ticket data
    if (ticket && ticket.uniqueId) {
      setCurrentTicket(ticket);
    }
    if (showDetail) {
      const filters = Object.keys(selectedFilters).length;
      getTickets(filters ? selectedFilters : limitRequest);
    }
    setShowDetail(!showDetail);
  };

  const applyFilters = (fields) => {
    let data = { ...fields };
    if (Object.keys(fields).length === 0) {
      data = limitRequest;
    }
    getTickets(data);
    setSelectedFilters(data);
  };

  const getCurrentTab = (ticket) => {
    if (ticket) {
      const stepInProcess = ticket.steps.find(
        (item) => item.status === "in_process"
      );
      const tab = stepInProcess ? stepInProcess.order : 0;
      setCurrentTab(tab);
    }
  };

  // const checkAccess = () => {
  //     const canAccess = ['analista de credito', 'oficial de negocio'];
  //     const access = canAccess.find(role => roles.includes(role));
  //     return Boolean(access) ? 'granted' : 'denied';
  // }

  const handleSelectTicket = async (ticket) => {
    // if(checkAccess() === 'denied'){
    //     handleToggleSimpleDialog('simple-dialog', `No tiene acceso a esta pantalla.`);
    //     return;
    // }
    if(props.id && currentTicket){
      setCurrentTicket(ticket);
      getCurrentTab(ticket);
    }else{
      await getTicket(ticket.uniqueId);
    }
    handleDetailToggle();
  };

  const getTicket = async (ticketId) => {
    setLoading(true);
    try {
      const response = await fetchTicket(props.user.token, ticketId);
      setCurrentTicket({ ...response.data });
      getCurrentTab(response.data);
      setLoading(false);
    } catch (errors) {
      setLoading(false);
      errorHandling(errors, handleToggleSimpleDialog);
    }
  };
  

  const modifyTicketStep = (steps, ticket, history, refreshTicketData) => {
    let data = { ...ticket, steps: steps };
    if (history) {
      data = { ...data, activityInfo: history };
    }
    setLoading(true);
    const endpoint = updateTicket(props.user.token, ticket.uniqueId, data);
    endpoint
      .then((response) => {
        if (refreshTicketData && refreshTicketData.fromDialog) {
          refreshTicketData.reload(response.data);
        }
        setCurrentTicket({ ...response.data });
        // getTicket(ticket.uniqueId);
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };
  const modifyTicket = async (data, history) => {
    let newInfo = { ...currentTicket };
    const ticketId = currentTicket.uniqueId;
    if (data) {
      Object.keys(data).forEach((item) => {
        newInfo[item] = data[item];
      });
    }
    if (history) {
      newInfo.activityInfo = history;
    }
    setLoading(true);
    const endpoint = updateTicket(props.user.token, ticketId, newInfo);
    await endpoint
      .then((response) => {
        setCurrentTicket({ ...response.data });
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const getUser = async (searchParams) => {
    const params = {
      token: props.user.token,
      ...(searchParams || {}),
    };
    return await fetchAssignees(params).then((response) => {
      // const data = response.data.find(user => user.id === jwtDecode(props.user.token).user_id);
      const data = response.data[0];
      return data;
    });
  };

  const getSelectedTicketParticipant = async () => {
    if (currentTicket.customer) {
      const participant = await fetchParticipant(
        props.user.token,
        currentTicket.customer
      ).then((res) => (res.data.results ? res.data.results : res.data));
      setParticipant(participant);
    }
  };

  const handleGetUserData = async () => {
    const userId = jwtDecode(props.user.token).user_id;
    const params = {
      id: userId,
    };
    const userData = await getUser(params);
    setLoggedUser(userData.username);
    setRoles(userData.groups);
  };

  useEffect(() => {
    // in case there's an outer call, won't call all tickets.
    if (!props.id) {
      getTickets(limitRequest);
    }
    handleGetUserData();
    // let intervalID = setInterval(getTickets, 10000);
  }, []);

  useEffect(() => {
    if (currentTicket) {
      // const semiCompleted = currentTicket.steps
      // .find(item => semiCompletedSteps.includes(item.step) && item.status);
      if (currentTicket.requestType === "outlay") {
        const semiCompleted = currentTicket.steps
          .filter((item) => item.status === "completed")
          .map((item) => item.step);
        setStepsSemiCompleted(semiCompleted);
      }
      const inaccess = Boolean(
        ["processed", "rejected_rrhh", "cancelled", "rejected"].includes(
          currentTicket.status
        )
      );
      setTicketCompleted(inaccess);
      getSelectedTicketParticipant();
    }
  }, [currentTicket]);

  useEffect(() => {
    if (props.id && !currentTicket) {
      getTicket(props.id);
    }
    if (props.id && currentTicket) {
      handleSelectTicket(currentTicket);
    }
  }, [props.id, currentTicket]);

  // useEffect(() => {
  //     if(roles) {
  //         setViewAccess(checkAccess());
  //     }
  // }, [roles]);

  // useEffect(() => {
  //     if(viewAccess === 'denied') {
  //         handleToggleSimpleDialog('simple-dialog', `No tiene acceso a esta pantalla.`);
  //     }
  // }, [viewAccess]);

  return (
    // !viewAccess || viewAccess === 'denied' ?
    //     <Grid style={{backgroundColor:'#fff', height:'2000px', width:'100%'}}>
    //         {
    //             simpleDialog.name === 'simple-dialog' ?
    //                 <SimpleConfirmDialog
    //                     open={true}
    //                     handleToggle={handleToggleSimpleDialog}
    //                     data={simpleDialog.msg}
    //                 /> : null
    //         }
    //     </Grid>
    //     :
    <Grid container direction="row">
      <Grid item xs={showDetail ? 8 : 12} style={{ padding: "20px" }}>
        <LoadingDialog open={loading} />
        <ToolbarComponent
          typeVariant="h6"
          typeClass="title-text"
          toolbarTitle="Gestión de Tickets"
          btnVariant="contained"
          goBackColor="#424242"
          goBack={handleDetailToggle}
          showGoBack={!props.id && showDetail}
          toolbarActions={
            !showDetail ? (
              <Button
                variant="contained"
                className={"action-method-btn"}
                disableElevation
                onClick={handleToggleDialog.bind(this, "new-ticket-dialog")}
              >
                <Add
                  style={{ fontSize: 20, color: "#fff", marginRight: ".5rem" }}
                />
                crear nuevo ticket
              </Button>
            ) : (
              <Typography
                variant={"h6"}
                className={"title-text"}
                style={{ textAlign: "right" }}
              >
                <span>TICKET:&nbsp;</span>
                <Button
                  variant="contained"
                  disableElevation
                  style={{
                    minWidth: 120,
                    height: 25,
                    borderRadius: 20,
                    backgroundColor:
                      (debtData.capacidad_endeudamiento > debtData.suggested
                        ? red[500]
                        : debtData.approvedAmount > debtData.suggested
                        ? red[500]
                        : debtData.capacidad_endeudamiento <
                            debtData.applicableAmount &&
                          debtData.capacidad_endeudamiento >
                            debtData.approvedAmount
                        ? orange[500]
                        : debtData.capacidad_endeudamiento >
                            debtData.applicableAmount &&
                          debtData.capacidad_endeudamiento <
                            debtData.suggested &&
                          red[500]) || "#4caf50",
                    //     (
                    //         debtData.applicableAmount < debtData.approvedAmount ? red[500] :
                    //         debtData.applicableAmount > debtData.approvedAmount
                    //     )
                    // orange[500],
                    color: "#fff",
                    fontSize: 12,
                  }}
                >
                  <strong>
                    {currentTicket && currentTicket.referenceCode}
                  </strong>
                </Button>
              </Typography>
            )
          }
        />

        <div
          style={{
            padding: "1%",
            border: "1px solid #EEEEEE",
            borderTop: "none",
            display: showDetail ? "none" : "block",
          }}
        >
          <Filters
            applyFilters={applyFilters}
            // listData={{ payTypes: payTypes, creditors: creditors, clients: clients }}
            listData={{
              tickets: tickets.map((item) => {
                return {
                  referenceCode: item.referenceCode,
                  uniqueId: item.uniqueId,
                };
              }),
              // participants: participants.map(item => {return {fullName: item.fullName, uniqueId: item.uniqueId} }),
              priority: [
                { name: "Baja", value: "low" },
                { name: "Media", value: "mid" },
                { name: "Alta", value: "high" },
              ],
              ticketTypes: REQUEST_TICKET_TYPES,
              status: ticketStates,
            }}
          />
          <div style={{ margin: "10px 0" }}></div>
          <TicketTable
            data={tickets}
            token={props.user.token}
            handleSelectTicket={handleSelectTicket}
            handleToggleDialog={handleToggleDialog}
            handleToggleSimpleDialog={handleToggleSimpleDialog}
          />
        </div>
        {showDetail && (
          <Paper square>
            <TicketDetail
              ticket={currentTicket}
              handleToggleDialog={handleToggleDialog}
              handleToggleSimpleDialog={handleToggleSimpleDialog}
              token={props.user.token}
              setLoading={setLoading}
              getTicket={getTicket}
              modifyTicketStep={modifyTicketStep}
              modifyTicket={modifyTicket}
              loggedUser={loggedUser}
              roles={roles}
              currentTab={currentTab}
              ticketCompleted={ticketCompleted}
              stepsSemiCompleted={stepsSemiCompleted}
              reloadTicket={reloadTicket}
              setDebtData={setDebtData}
            />
          </Paper>
        )}

        {dialog.name === "new-ticket-dialog" ? (
          <TicketCreationDialog
            open={true}
            data={dialog.data}
            // participants={participants}
            handleToggleDialog={handleToggleDialog}
            handleDetailToggle={handleDetailToggle}
            handleToggleSimpleDialog={handleToggleSimpleDialog}
            setCurrentTab={setCurrentTab}
            setLoading={setLoading}
            tickets={tickets.map((item) => {
              return {
                title: item.title,
                referenceCode: item.referenceCode,
                uniqueId: item.uniqueId,
                fullName: item.title + " - " + item.referenceCode,
              };
            })}
            token={props.user.token}
            loggedUser={loggedUser}
          />
        ) : null}

        {dialog.name === "data-adquisition-dialog" ? (
          <DataAdquisitionDialog
            open={true}
            data={dialog.data}
            handleToggleDialog={handleToggleDialog}
            handleToggleSimpleDialog={handleToggleSimpleDialog}
          />
        ) : null}
        {dialog.name === "manage-files-dialog" ? (
          <FileManagementDialog
            open={true}
            data={dialog.data}
            ticket={currentTicket}
            token={props.user.token}
            handleToggleDialog={handleToggleDialog}
            handleToggleSimpleDialog={handleToggleSimpleDialog}
            setLoading={setLoading}
            ticketCompleted={ticketCompleted}
          />
        ) : null}

        {dialog.name === "prev-step" ? (
          <ConfirmPrevTicket
            open={true}
            data={dialog.data}
            handlePrevStep={handlePrevStep}
            handleToggleDialog={handleToggleDialog}
            handleToggleSimpleDialog={handleToggleSimpleDialog}
          />
        ) : null}

        {dialog.name === "cancel-ticket" ? (
          <CancelTicket
            open={true}
            ticket={currentTicket}
            token={props.user.token}
            modifyTicket={modifyTicket}
            handleToggleDialog={handleToggleDialog}
            handleToggleSimpleDialog={handleToggleSimpleDialog}
            ticketCompleted={ticketCompleted}
            loggedUser={loggedUser}
          />
        ) : null}

        {dialog.name === "payment-calculator" ? (
          <PaymentCalculatorDialog
            open={true}
            ticket={currentTicket}
            data={dialog.data}
            token={props.user.token}
            modifyTicket={modifyTicket}
            handleToggleDialog={handleToggleDialog}
            handleToggleSimpleDialog={handleToggleSimpleDialog}
            ticketCompleted={ticketCompleted}
            fromTicket={true}
          />
        ) : null}
        {dialog.name === "participant-details" ? (
          <ParticipantDetail
            open={true}
            ticket={currentTicket}
            data={dialog.data}
            token={props.user.token}
            modifyTicket={modifyTicket}
            handleToggleDialog={handleToggleDialog}
            handleToggleSimpleDialog={handleToggleSimpleDialog}
            ticketCompleted={ticketCompleted}
            fromTicket={true}
          />
        ) : null}
        {dialog.name === "activity-info" ? (
          <ActivityInfoData
            open={true}
            data={currentTicket.activityInfo}
            token={props.user.token}
            handleToggleDialog={handleToggleDialog}
          />
        ) : null}

        {simpleDialog.name === "simple-dialog" ? (
          <SimpleConfirmDialog
            open={true}
            handleToggle={handleToggleSimpleDialog}
            data={simpleDialog.msg}
          />
        ) : null}

        {simpleDialog.name === "confirm-dialog" ? (
          <ConfirmDialogComponent
            open={true}
            data={simpleDialog}
            handleToggle={handleToggleSimpleDialog}
          />
        ) : null}
        {simpleDialog.name === "success-dialog" ? (
          <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
        ) : null}
        {simpleDialog.name === "error-dialog" ? (
          <ErrorDialog
            open={true}
            handleToggle={handleToggleSimpleDialog}
            data={simpleDialog.msg}
          />
        ) : null}
      </Grid>
      {showDetail && (
        <Grid item xs={4} style={{ padding: "20px" }}>
          <TicketExtraManagement
            ticket={currentTicket}
            token={props.user.token}
            loggedUser={loggedUser}
            setLoading={setLoading}
            handleToggleDialog={handleToggleDialog}
            handleToggleSimpleDialog={handleToggleSimpleDialog}
            modifyTicket={modifyTicket}
            ticketCompleted={ticketCompleted}
            roles={roles}
            handleReloadTicket={handleReloadTicket}
            reloadTicket={reloadTicket}
            getUser={getUser}
          />
        </Grid>
      )}
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps)(TicketManagementView);
